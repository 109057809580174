import React, { Component } from "react";

import { Breadcrumb ,message,Button } from 'antd';
import { Link } from 'react-router-dom';
import Table from 'CMPT/common/Table';
import http from '@/http';
import { API } from '@/http/api';
import {  copyObject} from '@/utils/index';
import EmptyView from "CMPT/widget/EmptyView";
import TitlePanel from "CMPT/TitlePanel";
import { DivConvert, RowTwoConvert,RowThreeConvert } from '@/utils/detail_convert.js'
import EquipmentInfo from "./EquipmentInfo";

import './DetailInfo.less'

class DetailInfo extends React.Component {
    state = {
        code: null,//
        datas: null,

        titleInfoMap:
            new RowThreeConvert(
                new DivConvert('orderNumber', '订单号'),
                new DivConvert('orderTypeName', '订单类型'),
                new DivConvert('orderStatusName', '订单状态'))
        ,

        recordsColumns: this.createColumn(),

        baseInfoMap: [ 
             new RowTwoConvert(new DivConvert('dealTypeName', '付款方式'),new DivConvert('dealPriceDesc', '成交价')),
            new RowTwoConvert(new DivConvert('jobLocation', '施工地'),new DivConvert('rentDate', '租赁日期')),
            new RowTwoConvert(new DivConvert('projectName', '项目名称'),new DivConvert('leaseIntroduce', '求租备注')),
            new RowTwoConvert(new DivConvert('equipmentParty', '设备方'),new DivConvert('equipmentPartyCompany', '设备方账号')),
           // new RowTwoConvert(new DivConvert('equipmentContactsName', '设备方联系人'),new DivConvert('equipmentContactsPhone', '手机号')),
            new RowTwoConvert(new DivConvert('leasingCompany', '求租方'),new DivConvert('leasing', '求租账号')),
            new RowTwoConvert(new DivConvert('lesseeContactsName', '求租联系人'),new DivConvert('lesseeContactsPhone', '手机号')),
        ]

    };

    createColumn(){
       return [
        {
            title: '时间',
            dataIndex: 'handleTime',
            render: (value, record, index) => {
                return <span>{'时间:'+value}</span>;
            }
        }, /*{
            title: '操作人',
            dataIndex: 'handleTime',
            render: (text, record, index) => {
                return <span>{text}</span>;
            }
        }, */{
            title: '订单状态',
            dataIndex: 'orderStatusName',
            render: (text, record, index) => {
                return <span>{'订单状态:'+text}</span>;
            }
        }, {
            title: '备注',
            dataIndex: 'orderRemark',
            render: (text, record, index) => {
                return <span>{'备注:'+text}</span>;
            }
        }
    ];
    }

    componentWillMount() {
        const { match } = this.props;
        this.state.code =  match.params.code;
     }


    getDetail() {

        let that = this;
        let dataParams = { code: this.state.code };
        const config = {
            api: API.equipmentOrderDetail,
            path: dataParams,
            showLoading: true
        };

        http(config).then(res => {
             if (res  && res.code==200&&res.data) {
                 let datas=res.data;
                that.convertHttpData(datas);
                that.setState({datas});
            }else{
                message.error(res.message || '订单详情查询失败');
            }

        });
    };

    convertHttpData(data){
       if(!data){
           return;
       }

        if(!data.dealBothInfo){
           data['dealBothInfo']={};
        }

        if(data.equipmentInfo && data.equipmentInfo.transactionPriceName){
            data.dealBothInfo['transactionPriceName']= data.equipmentInfo.transactionPriceName || "面议";
        }

        copyObject(data,data.dealBothInfo,['dealTypeName','jobLocation','rentDate','projectName','leaseIntroduce']);
        data.dealBothInfo['dealPriceDesc'] = data.dealPrice?(data.dealPrice+'元'):'-';
 
    }

    componentDidMount() {
        this.getDetail();
    }


    render() {
        let {baseInfoMap,datas,recordsColumns,titleInfoMap} = this.state;

        return (  <div className="detailInfo">
        <div>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={{ pathname: "/app/crm/orderManage", search: '?tab=4' }}>
                订单管理
              </Link>
            </Breadcrumb.Item >
            <Breadcrumb.Item>
              租赁订单详情
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>


        {
          (!datas)&&<EmptyView  tips="暂无数据" />
         }


        <div className="detailInfo_body">
         {/* 头部信息 */}
         {
                (datas&&datas.orderNumber) ? <TitlePanel   > {titleInfoMap.convert(datas)}</TitlePanel> :''
        }
      
          

         {/* 操作记录 */}
        {       
           datas&&datas.handleRecord ? (<TitlePanel   title="操作记录" >
               <Table rowKey={(record, index) => (record.code+"_"+index)} columns={ recordsColumns} showHeader={false} data={datas.handleRecord} pagination={false}/>
               </TitlePanel>):""    

        }  
  
  
          {/* 成交信息 */}
       {
           datas&&datas.equipmentInfo ?         
            <TitlePanel  title="成交信息" >
           <EquipmentInfo type={1} data={datas.equipmentInfo}/>
          </TitlePanel> : ""
        }


        {/*  底部的基本信息 */}
       { datas&&datas.dealBothInfo && <TitlePanel   >
        {
            baseInfoMap.map((item, index) => {
                return item.convert(datas.dealBothInfo, index);
            }) 
        }
         </TitlePanel>
        }


          

         </div>

         <div className="detail_btn">
              <Button onClick={() => this.props.history.goBack()}>
                返回
              </Button>
            </div>
       
        </div>);
 
    }
}


export default DetailInfo;
