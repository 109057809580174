import React, { Component } from 'react';
import { Icon } from 'antd';

export default function BOSteps(props) {
    const { info } = props
    return (
        <div className="bo-logistics-step">
            <div className="logistics-info">
                <div className="item">物流公司：<span>{info.logisticsCompany}</span></div>
                <div className="item">物流单号：<span>{info.logisticsNo}</span></div>
            </div>
           {
               info.timeList && info.timeList.length>0 ?
           <div className="logistics-detail">
                {info.timeList && info.timeList.map(item => {
                    return <div className="group" key={item.id}>
                                <span className="time">{item.time}</span>
                                <span className="icon">
                                    <Icon type="up-circle" theme="filled" />
                                </span>
                            <span className="text">{item.text}</span>
                        </div>
                })}
                
            </div> :  <div className="logistics-detail">
            暂时无法查询该物流单号信息
            </div>}
        </div>
    )
}