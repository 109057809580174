/**
 * @file 新增客户/编辑客户 - 供应商信息，可经营类目
 */
import React from 'react';

import { Button, Modal, Table } from 'antd';

import Upload from 'CMPT/common/widget/Upload';
import ClassChcekModal from 'CMPT/common/ClassCheckModal';

import { API } from '@/http/api';
import http from '@/http';

export default class BusinessCategories extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			visible: false,
			categoryList: []
		};

		this.columns = [
			{
				title: '序号',
				width: '60px',
				dataIndex: 'twoCode',
				render(text, record, index) {
					return <span>{index + 1}</span>;
				}
			},
			{
				title: '一级',
				dataIndex: 'one'
			},
			{
				title: '二级',
				dataIndex: 'two'
			},
			{
				title: (
					<div>
						<div>对应类目经营资质</div> 支持图片、word、pdf、excel、ppt、压缩包文件；多个文件打一个压缩包上传
					</div>
				),
				dataIndex: 'file',
				render: (text, record, index) => {
					return (
						<Upload
							accept=".jpeg,.jpg,.png,.bmp,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf,.rar,.zip,.7z,.gzip,.tar"
							listType="text"
							value={text}
							maxSize="500M"
							onChange={(file) => this.changeFile(file, record, index)}
						/>
					);
				}
			},
			{
				title: '操作',
				render: (text, record, index) => {
					return (
						<span
							style={{ cursor: 'pointer', color: '#2E4AFF' }}
							onClick={() => this.remove(record, index)}
						>
							删除
						</span>
					);
				}
			}
		];
	}

	remove(record, index) {
		Modal.confirm({
			title: '是否确认删除',
			onOk: () => {
				const { value = [] } = this.props;
				let newValue = [];
				for (let i = 0; i < value.length; i++) {
					const element = value[i];
					if (i !== index) {
						newValue.push(element);
					}
				}
				this.change(newValue);
			}
		});
	}

	changeFile(file, record, index) {
		const { value = [] } = this.props;

		record.file = file;

		this.change([ ...value ]);
	}

	change(newValue) {
		this.props.onChange(newValue);
	}

	add = () => {
		const { categoryList } = this.state;

		if (categoryList.length === 0) {
			this.loadCategoryList();
		}

		this.setState({
			visible: true
		});
	};

	cancelAdd = () => {
		this.setState({
			visible: false
		});
	};

	sureAdd = (selectedTwoList, map, selectedOneList) => {
		const { value = [] } = this.props;

		const newSelected = [];

		for (const { name, code, childList } of selectedOneList) {
			for (const child of childList) {
				if (!value.find(({ twoCode }) => twoCode === child.code)) {
					newSelected.push({
						one: name,
						two: child.name,
						oneCode: code,
						twoCode: child.code,
						twoCategoryId: child.categoryId
					});
				}
			}
		}

		this.change([ ...value, ...newSelected ]);

		this.setState({
			visible: false
		});
	};

	getHadCheckList() {
		const hadCheckList = [];

		const { value = [] } = this.props;
		for (const { two, twoCode, twoCategoryId } of value) {
			hadCheckList.push({
				categoryId: twoCategoryId,
				name: two,
				code: twoCode
			});
		}

		return hadCheckList;
	}

	loadCategoryList() {
		const config = {
			api: API.enterpriseUpgradeCategoryTree,
			showLoading: true
		};
		http(config).then(({ success, data }) => {
			if (success && data && data.length) {
				this.setState({
					categoryList: data
				});
			}
		});
	}

	render() {
		const { value = [] } = this.props;
		const { visible, categoryList } = this.state;
		const hadCheckList = this.getHadCheckList();
		const columns = this.columns;

		let scroll = {};

		if (value && value.length > 12) {
			scroll = { y: 600 };
		}

		return (
			<div>
				<Button type="primary" onClick={this.add}>
					增加
				</Button>
				<Table rowKey="twoCode" pagination={false} dataSource={value} columns={columns} scroll={scroll} />
				<ClassChcekModal
					visible={visible}
					categoryList={categoryList}
					hadChcekList={hadCheckList}
					checkChildOk={this.sureAdd}
					handleCancel={this.cancelAdd}
				/>
			</div>
		);
	}
}
