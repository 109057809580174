import React, { Component } from 'react';
import { Breadcrumb, Select, Tabs, Badge } from 'antd';
import './index.less'
import InquiryInfo from './InquiryInfo';
import RentInfo from './RentInfo/index';
import ServiceInfo from './ServiceInfo/index';
import GoodsAuditList from './GoodsAudit/GoodsAuditList';
import LeaseOperateMsg from './LeaseOperateMsg/LeaseOperateMsg';
import CapacityAuditList from './CapacityAudit/CapacityAuditList';
import InvireTenders from './InviteTenders/List'
import { API } from '@/http/api';
import http from '@/http';
import userUtil from "@/utils/user";
const {isUs} = userUtil;
const BreadcrumbItem = Breadcrumb.Item;
const { Option } = Select;
const { TabPane } = Tabs;

export default class Sourcing extends Component {
    state = {
        currentTab: '1',
        menuNumber: null,
        activeTab: 0
    }

    componentWillMount() {
        let {search} = this.props.location
            if(search){
                var str = search.substr(1);
                this.setState({
                currentTab: str.split("=")[1]
            })
        }
    }

    componentDidMount () {
        this.getTabsCount()
    }

    getTabsCount () {
        const config = {
            api: API.menuNumberMsgAudit,
        }
        http(config).then(res => {
            if (res && res.success && res.data) {
                this.setState({ menuNumber: res.data})
            }
        })
    }

    changeTabs = (value) => {
        this.props.history.push(`/app/crm/sourcing?tab=${value}`);
         this.setState({
             currentTab: value
         })
         this.getTabsCount()
    }
    changeSmallTabs = (e) => {
        this.setState({
            activeTab: e
        })
        this.getTabsCount()
    }

    render() {
        const {menuNumber, activeTab} = this.state;
        return (
            <div className="sourcing-page">
                <Breadcrumb>
                    <BreadcrumbItem>信息审核</BreadcrumbItem>
                </Breadcrumb>

                <Tabs activeKey={this.state.currentTab}  onChange={this.changeTabs} className='tabs' style={{margin: 0}}>
                        <TabPane tab={<Badge count={menuNumber && menuNumber.inquire}> 询价信息 </Badge>} key="1" className='tab'>
                            <InquiryInfo  countNumber={menuNumber && menuNumber.inquire} getTabsCount={this.getTabsCount.bind(this)} />
                        </TabPane>

                        <TabPane tab={<Badge count={menuNumber && menuNumber.invitationTender}> 招标信息 </Badge>} key="7" className='tab'>
                            <InvireTenders  countNumber={menuNumber && menuNumber.invitationTender} getTabsCount={this.getTabsCount.bind(this)} />
                        </TabPane>
                        {isUs && <TabPane tab={<Badge count={menuNumber && menuNumber.transport}> 运力信息 </Badge>} key="2" className='tab'>
                            <Tabs defaultActiveKey="1" onChange={this.changeSmallTabs}>
                                <TabPane tab="全部" key="" className='tab' >
                                    <CapacityAuditList state="" />   
                                </TabPane>
                                <TabPane    tab={"待审批"+(menuNumber &&menuNumber.transport?"("+menuNumber.transport+")":'')} key="1" className='tab' >
                                    <CapacityAuditList state="0" />   {/* 0表示待审核 */}
                                </TabPane>
                                <TabPane tab="已通过" key="2" className='tab' >
                                    <CapacityAuditList state="10" />  {/* 10 表示已通过 */}
                                </TabPane>
                                <TabPane tab="已驳回" key="3" className='tab'>
                                    <CapacityAuditList state="20" />  {/* 20表示已驳回  */}
                                </TabPane>
                                <TabPane tab="违规下架" key="4" className='tab'>
                                    <CapacityAuditList state="21" />  {/* 21表示违规下架  */}
                                </TabPane>
                            </Tabs>
                        </TabPane>}
                        <TabPane tab={<Badge count={menuNumber && menuNumber.delivery}> 发货信息 </Badge>} key="3" className='tab'>
                            <Tabs defaultActiveKey="1"  onChange={this.changeSmallTabs} >
                                <TabPane tab="全部" key="" className='tab' >
                                    <GoodsAuditList state="" />  
                                </TabPane>
                                <TabPane    tab={"待审批"+(menuNumber &&menuNumber.delivery?"("+menuNumber.delivery+")":'')} key="1" className='tab' >
                                    <GoodsAuditList state="0" />   {/* 0表示待审核 */}
                                </TabPane>
                                <TabPane tab="已通过" key="2" className='tab' >
                                    <GoodsAuditList state="10" />  {/* 10 表示已通过 */}
                                </TabPane>
                                <TabPane tab="已驳回" key="3" className='tab'>
                                    <GoodsAuditList state="20" />  {/* 20表示已驳回  */}
                                </TabPane>
                                <TabPane tab="违规下架" key="4" className='tab'>
                                    <GoodsAuditList state="21" />  {/* 21表示违规下架  */}
                                </TabPane>
                            </Tabs>
                        </TabPane>

                        {isUs && <TabPane tab={<Badge count={menuNumber && menuNumber.businessInformation}> 出租经营信息 </Badge>} key="4" className='tab'>
                            <Tabs defaultActiveKey="1"  onChange={this.changeSmallTabs} >
                                <TabPane tab="全部" key="" className='tab' >
                                    <LeaseOperateMsg state="" />  
                                </TabPane>
                                <TabPane  tab={"待审核"+(menuNumber &&menuNumber.businessInformation?"("+menuNumber.businessInformation+")":'')}  key="1" className='tab' >
                                    <LeaseOperateMsg state="0" />   {/* 0表示待审核 */}
                                </TabPane>
                                <TabPane tab="已通过" key="2" className='tab' >
                                    <LeaseOperateMsg state="10" />  {/* 10 表示已通过 */}
                                </TabPane>
                                <TabPane tab="已驳回" key="3" className='tab'>
                                    <LeaseOperateMsg state="20" />  {/* 20表示已驳回  */}
                                </TabPane>
                                <TabPane tab="违规下架" key="4" className='tab'>
                                    <LeaseOperateMsg state="21" />  {/* 21表示违规下架  */}
                                </TabPane>
                            </Tabs>
                        </TabPane>}

                        {isUs && <TabPane tab={<Badge count={menuNumber && menuNumber.equipmentOut}> 设备出租 </Badge>} key={'5'} className='tab'>
                           <Tabs defaultActiveKey="0"  onChange={this.changeSmallTabs} >
                             <TabPane tab="全部" key="-1" className='tab' >
                                 <RentInfo   auditStatus={-1} infoType={1} />
                              </TabPane>
                              <TabPane tab={"待审核"+(menuNumber &&menuNumber.equipmentOut?"("+menuNumber.equipmentOut+")":'')} key="0" className='tab' >
                                 <RentInfo   auditStatus={0} infoType={1} />
                              </TabPane>
                              <TabPane tab="已通过" key="1" className='tab' >
                                 <RentInfo   auditStatus={1} infoType={1} />
                              </TabPane>
                              <TabPane tab="已驳回" key="2" className='tab' >
                                 <RentInfo c auditStatus={2} infoType={1} />
                              </TabPane>
                              <TabPane tab="违规下架" key="3" className='tab' >
                                 <RentInfo   auditStatus={3} infoType={1} />
                              </TabPane>
                              </Tabs>
                        </TabPane>}

                        {isUs && <TabPane tab={<Badge count={menuNumber && menuNumber.equipmentIn}> 租用需求 </Badge>} key={'6'} className='tab'>
                            <Tabs defaultActiveKey="0"  onChange={this.changeSmallTabs} >
                            <TabPane tab="全部" key="-1" className='tab' >
                                 <RentInfo   auditStatus={-1} infoType={0} />
                              </TabPane>
                              <TabPane tab={"待审核"+(menuNumber &&menuNumber.equipmentIn?"("+menuNumber.equipmentIn+")":'')} key="0" className='tab' >
                                 <RentInfo   auditStatus={0} infoType={0} />
                              </TabPane>
                              <TabPane tab="已通过" key="1" className='tab' >
                                 <RentInfo   auditStatus={1} infoType={0} />
                              </TabPane>
                              <TabPane tab="已驳回" key="2" className='tab' >
                                 <RentInfo   auditStatus={2} infoType={0} />
                              </TabPane>
                              <TabPane tab="违规下架" key="3" className='tab' >
                                 <RentInfo   auditStatus={3} infoType={0} />
                              </TabPane>
                            </Tabs>
                        </TabPane>}

                        {isUs && <TabPane tab={<Badge count={menuNumber && menuNumber.technicalService}> 服务信息 </Badge>} key={'8'} className='tab'>
                            <Tabs defaultActiveKey="0"  onChange={this.changeSmallTabs} >
                                <TabPane tab="全部" key="-1" className='tab' >
                                    {activeTab == -1 ? <ServiceInfo   auditStatus={-1} /> : null}
                                </TabPane>
                                <TabPane tab={"待审核"+(menuNumber &&menuNumber.technicalService?"("+menuNumber.technicalService+")":'')} key="0" className='tab' >
                                    {activeTab == 0 ? <ServiceInfo   auditStatus={0} /> : null}
                                </TabPane>
                                <TabPane tab="已通过" key="1" className='tab' >
                                    {activeTab == 1 ? <ServiceInfo   auditStatus={10} /> : null}
                                </TabPane>
                                <TabPane tab="已驳回" key="2" className='tab' >
                                    {activeTab == 2 ? <ServiceInfo   auditStatus={20} /> : null}
                                </TabPane>
                                <TabPane tab="违规下架" key="3" className='tab' >
                                    {activeTab == 3 ? <ServiceInfo   auditStatus={21} /> : null}
                                </TabPane>
                            </Tabs>
                        </TabPane>}
                        
                        {/* <TabPane tab="求租信息" key={'5'} className='tab'>

                        </TabPane> */}
                       
                </Tabs>

            </div>
        );
    }
}