import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Button, Steps, Tooltip, Modal } from 'antd';
import userUtil from '@/utils/user';
import { AUTH_KEYS } from '@/utils/auth';
import http from '@/http';
import { API } from '@/http/api';
import useActionLoading from 'CMPT/common/hooks/useActionLoading';
import './SettingApproveManageRead.less';

const BreadcrumbItem = Breadcrumb.Item;
const Step = Steps.Step;

let noAuthPeople = [];

export default function SettingApproveManageRead({ match: { params: { id } }, history }) {
    const [manage, setManage] = useState({});

    const [showOnlineTip, toggleOnlineTip] = useState(false);

    const [showDeleteConfirm, toggleDeleteConfirm] = useState(false);
    const [deleteLoading, takeDeleteAction] = useActionLoading();

    useEffect(() => {
        loadDetail();
    }, [id]);

    function loadDetail() {
        http({
            api: API.flowTemplateGet,
            data: {
                encode: id
            },
            showLoading: true
        }).then(ajaxData => {
            const { type, typeDesc, name, templateDesc, projects, status, verifyNodeList } = ajaxData.data || {};
            setManage({
                name: name,
                type,
                approveTypeDesc: typeDesc,
                desc: templateDesc,
                projectsDesc: (projects || []).map(({ name }) => name),
                nodes: verifyNodeList || [],
                state: status
            })
        });
    }

    function getOperate(state) {
        if (userUtil.checkAuthByKey(AUTH_KEYS.SETTING_APPROVE_CONFIG)) {
            const online = <Button key='online' className='manage-operate' onClick={onlineManage}>上线</Button>;
            const offline = <Button key='offline' className='manage-operate' onClick={offlineManage}>下线</Button>;
            const copy = <Link key='copy' to={'/app/setting/approve/manage/copy/' + id} className='manage-operate'><Button>复制</Button></Link>;
            const modify = <Link key='modify' to={'/app/setting/approve/manage/modify/' + id} className='manage-operate'><Button>编辑</Button></Link>;
            const remove = <Button key='delete' type='danger' ghost className='manage-operate' onClick={displayDelete}>删除</Button>;

            switch (state) {
                case 0:
                    return [remove, modify, copy, online];
                case 1:
                    return [offline, copy];
                case 2:
                    return [copy];
                default:
                    return [];
            }
        }
    }

    function displayDelete() {
        toggleDeleteConfirm(true);
    }

    function onlineManage() {
        http({
            api: API.flowTemplateOnline,
            data: {
                encode: id
            },
            showLoading: true,
            successTip: '上线成功'
        }).then(ajaxData => {
            if (ajaxData.success) {
                loadDetail();
            } else if (Array.isArray(ajaxData.data)) {
                noAuthPeople = [];
                ajaxData.data.forEach(name => {
                    noAuthPeople.push(<span key={name} className='no-auth-person'>{name}</span>, '、');
                });
                noAuthPeople.pop();
                toggleOnlineTip(true);
            }
        });
    }

    function offlineManage() {
        http({
            api: API.flowTemplateOffline,
            data: {
                encode: id
            },
            showLoading: true,
            successTip: '下线成功'
        }).then(ajaxData => {
            if (ajaxData.success) {
                loadDetail();
            }
        });
    }

    function deleteManage() {
        takeDeleteAction(
            {
                api: API.flowTemplateDelete,
                data: {
                    encode: id
                },
                showLoading: true,
                successTip: '删除成功'
            }, () => {
                hideDeleteConfirm();
                history.replace('/app/setting/approve/manage');
            }
        );
    }

    function goEdit() {
        hideOnlineTip();
        history.push('/app/setting/approve/manage/modify/' + id);
    }

    function hideOnlineTip() {
        toggleOnlineTip(false);
    }

    function hideDeleteConfirm() {
        toggleDeleteConfirm(false);
    }

    const { name, type, approveTypeDesc, desc, projectsDesc = [], nodes = [], state } = manage;

    const manageOperate = getOperate(state);

    const { descText, descClass } = getStateDesc(state);

    return (
        <div className='setting-approve-manage-read'>
            <div className='manage-read-header'>
                <Breadcrumb>
                    <BreadcrumbItem>审批管理</BreadcrumbItem>
                    <BreadcrumbItem><Link to='/app/setting/approve/manage'>审批模板管理</Link></BreadcrumbItem>
                    <BreadcrumbItem>审批模板详情</BreadcrumbItem>
                </Breadcrumb>
                <div>
                    {
                        manageOperate
                    }
                </div>
            </div>
            <div className='manage-read-info'>
                <div className='info-title'>基本信息</div>
                <div className={descClass}>{descText}</div>
                <div className='info-detail'>模板名称：{name}</div>
                {type !== 10 && <div className='info-detail'>所属项目：{projectsDesc.join('、')}</div>}
                <div className='info-detail'>审批类型：{approveTypeDesc}</div>
                <div className='info-detail'>模板说明：{desc}</div>
            </div>
            <div className='manage-read-nodes'>
                <div className='nodes-title'>审批节点</div>
                <Steps direction='vertical' current={-9}>
                    {
                        nodes.map(node => (<Step key={node.id} description={<NodeRead info={node} />} />))
                    }
                </Steps>
            </div>
            <Modal
                title={<span>{noAuthPeople}不具备所选项目或所选业务的操作权限，请修改审批模板。</span>}
                visible={showOnlineTip}
                closable={false}
                maskClosable={false}
                okText='立即修改'
                width={400}
                cancelText='暂不修改'
                onOk={goEdit}
                onCancel={hideOnlineTip}
            />
            <Modal
                title="确认删除此模板？"
                visible={showDeleteConfirm}
                okText='删除'
                confirmLoading={deleteLoading}
                closable={false}
                width={400}
                maskClosable={false}
                onOk={deleteManage}
                onCancel={hideDeleteConfirm}
            />
        </div>
    );
}

function NodeRead({ info: { name, desc, typeDesc, verifyUsers } }) {
    const peopleDisplay = [];
    let split = '';

    switch (typeDesc) {
        case '并联':
            split = '+';
            break;
        case '协同':
            split = '/';
            break;
        default:
            typeDesc = '';
    }

    if (split) {
        split = <span className='people-split'>{split}</span>
    }

    (verifyUsers || []).forEach(person => {
        peopleDisplay.push(<Tooltip key={person.id} placement="topLeft" title={<div className='person-info-popover'>
            <p>部门：{person.departmentName}</p><p>邮箱：{person.email}</p></div>}><span className='manage-node-person'>{person.name}</span></Tooltip>, split)
    });

    peopleDisplay.pop();

    return (
        <div className='manage-node'>
            <div className='manage-node-title'>{name}{!!typeDesc && ` (${typeDesc})`}</div>
            {desc && <div className='manage-node-desc'>{desc}</div>}
            <div className='manage-node-people'>审批人：{
                peopleDisplay
            }</div>
        </div>
    );
}

function getStateDesc(state) {
    switch (state) {
        case 0:
            return {
                descText: '未上线',
                descClass: 'state-ready'
            };
        case 1:
            return {
                descText: '已上线',
                descClass: 'state-online'
            };
        default:
            return {
                descText: '使用中',
                descClass: 'state-using'
            };;
    }
}
