import React, { Component } from "react";
import { API } from "@/http/api";
import http from "@/http";
import {
  message,
  Button,
  InputNumber,
  Tabs,
  Input,
  Form,
} from "antd";
import reg from "@/utils/regex";
import "./CustomerDecision1.less";

const { phone } = { ...reg };
class CustomerDecision1 extends Component {
  state = {
    keyToIdMap: {} // 缓存决策 Key和ID的Map关系
  };
 
  componentDidMount() {
      this.getProjectSetting();  
  }

  // 获取决策列表
  getProjectSetting = () => {
    // 获取项目配置
    const defaultConfig = {
      api: API.decisionGetList,
      data: {
        bizType: "1"
      }
    };
    http(defaultConfig).then(res => {
      if (res.data && res.data.length) {
          this.initForm(res.data);
      }
    });
  };

  initForm = (data) => {
    let newKeyToIdMap = {};
    data.map(item => {
      newKeyToIdMap[item.key] = item.id;

      let fieldsValue = {};
      if ('crm_auth_to_phone' == item.key) {
        //TODO  实现方法限制了 后续扩展，万一要设置3个电话呢。 
        let phoenArray = item.value && item.value.split("#");
        if(phoenArray&&phoenArray.length==2){
          fieldsValue['phone1'] = phoenArray[0];
          fieldsValue['phone2'] = phoenArray[1];
        }
      } else {
        fieldsValue[item.key] = item.value;
      }

      // 更新界面变化
      this.props.form.setFieldsValue(fieldsValue);
    });

    this.setState({
      keyToIdMap: newKeyToIdMap
    });
  }

  trySubmit = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let fieldsObject = this.props.form.getFieldsValue();
        this.doSubmit({ ...fieldsObject });
      }
    });
  }

  createParmas = fields => {
    // 需要特殊处理
    fields.crm_auth_to_phone = fields.phone1 + "#" + fields.phone2;
    delete fields.phone1;
    delete fields.phone2;

    let params = [];
    let keyToIdMap =   this.state.keyToIdMap; // 获取列表时 已经缓存了
 
     // 遍历下 去改造
    Object.getOwnPropertyNames(fields).forEach(function (keyName) {
      let param =   { key: keyName, value: fields[keyName] };
      let id = keyToIdMap[keyName];
       if(id){
         // 修改类型的，会从后端拿到 ID。 后端依赖这个来判断，是更新 还是插入.
        param['id']= id;
      }
      params.push(param);
    })

    return params;
  }

  doSubmit = fields => { 
    // 构造参数
    const config = {
      api: API.decisionSaveOrUpdate,
      data: {
        bizType: "1",
        decisionList: this.createParmas(fields)
      }
    };

    // 调用 接口
    http(config).then(res => {
      if (res.success) {
        message.success("保存成功");
        this.refresh();
      }
    });
  };

  refresh=()=>{
    this.getProjectSetting();
  }

  validateConfirmPw = (_, value, cb) => {
    const inputValue = this.props.form.getFieldValue("trial_account_days");
    const reg = /^[0-9]+$/;
    if (!inputValue || reg.test(inputValue)) {
      cb();
    } else {
      cb("请输入正整数");
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="decision-page-1">
        <div className="decision-block">
          <div className="block">
            <div className="title">试用账号有效天数</div>
            <div className="input-unit">
              <Form onSubmit={this.saveSetting} className="login-form">
                <Form.Item>
                  {getFieldDecorator("trial_account_days", {
                    initialValue: this.state.inputValue,
                    rules: [
                      { required: true, validator: this.validateConfirmPw }
                    ]
                  })(
                    <InputNumber
                      placeholder="请输入"
                      min={0}
                    />
                  )}
                </Form.Item>
              </Form>
              <span className="unit">天</span>
            </div>
          </div>
          <div className="title" >
            认证信息通知接收人：<span>请输入接收人手机号</span>
          </div>
          <div className="decision-row-2">

            <Form.Item>
              {getFieldDecorator("phone1", {
                rules: [
                  { required: true, message: "请输入手机号" },
                  { pattern: phone, message: "请输入正确的手机号" }
                ]
              })(
                <Input
                  style={{ width: '200px' }}
                  maxLength={11}
                  placeholder="请输入手机号"
                />
              )}
            </Form.Item>

            <Form.Item label="" style={{ marginLeft: '10px' }}>
              {getFieldDecorator("phone2", {
                rules: [
                  { required: true, message: "请输入手机号" },
                  { pattern: phone, message: "请输入正确的手机号" }
                ]
              })(
                <Input
                  style={{ width: '200px' }}
                  maxLength={11}
                  placeholder="请输入手机号"
                />
              )}
            </Form.Item>

          </div>
          <div  className="decision-operate1">
            <Button type="primary" onClick={this.trySubmit} size="large">
              保存
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
export default Form.create({ name: "CustomerDecision1" })(CustomerDecision1);
