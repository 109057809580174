import React, { Component, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Form, Breadcrumb, Button, Input, Select, Steps, Tooltip, Icon, message, TreeSelect } from 'antd';
import http from '@/http';
import { API } from '@/http/api';
import './SettingApproveManageForm.less';

const BreadcrumbItem = Breadcrumb.Item;
const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;
const Step = Steps.Step;

const NODE_KEY = Symbol('node key');

const noop = () => { };

export default function SettingApproveManageForm({ match, history }) {
    const [formType, setFormType] = useState();

    const [manage, setManage] = useState({});

    const [manageNodes, setManageNodes] = useState([{ [NODE_KEY]: Math.random() }]);

    const [enums, setEnums] = useState({});

    const [people, setPeople] = useState([]);

    const [submitLoading, toggleSubmitLoading] = useState(false);

    const initManageDone = useRef(true);
    const initManageNodesNum = useRef(0);

    let baseForm, nodeForms = [];

    useEffect(() => {
        resetCurrent();

        const formType = getCurrentFormType(match.path);
        setFormType(formType);

        const id = match.params.id;

        switch (formType) {
            case FORM_TYPES.MODIFY:
                initEditForm(id);
                break;
            case FORM_TYPES.COPY:
                initCopyForm(id);
                break;
            default:
                initNewForm();
                break;
        }
    }, [match.path]);

    function resetCurrent() {
        initManageDone.current = true;
        initManageNodesNum.current = 0;
    }

    function initNewForm() {
        initEnums();
    }

    function initCopyForm(id) {
        initManage(id, (manage, copyNum) => {
            manage.name += '-副本';
            if (copyNum > 0) {
                manage.name += '(' + (copyNum + 1) + ')';
            }
        });

        initEnums();
    }

    function initEditForm(id) {
        initManage(id);

        initEnums();
    }

    function initManage(id, initName = noop) {
        http({
            api: API.flowTemplateGet,
            data: {
                encode: id
            },
            showLoading: true
        }).then(ajaxData => {
            const { copyNum, type, name, templateDesc, projects, verifyNodeList } = (ajaxData.data || {});
            const manage = {
                name: name,
                approveType: type,
                desc: templateDesc,
                projects: (projects || []).map(({ id }) => id),
            };
            initName(manage, copyNum);
            const nodes = (verifyNodeList || []).map(node => {
                const { desc,
                    name,
                    type,
                    verifyUsers } = node;
                return {
                    name,
                    desc,
                    nodeType: type,
                    people: (verifyUsers || []).map(({ id }) => 'user_' + id),
                    [NODE_KEY]: Math.random()
                }
            });

            initManageDone.current = false;
            setManage(manage);

            filterNoAuthPeople(manage, nodes);
        });
    }

    function initEnums() {
        http({
            api: API.flowTemplateAddInit,
            showLoading: true
        }).then(ajaxData => {
            const { nodeType = [], projectList = [], type = [] } = ajaxData.data || {};
            setEnums({
                approveTypes: type,
                nodeTypes: nodeType,
                projects: projectList
            });
        });
    }

    function submit() {
        Promise.all(nodeForms.map(validateForm).concat(validateForm(baseForm))).then(array => {
            toggleSubmitLoading(true);
            const formData = getFormData(array);

            const id = match.params.id;

            let api;

            switch (formType) {
                case FORM_TYPES.MODIFY:
                    api = API.flowTemplateUpdate;
                    formData.encode = id;
                    break;
                // case FORM_TYPES.COPY:
                //     api = API.flowTemplateCopy;
                //     formData.encode = id;
                //     break;
                default:
                    api = API.flowTemplateAdd;
                    break;
            }

            http({
                api,
                data: formData,
                showLoading: true,
                successTip: '保存成功'
            }).then(ajaxData => {
                toggleSubmitLoading(false);
                if (ajaxData.success) {
                    history.replace('/app/setting/approve/manage');
                }
            }).catch(error => {
                toggleSubmitLoading(false);
            });
        }).catch(errors => {
            console.info(errors);
        });
    }

    function getFormData(formArray) {
        const {
            name,
            projects: projectIds,
            desc: templateDesc,
            approveType: type
        } = formArray.pop();

        const formData = {
            name,
            projectIds,
            templateDesc,
            type
        }

        formData.verifyNodes = formArray.map(({ desc,
            name,
            nodeType: type,
            people: verifyBys }) => {
            if (!Array.isArray(verifyBys)) {
                verifyBys = [verifyBys];
            }

            verifyBys = verifyBys.map(value => value.replace('user_', ''));

            return {
                desc,
                name,
                type,
                verifyBys
            }
        });

        return formData;
    }

    function cancel() {
        if (history.length > 1) {
            history.goBack();
        }
    }

    function setBaseForm(form) {
        baseForm = form;
        if (!isNewForm(formType) && form && !initManageDone.current) {
            initManageDone.current = true;
            form.props.form.setFieldsValue({
                ...manage
            });
        }
    }

    function setNodeForm(form, index) {
        nodeForms[index] = form;
        if (!isNewForm(formType) && form && initManageNodesNum.current) {
            initManageNodesNum.current--;
            const nodeInfo = manageNodes[index];
            const { name = '',
                desc = '',
                nodeType = null,
                people = [] } = nodeInfo;
            form.props.form.setFieldsValue({
                name,
                desc,
                nodeType,
                people
            });
        }
    }

    function addNode(index) {
        if (manageNodes.length < 15) {
            const nextManageNodes = [...manageNodes];
            nextManageNodes.splice(index, 0, { [NODE_KEY]: Math.random() });
            setManageNodes(nextManageNodes);
        } else {
            message.info('最多支持15个节点');
        }
    }

    function removeNode(index) {
        if (manageNodes.length > 1) {
            const nextManageNodes = [...manageNodes];
            nextManageNodes.splice(index, 1);
            setManageNodes(nextManageNodes);
        }
    }

    function resetApprovePeople(value) {
        let projectIds;
        let type;
        if (Array.isArray(value)) {
            type = baseForm.props.form.getFieldValue('approveType');
            projectIds = value;
        } else {
            type = value;
            projectIds = baseForm.props.form.getFieldValue('projects') || [];
        }

        if (type === 10) {
            projectIds = [];
        }

        getPeople(type, projectIds);

        // 改了审批类型或项目时，清空审批人
        nodeForms.forEach(form => {
            form.props.form.setFieldsValue({
                people: []
            });
        });
    }

    function getPeople(type, projectIds, showLoading) {
        if (type === 10 || (type && projectIds.length)) {
            return http({
                api: API.flowTemplateUsers,
                data: {
                    projectIds,
                    type
                },
                showLoading
            }).then(ajaxData => {
                const originalTrees = ajaxData.data;

                setPeople(getTrees(originalTrees));

                return originalTrees;
            });
        }

        return Promise.reject('no need to get people');
    }

    function filterNoAuthPeople(manage, manageNodes) {
        // 审批模板编辑，信息回填的时候，存在原来的审批人A不具有权限了，这时候获取的审批人下拉列表就不包括该审批人A了
        // 这时将不具有权限的审批人A过滤掉

        getPeople(manage.approveType, manage.projects, true).then(trees => {

            const ids = getUserIds(trees);

            for (let index = 0; index < manageNodes.length; index++) {
                const node = manageNodes[index];

                node.people = node.people.filter(value => {
                    const id = +(value.replace('user_', ''));
                    return ids.includes(id);
                });
            }

            initManageNodesNum.current = manageNodes.length;
            setManageNodes(manageNodes);
        }).catch(error => {
            initManageNodesNum.current = manageNodes.length;
            setManageNodes(manageNodes);
        });
    }

    function getUserIds(trees) {
        if (trees) {
            return trees.reduce((ids, { child, user }) => {
                return ids.concat((user || []).map(({ id }) => id)).concat(getUserIds(child));
            }, []);
        }
        return [];
    }

    function getTrees(trees) {
        if (trees) {
            return trees.map(({ child, department, user }) => {
                const { name: title, id } = department || {};
                const value = 'de_' + id;
                return {
                    title,
                    value,
                    key: value,
                    selectable: false,
                    children: (user || []).map(({ name: title, id }) => {
                        const value = 'user_' + id;
                        return {
                            title,
                            value,
                            key: value,
                        }
                    }).concat(getTrees(child))
                }
            });
        }
        return [];
    }

    return (
        <div className='setting-approve-manage-form'>
            <Breadcrumb>
                <BreadcrumbItem>审批管理</BreadcrumbItem>
                <BreadcrumbItem><Link to='/app/setting/approve/manage'>审批模板管理</Link></BreadcrumbItem>
                <BreadcrumbItem>{getCurrentFormTitle(formType)}</BreadcrumbItem>
            </Breadcrumb>
            <div className='manage-form-info'>
                <div className='manage-form-info-title'>基本信息</div>
                <WrapperManageBaseInfoForm wrappedComponentRef={setBaseForm} typeList={enums.approveTypes} projectList={enums.projects} resetApprovePeople={resetApprovePeople} />
            </div>
            <div className='manage-nodes-info'>
                <div className='manage-nodes-info-title'>审批节点定义 <Tooltip placement="topLeft" align={{offset: [-50, -4]}}  overlayClassName='manage-nodes-info-tip' title={
                    <div>
                        <p>每个节点支持选择三种审批类型</p>
                        <p>1) 串联审批：仅一人审批</p>
                        <p>2) 并联审批：全部审批人均通过则通过，一人驳回即驳回</p>
                        <p>3) 协同审批：一人通过即通过，一人驳回即驳回</p>
                    </div>}><Icon type="question-circle" /></Tooltip></div>
                <div onClick={() => addNode(0)} className='manage-node-add-before'>+ 插入节点</div>
                <Steps direction='vertical' current={-9}>
                    {
                        manageNodes.map((node, index) => (<Step key={node[NODE_KEY]} description={<WrapperManageNodeForm wrappedComponentRef={form => { setNodeForm(form, index) }}
                            index={index} total={manageNodes.length} addNode={addNode} removeNode={removeNode} nodeTypes={enums.nodeTypes} peopleList={people} />} />))
                    }
                </Steps>
            </div>
            <div className='manage-form-operate'>
                <Button type="primary" onClick={submit} loading={submitLoading} size='large'>提交</Button>
                <Button onClick={cancel} size='large'>取消</Button>
            </div>
        </div>

    );
}

class ManageBaseInfoForm extends Component {
    render() {
        const { form: { getFieldDecorator, getFieldValue }, typeList = [], projectList = [], resetApprovePeople } = this.props;

        const approveType = getFieldValue('approveType');

        return (
            <Form >
                <FormItem
                    label="模板名称"
                >
                    {getFieldDecorator('name', {
                        rules: [{
                            required: true, message: '审批模板名称不能为空！',
                        }],
                    })(
                        <Input placeholder="请输入" className='manage-input' maxLength={50} />
                    )}
                </FormItem>
                <FormItem
                    label="审批类型"
                >
                    {getFieldDecorator('approveType', {
                        rules: [{
                            required: true, message: '审批类型不能为空！',
                        }],
                    })(
                        <Select placeholder="请选择" className='manage-select' onChange={resetApprovePeople}>
                            {
                                typeList.map(({ key, value }) => (<Option value={key} key={key}>{value}</Option>))
                            }
                        </Select>
                    )}
                </FormItem>
                {approveType !== 10 && <FormItem
                    label="所属项目"
                >
                    {getFieldDecorator('projects', {
                        rules: [{
                            required: true, message: '所属项目不能为空！',
                        }],
                    })(
                        <Select placeholder="请选择" className='manage-project-select' onChange={resetApprovePeople} mode="multiple" optionFilterProp="children">
                            {
                                projectList.map(({ id, name }) => (<Option value={id} key={id}>{name}</Option>))
                            }
                        </Select>
                    )}
                </FormItem>}
                <FormItem
                    label="模板说明"
                >
                    {getFieldDecorator('desc')(
                        <TextArea placeholder="请输入" className='manage-desc' maxLength={200} />
                    )}
                </FormItem>
            </Form>
        );
    }
}

const WrapperManageBaseInfoForm = Form.create({ name: 'ManageBaseInfoForm' })(ManageBaseInfoForm);

class ManageNodeForm extends Component {
    state = {
        nodeTypeTrigger: 0,
        searchValue: ''
    }

    currentSelectedPeople = null

    validatorPeople = (_, value, cb) => {
        const nodeType = this.props.form.getFieldValue('nodeType');
        if ([2, 3].includes(nodeType)) {
            if (!Array.isArray(value) || value.length < 2) {
                return cb('请至少选择两个审批人');
            }
            if (value.length > 6) {
                return cb('请至多选择六个审批人');
            }
        }
        return cb();
    }

    changeNodeType = (value) => {
        this.setState({ nodeTypeTrigger: value });
    }

    changePeople = (value) => {
        if (Array.isArray(value)) {
            if (value.length > 6) {
                message.info('最多支持6人同时审批');
                const currentSelectedPeople = this.currentSelectedPeople;
                const lastSelectedPeople = currentSelectedPeople ? (Array.isArray(currentSelectedPeople) ? [...currentSelectedPeople] : [currentSelectedPeople]) : [];
                setTimeout(() => {
                    this.props.form.setFieldsValue({ people: lastSelectedPeople });
                }, 0);
            }
        }
    }

    onDropdownVisibleChange = (isShow) => {
        if (!isShow) {
            this.setState({
                searchValue: ''
            });
        }
    }

    onSearch = (searchValue) => {
        this.setState({
            searchValue
        });
    }

    render() {
        const { form: { getFieldDecorator, getFieldValue }, nodeTypes = [], peopleList = [], index, total, addNode, removeNode } = this.props;

        const needMultiplePeople = [2, 3].includes(getFieldValue('nodeType'));

        this.currentSelectedPeople = getFieldValue('people');

        const { searchValue } = this.state;

        return (
            <Form className='manage-node'>
                {total !== 1 && <span onClick={() => removeNode(index)} className='manage-node-remove'><Icon type="delete" theme="filled" />  删除</span>}
                <div className='manage-node-row'>
                    <FormItem
                        label="节点名称"
                    >
                        {getFieldDecorator('name', {
                            rules: [{
                                required: true, message: '节点名称不能为空！',
                            }],
                        })(
                            <Input placeholder="请输入" className='manage-input' maxLength={20} />
                        )}
                    </FormItem>
                    <FormItem
                        label="节点说明"
                    >
                        {getFieldDecorator('desc')(
                            <Input placeholder="请输入" className='manage-input' maxLength={100} />
                        )}
                    </FormItem>
                    <FormItem
                        label="节点类型"
                    >
                        {getFieldDecorator('nodeType', {
                            rules: [{
                                required: true, message: '节点类型不能为空！',
                            }],
                        })(
                            <Select placeholder="请选择" className='manage-select' onChange={this.changeNodeType}>
                                {
                                    nodeTypes.map(({ key, value }) => (<Option value={key} key={key}>{value}</Option>))
                                }
                            </Select>
                        )}
                    </FormItem>
                </div>
                <FormItem
                    label="审批人"
                >
                    {getFieldDecorator('people', {
                        rules: [{
                            required: true, message: '审批人不能为空！',
                        }, {
                            validator: this.validatorPeople
                        }],
                    })(
                        <TreeSelect searchValue={searchValue} onDropdownVisibleChange={this.onDropdownVisibleChange} onSearch={this.onSearch}
                            placeholder="请选择" className={needMultiplePeople ? 'manage-people-select' : 'manage-select'} onChange={this.changePeople}
                            multiple={needMultiplePeople} treeCheckable={needMultiplePeople}
                            showSearch={needMultiplePeople} treeNodeFilterProp='title' allowClear treeDefaultExpandAll treeData={peopleList} />
                    )}
                </FormItem>
                <div onClick={() => addNode(index + 1)} className='manage-node-add-after'>+ 插入节点</div>
            </Form>
        );
    }
}

const WrapperManageNodeForm = Form.create({ name: 'ManageNodeForm' })(ManageNodeForm);

function getCurrentFormType(path) {
    const result = /manage\/([^/]+)\/?/.exec(path);
    if (result) {
        return result[1];
    }
    return FORM_TYPES.NEW;
}

function getCurrentFormTitle(type) {
    switch (type) {
        case FORM_TYPES.MODIFY:
            return '编辑审批模板';
        case FORM_TYPES.COPY:
            return '复制审批模板';
        default:
            return '新增审批模板';
    }
}

function isNewForm(type) {
    return type === FORM_TYPES.NEW;
}

const FORM_TYPES = {
    NEW: 'new',
    COPY: 'copy',
    MODIFY: 'modify'
}

function validateForm(form) {
    return new Promise((resolve, reject) => {
        form.props.form.validateFields((errors, values) => {
            if (errors) {
                reject(errors);
            } else {
                resolve(values)
            }
        });
    })
}
