import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import FinanceApproval from './FinanceApproval';
import ReceiptApproval from './ReceiptApproval';
import PayApproval from './PayApproval';
import RefundApproval from './RefundApproval';
import { Breadcrumb, Button, Tabs  } from 'antd';
import http from '@/http';
import { API } from '@/http/api';
const BreadcrumbItem = Breadcrumb.Item;
const TabPane = Tabs.TabPane;
class List extends Component {
    state = {
        bill:0 ,
        payment:0,
        receive:0,
        refund:0,
        tabActive:'1'
    }
    componentDidMount(){
        this.getCount();
    }
    getCount(){
        http({
            api: API.billFinanceAuditCount,
        }).then(res => {
            if(res.data){
                console.log("loadCaptcha--------------", res.data);
                this.setState({
                    bill:res.data.bill,
                    payment:res.data.payment,
                    receive:res.data.receive,
                    refund:res.data.refund
                })
            }           
        })
    }
    goAdd = () => {
        console.log(this.props)
        this.props.history.push('/app/operate/contract/main/add')
    }
    render() {
        const {bill,payment,receive,refund} = this.state;
        let tab1 = '账单审批('+bill+')';
        let tab2 = '收款审批('+receive+')';
        let tab3 = '付款审批('+payment+')';
        let tab4 = '退款审批('+refund+')';
        return (
            <div className="contract-list-out financeWrap">
                <div className="contract-header">
                    <Breadcrumb>
                        <BreadcrumbItem>财务收支</BreadcrumbItem>
                        <BreadcrumbItem><Link to='/app/operate/finance/approval'>财务审批</Link></BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <div className="contract-content">
                    <Tabs defaultActiveKey="1" animated={false}>
                        <TabPane tab={tab1} key="1">
                            <FinanceApproval/>
                        </TabPane>
                        <TabPane tab={tab2} key="2">
                            <ReceiptApproval/>
                        </TabPane>   
                        <TabPane tab={tab3} key="3">
                            <PayApproval />
                        </TabPane>   
                        <TabPane tab={tab4} key="4">
                            <RefundApproval />
                        </TabPane>                        
                    </Tabs>
                </div>
                
            </div>
        );
    }
}

export default List;