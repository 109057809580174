export const ManagementModel =  [
    // 经营模式
    {
        value: 1,
        label: "生产厂家 "
    },
    {
        value: 2,
        label: "经销批发"
    },
    {
        value: 3,
        label: "商业服务"
    },
    {
        value: 4,
        label: "招商代理"
    },
    {
        value: 5,
        label: "其它"
    }
];