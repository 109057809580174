import React, { Component } from 'react';
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import NotFound from 'CMPT/NotFound';
import ErrorPage from 'CMPT/common/ErrorPage';
// import Login from 'CMPT/Login';
import LoginNew from 'CMPT/LoginNew'
import LoginQr from 'CMPT/LoginWxQr'
import Main from 'CMPT/Main';
import userUtils from '@/utils/user';
const { isUs } = userUtils;
class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
            <Route exact path="/" render={() => <Redirect to="/app" push /> } />        
            {/* <Route path="/app" render={(props) => isLogin()?<Main {...props}/>:<Redirect to="/login" push /> } />        */}
            <Route path="/app" render={(props) => <Main {...props}/>} />
            <Route path="/login" render={() => isUs ? <LoginQr />:<LoginNew /> } /> 
            <Route path="/loginnew" render={() =>  <LoginNew /> } /> 
            {/* <Route path="/login" render={() => isLogin()?<Redirect to="/app" push />:<LoginQr /> } />  */}
            {/* <LoginQr></LoginQr> 企业微信扫码登录 */}
            <Route path="/403" render={() =><ErrorPage code={403}/>} />
            <Route path="/500" render={() =><ErrorPage code={500}/>} />
            <Route component={NotFound} />
        </Switch>        
      </Router>
    );
  }
}

export default App;
