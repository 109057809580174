import React, { Component } from 'react';
import { API } from '@/http/api';
import http from '@/http';
import Table from 'CMPT/common/Table';
import { Modal, Input, InputNumber, message, Popover, Icon } from 'antd';

export default class OrderPriceChange extends Component{
    state = {
        columns: [
            {
                title: '商品信息',
                dataIndex: 'goodsName',
                width: 240
            }, {
                title: '规格',
                dataIndex: 'spec'
            }, {
                title: '单价',
                dataIndex: 'goodsPrice',
                render: (text) => {
                    return <span>¥ {text.toFixed(2)}</span>
                }
            }, {
                title: '数量',
                dataIndex: 'num'
            }, {
                title: '降价或涨价',
                dataIndex: 'modifyPrice',
                render: (text, row, index) => {
                    return <span className="price-input">
                         ¥ <Input value={text} onBlur={this.checkAndCalc.bind(this, index)} onChange={this.setPrice.bind(this, index)} placeholder="0.00" />
                        </span>
                }
            }, {
                title: '运费',
                dataIndex: 'freight',
                rowSpan: 2,
                render: (value, row, index) => {
                    const length = this.state.goodsList.length
                    const obj = {
                        children: <span className="price-input">¥ <InputNumber value={this.state.freight} onChange={this.setFreight} placeholder="0.00" /></span>,
                        props: {rowSpan: length}
                    }
                    if (index % length !== 0) {
                        obj.props.rowSpan = 0
                    }
                    return obj
                }
            }
        ],
        goodsList: [],
        originOrderTotal: 0,
        orderTotal: 0,
        freight: 0,
        orderModifyTotal: 0,
        buyerAddress: ''
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props && nextProps.show) {
            const params = {
                api: API.orderDetail,
                showLoading: true,
                path: {
                    orderCode: nextProps.orderCode
                }
            }
            http(params).then(res => {
                if (res.success) {
                    this.setAllData(res.data)
                    this.setModifyTotal()
                }
            })
        }
    }
    setAllData = (obj) => {
        let list = []
        let ad = ''
        if (obj.buyerOrderAddressVo) {
            let nameList = ['provinceName', 'cityName', 'districtName', 'address']
            nameList.forEach(ii => {
                if (obj.buyerOrderAddressVo[ii]) {
                    ad += obj.buyerOrderAddressVo[ii]
                }
            })
        }
        list = obj.orderSubVos.map(sub => {
            return {
                key: sub.orderSubCode,
                goodsName: sub.orderSkuSnapshotDto.itemName,
                spec: Array.isArray(sub.orderSkuSnapshotDto.skuSpecValue) ? sub.orderSkuSnapshotDto.skuSpecValue.map(per => per.specValueName).join(' ') : '-',
                goodsPrice: sub.orderSkuSnapshotDto.salePrice,
                modifyPrice: undefined,
                num: sub.quantities
            }
        })
        this.setState({
            originOrderTotal: obj.finalAmount,
            freight: obj.finalExpressAmount,
            buyerAddress: ad,
            goodsList: list
        })
    }
    // 运费，影响总价orderModifyTotal
    setFreight = (e) => {
        const value = this.checkNumber(e)
        this.setState({
            freight: Number(value)
        }, this.setModifyTotal)
    }
    // 单个商品价格调整，影响订单金额orderTotal和总价orderModifyTotal
    setPrice = (index, e) => {
        let goodsList = this.state.goodsList
        const value = e.target.value
        this.checkNumber(value, true)
        goodsList[index].modifyPrice = value
        this.setState({
            goodsList
        })
    }
    // 失焦时判断
    checkAndCalc = (index, e) => {
        let goodsList = this.state.goodsList
        const value = this.checkNumber(e.target.value, true)
        goodsList[index].modifyPrice = Number(value)
        this.setState({
            goodsList
        }, this.setModifyTotal)
    }
    setModifyTotal = () => {
        let total = 0
        let orderTotal = 0
        const goodsList = this.state.goodsList
        goodsList.forEach(per => {
            orderTotal += per.goodsPrice * per.num + (per.modifyPrice || 0)
        })
        total += orderTotal + this.state.freight
        this.setState({
            orderTotal,
            orderModifyTotal: total
        })
    }
    checkNumber = (number, allow) => {
        let reg = /^[0-9]{0,6}$/
        let value = String(number)
        if (value.indexOf('.') !== -1) {
            reg = /^[0-9]{0,6}\.?[0-9]{0,2}$/
        }
        if (allow && value.startsWith('-')) {
            value = value.split('-')[1]
        } else if (!allow && value.startsWith('-')) {
            message.warning('请输入正确的数据')
            return 0
        }
        if (value && !reg.test(value)) {
            message.warning('请输入正确的数据')
            return 0
        }
        return number === '-' ? 0 : number
    } 
    cancel = () => {
        this.props.cancel(false)
    }
    confirm = () => {
        const params = {
            api: API.orderPriceModify,
            showLoading: true,
            data: this.getPostData()
        }
        http(params).then(res => {
            if (res.success) {
                message.success('修改成功')
                this.props.handleSuccess()
            }
        })
    }
    getPostData = () => {
        return {
            orderMainCode: this.props.orderCode,
            adjustFreight: this.state.freight,
            orderSubChangePrices: this.state.goodsList.map(per => {
                return {
                    adjustPrice: per.modifyPrice || 0,
                    orderSubCode: per.key
                }
            })
        }
    }
    render() {
        const { goodsList, originOrderTotal, orderTotal, orderModifyTotal, freight } = this.state
        const { show } = this.props
        return (
            <Modal
                    visible={show}
                    closable={false}
                    maskClosable={false}
                    width="1064px"
                    title="修改订单金额"
                    okText="修改"
                    onCancel={this.cancel}
                    onOk={this.confirm}
                    destroyOnClose={true}
                >
                <div className="order-price-modal">
                    <div className="order-info">
                        <span className="weight">订单原价</span><span className="lighter">{originOrderTotal.toFixed(2)}</span>元
                    </div>
                    <div className="goods-info">
                        <Table columns={this.state.columns} pagination={false} selfProcessData={goodsList} />
                        <Popover content='直接输入金额（负数代表降价，正数代表涨价）' title="">
                            <Icon type="question-circle" />
                        </Popover>
                    </div>
                    <div className="buyer-info">
                        <p>收货地址：{this.state.buyerAddress}</p>
                        <p>买家实付：{orderTotal.toFixed(2)}+{freight ? freight.toFixed(2) : 0}=
                            <span>{orderModifyTotal.toFixed(2)}</span>元（原价 + 运费 + 折扣或涨价）
                        </p>
                    </div>
                </div>
            </Modal>
        );
    }
}
