import {
    AJAX_TYPE
} from '../config';

// 区域

const prefix = 'zone';

export default {
    // 新增区域
    [`${prefix}Add`]: {
        url: '/web/zone/add',
        type: AJAX_TYPE.POST
    },
    // 删除区域
    [`${prefix}Delete`]: {
        url: '/web/zone/delete/:zoneEncode',
        type: AJAX_TYPE.GET
    },
    // 拆分区域
    [`${prefix}Divide`]: {
        url: '/web/zone/divide',
        type: AJAX_TYPE.POST
    },
    // 编辑区域
    [`${prefix}Edit`]: {
        url: '/web/zone/edit',
        type: AJAX_TYPE.POST
    },
    // 资产管理-查看区域详细信息
    [`${prefix}GetZoneDetail`]: {
        url: '/web/zone/getZoneDetail/:zoneEncode',
        type: AJAX_TYPE.GET
    },
    // 查看区域详细信息
    [`${prefix}GetZoneInfo`]: {
        url: '/web/zone/getZoneInfo/:zoneEncode',
        type: AJAX_TYPE.POST
    },
    // 获取某个楼层下的所有区域
    [`${prefix}Get_list`]: {
        url: '/web/zone/get_list',
        type: AJAX_TYPE.GET
    },
    // 合并区域
    [`${prefix}Merge`]: {
        url: '/web/zone/merge',
        type: AJAX_TYPE.POST
    },
    // 变更出租状态
    [`${prefix}UpdateRentStatus`]: {
        url: '/web/zone/updateRentStatus',
        type: AJAX_TYPE.GET
    },
}