import React, { Component } from 'react';
import { Breadcrumb, Button, Row, Col ,DatePicker,Input,TreeSelect,message,Select  } from 'antd';
import Table from 'CMPT/common/Table';
import http from '@/http';
import { API } from '@/http/api';
import AuthContainer from 'CMPT/common/auth/AuthContainer';
import { AUTH_KEYS } from '@/utils/auth';
const BreadcrumbItem = Breadcrumb.Item;
const { RangePicker } = DatePicker;
const Search = Input.Search;
const { Option } = Select;
var moment = require('moment');
class IncomeExpenseReport extends Component {
    componentWillMount(){        
        this.getList()
    }
    
    getList = () => {
        const config = {
            api: API.reportformIncomeExpense,
            data: {
                beginDate: this.state.createTimeFrom,// (string, optional): 创建时间-起始 ,
                endDate: this.state.createTimeTo,// (string, optional): 创建时间-结束 ,
                billNo: this.state.keywords,// (string, optional): 搜索关键字 ,
                feeTypes : this.state.currentMerchant,// (integer, optional): 费用类型 ,
                
                pageNo: this.state.pageNo || 1,// (integer, optional),
                pageSize: this.state.pageSize || 10,// (integer, optional),
            },
            showLoading: true
        };
        http(config).then(res => {

            this.setState({
                data: res.data&&res.data.datas||[],
                total: res.data&&res.data.total||0,
            })
        })
    }
    state = {
        incomeType:  [
        
        ],
        createTimeFrom:moment().subtract(1, 'years').add(1,'days').format('YYYY-MM-DD'),
        createTimeTo:moment().format('YYYY-MM-DD'),
        currentMerchant: [],
        currentRent: null,
        keywords:null,
        pageNo:1,
        pageSize:10,
        total:0,
        costType: [
            {key:1,value:1,title:"定金"},
            {key:2,value:2,title:"保证金"},
            {key:3,value:3,title:"合同保证金"},
            {key:4,value:4,title:"装修保证金"},
            {key:5,value:5,title:"物业保证金"},
            {key:6,value:6,title:"租金"},
            {key:7,value:7,title:"手续费"},
            {key:8,value:8,title:"服务费"},
            {key:9,value:9,title:"保洁费"},
            {key:10,value:10,title:"物业费"},
            {key:11,value:11,title:"网费"},
            {key:12,value:12,title:"水费"},
            {key:13,value:13,title:"电费"},
            {key:14,value:14,title:"滞纳金"},
            {key:15,value:15,title:"违约金"},
            {key:16,value:16,title:"搬家费"},
            {key:17,value:17,title:"耗材费"},
            {key:99,value:99,title:"其他费用"}
        ],
        
        columns: [
            {
                title: '创建时间',
                width: 140,
                dataIndex: 'createTime',
                fixed: 'left'
            },{
                title: '账单编号',
                width: 120,
                dataIndex: 'billNo',
                fixed: 'left'
            },{
                title: '项目',
                width: 120,
                dataIndex: 'projectName'
            },{
                title: '区域',
                width: 120,
                dataIndex: 'floorMsg'
            },{
                title: '账单类型',
                width: 120,
                dataIndex: 'billTypeStr'
            },{
                title: '费用类型',
                width: 120,
                dataIndex: 'feeTypeStr'
            },{
                title: '费用周期',
                width: 120,
                dataIndex: 'datePeriod'
            },{
                title: '合同编号',
                width: 120,
                dataIndex: 'contractNo'
            },{
                title: '账单状态',
                width: 120,
                dataIndex: 'billAuditStatus'
            },{
                title: '创建人',
                width: 120,
                dataIndex: 'createUsername'
            },{
                title: '应收/付金额',
                width: 120,
                dataIndex: 'amount'
            },{
                title: '应收/付时间',
                width: 120,
                dataIndex: 'date'
            },{
                title: '实收金额',
                width: 120,
                dataIndex: 'receiveAmount'
            },{
                title: '实收时间',
                width: 120,
                dataIndex: 'receiveDate'
            },{
                title: '审核状态',
                width: 120,
                dataIndex: 'receiveAuditStatusStr'
            },{
                title: '实付金额',
                width: 120,
                dataIndex: 'payAmount'
            },{
                title: '实付时间',
                width: 120,
                dataIndex: 'payDate'
            },{
                title: '审核状态',
                width: 120,
                dataIndex: 'payAuditStatusStr'
            },{
                title: '已开收据金额',
                width: 120,
                dataIndex: 'receiptAmount'
            },{
                title: '已退金额',
                width: 120,
                dataIndex: 'refundAmount'
            }
        ],
        data: []
    }
    handleDate = (moment,date) => {
        console.log(date)
        this.setState({
            createTimeFrom:date[0],
            createTimeTo:date[1]
        },()=>{
            this.getList();
        })
    }
    changeSearch = (value) => {
        this.setState({
            keywords: value,
            pageNo:1,
            pageSize:10
        }, () => {
            this.getList()
        })
    }
    onChange = (page, pageSize) => {
        console.log('contract list', page, pageSize)
        this.setState({
            pageNo: page,
            pageSize: pageSize
        }, () => {
            this.getList()
        })
    }
    
    chooseCostType = (res) => {        
        console.log(res)
        this.setState({
            currentMerchant: res,
            pageNo:1,
            pageSize:10
        }, () => {
            this.getList()
        })
    }
    reset=()=>{
        this.setState({
            createTimeFrom:moment().subtract(1, 'years').add(1,'days').format('YYYY-MM-DD'),
            createTimeTo:moment().format('YYYY-MM-DD'),
            currentMerchant: [],
            currentRent: null,
            keywords:null,
            pageNo:1,
            pageSize:10,
            isReset:true,
        },()=>{
            this.setState({
                isReset:false,
            })
            this.getList()
        })
    }
    export = () => {        
        const config = {
            api: API.reportformIncomeExpenseExportReport,
            data: {
                beginDate: this.state.createTimeFrom,// (string, optional): 创建时间-起始 ,
                endDate: this.state.createTimeTo,// (string, optional): 创建时间-结束 ,
                billNo: this.state.keywords,// (string, optional): 搜索关键字 ,
                feeTypes: this.state.currentMerchant,// (integer, optional): 招商状态 ,
                // rentStatus: this.state.currentRent,// (integer, optional): 出租状态 ,
                pageNo: this.state.pageNo || 1,// (integer, optional),
                pageSize: this.state.pageSize || 10,// (integer, optional),
            },
            responseType:'blob',
            showLoading: true
        };
        http(config).then(res => {
            if (res.size < 1024) {
                message.error('下载异常，请稍后重试')
            } else { 
            const content = res
            const blob = new Blob([content],{type: 'application/vnd.ms-excel'})
            const fileName = `收支报表${moment(new Date()).format('YYYY-MM-DD HH:mm')}.xlsx`
            if ('download' in document.createElement('a')) { // 非IE下载
                const elink = document.createElement('a')
                elink.download = fileName
                elink.style.display = 'none'
                elink.href = URL.createObjectURL(blob)
                document.body.appendChild(elink)
                elink.click()
                URL.revokeObjectURL(elink.href) // 释放URL 对象
                document.body.removeChild(elink)
            } else { // IE10+下载
                navigator.msSaveBlob(blob, fileName)
            }
        }
        })
    }
    render() {
        console.log(this.state.currentMerchant)
        const tProps = {
            treeData:this.state.costType,            
            onChange: this.chooseCostType,
            value:this.state.currentMerchant,
            treeCheckable: true,            
            searchPlaceholder: '费用类型',
            style: {float:'left',width:288,marginRight:12},
        };
        const costType = this.state.costType
        const children = [];
        for (let i = 0; i < costType.length; i++) {
            children.push(<Option key={costType[i].key}>{costType[i].title}</Option>);
        }
        return (
            <div className="report-con">
            <div className='report-header'>
                <Breadcrumb>
                    <BreadcrumbItem>报表中心</BreadcrumbItem>
                    <BreadcrumbItem> 收支报表</BreadcrumbItem>
                    
                </Breadcrumb>             
                <Button type="primary" className='download' onClick={this.export}>下载报表</Button>           
            </div>
            <div className='report-list'>
                {
                    !this.state.isReset?(
                        <div className="report-search">
                    
                    <Search style={{float:'left',width:240,marginRight:12}}
                        placeholder="请输入账单编号"
                        onSearch={this.changeSearch}
                    />
                    
                    <div className="select-time" style={{float:'left',width:270,marginRight:12}}>
                        <span className="inner-col-span">创建日期</span>
                        <RangePicker disabledDate={disabledDate} onChange={this.handleDate.bind(this)} style={{width: 206}} 
                        defaultValue={[moment().subtract(1, 'years').add(1,'days'), moment()]} allowClear={false} />
                    </div>                    
                    <Select
                        mode="multiple"
                        style={{ width: '288px',float:'left',marginRight:'8px' }}
                        placeholder="费用类型"                        
                        onChange={this.chooseCostType}
                    >
                        {children}
                    </Select>
                    <span className="report-reset" style={{float:'left',cursor: 'pointer',lineHeight:'30px'}} onClick={this.reset}>重置</span>
                    
                </div> 
                    ):(null)
                }
                <Table columns={this.state.columns} current={this.state.pageNo} data={this.state.data} total={this.state.total} change={this.onChange} scroll={{ x: 2400 }}/>
            </div>
        </div>
        );
    }
}

export default IncomeExpenseReport;


function disabledDate(current) {
    // Can not select days before today and today
    return current > moment() || current < moment().subtract(5, 'years'); // February 28;
}