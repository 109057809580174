import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom';
// import './GoodsAuditDetail.less'
import { API } from '@/http/api';
import http from '@/http';
import { Breadcrumb, Button, Modal, Input, message, Table } from 'antd';
import IMGurl from '@/images/empty_schedule@2x.png'
import { showErrorMessage, } from '@/utils/globalMessageReminder';
import { formatLinkSearch } from '@/utils';
import moment from 'moment';
import ImgPreview from 'CMPT/common/ImgPreview';
const { TextArea } = Input;

const userInfoData = [
  // { label: "昵称", field: "aaaaaaa" },
  { label: "姓名", field: "userName" },
  { label: "手机号码", field: "mobile" },
  { label: "货主身份", field: "goodsOwnerIdentityName" },
]

const goodsInfoName = [
  { label: "发货单编号", field: "freightNo" },
  { label: "审核状态", field: "auditStatusName" },
  { label: "下单平台", field: "placeOrderPlatformName" },
  { label: "支付方式", field: "fuel" },
  { label: "价格", field: "freightPrice" }, 
  { label: "发票信息", field: "freightInvoiceName" },
  { label: "货物类型", field: "freightGoodsTypeName" },
  { label: "货物量", field: "freightGoodsWeight" },
  { label: "预估趟数", field: "expectTransNumber" },
  { label: "期望车型", field: "freightVehicleType" },
  { label: "期望车长", field: "freightVehicleLength" },
  { label: "期望车高", field: "vehicleHeightName" },
  { label: "期望车宽", field: "vehicleWidthName" },
  { label: "发布时间", field: "createDate" },
  { label: "服务要求", field: "freightServicesStr" },
  { label: "服务备注", field: "freightServicesRemark" },
  { label: "是否跟车", field: "carFollowingFlag" },
  { label: "发货方姓名", field: "loadingContactUser" },
  { label: "发货方联系电话", field: "loadingTelephone" },
  { label: "期望装货时间", field: "expectLoadingDateStart" },
  { label: "发货方地址", field: "loadingAddress" },
  { label: "收货方姓名", field: "unloadingContactUser" },
  { label: "收货方联系电话", field: "unloadingTelephone" },
  { label: "期望卸货时间", field: "expectUnloadingDateStart" },
  { label: "收货方地址", field: "unloadingAddress" },
  { label: "预估运输里程", field: "transportMileage" },
]


const columns = [
  {
    title: '货运身份',
    dataIndex: 'driverTypeName',
    key: 'driverTypeName',
  },
  {
    title: '名称',
    dataIndex: 'quotedName',
    key: 'quotedName',
  },
  {
    title: '联系方式',
    dataIndex: 'quotedMobile',
    key: 'quotedMobile',
  }, {
    title: '报价',
    dataIndex: 'quotedPrice',
    key: 'quotedPrice',
  }, {
    title: '报价时间',
    dataIndex: 'createDate',
    key: 'createDate',
    render: (text, record) => {
      return (<span>{text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : ''}</span>);
  }
  },
];
class GoodsAuditDetail extends Component {
  state = {
    code: null, // 当前页面的ID,从前一个页面传递过来的
    pageNo: null, // 当前企业在前一个列表中的页码
    currentageCount: null, // 前一个页面中 列表内当前页的数量
    backPageNo: 1, //返回列表页面的页码
    rejectInput: '', // 驳回原因
    data: [],
    goodsOwnerCard: null,
    showRejectConfirmFlag: false, // 是否展示 驳回 对话框    
    auditRecords: [], // 审核记录
    previewVisible: false,
    
    modalTitle: '',
    modalPlaceholder: '',
    modalNum: null,
  }
  componentWillMount() {
    this.cDetail();

    if (this.props.location.search) {
      // 获取参数
      const search = this.props.location.search
      const formatData = formatLinkSearch(search)

      // 解析
      let code1 = this.props.match.params.code;
      let pageNo1 = null;
      let currentageCount1 = null;

      if (formatData && formatData.pageNo) {
        pageNo1 = formatData.pageNo
      }
      if (formatData && formatData.currentPageCount) {
        currentageCount1 = formatData.currentPageCount
      }

      // 赋值
      this.setState({
        code: code1,
        pageNo: pageNo1,
        backPageNo: pageNo1, //如果什么都不做，返回列表的原始页码即可。否则，需要判断跳转哪一页，尤其删除一个页面中的唯一一条数据
        currentageCount: currentageCount1,
      });
    }
  }
  componentDidMount() { }

  cDetail = () => {
    console.log(this.props, 'this.props')
    const config = {
      api: API.sourcingGetAuthDetail,
      data: { code: this.props.match.params.code },
    };
    http(config).then(res => {
      console.log(res, 'res333  车辆详情')
      if (res && res.code == 200) {
        let { data } = res;
        let str = ''
        data.freightServices && data.freightServices.map(item => {
          return str += item + '、'
        })
        data.freightServicesStr = str.length > 0 && str.substr(0, str.length - 1)
        data.createDate = data.createDate ? moment(data.createDate).format('YYYY-MM-DD HH:mm:ss') : ''
        data.expectLoadingDateStart = data.expectLoadingDateStart ? moment(data.expectLoadingDateStart).format('YYYY-MM-DD HH:mm') : ''
        data.expectUnloadingDateStart = data.expectUnloadingDateStart ? moment(data.expectUnloadingDateStart).format('YYYY-MM-DD HH:mm'): ''
        data.carFollowingFlag = data.carFollowingFlag ? '跟车' : '不跟车'
        if (data.carFollowingFlag == '跟车') { // 跟车
          goodsInfoName.splice(14, 0, { label: "跟车人姓名", field: "carFollowingName" })
          goodsInfoName.splice(15, 0, { label: "跟车人电话", field: "carFollowingMobile" })
        }
        data.freightPrice = data.freightPrice ? data.freightPrice : '议价'
        data.transportMileage = data.transportMileage + 'km'
        data.freightGoodsWeight = data.freightGoodsWeight + (data.freightUnitName ? data.freightUnitName : '')
        this.setState({
          data,
          goodsOwnerCard: data.goodsOwnerCard
        });
      }
    })
  }
  onRejectInput = (e) => {
    this.setState({
      rejectInput: e.target.value
    })
  };
  // 去展示 审核通过 对话框
  showPassAuthDialog = () => {
    // console.log(this.state.data, 'this.state.data')
    let config = {
      api: API.sourcingFreightAuditDoAudit,
      data: {
        auditStatus: 10,
        code: this.props.match.params.code,
      },
    }
    http(config).then(res => {
      console.log(res, 'res1111111111111111111')
      if (res.code == 200) {
        message.success('操作成功')
        this.cDetail()
      } else {
        message.error(res.message)
      }
    })
  }

  gotoReject = () => {
    const { modalNum } = this.state
    console.log(modalNum, 'modalNum')
    let strReason = modalNum == 10 ? "请输入下架原因" : "请输入驳回原因"
    let regStrReason = modalNum == 10 ? "请输入有效的下架原因" : "请输入有效的驳回原因"
    // 不能为null
    if (!this.state.rejectInput) {
      showErrorMessage(strReason);
      return;
    }
    // 去掉两边可能的空格
    var rejectInput = this.state.rejectInput.replace(/(^\s*)|(\s*$)/g, "");
    if (rejectInput.length < 1) {
      showErrorMessage(regStrReason);
      return;
    }
    let data = modalNum == 10 ? {
      code: this.props.match.params.code,
      auditRemark: this.state.rejectInput,
    } : {
      code: this.props.match.params.code,
      auditStatus: 20,
      auditRemark: this.state.rejectInput,
    }
    let config = {
      api: modalNum == 10 ? API.sourcingFreightAuditForcedDown : API.sourcingFreightAuditDoAudit,
      data,
    }
    http(config).then(res => {
      console.log(res, 'res1111111111111111111')
      if (res.code == 200) {
        this.setState({ showRejectConfirmFlag: false });
        message.success('操作成功')
        this.cDetail()
      } else {
        this.setState({ showRejectConfirmFlag: false });
        message.error(res.message)
      }
    })

  }
  /* start: 审核驳回的相关逻辑  */
  hidenRejectAuthDialog = () => {
    this.setState({ showRejectConfirmFlag: false });
  };

  showRejectAuthDialog = () => { // 驳回
    this.setState({ 
      showRejectConfirmFlag: true,
      modalTitle: '驳回',
      modalPlaceholder: '请输入驳回理由 （100字以内）',
      modalNum: 0,
    });
  };
  showForcedDialog = () => { // 强制下架
    this.setState({ 
      showRejectConfirmFlag: true,
      modalTitle: '强制下架',
      modalPlaceholder: '请输入下架理由 （100字以内）',
      modalNum: 10,
    });
  };

  // 获取审批记录
  watchRecord = () => {
    let recordHtml = null
    const config = {
      api: API.sourcingFreightAuditAuditRecord,
      path: {
        code: this.props.match.params.code
      },
    }
    http(config).then(res => {
      if (res && res.code == 200) {
        let auditRecords = res.data || []
        const recordContent = auditRecords && auditRecords.length > 0 ? auditRecords.map(item => {
          return <div className="record_item">
            <div className="status">{item.auditStatusName}</div>
            {item.auditRemark ? <div className="info_item">
              <span>理由：</span>
              <span>{item.auditRemark}</span>
            </div> : ''}
            <div className="info_item">
              <span>审批人：</span>
              <span>{item.createUserName}</span>
            </div>
            <div className="info_item">
              <span>时间：</span>
              <span>{item.createDate ? moment(item.createDate).format('YYYY-MM-DD HH:mm') : '-'}</span>
            </div>
          </div>
        }) :
          <div className='log-no-data'><div className="log-no-data-text">暂无数据</div></div>;

        recordHtml = <div className="sp_records">{recordContent}</div>;
      }
      Modal.info({
        title: '审批记录',
        icon: null,
        content: recordHtml
      });
    })
  }

  // 设置图片
  closeImagePreview = () => {
    this.setState({ previewVisible: false });
  }
  // 设置图片缩放
  cImage = (value, currentIndex = 0) => {
    this.setState({
      previewVisible: true,
      // IMGURL: value,
      imgList: [value],
      currentIndex: currentIndex
    });
  };

  render() {
    const { data, imgList, currentIndex, previewVisible, goodsOwnerCard, modalTitle, modalPlaceholder } = this.state
    return (
      <div className="userInfo">
        <div>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={{ pathname: "/app/crm/sourcing", search: '?tab=3' }}>
                信息审核
              </Link>
            </Breadcrumb.Item >
            <Breadcrumb.Item>
              发货信息
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div className="userInfo_body">
          <div>
            <h2><i></i>  货主信息</h2>
            <div>
              <ul>
                {goodsOwnerCard && userInfoData.map(item => {
                  return (
                    <li key={item.field}><span>{item.label}</span><span className="span">{goodsOwnerCard[item.field] ? goodsOwnerCard[item.field] : '-'}</span></li>
                  )
                })}
              </ul>
            </div>
          </div>

          <div>
            <h2><i></i>  发货单信息</h2>
            <ul>
              {data && goodsInfoName.map(item => {
                return (
                  <li key={item.field}><span>{item.label}</span><span className="span">{data[item.field] ? data[item.field] : '-'}</span></li>
                )
              })}
              <li className="body_img"><span style={{width: '75px'}}>相关图片</span>
                {data.goodsPhotos && data.goodsPhotos.length > 0 ? data.goodsPhotos.map((item, index) => <img key={index} src={item.url} alt="" onClick={() => this.cImage(item.url)} />) : '-'}
              </li>
            </ul>
            <ImgPreview show={previewVisible} current={currentIndex} imgList={imgList} onClose={this.closeImagePreview}/>
          </div>
          {data.freightQuotedPrices && data.freightQuotedPrices.length > 0 ? (<div className="table">
            <h2><i></i>  报价信息</h2>
            <Table dataSource={data.freightQuotedPrices} columns={columns} bordered pagination={false} />
          </div>) : null}
          <div className="approval-record" onClick={this.watchRecord}>审批记录</div>
        </div>
        {data.auditStatus == 0 ?
          (<div className="detail_btn">
            <Button type="primary" onClick={this.showPassAuthDialog.bind(this)}> 通过 </Button>
            <Button onClick={this.showRejectAuthDialog.bind(this)}> 驳回  </Button>
          </div>) : null
        }
        {data.auditStatus == 10 ?
          (<div className="detail_btn">
            <Button type="primary" onClick={this.showForcedDialog.bind(this)}> 强制下架 </Button>
          </div>) : null
        }
        <Modal
          title={modalTitle}
          visible={this.state.showRejectConfirmFlag}
          okText='确定'
          cancelText='取消'
          closable={false}
          maskClosable={false}
          onOk={this.gotoReject}
          onCancel={this.hidenRejectAuthDialog}
          width={400} >
          <TextArea rows={4} onChange={this.onRejectInput} placeholder={modalPlaceholder} maxLength={100} />
        </Modal>
      </div>
    )
  }
}
export default GoodsAuditDetail;
