import React, { Component } from 'react';
import DateRange from 'CMPT/common/DateRange';
import BOSelect from 'CMPT/common/Select';
import { deepclone } from '@/utils'
import moment from 'moment';

import { Radio, Select, Table, Row, DatePicker, Input, InputNumber, Icon } from 'antd';
class InexTable extends Component {
    state = {
        params: {
            bpid: 1, // 1收 2付
        },
        rentColumns: [
            {
                title: '收付',
                dataIndex: 'bp',
                align: 'center',
                width: '8%',
                render: (value, row, index) => {
                    let disabled = false
                    if (/^\d*$/.test(row.parseNum)) {
                        disabled = true
                    }
                    return <Radio.Group value={value} buttonStyle="solid" onChange={this.changeBpid.bind(this, index)} disabled={disabled}>
                                <Radio.Button value={1}>收</Radio.Button>
                                <Radio.Button value={2}>付</Radio.Button>
                            </Radio.Group>
                }
            }, {
                title: '费用类型',
                dataIndex: 'feiType',
                align: 'center',
                width: '10%',
                render: (value, row, index) => {
                    const rentList = this.getSelectList(this.props.init.feeType)
                    const marginList = this.getSelectList(this.props.init.type)
                    if (row.feiType === 1) {
                        return <BOSelect data={rentList} style={{width: '100%'}} value={row.feeType} selectMethod={this.changeFeeType.bind(this, index)} />
                    } else {
                        return <BOSelect data={marginList} disabled={true} style={{width: '100%'}} value={row.feeType} selectMethod={this.changeFeeType.bind(this, index)} />
                    }
                }
            }, {
                title: '应收付日期',
                dataIndex: 'bpDate',
                align: 'center',
                width: '15%',
                render: (value, row, index) => {
                    if (row.rentType === 3 || row.rentType === 4) return <span>-</span>
                    return <DatePicker value={value} allowClear={false} onChange={this.changeBpDate.bind(this, index)} />
                }
            }, {
                title: '费用周期',
                dataIndex: 'rentPeriod',
                align: 'center',
                width: '33%',
                render: (value, row, index) => {
                    let startDisabled = false
                    let endDisabled = false
                    let useEndDisabledDate = false
                    if (row.rentType === 3) {
                        startDisabled = true
                    }
                    if (row.feiType === 2) {
                        startDisabled = true
                        endDisabled = true
                    }
                    if (row.rentType === 3 || row.rentType === 4 || /^\d*$/.test(row.parseNum)) {
                        endDisabled = true
                        useEndDisabledDate = true
                    }
                    if (row.parseNum == '首期款') {
                        startDisabled = true
                        endDisabled = true
                    }
                    return <DateRange startValue={value[0]} endValue={value[1]} preventClear startDisabledDate={row.disabledDate[0]}
                                      startDisabled={startDisabled} endDisabled={endDisabled} endDisabledDate={row.disabledDate[1]}
                                      onDateChange={this.periodChange.bind(this, row.parseNum, row.rentType, index)} useEndDisabledDate={useEndDisabledDate}
                           />
                }
            }, {
                title: '收款金额(元)',
                dataIndex: 'money',
                align: 'center',
                width: '10%',
                render: (value, row, index) => {
                    if (row.rentType === 3 || row.rentType === 4 || value == 0) return <span>-</span>
                    return <InputNumber formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        value={value} onChange={this.changeMoney.bind(this, index)} max={99999999.99} min={0.01}
                           />
                }
            }, {
                title: '期数',
                dataIndex: 'parseNum',
                align: 'center',
                width: '6%',
                render: (value) => {
                    let text = value
                    if (/^\d*$/.test(text)) {
                        text = `第${text}期`
                    }
                    return text
                }
            }, {
                title: '备注',
                dataIndex: 'note',
                align: 'center',
                width: '12%',
                render: (value, row, index) => {
                    return <Input value={value} placeholder="非必填" maxLength={200} onChange={this.setNote.bind(this, index)} />
                }
            }, {
                title: '操作',
                dataIndex: 'operate',
                align: 'center',
                width: '6%',
                render: (value, row, index) => {
                    if (row.parseNum === 1) return <span>-</span>
                    return <span className="table-operate" onClick={this.deleteItem.bind(this, row.parseNum, row.rentType, index)}>删除</span>
                }
            }
        ],
        rentData: [], // rentType 资金类型 1 租金；2 保证金； 3 免租期前， 4 免租期后;  feiType 费用类型，取自字典 1 租金类型；2 保证金类型
        contractStartDate: '' //合同的开始时间
    }
    componentWillReceiveProps(props) {
        const tableData = props.tableData
        tableData.forEach(per => {
            per.bpDate = moment(per.bpDate)
            per.rentPeriod[0] = moment(per.rentPeriod[0])
            per.rentPeriod[1] = moment(per.rentPeriod[1])
            if (per.disabledDate && per.disabledDate.length) {
                let start = moment(per.disabledDate[0])
                let end = moment(per.disabledDate[1])
                per.disabledDate[0] = start
                per.disabledDate[1] = end
            }
        })
        let contractStartDate = ''
        let targetItem = tableData.find(per => {
            return per.rentType === 3
        })
        if (targetItem && targetItem.rentPeriod && targetItem.rentPeriod.length > 0) { // 有前免租期，合同开始时间为前免租期的开始时间，否则为期数为1的item的开始时间
            contractStartDate = targetItem.rentPeriod[0]
        } else {
            targetItem = tableData.find(per => {
                return per.parseNum === 1
            })
            if (targetItem && targetItem.rentPeriod && targetItem.rentPeriod.length > 0) { // 有前免租期，合同开始时间为前免租期的开始时间，否则为期数为1的item的开始时间
                contractStartDate = targetItem.rentPeriod[0]
            }
        }
        this.setState({
            rentData: tableData,
            contractStartDate
        })
    }
    changeBpDate = (index, value) => {
        let rentData = this.state.rentData
        rentData[index].bpDate = value
        this.setState({
            rentData
        })
    }
    changeFeeType = (index, value) => {
        let rentData = this.state.rentData
        rentData[index].feeType = Number(value)
        this.setState({
            rentData
        })
    }
    changeMoney = (index, value) => {
        let rentData = this.state.rentData
        rentData[index].money = value
        this.setState({
            rentData
        })
    }
    setNote = (index, value) => {
        let rentData = this.state.rentData
        rentData[index].note = value.target.value
        this.setState({
            rentData
        })
    }
    getSelectList = (list) => {
        let target = []
        if (list) {
            list.forEach(per => {
                target.push({value: per.key, name: per.value})
            })
        }
        return target
    }
    changeBpid = (index, e) => {
        let rentData = this.state.rentData
        rentData[index].bp = e.target.value
        this.setState({
            rentData
        })
    }
    deleteItem = (parseNum, rentType, index) => {
        let rentData = this.state.rentData
        const current = rentData[index]
        let target = undefined
        let nextTarget = undefined // 后一期
        let maxParseNum = 1 // 最大期数
        rentData.forEach(each => {
            if (/^\d*$/.test(each.parseNum)) {
                maxParseNum = each.parseNum > maxParseNum ? each.parseNum : maxParseNum
            }
        })
        // 期数删除时，时间合并到上一期，不可选时间:->当前期的不可选结束时间合并到上一期，上一期的开始日期变为下一期的开始不可选日期
        if (/^\d*$/.test(parseNum)) {
            target = rentData.find(per => {
                return per.parseNum === parseNum - 1
            })
            if (parseNum === maxParseNum) {
                nextTarget = rentData.find(per => {
                    return per.rentType === 4
                })
            } else {
                nextTarget = rentData.find(per => {
                    return per.parseNum === parseNum + 1
                })
            }
            target.rentPeriod[1] = current.rentPeriod[1]
            target.disabledDate[1] = current.disabledDate[1]
            if (nextTarget) { // 有后免租期，去设置
                nextTarget.disabledDate[0] = current.disabledDate[0]
            }
            // 后面的期数要重排
            let targetList = rentData.filter(item => {
                return item.parseNum > parseNum
            })
            targetList.map(i => {
                let ii = i
                ii.parseNum -= 1
                return ii
            })
        } else if (rentType === 3) { // 前免租期,开始时间合并，不可选时间取当前,如果有第二期，第二期的开始不可选变为上一期的开始时间
            target = rentData.find(per => {
                return per.parseNum === 1
            })
            target.rentPeriod[0] = current.rentPeriod[0]
            let startDisabled = target.disabledDate[0].calendar()
            target.disabledDate[0] = moment(startDisabled).subtract(1, 'days') // 把前免租期不可选的一天加上
            if (maxParseNum > 1) {
                nextTarget = rentData.find(per => { // 有第二期时，第二期不可选时间也要限制
                    return per.parseNum === 2
                })
                nextTarget.disabledDate[0] = moment(startDisabled)
            }
        } else if (rentType === 4) { // 后免租期，时间和不可选时间都合并
            target = rentData.find(each => {
                return each.parseNum === maxParseNum
            })
            target.rentPeriod[1] = current.rentPeriod[1]
            target.disabledDate[1] = current.disabledDate[1]
        }
        rentData.splice(index, 1)
        this.setState(rentData)
    }
    periodChange = (parseNum, rentType, index, field, value) => {
        console.log(parseNum, rentType, index, field, value, '======')
        let rentData = this.state.rentData; // 租赁数据
        let periodItem = rentData[index]; // 当前修改的那一项
        if (parseNum === '保证金') {  // 如果修改的期数为 保证金 的
            if (field === 'startValue') {
                periodItem.rentPeriod[0] = value
            } else {
                periodItem.rentPeriod[1] = value
            }
            this.setState({rentData})
            return
        }
        periodItem && (periodItem.rentPeriod[0] = value)  // 修改的那一项赋值
        if (/^\d*$/.test(parseNum) && rentData[index-1].parseNum == '首期款') {
            rentData[index-1].rentPeriod[1] = moment(value.format('YYYY-MM-DD')).subtract(1, 'days');
            rentData[index-1].money = this.calculateCurrentPrice(rentData[index-1].rentPeriod[0], rentData[index-1].rentPeriod[1])
        }
        let periodTarget = rentData.find(item => {
            if (/^\d*$/.test(parseNum)) {
                return parseNum === 1 ? item.rentType === 3 : item.parseNum === parseNum - 1
            }
        })
        // 修改的是后免租期
        if (rentType === 4) {
            const currentIndex = rentData.findIndex(per => {
                return per.parseNum === parseNum && per.rentType === rentType
            })
            periodTarget = rentData[currentIndex - 1]
        }
        if (periodTarget) {
            const targetValue = value.format('YYYY-MM-DD')
            periodTarget.rentPeriod[1] = moment(targetValue).subtract(1, 'days')
            periodTarget.money = this.calculateCurrentPrice(periodTarget.rentPeriod[0], periodTarget.rentPeriod[1])
        }
        if (rentType === 1) {
            periodItem.money = this.calculateCurrentPrice(periodItem.rentPeriod[0], periodItem.rentPeriod[1])
        }
        let newRentData = this.addDisabledData(rentData)
        this.setState({
            rentData: newRentData
        })
    }
    addDisabledData = (list) => {
        let newList = list.map((per, index, all) => {
            let item = per
            let targetItem = undefined
            let maxParseNum = 1
            item.disabledDate = [undefined, item.rentPeriod[1]]
            const parseNum = per.parseNum
            if (per.rentType === 4) { // 后免租期，找期数最大的那一期
                all.forEach(each => {
                    if (/^\d*$/.test(each.parseNum)) {
                        maxParseNum = each.parseNum > maxParseNum ? each.parseNum : maxParseNum
                    }
                })
                targetItem = all.find(each => {
                    return each.parseNum === maxParseNum
                })
                let dateCalendar = targetItem.rentPeriod[0]
                item.disabledDate[0] = moment(dateCalendar).add(1, 'days')
            } else if (/^\d*$/.test(parseNum)) { // 第几期，当前是第一期就找前免租期，没免租期就自己，或者第n-1期
                targetItem = all.find(each => {
                    if (/^\d*$/.test(parseNum)) {
                        return parseNum === 1 ? each.rentType === 3 : each.parseNum === parseNum - 1
                    }
                })
                if (targetItem) {
                    let dateCalendar = targetItem.rentPeriod[0]
                    item.disabledDate[0] = moment(dateCalendar).add(1, 'days')
                } else { // 没有前免租期,第一期取合同的开始时间
                    item.disabledDate[0] = this.state.contractStartDate
                }
            } else {
                item.disabledDate = []
            }
            return item
        })
        return newList
    }
    calculateCurrentPrice = (start, end) => {
        const { contractPriceList, decision, type } = this.props
        let payMount = 0
        let monthsDays = {}
        const startItem = contractPriceList.find(per => {
            return per.from <= start.format('YYYY-MM-DD') && start.format('YYYY-MM-DD') <= per.to
        })
        const endItem = contractPriceList.find(per => {
            return per.from <= end.format('YYYY-MM-DD') && end.format('YYYY-MM-DD') <= per.to
        })
        if (startItem === endItem) { // 说明在一个合同年内
            if (type === 'contract' || decision.estateCalculateMethod === '2') { // 租客按天计费，物业根据决策 2 按天计费
                let price = startItem.price
                if (type === 'estate') { // 物业费要除以30
                    price = price / 30
                }
                payMount = price * (end.diff(start, 'days') + 1)
            } else {
                monthsDays = this.getMonthsAndDays(start, end)
                payMount = startItem.price * monthsDays.months + (startItem.price / 30) * monthsDays.days
            }
        } else { // 跨年
            if (type === 'contract' || decision.estateCalculateMethod === '2') {
                let startPrice = startItem.price
                let endPrice = endItem.price
                if (type === 'estate') {
                    startPrice = startPrice / 30
                    endPrice = endPrice / 30
                }
                payMount = startPrice * (moment(startItem.to).diff(start, 'days') + 1) + endPrice * (end.diff(moment(endItem.from), 'days') + 1)
            } else {
                let monthsDaysBefore = this.getMonthsAndDays(start, moment(startItem.to))
                let monthsDaysAfter = this.getMonthsAndDays(moment(endItem.from), end)
                const payBefore = startItem.price * monthsDaysBefore.months + (startItem.price / 30) * monthsDaysBefore.days
                const payAfter = endItem.price * monthsDaysAfter.months + (endItem.price / 30) * monthsDaysAfter.days
                payMount = payBefore + payAfter
            }
        }
        return Number(payMount.toFixed(2))
    } 
    // 计算周期内整月数和天数
    getMonthsAndDays = (start, end) => {
        const startYear = start.get('year')
        const startMonth = start.get('month')
        const startDay = start.get('date')
        const startMonthDays = start.daysInMonth()
        const endYear = end.get('year')
        const endMonth = end.get('month')
        const endMonthDays = end.daysInMonth()
        const endDay = end.get('date')
        let fullMonths = 0
        if (endMonth === startMonth && startYear === endYear) { // 同年同月
            return {months: 0, days: endDay - startDay + 1}
        }
        if (endMonth <= startMonth) {
            fullMonths = 12 - startMonth + endMonth - 1
        } else {
            fullMonths = endMonth - startMonth - 1
        }
        let fullDays = 0
        if (startDay === 1) {
            fullMonths += 1
        } else {
            fullDays += startMonthDays - startDay + 1
        }
        if (endDay === endMonthDays) {
            fullMonths += 1
        } else {
            fullDays += endDay
        }
        return {months: fullMonths, days: fullDays}
    }
    render() {
        return (
                <Table columns={this.state.rentColumns}
                        showHeader={this.props.showHeader}
                        dataSource={this.state.rentData}
                        pagination={false}
                />
        );
    }
}

export default InexTable;