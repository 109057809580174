//浮层状态bizStauts、标签状态dataStatus，包含以下的rentStatus
const BIZ_DATA_STATUS = Object.freeze({
    All:
        [
            { value: 0, name: '不可招商' },
            { value: 1, name: '未出租' },
            { value: 2, name: '已出租' },
            { value: 3, name: '装修中' },
            { value: 4, name: '欠费' },
            { value: 5, name: '空置' },
            { value: 6, name: '到期' }
        ]
});

//标签tag状态
const TAG_DATA_STATUS = Object.freeze({
    ForbidRent: { value: 0, name: '不可招商' },
    NotRent: { value: 1, name: '未出租' },
    Rented: { value: 2, name: '已可租' },
    Decorating: { value: 3, name: '装修中' },
    Debt: { value: 4, name: '欠费' },
    UnUsed: { value: 5, name: '空置' },
    Expired: { value: 6, name: '到期' }
});

//装修状态
const DECORATE_STATUS = Object.freeze({
    ForbidRent: { value: 0, name: '不可招商' },
    NotRent: { value: 1, name: '未出租' },
    Rented: { value: 2, name: '已可租' },
    Decorating: { value: 3, name: '装修中' },
    Debt: { value: 4, name: '欠费' },
    UnUsed: { value: 5, name: '空置' },
    Expired: { value: 6, name: '到期' }
});

//出租状态rentStatus
const RENT_STATUS = Object.freeze({
    ForbidRent: { value: 0, name: '不可招商' },
    NotRent: { value: 1, name: '未出租' },
    Rented: { value: 2, name: '已可租' },
    All: [        
        { value: 1, name: '未出租' },
        { value: 2, name: '已可租' }
    ]
});

//招商状态
const MERCHANT_STATUS = Object.freeze({
    All: [
        { value: 0, name: '不可招商' },
        { value: 1, name: '可招商' }
    ]
});

//管理装修选项
const DECORATE_CHIOCE = Object.freeze({
    All: [
        { value: 0, name: '待装修' },
        { value: 1, name: '装修中' },
        { value: 2, name: '装修完成' },
        { value: 3, name: '验收通过' }
    ]
});

export {
    BIZ_DATA_STATUS,
    TAG_DATA_STATUS,
    DECORATE_STATUS,
    RENT_STATUS,
    MERCHANT_STATUS,
    DECORATE_CHIOCE
}