import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom';
import './CapacityAuditDetail.less'
import { API } from '@/http/api';
import http from '@/http';
import { Breadcrumb, Button, Modal, Input, message } from 'antd';
import IMGurl from '@/images/empty_schedule@2x.png'
import { showErrorMessage, } from '@/utils/globalMessageReminder';
import { formatLinkSearch } from '@/utils';
import moment from 'moment';
import ImgPreview from 'CMPT/common/ImgPreview';
const { TextArea } = Input;

const userInfoData = [
  { label: "提交人", field: "applyName" },
  { label: "联系电话", field: "applyMobile" },
  { label: "提交身份", field: "driverType" },
  { label: "公司名称", field: "companyName" },
]

const capacityInfoName = [
  { label: "服务区域", field: "serviceAreas" },
  { label: "常运货物", field: "freightGoodsTypes" },
  { label: "服务特色", field: "tcServices" },
  { label: "服务描述", field: "tcServicesRemark" },
  { label: "联系人", field: "contactUser" },
  { label: "联系方式", field: "telephone" },
  { label: "发票信息", field: "invoiceFlag" },
  { label: "可开发票", field: "freightInvoices" },
  { label: "车队规模", field: "ttransScaleVOList" },
]


class CapacityAuditDetail extends Component {
  state = {
    code: null, // 当前页面的ID,从前一个页面传递过来的
    pageNo: null, // 当前企业在前一个列表中的页码
    currentageCount: null, // 前一个页面中 列表内当前页的数量
    backPageNo: 1, //返回列表页面的页码
    rejectInput: '', // 驳回原因
    data: [],
    showRejectConfirmFlag: false, // 是否展示 驳回 对话框
    auditRecords: [], // 审核记录
    previewVisible: false,
    IMGURL: '', // 缩放图
    modalTitle: '',
    modalPlaceholder: '',
    modalNum: null,

  }
  componentWillMount() {
    this.cDetail();

    if (this.props.location.search) {
      // 获取参数
      const search = this.props.location.search
      const formatData = formatLinkSearch(search)

      // 解析
      let code1 = this.props.match.params.code;
      let pageNo1 = null;
      let currentageCount1 = null;

      if (formatData && formatData.pageNo) {
        pageNo1 = formatData.pageNo
      }
      if (formatData && formatData.currentPageCount) {
        currentageCount1 = formatData.currentPageCount
      }

      // 赋值
      this.setState({
        code: code1,
        pageNo: pageNo1,
        backPageNo: pageNo1, //如果什么都不做，返回列表的原始页码即可。否则，需要判断跳转哪一页，尤其删除一个页面中的唯一一条数据
        currentageCount: currentageCount1,
      });
    }
  }
  componentDidMount() { }

  cDetail = () => {
    console.log(this.props, 'this.props')
    const config = {
      api: API.sourcingTcAuditGetAuthDetail,
      data: { code: this.props.match.params.code },
    };
    http(config).then(res => {
      if (res && res.code == 200) {
        let { data } = res;
        let serviceAreasStr = ''
        let freightGoodsTypesStr = ''
        let tcServicesStr = ''
        let ttransScaleVOListStr = ''
        let freightInvoicesStr = ''
        
        data.serviceAreas && data.serviceAreas.map(item => {
          return serviceAreasStr += item + '、'
        })
        data.serviceAreas = serviceAreasStr.length > 0 && serviceAreasStr.substr(0, serviceAreasStr.length - 1)

        data.freightGoodsTypes && data.freightGoodsTypes.map(item => {
          return freightGoodsTypesStr += item + '、'
        })
        data.freightGoodsTypes = freightGoodsTypesStr.length > 0 && freightGoodsTypesStr.substr(0, freightGoodsTypesStr.length - 1)

        data.tcServices && data.tcServices.map(item => {
          return tcServicesStr += item + '、'
        })
        data.tcServices = tcServicesStr.length > 0 && tcServicesStr.substr(0, tcServicesStr.length - 1)
        data.driverType == 1 ? data.driverType = '企业' : data.driverType = '个人'

        data.invoiceFlag == 1 ? data.invoiceFlag = '可开发票' : data.invoiceFlag = '无需发票'
        data.freightInvoices && data.freightInvoices.map(item => {
          return freightInvoicesStr += item + '、'
        })
        data.freightInvoices = freightInvoicesStr.length > 0 && freightInvoicesStr.substr(0, freightInvoicesStr.length - 1)

        data.ttransScaleVOList && data.ttransScaleVOList.map(item => {
          return ttransScaleVOListStr += item.dictName + item.scaleCount + '辆、'
        })
        ttransScaleVOListStr = '驾驶员' + data.driverScale + '人、' + ttransScaleVOListStr
        data.ttransScaleVOList = ttransScaleVOListStr.length > 0 && ttransScaleVOListStr.substr(0, ttransScaleVOListStr.length - 1)

        this.setState({
          data
        });
      }
    })
  }
  onRejectInput = (e) => {
    this.setState({
      rejectInput: e.target.value
    })
  };
  // 去展示 审核通过 对话框
  showPassAuthDialog = () => {
    console.log(this.state.data, 'this.state.data')
    let config = {
      api: API.sourcingTcAuditDoAudit,
      data: {
        auditStatus: 10,
        code: this.props.match.params.code,
      },
    }
    http(config).then(res => {
      console.log(res, 'res1111111111111111111')
      if (res.code == 200) {
        message.success('操作成功')
        this.cDetail()
      } else {
        // 待审核/已驳回 弹窗提示
        if(['待审核', '已驳回'].includes(res.message)) {
          Modal.error({
            icon: 'none',
            title: '提示',
            content: <div>当前信息关联的企业认证状态为<span className="red">{res.message}</span>状态，不能操作审核通过</div>,
          });
        }
        else {
          message.error(res.message)
        }
        
        // `当前信息关联的企业认证状态为${res.message}状态，请先审核企业认证信息`
      }
    })
  }

  gotoReject = () => {
    const { modalNum } = this.state
    let strReason = modalNum == 10 ? "请输入下架原因" : "请输入驳回原因"
    let regStrReason = modalNum == 10 ? "请输入有效的下架原因" : "请输入有效的驳回原因"
    // 不能为null
    if (!this.state.rejectInput) {
      showErrorMessage(strReason);
      return;
    }
    // 去掉两边可能的空格
    var rejectInput = this.state.rejectInput.replace(/(^\s*)|(\s*$)/g, "");
    if (rejectInput.length < 1) {
      showErrorMessage(regStrReason);
      return;
    }
    let data = modalNum == 10 ? {
      code: this.props.match.params.code,
      auditRemark: this.state.rejectInput,
    } : {
      code: this.props.match.params.code,
      auditStatus: 20,
      auditRemark: this.state.rejectInput,
    }
    let config = {
      api: modalNum == 10 ? API.sourcingTcAuditForcedDown : API.sourcingTcAuditDoAudit,
      data,
    }
    http(config).then(res => {
      console.log(res, 'res 驳回、下架')
      if (res.code == 200) {
        this.setState({ showRejectConfirmFlag: false });
        message.success('操作成功')
        this.cDetail()
      } else {
        this.setState({ showRejectConfirmFlag: false });
        message.error(res.message)
      }
    })

  }
  /* start: 审核驳回的相关逻辑  */
  hidenRejectAuthDialog = () => {
    this.setState({ showRejectConfirmFlag: false });
  };

  showRejectAuthDialog = () => { // 驳回
    this.setState({ 
      showRejectConfirmFlag: true,
      modalTitle: '驳回',
      modalPlaceholder: '请输入驳回理由 （100字以内）',
      modalNum: 0,
    });
  };

  showForcedDialog = () => { // 强制下架
    this.setState({ 
      showRejectConfirmFlag: true,
      modalTitle: '强制下架',
      modalPlaceholder: '请输入下架理由 （100字以内）',
      modalNum: 10,
    });
  };

  // 获取审批记录
  watchRecord = () => {
    let recordHtml = null
    const config = {
      api: API.sourcingTcAuditAuditRecord,
      path: {
          code: this.props.match.params.code
      },
    }
    http(config).then(res => {
        if (res && res.code == 200) {
            let auditRecords = res.data || []
            const recordContent = auditRecords && auditRecords.length > 0 ? auditRecords.map(item => {
              return <div className="record_item">
                <div className="status">{item.auditStatusName}</div>
                {item.auditRemark ? <div className="info_item">
                  <span>理由：</span>
                  <span>{item.auditRemark}</span>
                </div> : ''}
                <div className="info_item">
                  <span>审批人：</span>
                  <span>{item.createUserName}</span>
                </div>
                <div className="info_item">
                  <span>时间：</span>
                  <span>{item.createDate ? moment(item.createDate).format('YYYY-MM-DD HH:mm') : '-'}</span>
                </div>
              </div>
            }) :
              <div className='log-no-data'><div className="log-no-data-text">暂无数据</div></div>;
        
            recordHtml = <div className="sp_records">{recordContent}</div>;
        
          }
          Modal.info({
            title: '审批记录',
            icon: null,
            content: recordHtml
          });
        })

  }

  // 设置图片
  closeImagePreview = () => {
    this.setState({ previewVisible: false });
  }
  // 设置图片缩放
  cImage = (value, currentIndex = 0) => {
    this.setState({
      previewVisible: true,
      // IMGURL: value,
      imgList: [value],
      currentIndex: currentIndex
    });
  };

  render() {
    const { data, imgList, currentIndex, previewVisible, modalTitle, modalPlaceholder } = this.state
    return (
      <div className="userInfo">
        <div>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={{ pathname: "/app/crm/sourcing", search: '?tab=2' }}>
                信息审核
              </Link>
            </Breadcrumb.Item >
            <Breadcrumb.Item>
              运力信息
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div className="userInfo_body">
          <div>
            <h2><i></i>  提交信息</h2>
            <div>
              <ul>
                {userInfoData && userInfoData.map(item => {
                  return (
                    <li key={item.field}><span>{item.label}</span><span className="span">{data[item.field] ? data[item.field] : '-'}</span></li>
                  )
                })}
              </ul>
            </div>
          </div>

          <div>
            <h2><i></i>  运力信息</h2>
            <ul>
              {capacityInfoName && capacityInfoName.map(item => {
                return (
                  <li key={item.field}><span>{item.label}</span><span className="span">{data[item.field] ? data[item.field] : '-'}</span></li>
                )
              })}
              <li className="body_img"><span style={{width: '75px'}}>相关图片</span>
              {data.photos && data.photos.length > 0 ? data.photos.map((item, index) => <img key={index} src={item.url} alt="" onClick={() => this.cImage(item.url)} />) : '-'}
              {/* <Modal visible={previewVisible} title=' ' footer={null} onCancel={this.closeImagePreview}>
                  <img alt="图片预览" style={{ width: '100%', margin: '0 0 24px' }} src={IMGURL} />
                </Modal> */}
              </li>
            </ul>
                <ImgPreview show={previewVisible} current={currentIndex} imgList={imgList} onClose={this.closeImagePreview}/>
          </div>
          <div className="approval-record" onClick={this.watchRecord}>审批记录</div>
        </div>
        {data.auditStatus == 0 ?
          (<div className="detail_btn">
            <Button type="primary" onClick={this.showPassAuthDialog.bind(this)}> 通过 </Button>
            <Button onClick={this.showRejectAuthDialog.bind(this)}> 驳回  </Button>
          </div>) : null
        }
        {data.auditStatus == 10 ?
          (<div className="detail_btn">
            <Button type="primary" onClick={this.showForcedDialog.bind(this)}> 强制下架 </Button>
          </div>) : null
        }
        <Modal
          title={modalTitle}
          visible={this.state.showRejectConfirmFlag}
          okText='确定'
          cancelText='取消'
          closable={false}
          maskClosable={false}
          onOk={this.gotoReject}
          onCancel={this.hidenRejectAuthDialog}
          width={400} >
          <TextArea rows={4} onChange={this.onRejectInput} placeholder={modalPlaceholder} maxLength={100} />
        </Modal>
      </div>
    )
  }
}
export default CapacityAuditDetail;
