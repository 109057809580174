import { API } from "@/http/api";
import http from "@/http";
import { showErrorMessage } from "@/utils/globalMessageReminder";
import { formatLinkSearch } from "@/utils";
import React, { Component } from "react";
import { Layout, Breadcrumb, Button, Tabs, Modal, Input, message } from "antd";
import { Link } from "react-router-dom";
import ImgPreview from 'CMPT/common/ImgPreview';

import "./EnterpriseAuthDetail.less";

import userUtils from "@/utils/user";

import UserInfo from "./enterpriseAuthDetail/userInfo";
import OrganizationInfo from "./enterpriseAuthDetail/organizationInfo";

const { checkAuthByKey } = userUtils;

const { TextArea } = Input;
const { Content } = Layout;

class EnterpriseDetail extends Component {
  state = {
    data: {},
    code: null, // 当前页面的ID,从前一个页面传递过来的
    pageNo: null, // 当前企业在前一个列表中的页码
    currentageCount: null, // 前一个页面中 列表内当前页的数量
    backPageNo: 1, //返回列表页面的页码

    showPassConfirmFlag: false, // 是否展示通过 对话框
    showRejectConfirmFlag: false, // 是否展示 驳回 对话框
    rejectInput: "", //用户输入的驳回理由
    errorTitle: null, // 审核时失败信息 对话框的title
    errorMsg: null, // 审核时失败信息
    showErrorFlag: false, // 是否展示 审核失败信息

    state: 1, // 详情页审核状态
    previewVisible: false,
    IMGURL: "", // 缩放图
  };

  componentWillMount() {
    this.cDetail();
    if (this.props.location.search) {
      // 获取参数
      const search = this.props.location.search;
      const formatData = formatLinkSearch(search);

      // 解析
      let code1 = this.props.match.params.code;
      let pageNo1 = null;
      let currentageCount1 = null;

      if (formatData && formatData.pageNo) {
        pageNo1 = formatData.pageNo;
      }
      if (formatData && formatData.currentPageCount) {
        currentageCount1 = formatData.currentPageCount;
      }

      // 赋值
      this.setState({
        code: code1,
        pageNo: pageNo1,
        backPageNo: pageNo1, //如果什么都不做，返回列表的原始页码即可。否则，需要判断跳转哪一页，尤其删除一个页面中的唯一一条数据
        currentageCount: currentageCount1,
      });
    }
  }

  componentDidMount() {}

  cDetail = () => {
    if (
      this.props.location.search &&
      this.props.location.search.charAt(
        this.props.location.search.length - 1
      ) == 1
    ) {
      const config = {
        api: API.enterpriseAuthGetSupplierAuthDetail,
        data: { id: this.props.match.params.code },
      };
      http(config).then((res) => {
        // console.log(res, 'res111111   企业详情')
        if (res.code == 200) {
          let data = res.data;
          data.auditStatus = data.auditFormDetail.mainInfo
            ? data.auditFormDetail.mainInfo.auditStatus
            : data.auditFormDetail.driverInfoVO.auditStatus;
          this.setState({
            data,
          });
        }
      });
    } else if (
      this.props.location.search &&
      this.props.location.search.charAt(
        this.props.location.search.length - 1
      ) == 2
    ) {
      const config = {
        api: API.enterpriseAuthDriverAuditInfo,
        data: { auditId: this.props.match.params.code },
      };
      http(config).then((res) => {
        // console.log(res, 'res222 司机详情')
        if (res.code == 200) {
          let data = res.data;
          data.auditStatus = data.audit.auditStatus;
          data.id = data.audit.id;
          data.driverId = data.driverInfo.id;
          data.userId = data.driverInfo.userId;
          data.code = 200;
          this.setState({
            data,
          });
        }
      });
    } else if (
      this.props.location.search &&
      this.props.location.search.charAt(
        this.props.location.search.length - 1
      ) == 3
    ) {
      const config = {
        api: API.enterpriseAuthVehicleGetAuthDetail,
        data: { code: this.props.match.params.code },
      };
      http(config).then((res) => {
        // console.log(res, 'res333  车辆详情')
        if (res.code == 200) {
          let data = res;
          data.auditStatus = data.data.auditStatus;
          this.setState({
            data,
          });
        }
      });
    } else if (
      this.props.location.search &&
      this.props.location.search.charAt(
        this.props.location.search.length - 1
      ) == 4
    ) {
      const config = {
        api: API.enterpriseAuthgetOperatorAuditDetail,
        path: { code: this.props.match.params.code },
      };
      http(config).then((res) => {
        // console.log(res, 'res333  操作员信息')
        if (res.code == 200) {
          let data = res;
          data.auditStatus = data.data.auditStatus;
          this.setState({
            data,
          });
        }
      });
    }
  };

  getDetail = () => {
    const config = {
      api: API.enterpriseAuthDetail,
      path: {
        code: this.props.match.params.code,
      },
      showLoading: true,
    };

    http(config).then((res) => {
      if (res.code == 0) {
        let data = res.data;

        this.setState({
          data,
        });
      }
    });
  };

  // 判断返回上一页面，列表应该去哪一页
  changeBackPageNo() {
    let backPageNo = 1;
    if (this.state.pageNo == 1) {
      // 原来是第一页，回去还看第一页
      backPageNo = 1;
    } else {
      // 这里是 除了1 之外的页码
      if (this.state.currentageCount == 1) {
        backPageNo = this.state.pageNo - 1;
      } else {
        backPageNo = this.state.pageNo;
      }
    }

    this.setState({
      backPageNo: backPageNo,
    });
  }

  /* start: 审核驳回的相关逻辑  */
  hidenRejectAuthDialog = () => {
    this.setState({ showRejectConfirmFlag: false });
  };

  showRejectAuthDialog = () => {
    this.setState({ showRejectConfirmFlag: true });
  };

  onRejectInput = (e) => {
    this.state.rejectInput = e.target.value;
  };

  gotoReject = () => {
    // 不能为null
    if (!this.state.rejectInput) {
      showErrorMessage("请输入驳回原因");
      return;
    }

    // 去掉两边可能的空格
    var rejectInput = this.state.rejectInput.replace(/(^\s*)|(\s*$)/g, "");
    if (rejectInput.length < 1) {
      showErrorMessage("请输入有效的驳回原因");
      return;
    }
    let config;
    let IS;
    if (
      this.props.location.search &&
      this.props.location.search.charAt(
        this.props.location.search.length - 1
      ) == 1
    ) {
      IS = this.props.location.search.charAt(
        this.props.location.search.length - 1
      );
      config = {
        api: API.enterpriseAuthAudit,
        data: {
          auditId: this.state.data.id,
          auditStatus: 20,
          auditRemark: this.state.rejectInput,
        },
      };
    } else if (
      this.props.location.search &&
      this.props.location.search.charAt(
        this.props.location.search.length - 1
      ) == 2
    ) {
      IS = this.props.location.search.charAt(
        this.props.location.search.length - 1
      );
      config = {
        api: API.enterpriseAuthDoAudit,
        data: {
          auditId: this.state.data.id,
          auditStatus: 20,
          auditRemark: this.state.rejectInput,
          driverId: this.state.data.driverId,
          userId: this.state.data.userId,
        },
      };
    } else if (
      this.props.location.search &&
      this.props.location.search.charAt(
        this.props.location.search.length - 1
      ) == 3
    ) {
      IS = this.props.location.search.charAt(
        this.props.location.search.length - 1
      );
      config = {
        api: API.enterpriseAuthVehicleDoAudit,
        data: {
          code: this.state.data.data.auditCode,
          auditStatus: 20,
          auditRemark: this.state.rejectInput,
        },
      };
    } else if (
      this.props.location.search &&
      this.props.location.search.charAt(
        this.props.location.search.length - 1
      ) == 4
    ) {
      IS = this.props.location.search.charAt(
        this.props.location.search.length - 1
      );
      config = {
        api: API.enterpriseAuthAuditOperator,
        data: {
          code: this.state.data.data.code,
          auditStatus: 20,
          auditRemark: this.state.rejectInput,
        },
      };
    }

    // config = IS == 1 ? {
    //     api: API.enterpriseAuthAudit,
    //     data: {
    //         auditId: this.state.data.id,
    //         auditStatus: 20,
    //         auditRemark: this.state.rejectInput
    //     },
    // } : {
    //     api: API.enterpriseAuthDoAudit,
    //     data: {
    //         auditId: this.state.data.id,
    //         auditStatus: 20,
    //         auditRemark: this.state.rejectInput,
    //         driverId: this.state.data.driverId,
    //         userId: this.state.data.userId,
    //     },
    // }
    http(config).then((res) => {
      if (res.code == 200) {
        this.setState({ showRejectConfirmFlag: false });
        message.success("操作成功");
        this.cDetail();
      } else {
        this.setState({ showRejectConfirmFlag: false });
        message.error(res.message);
      }
    });
  };

  addRejectAuthDialog() {
    return (
      <Modal
        title="驳回"
        visible={true}
        okText="确定"
        cancelText="取消"
        closable={false}
        maskClosable={false}
        onOk={this.gotoReject}
        onCancel={this.hidenRejectAuthDialog}
        width={400}
      >
        <TextArea
          rows={4}
          onChange={this.onRejectInput}
          placeholder="请输入驳回理由 （100字以内）"
          maxLength={100}
        />
      </Modal>
    );
  }
  /* end: 审核驳回的相关逻辑  */

  /* start: 审核通过的相关逻辑  */
  // 添加 审核通过的组件，但不是立即展示
  addPassAuthDialog() {
    return (
      <Modal
        title={"通过"}
        visible={true}
        okText="确定"
        cancelText="取消"
        closable={false}
        maskClosable={false}
        onOk={this.gotoPass}
        onCancel={this.hiddenPassAuthDialog}
        width={400}
      >
        <div>{"确认通过" + this.state.data.companyName + "的审核？"}</div>
      </Modal>
    );
  }

  hiddenAuthErrorInfoDialog = () => {
    this.setState({
      showPassConfirmFlag: false,
      showRejectConfirmFlag: false,
      errorTitle: null,
      errorMsg: null,
      showErrorFlag: false,
    });
  };

  addErrorInfoDialog() {
    return (
      <Modal
        title={this.state.errorTitle}
        visible={true}
        footer={[
          <Button
            key="primary"
            style={{ float: "none" }}
            type="primary"
            onClick={this.hiddenAuthErrorInfoDialog}
            size="large"
          >
            我知道了
          </Button>,
        ]}
        closable={false}
        maskClosable={false}
        width={400}
        onOk={this.hiddenAuthErrorInfoDialog}
      >
        <div>{this.state.errorMsg}</div>
      </Modal>
    );
  }

  // 设置图片
  closeImagePreview = () => {
    this.setState({ previewVisible: false });
  };
  // 设置图片缩放
  cImage = (value) => {
    this.setState({
      previewVisible: true,
      IMGURL: value
    });
  };

  render() {
    let {
      data,
      data: { applyInfoList = [] },
      previewVisible,
      currentIndex,
      imgList,
      IMGURL,
    } = this.state;

    const applyData =
      applyInfoList && applyInfoList.filter((item, index) => index % 2 === 0);
    const auditData =
      applyInfoList && applyInfoList.filter((item, index) => index % 2 !== 0);
    data.backPageNo = this.state.backPageNo;
    data.currentageCount = this.state.currentageCount;
    return (
      <Content className="customer-detail-content">
        <div className="customer-detail-head flexbox">
          <Breadcrumb className="customer-detail-head-bread">
            <Breadcrumb.Item>
              <Link
                style={{ color: "#333" }}
                to={{
                  pathname: "/app/crm/enterprise",
                  search: "backPageNo=" + this.state.backPageNo,
                }}
              >
                企业管理
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>企业信息</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div gutter={24} className="">
          <Modal
            visible={previewVisible}
            title=" "
            footer={null}
            onCancel={this.closeImagePreview}
          >
            <img
              alt="图片预览"
              style={{ width: "100%", margin: "0 0 24px" }}
              src={IMGURL}
            />
          </Modal>
          {/* <ImgPreview show={previewVisible} current={currentIndex} imgList={imgList} onClose={this.closeImagePreview}/> */}

          {data.auditFormDetail || data.code == 200 ? (
            <>
              <UserInfo data={this.state} props={this} />
              <OrganizationInfo
                code={this.state.data.supplierOrgDTO.organizationCompanyCode}
                props={this}
              />
            </>
          ) : null}
        </div>
      </Content>
    );
  }
}

export default EnterpriseDetail;
