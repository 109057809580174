import http from '@/http';
import { API } from '@/http/api';
import { formatLinkSearch } from '@/utils'
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Table from 'CMPT/common/Table';
import Select from 'CMPT/common/Select';
import SearchFormLogistics from './searchFormLogistics'
import moment from 'moment';
import { Popconfirm } from 'antd';


// 筛选项默认值
const defaultParams = {
    pageNo: 1, // 需要查询的页数
    pageSize: 10, // 每页条数
    queryText: '', // 订单编号
    placeOrderPlatform: '', // 下单平台
};
class LogisticsOrderList extends Component {
    state = {
        total: 0, // 当前数据的总数
        pageNo: 1, // 当前所展示的页码
        currentPageCount: 0, //当前页面的数量
        selectParams: {  // 接口入参，必须放这里。方便分页时保存信息
            subOrderStatus: this.props.state,// 审批状态
            ...defaultParams
        },
        columns: [
            {
                title: '发货单编号',
                key: "freightNo",
                dataIndex: 'freightNo',
            },{
                title: '订单编号',
                key: "subOrderNumber",
                dataIndex: 'subOrderNumber',
            }, {
                title: '装货地',
                key: "installGoodsAddress",
                dataIndex: 'installGoodsAddress',
            }, {
                title: '卸货地',
                key: "dischargeGoodsAddress",
                dataIndex: 'dischargeGoodsAddress',
            }, {
                title: '货主身份',
                key: "goodsOwnerIdentityName",
                dataIndex: 'goodsOwnerIdentityName',
            }, {
                title: '货主名称',
                key: "goodsOwnerName",
                dataIndex: 'goodsOwnerName',
            }, {
                title: '运输方类型',
                key: "driverIdentityName",
                dataIndex: 'driverIdentityName',
            },{
                title: '运输人',
                key: "driverName",
                dataIndex: 'driverName',
            },{
                title: '下单平台',
                key: "placeOrderPlatformName",
                dataIndex: 'placeOrderPlatformName',
            },{
                title: '订单状态',
                key: "subOrderStatusName",
                dataIndex: 'subOrderStatusName',
            },{
                title: '运输费用',
                key: "price",
                dataIndex: 'price',
            },{
                title: '订单创建时间', 
                key: 'createDate',
                dataIndex: 'createDate',
                render: (text, record) => {
                    return (<span>{moment(text).format('YYYY-MM-DD HH:mm')}</span>);
                }
            }, {
                title: '操作',
                key: 'code',
                width: 116,
                render: (text, record, index) => {
                    return (
                        <div>
                            <span className="table-operate-btn">
                                <Link to={'/app/crm/orderManage/logistics_detail/' + record.code + "?pageNo=" + this.state.pageNo + "&currentPageCount=" + this.state.currentPageCount} style={{ color: '#2E4AFF' }}>查看 </Link>
                            </span>
                            
                        </div>
                    )
                    
                }
            }
        ],
        data: [],
    }

    componentWillMount() {
        if (this.props.location) {
            // 获取参数
            const search = this.props.location.search
            const formatData = formatLinkSearch(search)

            // 解析
            let pageNo1 = null;
            if (formatData && formatData.backPageNo) {
                pageNo1 = formatData.backPageNo
            }

            // 赋值
            this.setState({
                selectParams: {
                    auditStatus: this.props.state,// 审批状态
                    pageNo: pageNo1, // 当前页数
                    pageSize: 10, // 每页条数
                }
            });
        }
    }

    //初始化
    componentDidMount() {
        this.getLists();
    }
    //查询列表
    getLists = () => {
        const httpParams = { ...this.state.selectParams };
        for (let [key, value] of Object.entries(httpParams)) {

            if (value === -1 || value === '') {
                //    console.log('[key, value]',[key, value])
                httpParams[key] = undefined;
            }
        }
        const config = {
            api: API.orderListManageLogisticsOrder,
            data: { ...httpParams },
            showLoading: true
        };

        http(config).then(res => {
            console.log(res, 'res1111111111111111111')
            if (res.code == 200) {
                const data = res.data;
                this.setState({
                    data: data.datas || [],
                    total: Number(data.total),
                    pageNo: data.pageNo,
                    currentPageCount: data.datas ? data.datas.length : 0,
                    selectParams: { ...this.state.selectParams, pageNo: data.pageNo }
                });
            }
        })
    }



    //分页
    onChangePage = (pageNo, pageSize, sort) => {
        this.setState({
            selectParams: { ...this.state.selectParams, pageNo: pageNo, pageSize: pageSize }
        }, () => {
            this.getLists()
        })
    }

    // 搜索
    onValuesChange = (allValues) => {
        this.setState({ selectParams: { ...this.state.selectParams, ...allValues } })
    }

    reset = (allValues) => {
        this.setState({
            selectParams: { ...this.state.selectParams, ...defaultParams }
        }, () => {
            this.getLists();
        })
    }

    render() {
        const selectParams = this.state.selectParams
        return (
            <div className='enterprise-auth-list'>
                <div className='searchBox'>
                    <SearchFormLogistics onValuesChange={this.onValuesChange} types={false} reset={this.reset} onSearch={() => { this.getLists() }} />
                </div>
                <Table columns={this.state.columns} rowKey={(record, index) => record.code} selfProcessData={this.state.data} total={this.state.total} change={this.onChangePage} current={selectParams.pageNo} />
            </div>

        );
    }
}

export default LogisticsOrderList;