import React from 'react'
import Table from 'CMPT/common/Table'
import { Breadcrumb, Input, Button,message } from 'antd'
import TestAccountPopPage from './testAccountPop';
import './index.less'
import { IdentityType } from 'CMPT/enterpriseAuth/enum'
import http from '@/http';
import { API } from '@/http/api';
const prefixCls = 'test-account';

class TestAccountPage extends React.Component {

    state = {
        popVisible: false,
        total: 0,
        params: {
            pageNo: 1,
            pageSize: 10,
            keyWord: '',
        },
        loading: false,
        columns: [
            { title: '序号', width: '60px',key: "no",render(text, record, index) {
                return <span>{index + 1}</span>;
            }},
            {title: '姓名',dataIndex: 'name'},
            {title: '昵称',dataIndex: 'nickName'},
            {title: '手机号',dataIndex: 'mobile'},
            {title: '创建时间',dataIndex: 'userCreateTime'},
            {title: '企业名称',dataIndex: 'companyName'},
            {title: '企业类型',dataIndex: 'authenticationTo', render: (text) => IdentityType.getTextFromValue(text) || '-'},
            {title: '添加时间',dataIndex: 'addTime'}
        ],
        data: []
    }
    // 添加测试账号弹窗
    onAddClick () {
        this.setState({ popVisible: true })
    }
    // 搜索change
    onSearchChange (value) {
        this.setState((state) => ({
            params: {...state.params, keyWord: value}
        }))
    }
    //分页
    onChangePage = (pageNo, pageSize, sort) => {
        this.setState((state)=>({
            params: {...state.params, pageNo: pageNo, pageSize: pageSize}
        }),() => {
            this.onLoadList()
        })
    }

    onLoadList () {

        http({
            api: API.testAccountList,
            data: {
                ...this.state.params
            },
            showLoading: true
        }).then(res => {
            if (res.success){
                this.setState({data: res.data.datas || [], total: res.data.totalRecord})
            }
           
        }).catch (error => {
 
        })
    }
    componentWillMount () {
        this.onLoadList()
    }

    onPopAddSuccess () {
        this.setState({popVisible: false})
        this.onLoadList()
    }

    render (){
        const { total,params,data,columns,loading } = this.state
        return (
            <div className={prefixCls + '-page'}>
                <div className={prefixCls + '-breadcrumb'}>
                    <Breadcrumb>
                        <Breadcrumb.Item>测试账号</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className={prefixCls + '-content'}>
                    <div className={prefixCls + '-filter'}>
                        <Input value={params.keyWord} placeholder='姓名/昵称/手机号/企业名称' allowClear style={{ width: 200 }} onChange={e => this.onSearchChange(e.target.value)}/>
                        <Button type='primary' className={prefixCls + '-item'} onClick={()=>this.onLoadList()}>搜索</Button>
                        <Button type='primary' onClick={() => this.onAddClick()}>添加</Button>
                    </div>
                    <div className={prefixCls + '-list-wrapper'}>
                        <Table 
                            rowKey="code"
                            columns={columns}
                            data={data}
                            total={total}
                            change={this.onChangePage}
                            pageSize={params.pageSize}
                            current={params.pageNo}
                            />
                    </div>
                </div>
                {  this.state.popVisible ? <TestAccountPopPage  addSuccess={() => this.onPopAddSuccess()} onCancel={() => this.setState({popVisible: false})}></TestAccountPopPage> : null }
                
            </div>
        );
    };
}
export default TestAccountPage