import authEnum from './authEnum';

const baseAuthConfig = authEnum.authConfig;
const scopeAuth = authEnum.scopeAuth;

function getSecondLevelIds() {
    const secondLevelIds = [];

    Object.entries(baseAuthConfig).forEach(([
        id,
        {
            level
        }
    ]) => {
        if (level === 2) {
            secondLevelIds.push(id);
        }
    });

    return secondLevelIds;
}

const secondLevelIds = getSecondLevelIds();

function richAuthConfig(baseAuthConfig) {
    Object.entries(baseAuthConfig).forEach(([
        id,
        authConfig
    ]) => {
        authConfig.id = id;
        if (authConfig.parentId) {
            const parentAuthConfig = baseAuthConfig[authConfig.parentId];
            if (!parentAuthConfig.subs) {
                parentAuthConfig.subs = [];
            }
            parentAuthConfig.subs.push(id);
        }
    });
}

richAuthConfig(baseAuthConfig);

export default function getAuthConfig(functionIds) {
    const userAuthConfig = getCopy();

    if (functionIds) {
        functionIds.forEach(id => {
            checkAuth(userAuthConfig, id);
        });

        checkAllIndeterminate(userAuthConfig);
    }

    return userAuthConfig;
}

export function getUserAuthConfig(roleFuncIds, userFuncIds) {
    const userAuthConfig = getCopy();

    if (userFuncIds) {
        userFuncIds.forEach(id => {
            checkAuth(userAuthConfig, id);
        });
    }

    if (roleFuncIds) {
        roleFuncIds.forEach(id => {
            checkAuth(userAuthConfig, id, true, true);
        });
    }

    if (userFuncIds || roleFuncIds) {
        checkAllIndeterminate(userAuthConfig);
    }

    return userAuthConfig;
}

function getCopy() {
    const userAuthConfig = {};
    Object.entries(baseAuthConfig).forEach(([
        id,
        authConfig
    ]) => {
        userAuthConfig[id] = {
            ...authConfig,
            checked: false,
            disabled: false,
            scopeDisabled: false,
            indeterminate: false
        }
    });
    return userAuthConfig;
}

function checkAuth(userAuthConfig, id, disabled = false, scopeDisabled = false) {
    const authConfig = userAuthConfig[id];
    if (authConfig) {
        authConfig.checked = true;
        authConfig.disabled = disabled;
    } else if (scopeAuth[id]) {
        const parentAuth = userAuthConfig[scopeAuth[id]];
        setLargerScope(parentAuth, id, scopeDisabled);
        parentAuth.checked = true;
        parentAuth.disabled = disabled;
    }
}

function setLargerScope(parentAuth, scopeValue, scopeDisabled) {
    if (parentAuth._setScope) {
        for (const {
                value,
                desc
            } of parentAuth.scope) {
            if (value === scopeValue && desc.includes('所有')) {
                parentAuth.scopeDisabled = scopeDisabled;
                parentAuth.scopeValue = scopeValue;
                // $$_II('set larger scope', scopeValue)
            }
        }
    } else {
        parentAuth._setScope = true;
        parentAuth.scopeDisabled = scopeDisabled;
        parentAuth.scopeValue = scopeValue;

        // $$_II('set  scope', scopeValue)
    }
}

function checkAllIndeterminate(userAuthConfig) {
    secondLevelIds.forEach(id => {
        checkIndeterminate(userAuthConfig, id);
    });
}

function checkIndeterminate(userAuthConfig, id) {
    const authConfig = userAuthConfig[id];
    if (authConfig.checked) {
        const subsAuth = authConfig.subs;
        if (subsAuth && subsAuth.length) {
            let parentIndeterminate = false;
            for (const subId of subsAuth) {
                checkIndeterminate(userAuthConfig, subId);
                const {
                    checked,
                    indeterminate
                } = userAuthConfig[subId];
                if (!checked || indeterminate) {
                    parentIndeterminate = true;
                }
            }
            authConfig.indeterminate = parentIndeterminate;
        }
    }
}

export function getReadAuth(functionIds) {
    let userAuthConfig;
    if (functionIds && functionIds.length) {
        userAuthConfig = {};
        functionIds.forEach(id => {
            if (baseAuthConfig[id]) {
                if (!userAuthConfig[id]) {
                    userAuthConfig[id] = {
                        ...baseAuthConfig[id],
                        subs: []
                    }
                }
            } else if (scopeAuth[id]) {
                const parentId = scopeAuth[id];
                if (!userAuthConfig[parentId]) {
                    userAuthConfig[parentId] = {
                        ...baseAuthConfig[parentId],
                        subs: []
                    }
                }

                userAuthConfig[parentId].scopeValue = id;
            }
        });

        const arrAuthConfig = Object.entries(userAuthConfig);

        for (const [id, {
                parentId
            }] of arrAuthConfig) {
            if (parentId) {
                let parentAuth = userAuthConfig[parentId];
                if (!parentAuth) {
                    parentAuth = {
                        ...baseAuthConfig[parentId],
                        subs: []
                    }
                    userAuthConfig[parentId] = parentAuth;
                    arrAuthConfig.push([parentId, parentAuth]);
                }
                parentAuth.subs.push(id);
            }
        }
    }
    return userAuthConfig;
}