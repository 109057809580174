import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { Form, Row, Col, message, Rate } from 'antd';
import moment from 'moment';
import http from '@/http'
import { API } from '@/http/api'
import { changeObjkey,getNewPhone } from '@/utils'
import userUtil from '@/utils/user';
import { AUTH_KEYS } from '@/utils/auth'
import Modelmodel from 'CMPT/common/drawer/model'
import BreadcrumbHeader from 'CMPT/common/breadcrumb/breadcrumb'
import ClickImg from 'CMPT/common/clickimg/clickimg'
import SendOrder from 'CMPT/serviceCenter/logistics/maintain/sendorder'
import CancelOrder from './cancelorder'
import AcceptanceOrder from './acceptanceorder'

const orderStatustextList = ['已取消','待派单','待处理','待验收','已完成'];
const orderStatuscolorList = ['#333','#FBBC06','#FBBC06','#FBBC06','#E94437'];
const FormatSet = 'YYYY-MM-DD HH:mm:ss';

class OperateOrderdetailform extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sendModelstatus: false,
            acceptanceModelstatus: false,
            cancelModelstatus: false,
            orderDetail: {},
            orderscheduleList: [],
            sendorderdata: {},
            acceptanceorder: {},
            cancelorder: {},
            result: [],
            servicerList: []
        }
    }
    //显示按钮
    showuseButton() {
        let modalList = [
            {
                auth: 'OPERATE_SERVICE_LOGISTICS_COMPLAINT_ACCEPTANCEORDER',
                name: '验收',
                title: '验收',
                buttontype: 'primary',
                ModelName: 'orderdetail-button',
                visible: this.state.acceptanceModelstatus,
                handleOk: this.handleacceptanceOk.bind(this),
                handleCancel: this.showacceptanceModal.bind(this),
                showModal: this.showacceptanceModal.bind(this),
                Drawermodel: <AcceptanceOrder encode={this.props.location.hash.substr(1)}
                                              form={this.props.form}
                                              Accresult={this.state.acceptanceorder.Accresult}
                                              result={this.state.result}
                                              chooseProject={this.changeacceptanceModal.bind(this)}>
                </AcceptanceOrder>
            },
            {
                auth: 'OPERATE_SERVICE_LOGISTICS_COMPLAINT_SENDORDER',
                name: this.state.orderDetail.orderDispatchFlag === 1 ? '重新派单' : '派单',
                title: '派单',
                buttontype: 'primary',
                ModelName: 'orderdetail-button',
                visible: this.state.sendModelstatus,
                handleOk: this.handlesendOk.bind(this),
                handleCancel: this.showsendModal.bind(this),
                showModal: this.showsendModal.bind(this),
                Drawermodel: <SendOrder form={this.props.form}
                                        urgent={this.state.servicerList}
                                        chooseProject={this.changesendModal.bind(this)}>
                </SendOrder>
            },
            {
                auth: 'OPERATE_SERVICE_LOGISTICS_COMPLAINT_CANCELORDER',
                name: '取消工单',
                title: '取消工单',
                buttontype: 'primary',
                ModelName: 'orderdetail-button red',
                visible: this.state.cancelModelstatus,
                handleOk: this.handlecancelOk.bind(this),
                handleCancel: this.showcancelModal.bind(this),
                showModal: this.showcancelModal.bind(this),
                Drawermodel: <CancelOrder encode={this.props.location.hash.substr(1)}
                                          form={this.props.form}
                                          chooseProject={this.changecancelModal.bind(this)}>
                </CancelOrder>
            }
        ];
        const status = this.state.orderDetail.orderStatus;
        switch (status) {
            case 1:
                modalList.splice(0,1);
                break;
            case 2:
                modalList.splice(0,2);
                break;
            case 3:
                modalList.splice(1,1);
                break;
            default:
                modalList = [];
                break
        }
        return modalList
    }

    //派单
    handlesendOk(event) {
        event.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const dataObj = {
                    orderCode: this.props.location.hash.substr(1),
                    userCode: this.state.sendorderdata.sendOrder,
                    userName: this.state.sendorderdata.userName,
                    mobile: this.state.sendorderdata.mobile,
                    remark: this.state.sendorderdata.remarks
                };
                this.showsendModal();
                this.postsendDateSave(dataObj)
            }
        });
    }
    showsendModal() {
        if (userUtil.hasAuth(AUTH_KEYS.OPERATE_SERVICE_LOGISTICS_COMPLAINT_SENDORDER)) {
            this.setState({
                sendModelstatus: !this.state.sendModelstatus
            });
        } else {
            message.warning(`抱歉，您没有派单权限!`)
        }
    }
    changesendModal(type,val,option) {
        const sendorderdata = this.state.sendorderdata;
        let targetVal = null;
        if (val && val.target) targetVal = val.target.value;

        switch (type) {
            case 1:
                sendorderdata.sendOrder = val;
                sendorderdata.userName = this.state.servicerList.filter(item => item.value === val)[0].userName
                break;
            case 2:
                sendorderdata.remarks = targetVal;
                break;
            default:
                break
        }

        this.setState({
            sendorderdata
        })
    }

    //验收
    handleacceptanceOk(event) {
        event.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({
                    acceptanceorder: {}
                });
                const dataObj = changeObjkey(this.state.acceptanceorder,{Accresult:'checkResult',rate:'stars',remarks:'remark',reason:'remark'});
                dataObj.orderCode = this.props.location.hash.substr(1);
                this.showacceptanceModal();
                this.postaccepDateSave(dataObj)
            }
        });
    }
    showacceptanceModal() {
        this.setState({
            acceptanceModelstatus: !this.state.acceptanceModelstatus
        });
    }
    changeacceptanceModal(type,val,option) {
        const acceptanceorder = this.state.acceptanceorder;
        let targetVal = null;
        if (val && val.target) targetVal = val.target.value;

        switch (type) {
            case 1:
                acceptanceorder.Accresult = Number(val);
                break;
            case 2:
                acceptanceorder.rate = Number(val);
                break;
            case 3:
                acceptanceorder.remarks = targetVal;
                break;
            case 4:
                acceptanceorder.reason = targetVal;
                break;
            default:
                break
        }

        this.setState({
            acceptanceorder
        })
    }

    //取消工单
    handlecancelOk(event) {
        event.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const dataObj = changeObjkey(this.state.cancelorder,{cancelresult:'remark'});
                dataObj.orderCode = this.props.location.hash.substr(1);
                this.showcancelModal();
                this.postcancelDateSave(dataObj)
            }
        });
    }
    showcancelModal() {
        this.setState({
            cancelModelstatus: !this.state.cancelModelstatus
        });
    }
    changecancelModal(type,val,option) {
        const cancelorder = this.state.cancelorder;
        let targetVal = null;
        if (val && val.target) targetVal = val.target.value;

        switch (type) {
            case 1:
                cancelorder.cancelresult = targetVal;
                break;
            default:
                break
        }

        this.setState({
            cancelorder
        })
    }

    componentWillMount() {
        this.getmaintainInit()
    }
    //请求初始化枚举
    getmaintainInit() {
        const config = {
            api: API.ordercomplainInit
        }
        http(config).then(result => {
            const { data } = result;
            let { orderCheckTypeEnum } = data;

            //通过不通过
            orderCheckTypeEnum = orderCheckTypeEnum.map(item => changeObjkey(item,{key:'value',value:'name'}));

            setTimeout(()=>{
                this.setState({
                    result: orderCheckTypeEnum
                })
            },0);

            this.getorderDetail(this.props.location.hash.substr(1))
        })
    }
    //请求维修工list
    postservicerList(data) {
        const config = {
            api: API.ordercomplainGetLaborList,
            data
        };
        http(config).then(result => {
            const { data } = result;
            const servicerList = data && data.map(item => {
                const list = changeObjkey(item,{encode:'value'});
                list.name = <Row><Col span={4}>{item.name}</Col><Col span={8}>{item.mobile}</Col><Col span={8}>待维修共{item.wppCount}单</Col></Row>;
                list.userName = item.name;
                list.mobile = item.mobile;
                return list
            });
            setTimeout(()=>{
                this.setState({
                    servicerList
                })
            },0)
        })
    }
    //请求详情数据
    getorderDetail(orderCode) {
        const config = {
            api: API.ordercomplainDetail,
            path: {
                orderCode
            },
            showLoading: true
        }
        http(config).then(result => {
            const { data } = result;
            const status = data.status;
            const orderDetail = {
                orderDispatchFlag: data.dispatchFlag,
                orderStatusText: orderStatustextList[status],
                orderStatusColor: orderStatuscolorList[status],
                orderID: data.orderId,
                orderStatus: status,
                orderhouse: data.projectHouseName,
                orderaddress: data.address,
                orderurgentlevel: data.urgentLevel,
                orderdescription: data.description,
                complaint: data.complainContent,
                orderimglist: data.photosUrl,
                orderReportinfo: <div><span style={{marginRight:12}}>{data.applicantName}</span><span>{getNewPhone(data.applicantNameMobile)}</span></div>,
                orderAcceptinfo: data.handleName ? <div><span style={{marginRight:12}}>{data.handleName}</span><span>{getNewPhone(data.handleNameMobile)}</span></div> : [0].includes(status) ? '' : null,
                orderAcceptdate: data.processTimeStr
            };

            const orderscheduleList = data.follows.map(item => {
                const { detail } = item;
                const statusText = item.description;
                let statusNode = [];
                switch (item.type) {
                    case 5:
                        statusNode = [
                            {
                                name: '取消人',
                                isuse: detail.cancelName,
                                node: detail.cancelName
                            },
                            {
                                name: '取消原因',
                                isuse: detail.remark,
                                node: detail.remark
                            }
                        ];
                        break;
                    case 1:
                        statusNode = [
                            {
                                name: '操作人',
                                isuse: item.operateUser,
                                node: item.operateUser
                            },
                            {
                                name: '上报人信息',
                                isuse: detail.applicantName,
                                node: <span><span style={{marginRight:12}}>{detail.applicantName}</span><span>{getNewPhone(detail.mobile)}</span></span>
                            },
                            {
                                name: '预计处理时间',
                                isuse: detail.processTime,
                                node: detail.processTime
                            }
                        ];
                        break;
                    case 2:
                        statusNode = [
                            {
                                name: '操作人',
                                isuse: item.operateUser,
                                node: item.operateUser
                            },
                            {
                                name: '受理人信息',
                                isuse: detail.receiverName,
                                node: <span><span style={{marginRight:12}}>{detail.receiverName}</span><span>{ getNewPhone(detail.mobile)}</span></span>
                            },
                            {
                                name: '备注',
                                isuse: detail.remark,
                                node: detail.remark
                            }
                        ];
                        break;
                    case 3:
                        statusNode = Number(detail.handleResult) === 2 ? [
                            {
                                name: '未解决原因',
                                isuse: detail.remark,
                                node: detail.remark
                            }
                        ] : [
                            {
                                name: '解决方案',
                                isuse: detail.remark,
                                node: detail.remark
                            }
                        ];
                        break;
                    case 4:
                        statusNode = Number(detail.handleResult) === 2 ? [
                            {
                                name: '验收人',
                                isuse: detail.checkUserName,
                                node: detail.checkUserName
                            },
                            {
                                name: '不通过原因',
                                isuse: detail.remark ,
                                node: detail.remark
                            }
                        ] : [
                            {
                                name: '验收人',
                                isuse: detail.checkUserName,
                                node: detail.checkUserName
                            },
                            {
                                name: '评价',
                                isuse: detail.stars,
                                node: <Rate disabled defaultValue={Number(detail.stars)}/>
                            },
                            {
                                name: '备注',
                                isuse: detail.remark,
                                node: detail.remark
                            }
                        ];
                        break;
                    default:
                        break
                }
                return {
                    date: item.operateTime,
                    statusText,
                    statusNode
                }
            })
            setTimeout(()=>{
                this.setState({
                    orderDetail,
                    orderscheduleList
                })
            },0)

            //请求维修工list
            if (Number(status) === 1) this.postservicerList({projectEncode: data.projectEncode})
        })
    }
    //请求派单保存
    postsendDateSave(data) {
        const config = {
            api: API.ordercomplainDispatch,
            data: {
                ...data
            },
            showLoading: true
        };
        http(config).then(result => result.success && (message.success(`派单成功`) && this.getorderDetail(this.props.location.hash.substr(1))))
    }
    //请求验收保存
    postaccepDateSave(data) {
        const config = {
            api: API.ordercomplainCheck,
            data: {
                ...data
            },
            showLoading: true
        };
        http(config).then(result => result.success && (message.success(`验收成功`) && this.getorderDetail(this.props.location.hash.substr(1))))
    }
    //请求取消工单
    postcancelDateSave(data) {
        const config = {
            api: API.ordercomplainCancel,
            data: {
                ...data
            },
            showLoading: true
        };
        http(config).then(result => result.success && (message.success(`取消成功`) && this.getorderDetail(this.props.location.hash.substr(1))))
    }

    render() {
        const { orderDetail, orderscheduleList } = this.state;
        const {
            orderStatusText,
            orderStatusColor,
            orderhouse,
            orderaddress,
            orderdescription,
            orderurgentlevel,
            complaint,
            orderimglist,
            orderReportinfo,
            orderAcceptinfo,
            orderAcceptdate
        } = orderDetail;
        // const breadlist = [{name:'投诉管理',route:'/app/operate/serviceCenter/logistics/complaint'},{name:this.state.orderDetail.orderID}];
        const breadlist = [{name:'投诉管理',route:'/app/operate/serviceCenter/logistics/complaint'},{name:'工单详情'}];
        const addorderButton =  <div>
            {this.showuseButton().map((item,key) => <Modelmodel {...item} key={key}></Modelmodel>)}
        </div>;

        return (
            <div className='maintain-orderDetail'>
                <div className='orderDetail-head'>
                    <BreadcrumbHeader breadlist={breadlist} buttonobj={addorderButton}></BreadcrumbHeader>
                </div>
                <div className='orderDetail-body'>
                    <Row>
                        <Col span={15}>
                            <div className='orderDetail-body-orderinfo'>
                                <div className='orderDetail-title'><span>工单信息</span>{orderurgentlevel === 1? <span className='urgentlevel'>紧急</span> : null}</div>
                                <div className='orderDetail-body-content'>
                                    <Row>
                                        <Col span={3}>所属项目:</Col>
                                        <Col span={12}>{orderhouse}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={3}>地址:</Col>
                                        <Col span={12}>{orderaddress}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={3}>投诉问题:</Col>
                                        <Col span={12}>{complaint}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={3}>紧急程度:</Col>
                                        <Col span={12}>{orderurgentlevel === 1? '紧急' : '一般'}</Col>
                                    </Row>
                                    {
                                        orderdescription ? (  <Row>
                                            <Col span={3}>更多描述:</Col>
                                            <Col span={12}>{orderdescription}</Col>
                                        </Row>) : null
                                    }
                                    {
                                        orderimglist && orderimglist.length>0 ? (
                                            <div>
                                                <Row>
                                                    <Col>图片上传:</Col>
                                                </Row>
                                                <Row>
                                                    <Col span={10}><ClickImg cardlist={orderimglist} className='orderDetail-body-content-img'></ClickImg></Col>
                                                </Row>
                                            </div>
                                        ) : null
                                    }
                                </div>
                            </div>
                            <div className='orderDetail-body-moreinfo'>
                                <div className='orderDetail-title'><span>更多信息</span></div>
                                <div className='orderDetail-body-content'>
                                    <Row>
                                        <Col span={3}>上报人信息:</Col>
                                        <Col span={12}>{orderReportinfo}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={3}>受理人信息:</Col>
                                        <Col span={12}>{orderAcceptinfo != null ? orderAcceptinfo : <span className='yellow-text' onClick={this.showsendModal.bind(this)}>暂无受理人信息 点击前往派单</span>}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={3}>预计处理时间:</Col>
                                        <Col span={12}>{orderAcceptdate}</Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col span={9} offset={1}>
                            <div className='orderDetail-body-orderschedule'>
                                <div className='orderDetail-title'><span>工单进度</span><span className='orderStatus' style={{color:orderStatusColor,borderColor:orderStatusColor}}>{orderStatusText}</span></div>
                                <div className='step-z'>
                                    {orderscheduleList.map((item,index) =>
                                        <div key={index} className='list-item'>
                                            <div className='list-left'>
                                                <div className='list-left-item list-left-time'><span>{item.date}</span><span>{item.statusText}</span></div>
                                                {item.statusNode.map((itemchild,indexs) => itemchild.isuse ? <div className='list-left-item' key={indexs}>{<div><span>{itemchild.name}:</span><span>{itemchild.node}</span></div>}</div> : null)}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

const OperateOrderdetail = Form.create({ name: 'OperateOrderdetailform' })(OperateOrderdetailform);

export default withRouter(OperateOrderdetail)
