import React, { Component } from 'react';
import { List, Modal, Table, Icon } from 'antd';
import './CapitalCommon.less'
import DecoratePopView from './DecoratePopView'
import RentStatePopView from './RentStatePopView'
import EditAreaPopView from './EditAreaPopView'
import SplitAreaPopView from './SplitAreaPopView'
import CombineAreaPopView from './CombineAreaPopView'
import { getBizStatus } from './Utils'
import { RENT_STATUS, DECORATE_STATUS } from './CapitalConfig'
import http from '@/http'
import { API } from '@/http/api';
import AuthContainer from 'CMPT/common/auth/AuthContainer';
import { AUTH_KEYS } from '@/utils/auth';
import userUtil from '@/utils/user';
const STATUS_TYPE = {   //浮层弹出显示的判断
    NOT_RENT_DEBT: 0,            //未出租-有未结账务
    NOT_RENT_NO_DEBT: 1,         //未出租-无未结账务
    RENTED_CONTRACT: 2,          //已出租-有合同
    RENTED_NO_CONTRACT: 3,       //已出租-无合同
    RENTED_DECORATING: 4,        //已出租-装修中
    FORBID: 5                    //不可租
}

const MENUS = {     //所有菜单选项及其显示顺序
    办理签约: userUtil.hasAuth(AUTH_KEYS.PROJECT_REGION_OPERATE_SIGN) ? '办理签约' : '',
    添加意向客户: userUtil.hasAuth(AUTH_KEYS.PROJECT_REGION_OPERATE_ADDCUSTOMER) ? '添加意向客户' : '',
    查看合同: userUtil.hasAuth(AUTH_KEYS.PROJECT_REGION_OPERATE_CHECKCONTRACT) ? '查看合同' : '',
    装修管理: userUtil.hasAuth(AUTH_KEYS.PROJECT_REGION_OPERATE_DECORATION) ? '装修管理' : '',
    办理续签: userUtil.hasAuth(AUTH_KEYS.PROJECT_REGION_OPERATE_AGAINSIGN) ? '办理续签' : '',
    办理退租: userUtil.hasAuth(AUTH_KEYS.PROJECT_REGION_OPERATE_TUIZU) ? '办理退租' : '',
    查看历史合同: userUtil.hasAuth(AUTH_KEYS.PROJECT_REGION_OPERATE_HISTORYCONTRACT) ? '查看历史合同' : '',
    变更出租状态: userUtil.hasAuth(AUTH_KEYS.PROJECT_REGION_OPERATE_RENTSTATUS) ? '变更出租状态' : '',
    编辑区域: userUtil.hasAuth(AUTH_KEYS.PROJECT_REGION_OPERATE_EDIT) ? '编辑区域' : '',
    分割区域: userUtil.hasAuth(AUTH_KEYS.PROJECT_REGION_OPERATE_DIVISION) ? '分割区域' : '',
    合并区域: userUtil.hasAuth(AUTH_KEYS.PROJECT_REGION_OPERATE_MERGE) ? '合并区域' : '',
}

/**
 * 浮层
 */
// 左边信息
class CapitalFloatInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            zoneDetail: null,
            showHighlightQk: props.QkDays ? true : false   //所有有未结账务状态均高亮显示欠款
        }

        this.getInfo();
    }

    componentDidUpdate(preProps) {
        if (preProps.visibleEncode !== this.props.visibleEncode) {
            this.getInfo();
        }
    }

    getInfo() {
        const { visibleEncode, zoneVosData: { encode } } = this.props
        if (visibleEncode === encode) {
            const config = {
                api: API.zoneGetZoneDetail,
                path: { zoneEncode: encode }
            }
            http(config).then(result => {
                this.setState({
                    zoneDetail: result.data
                })
            })
        }
    }

    handlerBackRefresh() {
        this.props.handlerBackRefresh()
    }

    handlerBackMenuClick(name) {
        this.props.handlerBackMenuClick(name)
    }

    viewDebt() {
        // console.log(this.state.zoneDetail)
        // return false
        this.props.history.push({ pathname: `/app/operate/contract/detail/${this.state.zoneDetail.overdueContractEncode}`, search: `?action=2&audit=0` })
    }

    render() {
        const zoneDetail = this.state.zoneDetail;

        let columns = [
            {
                title: '',
                dataIndex: 'title',
                key: 'title',
            },
            {
                title: '',
                dataIndex: 'value',
                key: 'value',
            }
        ]
        let dataSource = []

        let type = null
        let titleArray = []
        let valueArray = []
        if (zoneDetail) {
            //1、各类型状态
            switch (zoneDetail.rentStatus) {
                case RENT_STATUS.NotRent.value:     //未出租
                    if (this.props.QkDays) {  //有未结账务
                        type = STATUS_TYPE.NOT_RENT_DEBT;
                        titleArray = ['预计单价：', '当前物业费：']
                        valueArray[0] = `${zoneDetail.unitPrice}元/m²/月`
                        valueArray[1] = zoneDetail.contractPropertyPrice ? `${zoneDetail.contractPropertyPrice}元/m²` : '-'
                    } else {    //无未结账务
                        type = STATUS_TYPE.NOT_RENT_NO_DEBT;
                        titleArray = ['预计单价：', '当前物业费：']
                        valueArray[0] = `${zoneDetail.unitPrice}元/m²/月`
                        valueArray[1] = zoneDetail.contractPropertyPrice ? `${zoneDetail.contractPropertyPrice}元/m²` : '-'
                    }
                    break;
                case RENT_STATUS.Rented.value:      //已出租
                    if (zoneDetail.status == DECORATE_STATUS.Decorating.value) { //装修中
                        type = STATUS_TYPE.RENTED_DECORATING;
                        titleArray = ['承租人：', '合同编号：', '租期：', '下期付款日：', '签约价格：', '签约物业费：']
                        valueArray[0] = zoneDetail.customerName || '-'
                        valueArray[1] = `${zoneDetail.contractCode}`
                        valueArray[2] = `${zoneDetail.rentPeriod}`
                        valueArray[3] = zoneDetail.nextPayDate ? `${zoneDetail.nextPayDate}` : '账单已结清'
                        valueArray[4] = `${zoneDetail.contractPrice}元/m²/月`
                        valueArray[5] = zoneDetail.contractPropertyPrice ? `${zoneDetail.contractPropertyPrice}元/m²` : '-'
                    } else {
                        if (zoneDetail.contractEncode) {    //有合同
                            type = STATUS_TYPE.RENTED_CONTRACT;
                            titleArray = ['承租人：', '合同编号：', '租期：', '下期付款日：', '签约价格：', '签约物业费：']
                            valueArray[0] = zoneDetail.customerName || '-'
                            valueArray[1] = `${zoneDetail.contractCode}`
                            valueArray[2] = `${zoneDetail.rentPeriod}`
                            valueArray[3] = zoneDetail.nextPayDate ? `${zoneDetail.nextPayDate}` : '账单已结清'
                            valueArray[4] = zoneDetail.contractPrice ? `${zoneDetail.contractPrice}元/m²/月` : '-'
                            valueArray[5] = zoneDetail.contractPropertyPrice ? `${zoneDetail.contractPropertyPrice}元/m²` : '-'
                        } else {    //无合同
                            type = STATUS_TYPE.RENTED_NO_CONTRACT;
                            titleArray = ['承租人：', '预计单价：', '当前物业费：']
                            valueArray[0] = '待补录'
                            valueArray[1] = zoneDetail.unitPrice ? `${zoneDetail.unitPrice}元/m²/月` : '-'
                            valueArray[2] = zoneDetail.contractPropertyPrice ? `${zoneDetail.contractPropertyPrice}元/m²` : '-'
                        }
                    }
                    break;
                case RENT_STATUS.ForbidRent.value:  //不可租
                    type = STATUS_TYPE.FORBID;
                    titleArray = ['预计单价：', '当前物业费：']
                    valueArray[0] = zoneDetail.unitPrice ? `${zoneDetail.unitPrice}元/m²/月` : '-'
                    valueArray[1] = zoneDetail.contractPropertyPrice ? `${zoneDetail.contractPropertyPrice}元/m²` : '-'
                    break;
            }
            //2、全部
            titleArray.push('建筑面积：')
            titleArray.push('使用面积：')
            titleArray.push('套内面积：')
            titleArray.push('备注：')
            valueArray.push(zoneDetail.buildingArea ? `${zoneDetail.buildingArea}m²` : '-')
            valueArray.push(zoneDetail.useArea ? `${zoneDetail.useArea}m²` : '-')
            valueArray.push(zoneDetail.innerArea ? `${zoneDetail.innerArea}m²` : '-')
            valueArray.push(zoneDetail.remark || '-')
        }

        for (let i = 0; i < valueArray.length; i++) {
            let v = {
                key: i + '',
                title: titleArray[i],
                value: valueArray[i]
            }
            dataSource.push(v)
        }
        console.log(zoneDetail)
        return (
            <div>
                <div className='space-bw' style={{ marginBottom: '8px' }}>
                    <div className='float-title'>{this.props.zoneVosData.zoneNo}</div>
                    <div style={{lineHeight: '20px'}} className='border-no float-tag'>{getBizStatus(this.props.zoneVosData.bizStatus)}</div>
                </div>
                <div style={{ display: this.state.showHighlightQk ? 'inline' : 'none', fontSize: '12px', marginTop: '5px', marginBottom: '5px' }}>
                    <div className='flex-row'>
                        <Icon type="warning" theme="filled" style={{ color: '#E94437' }} />
                        <div style={{ color: '#E94437', paddingLeft: '5px', paddingRight: '5px' }}>
                            该区域已欠款{zoneDetail && zoneDetail.overdueDay}天
                    </div>
                        <a href="javascript:;" className='underline' style={{ fontSize: '12px', color: '#FFFFFF' }} onClick={() => this.viewDebt()}>查看</a>
                    </div>
                </div>
                <div className={userUtil.hasAuth(AUTH_KEYS.PROJECT_REGION_INFO) ? 'flex-row-init' : 'flex-row-init no-left-border'}>
                    <AuthContainer key="1" authCodes={AUTH_KEYS.PROJECT_REGION_INFO}>
                    <Table rowKey="key" className='float-info-detail' columns={columns} dataSource={dataSource} pagination={false} />
                    </AuthContainer>
                    <AuthContainer key="2" authCodes={AUTH_KEYS.PROJECT_REGION_OPERATE}>
                    <CapitalFloatMenu
                        type={type} history={this.props.history} doSearch={this.props.doSearch}
                        zoneVosData={this.props.zoneVosData} zoneVos={this.props.zoneVos} zoneDetail={zoneDetail}
                        handlerBackRefresh={this.handlerBackRefresh.bind(this)}
                        handlerBackMenuClick={this.handlerBackMenuClick.bind(this)} />
                    </AuthContainer>
                </div>
            </div>
        );
    }
}

// 右边操作菜单
const defaultShow = {
    isDecorateShow: false,
    isRentStateShow: false,
    isEditAreaShow: false,
    isSplitAreaShow: false,
    isCombineAreaShow: false
};

class CapitalFloatMenu extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            menu: '',
            isShow: defaultShow
        }
    }

    ItemMenuClick(item) {
        const details = this.props.zoneDetail;
        switch (item) {
            case MENUS.办理签约:
                this.props.history.push({ pathname: '/app/operate/contract/add', state: { projectEncode: details.projectEncode, floorEncode: details.floorEncode, encode: details.encode } })
                break;
            case MENUS.添加意向客户:
                this.props.history.push({ pathname: '/app/operate/invitation/intention/add', search: `?projectEncode=${details.projectEncode}&floorEncode=${details.floorEncode}&encode=${details.encode}` })
                break;
            case MENUS.查看合同:
                this.props.history.push(`/app/operate/contract/detail/${details.contractEncode}`)
                break;
            case MENUS.装修管理:
                this.setState({
                    isShow: {
                        defaultShow,
                        isDecorateShow: true
                    }
                })
                break;
            case MENUS.办理续签:
                this.props.history.push({ pathname: '/app/operate/contract/add', search: `?code=${details.contractEncode}&type=2`, state: { projectEncode: details.projectEncode, floorEncode: details.floorEncode, encode: details.encode } })
                break;
            case MENUS.办理退租:
                this.props.history.push({ pathname: `/app/operate/contract/detail/${details.contractEncode}`, search: `?action=1&audit=0` })
                break;
            // case MENUS.查看历史合同:
            //     this.props.history.push('?')
            //     break;
            case MENUS.变更出租状态:
                this.setState({
                    isShow: {
                        defaultShow,
                        isRentStateShow: true
                    }
                })
                break;
            case MENUS.编辑区域:
                this.setState({
                    isShow: {
                        defaultShow,
                        isEditAreaShow: true
                    }
                })
                break;
            case MENUS.分割区域:
                this.setState({
                    isShow: {
                        defaultShow,
                        isSplitAreaShow: true
                    }
                })
                break;
            case MENUS.合并区域:
                this.setState({
                    isShow: {
                        defaultShow,
                        isCombineAreaShow: true
                    }
                })
                break;
        }
        this.setState({
            menu: item
        })
        this.props.handlerBackMenuClick(item)
    }

    PopView() {
        let v = ''
        switch (this.state.menu) {
            case MENUS.装修管理:
                v = this.DecoratePop()
                break;
            case MENUS.变更出租状态:
                v = this.RentStatePop()
                break;
            case MENUS.编辑区域:
                v = this.EditAreaPop()
                break;
            case MENUS.分割区域:
                v = this.SplitAreaPop()
                break;
            case MENUS.合并区域:
                v = this.CombineAreaPop()
                break;
        }
        return v;
    }

    // ---------START---------
    // ---------装修管理---------
    DecorateCancel = () => {
        this.setState({
            isShow: {
                isDecorateShow: false
            }
        })
    }

    DecorateOk = () => {
        this.decorateChild.doAddUpdate();
    }

    onDecorateRef = (ref) => {
        this.decorateChild = ref;
    }

    handlerBackDecorate() {
        this.DecorateCancel()
    }

    DecoratePop() {
        return <Modal
            visible={this.state.isShow.isDecorateShow}
            centered
            destroyOnClose
            closable={false}
            maskClosable={false}
            width='1000px'
            onCancel={this.DecorateCancel}
            onOk={this.DecorateOk}>
            <DecoratePopView zoneDetail={this.props.zoneDetail} doSearch={this.props.doSearch} onRef={this.onDecorateRef} handlerBackDecorate={this.handlerBackDecorate.bind(this)} />
        </Modal>
    }

    // ---------变更出租状态---------
    RentStateCancel = () => {
        this.setState({
            isShow: {
                isRentStateShow: false
            }
        })
    }

    RentStateOk = () => {
        this.rentStateChild.doChangeRentState();
    }

    onRentStateRef = (ref) => {
        this.rentStateChild = ref;
    }

    handlerStateChanged = () => {
        this.RentStateCancel();
        this.props.handlerBackRefresh();
    }

    RentStatePop() {
        return <Modal
            visible={this.state.isShow.isRentStateShow}
            centered
            destroyOnClose
            closable={false}
            maskClosable={false}
            width={400}
            onCancel={this.RentStateCancel}
            onOk={this.RentStateOk}>
            <RentStatePopView zoneDetail={this.props.zoneDetail} onRef={this.onRentStateRef} handlerBackRentState={this.handlerStateChanged.bind(this)} />
        </Modal>
    }

    // ---------编辑区域---------
    EditAreaHide = () => {
        this.setState({
            isShow: {
                isEditAreaShow: false
            }
        })
    }

    handlerBackEditAreaDelete() {
        this.EditAreaHide()
        this.props.handlerBackRefresh()
    }

    handlerBackEditAreaClick(type) {
        switch (type) {
            case 'ok':
                this.props.handlerBackRefresh();
                this.EditAreaHide()
                break;
            case 'cancel':
                this.EditAreaHide()
                break
        }
    }

    EditAreaPop() {
        return <Modal
            visible={this.state.isShow.isEditAreaShow}
            centered
            destroyOnClose
            closable={false}
            maskClosable={false}
            width='640px'
            footer={null}>
            <EditAreaPopView zoneDetail={this.props.zoneDetail}
                handlerBackEditAreaDelete={this.handlerBackEditAreaDelete.bind(this)}
                handlerBackEditAreaClick={this.handlerBackEditAreaClick.bind(this)} />
        </Modal>
    }

    SplitAreaCancel = () => {
        this.setState({
            isShow: {
                isSplitAreaShow: false
            }
        })
    }

    SplitAreaOk = () => {
        this.setState({
            isShow: {
                isSplitAreaShow: false
            }
        })
    }

    handlerBackSplitAreaClick(type) {
        switch (type) {
            case 'ok':
                this.props.handlerBackRefresh();
                this.SplitAreaOk()
                break;
            case 'cancel':
                this.SplitAreaCancel()
                break
        }
    }

    SplitAreaPop() {
        return <Modal
            visible={this.state.isShow.isSplitAreaShow}
            centered
            destroyOnClose
            closable={false}
            maskClosable={false}
            width='1184px'
            footer={null}>
            <SplitAreaPopView data='editArea' zoneVosData={this.props.zoneVosData} handlerBackSplitAreaClick={this.handlerBackSplitAreaClick.bind(this)} />
        </Modal>
    }

    CombineAreaCancel = () => {
        this.setState({
            isShow: {
                isCombineAreaShow: false
            }
        })
    }

    CombineAreaOk = () => {
        this.setState({
            isShow: {
                isCombineAreaShow: false
            }
        })
    }

    handlerBackCombineAreaClick(type) {
        switch (type) {
            case 'ok':
                this.props.handlerBackRefresh();
                this.CombineAreaOk()
                break;
            case 'cancel':
                this.CombineAreaCancel()
                break
        }
    }

    CombineAreaPop() {
        return <Modal
            visible={this.state.isShow.isCombineAreaShow}
            centered
            destroyOnClose
            closable={false}
            maskClosable={false}
            width='728px'
            footer={null}>
            <CombineAreaPopView data='editArea' zoneVosData={this.props.zoneVosData} zoneVos={this.props.zoneVos} handlerBackCombineAreaClick={this.handlerBackCombineAreaClick.bind(this)} />
        </Modal>
    }
    // ---------END---------

    render() {
        let array = []
        switch (this.props.type) {
            case STATUS_TYPE.NOT_RENT_NO_DEBT:       //未出租-无未结账务
                array = [MENUS.办理签约, MENUS.添加意向客户, MENUS.变更出租状态, MENUS.编辑区域, MENUS.分割区域, MENUS.合并区域]
                break;
            case STATUS_TYPE.NOT_RENT_DEBT:          //未出租-有未结账务
                array = [MENUS.办理签约, MENUS.添加意向客户, MENUS.变更出租状态, MENUS.编辑区域]
                break;
            case STATUS_TYPE.RENTED_NO_CONTRACT: //已出租-无合同
                array = [MENUS.办理签约, MENUS.变更出租状态, MENUS.编辑区域]
                break;
            case STATUS_TYPE.RENTED_CONTRACT:    //已出租-有合同
                array = [MENUS.查看合同, MENUS.装修管理, MENUS.办理续签, MENUS.办理退租, MENUS.编辑区域]
                break;
            case STATUS_TYPE.RENTED_DECORATING:  //已出租-装修中
                array = [MENUS.查看合同, MENUS.装修管理, MENUS.办理续签, MENUS.办理退租, MENUS.编辑区域]
                break;
            case STATUS_TYPE.FORBID:        //不可租
                array = [MENUS.编辑区域]
                break;
        }

        return (
            <div>
               
                <List
                    className='float-menu'
                    size='small'
                    split={false}
                    dataSource={array}
                    renderItem={item => (
                        <List.Item key='1'>
                            <a href="javascript:;" style={{ fontSize: '12px', color: '#FBBC06' }} onClick={this.ItemMenuClick.bind(this, item)}>{item}</a>
                        </List.Item>)}>
                    {this.PopView()}
                </List>
            </div>
        );
    }
}

export {
    CapitalFloatInfo
}