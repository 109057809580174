import React, { Component } from 'react';
import { Modal } from 'antd';
import http from '@/http';
import { API } from '@/http/api';

class DeleteSupperlier extends Component {
    state = { 
        visible: false,
        supplierId: ''
     }
    componentWillReceiveProps(nextProps){       
        this.setState({
            visible: nextProps.visible,
            supplierId: nextProps.supplierId,
            name: nextProps.name
        })
    }
    componentDidMount(){
        // this.setState({
        //     visible: true
        // })
        
    }
    handleOk = (e) => {
        const config = {
            api: API.supplierDelete,
            path: {encode: this.state.supplierId},
            successTip: '删除成功',
            showLoading: true
        }
        http(config).then(res => {    
            if(res.success) {
                this.setState({
                    visible: false,
                },() => {
                    this.props.getNewLists();
                });
            }            
        })
       
    }
    handleCancel = (e) => {    
        this.setState({
            visible: false,
        });
    }
    render() {
        return (
            <div>
                <Modal
                    closable={false}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    okText='确定'
                    width={400}
                    onCancel={this.handleCancel}
                >
                    <p>确认删除 “{this.state.name}”？</p>
                </Modal>
            </div>
        );
    }

}

export default DeleteSupperlier