import React, { Component } from "react";
import { Layout, Breadcrumb, Button, Form, Modal, Input } from "antd";
import { Link } from "react-router-dom";
import { API } from "@/http/api";
import http from "@/http";
import Editor from "CMPT/common/widget/Editor";
import './RuleAddEdit.less';

const { Content } = Layout;

class RuleAddEdit extends React.Component {
    state = {
        encode: undefined, // 编辑时需要 该问题的encode. 新增不需要
        catalogCode: undefined,
        catalogName: undefined,
        htmlContent: undefined,

        isShowPublishModal: false,

        isPublishLoading:false,
        isSaveLoading:false,
        inputLength: 0
    };


    componentDidMount() {
        // 如果是编辑，encode会有值
        let encodeParmam = undefined;
        let categoryCodeParm = undefined;
        if (this.props.match.params) {
            if (this.props.match.params.code) {
                encodeParmam = this.props.match.params.code;
            }
            categoryCodeParm = this.props.match.params.categoryCode;
        }

        // 目录categoryCodeParm不为空，但encodeParmam 可能为空
        if (categoryCodeParm) {
            this.setState({
                categoryCode: categoryCodeParm,
                encode: encodeParmam // 新增为undefined. 编辑时 才会有值
            }, this.getCatalogDetail);
        }

    }

    getCatalogDetail() {
        const config = {
            api: API.ruleCategoryGetInfo,
            data: {
                code: this.state.categoryCode
            }
        };

        // query 
        http(config).then(res => {
            if (res && res.success) {
                this.setState({
                    catalogName: res.data.title
                }, this.queryRuleDetail);
            }
        });
    }

    addSubmit(values, status) {
        let dataParm = {
            ...values,
            status
        };
        dataParm.categoryCode = this.state.categoryCode;

        const config = {
            api: API.ruleCategoryRuleAdd,
            successTip: "添加成功！",
            data: dataParm,
            showLoading: true
        };
        http(config).then(res => {

            if (res && res.success) {
                this.goBack();
            }else{
                this.setState({isSaveLoading:false,isPublishLoading:false});
            }
        });
    }

    undateSubmit(values, statusValue) {
        let dataParm = {
            ...values,
            status: statusValue
        };
        dataParm.categoryCode = this.state.categoryCode;
        dataParm.code = this.state.encode;

        const config = {
            api: API.ruleCategoryRuleEdit,
            successTip: "编辑成功！",
            data: dataParm,
            showLoading: true
        };

        http(config).then(res => {
            if (res&&res.success) {
                this.goBack();
            }else{
                this.setState({isSaveLoading:false,isPublishLoading:false});
            }
        });
    }

    handleSubmit = (status) => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                if (this.state.encode) {
                    // this.state.encode有值，表示修改
                    this.undateSubmit(values, status);
                } else {
                    this.addSubmit(values, status);
                }
            }
        });
    };

    queryRuleDetail=() =>{
        if (!this.state.encode) {
            // 新增时,encode为空
            return;
        }

        // make config 
        const config = {
            api: API.ruleCategoryRuleDetail,
            data: {
                code: this.state.encode
            }
        };

        // query 
        http(config).then(res => {
            if (res && res.success) {
                this.props.form.setFieldsValue({
                    title: res.data.title,
                    content: res.data.content,
                });
                this.setState({
                    htmlContent: res.data.content,
                    inputLength: res.data.title.length
                });
            }
        });
    }


    // 富文本的 内容校验
    checkMessageContent = (rule, value) => {
        let fieldsObject = this.props.form.getFieldsValue();
        if (fieldsObject.content) {
            return Promise.resolve();
        }
        return Promise.reject("请填写问题内容");
    };

    goBack = () => {
        this.props.history.go(-1);
    }

    // 发起 保存
    save = () => {
        this.setState({isSaveLoading:true},this.handleSubmit(1));// 1表示未发布，也就是草稿
    }

    trySave = () => {
        this.props.form.validateFields((error, values) => {
            if (error) {
                return;
            }else{
                this.save();
            }
        });
    }

    doPublish = () => {
         this.setState({isPublishLoading:true},this.handleSubmit(2));     // 状态，1:未发布2：已发布
    }

    // 打开 发布的确认对话框
    openPublishModal = () => {
        this.setState({
            isShowPublishModal: true
        });
    }

    // 尝试去 打开 发布的确认对话框
    tryOpenPublishModal = () => {
        this.props.form.validateFields((error, values) => {
            if (error) {
                return;
            }else{
                this.openPublishModal();
            }
        });
    }

   // 关闭 发布的确认对话框
    closePublishModal = () => {
        this.setState({
            isShowPublishModal: false
        });
    }

 // 创建 发布的确认对话框
    createPublishModal = () => {
        return <Modal
            title="发布"
            visible={true}
            onOk={  this.doPublish }
            confirmLoading={this.state.isPublishLoading}
            onCancel={this.closePublishModal}>
            <p>确认发布规则内容吗？</p>
        </Modal>;
    }

    inputChange = (e) => {
        this.setState({
            inputLength: e.target.value.length
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { catalogName, inputLength } = this.state;
        return (
            <Content className="intention-list intention-content">
                <div className="intention-list-head flexbox">
                    <Breadcrumb className="intention-list-head-bread">
                        <Breadcrumb.Item  onClick={()=>{this.props.history.replace('/app/crm/rulecenter')}}>规则中心</Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <span   onClick={this.goBack}>规则列表</span>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {this.state.encode ? "编辑内容" : "新增内容"}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <Form  >
                    <div className="intention-add-body">
                        <div><span>{"所属目录:   " + (catalogName ? catalogName : '')}</span></div>
                        <div>
                            <Form.Item label="标题">
                                {getFieldDecorator("title", {
                                    rules: [{ required: true, max: 50, message: "不得超过50字" }]
                                })(
                                    <Input
                                        style={{ width: "300px", paddingRight: '50px' }}
                                        maxLength={50}
                                        placeholder="请输入"
                                        onChange={this.inputChange}
                                    />
                                )}
                                <span className="input_span">{inputLength}/50</span>
                            </Form.Item>

                            <Form.Item label="规则内容">
                                {getFieldDecorator("content", {
                                    rules: [{ required: true, validator: this.checkMessageContent }]
                                })(
                                    <Editor default={this.state.htmlContent}
                                        key={this.state.htmlContent}
                                    />
                                )}
                            </Form.Item>


                        </div>
                    </div>
                    <div className="btn-group">
                        <Button type="primary" onClick={() => this.tryOpenPublishModal()}> {this.state.encode?'发布':'直接发布'} </Button>
                        <Button type="primary"   loading={this.state.isSaveLoading} onClick={() => this.trySave()}> 保存草稿 </Button>
                        <Button type="link" onClick={() => this.goBack()} > 取消  </Button>
                    </div>
                </Form>
                {this.state.isShowPublishModal && this.createPublishModal()  }
            </Content>
        );
    }
}


const RuleAddEditForm = Form.create({ name: "rule_form" })(RuleAddEdit);
export default RuleAddEditForm;