import http from '@/http';
import { API } from '@/http/api';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, message,   Descriptions  } from 'antd';
import './index.less';
import { formatLinkSearch } from "@/utils";
import moment from "moment";
//import AcceptDialog from "./AcceptDialog"
//import Attachment from 'CMPT/common/Attachment';

const BreadcrumbItem = Breadcrumb.Item;

class Detail extends Component {

    state = {
        acceptObj: undefined,//要受理的对象

        records: undefined,//受理记录

        data: undefined,// 咨询数据本身
        code: undefined, // 查询详情需要的code
        tabInList: undefined,//返回列表需要的参数
        pageNoInList: undefined//返货列表需要的参数
    };

    componentDidMount() {
        let code = this.props.match.params.code;

        
        const formatData = formatLinkSearch(this.props.location.search);//解析 附加参数。方便 列表 详情 页面之间的跳转
        this.setState({ code, tabInList: formatData.tab, pageNoInList: formatData.pageNo }, this.init);
    }


    init = () => {
        this.getDetail();
    }


    getDetail = () => {
        // setup 1:基本入参
        const config = {
            api: API.invoiceDetail,
            path: { code: this.state.code },
            showLoading: true
        };


        // setup 2:请求，并初步处理
        http(config).then(res => {
            if (res && res.code == 200 && res.data) {
                this.setState({ data: res.data });
            } else {
                message.error(res.message || '详情查询失败');
            }
        });
    }


    render() {
        let { data, acceptObj, records, previewVisible, currentIndex, imgList } = this.state;
        return (<div className='container'>
            <Breadcrumb>
                <BreadcrumbItem>
                    <Link
                        to={{
                            pathname: "/app/crm/account_open"
                        }}
                    >
                        开户管理
                    </Link>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <Link
                        to={{
                            pathname: "/app/crm/account_open",
                            search: "?tab=2&pageNo=" + this.state.pageNoInList,
                        }}
                    >
                        网货开户管理
                    </Link>
                </BreadcrumbItem>
                <BreadcrumbItem> 网货用户详情  </BreadcrumbItem>
            </Breadcrumb>

            {!data ? '' : <div className='content_box'>
 
                { (data.threePartAuditStatus==3&&data.remark) ?<div class="reject"  >
                    <span class="reason">{'驳回原因：'+data.remark}</span>
                  </div>:''}
                <div className="content3" >
                    <Descriptions bordered column={3} title="基本信息" className="table-part">
                        <Descriptions.Item label="申请人"  >{data.applyUserName || '-'}</Descriptions.Item>
                        <Descriptions.Item label="联系方式"  >{data.applyUserPhone || '-'}</Descriptions.Item>
                        <Descriptions.Item label="申请提交时间"  >{data.applyTime ? moment(data.applyTime).format("YYYY-MM-DD HH:mm") : '-'}</Descriptions.Item>


                        <Descriptions.Item label="网货平台"  >{data.platformName || '-'}</Descriptions.Item>

                         <Descriptions.Item label="网货审批状态"  >{data.threePartAuditStatusName || '-'}</Descriptions.Item>
                    </Descriptions>
                </div>

                <div className="content3" >
                    <Descriptions bordered title="企业信息" column={3} className="table-part">
                        <Descriptions.Item label="企业名称"  >{data.supplierName || '-'}</Descriptions.Item>
                        <Descriptions.Item label="统一社会信用代码"  >{data.unifiedSocialCode || '-'}</Descriptions.Item>
                        <Descriptions.Item label="企业地址"  >{data.companyAddress || '-'}</Descriptions.Item>


                        <Descriptions.Item label="营业执照日期"  >{data.registerValidLongTerm ? "长期":(data.registerValidFrom ?moment(data.registerValidFrom).format("YYYY-MM-DD"): '-') + "~" + (data.registerValidTo?moment(data.registerValidTo).format("YYYY-MM-DD"):'-') }</Descriptions.Item>

                        <Descriptions.Item label="所属行业"  >{(data.industryName || '-')}</Descriptions.Item>
                        <Descriptions.Item label="联系人"  >{  (data.contactName || '')+(data.contactName?'/':'')+(data.contactPhone || '-')}</Descriptions.Item>

                        <Descriptions.Item label="法人姓名"  >{   data.legalManName}</Descriptions.Item>
                        <Descriptions.Item label="法人身份证号"  >{  data.legalIdCardNumber || '-'}</Descriptions.Item>
                        <Descriptions.Item label="身份证日期"  >{(data.idCardValidityStartTime ?moment(data.idCardValidityStartTime).format("YYYY-MM-DD"): '-') + "~" +  (data.idCardValidityEndTime?moment(data.idCardValidityEndTime).format("YYYY-MM-DD"):'-')}</Descriptions.Item>
                    </Descriptions>
                </div>

                <div className="content3" >
                    <Descriptions bordered title="企业信息" column={3} className="table-part">
                        <Descriptions.Item label="营业执照图片"  >{ data.registerCard ?  <img style={{ width: '120px', height: '90px', marginLeft: '10px', marginRight: '10px' }} src={data.registerCard} />:<span>-</span> }</Descriptions.Item>
                        <Descriptions.Item label="身份证正面照"  >{ data.frontIdCard ?  <img style={{ width: '120px', height: '90px', marginLeft: '10px', marginRight: '10px' }} src={data.frontIdCard} />:<span>-</span> }</Descriptions.Item>
                        <Descriptions.Item label="身份证反面照"  >{ data.backIdCard ?  <img style={{ width: '120px', height: '90px', marginLeft: '10px', marginRight: '10px' }} src={data.backIdCard} />:<span>-</span> }</Descriptions.Item>



                    </Descriptions>
                </div>


            </div>}
        </div>);
    }

}


export default Detail;