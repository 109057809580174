import {
    AJAX_TYPE
} from '../config';

// 项目报表

const prefix = 'statementproject';

export default {
    // 下载报表
    [`${prefix}Export`]: {
        url: '/statement/project/export',
        type: AJAX_TYPE.POST
    },
    // 根据条件查询
    [`${prefix}Get`]: {
        url: '/statement/project/get',
        type: AJAX_TYPE.POST
    },
}