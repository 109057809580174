import React, { Component } from "react";
import { Breadcrumb, Button, Input, InputNumber, Modal, Switch } from "antd";
import { Link } from "react-router-dom";
import Select from "@/components/common/Select";
import Table from "CMPT/common/Table";
import moment from "moment";
import { API } from "@/http/api";
import {
  HelpChannel,
  convertEnumToMap,
  convertValueToEnumLabel
} from "CMPT/common.js";
import http from "@/http";
import "./Video.less";
import userUtils from '@/utils/user';
import { AUTH_KEYS } from "@/utils/auth";
const { checkAuthByKey } = userUtils;
const canShowAddAuth = true;//checkAuthByKey(AUTH_KEYS.HELP_CENTER_MANAGE_QUESTION_MANAGE_CREATE); // 编辑权限；
const canShowbianjiAuth = true;//checkAuthByKey(AUTH_KEYS.HELP_CENTER_MANAGE_QUESTION_MANAGE_EDIT); // 编辑权限；
const canShowDeleteAuth = true;//checkAuthByKey(AUTH_KEYS.HELP_CENTER_MANAGE_QUESTION_MANAGE_DELETE); // 删除权限；

const BreadcrumbItem = Breadcrumb.Item;
const InputSearch = Input.Search;

class Issue extends Component {
  state = {
    isPreview: null,
    htmlDetail: null,

    hotMap: [
      /*{ value: '', name: '全部' },*/ { value: 1, name: "是热点" },
      { value: 0, name: "非热点" }
    ],
    selectHot: "",

    encode: "",
    visible: false,
    catalogCode: undefined,
    messageTitle: undefined,
    channel: null,
    catalogList: [],
    selfProcessData: null,
    tableConfig: {
      rowKey: "id",
      columns: [
        {
          title: "序号",
          key: "id",
          // width: 60,
          render: (text, record, index) => {
            return <span>{index + 1}</span>;
          }
        },
        {
          title: "标题",
          dataIndex: "messageTitle",
          render: (text, record) => {
            return record.topFlag === 1 ? (
              <span>
                <span className="stick">顶</span>
                <span>{text}</span>
              </span>
            ) : (
                <span>{text}</span>
              );
          },
          // width: "200"
        },
        {
          title: "类目",
          dataIndex: "catalogName",
          width: "100",
          render: status => (
            <span>
              {status}
              {/* {this.state.intentionStatusConfigArray.filter(item => item.value == status)[0]['name']} */}
            </span>
          )
        },
        {
          title: "渠道",
          dataIndex: "channel",
          // width: "20",
          render: (text, record) => (
            <span>
              {text ? convertValueToEnumLabel(text.split(","), HelpChannel) : ''}
            </span>
          )
        },
        {
          title: "是否热点",
          dataIndex: "isHot",
          // width: "20",
          render: (text, record, index) => (
            <Switch
              key={record.code}
              checkedChildren="开"
              unCheckedChildren="关"
              checked={record.isHot == 1}
              onChange={this.onHotChange.bind(this, record)}
            />
          )
        },
        {
          title: "浏览量",
          dataIndex: "seeCount",
          // width: "20"
        },
        {
          title: "反馈量",
          dataIndex: "feedbackCount",

          // width: "20"
        },
        {
          title: "正反馈",
          dataIndex: "feedbackCountPlus",
          // width: "20"
        },
        {
          title: "负反馈",
          dataIndex: "feedbackCountMinus",
          // width: 20,
          render: (text, record) => (
            <span>
              <Link
                to={
                  "/app/crm/helphandle/issue/feedback" +
                  "?code=" +
                  record.code +
                  "&type=" +
                  this.props.type +
                  "&title=" +
                  record.messageTitle
                }
                className="table-operate-btn"
              >
                {text}
              </Link>
            </span>
          )
        },
        {
          title: "创建时间",
          dataIndex: "createTime",
          // width: "120",
          render: projectVo => {
            return <span>{moment(projectVo).format("YYYY-MM-DD HH:mm")}</span>;
          }
        },

        {
          title: "更新时间",
          dataIndex: "modifiedTime",
          // width: "120",
          render: (sort, record) => (
            <span>
              {moment(record.modifiedTime || record.createTime).format(
                "YYYY-MM-DD HH:mm"
              )}
            </span>
          )
        },

        {
          title: "操作",
          // width: 160,
          render: (record, index) => (
            <div>
              <span
                className="text-color-highlight"
                style={{ marginRight: "16px" }}
                onClick={() => {
                  this.preivew(record);
                }}
              >
                预览
              </span>
              {
                canShowbianjiAuth ?
                <span
                  className="text-color-highlight"
                  style={{ marginRight: "16px" }}
                  onClick={() => {
                    this.edit(record);
                  }}
                >
                  编辑
                </span>
                : null 
              }
              {
                canShowDeleteAuth ?
                <span
                  className="text-color-highlight"
                  onClick={() => {
                    this.videoDel(record);
                  }}
                >
                  删除
                </span> : null
              }
            </div>
          )
        }
      ],
      current: 1,
      total: 10,
      pageSize: 10,
      // scroll: {x: 900}
    }
  };
  edit(record) {
    this.props.history.push(
      "/app/crm/helphandle/issue/editor/" +
      record.code +
      "?catalogType=" +
      this.props.type
    );
  }

  preivew(record) {
    this.setState({
      isPreview: true,
      htmlDetail: record.messageContents
    });
  }

  onHotChange = (record, checked) => {
    const config = {
      api: API.helpUpdate,
      data: {
        isHot: checked ? 1 : 0,
        encode: record.code
      }
    };
    http(config).then(res => {
      if (res.success) {
        this.getData();
        // record.isHot = (checked ? 1 : 0);
      }
    });
  };

  videoDel = record => {
    this.setState({
      visible: true,
      encode: record.code
    });
  };
  deleteRole = () => {
    const config = {
      api: API.helpIssueDel,
      showLoading: true,
      successTip: "删除成功！",
      data: {
        encode: this.state.encode
      }
    };
    http(config).then(res => {
      if (res.success) {
        this.handleCancel();
        this.getData();
      }
    });
  };
  handleCancel = () => {
    this.setState({
      visible: false
    });
  };

  sortNewData(data) {
    return data.map((item, index) => {
      item.sortIndex = index;
      item.sortShow = false;
      item.messageContents = item.messageContents?item.messageContents.rollbackXss():'';
      return item;
    });
  }

  getData = () => {
    const config = {
      api: API.helpIssueList,
      showLoading: true,
      data: {
        catalogType: this.props.type,
        pageSize: this.state.tableConfig.pageSize,
        pageNo: this.state.tableConfig.current,
        catalogCode: this.state.catalogCode,
        isHot: this.state.selectHot,
        messageTitle: this.state.messageTitle,
        channel: this.state.channel
      }
    };
    http(config).then(res => {
      if (res&&res.success) {
        let { tableConfig } = this.state;
        tableConfig.total = res.data.totalRecord;
        this.setState({
          selfProcessData: this.sortNewData(res.data.datas),
          tableConfig: tableConfig
        });
      }
    });
  };
  getCatalogList() {
    const config = {
      api: API.helpCatalogList,
      data: {
        catalogType: this.props.type
      }
    };
    http(config).then(res => {
      if (res.success) {
        let data = res.data.map(item => {
          item.name = item.catalogName;
          item.value = item.id;
          return item;
        });
        this.setState({
          catalogList: data
        });
      }
    });
  }

  componentDidMount() {
    this.getData();
    this.getCatalogList();
  }

  sort = record => {
    this.isSortShow(record);
  };
  isSortShow(record) {
    const { selfProcessData } = this.state;
    selfProcessData[record.sortIndex].sortShow = !selfProcessData[
      record.sortIndex
    ].sortShow;
    this.setState({
      selfProcessData
    });
  }

  sortBlur(record) {
    this.isSortShow(record);
    const config = {
      api: API.helpIssueSort,
      data: {
        sort: record.sort,
        encode: record.id
      }
    };
    http(config).then(res => {
      if (res.success) {
        this.getData();
      }
    });
    // 掉排序的接口
  }
  onchangInput = (e, index) => {
    const { selfProcessData } = this.state;
    selfProcessData[index].sort = e;
    this.setState({
      selfProcessData
    });
  };

  onChanges = (page, pageSize, sort) => {
    let { tableConfig } = this.state;
    tableConfig.current = page;
    tableConfig.pageSize = pageSize;
    this.setState(
      {
        tableConfig
      },
      () => {
        this.getData();
      }
    );
  };

  setKeywords = e => {
    this.setState(
      {
        messageTitle: e.target.value
      },
      () => this.goSearch()
    );
  };

  handleChangeCatalog = e => {
    this.setState(
      {
        catalogCode: e
      },
      () => this.goSearch()
    );
  };

  handleChangeHot = e => {
    this.setState(
      {
        selectHot: e
      },
      () => this.goSearch()
    );
  };

  goSearch = () => {
    this.getData();
  };

  reset = () => {
    this.setState(
      {
        catalogCode: undefined,
        messageTitle: undefined,
        selectHot: undefined,
        channel: undefined
      },
      () => {
        this.getData();
      }
    );
  };

  onChannelChange = value => {
    if (!value) {
      return;
    }

    this.setState(
      {
        channel: value
      },
      () => this.getData()
    );
  };
  render() {
    const { channel } = this.state;
    return (
      <div className="contract-list-out">
        <Modal
          title="确定是否删除？"
          visible={this.state.visible}
          okText="删除"
          maskClosable={false}
          width={400}
          onOk={this.deleteRole}
          onCancel={this.handleCancel}
          className="assign-follow-modal-del"
        ></Modal>

        <div className="contract-content">
          <div className="intention-body">
            <div
              className="intention-form"
              layout="inline"
              style={{ marginBottom: "30px" }}
            >
              <div>
                <span>
                  <span style={{ marginRight: "5px" }}>问题类目</span>
                  <Select
                    style={{ width: "150px" }}
                    placeholder="全部目录"
                    value={this.state.catalogCode}
                    data={this.state.catalogList}
                    selectMethod={this.handleChangeCatalog}
                  />
                </span>
              </div>
              <div>
                <span>
                  <span style={{ marginRight: "5px" }}>渠道</span>
                  <Select
                    style={{ width: "120px", marginRight: "12px" }}
                    data={convertEnumToMap(HelpChannel)}
                    value={channel}
                    allowClear
                    placeholder="渠道"
                    onChange={this.onChannelChange}
                  />
                </span>
              </div>

              <div>
                <span>
                  <span style={{ marginRight: "5px" }}>热点</span>
                  <Select
                    style={{ width: "80px" }}
                    placeholder="热点"
                    value={this.state.selectHot}
                    data={this.state.hotMap}
                    selectMethod={this.handleChangeHot}
                  />
                </span>
              </div>
              <div>
                <InputSearch
                  placeholder="请输入问题关键词"
                  onChange={this.setKeywords}
                  onSearch={() => this.goSearch()}
                  value={this.state.messageTitle}
                  style={{ width: "280px" }}
                  maxLength={50}
                />
              </div>

              <div>
                <span
                  style={{
                    cursor: "pointer",
                    fontSize: "12px",
                    marginRight: "20px",
                    textDecoration: "underline",
                    textUnderlinePosition: "under",
                    border: "none",
                    color: "#333",
                    lineHeight: "32px"
                  }}
                  type="link"
                  onClick={this.reset}
                >
                  重置
                </span>
              </div>
              {
                canShowAddAuth ?
                <Button
                  style={{ marginLeft: "20px" }}
                  type="primary"
                  onClick={() => {
                    this.props.history.push(
                      "/app/crm/helphandle/issue/add?catalogType=" + this.props.type
                    );
                  }}
                >
                  新增
                </Button>
                : null
              }
            </div>
            <div className="intention-table-contents">
              {this.state.selfProcessData ? (
                <Table
                  rowKey="code"
                  scroll={{ x: "max-content" }}
                  change={this.onChanges}
                  {...this.state.tableConfig}
                  selfProcessData={this.state.selfProcessData}
                />
              ) : null}
            </div>
          </div>
        </div>

        {/* {
                    this.state.isPreview && this.state.htmlDetail ?
                        <div className="big_img" >

                            <div> <div className="header">  <span className="btn-close" onClick={() => { this.setState({ isPreview: null, htmlDetail: null }) }} >关闭</span></div>
                               
                            </div>
               </div> : ''} */}

        {this.state.isPreview && this.state.htmlDetail ? (
          <Modal
            title="预览"
            visible={true}
            className="assign-follow-modal"
            footer={null}
            width={1000}
            height={1000}
            onCancel={() => {
              this.setState({ isPreview: null, htmlDetail: null });
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: this.state.htmlDetail }} />
          </Modal>
        ) : (
            ""
          )}
      </div>
    );
  }
}

export default Issue;
