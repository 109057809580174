import Table from 'CMPT/common/Table';
import http from '@/http';
import { API } from '@/http/api';
import { formatLinkSearch } from '@/utils'
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Radio, Input, Modal, Tooltip, message, Cascader } from 'antd';
import { AUTH_KEYS } from '@/utils/auth';
import './GoodList.less';
import { GoodsChannel, PricingType,PricingSwitch } from "CMPT/common.js";

import moment from 'moment';
const { Search, TextArea } = Input;



class GoodsList extends Component {
    state = {
        operation: [{
            key: 1,
            name: '驳回'
        },{
            key: 2,
            name: '通过'
        },{
            key: 3,
            name: '强制下架'
        },{
            key: 4,
            name: '价格模式设置'
        },{
            key: 5,
            name: '修改类目'
        }],
        radioVal: null,
        auditInfo: null,
        key: null,
        isShow: false,
        selectedRowKeys: [],
        selectRowObj: [],
        editPriceSwitchObj: undefined,//编辑价格模式开关的对象
        newPriceSwitchValue: undefined,// 存储下最新的价格模式，没有变化时就不用调用api

        searchValue: '', // 搜索框内容

        total: 0, // 当前数据的总数
        currentPageCount: 0, // 当前页的数量
        pageNo: 1, // 当前所展示的页码

        selectParams: {  // 接口入参，必须放这里。方便分页时保存信息
            authenticationStatus: this.props.state,// 审批状态
            pageNo: 1, // 需要查询的页数
            pageSize: 10, // 每页条数
        },
        columns: [
            // {
            //     title:'序号',
            //     key: "code",//(text,record,index)=>index,
            //     render:(text,record,index)=>{
            //            return ( <span>{index+1}</span>);
            //     },
            // },
            {
                title: '商品编号',
                dataIndex: 'itemNo',
                key: 'itemNo',
                render: (text, record) => { //待提交状态的，打开编辑页
                    let value;
                    if (text && text.length > 20) {
                        value = <Tooltip placement="top" title={text}>{text.slice(0, 20)}...</Tooltip>
                    } else {
                        value = text
                    }

                    return (<span   >{value}</span>);

                }
            }, {
                title: '商品分类',
                dataIndex: 'categoryName',
                key: 'categoryName',
                render: (text, record) => {
                    return <span className="table-operate-btn" onClick={() => { this.showCommonModal(record) }}  >{ text || "-"}</span>;
                }
            }, {
                title: '商品名称',
                dataIndex: 'itemName',
                key: 'itemName',
                render: (text, record) => {
                    let value;
                    if (text && text.length > 20) {
                        value = <Tooltip placement="top" title={text}>{text.slice(0, 20)}...</Tooltip>
                    } else {
                        value = text
                    }
                    return (<span>{value ? value : '-'}</span>)
                }
            },

            // new TableRenderHelp().createEnumColumn("渠道","channelId","channelId",GoodsChannel,'-'),
            {
                title: '渠道',
                dataIndex: 'channelId',
                key: 'channelId',
                width: "10%",
                render: (text, record) => {
                    return <span>{text ? (GoodsChannel[text] ? (GoodsChannel[text]) : "-") : '-'}</span>;
                }
            },

            {
                title: '价格模式',
                dataIndex: 'pricingSwitch',
                key: 'pricingSwitch',
                width: "10%",
                render: (text, record) => {
                    return <span className="table-operate-btn" onClick={() => { this.showPriceSwitchDialog(record) }}  >{ PricingSwitch[text] || "-"}</span>;
                }
            },



            {
                title: '销售价格',
                dataIndex: 'lowPrice',
                key: 'lowPrice',
                render: (text, record) => {
                    if (record.pricingType == 1) {//1是需询价
                        return (<span>需询价</span>);
                    }

                    // 标准
                    let unitName = record.itemUnitName ? ("/" + record.itemUnitName) : '';
                    if (record.lowPrice == record.highPrice) {
                        return (<span>¥{record.lowPrice + unitName}</span>)
                    }
                    return (<span>¥{record.lowPrice + unitName}-¥{record.highPrice + unitName}</span>);
                }
            }, {
                title: '供应商',
                dataIndex: 'companyName',
                key: 'companyName',
                render: (text, record) => {
                    return (<span>{text || '-'}</span>);
                }
            }, {
                title: '店铺',
                dataIndex: 'shopName',
                key: 'shopName',
                render: (text, record) => {
                    let value;
                    if (text && text.length > 20) {
                        value = <Tooltip placement="top" title={text}>{text.slice(0, 20)}...</Tooltip>
                    } else {
                        value = text
                    }
                    return (<span>{value ? value : '-'}</span>);
                }
            }, /*{
                title: '认证状态',
                dataIndex: 'authenticationStatus',
                key: 'authenticationStatus',
                render: (text, record) => {
                    if (text === 1) {
                        return (<span>已认证</span>);
                    } else {
                        return (<span>未认证</span>);
                    }
                }
            },*/ {
                title: '商品状态',
                dataIndex: 'statusName',
                key: 'statusName',
                render: (text, record) => {
                    return (<span>{text}</span>);
                }
            },
            {
                title: '更新时间',  // 0 表示待审核状态
                dataIndex: 'updateTime',
                key: 'updateTime',
                render: (text, record) => {
                    return (<span>{moment(text).format('YYYY-MM-DD HH:mm')}</span>);
                }
            }, {
                title: '操作',
                key: 'operate',
                render: (text, record, index) => {
                    return (<span className="table-operate-btn">
                        <Link to={'/app/crm/goodsManage/' + record.code + "?pageNo=" + this.state.pageNo + "&currentPageCount=" + this.state.currentPageCount} className="operate">查看详情</Link>
                    </span>)
                }
            }
        ],
        data: [],
        categoryTree: []
    }

    componentWillMount() {
        if (this.props.location) {
            // 获取参数
            const search = this.props.location.search
            const formatData = formatLinkSearch(search)

            // 解析
            let pageNo1 = null;
            let currentageCount1 = null;


            if (formatData && formatData.backPageNo) {
                pageNo1 = formatData.backPageNo
            }


            // 赋值
            this.setState({
                selectParams: {
                    authenticationStatus: this.props.state,// 审批状态
                    pageNo: pageNo1, // 当前页数
                    pageSize: 10, // 每页条数
                }
            });
        }
    }

    //初始化
    componentDidMount() {
        this.getCategoryTree()
    }





    //分页
    onChangePage = (pageNo, pageSize, sort) => {
        //console.log(pageNo, pageSize, sort, '====')

        this.getListFun({ type: 'pageNo', data: pageNo }, { type: 'pageSize', data: pageSize })
    }
    getListFun(obj1, obj2) {
        let selectParams = this.state.selectParams;
        // selectParams.pageNo = 1;

        if (obj1) {
            selectParams[obj1.type] = obj1.data;
        }
        if (obj2) {
            selectParams[obj2.type] = obj2.data;
        }
        this.setState({
            selectParams
        }, () => {
            //this.getLists();
        })
    }


    showPriceSwitchDialog = (item) => {
        this.setState({ editPriceSwitchObj: item, newPriceSwitchValue: item.pricingSwitch });
    }


    hiddenPriceSwitchDialog = () => {
        this.setState({ editPriceSwitchObj: undefined, newPriceSwitchValue: undefined });
    }

    gotoEditPriceSwitch = () => {
        // 检查参数
        let { newPriceSwitchValue, editPriceSwitchObj } = this.state;
        if (editPriceSwitchObj.pricingSwitch == newPriceSwitchValue || !editPriceSwitchObj) { // 如果用户选择的新值 和 原始值一致，就没必要去请求api了
            this.hiddenPriceSwitchDialog();
            return;
        }


        //构造参数
        const config = {
            api: API.itemUpdatePricingSwitch,
            data: {
                itemCode: editPriceSwitchObj.code,
                pricingSwitch: newPriceSwitchValue,

            },
            showLoading: true
        }

        // 解析
        http(config).then(res => {
            if (res && res.success) {
                this.hiddenPriceSwitchDialog();
                this.props.refresh && this.props.refresh();
            }
        })


    }
    // 获取类目枚举
    getCategoryTree = () => {
        const config = {
            api: API.itemGetCategoryTree,
            showLoading: true
        }

        // 解析
        http(config).then(res => {
            if (res.success) {
                this.setState({
                    categoryTree: res.data
                })

            }
        })
    }

    showCommonModal = (record) => {
        console.log(record, );
        this.setState({
            key: 5,
            isShow: true,
            changeCategoryGoodCode: record.code
        })
    }

    onPriceSwitchChange = (e) => {
        this.setState({ newPriceSwitchValue: e.target.value });
    }

    onSelectChange = (selectedRowKeys,e) => {
        this.setState({
            selectedRowKeys,
            selectRowObj: e
        });
    }

    handleClick = key => {
        if(this.state.selectedRowKeys.length == 0) {
            message.warning('请选择商品')
            return
        }
        // selectRowObj
        //0:待审核 1:待上架  2:销售中 3:审核不通过 4:强制下架 90:删除
        if(key === 1 || key === 2) {
            // 驳回状态
            const bool = this.state.selectRowObj.some(item => {
                return item.status === 0
            })
            if(!bool) {
                message.warning('请选择待审核状态的商品')
                return
            }
        }

        if(key === 3) {
            // 强制下架
            const bool = this.state.selectRowObj.some(item => {
                return item.status === 2
            })
            if(!bool) {
                message.warning('请选择销售中状态的商品')
                return
            }
        }
        this.setState({
            radioVal: null,
            auditInfo: null,
            isShow: true,
            key,
        })
    }

    handleCancel = () => {
        this.setState({
            isShow: false,
        })
    }
    categoryChange = (e) => {
        console.log(e, 'eeeeee');
        this.setState({
            categoryCode: e[2]
        })
    }
    handleOk = () => {
        // 提交
        const _this = this
        const {
            key,
            auditInfo,
            radioVal,
            selectedRowKeys,
            categoryCode,
            changeCategoryGoodCode
        } = this.state
        if (key == 5) {

        } else if(key == 4) {
            // 设置价格模式
            if(radioVal === null) {
                message.warning('请选择价格模式')
                return
            }
        } else {
            // 驳回、下架、通过
            if((key == 1 || key == 3)&&!auditInfo) {
                message.warning('请输入原因')
                return
            }
        }

        const config = {
            api: API.itemGoodsOperate,
            data: {
                auditInfo,
                operationType: key == 4 ? (radioVal == 0 ? 4 : 5) : key == 5 ? 6 : key,
                itemCodes: changeCategoryGoodCode ? [changeCategoryGoodCode] : selectedRowKeys,
                categoryCode: key == 5 ?  categoryCode : null
            },
        }

        // 解析
        http(config).then(res => {
            if (res && res.success) {
                this.setState({
                    isShow: false,
                    selectedRowKeys: [],
                    selectRowObj: [],
                }, () => {
                    setTimeout(() => {
                        _this.props.refresh && _this.props.refresh();
                    }, 1000)
                })
            }
        })

    }
    handleChangebohui = (e) => {
        this.setState({
            auditInfo: e.target.value
        })
    }
    handleChangexiajia = e => {
        this.setState({
            auditInfo: e.target.value
        })
    }
    onPriceSwitchChanges = e => {
        this.setState({
            radioVal: Number(e.target.value)
        })
    }
    render() {
        const selectParams = this.state.selectParams;
        const {
            selectedRowKeys,
            searchValue,
            newPriceSwitchValue,
            editPriceSwitchObj,
            operation,
            isShow,
            key,
            radioVal,
            selectRowObj,
            categoryTree
        } = this.state;

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        }

        let count = 0
        if(key == 1 || key == 2) {
            // 驳回、通过的待审核商品的数量
            count = selectRowObj.filter(item => {
                return item.status === 0
            }).length
        }

        if(key == 3) {
            count = selectRowObj.filter(item => {
                return item.status === 2
            }).length
        }
        let title = ''
        if(key == 1) {
            title = '批量驳回'
        } else if(key == 2) {
            title = '批量通过'
        } else if(key == 3) {
            title = '批量强制下架'
        } else if(key == 4) {
            title = '价格模式调整'
        } else if(key == 5) {
            title = '修改类目'
        }
        return (
            <div className='enterprise-auth-list' style={{padding: 0}}>

                <Table rowSelection={rowSelection} rowKey="code" columns={this.state.columns} selfProcessData={this.props.tableData} total={this.state.total} change={this.onChangePage} current={selectParams.pageNo} />
                <div className='operator'>
                    {
                        operation.map(item => {
                            return(
                                <span key={item.key} onClick={() => {this.handleClick(item.key)}}>{item.name}</span>
                            )
                        })
                    }
                </div>
                {editPriceSwitchObj && <Modal
                    title="价格模式调整"
                    visible={true}
                    okText="确定"
                    cancelText="取消"
                    closable={false}
                    maskClosable={false}
                    onOk={this.gotoEditPriceSwitch}
                    onCancel={this.hiddenPriceSwitchDialog}
                    width={400}
                >
                    <div>
                        <div style={{ display: "block", marginBottom: '10px' }}>
                            <span style={{ marginRight: '20px' }}>价格模式:</span>
                            <Radio.Group onChange={this.onPriceSwitchChange} value={newPriceSwitchValue}>
                                <Radio value={0}>标准</Radio>
                                <Radio value={1}>{"标准/询价"}</Radio>

                            </Radio.Group>
                        </div>
                        <span className="desc">说明：调整后，需商户自己编辑商品调整相应的价格模式</span>
                    </div>
                </Modal>}
                {isShow && <Modal
                    title={title}
                    visible={true}
                    okText="确定"
                    cancelText="取消"
                    closable={false}
                    maskClosable={false}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    width={400}
                >
                    {
                        key === 1 &&
                            <div>
                                <div>
                                    确定驳回所选择【{count}】个待审核的商品吗？
                                </div>
                                <div className='modalWrapper'>
                                    <span>驳回原因：</span>
                                    <TextArea placeholder='请输入' onChange={this.handleChangebohui}/>
                                </div>
                            </div>
                    }
                    {
                        key === 2 &&
                        <div>
                            <div>
                                确定审核通过所选【{count}】个待审核的商品吗？
                            </div>
                        </div>
                    }
                    {
                        key === 3 &&
                        <div>
                            <div>
                                确定强制下架所选择【{count}】个商品吗？
                            </div>
                            <div className='modalWrapper'>
                                <span>下架原因：</span>
                                <TextArea placeholder='请输入' onChange={this.handleChangexiajia}/>
                            </div>
                        </div>
                    }
                    {
                        key === 4 &&
                        <div>
                            <div style={{ display: "block", marginBottom: '10px' }}>
                                <span style={{ marginRight: '20px' }}>价格模式:</span>
                                <Radio.Group onChange={this.onPriceSwitchChanges} value={radioVal}>
                                    <Radio value={0}>标准</Radio>
                                    <Radio value={1}>{"标准/询价"}</Radio>
                                </Radio.Group>
                            </div>
                            <span className="desc">说明：调整后，需商户自己编辑商品调整相应的价格模式</span>
                        </div>
                    }
                    {
                        key === 5 &&
                        <div>
                             
                             <Cascader
                                style={{ width: '100%' }}
                                fieldNames={{ label: 'name', value: 'code', children: 'childList' }}
                                options={categoryTree}
                                onChange={this.categoryChange}
                            />
                        </div>
                    }

                </Modal>}
            </div>




        );
    }
}

export default GoodsList;
