import React, { Component } from 'react';
import { Link} from 'react-router-dom'
// /#/app/setting/template/contracttemplate/templatedetail/kOg-jrU7r9o?type=approve
class JobItemTemplate extends Component {
    render() {
        const linkObj = this.props.type == 3 ?{pathname:`/app/setting/template/contracttemplate/templatedetail/${this.props.encode}`,search:'?type=approve'} : { pathname: `/app/setting/template/receipttemplate/templatedetail/${this.props.encode}` }
        return (
            <Link to={linkObj} className="job-item">
              <p className="job-item-title">
                     {this.props.title}    
              </p>
                {this.props.templateType ? <p>模板类型：{this.props.templateType}</p> : null }
              <p>
                  发起时间：{this.props.time}
              </p>
              <p>
                  发起人：{this.props.name}
              </p>
            </Link>
        )
    }
}

export default JobItemTemplate
