import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Button, Steps, Tooltip, Modal } from 'antd';
import userUtil from '@/utils/user';
import { AUTH_KEYS } from '@/utils/auth';
import http from '@/http';
import { API } from '@/http/api';
import useActionLoading from 'CMPT/common/hooks/useActionLoading';
import './AnnounceRead.less';

const BreadcrumbItem = Breadcrumb.Item;
const Step = Steps.Step;

let noAuthPeople = [];

export default function SettingAnnounceRead({ match: { params: { id,isNotice } }, history }) {
    const [manage, setManage] = useState({});
    const [showDeleteConfirm, toggleDeleteConfirm] = useState(false);
    const [deleteLoading, takeDeleteAction] = useActionLoading();

    useEffect(() => {
        loadDetail();
    }, [id]);

    function loadDetail() {
        http({
            api: API.announceDetail,
            path: {
                encode: id
            },
            showLoading: true
        }).then(ajaxData => {
            const resData = ajaxData.data;

            const { type, pureText, content, title, status, userNames, deptNames, createTime } = ajaxData.data || {};

            let typeText = '';
            if (type == 1) {
                typeText = (
                    <span className="typeBlock notice">通知</span>
                )
            } else if (type == 2) {
                typeText = (
                    <span className="typeBlock festival">节日</span>
                )
            } else if (type == 3) {
                typeText = (
                    <span className="typeBlock reward">奖励</span>
                )
            } else if (type == 4) {
                typeText = (
                    <span className="typeBlock punishment">惩罚</span>
                )
            }
            let receiverStr = "";
            if (deptNames) {
                receiverStr = deptNames.join("、")
            }
            if (userNames) {
                receiverStr = userNames.join("、")
            }
            setManage({
                title: title,
                type: type,
                content: content,
                pureText: pureText,
                userNames: userNames || [],
                state: status,
                typeText,
                createTime,
                receiverStr
            })
        });
    }



    const { title, type, typeText, content, receiverStr = [], state } = manage;


    return (
        <div className='setting-announce-manage-read'>
            <div className='manage-read-info'>
                <div className='info-title'>{manage.title}
                    {manage.typeText}
                </div>
                <div className='info-time'>发布时间：{manage.createTime}</div>
                <div className='info-detail' dangerouslySetInnerHTML={{
                        __html:manage.content
                    }}
                />
            </div>
        </div>
    );
}


