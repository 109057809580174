import React, { Component } from 'react';
import { Breadcrumb, Tabs, Badge } from 'antd';
import './index.less';
import DataOverView from 'CMPT/valueAddService/trajectoryFind/dataOverView';
import UserOrder from 'CMPT/valueAddService/trajectoryFind/userOrder';
import ConsumeRecord from 'CMPT/valueAddService/trajectoryFind/consumeRecord';
import OperatorAuthList from 'CMPT/enterpriseAuth/OperatorAuthList'
import { API } from '@/http/api';
import http from '@/http';
import userUtil from "@/utils/user";
import storage from '@/utils/storage';
import { formatLinkSearch } from "@/utils";
const {isUs} = userUtil;
const { TabPane } = Tabs;
const BreadcrumbItem = Breadcrumb.Item;

class TrajectoryFind extends Component {

    state = {
        menuNumber: null,
        activeTab: '1',
        userNum: 0
    }

    componentDidMount() {
        this.countAuditDeposit()
        const search = this.props.location.search;
        let tabNum = storage.get('valueAddServiceTab');
        this.setState({ activeTab: tabNum || '1' })
        if (search) {
            const formatData = formatLinkSearch(search);
            console.log(this.props, 'propssss', formatData);
            this.setState({
                activeTab: formatData && formatData.tab || '1'
            })
        }
        
    }

    countAuditDeposit = () => {
        const config = {
            api: API.valueAddServiceCountAuditDeposit, 
            showLoading: true
        };
        http(config).then(res => {
            if (res.code == 200) {
                this.setState({
                    userNum: res.data.auditCount
                })
            }
        })
    }


    changeTabs = (e) => {
        console.log(this.props.history, 'his');
        // this.props.history.location.search = '?tab=' + e;
        storage.set('valueAddServiceTab', e)
        // this.props.history.replace(`/#/app/crm/valueaddService/trajectoryFind?tab=${e}`)
        this.setState({
            activeTab: e
        })
    }

    render() {
        const {userNum, activeTab} = this.state;
        console.log(activeTab, 'tabactiveTab');
        return (

            <div className="enterprise-auth-con">
                <div className='enterprise-auth-header'>
                    <Breadcrumb>
                        <BreadcrumbItem>轨迹查询</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <div className='index_tab_box'>
                    <Tabs activeKey={activeTab} className='tabs1' style={{margin: 0}} onChange={this.changeTabs} >
                        <TabPane tab="数据概览"  key="1" className='tab1'>
                           <DataOverView {...this.props}/>
                        </TabPane>
                        <TabPane tab={<Badge count={userNum}> 用户订单 </Badge>}  key="2" className='tab1'>
                           <UserOrder  {...this.props} />
                        </TabPane>
                        <TabPane tab="消费记录"  key="3" className='tab1'>
                           <ConsumeRecord {...this.props} />
                        </TabPane>
                    </Tabs>
                </div>
                

            </div>


        );
    }
}

export default TrajectoryFind;