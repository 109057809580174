import React, { Component } from 'react';
import emptyImg from '@/images/empty_Transparent@2x.png';
import "./EmptyView.less";

//TODO   需求还需要再理理，进行扩展，应该还可以加个 Button。 比如有些情况下 会有 跳转到其他页面的需求
export default class EmptyView extends React.Component {

    emptyClick = () => {
        if (this.props.onEmptyClick) {
            this.props.onEmptyClick();
        }
    }
    render() {

        return (<div className="my-empty-page">
            <img src={emptyImg} onClick={this.emptyClick} alt=""/>
            {this.props.tips ? <div onClick={this.emptyClick} className="div_tips"><span className="tips">{this.props.tips}</span></div> : ''}
            {this.props.tips2 ? <div onClick={this.emptyClick} className="div_tips" ><span className="tips">{this.tips2}</span></div> : ''}
        </div>);
    }
}