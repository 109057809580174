import http from '@/http';
import { API } from '@/http/api';
import React, { Component } from 'react';
import { Modal, Input, Radio, message } from "antd";

const { TextArea } = Input;

class Dialog extends Component {

    state = {
        remark: undefined, //备注
        reviewerStatus: undefined   // 状态
    };

    // 状态切换
    onStatusChange = (e) => {
        this.setState({ reviewerStatus: e.target.value });
    }

    // 备注
    onRemarkChange = (e) => {
        this.setState({ remark: e.target.value });
    }

    // 执行提交
    doAcceptance = () => {
        // setup 1: 校验
        let { remark, reviewerStatus } = this.state;
        if (typeof (reviewerStatus) == "undefined") {
            message.error('请设置受理状态');
            return;
        }
        if (typeof (remark) == "undefined") {
            message.error('请输入受理备注');
            return;
        }

        // setup 2:组装参数
        const config = {
            api: API.consultAcceptance,
            data: { remark, reviewerStatus, consultingServiceCode: this.props.item.consultingServiceCode },
            showLoading: true
        };


        // setup 2:请求，并初步处理
        http(config).then(res => {
            if (res && res.code == 200) {
                this.props.refresh && this.props.refresh();
                this.close();
            } else {
                message.error('操作失败');
            }
        });
    }



    close = () => {
        this.props.close && this.props.close();
    }


    render() {
        // // 状态 0待受理 1受理中 2受理完成 3无法受理 4无效信息
        return (
            <Modal
                visible={true}
                closable={false}
                maskClosable={false}
                width={600}
                title="服务受理"
                onCancel={this.close}
                onOk={this.doAcceptance}
            >
                <div>

                    <div style={{ marginBottom: '10px' }}>
                        <span style={{ marginRight: '10px' }}>受理状态:</span>
                        <Radio.Group onChange={this.onStatusChange}  >
                            <Radio value={1}>受理中</Radio>
                            <Radio value={2}>受理完成</Radio>
                            <Radio value={3}>无法受理</Radio>
                            <Radio value={4}>无效信息</Radio>
                        </Radio.Group>
                    </div>
                    <div >
                        <span style={{ display: 'inline' }}>受理备注:</span>
                        <TextArea style={{ display: 'inline', marginTop: '8px' }} showCount maxLength={200} placeholder='请输入受理信息，必填 (200字以内)' style={{ height: 120 }} onChange={this.onRemarkChange} />
                    </div>
                </div>
            </Modal>

        );
    }
}


export default Dialog;