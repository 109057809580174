import React, { Component } from 'react';
import { Breadcrumb, Icon, Button, Form, Input, Modal } from 'antd';
import InfoCard from '../../common/card/InfoCard';
import http from '@/http';
import { API } from '@/http/api';
import Regexp from '@/utils/regex';
import userUtil from '@/utils/user';
import md5 from 'js-md5';
import storage, { STORAGE_KEYS } from '@/utils/storage';
import './SettingChangePassword.less';

const { TIME_READ_VERIFICATION_CHANGE_PW } = STORAGE_KEYS;

class ChangePasswordForm extends React.Component {
    state = {
        countDownTime: 60,
        showCountDown: false,
        loading: false,
    };

    constructor(props) {
        super(props);
        const { search } = this.props.location;
        let mobile;
        if (search) {
            const query = {};
            decodeURI(search).replace('?', '').split('&').forEach(item => {
                const kv = item.split('=');
                query[kv[0]] = kv[1];
            });
            mobile = query['mobile'];
        }

        this.state = {
            mobile: mobile
        }
    }

    componentDidMount() {
        const lastTimeReadVerification = storage.get(TIME_READ_VERIFICATION_CHANGE_PW);
        if (lastTimeReadVerification) {
            const countDown = 60 - parseInt((Date.now() - lastTimeReadVerification) / 1000);
            if (countDown > 0) {
                this.startCountDown(countDown);
            } else {
                storage.remove(TIME_READ_VERIFICATION_CHANGE_PW);
            }
        }
    }

    getCodeModify = () => {
        http({
            api: API.userGetCodeModify,
            successTip: '验证码已发送'
        }).then(res => {
            this.startCountDown();
        })
    }

    changePwd = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({ loading: true });

                const request = {
                    ...values
                };
                delete request.confirmPassword;
                request.newPassword = md5(request.newPassword);
                request.oldPassword = md5(request.oldPassword);

                http({
                    api: API.userChangePwd,
                    data: request,
                    showLoading: true,
                    successTip: '修改密码成功！'
                }).then(data => {
                    this.setState({ loading: false });
                    if (data.success) {
                        storage.remove(TIME_READ_VERIFICATION_CHANGE_PW);
                        userUtil.logout();
                        this.props.history.replace("/login");
                    }
                }).catch(error => {
                    this.setState({ loading: false });
                });
            }
        });
    }

    startCountDown = (countDown) => {
        this.setState({
            showCountDown: true
        });

        if (!countDown) {
            countDown = 60;
            storage.set(TIME_READ_VERIFICATION_CHANGE_PW, Date.now());
        }

        this.setState({
            countDownTime: countDown
        });

        this.timer = setInterval(() => {
            if (this.state.countDownTime > 0) {
                this.setState((prevState, props) => (
                    {
                        countDownTime: prevState.countDownTime - 1
                    }
                ));
            } else {
                storage.remove(TIME_READ_VERIFICATION_CHANGE_PW);
                this.setState({
                    showCountDown: false
                })
                clearInterval(this.timer);
            }
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    validateConfirmPw = (_, value, cb) => {
        const newPassword = this.props.form.getFieldValue('newPassword');
        if (!value) {
            cb('再次确认新密码不能为空！');
        } else if (newPassword === value) {
            cb();
        } else {
            cb('新密码两次输入不一致！');
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div className='setting-change-password' >
                <Breadcrumb>
                    <Breadcrumb.Item>账户设置</Breadcrumb.Item>
                    <Breadcrumb.Item>修改密码</Breadcrumb.Item>
                </Breadcrumb>
                <InfoCard visible={false}>
                    <div className='change-passworAd-info'>绑定手机号：{this.state.mobile}
                        <span className={"verification-code" + (this.state.showCountDown ? ' show-count-down' : '')} onClick={this.getCodeModify}>
                            {this.state.showCountDown ? this.state.countDownTime + 's' : '发送验证码'}
                        </span>
                    </div>
                    <Form layout='vertical' style={{ marginTop: '24px' }}>
                        <Form.Item label='验证码' className='from-item'>
                            {getFieldDecorator("msgCode", { rules: [{ required: true, message: "请输入验证码" }] })(
                                <Input
                                    autoComplete="off"
                                    placeholder="请输入"
                                />
                            )}
                        </Form.Item>
                        <Form.Item label='旧密码' className='from-item'>
                            {getFieldDecorator("oldPassword", { rules: [{ required: true, message: "旧密码不能为空！" }] })(
                                <Input
                                    autoComplete="off"
                                    type='password'
                                    placeholder="请输入"
                                />
                            )}
                        </Form.Item>
                        <Form.Item label='新密码' className='from-item'>
                            {getFieldDecorator("newPassword", {
                                rules: [{ required: true, message: "新密码不能为空！" },
                                { type: 'string', pattern: Regexp.password, message: "密码为8-15位字母数字组合" }]
                            })(
                                <Input
                                    autoComplete="off"
                                    type='password'
                                    placeholder="请输入"
                                />
                            )}
                        </Form.Item>
                        <Form.Item label='再次输入新密码' className='from-item'>
                            {getFieldDecorator("confirmPassword", {
                                rules: [{ required: true,message:'请再次输入新密码' }]
                            })(
                                <Input
                                    autoComplete="off"
                                    type='password'
                                    placeholder="请输入"
                                />
                            )}
                        </Form.Item>
                    </Form>
                </InfoCard>
                <div className='change-password-btn-h'>
                    <Button type="primary" onClick={this.changePwd} loading={this.state.loading}>确认</Button>
                    <Button onClick={()=>{this.props.history.push('/app/account')}}>取消</Button>
                </div>
            </div>
        );
    }
}

export default Form.create({ name: 'ChangePasswordForm' })(ChangePasswordForm);