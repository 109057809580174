import React, { Component } from 'react';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/bar';
import 'echarts/lib/component/tooltip';
import img from '@/images/placeholder_chart@2x.png'
export default class Bar extends Component {
    state={
        hideCanvas:false
    }
    componentWillReceiveProps(nextProps) {
        
        const { text, yaxisTitle, lineColor, fontColor, xaxisData, yaxisData, gradientColor } = nextProps.options
        
        if(xaxisData.length==0||yaxisData.length==0){
            this.setState({
                hideCanvas:true
            })            
        }else{
            this.setState({
                hideCanvas:false
            },()=>{
                if(document.getElementById(this.props.options.id)){

                    const myBarChart = echarts.init(document.getElementById(this.props.options.id));
                    let options = {            
                        grid: {
                            containLabel:true,
                            left:0,
                            right:1,
                            bottom:0,
                        },
                        title: {
                            x: '18px',
                            subtext: yaxisTitle,
                            subtextStyle: {
                                color: fontColor,
                            },
                            left:'-5'                    
                        },
                        tooltip : {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'shadow',
                                label:{
                                    fontSize: 13,
                                    color: '#E0E4F1'
                                }
                            },
                            backgroundColor: '#fff',
                            borderWidth:1,
                            borderColor:'#535465',
                            padding: [5,12],
                            textStyle: {
                                fontSize: 12
                            }
                        },
                        calculable : true,
                        xAxis : [
                            {
                                type : 'category',
                                margin: 5,
                                data: xaxisData,
                                axisLine: {
                                    show: true,
                                    lineStyle: {
                                        color: lineColor,
                                        type: 'solid',
                                        width: 1
                                    }
                                },
                                axisTick : {
                                    show:false
                                },
                                axisLabel: {                                    
                                    formatter:function(val){
                                        const strs = val.split(''); //字符串数组
                                        const maxLength = 5
                                        let str = ''
                                        let line = 1
                                        for (var i = 0, s; s = strs[i++];) { //遍历字符串数组  
                                            str += s;
                                            if (!(i % maxLength)) {
                                                if (line >= 2) {
                                                    str += '...'
                                                    break
                                                } else {
                                                    str += '\n'
                                                    line += 1
                                                }
                                            };
                                        }
                                        return str
                                    },
                                    fontSize:12,
                                    interval:0,
                                    lineHeight: 15,
                                    color: fontColor
                                }
                            }
                        ],
                        yAxis : [
                            {
                                type : 'value',
                                axisLine: {
                                    show: true,
                                    lineStyle: {
                                        color: lineColor,
                                        type: 'solid',
                                        width: 1
                                    }
                                },
                                axisLabel: {
                                    color: fontColor
                                },
                                axisTick : {
                                    show:false
                                },
                                splitLine: {
                                    show: true,
                                    lineStyle: {
                                        color: [lineColor]
                                    }
                                }
                            },
                            {
                                axisLine: {
                                    show: true,
                                    lineStyle: {
                                        color: lineColor,
                                        type: 'solid',
                                        width: 1
                                    }
                                },
                                axisLabel: {
                                    color: fontColor
                                }
                            }
                        ],
                        series: [
                            {
                                name: text,
                                type:'bar',
                                barWidth: '24px',
                                data: yaxisData,
                                itemStyle: {
                                    color: {
                                        type: 'linear',
                                        x: 0,
                                        y: 0,
                                        x2: 0,
                                        y2: 1,
                                        colorStops: [{
                                            offset: 0, color: gradientColor[0]
                                        }, {
                                            offset: 1, color: gradientColor[1]
                                        }],
                                        global: false
                                    }
                                }
                            }
                        ]
                    }
                    myBarChart.setOption(options)
                }
            })            
            
        }
    }
    render() {
        return (
            <div className="echart-bak">
                <div className="chart-title">
                    <span></span>
                    {this.props.options.title}
                </div>
                {
                    this.state.hideCanvas?<div style={{ width: this.props.options.width, height: this.props.options.height ,textAlign:'center',paddingTop:'100px'}}>
                        <img src={img} width="104" height="88" alt=""/>
                        <p>暂无数据</p>
                    </div>:
                    <div id={this.props.options.id} style={{ width: this.props.options.width, height: this.props.options.height }}></div>
                }
                
                
            </div>
        );
    }
}
