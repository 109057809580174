import React, { Component } from 'react';
import { TreeSelect, Checkbox, Input, Button, Modal } from 'antd';
import './ClassCheckModal.less';

const { SHOW_PARENT } = TreeSelect;
const { Search } = Input;



class ClassCheckModal extends Component {
    state = {
        _categoryList: [],
        categoryList: [],
        _childrenCategoryList: [],
        childrenCategoryList: [],
        hadChcekList: [],
        historyCheckList: [],
        parCode:null,
        checkList:{}
    };

    onChange = value => {
        console.log('onChange ', value);
        this.setState({ value });
    };

    checkParCode = (childList,parCode) => {
        // console.log(childList)
        this.setState({
            _childrenCategoryList: childList,
            childrenCategoryList: childList,
            parCode:parCode
        })
    }

    checkChlCode = (e,code) => {
        let parCode = this.state.parCode;
        let checkList = this.state.checkList;
        if(e.target.checked){
            !!!checkList[parCode]&&(checkList[parCode] = [])
            this.state.hadChcekList.push(code)
            checkList[parCode].push(code)
            this.setState({
                hadChcekList: this.state.hadChcekList,
                checkList:checkList
            })
        }else{
            let index  = this.state.hadChcekList.findIndex(item=>item.code==code.code)
            let parIndex = checkList[parCode].findIndex(item=>item.code==code.code)
            if(index>=0){
                this.state.hadChcekList.splice(index,1)
                checkList[parCode].splice(parIndex,1)
                if(checkList[parCode].length==0){
                    delete  checkList[parCode]
                }
                this.setState({
                    hadChcekList: this.state.hadChcekList,
                    checkList:checkList
                })
            }
        }
    }

    serachParentCategory = ({ target: { value } }) => {
        let _categoryList = this.state._categoryList;
        let classList = []
        let childList = []
        let reg = new RegExp(value)
        for (let i = 0; i < _categoryList.length; i++) {
            if (_categoryList[i].name.match(reg)) {
                classList.push(_categoryList[i])
            }
        }
        if (classList.length > 0) {
            childList = classList[0].childList
        } else {
            classList = _categoryList;
            childList = classList[0].childList;
        }
        this.setState({
            categoryList: classList,
            childrenCategoryList: childList
        })
    }

    serachChildrenCategory = value => {
        let _childrenCategoryList = this.state._childrenCategoryList;
        let classList = []
        let reg = new RegExp(value)
        for (let i = 0; i < _childrenCategoryList.length; i++) {
            if (_childrenCategoryList[i].name.match(reg)) {
                classList.push(_childrenCategoryList[i])
            }
        }
        this.setState({
            childrenCategoryList: classList
        })
    }

    handleCancel = () => {
        this.props.handleCancel();
    }

    handleOk = () => {
        let categoryList = this.state.categoryList;
        let checkList = this.state.checkList;
        let tempCategoryList = []
        for(var i=0;i<categoryList.length;i++){
            if(checkList[categoryList[i].code]){
                tempCategoryList.push({
                    name:categoryList[i].name,
                    code:categoryList[i].code,
                    childList:checkList[categoryList[i].code]
                })
            }
        }
        this.props.checkChildOk(this.state.hadChcekList,this.state.checkList,tempCategoryList)
    }

    checkAllList = (e) =>{
        let childrenCategoryList = this.state.childrenCategoryList;
        let hadChcekList = this.state.hadChcekList;
        let parCode = this.state.parCode;
        let checkList = this.state.checkList;
       if(e.target.checked){
           !!!checkList[parCode]&&(checkList[parCode] = [])
           for(let i =0;i<childrenCategoryList.length;i++){
                if(!!!hadChcekList.find(item=>item==childrenCategoryList[i])){
                    hadChcekList.push(childrenCategoryList[i])
                }
                if(!!!checkList[parCode].find(item=>item==childrenCategoryList[i])){
                    checkList[parCode].push(childrenCategoryList[i])
                }               
           }
       }else{
            for(let i =0;i<childrenCategoryList.length;i++){
                if(!!hadChcekList.find(item=>item==childrenCategoryList[i])){
                    let index = hadChcekList.findIndex(item=>item==childrenCategoryList[i])
                    hadChcekList.splice(index,1)
                }
                delete checkList[parCode]
            }
       }
       this.setState({
        hadChcekList:hadChcekList,
        checkList:checkList
       })
    }


    componentWillMount() {
        this.setState({
            _categoryList: this.props.categoryList,
            categoryList: this.props.categoryList,
            _childrenCategoryList: this.props.categoryList.length > 0 ? this.props.categoryList[0].childList || [] : [],
            childrenCategoryList: this.props.categoryList.length > 0 ? this.props.categoryList[0].childList || [] : [],
            hadChcekList: this.props.hadChcekList || [],
            historyCheckList: JSON.parse(JSON.stringify(this.props.hadChcekList )) || [],
            parCode:this.props.categoryList.length > 0 ? this.props.categoryList[0].code || null : null
        })
    }

    componentWillReceiveProps(nextProps) {

        this.setState({
            _categoryList: nextProps.categoryList || this.props.categoryList,
            categoryList: nextProps.categoryList || this.props.categoryList,
            _childrenCategoryList: nextProps.categoryList && nextProps.categoryList.length > 0 ? nextProps.categoryList[0].childList || [] : this.props.categoryList.length > 0 ? this.props.categoryList[0].childList || [] : [],
            childrenCategoryList: nextProps.categoryList && nextProps.categoryList.length > 0 ? nextProps.categoryList[0].childList || [] : this.props.categoryList.length > 0 ? this.props.categoryList[0].childList || [] : [],
            hadChcekList: nextProps.hadChcekList || this.props.hadChcekList || [],
            historyCheckList: JSON.parse(JSON.stringify(nextProps.hadChcekList || this.props.hadChcekList))  || [],
            parCode:nextProps.categoryList && nextProps.categoryList.length > 0 ? nextProps.categoryList[0].code || null : this.props.categoryList.length > 0 ? this.props.categoryList[0].code || null : null,
        })
    }

    render() {
        let parCode = this.state.parCode;
        return <div><Modal
            title="添加类目"
            visible={this.props.visible}
            onCancel={this.handleCancel}
            footer={[
                <Button key="back" onClick={this.handleCancel}>
                    取消
            </Button>,
                <Button key="submit" type="primary" onClick={this.handleOk}>
                    确定
            </Button>,
            ]}
        ><div className="class-check-area">
                <div className="class-check-group">
                    <div className="class-check-group_header">
                        <Search
                            placeholder=""
                            onChange={this.serachParentCategory}
                            style={{ width: 160 }}
                        />
                    </div>
                    <div className="class-check-group_content">
                        {
                            this.state.categoryList.map(item =>
                                <div  className={ 'check-label '+parCode&&parCode==item.code ? 'active' : ''} onClick={() => this.checkParCode(item.childList,item.code)} key={item.code}>
                                    {item.name}
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className="class-check-group">
                    <div className="class-check-group_header">
                        <Search
                            placeholder=""
                            onChange={this.state.serachChildrenCategory}
                            style={{ width: 160 }}
                        />
                    </div>
                    <div className="class-check-group_content">
                        <Checkbox
                            checked={this.state.checkList[this.state.parCode]&&this.state.checkList[this.state.parCode].length==this.state.childrenCategoryList.length}
                            onChange={(e) => this.checkAllList(e)}
                        >
                            全选
                        </Checkbox>
                        {
                            this.state.childrenCategoryList.map(item =>
                                <div key={item.code}>
                                    <Checkbox
                                        checked={!!this.state.hadChcekList.find(hisItem => hisItem.code == item.code)}
                                        disabled={!!this.state.historyCheckList.find(hisItem => hisItem.code == item.code)}
                                        onChange={(e) => this.checkChlCode(e,item)}
                                    >
                                        {item.name}
                                    </Checkbox>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </Modal></div>
    }
}

export default ClassCheckModal;