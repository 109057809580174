import React, { Component } from 'react';
import { Form, Select, Input, Button, DatePicker } from 'antd';
import moment from 'moment';
import { API } from '@/http/api';
import http from '@/http';
import { AccountType, AuditStatus } from "../enum";

const { Option } = Select;
const { RangePicker } = DatePicker;

class SearchForm extends Component{
  state = { 
    data: []
  }
  componentDidMount () {
    this.identityType()
    
  }
  identityType = () => {
    const config = {
      api: API.vAndEGetIdentityType,
    };
    http(config).then((res) => {
      if (res && res.code == 200) {
        let { data } = res;
        console.log(res, '获取到身份');
        
        this.setState({
          data,
        });
      }
    });
  }

  reset = () => {
    const { form } = this.props;
    form.resetFields();
    this.props.form.validateFields(
      { force: true },
      (err, values) => {
        console.log(err, values, 'err, values')
        if (!err) {
          this.props.reset(values);
          // this.props.onSearch()
        }
      },
    );
  }
  
  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <Form layout='inline'>
        <Form.Item label="">
          {getFieldDecorator('plate', )(
            <Input
              style={{width: '200px'}}
              placeholder={'车牌号'}
            />
          )}
        </Form.Item>
        
        <Form.Item label="">
          {getFieldDecorator('identityType')(
            <Select allowClear placeholder="身份" style={{width: '200px'}}>
              {/* <Option key="-1" value={-1}>
                全部
              </Option> */}
              {this.state.data.map(item => (
                <Option key={item.code} value={item.code}>
                  {item.label}
                </Option>
              ))}
            </Select>,
          )}
        </Form.Item>

        <Form.Item label="">
          {getFieldDecorator('name', )(
            <Input
              style={{width: '200px'}}
              placeholder={'企业/人员名称'}
            />
          )}
        </Form.Item>

        <Form.Item label="">
          <Button type='primary' onClick={this.props.onSearch}>搜索</Button>
        </Form.Item>
        
        <Form.Item label="">
          <a className='reset' onClick={this.reset}>重置</a>
        </Form.Item>
      </Form>
    );
  }
}

const onValuesChanges = (props, changedValues, allValues) => {
  let result = {...allValues,pageNo: 1}
  if(allValues.commitTime && allValues.commitTime.length > 0){
    result.completeDateStart = moment(allValues.commitTime[0]).format('YYYY-MM-DD')
    result.completeDateEnd = moment(allValues.commitTime[1]).format('YYYY-MM-DD')
  } else {
    result.completeDateStart = ''
    result.completeDateEnd = ''
  }
  if(allValues.demandTime && allValues.demandTime.length > 0){
    result.submitDateState = moment(allValues.demandTime[0]).format('YYYY-MM-DD')
    result.submitDateEnd = moment(allValues.demandTime[1]).format('YYYY-MM-DD')
  } else {
    result.submitDateState = ''
    result.submitDateEnd = ''
  }
  
  delete result['demandTime']
  delete result['commitTime']
  for(let key in result){
    if(result[key] == undefined){
      result[key] = ''
    }
  }
  console.log(result, 'result')
  props.onValuesChange(result)
}

export default Form.create({ name: "VehicleManageList", onValuesChange: onValuesChanges })(SearchForm);
