import React, { Component } from 'react';
import { Tree } from 'antd';
import { API } from '@/http/api';
import http from '@/http';
import './index.less';

const { TreeNode } = Tree;
let datas = [];
class SortTree extends Component {
  componentWillMount() {
    this.init();
  }
  state = {
    sourceData: [],
    activeCode: '',
    sortNum: '',
  }

  init = () => {
    const {onOperate, sourceData = [] } = this.props;
    this.setState({
      sourceData,
      activeCode: sourceData.length > 0 ? sourceData[0].code : '',
      sortNum: sourceData.length > 0 ? sourceData[0].sortNum : 0,
    });
    datas = JSON.parse(JSON.stringify(sourceData));
    sourceData.length > 0 && onOperate(sourceData[0]);
  }


  operate = (data) => {
    const {onOperate} = this.props;
    onOperate(data);
    this.setState({
      activeCode: data.code
    });
  }

  renderTreeNodes = () => {
    const { sourceData, activeCode } = this.state;
    const { isAbled } = this.props;
    const data = [];
    if (!isAbled) {
      datas.map((item, index) => {
        data.push(
          <p className={item.code == activeCode ? "tree_node_active": "tree_node"} onClick={() => this.operate(item)} key={index}>{item.navbarName}</p>
        ) 
      });
    } else {
      sourceData.map((item) => {
        const icon = (
          <p className="tree-node">
            <i className="icon iconfont icon-approve" />
            {
              item.navbarName
            }
          </p>
        );
        data.push(<TreeNode key={item.code} title={icon} dataRef={item} />);
      });  
    }
    return data;
  }

  onDrop = (info) => {
    const { sourceData } = this.state;
    const dragKey = info.dragNode.props.eventKey; //拖拽的元素code
    const loop = (data, key, callback) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].code === key) {
          return callback(data[i], i, data);
        }
      }
    };
    const data1 = JSON.parse(JSON.stringify(sourceData));
    let dragObj;
    loop(data1, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });
    data1.splice(info.dropPosition, 0, dragObj);
    this.setState({
      sourceData: JSON.parse(JSON.stringify(data1))
    })
  }

  // 取消
  cancel = () =>{
    const { onCancel } = this.props;
    this.setState({
      sourceData: JSON.parse(JSON.stringify(datas))
    });
    onCancel();
  }

  // 保存
  save = () =>{
    const { onSave, id, type } = this.props;
    const { sourceData, sortNum } = this.state;
    const list = [];
    sourceData && sourceData.length > 0 && sourceData.forEach((item, index) => {
      list.push({
        code: item.code,
        sortNum: sortNum + index
      });
    });
    http({
      api: API.channelNavSort,
      data: {
        id,
        list,
        type
      }
    }).then(res => {
      if (res.success) {
        datas = JSON.parse(JSON.stringify(sourceData));
        onSave();
      }
    }); 
  }

	render () {
    const { isAbled } = this.props;
		return (
			<div className="sort_tree">
          {
            isAbled ? (
              <Tree
                className="draggable-tree"
                draggable
                blockNode
                onDrop={this.onDrop}
              >
              {
                this.renderTreeNodes()
              }
              </Tree>) : this.renderTreeNodes()
          }
				 {
           isAbled ? 
           <div className="btns">
              <span className="save" onClick={this.save}>保存</span>
              <span className="cancel" onClick={this.cancel}>取消</span>
            </div>
            : ''
         }
				
			</div>
		);
	}
}
export default SortTree;