import React, { Component } from "react";

 
import http from '@/http';
import { API } from '@/http/api';
import { DivConvert, UrlConvert, ImgConvert,CustomFunctionConvert } from '@/utils/detail_convert.js'
import {PromotionStateLabel ,CommonFlag} from  'CMPT/common.js';

import './DetailInfo.less'

class DetailInfo extends React.Component {
    state = {
        code: null,//
        datas: null,

        convertMap: [ // 后续做一个convert转换机制。统一
            new DivConvert('promotionName', '活动名称'),
            new DivConvert('promotionCode', '活动编码'),
            new DivConvert('promotionDesc', '活动描述'),
            new ImgConvert('promotionImgUrl', '活动图片'),
            new DivConvert('isHomepage', '是否有活动专题页'),//isHomepage
            new UrlConvert('homepageUrl', '专题页地址'),
           /* new CustomFunctionConvert('supplierLimitFlag', '是否限制供应商范围',(supplierLimitFlag)=>{
                   return <span>{supplierLimitFlag===1 ? '是' : '否'}</span>
            }),*/
            new DivConvert('promotionChannelDesc', '活动渠道'),
            new DivConvert('promotionRangeDesc', '活动用户范围'),
            new DivConvert('registerStartAndEndimeDesc', '活动报名时间'),
            new DivConvert('promotionStartAndEndimeDesc', '活动有效期'),
            new DivConvert('promotionRules', '活动规则'),
            new DivConvert('status', '活动状态')
        ]

    };

    componentWillMount() {
        this.state.code = this.props.code;
    }


    getDetail() {

        let dataParams = { code: this.state.code };
        const config = {
            api: API.promotionDetail,
            data: dataParams,
            showLoading: true
        };
        http(config).then(res => {
            const data = res.data;
            if (res && res.success && res.data) {
                res.data.status =  PromotionStateLabel[res.data.status] ? PromotionStateLabel[res.data.status] : '-'
                res.data.isHomepage = res.data.isHomepage==CommonFlag.Yes?'是':'无';
               
                this.setState({
                    datas:res.data
                });
            }

        });
    };

    componentDidMount() {
        this.getDetail();
    }


    render() {
        let myState = this.state;
        let datas = myState.datas;

        return <div className='detail_info'>
            {
                datas && myState.convertMap.map((item, index) => {
                    return item.convert(datas, index);
                })
            }

        </div>
    }
}


export default DetailInfo;
