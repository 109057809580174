import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Icon, Button, Tooltip } from 'antd';
import Table from 'CMPT/common/Table';
import SupplierInfo from './SupplierInfo';
import AuditModel from './AuditModel';
import RejectModel from './RejectModel';
import DeleteSupplier from './DeleteSupplier';
import http from '@/http';
import { API } from '@/http/api';
import AuthContainer from 'CMPT/common/auth/AuthContainer';
import { AUTH_KEYS } from '@/utils/auth';
import './SupplierLists.less';
const BreadcrumbItem = Breadcrumb.Item;

class SupplierDetail extends Component {
    state = {
        showAudit: false, //提交审批
        showDelete: false, //删除
        showRejectModel: false,
        fromPage: true,
        detailData: '',
        id: '',
        columns: [
            {
                title: '时间',
                dataIndex: 'opTimeStr',
            }, {
                title: '操作人',
                dataIndex: 'userName',
                render(text, record) {
                    return (
                        <div>
                            <Tooltip placement="top" title={record.userName}>{record.realName}</Tooltip>
                        </div>
                    )
                }
            }, {
                title: '审批结果',
                dataIndex: 'opereateTypeDesc',
            }, {
                title: '备注',
                dataIndex: 'remark',
                render: (text, record) => { //待提交状态的，打开编辑页
                    if (text && text.length > 20) {
                        return <Tooltip placement="top" title={text}>{text.slice(0, 20)}...</Tooltip>
                    } else {
                        return text
                    }
                }
            }
        ],
        data: []
    }
    componentDidMount() {
        const fromPage = this.props.location.search.split('=')[1];
        if (fromPage == 1) { //0是详情，1是审批
            this.setState({
                fromPage: false
            })
        }
        const id = this.props.match.params.id;
        this.setState({
            id
        })
        const config = {
            api: API.supplierQueryDetail,
            path: { encode: id },
            showLoading: true
        };
        http(config).then(res => {
            const list = res.data.opLogList && res.data.opLogList.map((item, index) => {
                return {
                    key: index,
                    opTimeStr: item.opTimeStr,
                    userName: item.userName || '-',
                    realName: item.name,
                    opereateTypeDesc: item.opereateTypeDesc,
                    remark: item.remark || '-'
                }
            })
            this.setState({
                detailData: res.data,
                data: list || []
            })
        })
    }
    //提交审批 
    auditModel = () => {
        this.setState({
            showAudit: true
        })
    }
    deleteSupplier = (id, name) => {
        this.setState({
            showDelete: true,
            supplierId: id,
            name
        })
    }
    goList = () => {
        this.props.history.push('/app/operate/serviceCenter/supplier');
    }
    //通过驳回
    modelFun(type) {
        this.setState({
            auditStatus: type,
            showRejectModel: true
        })
    }
    render() {
        const detailData = this.state.detailData;
        let auditBtn = null;
        if (detailData.auditConfig && (detailData.auditStatus == 10 || detailData.auditStatus == 30)) {
            auditBtn = <Button type="primary" className='new-role-btn' onClick={this.auditModel.bind(this)}>提交审批</Button>
        }
        const editStatus = detailData.auditStatus == 20 || (detailData.auditStatus == 30 && !detailData.auditConfig)
        return (
            <div className="supplier-detail">
                <div className='detail-header'>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link style={{ color: '#333' }} to={{ pathname: "/app/operate/serviceCenter/supplier" }}>
                                供应商列表页
                            </Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem>{this.state.fromPage ? '供应商详情' : '审批'}</BreadcrumbItem>
                    </Breadcrumb>
                    <AuthContainer authCodes={AUTH_KEYS.OPERATE_SERVICE_SUPPLIER_SUPPLIEREDIT}>
                        <div className="action-btn">
                            <Button className='btn-warning' style={{ display: detailData.auditStatus == 20 ? 'none' : '' }} onClick={this.deleteSupplier.bind(this, this.state.id, detailData.name)}>删除</Button>
                            <Button className='new-role-btn' style={{ display: editStatus ? 'none' : '' }}><Link to={`/app/operate/serviceCenter/supplier/edit/${this.state.id}?auditStatus=${detailData.auditStatus}`} className="operate">编辑</Link></Button>
                            {auditBtn}
                        </div>
                    </AuthContainer>
                </div>
                <SupplierInfo data={detailData} />
                <div className="supplier-baseInfo" style={{ display: detailData.opLogList ? 'block' : 'none' }}>
                    <div className="title">操作记录</div>
                    <div className="infor-table">
                        <Table columns={this.state.columns} data={this.state.data} pagination={false} />
                    </div>
                </div>
                {
                    detailData.auditFlag == 0 ? null :
                        (<div className="audit-btn">
                            <Button type="dashed" onClick={this.modelFun.bind(this, 1)}>通过</Button>
                            <Button type="danger" onClick={this.modelFun.bind(this, 2)}>驳回</Button>
                        </div>)
                }
                <AuditModel visible={this.state.showAudit} supplierId={this.state.id} goList={this.goList.bind(this)} />
                <RejectModel visible={this.state.showRejectModel} supplierId={this.state.id} auditStatus={this.state.auditStatus} goList={this.goList.bind(this)} />
                <DeleteSupplier visible={this.state.showDelete} supplierId={this.state.supplierId} name={this.state.name} getNewLists={this.goList.bind(this)} />
            </div>
        );
    }
}

export default SupplierDetail;