import React, { Component } from 'react';
import { Icon } from 'antd';
import MissionItem from './MissionItem'

class MissionList extends Component {

    checkItem = (v) => {
        this.props.checkItem(v);
    }

    deleteItem = (v) => {
        this.props.deleteItem(v);
    }

    editItem = (v) => {

        this.props.editItem( this.props.data.find(item=>item.encode==v));
    }

    getMore = () => {
        this.props.getMore()
    }
    render() {
        const itemListHtml = this.props.data.map((item, index) => <MissionItem key={index} text={item.taskName} checked={item.status == 2} value={item.encode} checkitem={this.checkItem} deleteitem={this.deleteItem} edititem={this.editItem} />)
        const moreHtml = this.props.isMore ? <div className="miss-list-more" onClick={this.getMore}>
            <span className="miss-list-more-text">
                展开更多
        </span>
        </div> : null
        const idName = this.props.idName || 'missionList'
        return (
            <div id={idName} className="mission-list" style={this.props.operate && !this.props.collapse ? { height: '37px', overflow: 'hidden' } : null}>
                <div id={idName+'Title'} className="miss-list-title" onClick={this.props.onCollapse} style={!this.props.operate ? { display: 'none' } : null}>
                    {this.props.operate ? this.props.collapse ? <Icon type="caret-down" /> : <Icon type="caret-right" /> : null}
                    {this.props.title}
                </div>
                <div className="miss-list-content">
                    {itemListHtml}
                </div>
                {moreHtml}
            </div>
        )
    }
}



export default MissionList
