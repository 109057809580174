import { AJAX_TYPE } from "../config";

// Role.Role

const prefix = "InviteTenders";

export default {
  // CRM招标公告审核列表
  [`${prefix}List`]: {
    url: "/web/mgr/tender/list",
    type: AJAX_TYPE.POST,
  },
  // 招标公告详情
  [`${prefix}Detail`]: {
    url: "/web/mgr/tender/view",
    type: AJAX_TYPE.GET,
  },
  // 招标审核
  [`${prefix}Audit`]: {
    url: "/web/mgr/tender/audit",
    type: AJAX_TYPE.POST,
  },
  [`${prefix}ForceOffShelves`]: {
    url: "/web/mgr/tender/forceOffShelves",
    type: AJAX_TYPE.POST,
  },
  // 招标公告审核记录
  [`${prefix}AuditLog`]: {
    url: "/web/mgr/tender/auditLog",
    type: AJAX_TYPE.GET,
  },
};
