import React, { Component } from "react";
import { Layout, Row, Breadcrumb,Input, Button, DatePicker, Tooltip } from "antd";
import Table from "CMPT/common/Table";
import {
  CustomerCreateType,
  CustomerRoleMap,
  convertAuthenticationToLabel,
  completeFlag
} from "./customerConfig.js";
import Select from "CMPT/common/Select";
import SelectGroup from "CMPT/common/SelectGroup";
import moment from "moment";
import { Link } from "react-router-dom";
import "./CustomerList.less";
import { convertEnumToMap } from "CMPT/common.js";
import { API } from "@/http/api";
import http from "@/http";

import userUtils from '@/utils/user';
import { AUTH_KEYS } from "@/utils/auth";
const { checkAuthByKey } = userUtils;


const { Content } = Layout;
const { RangePicker } = DatePicker;
const InputSearch = Input.Search;
const BreadcrumbItem = Breadcrumb.Item;

class CustomerList extends Component {
  state = {
    data: [],
    columns: this.createColumns(),
    tablePageConfig: this.createPageConfig(0, 20, 1),
    role: undefined,
    startDateMoment: undefined,
    endDateMoment: undefined,

    searchParam: {
      identitys: [],
      dataCreateBy: undefined,
      createTimeStart: null,
      createTimeEnd: null,
      keyword: null,
      completeFlag: undefined
    },

    pageNo: 1,
    pageSize: 10,
    total: 0
  };

  componentDidMount() {
    this.getCustomerList();
  }

  resetParams = () => {
    let searchParam = this.state.searchParam;
    searchParam.identitys = [];
    searchParam.dataCreateBy = undefined;
    searchParam.createTimeStart = null;
    searchParam.createTimeEnd = null;
    searchParam.keyword = null;

    this.setState(
      {
        role: undefined,
        pageNo: 1,
        startDateMoment: null,
        endDateMoment: null
      },
      () => {
        this.getCustomerList();
      }
    );
  };

  createCommonColumn(titleValue, keyValue, dataIndexValue) {
    return {
      title: titleValue,
      dataIndex: dataIndexValue,
      key: keyValue,
      //width: widthValue,
      render: (text, record, index) => {
        return <span>{text ? text : "---"}</span>;
      }
    };
  }

  createRenderColumn(titleValue, keyValue, dataIndexValue, renderFun) {
    return {
      title: titleValue,
      key: keyValue,
      dataIndex: dataIndexValue,
      //width: widthValue,
      render: renderFun
    };
  }

  //TODO 这种可以提取，作为公用方法
  createIndexColumn(titleName, keyValue) {
    return {
      title: titleName,
      key: keyValue,
      //width: widthValue + "%",
      render: (text, record, index) => {
        return <span>{index + 1}</span>;
      }
    };
  }

  createRenderLongString(maxLength) {
    return (text, record, index) => {
      let value;
      if (text && text.length > maxLength) {
        value = (
          <Tooltip placement="top" title={text}>
            {text.slice(0, maxLength)}...
          </Tooltip>
        );
      } else {
        value = text;
      }
      return <span>{value ? value : "-"}</span>;
    };
  }

  createTimeColumn(titleValue, keyValue, dataIndexValue, targetFormat) {
    return this.createRenderColumn(
      titleValue,
      keyValue,
      dataIndexValue,
      (text, record, index) => (
        <span>{text ? moment(text).format(targetFormat) : "-"}</span>
      )
    );
  }

  createOperateColumn(titleValue, renderFun) {
    return {
      title: titleValue,
      render: renderFun
    };
  }

  createYesNoColumn(titleValue, keyValue, dataIndexValue) {
    return this.createEnumColumn(
      titleValue,
      keyValue,
      dataIndexValue,
      //widthValue,
      { 1: "是", 0: "否" },
      "否"
    );
  }

  createEnumColumn(
    titleValue,
    keyValue,
    dataIndexValue,
    // widthValue,
    enumMap,
    defaultForNull
  ) {
    return {
      title: titleValue,
      dataIndex: dataIndexValue,
      key: keyValue,
      //  width: widthValue + "%",
      render: (value, record, index) => {
        if (!value && !(value === 0)) {
          return defaultForNull;
        }
        return enumMap[value];
      }
    };
  }

  createCustomerOperate() {
    let my = this;
    return this.createOperateColumn("操作", (text, record, index) => {
      return (
        <div>
          <Link
            to={"/app/crm/client/newdetail/" + record.code}
            className="text-color-highlight"
          >
            <span className="text-color-highlight">查看详情</span>
          </Link>
        </div>
      );
    });
  }

  createColumns() {
    let my = this;
    return [
      my.createIndexColumn("序号", "code"), // 序号需要确定一个key ，保证 唯一性
      my.createRenderColumn(
        "企业名称",
        "companyName",
        "companyName",
        my.createRenderLongString(10)
      ),
      my.createRenderColumn(
        "企业简称",
        "shortName",
        "shortName",
        my.createRenderLongString(10)
      ),
      my.createYesNoColumn("是否有企业邮箱", "havaEmail", "havaEmail"),
      this.createCommonColumn(
        "企业邮箱域名",
        "enterpriseEmailDomain",
        "enterpriseEmailDomain"
      ),

      {
        title: "认证身份",
        dataIndex: "authenticationTo",
        key: "authenticationTo",
        render: (text, record) => {
          return <span>{convertAuthenticationToLabel(text)}</span>;
        }
      },
      {
        title: "佣金比例",
        dataIndex: "commission",
        key: "commission",
        render: (text, record) => {
          return <span>{(text && text != '-') ? (text + '%') : '--'}</span>;
        }
      },
      {
        title: "资料是否完善",
        dataIndex: "completeFlag",
        key: "completeFlag",
        render: (text, record) => {
          return text == 1 ? '否' : (text == 2 ? '是' : '-');
        }
      },
      my.createRenderColumn(
        "管理员姓名",
        "personalName",
        "personalName",
        my.createRenderLongString(8)
      ),
      this.createCommonColumn("管理员手机号", "personalPhone", "personalPhone"),
      // this.createCommonColumn('创建方式', 'dataCreateBy', 'dataCreateBy', 12),
      this.createEnumColumn(
        "创建方式",
        "dataCreateBy",
        "dataCreateBy",
        { 1: "企业申请", 2: "后台创建" },
        "企业申请"
      ),
      this.createCommonColumn("创建时间", "applyTime", "applyTime"),
      this.createCustomerOperate()
    ];
  }

  getCustomerList() {
    let myData = { ...this.state.searchParam };
    myData.completeFlag = myData.completeFlag && Number(myData.completeFlag);
    myData.pageSize = this.state.pageSize;
    myData.pageNo = this.state.pageNo;
    if (myData.dataCreateBy === 0) {
    } else if (myData.dataCreateBy) {
      myData.dataCreateBy = parseInt(myData.dataCreateBy);
    }

    const config = {
      api: API.crmCustomerList,
      data: myData,
      showLoading: true
    };

    http(config).then(res => {
      if (res && res.success) {
        // let newData = [...res.data.datas];
        // let newTablePageConfig = this.createPageConfig(res.data.totalRecord, res.data.pageSize, res.data.pageNo);
        this.setState({
          // tablePageConfig: newTablePageConfig,
          data: res.data.datas,
          pageNo: res.data.pageNo,
          pageSize: res.data.pageSize,
          total: res.data.totalRecord
        });
      } else {
        //TODO展示空数据
      }
    });
  }

  createPageConfig(totalRecord, pageSizeNumber, pageNo) {
    return {
      current: pageNo,
      total: totalRecord,
      pageSize: pageSizeNumber
    };
  }

  handleCustmerType = value => {
    if (!value) {
      return;
    }

    let searchParam = this.state.searchParam;
    searchParam.identitys = value;

    this.setState(
      {
        role: value,
        pageNo: 1
      },
      () => this.getCustomerList()
    );
  };

  handleCreateType = value => {
    let searchParam = this.state.searchParam;
    searchParam.dataCreateBy = value;
    this.setState({ searchParam, pageNo: 1 }, () => this.getCustomerList());
  };

  handleIsComlete = value => {
    let searchParam = this.state.searchParam;
    searchParam.completeFlag = value;
    this.setState({ searchParam, pageNo: 1 }, () => this.getCustomerList());
  };

  handleDate = (value, date) => {
    let searchParam = this.state.searchParam;
    searchParam.createTimeStart = date[0];
    searchParam.createTimeEnd = date[1];

    this.setState(
      {
        searchParam,
        pageNo: 1,
        startDateMoment: value[0],
        endDateMoment: value[1]
      },
      () => this.getCustomerList()
    );
  };

  setKeywords = ({ target: { value } }) => {
    let searchParam = this.state.searchParam;
    searchParam.keyword = value ? value.replace(/^\s+|\s+$/g, "") : "";
    this.setState({ searchParam: searchParam, pageNo: 1 });
  };

  tableChange = (page, pageSize, sort) => {
    let order = sort && sort.order == "ascend" ? 1 : 2;
    this.setState(
      {
        pageNo: page,
        pageSize: pageSize,
        orderByList: order
      },
      () => this.getCustomerList()
    );
  };

  render() {
    let mySate = this.state;
    const {
      pageNo,
      pageSize,
      total,
      startDateMoment,
      endDateMoment
    } = this.state;

    return (
      <Content className="customerlist-list customerlist-content"  >
         <div className='customerlist-header'>
                    <Breadcrumb>
                        <BreadcrumbItem>客户管理</BreadcrumbItem>
                    </Breadcrumb>
                </div>
        <div className="customerlist-body">
          <Row>
            <span>
              <span style={{ marginRight: "5px" }}>企业身份</span>
              <Select
                mode="multiple"
                style={{ width: "120px", marginRight: "12px" }}
                data={convertEnumToMap(CustomerRoleMap)}
                value={mySate.role}
                allowClear
                placeholder="企业身份"
                onChange={this.handleCustmerType}
              />
            </span>

            <span>
              <span style={{ marginRight: "5px" }}>创建方式</span>
              <Select
                style={{ width: "104px", marginRight: "12px" }}
                data={convertEnumToMap(CustomerCreateType, true)}
                value={mySate.searchParam.dataCreateBy}
                allowClear
                placeholder="创建方式"
                onChange={this.handleCreateType}
              />
            </span>

            <span>
              <span style={{ marginRight: "5px" }}>资料是否完善</span>
              <Select
                style={{ width: "104px", marginRight: "12px" }}
                data={convertEnumToMap(completeFlag, true)}
                value={mySate.searchParam.completeFlag}
                allowClear
                placeholder="资料是否完善"
                onChange={this.handleIsComlete}
              />
            </span>

            <span>
              <span style={{ marginRight: "5px" }}>创建时间</span>
              <RangePicker
                value={[startDateMoment, endDateMoment]}
                onChange={this.handleDate}
                style={{ width: "200px", marginRight: "12px" }}
              />
            </span>

            <span>
              <InputSearch
                placeholder="企业名称/企业简称/手机号搜索"
                onChange={this.setKeywords}
                onSearch={() => this.getCustomerList()}
                value={mySate.searchParam.keyword}
                style={{ width: "280px" }}
                maxLength={50}
              />
            </span>

            <span
              span={3}
              className="reset"
              style={{
                cursor: "pointer",
                fontSize: "12px",
                textDecoration: "underline",
                textUnderlinePosition: "under",
                color: "#333"
              }}
              onClick={this.resetParams.bind(this)}
            >
              <a >重置</a>
            </span>
            
            {
              checkAuthByKey(AUTH_KEYS.CUSTOMER_MANAGE_ADD) ? 
              <span>
                <Button
                  type="primary"
                  style={{ marginLeft: "20px" }}
                  history={this.props.history}
                  onClick={() =>
                    this.props.history.push("/app/crm/client/newAddEdit")
                  }
                >
                  新增
                </Button>
              </span> : null
            }
            
          </Row>
          <Table
            change={this.tableChange}
            rowKey="code"
            scroll={{ x: "max-content" }}
            selfProcessData={this.state.data}
            columns={mySate.columns}
            data={mySate.data}
            pageSize={pageSize}
            current={pageNo}
            total={total}
          />
        </div>
      </Content>
    );
  }
}
export default CustomerList;
