import React, { Component } from 'react';
import { Modal, Select } from 'antd';
import http from '@/http';
import { API } from '@/http/api';
import './AssignStaff.less'

const Option = Select.Option;
export default class AssignStaff extends Component {
    state = {
        staff: [],
        staffUsing: [],
        selectedValue: [],
        visible: false,
        confirmVisible: false,
        assignLoading: false
    }

    currentRoleId = null;
    currentRoleName = null;

    show(idCode, roleName) {
        this.setState({
            visible: true,
            staffUsing: []
        });
        this.currentRoleId = idCode;
        this.currentRoleName = roleName;

        http({
            api: API.userGetList,
            data: {
                pageSize: null
            },
            showLoading: true
        }).then(ajaxData => {
            if (ajaxData.data) {
                const staff = ajaxData.data.datas || [];

                const initSelectedValue = [];

                staff.forEach(person => {
                    const { idCode, roleName: _name } = person;
                    if (_name === roleName) {
                        person.disabled = true;
                        initSelectedValue.push(idCode);
                    }
                });

                this.setState({
                    staff,
                    selectedValue: initSelectedValue
                });
            } else {
                this.setState({
                    staff: [],
                    selectedValue: []
                });
            }
        });
    }

    getStaffUsing = () => {
        const { staff, selectedValue } = this.state;
        const staffUsing = [];
        if (selectedValue.length > 0) {
            const currentRoleName = this.currentRoleName;
            staff.forEach(person => {
                const { roleName, idCode } = person;
                if (roleName && roleName !== currentRoleName && selectedValue.includes(idCode)) {
                    staffUsing.push(person);
                }
            });
        }
        return staffUsing;
    }

    hide = () => {
        this.setState({
            visible: false,
            selectedValue: []
        });
    }

    checkAssign = () => {
        if (this.currentRoleId) {
            const staffUsing = this.getStaffUsing();
            const staffInUsing = staffUsing.length > 0;
            if (staffInUsing) {
                this.setState({
                    confirmVisible: true,
                    staffUsing
                });
                // const green = document.querySelector('.staff-select .ant-select-selection__choice[title=张三2]');
                // if (green) {
                //     green.classList.add('staff-using');
                // }
            } else {
                this.assign();
            }
        }
    }

    assign = () => {
        this.setState({
            assignLoading: true
        });

        const { selectedValue } = this.state;

        http({
            api: API.roleRoleUser,
            data: {
                roleCode: this.currentRoleId,
                userCodes: selectedValue
            },
            successTip: '分配成功',
            showLoading: true
        }).then(ajaxData => {
            this.setState({
                assignLoading: false
            });
            if (ajaxData.success) {
                this.hide();
            }
        }).catch(error => {
            this.setState({
                assignLoading: false
            });
        });
    }

    changeStaff = (value) => {
        this.setState({
            selectedValue: value
        });
    }

    confirm = () => {
        this.hideConfirm();
        this.assign();
    }

    hideConfirm = () => {
        this.setState({
            confirmVisible: false
        });
    }

    render() {
        const { visible, confirmVisible, assignLoading, staff, staffUsing, selectedValue } = this.state;
        return [
            <Modal
                title="分配"
                visible={visible}
                closable={false}
                maskClosable={false}
                okText='保存'
                confirmLoading={assignLoading}
                onOk={this.checkAssign}
                onCancel={this.hide}
                className='assign-staff'
                width={624}
                key='staff'
            >
                <div className='tip'>
                    分配员工
                </div>
                <Select
                    className='staff-select'
                    mode="multiple"
                    placeholder="请选择员工"
                    value={selectedValue}
                    onChange={this.changeStaff}
                    optionFilterProp='children'
                >
                    {
                        staff.map(person => (<Option value={person.idCode} key={person.idCode} disabled={person.disabled}>{person.name}</Option>))
                    }
                </Select>
            </Modal>,
            <Modal
                title="确定将以下员工角色作更改吗？"
                visible={confirmVisible}
                closable={false}
                maskClosable={false}
                onOk={this.confirm}
                onCancel={this.hideConfirm}
                className='assign-staff-confirm'
                width={400}
                key='confirm'>
                {
                    staffUsing.map(person => (<div key={person.idCode}>{person.name}：{person.roleName}</div>))
                }
            </Modal>
        ];
    }
}