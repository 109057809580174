import React  from "react";
import moment from "moment";
import {   Tooltip } from "antd";

export  default class TableRenderHelp{

createCommonColumn(titleValue, keyValue, dataIndexValue,defaultValueNo) {
    return {
      title: titleValue,
      dataIndex: dataIndexValue,
      key: keyValue,
      //width: widthValue,
      render: (text, record, index) => {
        return <span>{  text || (defaultValueNo||"-")}</span>;
      }
    };
  }
 

  createTextColumn(titleValue, dataIndexValue) {
    return this.createCommonColumn(titleValue,dataIndexValue,dataIndexValue,undefined);
  }

  createRenderColumn(titleValue, keyValue, dataIndexValue, renderFun) {
    return {
      title: titleValue,
      key: keyValue,
      dataIndex: dataIndexValue,
      //width: widthValue,
      render: renderFun
    };
  }

  //TODO 这种可以提取，作为公用方法
  createIndexColumn(titleName, keyValue) {
    return {
      title: titleName,
      key: keyValue,
      render: (text, record, index) => {
        return <span>{index + 1}</span>;
      }
    };
  }

  createRenderLongString(maxLength) {
    return (text, record, index) => {
      let value;
      if (text && text.length > maxLength) {
        value = (
          <Tooltip placement="top" title={text}>
            {text.slice(0, maxLength)}...
          </Tooltip>
        );
      } else {
        value = text;
      }
      return <span>{value ? value : "-"}</span>;
    };
  }

  createTimeColumn(titleValue, keyValue, dataIndexValue, targetFormat) {
    return this.createRenderColumn(
      titleValue,
      keyValue,
      dataIndexValue||keyValue,
      (text, record, index) => (
        <span>{text ? moment(text).format(targetFormat||"YYYY-MM-DD HH:mm") : "-"}</span>
      )
    );
  }

  createOperateColumn(titleValue, renderFun) {
    return {
      title: titleValue,
      render: renderFun
    };
  }

  createYesNoColumn(titleValue, keyValue, dataIndexValue) {
    return this.createEnumColumn(
      titleValue,
      keyValue,
      dataIndexValue,
      //widthValue,
      { 1: "是", 0: "否" },
      "否"
    );
  }

  createEnumColumn(
    titleValue,
    keyValue,
    dataIndexValue,
    // widthValue,
    enumMap,
    defaultForNull
  ) {
    return {
      title: titleValue,
      dataIndex: dataIndexValue,
      key: keyValue,
      //  width: widthValue + "%",
      render: (value, record, index) => {
        if (!value && !(value === 0)) {
          return <span>{defaultForNull||'-'}</span>;
        }
        return <span>{enumMap[value]}</span>;
      }
    };
  }


  createEnumColumn2(
    titleValue,
    keyValue,
    dataIndexValue,
    // widthValue,
    _enum,
    defaultForNull
  ) {
    return {
      title: titleValue,
      dataIndex: dataIndexValue,
      key: keyValue,
      //  width: widthValue + "%",
      render: (value, record, index) => {
       
        return <span>{_enum.getTextFromValue(value)|| (defaultForNull||'-')}</span>;
      }
    };
  }
}
