import React, { Component } from 'react';
import { Breadcrumb, Button, Row, Form, Popover, Icon, Modal, Input, message } from 'antd';
import BaseInfo from '../financeApproval/BaseInfo'
import { Link } from 'react-router-dom';
import Table from 'CMPT/common/Table';
import Tip from 'CMPT/common/Tip';
import ApprovalChain from 'CMPT/common/ApprovalChain';
import CandPDetail from './CandPDetail';
import Print from '../receiptManage/Print'
import http from '@/http';
import { API } from '@/http/api';
import { AUTH_KEYS } from '@/utils/auth';
import AuthContainer from 'CMPT/common/auth/AuthContainer';
import "../financeApproval/finance.less"
import './bill.less'

const BreadcrumbItem = Breadcrumb.Item;
const Item = Form.Item;
const { TextArea } = Input;
const success = () => {
    message.success('催缴成功，我们将以短信形式通知付款人');
};
const info = () => {
    message.info('每小时只能催缴一次，请稍候再试');
};
const iInfo = {
    title1: '收款信息',
    t1: '实收金额',
    t2: "剩余应收金额"
}
const oInfo = {
    title1: '付款信息',
    t1: '实付金额',
    t2: "剩余应付金额"
}

class DetailInfo extends Component {
    state = {
        textDes: {},
        payRecordId: '',
        billType: undefined,//1.收2.付
        deleteType: 1,
        showMore: true,
        showDeleteModal: false,
        showReceiptModal: false,
        showRefundModal: false,
        billInfo: {},
        columns: [
            {
                title: '收款时间',
                dataIndex: 'date',
            }, {
                title: '收款金额(元)',
                dataIndex: 'amount',
            }, {
                title: '支付途径',
                dataIndex: 'channel',
                render: (value) => {
                    const payChannel = ['现金', '刷卡', '转账', '支付宝', '微信', '', '', '', '其他', '', '', '', '', '', '']
                    return (
                        <div>{payChannel[value - 1]}</div>
                    )
                }
            }, {
                title: '状态',
                dataIndex: 'auditStatus',
                render: (value) => {
                    const auditStatusList = ['审批中', '审批驳回', '审批通过'];
                    let color = ''
                    switch (value) { // 0 待审批，1 已驳回 2 通过 3 不通过
                        case 0:
                            color = '#FBBC06'
                            break
                        case 1:
                            color = '#E94437'
                            break;
                        case 3:
                            color = '#EE423D'
                            break
                        default:
                            color = '#E94437'
                    }
                    return (
                        <div><span style={{ color: color, marginRight: '6px' }}>●</span>{auditStatusList[value]}</div>
                    )
                }
            }, {
                title: '备注',
                dataIndex: 'auditComment',
            }, {
                title: '操作',
                key: 'account',
                render: (obj) => {
                    return (
                        <div className="flex">
                            <CandPDetail encode={obj.paymentEncode} {...this.props} />
                            <AuthContainer authCodes={AUTH_KEYS.OPERATE_FINANCE_BILL_PAY}>
                                <Link to={'/app/operate/finance/bill/make-collection/' + obj.billEncode + '?type=' + this.state.billType} className="table-operate-btn" style={{ display: obj.editFlag == 1 ? '' : 'none' }}>编辑</Link>
                            </AuthContainer>
                            <AuthContainer authCodes={AUTH_KEYS.OPERATE_FINANCE_BILL_DEL}>
                                <span className="table-operate-btn" onClick={this.delete.bind(this, 2, obj)} style={{ display: obj.auditStatus == 1 ? '' : 'none' }}>删除</span>
                            </AuthContainer>


                            {/* <span className="table-operate-btn" style={{display:obj.auditStatus == 1?'':'none'}}>编辑</span> */}

                        </div>
                    )
                }
            }
        ],
        data: [],
        receiptData: [],
        receiptColumns: [{
            title: '开据时间',
            dataIndex: 'receiptDate',
        }, {
            title: '开据金额(元)',
            dataIndex: 'receiptAmount',
        }, {
            title: '操作',
            key: 'receiptEncode',
            render: (value) => {
                return (
                    <div>
                        <Print receiptInfo={value} />
                    </div>
                )
            }
        }],
        stepList: [],
        loading: false,
        resetRefundInfo: undefined,
        isShowOverdue: false,
    }
    componentWillMount() {
        let type = this.props.location.search.substr(1).split('=')[1];
        this.setState({
            billType: type
        })
        let columnsTemp = this.state.columns;

        if (type == 1) {
            this.setState({
                textDes: iInfo
            })
        }
        else if (type == 2) {
            this.setState({
                textDes: oInfo,
                isShowOverdue: true
            })
            columnsTemp[0].title = '付款时间'
            columnsTemp[1].title = '付款金额(元)'
            this.setState({
                columns: columnsTemp
            })
        }
        else {
            this.props.history.push({ pathname: '/404' });
            console.log('404')
        }
    }
    componentDidMount() {
        this.getInfo();
        this.getHistoryRecord();
        this.getReceiptList();
        this.getAuditFlowList();
    }
    // POST 
    getAuditFlowList() {
        http({
            api: API.billGetAuditFlowList,
            data: {
                encode: this.props.match.params.id,
                type: 31
            }
        }).then(res => {
            if (res.data) {
                this.setState({
                    stepList: res.data
                })
            }
        })
    }
    getReceiptList() {
        http({
            api: API.billReceiptlist,
            data: {
                billEncode: this.props.match.params.id
            }
        }).then(res => {
            if (res.data) {
                this.setState({
                    receiptData: res.data.datas
                })
            }
        })
    }
    getHistoryRecord() {
        http({
            api: API.billpayGetPaymentList,
            data: {
                budgetType: this.state.billType, //1收2付
                billEncode: this.props.match.params.id
            }
        }).then((res) => {
            if (res.data) {
                this.setState({
                    data: res.data
                })
            }
        })
    }
    getInfo() {
        http({
            api: API.billGetById,
            data: {
                encode: this.props.match.params.id
            }
        }).then(res => {
            if (res.data) {
                console.log("loadCaptcha--------------", res.data);
                this.setState({
                    billInfo: res.data,

                })
            }
        })
    }
    callPill(value) {
        console.log(value);
        http({
            api: API.billRemind,
            data: {
                billEncode: value
            }
        }).then(res => {
            if (res.data) {
                if (res.data.success == 1) {
                    success();
                }
                if (res.data.success == 0) {
                    info();
                }
            }
        })
    }
    deleteBill() {
        this.setState({
            loading: true
        })
        http({
            api: API.billDropBill,
            data: {
                billEncode: this.props.match.params.id
            }
        }).then(res => {
            if (res.success) {
                message.success('账单删除成功');
                this.props.history.push({ pathname: '/app/operate/finance/bill' });
            }
            this.setState({
                loading: false
            })
        })
    }
    deletePay() {
        this.setState({
            loading: true
        })
        http({
            api: API.billpayDrop,
            data: {
                encode: this.state.payRecordId
            }
        }).then(res => {
            if (res.success) {
                message.success('支付记录删除成功');
                this.getHistoryRecord();
                this.getInfo();
            }
            this.setState({
                loading: false
            })
        })
    }
    delete(value, obj) {
        console.log(value, obj)
        this.setState({ showDeleteModal: true, deleteType: value });
        if (value == 2) {
            this.setState({
                payRecordId: obj.paymentEncode
            })
        }
    }
    handleOk() {
        if (this.state.deleteType == 1) {
            this.deleteBill();
        }
        if (this.state.deleteType == 2) {
            this.deletePay();
        }
        this.setState({
            showDeleteModal: false
        })
    }
    handleCancel() {
        this.setState({ showDeleteModal: false })
    }
    receipt() {
        this.setState({ showReceiptModal: true })
    }
    receiptOk() {
        const { billInfo } = this.state;
        this.props.form.validateFields((err, values) => {
            if (true) {
                console.log('Received values of form: ', values);
                if (!values.amount) {
                    message.error('请输入金额');
                    return false;
                }
                if (values.amount - billInfo.couldReceiptAmount > 0) {
                    message.error('本次收据金额不得大于待开收据金额，请检查后重新输入');
                    return false;
                }
                if (values.amount <= 0) {
                    message.error('请输入正确的开收据金额');
                    return false;
                }
                if (! /^\d{0,1}(\d{0,10})\.{0,1}(\d{1,2})?$/ig.test(values.amount)) {
                    message.error('开据金额精确至小数点后两位');
                    return false;
                }
                this.setState({
                    loading: true
                })
                http({
                    api: API.billReceipt,
                    data: {
                        billEncode: this.props.match.params.id,
                        amount: values.amount
                    }
                }).then(res => {
                    if (res.success) {
                        message.success('操作成功');
                        this.setState({ showReceiptModal: false })
                    }
                    this.setState({
                        loading: false
                    })
                })
            }
        });
    }
    receiptCancel() {
        this.setState({ showReceiptModal: false })
    }
    getRejectRefund() {
        http({
            api: API.billrefundGetRejectedRefund,
            data: {
                billEncode: this.state.billInfo.encode
            }
        }).then(res => {
            if (res.data) {
                this.setState({
                    resetRefundInfo: res.data
                })
            }
        })
    }
    refund() {
        const obj = this.state.billInfo;
        if (obj.refundStatus == 2 && obj.refundAudit == false) {
            message.error('请先删除上次退款记录');
        } else {
            this.setState({ showRefundModal: true });
            this.getRejectRefund();
        }
    }
    refundOk() {
        const { billInfo, resetRefundInfo } = this.state;
        this.props.form.validateFields((err, values) => {
            if (true) {
                console.log('Received values of form: ', values);
                if (!values.amount || values.amount <= 0) {
                    message.error('请输入正确的退款金额');
                    return false;
                }
                if (!values.reason) {
                    message.error('请输入退款原因');
                    return false;
                }
                if (values.amount - billInfo.paidAmount > 0) {
                    message.error('退款金额不得大于实收金额，请重新输入');
                    return false;
                }
                if (! /^\d{0,1}(\d{0,10})\.{0,1}(\d{1,2})?$/ig.test(values.amount)) {
                    message.error('退款金额精确至小数点后两位');
                    return false;
                }
                this.setState({
                    loading: true
                })
                if (resetRefundInfo) {
                    http({
                        api: API.billrefundUpdate,
                        data: {
                            billEncode: this.props.match.params.id,
                            amount: values.amount,
                            reason: values.reason,
                            refundEncode: resetRefundInfo.refundEncode
                        }
                    }).then(res => {
                        message.success('操作成功');
                        this.setState({ showRefundModal: false })
                    })
                    this.setState({
                        loading: false
                    })
                } else {
                    http({
                        api: API.billrefundRefund,
                        data: {
                            billEncode: this.props.match.params.id,
                            amount: values.amount,
                            reason: values.reason,
                            // refundEncode:''
                        }
                    }).then(res => {
                        if (res.code == '0') {
                            message.success('操作成功');
                            this.setState({ showRefundModal: false })
                        }
                        this.setState({
                            loading: false
                        })
                    })
                }

            }
        });
    }
    goEdit() {
        this.props.history.push(`/app/operate/finance/bill/add?type=${this.state.billType}&billEncode=${this.props.match.params.id}`)
    }
    refundCancel() {
        this.setState({ showRefundModal: false })
    }
    goshoufu(value) {
        const obj = this.state.billInfo;
        // this.props.history.push(`/app/operate/finance/bill/make-collection/${this.props.match.params.id}/?type=${value}`)
        if (obj.paymentReceiveAuditStatus == 4) {
            let text = obj.budgetType == 1 ? '有收款尚在审批中，请确认后再重新尝试' : '有付款尚在审批中，请确认后再重新尝试'
            message.error(text);
        } else if (obj.paymentStatus == 2 && obj.paymentAudit == false) {
            message.error('请先删除上次收支记录');
        } else {
            this.props.history.push(`/app/operate/finance/bill/make-collection/${this.props.match.params.id}/?type=${value}`)
        }
    }
    render() {
        const { billInfo, textDes, resetRefundInfo, isShowOverdue } = this.state;
        const { getFieldDecorator } = this.props.form;
        const auditComponent = (
            <Popover overlayClassName="bo-audit-popover" content={<ApprovalChain stepList={this.state.stepList} />} trigger="click" placement="bottomRight">
                <div className="clickPopover"><i className="iconfont iconapprove"></i><span>查看审批流程</span></div>
            </Popover>
        )
        return (
            <div className="billWrap">
                <Form>
                    <Modal
                        className="passModal"
                        title='确认删除此账单'
                        closable={false}
                        visible={this.state.showDeleteModal}
                        onOk={this.handleOk.bind(this)}
                        okText='确定'
                        width={400}
                        onCancel={this.handleCancel.bind(this)}
                        okButtonProps={{ loading: this.state.loading }}
                    >
                    </Modal>
                    <Modal
                        className="passModal"
                        title='开收据'
                        closable={false}
                        visible={this.state.showReceiptModal}
                        onOk={this.receiptOk.bind(this)}
                        okText='确定'
                        width={400}
                        onCancel={this.receiptCancel.bind(this)}
                        okButtonProps={{ loading: this.state.loading }}
                    >
                        <div className="cx-modal-11">
                            <Row className="fs12 b12">
                                实收金额：{billInfo.paidAmount}元
                        </Row>
                            <Row className="fs12 b12">
                                已开收据金额：{billInfo.receiptAmount}元
                        </Row>
                            <Row className="fs12 b12">
                                待开收据金额：{billInfo.couldReceiptAmount}元
                        </Row>
                            <Item label="本次收据金额" style={{ width: '240px' }}>
                                {getFieldDecorator('amount', {
                                    rules: [{
                                        required: this.state.showReceiptModal, message: '请输入',
                                    }]
                                })(
                                    <Input placeholder="请输入" suffix="元" />
                                )}
                            </Item>
                        </div>
                    </Modal>
                    <Modal
                        className="passModal"
                        title='申请退款'
                        closable={false}
                        visible={this.state.showRefundModal}
                        onOk={this.refundOk.bind(this)}
                        okText='确定'
                        width={400}
                        onCancel={this.refundCancel.bind(this)}
                        okButtonProps={{ loading: this.state.loading }}
                    >
                        <div className="cx-modal-11">
                            <Item label="退款金额" style={{ width: '240px' }}>
                                {getFieldDecorator('amount', {
                                    initialValue: resetRefundInfo && resetRefundInfo.amount,
                                    rules: [{
                                        required: this.state.showRefundModal, message: '请输入',
                                    }]
                                })(
                                    <Input placeholder="请输入" suffix="元" type="number" />
                                )}
                            </Item>
                            <Item label="退款原因">
                                {getFieldDecorator('reason', {
                                    initialValue: resetRefundInfo && resetRefundInfo.reason,
                                    rules: [{
                                        required: true, message: '请输入',
                                    }]
                                })(
                                    <TextArea rows={4} placeholder="请输入" style={{ marginTop: '8px' }} maxLength={200} />
                                )}
                            </Item>
                        </div>
                    </Modal>
                    <div className="bill-header">
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <Link style={{ color: '#333' }} to={{ pathname: "/app/operate/finance/bill" }}>
                                    财务收支
                                 </Link>
                            </BreadcrumbItem>
                            <BreadcrumbItem>
                                <Link style={{ color: '#333' }} to={{ pathname: "/app/operate/finance/bill" }}>
                                    账单管理
                                </Link>
                            </BreadcrumbItem>
                            <BreadcrumbItem>账单详情</BreadcrumbItem>
                        </Breadcrumb>
                        <div className="rButton">
                            <AuthContainer authCodes={AUTH_KEYS.OPERATE_FINANCE_BILL_DEL}>
                                <Button type="link" className="btn-warning ml20" onClick={this.delete.bind(this, 1)}>删除</Button>
                            </AuthContainer>
                            <AuthContainer authCodes={AUTH_KEYS.OPERATE_FINANCE_BILL_RECEIPT}>
                                <Button type="link" className="ml20" onClick={this.receipt.bind(this)} style={{ display: billInfo.receiptFlag == 1 ? '' : 'none' }}>开收据</Button>
                            </AuthContainer>
                            <AuthContainer authCodes={AUTH_KEYS.OPERATE_FINANCE_BILL_REFUND}>
                                <Button type="link" className="ml20" onClick={this.refund.bind(this)} style={{ display: billInfo.refundFlag == 1 ? '' : 'none' }}>申请退款</Button>
                            </AuthContainer>
                            <AuthContainer authCodes={AUTH_KEYS.OPERATE_FINANCE_BILL_CALL}>
                                <Button type="link" className="ml20" onClick={this.callPill.bind(this, this.props.match.params.id)} style={{ display: billInfo.remindFlag == 1 ? '' : 'none' }}>催缴</Button>
                            </AuthContainer>
                            {/* <AuthContainer authCodes={AUTH_KEYS.OPERATE_FINANCE_BILL_EDIT}>
                            <Button type="primary" onClick={this.goEdit.bind(this)} className="ml20" style={{display:billInfo.editFlag==1?'':'none'}}>编辑</Button>
                        </AuthContainer>     */}
                            <AuthContainer authCodes={AUTH_KEYS.OPERATE_FINANCE_BILL_PAY}>
                                <Button type="primary" className="ml20" onClick={this.goshoufu.bind(this, 1)} style={{ display: billInfo.payFlag == 1 && this.state.billType == 1 ? '' : 'none' }}>收款</Button>
                            </AuthContainer>
                            <AuthContainer authCodes={AUTH_KEYS.OPERATE_FINANCE_BILL_PAY}>
                                <Button type="primary" className="ml20" onClick={this.goshoufu.bind(this, 2)} style={{ display: billInfo.payFlag == 1 && this.state.billType == 2 ? '' : 'none' }}>付款</Button>
                            </AuthContainer>
                        </div>
                    </div>
                    <div style={{ display: billInfo.auditStatus == 0 ? '' : 'none' }}>
                        <Tip type="wait" title="审批中" hint={true} secondTitle={'当前审批人：' + billInfo.auditUserName} hintNode={auditComponent} />
                    </div>
                    <div style={{ display: billInfo.auditStatus == 1 ? '' : 'none' }}>
                        <Tip type="warn" title="审批驳回" hint={true} hintNode={auditComponent} des={billInfo.auditComment} />
                    </div>
                    {/* <div style={{display:billInfo.auditStatus==2?'':'none'}}>
                    <Tip type="pass" title="审批通过" hint={true} hintNode={auditComponent}/>
                </div> */}
                    <BaseInfo {...this.state} {...this.props} noshowIcon={isShowOverdue} />
                    <div className="approval-baseInfo" style={{ display: this.state.data.length != 0 ? '' : 'none' }}>
                        <div className="title">{textDes.title1}</div>
                        <Row className="fs12 mb24">
                            <span>{textDes.t1}：{billInfo.paidAmount}元</span>
                            <span style={{ display: billInfo.couldPayAmount == 0 ? 'none' : '', marginLeft: '20px' }}>{textDes.t2}：{billInfo.couldPayAmount}元</span>
                        </Row>
                        <div className="infor-table">
                            <Table rowKey="paymentEncode" columns={this.state.columns} data={this.state.data} pagination={false} />
                        </div>
                    </div>
                    <div className="approval-baseInfo" style={{ display: this.state.receiptData.length != 0 ? '' : 'none' }}>
                        <div className="title">收据信息</div>
                        <Row className="fs12 mb24">
                            <span>已开收据金额：{billInfo.receiptAmount}元</span>
                            <span style={{ marginLeft: '20px' }}>待开收据金额：{billInfo.couldReceiptAmount}元</span>
                        </Row>
                        <div className="infor-table">
                            <Table rowKey="receiptEncode" columns={this.state.receiptColumns} data={this.state.receiptData} pagination={false} />
                        </div>
                    </div>
                </Form>
            </div>
        )
    }
}

export default Form.create({ name: 'detailInfo' })(DetailInfo);