import React, { Component } from "react";
import { Modal, Form, Input } from "antd";
import { API } from "@/http/api";
import http from "@/http";

class NewCatalogAdd extends Component {
  getAdd(values) {
    const config = {
      api: API.newHelpCatalogAdd,
      showLoading: true,
      data: {
        catalogName: values.catalogName,
        catalogType: this.props.catalogType
      }
    };
    http(config).then(res => {
      if (res.success) {
        this.handleCancel();
        this.props.refresh();
      }
    });
  }
  edit(values) {
    const config = {
      api: API.newHelpCatalogEdit,
      showLoading: true,
      data: {
        encode: this.props.editId,
        catalogName: values.catalogName
      }
    };
    http(config).then(res => {
      if (res.success) {
        this.handleCancel();
        this.props.refresh();
      }
    });
  }
  handleOk = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (this.props.title) {
          this.edit(values);
        } else {
          this.getAdd(values);
        }
      }
    });
  };
  componentWillReceiveProps(nextProps) {
    if (this.props.title !== nextProps.title) {
      this.props.form.setFieldsValue({ catalogName: nextProps.title });
    }
  }
  handleCancel = () => {
    this.props.assignCancel();
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    let my = this;
    //console.log("  render-->", my.props.title);
    let name = my.props.title;
    return (
      <Modal
        title={this.props.editId ? "编辑目录" : "添加目录"}
        visible={this.props.visible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        className="assign-follow-modal"
      >
        <Form className="login-form">
          <Form.Item label="目录名称">
            {getFieldDecorator("catalogName", {
              initialValue: my.props.title,
              rules: [
                { required: true, message: "请输入目录名称" },
                { max: 8, message: "目录名称不能超过8位" }
              ]
            })(
              <Input
                className="login-input"
                placeholder="请输入(最长8位)"
                maxLength={8}
              />
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default Form.create({ name: "NewCatalogAdd" })(NewCatalogAdd);
