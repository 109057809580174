import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Breadcrumb } from 'antd';
import ReceiptList from './ReceiptList';
import '../billManage/bill.less'
const BreadcrumbItem = Breadcrumb.Item;
class ReceiptManage extends Component {
    state = {

    }
    goAdd = () => {
        console.log(this.props)
        this.props.history.push('/app/operate/contract/main/add')
    }
    render() {
        return (
            <div className="contract-list-out financeWrap">
                <div className="contract-header">
                    <Breadcrumb>
                        <BreadcrumbItem>财务收支</BreadcrumbItem>
                        <BreadcrumbItem>收据管理</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <div className="contract-content bgColor-receipt">
                    <ReceiptList />
                </div>
            </div>
        );
    }
}

export default ReceiptManage;