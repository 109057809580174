import React, { Component } from 'react';
import { Link} from 'react-router-dom'

class JobItemContract extends Component {
    toDetail =()=>{
        console.log(this.props)
        // this.props.history.push(`/app/operate/contract/detail/${this.props.encode}?audit=${this.props.auditType}`)
    }
    render() {
        return (
            <Link to={{ pathname : `/app/operate/contract/detail/${this.props.encode}`,search:`?audit=${this.props.auditType}`}} className="job-item" onClick={this.toDetail}>
              <span className="job-item-contract-type">{this.props.status == 2 ? '变更' : '新签'} </span> 
                <p className="job-item-title">
                    {this.props.name}    
              </p>
              <p className="job-item-text">
                {this.props.location}
              </p>
              <p>
                  发起时间：{this.props.time}
              </p>
            </Link>
        )
    }
}

export default JobItemContract
