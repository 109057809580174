import React, { Component } from 'react';
import regex from '@/utils/regex';
import { API } from '@/http/api';
import http from '@/http';
import DateRange from 'CMPT/common/DateRange';
import { getNewPhone} from '@/utils/index';
import Upload from 'CMPT/common/Upload';
import BOSelect from 'CMPT/common/Select';
import MultiCascader from 'CMPT/common/MultiCascader';
import moment from 'moment';
import debounce from 'lodash/debounce';
import { Form, Row, Col, Input, Icon, DatePicker, Cascader, message } from 'antd';
const Item = Form.Item;
const { phoneNew} = { ...regex }
class BaseInfo extends Component {
    state = {
        commonSpan: 6,
        contractStartDate: null, //合同开始时间
        contractEndDate: null, // 合同结束时间
        contractDisabledDate: null, // 续签时合同的开始时间不能小于旧合同的结束时间
        alterStartDisabledDate: null, // 变更时间不能小于旧合同的开始时间
        alterEndDisabledDate: null, // 变更时变更时间不能超过旧合同的结束时间
        exemptBeforeStartDate: null, //合同免租金合同开始前开始时间
        exemptBeforeEndDate: null, //合同免租金合同开始前结束时间，不可改，和合同开始时间一致
        exemptAfterStartDate: null, //合同免租金合同结束后开始时间，不可改，和合同结束时间一致
        exemptAfterEndDate: null, // 合同免租金合同结束后结束时间
        cascaderOptions: [],
        fieldNames: {
            label: 'name', value: 'code', children: 'subAreas'
        },
        departmentPerson: [],
        departmentPersonNames: '',
        blockList: [],
        customerList: [],
        projectsList: [],
        projectName: '',
        cascaderData: [],
        type: 1,
        logoUrl: '',
        decisionObj: {}, // 决策信息，根据项目来
        taxNumberRules: [],
        identityRules: []
    }
    componentDidMount() {
        this.getSelectData()
        this.getDefaultData()
        this.setState({
            identityRules: [{validator: this.idCardValidator}],
            taxNumberRules: [{validator: this.TaxNumberValidator}]
        })
    }
    getSelectData = () => {
        const locationConfig = {
            api: API.areaChinaArea
        }
        // 省市区
        http(locationConfig).then(loRes => {
            this.setState({
                cascaderOptions: loRes.data
            })
        })
        // 部门人员
        this.searchUser()
    }
    getcustomer = () => {
        const customerList = this.state.customerList
        if (customerList && customerList.length) return
        this.searchCustomer()
    }
    searchCustomer = (value) => {
        const config = {
            api: API.customerGetCustomerList,
            data: {
                orderByList: [2]
            }
        }
        if (value) {
            config.data.fuzzyKey = value
        }
        http(config).then(res => {
            const list = res.data || []
            this.setState({
                customerList: list.map(per => {
                    return {
                        value: per.encode,
                        name: `${per.name}-${per.mobile}-${per.customerType === 1 ? '公司' : '个人'}`
                    }
                })
            })
        })
    }
    searchUser = (keyWord, projects) => {
        const deptConfig = {
            api: API.userGetList,
            data: {
                pageSize: null
            }
        }
        if (keyWord) {
            deptConfig.data.nameLike = keyWord
        }
        if (projects) {
            deptConfig.data.projectEncodes = projects // list
        }
        http(deptConfig).then(deptRes => {
            this.setState({
                departmentPerson: this.transDeptData(deptRes.data.datas)
            })
        })
    }
    customerChange = (e) => {
        if (!e) {
            this.setCustomerData({})
        }
    }
    getDefaultData = () => {
        const { info, type, isEdit } = this.props
        let contractDisabledDate = undefined
        let alterStartDisabledDate = undefined
        let alterEndDisabledDate = undefined
        if (info.contractDateRange && info.contractDateRange) {
            this.setState({
                contractStartDate: info.contractDateRange[0],
                contractEndDate: info.contractDateRange[1],
                exemptBeforeStartDate: info.contractDateRange[0],
                exemptBeforeEndDate: info.exemptBeforeDateRange ? info.exemptBeforeDateRange[1] : undefined,
                exemptAfterStartDate: info.exemptAfterDateRange ? info.exemptAfterDateRange[0] : undefined,
                exemptAfterEndDate: info.contractDateRange[1]
            })
        }
        if (type === 2 && info.oldContractDateRange) {
            contractDisabledDate = info.oldContractDateRange[1].add(1, 'days')
        } else if (type === 3 && info.oldContractDateRange) {
            alterStartDisabledDate = info.oldContractDateRange[0]
            alterEndDisabledDate = info.oldContractDateRange[1]
        }
        
        const projectConfig = {
            api: API.commonProjectfilter
        }
        // 先请求完项目接口后，再去回显区域信息
        // 项目
        http(projectConfig).then(pro => {
            const keyObj = {name: 'name', value: 'encode', no: 'projectNo'}
            this.setState({
                projectsList: this.transKVData(pro.data, keyObj)
            })
            if (info.projects) {
                this.getFloorZone(info.projects, null, info.projectName, info.projectNo)
            }
        })
        if (info.customerBlock) {
            this.getBlocks(info.customerLocation)
        }
        this.setState({
            type: this.props.type,
            contractDisabledDate,
            alterStartDisabledDate,
            alterEndDisabledDate,
            logoUrl: info.customerCompanyLogo || ''
        })
    }
    transDeptData = (list) => {
        let transList = []
        list.forEach(per => {
            transList.push({value: `${per.departmentEncode},${per.idCode}`, name: `${per.departmentName}-${per.name}`})
        })
        return transList
    }
    transKVData = (list, keyObj) => {
        let target = []
        if (list) {
            list.forEach(per => {
                target.push({value: `${per[keyObj.value]}`, name: `${per[keyObj.name]}`, no: `${per[keyObj.no]}`})
            })
        }
        return target
    }
    contractDateChange = (field, value) => {
        const setFieldsValue = this.props.form.setFieldsValue
        this.props.reGenerateStatus(true, true)
        if (field === 'startValue') {
            this.setState({
                contractStartDate: value,
                exemptBeforeStartDate: value,
                exemptBeforeEndDate: undefined
            }, () => {
                setFieldsValue({exemptBeforeDateRange: [value, undefined]})
                if (this.state.contractStartDate && this.state.contractEndDate) {
                    this.checkContractPeriod()
                }
            })
        } else {
            this.setState({
                contractEndDate: value,
                exemptAfterEndDate: value,
                exemptAfterStartDate: undefined
            }, () => {
                setFieldsValue({exemptAfterDateRange: [undefined, value]})
                if (this.state.contractStartDate && this.state.contractEndDate) {
                    this.checkContractPeriod()
                }
            })
        }
    }
    // 检查所选时间是否和其他合同重复
    checkContractPeriod = () => {
        const config = {
            api: API.contractCheckPeriod,
            data: this.getCheckPeriodData()
        }
        if (!config.data) return
        http(config).then(res => {
        })
    }
    getCheckPeriodData = () => {
        const {getFieldValue} = this.props.form
        const { info, type, isEdit } = this.props
        let obj = {}
        const rooms = getFieldValue('projectRoom')
        if (!rooms || !rooms.length) {
            message.warning('请选选择房间')
            return
        }
        obj.startTime = this.state.contractStartDate.format('YYYY-MM-DD')
        obj.endTime = this.state.contractEndDate.format('YYYY-MM-DD')
        if (isEdit) {
            obj.encode = info.contractEncode
        } else if (type === 2 || type === 3) {
            obj.parentEncode = info.contractEncode
        }
        obj.zoneEncodes = rooms.map(per => per.id)
        obj.signType = type || ''
        return obj
    }
    exemptBeforeDateChange = (field, value) => {
        this.props.reGenerateStatus(true)
        if (field === 'startValue') {
            this.setState({
                exemptBeforeStartDate: value
            })
        } else {
            this.setState({
                exemptBeforeEndDate: value
            })
        }
    }
    exemptAfterDateChange = (field, value) => {
        this.props.reGenerateStatus(true)
        if (field === 'startValue') {
            this.setState({
                exemptAfterStartDate: value
            })
        } else {
            this.setState({
                exemptAfterEndDate: value
            })
        }
    }
    alterDateChange = (value) => {
        this.props.reGenerateStatus(true, true)
        this.setState({
            contractStartDate: value,
            exemptBeforeStartDate: value
        })
        const {getFieldValue, setFieldsValue} = this.props.form
        const dateRange = getFieldValue('contractDateRange')
        if (dateRange && dateRange.length) {
            setFieldsValue({contractDateRange: [value,dateRange[1]]})
        }
    }
    deptPersonSelect = (value) => {
        const list = this.state.departmentPerson
        const item = list.find(per => {
            return per.value === value
        })
        this.setState({
            departmentPersonNames: item.name
        })
    }
    getSelectList = (list) => {
        let target = []
        if (list) {
            list.forEach(per => {
                target.push({value: `${per.key}`, name: per.value})
            })
        }
        return target
    }
    customerTypeChange = (value) => {
        let taxNumberRules = [{validator: this.TaxNumberValidator}]
        let identityRules = [{validator: this.idCardValidator}]
        if (value === '1') {
            taxNumberRules.push({required: true, message: '请输入'})
        } else if (value === '2') {
            identityRules.push({required: true, message: '请输入'})
        }
        this.setState({
            taxNumberRules: taxNumberRules,
            identityRules: identityRules
        })
    }
    getFloorZone = (value, options, name, projectNo) => {
        if (options) {
            const { reGenerateStatus } = this.props
            reGenerateStatus(true, true)
        }
        const projectList = this.state.projectsList
        const item = projectList.find(p => {
            return p.value === value
        })
        const decisionConfig = {
            api: API.contractGetDecisionInfo,
            path: {projectCode: value}
        }
        // 决策接口,根据项目
        http(decisionConfig).then(deRes => {
            let decisionObj = {}
            decisionObj.defaultContractTemplate = deRes.data.defaultRentTemplateEncode
            decisionObj.defaultEstateTemplate = deRes.data.defaultPropTemplateEncode
            deRes.data.decisionList.forEach(de => {
                if (de.key === 'property_terms_and_charges') { // 物业计费条款方式 月/日
                    decisionObj.estateCalculateMethod = de.value
                } else if (de.key === 'defined_contract_code_switch') { // 编号是否自动生成
                    decisionObj.contractNoAuto = de.value === '1'
                    if (this.props.isEdit) {
                        this.props.form.setFieldsValue({contractNumber: this.props.info.contractNumber})
                    } else if (decisionObj.contractNoAuto && this.props.type !== 3) {
                        this.props.form.setFieldsValue({contractNumber: deRes.data.contractCode})
                    }
                } else if (de.key === 'days_of_advance_payment') { // 提前付款天数
                    decisionObj.aheadDays = de.value
                } else if (de.key === 'property_price') {
                    decisionObj.estatePrice = de.value
                } else if (de.key === 'late_fee') {
                    decisionObj.delayPayment = de.value
                } else if (de.key === 'rent_period_division') { // 租期划分方式
                    decisionObj.periodDevideMethod = de.value
                }
            })
            this.setState({
                decisionObj
            })
        })
        // 根据项目id筛选部门人员
        this.searchUser(null, [value])
        let config = {
            api: API.commonFloorZoneFilter,
            data: {
                projectEncode: value,
                merchantStatus: 1,
                rentStatus: 1
            }
        }
        const rooms = this.props.info.projectRoom
        if (rooms) {
            config.data.zoneEncodes = rooms.map(per => per.id)
        }
        http(config).then(res => {
            let targetList = []
            if (res.data) {
                res.data.forEach(per => {
                    if (per.zones && per.zones.length) {
                        let obj = {}
                        obj.name = per.name
                        obj.id = per.encode
                        obj.no = per.floorNo
                        obj.child = []
                        let allItem = {
                            name: '所有房间',
                            id: 'all-rooms',
                            parentId: per.encode,
                            canSelect: true, // 是否能点击，存在无区域的房间时不能点击
                            isSelectAll: false, // 是否全选
                            canSelectNum: per.zones.length
                        }
                        per.zones.forEach(zo => {
                            let zoObj = {}
                            zoObj.name = zo.zoneNo
                            zoObj.zoneNo = zo.zoneNo
                            zoObj.id = zo.encode
                            zoObj.parentId = zo.floorEncode
                            zoObj.price = zo.unitPrice
                            zoObj.area = zo.rentalArea
                            obj.child.push(zoObj)
                            if (!zoObj.area) {
                                allItem.canSelect = false
                            }
                        })
                        obj.child.unshift(allItem)
                        targetList.push(obj)
                    }
                })
            }
            this.setState({
                projectName: name ? name : (item ? item.name : null),
                projectNo: projectNo ? projectNo : (item ? item.no : null),
                cascaderData: targetList
            })
            if (options) {
                // 手动切换项目后，房间要清空
                const {setFieldsValue} = this.props.form
                setFieldsValue({projectRoom: undefined})
            }
        })
    }
    projectRoomChange = (value, flag) => {
        if (!flag) { // 第一次挂载的时候不需要触发
            this.props.reGenerateStatus(true, true)
        }
    }
    getBlocks = (value) => {
        const regionId = value[2]
        const boConfig = {
            api: API.areaSubAreas,
            data: {
                encode: regionId
            }
        }
        http(boConfig).then(boRes => {
            let list = boRes.data && boRes.data.map(item => {
                return {
                    name: item.name,
                    value: item.code
                }
            })
            this.setState({
                blockList: list || []
            })
        })
    }
    setLogo = (value) => {
        if (value.file.status === 'done') {
            let item = value.file.response.file
            this.setState({
                logoUrl: item.publicUrl
            })
        }
    }
    getCustomerDetail = (value) => {
        let config = {
            api: API.customerGet,
            path: {
                encode: value
            }
        }
        http(config).then(res => {
            const data = res.data
            this.setCustomerData(data || {})
        })
    }
    setCustomerData = (data) => {
        const {setFieldsValue} = this.props.form
        setFieldsValue({customerName: data.name || undefined})
        setFieldsValue({customerType: data.customerType ? String(data.customerType) : undefined})
        setFieldsValue({customerIndustry: data.industryType ? String(data.industryType) : undefined})
        setFieldsValue({customerTaxNumber: data.creditNumber || undefined})
        setFieldsValue({RelatedPersonPhone: getNewPhone(data.mobile) || undefined})
        this.customerTypeChange(String(data.customerType))
    }
    alterDisabledDate = (value) => {
        let alterEndDisabledDate = this.state.alterEndDisabledDate.valueOf()
        if (!this.props.isEdit) {
            alterEndDisabledDate += 1 * 24 * 3600000
        }
        return value.valueOf() < this.state.alterStartDisabledDate.valueOf() || value.valueOf() > alterEndDisabledDate
    }
    handleBeforeUpload = (file) => {
        const size = file.size
        if (size / 1024 > 500) {
            message.error('请上传500k以内的图片')
            return false
        }
    }
    removeImg = (e) => {
        if (e) e.stopPropagation()
        this.setState({
            logoUrl: ''
        })
    }
    phoneValidator = (rule, value, cb) => {
        if (value) {
            if (/^\d*$/.test(value)) {
                if (value.startsWith('1') || value.startsWith('0')) {
                    if (value.startsWith('1') && !regex.phone.test(value)) {
                        cb('请输入正确的手机号')
                    } else if (value.startsWith('0') && !/^0\d{9,10}$/.test(value)) {
                        cb('请输入正确的固话号')
                    } else {
                        cb()
                    }
                } else {
                    cb('请输入正确的联系电话')
                }
            } else {
                cb('请输入正确的联系电话')
            }
        } else {
            cb()
        }
    }
    idCardValidator = (rule, value, cb) => {
        if (value && !regex.idCard.test(value)) {
            cb('身份证不合法')
        } else {
            cb()
        }
    }
    TaxNumberValidator = (rule, value, cb) => {
        if (value && !/^[0-9A-Z]*$/.test(value)) {
            cb('统一社会信用代码输入不合法')
        } else {
            cb()
        }
    }
    render() {
        const { getFieldDecorator } = this.props.form
        const { info, init } = this.props
        const customerTypeList = this.getSelectList(init.customerType)
        const industryList = this.getSelectList(init.industryType)
        return (
            <div className="contract-baseinfo">
                <div className="info-body">
                    <Form>
                        <div className="contract-block">
                            <Row className="info-title"><span>企业信息</span></Row>
                            <Row gutter={24}>
                                <Col span={this.state.commonSpan}>
                                    <Item label="选择客户">
                                        <BOSelect data={this.state.customerList} placeholder="请选择" showSearch filterOption={false} allowClear
                                                  selectMethod={this.getCustomerDetail} onSearch={debounce(this.searchCustomer, 500)}
                                                  onDropdownVisibleChange={this.getcustomer} onChange={this.customerChange}/>
                                    </Item>
                                </Col>
                                <Col span={this.state.commonSpan}>
                                    <Item label="客户名称">
                                        {getFieldDecorator('customerName', {
                                            rules: [{
                                                required: true, message: '请选择',
                                            }],
                                            initialValue: info.customerName
                                        })(
                                            <Input placeholder="请输入" maxLength={50} />
                                        )}
                                    </Item>
                                </Col>
                                <Col span={this.state.commonSpan}>
                                    <Item label="客户类型">
                                        {getFieldDecorator('customerType', {
                                            rules: [{
                                                required: true, message: '请选择',
                                            }],
                                            initialValue: info.customerType
                                        })(
                                            <BOSelect data={customerTypeList} placeholder="请选择" selectMethod={this.customerTypeChange} />
                                        )}
                                    </Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={this.state.commonSpan}>
                                    <Item label="客户行业">
                                        {getFieldDecorator('customerIndustry', {
                                            initialValue: info.customerIndustry
                                        })(
                                            <BOSelect data={industryList} placeholder="请选择" />
                                        )}
                                    </Item>
                                </Col>
                                <Col span={this.state.commonSpan}>
                                    <Item label="统一社会信用代码">
                                        {getFieldDecorator('customerTaxNumber', {
                                            rules: this.state.taxNumberRules,
                                            initialValue: info.customerTaxNumber
                                        })(
                                            <Input placeholder="请输入" maxLength={18} />
                                        )}
                                    </Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={this.state.commonSpan}>
                                    <Item label="联系人">
                                        {getFieldDecorator('RelatedPerson', {
                                            rules: [{
                                                required: true, message: '请输入',
                                            }],
                                            initialValue: info.RelatedPerson
                                        })(
                                            <Input placeholder="请输入" maxLength={20} />
                                        )}
                                    </Item>
                                </Col>
                                <Col span={this.state.commonSpan}>
                                    <Item label="联系电话">
                                        {getFieldDecorator('RelatedPersonPhone', {
                                            rules: [{
                                                required: true, message: '联系电话不能为空',
                                            }, { pattern: phoneNew, message: '请输入正确的联系电话' },{
                                            }],
                                            initialValue: info.RelatedPersonPhone,
                                            getValueFromEvent: e => (getNewPhone(e))
                                        })(
                                            <Input placeholder="请输入" maxLength={13} />
                                        )}
                                    </Item>
                                </Col>
                                <Col span={this.state.commonSpan}>
                                    <Item label="身份证">
                                        {getFieldDecorator('RelatedPersonIdentity', {
                                            rules: this.state.identityRules,
                                            initialValue: info.RelatedPersonIdentity
                                        })(
                                            <Input placeholder="请输入" maxLength={18} />
                                        )}
                                    </Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={this.state.commonSpan}>
                                    <Item label="邮箱地址">
                                        {getFieldDecorator('customerEmail', {
                                            rules: [
                                                {
                                                    type: 'email', message: '邮箱格式错误',
                                                }
                                            ],
                                            initialValue: info.customerEmail
                                        })(
                                            <Input placeholder="请输入" />
                                        )}
                                    </Item>
                                </Col>
                                <Col span={this.state.commonSpan}>
                                    <Item label="银行卡号">
                                        {getFieldDecorator('customerBankCard', {
                                            rules: [
                                                {pattern: /^\d*$/, message: '请输入正确的银行卡号'}
                                            ],
                                            initialValue: info.customerBankCard
                                        })(
                                            <Input placeholder="请输入" maxLength={19} />
                                        )}
                                    </Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={this.state.commonSpan}>
                                    <Item label="省市区">
                                        {getFieldDecorator('customerLocation', {
                                            initialValue: info.customerLocation
                                        })(
                                            <Cascader options={this.state.cascaderOptions} fieldNames={this.state.fieldNames} onChange={this.getBlocks}
                                                      placeholder="请选择" suffixIcon={<Icon type="caret-down"/>}/>
                                        )}
                                    </Item>
                                </Col>
                                <Col span={this.state.commonSpan}>
                                    <Item label="板块">
                                        {getFieldDecorator('customerBlock', {
                                            initialValue: info.customerBlock
                                        })(
                                            <BOSelect notFoundContent="请先选择省市区" data={this.state.blockList} placeholder="请选择" />
                                        )}
                                    </Item>
                                </Col>
                                <Col span={this.state.commonSpan}>
                                    <Item label="详细地址">
                                        {getFieldDecorator('customerAddress', {
                                            initialValue: info.customerAddress
                                        })(
                                            <Input placeholder="请输入" maxLength={50} />
                                        )}
                                    </Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{width: 120}}>
                                    <Item label="公司logo">
                                        <Upload action={API.uploadAdd.url}
                                                beforeUpload={this.handleBeforeUpload}
                                                accept=".png,.jpg"
                                                onChange={this.setLogo}
                                                showUploadList={false}
                                                listType="picture-card">
                                                {this.state.logoUrl && <div className="contract-upload-img">
                                                    <img src={this.state.logoUrl} alt="" /><Icon type="close-circle" onClick={this.removeImg}/>
                                                </div>}
                                        </Upload>
                                    </Item>
                                </Col>
                            </Row>
                        </div>
                        <div className="contract-block">
                            <Row className="info-title"><span>房源信息</span></Row>
                            <Row gutter={24}>
                                <Col span={this.state.commonSpan}>
                                    <Item label="签约项目">
                                        {getFieldDecorator('projects', {
                                            rules: [
                                                {required: true, message: '请选择'}
                                            ],
                                            initialValue: info.projects
                                        })(
                                            <BOSelect placeholder="请选择" data={this.state.projectsList} selectMethod={this.getFloorZone} />
                                        )}
                                    </Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={9}>
                                    <Item label="签约房间">
                                        {getFieldDecorator('projectRoom', {
                                            rules: [
                                                {required: true, message: '请选择'}
                                            ],
                                            initialValue: info.projectRoom
                                        })(
                                            <MultiCascader data={this.state.cascaderData} placeholder="请选择"
                                                           onChange={this.projectRoomChange} />
                                        )}
                                    </Item>
                                </Col>
                            </Row>
                        </div>
                        <div className="contract-block">
                            <Row className="info-title"><span>合同信息</span></Row>
                            <Row gutter={24}>
                                <Col span={this.state.commonSpan}>
                                    <Item label="合同编号">
                                        {getFieldDecorator('contractNumber', {
                                            initialValue: info.contractNumber
                                        })(
                                            <Input placeholder="请输入" disabled={this.state.type === 3 ? 'disabled':this.state.decisionObj.contractNoAuto} maxLength={20} />
                                        )}
                                    </Item>
                                </Col>
                                {this.state.type === 3 ? <Col span={this.state.commonSpan}>
                                    <Item label="变更生效时间">
                                        {getFieldDecorator('contractAlterDate', {
                                            rules: [
                                                {required: true, message: '请选择'}
                                            ],
                                            initialValue: info.contractAlterDate
                                        })(
                                            <DatePicker style={{width: '100%'}}
                                                        onChange={this.alterDateChange}
                                                        disabledDate={this.alterDisabledDate}
                                            />
                                        )}
                                    </Item>
                                </Col>: null}
                            </Row>
                            <Row gutter={24}>
                                <Col span={this.state.commonSpan * 3}>
                                    <Item label="合同期限">
                                        {getFieldDecorator('contractDateRange', {
                                            rules: [
                                                {required: true, message: '请选择'}
                                            ],
                                            initialValue: info.contractDateRange
                                            })(
                                            <DateRange onDateChange={this.contractDateChange} startDisabledDate={this.state.contractDisabledDate}
                                                startValue={this.state.contractStartDate} endValue={this.state.contractEndDate} 
                                                startDisabled={this.props.type === 3 ? true : false} showFast />
                                        )}
                                    </Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Item label="合同内免租期">
                                        <Col span={10}>
                                            <Item>
                                            {getFieldDecorator('exemptBeforeDateRange', {
                                                initialValue: info.exemptBeforeDateRange
                                                })(
                                                    <DateRange startValue={this.state.exemptBeforeStartDate} endValue={this.state.exemptBeforeEndDate}
                                                    onDateChange={this.exemptBeforeDateChange} endDisabledDate={this.state.contractEndDate} startDisabled />
                                                )}
                                                <span className="info-sub-title">前</span>
                                            </Item>
                                        </Col>
                                        <Col span={10}>
                                            <Item>
                                                {getFieldDecorator('exemptAfterDateRange', {
                                                    initialValue: info.exemptAfterDateRange
                                                    })(
                                                        <DateRange startValue={this.state.exemptAfterStartDate} endValue={this.state.exemptAfterEndDate}
                                                        onDateChange={this.exemptAfterDateChange} endDisabled
                                                        startDisabledDate={this.state.contractStartDate} />
                                                )}
                                                <span className="info-sub-title">后</span>
                                            </Item>
                                        </Col>
                                    </Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={4}>
                                    <Item label="签约日期">
                                        {getFieldDecorator('contractSignDate', {
                                            rules: [
                                                {required: true, message: '请选择'}
                                            ],
                                            initialValue: info.contractSignDate
                                        })(
                                            <DatePicker style={{width: '100%'}} allowClear={false} />
                                        )}
                                    </Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={4}>
                                    <Item label="签约人">
                                        {getFieldDecorator('contractSignPerson', {
                                            rules: [
                                                {required: true, message: '请选择'}
                                            ],
                                            initialValue: info.contractSignPerson
                                        })(
                                            <BOSelect placeholder="请选择" data={this.state.departmentPerson} showSearch filterOption={false}
                                                      selectMethod={this.deptPersonSelect} onSearch={debounce(this.searchUser, 500)}/>
                                        )}
                                    </Item>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </div>
            </div>
        );
    }
}

export default Form.create({ name: 'BaseInfo' })(BaseInfo);