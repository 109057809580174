import React, { Component } from 'react';
import moment from 'moment';
import {
    DatePicker, message, Row, Col
} from 'antd';
export default class BODateRange extends Component {
    state = {
        currentSelect: ''
    };
    disabledStartDate = (startValue) => {
        const { endValue, startDisabledDate, useEndDisabledDate, endDisabledDate } = this.props
        if (startDisabledDate && !endValue) { // 续签时限制
            return startValue.valueOf() <= startDisabledDate.valueOf()
        }
        if (!startValue || !endValue) {
            return false
        }
        let end = endValue.valueOf() + 1*24*3600*1000
        if (useEndDisabledDate) {
            end = endDisabledDate.valueOf()
        }
        const start = startValue.format('YYYY-MM-DD')
        const start20 = moment(start).add(20, 'years')
        return startValue.valueOf() > end || start20.valueOf() < end || (startDisabledDate ? startValue.valueOf() < startDisabledDate.valueOf() : false)
    }
    
    disabledEndDate = (endValue) => {
        const {startValue, endDisabledDate } = this.props
        if (!endValue || !startValue) {
            return false
        }
        const end = endValue.format('YYYY-MM-DD')
        const end20 = moment(end).subtract(20, 'years').add(1, 'days')
        return endValue.valueOf() < startValue.valueOf() || end20.valueOf() > startValue.valueOf() || (endDisabledDate ? endValue.valueOf() > endDisabledDate.valueOf()+1*24*3600*1000 : false)
    }

    onDateChange = (field, value, flag) => {
        const { onChange, onDateChange, startValue, endValue } = this.props
        if (flag) {
            this.setState({
                currentSelect: ''
            });
        }
        onDateChange(field, value)
        if (onChange) {
            if (startValue && field === 'endValue') {
                onChange([startValue, value])
            } else if (endValue && field === 'startValue') {
                onChange([value, endValue])
            }
        }
    }

    onStartChange = (value) => {
        this.onDateChange('startValue', value, true)
    }

    onEndChange = (value) => {
        this.onDateChange('endValue', value, true)
    }
    fastClick = (e) => {
        if (!this.props.startValue) {
            message.warning('请选择开始时间')
            return
        }
        const endValue = this.props.startValue.format('YYYY-MM-DD')
        const year = e.target.className.split(' ')[0]
        this.setState({
            currentSelect: year
        })
        this.onDateChange('endValue', moment(endValue).add({'one': 1, 'three': 3, 'five': 5}[year], 'years').subtract(1, 'days'))
    }
    render() {
        const { currentSelect } = this.state
        const { showFast, startDisabled, endDisabled, startValue, endValue, preventClear } = this.props
        return (
            <div className="bo-daterange">
            <Row gutter={24}>
                <Col className="gutter-row" span={10}>
                    <DatePicker
                        disabledDate={this.disabledStartDate}
                        format="YYYY-MM-DD"
                        value={startValue}
                        disabled={startDisabled}
                        placeholder="开始时间"
                        allowClear={!preventClear}
                        onChange={this.onStartChange}
                    />
                </Col>
                <Col className="gutter-row" span={4}>
                    <span className="bo-daterange-line">~</span>
                </Col>
                <Col className="gutter-row" span={10}>
                    <DatePicker
                        disabledDate={this.disabledEndDate}
                        format="YYYY-MM-DD"
                        value={endValue}
                        disabled={endDisabled}
                        placeholder="结束时间"
                        allowClear={!preventClear}
                        onChange={this.onEndChange}
                    />
                </Col>
            </Row>
                {showFast ? (
                    <span className="bo-daterange-fast" onClick={this.fastClick}>
                        <span className={currentSelect === 'one' ? 'one active' : 'one'}>1年</span>
                        <span className={currentSelect === 'three' ? 'three active' : 'three'}>3年</span>
                        <span className={currentSelect === 'five' ? 'five active' : 'five'}>5年</span>
                    </span>
                ) : null}
            </div>
        )
    }
}
