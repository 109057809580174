import React, { Component } from 'react';
import './index.less';
import GoodList from 'CMPT/goodsManage/GoodList';
import Select from 'CMPT/common/Select';
import { Breadcrumb, Tabs, Input } from 'antd';
import { API } from '@/http/api';
import http from '@/http';
import Pagination from "CMPT/common/Pagination";
import storage from '@/utils/storage';
const { Search } = Input;
const { TabPane } = Tabs;
const BreadcrumbItem = Breadcrumb.Item;

export default class NoProject extends Component{

    state = {
        goodStatus: [
            { value: '所有商品', key: '' },
            { value: '销售中', key: '2' },
            { value: '待上架', key: '1' },
            { value: '待审核', key: '0' },
            { value: '违规下架', key: '4' },
            { value: '已删除', key: '90' }
        ],
        activeKey: '',
        searchValue: '',
        tableData: [],
        categoryOneList: [],
        categoryTwoList: [],
        shopList: [],
        loading: false,
        params: {
            categoryOne: undefined,
            categoryTwo: undefined,
            shop: undefined,
            pageSize: 10,
            pageNo: 1,
        },
        total: 0
    }

    componentDidMount () {
        this.getCategory()
        this.searchShop()
        this.getGoodsStatus(()=> {
            let statusKey = storage.get('goods_status') || null;
            if (statusKey) {
                this.setState({activeKey: statusKey}, () => {
                    this.getGoodsList()
                })
                return;
            }
            this.getGoodsList()
        })
        
    }
    getCategory = () => {
        const config = {
            api: API.commonCategory,
        }
        http(config).then(res => {
            if (res.success) {
                let categoryOneList = res.data && res.data.map(per => {
                    return {
                        name: per.name,
                        value: per.code,
                        childList: per.childList && per.childList.map(ii => {
                            return {
                                name: ii.name,
                                value: ii.code
                            }
                        })
                    }
                }) || []
                this.setState({
                    categoryOneList
                })
            }
        })
    }
    searchShop = (keyWord) => {
        const config = {
            api: API.commonShopList,
        }
        if (keyWord) {
            config.data = {
                shopName: keyWord
            }
        }
        http(config).then(res => {
            if (res.success) {
                const shopList = res.data && res.data.map(per => {
                    return {
                        value: per.code,
                        name: per.shopName
                    }
                }) || []
                this.setState({shopList})
            }
        })
    }
    paginationChange = (pageNo, pageSize) => {
        // console.log(pageNo, pageSize)
        // this.setState({
        //     pageNo: pageNo,
        //     pageSize: pageSize
        // }, ()=> {
        //     this.getGoodsList()
        // })
        let params = this.state.params;
        params.pageNo = pageNo;
        params.pageSize = pageSize;
        this.setState({params}, this.getGoodsList)
    }

    // 获取商品状态枚举
    getGoodsStatus = (cb) => {
        const config = {
            api: API.itemStatus,
            showLoading: true
        }
        http(config).then(res => {
            if (res && res.success && res.data && res.data.itemList) {
                this.setState({goodStatus: [{ value: '所有商品', key: '' }, ...res.data.itemList]})
            }
            cb()
        })
    } 
    // 获取商品列表
    getGoodsList = () => {
        const params = this.state.params
        const cateCode = params.categoryTwo ? params.categoryTwo : params.categoryOne
        const config = {
            api: API.itemQueryList,
            data: {
                hasActivityCode: "",
                keyword: this.state.searchValue,
                orderByList: [0],
                orderList: [0],
                pageNo: params.pageNo,
                pageSize: params.pageSize,
                status: this.state.activeKey,
                categoryCode: cateCode,
                shopCodeList: params.shop ? [params.shop] : null
            },
            showLoading: true
        }

        http(config).then(res => {
            if (res && res.success && res.data && res.data.datas) {
                this.setState({
                    tableData: [...res.data.datas],
                    total: res.data.totalRecord
                })
            }
        })
    } 
    // 切换状态
    tabsChange = (value) => {
        this.setState({activeKey: value}, () => {
            storage.set('goods_status', value)
            this.getGoodsList()
        })
    }
    // 搜索按钮
    onSearchOn = (keywords) => {
        this.setState({activeKey: '', searchValue: keywords}, () => {
            this.getGoodsList()
        })
    }


    // 输入搜索框
    searchChange = (e) => {
        this.setState({searchValue: e.target.value})
    }
    // resetParams = () => {
    //     let params = this.state.params
    //     Object.keys(params).forEach(key => {
    //         if (key === 'keyWord') {
    //             params[key] = ''
    //         } else {
    //             params[key] = undefined
    //         }
    //     })
    //     params.pageNo = 1
    //     params.pageSize = 10
    //     this.setState({
    //         params
    //     }, this.getOrderList)
    //     this.storageParams()
    // }
    // 重置
    resetSearch = () => {
        let params = this.state.params
        Object.keys(params).forEach(key => {
            params[key] = undefined
        })
        params.pageNo = 1
        params.pageSize = 10
        this.setState({params, searchValue: ''}, () => {
            this.getGoodsList()
        })
    }
    selectChange = (attr, data) => {
        if (attr === 'categoryOne') {
            const categoryOneList = this.state.categoryOneList
            const categoryTwoList = categoryOneList.find(per => per.value === data)
            if (categoryTwoList) {
                this.setState({
                    categoryTwoList: categoryTwoList.childList
                })
            }
        }
        let params = this.state.params
        params[attr] = data
        if (data === 99) {
            params[attr] = undefined
        }
        params.pageNo = 1
        this.setState({ params }, this.getGoodsList)
    }
    

    render() {
        const { goodStatus, activeKey, searchValue, params } = this.state;
        return (
            <div className="enterprise-auth-con">
                <div className='enterprise-auth-header'>
                    <Breadcrumb>
                        <BreadcrumbItem>商品管理</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <div className="content_box">
                    <ul className="status-tab">
                        {goodStatus.map(per => {
                            return <li key={per.value} className={activeKey === per.key ? 'active' : '' }
                                        onClick={() => {this.tabsChange(per.key)}}>{per.value}</li>
                        })}
                    </ul>
                    <div className="goods_fliter">
                        <Select data={this.state.categoryOneList} placeholder="一级分类" value={params.categoryOne}
                            selectMethod={this.selectChange.bind(this, 'categoryOne')} style={{ width: '124px' }} />
                        <Select data={this.state.categoryTwoList} placeholder="二级分类" value={params.categoryTwo}
                            selectMethod={this.selectChange.bind(this, 'categoryTwo')} style={{ width: '124px' }} />
                        <Select data={this.state.shopList} placeholder="店铺" value={params.shop} showSearch
                                filterOption={false} onSearch={this.searchShop}
                                selectMethod={this.selectChange.bind(this, 'shop')} style={{ width: '124px' }} />
                        <Search
                            placeholder="请输入商品编码/商品名称"
                            enterButton="搜索"
                            className="goods_search"
                            value={searchValue}
                            // size="large"
                            onChange={value => {this.searchChange(value)}}
                            onSearch={value => {this.onSearchOn(value)}}
                        />
                        <span className="goods_reset" onClick={this.resetSearch}>重置</span>
                    </div>
                    <GoodList  refresh={this.getGoodsList} tableData={this.state.tableData}/> 
                    {
                        this.state.tableData.length > 0 && <div className="table-footer">
                            <Pagination change={this.paginationChange.bind(this)} total={this.state.total} current={this.state.params.pageNo} />
                        </div>
                    }
                </div>

            </div>
        );
    }
}
