/**
 * @file 新增客户/编辑客户 - 供应商信息
 */
import React from 'react';

import { Modal, Form, Checkbox, Radio, Input } from 'antd';

import Upload from 'CMPT/common/widget/Upload';
import BusinessCategories from './BusinessCategories';
import { ManagementModel } from './CommonConst.js';

import './EditSupplierInfo.less';

export default function EditSupplierInfo({ form, completeCheckedDisabled, isAgent, toggleAgent }) {
	const { getFieldDecorator, setFieldsValue, getFieldValue } = form;

	const completeChecked = completeCheckedDisabled || getFieldValue('completeChecked');

	function changeCompleteChecked(e) {
		if (e.target.checked) {
			setFieldsValue({
				completeChecked: true
			});
			toggleAgent(true);
		} else {
			Modal.confirm({
				title: '是否确认暂不完善资料，确定后已填写资料将被清空',
				onOk() {
					clean();
				}
			});
		}
	}

	function changeAgentChecked(e) {
		if (e.target.checked) {
			toggleAgent(true);
		} else {
			if (hasAgentInfo()) {
				Modal.confirm({
					title: '是否确定不是代理人，确定后将清空已上传代理人相关资料',
					onOk() {
						cleanAgent();
					}
				});
			} else {
				toggleAgent(false);
			}
		}
	}

	function clean() {
		toggleAgent(true);

		setFieldsValue({
			completeChecked: false,
			openLicensePic: {},
			taxpayerCertificatePic: {},
			corporateIdPic1: {},
			corporateIdPic2: {},
			agentIdPic1: {},
			agentIdPic2: {},
			powerPdf: {},
			businessCategories: [],
			listXls: {},
			taxRegistrationPic: {},
			orgPic: {}
		});
	}

	function hasAgentInfo() {
		const agentIdPic1 = getFieldValue('agentIdPic1');

		if (agentIdPic1 && (agentIdPic1.name || agentIdPic1.url)) {
			return true;
		}

		const agentIdPic2 = getFieldValue('agentIdPic2');
		if (agentIdPic2 && (agentIdPic2.name || agentIdPic2.url)) {
			return true;
		}

		const powerPdf = getFieldValue('powerPdf');
		if (powerPdf && (powerPdf.name || powerPdf.url)) {
			return true;
		}

		return false;
	}

	function cleanAgent() {
		toggleAgent(false);

		setFieldsValue({
			agentIdPic1: {},
			agentIdPic2: {},
			powerPdf: {}
		});
	}

	return (
		<div className="new_customer-add-body edit-supplier-info">
			<h6>供应商信息</h6>

			<Form.Item className="complete-checked-area">
				{getFieldDecorator('completeChecked')(<Checkbox style={{ display: 'none' }}>是否完善资料</Checkbox>)}
				<Checkbox onChange={changeCompleteChecked} checked={completeChecked} disabled={completeCheckedDisabled}>
					是否完善资料
				</Checkbox>
				<span className="tip">完善资料才能发布商品，使用招标功能，选择否则需要客户在商城端进行补充资料认证</span>
			</Form.Item>

			<Form.Item label="佣金比例" className="commission-area">
				{getFieldDecorator('commission', {
					rules: [
						{
							pattern: /^99$|^(\d|[1-9]\d)(\.\d{1,5})*$/,
							message: '仅能输入数字，0<=n< 100，小数点后最多保留5位'
						},
						{ required: true, message: '请输入佣金比例' }
					]
				})(<Input suffix="%" placeholder={'佣金比例'} />)}
			</Form.Item>

			<Form.Item label="经营模式">
				{getFieldDecorator('managementModel', {
					rules: [
						{
							required: true,
							message: '请选择'
						}
					]
				})(
					<Radio.Group>
						{ManagementModel.map((item) => {
							return (
								<Radio key={item.value} value={item.value}>
									{item.label}
								</Radio>
							);
						})}
					</Radio.Group>
				)}
			</Form.Item>

			{completeChecked && (
				<React.Fragment>
					<Form.Item
						required
						label={
							<React.Fragment>
								开户许可证<span className="title-tip">支持JGP/JPEG/PNG/BMP格式，最大5M</span>
							</React.Fragment>
						}
					>
						{getFieldDecorator('openLicensePic', {
							rules: [ { validator: getValidateUpload('请上传开户许可证') } ]
						})(<Upload accept=".jpeg,.jpg,.png,.bmp" maxSize="5M" />)}
					</Form.Item>

					<Form.Item
						required
						label={
							<React.Fragment>
								一般纳税人资格证<span className="title-tip">支持JGP/JPEG/PNG/BMP格式，最大5M</span>
							</React.Fragment>
						}
					>
						{getFieldDecorator('taxpayerCertificatePic', {
							rules: [ { validator: getValidateUpload('请上传一般纳税人资格证') } ]
						})(<Upload accept=".jpeg,.jpg,.png,.bmp" maxSize="5M" />)}
					</Form.Item>

					<div className="two-part-title required">
						法人身份证正反面<span className="title-tip">支持JGP/JPEG/PNG/BMP格式，最大5M</span>
					</div>
					<div className="two-part">
						<Form.Item required>
							{getFieldDecorator('corporateIdPic1', {
								rules: [ { validator: getValidateUpload('请上传法人身份证正面') } ]
							})(<Upload accept=".jpeg,.jpg,.png,.bmp" maxSize="5M" />)}
						</Form.Item>

						<Form.Item required>
							{getFieldDecorator('corporateIdPic2', {
								rules: [ { validator: getValidateUpload('请上传法人身份证反面') } ]
							})(<Upload accept=".jpeg,.jpg,.png,.bmp" maxSize="5M" />)}
						</Form.Item>
					</div>

					<Form.Item>
						<Checkbox onChange={changeAgentChecked} checked={isAgent}>
							我是代理人
						</Checkbox>
					</Form.Item>

					{isAgent && (
						<React.Fragment>
							<div className="two-part-title required">
								代理人身份证正反面<span className="title-tip">支持JGP/JPEG/PNG/BMP格式，最大5M</span>
							</div>
							<div className="two-part">
								<Form.Item>
									{getFieldDecorator('agentIdPic1', {
										rules: [ { validator: getValidateUpload('请上传代理人身份证正面') } ]
									})(<Upload accept=".jpeg,.jpg,.png,.bmp" maxSize="5M" />)}
								</Form.Item>

								<Form.Item>
									{getFieldDecorator('agentIdPic2', {
										rules: [ { validator: getValidateUpload('请上传代理人身份证反面') } ]
									})(<Upload accept=".jpeg,.jpg,.png,.bmp" maxSize="5M" />)}
								</Form.Item>
							</div>

							<Form.Item
								required
								label={
									<React.Fragment>
										授权书<span className="title-tip">PDF文件，最大5M</span>
										<a download href="/template/企业授权书模板.docx">
											下载授权书模板
										</a>
									</React.Fragment>
								}
							>
								{getFieldDecorator('powerPdf', {
									rules: [ { validator: getValidateUpload('请上传授权书') } ]
								})(<Upload accept=".pdf" listType="text" maxSize="5M" />)}
							</Form.Item>
						</React.Fragment>
					)}

					<Form.Item label="可经营类目" required>
						{getFieldDecorator('businessCategories', {
							rules: [ { validator: validateBusinessCategories } ]
						})(<BusinessCategories />)}
					</Form.Item>

					<Form.Item
						label={
							<React.Fragment>
								上线产品/商品目录<span className="title-tip">支持XLS/XLSX文件，最大5M</span>
								<a download href="/template/上线产品商品清单.xlsx">
									下载上线产品/商品目录模板
								</a>
							</React.Fragment>
						}
					>
						{getFieldDecorator('listXls')(<Upload accept=".xls,.xlsx" listType="text" maxSize="5M" />)}
					</Form.Item>

					<Form.Item
						label={
							<React.Fragment>
								税务登记证<span className="title-tip">如提交三证合一的营业执照，则无需提交</span>
							</React.Fragment>
						}
					>
						{getFieldDecorator('taxRegistrationPic')(<Upload accept=".jpeg,.jpg,.png,.bmp" maxSize="5M" />)}
					</Form.Item>

					<Form.Item
						label={
							<React.Fragment>
								组织机构代码证<span className="title-tip">如提交三证合一的营业执照，则无需提交</span>
							</React.Fragment>
						}
					>
						{getFieldDecorator('orgPic')(<Upload accept=".jpeg,.jpg,.png,.bmp" maxSize="5M" />)}
					</Form.Item>
				</React.Fragment>
			)}
		</div>
	);
}

function getValidateUpload(requiredMessage) {
	return function validateUpload(rule, value) {
		if (value) {
			if (value.status === 'error') {
				return Promise.reject('上传失败，请重新上传');
			}
			if (value.status === 'uploading') {
				return Promise.reject('上传中，请稍后');
			}
			if (value.url) {
				return Promise.resolve();
			}
		}
		return Promise.reject(requiredMessage);
	};
}

function validateBusinessCategories(rule, value) {
	if (!value || value.length === 0) {
		return Promise.reject('请完善可经营类目');
	}

	for (const { file } of value) {
		if (!file || !file.url) {
			return Promise.reject('请完善对应类目经营资质');
		}
	}

	return Promise.resolve();
}
