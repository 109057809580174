import React, { Component } from 'react';
import { Input ,DatePicker  } from 'antd';
import Table from 'CMPT/common/Table';
import BOSelect from 'CMPT/common/Select';
import http from '@/http';
import { API } from '@/http/api';
import debounce from 'lodash/debounce';
const {  RangePicker } = DatePicker;
const Search = Input.Search;

class OperateRecord extends Component {
    state = {
        columns: [
            {
                title: '合同编号',
                dataIndex: 'contractNum',                
            }, {
                title: '租客名称',
                dataIndex: 'tenantName',
            }, {
                title: '操作人',
                dataIndex: 'operatePeo',
            }, {
                title: '操作类型',
                dataIndex: 'operateType',
            }, {
                title: '操作时间',
                dataIndex: 'operateTime',
            }
        ],
        data: [],
        startDateMoment: undefined,
        endDateMoment: undefined, 
        departmentPerson: [],
        departmentPersonNames: '',
        selectParams: {
            bizEncode: undefined,
            createTimeStart: undefined, 
            createTimeEnd: undefined,
            createCode: undefined, //操作人id
        }
    }
    componentDidMount() {
        this.getDepartment()
        this.setCodeAndSearch(this.props.code)
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.code !== this.state.selectParams.bizEncode) {
            this.setCodeAndSearch(nextProps.code)
        }
    }
    setCodeAndSearch = (code) => {
        
        let selectParams = this.state.selectParams
        selectParams.bizEncode =code
        this.setState({
            selectParams
        }, () => {
            this.getLists()
        })
    }
     //操作时间
     handleDate = (value, date) => {
        let selectParams = this.state.selectParams;
        selectParams.createTimeStart = date[0];
        selectParams.createTimeEnd = date[1];
        this.setState({
            selectParams,
            startDateMoment: value[0],
            endDateMoment: value[1]
        },() => {
            this.getLists();
        })
    }
    //部门人员
    getDepartment() {
        const deptConfig = {
            api: API.userGetList,
            data: {
                pageSize: null
            }
        }
        http(deptConfig).then(deptRes => {
            this.setState({
                departmentPerson: this.transDeptData(deptRes.data.datas)
            })
        })
    }
    transDeptData = (list) => {
        let transList = []
        list.forEach(per => {
            transList.push({value: `${per.idCode}`, name: `${per.departmentName}-${per.name}`})
        })
        return transList
    }
    searchUser = (value) => {
        const deptConfig = {
            api: API.userGetList,
            data: {
                nameLike: value,
                pageSize: null
            }
        }
        if (!value) {
            delete deptConfig.data.nameLike
        }
        http(deptConfig).then(deptRes => {
            this.setState({
                departmentPerson: this.transDeptData(deptRes.data.datas)
            })
        })
    }
    deptPersonSelect = (value) => {
        let selectParams = this.state.selectParams;
        selectParams.createCode = value;
        const list = this.state.departmentPerson
        const item = list.find(per => {
            return per.value === value
        })
        this.setState({
            selectParams,
            departmentPersonNames: item.name
        },() => {
            this.getLists();
        })
    }
    getLists() {
        const config = {
            api: API.contractOperateRecord,
            data: this.state.selectParams,
            showLoading: true
        };
        http(config).then(res => {   
            const data = res.data;
            //表格数据
            const list = data && data.map((item, index) => {
                return {
                    key: index,
                    contractNum: item.contractCode || '--',
                    tenantName: item.customerName,
                    operatePeo: item.createName,
                    operateType: item.typeStr,
                    operateTime: item.createTimeStr            
                }
            })
            this.setState({
                data: list || []
            })             
        })
    }
    reset = () => {
        let selectParams = this.state.selectParams
        this.setState({
            startDateMoment: undefined,
            endDateMoment: undefined, 
            selectParams: {
                bizEncode: selectParams.bizEncode,
                createTimeStart: undefined, 
                createTimeEnd: undefined,
                createCode: undefined
            }
        },() => {
            this.getLists() 
        })
    }
    render() {
        const {selectParams, startDateMoment, endDateMoment} = this.state
        return (
            <div className="operate-record">
                <div className="operate-search">                
                    <BOSelect style={{width:280}} placeholder="请选择" showSearch filterOption={false}
                              value={selectParams.createCode} data={this.state.departmentPerson}
                              selectMethod={this.deptPersonSelect} onSearch={debounce(this.searchUser, 500)}/>
                    <span className="operate-span">操作时间</span>
                    <RangePicker value={[startDateMoment,endDateMoment]} onChange={this.handleDate} />
                    <span className="operate-span operate-margin" onClick={this.reset}>重置</span>
                </div>
                <div className="operate-list">
                    <Table pagination={false} columns={this.state.columns} data={this.state.data} />
                </div>
            </div>
        );
    }
}

export default OperateRecord;