import {
    AJAX_TYPE
} from '../config';

// complain service

const prefix = 'ordercomplain';

export default {
    // 新增工单
    [`${prefix}Add`]: {
        url: '/web/order/complain/add',
        type: AJAX_TYPE.POST
    },
    // 取消工单
    [`${prefix}Cancel`]: {
        url: '/web/order/complain/cancel',
        type: AJAX_TYPE.POST
    },
    // 验收工单
    [`${prefix}Check`]: {
        url: '/web/order/complain/check',
        type: AJAX_TYPE.POST
    },
    // 查看工单
    [`${prefix}Detail`]: {
        url: '/web/order/complain/detail/:orderCode',
        type: AJAX_TYPE.POST
    },
    // 派发工单
    [`${prefix}Dispatch`]: {
        url: '/web/order/complain/dispatch',
        type: AJAX_TYPE.POST
    },
    // 可查看维修工集合,支持联想
    [`${prefix}GetLaborList`]: {
        url: '/web/order/complain/getLaborList',
        type: AJAX_TYPE.POST
    },
    // 处理工单
    [`${prefix}Handle`]: {
        url: '/web/order/complain/handle',
        type: AJAX_TYPE.POST
    },
    // 投诉列表过滤条件
    [`${prefix}Init`]: {
        url: '/web/order/complain/init',
        type: AJAX_TYPE.GET
    },
    // 工单列表(支持web和APP端)
    [`${prefix}List`]: {
        url: '/web/order/complain/list',
        type: AJAX_TYPE.POST
    },
    // 投诉状态统计
    [`${prefix}StatusCount`]: {
        url: '/web/order/complain/statusCount',
        type: AJAX_TYPE.POST
    },
}
