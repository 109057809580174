import { AJAX_TYPE } from "../config";

const prefix = "crmCustomer";

export default {
  // 分页
  [`${prefix}List`]: {
    url: "/web/crmcustomer/all/list",
    type: AJAX_TYPE.POST
  },

  // 企业的审核详情
  [`${prefix}Detail`]: {
    url: "/web/crmcustomer/all/get/:encode",
    type: AJAX_TYPE.GET
  },
  [`${prefix}Upgrade`]: {
    url: "/web/crmcustomer/all/upgrade",
    type: AJAX_TYPE.POST
  },
  [`${prefix}Add`]: {
    url: "/web/crmcustomer/all/add",
    type: AJAX_TYPE.POST
  },
  [`${prefix}Edit`]: {
    url: '/web/crmcustomer/all/edit',
    type: AJAX_TYPE.POST
  },
  // 企业经营资质
  [`${prefix}ManageInfo`]: {
    url: '/web/crm/enterprise/queryCategoryList',
    type: AJAX_TYPE.POST
  }
};
