import React, { Component } from 'react';
import StepZ from 'CMPT/common/StepZ';
import http from '@/http';
import { API } from '@/http/api';


class RelatedContract extends Component {
    state = {
        relatedContract: []
    }
    getRelatedContract(code) {
        const config = {
            api: API.contractHistory,
            path: {enCode: code},
            showLoading: true
        };
        http(config).then(res => {
               this.setState({
                    relatedContract: res.data
               })
                
        })
    }
    componentDidMount() {
        this.getRelatedContract(this.props.code)
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.code !== this.props.code) {
            this.getRelatedContract(nextProps.code)
        }
    }
    render() {
        return (
            <div className="related-contract">
                <StepZ  list={this.state.relatedContract} history={this.props.history}/>
            </div>
        );
    }
}

export default RelatedContract;