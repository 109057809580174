import {
    AJAX_TYPE
} from '../config';

// Role.Role

const prefix = 'enterpriseAuth';

export default {
    // 操作员-审核记录
    [`${prefix}OperatorAuditRecord`]: {
        url: ' /api/isx/v1/admin/audit/auditRecord/:code',
        type: AJAX_TYPE.GET
    },
    // --------------- new ---------------
    // 认证列表查询  /api/isx
    [`${prefix}Query`]: {
        url: '/api/isx/v1/basis/supplier/query-audit',
        type: AJAX_TYPE.POST
    },
    // 查看详情
    [`${prefix}GetAuthDetail`]: {
        url: '/api/isx/v1/basis/supplier/getAuthDetail',
        type: AJAX_TYPE.POST
    },

    // 查看详情-2
    [`${prefix}GetSupplierAuthDetail`]: {
        url: '/api/isx/v1/admin/audit/getSupplierAuthDetail',
        type: AJAX_TYPE.POST
    },
    // 审核 / 驳回
    [`${prefix}Audit`]: {
        url: '/api/isx/v1/basis/supplier/admin/audit',
        type: AJAX_TYPE.POST
    },
    // 审核记录
    [`${prefix}AuditRecord`]: {
        url: '/api/isx/v1/basis/supplier/admin/auditRecord/:supplierId',
        type: AJAX_TYPE.GET
    },
    // 司机-认证列表查询
    [`${prefix}ListDriverAuditByPage`]: {
        url: '/api/isx/v1/driver/listDriverAuditByPage',
        type: AJAX_TYPE.POST
    },
    // 司机-查看详情
    [`${prefix}DriverAuditInfo`]: {
        url: '/api/isx/v1/driver/driverAuditInfo',
        type: AJAX_TYPE.POST
    },
    // 司机-审核 / 驳回
    [`${prefix}DoAudit`]: {
        url: '/api/isx/v1/driver/doAudit',
        type: AJAX_TYPE.POST
    },
    // 车辆-认证列表查询
    [`${prefix}QueryAuditByPage`]: {
        url: '/api/isx/v1/admin/audit/queryAuditByPage',
        type: AJAX_TYPE.POST
    },
    // 车辆-查看详情
    [`${prefix}VehicleGetAuthDetail`]: {
        url: '/api/isx/v1/admin/audit/getAuthDetail',
        type: AJAX_TYPE.POST
    },
    // 车辆-审核 / 驳回
    [`${prefix}VehicleDoAudit`]: {
        url: '/api/isx/v1/admin/audit/doAudit',
        type: AJAX_TYPE.POST
    },
    // 车辆/出租信息/求租信息-审核记录
    [`${prefix}VehicleAuditRecord`]: {
        url: '/api/isx/v1/admin/audit/auditRecord/:code',
        type: AJAX_TYPE.GET
    },
    // 出租设备审核列表
    [`${prefix}QueryRentRecord`]: {
        url: '/api/isx/v1/admin/audit/releaseInfo/auditList',
        type: AJAX_TYPE.POST
    },

    // 出租设备审核详情
    [`${prefix}QueryRentDetail`]: {
        url: '/api/isx/v1/admin/audit/releaseInfo/releaseDetail',
        type: AJAX_TYPE.POST
    },

    // 出租设备审核操作
    [`${prefix}QueryRentOperate`]: {
        url: '/api/isx/v1/admin/audit/releaseInfo/auditReleaseInfo',
        type: AJAX_TYPE.POST
    },


    // --------------- --------------- ---------------
    // 分页
    [`${prefix}List`]: {
        url: '/web/crm/enterprise/list',
        type: AJAX_TYPE.POST
    },
    // 企业客源列表
    [`${prefix}QueryCustomerResourceList`]: {
        url: '/web/crm/enterprise/queryCustomerResourceList',
        type: AJAX_TYPE.POST
    },

    // 企业的审核详情
    [`${prefix}Detail`]: {
        url: '/web/crm/enterprise/get/:code',
        type: AJAX_TYPE.GET
    },

    // 通过 或者  驳回
    [`${prefix}Do`]: {
        url: '/web/crm/enterprise/authentication',
        type: AJAX_TYPE.POST
    },

    //  企业可经营类目列表
    [`${prefix}CategoryList`]: {
        url: '/web/crm/enterprise/queryCategoryList',
        type: AJAX_TYPE.POST
    },

     // 下载企业授权书模板Word
     [`${prefix}DownAuth`]: {
        url: '/web/crm/enterprise/downAuthTemplate',
        type: AJAX_TYPE.GET
    },

    // 下载上线产品清单Excel
     [`${prefix}DownProduct`]: {
        url: '/web/crm/enterprise/downOnlineProductTemplate',
        type: AJAX_TYPE.GET
    },

    //  操作员认证列表
    [`${prefix}AuditOperatorList`]: {
         url: '/api/isx/v1/admin/audit/operator/auditOperatorList',
         type: AJAX_TYPE.POST
     },
     //  操作员认证
     [`${prefix}AuditOperator`]: {
         url: '/api/isx/v1/admin/audit/operator/auditOperator',
         type: AJAX_TYPE.POST
     },
     //  获取操作员详情
     [`${prefix}getOperatorAuditDetail`]: {
         url: '/api/isx/v1/admin/audit/operator/getOperatorAuditDetail/:code',
         type: AJAX_TYPE.GET
     },

     //  企业类型
     [`${prefix}GetCompanyType`]: {
        url: '/api/isx/v1/basis/supplier/getCompanyType',
        type: AJAX_TYPE.GET
    },


     
}