import { AJAX_TYPE } from "../config";

// 公告

const prefix = "serviceInfo";

export default {
  [`${prefix}GetList`]: {
    url: "/api/isx/v1/admin/audit/serviceInformation/queryAuditByPage",
    type: AJAX_TYPE.POST
  },

  [`${prefix}GetDetail`]: {
    url: "/api/isx/v1/admin/audit/serviceInformation/detail/:code",
    type: AJAX_TYPE.GET
  },

  [`${prefix}Audit`]: {
    url: "/api/isx/v1/admin/audit/serviceInformation/audit",
    type: AJAX_TYPE.POST
  },

  


  
  

  
  

  

  

};
