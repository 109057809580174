import React, { Component } from "react";
import { Modal, Breadcrumb, Row, Input, Button, DatePicker, Switch,Tooltip, Checkbox, message } from "antd";
import Table from "CMPT/common/Table";
import { VerifyTypeConfig } from "./userConfig.js";
import Select from "CMPT/common/Select";
import { Link } from "react-router-dom";
import moment from 'moment';
import PageInfo from "@/utils/pageInfo.js";
import { convertAuthenticationToLabel,UserLabelType,UserStatusType ,UserSupplierType} from 'CMPT/operate/customerConfig'
import { convertEnumToMap } from "CMPT/common.js";
import TableRenderHelp from "@/utils/TableRenderHelp.js";
 import UserLabelGroup from './UserLabelGroup';
import UseLabelManagerModal from "./UseLabelManagerModal";
import { API } from "@/http/api";
import {parseBlobReponse} from "@/utils/downloadHelper.js";
import http from "@/http";
import "./UserManager.less";


const { RangePicker } = DatePicker;
const InputSearch = Input.Search;
const BreadcrumbItem = Breadcrumb.Item;

class UserManager extends React.Component {
  state = {
    data: [],
    queryIndex: undefined,

    isExportAuth:false,// 是否具有导出权限

    checkedItem: [],//切换筛选条件，需要重置这个数组。  无论怎么分页，记录都在这里.  
    currentPageRowKeys: [],//当前页的所有数据
    currentPageSelectedRowKeys: [], // 当前页中 被选中的数据


    columns: this.createColumns(),
    pageInfo: new PageInfo(),
    selectedUser: undefined,//  当前被选中的用户，要对其进行标签管理。 

    isBatchSet: false,
    isBatchRevoke: false,

    startDateMoment: undefined,
    endDateMoment: undefined,

    selectedLabelArray: [],

    filterSearchParam: {
      vertifyStatus: undefined,
      createBeginTime: undefined,
      createEndTime: undefined,
      keyWord: undefined,
      userLabelType:undefined,
      userStatusType:undefined,
      userSupplierType:undefined,
      inviteInfo:undefined //邀请人手机号码/所属企业名称
    }

  };

  componentDidMount() {
    this.getUserlist();
    this.checkExportPermission();
  }

  // 判断下是否具有导出用户数据的权限
  checkExportPermission(){
    const config = {
      api: API.userManagerExportAuth,
       showLoading: false
    };

    // 请求
    http(config).then(res => {
      if (res && res.success  ) {
          this.setState({isExportAuth:'true'==res.data});
      }
    });

  }

  resetParams = () => {
    let filterSearchParam = this.state.filterSearchParam;
    Object.keys(filterSearchParam).forEach(key => {
      filterSearchParam[key] = undefined
    })


    let pageInfo = this.state.pageInfo;
    pageInfo.resetPageInfo();

    this.setState({
      pageInfo,
      filterSearchParam,
      startDateMoment: undefined,
      endDateMoment: undefined,
      checkedItem: [] // 条件变化，需要重置下 checkedItem
    }, this.getUserlist);
  };


  onSelectChange = currentPageSelectedRowKeys => {
    // 参数
    let { checkedItem, currentPageRowKeys } = this.state;

    // 先把已经选中 加入到checkedItem
    if (currentPageSelectedRowKeys && currentPageSelectedRowKeys.length > 0) {
      currentPageSelectedRowKeys.forEach(item => {
        if (checkedItem.indexOf(item) < 0) { //不在里面，就推进去
          checkedItem.push(item);
        }
      });
    }

    // 把没有选中的，从checkedItem移除
    if (currentPageRowKeys && currentPageRowKeys.length > 0) {
      currentPageRowKeys.forEach(item => {
        if (currentPageSelectedRowKeys.indexOf(item) < 0) { // 不在里面，说明 没有被选中
          let index = checkedItem.indexOf(item);//存在，说明以前选中了，现在不需要选中了，需要移除掉
          if (index >= 0) {
            checkedItem.splice(index, 1); //去移除
          }

        }
      });
    }

    // 更新
    this.setState({ currentPageSelectedRowKeys, checkedItem });
  };

  openCloseChange = (checked, record) => {
    
    const config = {
      api: API.userManagerSetStatus,
      data: {
        idCode: record.code,
        status: record.status == 1 ? 0 : 1
      },
      showLoading: true
    };

    // 请求
    http(config).then(res => {
      if (res.success) {
        this.getUserlist()
      }
    })
  };

createColumns() {
    let help = new TableRenderHelp();
    return [
      help.createCommonColumn("姓名", "name", "name"),
      help.createCommonColumn("手机号", "mobile", "mobile"),
      help.createCommonColumn("昵称", "nickName", "nickName"),
      help.createCommonColumn("身份", "authUserLabel", "authUserLabel"),
      help.createOperateColumn("是否企业用户", (text, record, index) => {
        let {suppplierNameList,suppplierNameLabel} = record;
        if(!suppplierNameList || !suppplierNameList.length || suppplierNameList.length<=1){
           return ( <div><span  key={"enterprise_"+index} >{suppplierNameLabel||''}</span></div> );
        }
         
        // 这里suppplierNameList肯定大于1
         return ( 
          <div><Tooltip key={"enterprise_"+index} arrowPointAtCenter={true} autoAdjustOverflow={false} title={<div>{suppplierNameList.map((item,index2)=>{ return <span style={{marginBottom: "10px"}} key={"Tooltip_item_"+index2}>{item}<br/></span>})}</div>}>
               <span  >{suppplierNameLabel||''}</span>
          </Tooltip> </div>
        );
      }),
      
     // help.createRenderColumn("企业名称", "companyName", "companyName", help.createRenderLongString(10)),
     // help.createRenderColumn("企业简称", "shortName", "shortName", help.createRenderLongString(10)),
     /* help.createRenderColumn("企业类型", "authenticationTo", "authenticationTo",
        (text, record) => {
          return (<span>{convertAuthenticationToLabel(text)}</span>);
        }
      ),*/
      // help.createEnumColumn("核验状态", "vertifyStatus", "vertifyStatus", VerifyTypeConfig),
     // help.createCommonColumn("标签", "labelName", "labelName", "无"),
      help.createCommonColumn("创建时间", "createTime", "createTime"),
      help.createCommonColumn("邀请人", "inviteMobile", "inviteMobile"),
      help.createCommonColumn("邀请人所属公司", "inviteOrganizationName", "inviteOrganizationName"),
      help.createCommonColumn("邀请方式", "inviteSubTypeName", "inviteSubTypeName"),
      help.createCommonColumn("最近登录", "lastLoginTime", "lastLoginTime"),
      help.createOperateColumn("操作", (text, record, index) => {
        let {status} = record;

        return (
          <div>
            {/*<span onClick={this.showUserLabelManager.bind(this, record)} className="text-color-highlight">标签管理</span>
            <Link
              to={"/app/crm/user/detail/" + record.code}
              className="text-color-highlight"
            >
              <span className="text-color-highlight">查看详情</span>
        </Link> */}

        <span className="status-tip">{ status==1 ? "启用" : "停用"}</span>
          <Switch
             checked={status==1}
              onChange={status => this.openCloseChange(status, record)}
            />
          </div>
        );
      })
    ];
  }

  getUserlist() {
    // setup 1: 构造基本参数
    let myState = this.state;
    let myData = {
      ...myState.filterSearchParam,
      ...myState.pageInfo.paramForSearch()
    };


    // setup 2: 构造 接口入参
    const config = {
      api: API.userManagerList,
      data: myData,
      showLoading: true
    };

    // 请求
    http(config).then(res => {
      if (res && res.success && res.data) {
        if (!res.data.datas) {
          res.data.datas = [];
        }

        // 抽取 所有的code ，后面判断选中与否
        let { currentPageRowKeys, checkedItem } = this.state;
        let currentPageSelectedRowKeys = [];
        currentPageRowKeys = [];
        res.data.datas.forEach(item => {
          currentPageRowKeys.push(item.code);

          if (checkedItem.indexOf(item.code) >= 0) { //之前被选中了
            currentPageSelectedRowKeys.push(item.code);
          }

          if(item.authUserLabelList && item.authUserLabelList.length){
            item["authUserLabel"] = item.authUserLabelList.join("、");
          }else{
            item["authUserLabel"] = "个人";
          }

          if(item.suppplierNameList && item.suppplierNameList.length){
            item["suppplierNameLabel"] = item.suppplierNameList[0]+(item.suppplierNameList.length>1?'...':'');
          }else{
            item["suppplierNameLabel"] = "否" ;

          }
        });



        this.setState({
          queryIndex: new Date().getTime(),
          pageInfo: myState.pageInfo.changeFromJson(res.data),
          data: res.data.datas,
          currentPageRowKeys,
          currentPageSelectedRowKeys
        });
      }
    });
  }

  refresh = () => {
    this.getUserlist();
  }


  // 跟进核验状态 进行筛选
  onChangetVertifyStatus = value => {
    let filterSearchParam = this.state.filterSearchParam;
    filterSearchParam.vertifyStatus = value;

    let pageInfo = this.state.pageInfo;
    pageInfo.resetPageInfo();

    this.setState({ filterSearchParam, pageInfo }, this.getUserlist);
  };

  // 根据时间 进行筛选
handleDate = (value, date) => {
    // 时间筛选
    let filterSearchParam = this.state.filterSearchParam;
    filterSearchParam.createBeginTime = date[0];
    filterSearchParam.createEndTime = date[1];

    // 条件有变化，页码需要重置
    let pageInfo = this.state.pageInfo;
    pageInfo.resetPageInfo();

    // 重新查询
    this.setState({
      filterSearchParam,
      pageInfo,
      startDateMoment: value[0],
      endDateMoment: value[1],
      checkedItem: []
    }, this.getUserlist);
  };

  handleUserLabelType= value => {
      // 时间筛选
      let filterSearchParam = this.state.filterSearchParam;
      filterSearchParam.userLabelType = value;
   
    // 条件有变化，页码需要重置
    let pageInfo = this.state.pageInfo;
      pageInfo.resetPageInfo();
  
      // 重新查询
      this.setState({
        filterSearchParam,
        pageInfo,
        checkedItem: []
      }, this.getUserlist);
  };

  handleUserStatusType= value => {
    // 时间筛选
    let filterSearchParam = this.state.filterSearchParam;
    filterSearchParam.userStatusType = value;
 
    // 条件有变化，页码需要重置
    let pageInfo = this.state.pageInfo;
    pageInfo.resetPageInfo();

    // 重新查询
    this.setState({
      filterSearchParam,
      pageInfo,
      checkedItem: []
    }, this.getUserlist);
};

handleUserSupplierType= value => {
  // 时间筛选
  let filterSearchParam = this.state.filterSearchParam;
  filterSearchParam.userSupplierType = value;

  // 条件有变化，页码需要重置
  let pageInfo = this.state.pageInfo;
  pageInfo.resetPageInfo();

  // 重新查询
  this.setState({
    filterSearchParam,
    pageInfo,
    checkedItem: []
  }, this.getUserlist);
};

  // 关键词筛选
  setKeywords = ({ target: { value } }) => {
    let filterSearchParam = this.state.filterSearchParam;
    filterSearchParam.keyWord = value ? value.replace(/^\s+|\s+$/g, "") : "";

    let pageInfo = this.state.pageInfo;
    pageInfo.resetPageInfo();

    this.setState({ filterSearchParam: filterSearchParam, pageInfo });
  };

  searchByKeywords = () => {
    this.setState({
      checkedItem: []
    }, this.getUserlist);
  }

    // 关键词筛选
    setInviteKeywords = ({ target: { value } }) => {
      let filterSearchParam = this.state.filterSearchParam;
      filterSearchParam.inviteInfo = value ? value.replace(/^\s+|\s+$/g, "") : "";
  
      let pageInfo = this.state.pageInfo;
      pageInfo.resetPageInfo();
  
      this.setState({ filterSearchParam: filterSearchParam, pageInfo });
    };
  
    searchInviteByKeywords = () => {
      this.setState({
        checkedItem: []
      }, this.getUserlist);
    }

  // 页码切换
  tableChange = (pageNo, pageSize, sort) => {
    let pageInfo = this.state.pageInfo;
    pageInfo.changeForClick(pageNo, pageSize);
    this.setState({ pageInfo }, this.getUserlist);
  };


  // 弹出 批量设置
  tryBatchSet = () => {
    this.setState({
      selectedLabelArray: [],// 批量设置和批量取消，共用了这个变量，所以需要重置下
      isBatchSet: true
    });
  }

  // 关闭 批量设置
  cancelOrCloseBatchSet = () => {
    this.setState({
      isBatchSet: false
    });
  }

  tryBatchRevoke = () => {
    this.setState({
      selectedLabelArray: [],// 批量设置和批量取消，共用了这个变量，所以需要重置下
      isBatchRevoke: true
    });
  }

  cancelOrCloseRevoke = () => {
    this.setState({
      isBatchRevoke: false
    });
  }

  // 批量设置
  doBatchSet = () => {
    this.doBatch(true);
  }

  doBatch = (isSet) => {
    // 参数
    let { selectedLabelArray, checkedItem } = this.state;
    const config = {
      api: isSet ? API.labelBatchSet : API.labelBatchCancel,
      data: { labelList: selectedLabelArray, userList: checkedItem },
      showLoading: true
    };

    // 网络请求
    http(config).then(res => {
      if (res && res.success) {
        isSet ? this.cancelOrCloseBatchSet() : this.cancelOrCloseRevoke();
        this.setState({ checkedItem: [], currentPageSelectedRowKeys: [] }, this.refresh);
      }
    });
  }

  // 批量取消
  doBatchRevoke = () => {
    this.doBatch(false); // false就是取消
  }

  // 用户点击了批量设置标签，但需要先检测数据是否符合要求
  tryShowBatchSetModal = () => {
    if (!this.isHaveCheckedUser()) {
      return;
    }

    this.tryBatchSet();
  }

  isHaveCheckedUser = () => {
    let checkedItem = this.state.checkedItem;
    if (!checkedItem || checkedItem.length < 1) {
      message.error("请选择用户");
      return false;
    }

    return true;
  }

  // 用户点击了批量取消标签，但需要先检测数据是否符合要求
  tryShowBatchRevokeModal = () => {
    if (!this.isHaveCheckedUser()) {
      return;
    }

    this.tryBatchRevoke();
  }

  // 给用户 批量打标签的对话框
  createBatchSetModal = () => {
    return <Modal
      visible={true}
      title='批量打标签'
      onCancel={this.cancelOrCloseBatchSet.bind(this)}
      onOk={this.doBatchSet.bind(this)}
    >
      <div>
        <div><span>{'对选择的' + this.state.checkedItem.length + "个用户批量打标签"}</span></div>
        <div><span>请勾选需要打的标签</span></div>
        <div><UserLabelGroup onLabelSelectChange={this.onLabelSelectChange.bind(this)} /></div>
      </div>
    </Modal>
  }

  createBatchRevokeModal = () => {
    return <Modal
      visible={true}
      title='批量取消标签'
      onCancel={this.cancelOrCloseRevoke.bind(this)}
      onOk={this.doBatchRevoke.bind(this)}
    >
      <div>
        <div><span>{'对选择的' + this.state.checkedItem.length + "个用户批量取消标签"}</span></div>
        <div><span>请勾选需要取消的标签</span></div>
        <div><UserLabelGroup onLabelSelectChange={this.onLabelSelectChange.bind(this)} /></div>
      </div>
    </Modal>
  }

  // label变化
  onLabelSelectChange = (checkedValue) => {
    this.setState({ selectedLabelArray: checkedValue });
  }

  closeUserLabelManager = () => {
    this.setState({ selectedUser: null });

  }

  showUserLabelManager = (selectedUser) => {
    this.setState({ selectedUser });
  }

  // 导出用户数据，最多1000条
  export=()=>{
     // setup 1: 构造基本参数
     let myState = this.state;
     let myData = {
       ...myState.filterSearchParam,
       ...myState.pageInfo.paramForSearch()
     };

     // 我们最多可以导出1000条，进行提示
     message.info("最多导出1000条用户数据");
 
 
     // setup 2: 构造 接口入参
     const config = {
       api: API.userManagerExport,
       data: myData,
       responseType: 'blob',
       showLoading: true
     };
 
     // 请求
     http(config).then(res => {
          res&&parseBlobReponse('用户列表'+moment().locale('zh-cn').format('YYYYMMDDHHmmss'),res,(errorMsg)=>{ message.error(errorMsg) });
     });

  }

  render() {
    let mySate = this.state;
    const {
      pageInfo,
      startDateMoment,
      endDateMoment,
      isBatchSet,
      isBatchRevoke,
      selectedUser,
      currentPageSelectedRowKeys,
      isExportAuth
    } = this.state;

    const rowSelection = {
      selectedRowKeys: currentPageSelectedRowKeys,
      onChange: this.onSelectChange,
    };

    return (
      <div className="userlist-content"  >
        <div className='enterprise-auth-header'>
          <Breadcrumb>
            <BreadcrumbItem>用户管理</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div className="userlist-body">
          <Row>

          <span>
               <Select style={{ marginRight: "5px" }}
                style={{ width: "104px", marginRight: "12px" }}
                data={convertEnumToMap(UserStatusType, false)}
                value={mySate.filterSearchParam.userStatusType}
                allowClear
                placeholder="状态"
                onChange={this.handleUserStatusType}
              />
         </span>
           
         {/* <span>
               <Select style={{ marginRight: "5px" }}
                style={{ width: "104px", marginRight: "12px" }}
                data={convertEnumToMap(UserLabelType, false)}
                value={mySate.filterSearchParam.userLabelType}
                allowClear
                placeholder="身份"
                onChange={this.handleUserLabelType}
              />
         </span>

         <span>
               <Select style={{ marginRight: "5px" }}
                style={{ width: "104px", marginRight: "12px" }}
                data={convertEnumToMap(UserSupplierType, false)}
                value={mySate.filterSearchParam.userSupplierType}
                allowClear
                placeholder="是否企业用户"
                onChange={this.handleUserSupplierType}
              />
         </span>*/}


            <span>
              <span style={{ marginRight: "5px" }}>创建时间</span>
              <RangePicker
                value={[startDateMoment, endDateMoment]}
                onChange={this.handleDate}
                style={{ width: "200px", marginRight: "12px" }}
              />
            </span>

            <span>
              <InputSearch
                placeholder="邀请人手机号/所属企业名称"
                onChange={this.setInviteKeywords}
                onSearch={this.searchInviteByKeywords}
                value={mySate.filterSearchParam.inviteInfo}
                style={{ width: "250px" , marginRight: "12px" }}
                maxLength={50}
              />
            </span>

            <span>
              <InputSearch
                placeholder="手机号/姓名/所属企业"
                onChange={this.setKeywords}
                onSearch={this.searchByKeywords}
                value={mySate.filterSearchParam.keyWord}
                style={{ width: "280px" }}
                maxLength={50}
              />
            </span>

            <span
              span={3}
              className="reset"
              style={{
                cursor: "pointer",
                fontSize: "12px",
                textDecoration: "underline",
                textUnderlinePosition: "under",
                color: "#333"
              }}
              onClick={this.resetParams.bind(this)}
            >
              <a >重置</a>
            </span>

            <Button type="link" style={{'marginLeft':'10px',display:isExportAuth?'inline-block':'none'}} onClick={this.export}>导出</Button>

          </Row>

          {/*<Row style={{ marginTop: "16px", marginBottom: "5px" }}>
            <span>
              <Button
                type="primary"
                style={{ marginLeft: "0px" }}
                onClick={this.tryShowBatchSetModal.bind(this)}
              >
                批量打标签
             </Button>
            </span>
            <span>
              <Button
                type="primary"
                style={{ marginLeft: "20px" }}
                onClick={this.tryShowBatchRevokeModal.bind(this)}
              >
                批量取消标签
             </Button>
            </span>
            <span key={this.state.checkedItem} style={{ marginLeft: "20px" }}>{"已选" + this.state.checkedItem.length + "个用户"}</span>
            </Row>  */}
          <Table
            change={this.tableChange}
            rowKey="code"
            {...pageInfo.paramForTable()}
            scroll={{ x: "max-content" }}
            selfProcessData={this.state.data}
            columns={mySate.columns}
          />
        </div>

        {isBatchSet && this.createBatchSetModal()}
        {isBatchRevoke && this.createBatchRevokeModal()}

        {selectedUser ?
          <UseLabelManagerModal
            userCode={selectedUser.code}
            userName={selectedUser.name}
            userPhone={selectedUser.mobile}
            onRefresh={this.refresh}
            onCancel={this.closeUserLabelManager} /> : ''}
      </div>
    );
  }
}


export default UserManager;
