import React, { Component } from 'react';
import { Checkbox } from 'antd';
import {
    HelpChannel,
    convertEnumToMap2
} from "CMPT/common.js";
const CheckboxGroup = Checkbox.Group;

class ChannelRadioGroup extends Component {
    state = {
        options: [],// 可以供选择的类型。 新增/编辑 问题时，会被类目所限制
        initValue: [],//  编辑 类目/问题时，初始值
        value: [] // 被选中的值，包括初始化时的赋值 
    };

    componentWillMount() {
        // setup1: 设置可选项
        let initValue;
        if (this.props.defaultValue) {
            initValue = this.props.defaultValue;
        } if (this.props.value) {
            initValue = this.props.value;
        } else if (this.props.initialValue) {
            initValue = this.props.initialValue;
        }

        if (typeof (initValue) == 'string') {
            let valueArray = initValue.split(',');
            //TODO 应该再检验下 valueArray 是否在 options中
            this.setState({
                initValue: valueArray,
                value: valueArray
            });
        }
        /*  else if (Array.isArray(initValue) && initValue.length > 0 && typeof (initValue[0]) == 'string') {
          this.setState({
              defaultValue: initValue,
              value: valueArray
          });
      } */



        // setup 2: 设置可选范围
        let allOptionChannelMap = convertEnumToMap2(HelpChannel);
        if (this.props.optionValue && typeof (this.props.optionValue) == 'string') {
            let channelMap = [];

            let splitValueArray = this.props.optionValue.split(',');// 过滤范围
            allOptionChannelMap.map(item => {
                for (let i = 0, len = splitValueArray.length; i < len; i++) {
                    if (splitValueArray[i] == item.value) {
                        channelMap.push(item);
                        return;
                    }
                }
            });
            this.setState({
                options: channelMap // 经过了过滤
            });
        } else {
            this.setState({
                options: allOptionChannelMap
            });
        }

    }

    innerOnChange = (checkedValue) => {
        this.state.value = checkedValue;
        if (this.props.onChange) {
            this.props.onChange(checkedValue.join(','));
        }
    }

    render() {
        return <CheckboxGroup options={this.state.options} defaultValue={this.state.initValue} onChange={this.innerOnChange}>
        </CheckboxGroup>;
    }
}



export default ChannelRadioGroup;