import React, { Component } from 'react'
import http from '@/http';
import { API } from '@/http/api';
import { Select ,message,Modal,Form,Button} from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import './receipt.less'
const Item = Form.Item;
const Option = Select.Option;
moment.locale('zh-cn');
class Print extends Component {
    state = {
        showPrintModal:false,
        payChannel:[],
        shoujuModal:false,
        encode:'',
    }
    componentDidMount(){
        // console.log(this.props.receiptInfo)
    }
    getMoban(){
        // console.log(this.this.props.receiptInfo.projectEncode)
        http({
            api: API.templateAllList,
            data:{
                type:2,
                projectCode:this.props.receiptInfo.projectEncode
            }
        }).then(res => {
            if(res.success){
                if(res.data){
                    if(res.data.length == 1){
                        this.setState({
                            encode:res.data[0].encode,
                        },()=>{
                            this.exportBill();
                        })
                    }else if(res.data.length == 0){
                        message.error('未配置收据模板')
                    }else{
                        this.setState({
                            payChannel:res.data,
                            showPrintModal:true
                        })
                    }        
                }else{
                    message.error('未配置收据模板')
                }                     
            }else{
                message.error('接口出错');
            }  
        })
    }
    // POST /web/bill/receipt/download
    exportBill(){
        const config = {
            api: API.billReceiptdownload,
            data:{
                receiptEncode:this.props.receiptInfo.receiptEncode,
                templateEncode:this.state.encode
            },
            responseType: 'blob',
            showLoading: true
        };
        http(config).then(res => {
            
            let reader = new FileReader()
            reader.onload = e => {
                if (e.target.result.indexOf('"success":false') !== -1) {
                    const resultParse = JSON.parse(e.target.result)
                    console.log('resultParse',resultParse)
                    message.error(resultParse.msg)
                } else {                  
                    const content = res
                    const blob = new Blob([content], { type: 'text/plain' })
                    // const fileName = `收据${moment(new Date()).format('YYYY-MM-DD HH:mm')}.doc`
                    const fileName = `${this.props.receiptInfo.receiptNo}.docx`
                    if ('download' in document.createElement('a')) { // 非IE下载
                        const elink = document.createElement('a')
                        elink.download = fileName
                        elink.style.display = 'none'
                        elink.href = URL.createObjectURL(blob)
                        document.body.appendChild(elink)
                        elink.click()
                        URL.revokeObjectURL(elink.href) // 释放URL 对象
                        document.body.removeChild(elink)
                    } else { // IE10+下载
                        navigator.msSaveBlob(blob, fileName)
                    }
                }
            }
            reader.readAsText(res)
        })
    }
    printOk(){
        if(this.state.encode){
            this.exportBill();
            this.setState({
                showPrintModal:false
            })
        }else{
            message.error('请选择模板')
        }  
        
    }
    printCancel(){
        this.setState({
            showPrintModal:false
        })
    }
    showPrint(){
        // this.setState({
        //     showPrintModal:true
        // })
        this.getMoban();
    }
    // shoujuCancel(){
    //     this.setState({
    //         shoujuModal:false
    //     })
    // }
    printAction(){
        window.document.body.innerHTML = window.document.getElementById('printCon').innerHTML;  
        window.print(); 
        window.location.reload();
    }
    changeMo(value){
        console.log(value)
        this.setState({
            encode:value
        })
    }
    render(){
        const { getFieldDecorator } = this.props.form;
        const optionsChannel = this.state.payChannel.map(per => {
            return <Option value={per.encode} key={per.encode}>{per.name}</Option>
        })
        return(
            <div>
                <Modal
                    className="passModal"
                    title='打印收据单'
                    closable={false}
                    visible={this.state.showPrintModal}
                    onOk={this.printOk.bind(this)}
                    okText='确定'
                    width={400}
                    onCancel={this.printCancel.bind(this)}
                >     
                    <div>
                        <Item label="收据模板" style={{ width: '240px'}}>
                            {getFieldDecorator('encode', {
                                rules: [{
                                    required: true, message: '请选择',
                                }]
                            })(
                                <Select placeholder="请选择" style={{ width: '240px'}} onChange={this.changeMo.bind(this)}>
                                    {optionsChannel}
                                </Select>               
                            )}
                        </Item>
                    </div>        
                </Modal>
                {/* <Modal
                    className="passModal"
                    title='收据模板模板名称111'
                    closable={false}
                    visible={this.state.shoujuModal}
                    width={800}
                    footer={null}
                    closable
                    maskClosable
                    onCancel={this.shoujuCancel.bind(this)}
                >     
                    <div className="cx-print">

                        <div className="shouju" id="printCon">
                            
                        </div>

                        <div className="print-btn">
                            <Button type="primary" onClick={this.printAction.bind(this)}>打印</Button>
                        </div>
                    </div>        
                </Modal> */}
                <span className="table-operate-btn" onClick={this.showPrint.bind(this)}>下载</span>
            </div>
        )
    }
}

export default Form.create({ name: 'Print' })(Print);