import React from 'react';
import {
    Table, Input, Form
} from 'antd';

import Select from 'CMPT/common/Select'

const FormItem = Form.Item;
const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
    <EditableContext.Provider value={form}>
        <tr {...props} />
    </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class HeaderCell extends React.Component {
    render() {
        const {
            title,
            required,
            width,
        } = this.props;

        return (
            <th width={width} style={{ fontSize: '12px', textAlign: 'center' }}>
                <span className="ant-table-header-column">
                    <div>
                        <span className="ant-table-column-title">{title}</span>
                        {required ? (<span className="required" style={{ color: '#E94437', fontWeight: "normal", marginLeft: "4px" }}>*</span>) : null}
                    </div>
                </span>
            </th>
        );
    }
}


class EditableCell extends React.Component {
    constructor(props) {
        super(props);        
    }
    state={        
        businessList : [
            {   
                value: 0, 
                name: '不可招商' 
            },
            { 
                value: 1, 
                name: '可招商'
            }
        ],
        
        
    }
    rentStatusList0 = [
        { 
            value: 0, 
            name: '-'
        },
        
    ]
    rentStatusList1 = [
        
        { 
            value: 1, 
            name: '未出租'
        },
        { 
            value: 2, 
            name: '已出租'
        }
    ]
    merchantStatusChange=(key,dataIndex, value)=>{        
        
    }
    getInput=(key)=> {
        const { onChange } = this.props;
        var _this = this
        switch (this.props.dataIndex) {
            case 'merchantStatus':                
                return <div>
                    <Select style={{width:100}} defaultValue={1} data={this.state.businessList} selectMethod={(value)=>{
                        console.log(key, 'merchantStatus', value)
                        onChange(key, 'merchantStatus', value)
                        _this.setState({
                            merchantStatus:value
                        })
                        if(value==0){
                            onChange(key, 'rentStatus', 0)
                        }else{
                            onChange(key, 'rentStatus', 1)
                        }
                    }}/>
                    {
                        this.state.merchantStatus==0?(
                            <Select key={0} defaultValue={0} style={{
                                position: 'absolute',
                                left: 130,
                                top: 4,
                                width: 82,
                            }} data={this.rentStatusList0} selectMethod={(value) => { onChange(key, 'rentStatus', value)}}/>

                        ):(
                            <Select key={1} defaultValue={1} style={{
                                position: 'absolute',
                                left: 130,
                                top: 4,
                                width: 82,
                            }} data={this.rentStatusList1} selectMethod={(value) => { onChange(key, 'rentStatus', value)}}/>
                        )
                    }
                </div>
            case 'rentStatus':                
                return <div></div>
            case 'remark':
            case 'zoneNo':
            case 'buildingArea':
            case 'unitPrice':
            case 'useArea':
            case 'innerArea':
                return <Input style={this.props.dataIndex === 'unitPrice' ? { width: "130px" } : {width: "90px"}} 
                              placeholder={'请输入'}
                              onBlur={(e) => { onChange(key, this.props.dataIndex, e.target.value)}}/>
            default:
                break
        }
    };

    render() {
        const {
            editable,
            required,
            suffix,
            dataIndex,
            record,
            index,
            ...restProps
        } = this.props;

        const constraint = (record.constraint || {})[dataIndex];
        const { status, message } = constraint || {};
        
        return (
            <EditableContext.Consumer>
                {(form) => {
                    this.form = form;
                    return (
                        <td {...restProps}>
                            {editable ? (
                                <FormItem validateStatus={status} help={message} style={{ margin: 0}} key={index}>
                                    {form.getFieldDecorator(dataIndex, {
                                        rules: [{
                                            required: required,
                                            message: message || '必填',
                                        }],
                                        initialValue: record[dataIndex]
                                    })(this.getInput(record.key))}
                                </FormItem>
                            ) : restProps.children}
                        </td>
                    );
                }}
            </EditableContext.Consumer>
        );
    }
}

export default class EditableTable extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            dataSource: this.props.dataSource
        };
    }

    componentWillReceiveProps(props) {
        this.setState({
            ...this.state,
            ...props
        })
    }

    onChange = (key, dataIndex, value) => {
        console.log('onChange')
        console.log(key, dataIndex, value)
        const dataSource = this.state.dataSource;        
        const index = dataSource.findIndex(item => key === item.key);        
        if (index > -1) {
            const item = dataSource[index];
            item[dataIndex] = value;
            this.setState({ dataSource: dataSource });
            if (typeof this.props.onChange == 'function') {                
                this.props.onChange(this.state.dataSource);
            }
        }
    }

    render() {
        const components = {
            header: {
                cell: HeaderCell,
            },
            body: {
                row: EditableFormRow,
                cell: EditableCell,
            },
        };
        const columns = this.props.columns.map((col) => {            
            return {
                ...col,
                onHeaderCell: record => ({
                    record,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    required: col.required,
                    suffix: col.suffix,
                    message: col.message,
                    width: col.width
                }),
                onCell: record => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    required: col.required,
                    suffix: col.suffix,
                    message: col.message,
                    width: col.width,
                    onChange: this.onChange
                }),
            };
        });
        return (
            <Table
                className="table-floor"
                components={components}
                rowKey={(record) => record.key || 'header'}
                rowClassName={() => 'editable-row'}
                dataSource={this.state.dataSource}
                columns={columns}
                pagination={false}
            />
        );
    }
}
