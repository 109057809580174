import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom';
import IMGurl from '@/images/empty_schedule@2x.png'
import moment from 'moment';

export default withRouter(function VehicleAuth({ data, props }) {
  let VEHICLE_DATA
  let QIYE_FLAG = ''

  let vehicleInfoData = [
    { label: "车型", field: "type" },
    { label: "车牌号", field: "plate" },
    { label: "是否拖挂", field: "withTrailer" },
    { label: "空车质量", field: "emptyMass" },
    { label: "核载重量", field: "deadWeight" },
    { label: "车货总质量", field: "totalMass" },
    { label: "准牵引总质量", field: "tractionMass" },
    { label: "车长", field: "length" },
    { label: "车宽", field: "width" },
    { label: "车高", field: "hight" },
    { label: "车轴", field: "axle" },
    { label: "国标", field: "ns" },
    { label: "燃油", field: "fuel" },
    { label: "功能", field: "function" },
  ]

  if (data.data && data.data.data.plate)  { // 车辆信息
    VEHICLE_DATA = data.data.data
    QIYE_FLAG = '车辆'
    vehicleInfoData.splice(2, 0, { label: "车架号", field: "vinNo" })
    vehicleInfoData.splice(3, 0, { label: "发动机号", field: "engineNo" })
    // console.log(vehicleInfoData, 'vehicleInfoData')
  }

  return (
    <>
    <div className="userInfo" style={{ "padding": 0 }}>
      {VEHICLE_DATA ? (
        <div className="userInfo_header">
        <div className="userInfo_title">
          认证为
          {/* <span>{DRIVER_DATA.audit.displayName}</span> */}
          <span className="flag">{QIYE_FLAG}</span>
        </div>
        <p>提交时间：{moment(VEHICLE_DATA.createDate).format('YYYY-MM-DD HH:mm:ss')}</p>
        {VEHICLE_DATA.auditStatusName ? (<div className="userInfo_right">
          <span>{VEHICLE_DATA.auditStatusName}</span>
          {/* <p onClick={()=>lookRecords()}>查看审核记录</p> */}
          <p>
            <Link to={'/app/crm/enterprise_auth/record/' + data.code+ '/'+3} >查看审核记录</Link>
          </p>
        </div>): null}
      </div>
      ): null}
      <div className="userInfo_body">
        {VEHICLE_DATA ? (
          <>
            <div>
              <h2><i></i>  车辆信息</h2>
              <ul>
                {vehicleInfoData && vehicleInfoData.map(item => {
                  return (
                    <li key={item.field}><span>{item.label}</span><span className="span">{VEHICLE_DATA[item.field]}</span></li>
                  )
                })}
                <li className="body_img"><span>车辆照片</span>
                {VEHICLE_DATA.vehiclePhotos && VEHICLE_DATA.vehiclePhotos.map((item, index) => <img key={index} src={item.url} alt="" onClick={() => props.cImage(item.url)} />)}
                </li>
                <li className="body_img"><span>行驶证</span>
                {VEHICLE_DATA.vehicleLicenseFront ? (<img src={VEHICLE_DATA.vehicleLicenseFront.url} alt="" onClick={() => props.cImage(VEHICLE_DATA.vehicleLicenseFront.url)} />) : null }
                {VEHICLE_DATA.vehicleLicenseBack?(<img src={VEHICLE_DATA.vehicleLicenseBack.url} alt="" onClick={() => props.cImage(VEHICLE_DATA.vehicleLicenseBack.url)} />):null}
                </li>
                <li className="body_img"><span>营运许可证</span>
                {VEHICLE_DATA.vehicleOperationLicense?(<img src={VEHICLE_DATA.vehicleOperationLicense.url} alt="" onClick={() => props.cImage(VEHICLE_DATA.vehicleOperationLicense.url)} />): null}
                </li>
                <li className="body_img"><span>车辆强制险</span>
                {VEHICLE_DATA.vehicleCiov && VEHICLE_DATA.vehicleCiov.map((item, index) => <img key={index} src={item.url} alt="" onClick={() => props.cImage(item.url)} />)}
                </li>
              </ul>
            </div>
          </>
        ) : null
        }
      </div>
    </div>
    </>
  )
})