import {
    AJAX_TYPE
} from '../config';


const prefix = 'item';

export default {
    // 商品列表
    [`${prefix}QueryList`]: {
        url: '/api/supply/web/crm/item/queryList',
        type: AJAX_TYPE.POST
    },

       // 待推荐的商品列表
       [`${prefix}NoRecommendList`]: {
        url: '/api/supply/web/crm/item/queryNoRecommendList',
        type: AJAX_TYPE.POST
    },

    //商品详情
    [`${prefix}Detail`]: {
        url: '/api/supply/web/crm/item/detail',
        type: AJAX_TYPE.POST
    },
    // 敏感词检测
    [`${prefix}SensitiveWords`]: {
        url: '/web/crm/audit/sensitiveCheck',
        type: AJAX_TYPE.POST
    },
    // 已经知晓
    /* [`${prefix}MgOk`]: {
        url: '/web/crm/audit/audit',
        type: AJAX_TYPE.POST
    }, */
    //强制下架
    [`${prefix}ForceOffShelves`]: {
        url: '/web/crm/audit/forceOffShelves',
        type: AJAX_TYPE.POST
    },
    // //查询地址
    [`${prefix}Status`]: {
        url: '/web/mall/item/status',
        type: AJAX_TYPE.GET
    },
    // 商品审核
    [`${prefix}Audit`]: {
        url: '/web/crm/audit/audit',
        type: AJAX_TYPE.POST
    },

    // 修改客服电话
    [`${prefix}EditServiceTel`]: {
        url: '/api/supply/web/crm/item/saveGoodsContact',
        type: AJAX_TYPE.POST
    },
     // 修改商品价格模式
     [`${prefix}UpdatePricingSwitch`]: {
        url: '/api/supply/web/crm/item/updatePricingSwitch',
        type: AJAX_TYPE.POST
    },
    // 商品操作
    [`${prefix}GoodsOperate`]: {
        url: '/api/supply/web/crm/item/batchUpdateItemState',
        type: AJAX_TYPE.POST
    },
    // 修改类目，枚举
    [`${prefix}GetCategoryTree`]: {
        url: '/web/mall/navbar/queryNavbarCategoryTree',
        type: AJAX_TYPE.POST
    },
    
}
