import React from 'react';
import {
    Menu, Button
} from 'antd';
import { Link } from 'react-router-dom';
import { API } from '@/http/api';
import http from '@/http';
import { addProject } from '@/utils/draft';
import './SiderMenu.less';
import AuthContainer from 'CMPT/common/auth/AuthContainer';
import { AUTH_KEYS } from '@/utils/auth';
import userUtil from '@/utils/user';
import storage from '@/utils/storage';
const { Item: MenuItem } = Menu;
class ProjectSiderMenu extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedKey: 'all',
            menuItems: []
        };
    }

    menuItemClick = (item) => {
        this.setState({
            selectedKey: (item === null ? 'all' : item.encode)
        });
        if(item===null){
            storage.remove('tabKey')            
        }
    }

    addNewProject = () => {
        addProject(this.props.history)
    }

    getMenuItems() {
        if (userUtil.hasAuth(AUTH_KEYS.PROJECT_All)) {                    
            this.setState({
                hasAll:true
            })
        } else {
            this.setState({
                hasAll:false
            })
        }
        const config = {
            api: API.commonProjectfilter,
            showLoading: true,
        }
        http(config).then(result => {
            if (result.success && result.data.length > 0) {
                this.setState({
                    menuItems: result.data
                });                
                
            } 
            
        })
    }

    componentDidMount() {
        
        this.getMenuItems();        
        
        this.dealWithSelectedKey(this.props.pathname);
    }
    
    componentWillReceiveProps(nextProps) {
        if (this.props.pathname !== nextProps.pathname) {
            this.dealWithSelectedKey(nextProps.pathname);
        }
    }

    dealWithSelectedKey = (pathname) => {                
        let projectId = 'all';
        if (pathname != '/app/project/detail' && pathname != '/app/project/detail/') {
            const path = pathname.split('/');
            projectId = path[path.length - 1];
        }        
        this.setState({
            selectedKey: projectId
        });
        
    };

    render() {
        
            return [
                <Menu key='menu' mode="inline" selectedKeys={[this.state.selectedKey]}>
                    {
                        this.state.hasAll?<MenuItem key='all' onClick={this.menuItemClick.bind(this, null)}><Link to="/app/project/detail/all" className='project-menu-title'>全部项目</Link></MenuItem>:null
                    }
                    
                    {
                        userUtil.hasAuth(AUTH_KEYS.PROJECT_LIST)?(
                            this.state.menuItems.map((item) => {
                                return <MenuItem key={item.encode} onClick={this.menuItemClick.bind(this, item)}><Link to={`/app/project/detail/${item.encode}`} className='project-menu-title' title={item.regionName+'-'+item.name}>{item.regionName}-{item.name}</Link></MenuItem>
                            })
                        ):null

                        
                    }
                </Menu>,
                <AuthContainer key="1" authCodes={AUTH_KEYS.PROJECT_NEW}>            
                    <div key='add' className="add-project">
                        <Button type="link" className="add-project-button" onClick={this.addNewProject}>
                            新建项目
                        </Button>
                    </div>
                </AuthContainer>    
            ];        
    }
}

export default ProjectSiderMenu;