import React, { PureComponent } from "react";
import { Breadcrumb, Input, Button, Select, Tree } from "antd";
import { Link } from "react-router-dom";
import Pagination from "CMPT/common/Pagination";
import List from "./List";

import { API } from "@/http/api";
import http from "@/http";

import "./index.less";
import userUtils from '@/utils/user';
import { AUTH_KEYS } from "@/utils/auth";
const { checkAuthByKey } = userUtils;
const { TreeNode } = Tree;
const canShowAddAuth = checkAuthByKey(AUTH_KEYS.SETTING_CENTER_MANAGE_ACCOUNT_MANAGE_ADD); // 新增权限；
const { Search } = Input;

export default class AccountManage extends PureComponent {
  state = {
    activeKey: "",
    searchValue: "",
    tableData: [],
    loading: false,
    pageSize: "10",
    pageNo: 1,
    total: 0,
    crmState: null,
    treeData: []
  };

  componentDidMount() {
    // 组织树
    this.accountGetOrganizationTreeList()
    this.getList();
  }

  paginationChange = (pageNo, pageSize) => {
    console.log(pageNo, pageSize);
    this.setState(
      {
        pageNo: pageNo,
        pageSize: pageSize,
      },
      () => {
        this.getList();
      }
    );
    // this.props.onChange(pageNo, pageSize)
  };

  

  accountGetOrganizationTreeList = () => {
    const config = {
      api: API.accountGetOrganizationTreeList,
      showLoading: true,
    };
    http(config).then((res) => {
      if (res && res.success) {
        // let treeData = res.data && res.data.map(item => {
        //   item.title = item.organizationName;
        //   item.key = item.organizationCode;
        //   item.children = item.childrenList && item.childrenList.map(ch => {
        //     ch.title = ch.organizationName;
        //     ch.key = ch.organizationCode;
        //     return ch;
        //   })
        //   return item;
        // })
        this.setState({
          treeData: res.data
        })
      }
    });
  }
  onTreeSelect = (e) => {
    console.log(e, 'eeee');
    this.setState({
      organizationCode: e[0]
    }, () => {
      this.getList()
    })
  }


  // 获取商品列表
  getList = () => {
    const config = {
      api: API.accountList,
      data: {
        keyword: this.state.searchValue,
        pageNo: this.state.pageNo,
        pageSize: this.state.pageSize,
        crmState: this.state.crmState,
        organizationCode: this.state.organizationCode
      },
      showLoading: true,
    };
    http(config).then((res) => {
      if (res && res.success && res.data && res.data.datas) {
        this.setState({
          tableData: [...res.data.datas],
          total: res.data.totalRecord,
        });
      }
    });
  };
  // 搜索按钮
  onSearchOn = (keywords) => {
    this.setState({ activeKey: "", searchValue: keywords }, () => {
      this.getList();
    });
  };

  // 输入搜索框
  searchChange = (e) => {
    this.setState({ searchValue: e.target.value });
  };
  // 重置
  resetSearch = () => {
    this.setState({ searchValue: "" }, () => {
      this.getList();
    });
  };

  selectChange = (e) => {
    console.log(e, 'eeee');
    this.setState({ crmState: e }, () => {
      this.getList()
    })
  }

  render() {
    const { searchValue, treeData } = this.state;
    console.log(treeData, 'ggggg');
    return (
      <div className="enterprise-auth-con">
        <div className="enterprise-auth-header">
          <Breadcrumb>
            <Breadcrumb.Item>设置中心</Breadcrumb.Item>
            <Breadcrumb.Item>账号管理</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="account_content_box">
          <div className="content_left">
            <Tree
              onSelect={this.onTreeSelect}
              treeData={treeData}
              autoExpandParent
            />

          </div>
          <div className="content_right">
            <div className="account_manage_fliter">
              <Select placeholder="请选择状态" style={{ width: 120, marginRight: 10 }} onChange={this.selectChange}>
                <Select.Option value={null}>全部</Select.Option>
                <Select.Option value='0'>禁用</Select.Option>
                <Select.Option value='1'>启用</Select.Option>
              </Select>

              <Search
                placeholder="姓名、手机号"
                enterButton="搜索"
                className="account_manage_search"
                value={searchValue}
                // size="large"
                onChange={(value) => {
                  this.searchChange(value);
                }}
                onSearch={(value) => {
                  this.onSearchOn(value);
                }}
              />
              
              <span className="account_manage_reset" onClick={this.resetSearch}>
                重置
              </span>
              {
                canShowAddAuth ?
                <Button className="account_manage_create" type="primary">
                  <Link to={"/app/crm/setting/account/detail"}>新增</Link>
                </Button>
                : null
              }
            </div>
            <List tableData={this.state.tableData} getList={this.getList} />
            {this.state.tableData.length > 0 && (
              <div className="table-footer">
                <Pagination
                  change={this.paginationChange.bind(this)}
                  total={this.state.total}
                  current={this.state.pageNo}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
