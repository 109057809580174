import React, { Component } from 'react';
import {
    Pagination, Dropdown, Icon, Menu
} from 'antd';
export default class BOTable extends Component {
    state = {
        pageSize: 10,
        current: 1
    }
    componentDidMount() {
        this.setState({
            current: this.props.current || 1
        })
    }
    componentWillReceiveProps(nextProps, nextStatus) {
        this.setState({
            current: nextProps.current || 1
        })
    }
    itemRender = (current, type, originalElement) => {
        if (type === 'prev') {
            return <span className="ant-pagination-page-change">上一页</span>;
        } if (type === 'next') {
            return <span className="ant-pagination-page-change">下一页</span>;
        }
        return originalElement;
    }
    currentSelect = ({ item, key, selectedKeys }) => {
        key = parseInt(key)
        const { change } = this.props
        this.setState({
            pageSize: key,
            current: 1
        })
        change(1, key)
    }
    paginationChange = (page) => {
        const { change } = this.props
        const pageSize = this.state.pageSize
        this.setState({
            current: page
        })
        change(page, pageSize)
    }
    render() {
        const { total } = this.props

        const menu = (
            <Menu onClick={this.currentSelect}>
                <Menu.Item key={10} style={{ fontSize: '12px' }}>10条</Menu.Item>
                <Menu.Item key={20} style={{ fontSize: '12px' }}>20条</Menu.Item>
                <Menu.Item key={30} style={{ fontSize: '12px' }}>30条</Menu.Item>
            </Menu>
        );
        return (
            <div className="bo-pagination">
                {
                    !this.props.showSizeChanger ?
                        <span className="bo-pagination-current">
                            {/* 当前页第<span>1</span>条， */}
                            共{total || 0}条
                        </span>
                        : null
                }
                {
                    !this.props.showSizeChanger ?
                        <span>
                            每页显示： <Dropdown overlay={menu} trigger={['click']}>
                                <span className="ant-dropdown-current">
                                    {this.state.pageSize}条 <Icon type="caret-down" />
                                </span>
                            </Dropdown>
                        </span>
                        : null
                }

                <div className="bo-pagination-item">
                    <Pagination total={total} itemRender={this.itemRender}
                        current={this.state.current} onChange={this.paginationChange}
                        pageSize={this.state.pageSize}
                    />
                </div>
            </div>
        )
    }
}
