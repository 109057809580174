import React, { Component } from 'react';
import { Modal,Form, Input } from 'antd';
import http from '@/http';
import { API } from '@/http/api';
const FormItem = Form.Item;
const { TextArea } = Input;

class RejectModel extends Component {
    state = { 
        visible: false,
        auditStatus: '',//审批状态，1通过，2驳回
        supplierId: ''
    }
     componentWillReceiveProps(nextProps){       
        this.setState({
            visible: nextProps.visible,
            auditStatus: nextProps.auditStatus,
            supplierId: nextProps.supplierId
        })
    }
    // componentDidMount(){
    //     this.setState({
    //         visible: this.props.visible,
    //         auditStatus: this.props.auditStatus
    //     })
    // }
    handleOk = (e) => {
        // this.setState({
        //     visible: false,
        // });
        this.handleSubmit(e);
    }
    handleCancel = (e) => {
        this.setState({
            visible: false
        });
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
          if (!err) {
            const config = {
                api: API.supplierAudit,
                data: {
                    auditStatus: this.state.auditStatus,
                    code: this.state.supplierId,
                    desc: values.desc
                },
                showLoading: true
            }
            http(config).then(res => {      
                if(res.success) {
                    this.setState({
                        visible: false
                    },() => {
                        this.props.goList();
                    });
                }              
            })
          }
        });
      }
    render() {
        const { getFieldDecorator } = this.props.form;
        const auditStatus = this.state.auditStatus;
        return (
            <div>
                <Modal
                    title={ auditStatus == 1? '通过' : '驳回'}
                    closable={false}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    okText='确定'
                    width={400}
                    onCancel={this.handleCancel}
                >
                     <Form onSubmit={this.handleSubmit}>
                        <FormItem label='备注' required={false}>
                            {getFieldDecorator('desc', {
                                rules: [{
                                    required: false
                                }],
                            })(
                                <TextArea rows={4} maxLength={200} placeholder='请输入'/>
                            )}
                        </FormItem>
                     </Form>                  
                </Modal>
            </div>
        );
    }

}


export default Form.create({ name: 'RejectModel' })(RejectModel);