import React from 'react';
import { Link } from 'react-router-dom';
import { Button, } from 'antd';
import './ErrorPage.less'

export default function ErrorPage({ code = 404 }) {
    const errorMsg = ErrorMap[code];
    if (errorMsg) {
        return (
            <div className='error-page'>
                <div className={`left-info error-${code}`} />
                <div className='right-info'>
                    <div className='code'>{code}</div>
                    <div className='msg'>{errorMsg}</div>
                    <div className='btn'><Button type="primary" ><Link to='/'>返回首页</Link></Button></div>
                </div>
            </div>
        );
    }
    return null;
}

const ErrorMap = {
    403: '抱歉，你无权访问该页面',
    404: '抱歉，你访问的页面不存在',
    500: '抱歉，服务器出错了',
}