import React, { Component } from 'react';
import { Layout, Menu, Breadcrumb, Button, Form, Icon, Input, Row, Col, DatePicker, Upload, Radio, message, Tooltip, Slider, InputNumber, } from 'antd';
import Select from 'CMPT/common/Select'
import { Link } from 'react-router-dom'
import SelectGroup from 'CMPT/common/SelectGroup'
import MultiCascader from 'CMPT/common/MultiCascader';
import reg from '@/utils/regex'
import debounce from 'lodash/debounce';
import { customerGetCustomerList, usergetList } from './service/customerService';
import { intentionInit, getProjectDetailInfoByName, intentionAdd, intentionGet, intentionEdit, intentionGetDispatchUserList } from './service/intentionService'
import { changeObjkey ,getNewPhone} from '@/utils/index'
import FileUploadSecret from './FileUploadSecret'
import FileUploadBiz from 'CMPT/common/FileUploadBiz'
import axios from 'axios';
import moment from 'moment';
import './intention.less'

const { Header, Content, Footer } = Layout;
const { TextArea } = Input;
const { phone, idCard, telephone, socialCreditCode, website ,phoneNew} = { ...reg }

class EditIntention extends Component {

    state = {
        customerList: [],
        userList: [],
        followerList:[],
        projectList: [],
        imageUrl: null,
        collapse: false,
        intentionRate: 0,
        intentionStatusConfigArray: [],
        industryTypeConfigArray: [],
        customerTypeConfigArray: [],
        intentionChannelTypeConfigArray: [],
        lostReasonTypeConfigArrray: [],
        areaTypeConfigArray: [],
        followupTypeConfigArray: [],
        followupFlagConfigArray: [],
        projectList: [],
        projectDataList: [],
        floorList: [],
        cascaderData:[],
        fileList: [],
        customerName: null,
        userName: null,
        status: null,
        intentionZoneList: undefined,
        fileInfoList: [],
        channelType:null,
        lastStatus:null
    }




    handleChange = value => {
        // console.log(value.format('YYYY-MM-DD'))
    }

    handleChangeChannelType = value => {
        this.setState({
            channelType: value
        })        
    }

    handleChangeStatus = value => {
        this.setState({
            status: value
        })
    }

    handleChangeGroup = value => {
        console.log(value);
    }

    handleChangeProject = value => {
        let _project = this.state.projectDataList.find(item => item.encode == value)
        let floors = _project.floors;
        //   console.log(floors);
        let targetList = []

        floors.forEach(per => {
                if (per.zones && per.zones.length) {
                    let obj = {}
                    obj.name = per.name
                    obj.id = per.encode
                    obj.no = per.floorNo
                    obj.child = []
                    per.zones.forEach(zo => {
                        let zoObj = {}
                        zoObj.name = zo.zoneNo
                        zoObj.id = zo.encode
                        zoObj.parentId = zo.floorEncode
                        zoObj.price = zo.unitPrice
                        zoObj.area = zo.rentalArea
                        zoObj.zoneNo = zo.zoneNo
                        obj.child.push(zoObj)
                    })
                    targetList.push(obj)
                }
            })
        this.setState({
            floorList: floors,
            cascaderData:targetList
        }, () => {
            //    console.log(this.state.floorList)
        })
    }

    onRateChange = value => {
        this.props.form.setFieldsValue({
            intentionRate: value
        })
    }




    handleChangeCustomer = value => {
        // console.log(this.state.customerList.find(item=>item.value==value)["name"]);
        this.setState({
            customerName: this.state.customerList.find(item => item.value == value)["name"]
        })
    }

    handleChangeUser = value => {
        this.setState({
            userName: this.state.userList.find(item => item.value == value)["name"]
        })
    }
    fileUp = fileList => {
        this.setState({
            fileInfoList: fileList
        })
    }

    componentDidMount() {
        Promise.all([this.getCustomerList({}), this.getUserList(), this.getProjectList('')]).then(() => {
            intentionInit().then(res => {
                let data = res.data;
                let { intentionStatusEnum, customerTypeEnum, industryTypeEnum, intentionChannelTypeEnum, lostReasonTypeEnum, areaTypeEnum, followupTypeEnum, followupFlagEnum } = { ...data }
                let intentionStatusConfigArray = intentionStatusEnum.map(item => changeObjkey(item, { value: 'name', key: 'value' }))
                let customerTypeConfigArray = customerTypeEnum.map(item => changeObjkey(item, { value: 'name', key: 'value' }))
                let industryTypeConfigArray = industryTypeEnum.map(item => changeObjkey(item, { value: 'name', key: 'value' }))
                let intentionChannelTypeConfigArray = intentionChannelTypeEnum.map(item => changeObjkey(item, { value: 'name', key: 'value' }))
                let lostReasonTypeConfigArrray = lostReasonTypeEnum.map(item => changeObjkey(item, { value: 'name', key: 'value' }))
                let areaTypeConfigArray = areaTypeEnum.map(item => changeObjkey(item, { value: 'name', key: 'value' }))
                let followupTypeConfigArray = followupTypeEnum.map(item => changeObjkey(item, { value: 'name', key: 'value' }))
                let followupFlagConfigArray = followupFlagEnum.map(item => changeObjkey(item, { value: 'name', key: 'value' }))
                this.setState({
                    intentionStatusConfigArray: intentionStatusConfigArray,
                    industryTypeConfigArray: industryTypeConfigArray,
                    customerTypeConfigArray: customerTypeConfigArray,
                    intentionChannelTypeConfigArray: intentionChannelTypeConfigArray,
                    lostReasonTypeConfigArrray: lostReasonTypeConfigArrray,
                    areaTypeConfigArray: areaTypeConfigArray,
                    followupTypeConfigArray: followupTypeConfigArray,
                    followupFlagConfigArray: followupFlagConfigArray,
                    lastStatus:intentionStatusConfigArray[intentionStatusConfigArray.length-1].value
                }, () => {
                    this.getDetail();
                })
            })
        })
    }


    getCustomerList = data => {
        return customerGetCustomerList(data).then(res => {
            if (res.code == 0) {
                let data = res.data;
                data && this.setState({
                    customerList: data.map(item => {
                        return {
                            name: item.name,
                            value: item.encode
                        }
                    })
                }, () => {
                    // console.log(this.state.customerList)
                })
            }
        })
    }

    getUserList = value => {
        return intentionGetDispatchUserList().then(res => {
            this.setState({
                userList: res.map(item => {
                    return { value: item.idCode, name: item.name, account: item.mobile }
                }),
                followerList:res.map(item => {
                    return { value: item.idCode, name: item.name, account: item.mobile }
                }),
            })
        });
    }

    getProjectList = value => {
        return getProjectDetailInfoByName({ projectName: value }).then(res => {
            if (res.code == 0) {
                let data = res.data;
                this.setState({
                    projectList: data.map(item => {
                        return {
                            name: item.name,
                            value: item.encode
                        }
                    }),
                    projectDataList: data,
                    // floorList:data[0].floors
                })
            }
        })
    }

    searchUser = (value) => {
        let followerList = []
        if(value===''){
            followerList = this.state.followerList
        }else{
            followerList = this.state.followerList.filter(i=>i.name.includes(value))
        }
        this.setState({
            userList:followerList
        })
    }

    searchCustomer = value => {
        this.getCustomerList({ fuzzyKey: value });
    }

    handleCustomerSearch = debounce(this.searchCustomer, 800)

    handleSearch = debounce(this.searchUser, 800)

    // fileUp = fileList => this.setState({ fileList: fileList, })

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                // console.log(this.props.form.getFieldsValue());
                let fieldsObject = this.props.form.getFieldsValue();
                // fieldsObject.file = this.state.fileList;
                fieldsObject.channelPhone=fieldsObject.channelPhone.replace(/\s+/g, '')
                fieldsObject.userName = this.state.userName;
                fieldsObject.customerName = this.state.customerName;
                fieldsObject.startDate = fieldsObject.startDate ? fieldsObject.startDate.format('YYYY-MM-DD') : null
                fieldsObject.endDate = fieldsObject.endDate ? fieldsObject.endDate.format('YYYY-MM-DD') : null
                fieldsObject.quitDate = fieldsObject.quitDate ? fieldsObject.quitDate.format('YYYY-MM-DD') : null
                fieldsObject.signDate = fieldsObject.signDate ? fieldsObject.signDate.format('YYYY-MM-DD') : null
                fieldsObject.preSignDate = fieldsObject.preSignDate ? fieldsObject.preSignDate.format('YYYY-MM-DD') : null
                fieldsObject.encode = this.props.match.params.id
                fieldsObject.intentionZoneList = fieldsObject.intentionZoneList ? fieldsObject.intentionZoneList.map(item => {
                    // let _tmpArray = item.split('%')
                    return {
                        floorEncode: item.parentId,
                        zoneEncode: item.id
                    }
                }) : []
                fieldsObject.fileInfoList = this.state.fileInfoList;
                for (let i in fieldsObject) {
                    if (!fieldsObject[i]&&fieldsObject[i]!==0) {
                        fieldsObject[i] = null
                    }
                }
                intentionEdit(fieldsObject).then(res => {
                    if (res.code == 0) {
                        message.success("修改意向成功")
                        this.props.history.push('/app/operate/invitation/intention')
                    }

                })

            }
        });
    }
    getDetail = () => {
        intentionGet({ encode: this.props.match.params.id }).then(res => {
            if (res.code == 0) {
                let data = res.data;
                this.props.form.setFieldsValue({
                    areaType: data.areaType || undefined,
                    customerName: data.customerName,
                    visitDate: data.visitDate && moment(data.visitDate),
                    channelType: data.channelType,
                    channelContact: data.channelContact,
                    channelPhone:getNewPhone(data.channelPhone),
                    userEncode: data.userEncode,
                    followupType: data.followupType,
                    followupFlag: data.followupFlag,
                    status: data.status,
                    followupTime: data.followupTime && moment(data.followupTime),
                    followupRemark: data.followupRemark,
                    industryType: data.industryType || undefined,
                    investmentMoney: data.investmentMoney,
                    projectEncode: data.projectVo && data.projectVo.encode || undefined,
                    // intentionZoneList: data.intentionZoneList ? data.intentionZoneList.map(item => `${item.floorEncode}%${item.zoneEncode}`) : undefined,
                    intentionZoneList:data.intentionZoneList ? data.intentionZoneList.map(item => {
                        return {
                            id: item.zoneEncode,
                            parentId:item.floorEncode
                        }
                    }) : undefined,
                    startDate: data.startDate && moment(data.startDate),
                    endDate: data.endDate && moment(data.endDate),
                    quitDate: data.quitDate && moment(data.quitDate),
                    preSignDate: data.preSignDate && moment(data.preSignDate),
                    signDate: data.signDate && moment(data.signDate),
                    intentionRate: data.intentionRate||undefined,
                    lostReasonType: data.lostReasonType
                }, () => {
                    let _project = data.projectVo && this.state.projectDataList.find(item => item.encode == data.projectVo.encode)
                    let floors = _project && _project.floors ? _project.floors : [];
                    let targetList = []

                    floors.forEach(per => {
                            if (per.zones && per.zones.length) {
                                let obj = {}
                                obj.name = per.name
                                obj.id = per.encode
                                obj.no = per.floorNo
                                obj.child = []
                                per.zones.forEach(zo => {
                                    let zoObj = {}
                                    zoObj.name = zo.zoneNo
                                    zoObj.id = zo.encode
                                    zoObj.parentId = zo.floorEncode
                                    zoObj.price = zo.unitPrice
                                    zoObj.area = zo.rentalArea
                                    zoObj.zoneNo = zo.zoneNo
                                    obj.child.push(zoObj)
                                })
                                targetList.push(obj)
                            }
                        })
                    this.setState({
                        floorList: floors,
                        cascaderData:targetList,
                        status: data.status,
                        userName: data.userName,
                        fileInfoList: data.fileInfoList,
                        channelType:data.channelType
                    })
                })

            }
        })
    }
    render() {
        // console.log(this.props);
        const {
            getFieldDecorator, getFieldsError, getFieldError, isFieldTouched,
        } = this.props.form;
        const config = {
            rules: [{ required: true, message: '请选择' }],
        }
        const { intentionRate } = this.state;
        return (
            <Content className="intention-list intention-content">
                <div className="intention-list-head flexbox">
                    <Breadcrumb className="intention-list-head-bread">
                        <Breadcrumb.Item>
                        <Link style={{ color: '#333' }} to={{ pathname: "/app/operate/invitation/intention" }}>
                        招商中心
                            </Link>
                        
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                        <Link style={{ color: '#333' }} to={{ pathname: "/app/operate/invitation/intention" }}>
                        意向管理
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>编辑意向</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <Form onSubmit={this.handleSubmit}>
                    <div className="intention-add-body">
                        <div>
                            <Row gutter={80}>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item label="来访渠道">
                                        {getFieldDecorator('channelType', config)(
                                            <Select style={{ width: '100%' }} data={this.state.intentionChannelTypeConfigArray} placeholder="请选择" selectMethod={this.handleChangeChannelType} />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item label="来访时间">
                                        {getFieldDecorator('visitDate', {
                                            rules:[
                                               { required:this.state.channelType == 2,message:'请选择'}
                                            ]
                                        })(
                                            <DatePicker style={{ width: '100%' }} format='YYYY-MM-DD' onChange={this.handleChange} />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={80}>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item label="渠道联系人">
                                        {getFieldDecorator('channelContact', {})(
                                            <Input style={{ width: '100%' }} placeholder="请输入" />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item label="渠道联系人手机">
                                        {getFieldDecorator('channelPhone', {
                                            rules: [{
                                                pattern: phoneNew, message: '请填写正确的手机号'
                                            }],
                                            getValueFromEvent: e => (getNewPhone(e))
                                        })(
                                            <Input style={{ width: '100%' }}  maxLength={13} placeholder="请输入" />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={80}>
                                <Col className="gutter-row" span={24}>
                                    <Form.Item label="附件">
                                        {getFieldDecorator('file', {})(
                                            <FileUploadBiz fileList={this.state.fileInfoList} fileUp={this.fileUp} />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            {/* <FileUploadSecret fileUp={this.fileUp} /> */}
                            {/* <ImageUploadBiz fileUp={this.fileUp} /> */}
                            {/* <Form.Item label="附件">
                                        {getFieldDecorator('filelist', config)(
                                            <Upload
                                                listType="picture"
                                                beforeUpload={this.beforeUpload}
                                                onChange={this.handleUploadChange}
                                                action={process.env.IMG_UPLOAD + "secret.html"}
                                                customRequest={this.doUploadFile.bind(this)}
                                                fileList = {defaultFileList}
                                            >
                                                {uploadButton}
                                            </Upload>
                                        )}
                                    </Form.Item> */}

                        </div>
                        <div className="middle-line">
                            <span className="gray-line"></span>
                            <a className="text-color-highlight" href="javascript:void(0)" onClick={() => this.setState({ collapse: !this.state.collapse })}>
                                {!this.state.collapse ? '展开' : '收起'}
                            </a>
                            <span className="gray-line"></span>
                        </div>
                        <div style={{ display: this.state.collapse ? 'block' : 'none' }}>
                            <Row gutter={80}>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item label="投资产业">
                                        {getFieldDecorator('industryType', {})(
                                            <Select style={{ width: '100%' }} data={this.state.industryTypeConfigArray} placeholder="请选择" selectMethod={this.handleChange} />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item label="投资规模">
                                        {getFieldDecorator('investmentMoney', {
                                            rules: [{
                                                pattern: telephone, message: '10位以内整数'
                                            }]
                                        })(
                                            <Input style={{ width: '100%' }}
                                                placeholder="请输入"
                                                maxLength={10}
                                                suffix={
                                                    <Tooltip title="Extra information">
                                                        <span>万元</span>
                                                    </Tooltip>
                                                }
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={80}>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item label="意向项目">
                                        {getFieldDecorator('projectEncode', {})(
                                            <Select style={{ width: '100%' }} data={this.state.projectList} placeholder="请选择" selectMethod={this.handleChangeProject} showSearch optionFilterProp="children"/>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item label="意向面积">
                                        {getFieldDecorator('areaType', {})(
                                            <Select style={{ width: '100%' }} data={this.state.areaTypeConfigArray} placeholder="请选择" selectMethod={this.handleChange} />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={80}>
                                {/* <Col className="gutter-row" span={16}>
                                    <Form.Item label="意向区域">
                                        {getFieldDecorator('intentionZoneList', {
                                            // initialValue:this.state.intentionZoneList
                                        })(
                                            <SelectGroup style={{ width: '100%' }} data={this.state.floorList} placeholder="请选择" handleChangeGroup={this.handleChangeGroup} />
                                        )}
                                    </Form.Item>
                                </Col> */}
                                <Col className="gutter-row" span={16}>
                                <Form.Item label="意向区域">
                                        {getFieldDecorator('intentionZoneList', {})(
                                            <MultiCascader notFoundContent='无匹配结果' data={this.state.cascaderData} placeholder="请先选择项目" noShowArea={true}/>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={80}>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item label="预计起租日期">
                                        {getFieldDecorator('startDate', {})(
                                            <DatePicker style={{ width: '100%' }} onChange={this.handleChange} />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item label="预计到租日期">
                                        {getFieldDecorator('endDate', {})(
                                            <DatePicker style={{ width: '100%' }} onChange={this.handleChange} />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item label="预计退租日期">
                                        {getFieldDecorator('quitDate', {})(
                                            <DatePicker style={{ width: '100%' }} onChange={this.handleChange} />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={80}>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item label="预计签约日期">
                                        {getFieldDecorator('preSignDate', {})(
                                            <DatePicker style={{ width: '100%' }} onChange={this.handleChange} />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item label="意向签订日期">
                                        {getFieldDecorator('signDate', {})(
                                            <DatePicker style={{ width: '100%' }} onChange={this.handleChange} />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={26}>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item label="成交几率">
                                        {getFieldDecorator('intentionRate', {})(
                                            <Slider
                                                min={0}
                                                max={100}
                                                onChange={this.onRateChange}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    <div style={{ paddingTop: '40px' }}>
                                        <Form.Item>
                                            {getFieldDecorator('intentionRate', {
                                            })(
                                                <InputNumber
                                                    min={0}
                                                    max={100}
                                                    formatter={intentionRate => `${intentionRate}%`}
                                                    parser={intentionRate => intentionRate.replace('%', '')}
                                                    onChange={this.onRateChange}
                                                />
                                            )}
                                        </Form.Item>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                    </div>
                    <div className="btn-group">
                        <Button type="primary" htmlType="submit">提交</Button> <Button type="link" onClick={() => this.props.history.push('/app/operate/invitation/intention')}>取消</Button>
                    </div>
                </Form>
            </Content>
        );
    }
}

const IntentionEditForm = Form.create({ name: 'intention_edit' })(EditIntention);

export default IntentionEditForm;