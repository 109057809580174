import React, { Component } from 'react';
import { Link ,withRouter} from 'react-router-dom';
import CollectionBill from './CollectionBill';
import PayBill from './PayBill';
import RefundBill from './RefundBill';
import { Breadcrumb, Button, Tabs ,Menu,Dropdown,message } from 'antd';
import http from '@/http';
import { API } from '@/http/api';
import { AUTH_KEYS } from '@/utils/auth';
import AuthContainer from 'CMPT/common/auth/AuthContainer';
import './bill.less'
const moment = require('moment');
const BreadcrumbItem = Breadcrumb.Item;
const TabPane = Tabs.TabPane;
class List extends Component {
    state = {
        payment:0 ,
        receive:0,
        refund:0,
        tabActive:'1'
    }  
    getCount(){
        http({
            api: API.billGetCount,
        }).then(res => {
            if(res.data){
                console.log("loadCaptcha--------------", res.data);
                this.setState({
                    receive:res.data.receive,
                    payment:res.data.payment,
                    refund:res.data.refund
                })
            }           
        })
    }
    exportBill(params,api,name){
        const config = {
            api: api,
            data:params,
            responseType: 'blob',
            showLoading: true
        };
        http(config).then(res => {
            let reader = new FileReader()
            reader.onload = e => {
                if (e.target.result.indexOf('"success":false') !== -1) {
                    const resultParse = JSON.parse(e.target.result)
                    console.log('resultParse',resultParse)
                    message.error(resultParse.msg)
                } else {
                    const content = res
                    const blob = new Blob([content], { type: 'application/vnd.ms-excel' })
                    const fileName = name;
                    if(res.code == '2000013'){
                        message.error('无导出报表的权限');
                        return;
                    }
                    if ('download' in document.createElement('a')) { // 非IE下载
                        const elink = document.createElement('a')
                        elink.download = fileName
                        elink.style.display = 'none'
                        elink.href = URL.createObjectURL(blob)
                        document.body.appendChild(elink)
                        elink.click()
                        URL.revokeObjectURL(elink.href) // 释放URL 对象
                        document.body.removeChild(elink)
                    } else { // IE10+下载
                        navigator.msSaveBlob(blob, fileName)
                    }
                }
            }
            reader.readAsText(res)
            
        })
    }
    export = () => {
        const tabnum = this.state.tabActive;
        if(tabnum == '1'){
            this.exportBill(this.refs.cBill.state.queryParams,API.billExport,`收款账单${moment(new Date()).format('YYYY-MM-DD HH:mm')}.xlsx`);
        }
        if(tabnum == '2'){
            this.exportBill(this.refs.pBill.state.queryParams,API.billExport,`付款账单${moment(new Date()).format('YYYY-MM-DD HH:mm')}.xlsx`);
        }
        if(tabnum == '3'){
            this.exportBill(this.refs.rBill.state.queryParams,API.billrefundExport,`退款账单${moment(new Date()).format('YYYY-MM-DD HH:mm')}.xlsx`);
        }
    }
    componentDidMount(){
        this.getCount();
    }
    changeTab(value){
        console.log(value)
        this.setState({
            tabActive:value
        })
    }
    render() {
        const {receive,payment,refund} = this.state;
        let tab1 = '收款账单('+receive+')';
        let tab2 = '付款账单('+payment+')';
        let tab3 = '退款账单('+refund+')';
        const menu = (
            <Menu className="cx-menu-class">
              <Menu.Item>
                  <Link to="/app/operate/finance/bill/add?type=1">收款账单</Link>
              </Menu.Item>
              <Menu.Item>
                <Link to="/app/operate/finance/bill/add?type=2">付款账单</Link>
              </Menu.Item>
            </Menu>
          );
        return (
            <div className="contract-list-out financeWrap">
                <div className="contract-header">
                    <Breadcrumb>
                        <BreadcrumbItem>财务收支</BreadcrumbItem>
                        <BreadcrumbItem>账单管理</BreadcrumbItem>
                    </Breadcrumb>
                    <div className="rButton">
                        <AuthContainer authCodes={AUTH_KEYS.OPERATE_FINANCE_BILL_EXPORT}>
                            <Button type="link" style={{'marginRight':'20px'}} onClick={this.export}>导出报表</Button>
                        </AuthContainer>
                        <AuthContainer authCodes={AUTH_KEYS.OPERATE_FINANCE_BILL_ADD}>
                            <Dropdown overlay={menu} placement="bottomRight">
                                <Button type="primary">新增账单</Button>
                            </Dropdown>
                        </AuthContainer>
                        
                    </div>
                </div>
                <div className="contract-content">
                    <Tabs defaultActiveKey={this.state.tabActive} animated={false} onChange={this.changeTab.bind(this)}>
                        <TabPane tab={tab1} key="1">
                            <CollectionBill ref="cBill" {...this.props}/>
                        </TabPane>
                        <TabPane tab={tab2} key="2">
                            <PayBill ref="pBill" {...this.props}/>
                        </TabPane>   
                        <TabPane tab={tab3} key="3">
                            <RefundBill ref="rBill"/>
                        </TabPane>                          
                    </Tabs>
                </div>
                
            </div>
        );
    }
}

export default withRouter(List);