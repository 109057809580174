import React, { Component } from 'react';
import { Breadcrumb, Select, Tabs } from 'antd';
import './index.less'
import VehicleManageList from './VehicleManage/VehicleManageList';
import EquipmentList from './EquipmentManage/EquipmentList';

// import GoodsAuditList from './GoodsAudit/GoodsAuditList';
// import CapacityAuditList from './CapacityAudit/CapacityAuditList';

const BreadcrumbItem = Breadcrumb.Item;
const { Option } = Select;
const { TabPane } = Tabs;

export default class Sourcing extends Component {
    state = {
        currentTab: '1'
    }

    componentWillMount() {
        let {search} = this.props.location
        
            if(search){
                var str = search.substr(1);
                this.setState({
                currentTab: str.split("=")[1]
            })
        }
    }

    changeTabs = (value) => {
        this.props.history.push(`/app/crm/vehicleAndEquipment?tab=${value}`);
         this.setState({
             currentTab: value
         })
    }

    render() {
        return (
            <div className="sourcing-page">
                {/* <Breadcrumb>
                    <BreadcrumbItem>车辆管理</BreadcrumbItem>
                </Breadcrumb> */}


                <Tabs activeKey={this.state.currentTab}  onChange={this.changeTabs} className='tabs' style={{margin: 0}}>
                        <TabPane tab="车辆管理" key="1" className='tab'>
                            <VehicleManageList />
                        </TabPane>
                        <TabPane tab="设备管理" key="2" className='tab'>
                            <EquipmentList />
                        </TabPane>
                       
                </Tabs>

            </div>
        );
    }
}