import axios from "axios";
import userUtil from "@/utils/user";
import qs from "qs";
import { _typeof, encryptData } from "@/utils/index";
import { refreshRoute } from "@/route/config";
import storage from "@/utils/storage";
import {
  showLoading as loading,
  hideLoading as loadingEnd,
  showErrorMessage,
  showSuccessMessage,
} from "@/utils/globalMessageReminder";
import { API } from "./api";
import { AJAX_TYPE } from "./config";

export default function http(config) {
  // if (userUtil.isLogin()) {
    // addUserToken(config);
    return ajax(config);
  // } else {
  //   goToLogin();
  // }
}


function addUserToken(config) {
  const user = userUtil.get();
  config.headers = {
    token: user && user.token,
  };
}

function goToLogin() {
  window.location.href = "/#/login";
}
function goTo403() {
  window.location.href = "/#/403";
}
// ----------------old login--------------------------
// export function login(data) {
//     const config = {
//         api: API.userLoginForWeb,
//         successTip: '登录成功！',
//         data
//     };
//     return ajax(config).then(res => {
//         if (res.success) {
//             userUtil.login(res.data);
//             refreshRoute();
//         }
//         return res;
//     });
// }
// ---------------new login-------------------------

const APP_CONSTANT_MAP = {
  develop: {
    APP_ID: "269573a4f92811e9befed6d44df114a6",
    SECRET: "secret-haha",
  },
  product: {
    APP_ID: "9e90f8496ed6fd3a016f22961571003c",
    SECRET: "Jjq4Aw7H20gbBq4j",
  },
};

const APP_CONSTANT_KEY = {
  develop: {
    keyX: "9D18DCF9A4C04E12FFFF68A5A43AE321D56E2E693B4DBAB6CBE263EA156B81B9",
    keyY: "68864B894038382F7649B088733CB9F4DE322953CC81C58FC76F28BC96D43AE7",
  },
  product: {
    keyX: "CE334A29C0FDF7810D4BBB1C9917E54719E53394F947AC8B525CCDEFDDA44810",
    keyY: "649E2A9651401CC3251BCEDAD42CE1506841A7A31D3EE3DEADDE65D769BA4458",
  },
};

function getAppMap(obj, key) {
  // if (window.location.hostname === 'finance.proxy.ipo.com' || window.location.hostname === 'fin-platform.an2.idf10.cn') {
  //     return obj.product[key]
  // }
  // else {
  return obj.develop[key];
  // }
}
function resetPwd() {
  window.location.href = "/#/reset";
}

function doEncrypt(keyX, keyY, pwd) {
  var msgData = window.CryptoJS.enc.Utf8.parse(pwd);
  var cipherMode = window.SM2CipherMode.C1C3C2;
  var cipher = new SM2Cipher(cipherMode); // eslint-disable-line
  var userKey = cipher.CreatePoint(keyX, keyY);
  msgData = cipher.GetWords(msgData.toString());
  var encryptData = cipher.Encrypt(userKey, msgData);
  return encryptData.toUpperCase();
}
export function login(data, requestId) {
  const { extraPrincipal, password } = data;
  const keyX =
    window.location.host.indexOf(".idf10.com.cn") !== -1 ||
    window.location.host.indexOf(":3000") !== -1
      ? APP_CONSTANT_KEY.develop.keyX
      : APP_CONSTANT_KEY.product.keyX;
  const keyY =
    window.location.host.indexOf(".idf10.com.cn") !== -1 ||
    window.location.host.indexOf(":3000") !== -1
      ? APP_CONSTANT_KEY.develop.keyY
      : APP_CONSTANT_KEY.product.keyY;
  const newPassword = doEncrypt(keyX, keyY, password);
  if (!requestId) {
    const config = {
      api: API.userRequestId,
    };
    return loginAjax(config).then((res) => {
      const loginConfig = {
        api: API.userLogin,
        successTip: "登录成功",
        // requestId: res.data,
        data: {
          ...data,
          extraPrincipal: extraPrincipal.toUpperCase(),
          password: newPassword,
          requestId: res.data,
          mode: 1,
          device: 1,
          tokenExpire: 2,
        },
      };
      return loginAjax(loginConfig).then((resd) => {
        if (resd.success) {
          userUtil.login(resd.data);

          // 跳转redirect
        }
        return { ...resd, requestId: res.data };
      });
    });
  } else {
    const loginConfig = {
      api: API.userLogin,
      // requestId: res.data,
      data: {
        ...data,
        extraPrincipal: extraPrincipal.toUpperCase(),
        password: newPassword,
        requestId,
        mode: 1,
        device: 1,
        tokenExpire: 2,
      },
    };
    return loginAjax(loginConfig).then((resd) => {
      if (resd.success) {
        userUtil.login(resd.data);
        // 跳转redirect
      }
      return { ...resd, requestId };
    });
  }
}

export function loginAjax(config) {
  const loginAjax = axios.create();
  const ajaxConfig = getAjaxConfig(config);
  const showLoading = !!config.showLoading;
  loginAjax.interceptors.request.use((config) => {
    const { method, url, headers } = config;
    let { data } = config;

    headers.appId = getAppMap(APP_CONSTANT_MAP, "APP_ID");
    if (method === "get" || method === "delete") {
      const idx = url.indexOf("?");
      let searchStr;
      let searchObj = qs.parse(searchStr, { ignoreQueryPrefix: true });
      searchObj = encryptData(
        searchObj,
        getAppMap(APP_CONSTANT_MAP, "SECRET"),
        "md5"
      );
      searchStr = qs.stringify(searchObj);
      if (idx !== -1) {
        config.url = url + "&" + searchStr;
      } else {
        config.url = url + "?" + searchStr;
      }
    }
    if (method === "post" || method === "put" || method === "patch") {
      // if (headers['Content-Type'] && !headers['Content-Type'].includes('multipart/form-data')) {
      data = encryptData(data, getAppMap(APP_CONSTANT_MAP, "SECRET"), "md5");
      config.data = data;
      // }
    }
    return config;
    // if (config.loading) loading();
    // return config;
  });

  loginAjax.interceptors.response.use(
    (data) => {
      if (/\/\/upload./.test(data.request.responseURL)) {
        return data;
      }
      if (data.config.loading) loadingEnd();
      if (data.data.code === "200001" || data.data.code === "20002") {
        showErrorMessage(data.data.msg);
        // 先清localStorage
        storage.clear();
        goToLogin();
        return Promise.reject();
      } else if (data.data.code === "300001") {
        goTo403();
        // showErrorMessage(data.data.msg);
        // return Promise.reject();
      } else if (data.data.code === "2000013") {
        showErrorMessage(data.data.msg);
        return Promise.reject();
      } else if (data.data.code === "2000014") {
        // 重置密码
        if (data.request.responseURL.indexOf("/user/login") !== -1) {
          userUtil.login(data.data.data);
        }
        resetPwd();
        return Promise.reject();
      } else if (_typeof(data.data) === "Object") {
        if (data.data.success) {
          if (data.config.successTip) {
            showSuccessMessage(data.config.successTip);
          }
        } else {
          showErrorMessage(data.data.msg);
        }
      }
      return data;
    },
    (error) => {
      if (error.response) {
        if (error.response.config.loading) loadingEnd();
        switch (error.response.status) {
          case 500:
            showErrorMessage(error.response.data.msg || "服务器报错");
            break;
          default:
            showErrorMessage(error.response.data.msg || "服务器报错");
        }
        return Promise.reject(error.response.data); // 返回接口返回的错误信息
      }
      return Promise.reject(error);
    }
  );

  return new Promise((resolve, reject) => {
    loginAjax(ajaxConfig)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {});
  });
}

// ---------------------------------------------------------------------------------------

const DEFAULT_API_TYPE = AJAX_TYPE.GET;

export function ajax(config) {
  // debugger
  const ajaxConfig = getAjaxConfig(config);
  const showLoading = !!config.showLoading;

  return new Promise((resolve, reject) => {
    axios(ajaxConfig)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {});
  });
}

export function logins(data) {
  const host =
    window.location.host == "oms-crm.idf10.com.cn"
      ? "http://dcmanage.an2.idf10.cn"
      : "http://dcmanage.st15.idf10.com.cn"; //这里还要加判断'http://dcmanage.st15.idf10.com.cn
  const config = {
    api: {
      url: host + "/paasmanage/ucapi/account/common/2/v2/login",
      type: "POST",
    },
    headers: {
      appId: "2ef4c027eee911e98a1cd22e1d4c10ad",
      "Content-Type": "application/json;charset=utf-8",
    },
    xhrFields: { withCredentials: true },
    data,
  };
  console.log(config, "logins  config");
  return ajax(config).then((res) => {
    if (res.success) {
      const config = {
        api: API.userLoginForWeb,
        successTip: "登录成功！",
        data: { accountId: res.data.accountId },
      };
      return ajax(config).then((res) => {
        if (res.success) {
          userUtil.login(res.data);
          refreshRoute();
        }
        return res;
      });
    }
  });
}

// export function loginImg() {
//     return new Promise((resolve, reject) => {
//         axios({
//             headers: {
//                 Accept: "*/*",
//                 appId: '2ef4c027eee911e98a1cd22e1d4c10ad',
//                 // 'cache-control': 'no-cache',
//                 // 'content-type': ' image/jpeg',
//                 // pragma: 'no-cache ',
//             },
//             method:'get',
//             url:'http://10.59.75.41:8286/paasmanage/ucapi/account/common/imgcode?imgCodeType=2&imgType=2'
//         }).then(res=>{
//             resolve(res)
//         })
//     })

// }

// http请求拦截器

export function formRequest(formData) {
  const fromData = new FormData();
  const user = userUtil.get();
  fromData.append("file", formData.file);

  const config = {
    // onUploadProgress: progressEvent => {
    //     formData.value.onProgress(progressEvent.loaded / progressEvent.total * 100 | 0);
    // },
    headers: {
      // appId: '2ef4c027eee911e98a1cd22e1d4c10ad',
      token: user.token,
      "Content-Type": "multipart/form-data",
    },
  };
  return axios
    .post(formData.action, fromData, config)
    .then((res) => {
      return res;
      // value.onSuccess(res);
    })
    .catch((err) => {
      try {
        formData.value.onError(err);
      } catch (error) {
        return Promise.reject(err);
      }
    });
}
// axios.defaults.withCredentials = true

axios.interceptors.request.use(
  (config) => {
    if (config.loading) loading();
    return config;
  },
  (error) => {
    //请求错误处理

    Promise.reject(error);
  }
);

// http响应拦截器
axios.interceptors.response.use(
  (data) => {
    console.log(data, 'data');
    if (/\/\/upload./.test(data.request.responseURL)) {
      return data.data;
    }
    if (data.config.loading) loadingEnd();
    if (data.data.code === "200001") {
      showErrorMessage(data.data.msg);
      // 先清localStorage
      storage.clear();
      goToLogin();
      return Promise.reject();
    } else if (data.data.code == '500') {
      showErrorMessage(data.data.message);
    } else if (data.data.code === "300001") {
      goTo403();
      // showErrorMessage(data.data.msg);
      // return Promise.reject();
    } else if (data.data.code === "2000013") {
      showErrorMessage(data.data.msg);
      return Promise.reject();
    } else if (data.data.code === "9998") {
      // 当code为9998，不通过系统气泡 展示 错误信息，页面自行处理
      return data.data;
    } else if (data.data.msg === "天眼查查询无数据") {
      return data.data;
    } else if (_typeof(data.data) === "Object") {
      if (data.data.success) {
        if (data.config.successTip) {
          showSuccessMessage(data.config.successTip);
        }
      } else {
        showErrorMessage(data.data.msg);
      }
    }
    return data.data;
  },
  (error) => {
    if (error.response) {
      if (error.response.config.loading) loadingEnd();
      switch (error.response.status) {
        case 500:
          showErrorMessage(error.response.data.msg || "服务器报错");
          break;
        default:
          showErrorMessage(error.response.data.msg || "服务器报错");
      }
      return Promise.reject(error.response.data); // 返回接口返回的错误信息
    }
    return Promise.reject(error);
  }
);
/**
 *
 * @example
 * getAjaxConfig({
        api: api.*,
        path: {},
        data: {},
        showLoading: true
    })
 */
function getAjaxConfig(config = {}) {
  const { api, path, data, headers, responseType } = config;
  const type = api.type || DEFAULT_API_TYPE;

  let url = api.url;
  if (path) {
    Object.entries(path).forEach(([key, value]) => {
      url = url.replace(":" + key, value);
    });
  }

  const ajaxConfig = {
    url,
    method: type,
    responseType,
  };

  if (data) {
    if (type === AJAX_TYPE.GET) {
      ajaxConfig.params = data;
    } else {
      ajaxConfig.data = data;
    }
  }

  if (headers) {
    ajaxConfig.headers = headers;
  }
  if (config.showLoading) {
    ajaxConfig.loading = config.showLoading;
  }
  if (config.successTip) {
    ajaxConfig.successTip = config.successTip;
  }
  return ajaxConfig;
}
