import React, { Component } from "react";
import { Breadcrumb, Table, Descriptions, Button, Input, message, Modal } from "antd";
import moment from 'moment'
import qs from 'qs'
import { Link } from "react-router-dom";
import { API } from "@/http/api";
import http from "@/http";
import ImgPreview from 'CMPT/common/ImgPreview';

import "./index.less";

const { TextArea } = Input;

const approveStatus = {
  0: "审核中",
  10: "审核通过",
  20: "审核驳回",
  21: "强制下架"
};

export default class RentDetail extends Component {
  state = {
    code: "",
    serviceInfoData: {},
    auditRemark: "",
    imgList : [], 
    currentIndex: 0, 
    showImgPreview: false
  };

  componentWillMount() {
    const match = this.props.match;
    if (match && match.params && match.params.code) {
      this.setState(
        {
          code: match.params.code,
        },
        () => {
          this.getDetail();
          this.getAuditRecords();
        }
      );
    }
  }

  // 获取详情数据
  getDetail = () => {
    const config = {
      api: API.serviceInfoGetDetail,
      path: {
        code: this.state.code
      },
      showLoading: true,
    };
    http(config).then((res) => {
      if (res && res.code === 200) {
        let imageList = res.data.imageList && res.data.imageList.map(item => {
          return item.url;
        })
        this.setState({
          serviceInfoData: res.data || {},
          imageList
        });
      }
    });
  };

  changeAuditRemark = (e) => {
    this.setState({
      auditRemark: e.target.value,
    });
  };

  // 审核
  onVerify = (status) => {
    if ((status === "20" || status === "21") && !this.state.auditRemark) {
      message.error("请输入审核意见");
      return;
    }
    let param = {
      auditStatus: status,
      code: this.state.code,
    };
    if (status === "20" || status === "21") {
      param.auditRemark = this.state.auditRemark;
    }
    const config = {
      api: API.serviceInfoAudit,
      data: param,
      showLoading: true,
    };
    http(config).then((res) => {
      if (res && res.code === 200) {
        message.success(status === "20" ? "已驳回" : status === "21" ? "已强制下架" : "已通过");
        this.props.history.push(`/app/crm/sourcing?tab=8`);
      } else {
        // 待审核/已驳回 弹窗提示
        if(['待审核', '已驳回'].includes(res.message)) {
          Modal.error({
            icon: 'none',
            title: '提示',
            content: <div>当前信息关联的企业认证状态为<span className="red">{res.message}</span>状态，不能操作审核通过</div>,
          });
        }
        else {
          message.error(res.message)
        }
      }
    });
  };

  // 获取审批记录
  getAuditRecords = () => {
      let that = this;
      const config = {
          api: API.enterpriseAuthVehicleAuditRecord,
          path: {
              code: this.state.code
          },
          showLoading: true
      }
      http(config).then(res => {
          if (res && res.code == 200) {
              let auditRecords = res.data || []
              that.setState({
                  auditRecords
              })
          }
      })
  }

  watchRecord = () => {
      const { auditRecords } = this.state;

      const recordContent = auditRecords && auditRecords.length > 0 ? auditRecords.map(item => {
          return <div className="record_item">
              <div className="status">{item.auditStatusName}</div>
              {item.auditRemark ? <div className="info_item">
                  <span>理由：</span>
                  <span>{item.auditRemark}</span>
              </div> : ''}
              <div className="info_item">
                  <span>审批人：</span>
                  <span>{item.editUserName}</span>
              </div>
              <div className="info_item">
                  <span>时间：</span>
                  <span>{moment(item.editDate).format('YYYY-MM-DD HH:mm:ss')}</span>
              </div>
          </div>
      }) :
          <div className='log-no-data'><div className="log-no-data-text">暂无数据</div></div>;

      const recordHtml = <div className="sp_records">{recordContent}</div>;

      Modal.info({
          title: '审批记录',
          icon: null,
          content: recordHtml
      });
  }

  imageShow = (imgList, currentIndex) => {
    this.setState({
      imgList,
      currentIndex,
      showImgPreview: true
    })
  }
  closeImgPreview= () => {
    this.setState({
      showImgPreview: false
    })
  }

  render() {
    let { serviceInfoData, imageList } = this.state;
    return (
      <div className="sourcing-detail-page">
        <div>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={{ pathname: "/app/crm/sourcing", search: `?tab=8` }}>
                信息审核
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>服务信息</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="sourcing-content">
          {serviceInfoData && (
            <Descriptions bordered title="提交信息" className="table-part">
              <Descriptions.Item label="企业名称">
                {serviceInfoData.supplierName || "-"}
              </Descriptions.Item>
              <Descriptions.Item label="身份类型">
                {serviceInfoData.corpIdentityTypeName || "-"}
              </Descriptions.Item>
              <Descriptions.Item label="">

              </Descriptions.Item>
              <Descriptions.Item label="提交人">
                {serviceInfoData.createUser || "-"}
              </Descriptions.Item>
              <Descriptions.Item label="提交时间">
                {moment(serviceInfoData.submitDate).format('YYYY-MM-DD HH:mm:ss') || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="信息状态">
                {approveStatus[serviceInfoData.status] || "-"}
              </Descriptions.Item>
            </Descriptions>
          )}

          {serviceInfoData && (
            <Descriptions bordered title="服务信息" className="table-part">
              <Descriptions.Item label="服务范围" span={3}>
                {serviceInfoData.consultingServiceTypeList && serviceInfoData.consultingServiceTypeList.map((item, index) => {
                  if (index == serviceInfoData.consultingServiceTypeList.length - 1) {
                    return item.consultingServiceTypeName
                  }
                  return item.consultingServiceTypeName + '、'
                })}
              </Descriptions.Item>
              <Descriptions.Item label="业务区域" span={3}>
                {serviceInfoData.consultingServiceAreaList && serviceInfoData.consultingServiceAreaList.map((item, index) => {
                  if (index == serviceInfoData.consultingServiceAreaList.length - 1) {
                    return item.name
                  }
                  return item.name + '、'
                })}
              </Descriptions.Item>
              <Descriptions.Item label="联系人">
                {serviceInfoData.constantName || "-"}
              </Descriptions.Item>
              <Descriptions.Item label="联系电话">
                {serviceInfoData.constantPhone || "-"}
              </Descriptions.Item>
              <Descriptions.Item label="服务介绍" span={3}>
                {serviceInfoData.serviceIntroduction || "-"}
              </Descriptions.Item>
              <Descriptions.Item label="图片" span={3}>
                {imageList && imageList.map((item, index) => {
                  return ( <img src={item} className="item_img" key={index} onClick={() => {this.imageShow(imageList, index)}} ></img> )
                })}
              </Descriptions.Item>


              <Descriptions.Item label="审核意见" span={3}>
                  <TextArea
                    defaultValue={serviceInfoData.auditRemark}
                    onChange={this.changeAuditRemark}
                    style={{ width: "100%", padding: "20px" }}
                    name=""
                    id=""
                    cols="30"
                    rows="10"
                  ></TextArea>
                </Descriptions.Item>
            </Descriptions>
          )}

          
          {
            serviceInfoData.status !== 0 ? 
            <div className="approval-record" onClick={this.watchRecord}>审批记录</div>
            : null
          }
        </div>
        <div className="action-part">
          {serviceInfoData.status === 0 && (
            <Button
              className="action-button-active"
              onClick={() => {
                this.onVerify("10");
              }}
            >
              审核通过
            </Button>
          )}
          {serviceInfoData.status === 0 && (
            <Button
              className="action-button-active"
              onClick={() => {
                this.onVerify("20");
              }}
            >
              审核驳回
            </Button>
          )}
          {serviceInfoData.status === 10 && (
            <Button
              className="action-button-active"
              onClick={() => {
                this.onVerify("21");
              }}
            >
              强制下架
            </Button>
          )}
          <Button
            className="action-button-active"
            onClick={this.onTapForceOffShelves}
          >
            <Link to={{ pathname: "/app/crm/sourcing", search: `?tab=8` }}>
              返回
            </Link>
          </Button>
        </div>
        <ImgPreview show={this.state.showImgPreview} current={this.state.currentIndex} imgList={this.state.imgList} onClose={this.closeImgPreview}/>
      </div>
    );
  }
}
