import Table from 'CMPT/common/Table';
import http from '@/http';
import { API } from '@/http/api';
import AuthContainer from 'CMPT/common/auth/AuthContainer';
import { formatLinkSearch } from '@/utils'
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Button, Icon, Input, Row, Col, DatePicker, Checkbox, Modal, Tooltip, message } from 'antd';
import debounce from 'lodash/debounce';
import Select from 'CMPT/common/Select';
import { AUTH_KEYS } from '@/utils/auth';
import userUtil from '@/utils/user';
import SearchForm from './searchForm'
import { IdentityType, CertType } from "./enum";
import './EnterpriseAuthList.less';
import moment from 'moment';


// 筛选项默认值
const defaultParams = {
    pageNo: 1, // 需要查询的页数
    pageSize: 10, // 每页条数
    identityType: '',
    auditType: '',
    commitTimeStart: '',
    commitTimeEnd: '',
    name: '',
  };
class DriverAuthList extends Component {
    state = {
        total: 0, // 当前数据的总数
        pageNo: 1, // 当前所展示的页码
        currentPageCount: 0, //当前页面的数量
        selectParams: {  // 接口入参，必须放这里。方便分页时保存信息
            auditStatus: this.props.state,// 审批状态
            ...defaultParams 
        },
        columns: [
            {
                title: '序号',
                width: "60px",
                key: "id",
                render: (text, record, index) => {
                    return (<span>{index + 1}</span>);
                },
            },
            {
                title: '姓名',
                key: "applyName",
                dataIndex: 'applyName',
                render: (text, record) => { //待提交状态的，打开编辑页
                    let value;
                    if (text && text.length > 20) {
                        value = <Tooltip placement="top" title={text}>{text.slice(0, 20)}...</Tooltip>
                    } else {
                        value = text
                    }

                    return (<span   >{value}</span>);

                }
            }, {
                title: '昵称',
                key: "applyNickname",
                dataIndex: 'applyNickname',
                render: (text, record) => {
                    return (<span>{text}</span>);
                }
            },{
                title: '手机号码',
                key: 'applyMobile',
                dataIndex: 'applyMobile',
                render: (text, record) => {
                    return (<span>{text}</span>);
                }
            }, 
            {
                title: '认证状态',
                key: 'auditTypeName',
                dataIndex: 'auditTypeName',
            },
            {
                title: '邀请人',
                key: 'inviteUser',
                dataIndex: 'inviteUser',
            },
            {
                title: this.props.state == 0 ? '提交时间' : '审核时间',  // 0 表示待审核状态
                key: this.props.state == 0 ? 'createDate' : 'editDate',
                dataIndex: this.props.state == 0 ? 'createDate' : 'editDate',
                render: (text, record) => {
                    //console.log(' applyTime;%s', text);
                    return (<span>{moment(text).format('YYYY-MM-DD HH:mm')}</span>);
                }
            }, {
                title: '操作',
                key: 'operate',
                render: (text, record, index) => {
                    return (<span className="table-operate-btn">
                        <Link to={'/app/crm/enterprise_auth/detail/' + record.code + "?pageNo=" + this.state.pageNo + "&currentPageCount=" + this.state.currentPageCount+'&is='+this.props.is} className="operate">查看详情</Link>
                    </span>)
                }
            }
        ],
        data: [],
    }

    componentWillMount() {
        if (this.props.location) {
            // 获取参数
            const search = this.props.location.search
            const formatData = formatLinkSearch(search)

            // 解析
            let pageNo1 = null;
            if (formatData && formatData.backPageNo) {
                pageNo1 = formatData.backPageNo
            }

            // 赋值
            this.setState({
                selectParams: {
                    auditStatus: this.props.state,// 审批状态
                    pageNo: pageNo1, // 当前页数
                    pageSize: 10, // 每页条数
                }
            });
        }
    }

    //初始化
    componentDidMount() {
        this.init();
    }

    init = () => {
        const httpParams = { ...this.state.selectParams };
        for (let [key, value] of Object.entries(httpParams)) {
            
            if (value === -1 || value === '') {
            //    console.log('[key, value]',[key, value])
                httpParams[key] = undefined;
            }
        }
        const config = {
            api: API.enterpriseAuthAuditOperatorList,
            data: { ...httpParams },
            showLoading: true
        };
        
        http(config).then(res => {
            console.log(res, 'res1111111111111111111')
            if (res.code == 200) {
                const data = res.data;
                this.setState({
                    data: data.datas || [],
                    total: data.total, 
                    pageNo: data.pageNo,
                    currentPageCount:  data.datas ?  data.datas.length : 0,
                    selectParams: {...this.state.selectParams, pageNo: data.pageNo}
                }, () => {
                    console.log(res.data, this.state, '123')
                });
            }
        })
    }


    //分页
    onChangePage = (pageNo, pageSize, sort) => {
        this.setState({
            selectParams: {...this.state.selectParams, pageNo: pageNo, pageSize: pageSize}
        },() => {
        this.init()
        })
    }

    // 搜索
    onValuesChange = (allValues) => {
        this.setState({selectParams: { ...this.state.selectParams, ...allValues }})
    }

    reset = (allValues) => {
        this.setState({
            selectParams: {...this.state.selectParams,...defaultParams}
        }, () => {
            this.init();
        })
    }

    render() {
        const selectParams = this.state.selectParams
        return (
            <div className='enterprise-auth-list'>
                <div className='searchBox'>
                    <SearchForm onValuesChange={this.onValuesChange} types={false} reset={this.reset} onSearch={() => {this.init()}}/>
                </div>
                <Table  columns={this.state.columns} rowKey={(record, index) => record.code} selfProcessData={this.state.data} total={this.state.total} change={this.onChangePage} current={selectParams.pageNo} />
            </div>

        );
    }
}

export default DriverAuthList;