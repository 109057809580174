import React, { Component } from 'react';
import { deepclone } from '@/utils'
import moment from 'moment';

import { Radio, Select, Table, Row, DatePicker, Input, InputNumber, Icon } from 'antd';
class InexTable extends Component {
    state = {
        rentColumns: [
            {
                title: '收付',
                dataIndex: 'bp',
                align: 'center',
                width: '8%',
                render: (value) => value === 1 ? '收' : '付'
            }, {
                title: '费用类型',
                dataIndex: 'feiType',
                align: 'center',
                width: '10%',
                render: (value, row) => ['租金', '保证金', '免租期', '免租期'][row.rentType - 1]
            }, {
                title: '应收付日期',
                dataIndex: 'bpDate',
                align: 'center',
                width: '15%',
            }, {
                title: '费用周期',
                dataIndex: 'rentPeriod',
                align: 'center',
                width: '33%',
                render: (value) => value ? `${value[0]} 至 ${value[1]}` : ''
            }, {
                title: '收款金额(元)',
                dataIndex: 'money',
                align: 'center',
                width: '10%',
                render: (value, row) => {
                    if (row.rentType === 3 || row.rentType === 4) return <span>-</span>
                    return value
                }
            }, {
                title: '期数',
                dataIndex: 'parseNum',
                align: 'center',
                width: '6%',
                render: (value) => {
                    let text = value
                    if (typeof text === 'number') {
                        text = `第${text}期`
                    }
                    return text
                }
            }, {
                title: '备注',
                dataIndex: 'note',
                align: 'center',
                width: '12%'
            }, {
                title: '操作',
                dataIndex: 'operate',
                align: 'center',
                width: '6%',
            }
        ],
        rentData: []
    }
    componentDidMount() {
    }
    render() {
        const { tableData, rowClassName } = this.props
        return (
                <Table columns={this.state.rentColumns}
                        dataSource={tableData}
                        rowClassName={rowClassName}
                        pagination={false}
                />
        );
    }
}

export default InexTable;