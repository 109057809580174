import React, { Component } from 'react';
import {Row, Col, Icon} from 'antd'
import JobList from './JobList'

class JobPane extends Component {
    render() {
        return (
            <div className="job-pane">
                 <h4>
                     {this.props.title}
                 </h4>
                 <div className="job-pane-list">
                       <JobList title={this.props.title}  data={this.props.data}/> 
                 </div>
            </div>
        )
    }
}

export default JobPane
