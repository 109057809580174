import React, { Component } from 'react';
import { Layout, Menu, Breadcrumb, Button, Table, Row, Col, Tooltip, Card, Modal, Input } from 'antd';
import { Link } from 'react-router-dom'

import { changeObjkey, getNewPhone } from '@/utils/index'
import './GoodsDetail.less';
import { API } from '@/http/api';
import http from '@/http';
import { none } from 'ol/centerconstraint';
import {GoodsChannel} from "CMPT/common.js";
import userUtils from '@/utils/user';
import { AUTH_KEYS } from "@/utils/auth";
const { checkAuthByKey } = userUtils;
const canShowShenHeAuth = checkAuthByKey(AUTH_KEYS.GOODS_MANAGE_EXAMINE); // 审核权限；
const canShowXiaJiaAuth = checkAuthByKey(AUTH_KEYS.GOODS_MANAGE_DOWN); // 强制下架；


const { TextArea } = Input;
const { Header, Content, Footer } = Layout;

class GoodsDetail extends Component {
    state = {   
        liyouModal: false,
        isGoDowing: true,
        goodDetail: {},
        bigImgSrc: null,
        sensitiveCheckFlag: true
    }


    componentDidMount() {
        const match= this.props.match
        if (match && match.params && match.params.id) {
            // this.getOrderDetail(match.params.id)
            this.setState({
                goodCode: match.params.id
            }, () => {
                this.getGoodDetail();
            })
        }
    }

    getGoodDetail = () => {
        const config = {
            api: API.itemDetail,
            data: {
                itemCode: this.state.goodCode
            }
        }
        http(config).then(res => {
            if (res && res.success && res.data) {
                // 商品单位是 spu的属性，可以复制给SKU。方便操作
                if(res&&res.data&&res.data.skuList&&res.data.skuList.length>0){
                    let  itemUnit = res.data.itemUnit;
                    let itemUnitName =  res.data.itemUnitName;
                    res.data.skuList.forEach(item=>{
                        item.itemUnit = itemUnit;
                        item.itemUnitName =itemUnitName;
                    });
                }
 
                // 赋值
                this.setState({
                    goodDetail: res.data,
                   // sensitiveCheckFlag: res.data.sensitiveCheckFlag ? false : true
                })
            }
        })
    }

    // 敏感词检测
    getMGDetail = () => {
        let that = this;
        const config = {
            api: API.itemSensitiveWords,
            data: {
                itemCode: this.state.goodCode,
                shopCode: this.state.goodDetail.shopCode,
            }
        }
        http(config).then(res => {
            if (res && res.success && res.data) {
                const data = res.data;
                if(data.checkResult) {
                    //检测成功
                    Modal.success({
                        title: '校验通过',
                        okText: '我已知晓',
                        content: (
                            <p className="mg_content">商品信息中没有敏感词</p>
                        ),
                        onOk() {
                            that.mgOk();
                        }
                    });
                } else {
                    // 检测失败
                    Modal.warning({
                        title: '在审核前必须了解的重要信息',
                        okText: '我已知晓',
                        content:(
                            <div className="mg_content">
                             {
                                 data.sensitivePropertyNames.map(item => (<p key={item}>{item}</p>))
                             }
                            </div>
                        ),
                        onOk() {
                             that.mgOk();
                        },
                     });
                }
            }
        }); 
    }

    statusChange = (status) => {
        switch (status) {
            case 2:
                return '审核通过';
            case 3:
                return '审核驳回';
            case 4:
                return '强制下架';
            case 5:
                return '校验敏感词';
            case 6:
                return '阅读敏感词提示';
            default:
                break;
        }
    }

    watchRecord = () => {
        const { auditSelectVos } = this.state.goodDetail;

        // const record = [
        //     {auditType: '审批通过', auditUser: 'admin', createTime: '2020-01-20', auditInfo: ''},
        //     {auditType: '审批通过', auditUser: 'admin', createTime: '2020-01-20', auditInfo: 'xxx'},
        //     {auditType: '审批通过', auditUser: 'admin', createTime: '2020-01-20', auditInfo: 'xxx'},
        // ]
        const recordContent = auditSelectVos && auditSelectVos.length > 0 ? auditSelectVos.map(item => {
            return <div className="record_item">
                <div className="status">{this.statusChange(item.auditType)}</div>
                {item.auditInfo ? <div className="info_item">
                    <span>理由：</span>
                    <span>{item.auditInfo}</span>
                </div> : ''}
                {item.sensitivePropertyNames ? <div className="info_item">
                    <span>内容：</span>
                    {
                        item.sensitivePropertyNames.map(i => (<span key={i}>{i};</span>))
                    }
                </div> : ''}
                <div className="info_item">
                    <span>审批人：</span>
                    <span>{item.auditUser}</span>
                </div>
                <div className="info_item">
                    <span>时间：</span>
                    <span>{item.createTime}</span>
                </div>
            </div>
        }) :
        <div className='log-no-data'><div className="log-no-data-text">暂无数据</div></div>;

        const recordHtml = <div className="sp_records">{recordContent}</div>;
        
        Modal.info({
            title: '审批记录',
            icon: null,
            content: recordHtml
        });
    }
    // 弹窗 确定
    handleOk = () => {
        if (this.state.isGoDowing) {
            this.doingGoDowing()
        } else {
            this.approvalDoing(false)
        }
        // if (this.state.isGoDowing) {
        // } else {
        //     this.dogingCannotGo()
        // }
    }
    // 去下架
    doingGoDowing = () => {
        const config = {
            api: API.itemForceOffShelves,
            data: {
                auditInfo: this.state.auditInfo,
                auditType: 4,
                itemCode: this.state.goodCode,
                shopCode: this.state.goodDetail.shopCode,
                updateTime: this.state.goodDetail.updateTime
            },
            successTip: '下架成功'
        }
        http(config).then(res => {
            if (res && res.success) {
                this.setState({liyouModal: false})
                this.getGoodDetail()
            } else {
                if (res.code == '300001') {
                    Modal.info({
                        title: '信息有变更',
                        icon: null,
                        content: '商品信息有变更，请确认变更内容后重新操作',
                        onOk: () => {
                            this.getGoodDetail()
                        }
                    })
                }
            }
        })
    }
    // 点击下架按钮
    goDowningGoods = (isGoingDown) => {
        this.setState({
            isGoDowing: isGoingDown,
            liyouModal: true
        })
    }
    // 审核接口
    approvalDoing = (isTongguo) => {
        let config = {api: API.itemAudit};
        if (isTongguo) {
            config.data = {
                auditType: 2,
                itemCode: this.state.goodCode,
                shopCode: this.state.goodDetail.shopCode
            }
            config.successTip = '通过成功'
        } else {
            config.data = {
                auditInfo: this.state.auditInfo,
                auditType: 3,
                itemCode: this.state.goodCode,
                shopCode: this.state.goodDetail.shopCode
            }
            config.successTip = '驳回成功'
        }
        http(config).then(res => {
            if (res && res.success) {
                this.getGoodDetail()
                if (!isTongguo) {
                    this.setState({liyouModal: false})
                }
            }
        })
    }
    // 审批通过
    approvalCanGo = () => {
        Modal.confirm({
            title: '通过',
            content: '是否确认通过？',
            okText: '通过',
            onOk: ()=> {
                this.approvalDoing(true)
            }
        })
    }

    // 我已知晓
    mgOk = () => {
        const that = this;
        const config = {
            api: API.itemAudit,
            data: {
                auditType: 6,
                itemCode: this.state.goodCode,
                shopCode: this.state.goodDetail.shopCode
            }
        }
        http(config).then(res => {
            if (res && res.success) {
                that.setState({
                    sensitiveCheckFlag: false
                });
                that.getGoodDetail();
            }
        });
    }

    watchBigImage = (src) => {
        this.setState({
            bigImgSrc: src
        })
    }

    textareaChange = (e) => {
        this.setState({
            auditInfo: e.target.value
        })
    }

   
    

    render() {
        const columns = [
            {
              title: '款式',
              dataIndex: 'skuName',
              key: 'skuName',
            //   render: (text, record) => {
            //         return (<span>{record.orderSkuSnapshotDto && record.orderSkuSnapshotDto.itemName || '无'}</span>);
            //     }
            },
            {
              title: '库存',
              dataIndex: 'stock',
              key: 'stock',
            //   render: (text, record) => {
            //     return (<span>{record.orderSkuSnapshotDto && record.orderSkuSnapshotDto.skuSpecValue && record.orderSkuSnapshotDto.skuSpecValue.map(item => {
            //         return item.specValueName + ' '
            //     })}</span>);
            //   }
            },
            {
              title: '价格',
              dataIndex: 'price', // quantities
              key: 'price',
               render: (text, record) => {
                   if(record.pricingType==1){
                      return <span>需询价</span>;
                   }

                let unitName = record.itemUnitName?("/"+record.itemUnitName):'';
                return (<span>{record.price+unitName}</span>);
               }
            },
            {
                title: 'SKU编码',
                dataIndex: 'skuNo',
                key: 'skuNo',
            }
        ];
        const { isGoDowing, goodDetail, bigImgSrc, auditInfo, sensitiveCheckFlag } = this.state;
        const authenticationStatusName = goodDetail && goodDetail.authenticationStatus === 1 ? '已完成认证' : [0, 2].indexOf(goodDetail.authenticationStatus) != -1 ? '未完成认证' : '';
        const authenticationStatusFlag = goodDetail && goodDetail.authenticationStatus === 1 ? false : true;
        return (
            <Content className="intention-list intention-content goods_detail">
                <div className="intention-list-head flexbox">
                    <Breadcrumb className="intention-list-head-bread">
                        <Breadcrumb.Item>
                            <Link style={{ color: '#333' }} to={{ pathname: "/app/crm/goodsManage" }}>
                                商品列表
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            商品详情
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className="info_content">

                    <div className="info_item">
                        <span className="item_label">认证状态：</span>
                        <span className="item_msg">
                            {authenticationStatusName || '无'}
                        </span>
                    </div>
                    
                    <div className="info_item">
                        <span className="item_label">供应商名称：</span>
                        <span className="item_msg">{goodDetail && goodDetail.companyName || '无'}</span>
                    </div>
                    
                    <div className="info_item">
                        <span className="item_label">店铺名称：</span>
                        <span className="item_msg">{goodDetail && goodDetail.shopName || '无'}</span>
                    </div>

                    <div className="info_item">
                        <span className="item_label">开票税率：</span>
                        <span className="item_msg">{goodDetail && goodDetail.taxRate && (goodDetail.taxRate).replaceAll("/","%,")+"%" || '无'}</span>
                    </div>
                    
                    <div className="info_item">
                        <span className="item_label">商品编码：</span>
                        <span className="item_msg">{goodDetail && goodDetail.itemNo || '无'}</span>
                    </div>
                    
                    <div className="info_item">
                        <span className="item_label">商品状态：</span>
                        <span className="item_msg">{goodDetail && goodDetail.statusName || '无'}</span>
                    </div>
                    
                    <div className="info_item">
                        <span className="item_label">商品分类：</span>
                        <span className="item_msg">{goodDetail && goodDetail.category && goodDetail.category.map(item => {return item.name + ' '}) || '无'}</span>
                        {
                            goodDetail && goodDetail.categoryStatus === 0 ? (
                                <span className="look_lm">
                                    与认证类目不一致  
                                    <Link to={{ pathname: `/app/crm/client/newdetail/${goodDetail.companyCode}?tab=2` }} className="look_lm_btn">查看认证类目</Link>
                                </span>
                            ) : ''
                        }
                        
                    </div>
                    
                    <div className="info_item">
                        <span className="item_label">商品名称：</span>
                        <span className="item_msg">{goodDetail && goodDetail.itemName || '无'}</span>
                    </div>

                    <div className="info_item">
                        <span className="item_label">商品渠道：</span>
                        <span className="item_msg">{goodDetail && goodDetail.channelId? GoodsChannel[goodDetail.channelId]: '-'}</span>
                    </div>

                    <div className="info_item next_line">
                        <span className="item_label">商品图片：</span>
                        <div className="item_msg goods_img">
                            { 
                                goodDetail 
                                && goodDetail.itemMediaVoList 
                                && goodDetail.itemMediaVoList.map(item => {return <div className="img_box"><img src={item.url} key={item.name}  onClick={()=> {this.watchBigImage(item.url)}}/></div>}) 
                                || '无'
                            }
                        </div>
                    </div>
                    <div className="info_item next_line">
                        <span className="item_label">产品质检报告</span>
                        <div className="item_msg goods_img">
                            { 
                                goodDetail 
                                && goodDetail.configAttachmentList 
                                && goodDetail.configAttachmentList.map(item => {return <div className="img_box"><img src={item.url} key={item.name}  onClick={()=> {this.watchBigImage(item.url)}}/></div>}) 
                                || '无'
                            }
                        </div>
                    </div>
                    <div className="info_item">
                        <span className="item_label">商品标签：</span>
                        <span className="item_msg">
                            { 
                                goodDetail 
                                && goodDetail.itemLabelList 
                                && goodDetail.itemLabelList.map(item => {return item.labelName + ' '}) 
                                || '无'
                            }
                        </span>
                    </div>
                    <div className="info_item">
                        <span className="item_label">商品描述：</span>
                        <span className="item_msg">{goodDetail && goodDetail.itemDescripe || '无'}</span>
                    </div>
                    <div className="info_item">
                        <span className="item_label">商品属性：</span>
                        <span className="item_msg">{goodDetail && goodDetail.itemProp || '无'}</span>
                    </div>
                    <div className="info_item next_line">
                        <span className="item_label">商品规格&价格&库存：</span>
                        {
                            goodDetail && goodDetail.skuList ?
                            <Table rowKey='skuCode' dataSource={goodDetail.skuList} columns={columns} pagination={false}></Table>
                            : null
                        }
                    </div>
                    <div className="info_item next_line">
                        <span className="item_label">规格图片：</span>
                        <span className="item_msg goods_img">
                            { 
                                goodDetail 
                                && goodDetail.specValueList 
                                && goodDetail.specValueList.map(item => {
                                    return <>{
                                        item.specValueList && item.specValueList.map(spec => {
                                            return <div className="img_box">
                                                        <img src={spec.itemMedia && spec.itemMedia.url} key={spec.itemMedia && spec.itemMedia.mediaKey}  onClick={()=> {this.watchBigImage(spec.itemMedia && spec.itemMedia.url)}}/>
                                                        <span>{spec.specValueName}</span>
                                                    </div>
                                        }) 
                                    }</>
                                    
                                }) 
                                || '无'
                            }
                        </span>
                    </div>
                    <div className="info_item">
                        <span className="item_label">售后服务：</span>
                        <span className="item_msg">{goodDetail && goodDetail.itemAftersale || '无'}</span>
                    </div>
                    <div className="info_item next_line">
                        <span className="item_label">商品详情：</span>
                        <span className="item_msg">
                        
                            {
                                goodDetail && goodDetail.itemInfo ?
                                <div dangerouslySetInnerHTML={{
                                    __html: goodDetail.itemInfo
                                  }}/> :
                                '无'
                            }
                        </span>
                    </div>

                    <div className="shenpi_btn" onClick={this.watchRecord}>审批记录</div>
                </div>

                {
                    goodDetail.status == '0' && canShowShenHeAuth ? 
                    <div className="shenpi_btns">
                       {/* <Button onClick={this.getMGDetail} >敏感词校验</Button> 
                           <Button type="primary" onClick={this.approvalCanGo} disabled={sensitiveCheckFlag || authenticationStatusFlag}>通过</Button>
                           <Button type="" onClick={() => {this.goDowningGoods(false)}} disabled={sensitiveCheckFlag || authenticationStatusFlag}>驳回</Button>
                       */}
                        <Button type="primary" onClick={this.approvalCanGo}  >通过</Button>
                        <Button type="" onClick={() => {this.goDowningGoods(false)}}  >驳回</Button>
                    </div>
                    : goodDetail.status == '2' && canShowXiaJiaAuth ? 
                    <div className="shenpi_btns">
                        <div>
                        <Button type="primary" className="go_down_btn" onClick={() => {this.goDowningGoods(true)}}>违规下架</Button>
                        </div>
                    </div>
                    : ''
                }

                <Modal
                    title={isGoDowing ? '违规下架' : '驳回'}
                    visible={this.state.liyouModal}
                    onOk={this.handleOk}
                    onCancel={()=> {this.setState({liyouModal:false})}}
                    okButtonProps={{disabled:!auditInfo}}
                    >
                    <div>
                        <span className="modal_tit">{isGoDowing ? '下架理由' : '驳回理由'}</span>
                        <TextArea 
                            value={auditInfo}
                            onChange={this.textareaChange}
                            maxLength={100}
                            placeholder={isGoDowing ? '请输入下架理由（100字以内）' : '请输入驳回理由（100字以内）'}>
                        </TextArea>
                    </div>
                </Modal>
                {
                    bigImgSrc ?
                        <div className="big_img" onClick={() => { this.setState({ bigImgSrc: null }) }}>
                            <img src={bigImgSrc} alt="" />
                        </div>
                        : null
                }
            </Content>
        )
    }

}

export default GoodsDetail;