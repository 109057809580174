import { AJAX_TYPE } from "../config";

// 账号管理
const prefix = "account";

export default {
  // 账号管理-列表
  ["accountList"]: {
    url: "/web/crm/account/getAccountList",
    type: AJAX_TYPE.POST,
  },
  // 添加
  ["accountAdd"]: {
    url: "/web/crm/account/addAccount",
    type: AJAX_TYPE.POST,
  },

  // 查看详情
  ["accountDetail"]: {
    url: "/web/crm/account/getDetail",
    type: AJAX_TYPE.GET,
  },

  // 更新账号状态
  ["accountUpdate"]: {
    url: "/web/crm/account/updateAccount",
    type: AJAX_TYPE.POST,
  },

  // /web/crm/promission/role/all
  // 全部角色

  ["accountRoleAll"]: {
    url: "/web/crm/promission/role/all",
    type: AJAX_TYPE.GET,
  },

  // 开户申请列表
  [`${prefix}ListForPage`]: {
    url: "/api/payPlatform/v1/basis/openAccount/listForPage",
    type: AJAX_TYPE.POST,
  },
  // 开户申请详情
  [`${prefix}DetailForCrm`]: {
    url: "/api/payPlatform/v1/basis/openAccount/detailForCrm/:code",
    type: AJAX_TYPE.GET,
  },
  // 提交审核
  [`${prefix}Submit`]: {
    url: "/api/payPlatform/v1/basis/openAccount/submit",
    type: AJAX_TYPE.POST,
  },
  // 生成协议
  [`${prefix}CreateAgreement`]: {
    url: "/api/payPlatform/v1/basis/openAccount/createAgreement",
    type: AJAX_TYPE.POST,
  },
  // 确认协议
  [`${prefix}ConfirmAgreement`]: {
    url: "/api/payPlatform/v1/basis/openAccount/confirmAgreement",
    type: AJAX_TYPE.POST,
  },
  // 获取协议
  [`${prefix}GetAgreement`]: {
    url: "/api/payPlatform/v1/basis/openAccount/getAgreement",
    type: AJAX_TYPE.POST,
  },
  // 获取商户协议图片
  [`${prefix}GetAgreementImage`]: {
    url: "/api/payPlatform/v1/basis/openAccount/getAgreementImage",
    type: AJAX_TYPE.POST,
  },
  // 确认协议,发送开户通知
  [`${prefix}SubmitAgreement`]: {
    url: "/api/payPlatform/v1/basis/openAccount/submitAgreement",
    type: AJAX_TYPE.POST,
  },
  // 驳回协议
  [`${prefix}RejectAgreement`]: {
    url: "/api/payPlatform/v1/basis/openAccount/rejectAgreement",
    type: AJAX_TYPE.POST,
  },
  // 完成开户
  [`${prefix}CompleteOpening`]: {
    url: "/api/payPlatform/v1/basis/openAccount/passAgreement",
    type: AJAX_TYPE.POST,
  },
  // 协议模版
  [`${prefix}AgreementListForPage`]: {
    url: "/api/payPlatform/v1/basis/Agreement/listForPage",
    type: AJAX_TYPE.POST,
  },
  // 变更协议
  [`${prefix}EditAgreement`]: {
    url: "/api/payPlatform/v1/basis/openAccount/editAgreement",
    type: AJAX_TYPE.POST,
  },
  // 变更协议记录
  [`${prefix}GetRecordList`]: {
    url: "/api/payPlatform/v1/basis/changeRecordt/getRecordlist",
    type: AJAX_TYPE.POST,
  },
  

  ["accountGetOrganizationTreeList"]: {
    url: "/web/crm/organization/getOrganizationTreeList",
    type: AJAX_TYPE.GET,
  },

  ["accountGetOrganizationList"]: {
    url: "/web/crm/organization/getOrganizationList",
    type: AJAX_TYPE.GET,
  },

  ["accountAddOrganization"]: {
    url: "/web/crm/organization/addOrganization",
    type: AJAX_TYPE.POST,
  },

  ["accountUpdateOrganization"]: {
    url: "/web/crm/organization/updateOrganization",
    type: AJAX_TYPE.POST,
  },

  ["accountDeleteOrganization"]: {
    url: "/web/crm/organization/deleteOrganization",
    type: AJAX_TYPE.POST,
  },

  

  
  

  
  

  
};
