
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom';
import './LeaseOperateMsgDetail.less'
import { API } from '@/http/api';
import http from '@/http';
import { Breadcrumb, Button, Modal, Input, message, Table } from 'antd';
import IMGurl from '@/images/empty_schedule@2x.png'
import { showErrorMessage, } from '@/utils/globalMessageReminder';
import { formatLinkSearch } from '@/utils';
import moment from 'moment';
import ImgPreview from 'CMPT/common/ImgPreview';
const { TextArea } = Input;

const approveStatus = {
  0: "审核中",
  1: "审核通过",
  2: "审核驳回",
  3: "强制下架"
};

const userInfoData = [
  { label: "企业名称", field: "companyName" },
  { label: "身份类型", field: "identityType" },
  { label: "提交人", field: "createUser" },
  { label: "提交时间", field: "createDate" },
  { label: "信息状态", field: "auditStatusName" },
]

const goodsInfoName = [
  { label: "服务区域", field: "areaName", isArray: true, isString: true },
  { label: "设备类型", field: "equipmentType", isArray: true, isString: true },
  { label: "服务特色", field: "sellingPoint", isArray: true, isString: true },
  { label: "设备规模", field: "equipmentScale" },
  { label: "联系人", field: "contactsName" },
  { label: "联系电话", field: "contactsMobile" },
  { label: "服务介绍", field: "serviceProfile" },
  { label: "图片", field: "urls", isArray: true, isString: false },
]


const columns = [
  {
    title: '货运身份',
    dataIndex: 'driverTypeName',
    key: 'driverTypeName',
  },
  {
    title: '名称',
    dataIndex: 'quotedName',
    key: 'quotedName',
  },
  {
    title: '联系方式',
    dataIndex: 'quotedMobile',
    key: 'quotedMobile',
  }, {
    title: '报价',
    dataIndex: 'quotedPrice',
    key: 'quotedPrice',
  }, {
    title: '报价时间',
    dataIndex: 'createDate',
    key: 'createDate',
    render: (text, record) => {
      return (<span>{text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : ''}</span>);
  }
  },
];
class GoodsAuditDetail extends Component {
  state = {
    code: null, // 当前页面的ID,从前一个页面传递过来的
    pageNo: null, // 当前企业在前一个列表中的页码
    currentageCount: null, // 前一个页面中 列表内当前页的数量
    backPageNo: 1, //返回列表页面的页码
    rejectInput: '', // 驳回原因
    data: [],
    showRejectConfirmFlag: false, // 是否展示 驳回 对话框    
    auditRecords: [], // 审核记录
    
    modalTitle: '',
    modalPlaceholder: '',
    modalNum: null,
    imgList : [], 
    currentIndex: 0, 
    showImgPreview: false
  }
  componentWillMount() {
    this.cDetail();

    if (this.props.location.search) {
      // 获取参数
      const search = this.props.location.search
      const formatData = formatLinkSearch(search)

      // 解析
      let code1 = this.props.match.params.code;
      let pageNo1 = null;
      let currentageCount1 = null;

      if (formatData && formatData.pageNo) {
        pageNo1 = formatData.pageNo
      }
      if (formatData && formatData.currentPageCount) {
        currentageCount1 = formatData.currentPageCount
      }

      // 赋值
      this.setState({
        code: code1,
        pageNo: pageNo1,
        backPageNo: pageNo1, //如果什么都不做，返回列表的原始页码即可。否则，需要判断跳转哪一页，尤其删除一个页面中的唯一一条数据
        currentageCount: currentageCount1,
      });
    }
  }
  componentDidMount() { }

  cDetail = () => {
    console.log(this.props, 'this.props')
    const config = {
      api: API.sourcingBusinessInformationDetail,
      path: { code: this.props.match.params.code },
    };
    http(config).then(res => {
      console.log(res, 'res333  车辆详情')
      if (res && res.code == 200) {
        let { data } = res;
        data.createDate = data.createDate ? moment(data.createDate).format('YYYY-MM-DD HH:mm:ss') : '';
        data.auditStatusName = approveStatus[data.auditStatus];
        this.setState({
          data
        });
      }
    })
  }
  onRejectInput = (e) => {
    this.setState({
      rejectInput: e.target.value
    })
  };
  // 去展示 审核通过 对话框
  showPassAuthDialog = () => {
    // console.log(this.state.data, 'this.state.data')
    let config = {
      api: API.sourcingAuditBusinessInformation,
      data: {
        auditStatus: 10,
        code: this.props.match.params.code,
      },
    }
    http(config).then(res => {
      if (res.code == 200) {
        message.success('操作成功')
        setTimeout(()=> {
          this.cDetail()
        }, 1000)
      } else {
        // 待审核/已驳回 弹窗提示
        if(['待审核', '已驳回'].includes(res.message)) {
          Modal.error({
            icon: 'none',
            title: '提示',
            content: <div>当前信息关联的企业认证状态为<span className="red">{res.message}</span>状态，不能操作审核通过</div>,
          });
        }
        else {
          message.error(res.message)
        }
      }
    })
  }

  gotoReject = () => {
    const { modalNum } = this.state
    console.log(modalNum, 'modalNum')
    let strReason = modalNum == 10 ? "请输入下架原因" : "请输入驳回原因"
    let regStrReason = modalNum == 10 ? "请输入有效的下架原因" : "请输入有效的驳回原因"
    // 不能为null
    if (!this.state.rejectInput) {
      showErrorMessage(strReason);
      return;
    }
    // 去掉两边可能的空格
    var rejectInput = this.state.rejectInput.replace(/(^\s*)|(\s*$)/g, "");
    if (rejectInput.length < 1) {
      showErrorMessage(regStrReason);
      return;
    }
    let data = modalNum == 10 ? {
      code: this.props.match.params.code,
      auditStatus: 21,
      auditRemark: this.state.rejectInput,
    } : {
      code: this.props.match.params.code,
      auditStatus: 20,
      auditRemark: this.state.rejectInput,
    }
    let config = {
      api: API.sourcingAuditBusinessInformation,
      data,
    }
    http(config).then(res => {
      console.log(res, 'res1111111111111111111')
      if (res.code == 200) {
        this.setState({ showRejectConfirmFlag: false });
        message.success('操作成功')
        setTimeout(()=> {
          this.cDetail()
        }, 1000)
      } else {
        this.setState({ showRejectConfirmFlag: false });
        message.error(res.message)
      }
    })

  }
  /* start: 审核驳回的相关逻辑  */
  hidenRejectAuthDialog = () => {
    this.setState({ showRejectConfirmFlag: false });
  };

  showRejectAuthDialog = () => { // 驳回
    this.setState({ 
      showRejectConfirmFlag: true,
      modalTitle: '驳回',
      modalPlaceholder: '请输入驳回理由 （100字以内）',
      modalNum: 0,
    });
  };
  showForcedDialog = () => { // 强制下架
    this.setState({ 
      showRejectConfirmFlag: true,
      modalTitle: '强制下架',
      modalPlaceholder: '请输入下架理由 （100字以内）',
      modalNum: 10,
    });
  };

  // 获取审批记录
  watchRecord = () => {
    let recordHtml = null
    const config = {
      api: API.sourcingFreightAuditAuditRecord,
      path: {
        code: this.props.match.params.code
      },
    }
    http(config).then(res => {
      if (res && res.code == 200) {
        let auditRecords = res.data || []
        const recordContent = auditRecords && auditRecords.length > 0 ? auditRecords.map(item => {
          return <div className="record_item">
            <div className="status">{item.auditStatusName}</div>
            {item.auditRemark ? <div className="info_item">
              <span>理由：</span>
              <span>{item.auditRemark}</span>
            </div> : ''}
            <div className="info_item">
              <span>审批人：</span>
              <span>{item.createUserName}</span>
            </div>
            <div className="info_item">
              <span>时间：</span>
              <span>{item.createDate ? moment(item.createDate).format('YYYY-MM-DD HH:mm') : '-'}</span>
            </div>
          </div>
        }) :
          <div className='log-no-data'><div className="log-no-data-text">暂无数据</div></div>;

        recordHtml = <div className="sp_records">{recordContent}</div>;
      }
      Modal.info({
        title: '审批记录',
        icon: null,
        content: recordHtml
      });
    })
  }

  imageShow = (imgList, currentIndex) => {
    this.setState({
      imgList,
      currentIndex,
      showImgPreview: true
    })
  }
  closeImgPreview= () => {
    this.setState({
      showImgPreview: false
    })
  }


  render() {
    const { data, modalTitle, modalPlaceholder, imgList, currentIndex, showImgPreview } = this.state
    return (
      <div className="userInfo">
        <div>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={{ pathname: "/app/crm/sourcing", search: '?tab=4' }}>
                信息审核
              </Link>
            </Breadcrumb.Item >
            <Breadcrumb.Item>
              出租经营信息
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div className="userInfo_body">
          <div>
            <h2><i></i>  提交信息</h2>
            <div>
              <ul>
                {data && userInfoData.map(item => {
                  return (
                    <li key={item.field}><span>{item.label}</span><span className="span">{data[item.field]}</span></li>
                  )
                })}
              </ul>
            </div>
          </div>

          <div>
            <h2><i></i>  经营信息</h2>
            <ul>
              {data && goodsInfoName.map(item => {
                if (item.isArray) {
                  if (item.isString) {
                    return (
                      <li key={item.field}><span>{item.label}</span><span className="span">{
                        data[item.field] && data[item.field].map((arr, index) => {
                          if (index == data[item.field].length - 1) {
                            return arr
                          }
                          return arr + '、'
                        })
                      }</span></li>
                    )
                  }
                  return (
                    <li className="li_image" key={item.field}><span>{item.label}</span><span className="img_box">{
                      data[item.field] && data[item.field].map((arr, idx) => {
                        return <img alt="" key={arr} src={arr} onClick={this.imageShow.bind(this, data[item.field], idx)}/>
                      })
                    }</span></li>
                  )
                }
                return (
                  <li key={item.field}><span>{item.label}</span><span className="span">{data[item.field]}</span></li>
                )
              })}
            </ul>
          </div>
          <div className="approval-record" onClick={this.watchRecord}>审批记录</div>
        </div>
        {data.auditStatus == 0 ?
          (<div className="detail_btn">
            <Button type="primary" onClick={this.showPassAuthDialog.bind(this)}> 通过 </Button>
            <Button onClick={this.showRejectAuthDialog.bind(this)}> 驳回  </Button>
          </div>) : null
        }
        {data.auditStatus == 1 ?
          (<div className="detail_btn">
            <Button type="primary" onClick={this.showForcedDialog.bind(this)}> 强制下架 </Button>
          </div>) : null
        }
        <Modal
          title={modalTitle}
          visible={this.state.showRejectConfirmFlag}
          okText='确定'
          cancelText='取消'
          closable={false}
          maskClosable={false}
          onOk={this.gotoReject}
          onCancel={this.hidenRejectAuthDialog}
          width={400} >
          <TextArea rows={4} onChange={this.onRejectInput} placeholder={modalPlaceholder} maxLength={100} />
        </Modal>
        <ImgPreview show={showImgPreview} current={currentIndex} imgList={imgList} onClose={this.closeImgPreview}/>
      </div>
    )
  }
}
export default GoodsAuditDetail;
