import React from 'react';


export default {


    // 天眼查公司行政处罚
    [`tyc_aadminstrativesanction`]: [
        {
            title: '行政处罚内容',
            key: "content",
            dataIndex: 'content',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },            
        },
        {
            title: '行政处罚决定书文号',
            key: "punishNumber",
            dataIndex: 'punishNumber',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        },
        {
            title: '作出行政处罚决定日期',
            key: "decisionDate",
            dataIndex: 'decisionDate',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        },
        {
            title: '违法行为类型',
            key: "type",
            dataIndex: 'type',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        },
        {
            title: '作出行政处罚决定机关名称',
            key: "departmentName",
            dataIndex: 'departmentName',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        },
        {
            title: '公示日期',
            key: "publishDate",
            dataIndex: 'publishDate',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        },


    ],
    // 天眼查经营异常
    [`tyc_abnormaloperation`]: [
        {
            title: '列入日期',
            key: "putDate",
            dataIndex: 'putDate',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        },
        {
            title: '移出日期',
            key: "removeDate",
            dataIndex: 'removeDate',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        },
        {
            title: '移出部门',
            key: "removeDepartment",
            dataIndex: 'removeDepartment',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        },
        {
            title: '移除异常名录原因',
            key: "removeReason",
            dataIndex: 'removeReason',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        },
        {
            title: '列入异常名录原因',
            key: "putReason",
            dataIndex: 'putReason',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        },
        {
            title: '决定列入异常名录部门(作出决定机关)',
            key: "putDepartment",
            dataIndex: 'putDepartment',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        },


    ],

    // 天眼查欠税公告
    [`tyc_arrearsnotice`]: [
        {
            title: '欠税金额',
            key: "ownTaxAmount",
            dataIndex: 'ownTaxAmount',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        },
        {
            title: '税务类型',
            dataIndex: 'type',
            key: 'type',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        }, {
            title: '欠税税种',
            dataIndex: 'taxCategory',
            key: 'taxCategory',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        }, {
            title: '当前新发生欠税余额',
            dataIndex: 'newOwnTaxBalance',
            key: 'newOwnTaxBalance',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        }, {
            title: '欠税余额',
            dataIndex: 'ownTaxBalance',
            key: 'ownTaxBalance',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        }, {
            title: '纳税人识别号',
            dataIndex: 'taxIdNumber',
            key: 'taxIdNumber',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        }, {
            title: '发布时间',
            dataIndex: 'publishDate',
            key: 'publishDate',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        },

    ],

    // 天眼查公司变更记录
    [`tyc_changelog`]: [
        {
            title: '变更时间',
            key: "changeTime",
            dataIndex: 'changeTime',
            width: "140px",
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },            
        },
        {
            title: '变更事项',
            dataIndex: 'changeItem',
            key: 'changeItem',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        }, {
            title: '变更前',
            dataIndex: 'contentBefore',
            key: 'contentBefore',
            render: (text, record) => {
                return (<span dangerouslySetInnerHTML={{__html:text}}></span>);
            }
        }, {
            title: '变更后',
            dataIndex: 'contentAfter',
            key: 'contentAfter',
            render: (text, record) => {
                return (<span dangerouslySetInnerHTML={{__html:text}}></span>);
            }
        },

    ],

    // 天眼查公司法院公告
    [`tyc_courtnotic`]: [
        {
            title: '案件内容',
            key: "content",
            dataIndex: 'content',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },
        {
            title: '法院名',
            dataIndex: 'courtcode',
            key: 'courtcode',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        }, {
            title: '处理等级',
            dataIndex: 'dealgrade',
            key: 'dealgrade',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        }, {
            title: '原告',
            dataIndex: 'party1',
            key: 'party1',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        }, {
            title: '当事人',
            dataIndex: 'party2',
            key: 'party2',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        }, {
            title: '刊登日期',
            dataIndex: 'publishdate',
            key: 'publishdate',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        }, {
            title: '原因',
            dataIndex: 'reason',
            key: 'reason',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        },

    ],

    // 天眼查行政处罚-信用中国
    [`tyc_creditchina`]: [
        {
            title: '处罚结果',
            key: "result",
            dataIndex: 'result',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        },
        {
            title: '行政处罚决定书文号',
            key: "punishNumber",
            dataIndex: 'punishNumber',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        },
        {
            title: '处罚事由',
            key: "reason",
            dataIndex: 'reason',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        },
        {
            title: '处罚依据',
            key: "evidence",
            dataIndex: 'evidence',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        },
        {
            title: '处罚状态',
            key: "punishStatus",
            dataIndex: 'punishStatus',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        },
        {
            title: '作出行政处罚决定日期',
            key: "decisionDate",
            dataIndex: 'decisionDate',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        },
        {
            title: '处罚类别',
            key: "type",
            dataIndex: 'type',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        },
        {
            title: '作出行政处罚决定机关名称',
            key: "departmentName",
            dataIndex: 'departmentName',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        },
        {
            title: '处罚名称',
            key: "punishName",
            dataIndex: 'punishName',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        },


    ],

    // 天眼查失信人
    [`tyc_dishonestperson`]: [
        {
            title: '发布日期',
            key: "publishdate",
            dataIndex: 'publishdate',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        },
        {
            title: '立案日期',
            key: "regdate",
            dataIndex: 'regdate',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        },
        {
            title: '案号',
            key: "casecode",
            dataIndex: 'casecode',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        },
        {
            title: '执行法院',
            key: "courtname",
            dataIndex: 'courtname',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        },
        {
            title: '履行情况',
            key: "performance",
            dataIndex: 'performance',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        },
        {
            title: '法定负责人/主要负责人信息',
            key: "businessentity",
            dataIndex: 'businessentity',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        },
        {
            title: '失信人类型',
            dataIndex: 'type',
            key: 'type',
            render: (text, record) => { //待提交状态的，打开编辑页
                return (<span>{text?(text === 1 ? '公司' : '个人'):''}</span>);

            }
        }, {
            title: '生效法律文书确定的义务',
            dataIndex: 'duty',
            key: 'duty',
            render: (text, record) => {
                return (<span>{text}</span>);
            },
            disabled:true
        }, {
            title: '执行依据文号',
            dataIndex: 'gistid',
            key: 'gistid',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        },

    ],

    // 天眼查被执行人
    [`tyc_executedby`]: [

        {
            title: '案号',
            dataIndex: 'caseCode',
            key: 'caseCode',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        }, {
            title: '执行法院',
            dataIndex: 'execCourtName',
            key: 'execCourtName',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        }, {
            title: '被执行人名称',
            dataIndex: 'pname',
            key: 'pname',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        }, {
            title: '创建时间',
            dataIndex: 'caseCreateTime',
            key: 'caseCreateTime',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        }, {
            title: '执行标的',
            dataIndex: 'execMoney',
            key: 'execMoney',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        },

    ],

    // 天眼查历史经营异常
    [`tyc_historcalabnormaloperation`]: [
        {
            title: '列入日期',
            key: "putDate",
            dataIndex: 'putDate',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        },
        {
            title: '移出日期',
            dataIndex: 'removeDate',
            key: 'removeDate',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        }, {
            title: '移出部门',
            dataIndex: 'removeDepartment',
            key: 'removeDepartment',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        }, {
            title: '移除异常名录原因',
            dataIndex: 'removeReason',
            key: 'removeReason',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        }, {
            title: '列入异常名录原因',
            dataIndex: 'putReason',
            key: 'putReason',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        }, {
            title: '决定列入异常名录部门(作出决定机关)',
            dataIndex: 'putDepartment',
            key: 'putDepartment',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        },

    ],

    // 天眼查历史法院公告
    [`tyc_historcalcourtnotice`]: [
        {
            title: '刊登日期',
            key: "publishdate",
            dataIndex: 'publishdate',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        },
        {
            title: '公告id',
            key: "announce_id",
            dataIndex: 'announce_id',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        },
        {
            title: '公告号',
            key: "bltnno",
            dataIndex: 'bltnno',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        },
        // 详情需要
        {
            title: '公告类型名称',
            key: "bltntypename",
            dataIndex: 'bltntypename',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },
        {
            title: '相关公司列表',
            dataIndex: 'companyList',
            key: 'companyList',
            render: (text, record) => {
                return (<span>{text}</span>);
            },
            child:[
                {
                    title: '变更事项',
                    dataIndex: 'id',
                    key: 'id',   
                },
                {
                    title: '变更事项',
                    dataIndex: 'name',
                    key: 'name',   
                },
                {
                    title: '变更事项',
                    dataIndex: 'type',
                    key: 'type',   
                },
            ],
            disabled:true
        },
        {
            title: '法院名',
            key: "courtcode",
            dataIndex: 'courtcode',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },
        {
            title: '对应表id',
            key: "id",
            dataIndex: 'id',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },
        {
            title: '法官电话',
            key: "judgephone",
            dataIndex: 'judgephone',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },
        {
            title: '联系方式',
            key: "mobilephone",
            dataIndex: 'mobilephone',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },
        {
            title: '原告',
            key: "party1",
            dataIndex: 'party1',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },
        {
            title: '原告',
            key: "party1Str",
            dataIndex: 'party1Str',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },
        {
            title: '当事人',
            key: "party2",
            dataIndex: 'party2',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },
        {
            title: '当事人',
            key: "party2Str",
            dataIndex: 'party2Str',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },
        {
            title: '省份',
            key: "province",
            dataIndex: 'province',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },
        {
            title: '刊登版面',
            key: "publishpage",
            dataIndex: 'publishpage',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },
        {
            title: '唯一标识符',
            key: "uuid",
            dataIndex: 'uuid',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },
    ],

    // 天眼查历史历史失信人
    [`tyc_historcaldishonest`]: [
        {
            title: '发布日期',
            key: "publishdate",
            dataIndex: 'publishdate',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        },
        {
            title: '立案日期',
            dataIndex: 'regdate',
            key: 'regdate',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        }, {
            title: '案号',
            dataIndex: 'casecode',
            key: 'casecode',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        }, {
            title: '执行法院',
            dataIndex: 'courtname',
            key: 'courtname',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        }, {
            title: '履行情况',
            dataIndex: 'performance',
            key: 'performance',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        }, {
            title: '法定负责人/主要负责人信息',
            dataIndex: 'businessentity',
            key: 'businessentity',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        }, {
            title: '失信人类型',
            dataIndex: 'type',
            key: 'type',
            render: (text, record) => {
                return (<span>{text?(text === 1 ? '公司' : '个人'):''}</span>);
            }
        }, {
            title: '生效法律文书确定的义务',
            dataIndex: 'duty',
            key: 'duty',
            render: (text, record) => {
                return (<span>{text}</span>);
            },
            disabled:true
        }, {
            title: '执行依据文号',
            dataIndex: 'gistid',
            key: 'gistid',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        },

    ],

    // 天眼查历史历史被执行人
    [`tyc_historcalexecuted`]: [
        {
            title: '案号',
            key: "caseCode",
            dataIndex: 'caseCode',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        },
        {
            title: '执行法院',
            dataIndex: 'execCourtName',
            key: 'execCourtName',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        }, {
            title: '被执行人名称',
            dataIndex: 'pname',
            key: 'pname',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        }, {
            title: '创建时间',
            dataIndex: 'caseCreateTime',
            key: 'caseCreateTime',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        }, {
            title: '执行标的',
            dataIndex: 'execMoney',
            key: 'execMoney',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        },

    ],

    // 天眼查历史开庭公告
    [`tyc_historcalopencourt`]: [
    
        {
            title: '开庭日期(时间)',
            dataIndex: 'startDate',
            key: 'startDate',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        }
      
        , {
            title: '案由',
            dataIndex: 'caseReason',
            key: 'caseReason',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        }
        , {
            title: '案号',
            dataIndex: 'caseNo',
            key: 'caseNo',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        },
      
        // 详情里需要
        {
            title: '地区',
            dataIndex: 'area',
            key: 'area',
            render: (text, record) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },
        {
            title: '承办部门',
            dataIndex: 'contractors',
            key: 'contractors',
            render: (text, record) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },
        {
            title: '法院',
            dataIndex: 'court',
            key: 'court',
            render: (text, record) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },
        {
            title: '法庭',
            dataIndex: 'courtroom',
            key: 'courtroom',
            render: (text, record) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },
        {
            title: '被告/被上诉人',
            dataIndex: 'defendant',
            key: 'defendant',
            render: (text, record) => {
                return (<span>{text}</span>);
            },
            child:[
                {
                    title: '人或公司id',
                    dataIndex: 'id',
                    key: 'id',   
                },
                {
                    title: '人或公司名',
                    dataIndex: 'name',
                    key: 'name',   
                },
                {
                    title: '1-公司 2-人',
                    dataIndex: 'type',
                    key: 'type',   
                },
            ],
            disabled:true
        },
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },
        {
            title: '审判长/主审人',
            dataIndex: 'judge',
            key: 'judge',
            render: (text, record) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },
        {
            title: '当事人',
            dataIndex: 'litigant',
            key: 'litigant',
            render: (text, record) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },
        {
            title: '原告/上诉人',
            dataIndex: 'plaintiff',
            key: 'plaintiff',
            render: (text, record) => {
                return (<span>{text}</span>);
            },
            child:[
                {
                    title: '人或公司id',
                    dataIndex: 'id',
                    key: 'id',   
                },
                {
                    title: '人或公司名',
                    dataIndex: 'name',
                    key: 'name',   
                },
                {
                    title: '1-公司 2-人',
                    dataIndex: 'type',
                    key: 'type',   
                },
            ],
            disabled:true
        },

    ],

    // 天眼查历史行政处罚
    [`tyc_historcalpunish`]: [        
        {
            title: '行政处罚决定书文号',
            dataIndex: 'punishNumber',
            key: 'punishNumber',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        }, {
            title: '作出行政处罚决定日期',
            dataIndex: 'decisionDate',
            key: 'decisionDate',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        },
        // 详情显示
        {
            title: '省份简称',
            key: "base",
            dataIndex: 'base',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },
        {
            title: '行政处罚内容',
            key: "content",
            dataIndex: 'content',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },
        {
            title: '作出行政处罚决定机关名称',
            key: "departmentName",
            dataIndex: 'departmentName',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },
        {
            title: '公司名称',
            key: "name",
            dataIndex: 'name',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },
        {
            title: '违法行为类型',
            key: "type",
            dataIndex: 'type',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },

    ],

    // 天眼查历史法律诉讼
    [`tyc_historicallitigation`]: [        
        {
            title: '发布时间',
            dataIndex: 'submittime',
            key: 'submittime',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        }, {
            title: '案由',
            dataIndex: 'casereason',
            key: 'casereason',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        }, {
            title: '案号',
            dataIndex: 'caseno',
            key: 'caseno',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        },
        // 详情里需要
        {
            title: '案件类型',
            dataIndex: 'casetype',
            key: 'casetype',
            render: (text, record) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },
        {
            title: '法院',
            dataIndex: 'court',
            key: 'court',
            render: (text, record) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },
        {
            title: '被告',
            dataIndex: 'defendants',
            key: 'defendants',
            render: (text, record) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },
        {
            title: '文书类型',
            dataIndex: 'doctype',
            key: 'doctype',
            render: (text, record) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },
        {
            title: '对应表id',
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },
        {
            title: '天眼查显示url',
            dataIndex: 'lawsuitUrl',
            key: 'lawsuitUrl',
            render: (text, record) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },
        {
            title: '原告',
            dataIndex: 'plaintiffs',
            key: 'plaintiffs',
            render: (text, record) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },
        {
            title: '第三人',
            dataIndex: 'thirdParties',
            key: 'thirdParties',
            render: (text, record) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },
        {
            title: '标题',
            dataIndex: 'title',
            key: 'title',
            render: (text, record) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },
        {
            title: '原文链接地址',
            dataIndex: 'url',
            key: 'url',
            render: (text, record) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },
        {
            title: 'uuid',
            dataIndex: 'uuid',
            key: 'uuid',
            render: (text, record) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },

    ],

    // 天眼查司法协助
    [`tyc_judicialassistance`]: [
        {
            title: '执行通知书文号',
            key: "executeNoticeNum",
            dataIndex: 'executeNoticeNum',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        },
        {
            title: '执行人公司id',
            dataIndex: 'executedPersonCid',
            key: 'executedPersonCid',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        }, {
            title: '类型|状态',
            dataIndex: 'typeState',
            key: 'typeState',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        }, {
            title: '执行法院',
            dataIndex: 'executiveCourt',
            key: 'executiveCourt',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        }, {
            title: '执行人类型',
            dataIndex: 'executedPersonType',
            key: 'executedPersonType',
            render: (text, record) => {
                return (<span>{text?(text === 1 ? '公司' : '个人'):''}</span>);
            }
        }, {
            title: '被执行人',
            dataIndex: 'executedPerson',
            key: 'executedPerson',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        },

    ],

    // 天眼查公司法律诉讼
    [`tyc_legalproceeding`]: [
        {
            title: '裁判日期',
            key: "judgetime",
            dataIndex: 'judgetime',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        },
        {
            title: '案由',
            dataIndex: 'casereason',
            key: 'casereason',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        }, 
        {
            title: '案号',
            dataIndex: 'caseno',
            key: 'caseno',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        },
        // 详情里需要
        {
            title: '摘要',
            dataIndex: 'abstracts',
            key: 'abstracts',
            render: (text, record) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },
        {
            title: '代理人',
            dataIndex: 'agent',
            key: 'agent',
            render: (text, record) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },
        {
            title: '案件类型',
            dataIndex: 'casetype',
            key: 'casetype',
            render: (text, record) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },
        {
            title: '法院',
            dataIndex: 'court',
            key: 'court',
            render: (text, record) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },
        {
            title: '被告',
            dataIndex: 'defendants',
            key: 'defendants',
            render: (text, record) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },
        {
            title: '文书类型',
            dataIndex: 'doctype',
            key: 'doctype',
            render: (text, record) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },
        {
            title: '对应表id',
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },
        {
            title: '天眼查显示url',
            dataIndex: 'lawsuitUrl',
            key: 'lawsuitUrl',
            render: (text, record) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },
        {
            title: '原告',
            dataIndex: 'plaintiffs',
            key: 'plaintiffs',
            render: (text, record) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },
        {
            title: '发布时间',
            dataIndex: 'submittime',
            key: 'submittime',
            render: (text, record) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },
        {
            title: '第三人',
            dataIndex: 'thirdParties',
            key: 'thirdParties',
            render: (text, record) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },
        {
            title: '标题',
            dataIndex: 'title',
            key: 'title',
            render: (text, record) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },
        {
            title: '原文链接地址',
            dataIndex: 'url',
            key: 'url',
            render: (text, record) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },
        {
            title: '相关公司id',
            dataIndex: 'splitGids',
            key: 'splitGids',
            render: (text, record) => {
                return (<span>{text}</span>);
            },
            disabled:true
        },
        
    ],

    // 天眼查税收违法
    [`tyc_taxviolation`]: [
        {
            title: '发布时间',
            key: "publish_time",
            dataIndex: 'publish_time',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        },
        {
            title: '案件性质',
            dataIndex: 'case_type',
            key: 'case_type',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        }, {
            title: '纳税人名称',
            dataIndex: 'taxpayer_name',
            key: 'taxpayer_name',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        }

    ],

    // 天眼查商标信息
    [`tyc_trademarkinfo`]: [
        {
            title: '状态',
            key: "status",
            dataIndex: 'status',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        },
        {
            title: '国际分类code',
            dataIndex: 'tmClass',
            key: 'tmClass',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        }, {
            title: '商标名称',
            dataIndex: 'tmName',
            key: 'tmName',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        }, {
            title: '商标图片',
            dataIndex: 'tmPic',
            key: 'tmPic',
            render: (text, record) => {
                return (<span>{text}</span>);
            },
            disabled:true
        }, {
            title: '国际分类',
            dataIndex: 'intCls',
            key: 'intCls',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        }, {
            title: '注册号',
            dataIndex: 'regNo',
            key: 'regNo',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        }, {
            title: '申请日期',
            dataIndex: 'appDate',
            key: 'appDate',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        }, {
            title: '申请人',
            dataIndex: 'applicantCn',
            key: 'applicantCn',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        },

    ],
    // 天眼查严重违法
    [`tyc_illegalinfo`]: [
        {
            title: '移除原因',
            key: "removeReason",
            dataIndex: 'removeReason',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        },
        {
            title: '决定移除部门',
            dataIndex: 'removeDepartment',
            key: 'removeDepartment',
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        }, {
            title: '列入日期',
            dataIndex: 'putDate',
            key: 'putDate',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        }, {
            title: '列入原因',
            dataIndex: 'putReason',
            key: 'putReason',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        }, {
            title: '决定列入部门(作出决定机关)',
            dataIndex: 'putDepartment',
            key: 'putDepartment',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        }, {
            title: '移除日期',
            dataIndex: 'removeDate',
            key: 'removeDate',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        },

    ],
}


export var category={
    383:{
        name:'司法风险',
        url:'http://open.tianyancha.com/cloud-open-admin/_feign/interface/find.json?parentId=383&opened=1'
    },
    390:{
        name:"经营风险",
        url:"http://open.tianyancha.com/cloud-open-admin/_feign/interface/find.json?parentId=390&opened=1"
    },
    438:{
        name:'知识产权',
        url:"http://open.tianyancha.com/cloud-open-admin/_feign/interface/find.json?parentId=438&opened=1"
    },
    759:{
        name:'历史信息',
        url:"http://open.tianyancha.com/cloud-open-admin/_feign/interface/find.json?parentId=759&opened=1"
    }
}



