import React, { Component } from 'react';
import {Row,Col,Form ,Input} from 'antd';
import Pattern from '@/utils/regex';
import { API } from '@/http/api';
import "../../financeApproval/finance.less"
import '../bill.less'

const Item = Form.Item;

class PayInfo extends Component {
    state = {

    }
    componentDidMount() {
    }

    render(){
        const {textDes,resetBill} = this.props;
        const { getFieldDecorator } = this.props.form;
        const isCreat = this.props.form.getFieldValue('billCategoryMap') || this.props.showMore;
        return (
            <div className="approval-baseInfo"> 
                <div className="title">{textDes.title2}</div>
                    <Row className="fs12 mb9">
                        <Col span={8}>
                            <Item label={textDes.customerName} style={{ width: '237px'}}>
                                {getFieldDecorator('customerName', {
                                    initialValue:resetBill.customerName,
                                    rules: [{
                                        required: true, message: '付款人不能为空',
                                    }]
                                })(
                                    <Input placeholder="请输入" maxLength={20}/>                      
                                )}
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item label={textDes.customerPhone} style={{ width: '237px'}}>
                                {getFieldDecorator('customerPhone', {
                                    initialValue:resetBill.customerPhone,
                                    rules: [{
                                        required: true, message: '请填正确的手机号',pattern:Pattern.phone
                                    }]
                                })(
                                    <Input placeholder="请输入" maxLength={11}/>                      
                                )}
                            </Item>
                        </Col>
                        <Col span={8} className={isCreat?'':'displayNone'}>
                            <Item label={textDes.paymentMessage.bank} style={{ width: '237px'}} className="cx-maohao">
                                {getFieldDecorator('paymentMessage.bank', {
                                    initialValue:resetBill.bank,
                                    rules: [{
                                        required: false, message: '请输入',
                                    }]
                                })(
                                    <Input placeholder="XX银行XX支行" maxLength={30}/>                      
                                )}
                            </Item>
                        </Col>
                    </Row> 
                    <Row className="fs12 mb9" className={isCreat?'':'displayNone'}>
                        <Col span={8} className="cx-maohao">
                            <Item label={textDes.paymentMessage.account} style={{ width: '237px'}}>
                                {getFieldDecorator('paymentMessage.account', {
                                    initialValue:resetBill.account,
                                    rules: [{
                                        required: false, message: '请输入',
                                    }]
                                })(
                                    <Input placeholder="请输入" maxLength={20}/>                    
                                )}
                            </Item>
                        </Col>
                    </Row>        
                </div>    
        )
    }
}

export default PayInfo;