import React, { Component } from 'react';
import './ProjectOverview.less'
import Chartbar from './chart/Bar';
import Chartline from './chart/Line';
import Chartpie from './chart/Pie';
import { Row, Col, DatePicker,message } from 'antd';
import ProjectInfoCard from './ProjectInfoCard'
import Maps from './maps/Maps'
import moment from 'moment';
import http from '@/http'
import { API } from '@/http/api';
import { projectstatsCustomer, projectstatsBarData, monthInfo } from './chart/ChartUtil'
import AuthContainer from 'CMPT/common/auth/AuthContainer';
import { AUTH_KEYS } from '@/utils/auth';
import userUtil from '@/utils/user';
import storage from '@/utils/storage';
const { RangePicker } = DatePicker;

export default class Project extends Component{
    constructor(props) {
        super(props);

        this.state = {
            showMap:true,
            barOptions: {
                id: 'average-rank',
                title: '签约均价排名',
                text: '签约均价',
                yaxisTitle: '签约均价 (元/㎡/日)',
                lineColor: '#535465',
                fontColor: '#333',
                xaxisData: [],
                yaxisData: [],
                gradientColor: ['#A15DE9', '#8861F3'],
                width: '100%',
                height: 330
            },
            lineOptions: {
                id: 'rent-rate',
                title: '出租率',
                text: '出租率',
                showReminder: true,
                reminderInfo: '出租率按合同有效期间存在有效合同的区域面积之和/当前招商面积之和计算',
                mainColor: '#27C097',
                lineColor: '#535465',
                fontColor: '#333',
                xaxisData: ['4-13','4-14','4-15','4-16','4-17','4-18','4-19'],
                yaxisData: ['20', '25', '32', '50', '70', '65', '60'],
                width: '100%',
                height: 330
            },
            pieOptions: {
                id: 'rent-company',
                title: '承租企业画像',
                text: '承租企业',
                lineColor: '#333',
                pieRadius: ['40%', '70%'],
                data:[],
                width: '100%',
                height: 330
            },
            barOptions2: {
                id: 'area-rank',
                title: '成交面积排名',
                text: '成交面积',
                yaxisTitle: '成交面积 (㎡)',
                lineColor: '#535465',
                fontColor: '#333',
                xaxisData: [],
                yaxisData: [],
                gradientColor: ['#E4AD0C', '#CB8822'],
                width: '100%',
                height: 330
            },
            barOptions3: {
                id: 'income-rank',
                title: '租金收入排名',
                text: '租金收入',
                yaxisTitle: '租金收入 (万元)',
                lineColor: '#535465',
                fontColor: '#333',
                xaxisData: [],
                yaxisData: [],
                gradientColor: ['#27C097', '#22B0A2'],
                width: '100%',
                height: 330
            },
            isOpenRangePicker: false,
            monthValues: [moment(monthInfo().halfYearBefore, 'YYYY-MM月'), moment(monthInfo().current, 'YYYY-MM月')],
            projectInfo: {
                availableArea: 0,
                rentalRate: 0,
                onlineAveragePrice: 0,
                contractCnt: 0,
                projectCnt: 0
            }
        }
    }

    handleOpenChange = () => {
        this.setState({
            isOpenRangePicker: true
        });
    };
    clearRangePicker=()=>{
        var monthValues = [moment(monthInfo().halfYearBefore, 'YYYY-MM月'), moment(monthInfo().current, 'YYYY-MM月')]
        let from = moment(monthValues[0]).format('YYYY-MM-01');        
        let to = moment(monthValues[1]).format('YYYY-MM-01');
        this.getProjectstatsRentalRate(from, to);
        this.getProjectstatsRankarea(from, to);
        this.getProjectstatsRanksale(from, to);

        this.setState({
            monthValues,
            isOpenRangePicker: false
        });
    }
    handleMonthPanelChange = (monthValues) => {
        
        let from = moment(monthValues[0]).format('YYYY-MM-01');
        if(monthValues[0].unix()<moment().subtract(2,'y').unix()){
            message.warning('只能选择两年内的统计数据');
            from= moment().subtract(2,'y').format('YYYY-MM-01');
            monthValues[0] = moment().subtract(2,'y')
        }
        let to = moment(monthValues[1]).format('YYYY-MM-01');
        
        this.getProjectstatsRentalRate(from, to);
        this.getProjectstatsRankarea(from, to);
        this.getProjectstatsRanksale(from, to);

        this.setState({
            monthValues,
            isOpenRangePicker: false
        });
    };

    getProjectstatsCustomer = () => {
        const config = {
            api: API.projectstatsCustomer,
            path: {
                projectEncode: ''
            },
            showLoading: false,
        }
        http(config).then(result => {
            if (result.success && result.data.stats.length > 0) {
                let pieOptions = this.state.pieOptions;
                pieOptions.data = projectstatsCustomer(result.data.stats);
                this.setState({
                    pieOptions
                });
            }
        })
    }

    getProjectstatsInfo = () => {
        const config = {
            api: API.projectstatsInfo,
            path: {
                projectEncode: ''
            },
            showLoading: false,
        }
        http(config).then(result => {
            if (result.success) {
                Object.assign(this.state.projectInfo, result.data);
                this.setState({
                    projectInfo: this.state.projectInfo
                });
            }
        })
    }

    getProjectstatsRankprice = () => {
        const config = {
            api: API.projectstatsRankprice,
            showLoading: false,
        }
        http(config).then(result => {
            if (result.success) {
                let barOptions = this.state.barOptions;
                let {xaxisData, yaxisData} = projectstatsBarData('price', result.data);
                barOptions.xaxisData = xaxisData;
                barOptions.yaxisData = yaxisData;
                this.setState({
                    barOptions
                });
            }
        })
    }

    getProjectstatsRentalRate = (from, to) => {
        const config = {
            api: API.projectstatsRentalRate,
            path: {
                projectEncode: '',  
            },
            data: {
                from,
                to
            },
            showLoading: false,
        }
        http(config).then(result => {
            if (result.success) {
                let lineOptions = this.state.lineOptions;
                lineOptions.xaxisData = result.data.xaxis;
                lineOptions.yaxisData = result.data.ratesList;
                this.setState({
                    lineOptions
                });
            }
        })
    }

    getProjectstatsRankarea = (from, to) => {
        const config = {
            api: API.projectstatsRankarea,
            path: {
                projectEncode: '',  
            },
            data: {
                from,
                to
            },
            showLoading: false,
        }
        http(config).then(result => {
            if (result.success) {
                let barOptions2 = this.state.barOptions2;
                let {xaxisData, yaxisData} = projectstatsBarData('area', result.data);
                barOptions2.xaxisData = xaxisData;
                barOptions2.yaxisData = yaxisData;
                this.setState({
                    barOptions2
                });
            }
        })
    }

    getProjectstatsRanksale = (from, to) => {
        const config = {
            api: API.projectstatsRanksale,
            path: {
                projectEncode: '',  
            },
            data: {
                from,
                to
            },
            showLoading: false,
        }
        http(config).then(result => {
            if (result.success) {
                let barOptions3 = this.state.barOptions3;
                let {xaxisData, yaxisData} = projectstatsBarData('sale', result.data);
                barOptions3.xaxisData = xaxisData;
                barOptions3.yaxisData = yaxisData;
                this.setState({
                    barOptions3
                });
            }
        })
    }
    getProjectSetting = () => {
        // 获取项目配置
        const defaultConfig = {
            api: API.decisionByProjectEncode,
            path: {
                projectEncode: "qeZDWfgpvSs"
            },
            data: {
                bizType: '1'
            }
        }
        http(defaultConfig).then(res => {
            console.log(res)
            if(res.data&&res.data.length){
                if(res.data[0].value==1){
                    this.setState({
                        showMap:true
                    })    
                }else{
                    this.setState({
                        showMap:false
                    })
                }
            }else{
                this.setState({
                    showMap:true
                })
            }
        })
    }
    componentDidMount() {
        storage.set('tabKey','1')
        /*
            1、有无项目空间权限，
            有跳转2，无跳转我的工作
            2、有无全部项目权限，
            有跳3，无跳4
            3、本公司内有无项目
            有跳全部项目，无跳新增页
            4、项目列表有无项目
            有跳默认第一个项目，无跳新增页
        */
        if (userUtil.hasAuth(AUTH_KEYS.PROJECT_All)) {         
            const config = {
                api: API.projectstatsGeo,
                showLoading: true,
            }
            http(config).then(result => {
                if (result.success && result.data.length > 0) {
                    // 1. 企业画像
                    this.getProjectstatsCustomer();
            
                    // 2. 实时信息
                    this.getProjectstatsInfo();
            
                    // 3. 签约均价排名
                    this.getProjectstatsRankprice();
            
                    let from = moment(this.state.monthValues[0]).format('YYYY-MM-01');
                    let to = moment(this.state.monthValues[1]).format('YYYY-MM-01');
                    // 4. 出租率
                    this.getProjectstatsRentalRate(from, to);
            
                    // 5. 成交面积排名
                    this.getProjectstatsRankarea(from, to);
            
                    // 6. 租金收入排名
                    this.getProjectstatsRanksale(from, to);
            
                    this.getProjectSetting()             
                }else{
                    this.props.history.push('/app/project/index')
                }
            })                       
            
        }else{
            
                const config = {
                    api: API.commonProjectfilter,
                    showLoading: true,
                }
                http(config).then(result => {
                    if (result.success && result.data.length > 0) {
                        this.props.history.push('/app/project/detail/'+result.data[0].encode)                            
                    } else {                        
                        this.props.history.push('/app/project/index')
                    }
                })
            
        }
    }

    render() {
        if(userUtil.hasAuth(AUTH_KEYS.PROJECT_All)){            
            return (                        
                <div className="project-page">
                    <div className="project-main">
                        <div className="title">实时信息</div>
                        {
                            !this.state.showMap?<ProjectInfoCard history={this.props.history} projectInfo={this.state.projectInfo}/>:<Maps history={this.props.history} projectInfo={this.state.projectInfo}></Maps>
                        }
                    </div>
                    <div className="project-chart">
                        <Row gutter={16} style={{marginTop: '12px'}}>
                            <Col span={12} style={{paddingRight:6}}><Chartbar options={this.state.barOptions} /></Col>
                            <Col span={12} style={{paddingLeft:6}}><Chartpie options={this.state.pieOptions} /></Col>
                        </Row>
                        <div className="title">
                            <span>签约租赁统计</span>
                            <RangePicker
                                className="project-overview-range-picker"
                                placeholder={['开始月份', '结束月份']}
                                format="YYYY-MM月"
                                value={this.state.monthValues}
                                mode={['month', 'month']}
                                onOpenChange={this.handleOpenChange}
                                onPanelChange={this.handleMonthPanelChange}
                                onChange={this.clearRangePicker}
                                // open={this.state.isOpenRangePicker}
                                allowClear={false}
                            />
                        </div>
                        <Row style={{marginBottom: '12px'}}>
                            <Col span={24}><Chartline options={this.state.lineOptions} /></Col>
                        </Row>
                        <Row gutter={16} style={{ marginBottom: '40px'}}>
                            <Col span={12} style={{paddingRight:6}}><Chartbar options={this.state.barOptions2} /></Col>
                            <Col span={12} style={{paddingLeft:6}}><Chartbar options={this.state.barOptions3} /></Col>
                        </Row>
                    </div>
                </div>
            )
        }else{
            return <div></div>
        }
        
    }
}
