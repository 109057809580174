import Enum from "@/utils/Enum";

// 状态 0待受理 1受理中 2受理完成 3无法受理 4无效信息

export const Status = new Enum(
  { alias: "PENDING", text: "待受理", value: 0 },
  { alias: "PROCESSING", text: "受理中", value: 1 },
  { alias: "SOLVED", text: "受理完成", value: 2 },
  { alias: "REJECT", text: "无法受理", value: 3 },
  { alias: "INVALID", text: "无效信息", value: 4 },
);

