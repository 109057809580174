import {
    AJAX_TYPE
} from '../config';

// Function.Function

const prefix = 'function';

export default {
    // 获取权限
    [`${prefix}GetList`]: {
        url: '/web/function/getList',
        type: AJAX_TYPE.POST
    },
    // 获取权限的用户
    [`${prefix}GetUserList`]: {
        url: '/web/function/getUserList/:functionId',
        type: AJAX_TYPE.POST
    },
}