import React, { Component } from 'react';
import { Breadcrumb, Icon, Button, Input, Tree, List, Typography } from 'antd';
import InfoCard from '../common/card/InfoCard';
import './HelpCenter.less';
import { NONAME } from 'dns';


const { TreeNode,DirectoryTree  } = Tree;

const x = 3;
const y = 2;
const z = 1;
const gData = [];

const generateData = (_level, _preKey, _tns) => {
    const preKey = _preKey || '0';
    const tns = _tns || gData;

    const children = [];
    for (let i = 0; i < x; i++) {
        const key = `${preKey}-${i}`;
        tns.push({ title: key, key });
        if (i < y) {
            children.push(key);
        }
    }
    if (_level < 0) {
        return tns;
    }
    const level = _level - 1;
    children.forEach((key, index) => {
        tns[index].children = [];
        return generateData(level, key, tns[index].children);
    });
};
generateData(z);

const refs = []

class HelpCenter extends React.Component {
    state = {
        // 是否展示所有文本内容
        showTotal: true,
        // 显示展开还是收起
        exchangeButton: true,
        // 是否显示展开收起按钮
        showExchangeButton: false,
        treeData: [
            {
                title: '项目空间', key: '0'
                // , children: [
                //     { title: '答疑一', key: '1' },
                //     {
                //         title: '答疑二', key: '2', isLeaf: true, children: [
                //             { title: '答疑列表', key: '1' },
                //             { title: '答疑列表', key: '2', isLeaf: true },
                //         ]
                //     },
                // ]
            },
            { title: '合同模块', key: '1' },
            { title: '招商管理', key: '2' },
            // { title: 'APP-招商管理', key: '3' },
            { title: '财务模块', key: '4', isLeaf: true },
        ],
        seleteIndex: 0,
        data: [
            [{//项目
                title: '全部项目不是我们公司的全部运营中的项目', content: `若发现自己可查看的项目不全，或数据不对，请联系管理员，录入对应项目，并分配权限后，即可查看全部项目`
            },
            {
                title: '出租率的计算逻辑', content: `出租率是根据当前已签约面积/可招商面积计算得出，若区域已出租，但未录入合同，则计算可能会有差异。`,
            },
            {
                title: '签约均价的计算逻辑', content: `签约均价是根据当前已签约的合同总价格/已签约合同的总面积计算得出，若区域已出租，但未录入合同，则计算可能会有差异。`,
            },
            {
                title: '我只有一个项目，没必要按照地图展示？', content: `若您是管理员，则可在系统决策中，调整全部项目展现形式，平铺或按照地图展示。`,
            },
            {

                title: '一个项目中，有多栋大楼，怎么录入', content: `目前仅支持按照一个项目一栋的方式进行录入及展示，若项目有多栋大楼，请分别录入多个项目。`,
            },
            {
                title: '租金、物业费等费用和实际价格不一致怎么办？', content: `目前仅支持按照一个项目设置一个物业费，租金可按照项目实际情况，在签约时进行调整。`,
            },
            {
                title: '区域是空置时，为什么会显示有欠款？', content: `区域当前状态为空置，但是历史合同上有未结账务，所以显示有欠款。`,
            },
            {
                title: '区域已经完成装修，为什么还显示为装修中？', content: `区域装修完成，必须验收通过，才会视为装修全部结束。`,
            },
            {
                title: '删除区域后，合同和财务信息还可以查看吗？', content: `删除区域后，合同和财务信息还可以被查看，但在首页统计的时候，将不被统计。`,
            }],
            [//合同

                {
                    title: '合同编号是如何生成的，是否可以自定义？',
                    content: `合同编号可以自定义，在决策中可以开启自动生成合同编号，开启后可以自定义标识，合同编号 = 自定义标识 + 年 / 月 / 日 + 序号（示例：idf10 - 20190422 - 1）。如果未开启，可以在录入合同时直接填写。`
                },

                {
                    title: '一个合同是否可以绑定多个房源？',
                    content: `可以，添加房源时不支持跨项目，但支持在一个项目中多选楼层和区域的。`
                },
                {
                    title: '新建合同的合同条款页租金明细是如何生成的？',
                    content: `租金明细中的费用计算会细分至区域。 房源是项目名称 + 楼层 + 区域，来源于基本信息中添加的房源，不可更改；面积是根据所选房源同步的自带信息，不可更改；单价是根据所选房源同步的自带信息，支持更改；第一年、第二年….第N年的价格是根据单价，以及租赁条款和物业条款中分别设置的递增率和折扣进行计算得到的，计算后的值不支持更改。`
                },
                {
                    title: '租赁条款和物业条款如果完全一样，是否可以统一输入？',
                    content: `租赁条款和物业条款需要分别录入，会生成两份账单。但是如果两个条款完全一样，可以通过物业条款上的“复制租赁条款”按钮进行快捷复制。`
                },
                {
                    title: '合同内容太多一次性录入不完，保存草稿后如何找到？',
                    content: '合同录入的每个页面都可以保存草稿，保存后再下次录入合同时会提醒还有合同未完成，如果继续完成会直接导入草稿合同内容，如果放弃则可以新建合同。'
                },
                {
                    title: '如果合同在租期中需要变更合同内容，该如何操作？',
                    content: `已经审批通过的在租期中的合同，可以点击合同列表页或合同详情页的变更按钮进行合同变更，合同变更的流程与新签一样，支持所有字段的更新，变更后的合同会根据合同中填写的生效日期保留旧合同的部分收支同时生成新合同的部分收支。变更后的合同审批通过后，会生成一份变更前的旧合同和一份变更后的新合同。变更前的旧合同状态变为已变更，此状态下无法再次操作合同变更。变更后的新合同会在审批通过后就将状态变更为租期中，此时可以对该状态下的新合同再次进行变更。`
                },

                {
                    title: '如何对同个租客进行续租？',
                    content: `已经审批通过的在租期中的合同，可以点击合同列表页或合同详情页的续租按钮进行合同续租,合同续租流程与新签一样，支持所有字段的更新，续租后的合同会根据新合同中的内容生成新合同的收支。`
                },

                {
                    title: '合同操作变更或续租后如何查看之前的历史合同？',
                    content: `在合同详情中，可以点击关联合同进行相关合同的查看，通过该页面可以查看到与变更、续租、退租、作废等动作相关的所有历史及未来合同。`
                },

                {
                    title: '如何对合同进行退租？',
                    content: `已经审批通过的在租期中的合同，可以点击合同列表页或合同详情页的退租按钮进行合同退租，根据页面提示填写退租申请，在申请中可以添加待处理的账单。如果退租审批通过后该合同的账单未完成结算，则合同状态为已退租未结算，如果该合同的账单已完成结算，则合同状态为已退租。`
                },
                {
                    title: '如何对合同进行作废？',
                    content: `目前仅支持未产生实际账单的合同进行作废，即没有实收实支的合同，在合同详情页可点击“作废”按钮，提交作废合同申请，如果开启了审批，在审批通过后该合同会被删除。
`
                },
            ],
            [//招商管理
                {
                    title: '客户管理功能介绍',
                    content: `客户管理功能支持对商业项目的意向客户进行包含基本信息、合作状态等的信息管理。允许对客户进行新增、维护信息的操作。`
                },
                {
                    title: '如何新增客户？',
                    content: `进入客户管理页面，点击右上角【新增客户】按钮后进入新增客户页面。新增客户时需要填写客户名称、跟进人、客户类型、联系方式等信息，
                    完善信息后可选择直接完成创建客户，或继续添加客户意向。`
                },
                {
                    title: '如何维护客户？',
                    content: `在客户管理页面点击【编辑】按钮，可进入客户信息编辑页面，允许对客户的基本信息及状态进行修改。`
                },
                {
                    title: '如何修改客户的跟进人？',
                    content: `在客户管理页面点击【分配】按钮，在弹窗中输入想要分配的跟进人姓名，查找到相关跟进人后完成确认，即完成了客户跟进人的修改。用户可选择对单一客户进行分配跟进人，或多选用户进行批量跟进。`
                },
                {
                    title: '意向管理功能介绍',
                    content: `意向管理功能支持对客户合作意向进行包含基本信息、状态等的信息管理。允许对意向进行新增、跟进等操作。`
                },
                {
                    title: '如何新增意向？',
                    content: `进入意向管理页面，点击右上角【新增意向】按钮后进入新增意向页面。新增意向时需要填写客户名称、跟进人、客户类型、状态、跟进方式等信息，完善信息后确认即完成意向新增。`
                },
                {
                    title: '如何跟进意向？',
                    content: `在意向管理页面，点击任意意向，可在弹窗中对意向的最新进展进行跟进。跟进时需要填写本次跟进后意向的状态、跟进方式、跟进时间、跟进结果，确认后即完成对当前意向新增跟进。完整的跟进记录可在意向详情中查看。`
                },
                {
                    title: '如何修改意向信息？',
                    content: `在意向管理页面点击【编辑】按钮，可进入意向息编辑页面，允许对意向的基本信息进行修改。`
                },
                {
                    tltle: '如何修改意向的跟进人？',
                    content: `在意向管理页面点击【分配】按钮，在弹窗中输入想要分配的跟进人姓名，查找到相关跟进人后完成确认，即完成了意向跟进人的修改。用户可选择对单一意向进行分配跟进人，或多选意向进行批量跟进。`
                }


            ],
            [//
                {
                    title: '客户管理功能介绍', content:
                        `客户管理功能支持对商业项目的所有客户进行包含基本信息、合作状态等的信息管理。允许对客户进行新增、信息维护、快速筛选查找客户等操作。`
                },
                {
                    title: '如何新增客户？', content:
                        `进入客户管理页面，点击右上角【更多操作-新建客户】按钮后进入新增客户页面。新增客户时需要填写客户类型、客户名称、联系电话、跟进人等信息，完善信息后可选择直接保存创建客户，或继续添加客户意向。`
                },
                {
                    title: '如何维护客户？', content:
                        `可通过以下方式进入客户信息编辑页面，对客户的基本信息及状态、详细信息、主要联系人进行补充修改。在客户管理页面，点击具体客户的【更多-编辑】按钮，进入客户信息编辑页面；在客户详情页面，点击右上角【更多操作-编辑客户】按钮，进入客户信息编辑页面；`
                },
                {
                    title: '如何修改客户的跟进人？', content:
                        `可选择单个客户分配或多个客户批量分配，进行跟进人的修改。单个分配：在客户管理页面点击具体客户的【更多-分配】按钮，或在客户详情页点击【更多操作-分配】按钮，进行客户跟进人修改；在弹窗中直接选择或通过输入跟进人姓名查找选择，点击确认即完成客户跟进人修改；批量分配：在客户管理页面点击右上角【更多操作-批量分配】按钮，选择批量分配的客户后，点击底部【批量分配】按钮，在弹窗中直接选择或通过输入跟进人姓名查找选择修改后的跟进人，点击确认完成多客户跟进人修改；`
                },
                {
                    title: '如何联系客户？', content:
                        `在客户管理页面、客户详情页面、意向跟进页面、意向详情页面，均有拨打电话按钮；点击【拨打电话】，在弹窗中选择拨打号码，可直接电话联系客户。`
                },
                {
                    title: '意向跟进功能介绍', content:
                        `意向跟进功能支持对客户合作意向进行包含基本信息、状态等的信息管理。允许对意向进行新增、编辑、跟进、快速筛选查找等操作。`
                },
                {
                    title: '如何新增意向？', content:
                        `可通过查找客户后添加意向，或直接新建意向关联客户，完成意向新增；新增意向时需填写客户名称、跟进人、意向状态、意向来源等信息。客户的所有意向可在客户详情页中查看。在客户管理页面点击具体客户的【添加意向】按钮，或在客户详细页面点击右上角【更多操作-添加意向】按钮，进入新增意向页面，自动关联客户信息。在意向跟进页面点击右上角【更多操作-新建意向】按钮，进入新增意向页面，选择客户名称并填写其他信息。`
                },
                {
                    title: '如何修改意向信息？', content:
                        `可通过以下方式进入意向编辑页面，对意向的状态、意向信息进行补充修改。在意向跟进页面，点击具体意向的【更多-编辑】按钮，进入意向编辑页面；在意向详情页面，点击右上角【更多操作-编辑意向】按钮，进入客户信息编辑页面；`
                },
                {
                    title: '如何跟进意向？', content: `在意向跟进页面点击具体意向的【添加跟进】按钮，或者在意向详情页面点击右上角【更多操作-添加跟进】按钮，进入添加跟进页面；填写本次跟进的跟进时间、跟进方式、跟进后意向状态、跟进结果等，确认后及完成对当前意向的跟进新增。完整的跟进记录可在意向详情页中查看。`
                },
                {
                    title: '	如何修改意向的跟进人？', content:
                        `可选择单个意向分配或多个意向批量分配，进行跟进人的修改。单个分配：在意向跟进页面点击具体意向的【更多-分配】按钮，或在意向详情页点击【更多操作-分配】按钮，进行意向跟进人修改；在弹窗中直接选择或通过输入跟进人姓名查找选择，点击确认即完成意向跟进人修改；批量分配：在意向跟进页面点击右上角【更多操作-批量分配】按钮，选择批量分配的意向后，点击底部【批量分配】按钮，在弹窗中直接选择或通过输入跟进人姓名查找选择修改后的跟进人，点击确认完成多意向跟进人修改；`
                }
            ],
            [
                {
                    title: '账单的分类有几种？分别有什么区别？', content: `从不同的维度分类，账单的种类不尽相同。从业务上区分，系统中的账单分为合同账单和运营账单。两者的区别在于前者是和已签合同进行强关联，合同账单绑定相关的合同，账单导出的字段中，也比运营账单多一个“合同编号”的字段，方便财务人员基于合同和签约的维度对账单进行分析；区别于合同账单与合同绑定，运营账单和房源进行绑定，用户可以自定义账单绑定的房源数量，便于录入一些在日常运营过程中产生的账单。从收支类别上区分，账单可以分为收款账单和付款账单。收款账单是记录业务中产生的应该收取和已经收取的款项信息，用户可以根据需要选择录入应收账单和已收账单；与之对应，付款账单是记录业务中产生的应该支出和已经支出的款项信息，用户可以根据需要选择录入应付账单和已付账单。`
                },
                {
                    title: '怎么创建一个应收账单？', content: `首先明确一下账单的来源：账单的来源有三种，第一种是从合同模块中自动同步过来的已经创建好的账单，此类账单类别统一为合同账单，收支的类别包含收款账单和付款账单两种。第二种是从工单系统中同步过来的账单，此类账单常见过程中无需审核，在工单结单的场景中，同步生成一个账单记录；第三种是创建的账单，基于系统在运行过程中产生的实际账单，进行手动录入，可创建的账单类别涵盖合同账单和运营账单。
                1.	创建账单的步骤如下（此处默认有账单创建权限，若无此权限请联系管理员开通对应权限）：
                a)	首先进入账单列表，点击新增账单。
                b)	进行账单类型的选择，可根据需要选择收款账单或付款账单（以收款账单为例）。
                c)	进行关联方式的选择，如果要创建合同账单，需要选择“通过合同”关联；如果要创建运营账单，需要选择“通过房源”关联；
                d)	选择完成之后点击确定。
                e)	以创建“运营账单”为例，下一步进行账单具体字段的填写，选择项目信息和房源信息，填写账单信息和收/付款人信息，确认无误之后点击提交。
                f)	提交成功之后，进入审核阶段，审核通过之后，账单即可生效。`},
                {
                    title: '什么样的账单才会逾期？系统基于什么判断逾期？逾期了之后可以做什么？', content: `
                1.	系统中虽然包含收付两大类账单，但是基于实际的业务场景，系统中仅会针对收款账单进行逾期的提醒。
                2.	系统通过账单的应收日期字段和当前时间进行匹配，如果截止到目前（列表打开时间），应收账单的应收额仍然大于实收额，系统判断该长大已经逾期。
                3.	逾期了之后可以进行账单的催缴，提醒付款人尽快完成付款。`},
                {
                    title: '怎么进行预期账单的催缴？', content: `
                	针对逾期的账单可以进行催缴的操作，具体的实现方式因端的不用而异。
                	WEB端：
                1.	进入账单列表，可以筛选出逾期账单。
                2.	点击页面右侧催缴按钮，确认收款人信息之后，进行催缴
                3.	系统会对指定手机号发送短信，提示付款人快速付款。
                4.	注：每小时尽可以发送一条催缴短信。
                	APP端：
                1.	进入账单列表，可以筛选出逾期账单。
                2.	点击页面右侧催缴按钮，确认收款人信息之后，进行催缴
                3.	系统会对指定手机号发送短信，提示付款人快速付款；
                4.	也可以直接点击手机号码，快速联系用户进行催缴。
                5.	注：每小时仅可以发送一条催缴短信`},
                {
                    title: '账单的已结清和未结清分别表示什么意思？', content: `
                1.	系统中的收款账单和付款账单都会有已结清和未结清的状态；
                2.	已结清：账单中的应收/应付额等于账单中的实收/实付额。
                3.	未结清：账单中的应收/应付额大于账单中的实收/实付额。
                4.	注：提到的实收/实付额均为收付申请审批确认之后的实收/实付额。`},
                {
                    title: '什么样的账单可以退款？怎么退款？退款额有什么限制？', content: `
                	已经有实收的收款账单才可以退款。
                	账单退款的步骤如下
                1.	进入账单列表，筛选已经有实收的收款账单；
                2.	点击退款按钮，确认退款金额和退款原因；
                3.	点击提交，发起退款的审批。
                4.	审批通过之后，退款生效。
                5.	注：退款额不得大于账单的实收额。`},
                {
                    title: '怎么进行账单的收款确认？同一个账单支持多笔收款吗？', content: `
                	可对实收款小于应收款的账单进行收款确认。
                	操作步骤如下：
                1.	进入账单列表，筛选出目标账单。
                2.	点击页面右侧收款按钮，进入收款确认页面。
                3.	依次确认账单的房源信息、账单信息，填写此次的收款信息（不得大于剩余应收），填写收款途径和付款途径，点击确认发起收款申请。
                4.	审批通过之后，本笔收款生效。
                5.	同一个账单可以支持多笔收款，多笔收款的收款记录可以在账单详情中查询。`},
                {
                    title: '账单的明细怎么导出？同一个账单的多笔收款或者付款记录怎么在明细中展示？', content: `
                	系统提供账单的导出功能，用户可根据需求对账单进行筛选之后，批量导出账单。
                	操作步骤如下：
                1.	进入账单列表，根据需要筛选账单。
                2.	点击页面右上角“导出报表”按钮，系统会将目标账单批量导出为表格，并自动下载到本地。
                3.	如果针对同一个账单进行过多笔的收款或者付款记录，系统会在生成的账单表格中添加多行来展示这些记录。`},
                {
                    title: ' 怎么在系统中开一张收据？', content:
                        ` 	针对有实收的账单，系统提供收据开具功能，可以多次开具收据，但是开具的收据金额不能大于账单的实收金额。
                	具体的操作步骤如下
                1.	进入收款账单列表，选定目标账单，点击收据，进入收据申请页面；
                2.	页面显示当前账单实收金额和已开待开收据金额。
                3.	输入本次开收据的金额和时间，点击确定。
                4.	在收据管理页面找到这条收据申请，保证电脑已连接打印机，点击打印进行预览和打印。`},

                {
                    title: '怎么建立一个合同模板？', content: `
                	初次使用系统，需要根据实际业务场景进行合同模板的创建，审批通过之后的合同模板可以在合同模块创建添加合同的场景中调用。调用过程中，系统自动将合同模板中的关键字和用户已填的信息进行匹配，快捷生成合同。
                	具体步骤如下：
                1.	进入合同模板列表，点击新建模板，进入模板新建页面；
                2.	填写模板基本信息，包含名称适用范围类型等信息；
                3.	根据实际合同需要，复制需要的关键字到word文档，如果是表格类关键字，正常复制即可，生成合同时，系统会自动排版为表格；
                4.	保存编辑后的word模板，上传系统；
                5.	审核通过后即为创建成功。
                6.	创建成功的合同模板，在添加合同的过程中可被调用。也可以修改，修改之后需要重新审核。`},

                {
                    title: '怎么创建一个收据模板？', content: `
                	和合同模板一样，初次使用系统，需要根据实际业务场景进行收据模板的创建，审批通过之后的收据模板可以在合同模块创建添加合同的场景中调用。调用过程中，系统自动将合同模板中的关键字和用户已填的信息进行匹配，快捷生成合同。
                	具体步骤如下：
                1.	进入合同模板列表，点击新建模板，进入模板新建页面；
                2.	填写模板基本信息，包含名称适用范围类型等信息；
                3.	根据实际合同需要，复制需要的关键字到word文档；
                4.	保存编辑后的word模板，上传系统；
                5.	审核通过后即为创建成功。
                6.	创建成功的收据模板，在开具收据的过程中可被调用。也可以修改，修改之后需要重新审核。`}

            ]
        ]
        ,
        shouldHide: [],
        expanded: []

    };

    renderTreeNodes = data =>
        data.map((item,index) => {
            if (item.children) {
                return (
                    <TreeNode title={item.title} key={index}  icon={<Icon type="meh-o1" />}  dataRef={item}>
                        {this.renderTreeNodes(item.children)}
                    </TreeNode>
                );
            }
            return <TreeNode key={index}  icon={<Icon type="meh-o1" />}  {...item} dataRef={item} />;
        });

    onTreeSelect = (selectOptions) => {
        this.setState({
            seleteIndex: selectOptions[0]
        }, () => {
            this.measure();
        })
    }

    expanding = (index, selectIndex) => {
        const expanded = [...this.state.expanded];
        let childExpand = expanded[selectIndex];
        if (!childExpand) {
            expanded[selectIndex] = childExpand = [];
        }
        childExpand[index] = !childExpand[index];
        this.setState({ expanded: expanded });
        console.log(expanded);
    }

    measure = () => {
        const shouldHide = [...this.state.shouldHide];
        (refs[this.state.seleteIndex] || []).forEach((item, index) => {
            const size = item.children.length;
            let height = 0;
            for (let i = 0; i < size; ++i) {
                height += item.children[i].clientHeight;
            }
            let childHide = shouldHide[this.state.seleteIndex];
            if (!childHide) {
                shouldHide[this.state.seleteIndex] = childHide = [];
            }
            childHide[index] = height > 72;
        });
        this.setState({ shouldHide: shouldHide });
    }

    itemRef = (ref, index, seleteIndex) => {
        if (ref) {
            let childRefs = refs[seleteIndex];
            if (!childRefs) {
                refs[seleteIndex] = childRefs = [];
            }
            childRefs[index] = ref;
        }
    }
    componentDidMount() {
        this.measure();
    }

    render() {

        const { data, seleteIndex, shouldHide, expanded } = this.state;
        const childHide = shouldHide[seleteIndex];
        const childExpanded = expanded[seleteIndex];
        return (
            <div className="help-center">

                <div className="help-head">
                    <div className="help-center-title">十方资易答疑助手</div>
                </div>

                <div className='help-body'>
                    <DirectoryTree 
                        className="help-draggable-tree"
                        defaultExpandedKeys={this.state.expandedKeys}
                        blockNode
                        defaultSelectedKeys={['0']}
                        onSelect={this.onTreeSelect}
                    >
                        {this.renderTreeNodes(this.state.treeData)}
                    </DirectoryTree >

                    <List className="help-list-item"
                        dataSource={data[seleteIndex]}
                        renderItem={(item, index) => (
                            <List.Item>
                                <div className='help-item-title'>{item.title}</div>
                                <div className='help-item-content'
                                    ref={ref => this.itemRef(ref, index, seleteIndex)}
                                    style={{ maxHeight: childExpanded && childExpanded[index] ? 'none' : '72px' }}>
                                    {item.content.split('\n').map((i,index) => (<div key={index}>{i}</div>))}
                                </div>
                                {childHide && childHide[index] ?
                                    (<div className="help-show-all" onClick={() => {
                                        this.expanding(index, seleteIndex);
                                    }}>{childExpanded && childExpanded[index] ? '收起' : '展示全部'}</div>) : null}
                            </List.Item>
                        )}
                    />
                </div>
            </div>
        );
    }
}

export default HelpCenter;