import React, { Component } from 'react';
import { Form, Select, Input, Button, DatePicker } from 'antd';
import moment from 'moment';
import { API } from '@/http/api';
import http from '@/http';

const { Option } = Select;
const { RangePicker } = DatePicker;

class SearchForm extends Component{
  state = { 
    supplierArray:[]
  }

   //初始化
   componentDidMount() {
       
    let that = this;
     
    http({
      api:API.enterpriseAllList,
      data:{},
      showLoading: false
    }).then(res => {
       if(res&&res.code==200&&res.data&&Array.isArray(res.data)){
         let supplierArray=[];
        res.data.forEach((item)=>{
          supplierArray.push({id:item.id,name:item.name});
        });
        that.setState({supplierArray});

       }
    });
  }

  reset = () => {
    const { form } = this.props;
    form.resetFields();
    this.props.form.validateFields(
      { force: true },
      (err, values) => {
        console.log(err, values, 'err, values')
        if (!err) {
          this.props.reset(values);
          // this.props.onSearch()
        }
      },
    );
  }
  render() {
    const {supplierArray} = this.state;

    const { getFieldDecorator } = this.props.form
    return (
      <Form layout='inline'>
       
       <Form.Item label="">
          {getFieldDecorator('auditType')(
            <Select allowClear optionFilterProp="label" placeholder="审批类型" style={{width: '200px'}}>
              <Option key="-1" value={-1}> 全部 </Option>
              <Option key="1" value={1}> 初次提交 </Option>
              <Option key="2" value={2}> 重新提交 </Option>
            </Select>
          )}
        </Form.Item>

        <Form.Item label="提交人">
          {getFieldDecorator('queryInfo', )(
            <Input
              style={{width: '200px'}}
              placeholder={'姓名/手机号码'}
            />
          )}
        </Form.Item>
        

        <Form.Item label="">
          {getFieldDecorator('supplierId')(
            <Select allowClear showSearch optionFilterProp="label" placeholder="所属企业" style={{width: '200px'}}>
              <Option key="-1" value={-1}>
                全部
              </Option>
              {supplierArray.map(item => (
                <Option key={item.id} value={item.id} label={item.name}>
                  {item.name}
                </Option>
              ))}
            </Select>,
          )}
        </Form.Item>
        {/* <Form.Item label="信息名称">
          {getFieldDecorator('infoName', {
          rules: [{ required: false, message: '123' }],
          })(
            <Input
              style={{width: '250px'}}
              placeholder='信息名称'
            />
          )}
        </Form.Item> */}
        <Form.Item label="">
          <Button type='primary' onClick={this.props.onSearch}>搜索</Button>
        </Form.Item>
        <Form.Item label="">
          <a className='reset' onClick={this.reset}>重置</a>
        </Form.Item>
      </Form>
    );
  }
}

const onValuesChanges = (props, changedValues, allValues) => {
  let result = {...allValues,pageNo: 1}
  if(allValues.commitTime && allValues.commitTime.length > 0){
    result.commitTimeStart = moment(allValues.commitTime[0]).format('YYYY-MM-DD')
    result.commitTimeEnd = moment(allValues.commitTime[1]).format('YYYY-MM-DD')
  } else {
    result.commitTimeStart = ''
    result.commitTimeEnd = ''
  }
  delete result['commitTime']
  for(let key in result){
    if(result[key] == undefined){
      result[key] = ''
    }
  }
  props.onValuesChange(result)
}

export default Form.create({ name: "EnterpriseAuthList", onValuesChange: onValuesChanges })(SearchForm);
