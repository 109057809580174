import {
    AUTH_KEYS
} from '@/utils/auth';

// 这些值是在.env.development中配置。如果本地需要修改，请创建.env.development.local并在.env.development.local文件中覆盖相应的值
const {
    REACT_APP_useDefaultUser,
    REACT_APP_userName,
    REACT_APP_userToken,
    REACT_APP_userIdCode
} = process.env;

const user = {
    functionList: Object.values(AUTH_KEYS),
    userName: REACT_APP_userName,
    token: REACT_APP_userToken,
    idCode: REACT_APP_userIdCode
}

const UserInfoInstance={}

export default {
    // 为true时使用默认用户，页面加载时会默认在localStorage中存储user；
    // 改为false时，需将localStorage中用户清空或执行页面上的退出，然后用户需要执行登录
    useDefaultUser: REACT_APP_useDefaultUser === 'y',
    user
}