import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom';
import { Form, Input, Button, Select, Row, Col, DatePicker, message, Modal, Icon } from 'antd'
import userUtil from '@/utils/user'
import { AUTH_KEYS } from '@/utils/auth'
import http from '@/http'
import { API } from '@/http/api'
import { changeObjkey } from '@/utils'
import AuthContainer from 'CMPT/common/auth/AuthContainer';
import BreadcrumbHeader from 'CMPT/common/breadcrumb/breadcrumb'
import MultiCascader from 'CMPT/common/MultiCascader';
import Table from 'CMPT/common/Table'
import LogisticsTabnums from 'CMPT/common/tabnums/tabnums'
import SendOrder from 'CMPT/serviceCenter/logistics/maintain/sendorder'
import AcceptanceOrder from './acceptanceorder'
import CancelOrder from './cancelorder'

const { RangePicker } = DatePicker;
const { Option }= Select;
const { Search } = Input;
const keyMapCascader = {key:'value',value:'label'};

class OperateComplaintform extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sendModelstatus: false,
            acceptanceModelstatus: false,
            cancelModelstatus: false,
            optionstimeTypeList: [],
            tabnums: [],
            projects: [],
            projectschildren: [],
            urgent: [],
            columns: [
                {
                    title: '工单编号',
                    dataIndex: 'key',
                    fixed: 'left',
                    width: 150,
                    render: (text, row) => (
                        <span className="table-operate-btn color" onClick={this.operateID.bind(this, text, row)}>{text}</span>
                    )
                },{
                    title: '项目-房源',
                    dataIndex: 'projectList',
                    width: 200
                },{
                    title: '投诉问题',
                    dataIndex: 'complainttext',
                    width: 200
                },{
                    title: '紧急程度',
                    dataIndex: 'urgentLeveltext',
                    width: 120
                },{
                    title: '地址',
                    dataIndex: 'address',
                    width: 250
                },{
                    title: '工单状态',
                    dataIndex: 'statustext',
                    width: 120
                },{
                    title: '上报人信息',
                    dataIndex: 'applicant', //applicantName + mobile
                    width: 120
                },{
                    title: '上报时间',
                    dataIndex: 'createTime',
                    width: 180
                },{
                    title: '受理人信息',
                    dataIndex: 'labor', //laborUserName + laborMobile
                    width: 120
                },{
                    title: '预计处理时间',
                    dataIndex: 'processTime',
                    width: 180
                },{
                    title: '完成时间',
                    dataIndex: 'completedTime',
                    width: 180
                },{
                    title: '操作',
                    dataIndex: 'operate',
                    fixed: 'right',
                    width: 150,
                    render: (text, row) => (
                        <span>
                            {text.map(per => {
                                return <span className="table-operate-btn" key={per} onClick={this.operate.bind(this, per, row)}>{per}</span>
                            })}
                        </span>
                    )
                }
            ],
            tabledata: [],
            tabletotal: 0,
            searchObj: {
                appOrWeb: 0, // 客户端类型:0-PC端、1-APP端
                searchTimeType: null, // 过滤时间类型:0预计处理时间、1:处理完成时间、2:上报时间 ,
                beginTime: null, // 开始时间 时间戳
                endTime: null, // 结束时间  时间戳
                searchKeyName: null, // 通用searchKey字段,工单ID/地址/上报人/上报人手机号/受理人/投诉内容 ,
                projectEncode: null, // 项目code
                zoneEncodeList: null, // 房间code
                status: this.props.location.hash ? [Number(this.props.location.hash.substr(1))] : null, // 状态（1待派单、2待处理、3处理中、4待验收、5已完成、0已取消）
                urgentLevel: null, // 紧急程度 （1紧急、2一般）
                orderByList : [7], //  排序 1创建时间正序、2创建时间倒序、3最后更新时间正序、4最后更新时间倒序、5名称正序 6名称倒序 7预计处理时间正序、8预计处理时间倒序、9订单ID正序、10订单ID倒序
                pageNo: 1, // 当前页数
                pageSize: 10 // 每页条数
            },
            defaultObj: {
                searchKeyName: null,
                projectEncode: undefined,
                zoneEncodeList: [],
                urgentLevel: undefined,
                searchTimeType: undefined,
                momentTime: null,
                status: '-1'
            },
            sendorderdata: {},
            acceptanceorder: {},
            cancelorder: {},
            servicerList: [],
            currentCode: null,
            result: []
        }
    }
    //tabs选中哪一项
    getnumindex(val) {
        const searchObj = this.state.searchObj;
        const defaultObj = this.state.defaultObj;
        searchObj.status = Number(val) === -1 ? null : [Number(val)];
        searchObj.pageNo = 1;
        defaultObj.status = val;
        setTimeout(()=>{
            this.setState({
                searchObj,
                defaultObj
            })
        },0);
        this.postitemList(searchObj)
    }
    //模糊搜索
    handleSearch(val) {
        const searchObj = this.state.searchObj;
        searchObj.searchKeyName = val === '' ? null : val;
        searchObj.pageNo = 1;
        setTimeout(()=>{
            this.setState({
                searchObj
            })
        },0);
        this.postitemList(searchObj)
    }
    handleChange(event) {
        const val = event.target.value;
        const defaultObj = this.state.defaultObj;
        defaultObj.searchKeyName = val;

        this.setState({
            defaultObj
        });

        if (val === '' && event.type === 'click') this.handleSearch(null)
    }
    //选择项目-房源某项
    chooseProject(val) {
        const searchObj = this.state.searchObj;
        const defaultObj = this.state.defaultObj;
        searchObj.projectEncode = val;
        searchObj.pageNo = 1;
        defaultObj.projectEncode = val;
        setTimeout(()=>{
            this.setState({
                searchObj,
                defaultObj
            })
        },0);
        if (val) this.loadDataProject(val);
        this.postitemList(searchObj)
    }
    chooseProjectchildren(val) {
        const searchVal = val && val.map(item => item.id);
        const searchObj = this.state.searchObj;
        const defaultObj = this.state.defaultObj;
        searchObj.zoneEncodeList = searchVal;
        searchObj.pageNo = 1;
        defaultObj.zoneEncodeList = searchVal;
        setTimeout(()=>{
            this.setState({
                searchObj,
                defaultObj
            })
        },0);
        this.postitemList(searchObj)
    }
    //选择紧急程度某项
    chooseUrgent(val) {
        const searchObj = this.state.searchObj;
        const defaultObj = this.state.defaultObj;
        searchObj.urgentLevel = val ? [Number(val)] : null;
        searchObj.pageNo = 1;
        defaultObj.urgentLevel = val;
        setTimeout(()=>{
            this.setState({
                searchObj,
                defaultObj
            })
        },0);
        this.postitemList(searchObj)
    }
    //选择处理时间类型
    choosedateType(val) {
        const defaultObj = this.state.defaultObj;
        const searchObj = this.state.searchObj;
        const timeFirst = searchObj.beginTime;
        const timeSecond = searchObj.endTime;

        defaultObj.searchTimeType = val;
        searchObj.searchTimeType = Number(val);
        searchObj.pageNo = 1;
        setTimeout(()=>{
            this.setState({
                defaultObj
            })
        },0);

        if (timeFirst && timeSecond) {
            setTimeout(()=>{
                this.setState({
                    searchObj
                })
            },0);

            this.postitemList(searchObj)
        }
    }
    //改变上报时间
    onChangedate(date) {
        const searchTimeType = this.state.defaultObj.searchTimeType;

        if (searchTimeType) {
            const searchObj = this.state.searchObj;
            const defaultObj = this.state.defaultObj;
            const timeFirst = date[0] ? date[0].format('YYYY-MM-DD') : null;
            const timeSecond = date[1] ? date[1].format('YYYY-MM-DD') : null;

            searchObj.beginTime = timeFirst;
            searchObj.endTime = timeSecond;
            searchObj.pageNo = 1;
            defaultObj.momentTime = date;

            setTimeout(()=>{
                this.setState({
                    searchObj,
                    defaultObj
                })
            },0);

            this.postitemList(searchObj)
        } else {
            message.warning('请先选择处理时间类型');
        }
    }
    //点击重置
    clickreaset() {
        const defaultObjList = this.state.defaultObj;
        const defaultObj = {
            searchKeyName: null,
            projectEncode: undefined,
            zoneEncodeList: [],
            urgentLevel: undefined,
            searchTimeType: undefined,
            momentTime: null,
            status: '-1'
        };

        if (JSON.stringify(defaultObjList) !== JSON.stringify(defaultObj)) {
            const searchObj = {
                appOrWeb: 0, // 客户端类型:0-PC端、1-APP端
                searchTimeType: null, // 过滤时间类型:0预计处理时间、1:处理完成时间、2:上报时间 ,
                beginTime: null, // 开始时间 时间戳
                endTime: null, // 结束时间  时间戳
                searchKeyName: null, // 通用searchKey字段,工单ID/地址/上报人/上报人手机号/受理人/投诉内容 ,
                projectEncode: null, // 项目code
                zoneEncodeList: null, // 房间code
                status: null, // 状态（1待派单、2待处理、3处理中、4待验收、5已完成、0已取消）
                urgentLevel: null, // 紧急程度 （1紧急、2一般）
                orderByList : [7], //  排序 1创建时间正序、2创建时间倒序、3最后更新时间正序、4最后更新时间倒序、5名称正序 6名称倒序 7预计处理时间正序、8预计处理时间倒序、9订单ID正序、10订单ID倒序
                pageNo: 1, // 当前页数
                pageSize: 10 // 每页条数
            };
            setTimeout(()=>{
                this.setState({
                    searchObj,
                    defaultObj,
                    projectschildren: []
                })
            },0);
            this.postitemList(searchObj)
        }
    }
    //下一页 改变table页码
    onChangetable(pageNo,pageSize) {
        const searchObj = this.state.searchObj;
        searchObj.pageNo = Number(pageNo);
        searchObj.pageSize = Number(pageSize);

        setTimeout(()=>{
            this.setState({
                searchObj
            })
        },0);
        this.postitemList(searchObj)
    }
    //点击工单跳转详情
    operateID(data, row) {
        this.props.history.push({
            pathname:"/app/operate/serviceCenter/logistics/complaint/orderDetail",
            hash: `#${row.encode}`
        })
    }
    //点击派单 验收等按钮
    operate(str,item) {
        //将所选的当前列表code取出
        setTimeout(()=>{
            this.setState({
                currentCode: item.encode
            })
        },0)
        //根据不同操作进行不同方法
        switch (str) {
            case '派单':
            case '重新派单':
                this.showsendModal(item.projectEncode);
                break;
            case '验收':
                this.showacceptanceModal();
                break;
            case '取消工单':
                this.showcancelModal();
                break;
            default:
                break
        }
    }
    //派单
    handlesendOk(event) {
        event.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const dataObj = {
                    orderCode: this.state.currentCode,
                    userCode: this.state.sendorderdata.sendOrder,
                    userName: this.state.sendorderdata.userName,
                    mobile: this.state.sendorderdata.mobile,
                    remark: this.state.sendorderdata.remarks
                };
                this.showsendModal();
                this.postsendDateSave(dataObj)
            }
        });
    }
    async showsendModal(projectEncode) {
        if (!this.state.sendModelstatus) await this.postservicerList({projectEncode}); //请求维修工list

        this.setState({
            sendModelstatus: !this.state.sendModelstatus
        });
    }
    changesendModal(type,val,option) {
        const sendorderdata = this.state.sendorderdata;
        let targetVal = null;
        if (val && val.target) targetVal = val.target.value;

        switch (type) {
            case 1:
                sendorderdata.sendOrder = val;
                sendorderdata.userName = this.state.servicerList.filter(item => item.value === val)[0].userName;
                sendorderdata.mobile = this.state.servicerList.filter(item => item.value === val)[0].mobile;
                break;
            case 2:
                sendorderdata.remarks = targetVal;
                break;
            default:
                break
        }

        this.setState({
            sendorderdata
        })
    }
    //验收
    handleacceptanceOk(event) {
        event.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const acceptanceorder = this.state.acceptanceorder;
                this.setState({
                    acceptanceorder: {}
                });
                const dataObj = changeObjkey(acceptanceorder,{Accresult:'checkResult',rate:'stars',remarks:'remark',reason:'remark'});
                dataObj.orderCode = this.state.currentCode;
                this.showacceptanceModal();
                this.postaccepDateSave(dataObj)
            }
        });
    }
    showacceptanceModal() {
        this.setState({
            acceptanceModelstatus: !this.state.acceptanceModelstatus
        });
    }
    changeacceptanceModal(type,val,option) {
        const acceptanceorder = this.state.acceptanceorder;
        let targetVal = null;
        if (val && val.target) targetVal = val.target.value;

        switch (type) {
            case 1:
                acceptanceorder.Accresult = Number(val);
                break;
            case 2:
                acceptanceorder.rate = Number(val);
                break;
            case 3:
                acceptanceorder.remarks = targetVal;
                break;
            case 4:
                acceptanceorder.reason = targetVal;
                break;
            default:
                break
        }

        this.setState({
            acceptanceorder
        })
    }
    //取消工单
    handlecancelOk(event) {
        event.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const dataObj = changeObjkey(this.state.cancelorder,{cancelresult:'remark'});
                dataObj.orderCode = this.state.currentCode;
                this.showcancelModal();
                this.postcancelDateSave(dataObj)
            }
        });
    }
    showcancelModal() {
        this.setState({
            cancelModelstatus: !this.state.cancelModelstatus
        });
    }
    changecancelModal(type,val,option) {
        const cancelorder = this.state.cancelorder;
        let targetVal = null;
        if (val && val.target) targetVal = val.target.value;

        switch (type) {
            case 1:
                cancelorder.cancelresult = targetVal;
                break;
            default:
                break
        }

        this.setState({
            cancelorder
        })
    }
    //处理options数据类型
    orderOptionsList(options) {
        return this.state[options].map(d => <Option key={d.value}>{d.label}</Option>)
    }

    componentWillMount() {
        this.getmaintainInit();
    }

    //请求初始化枚举
    getmaintainInit() {
        const config = {
            api: API.ordercomplainInit
        }
        http(config).then(result => {
            const { data } = result;
            let { urgentLevelEnum, complainStatusEnum, orderCheckTypeEnum, timeByEnum } = data;

            //工单状态
            complainStatusEnum = complainStatusEnum.map(item => changeObjkey(item,{key:'value',value:'name'}));

            //处理时间类型
            timeByEnum = timeByEnum.map(item => changeObjkey(item,keyMapCascader));

            //紧急程度
            urgentLevelEnum = urgentLevelEnum.map(item => changeObjkey(item,keyMapCascader));

            //通过不通过
            orderCheckTypeEnum = orderCheckTypeEnum.map(item => changeObjkey(item,{key:'value',value:'name'}));

            setTimeout(()=>{
                this.setState({
                    tabnums: complainStatusEnum,
                    optionstimeTypeList: timeByEnum,
                    urgent: urgentLevelEnum,
                    result: orderCheckTypeEnum
                })
            },0);

            //工单状态数量查询
            this.postitemStatuscount({},complainStatusEnum);
            //请求项目列表
            this.getitemInit();
        })
    }
    //请求初始化的项目
    getitemInit() {
        const config = {
            api: API.commonProjectfilter
        };
        http(config).then(result => {
            const { data } = result;
            const json = data.map(item => changeObjkey(item,{encode:'value',name:'label'},true));

            this.setState({
                projects: json
            })
        })
    }
    //请求工单状态数量
    postitemStatuscount(obj,tablists) {
        const config = {
            api: API.ordercomplainStatusCount,
            data: obj
        };

        http(config).then(result => {
            const { data } = result;
            const tabnums = tablists || this.state.tabnums;
            tabnums.map(item => {
                data.forEach(map => {
                    if (String(item.value) === String(map.key)) {
                        item.num = map.value
                    }
                })
                return item
            })

            this.setState({
                tabnums
            })

            this.postitemList(this.state.searchObj)
        })
    }
    //请求工单list
    postitemList(data) {
        const config = {
            api:API.ordercomplainList,
            data: data,
            showLoading: true
        };

        http(config).then(result => {
            const { data } = result;
            const { datas, total } = data;

            const tabledata = datas.map(item => {
                const list = changeObjkey(item,{orderId:'key'});
                const statustextName = item.statusDesc;
                let operate = [],statustext = '';
                list.applicant = item.applicantName ? <div><div>{item.applicantName}</div><div>{item.applicantNameMobile}</div></div> : '-'; //上报人信息
                list.labor = item.handleName ? <div><div>{item.handleName}</div><div>{item.handleNameMobile}</div></div> : '-'; //受理人信息
                if (item.urgentLevel) list.urgentLeveltext = Number(item.urgentLevel) === 1 ?
                    <div><span style={{color: '#E94437', marginRight: '6px'}}>●</span>{item.urgentLevelName}</div>
                    :
                    <div><span style={{color: '#E94437', marginRight: '6px'}}>●</span>{item.urgentLevelName}</div>; // 紧急程度
                if (item.applicantTime) list.createTime = item.applicantTime; // 上报时间
                if (item.processTime) list.processTime = item.processTime; // 预计处理时间
                if (item.completedTime) list.completedTime = item.completedTime; // 处理完成时间
                if (item.complainContent) list.complainttext = item.complainContent; // 投诉问题
                if (item.projectHouseName) list.projectList = item.projectHouseName; // 项目-房源


                switch (Number(item.status)) {
                    case 1:
                        operate = [
                            userUtil.hasAuth(AUTH_KEYS.OPERATE_SERVICE_LOGISTICS_COMPLAINT_SENDORDER) && (item.dispatchFlag === 1 ? '重新派单' : '派单'),
                            userUtil.hasAuth(AUTH_KEYS.OPERATE_SERVICE_LOGISTICS_COMPLAINT_CANCELORDER) && '取消工单'
                        ];
                        statustext = <div><span style={{color: '#FBBC06', marginRight: '6px'}}>●</span>{statustextName}</div>;
                        break;
                    case 2:
                        operate = [
                            userUtil.hasAuth(AUTH_KEYS.OPERATE_SERVICE_LOGISTICS_COMPLAINT_CANCELORDER) && '取消工单'
                        ];
                        statustext = <div><span style={{color: '#FBBC06', marginRight: '6px'}}>●</span>{statustextName}</div>;
                        break;
                    case 3:
                        operate = [
                            userUtil.hasAuth(AUTH_KEYS.OPERATE_SERVICE_LOGISTICS_COMPLAINT_ACCEPTANCEORDER) && '验收',
                            userUtil.hasAuth(AUTH_KEYS.OPERATE_SERVICE_LOGISTICS_COMPLAINT_CANCELORDER) && '取消工单'
                        ];
                        statustext = <div><span style={{color: '#FBBC06', marginRight: '6px'}}>●</span>{statustextName}</div>;
                        break;
                    case 4:
                        operate = [];
                        statustext = <div><span style={{color: '#E94437', marginRight: '6px'}}>●</span>{statustextName}</div>;
                        break;
                    case 0:
                        operate = [];
                        statustext = <div><span style={{color: '#333', marginRight: '6px'}}>●</span>{statustextName}</div>;
                        break;
                    default:
                        operate = [];
                        statustext = '';
                        break
                }
                list.operate = operate;
                list.statustext = statustext;

                return list
            })

            this.setState({
                tabletotal: total,
                tabledata
            })
        })
    }
    //请求维修工list
    postservicerList(data) {
        const config = {
            api: API.ordercomplainGetLaborList,
            data
        };
        http(config).then(result => {
            const { data } = result;
            const servicerList = data && data.map(item => {
                const list = changeObjkey(item,{encode:'value'});
                list.name = <Row><Col span={4}>{item.name}</Col><Col span={8}>{item.mobile}</Col><Col span={8}>待维修共{item.wppCount}单</Col></Row>;
                list.userName = item.name;
                list.mobile = item.mobile;
                return list
            });
            setTimeout(()=>{
                this.setState({
                    servicerList
                })
            },0)
        })
    }
    //请求房源项目二级
    loadDataProject(selectedOptions) {
        const config = {
            api: API.commonFloorZoneFilter,
            data: {
                projectEncode: selectedOptions
            }
        };

        http(config).then(result => {
            const { data } = result;
            const projectschildren = [];
            data && data.length > 0 && data.forEach(item => {
                if (item.zones && item.zones.length > 0) {
                    const list = changeObjkey(item,{encode:'id',floorNo:'no',name:'name'},true);
                    list.child = item.zones.map(itemChild => changeObjkey(itemChild,{zoneNo:'name',encode:'id',floorEncode:'parentId'},true));
                    projectschildren.push(list)
                }
            });

            setTimeout(()=>{
                this.setState({
                    projectschildren
                })
            },0)
        })
    }
    //请求派单保存
    postsendDateSave(data) {
        const config = {
            api: API.ordercomplainDispatch,
            data: {
                ...data
            },
            showLoading: true
        };
        const searchObj = this.state.searchObj;
        searchObj.pageNo = 1;
        http(config).then(result => result.success && (message.success(`派单成功`) && this.postitemStatuscount({})))
    }
    //请求验收保存
    postaccepDateSave(data) {
        const config = {
            api: API.ordercomplainCheck,
            data: {
                ...data
            },
            showLoading: true
        };
        const searchObj = this.state.searchObj;
        searchObj.pageNo = 1;
        http(config).then(result => result.success && (message.success(`操作成功`) && this.postitemStatuscount({})))
    }
    //请求取消工单
    postcancelDateSave(data) {
        const config = {
            api: API.ordercomplainCancel,
            data: {
                ...data
            },
            showLoading: true
        };
        const searchObj = this.state.searchObj;
        searchObj.pageNo = 1;
        http(config).then(result => result.success && (message.success(`取消成功`) && this.postitemStatuscount({})))
    }

    render() {
        const breadlist = [{name:'投诉管理'}];
        const addorderButton =  <AuthContainer authCodes={AUTH_KEYS.OPERATE_SERVICE_LOGISTICS_COMPLAINT_ADDORDER}>
            <Button type="primary" className='new-role-btn' >
                <Link to='/app/operate/serviceCenter/logistics/complaint/addorder'>新增工单</Link>
            </Button>
        </AuthContainer>;
        const optionsLevel = this.orderOptionsList('urgent');
        const optionstimeType = this.orderOptionsList('optionstimeTypeList');
        const optionProjects = this.orderOptionsList('projects');
        const { tabnums, defaultObj, columns, tabledata, tabletotal, sendModelstatus, servicerList, acceptanceModelstatus, cancelModelstatus, searchObj } = this.state;
        return (
            <div className='OperateMaintain'>
                <BreadcrumbHeader breadlist={breadlist} buttonobj={addorderButton}></BreadcrumbHeader>
                <div className='OperateMaintainnav'>
                    <LogisticsTabnums
                        nums={tabnums}
                        activeKey={this.props.location.hash ? this.props.location.hash.substr(1) : defaultObj.status}
                        isminWidth={true}
                        getnumindex={this.getnumindex.bind(this)}>
                    </LogisticsTabnums>
                </div>
                <div className='OperateMaintaincontent'>
                    <Row className='OperateMaintaincontent-select'>
                        <Col span={6}>
                            <Search
                                style={{width: '100%'}}
                                allowClear
                                placeholder="工单编号/地址/上报人信息/受理人信息/投诉问题"
                                value={defaultObj.searchKeyName}
                                onChange={this.handleChange.bind(this)}
                                onSearch={this.handleSearch.bind(this)}
                            />
                        </Col>
                        <Col span={3}>
                            <Select style={{width: '100%'}}
                                    placeholder="请选择项目"
                                    allowClear
                                    value={defaultObj.projectEncode}
                                    suffixIcon={<Icon type="caret-down" />}
                                    onChange={this.chooseProject.bind(this)}>
                                {optionProjects}
                            </Select>
                        </Col>
                        <Col span={3}>
                            <MultiCascader
                                    style={{ width: '100%' }}
                                    placeholder="请选择房源"
                                    data={this.state.projectschildren}
                                    value={defaultObj.zoneEncodeList}
                                    noShowArea={true}
                                    onChange={this.chooseProjectchildren.bind(this)}>
                            </MultiCascader>
                        </Col>
                        <Col span={3}>
                            <Select style={{width: '100%'}}
                                    placeholder="紧急程度"
                                    allowClear
                                    value={defaultObj.urgentLevel}
                                    suffixIcon={<Icon type="caret-down" />}
                                    onChange={this.chooseUrgent.bind(this)}>
                                {optionsLevel}
                            </Select>
                        </Col>
                    </Row>
                    <Row className='OperateMaintaincontent-select special'>
                        <Col span={2}>
                            <Select style={{width: '100%'}}
                                    placeholder="处理时间类型"
                                    value={defaultObj.searchTimeType}
                                    suffixIcon={<Icon type="caret-down" />}
                                    onSelect={this.choosedateType.bind(this)}>
                                {optionstimeType}
                            </Select>
                        </Col>
                        <Col span={5}>
                            <RangePicker onChange={this.onChangedate.bind(this)}
                                         style={{width: '100%'}}
                                         value={defaultObj.momentTime} />
                        </Col>
                        <Col span={2} style={{marginLeft: 24}}>
                            <span style={{cursor: 'pointer',fontSize: '12px',textDecoration:'underline',textUnderlinePosition: 'under'}}
                                  onClick={this.clickreaset.bind(this)}>
                                重置
                            </span>
                        </Col>
                    </Row>
                    <div className='OperateMaintaintable-body contract-table'>
                        <Table
                            scroll={{ x: 1950 }}
                            columns={columns}
                            data={tabledata}
                            total={tabletotal}
                            current={searchObj.pageNo}
                            change={this.onChangetable.bind(this)} />
                    </div>
                </div>

                <div>
                    <Modal wrapClassName='Operate-sendorder'
                           title='派单'
                           destroyOnClose={true}
                           visible={sendModelstatus}
                           onCancel={this.showsendModal.bind(this)}
                           onOk={this.handlesendOk.bind(this)}>
                        <SendOrder  urgent={servicerList}
                                    form={this.props.form}
                                    chooseProject={this.changesendModal.bind(this)}>
                        </SendOrder>
                    </Modal>
                    <Modal wrapClassName='Operate-acceptanceorder'
                           title='验收'
                           destroyOnClose={true}
                           visible={acceptanceModelstatus}
                           onCancel={this.showacceptanceModal.bind(this)}
                           onOk={this.handleacceptanceOk.bind(this)}>
                        <AcceptanceOrder form={this.props.form}
                                         Accresult={this.state.acceptanceorder.Accresult}
                                         result={this.state.result}
                                         chooseProject={this.changeacceptanceModal.bind(this)}>
                        </AcceptanceOrder>
                    </Modal>
                    <Modal wrapClassName='Operate-cancel'
                           title='取消工单'
                           destroyOnClose={true}
                           visible={cancelModelstatus}
                           onCancel={this.showcancelModal.bind(this)}
                           onOk={this.handlecancelOk.bind(this)}>
                        <CancelOrder encode={this.state.currentCode}
                                     form={this.props.form}
                                     chooseProject={this.changecancelModal.bind(this)}>
                        </CancelOrder>
                    </Modal>
                </div>
            </div>
        )
    }
}

const OperateComplaint = Form.create({ name: 'OperateComplaintform' })(OperateComplaintform);

export default withRouter(OperateComplaint)
