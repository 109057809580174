import React, { Component } from "react";
import {
  Layout,
  Checkbox,
  Breadcrumb,
  Button,
  Form,
  Radio,
  Icon,
  Input,
  Row,
  Col,
  DatePicker,
  Modal,
  Cascader,
  Select
} from "antd";
import Select2 from "CMPT/common/Select";
import { API } from "@/http/api";
import http from "@/http";
import DateRange from "CMPT/common/DateRange";
import FileUpload from "CMPT/common/FileUpload";
import { ManagementModel } from "./CommonConst.js";
import {
  CommonFlag,
  AccountTypeMap,
  AccountTypeFlag,
  FormatSearch,
  convertEnumToMap
} from "CMPT/common.js";
import {
  industryTypeConfig,
  areaTypeConfig
} from "CMPT/operate/intentionConfig";
import {
  channelTypeConfig,
  sourceTypeConfig,
  statusConfig,
  customerTypeConfig
} from "CMPT/operate/customerConfig.js";
import { objectarrayToArray } from "@/utils/fliter";
import {
  customerAdd,
  customerCheck
} from "CMPT/operate/service/customerService";
import { changeObjkey, getNewPhone } from "@/utils/index";
import ImageUploadBiz from "CMPT/common/ImageUpLoadBiz";
import Upload from "CMPT/common/widget/Upload";
import FileUploadBiz from "CMPT/common/FileUploadBiz";
import { showErrorMessage } from "@/utils/globalMessageReminder";
import { Link } from "react-router-dom";
import reg from "@/utils/regex";
import debounce from "lodash/debounce";
import axios from "axios";
import user from "@/utils/user";
import { AUTH_KEYS } from "@/utils/auth";
import userUtil from "@/utils/user";
import moment from "moment";

import EditSupplierInfo from './EditSupplierInfo';

import "./NewAddEditCustomer.less";

const RadioGroup = Radio.Group;
const { Header, Content, Footer } = Layout;
const { Option } = Select;
const { TextArea } = Input;
const {
  phone,
  idCard,
  telephone,
  socialCreditCode,
  website,
  zipCode,
  phoneNew,
  businessLicense,
  personName
} = { ...reg };

class NewAddEditCustomer extends Component {
  componentDidMount() {
    // 初始化下 参数
    this.initState();
  }


  initState() {
    let search = this.props.history.location.search;
    var result = FormatSearch(search);
    this.setState({ encode: result.encode }, () => {
      if (this.state.encode) {
        // this.loadArea(); // 编辑情况下 需要预加载一些数据
        this.getDetail();
      }
    });
  }

  // 提交保存
  handleSubmit = e => {

    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, fieldsObject) => {
      if (!err) {
        // let fieldsObject = this.props.form.getFieldsValue();

        fieldsObject.isPurchase = 1; //默认是1，即 true
        fieldsObject.isGarden = this.state.isShowGraderForm ? 1 : 0;
        fieldsObject.isSupplier = this.state.isShowSupplierForm ? 1 : 0;
        fieldsObject.havaEmail = this.state.havaEmail ? 1 : 0; //接口需要int

        // 地址相关
        fieldsObject.provinceEncode = this.state.provinceEncode;
        fieldsObject.cityEncode = this.state.cityEncode;
        fieldsObject.regionEncode = this.state.regionEncode;
        delete fieldsObject.addressEncode;

        if (fieldsObject.signDate && fieldsObject.signDate.length > 0) {
          fieldsObject.signDate = moment(fieldsObject.signDate[0]).format(
            "YYYY-MM-DD"
          );
        }

        fieldsObject.businessLicenseUrl = fieldsObject.businessLicensePic.url;

        this.getCompleteForm(fieldsObject);
        this.cleanUselessKeys(fieldsObject);

        if (this.state.accountType == AccountTypeFlag.TRIAL) {
          if (this.state.trialStartDate && this.state.trialEndDate) {
            fieldsObject.contractStartDate = moment(
              this.state.trialStartDate
            ).format("YYYY-MM-DD");
            fieldsObject.contractEndDate = moment(
              this.state.trialEndDate
            ).format("YYYY-MM-DD");
          }
        } else {
          // 下面是正式账号
          if (fieldsObject.signDate) {
            fieldsObject.signDate = moment(fieldsObject.signDate).format(
              "YYYY-MM-DD"
            );
          }

          if (this.state.contractStartDate && this.state.contractEndDate) {
            fieldsObject.contractStartDate = moment(
              this.state.contractStartDate
            ).format("YYYY-MM-DD");
            fieldsObject.contractEndDate = moment(
              this.state.contractEndDate
            ).format("YYYY-MM-DD");
          }
        }

        fieldsObject.fileInfoList = this.state.fileInfoList;
        fieldsObject.authenticationTo = this.state.authenticationTo;

        delete fieldsObject.trialTimeRange;
        delete fieldsObject.contractDate;

        this.cleanInvalidKeys(fieldsObject);

        this.doSubmit({ ...fieldsObject,tianyanchaId:this.state.tianyanchaId }).then(res => {
          if (res.code == 0) {
            this.props.history.go(-1);
          }
        });
      }
    });
  };

  cleanInvalidKeys(fieldsObject){
    for (const [key,value] of Object.entries(fieldsObject)) {
      if (value == null || value === 'Invalid date') {
        delete fieldsObject[key];
      }
    }
  }

  cleanUselessKeys(fieldsObject){
    const keys = ['completeChecked','businessLicensePic','openLicensePic','taxpayerCertificatePic','corporateIdPic1','corporateIdPic2', 'isAgent',
    'agentIdPic1','agentIdPic2','powerPdf','listXls','taxRegistrationPic','orgPic','businessCategories'];

    for (const key of keys) {
      delete fieldsObject[key];
    }
  }

  getCompleteForm(fieldsObject){
    if (!fieldsObject.completeChecked) {
      fieldsObject.completeFlag = 1;
      return ;
    }

    fieldsObject.completeFlag = 2;
    
    fieldsObject.proxyFlag = this.state.isAgent ? 1: 2;
    
    const {openLicensePic,taxpayerCertificatePic,corporateIdPic1,corporateIdPic2,
      agentIdPic1,agentIdPic2,powerPdf,listXls,taxRegistrationPic,orgPic,businessCategories} = fieldsObject;

    const medias = {
      authorityCertForm:{files:[powerPdf]},
      legalPersonCertForm:{files:[corporateIdPic1,corporateIdPic2]},
      normalTaskCertForm:{files:[taxpayerCertificatePic]},
      onlineProductForm:{files:[listXls]},
      openAccountPermitForm:{files:[openLicensePic]},
      organizationCodeForm:{files:[orgPic]},
      proxyPersonCertForm:{files:[agentIdPic1,agentIdPic2]},
      taxCheckInForm:{files:[taxRegistrationPic]},
    };

    const completeForm = {};

    for (const [key,{files}] of Object.entries(medias)) {
      const mediaArr = [];
      for (const file of files) {
        if (file && file.url) {
          const {mediaExt,mediaKey,type,name,url} = file;
          mediaArr.push({mediaExt,mediaKey,type:mediaExt,name,url})
        }
      }

      if (mediaArr.length) {
        completeForm[key] = mediaArr;
      }

    }

    completeForm.categoryList = businessCategories.map(({one,two,oneCode,twoCode,file:{mediaExt,mediaKey,type,name,url}})=>{
      return {
        "levelOneName": one,
      	"levelTwoName": two,
        "levelOneCode": oneCode,
      	"levelTwoCode": twoCode,
     		 "mediaReqDto": 
    			{
      			mediaExt,mediaKey,type:mediaExt,name,url,
    			}
      }
    })

    fieldsObject.completeForm = completeForm;
  }

  initEnterpriseCompleteVo(enterpriseCompleteVo){
    const medias = {};

    if (enterpriseCompleteVo) {
      const map = {
        authorityCert: ['powerPdf'],
        legalPersonCert: ['corporateIdPic1'],
        legalPersonCertRevert: ['corporateIdPic2'],
        normalTaskCert: ['taxpayerCertificatePic'],
        onlineProduct: ['listXls'],
        openAccountPermit: ['openLicensePic'],
        organizationCode: ['orgPic'],
        proxyPersonCert: ['agentIdPic1'],
        proxyPersonCertRevert: ['agentIdPic2'],
        taxCheckIn: ['taxRegistrationPic'],
      }
  
      for (const [key,files] of Object.entries(enterpriseCompleteVo)) {
        if (files && files.length) {
          const matchKeys = map[key];
          for (let i = 0; i < files.length; i++) {
            const matchKey = matchKeys[i];
            medias[matchKey] = files[i];
          }
        }
      }
    }

    return medias;
  }

  initBusinessCategories(categoryList = []){
    if (categoryList) {
      const businessCategories = categoryList.map(({levelOneCode,levelOneName,levelTwoCode,levelTwoName,mediaVo:{mediaExt,mediaKey,type,name,url}})=>{
        return {
          one:levelOneName,two:levelTwoName,
          oneCode:levelOneCode,twoCode:levelTwoCode,file:{mediaExt,mediaKey,type:mediaExt,name,url}
        }
      });
  
      return businessCategories ;
    }
    
    return [];
  }

  initEditInfo(data) {
    let accoutData = {};
    if (data.acountType == AccountTypeFlag.FORMAL) {
      accoutData.signDate = moment(data.signDate, "YYYY-MM-DD");
      accoutData.contractStartDate = moment(data.contractStartDate, "YYYY-MM-DD");
      accoutData.contractEndDate = moment(data.contractEndDate, "YYYY-MM-DD");
      accoutData.fileInfoList = data.fileInfoList && data.fileInfoList.length > 0 ? [{ ...data.fileInfoList[0] }] : [];
    } else {
      accoutData.trialStartDate = moment(data.contractStartDate, "YYYY-MM-DD");
      accoutData.trialEndDate = moment(data.contractEndDate, "YYYY-MM-DD");
    }

    this.setState({
      havaEmail: data.havaEmail ? true : false,
      isShowGraderForm: data.isGarden == 1 ? true : false,
      isShowSupplierForm: data.isSupplier == 1 ? true : false,
      provinceEncode: data.provinceEncode,
      cityEncode: data.cityEncode,
      regionEncode: data.regionEncode,
      accountType: data.acountType,
      completeCheckedDisabled : data.completeFlag === 2,
      isSupplierDisabled: data.isSupplier == 1,
      isAgent: data.proxyFlag === 1,
      ...accoutData
    }, () => this.initFieldsValue(data));
  }

  initFieldsValue(data) {
    let addressEncodeArray = [];
    if (data.isGarden == 1) {
      addressEncodeArray.push(data.provinceEncode);
      addressEncodeArray.push(data.cityEncode);
      addressEncodeArray.push(data.regionEncode);
    }


    let accoutField = {};
    if (data.acountType == AccountTypeFlag.FORMAL) {
      accoutField.contractDate = data.contractStartDate;// 这里只是保证 Form有值，无实际意义.state中变量才有意义
      accoutField.signDate = moment(data.signDate, "YYYY-MM-DD");
      accoutField.fileInfoList = data.fileInfoList && data.fileInfoList.length > 0 ? [{ ...data.fileInfoList[0] }] : [];
    } else {
      accoutField.trialTimeRange = data.contractStartDate;// 这里只是保证 Form有值，无实际意义.state中变量才有意义
    }

    const medias = this.initEnterpriseCompleteVo(data.enterpriseCompleteVo);

    this.props.form.setFieldsValue({
      companyName: data.companyName,
      shortName: data.shortName,
      creditCode: data.creditCode,
      personalIdentity: data.personalIdentity,
      personalPhone: data.personalPhone,
      havaEmail: data.havaEmail ? true : false,
      address: data.address,
      projectNumber: data.projectNumber,
      isGarden: data.isGarden == 1 ? true : false,
      isSupplier: data.isSupplier == 1 ? true : false,
      category: data.category,
      commission: data.commission,
      managementModel: data.managementModel,
      addressEncode: addressEncodeArray,
      acountType: data.acountType + '',
      trialTimeRange: data.trialTimeRange,
      enterpriseDesc: data.enterpriseDesc,
      contactName: data.contactName,
      contactPhone: data.contactPhone,
      personalName: data.personalName,
      personalEmail: data.personalEmail,
      businessLicensePic:{ url: data.businessLicenseUrl },
      ...accoutField,
      completeChecked: data.completeFlag === 2,
      ...medias,
      businessCategories: this.initBusinessCategories(data.categoryList)
    });
  }
  doSubmit(data) {
    let isAdd = !this.state.encode;
    if (!isAdd) {//不是新增就是编辑
      data.encode = this.state.encode;
    }

    let config = {
      api: isAdd ? API.crmCustomerAdd : API.crmCustomerEdit,
      showLoading: true,
      data: data
    };

    return http(config);
  }

  getDetail = () => {
    if (!this.state.encode) {
      return;
    }
    const config = {
      api: API.crmCustomerDetail,
      path: {
        encode: this.state.encode
      },
      showLoading: true
    };

    http(config).then(res => {
      if (res && res.success && res.data) {
        let data = res.data;
        this.initEditInfo(data);
      }
    });
  };



  // 隐藏弹框
  hideConfirm = () => {
    this.setState({
      showConfirmFlag: false
    });
    this.props.history.push("/app/crm/client");
  };

  state = {
    encode: null,// 仅仅编辑的情况下需要
    userList: [],
    accountType: null,
    havaEmail: true,
    imageUrl: null,
    collapse: false,
    intentionRate: 0,
    customerType: null,
    userName: null,
    remarkLength: 0,
    blockList: [],
    fileInfoList: [],
    followUserList: [],

    trialAccountDays: -1,
    trialStartDate: null,
    trialEndDate: null,

    manageType: [], // 主营类型

    authenticationTo: null, // 准备去升级到哪个身份
    isShowSupplierForm: false,
    isShowGraderForm: false,
    region: [],

    signDate: null,//签约时间
    contractStartDate: null, //合同开始时间
    contractEndDate: null, // 合同结束时间

    provinceEncode: null, //省编码
    cityEncode: null, //市编码
    regionEncode: null, //区编码
    showConfirmFlag: false,
    contractDateConfig: {},
    detailList: []
  };

  getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  fileUp = fileList => {
    this.setState({
      fileInfoList: fileList
    });
  };

  onSignDateChange = (value) => {
    this.setState({
      signDate: value
    });
  };

  handleCustomerTypeChange = value => {
    this.setState(
      {
        acountType: value
      },
      () => {
        if (value == 0) {
          this.setState({
            contractDateConfig: {}
          });
        }
        if (value == 1) {
          this.setState({
            contractDateConfig: {
              rules: [{ required: true, message: "请选择" }]
            }
          });
        }
      }
    );
  };

  onRateChange = value => {
    this.props.form.setFieldsValue({
      intentionRate: value
    });
  };

  handleUploadChange = info => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });

      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      this.getBase64(info.file.originFileObj, imageUrl =>
        this.setState({
          imageUrl,
          loading: false
        })
      );
    }
  };

  //修改省市区
  onAddressRegionChange = (value, selectedOptions) => {
    if (value && value.length >= 3) {
      this.state.provinceEncode = value[0];
      this.state.cityEncode = value[1];
      this.state.regionEncode = value[2];
    }
  };

  loadRegion = selectedOptions => {
    const targetOption = selectedOptions[selectedOptions.length - 1];

    http({
      api: API.areaSubAreas,
      data: {
        encode: targetOption.code
      },
      showLoading: false
    }).then(res => {
      targetOption.children = res.data;
      if (selectedOptions.length < 2) {
        res.data.forEach(item => {
          item.isLeaf = false;
          return item;
        });
      }
      this.setState({ region: [...this.state.region] });
    });
  };



  // 省份列表
  loadArea = () => {
    http({
      api: API.areaChinaArea,
      showLoading: false
    }).then(res => {
      if (res.success) {
        this.setState({
          region: res.data.map(item => {
            item.isLeaf = false;
            return item;
          })
        })
      }
    });
  };


  cancel = () => {
    this.props.history.push("/app/crm/client");
  };

  // 企业简介
  remarkChange = e => {
    let _remark = this.props.form.getFieldValue("enterpriseDesc");
    this.setState({
      remarkLength: _remark ? _remark.length : 0
    });
  };

  // 合同有效期
  contractDateChange = (field, value) => {
    if (field === "startValue") {
      this.setState({
        contractStartDate: value
      });
    } else {
      this.setState({
        contractEndDate: value
      });
    }
  };

  trialDateChange = (field, value) => {
    if (field === "startValue") {
      this.setState({
        trialStartDate: value
      });
    } else {
      this.setState({
        trialEndDate: value
      });
    }
  };

  onChanggeEmailCheckbox = e => {
    let newValue = e.target.checked;
    this.setState({
      havaEmail: newValue
    });
  };

  handleAccountTypeChange = value => {
    this.setState({
      accountType: value
    }, () => this.checkAccountType());
  };

  checkAccountType = () => {
    if (this.state.accountType != AccountTypeFlag.TRIAL) {
      return;
    }

    let { trialAccountDaysValue, trialStartDate, trialEndDate } = this.state;
    if (trialAccountDaysValue > 0) {
      if (!trialStartDate || !trialEndDate) {
        this.setState({
          trialStartDate: moment(),
          trialEndDate: moment().add(trialAccountDaysValue, "days")
        });
      }
    } else {
      this.getTrialSetting();
    }
  }

  switchIsSupplier = e => {
    let newValue = e.target.checked;
    this.state.isShowSupplierForm = newValue;
    this.setState({});

    // if (
    //   newValue &&
    //   (!this.state.manageType || this.state.manageType.length <= 0)
    // ) {
    //   this.loadManageType();
    // }

    this.changeAuthenticationTo();
  };

  /**
   * 这个处理方案是有问题的，应该吧 主营类目封装成一个 具体业务的组件
   */
  loadManageType = () => {
    const config = {
      api: API.enterpriseUpgradeCategoryQuery,
      showLoading: true
    };
    http(config).then(res => {
      if (res && res.success) {
        // 组装
        let newManageType = res.data.map(valueReturn => {
          return {
            value: valueReturn.code,
            name: valueReturn.name
          };
        });

        // 赋值
        this.setState({
          ManageType: newManageType
        }, () => this.getDetail());
      }
    });
  };

  //TODO  新增和升级 使用了相同的代码：获取决策列表。 完全可以封装出一个 试用期的业务组件
  getTrialSetting = () => {
    if (this.state.trialAccountDays > 0) {
      //获取过就不用再获取
      return;
    }

    // 获取项目配置
    const defaultConfig = {
      api: API.decisionGetList,
      path: {
        bizType: 1
      },
      data: {
        bizType: "1"
      }
    };

    let myState = this.state;
    //trial_account_days
    http(defaultConfig).then(res => {
      if (res.data && res.data.length) {
        for (let j = 0, len = res.data.length; j < len; j++) {
          let item = res.data[j];
          if (item && item.key == "trial_account_days") {
            let trialAccountDaysValue = item.value;
            if (!myState.trialStartDate || !myState.trialEndDate) {
              myState.trialStartDate = moment();
              myState.trialEndDate = moment().add(
                trialAccountDaysValue,
                "days"
              );
            }

            this.setState({
              trialAccountDays: trialAccountDaysValue
            });
            break;
          }
        }
      }
    });
  };

  switchIsGrader = e => {
    let newValue = e.target.checked;
    this.state.isShowGraderForm = newValue;
    this.setState({});

    if (newValue) {
      this.getTrialSetting();
    }

    if (newValue && (!this.state.region || this.state.region.length <= 0)) {
      this.loadArea();
    }

    this.changeAuthenticationTo();
  };

  changeAuthenticationTo() {
    let newValue = null;
    if (this.state.isShowSupplierForm && this.state.isShowGraderForm) {
      newValue = "2,3";
    } else if (this.state.isShowGraderForm) {
      newValue = "3";
    } else if (this.state.isShowSupplierForm) {
      newValue = "2";
    }

    this.state.authenticationTo = newValue;
  }

  showSupplierInfo() {
    const { getFieldDecorator } = this.props.form;
    let myState = this.state;
    const config = {
      rules: [{ required: true, message: "请选择" }]
    };

    return (
      <div className="new_customer-add-body">
        <h6>供应商信息</h6>

        {myState.ManageType && myState.ManageType.length > 0 ? (
          <Row gutter={24}>
            <Col className="gutter-row" span={8}>
              <Form.Item label="主营类型">
                {getFieldDecorator(
                  "category",
                  config
                )(
                  <Select2
                    style={{ width: "100%" }}
                    data={this.state.ManageType}
                    placeholder="请选择"
                    selectMethod={this.handleManagerTypeChange}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        ) : (
            ""
          )}

        <Row gutter={5}>
          <Col span={5}>
            <Form.Item label="佣金比例">
              {getFieldDecorator("commission", {
                valuePropName: "value",
                rules: [
                  {
                    pattern: /^99$|^(\d|[1-9]\d)(\.\d{1,5})*$/,
                    message: "仅能输入数字，0<=n< 100，小数点后最多保留5位"
                  },
                  { required: true, message: "请输入佣金比例" }
                ]
              })(
                <Input
                  suffix="%"
                  placeholder={"佣金比例"}
                />
              )}
            </Form.Item >
          </Col>
        </Row>
        <Row gutter={24}>
          <Col className="gutter-row" span={12}>
            <Form.Item label="经营模式">
              {getFieldDecorator("managementModel", {
                rules: [
                  {
                    required: true,
                    message: "请选择"
                  }
                ]
              })(
                <RadioGroup  >
                  {ManagementModel.map(item => {
                    return (
                      <Radio key={item.value} value={item.value}>
                        {item.label}
                      </Radio>
                    );
                  })}
                </RadioGroup>
              )}
            </Form.Item>
          </Col>
        </Row>
      </div>
    );
  }

  debounce = (func, wait, immediate) => {

    

    return function () {
      var context = this;
      var args = arguments;

      if (debounce.timeout) clearTimeout(debounce.timeout);
      if (immediate) {
        // 如果已经执行过，不再执行
        var callNow = !debounce.timeout;
        debounce.timeout = setTimeout(function () {
          debounce.timeout = null;
        }, wait)
        if (callNow) func.apply(context, args)
      }
      else {
        debounce.timeout = setTimeout(function () {
          func.apply(context, args)
        }, wait);
      }
    }
  }
  setCompanyTianyan=(obj)=>{
    console.log(obj)
    this.props.form.setFieldsValue({companyName:obj.name.replace(/<\/?em>/g,'')})
    this.setState({
      companyName:'',
      tianyanchaId:obj.id
    })
  }
  tianyan_search=()=>{
    var companyName =this.props.form.getFieldValue('companyName')
    console.log(this.props.form.getFieldError('companyName'))
    if(this.props.form.getFieldError('companyName')){
      companyName=''
    }
    console.log(companyName)
    this.setState({
      companyName
    })
    if(!companyName)return
    const config = {
      api: API.tyc_search,
      path: {
        word: companyName
      },
      showLoading: true
    };

    http(config).then(res => {
      if (res && res.success && res.data) {
        let data = res.data;
        this.setState({
          companyName,
          companyNameList:data
        })
      }
    });
  }
  toggleAgent=(flag)=>{
    this.setState({
      isAgent: flag
    })
  }

  showGradenInfo() {
    let myState = this.state;
    const { getFieldDecorator } = this.props.form;
    const config = {
      rules: [{ required: true, message: "请选择" }]
    };

    const { provinceEncode, cityEncode, regionEncode } = this.state;

    return (
      <div className="new_customer-add-body">
        <h6>园区信息</h6>

        <Row gutter={24}>
          <Col className="gutter-row" span={16}>
            <Form.Item label={"总部地址"} required>
              <Input.Group>
                <Row gutter={24}>
                  <Col span={8}>
                    {getFieldDecorator(
                      "addressEncode",
                    )(
                      <Cascader
                        style={{ width: "100%" }}
                        defaultValue={[provinceEncode, cityEncode, regionEncode]}
                        fieldNames={{
                          label: "name",
                          value: "code",
                          children: "subAreas"
                        }}
                        allowClear={false}
                        changeOnSelect
                        suffixIcon={<Icon type="caret-down" />}
                        placeholder={"省市区"}
                        options={this.state.region}
                        onChange={(value, selectedOptions) => {
                          this.onAddressRegionChange(value, selectedOptions);
                        }}
                      />
                    )}
                  </Col>
                  <Col span={12}>
                    {getFieldDecorator("address", {
                      rules: [
                        { max: 30, message: "详细地址不能超过30位" },
                        { required: true, message: "请输入总部地址" }
                      ]
                    })(
                      <Input
                        style={{ width: "100%" }}
                        maxLength={30}
                        placeholder={"详细地址"}
                      />
                    )}
                  </Col>
                </Row>
              </Input.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col className="gutter-row" span={8}>
            <Form.Item label="项目数量">
              {getFieldDecorator("projectNumber", {
                rules: [
                  {
                    pattern: /^[1-9][0-9]{0,10}$/,
                    message: "请输入10位以内的数字"
                  },
                  { required: true, message: "请输入项目数量" }
                ]
              })(
                <Input
                  style={{ width: "100%" }}
                  maxLength={10}
                  placeholder="请输入"
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col className="gutter-row" span={8}>
            <Form.Item label="账号类型">
              {getFieldDecorator(
                "acountType",
                config
              )(
                <Select2
                  style={{ width: "100%" }}
                  data={convertEnumToMap(AccountTypeMap)}
                  placeholder="请选择"
                  selectMethod={this.handleAccountTypeChange}
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        {/*   账号类型:正式  */}
        {this.state.accountType == AccountTypeFlag.FORMAL ? (
          <div>
            <Row gutter={24}>
              <Col className="gutter-row" span={8}>
                <Form.Item label="合同有效期">
                  {getFieldDecorator(
                    "contractDate",
                    {
                      initialValue: myState.contractStartDate,
                      rules: [{ required: true, message: "请选择" }]
                    }
                  )(
                    <DateRange
                      key={myState.contractStartDate ? myState.contractStartDate : '' + myState.contractEndDate ? myState.contractEndDate : ''}
                      onDateChange={this.contractDateChange}
                      startValue={myState.contractStartDate}
                      endValue={myState.contractEndDate}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col className="gutter-row" span={8}>
                <Form.Item label="签约时间">
                  {getFieldDecorator(
                    "signDate",
                    {
                      initialValue: myState.signDate,
                      rules: [{ required: true, message: "请选择" }]
                    }
                  )(
                    <DatePicker style={{ width: "100%" }} allowClear={false} onChange={this.onSignDateChange} />
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col className="gutter-row" span={24}>
                <Form.Item label="附件">
                  {getFieldDecorator(
                    "fileInfoList"
                  )(
                    <FileUploadBiz
                      fileUp={this.fileUp}
                      fileList={this.state.fileInfoList}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </div>
        ) : (
            ""
          )}

        {this.state.accountType == AccountTypeFlag.TRIAL ? (
          <div>
            <Row gutter={24}>
              <Col className="gutter-row" span={8}>
                <Form.Item label="试用期">
                  {getFieldDecorator(
                    "trialTimeRange",

                    {
                      initialValue: myState.trialStartDate,
                      rules: [{ required: true, message: "请选择" }]
                    }
                  )(
                    <DateRange
                      key={myState.trialStartDate ? myState.trialStartDate : '' + myState.trialEndDate ? myState.trialEndDate : ''}
                      onDateChange={this.trialDateChange}
                      startValue={myState.trialStartDate}
                      endValue={myState.trialEndDate}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </div>
        ) : (
            ""
          )}
      </div>
    );
  }

  render() {
    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      isFieldTouched
    } = this.props.form;

    let my = this;
    let myState = this.state;
    let { encode } = this.state;

    const isEdit = !!encode;
    var tianyancha = this.debounce(this.tianyan_search,1000)
    return (
      <div className="new_customer-list new_customer-content">
        <div className="new_customer-list-head flexbox">
          <Breadcrumb className="new_customer-list-head-bread">
            <Breadcrumb.Item>
              <Link
                style={{ color: "#333" }}
                to={{ pathname: "/app/crm/client" }}
              >
                客户管理
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{encode ? '编辑客户' : '新增客户'}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <Form onSubmit={this.handleSubmit}>
          <div className="new_customer-add-body">
            <h6>企业信息</h6>
            <Row gutter={24}>
              <Col className="gutter-row" span={8}>
                <Form.Item label="企业名称">
                  {getFieldDecorator("companyName", {
                    rules: [
                      { required: true, message: "请输入企业名称" },
                      {
                        pattern: businessLicense,
                        message:
                          '企业名称格式错误，支持汉字、字母、数字、空格、括号、"()"、"-"'
                      },
                      { max: 60, message: "最大长度60" }
                    ]
                  })(
                    <Input
                      style={{ width: "100%" }}
                      maxLength={60}
                      autoComplete="off"
                      placeholder="请按营业执照填写"
                      onChange={tianyancha}
                    />
                  )}
                </Form.Item>
                {
                  this.state.companyName?<div className="company-name-options-ul">
                  <ul>
                    <li onClick={()=>{this.setCompanyTianyan({name:this.state.companyName})}}>
                      <p className="default-name">{this.state.companyName}</p>
                    </li>
                    {
                      this.state.companyNameList&&this.state.companyNameList.map(res=>{
                        return(
                          <li onClick={()=>{this.setCompanyTianyan({name:res.name,id:res.id})}} key={res.id} className="company-name-options">
                            <p><span className="name" dangerouslySetInnerHTML={{__html:res.name}}></span> <span>地址：{res.base}</span></p>
                            <p className="p2"><span>法人：{res.legalPersonName}</span><span>成立日期{res.estiblishTime.replace(' 00:00:00.0','')}</span> <span>统一社会信用代码：{res.creditCode}</span></p>
                          </li>
                        )
                      })
                    }
                  </ul>
                </div>:null
                }
                
              </Col>
            </Row>
            <Row gutter={24}>
              <Col className="gutter-row" span={8}>
                <Form.Item label="企业简称">
                  {getFieldDecorator("shortName", {
                    rules: [
                      { required: true, message: "请输入企业简称" },
                      {
                        pattern: businessLicense,
                        message:
                          '企业简称格式错误，支持汉字、字母、数字、空格、括号、"()"、"-"'
                      },
                      { max: 10, message: "不能超过10字" }
                    ]
                  })(
                    <Input
                      style={{ width: "100%" }}
                      maxLength={10}
                      autoComplete="off"
                      placeholder="不超过10字"
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col className="gutter-row" span={8}>
                <Form.Item label="统一社会信用代码">
                  {getFieldDecorator("creditCode", {
                    rules: [
                      { required: true, message: "请输入统一社会信用代码" },
                      {
                        pattern: socialCreditCode,
                        message:
                          "统一社会信用代码支持字母、数字及短横杠,长度为15位或18位或20位"
                      }
                    ]
                  })(
                    <Input
                      style={{ width: "100%" }}
                      maxLength={20}
                      placeholder="请输入统一社会信用代码"
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col className="gutter-row" span={24}>
                <Form.Item label="营业执照" required>
                  {getFieldDecorator("businessLicensePic",{
                    rules: [{
                      validator(rule, value){
                        if (value) {
                          if (value.status === 'error') {
                            return Promise.reject('上传失败，请重新上传')
                          }
                          if (value.status === 'uploading') {
                            return Promise.reject('上传中，请稍后')
                          }
                          if (value.url) {
                            return Promise.resolve();
                          }
                        }
                        return Promise.reject('请上传营业执照');
                      }
                    }]
                  })(
                    <Upload
                    accept='.jpeg,.jpg,.png,.bmp'
                      maxSize="5M"
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col className="gutter-row" span={16}>
                <Form.Item label="企业简介">
                  {getFieldDecorator("enterpriseDesc", {
                    rules: [{ max: 100, message: "不得超过100字" }]
                  })(
                    <TextArea
                      placeholder="请输入"
                      maxLength={100}
                      autoSize={{ minRows: 4, maxRows: 4 }}
                      onChange={debounce(this.remarkChange, 1000)}
                    />
                  )}
                </Form.Item>
                <span style={{ float: "right" }}>
                  {this.state.remarkLength}/100
                </span>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col className="gutter-row" span={8}>
                <Form.Item label="联系人姓名">
                  {getFieldDecorator("contactName", {
                    rules: [{ max: 20, message: "最多20字" }]
                  })(
                    <Input
                      style={{ width: "100%" }}
                      maxLength={20}
                      placeholder="请输入"
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col className="gutter-row" span={8}>
                <Form.Item label="联系人手机号">
                  {getFieldDecorator("contactPhone", {
                    rules: [
                      { pattern: phone, message: "请输入正确的手机号" }
                    ]
                  })(
                    <Input
                      style={{ width: "100%" }}
                      maxLength={11}
                      placeholder="请输入"
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </div>

          <div className="new_customer-add-body">
            <h6>管理员账号信息</h6>
            <div>
              <Row gutter={24}>
                <Col className="gutter-row" span={8}>
                  <Form.Item label="姓名">
                    {getFieldDecorator("personalName", {
                      rules: [
                        { required: true, message: "请输入管理员姓名" },
                        {
                          pattern: personName,
                          message:
                            '姓名格式错误，支持 汉字、字母、数字、-、/"/"'
                        },
                        { max: 20, message: "不得超过20字" }
                      ]
                    })(
                      <Input
                        style={{ width: "100%" }}
                        maxLength={20}
                        placeholder="请输入"
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col className="gutter-row" span={8}>
                  <Form.Item label="身份证">
                    {getFieldDecorator("personalIdentity", {
                      rules: [
                        { required: true, message: "请输入" },
                        {
                          pattern: socialCreditCode,
                          message: "请输入正确的身份证号"
                        }
                      ]
                    })(
                      <Input
                        style={{ width: "100%" }}
                        maxLength={18}
                        placeholder="请输入"
                      // onBlur={this.handleCheck}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col className="gutter-row" span={8}>
                  <Form.Item label="手机号">
                    {getFieldDecorator("personalPhone", {
                      valuePropName: "value",
                      rules: [
                        { required: true, message: "请输入" },
                        { pattern: phone, message: "请输入正确的手机号" }
                      ]
                    })(
                      <Input
                        disabled={encode ? true : false}
                        style={{ width: "100%" }}
                        maxLength={11}
                        placeholder="请输入"
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col className="gutter-row" span={8}>
                  <Form.Item label="是否有企业邮箱">
                    {getFieldDecorator("havaEmail", {
                      valuePropName: "checked",
                      initialValue: true
                    })(
                      <Checkbox
                        onChange={e => {
                          this.onChanggeEmailCheckbox(e);
                        }}
                        style={{ marginLeft: "30px" }}
                      >
                        是否有企业邮箱
                      </Checkbox>
                    )}
                  </Form.Item>
                </Col>
              </Row>


              <Row gutter={24}>
                <Col className="gutter-row" span={8}>
                  <Form.Item label={myState.havaEmail ? "企业邮箱" : "个人邮箱"}>
                    {getFieldDecorator("personalEmail", {
                      valuePropName: "value",
                      rules: [
                        { required: true, message: "请输入" },
                        {
                          type: "email",
                          message: "请输入正确的邮箱地址"
                        }
                      ]
                    })(
                      <Input style={{ width: "100%" }} placeholder="请输入" />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </div>

          <div className="new_customer-add-body">
            <h6>企业身份</h6>

            <Row gutter={24}>
              <Col className="gutter-row" span={5}>
                <Checkbox checked={true} disabled style={{ marginLeft: "30px" }}>
                  采购方
                </Checkbox>
              </Col>

              {[/* 编辑状态下 ，不能修改客户的既有身份 */]}
              <Col className="gutter-row" span={5}>
                <Form.Item>
                  {getFieldDecorator("isSupplier", {
                    valuePropName: "checked"
                  })(
                    <Checkbox
                      disabled={myState.isSupplierDisabled}
                      onChange={e => {
                        my.switchIsSupplier(e);
                      }}
                      style={{ marginLeft: "30px" }}
                    >
                      供应方
                    </Checkbox>
                  )}
                </Form.Item>
              </Col>
              {/* 先隐藏 @林园
              <Col className="gutter-row" span={5}>
                <Form.Item>
                  {getFieldDecorator("isGarden", {
                    valuePropName: "checked"
                  })(
                    <Checkbox
                      disabled={myState.encode ? true : false}
                      onChange={e => {
                        my.switchIsGrader(e);
                      }}
                      style={{ marginLeft: "30px" }}
                    >
                      园区方
                    </Checkbox>
                  )}
                </Form.Item>
              </Col> */}
            </Row>
          </div>

          {myState.isShowSupplierForm ? <EditSupplierInfo completeCheckedDisabled={myState.completeCheckedDisabled} form={this.props.form} isAgent={myState.isAgent} toggleAgent={this.toggleAgent}/> : null}

          {myState.isShowGraderForm ? this.showGradenInfo() : ""}

          <div className="btn-group">
            <Button type="primary" htmlType="submit">
              提交
            </Button>
            <Button onClick={this.cancel} size="large">
              取消
            </Button>
          </div>
        </Form>

        <Modal
          title="账号创建成功"
          content=""
          visible={this.state.showConfirmFlag}
          footer={[
            <Button
              style={{ float: "none" }}
              type="primary"
              onClick={this.hideConfirm}
              size="large"
            >
              我知道了
            </Button>
          ]}
          closable={false}
          maskClosable={false}
          width={400}
          onOk={this.hideConfirm}
        >
          <p style={{ fontSize: "12px" }}>
            公司名：{this.state.enterpriseName}
          </p>
          <p style={{ fontSize: "12px" }}>
            管理员手机号：{this.state.adminPhone}，初始密码已通过短信发送,
            请通知员工尽快修改密码！
          </p>
        </Modal>
      </div>
    );
  }
}

const NewCustomerAddEditForm = Form.create({ name: "new_customer_add" })(
  NewAddEditCustomer
);

export default NewCustomerAddEditForm;
