import React, { Component } from 'react';
import { Breadcrumb, Tabs, Button, Form, Input, Radio, DatePicker, message } from 'antd';
import { Link } from "react-router-dom";
import './index.less';
import ImgPreview from 'CMPT/common/ImgPreview';
import UploadVoucher from './uploadVoucher';
import { API } from '@/http/api';
import http from '@/http';
import userUtil from "@/utils/user";
import moment from "moment";
import Table from "CMPT/common/Table";
const { RangePicker } = DatePicker;
const BreadcrumbItem = Breadcrumb.Item;
const { TabPane } = Tabs;

const { Search } = Input;

const RadioGroup = Radio.Group
class AddRecord extends Component {

    state = {
        searchValue: '',
        columns: [
            {
                title: "创建时间", // 0 表示待审核状态
                key: "createDate",
                align: "center",
                dataIndex: "createDate",
                render: (text, record) => {
                  return (
                    <span>{text ? moment(text).format("YYYY-MM-DD HH:mm") : "-"}</span>
                  );
                },
            },
            {
              title: "创建人",
              key: "accountName",
              align: "center",
              dataIndex: "accountName",
            },
            {
              title: "付款金额",
              key: "payPrice",
              align: "center",
              dataIndex: "payPrice",
            //   render: (text) => {
            //     return <span>{text}</span>;
            //   },
            },
            {
              title: "购买数",
              key: "serviceNumber",
              align: "center",
              dataIndex: "serviceNumber",
            },
            {
              title: "备注",
              key: "desc",
              align: "center",
              dataIndex: "desc",
              width: 120
            },
            {
              title: "付款凭证",
              key: "payPhotos",
              align: "center",
              dataIndex: "payPhotos",
                render: (text) => {
                return text ? <div className='img_show_btn' onClick={() => this.imgShow(text)}><span>
                    <i className='iconfont icon-a-tupian1'></i>
                    共{text.length}张
                </span></div> : '-';
              },
            },
            {
                title: "状态",
                key: "stateName",
                align: "center",
                dataIndex: "stateName",
            },
            {
                title: "操作",
                key: "code",
                align: "center",
                dataIndex: "code",
                render: (text, record) => {
                  return record.stateName && record.stateName == '账期待付款' ? 
                  <div className='oprate img_show_btn' 
                    onClick={() => {
                        this.setState({
                            payMoneyVoucherModal: true,
                            upVCode: record.code
                        })
                    }}>
                      <span>上传凭证</span>
                  </div> : '';
                },
            },
        ],
        params: {
            pageSize: 10,
            pageNo: 1,
            state: null,
            supplierId: 1
        },
        addRecordList: [],
        currentIndex: 0,
        payMoneyVoucherModal: false,
        upVCode: ''
    }

    componentDidMount() {
        this.auditList()
    }

    imgShow = (list) => {
        let imgList = list.map(item => {
            return item.url
        })
        this.setState({
            imgList,
            previewVisible: true
        })
    }
    closeImagePreview = () => {
        this.setState({
            previewVisible: false
        })
    }

    
    auditList = () => {
        let  {params} = this.state;
        const config = {
            api: API.valueAddServiceAuditList,
            showLoading: true,
            data: params
        };
        http(config).then(res => {
            if (res.code == 200) {
                res.data.total = Number(res.data.total)
                this.setState({
                    addRecordList: res.data
                })
            }
        })
    }

    rangeChange = (value, date) => {
        let params = this.state.params;
        params.startDate = date[0];
        params.endDate = date[1];
        this.setState({
            params,
            startDateMoment: value[0],
            endDateMoment: value[1]
        },() => {
            this.auditList();
        })
    }

    searchChange = (e) => {
        this.setState({
            searchValue: e.target.value
        })
    }

    onSearchOn = (e) => {
        let {params} = this.state;
        params.keyword = e;
        this.setState({
            params
        }, () => {
            this.auditList()
        })
    }

    changeTabs = (e) => {
        let {params} = this.state;
        params.state = e == 1 ? null : e;
        this.setState({
            params
        }, () => {
            this.auditList()
        })
    }

    resetSearch = () => {
        let {params} = this.state;
        params.keyword = '';
        params.startDate = '';
        params.endDate = '';
        this.setState({
            params,
            startDateMoment: undefined,
            endDateMoment: undefined,
            searchValue: ''
        }, () => {
            this.auditList()
        })
    }

    payMoneyVoucherModalOk = (payPhotos) => {
        console.log(payPhotos, 'payPhotos');
        let {upVCode} = this.state;
        const config = {
            api: API.valueAddServiceDepositUpload,
            showLoading: true,
            data: {
                code:upVCode,
                payPhotos
            }
        };
        http(config).then(res => {
            if (res.code == 200) {
                message.success('上传成功');
                this.setState({
                    payMoneyVoucherModal: false
                }) 
                this.auditList()
            }
        })
    }

    payMoneyVoucherModalCancel = () => {
        this.setState({
            payMoneyVoucherModal: false
        }) 
    }

    //分页
  onChangePage = (pageNo, pageSize, sort) => {
    this.setState(
      {
        params: {
          ...this.state.params,
          pageNo: pageNo,
          pageSize: pageSize,
        },
      },
      () => {
        this.auditList();
      }
    );
  };
    


    render() {
        const {searchValue, params, addRecordList, columns, previewVisible, currentIndex, imgList, startDateMoment, endDateMoment, payMoneyVoucherModal } = this.state;
        const { getFieldDecorator } = this.props.form;
        return (

            <div className="enterprise-auth-con">
                <div className='enterprise-auth-header'>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link
                                style={{ color: "#333" }}
                                to={{
                                    pathname: "/app/crm/valueaddService/trajectoryFind",
                                }}
                            >
                                数据概览
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>添加记录</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className='tab_box'>
                    <Tabs defaultActiveKey="1" className='tabs' style={{margin: 0}} onChange={this.changeTabs} >
                        <TabPane tab="全部"  key="1" className='tab'>
                        </TabPane>
                        <TabPane tab="成功"  key="2" className='tab'>
                        </TabPane>
                        <TabPane tab="账期待付款"  key="4" className='tab'>
                        </TabPane>
                    </Tabs>

                    <div className='table_box'>
                        <div className="goods_fliter">
                            <RangePicker  value={[startDateMoment,endDateMoment]} onChange={this.rangeChange} />
                            <Search
                                placeholder="请输入姓名/手机号码"
                                enterButton="搜索"
                                className="goods_search"
                                value={searchValue}
                                // size="large"
                                onChange={value => {this.searchChange(value)}}
                                onSearch={value => {this.onSearchOn(value)}}
                            />
                            <span className="goods_reset" onClick={this.resetSearch}>重置</span>
                        </div>
                        <Table
                            columns={columns}
                            rowKey={(record, index) => record.code}
                            selfProcessData={addRecordList && addRecordList.datas}
                            total={addRecordList && addRecordList.total}
                            change={this.onChangePage}
                            current={addRecordList && addRecordList.pageNo}
                            />
                    </div>
                </div>
                <UploadVoucher
                    payMoneyVoucherModal={payMoneyVoucherModal} 
                    payMoneyVoucherModalOk = {this.payMoneyVoucherModalOk}
                    payMoneyVoucherModalCancel = {this.payMoneyVoucherModalCancel}
                />
                <ImgPreview show={previewVisible} current={currentIndex} imgList={imgList} onClose={this.closeImagePreview}/>
            </div>

        );
    }
}

const AddTRecord = Form.create({ name: "AddRecord" })(AddRecord);
export default AddTRecord;