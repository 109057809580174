import React, { Component } from 'react';
import { Modal, Form, message } from 'antd';
import ChooseProject from './ChooseProject'
import http from '@/http';
import { API } from '@/http/api';
import { Select } from 'antd';
const FormItem = Form.Item;

const { Option } = Select;
class CopyTemplate extends Component {
    state = { visible: false, projectIds: [], initialValue: [] }
    componentWillReceiveProps(nextProps) {
        console.log(nextProps)
        this.setState({
            visible: nextProps.visible,
            projectList: nextProps.projectList&&nextProps.projectList.map(item => { return { value: item.value, label: item.name } }),

        })
    }
    // 获取适用范围
    getProjectList = (list) => {
        console.log(list)
        if(!Array.isArray(list)){
            list = [list]
        }
        this.setState({
            projectId : list
        })

    }

    handleOk = (e) => {
        this.props.form.validateFields((err, value) => {
            // 选择项目后
            if (!err) {
                if(!Array.isArray(value.projectId)){
                    value.projectId = [value.projectId]
                }
                http({
                    api: API.templateCopy,
                    path: {}, // 路径参数
                    data: {
                        encode: this.props.templateId,
                        projectIds: value.projectId
                    },
                    showLoading: true // 是否显示全局加载
                }).then(res => {
                    if (res.success) {
                        this.props.form.setFieldsValue({ 'projectId': [] })
                        this.setState({
                            visible: false,
                            initialValue: []
                        });
                        this.props.reload(true)
                        message.success('模板已复制到对应的项目中');

                    }
                })
            }
        })

    }
    chackProject=(rule, value, callback)=>{
        const {projectId} = this.props.form.getFieldsValue()
        console.log(projectId)
        if(projectId.length==0){
            callback('请选择适用范围')
        }else if(projectId.length>1){
            callback('只能选一个项目')
        }else {
            callback()
        }
    }
    handleCancel = (e) => {
        this.props.reload()
        this.setState({
            visible: false,
            initialValue: []
        });
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        var node = null
        const options = this.state.projectList&&this.state.projectList.map(res=>{            
            return <Option key={res.value} value={res.value}>{res.label}</Option>
        })
        return (
            <div className="copy-template">
                <Modal
                    closable={false}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    okText='确定'
                    width={400}
                    onCancel={this.handleCancel}
                >
                    <div className="copy-title" style={{marginBottom:10}}>复制模版至</div>

                    <Form>
                        <Form.Item >
                            {getFieldDecorator('projectId', {
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择适用范围',
                                        // validator:this.chackProject
                                    },
                                ],
                                initialValue:Array.isArray(this.state.projectId)? this.state.projectId[0]: this.state.projectId
                                    })(
                                        <Select style={{ width: 240 }} onChange={this.getProjectList}>
                                            {options}
                                        </Select>
                                // <ChooseProject multiple={false} checkedList={this.state.initialValue} projectList={this.state.projectList} getProjectList={this.getProjectList} />
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        );
    }

}

export default Form.create({ name: 'CopyTemplate' })(CopyTemplate)