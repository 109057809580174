import {
    AJAX_TYPE
} from '../config';


const prefix = 'reportform';

export default {
    //项目报表
    [`${prefix}Project`]: {
        url: '/web/statement/project/get',
        type: AJAX_TYPE.POST
    },
    //项目报表导出
    [`${prefix}ProjectExport`]: {
        url: '/web/statement/project/export',
        type: AJAX_TYPE.POST
    },
    //区域报表
    [`${prefix}Regional`]: {
        url: '/web/statement/zone/get',
        type: AJAX_TYPE.POST
    },
    //区域报表导出
    [`${prefix}RegionalExport`]: {
        url: '/web/statement/zone/export',
        type: AJAX_TYPE.POST
    },
    //区域报表初始化
    [`${prefix}RegionalInit`]: {
        url: '/web/statement/zone/getZoneStatementInitData',
        type: AJAX_TYPE.GET
    },
     //合同报表
     [`${prefix}Contract`]: {
        url: '/web/statement/contract/contractReport',
        type: AJAX_TYPE.POST
    },
     //合同报表导出
     [`${prefix}ContractExport`]: {
        url: '/web/statement/contract/contractReportExport',
        type: AJAX_TYPE.POST
    },
      //收支报表
      [`${prefix}IncomeExpense`]: {
        url: '/web/bill/report',
        type: AJAX_TYPE.POST
    },     
    // 收支报表导出excel
    [`${prefix}IncomeExpenseExportReport`]: {
        url: '/web/bill/exportReport',
        type: AJAX_TYPE.POST
    },
    //欠费明细报表
    [`${prefix}ArrearsDetailedReport`]: {
        url: '/web/statement/bill/arrearsBillReport',
        type: AJAX_TYPE.POST
    },
    //欠费明细报表导出excel
    [`${prefix}ArrearsDetailedReportExportReport`]: {
        url: '/web/statement/bill/arrearsBillReportExport',
        type: AJAX_TYPE.POST
    },
    //资源台账报表
    [`${prefix}Resource`]: {
        url: '/web/statement/recource/get',
        type: AJAX_TYPE.POST
    },
    //资源台账报表导出excel
    [`${prefix}ResourceExportReport`]: {
        url: '/web/statement/recource/export',
        type: AJAX_TYPE.POST
    },     
}