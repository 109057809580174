import React, { Component } from "react";

import http from '@/http';
import { API } from '@/http/api';
import { Modal, Input, message, Checkbox, Tooltip, Button } from "antd";
import UserLabelGroup from "./UserLabelGroup";

class UseLabelManagerModal extends Modal {
    state = {
        labelArray: undefined
    };



    componentDidMount() {
        this.queryLabel();
    };

    queryLabel = () => {
        // setup 2: 构造接口入参
        const config = {
            api: API.userManagerMyLabel,
            path: { userCode: this.props.userCode },
            showLoading: true
        };

        http(config).then(res => {
            if (res && res.success && res.data && Array.isArray(res.data)) {
                let labelArray = [];
                res.data.map((item) => {
                    labelArray.push(item.labelCode);
                });
                this.setState({ labelArray });
            }
        });
    }

    onLabelSelectChange = (checkedValue) => {
        this.setState({ labelArray:checkedValue });
    }


    doSubmit = () => {
        // 参数
        const config = {
            api: API.labelBatchSet,
            data: { labelList: this.state.labelArray, userList: [this.props.userCode] },
            showLoading: true
        };

        // 网络请求
        http(config).then(res => {
            if (res && res.success) {
                // 关闭自身
                if (this.props.onCancel) {
                    this.props.onCancel();
                }

                // 刷新
                if (this.props.onRefresh) {
                    this.props.onRefresh();
                }
            }
        });


    }

    render() {
        let { labelArray } = this.state;
        let {userName,userPhone} = this.props;

        return <Modal
            {...this.props}
            visible={true}
            title='标签管理'
            onOk={this.doSubmit.bind(this)}
        >
            <div>
                <div style={{ marginBottom: "20px" }}>
                    <span style={{ fontSize: '16px' }}> { (userName?userName:'') +  (userPhone? (userName?"(":'') + userPhone + (userName?")":''):'')}</span>
                    <span style={{ marginLeft: "20px", fontSize: '10px' }}>勾选表示打标签</span>
                </div>

                {labelArray ? <UserLabelGroup
                    defaultValue={labelArray}
                    onLabelSelectChange={this.onLabelSelectChange.bind(this)} /> : ''}
            </div>
        </Modal>
    }

}


export default UseLabelManagerModal;