import React, { Component } from 'react';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/pie';
import 'echarts/lib/component/tooltip';
import img from '@/images/placeholder_chart@2x.png'

export default class Single extends Component{
    state={
        hideCanvas:false
    }
    componentWillReceiveProps(nextProps) {
        console.log(nextProps.options.data)
        const { text, lineColor, data, pieRadius } = nextProps.options
        
        var result = data.some(res=>{
            if(res.value>0){
                return true
            }
        })
        console.log(result)
        if(!result){
            this.setState({
                hideCanvas:true,
            })
        }else{
            this.setState({
                hideCanvas:false
            },()=>{
                if(document.getElementById(this.props.options.id)){
                    const myPieChart = echarts.init(document.getElementById(this.props.options.id));
                    const pieOptions = {
                        tooltip : {
                            trigger: 'item',
                            formatter: "{b} : {c}",
                            backgroundColor: 'rgba(23,25,36,0.8)',
                            padding: [5,12],
                            textStyle: {
                                fontSize: 12
                            }
                        },
                        calculable : true,
                        series : [
                            {
                                name: text,
                                type:'pie',
                                radius : [60,100],
                                center: ['50%', '50%'],
                                data: data,
                                label: {
                                    normal: {
                                        textStyle: {
                                            color: lineColor
                                        }
                                    }
                                }
                            }
                        ]
                    }
                    myPieChart.setOption(pieOptions)
                } 
            })
        }
    }
    render() {
        return (
            <div className="echart-bak" >
                <div className="chart-title">
                    <span></span>
                    {this.props.options.title}
                </div>
                {
                    this.state.hideCanvas?<div style={{ width: this.props.options.width, height: this.props.options.height ,textAlign:'center',paddingTop:'66px'}}>
                        <img src={img} width="104" height="88" alt=""/>
                        <p>暂无数据</p>
                    </div>:<div key={new Date().getTime()} id={this.props.options.id} style={{ width: this.props.options.width, height: this.props.options.height }}></div>
                }
            </div>
        )
    }   
}
