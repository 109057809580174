import React, { Component } from 'react';
import { Upload, message, Icon, Tooltip, Row, Col } from 'antd';
import {
    API
} from '@/http/api';
import axios from 'axios';
import wordSrc from '@/images/files_word@2x.png';
import pdfSrc from '@/images/files_pdf@2x.png';
import excelSrc from '@/images/files_excel@2x.png';
import pptSrc from '@/images/files_ppt@2x.png';
import txtSrc from '@/images/files_txt@2x.png';
import unknowSrc from '@/images/files_unknown@2x.png';
import { formRequest } from '@/http';
import { spawn } from 'child_process';

//由于附件需要上传的文件比较多，特地封装一下，无特殊需求的 antd的upload应该可以满足需求
const fileTypeArray = ['mp4', 'flv', 'f4v', 'webm', 'm4v', 'mov', '3gp', '3g2', 'rm', 'rmvb', 'wmv', 'avi', 'asf', 'mpg', 'mpeg', 'mpe', 'ts', 'div', 'dv', 'divx', 'vob', 'dat', 'mkv', 'swf', 'lavf', 'cpk', 'dirac', 'ram', 'qt', 'fli', 'flc', 'mod']
const hasPicFile = ['doc', 'docx', 'xlsx']
class ImageUploadBiz extends Component {

    state = {
        fileList: [],
        imgInfo: null,
        loading: false,
        imageUrl: null,
        fileName: null,
        vedioUrl:null,
    }


    handleChange = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
    };

    beforeUpload = (file) => {
        const fileNameToArray = file.name.split('.');
        const fileType = fileNameToArray[fileNameToArray.length - 1];
        const isFile = !!fileTypeArray.find(item => item == fileType)
        if (!isFile) {
            message.error('只支持' + fileTypeArray.join() + '文件');
        }
        const isLt5M = file.size / 1024 / 1024 <= 5000;
        if (!isLt5M) {
            message.error('文件大小不能超过5G');
        }
        return isFile && isLt5M;
    }

    doUploadFile(value) {
        value.action = API.uploadAdd.url;
        const file = value.file;
        const fileNameToArray = file.name.split('.');
        const fileType = fileNameToArray[fileNameToArray.length - 1];
        console.log(value)
        formRequest(value).then(res => {
            if (res.code == 0) {
                let data = res.data;
                if (!data) {
                    message.error('上传失败，请重试')
                    return;
                }
                let fileList = [];
                let fileInfo = {
                    mediaExt: fileType,
                    mediaKey: data.key,
                    name: data.fileName,
                    vedioUrl:URL.createObjectURL(file),
                    type: fileType,
                    url: data.publicDownloadUrl
                }
                fileList = [fileInfo];
                console.log(fileType);
                console.log(fileType == 'mp4' || 'png' ? data.publicDownloadUrl : unknowSrc)
                this.setState({
                    fileList: fileList,
                    imgInfo: fileInfo,
                    imageUrl: data.publicDownloadUrl ,
                    vedioUrl:URL.createObjectURL(file),
                    loading: false,
                    fileName: data.fileName
                }, () => {
                    setTimeout(() => {
                        this.getVideoImg()
                    }, 5000);
                })
            }
        })
    }

    doUploadImg(value,fileList){
        value.action = API.uploadAdd.url;
        const file = value.file;
        const fileNameToArray = file.name.split('.');
        const fileType = fileNameToArray[fileNameToArray.length - 1];
        console.log(value)
        formRequest(value).then(res => {
            if (res.code == 0) {
                fileList.videoPicUrl = res.data.publicDownloadUrl;
                this.setState({fileList:[fileList]},()=>{
                    this.props.fileUp(this.state.fileList)
                })
            }
        })
    }

    base64ByBlob = (base64, callback) => {
        var arr = base64.split(','), mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        console.log(new Blob([u8arr], { type: mime }))
        callback(new Blob([u8arr], { type: mime }))
      }

      fileByBase64 = (file, callback) => {
        var reader = new FileReader();
        // 传入一个参数对象即可得到基于该参数对象的文本内容
        reader.readAsDataURL(file);
        reader.onload = function (e) {
          // target.result 该属性表示目标对象的DataURL
          console.log(e.target.result);
          callback(e.target.result)
        };
      }

      dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(',');
        var mime = arr[0].match(/:(.*?);/)[1];
        var bstr = atob(arr[1]);
        var n = bstr.length; 
        var u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        //转换成file对象
        return new File([u8arr], filename, {type:mime});
        //转换成成blob对象
        //return new Blob([u8arr],{type:mime});
      }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.fileList || nextProps.fileList.length == 0) {
            return;
        }
        // const fileType = nextProps.fileList.length && nextProps.fileList[0].mediaExt;
        console.log('componentWillReceiveProps----------------------------------')
        this.setState({
            imageUrl: nextProps.fileList[0].url,
            vedioUrl: nextProps.fileList[0].vedioUrl||nextProps.fileList[0].url,
            fileName: nextProps.fileList[0].name
        })
    }
    getVideoImg = () => {
        const video = document.getElementById('videoPreview');
        if(video&&video.duration){
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                const imgHeight = video.videoHeight;
                const imgWidth = video.videoWidth;
                ctx.drawImage(video, 0, 0, imgWidth, imgHeight);
                const imgSrc = canvas.toDataURL('image/png');
                // this.base64ByBlob(imgSrc, (blob => {
                //       console.log(blob, 'blob')
                //       var url = window.URL.createObjectURL(blob)
                //       console.log(url, 'url')
                //     })
                // )
                var myFile = this.dataURLtoFile(imgSrc,'testimgtestimgtestimg');
                let _fileInfo = this.state.fileList[0]
                let duration = ('000' + ~~(video.duration/60)).substr(-2,2) + ':' +  ('0000'+~~( video.duration%60)).substr(-2,2)
                _fileInfo.duration = duration;
                // _fileInfo.videoPicUrl = imgSrc;
                this.doUploadImg({file:myFile},_fileInfo)
                // this.setState({fileList:[_fileInfo]},()=>{
                //     this.props.fileUp(this.state.fileList)
                // })
                // console.log(imgSrc, 'img');
        }
    }
    render() {
        const uploadButton = (
            <div>
                {!this.state.loading ? <span style={{ fontSize: '24px' }} className='icon iconfont iconupload'></span> : <Icon style={{ fontSize: '42px' }} type='loading' />}
                <div className="ant-upload-text" style={{ margin: 'auto', width: '76%' }}>
                    拖拽至此 或 点击上传
              </div>
            </div>
        );
        const imageUrl = this.state.imageUrl;
        // const fileName = this.state.fileName;
        // const fileNameToArray = fileName.split('.');
        // const fileType = fileNameToArray[fileNameToArray.length - 1];
        return (
            <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={this.beforeUpload}
                action={API.uploadAdd.url}
                // action='http://oms.st.idf10.com.cn/web/distribute_file/upload'
                customRequest={this.doUploadFile.bind(this)}
                showUploadList={false}
                onChange={this.handleChange}
            >
                {imageUrl ? <video id="videoPreview" crossOrigin="anonymous" width='300' src={this.state.vedioUrl} alt="avatar" controls='controls'></video> : uploadButton} <br />
                {/* {imageUrl ? <img style={{width:'48px',height:'48px'}} src={imageUrl} alt="avatar" /> : uploadButton} <br/> */}
                {/* {fileName ? <p style={{ width: '100px', height: '18px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}> {fileName}</p> : null} */}
            </Upload>
        )
    }
}

export default ImageUploadBiz
