import { AJAX_TYPE } from "../config";

// 公告

const prefix = 'Announcement';

export default {
    // 新增公告
    [`${prefix}Insert`]: {
        url: '/web/crm/affiche/insert',
        type: AJAX_TYPE.POST
    },

    // 修改公告
    [`${prefix}Edit`]: {
        url: '/web/crm/affiche/edit',
        type: AJAX_TYPE.POST
    },

    // 删除公告
    [`${prefix}Remove`]: {
        url: '/web/crm/affiche/remove/:id',
        type: AJAX_TYPE.POST
    },
    
    // 获取公告分页接口
    [`${prefix}QueryPage`]: {
        url: '/web/crm/affiche/queryPage',
        type: AJAX_TYPE.POST
    },

    // 获取公告详情
    [`${prefix}Info`]: {
        url: '/web/crm/affiche/info/:id',
        type: AJAX_TYPE.GET
    },

    // 获取公告类型列表
    [`${prefix}Typelist`]: {
        url: '/web/crm/afficheType/list',
        type: AJAX_TYPE.POST
    },

    // 获取公告类型分页接口
    [`${prefix}TypeQueryPage`]: {
        url: '/web/crm/afficheType/queryPage',
        type: AJAX_TYPE.POST
    },

    // 删除公告类型
    [`${prefix}TypeRemove`]: {
        url: '/web/crm/afficheType/remove/:id',
        type: AJAX_TYPE.POST
    },

    // 新增公告类型
    [`${prefix}TypeInsert`]: {
        url: '/web/crm/afficheType/insert',
        type: AJAX_TYPE.POST
    },

    // 编辑公告类型
    [`${prefix}TypeEdit`]: {
        url: '/web/crm/afficheType/edit',
        type: AJAX_TYPE.POST
    },
}