import React, { Component } from "react";
import { Modal, Form, Input, Radio } from "antd";
import ChannelCheckboxGroup from "./ChannelCheckboxGroup";
import { API } from "@/http/api";
import http from "@/http";


class CatalogAdd extends Component {

  state = {
    initValue: ''
  };
  //TODO  getAdd 和 edit 两个方法部分逻辑可以合并 20200414
  getAdd(values) {
    const config = {
      api: API.helpCatalogAdd,
      showLoading: true,
      data: {
        catalogName: values.catalogName,
        catalogType: this.props.catalogType,
        channel: values.channel
      }
    };
    http(config).then(res => {
      if (res.success) {
        this.handleCancel();
        this.props.refresh();
      }
    });
  }
  edit(values) {
    const config = {
      api: API.helpCatalogEdit,
      showLoading: true,
      data: {
        encode: this.props.editId,
        catalogName: values.catalogName,
        channel: values.channel
      }
    };
    http(config).then(res => {
      if (res.success) {
        this.handleCancel();
        this.props.refresh();
      }
    });
  }
  handleOk = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (this.props.title) {
          this.edit(values);
        } else {
          this.getAdd(values);
        }
      }
    });
  };
  componentWillMount() {
    if (this.props.title) {
      this.props.form.setFieldsValue({ catalogName: this.props.title });
    }


    if (this.props.channel) {
      this.props.form.setFieldsValue({ channel: this.props.channel });
      this.setState({
        initValue: this.props.channel
      });
    }
  }
  handleCancel = () => {
    this.props.assignCancel();
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    let my = this;
    const { initValue } = this.state;

    return (
      <Modal
        title={this.props.title ? "编辑目录" : "添加目录"}
        visible={true}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        className="assign-follow-modal"
      >
        <Form className="login-form">
          <Form.Item label="目录名称">
            {getFieldDecorator("catalogName", {
              initialValue: my.props.title,
              rules: [
                { required: true, message: "请输入目录名称" },
                { max: 8, message: "目录名称不能超过8位" }
              ]
            })(
              <Input
                className="login-input"
                placeholder="请输入(最长8位)"
                maxLength={8}
              />
            )}
          </Form.Item>

          <Form.Item label="渠道">
            {getFieldDecorator("channel", {
              initialValue: initValue,
              rules: [{ required: true, message: "请设置渠道信息" }]
            })(
              <ChannelCheckboxGroup key={initValue} initialValue={initValue} />
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default Form.create({ name: "CatalogAdd" })(CatalogAdd);
