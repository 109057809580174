import { AJAX_TYPE } from "../config";

const prefix = "enterprise";

export default {
  // 分页
  [`${prefix}List`]: {
    url: "/web/crm/enterprise/newlist",
    type: AJAX_TYPE.POST,
  },
  [`${prefix}AllList`]: {
    url: "/api/isx/v1/basis/supplier/getSupplierAndLabelList",
    type: AJAX_TYPE.POST,
  },
  // 获取企业下组织
  [`${prefix}GetOrgTree`]: {
    url: "/api/isx/v1/admin/company/getOrgTree",
    type: AJAX_TYPE.POST,
  },
  // 获取企业下人员
  [`${prefix}GetAdminUserList`]: {
    url: "/api/isx/v1/admin/company/getAdminUserList",
    type: AJAX_TYPE.POST,
  },
  [`${prefix}SetStatus`]: {
    url: "/web/crm/enterprise/updateStatus",
    type: AJAX_TYPE.POST,
  },

  [`${prefix}Export`]: {
    url: '/web/crm/enterprise/exportqueryList',
    type: AJAX_TYPE.POST
  },
  //获取企业是否为自营
  // [`${prefix}SupportStatus`]: {
  //   url: '/web/crm/enterprise/updateSupportStatus',
  //   type: AJAX_TYPE.POST
  // },
  
};
