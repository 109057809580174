import React,{Component} from 'react'
import { Icon } from 'antd';
class stepZ extends Component{
    state = {
        list:[]
    }
    componentWillReceiveProps(nextProps){       
        const list = nextProps.list && nextProps.list.map( res=> {
            return  {
                current: res.current,
                code: res.contractEnCode,
                title:{
                    time:res.refundRentTime,
                    des:'退租时间'
                },
                content:[
                    {key:'租客名称',value: res.customerName},
                    {key:'合同编号',value: res.contractCode},
                    {key:'退租原因',value: res.remark},
                    {key:'合同周期',value:`${res.startTime}至${res.endTime}`}
                ]
            }
        })
        this.setState({
            list: list || []
        })
    }
    //跳转详情
    goDetail(id) {
        this.props.history.push(`/app/operate/contract/detail/${id}`)
    }
    render(){   
        var list = (this.state.list).map(res=>{
            return (
                <div key={res.title.time} className="list-item">
                    <div className="list-left">
                        <div className="list-left-des">{res.title.des}</div>
                        <div className={ res.current ? 'list-left-time current': 'list-left-time'} >{res.title.time}</div>                       
                    </div>
                    <div className="list-first" style={{display: res.current ? 'block': 'none'}}>当前合同</div>
                    <div className="list-right" style={{display: res.current ? 'none': 'block'}} onClick={this.goDetail.bind(this, res.code)}>                     
                        {
                            res.content.map(item=>{
                                return (
                                    <div key={item.key} className="list-right-content">
                                        {item.value && <div>{item.key}: {item.value}</div>}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            )
        })    
        return (
            <div className="step-z">
                {list}
            </div>
        )
    }
}

export default stepZ