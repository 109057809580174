import {
    API
} from '@/http/api';
import http from '@/http';

function intentionInit() {
    let config = {
        api: API.intentionInit,
        loading:true
    }
    return http(config)
}

function intentionList(data) {
    let config = {
        api: API.intentionList,
        data: data
    }
    return http(config)
}

function getProjectDetailInfoByName(data) {
    let config = {
        api: API.ProjectsgetProjectDetailInfoByName,
        data: data
    }
    return http(config)
}

function intentionAdd(data) {
    let config = {
        api:API.intentionAdd,
        showLoading:true,
        data:data
    }
    return http(config)
}

function intentionDispatch(data){
    let  config = {
        api: API.intentionDispatch,
        showLoading:true,
        data:data
    }  
    
    return http(config)    
}

function intentionDispatchBatch(data){
    let  config = {
        api: API.intentionDispatchBatch,
        showLoading:true,
        data:data
    }  
    
    return http(config)    
}

function intentionFollowup(data){
    let  config = {
        api: API.intentionAddFollowup,
        showLoading:true,
        data:data
    }  
    
    return http(config)    
}

function intentionGet(data){
    let config = {
        api:API.intentionGet,
        showLoading:true,
        path:data        
    }

    return http(config);
}

function intentionEdit(data){
    let  config = {
        api: API.intentionEdit,
        showLoading:true,
        data:data
    }  
    
    return http(config)
}

function intentionStatusCount(){
    let  config = {
        api: API.intentionStatusCount,
        showLoading:true,
        data:{}
    }  
    
    return http(config)   
}

function intentionGetAddUserList(value) {
    let  config = {
        api: API.intentionGetAddUserList,
        data:{
            nameLike:null
        }
    }  
    
    return http(config).then(res => {
        return res.data&&res.data.datas;
    })    
}

function intentionGetEditUserList(value) {
    let  config = {
        api: API.intentionGetEditUserList,
        data:{
            nameLike:null
        }
    }  
    
    return http(config).then(res => {
        return res.data&&res.data.datas;
    })    
}

function intentionGetDispatchBatchUserList(value) {
    let  config = {
        api: API.intentionGetDispatchBatchUserList,
        data:{
            nameLike:null
        }
    }  
    
    return http(config).then(res => {
        return res.data&&res.data.datas;
    })    
}

function intentionGetDispatchUserList(value) {
    let  config = {
        api: API.intentionGetDispatchUserList,
        data:{
            nameLike:null
        }
    }  
    
    return http(config).then(res => {
        return res.data&&res.data.datas;
    })    
}


export {
    intentionInit,
    intentionList,
    getProjectDetailInfoByName,
    intentionAdd,
    intentionDispatch,
    intentionDispatchBatch,
    intentionFollowup,
    intentionGet,
    intentionEdit,
    intentionStatusCount,
    intentionGetEditUserList,
    intentionGetDispatchBatchUserList,
    intentionGetDispatchUserList,
    intentionGetAddUserList
}