/*
* @param breadlist 面包屑需要数据 格式如 [{name:'维修服务',route:this.props.location.pathname}]
* @param buttonobj 是否有右边的按钮或其它内容，如果有请传react-dom
* */

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import './breadcrumb.less'

function BreadcrumbHeader(props) {
    const { breadlist, buttonobj } = props;
    const breaditem = breadlist.map((item,index) => {
        return <Breadcrumb.Item key={index}>{ item.route? (<Link to={item.route} replace>{item.name}</Link>) : item.name }</Breadcrumb.Item>
    })

    return (
        <div className="logisticsheader">
            <Breadcrumb className="logisticsheader-left">
                {breaditem}
            </Breadcrumb>

            <div className='logisticsheader-right'>
                {buttonobj}
            </div>
        </div>
    )
}

export default withRouter(BreadcrumbHeader)
