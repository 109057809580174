import { API } from '@/http/api';
import http from '@/http';
import { showErrorMessage, } from '@/utils/globalMessageReminder';
import { formatLinkSearch } from '@/utils';
import React, { Component } from 'react';
import { Form, Select, Layout, Breadcrumb, Button, Row, Col, Tabs, Upload, Modal, Input, message } from 'antd';
import { Link } from 'react-router-dom';
import ImgPreview from 'CMPT/common/ImgPreview';
import { formRequest, ajax } from '@/http';
import './ContractManagementDetail.less';
import { signType as signTypeEnum, statusType } from "./enum";
import pdf from '@/images/files_pdf@2x.png'
import empty from '@/images/nodata.png'
const { Option } = Select;
const { TextArea } = Input;
const { Content } = Layout;
const fileTypeArray = ['image/jpeg', 'jpg', 'png'];

class ContractManagementDetail extends Component {
    state = {
        data: {},
        code: null, // 当前页面的ID,从前一个页面传递过来的
        pageNo: null, // 当前企业在前一个列表中的页码
        currentageCount: null, // 前一个页面中 列表内当前页的数量

        pictures: [],
        imgPreviewVisible: false,
        picturesHeiMingDan: [],
        picturesGuanLian: [],
        contractUpload: [],
        //数通盖章进度
        ckSchedule: null,
        // 联系地址
        contactAddress: null,
        // 合同Id
        contractId: null,
        // 合同名称
        contractName: null,
        // 合同编号
        contractNo: null,
        // 合同链接
        contractUrl: null,
        createUserId: null,
        // 统一社会信用代码
        creditCode: null,
        // 企业id
        enterpriseId: null,
        // 企业名称
        enterpriseName: null,
        // 有效期结束时间
        expiryEndDate: null,
        // 有效期开始时间
        expiryStartDate: null,
        // 法人
        ownerName: null,
        // 注册地址
        registerAddress: null,
        // 签约时间
        signDate: null,
        // 签约方式
        signType: null,
        // 合同状态
        status: null,
        // 供应商盖章进度
        supplierSchedule: null,
        // 意见说明
        remark: null,
        // 审批意见 1同意 ，2 驳回
        auditStatus: null,

        // 合同文件
        contractList: [],
        // 黑名单审核
        blackList: [],
        // 关联交易审核
        tradeList: [],

        // 取消签约 弹框
        cancelSignVisible: false,

        // 上传合同 弹框
        contractSignVisible: false,

        // 签约记录
        history: [],
    }

    componentWillMount() {
        if (this.props.location.search) {
            // 获取参数
            const search = this.props.location.search
            const formatData = formatLinkSearch(search)

            // 解析
            let code1 = this.props.match.params.code;
            let pageNo1 = null;
            let currentageCount1 = null;

            if (formatData && formatData.pageNo) {
                pageNo1 = formatData.pageNo
            }
            if (formatData && formatData.currentPageCount) {
                currentageCount1 = formatData.currentPageCount
            }

            // 赋值
            this.setState({
                code: code1,
                pageNo: pageNo1,

                currentageCount: currentageCount1,
            });
        }
    }

    componentDidMount() {
        this.getDetail();
        
    }

    beforeUpload = (file) => {
        const fileNameToArray = file.name.split('.');
        const fileType = fileNameToArray[fileNameToArray.length - 1].toLowerCase();
        const isFile = !!fileTypeArray.find((item) => item == fileType);
        if (!isFile) {
            message.error('只支持' + fileTypeArray.join() + '文件');
        }
        const isLt5M = file.size / 1024 <= 500;
        if (!isLt5M) {
            message.error('文件大小不能超过500kb');
        }
        return isFile && isLt5M;
    }
    beforeUploadContract = (file) => {
        const fileNameToArray = file.name.split('.');
        const fileType = fileNameToArray[fileNameToArray.length - 1].toLowerCase();
        const isFile = fileType == 'pdf'
        if (!isFile) {
            message.error('只支持pdf文件');
            return false
        }
        const isLt5M = file.size / 1024 / 1024 <= 20;
        if (!isLt5M) {
            message.error('文件大小不能超过20M');
            return false
        }
        return isFile && isLt5M;
    }
    doUploadFileHeiMingDan = (value) => {
        this.doUploadFile(value, 'picturesHeiMingDan')
    }
    doUploadFileGuanLian = (value) => {
        this.doUploadFile(value, 'picturesGuanLian')
    }
    doUploadFileContract = (value) => {
        this.doUploadFile(value, 'contractUpload')
    }
    doUploadFile = (value, pictures) => {
        var _pictures = this.state[pictures]
        value.action = API.uploadAdd.url;
        // value.action = 'http://oms.st.idf10.com.cn/web/distribute_file/upload';
        formRequest(value).then((res) => {

            if (res.code == 0 && res.data) {
                const data = res.data;
                const file = value.file;
                const fileNameToArray = file.name.split('.');
                const fileType = fileNameToArray[fileNameToArray.length - 1];
                data.mediaExt = fileType;
                data.mediaKey = data.key;
                data.name = data.fileName;
                data.type = fileType;
                data.url = pictures == 'contractUpload' ? data.publicDownloadUrl : data.publicUrl;

                if (_pictures.length === 0) {
                    this.setPictures([{ ...data }], pictures);
                } else {
                    this.setPictures([..._pictures, { ...data }], pictures);
                }
            } else {
                message.error('上传失败，请重试');
            }
        });
    }
    deletePicture(pic, i, e, item) {
        e.stopPropagation();
        this.state[item].splice(i, 1);
        this.setState({
            [item]: this.state[item]
        })
    }
    setPictures(pictures, item) {
        this.setState({ [item]: pictures });
    }

    closeImgPreview = () => {
        this.setState({ imgPreviewVisible: false })
    }
    showImaPreview = () => {
        this.setState({ imgPreviewVisible: true })
    }
    getDetail = () => {
        const config = {
            api: API.sign_getAuditDetail,
            data: {
                contractSignId: this.props.match.params.code
            },
            showLoading: true
        };

        http(config).then(res => {
            if (res.code == 0) {
                let data = res.data;

                this.setState({
                    ...data                    
                }, () => {
                    console.log(res.data)
                });
            }
        })
    }


    handleSubmit = e => {
        e.preventDefault();
        if (this.state.picturesHeiMingDan.length == 0) {
            showErrorMessage('请至集团黑名单系统查验，并上传查验截图')
            return false
        }
        if (this.state.picturesGuanLian.length == 0) {
            showErrorMessage('请至集团关联交易系统查验，并上传查验截图')
            return false
        }
        this.props.form.validateFields((err, values) => {
            console.log(values)
            if (!err) {
                console.log('Received values of form: ', values);

                // 确认审核
                this.approve({ ...values })
            }
        });
    };
    approve({ approve, tips }) {
        const config = {
            api: API.sign_auditContract,
            data: {


                // 黑名单审核
                blackList: this.state.picturesHeiMingDan,
                // 关联交易审核
                tradeList: this.state.picturesGuanLian,
                // 合同ID
                contractId: this.state.contractId,
                // 企业ID
                enterpriseId: this.state.enterpriseId,
                // 审核意见,[1:同意受理，2：驳回不予受理]
                suggestion: approve,
                // 意见说明
                suggestionRemark: tips,


            },
            showLoading: true
        };

        http(config).then(res => {
            if (res.code == 0) {
                this.getDetail()
            }
        })
    }
    handleOkCancelSign = () => {
        // 取消签约
        const config = {
            api: API.sign_cancel,
            data: {

                contractCode: this.props.match.params.code
            },
            showLoading: true
        };
        console.log()
        http(config).then(res => {
            if (res.code == 0) {
                window.history.back()
            }
        })
    }

    handleCancelCancelSign = () => {
        this.setState({ cancelSignVisible: false })
    }
    // 获取签约记录
    getHistory=()=> {
        const config = {
            api: API.sign_sealList,
            data: {
                contractCode: this.props.match.params.code
            },
            showLoading: true
        };
        console.log()
        http(config).then(res => {
            if (res.code == 0) {
                this.setState({
                    history: res.data,
                    signHistoryVisible: true
                })                
            }
        })
    }
    // 确定上传合同
    handleOkContractSign = () => {


        const config = {
            api: API.sign_uploadContract,
            data: {
                contractId: this.state.contractId,
                contractList: this.state.contractUpload
            },
            showLoading: true
        };
        console.log()
        http(config).then(res => {
            if (res.code == 0) {
                this.getDetail()
                this.setState({ contractSignVisible: false })
            }
        })


    }

    render() {
        const { data, imgPreviewList, imgPreviewVisible } = this.state;
        let {
            ckSchedule,
            contactAddress,
            contractId,
            contractName,
            contractNo,
            contractUrl,
            createUserId,
            creditCode,
            enterpriseId,
            enterpriseName,
            expiryEndDate,
            expiryStartDate,
            ownerName,
            registerAddress,
            signDate,
            signType,
            status,
            contractList,
            history,
            remark,
            auditStatus,
            blackList,
            tradeList,
            supplierSchedule } = this.state
        const { getFieldDecorator } = this.props.form;
        
        return (
            <Content className="customer-detail-content" style={{ "paddingTop": 0 }}>
                <div className="customer-detail-head flexbox">
                    <Breadcrumb className="customer-detail-head-bread">
                        <Breadcrumb.Item>
                            <Link style={{ color: '#333' }} to={{ pathname: '/app/crm/contract-management' }} >
                                签约管理
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            签约详情
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div gutter={24} className="customer-detail-content-box">

                    <div className="box">
                        <p className="title">状态：{statusType.getTextFromValue(status)}</p>
                        <p className="title">签约方式：{signTypeEnum.getTextFromValue(signType)}</p>
                    </div>
                    <div className="box">
                        <p className="title">签约信息</p>
                        <div className="sign-form">
                            <p>
                                <span>合同编号</span>
                                ：
                                <span>{contractNo}</span>
                            </p>
                            <p>
                                <span>企业名称</span>
                                ：
                                <span>{enterpriseName}</span>
                            </p>
                            <p>
                                <span>统一社会信用代码</span>
                                ：
                                <span>{creditCode}</span>
                            </p>
                            <p>
                                <span>法定代表人</span>
                                ：
                                <span>{ownerName}</span>
                            </p>
                            <p>
                                <span>注册地址</span>
                                ：
                                <span>{registerAddress}</span>
                            </p>
                            <p>
                                <span>联系地址</span>
                                ：
                                <span>{contactAddress}</span>
                            </p>
                            {
                                // 待审核 线上
                                [2].includes(status) && signType == 1 ?
                                    <p>
                                        <span>合同内容</span>
                                    ：
                                    <span>
                                            <a target="_black" href={contractUrl} className="contract-pdf">{contractName}</a>
                                        </span>
                                    </p>
                                    : null
                            }
                            {
                                // 待审核 线下
                                [2].includes(status) && signType == 2 ?
                                    <p>
                                        <span>合同内容</span>
                                    ：
                                    <span>
                                            线下沟通
                                        </span>
                                    </p>
                                    : null
                            }
                            {
                                // 待盖章 线上
                                [3, 4, 5, 8].includes(status) && signType == 1 ?
                                    <p>
                                        <span>合同内容</span>
                                    ：
                                    <span>
                                            <a target="_black" href={contractUrl} className="contract-pdf">{contractName}</a>
                                        </span>
                                    </p>
                                    : null
                            }

                            {
                                // 待盖章 线下
                                [3, 4, 5, 8].includes(status) && signType == 2 ?
                                    null
                                    : null
                            }
                            {
                                // 已签约 线上
                                [6].includes(status) && signType == 1 ?
                                    <p>
                                        <span>合同内容</span>
                                    ：
                                    <span>
                                            <a target="_black" href={contractUrl} className="contract-pdf">{contractName}</a>
                                        </span>
                                    </p>
                                    : null
                            }
                            {
                                // 已签约 线下
                                [6].includes(status) && signType == 2 ?
                                    <p>
                                        <span>合同内容</span>
                                    ：
                                    <span>
                                            {
                                                contractList.map((res, i) => {
                                                    return <a key={res.url} href={res.url} className="contract-pdf">{res.name}</a>
                                                })
                                            }
                                        </span>
                                    </p>
                                    : null
                            }
                            {
                                // 签约失败 线上
                                [7].includes(status) && signType == 1 ?
                                    <p>
                                        <span>合同内容</span>
                                    ：
                                    <span>
                                            <a target="_black" href={contractUrl} className="contract-pdf">{contractName}</a>
                                        </span>
                                    </p>
                                    : null
                            }
                            {
                                // 签约失败 线下
                                [7].includes(status) && signType == 2 ?
                                    null
                                    : null
                            }
                        </div>
                    </div>
                    {status == 2 ?
                        <div className="box" alt="待审核才可以编辑">
                            <div className="title">审核流程</div>
                            <div className="contract-process-item">
                                <p className="contract-process-item-name"><span>*</span> 黑名单审核（请至集团黑名单系统查验，并上传查验截图）：</p>
                                <div className="contract-terms-upload">
                                    <ul className="contract-terms-upload-up" style={{ 'margin': 0, 'padding': 0 }}>
                                        {
                                            this.state.picturesHeiMingDan.map((res, i) => {
                                                return <li className="contract-terms-upload-img" key={res.mediaKey}>
                                                    <img src={res.url} key={res.mediaKey} alt="" />
                                                    <span className="iconfont" onClick={(e) => this.deletePicture(res, i, e, 'picturesHeiMingDan')}>&#xe7bc;</span>
                                                </li>
                                            })
                                        }
                                        <Upload
                                            className={'after-sales-upload-6666'}
                                            showUploadList={false}
                                            beforeUpload={this.beforeUpload}
                                            customRequest={this.doUploadFileHeiMingDan}
                                            disabled={this.state.picturesHeiMingDan.length === 10}
                                        >
                                            <i className={'icon iconfont iconupload'} />
                                            <div>点击上传</div>
                                            <div>（{this.state.picturesHeiMingDan.length}/10）</div>
                                        </Upload>
                                    </ul>

                                </div>
                            </div>
                            <div className="contract-process-item">
                                <p className="contract-process-item-name"><span>*</span> 关联交易审核（请至集团关联交易系统查验，并上传查验截图）：</p>
                                <div className="contract-terms-upload">
                                    <ul className="contract-terms-upload-up" style={{ 'margin': 0, 'padding': 0 }}>
                                        {
                                            this.state.picturesGuanLian.map((res, i) => {
                                                return <li className="contract-terms-upload-img" key={res.mediaKey}>
                                                    <img src={res.url} key={res.mediaKey} alt="" />
                                                    <span className="iconfont" onClick={(e) => this.deletePicture(res, i, e, 'picturesGuanLian')}>&#xe7bc;</span>
                                                </li>
                                            })
                                        }
                                        <Upload
                                            className={'after-sales-upload-6666'}
                                            showUploadList={false}
                                            beforeUpload={this.beforeUpload}
                                            customRequest={this.doUploadFileGuanLian}
                                            disabled={this.state.picturesGuanLian.length === 10}
                                        >
                                            <i className={'icon iconfont iconupload'} />
                                            <div>点击上传</div>
                                            <div>（{this.state.picturesGuanLian.length}/10）</div>
                                        </Upload>
                                    </ul>

                                </div>
                            </div>
                            <div className="contract-process-item contract-process-item-form">
                                <p className="contract-process-item-name"><span>*</span> 审核意见（请仔细审核签约信息并填写审核意见）：</p>
                                <Form labelCol={{ span: 2 }} wrapperCol={{ span: 20 }} onSubmit={this.handleSubmit}>

                                    <Form.Item label="审批意见">
                                        {getFieldDecorator('approve', {
                                            initialValue:1,
                                            rules: [{ required: true, message: '请选择审批意见' }],
                                        })(
                                            <Select
                                                placeholder="请选择审批意见"                                                
                                            >
                                                <Option value={1}>同意受理</Option>
                                                <Option value={2}>驳回不予受理</Option>
                                            </Select>,
                                        )}
                                    </Form.Item>
                                    <Form.Item label="意见说明">
                                        {getFieldDecorator('tips', {
                                            rules: [{ required: true, message: '请填写意见说明' }],
                                        })(<TextArea rows="4" maxLength={300} />)}
                                    </Form.Item>
                                    <p style={{ 'marginTop': '20px', 'marginLeft': '8px' }}><span onClick={this.getHistory} className="sign-record">签约记录</span></p>
                                    <div className="operate">
                                        <Button type="primary" htmlType="submit"> 确认 </Button>
                                        <Button onClick={() => { window.history.back() }}> 取消  </Button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        :
                        <div className="box" alt="非待审核都是展示">
                            <div className="title">审核流程</div>
                            <div className="contract-process-item">
                                <p className="contract-process-item-name"><span>*</span> 黑名单审核（请至集团黑名单系统查验，并上传查验截图）：</p>
                                <div className="contract-terms-upload">
                                    <ul className="contract-terms-upload-up" style={{ 'margin': 0, 'padding': 0 }}>
                                        {
                                            blackList.map((res, i) => {
                                                return <li className="contract-terms-upload-img" key={res.mediaKey}>
                                                    <img src={res.url} key={res.mediaKey} alt="" />
                                                </li>
                                            })
                                        }
                                    </ul>

                                </div>
                            </div>
                            <div className="contract-process-item">
                                <p className="contract-process-item-name"><span>*</span> 关联交易审核（请至集团关联交易系统查验，并上传查验截图）：</p>
                                <div className="contract-terms-upload">
                                    <ul className="contract-terms-upload-up" style={{ 'margin': 0, 'padding': 0 }}>
                                        {
                                            tradeList.map((res, i) => {
                                                return <li className="contract-terms-upload-img" key={res.mediaKey}>
                                                    <img src={res.url} key={res.mediaKey} alt="" />
                                                </li>
                                            })
                                        }
                                    </ul>

                                </div>
                            </div>
                            <div className="contract-process-item contract-process-item-form">
                                <p className="contract-process-item-name"><span>*</span> 审核意见（请仔细审核签约信息并填写审核意见）：</p>
                                <p className="contract-process-item-name yijian"><i><span>*</span> 审批意见：</i> {auditStatus == 1 ? <i>同意受理</i> : <i>驳回不予受理</i>}</p>
                                <p className="contract-process-item-name yijian"><i><span>*</span> 意见说明：</i> <i>{remark}</i></p>

                                <p style={{ 'marginTop': '20px', 'marginLeft': '8px' }}><span onClick={() => { this.setState({ signHistoryVisible: true }) }} className="sign-record">签约记录</span></p>

                                {
                                    [3, 4, 5, 8].includes(status) && signType == 2 ?
                                        <div className="operate" alt="待盖章（线下）">
                                            <Button type="primary" onClick={() => { this.setState({ contractSignVisible: true }) }}> 上传合同 </Button>
                                            <Button type="primary" onClick={() => { this.setState({ cancelSignVisible: true }) }}> 取消签约 </Button>
                                            <Button onClick={() => { window.history.back() }}> 取消  </Button>
                                        </div> : null
                                }


                                <Modal
                                    title="取消签约提示"
                                    visible={this.state.cancelSignVisible}
                                    onOk={this.handleOkCancelSign}
                                    onCancel={this.handleCancelCancelSign}
                                >
                                    <p>取消签约后合同状态将为失败，确定要取消签约？</p>

                                </Modal>


                            </div>
                        </div>
                    }
                    <Modal
                        title="上传合同"
                        visible={this.state.contractSignVisible}
                        onOk={this.handleOkContractSign}
                        onCancel={() => { this.setState({ contractSignVisible: false }) }}
                    >
                        <div className="contract-sign-upload">
                            <div style={{ 'marginRight': '20px' }}>
                                <p>合同文件</p>
                                <p>支持PDF</p>
                            </div>
                            {
                                this.state.contractUpload[0] && this.state.contractUpload[0].fileName ?
                                    <div className="contract-sign-upload-file" title={this.state.contractUpload[0].fileName}>
                                        <img src={pdf} alt="pdf" />
                                        <p>{this.state.contractUpload[0].fileName}</p>
                                        <span className="iconfont" onClick={(e) => { this.setState({ contractUpload: [] }) }}>&#xe7bc;</span>
                                    </div> :
                                    <Upload
                                        className={'after-sales-upload-6666'}
                                        showUploadList={false}
                                        beforeUpload={this.beforeUploadContract}
                                        customRequest={this.doUploadFileContract}
                                        disabled={this.state.contractUpload[0] && this.state.contractUpload[0].fileName}
                                    // onChange={this.handleChange}
                                    >
                                        <i className={'icon iconfont iconupload'} />
                                        <div>点击上传</div>
                                    </Upload>
                            }


                        </div>

                    </Modal>
                    <Modal
                        title="签约记录"
                        visible={this.state.signHistoryVisible}
                        onCancel={() => { this.setState({ signHistoryVisible: false }) }}
                        footer={null}
                    >
                        {
                            history.length ?
                                <ul className="signHistoryRecord">
                                    {history.map(res => {
                                        return (
                                            <li key={res.createTime}>
                                                <p><span>状态：</span> <span>{res.operateDescribe}</span> </p>
                                                <p><span>操作人：</span> <span>{res.operator}</span> </p>
                                                <p><span>时间：</span> <span>{res.createTime}</span> </p>
                                            </li>
                                        )
                                    })}
                                </ul>
                                :
                                <div style={{ textAlign: 'center' }}>
                                    <img width="200" src={empty} alt="" />
                                    <p style={{ marginTop: '20px' }}>暂无记录</p>
                                </div>
                        }
                    </Modal>

                </div>
            </Content>
        )
    }

}

export default Form.create({ name: 'ContractManagementDetail' })(ContractManagementDetail);