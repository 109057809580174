/*

  一,目标: 列表数据，存在大量的筛选需求。为统一 筛选样式及操作方式，因此开发此组件

  二 说明：
    1） 当前还没有开发所有的筛选类型，需要继续补充
    2) 同一个页面中的Api筛选框，如果存在2个组件 使用同一个接口。内部实现会根据api的url作为key去缓存接口数据，而且是转化后的数据。保证不会重复调用

  三， 使用方法:
     1）  引入引用：
        import FilterPanel from "CMPT/common/FilterPanel";
        import { FilterType } from '@/utils/fliter';   
     2) 配置筛选配置:
     声明变量
     state = {
        filterConfig:this.createFilterConfig(),
       ....
       }    

   // 按照一行行配置
   createFilterConfig(){
    return {
       config:[
        this.createRow1(),
        this.createRow2(),
        this.createRow3(),
        this.createRow4()
      ]
    } ;
  }

      // 每行配置如下:
        childList:[
        {
          type:FilterType.INPUT,
          label:'订单号',
          config:{
            field:'orderNumber',
            placeholder:''
          }
        },
        {
          type:FilterType.ENUM_SELECT,
          label:'订单来源',
          config:{
            field:'orderType',
            enumData:OrderType
          }
        }, 
       
        {
          type:FilterType.TIME_RANGE,
          label:'成交时间',
          config:{
            field:['createStartTime','createEndTime'],
          }
        },
       
      ]

      3) 使用 

         组件在render()中引用
         <FilterPanel data={filterConfig} onFilterChange={this.onFilterParmChange}/>

         参数回调
       onFilterParmChange=(filterParm)=>{
         this.setState({filterParm},this.doQueryList); //带有分页的话，这里需要重置页码为1，同时缓存筛选结果(为手动页码跳转准备数据)
       }


       注意：这个回调不包括分页信息。 如果列表带分页器，需要在state中缓存 filterParm,那么在查询前，要把filterParm和页码信息进行组装。


  四，参数说明：
  1), Select类型 注意返回结果，是否需要转换为其他Number类型


  五, 后续计划：
     1) 对部分事件的回调，例如 搜索框获得了焦点,进而判断主界面是否隐藏Banner
     2）添加其他筛选类型。例如，接口返回的字典 来加载 下拉选择框， 省(直辖市)选择，省市选择 等
     3) 需要添加 各个筛选组件的默认值。 在重置时，带上 默认值
     4) 把每个组件的配置参数 进行归纳，并改进。 提供一份 使用说明文档

  六，目前存在的问题：
    

  七, 修改记录：


*/


import React, { Component } from 'react';
import { Input, Button,Form,  Cascader,Icon,DatePicker} from "antd";
import Select from 'CMPT/common/Select';
import DateRange from "CMPT/common/DateRange";
import { API } from "@/http/api";
import http from "@/http";
import moment from 'moment';
import { FilterType } from '@/utils/fliter';
import "./FilterPanel.less";

const { RangePicker } = DatePicker;
 
class FilterPanel extends Component {

    state = {
        filterParm :{},// 返回给 调用方的结果集合。 需要保持 字段和返回结果的 类型没有错误
        tempData:{ },// 组件操作中的中间值。例如 日期选择中的moment
        httpTempData:this.createHttpTempData(), // 保存 网络接口数据并转化的数据

    };


    createHttpTempData(){
      const {pageId}= this.props;
      if(!pageId){
        // 没有Id ，说明一个页面 不需要共享Api接口数据(全局变量)。  给一个默认对象即可
         return {};
      }


      // 说明多个页面组件 需要共享 global.filterHttpTempData
      var httpTempData = global.filterHttpTempData[pageId];
      if(!httpTempData){
        httpTempData=[];
        global.filterHttpTempData[pageId] = httpTempData;
      }else{
        //console.log(" FilterPanel old old old old   pageId---> ",pageId);
      }

      return httpTempData;
    }

    dispatchCallback=()=>{
       //notifyChange();
    }

    componentWillUnmount() {
      const {pageId}= this.props;
      if(!pageId){
        // 没有Id ，说明一个页面 不需要共享全局变量。  给一个默认对象即可
         return {};
      }


      delete global.filterHttpTempData[pageId];
  }

    notifyChange=()=>{
      const { onFilterChange } = this.props
      let { filterParm } = this.state;

      if( onFilterChange){
            onFilterChange(filterParm);
      }
    }

    componentDidMount() {
      if(!this.props.notInitNotify){
        this.notifyChange();
      }
    }

    selectEnumSelectChange = (config,value) => {
      let {field} = config;
        let { filterParm } = this.state;
        filterParm[field] = value;//TODO 后续考虑，这个数据是否需要转化为 数字类型


        this.setState({ filterParm },  () => this.dispatchCallback());
      }


      selectApiSelectChange = (config,value) => {
        let {field} = config;
         
        
        let { filterParm,tempData } = this.state;
        
          tempData[field] =   filterParm[field] = value;
         /* if(config.returnType=='number'){
            filterParm[field] =  Number(value);
          }else {
            filterParm[field] = value;
          }*/  
          this.setState({ filterParm,tempData },  () => this.dispatchCallback());
        }


        loadSelectApiUrl=(config)=>{
          let {httpTempData} = this.state;
          let {  api} = config;

          // 先看下 是否已经有 api加载任务 在执行。有，退出即可
          let keyLoading = 'loading_'+api.url;
           if(httpTempData[keyLoading]){
               return;
           }


         
          httpTempData[keyLoading] = true;  // 开始执行 接口加载

          http({
            api,
            data:config.dataBody||{},
            showLoading: false
          }).then(res => {

            httpTempData[keyLoading] = false;//结束接口的加载

            if (res&&(res.success||res.code==200)) {
              // 查找有效数据
               let dataHttpArray=undefined;//实体数据肯定是个数组。不同api可能接口结构不同，要猜测出对应结构体
               if(res.data ) {
                if(Array.isArray(res.data)){
                  dataHttpArray = res.data;
                }else if(Array.isArray(res.data.datas)){
                  dataHttpArray = res.data.datas;
                }
               }
               if(!dataHttpArray || !dataHttpArray.length){
                   return;
               }

               // 使用数据
               let dataArray=[];
               dataHttpArray.forEach(item=>{
                 dataArray.push({value:item[config.valueField],name:item[config.nameField]}); 
               });

               // 缓存数据
              let {httpTempData} = this.state;
              httpTempData[api.url] = dataArray;
  
              this.setState({
                httpTempData
              });
            }
          });
        }


      loadProvince = () => {
        http({
          api: API.areaProvinces,
          showLoading: false
        }).then(res => {
          if (res&&res.success) {
            let region =   res.data.map(item => {
              item.isLeaf = false;
              return item;
            })


            let {tempData} = this.state;
            tempData.region = region;

            this.setState({
              tempData
            });
          }
        });
      };

 

    onDateRangeChange(field,dataField,value){
        let { filterParm ,tempData } = this.state;
        if (dataField === "startValue") {
          filterParm[field[0]] = value.format('YYYY-MM-DD');// 这个是日期组件，不带时间
          tempData[field[0]] = value;
        } else {
          filterParm[field[1]] = value.format('YYYY-MM-DD');// 这个是日期组件，不带时间
          tempData[field[1]] = value;
        }
    
        this.setState({ filterParm ,tempData}, this.dispatchCallback);

    }



    onTimeChange(field,moment,dateString){
      let { filterParm ,tempData} = this.state;

      // 目前看 返回的dateString是个数组  20210906
      if(dateString&&Array.isArray(dateString)&&dateString.length==2){
        filterParm[field[0]] = dateString[0];
        filterParm[field[1]] = dateString[1];
      }

      tempData[(field[0]+'_'+field[1])] = moment;
  
      this.setState({ filterParm ,tempData}, this.dispatchCallback);
  }




    onInputChange = (field,e) => {
        let { filterParm } = this.state;
 
        let value = e.target.value;
        filterParm[field]= value ? value.replace(/^\s+|\s+$/g, "") : "";


        this.setState({ filterParm }/*, this.dispatchCallback*/)
      };


       resetFilter = () => {
         //TODO 重置的情况下，如果有 默认值，应该把默认值带出来
         let {filterParm,tempData} = this.state;

         Object.keys(filterParm).forEach(key => {
           //filterParm[key] = undefined
            delete filterParm[key]  ;// 目前相当于 清除
         })
         Object.keys(tempData).forEach(key => {
             //tempData[key] = undefined
             delete tempData[key];// 目前相当于 清除
        })
 
        this.setState({ filterParm,tempData }, this.notifyChange);
      }

      onSearchClick=()=>{
        this.notifyChange();
      }



    onAddressRegionChange = (field,value, selectedOptions) => {
      let { filterParm } = this.state;
 
       filterParm[field[0]]= value[0];
       filterParm[field[1]]=value[1];

       filterParm[field[2]]=value[2];

       this.setState({ filterParm }, this.dispatchCallback);
    };

    loadRegion = selectedOptions => {
      const targetOption = selectedOptions[selectedOptions.length - 1];
  
      http({
        api: API.areaSubAreas,
        data: {
          encode: targetOption.code
        },
        showLoading: false
      }).then(res => {
        targetOption.children = res.data;
        if (selectedOptions.length < 2) {
          res.data.forEach(item => {
            item.isLeaf = false;
            return item;
          });
        }

        let {tempData} = this.state;
        tempData.region = [...tempData.region]  ;

        this.setState({ tempData });
      });
    };


    /*  start:组件创建 */

    createEachRow(item){
      //TODO  后续type修改为 枚举enum
      //TODO 后续要添加默认值
      if(item.type==FilterType.ENUM_SELECT){
        return this.createEnumSelect(item.config);
    }else if(item.type==FilterType.DATE_RANGE){
        return this.createDateRange(item.config);
    }else if(item.type==FilterType.INPUT){
        return this.createInputSearch(item.config);
    }else if(item.type==FilterType.PCD){
      return this.createPcd(item.config);
    }else if(item.type==FilterType.TIME_RANGE){
      return this.createTimeRange(item.config);
    }else if( item.type==FilterType.API_SELECT){
      return this. createApiSelect(item.config);
    }
    return "";
    }


    createTimeRange(config){
      let {field,placeholder} = config;
      let {tempData} = this.state;

      // 做个简单校验
      let placeHolderValue =[];
      if(placeholder&&Array.isArray(placeholder)&&placeholder.length==2){
        placeHolderValue = placeholder;
      }

      // 当前时间。如果重置/清除，组件内时间会被清除
      let momentValue = tempData[(field[0]+'_'+field[1])]   ;


      return ( 
       <RangePicker
          style={{ width: '200px',     textAlign: 'center' }}
          onChange={this.onTimeChange.bind(this,field)}
          placeholder = {placeHolderValue}
          value={momentValue}
          showTime={{
            hideDisabledOptions: true,
            defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
          }}
        /> );
    }

    createDateRange(config){
      let {field} = config;
      let {tempData} = this.state;

      return ( 
       <DateRange
          style={{ width: '200px',     textAlign: 'center' }}
          onDateChange={this.onDateRangeChange.bind(this,field)}
          startValue={tempData[field[0]]}
          endValue={tempData[field[1]]}
        /> );
  }

  createEnumSelect(config){
    let {field, placeholder} = config;
     let {filterParm} = this.state;//TOOD filterParm 可以换成 temp data



  //TODO 构造 下拉框的数据。后来 可以缓存这个对象，不要每次转换
     let enumMap=[];
     let  enumData = config.enumData;
     var valueEntity =  enumData.valueIndex;
     valueEntity.forEach((item)=>{
      enumMap.push({value:item.value,name:item.text}); 
     });



      return ( 
      <Select data={enumMap} getPopupContainer={triggerNode => triggerNode.parentNode} placeholder={placeholder||'请选择'} value={filterParm[field]}  
      filterOption={false}
      selectMethod={this.selectEnumSelectChange.bind(this,config)} style={{ width: '200px' }} /> );
  }

  createApiSelect(config){
    let {field, placeholder,api,showSearch} = config;
    let {httpTempData,tempData} = this.state;
 
    let enumMap = httpTempData[api.url];
     if(!enumMap ){
       enumMap = [];//临时赋值，等待接口加载数据. Select空间有问题，需要构造一个无效数据
       this.loadSelectApiUrl(config);
    }


    // 是否过滤，搜索过滤策略
     let filterOptionValue = undefined;
     if(showSearch){
 
      filterOptionValue = (input, option) =>{
        if(!option || !option.props || !option.props.children){
             return false;
        }
       let firstChildren =  option.props.children;

       // 自定义Select重写了 Option。 导致option会出现Tooltip . 需要下探一层
        if(typeof(firstChildren)=='string' ){
          return firstChildren.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }else if(firstChildren.props && firstChildren.props.children && typeof(firstChildren.props.children)=='string'){ 
          return firstChildren.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        } //目前没有出现第三种

        return false;
      }
     }else{
      filterOptionValue = false;
     }

     return ( 
     <Select data={enumMap} getPopupContainer={triggerNode => triggerNode.parentNode} placeholder={placeholder||'请选择'} value={tempData[field]}  
     showSearch={showSearch?true:false}
     filterOption={ filterOptionValue}
     selectMethod={this.selectApiSelectChange.bind(this,config)} style={{ width: '200px' }} /> );

  }

    createInputSearch(config){
      let { filterParm } = this.state;
      let {field,maxLength,placeholder} = config;

      return ( 
          <Input
          placeholder={placeholder||'请输入'}
          onChange={this.onInputChange.bind(this,field)}
          value={filterParm[field]}
          style={{ width: "200px"  }}
          maxLength={maxLength}
        /> );
  }

    createSearchBtn(){
      return (<Form.Item label="">
      <Button type='primary' onClick={this.onSearchClick}>搜索</Button>
    </Form.Item>);
    }

    createResetBtn(){
      return (<Form.Item label="">
      <a className='reset' onClick={this.resetFilter}>重置</a>
    </Form.Item>);
    }

    createPcd(config){
      let {field } = config;
      let {tempData} = this.state;


 
      // 如果还没初始化，那就拉取下省市区信息
      if(!tempData.region || !tempData.region.length){
        this.loadProvince();
      }

      return ( <Cascader
        style={{ width: "200px" }}
        loadData={this.loadRegion}
        fieldNames={{
          label: "name",
          value: "code",
          children: "children"
        }}
        allowClear={false}
        changeOnSelect
        suffixIcon={<Icon type="caret-down" />}
        placeholder={"省市区"}
        options={this.state.tempData.region}
        onChange={(value, selectedOptions) => {
          this.onAddressRegionChange(field,value, selectedOptions);
        }}
      />);
    }

      /*  end:组件创建 */


    render() {

        let that = this;
        let {config,btnLast} = that.props.data;
        if(!config || !Array.isArray(config) || !config.length){
            return "";
        }

        
        return (  <div  className="panel" >
         { 
            config.map(function (item, index1) {
             let {childList} = item;
             if(!childList || !Array.isArray(childList) || !childList.length){
               return "";
             }
            
            return (
              <Form  key={'one_item_'+index1}  tyle={{marginTop:'25px',marginBottom:'25px'}} layout='inline'   >
                { childList.map(function (item2, index2) {
                  
                      let {label} = item2;
                      return (
                        <Form.Item  key={'sub_item_'+index2} labelCol={{ style: { width: 80 } }} label={label}>
                           {that.createEachRow(item2)}    
                          </Form.Item>
                      )
                 })               
                }  
                {
                  btnLast&&that.createSearchBtn() 
                }  
                {
                  btnLast &&that.createResetBtn()
                }            
              </Form>
            );

           })
        }
 

          
            { !btnLast&&<Form   style={{marginTop:'25px',marginBottom:'25px',paddingLeft:"600px"}} layout='inline'   >
              {that.createSearchBtn()}
              {that.createResetBtn()}
            </Form>  }
        
    
      </div>);
    }

}


export default FilterPanel;