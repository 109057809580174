import React, { Component } from "react";
import { Link } from 'react-router-dom';

import {   message } from 'antd';
import EmptyView from "CMPT/widget/EmptyView";
import Table from 'CMPT/common/Table';
import MultiLineText from "CMPT/widget/MultiLineText";
import { API } from "@/http/api";
import {OrderType,PayType} from "../enum.js";
import http from "@/http";
import FilterPanel from "CMPT/common/FilterPanel";
import { FilterType } from '@/utils/fliter';
import TableRenderHelp from "@/utils/TableRenderHelp.js";
import "./List.less";

 class EquipmentIntentionList extends Component {

  state = {
    filterConfig:this.createFilterConfig(),//筛选条件配置
    filterParm:{},// 筛选组件返回的 参数组。当翻页时，需要保存下
    columns: this.createColumns(),//表单 项

    httpData: [],//请求数据
    tempData: {
      isLoading: true,
    },

    total: 0, // 当前数据的总数
    pageNo: 1, // 当前所展示的页码
    pageSize: 10, // 每页条数
  
  };



  onChangePage = (pageNo, pageSize, sort) => {
    this.setState({
         pageNo , pageSize 
    } ,this.doQueryList)
}

 
  onFilterParmChange=(filterParm)=>{
    this.setState({filterParm,pageNo:1},this.doQueryList);// 每次搜索，从第1页取数据
  }


  createQueryParam = () => {
    let {filterParm,pageNo,pageSize} = this.state;
 
    let param = {
       pageNo ,
       pageSize , 
       orderStatus:this.props.status
    };


 

    filterParm&&Object.keys(filterParm).forEach(key => {
      if (filterParm[key] || filterParm[key]===0) {
        param[key] = filterParm[key];
      }
    });

 
    return param;
  }

  doQueryList = () => {
    // setup 1:基本入参
    const config = {
      api: API.equipmentOrderList,
      data: this.createQueryParam(),
      showLoading: true
    };

 

    // setup 2:请求，并初步处理
    http(config).then(res => {
      let tempData = this.state.tempData;
      tempData.isLoading = false;

      if (res &&res.code==200 && res.data  && res.data.datas) {
        let data= res.data;

        this.setState({
          httpData: data.datas || [],
          tempData,
          total: Number(data.total),
          pageNo: data.pageNo
         });
      }else{
        message.error(res.message || '列表查询失败');
      }
    });
  };

 
  createRow1(){
    return {
      childList:[
        {
          type:FilterType.INPUT,
          label:'订单号',
          config:{
            field:'orderNumber',
            placeholder:''
          }
        },
        {
          type:FilterType.ENUM_SELECT,
          label:'订单来源',
          config:{
            field:'orderType',
            enumData:OrderType
          }
        }, 
       
        {
          type:FilterType.TIME_RANGE,
          label:'成交时间',
          config:{
            field:['createStartTime','createEndTime'],
          }
        },
       
      ]
    }
  }

 

  createRow2(){
    return {
      childList:[
        {
          type:FilterType.ENUM_SELECT,
          label:'付款方式',
          config:{
            field:'dealTypeStatus',
            enumData:PayType
          }
        },
    
        {
          type: FilterType.API_SELECT,
          label:'设备方公司',
          config:{
            field:'equipmentSupplierId',
            api:API.enterpriseAllList,
            showSearch:true,
            valueField:'id',
            nameField:'name'
           }
        }, 
   
        {
          type: FilterType.API_SELECT,
          label:'租用方公司',
          config:{
            field:'demandSupplierId',
            api:API.enterpriseAllList,
            showSearch:true,
            valueField:'id',
            nameField:'name'
    
          }
        }
       
  
      ]
    }
}

createRow3(){
  return {
    childList:[
      {
        type: FilterType.TIME_RANGE,
        label:'支付时间',
        config:{
          field:['payStartTime','payEndTime'],
        }
      } 

    ]
  }
}

  createFilterConfig(){
    return {
       config:[
        this.createRow1(),
        this.createRow2(),
        this.createRow3(),
       ]
    } ;
  }

  createColumns() {
    let help = new TableRenderHelp();
    return [
      help.createCommonColumn("订单编号", "orderNumber", "orderNumber"),
      {
        title: "设备名称",
        key: "equipmentNames",
        dataIndex: "equipmentNames",
        render: (value, record, index) => {
          if(!value || !value.length){
              return <span>--</span>;
          }
          return (
              value.map((item,index1)=>{
                return (<MultiLineText key={index1} showTips={true} value={item} />);
               }
            )
          );
        }
      },

      help.createCommonColumn("设备方", "equipmentParty", "equipmentParty"),
      help.createCommonColumn("设备方公司", "equipmentPartyCompany", "equipmentPartyCompany"),
      help.createCommonColumn("租用方", "leasing", "leasing"),
      help.createCommonColumn("租用方公司", "leasingCompany", "leasingCompany"),
      help.createCommonColumn("成交时间", "createTime", "createTime"),
      help.createCommonColumn("支付时间", "payTime", "payTime"),
      help.createCommonColumn("付款方式", "dealTypeName", "dealTypeName"),
      help.createCommonColumn("订单来源", "orderTypeName", "orderTypeName"),
      help.createCommonColumn("订单状态", "orderStatusName", "orderStatusName"),
 
      {
        title: "操作",
        key: "operate",
        fixed: 'right',
        render: (text, record, index) => {
          return (
            <Link to={'/app/crm/orderManage/equipment_order/detail/' + record.code} className="table-operate-btn">{'详情'}</Link>
        )
        }
      }
    ];
  };

   

  componentDidMount() {

   // this.doQueryList();
  }


  componentWillUnmount() {
 }


 

  render() {
    const {  filterConfig,tempData,httpData,columns,pageNo,total} = this.state;
    const {pageId}= this.props;
    let isLoading =  tempData.isLoading;


    return (
      <div className="equipment_panel" >

        <FilterPanel data={filterConfig}  pageId={pageId} onFilterChange={this.onFilterParmChange} />

        { httpData &&  httpData.length > 0 ? (
          <Table 
          rowKey={(record, index) => (record.code+"_"+index)}
          columns={ columns}
          selfProcessData={httpData} 
          total={ total} 
          change={this.onChangePage}
          current={ pageNo} 
          />
        ) : (isLoading ? "" : <EmptyView tips="暂无数据" />)}
 
      </div>
    );
  }
}

export default EquipmentIntentionList;
