/**
 * @file 新增客户/编辑客户 - 供应商信息
 */
import React from 'react';
import Table from "CMPT/common/Table";
import AttachmentDownload from 'CMPT/common/AttachmentDownload';


export default function EnterpriseMC({data, onChange, total, current, onlineProduct}) {

	const columns = [
		{
			title: "序号",
			dataIndex: "key"
		},
		{
			title: "一级",
			dataIndex: "first"
		},
		{
			title: "二级",
			dataIndex: "second"
		},
		{
			title: "对应类目经营资质",
			dataIndex: "link",
			render:(text, record) => {
				return <AttachmentDownload file={record.file} />
			}
		},
	]

	return (
		<div className="enterprise-managent-category">
			<p>可经营类目：</p>
			<Table columns={columns} data={data} change={onChange} total={total} current={current}/>
			<p></p>
			<div>
				<p>上线产品/商品目录{!onlineProduct || !onlineProduct.length ? '：无' : ''}</p>
			{
				onlineProduct.map(item=>{
					return <p><AttachmentDownload key={item.encode} file={{fileName: item.name, url: item.url}} /></p>
				})
			}
			</div>
		</div>
	);
}
