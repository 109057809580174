/**
 * 后端列举的枚举值，网址 http://pms.ipo.com/pages/viewpage.action?pageId=138958258
 * 根据该值生成基础权限关系对象
 *
 * 合同查看_所有人("合同查看_所有人",990201010101L,9902010101L,Boolean.TRUE,FunctionRangeEnum.所有人.getRange())
 * 说明：权限描述："合同查看_所有人"
 *      990201010101L：该权限id
 *      9902010101L：父权限id（父权限id为99时，说明该权限为一级菜单控制权限）
 *      Boolean.TRUE：是否是叶子节点
 *      FunctionRangeEnum.所有人.getRange()：数据权限
 */
var backendEnumStr = `
    客户中心("客户中心",7000L,99L,Boolean.FALSE,null),
    客户管理("客户管理",7001L,7000L,Boolean.FALSE,null),
    客户_查看列表("客户_列表",7002L,7001L,Boolean.TRUE,null),
    客户_查看列表_所有人("客户_列表_所有人",7003L,7002L,Boolean.TRUE,FunctionRangeEnum.所有人.getRange()),
    客户_查看列表_仅自己("客户_列表_仅自己",7004L,7002L,Boolean.TRUE,FunctionRangeEnum.仅自己.getRange()),
    客户_新增运营商("新增运营商",7005L,7001L,Boolean.TRUE,null),
`;

function getAuthConfig() {
  const authConfig = {};
  const scopeAuth = {};
  const backendEnum = backendEnumStr.split("\n");
  for (const enumStr of backendEnum) {
    if (enumStr.trim()) {
      const leftIndex = enumStr.indexOf("(");
      const desc = enumStr.slice(0, leftIndex).replace(/.*_/g, "");
      const config = enumStr
        .slice(leftIndex + 1, enumStr.length - 1)
        .split(",");
      const id = +config[1].replace("L", "");
      const parentId = +config[2].replace("L", "");
      if (enumStr.includes("FunctionRangeEnum")) {
        const parent = authConfig[parentId];
        if (!parent.scope) {
          parent.scope = [];
        }
        if (desc.includes("所有")) {
          parent.scopeValue = id;
        }
        parent.scope.push({
          value: id,
          desc,
        });
        scopeAuth[id] = parentId;
      } else {
        if (parentId === 99) {
          authConfig[id] = {
            parentId: null,
            level: 1,
            desc,
          };
        } else {
          authConfig[id] = {
            parentId,
            level: authConfig[parentId].level + 1,
            desc,
          };
        }
      }
    }
  }
  return {
    authConfig,
    scopeAuth,
  };
}

export default getAuthConfig(backendEnumStr);
