import React, { Component } from 'react';
import { Form, Input, Rate } from 'antd';
import http from '@/http'
import { API } from '@/http/api'
import Select from 'CMPT/common/Select'

const { TextArea } = Input;
const { Item } = Form;
let count = 0;

export default class AcceptanceOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            feeslist: 0
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.encode && count === 0) {
            this.getmaintainInit(nextProps.encode);
            count++
        }
        return true
    }
    componentWillUnmount() {
        count = 0
    }

    //请求费用清单数量
    getmaintainInit(encode) {
        const config = {
            api: API.maintainBillCount,
            path: {
                encode
            }
        }
        http(config).then(result => {
            const { data } = result;
            let feeslist = null;
            if (data) {
                feeslist = 1
            } else {
                feeslist = 0
            }
            setTimeout(()=>{
                this.setState({
                    feeslist
                })
            },0)
        })

    }

    render() {
        const { Accresult, result } = this.props;
        const { getFieldDecorator } = this.props.form;
        let list = null;
        if (Accresult === 1) {
            list = <div>
                <Item label="备注">
                    {getFieldDecorator('remark')(
                        <TextArea
                            maxLength={200}
                            style={{resize:'none'}}
                            onChange={this.props.chooseProject.bind(this,3)}>
                        </TextArea>
                    )}
                </Item>
                <Item label="评价">
                    {getFieldDecorator('rate',{
                        rules: [{required: true, message: '请选择评价',}],
                    })(
                        <Rate onChange={this.props.chooseProject.bind(this,2)}/>
                    )}
                </Item>
                <div className='row-special-tips'>{this.state.feeslist === 0 ? '温馨提示：暂未添加费用清单，若验收通过，工单将被关闭，且不生成账单。' : '温馨提示：若验收通过，工单将被关闭，且生成最终账单。'}</div>
            </div>
        } else if (Accresult === 2) {
            list = <div>
                <Item label="不通过原因">
                    {getFieldDecorator('notresult',{
                        rules: [{required: true, message: '请输入不通过原因',}],
                    })(
                        <TextArea
                            maxLength={200}
                            style={{resize:'none'}}
                            onChange={this.props.chooseProject.bind(this,4)}>
                        </TextArea>
                    )}
                </Item>
            </div>
        }

        return (
            <div className='maintain-addorder maintain-form'>
                <Form>
                    <Item label="验收结果">
                        {getFieldDecorator('result',{
                            rules: [{required: true, message: '请选择验收结果',}],
                        })(
                            <Select
                                data={result}
                                placeholder="请选择"
                                selectMethod={this.props.chooseProject.bind(this,1)}>
                            </Select>
                        )}
                    </Item>
                    {list}
                </Form>
            </div>
        )
    }
}
