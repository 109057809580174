import React, { Component } from 'react';
import {Row,Select,Form,Spin} from 'antd';
import http from '@/http';
import { API } from '@/http/api';
import MultiCascader from 'CMPT/common/MultiCascader';
import "../../financeApproval/finance.less"
import '../bill.less'

const Item = Form.Item;
const Option = Select.Option;

class Tenant extends Component {
    state = {
        fetching: false,
        customerList:[],
        cascaderData: [],
    }
    componentDidMount() {
        let name = ''
        let code = ''
        if (this.props.resetBill.customerObj) {
            code = this.props.resetBill.customerObj.split(',')[0]
            name = this.props.resetBill.customerObj.split(',')[1]
        }
        this.getCustomerList(name, code)
        if (this.props.resetBill.customerEncode) {
            this.getContract(this.props.resetBill.customerEncode)
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.resetBill !== this.props.resetBill) {
            if (nextProps.resetBill.customerEncode) {
                this.getContract(nextProps.resetBill.customerEncode)
            }
        }
    }
    getCustomerList(value, code){
        console.log(value)
        let config = {
            api: API.contractGetCustomerList,
            data:{
                fuzzyKey:value
            }
        }
        if (code) {
            config.data.encode = code
        }
        http(config).then(res => {
            console.log(res);
            this.setState({
                customerList:res.data
            })
        })
    }
    handleChange(value){
        console.log(value)
        this.setState({
            fetching: false,
        });
        this.getContract(value.split(',')[0]);
        this.props.form.setFieldsValue({
            contractObj:undefined
        })
    }
    getContract(value){
        http({
            api: API.contractGetContractList,
            path:{
                encode:value
            }
        }).then(res => {
            console.log('-----------', res);
            this.setState({
                cascaderData:res.data && res.data.map(per => {
                    return {
                        code: `${per.projectEncode},${per.contractEncode}`,
                        name: per.contractInfoStr
                    }
                }) || []
            })
        })
    }
    render(){
        const { fetching,cascaderData } = this.state;
        const { resetBill,billType } = this.props;
        const options = this.state.customerList && this.state.customerList.map(per => {
            return <Option value={per.encode+','+per.customerName} key={per.encode}>{per.customerName}</Option>
        })
        const { getFieldDecorator } = this.props.form;
        return (
            <div>
                <Row className="fs12">
                    <Item label="租客名称" style={{ width: '237px'}}>
                        {getFieldDecorator('tenantObj', {
                            initialValue:resetBill.customerObj,
                            rules: [{
                                required: billType == 2, message: '租客名称不能为空',
                            }]
                        })(
                            <Select
                                showSearch
                                showArrow={false}
                                placeholder="请输入"
                                notFoundContent={fetching ? <Spin size="small" /> : null}
                                filterOption={false}
                                onSearch={this.getCustomerList.bind(this)}
                                onChange={this.handleChange.bind(this)}
                                notFoundContent='暂无数据'
                                disabled={!resetBill.canEdit}
                            >
                                {options}
                            </Select>
                        )}
                    </Item>
                </Row>
                <Row className="fs12">
                    <Item label="选择合同" style={{ width: '396px'}}>
                        {getFieldDecorator('contractObj', {
                            rules: [{
                                required: billType == 2, message: '合同选择不能为空',
                            }],
                            initialValue: resetBill.mergeCode
                        })(
                            <Select
                                placeholder="请先选择租客"
                                notFoundContent='请先选择租客'
                                disabled={!resetBill.canEdit}
                            >
                                {cascaderData?cascaderData.map(per => {
                                    return <Option value={per.code} key={per.code}>{per.name}</Option>
                                }):null}
                            </Select>
                        )}
                    </Item>
                </Row>     
            </div>            
        )
    }
}

export default Tenant;