import React, { Component } from 'react';
import { Breadcrumb, Table, Descriptions, Modal, Button, Input } from 'antd';
import { Link } from 'react-router-dom'
import { API } from '@/http/api';
import http from '@/http';
import userUtils from '@/utils/user';
import { 
    getAuditStatusDesc, 
    getPublishStatusDesc, 
    getProductTypeName, 
    getInquiryTypeDesc, 
    getQuoteTypeDesc 
} from '../sourcingStatus';
import { AUTH_KEYS } from "@/utils/auth";
import ImgPreview from 'CMPT/common/ImgPreview';
import moment from 'moment';
import 'moment/locale/zh-cn';
import './index.less'

moment.locale('zh-cn');

const { checkAuthByKey } = userUtils;
const auditAuth = checkAuthByKey(AUTH_KEYS.SOURCING_MANAGE_AUDIT); // 审核
const offShevesAuth = checkAuthByKey(AUTH_KEYS.SOURCING_MANAGE_OFFSHELVES); // 违规下架
const { TextArea } = Input;

export default class SourcingDetail extends Component {
    state = {
        inquiryCode: '',
        isAuditReject: false,
        sensitiveWordsHasChecked: true,// 敏感词现在不需要。所以从false默认值改为true，保证先前流程能跳过敏感词 20210917
        sourcingDetail: {},
        productListData: [],
        auditRecords: [],// 审核记录
        previewVisible: false,
    }

    componentWillMount() {
        const match = this.props.match
        if (match && match.params && match.params.id) {
            this.setState({
                inquiryCode: match.params.id
            }, () => {
                this.refreshData()
            })
        }
    }

    refreshData = () => {
        this.getSourcingDetail()
        this.getAuditRecords()
    }

    watchRecord = () => {
        const { auditRecords } = this.state;

        const recordContent = auditRecords && auditRecords.length > 0 ? auditRecords.map(item => {
            return <div className="record_item">
                <div className="status">{getAuditStatusDesc(item.type)}</div>
                {item.reason ? <div className="info_item">
                    <span>理由：</span>
                    <span>{item.reason}</span>
                </div> : ''}
                <div className="info_item">
                    <span>审批人：</span>
                    <span>{item.createUserName}</span>
                </div>
                <div className="info_item">
                    <span>时间：</span>
                    <span>{item.createTime}</span>
                </div>
            </div>
        }) :
            <div className='log-no-data'><div className="log-no-data-text">暂无数据</div></div>;

        const recordHtml = <div className="sp_records">{recordContent}</div>;

        Modal.info({
            title: '审批记录',
            icon: null,
            content: recordHtml
        });
    }

    // 获取询价详情数据
    getSourcingDetail = () => {
        let that = this;
        const config = {
            api: API.sourcingGetDetail,
            data: {
                inquiryCode: this.state.inquiryCode
            },
            showLoading: true
        }
        http(config).then(res => {
            if (res && res.success && res.data) {
                 var  sourcingDetail=res.data || {};
               
                 // 过滤下 无效的附件数据
                 let mediaList=[];
                 if(sourcingDetail.mediaList&&Array.isArray(sourcingDetail.mediaList)&&sourcingDetail.mediaList.length){
                    sourcingDetail.mediaList.map((item)=>{
                        if(item.url&&item.name){
                            mediaList.push(item);
                        }
                    });
                 }
                 sourcingDetail.mediaList = mediaList;
                
                this.setState({
                    sourcingDetail
                })
            }
        })
    }

    // 获取审批记录
    getAuditRecords = () => {
        let that = this;
        const config = {
            api: API.sourcingAuditList,
            data: {
                inquiryCode: this.state.inquiryCode
            },
            showLoading: true
        }
        http(config).then(res => {
            if (res && res.success && res.data) {
                let auditRecords = res.data || []
                that.setState({
                    auditRecords
                })
            }
        })
    }

    // 敏感词检测
    getSensitiveCheck = () => {
        let that = this;
        const config = {
            api: API.sourcingSensitiveCheck,
            data: {
                inquiryCode: this.state.inquiryCode
            },
            showLoading: true
        }
        http(config).then(res => {
            if (res && res.success && res.data) {
                const data = res.data;
                if (data.checkResult) {
                    //检测成功
                    Modal.success({
                        title: '校验通过',
                        okText: '我已知晓',
                        content: (
                            <p className="mg_content">询价信息中没有敏感词</p>
                        ),
                        onOk() {
                            that.setState({
                                sensitiveWordsHasChecked: true
                            })
                        }
                    });
                } else {
                    // 检测失败
                    Modal.warning({
                        title: '在审核前必须了解的重要信息',
                        okText: '我已知晓',
                        content: (
                            <div className="mg_content">
                                {
                                    data.sensitivePropertyNames.map(item => (<p key={item}>{item}</p>))
                                }
                            </div>
                        ),
                        onOk() {
                            that.setState({
                                sensitiveWordsHasChecked: true
                            })
                        },
                    });
                }
            }
        });
    }

    // 审批通过
    approvalThrough = () => {
        let that = this;
        Modal.confirm({
            title: '通过',
            content: '是否确认通过？',
            okText: '通过',
            onOk: () => {
                const config = {
                    api: API.sourcingAudit,
                    data: {
                        inquiryCode: this.state.inquiryCode,
                        type: 2 //审核类型 2：通过，3：驳回，4：强制下架
                    },
                    showLoading: true,
                    successTip: '通过成功'
                }
                http(config).then(res => {
                    if (res && res.success) {
                        that.refreshData()
                    }
                })
            }
        })
    }

    // 点击驳回按钮
    approvalReject = () => {
        this.setState({
            isAuditReject: true,
            liyouModal: true
        })
    }

    // 点击强制下架
    onTapForceOffShelves = () => {
        this.setState({
            isAuditReject: false,
            liyouModal: true
        })

    }
    // 输入驳回或者下架理由
    textareaChange = (e) => {
        this.setState({
            auditInfo: e.target.value
        })
    }

    // 弹窗 确定
    handleOk = () => {
        if (this.state.isAuditReject) { // 驳回
            this.approveReject()
        } else { // 强制下架
            this.forceOffShelves()
        }
    }
    // 审核驳回
    approveReject = () => {
        let that = this;
        const config = {
            api: API.sourcingAudit,
            data: {
                reason: this.state.auditInfo,
                inquiryCode: this.state.inquiryCode,
                type: 3 //审核类型 2：通过，3：驳回，4：强制下架
            },
            showLoading: true,
            successTip: '操作成功'
        }
        http(config).then(res => {
            if (res && res.success) {
                that.setState({ liyouModal: false })
                that.refreshData()
            }
        })
    }

    // 去下架
    forceOffShelves = () => {
        let that = this
        const config = {
            api: API.sourcingForceOffShelves,
            data: {
                reason: this.state.auditInfo,
                inquiryCode: this.state.inquiryCode,
                type: 4,
            },
            showLoading: true,
            successTip: '下架成功'
        }
        http(config).then(res => {
            if (res && res.success) {
                that.setState({ liyouModal: false })
                that.refreshData()
            } else {
                if (res.code == '300001') {
                    Modal.info({
                        title: '信息有变更',
                        icon: null,
                        content: '询价信息有变更，请确认变更内容后重新操作',
                        onOk: () => {
                            that.refreshData()
                        }
                    })
                }
            }
        })
    }

    productListColumns = [
        {
            title: '物料编码',
            width: 120,
            dataIndex: 'materialCode',
            key: 'materialCode',
            render: (text, record) => {
                return (<span>{text ? text : '-'}</span>);
            }
        }, {
            title: '物料名称',
            width: 120,
            dataIndex: 'materialName',
            key: 'materialName',
            render: (text, record) => {
                return (<div>{text ? text : '-'}</div>)
            }
        }, {
            title: '品牌',
            width: 120,
            dataIndex: 'materialBrand',
            key: 'materialBrand',
            render: (text, record) => {
                return (<span>{text ? text : '-'}</span>)
            }
        }, {
            title: '规格型号',
            width: 120,
            dataIndex: 'materialSpecification',
            key: 'materialSpecification',
            render: (text, record) => {
                return (<div>{text ? text : '-'}</div>)
            }
        }, {
            title: '物料描述',
            dataIndex: 'materialDesc',
            key: 'materialDesc',
            render: (text, record) => {
                return (<div>{text ? text : '-'}</div>);
            }
        }, {
            title: '采购量',
            width: 120,
            dataIndex: 'materialPurchaseNum',
            key: 'materialPurchaseNum',
            render: (text, record) => {
                return (<span>{text ? text : '-'}</span>);
            }
        },
        {
            title: '单位',
            width: 120,
            dataIndex: 'materialUnit',
            key: 'materialUnit',
            render: (text, record) => {
                return (<span>{text ? text : '-'}</span>);
            }
        }
    ]
    // 设置图片
  closeImagePreview = () => {
    console.log("设置图片", this.state.previewVisible);
    this.setState({ previewVisible: false });
  };
  // 设置图片缩放
  cImage = (imgList, currentIndex =0, isForArr) => {
    console.log('imgList', imgList)
    imgList = isForArr ? imgList.map(item => {
      return item.url || item.src || item;
    }) : imgList;
    this.setState({
      previewVisible: true,
      // IMGURL: value,
      imgList,
      currentIndex: currentIndex
    });
  };

    render() {
        let { sourcingDetail, isAuditReject, auditInfo, sensitiveWordsHasChecked, previewVisible, currentIndex, imgList } = this.state
        let mediaList = sourcingDetail.mediaList || [];

        //报名开始时间
        var baomingStartTime = sourcingDetail.quoteStartTime;
        if(!baomingStartTime){
            baomingStartTime =   sourcingDetail.createTime;
        }
        
        // 收货截止时间
        //TODO 写个util方法，OK ？
       var expectDeliveryStartTime = sourcingDetail.expectDeliveryStartTime ?( typeof sourcingDetail.expectDeliveryStartTime === 'number'?moment(sourcingDetail.expectDeliveryStartTime).format("YYYY-MM-DD HH:mm"):sourcingDetail.expectDeliveryStartTime ): '-';
       var expectDeliveryEndTime =    sourcingDetail.expectDeliveryEndTime ?( typeof sourcingDetail.expectDeliveryEndTime === 'number'?moment(sourcingDetail.expectDeliveryEndTime).format("YYYY-MM-DD HH:mm"):sourcingDetail.expectDeliveryEndTime ): '-';


        return (
            <div className='sourcing-detail-page'>
                <div className="intention-list-head detail-nav">
                    <Breadcrumb className="intention-list-head-bread">
                        <Breadcrumb.Item>
                            <Link to={{ pathname: "/app/crm/sourcing" }}>
                                询价管理
                            </Link>
                        </Breadcrumb.Item >
                        <Breadcrumb.Item>
                            询价单详情
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className="sourcing-content">
                    <Descriptions bordered title="询价信息" className="table-part">
                        <Descriptions.Item label="标题" span={3}>{sourcingDetail.title || '-'}</Descriptions.Item>
                        <Descriptions.Item label="编号">{sourcingDetail.inquiryNo || '-'}</Descriptions.Item>
                        <Descriptions.Item label="产品类型">{getProductTypeName(sourcingDetail.productType)}</Descriptions.Item>
                        <Descriptions.Item label="状态">{getPublishStatusDesc(sourcingDetail.status)}</Descriptions.Item>
                        <Descriptions.Item label="发布人">{sourcingDetail.createNickName || '-'}</Descriptions.Item>
                        <Descriptions.Item label="发布人电话">{sourcingDetail.createUserPhone || '-'}</Descriptions.Item>
                        <Descriptions.Item label="发布时间">{  sourcingDetail.createTime ?( typeof sourcingDetail.createTime === 'number'?moment(sourcingDetail.createTime).format("YYYY-MM-DD HH:mm"):sourcingDetail.createTime ): '-'}</Descriptions.Item>
                        <Descriptions.Item label="所属企业" span={3}>{sourcingDetail.purchaserName}</Descriptions.Item>
                    </Descriptions>
                    <Descriptions bordered title="采购要求" className="table-part" >
                       
                     
                       <Descriptions.Item label="报价开始时间"> {   baomingStartTime ?( typeof  baomingStartTime === 'number'?moment(baomingStartTime).format("YYYY-MM-DD HH:mm"):baomingStartTime ): '-'}</Descriptions.Item>
                        <Descriptions.Item label="报价截止时间"> {  sourcingDetail.quoteEndTime ?( typeof sourcingDetail.quoteEndTime === 'number'?moment(sourcingDetail.quoteEndTime).format("YYYY-MM-DD HH:mm"):sourcingDetail.quoteEndTime ): '-'}</Descriptions.Item>
                        <Descriptions.Item label="期望收货时间">{ expectDeliveryStartTime + '~' +  expectDeliveryEndTime}</Descriptions.Item>
                        <Descriptions.Item label="交货期限">{'自下单后' + sourcingDetail.deliveryDay + '天内到指定地点'}</Descriptions.Item>
                        <Descriptions.Item label="收货地址" span={2}>{sourcingDetail.deliveryAddress}</Descriptions.Item>
                        <Descriptions.Item label="补充说明" span={3}>
                             {sourcingDetail.remark}
                        </Descriptions.Item>
                    </Descriptions>
                    <Descriptions bordered title="询价方式及隐私" className="table-part">
                        <Descriptions.Item label="询价类型">{getInquiryTypeDesc(sourcingDetail.inquiryType)}</Descriptions.Item>
                        <Descriptions.Item label="报价过程">{getQuoteTypeDesc(sourcingDetail.quoteType)}</Descriptions.Item>
                        <Descriptions.Item label="联系方式">{sourcingDetail.contactName + ' ' + sourcingDetail.contactPhone}</Descriptions.Item>
                    </Descriptions>
                    <div className='product-list-title'>产品清单</div>
                    <Table className="table-part" dataSource={sourcingDetail.productList} columns={this.productListColumns} pagination={false} bordered={true} style={{ marginBottom: 20 }}></Table>
                    <Descriptions bordered className="table-part" style={{ marginBottom: 20 }}>
                        <Descriptions.Item label="附件" >
                            {
                               (mediaList&&mediaList.length > 0) ? mediaList.map((item) => {
                                   if(item.type == 'png' || item.type == 'jpg'){
                                       return ( <p><a onClick={(e) => {e.preventDefault(); this.cImage([item.url])}} href={item.url}>{item.name}</a></p> )
                                   }
                                return ( <p><a href={item.url} download={item.name}>{item.name}</a></p> )
                                }) : <span>无</span>
                            }
                        </Descriptions.Item>
                    </Descriptions>
                    <div className="approval-record" onClick={this.watchRecord}>审批记录</div>
                </div>
                <div className="action-part">
                    {   //1：待审核，2：已通过，3：已驳回，4：强制下架
                        (sourcingDetail.auditStatus == 1 && auditAuth) ?
                            <span>
                                {/*<Button className="sensitive-words-check" onClick={this.getSensitiveCheck}>敏感词校验</Button>
                                <Button className={sensitiveWordsHasChecked ? "action-button-active" : 'action-button-disabled'} disabled={!sensitiveWordsHasChecked} onClick={this.approvalThrough}>通过</Button>
                                <Button className={sensitiveWordsHasChecked ? "action-button-active" : 'action-button-disabled'} disabled={!sensitiveWordsHasChecked} onClick={this.approvalReject}>驳回</Button>*/}

                                <Button className={  "action-button-active" }  onClick={this.approvalThrough}>通过</Button>
                                <Button className={ "action-button-active" }  onClick={this.approvalReject}>驳回</Button>
                            </span> : (sourcingDetail.auditStatus == 2 && offShevesAuth) ?
                                <span>
                                    <Button className="action-button-active" onClick={this.onTapForceOffShelves}>违规下架</Button>
                                </span> : null
                    }

                </div>
          <ImgPreview show={previewVisible} current={currentIndex} imgList={imgList} onClose={this.closeImagePreview}/>

                <Modal
                    title={isAuditReject ? '驳回' : '违规下架'}
                    visible={this.state.liyouModal}
                    okButtonProps={{ disabled: !auditInfo }}
                    onOk={this.handleOk}
                    onCancel={() => { this.setState({ liyouModal: false, auditInfo: null }) }}
                >
                    <div>
                        <span className="modal_tit">{isAuditReject ? '驳回理由' : '下架理由'}</span>
                        <TextArea
                            maxLength={100}
                            value={auditInfo}
                            onChange={this.textareaChange}
                            placeholder={isAuditReject ? '请输入驳回理由（100字以内）' : '请输入下架理由（100字以内）'}>
                        </TextArea>
                    </div>
                </Modal>

            </div >
        );
    }

}