import React, { Component } from 'react';
import { Icon } from 'antd';
const StepsLine = (props) => {
    return (
        <span className={props.flag? 'bo-steps-line line-pass' : 'bo-steps-line line-static'}></span>
    )
}
const StepsNode = (props) => {
    // status 0 未开始 1 通过 2 待审批 3 驳回
    let className = ''
    switch(props.status) {
        case 1:
            return (
                <span className="bo-steps-node-pass">
                    <Icon type="check-circle" theme="filled" style={{color: '#FBBC06', fontSize: "24px"}} />
                    <span className="bo-steps-node-text text-pass">{props.name || '审批通过'}</span>
                </span>
            )
        case 2:
            return (
                <span className="bo-steps-node-pedding">
                    <span className="bo-steps-node-pedding-inner"></span>
                    <span className="bo-steps-node-text text-pedding">{props.name || '待审批'}</span>
                </span>
            )
        case 3:
            return (
                <span className="bo-steps-node-reject">
                    <Icon type="minus-circle" style={{color: '#e94437', fontSize: "24px"}} />
                    <span className="bo-steps-node-text text-reject">{props.name || '审批驳回'}</span>
                </span>
            )
        default:
            return (
                <span className="bo-steps-node-static">
                    <span className="bo-steps-node-text text-static">{props.name || '未开始'}</span>
                </span>
            )
    }
}
export default function BOSteps(props) {
    const { nodeNum, current, currentNodeStatus, nodeNameList } = props.steps
    const nodeStatusList = new Array(nodeNum).fill(0)
    const nameList = nodeNameList ? nodeNameList : []
    return (
        <div className="bo-steps">
            {nodeStatusList.map((per, i) => {
                if (i === current - 1) {
                    if (i === nodeStatusList.length - 1) {
                        return (<StepsNode key={i} status={currentNodeStatus} name={nameList[i]} />)
                    } else {
                        return (<span key={i}><StepsNode status={currentNodeStatus} name={nameList[i]} /> <StepsLine flag={false} /></span>)
                    }
                } else if (i < current) {
                    return (<span key={i}><StepsNode status={1} name={nameList[i]} /> <StepsLine flag={true} /></span>)
                } else {
                    if (i === nodeStatusList.length - 1) {
                        return <StepsNode key={i} status={0} name={nameList[i]} />
                    } else {
                        return (<span key={i}><StepsNode status={0} name={nameList[i]} /> <StepsLine flag={false} /></span>)
                    }
                }
            })}
        </div>
    )
}