import React, { Component } from "react";
import {  Modal,Checkbox,DatePicker, Input, message } from "antd";
 import Table from "CMPT/common/Table";
import http from "@/http";
import { API } from "@/http/api";
 import { Link } from 'react-router-dom';
 import moment from "moment";
 import TableRenderHelp from "@/utils/TableRenderHelp.js";
import { FilterType } from '@/utils/fliter';
import Select from 'CMPT/common/Select';
import {  GoodsChannel } from "CMPT/common.js";
import "./index.less";

const { Search } = Input;
const { RangePicker } = DatePicker;


class chooseGoods extends Component {

    state={
        selectData:[],//当前约定，返回数据 都能被选中
         columns:this.createColumns(),
        httpData:[],
        total:0,

        filterConfig:this.createFilterConfig(),// 过滤条的配置

        startDateMoment: undefined,
        endDateMoment: undefined,

        searchValue: '',
        categoryOneList: [],
        categoryTwoList: [],
        categoryThreeList: [],
        shopList: [],
 
         params: {
           categoryOne: undefined,
           categoryTwo: undefined,
           categoryThree: undefined,
           shop: undefined,
           pageSize: 10,
           pageNo: 1,
       }

    }


    onSelectChange(e,data){
      var checked = e.target.checked;
      let {selectData,httpData} = this.state;

      // 选中的那一项
      data.checked = checked;


      // 已选中
      if(checked){
          if(selectData.indexOf(data)<0){
              selectData.push(data);
              this.setState({httpData,selectData});
          }
      }else{
          if(selectData.indexOf(data)>=0){
              selectData.remove(data);
              this.setState({httpData,selectData});
          }
      }


    }

    createColumns(){
        let help = new TableRenderHelp();
  
        return [
            help.createRenderColumn(
                "",  "code", "code", 
                (text, record) => {
                  return   <Checkbox checked={record.checked} onChange={(e)=>this.onSelectChange(e,record )}/> ;
                }
              ),
              help.createTextColumn("商品编号", "itemNo"),
              help.createTextColumn("商品分类", "categoryName"),
               help.createRenderColumn(
                "商品名称",  "itemName", "itemName", 
                (text, record) => {
                  return   <span className="table-operate-btn">
                  <Link to={'/app/crm/goodsManage/'+record.code} target="_blank" className="operate">{text}</Link>
                  </span>;
                }
              ),
               help.createRenderColumn(
                "渠道",  "channelId", "channelId", 
                (text, record) => {
                  return <span>{text?(GoodsChannel[text]?(GoodsChannel[text]):"-"):'-'}</span>;
                }
              ),
              help.createTextColumn("价格", "lowPrice"),
              help.createTextColumn("店铺", "shopName"),
              help.createTextColumn("状态", "statusName"),
               help.createTextColumn("更新时间", "updateTime"),
            
            {
              title: '操作',
              render: (record, index) => <div>
                   {/** TODO  后期应该对 快速点击 做屏蔽，同时 对请求做 等待处理 */}
                  <span className="text-color-highlight" style={{marginRight:'16px', whiteSpace: 'nowrap'}} onClick={() => { this. doAddByOne(record) }}>添加</span> 
                  <span className="text-color-highlight" style={{marginRight:'16px', marginTop:'10px',whiteSpace: 'nowrap'}} onClick={() => { this.addAndTopByOne(record) }}>添加并置顶</span>
               </div>
          }
        ];
  
      }

      componentDidMount(){
          this.getGoodsList();
          this.getCategory();
          this.searchShop();
      }

      /**
       * 查询店铺，用来展示 店铺列表
       * @param {*} keyWord 
       */
      searchShop = (keyWord) => {
        const config = {
            api: API.commonShopList,
        }
        if (keyWord) {
            config.data = {
                shopName: keyWord
            }
        }
        http(config).then(res => {
            if (res&&res.success) {
                const shopList = res.data && res.data.map(per => {
                    return {
                        value: per.code,
                        name: per.shopName
                    }
                }) || []
                this.setState({shopList})
            }
        })
      }
     
      /**
       * 查询商品分类，用在 分类选择
       */
      getCategory = () => {
        const config = {
            api: API.commonCategory,
        }
        http(config).then(res => {
            if (res.success) {
                let categoryOneList = res.data && res.data.map(per => {
                    return {
                        name: per.name,
                        value: per.code,
                        childList: per.childList && per.childList.map(ii => {
                            return {
                                name: ii.name,
                                value: ii.code,
                                childList:ii.childList && ii.childList.map(iii => {
                                  return {
                                      name: iii.name,
                                      value: iii.code,
                                   }
                              })
                            }
                        })
                    }
                }) || []
                this.setState({
                    categoryOneList
                })
            }
        })
    }
  
   
   /**
    * 去搜索
    * @param {*} keywords 
    */
    onSearchOn = (keywords) => {
      let {params} = this.state;
      params.pageNo =1;
   

      this.setState({
        activeKey: '', 
        params,
        searchValue: keywords
      },  
        this.getGoodsList  
      )
  }
  
       // 输入搜索框
       searchChange = (e) => {
        this.setState({searchValue: e.target.value})
    }

      /**
       * 全选
       * @param {*} e 
       * @returns 
       */    
      selectAll=(e)=>{
        let {httpData} = this.state;
        if(!httpData || !httpData.length){
            return;
        }

        const checked = e.target.checked;
         var selectData=[];// 如果全不选，这里就是0长度的数组

       
          httpData.forEach(function(e) {
            e.checked = checked; // 数据设置为不可选，界面会相应变化

            if(checked){//当前 全选，才加入数组
               selectData.push(e);
            }
           });
      


         this.setState({selectData,httpData});
      }

      /**
       * 触发  批量添加/置顶
       */
      addAndTopBatch=()=>{
        let {selectData}=this.state;
        this.addAndTop(selectData);

    }


    doAddBatch=()=>{
      let {selectData}=this.state;
      this.doAdd(selectData);
    }

    /**
     * 单个 添加 置顶
     * @param {*} data 
     */
    addAndTopByOne=(data)=>{
       this.addAndTop(new Array(data));
    }

    /**
     * 单个添加
     * @param {*} data 
     */
    doAddByOne=(data)=>{
       this.doAdd(new Array(data));
    }

    /**
     * 批量的去执行 添加置顶
     * @param {*} dataArray 
     */
    addAndTop=(dataArray)=>{
      var data=[];
      if(dataArray&&dataArray.length){
      dataArray.forEach(item=>{
          var newItem ={...item }
          newItem.flagStatus = 1;
          data.push(newItem); //1 标识 添加并且置顶
       });
      }

      this.doUpdate(data);

    }

    /**
     * 批量的去执行 添加
     * @param {*} dataArray 
     */
    doAdd=(dataArray)=>{
      var data=[];
      if(dataArray&&dataArray.length){
        dataArray.forEach(item=>{
          var newItem ={...item }
          newItem.flagStatus = 0;//0 标识 添加不置顶
          data.push(newItem);
         });
      }

      this.doUpdate(data);
    }


        /**
     * 添加/置顶实际是 update操作
     * @param {*} data 
     */
         doUpdate=(dataSource)=>{
          // 不能带时间
          var data = [];
          if(dataSource&&dataSource.length){
            dataSource.forEach(item=>{

            var newItem = {...item};
            newItem.updateTime = undefined;//0 标识 添加不置顶
            newItem.channel = item.channelId;
            newItem.channelId= undefined;
            newItem.channelType=0;
            newItem.salePrice = item.lowPrice;
            //newItem.lowPrice = undefined;
            newItem.auditStatus = 10;
            newItem.checked = undefined;

            data.push(newItem);
          });
       }
          
 
             // setup 1:基本入参
        const config = {
          api: API.goodsInsertBatch,
          data 
         };
   
       // setup 2:请求，并初步处理
         http(config).then(res => {
           if (res && res.success && res.data) {
            message.success("添加成功");

              this.getGoodsList();
              if(this.props.refresh){
                this.props.refresh();
             }
           }
       });
     }


    handleDate = (value, date) => {
      let {params} = this.state;
      params.pageNo =1;

      // 重新查询
      this.setState( {
          params,
          startDateMoment: value[0],
          endDateMoment: value[1]
        }, this.getGoodsList
      );
    };

    createFilterConfig(){
        return {
           config:[
            this.createFilterRow()
            ],
            btnLast:true
    
        } ;
      }
    
    
      createFilterRow(){
        return {
          childList:[
    
            {
                type:FilterType.DATE_RANGE,
                label:'注册日期',
                config:{
                  field:['createdStartTime','createdEndTime'],
                }
              },      
            {
              type:FilterType.INPUT,
              //label:'意向单编号',
              config:{
                field:'keyword',
                placeholder:'请输入企业名称,企业简称'
              }
            },
            
    
          ]
        }
      }

  
  onFilterParmChange=(filterParm)=>{
    //this.setState({filterParm,pageNo:1},this.goQuery);// 每次搜索，从第1页取数据
  }

   


    close=()=>{
        if(this.props.close){
            this.props.close();
        }
            
    }

       //分页
       onChangePage = (pageNo, pageSize, sort) => {
         let {params} = this.state;
         params.pageNo =pageNo;
         params.pageSize =pageSize;

          this.setState( { params },this.getGoodsList );
      };

    // 获取商品列表
getGoodsList = () => {
  const {params,startDateMoment,endDateMoment,searchValue} = this.state;
  const cateCode = params.categoryThree|| (params.categoryTwo || params.categoryOne)
  const config = {
      api: API.itemNoRecommendList,
      data: {
          keyword:  searchValue,
          orderByList: [0],
          orderList: [0],
          pageNo: params.pageNo,
          pageSize: params.pageSize,
          status: 2,//销售中
          categoryCode: cateCode,
          startTime: startDateMoment? moment(startDateMoment).format("YYYY-MM-DD"):undefined,
          endIime: endDateMoment?  moment(endDateMoment).format("YYYY-MM-DD"):undefined,
          shopCodeList: params.shop ? [params.shop] : null
      },
      showLoading: true
  }

  http(config).then(res => {
      if (res && res.success && res.data && res.data.datas) {
          this.setState({
              selectData :[],
              httpData: [...res.data.datas],
              total: res.data.totalRecord
          })
      }
  })
} 


  // 重置
  resetSearch = () => {
    var params= {
      categoryOne: undefined,
      categoryTwo: undefined,
      categoryThree: undefined,
      shop: undefined,
      pageSize: 10,
      pageNo: 1,
  }
    this.setState({ 
      startDateMoment: undefined,
      endDateMoment: undefined,
      searchValue: "",
      params 
      },  
      this.getGoodsList
     );
  };

    selectChange = (attr, data) => {
      if (attr === 'categoryOne') {
          const categoryOneList = this.state.categoryOneList
          const categoryTwoList = categoryOneList.find(per => per.value === data)
          if (categoryTwoList) {
              this.setState({
                  categoryTwoList: categoryTwoList.childList
              })
          }
      }else if(attr === 'categoryTwo'){
        const categoryTwoList = this.state.categoryTwoList
        const selected = categoryTwoList.find(per => per.value === data)
        if (selected) {
            this.setState({
                 categoryThreeList: selected.childList
            })
        }
      }
      let params = this.state.params
      params[attr] = data
      if (data === 99) {
          params[attr] = undefined
      }
      params.pageNo = 1;
      
      this.setState({ params }, this.getGoodsList)
    }

    createFooter=()=>{
        let {httpData,selectData} = this.state;
        var isAllSelected =  httpData? (selectData.length==httpData.length && selectData.length>0 ):false;
        var isNoneSelected = !(selectData&&selectData.length);

        return (<div className="footer">
            <Checkbox   checked={isAllSelected} onChange={ this.selectAll} /> 
            <span className={isAllSelected?"text-color-disable": "text-color-highlight"} style={{ marginLeft:'2px', whiteSpace: 'nowrap'}}  >全选</span>  
        <span className={isNoneSelected?"text-color-disable": "text-color-highlight"} style={{marginRight:'16px',marginLeft:'16px', whiteSpace: 'nowrap'}} onClick={ this.doAddBatch   }>添加</span> 
        <span className={isNoneSelected?"text-color-disable": "text-color-highlight"} style={{marginRight:'16px', whiteSpace: 'nowrap'}} onClick={ this.addAndTopBatch  }>添加并置顶</span>
         </div>);
    }

    render() {

      let { columns,httpData,filterConfig,startDateMoment,endDateMoment,params,total,searchValue,shopList,categoryOneList,categoryTwoList,categoryThreeList} = this.state;
      let {pageNo} =params;
      
        return (<Modal 
            title={`添加推荐商品(仅能添加销售中商品)`}
            visible={true}
            cancelText=""
            onCancel={this.close}
            width={1200} 
            footer={ []  }
            className="chooseshop_modal">
             
            {/*<FilterPanel data={filterConfig}   onFilterChange={this.onFilterParmChange}/>*/}
                {/** 先复制过来 实现。后续 改为组件  20211014  */}
           <div className="goods_fliter">
                        <span className="status_my">销售中状态</span>
                        <Select data={ categoryOneList} placeholder="一级分类" value={params.categoryOne}
                            selectMethod={this.selectChange.bind(this, 'categoryOne')} style={{ width: '124px' }} />
                        <Select data={ categoryTwoList} placeholder="二级分类" value={params.categoryTwo}
                            selectMethod={this.selectChange.bind(this, 'categoryTwo')} style={{ width: '124px' }} />
                      <Select data={ categoryThreeList} placeholder="三级分类" value={params.categoryThree}
                            selectMethod={this.selectChange.bind(this, 'categoryThree')} style={{ width: '124px' }} />

                        <Select data={ shopList} placeholder="店铺" value={params.shop} showSearch
                                filterOption={false} onSearch={this.searchShop}
                                selectMethod={this.selectChange.bind(this, 'shop')} style={{ width: '124px' }} />
                                 <span>
              <span style={{ marginRight: "5px" }}>更新日期</span>
              <RangePicker
                value={[startDateMoment, endDateMoment]}
                onChange={this.handleDate}
                style={{ width: "250px", marginRight: "12px" }}
              />
            </span>
                        <Search
                            placeholder="请输入商品名称,商品编码"
                            enterButton="搜索"
                            className="goods_search"
                            value={searchValue}
                            // size="large"
                            style={{   marginTop: "12px" }}
                            onChange={value => {this.searchChange(value)}}
                            onSearch={value => {this.onSearchOn(value)}}
                        />
                <span className="goods_reset"   style={{   marginTop: "12px" }}  onClick={this.resetSearch}>重置</span>
                    </div>

           <Table
           columns={ columns}
          rowKey={(record, index) => record.encode}
          selfProcessData={httpData}
          total={total}
          change={this.onChangePage}
          current={ pageNo}
          footer={  this.createFooter}
        />
            
            </Modal>);
    }
}

export default chooseGoods;
