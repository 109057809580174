const projectstatsCustomer = (statses) => {

    let totalCount = 0;
    statses.map(item => {
        totalCount += item.cnt;
    });

    let colors = [];
    if (statses.length <= 8) {
        colors = ['#0098fb', '#00cc6f', '#ffd45a', '#fa666c', '#9961e1', '#575bed', '#3d4e95', '#536397'];
    } else if (statses.length == 9) {
        colors = ['#0098fb', '#00cc6f', '#a7df89', '#ffd45a', '#fa666c', '#9961e1', '#575bed', '#3d4e95', '#536397'];
    } else if (statses.length == 10) {
        colors = ['#0098fb', '#00cc6f', '#a7df89', '#ffd45a', '#ffa656', '#fa666c', '#9961e1', '#575bed', '#3d4e95', '#536397'];
    } else {
        colors = ['#0098fb', '#00cc6f', '#a7df89', '#ffd45a', '#ffa656', '#fa666c', '#9961e1', '#575bed', '#3d4e95', '#536397', '#9daee5'];
    }

    let data = statses.map((item, index) => {
        return {
            value:item.cnt, name:`${item.industryDesc}: ${totalCount?(item.cnt * 100.0 / totalCount).toFixed(1):0}%`, itemStyle: {color: colors[index % colors.length]}
        }
    });

    return data;
};

const projectstatsBarData = (type, data) => {
    let xaxisData = data.map(item => {
        return item.projectName;
    });

    let yaxisData = [];
    if (type == 'price') {
        yaxisData = data.map(item => {
            return item.price;
        });
    } else if (type == 'area') {
        yaxisData = data.map(item => {
            return item.dealArea;
        });
    } else if (type == 'sale') {
        yaxisData = data.map(item => {
            return item.amount;
        });
    }

    return {xaxisData, yaxisData};
};

const monthInfo = () => {
    let myDate = new Date();
    let year = myDate.getFullYear(); 
    let month = myDate.getMonth() + 1;
    let current = `${year}-${month}月`

    if (month - 6 > 0) {
        month = month - 6;
    } else {
        year = year - 1;
        month = month + 6;
    }
    let halfYearBefore = `${year}-${month}月`
    return {current, halfYearBefore};
};

export {
    projectstatsCustomer,
    projectstatsBarData,
    monthInfo
}