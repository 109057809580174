import {
    AJAX_TYPE
} from '../config';
const prefix = 'label';
export default {
    [`${prefix}BatchSet`]: {
        url: '/web/crm/label/set',
        type: AJAX_TYPE.POST
    },
    [`${prefix}List`]: {
        url: '/web/crm/label/getLabelByType/:type',
        type: AJAX_TYPE.GET
    } ,
    [`${prefix}BatchCancel`]: {
        url: '/web/crm/label/cancel',
        type: AJAX_TYPE.POST
    },
}