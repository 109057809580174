import React, { Component } from 'react'
import { Input } from 'antd';

class NumericInput extends React.Component {
    onChange = e => {
        const { value } = e.target;
        console.log(value)
        // 建筑面积禁止输入负数，且最多保留至小数点后两位
        // const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]{0,2})?$/;
        const reg = /^(0|[1-9][0-9]{0,5})(\.[0-9]{0,2})?$/;
        if ((!Number.isNaN(value) && reg.test(value)) || value === '') {
            this.props.onChange(value);
        }
    };

    // '.' at the end or only '-' in the input box.
    onBlur = () => {
        const { value, onBlur, onChange } = this.props;
        if (value && (value.charAt(value.length - 1) === '.')) {
            onChange(value.slice(0, -1));
        }
        if (onBlur) {
            onBlur();
        }
    };

    render() {
        return (
            <Input
                {...this.props}
                onChange={this.onChange}
                onBlur={this.onBlur}
            />
        );
    }
}

export { NumericInput }