import http from "@/http";
import { API } from "@/http/api";
import { formatLinkSearch } from "@/utils";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table from "CMPT/common/Table";
import SearchForm from "./searchForm";
import moment from "moment";

const openAccountTypeMap = {
  0: "个人",
  1: "企业",
};

// 筛选项默认值
const defaultParams = {
  pageNo: 1, // 需要查询的页数
  pageSize: 10, // 每页条数
  name: "", // 商户名称
  unifiedSocialCode: "", // 统一社会信用代码
  // type: '', // 商户类型
  contactName: "", // 联系人姓名
  contactMobile: "", // 联系人手机号
  // contactEmail: '', // 联系人邮箱
  status: "", // 状态
  submitDateState: "", // 需求开始时间
  submitDateEnd: "", // 需求结束时间
  completeDateStart: "", // 申请开始时间
  completeDateEnd: "", // 申请结束时间
};
class EquipmentList extends Component {
  state = {
    total: 0, // 当前数据的总数
    pageNo: 1, // 当前所展示的页码
    currentPageCount: 0, //当前页面的数量
    selectParams: {
      // 接口入参，必须放这里。方便分页时保存信息
      auditStatus: this.props.state, // 审批状态
      ...defaultParams,
    },
    columns: [
      // {
      //   title: '序号',
      //   width: "60px",
      //   key: "id",
      //   render: (text, record, index) => {
      //       return (<span>{index + 1}</span>);
      //   },
      // },
      {
        title: "设备编号 ",
        key: "equipmentNumber",
        align: "center",
        dataIndex: "equipmentNumber",
      },
      {
        title: "设备名称",
        key: "equipmentName",
        align: "center",
        dataIndex: "equipmentName",
      },
      {
        title: "设备类型",
        key: "primaryTypeName",
        align: "center",
        dataIndex: "primaryTypeName",
        render: (text, record) => {
          return (
            <span>{text + ' / ' + record.secondaryTypeName}</span>
          );
        },
      },
      {
        title: "设备所在地",
        key: "provinceName",
        align: "center",
        dataIndex: "provinceName",
        render: function(text, record) {
          return text+'/'+ record.cityName +'/' + record.areaName
        }
      },
      {
        title: "创建人",
        key: "creator",
        align: "center",
        dataIndex: "creator",
      },
      {
        title: "所属企业",
        key: "affiliatedEnterprise",
        align: "center",
        dataIndex: "affiliatedEnterprise",
      },
      {
        title: "创建时间", // 0 表示待审核状态
        key: "creationTime",
        align: "center",
        dataIndex: "creationTime",
        render: (text, record) => {
          return (
            <span>{text ? moment(text).format("YYYY-MM-DD HH:mm") : "-"}</span>
          );
        },
      },
      {
        title: "设备状态",
        key: "useStatusName",
        align: "center",
        dataIndex: "useStatusName",
      },
      {
        title: "出租状态",
        key: "releaseStatusName",
        align: "center",
        dataIndex: "releaseStatusName",
      },
      {
        title: "产权",
        key: "relationshipTypeName",
        align: "center",
        dataIndex: "relationshipTypeName",
      },
      {
        title: "操作",
        key: "operate",
        align: "center",
        width: 120,
        render: (text, record, index) => {
          return (<span className="table-operate-btn">
              <Link
                to={
                  "/app/crm/vehicleAndEquipment/equipmentDetail/" +
                  record.equipmentReleaseCode +
                  "?pageNo=" +
                  this.state.pageNo +
                  "&currentPageCount=" +
                  this.state.currentPageCount
                }
                style={{ color: "#2E4AFF" }}
              >
                查看详情
              </Link>
            </span>)
        },
      },
    ],
    data: [],
  };

  componentWillMount() {
    if (this.props.location) {
      // 获取参数
      const search = this.props.location.search;
      const formatData = formatLinkSearch(search);

      // 解析
      let pageNo1 = null;
      if (formatData && formatData.backPageNo) {
        pageNo1 = formatData.backPageNo;
      }

      // 赋值
      this.setState({
        selectParams: {
          pageNo: pageNo1, // 当前页数
          pageSize: 10, // 每页条数
        },
      });
    }
  }

  //初始化
  componentDidMount() {
    this.getLists();
  }
  //查询列表
  getLists = () => {
    const httpParams = { ...this.state.selectParams };
    
    for (let [key, value] of Object.entries(httpParams)) {
      if (value === -1 || value === "") {
        httpParams[key] = undefined;
      }
    }
    const config = {
      api: API.vAndEEquipmentListPage,
      data: { ...httpParams },
      showLoading: true,
    };

    http(config).then((res) => {
      if (res.code == 200) {
        const data = res.data;
        this.setState({
          data: data.datas || [],
          total: Number(data.total),
          pageNo: data.pageNo,
          currentPageCount: data.datas ? data.datas.length : 0,
          selectParams: { ...this.state.selectParams, pageNo: data.pageNo },
        });
      }
    });
  };

  handleDelete = (code) => {
    const config = {
      api: API.sourcingFreightAuditForcedDown,
      data: {
        code: code,
      },
    };
    http(config).then((res) => {
      if (res && res.code == 200) {
        this.getLists();
      }
    });
  };

  //分页
  onChangePage = (pageNo, pageSize, sort) => {
    this.setState(
      {
        selectParams: {
          ...this.state.selectParams,
          pageNo: pageNo,
          pageSize: pageSize,
        },
      },
      () => {
        this.getLists();
      }
    );
  };

  // 搜索
  onValuesChange = (allValues) => {
    this.setState({
      selectParams: { ...this.state.selectParams, ...allValues },
    }, () => this.getLists());
  };

  reset = (allValues) => {
    this.setState(
      {
        selectParams: { ...this.state.selectParams, ...defaultParams },
      },
      () => {
        this.getLists();
      }
    );
  };

  render() {
    const selectParams = this.state.selectParams;
    return (
      <div className="enterprise-auth-list">
        <div className="searchBox">
          <SearchForm
            // onValuesChange={this.onValuesChange}
            types={false}
            reset={this.reset}
            onSearch={this.onValuesChange}
          />
        </div>
        <Table
          columns={this.state.columns}
          rowKey={(record, index) => record.code}
          selfProcessData={this.state.data}
          total={this.state.total}
          change={this.onChangePage}
          current={selectParams.pageNo}
        />
      </div>
    );
  }
}

export default EquipmentList;
