import http from '@/http';
import { API } from '@/http/api';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, message, Timeline, Descriptions, Button } from 'antd';
import './Detail.less';
import { formatLinkSearch } from "@/utils";
import moment from "moment";
import { Status } from "./enum";
import AcceptDialog from "./AcceptDialog"
import Attachment from 'CMPT/common/Attachment';

const BreadcrumbItem = Breadcrumb.Item;

class Detail extends Component {

    state = {
        acceptObj: undefined,//要受理的对象

        records: undefined,//受理记录

        data: undefined,// 咨询数据本身
        code: undefined, // 查询详情需要的code
        tabInList: undefined,//返回列表需要的参数
        pageNoInList: undefined//返货列表需要的参数
    };

    componentDidMount() {
        let code = this.props.match.params.code;
        const formatData = formatLinkSearch(this.props.location.search);
        this.setState({ code, tabInList: formatData.tab, pageNoInList: formatData.pageNo }, this.init);
    }


    init = () => {
        this.getDetail();
    }

    getRecords = () => {
        // setup 1:基本入参
        const config = {
            api: API.consultAuditList,
            path: { code: this.state.code },
            showLoading: true
        };


        // setup 2:请求，并初步处理
        http(config).then(res => {
            if (res && res.code == 200 && res.data) {
                this.setState({ records: res.data });
            } else {
                message.error(res.message || '记录查询失败');
            }
        });
    }

    getDetail = () => {
        // setup 1:基本入参
        const config = {
            api: API.consultDetail,
            path: { code: this.state.code },
            showLoading: true
        };


        // setup 2:请求，并初步处理
        http(config).then(res => {
            if (res && res.code == 200 && res.data) {
                this.setState({ data: res.data }, this.getRecords);
            } else {
                message.error(res.message || '详情查询失败');
            }
        });
    }

    showAcceptDialog = (item) => {
        this.setState({ acceptObj: item });
    }

    hiddenAcceptDialog = () => {
        this.setState({ acceptObj: undefined });
    }

    refresh = () => {
        // 不修改任何 筛选条件
        this.getDetail();
    }



    render() {
        let { data, acceptObj, records, previewVisible, currentIndex, imgList } = this.state;
        return (<div className='container'>
            <Breadcrumb>
                <BreadcrumbItem>
                    <Link
                        to={{
                            pathname: "/app/crm/consultList",
                            search: "?tab=" + this.state.tabInList + "&pageNo=" + this.state.pageNoInList,
                        }}
                    >
                        咨询列表
                    </Link>
                </BreadcrumbItem>
                <BreadcrumbItem> 详细信息  </BreadcrumbItem>
            </Breadcrumb>

            <div className='content_box'>

                {/*   上半部分 */}
                <div className="content2">
                    <Descriptions bordered column={3} className="table-part">
                        <Descriptions.Item label="信息类型"  >{data && data.typeName || '-'}</Descriptions.Item>
                        <Descriptions.Item label="提交人"  >{(data && data.submitName || '') + (data && data.submitName ? '/' : '') + (data && data.submitPhone || '-')}</Descriptions.Item>
                        <Descriptions.Item label="提交时间"  >{data && data.submitDate ? moment(data.submitDate).format("YYYY-MM-DD HH:mm") : '-'}</Descriptions.Item>


                        <Descriptions.Item label="咨询信息" span={3}>{data && data.demandInfo || '-'}</Descriptions.Item>

                        <Descriptions.Item label="联系人"  >{(data && data.contactsName || '') + (data && data.contactsName ? '/' : '') + (data && data.contactsPhone || '-')}</Descriptions.Item>
                        <Descriptions.Item label="所属企业"  >{data && data.contactsCompany || '-'}</Descriptions.Item>
                        <Descriptions.Item label="职位"  >{data && data.contactsPosition || '-'}</Descriptions.Item>

                        <Descriptions.Item label="所属地区" span={3}>{data && data.contactsRegion || '-'}</Descriptions.Item>
                        <Descriptions.Item label="当前状态" span={3}>{data && Status.getTextFromValue(data.status) || '-'}</Descriptions.Item>

                        <Descriptions.Item label="附件" span={3} >
                            <Attachment data={data && data.fileList} />
                        </Descriptions.Item>
                    </Descriptions>

                    <div style={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {/* 除了 受理完成、无效信息  其他的都可以受理的. 状态 0待受理 1受理中 2受理完成 3无法受理 4无效信息 */}
                        {data && typeof (data.status) != "undefined" && data.status != Status.SOLVED && data.status != Status.INVALID && <Button onClick={() => this.showAcceptDialog(data)}
                        >受理</Button>}
                    </div>
                </div>


                {/*   受理记录 */}
                <div  >
                    <h1 style={{ marginBottom: '20px' }}>受理记录：</h1>

                    {records && records.length > 0 ? <Timeline>
                        {records.map(item =>
                        (<Timeline.Item color="gray" key={item.reviewerDate}>{moment(item.reviewerDate).format('YYYY-MM-DD HH:mm:ss')}
                            <h2 style={{ color: (item.reviewerStatus == Status.REJECT || item.reviewerStatus == Status.INVALID) ? '#FE3351' : '#2E4AFF' }}>{Status.getTextFromValue(item.reviewerStatus) || '-'}</h2>
                            {item.remark && <p>受理备注：{item.remark || '-'}</p>}
                            {item.reviewerName && <p>受理人：{item.reviewerName || '-'}</p>}
                        </Timeline.Item>)
                        )}
                    </Timeline> :
                        <div className='log-no-data'><div className="log-no-data-text">暂无数据</div></div>
                    }
                </div>


                {acceptObj && <AcceptDialog item={acceptObj} refresh={this.refresh} close={this.hiddenAcceptDialog} />}



            </div>
        </div>);
    }

}


export default Detail;