// 前端的数据存储模块

function set(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
}

function get(key) {
    const value = localStorage.getItem(key);
    return value && JSON.parse(value);
}

function remove(key) {
    localStorage.removeItem(key);
}

function clear() {
    // TODO:是否是全部清空
    localStorage.clear();
}

export const STORAGE_KEYS = Object.freeze({
    USER: 'USER',
    TIME_READ_VERIFICATION: '何时仗剑走天涯', // 忘记密码时读取验证码时间
    TIME_READ_VERIFICATION_CHANGE_PW: 'MAKE IT COUNT',// 修改密码时读取验证码时间
});

export default {
    set,
    get,
    remove,
    clear
}