import {
    AJAX_TYPE
} from '../config';

const prefix = 'vAndE'; //车辆设备

export default {
    // 设备列表
    [`${prefix}EquipmentListPage`]: {
        url: '/api/equipment/v1/basis/equipment/crm/listForPage',
        type: AJAX_TYPE.POST
    },
    // 设备详情
    [`${prefix}EquipmentDetail`]: {
        url: '/api/equipment/v1/basis/equipment/crm/detail/:equipmentReleaseCode',
        type: AJAX_TYPE.GET
    },
    // 车辆列表
    [`${prefix}VehicleListPage`]: {
        url: '/api/isx/v1/admin/manage/vehicle/list',
        type: AJAX_TYPE.POST
    },
    // /api/isx/v1/admin/manage/vehicle/getDetail/{code}
    [`${prefix}VehicleDetail`]: {
        url: '/api/isx/v1/admin/manage/vehicle/getDetail/:code',
        type: AJAX_TYPE.GET
    },
    // /api/isx/v1/commonDictionary/common/getDictionary/
    [`${prefix}GetDictionary`]: {
        url: '/api/isx/v1/commonDictionary/common/getDictionary/:bizType',
    },
    //获取身份类型筛选项
    [`${prefix}GetIdentityType`]: {
        url: '/api/isx/v1/admin/manage/vehicle/getIdentityType',
        type: AJAX_TYPE.GET
    },
}