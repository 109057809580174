import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { Form, Input } from "antd";
import moment from "moment";

export default withRouter(function OperatorAuth({ data, props, form }) {
  let OPERATOR_DATA;
  let QIYE_FLAG = "";
  const operatorInfoData = [
    { label: "姓名", field: "operatorName", isEdit: true },
    // { label: "昵称", field: "nickName" },
    { label: "手机号码", field: "mobile" },
    { label: "紧急联系人", field: "emergencyContact", isEdit: true },
    { label: "紧急联系人手机号码", field: "emergencyMobile", isEdit: true },
    { label: "身份证号码", field: "identityCode", isEdit: true },
  ];
  const { getFieldDecorator } = form;

  if (data.data && data.data.data.operatorName) {
    // 操作员
    OPERATOR_DATA = data.data.data;
    QIYE_FLAG = "操作员";
  }

  return (
    <>
      <div className="userInfo" style={{ padding: 0 }}>
        <div className="userInfo_body">
          {OPERATOR_DATA ? (
            <>
              <div className="userInfo_header">
                <div className="userInfo_title">
                  认证为
                  {/* <span>{DRIVER_DATA.audit.displayName}</span> */}
                  <span className="flag">{QIYE_FLAG}</span>
                </div>
                <p>
                  提交时间：
                  {moment(OPERATOR_DATA.subTime).format("YYYY-MM-DD HH:mm:ss")}
                </p>
                <div className="userInfo_right">
                  <span>{OPERATOR_DATA.auditStatusName}</span>
                  {/* <p onClick={()=>lookRecords()}>查看审核记录</p> */}
                  <p>
                    <Link
                      to={
                        "/app/crm/enterprise_auth/record/" + data.code + "/" + 4
                      }
                    >
                      查看审核记录
                    </Link>
                  </p>
                </div>
              </div>
              <div>
                <h2>
                  <i></i> 个人信息
                </h2>
                <div>
                  <ul>
                    {operatorInfoData &&
                      operatorInfoData.map((item) => {
                        return OPERATOR_DATA.auditStatus === 0 &&
                          item.isEdit ? (
                          <li key={item.field}>
                            <Form.Item label={item.label}>
                              {getFieldDecorator(
                                item.field,
                                { initialValue: OPERATOR_DATA[item.field] },
                                {
                                  rules: [
                                    {
                                      required: true,
                                      message: "请输入",
                                    },
                                  ],
                                }
                              )(<Input placeholder="Username" />)}
                            </Form.Item>
                          </li>
                        ) : (
                          <li key={item.field}>
                            <span>{item.label}</span>
                            <span className="span">
                              {OPERATOR_DATA[item.field]}
                            </span>
                          </li>
                        );
                      })}
                    <li className="body_img">
                      <span>身份证正反面</span>
                      {OPERATOR_DATA["identityFrontAttachment"] ? (
                        <img
                          src={OPERATOR_DATA["identityFrontAttachment"]}
                          alt=""
                          onClick={() =>
                            props.cImage(
                              [OPERATOR_DATA["identityFrontAttachment"], OPERATOR_DATA["identityBackAttachment"]], 0, true
                            )
                          }
                        />
                      ) : (
                        "-"
                      )}
                      {OPERATOR_DATA["identityBackAttachment"] ? (
                        <img
                          src={OPERATOR_DATA["identityBackAttachment"]}
                          alt=""
                          onClick={() =>
                            props.cImage(
                              [OPERATOR_DATA["identityFrontAttachment"], OPERATOR_DATA["identityBackAttachment"]], 1, true
                            )
                          }
                        />
                      ) : (
                        "-"
                      )}
                    </li>
                    <li className="body_img">
                      <span>驾驶证件</span>
                      {OPERATOR_DATA["drivingLicenceAttachment"] ? (
                        <img
                          src={OPERATOR_DATA["drivingLicenceAttachment"]}
                          alt=""
                          onClick={() =>
                            props.cImage(
                              [OPERATOR_DATA["drivingLicenceAttachment"], OPERATOR_DATA["drivingLicenceBackAttachment"]], 0, true
                            )
                          }
                        />
                      ) : (
                        "-"
                      )}
                      {OPERATOR_DATA["drivingLicenceBackAttachment"] ? (
                        <img
                          src={OPERATOR_DATA["drivingLicenceBackAttachment"]}
                          alt=""
                          onClick={() =>
                            props.cImage(
                              [OPERATOR_DATA["drivingLicenceAttachment"], OPERATOR_DATA["drivingLicenceBackAttachment"]], 1, true

                            )
                          }
                        />
                      ) : (
                        "-"
                      )}
                    </li>
                    <li className="body_img">
                      <span>特殊作业操作证</span>
                      {OPERATOR_DATA["specialPapersAttachment"] &&
                        OPERATOR_DATA["specialPapersAttachment"].map((item, index) => {
                          return (
                            <img
                              src={item.url}
                              alt=""
                              onClick={() => props.cImage(OPERATOR_DATA["specialPapersAttachment"], index, true)}
                            />
                          );
                        })}
                    </li>
                  </ul>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
});
