import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Modal, Icon } from "antd";
import userUtil from "@/utils/user";
import "./header.less";
import Message from "@/components/message/MessageCenter";
import { API } from "@/http/api";
import http from "@/http";
import HeaderLogo from "@/images/logo-noslogn.png";
import lqeLogo from "@/images/lqeLogo.jpg";

const { Item: MenuItem } = Menu;



class Header extends Component {
  state = {
    logoutConfirmVisible: false,
    showMsg: false,
    userName: "",
    logoUrl: ''
    // data: null
  };

  displayLogout = () => {
    this.setState({
      logoutConfirmVisible: true,
    });
  };

  logout = () => {
    const config = {
      api: API.userLoginOut,
      showLoading: true,
    };
    http(config).then((res) => {
      if (res && res.success) {
        this.hideLogout();
        userUtil.logout();
        window.location.href = "/#/login";
      }
    });
  };

  hideLogout = () => {
    this.setState({
      logoutConfirmVisible: false,
    });
  };

  showMsg = () => {
    this.setState({
      showMsg: true,
    });
  };

  // unReadCnt = () => {
  //     http({
  //         api: API.messageUnReadCnt,
  //     }).then(res => {
  //         let data = res.data || 0;
  //         if (data > 99) {
  //             data = '99+';
  //         }
  //         this.setState({
  //             data
  //         });
  //     })
  // }
  // getRendCnt = () => {
  //     this.unReadCnt();
  //     clearInterval(this.interval);
  //     this.interval = setInterval(() => {
  //         this.unReadCnt();
  //     }, 30000)
  // }
  componentDidMount() {
    // this.getRendCnt()
    // 添加监听器
    var hidden, visibilityChange;
    if (typeof document.hidden !== "undefined") {
      hidden = "hidden";
      visibilityChange = "visibilitychange";
    } else if (typeof document.mozHidden !== "undefined") {
      hidden = "mozHidden";
      visibilityChange = "mozvisibilitychange";
    } else if (typeof document.msHidden !== "undefined") {
      hidden = "msHidden";
      visibilityChange = "msvisibilitychange";
    } else if (typeof document.webkitHidden !== "undefined") {
      hidden = "webkitHidden";
      visibilityChange = "webkitvisibilitychange";
    }
    this.hidden = hidden;
    this.visibilityChange = visibilityChange;
    document.addEventListener(
      visibilityChange,
      this.visibilityChangeListener,
      false
    );

    // 去查询 用户信息
    this.queryUserInfo();
    // 获取平台logo
    this.getTenantInfo()
  }

  getTenantInfo = () => {
    const config = {
      api: API.userGetUserLogo,
    };
    http(config).then((res) => {
      if (res.success) {
        this.setState({
          logoUrl: res.data.logo
        })
      }
    });
  }
  visibilityChangeListener = () => {
    // alert("当前页面是否被隐藏：" + document[hidden]);
    if (document[this.hidden]) {
      clearInterval(this.interval);
    } else {
      // this.getRendCnt()
    }
  };

  componentWillUnmount() {
    clearInterval(this.interval);
    document.removeEventListener(
      this.visibilityChange,
      this.visibilityChangeListener,
      false
    );
  }

  queryUserInfo() {
    var _this = this;
    const config = {
      api: API.userLoginInfo,
      showLoading: true,
    };
    http(config).then((res) => {
      if (res.success) {
        _this.setState({
          userName: res.data.name,
        });
      }
    });
  }

  render() {
    const { selectedKey, firstLevelMenu, logoUrl } = this.props;
    return (
      <Layout.Header className="header">
        <Link to="/app">
            {
              userUtil.ishfjl
              ?<div className="logo">
                <img src="https://file.idf10.com/mp/logo_hfjl_s.png" alt="数通十方云商城运营管理后台" />
                <span className="slogn">华发景龙网运营中心</span>
              </div>
              :
              userUtil.istflq ?
              <div className="logo">
                <img  src="https://file.idf10.com/mp/logo_config.png" alt="数通十方云商城运营管理后台" />
                <span className="slogn">天府沥青网运营中心</span>
              </div>
              :
              userUtil.istfsn ?
              <div className="logo">
                <img  src="https://file.idf10.com/mp/logo_config.png" alt="数通十方云商城运营管理后台" />
                <span className="slogn">天府水泥网运营中心</span>
              </div>
              :
              userUtil.istfgc ?
              <div className="logo">
                <img  src="https://file.idf10.com/mp/logo_config.png" alt="数通十方云商城运营管理后台" />
                <span className="slogn">天府钢材网运营中心</span>
              </div>
              :
              userUtil.islqemall ?
              <div className="logo">
                <img  src={lqeLogo} alt="数通十方云商城运营管理后台" style={{height:'56px'}}/>
                <span className="slogn">路桥e站运营中心</span>
              </div>
              :<div className="logo">
                <img  src={userUtil.isUs ?  HeaderLogo : 'https://file.idf10.com/mp/logo_config.png'} alt="数通十方云商城运营管理后台" />
                <span className="slogn">{userUtil.isUs ? '运营中心' : '天府建材网运营中心'}</span>
              </div>
            }
        </Link>
        <Menu
          theme="dark"
          mode="horizontal"
          className="menu"
          selectedKeys={[selectedKey]}
        >
          {firstLevelMenu.map((menu) => (
            <MenuItem key={menu.route} className="menu-item-cus">
              <Link to={menu.route}>{menu.title}</Link>
            </MenuItem>
          ))}
        </Menu>
        <div className="actions-menu">
          {/* <Link className="user-help" to='/app/help'><span>帮助</span> <Icon type="question-circle" /></Link> */}

          {/* <Message className="user-msg" onClick={this.showMsg} placement="bottom" style={{top:'0 !important'}}>
                        <span className='msg-number'>{this.state.data}</span>
                        <i className="icon iconfont iconnotifications"></i>
                    </Message>
                     */}
          {/* <Link className="user-info" to='/app/account'>{userUtil.getName()}</Link> */}
          <span style={{ marginRight: "20px" }}>{this.state.userName}</span>
          <span onClick={this.displayLogout} className="user-logout">
            退出
          </span>
        </div>

        <Modal
          visible={this.state.logoutConfirmVisible}
          title="确认退出登录？"
          width={400}
          closable={false}
          maskClosable={false}
          okText="退出"
          onOk={this.logout}
          onCancel={this.hideLogout}
        />
      </Layout.Header>
    );
  }
}

export default Header;
