import {
    AJAX_TYPE
} from '../config';

// schedule-controller

const prefix = 'job';

export default {
    // add
    [`${prefix}Add`]: {
        url: '/job/add',
        type: AJAX_TYPE.POST
    },
    // disable
    [`${prefix}Disable`]: {
        url: '/job/disable',
        type: AJAX_TYPE.POST
    },
    // getJobState
    [`${prefix}GetJobState`]: {
        url: '/job/getJobState',
        type: AJAX_TYPE.POST
    },
    // pauseAllJob
    [`${prefix}PauseAllJob`]: {
        url: '/job/pauseAllJob',
        type: AJAX_TYPE.POST
    },
    // pauseJob
    [`${prefix}PauseJob`]: {
        url: '/job/pauseJob',
        type: AJAX_TYPE.POST
    },
    // refresh
    [`${prefix}Refresh`]: {
        url: '/job/refresh',
        type: AJAX_TYPE.GET
    },
    // resumeAllJob
    [`${prefix}ResumeAllJob`]: {
        url: '/job/resumeAllJob',
        type: AJAX_TYPE.POST
    },
    // resumeJob
    [`${prefix}ResumeJob`]: {
        url: '/job/resumeJob',
        type: AJAX_TYPE.POST
    },
    // updateCron
    [`${prefix}UpdateCron`]: {
        url: '/job/updateCron',
        type: AJAX_TYPE.POST
    },
}