import React, { Component } from 'react';
import "./EquipmentInfo.less";
import moment from 'moment';

class EquipmentInfo extends Component {


    renderChild = (child, index) => { // 控制内容的分发
        if(!child){
            return "";
        }

        let that = this;
        return <div className="e_row" key={index}>
            <img className="img" style={{width:'80px',height:'80px'}} src={child.imageUrl}  />
            <div  className="infos">
                <div   className="info1">{child.equipmentName}</div>
                <div  className="info2">
                    <span className="info">出厂日期:{(child.dateOfProduction&&typeof(child.dateOfProduction)=='number')?(moment(child.dateOfProduction).format('YYYY-MM-DD HH:mm')):'-'}</span>
                    <span className="info">所在地:{child.provinceName}</span>
                </div>
            </div>
            <div  className="info3"   >{child.brandName}</div>
            
            {
             that.props.type==1 && (<div className="info3" style={{ marginLeft:'50px'  }}>{ "成交价:"+ ( (child.transactionPrice&&child.transactionPriceName) ?(child.transactionPrice+child.transactionPriceName):'-') }</div>)
           }

           {
           that.props.type==2 && ( <div className="info3" style={{ marginLeft:'50px'  }}>{ "报价:"+ ( (child.expectedPrice&&child.expectedPriceName) ?(child.expectedPrice+child.expectedPriceName):'-') }</div>)
           }
           
            </div>

    }

    render() {
        return (<div className="equipment_panel2">
          
            {
            Array.isArray(this.props.data)&&
                this.props.data.map((child, index) => {
                    return this.renderChild(child, index)
                })  
            }

 
        </div>);
    }

}


export default EquipmentInfo;