import React, { Component } from 'react';
import { Breadcrumb, Button, Row, Col ,DatePicker,Input,message,Tooltip } from 'antd';
import Table from 'CMPT/common/Table';
import http from '@/http';
import { API } from '@/http/api';
import './Report.less';
const BreadcrumbItem = Breadcrumb.Item;
const { RangePicker } = DatePicker;
const Search = Input.Search;
var moment = require('moment');


class ProjectReport extends Component {
    state = {
        columns: [
            {
                title: '创建时间',
                width: 140,
                dataIndex: 'creatTime',
                fixed: 'left'
            }, {
                title: '项目名称',
                width: 150,
                dataIndex: 'ProjectName',
                fixed: 'left'
            }, {                
                title: '项目编号',
                width: 120,
                dataIndex: 'projectNumber',
                fixed: 'left'
            }, {
                title: '城市',
                width: 100,
                dataIndex: 'city'
            }, {
                title: '区域',
                width: 100,
                dataIndex: 'region'
            }, {              
                title: '详细地址',
                width: 120,
                dataIndex: 'location',
                render: (text) => {
                    if(text && text.length > 13) {
                        return <Tooltip placement="top" title={text}>
                                <span className="lineEllipsis" style={{'WebkitBoxOrient': 'vertical'}}>{text.slice(0, 50)}...</span>
                            </Tooltip>
                    } else {
                        return text
                    }
                }
            }, {              
                title: '建筑面积(m²)',
                width: 150,
                dataIndex: 'buildingArea'
            }, {              
                title: '占地面积(m²)',
                width: 150,
                dataIndex: 'coverArea'
            }, {              
                title: '总楼层数',
                width: 100,
                dataIndex: 'floors'
            }, {              
                title: '物业费(元/m²)',
                width: 140,
                dataIndex: 'propertyFee'
            }, {              
                title: '竣工时间',
                width: 100,
                dataIndex: 'completionTime'
            }, {              
                title: '项目介绍',
                width: 150,
                dataIndex: 'projectIntroduction',
                render: (text) => {
                    if(text && text.length > 13) {
                        return <Tooltip placement="top" title={text}>
                                <span className="lineEllipsis" style={{'WebkitBoxOrient': 'vertical'}}>{text.slice(0, 50)}...</span>
                            </Tooltip>
                    } else {
                        return text
                    }
                }
            }, {              
                title: '楼层名称',
                width: 100,
                dataIndex: 'floorName'
            }, {              
                title: '楼层编号',
                width: 100,
                dataIndex: 'floorNumber'
            }, {              
                title: '楼层面积(m²)',
                width: 120,
                dataIndex: 'floorArea'
            }, {              
                title: '楼层单价(元)',
                width: 120,
                dataIndex: 'floorPrice'
            }, {              
                title: '层高(米)',
                width: 100,
                dataIndex: 'storeyHeight'
            }      
        ],
        data: [
        ],
        total:0,
        startDateMoment: undefined,
        endDateMoment: undefined, 
        selectParams: {
            keywords:null, 
            createTimeFrom: moment().subtract(1, 'years').add(1,'days').format('YYYY-MM-DD'),
            createTimeTo: moment().format('YYYY-MM-DD'),
            pageNo: 1,// 当前页数
            pageSize: 10,// 每页条数
        }
    }
    componentDidMount() {
        this.getLists()
    }
    onSearchName = (val) => {
        let selectParams = this.state.selectParams;
        selectParams.pageNo = 1;
        selectParams.pageSize = 10;
        selectParams.keywords = val;
        this.setState({
            selectParams
        },() => {
            this.getLists();
        })
    }
    setName = (e) => {
        let selectParams = this.state.selectParams;
        selectParams.keywords = e.target.value;
        this.setState({
            selectParams
        })
    }
    handleDate = (value, date) => {
        let selectParams = this.state.selectParams;
        selectParams.pageNo = 1;
        selectParams.pageSize = 10;
        selectParams.createTimeFrom = date[0];
        selectParams.createTimeTo = date[1];
        this.setState({
            selectParams,
            startDateMoment: value[0],
            endDateMoment: value[1]
        },() => {
            this.getLists();
        })
    }
    onChangePage = (pageNo, pageSize) => {
        let selectParams = this.state.selectParams;
        selectParams.pageNo = pageNo;
        selectParams.pageSize = pageSize;
        this.setState({
            selectParams
        },() => {
            this.getLists();
        })
    }
    getLists() {
        const config = {
            api: API.reportformProject,
            data: this.state.selectParams,
            showLoading: true
        };
        http(config).then(res => {
            const data = res.data;
            const list = data && data.datas.map((item,key) => {
                return {
                    key: key, 
                    creatTime: item.createTime ,
                    ProjectName: item.name ,
                    projectNumber: item.projectNo ,
                    city: item.cityName ,
                    region: item.regionName ,
                    location: item.address ,
                    buildingArea: item.buildingArea,
                    coverArea: item.area ,
                    floors: item.totalFloorCnt ,
                    propertyFee: item.propertyFee ,
                    completionTime: item.completionDate ,
                    projectIntroduction: item.introduction ,
                    floorName: item.floorName ,
                    floorNumber: item.floorNo ,
                    floorArea: item.floorArea ,
                    floorPrice: item.floorPrice ,
                    storeyHeight: item.floorHeight 
                }
            })
            this.setState({
               data: list || [],
               total: data && data.totalRecord
            }) 
          
        })
    } 
    export = () => {
        const config = {
            api: API.reportformProjectExport,
            data: this.state.selectParams,
            responseType:'blob',
            showLoading: true
        };
        http(config).then(res => {
            if (res.size < 1024) {
                message.error('下载异常，请稍后重试')
            } else { 
            const content = res
            const blob = new Blob([content],{type: 'application/vnd.ms-excel'})
            const fileName = `项目报表${moment(new Date()).format('YYYY-MM-DD HH:mm')}.xlsx`
            if ('download' in document.createElement('a')) { // 非IE下载
                const elink = document.createElement('a')
                elink.download = fileName
                elink.style.display = 'none'
                elink.href = URL.createObjectURL(blob)
                document.body.appendChild(elink)
                elink.click()
                URL.revokeObjectURL(elink.href) // 释放URL 对象
                document.body.removeChild(elink)
            } else { // IE10+下载
                navigator.msSaveBlob(blob, fileName)
            }
        }
        })
    }
    reset = () => {
        this.setState( {
            isReset:true,
             startDateMoment: undefined,
             endDateMoment: undefined,
             selectParams:  {
                keywords: null, 
                createTimeFrom: moment().subtract(1, 'years').add(1,'days').format('YYYY-MM-DD'),
                createTimeTo:  moment().format('YYYY-MM-DD'),
                pageNo: 1,// 当前页数
                pageSize: 10,// 每页条数
             }
        },() => {
            this.setState({
                isReset:false,
            })
            this.getLists();
         })      
     }
    render() {
        return (
            <div className="report-con">
                <div className='report-header'>
                    <Breadcrumb>
                        <BreadcrumbItem>报表中心</BreadcrumbItem>
                        <BreadcrumbItem>项目报表</BreadcrumbItem>
                    </Breadcrumb>
                    <Button type="primary" className='download' onClick={this.export}>下载报表</Button>
                </div>
                <div className='report-list'>             
                    {
                        !this.state.isReset?(
                            <div className='manage-filter'>
                                <Search
                                    placeholder="请输入项目名称/项目编号"
                                    value={this.state.selectParams.keywords}
                                    onSearch={this.onSearchName}
                                    onChange={this.setName}
                                    className='manage-search'
                                />
                                创建时间
                                <RangePicker className='manage-filter-date' 
                                defaultValue={[moment().subtract(1, 'years').add(1,'days'), moment()]} allowClear={false} disabledDate={disabledDate}
                                onChange={this.handleDate} style={{width: '206px'}} />
                                <span className="report-reset" style={{cursor: 'pointer'}} onClick={this.reset}>重置</span>
                            </div>
                        ):(null)
                    }
                    
                    <Table columns={this.state.columns} data={this.state.data} total={this.state.total} change={this.onChangePage} current={this.state.selectParams.pageNo} scroll={{ x: 2070 }}/>
                </div>
            </div>
        );
    }
}

export default ProjectReport;

function disabledDate(current) {
    // Can not select days before today and today
    return current > moment() || current < moment().subtract(5, 'years'); // February 28;
}