import React, { Component } from 'react';
import { Layout, Table, Breadcrumb, Button, Row, Col, Tooltip, Card, Modal, Input } from 'antd';
import { Link } from 'react-router-dom'
import LogisticsStep from "CMPT/common/LogisticsStep";
import { getLogisticsInfo } from "@/utils";
import { changeObjkey, getNewPhone } from '@/utils/index'
import {
    AUTH_KEYS
} from '@/utils/auth';
import userUtil from "@/utils/user";
import './OrderDetail.less';
import { API } from '@/http/api';
import http from '@/http';
import { none } from 'ol/centerconstraint';
const { TextArea } = Input;
const { Header, Content, Footer } = Layout;

class OrderDetail extends Component {
    state = {   
        liyouModal: false,
        isGoDowing: true,
        detailInfo: {},
        bigImgSrc: null,
        logisticsInfo: {},
        confirmGetInTime:undefined, // 确认收货时间
        buyerLogisticsInfo: {}, //买家物流 
        solderLogisticsInfo: {},// 卖家物流
        showLogisticsModal: false
    }


    componentDidMount() {
        const match= this.props.match
        if (match && match.params && match.params.id) {
            // this.getOrderDetail(match.params.id)
            this.setState({
                orderCode: match.params.id
            }, () => {
                this.getOrderDetail()
            })
        }
       
    }


    getOrderDetail = () => {
        const params = {
            api: API.orderDetail,
            showLoading: true,
            path: {
                orderCode: this.state.orderCode
            }
        }
        http(params).then(res => {
            if (res.success) {
                let data =res.data;

                // 找出确认收货时间
                var    confirmGetInTime =undefined;
                if(data&&data.operateInfos && Array.isArray(data.operateInfos) && data.operateInfos.length){
                    data.operateInfos.forEach((item)=>{
                        if(!confirmGetInTime){ // 取一次就够了
                            var operateType = item.operateType?parseInt(item.operateType):-1;
                            if(operateType==9 || operateType==10 ){//9表示这个是确认收货
                                confirmGetInTime =  item.createTime;
                            }
                        }

                    });
                }


                this.setState({
                    detailInfo: res.data,
                    confirmGetInTime
                },()=>{

                    /* start:截取手机后4位 */
                    var customerName4SF_value=undefined;
                    if(res&&res.data&&res.data.expressInfo && 'SF'==res.data.expressInfo.expressSimpleName){
                         // 添加代码变量的判断 http://jira.ipo.com/browse/IDF10-1220
                        var mobile = (res&&res.data&&res.data.buyerInfo)?res.data.buyerInfo.mobile:undefined;
                        if(mobile){
                            customerName4SF_value = mobile.substring(mobile.length-4);//收件人/寄件人都可以
                        }
                    }
                 /* end:截取手机后4位 */


                    //发货
                    res.data.expressInfo&& res.data.expressInfo.expressNo&&(this.getLogisticsInfo({
                        "logisticCode": res.data.expressInfo.expressNo,
                        "mainCode": res.data.orderMainCode,
                        "shipperCode":  res.data.expressInfo.expressSimpleName,
                        logisticsCompany:res.data.expressInfo.expressCompany,
                        customerName4SF :customerName4SF_value
                    },1))
                    //退货
                    const orderSubVos =  res.data.orderSubVos;
                    orderSubVos.forEach(item => {
                          /* start:截取手机后4位 */
                        var customerName4SF_value2=undefined;
                         // 添加代码变量的判断 http://jira.ipo.com/browse/IDF10-1220
                        if(item&&item.afterServiceInfo&& item.afterServiceInfo.expressInfo && 'SF'==item.afterServiceInfo.expressInfo.expressSimpleName){
                            var mobile =  (res&&res.data&&res.data.buyerInfo)?res.data.buyerInfo.mobile:undefined;
                            if(mobile){
                                customerName4SF_value2 = mobile.substring(mobile.length-4);//寄件人都可以
                            }
                        }
                          /* end:截取手机后4位 */
                        item.afterServiceInfo&& item.afterServiceInfo.expressInfo&&(
                            this.getLogisticsInfo({
                                "logisticCode": item.afterServiceInfo.expressInfo.waybillNo,
                                "mainCode": item.afterServiceInfo.serviceCode,
                                "shipperCode":  item.afterServiceInfo.expressInfo.expressSimpleName, 
                                logisticsCompany:item.afterServiceInfo.expressInfo.expressName ,
                                customerName4SF :customerName4SF_value2
                            },2,item.afterServiceInfo.serviceCode)
                        )
                    });
                })
            }
        })
    }

    getLogisticsInfo = (obj,type,code = null) => {
        getLogisticsInfo(obj,type).then(res => {
            let logisticsInfo = {
                logisticsCompany:obj.logisticsCompany,
                logisticsNo:obj.logisticCode
            }
            logisticsInfo.timeList = res
            if(type==1){
                this.setState({
                    solderLogisticsInfo:logisticsInfo
                })
            }else{
                let buyerLogisticsInfo = this.state.buyerLogisticsInfo;
                !buyerLogisticsInfo[code]&&(buyerLogisticsInfo[code]={})
                buyerLogisticsInfo[code] = logisticsInfo
                this.setState({
                    buyerLogisticsInfo:buyerLogisticsInfo
                })               
            }

        })
    }

    logisticsModalShow = (bo,type,code = null) => {
        if(bo){
            let logisticsInfo = {}
            type == 1 ? (logisticsInfo = this.state.solderLogisticsInfo) : (logisticsInfo =this.state.buyerLogisticsInfo[code])
            this.setState({
                showLogisticsModal: bo,
                logisticsInfo:logisticsInfo
            })
        }else{
            this.setState({
                showLogisticsModal: bo
            })           
        }
    }

    goMallSnapshot = (orderSubCode) => {
        window.open(`${userUtil.mallUrlForLink}/snapshot/${orderSubCode}`)
    }

    watchBigImage = (src) => {
        this.setState({
            bigImgSrc: src
        })
    }



    render() {
        const columns = [
            {
              title: '商品名称',
              dataIndex: 'buyerInfo',
              key: 'buyerInfo',
              render: (text, record) => {
                    return (<span>{record.orderSkuSnapshotDto && record.orderSkuSnapshotDto.itemName || '无'}</span>);
                }
            },
            {
              title: '规格',
              dataIndex: 'shopVo',
              key: 'shopVo',
              render: (text, record) => {
                return (<span>{record.orderSkuSnapshotDto && record.orderSkuSnapshotDto.skuSpecValue && record.orderSkuSnapshotDto.skuSpecValue.map(item => {
                    return item.specValueName + ' '
                })}</span>);
              }
            },
            {
              title: '单价*数量',
              dataIndex: 'salePrice ', // quantities
              key: 'salePrice',
              render: (text, record) => {
                return (<span>{`${(record.pricingType && record.pricingType == 1) ? '需询价' : record.salePrice && record.salePrice.toFixed(2)}*${record.quantities}`}</span>);
              }
            },
            {
                title: '商品总计',
                dataIndex: 'finalAmount',
                key: 'finalAmount',
                render: (text, record) => {
                    return (<span>{text && text.toFixed(2)}</span>);
                  }
            },
            {
                title: '售后状态',
                dataIndex: 'afterServiceInfo',// serviceStatus
                key: 'afterServiceInfo',
                render: (text, record) => {
                    return (<span>{record.afterServiceInfo && record.afterServiceInfo.serviceStatus || '无需售后'}</span>);
                  }
            },
            {
                title: '商品快照',
                dataIndex: 'orderSubCode',
                key: 'orderSubCode',
                render: (text, record) => {
                    return (<span style={{cursor: 'pointer', color: '#2E4AFF'}} onClick={()=> {this.goMallSnapshot(text)}}>查看</span>);
                }
            },
        ];
        const { detailInfo, bigImgSrc,confirmGetInTime } = this.state;
        const { buyerInfo } = detailInfo; // 用户信息
        const { shopVo } = detailInfo; // 店铺信息
        const { deliveryOrderAddressVo } = detailInfo; // 卖家地址
        const { expressInfo } = detailInfo;  // 物流
        const { buyerOrderAddressVo } = detailInfo; // 买家地址
        const { returnOrderAddressVos } = detailInfo; // 卖家退货地址
        const { invoiceVo } = detailInfo; // 发票信息
        const { payInfo } = detailInfo; // 支付信息
        const { orderSubVos } = detailInfo;
        let afterServiceArr = [];
        orderSubVos && orderSubVos.map(item => {
            if (item.afterServiceInfo) {
                afterServiceArr.push(item.afterServiceInfo)
            }
        })

        return (
            <Content className="intention-list intention-content order_detail">
                <div className="intention-list-head flexbox">
                    <Breadcrumb className="intention-list-head-bread">
                        <Breadcrumb.Item>
                            <Link style={{ color: '#333' }} to={{ pathname: "/app/crm/orderManage" }}>
                                订单列表
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            订单详情
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className="info_content">
                    <div className="items_box">
                        <div className="box_tit">买家信息</div>
                        <div className="info_item">
                            <span className="item_label">昵称：</span>
                            <span className="item_msg">{buyerInfo && buyerInfo.nickName || '无'}</span>
                        </div>
                        <div className="info_item">
                            <span className="item_label">姓名：</span>
                            <span className="item_msg">{buyerOrderAddressVo && buyerOrderAddressVo.name || '无'}</span>
                        </div>
                        <div className="info_item">
                            <span className="item_label">手机号：</span>
                            <span className="item_msg">{buyerInfo && buyerInfo.mobile || '无'}</span>
                        </div>
                        <div className="info_item">
                            <span className="item_label">企业邮箱：</span>
                            <span className="item_msg">{buyerInfo && buyerInfo.email || '无'}</span>
                        </div>
                        <div className="info_item">
                            <span className="item_label">企业名称：</span>
                            <span className="item_msg">{buyerInfo && buyerInfo.companyName || '无'}</span>
                        </div>
                        <div className="info_item">
                            <span className="item_label">统一社会信用代码：</span>
                            <span className="item_msg">{buyerInfo && buyerInfo.creditNumber || '无'}</span>
                        </div>
                    </div>

                    <div className="items_box">
                        <div className="box_tit">卖家信息</div>
                        <div className="info_item">
                            <span className="item_label">企业名称：</span>
                            <span className="item_msg">{shopVo && shopVo.shopName || '无'}</span>
                        </div>
                        <div className="info_item">
                            <span className="item_label">统一社会信用代码：</span>
                            <span className="item_msg">{detailInfo && detailInfo.supplyCredit || '无'}</span>
                        </div>
                        <div className="info_item">
                            <span className="item_label">店铺名称：</span>
                            <span className="item_msg">{shopVo && shopVo.shopName || '无'}</span>
                        </div>
                        <div className="info_item">
                            <span className="item_label">主营类目：</span>
                            <span className="item_msg">{shopVo && shopVo.mainCategoryDesc || '无'}</span>
                        </div>
                        <div className="info_item">
                            <span className="item_label">经营模式：</span>
                            <span className="item_msg">{shopVo && shopVo.mainCategoryDesc || '无'}</span>
                        </div>
                    </div>

                    <div className="items_box">
                        <div className="box_tit">订单信息</div>
                        <div className="info_item">
                            <span className="item_label">订单编号：</span>
                            <span className="item_msg">{detailInfo && detailInfo.orderNo || '无'}</span>
                        </div>
                        <div className="info_item">
                            <span className="item_label">下单时间：</span>
                            <span className="item_msg">{detailInfo && detailInfo.createTime || '无'}</span>
                        </div>
                        <div className="info_item">
                            <span className="item_label">订单状态：</span>
                            <span className="item_msg">{detailInfo && detailInfo.statusName || '无'}</span>
                        </div>
                        <div className="info_item">
                            <span className="item_label">下单平台：</span>
                            <span className="item_msg">{detailInfo && detailInfo.channelTypeName || '无'}</span>
                        </div>
                        <div className="info_item">
                            <span className="item_label">支付方式：</span>
                            <span className="item_msg">{payInfo && (payInfo.payType == '0' ? '线下支付' : payInfo.payType == '1' ?'线上支付' : '账期支付') || '无'}</span>
                        </div>
                        <div className="info_item">
                            <span className="item_label">支付时间：</span>
                            <span className="item_msg">{payInfo && payInfo.payTime || '无'}</span>
                        </div>
                        {
                              confirmGetInTime? <div className="info_item">
                               <span className="item_label">确认收货时间：</span>
                               <span className="item_msg">{confirmGetInTime}</span>
                           </div>:null
                        }
                        <div className="info_item">
                            <span className="item_label">订单总额：</span>
                            <span className="item_msg">{detailInfo && detailInfo.finalAmount || '无'}</span>
                        </div>
                        {
                            detailInfo && detailInfo.orderSubVos ?
                            <Table rowKey='itemCode' dataSource={detailInfo && detailInfo.orderSubVos} columns={columns} pagination={false}></Table>
                            : null
                        }
                        <div className="info_item">
                            <span className="item_label">订单备注：</span>
                            <span className="item_msg">{detailInfo && detailInfo.buyerRemark || '无'}</span>
                        </div>
                        {/* =========== */}
                        <div className="info_item">
                            <span className="item_label">货品签收单：</span>
                            <span className="item_msg">
                                { 
                                    expressInfo 
                                    && expressInfo.receiptVoucher 
                                    && expressInfo.receiptVoucher.map(item => {return <img key={item.encode} src={item.url} className="item_img" onClick={()=> {this.watchBigImage(item.url)}}/>}) 
                                    || '无'
                                }
                            </span>
                        </div>
                    </div>
                    
                    <div className="items_box">
                        <div className="box_tit">收货物流信息</div>
                        <div className="info_item">
                            <span className="item_label">物流单号：</span>
                            <span className="item_msg">
                                {expressInfo && expressInfo.expressNo ? 
                                <a className="" onClick={()=>{this.logisticsModalShow(true,1)}} href='javascript:void(0)'>{expressInfo.expressNo+'[物流跟踪]'}</a> : '无'}
                             </span>
                        </div>
                        <div className="info_item">
                            <span className="item_label">物流公司：</span>
                            <span className="item_msg">{expressInfo && expressInfo.expressCompany || '无'}</span>
                        </div>
                        <div className="info_item">
                            <span className="item_label">买家收货地址：</span>
                            <span className="item_msg">
                                {
                                    buyerOrderAddressVo ?
                                    `
                                    ${buyerOrderAddressVo && buyerOrderAddressVo.provinceName}
                                    ${buyerOrderAddressVo && buyerOrderAddressVo.cityName}
                                    ${buyerOrderAddressVo && buyerOrderAddressVo.districtName}
                                    ${buyerOrderAddressVo && buyerOrderAddressVo.name},
                                    ${buyerOrderAddressVo && buyerOrderAddressVo.phone}
                                    `
                                    : '无'
                                }
                            </span>
                        </div>
                        <div className="info_item">
                            <span className="item_label">驾驶员/车辆：</span>
                            <span className="item_msg">
                                {
                                    expressInfo && expressInfo.logisticDrivers && expressInfo.logisticDrivers.map((item, index) => {
                                        return <span>{item.driverPlate}-{item.driverName}/{item.driverMobile}{index == expressInfo.logisticDrivers.length - 1? null : <>、</>}</span>
                                    })
                                }
                            </span>
                        </div>
                        <div className="info_item">
                            <span className="item_label">卖家发货地址：</span>
                            <span className="item_msg">
                                {
                                    deliveryOrderAddressVo ?
                                    `
                                    ${deliveryOrderAddressVo && deliveryOrderAddressVo.provinceName}
                                    ${deliveryOrderAddressVo && deliveryOrderAddressVo.cityName}
                                    ${deliveryOrderAddressVo && deliveryOrderAddressVo.districtName}
                                    ${deliveryOrderAddressVo && deliveryOrderAddressVo.name},
                                    ${deliveryOrderAddressVo && deliveryOrderAddressVo.phone}
                                    ` : '无'
                                }
                            </span>
                        </div>
                        <div className="info_item">
                            <span className="item_label">卖家退货地址：</span>
                            <span className="item_msg">
                                {
                                    returnOrderAddressVos ?
                                    `
                                    ${returnOrderAddressVos && returnOrderAddressVos.provinceName}
                                    ${returnOrderAddressVos && returnOrderAddressVos.cityName}
                                    ${returnOrderAddressVos && returnOrderAddressVos.districtName}
                                    ${returnOrderAddressVos && returnOrderAddressVos.name},
                                    ${returnOrderAddressVos && returnOrderAddressVos.phone}
                                    ` : '无'
                                }
                            </span>
                        </div>
                        <div className="info_item">
                            <span className="item_label">发货时间：</span>
                            <span className="item_msg">{expressInfo && expressInfo.expressTime || '无'}</span>
                        </div>
                        {/* =========== */}
                        <div className="info_item">
                            <span className="item_label">物流凭证：</span>
                            <span className="item_msg">
                                { 
                                    expressInfo 
                                    && expressInfo.sendVoucher 
                                    && expressInfo.sendVoucher.map(item => {return <img key={item.encode} src={item.url} className="item_img" onClick={()=> {this.watchBigImage(item.url)}}/>}) 
                                    || '无'
                                }
                            </span>
                        </div>
                    </div>
                    {
                        afterServiceArr && afterServiceArr.length ?
                        <div className="items_box">
                            <div className="box_tit">退货物流信息</div>
                            {
                                afterServiceArr.map(item => {
                                    return <>
                                        <div className="info_item">
                                            <span className="item_label">物流单号：</span>
                                            <span className="item_msg">
                                                {item.expressInfo && item.expressInfo.waybillNo ? 
                                                <a onClick={()=>{this.logisticsModalShow(true,2,item.serviceCode)}} href='javascript:void(0)'>{item.expressInfo.waybillNo+'[物流跟踪]'}</a> : '无'}
                                            </span>
                                        </div>
                                        <div className="info_item">
                                            <span className="item_label">物流公司：</span>
                                            <span className="item_msg">{item.expressInfo && item.expressInfo.expressName || '无'}</span>
                                        </div>
                                        <div className="info_item">
                                            <span className="item_label">卖家退货地址：</span>
                                            <span className="item_msg">
                                                {
                                                    item.expressInfo && item.expressInfo.receiverAddressInfo ?
                                                    `
                                                    ${item.expressInfo.receiverAddressInfo && item.expressInfo.receiverAddressInfo.provinceName}
                                                    ${item.expressInfo.receiverAddressInfo && item.expressInfo.receiverAddressInfo.cityName}
                                                    ${item.expressInfo.receiverAddressInfo && item.expressInfo.receiverAddressInfo.districtName}
                                                    ${item.expressInfo.receiverAddressInfo && item.expressInfo.receiverAddressInfo.name},
                                                    ${item.expressInfo.receiverAddressInfo && item.expressInfo.receiverAddressInfo.phone}
                                                    ` : '无'
                                                }
                                            </span>
                                        </div>
                                        <div className="info_item">
                                            <span className="item_label">发货时间：</span>
                                            <span className="item_msg">{item.expressInfo && item.expressInfo.sendTime || '无'}</span>
                                        </div>
                                        {/* =========== */}
                                        <div className="info_item">
                                            <span className="item_label">物流凭证：</span>
                                            <span className="item_msg">
                                                { 
                                                    item.expressInfo 
                                                    && item.expressInfo.afterSalesVoucher 
                                                    && item.expressInfo.afterSalesVoucher.map(itema => {return <img key={itema.encode} src={itema.url} className="item_img" onClick={()=> {this.watchBigImage(itema.url)}}/>}) 
                                                    || '无'
                                                }
                                            </span>
                                        </div>
                                    </>
                                })
                            }
                        </div>
                        : null

                    }                    

                    <div className="items_box">
                        <div className="box_tit">发票信息</div>
                        <div className="info_item">
                            <span className="item_label">买家是否申请：</span>
                            <span className="item_msg">{invoiceVo ? '是' : '否'}</span>
                        </div>
                        <div className="info_item">
                            <span className="item_label">发票类型：</span>
                            <span className="item_msg">{invoiceVo && invoiceVo.titleType == '1' ? '个人' : '企业' || '无'}</span>
                        </div>
                        <div className="info_item">
                            <span className="item_label">发票抬头：</span>
                            <span className="item_msg">{invoiceVo && invoiceVo.name || '无' }</span>
                        </div>
                        <div className="info_item">
                            <span className="item_label">税号：</span>
                            <span className="item_msg">{invoiceVo && invoiceVo.taxNo || '无' }</span>
                        </div>
                        <div className="info_item">
                            <span className="item_label">开户银行：</span>
                            <span className="item_msg">{invoiceVo && invoiceVo.bankName || '无' }</span>
                        </div>
                        <div className="info_item">
                            <span className="item_label">银行账号：</span>
                            <span className="item_msg">{invoiceVo && invoiceVo.bankAccount || '无' }</span>
                        </div>
                        <div className="info_item">
                            <span className="item_label">企业地址：</span>
                            <span className="item_msg">{invoiceVo && invoiceVo.enterpriseAddress || '无' }</span>
                        </div>
                        <div className="info_item">
                            <span className="item_label">企业电话：</span>
                            <span className="item_msg">{invoiceVo && invoiceVo.enterprisePhone || '无' }</span>
                        </div>
                        <div className="info_item">
                            <span className="item_label">卖家是否已开票：</span>
                            <span className="item_msg">{
                                // invoiceVo && invoiceVo.status && invoiceVo.status == '0' ? '待开票'
                                // : invoiceVo.status == '1' ? '开票中' 
                                // : invoiceVo.status == '2' ? '已开票'
                                // : invoiceVo.status == '3' ? '已取消' : '无'
                            }</span>
                        </div>
                        <div className="info_item">
                            <span className="item_label">发票类型：</span>
                            <span className="item_msg">{invoiceVo && invoiceVo.type == 1 ? '电子' : '纸质' || '' }</span>
                        </div>
                    </div>

                    <div className="items_box">
                        <div className="box_tit">买家付款凭证</div>
                        <div className="info_item">
                            <span className="item_msg">
                                { 
                                    payInfo 
                                    && payInfo.mediaDtos 
                                    && payInfo.mediaDtos.map(item => {return <img key={item.encode} src={item.url} className="item_img" onClick={()=> {this.watchBigImage(item.url)}}/>}) 
                                    || '无'
                                }
                            </span>
                        </div>
                        <div className="info_item">
                            <span className="item_label">付款凭证是否已确认：</span>
                            <span className="item_msg">{payInfo && payInfo.mediaDtos && payInfo.mediaDtos.length > 0?'是':'否'}</span>
                        </div>
                    </div>

                    
                </div>
                
                {
                    bigImgSrc ?
                        <div className="big_img" onClick={() => { this.setState({ bigImgSrc: null }) }}>
                            <img src={bigImgSrc} alt="" />
                        </div>
                        : null
                }
                <Modal
                    visible={this.state.showLogisticsModal}
                    title="物流跟踪"
                    closable={false}
                    wrapClassName="order-logistics-modal"
                    okText="我知道了"
                    onCancel={()=>this.logisticsModalShow(false,1)}
                    onOk={()=>this.logisticsModalShow(false,1)}
                >
                    <LogisticsStep info={this.state.logisticsInfo} />
                </Modal>            
            </Content>
        )
    }

}

export default OrderDetail;