import React, { Component } from "react";
import { Checkbox } from 'antd';
import ComposeAuthModel from "./ComposeAuthModel.js";
import { ChildCheckType, AuthType } from "./AuthModel.js";
import '../AuthSetting.less';

export default class SubMenuAuthModel extends ComposeAuthModel {
  render() {
    return <div key={'sub_menu_' + this.id} className="sm_auth_line">
      <Checkbox key={'chb_' + this.id} checked={this.isChecked} onChange={this.onCheckChange}>{this.name}</Checkbox>
      {this.createMyChildrenUI()}
    </div>;
  }

  createMyChildrenUI() {
    let result = this.createChildrenUiInner();
    if (result) {
      return <div className="level_5_box"  >{result}</div>;
    } else {
      return '';
    }
  }

  onCheckPassDown(parent) {
    if (!parent.isChecked) {
      this.setChecked(false);
      this.dispatchCheckDown();
    }
  }

  onCheckPassUp(child) {
    // 这里只会有 AuthType.OPERATION 能够回调到这里。
    // 如果OPERATION被选中，而自己没有被选中，就要把自己更新为 选中，并传递给父亲节点
    if (child.type == AuthType.OPERATION) {
      if (child.isChecked) {
        if (!this.isChecked) {
          //有一个子节点被选中，它自身就会被选中
          this.setChecked(true);
          // 传递给父亲节点，看父亲如何处理
          this.dispatchCheckUp(this);
        }
        return;
      }
    }

  }
}