import {
    AJAX_TYPE
} from '../config';

// 待审核数字统计

export default {
    // 信息审核角标
    [`menuNumberMsgAudit`]: {
        url: '/web/crm/mark/msgAudit',
        type: AJAX_TYPE.POST
    },
    // 认证管理角标
    [`menuNumberRecogniseMgr`]: {
        url: '/web/crm/mark/recogniseMgr',
        type: AJAX_TYPE.POST
    },
    
}