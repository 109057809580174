import React, { useState, useEffect } from 'react';
import { Tabs } from 'antd';
import DisplayAuthRow from './DisplayAuthRow';

const TabPane = Tabs.TabPane;

export default function DisplayAuth({ authMap }) {
    const [firstLevelAuthCodes, setFirstLevelAuthCodes] = useState([]);
    
    useEffect(()=>{
        setFirstLevelAuthCodes(getFirstLevelAuthCodes(authMap || {}));
    },[authMap]);

    return (
        <Tabs>
            {
                firstLevelAuthCodes.map(code => {
                    const auth = authMap[code];
                    const secondLevelAuthCodes = auth.subs;
                    return (
                        <TabPane tab={auth.desc} key={code}>
                            {secondLevelAuthCodes.map(authCode => (<DisplayAuthRow authMap={authMap} authCode={authCode} key={authCode} />))}
                        </TabPane>);
                })
            }
        </Tabs>
    );
}

function getFirstLevelAuthCodes(authMap) {
    const codes = [];
    for (const [code, auth] of Object.entries(authMap)) {
        if (auth.level === 1) {
            codes.push(code);
        }
    }
    return codes;
}
