import React, { Component } from 'react';
import { API } from '@/http/api';
import http from '@/http';
import { changeObjkey ,getNewPhone} from '@/utils/index';
import storage from '@/utils/storage';
import Steps from 'CMPT/common/Steps';
import BaseInfo from './add/BaseInfo';
import ContractTerms from './add/ContractTerms';
import IncomeExpense from './add/IncomeExpense';
import { Link } from 'react-router-dom';
import { Breadcrumb, Button, message, Modal } from 'antd';
import moment from 'moment';
const BreadcrumbItem = Breadcrumb.Item;
class ContractAdd extends Component {
    state = {
        stepOptions: {
            nodeNum: 3,
            current: 1,
            currentNodeStatus: 2,
            nodeNameList: ['基本信息', '合同条款', '确认收付']
        },
        initData: {}, // 初始化枚举数据
        componentRef: '', //表单ref
        formRef: '', // 获取表单内部state
        tempDraft: '',
        contractParams: {
            draftStep: 1, // 保存草稿的
            decisionObj: {}, // 合同决策信息，根据项目来的
            customerName: undefined, // 客户姓名
            customerType: undefined, // 客户类型
            customerIndustry: undefined, // 客户行业
            customerTaxNumber: '', // 客户统一社会信用代码
            RelatedPerson: '', // 联系人
            RelatedPersonPhone: '', // 联系人电话
            RelatedPersonIdentity: '', // 联系人身份证
            customerEmail: '', // 客户邮箱
            customerBankCard: '', // 客户银行卡号
            customerLocation: undefined, // 客户省市区
            customerBlock: undefined, // 客户所在版块
            customerAddress: '', // 客户详情地址
            customerCompanyLogo: '', //客户公司logo
            projects: undefined, // 合同关联项目
            projectName: '', // 项目名称
            projectNo: '', //项目编号，新增时接口需要，也需要回填
            projectRoom: undefined, // 项目的房间
            contractNumber: '', // 合同编号
            contractAlterDate: undefined, //合同变更时间
            years: 0, //合同总年数
            contractDateRange: [], // 合同的开始\结束时间
            oldContractDateRange: [], // 旧合同的开始结束时间
            exemptBeforeDateRange: [], // 合同内免租期的前的开始\结束时间
            exemptAfterDateRange: [], //合同内免租期的后的开始\结束时间
            contractSignDate: moment(), // 合同签约时间,默认当前时间
            contractSignPerson: storage.get('USER') && `${storage.get('USER').departmentIdCode},${storage.get('USER').idCode}`, // 合同签约人，默认当前登录人
            contractSignDeptPersonNames: storage.get('USER') && `${storage.get('USER').departmentName}-${storage.get('USER').userName}`, // 签约部门名-签约人名

            contractTemplate: undefined, // 租客合同模板
            contractDeposit: '', // 合同押金月数
            contractPayment: '', // 合同付款数
            contractDropId: '', // 付款单位 月 季 年
            contractPaymentType: undefined, // 合同付款类型 1 提前付款天数 2 固定付款日期
            contractPaymentDate: '', // 合同付款日期
            contractFeiList: [], // 合同折扣、递增率
            contractMargin: [], //合同保证金
            contractDelayPayment: '', // 滞纳金比例
            estateTemplate: undefined, // 物业合同模板，以下和租客类似
            estateDeposit: '', //
            estatePayment: '', //
            estateDropId: '',
            estatePaymentType: undefined, //
            estatePaymentDate: '', //
            estateFeiList: [], // 物业折扣、递增率
            estateMargin: [],//物业保证金
            estateDelayPayment: '',
            rentList: [], //租金明细列表,

            firstChooseType: 1,
            firstFeeDays: undefined,
            firstRangeType: 1,
            firstBeginDay: null,
            firstEndDay: null,
            firstFeePointDate: null,

            // firstChooseType1: undefined,
            // firstFeeDays1: undefined,
            // firstRangeType1: undefined,
            // firstBeginDay1: moment(),
            // firstEndDay1: moment(),
            // firstFeePointDate1: moment()
            // TODO: 确认收付列表（租客+物业）
        },
        type: 1, // 1 新签 2 续签 3 变更
        query: {},
        contractInexData: { // 租客收支列表
            contractInexList: [],
            contractInexOriginList: [], // 租客合同变更时的老合同收支
            contractPriceByYear:[] // 租客乘以面积后的年单价
        },  //  物业收支列表
        estateInexData: {
            estateInexList: [],
            estateInexOriginList: [],
            estatePriceByYear: [] // 物业乘以面积后的年代价
        },
        showModal: false,
        draftType: 1, // 1保存 2 回显
        isDraft: false,
         // 是否需要重新生成条款或收支，新增是true,编辑若修改了房间信息，合同时间为true(变更和续租时都会改变时间)
         // 条款页面修改了任何一处都需要重新生成收支
         // 草稿回显时也是
        reGenerate: true,
        // 是否需要重新生成租金明细和折扣，由第一步参数决定
        regenerateRentList: true,
        isEdit: false
    }
    componentDidMount() {
        this.setState({
            componentRef: React.createRef()
        })
        this.getInitData()
        this.actionBySearchParams()
    }
    // 有search表示编辑、变更或续租，否则为新增
    actionBySearchParams = () => {
        const searchParams = this.props.history.location.search
        if (searchParams) {
            const searchFormat = this.formatSearch(searchParams)
            const code = searchFormat.code
            const isEdit = searchFormat.isEdit
            if (isEdit === 'true') { // 编辑的时候不需要重新生成租金和收付，修改关键信息后才改变
                this.setState({
                    isEdit: true,
                    reGenerate: false,
                    regenerateRentList: false
                })
            }
            this.getContractDetail(searchFormat.code, Number(searchFormat.type))
            if (searchFormat.type) {
                this.setState({
                    type: Number(searchFormat.type)
                })
            }
        } else { // 新增的时候需要请求草稿信息
            const config = {
                api: API.draftQuery,
                path: {
                    bizType: 4
                }
            }
            http(config).then(res => {
                if (res.data) {
                    this.setState({
                        showModal: true,
                        draftType: 2,
                        isDraft: true,
                        tempDraft: res.data
                    })
                } else { // 没有草稿信息时需要判断是否从项目过来
                    this.actionFromProject()
                }
            })
        }
    }
    actionFromProject = () => {
        let stateData = this.props.history.location.state
        if (stateData) {
            let contractParams = this.state.contractParams
            contractParams.projects = stateData.projectEncode
            contractParams.projectRoom = [{
                id: stateData.encode,
                parentId: stateData.floorEncode
            }]
            this.setState({
                contractParams
            }, () => {
                const formRef = this.state.formRef
                formRef.getDefaultData()
            })
        }
    }
    formatSearch = (ss) => {
        const ssSplit = ss.split('?')[1].split('&')
        let obj = {}
        ssSplit.forEach(per => {
            let perSplit = per.split('=')
            obj[perSplit[0]] = perSplit[1]
        })
        return obj
    }
    getContractDetail = (code, type) => {
        const config = {
            api: API.contractApproveinfo,
            data: {encode: code},
            showLoading: true
        };
        http(config).then(res => {
            const data = res.data
            let contractParams = {}
            contractParams.contractEncode = code // 合同code，对应与后端的encode
            // 合同信息
            const contractVo = data.contractVo
            contractParams.parentEncode = contractVo.parentEncode
            if (this.state.isEdit) {
                contractParams.contractDateRange = [moment(contractVo.startTime), moment(contractVo.endTime)]
                if (contractVo.freeEndTime) {
                    contractParams.exemptBeforeDateRange = [moment(contractVo.startTime), moment(contractVo.freeEndTime)]
                }
                if (contractVo.freeStartTime) {
                    contractParams.exemptAfterDateRange = [moment(contractVo.freeStartTime), moment(contractVo.endTime)]
                }
                // 新签被驳回，都是undefined,不会做限制
                contractParams.oldContractDateRange = [moment(contractVo.parentStartTime), moment(contractVo.parentEndTime)]
            } else {
                contractParams.oldContractDateRange = [moment(contractVo.startTime), moment(contractVo.endTime)]
            }
            if (type === 3 && this.state.isEdit) { // 编辑时需要回显变更时间
                contractParams.contractAlterDate = moment(contractVo.changeEffectiveTime)
            }
            contractParams.contractSignDate = moment(contractVo.signTime)
            contractParams.contractNumber = contractVo.contractCode
            contractParams.contractSignPerson = `${contractVo.deptCode},${contractVo.signUserCode}`
            contractParams.contractSignDeptPersonNames = `${contractVo.deptName}-${contractVo.signUserName}`
            if (contractVo.mediaDto) {
                contractParams.mediaInfo = contractVo.mediaDto
            }
            //企业信息
            const customerInfo = data.contactCustomerVo;
            if (this.state.isEdit) {
                contractParams.customerCode = customerInfo.encode ? customerInfo.encode : undefined // 编辑接口需要
            }
            contractParams.customerName =  customerInfo.customerName
            contractParams.customerType = customerInfo.customerType ? String(customerInfo.customerType) : undefined
            contractParams.customerCompanyLogo = customerInfo.companyLogo
            contractParams.customerIndustry =  customerInfo.industryType ? String(customerInfo.industryType) : undefined
            contractParams.customerTaxNumber = customerInfo.creditNumber
            contractParams.RelatedPerson = customerInfo.contactName
            contractParams.RelatedPersonPhone = getNewPhone(customerInfo.phone)
            contractParams.RelatedPersonIdentity = customerInfo.identityNumber
            contractParams.customerEmail = customerInfo.email // 客户邮箱
            contractParams.customerBankCard = customerInfo.bankNo // 客户银行卡号
            if (customerInfo.provinceCode) {
                contractParams.customerLocation = [customerInfo.provinceCode, customerInfo.cityCode, customerInfo.regionCode]
            }
            contractParams.customerBlock = customerInfo.blockCode // 客户所在版块
            contractParams.customerAddress = customerInfo.address
            //房源信息
            const houseInfor = data.contractZoneVo;
            contractParams.projectName = houseInfor.projectName;
            contractParams.projectNo = houseInfor.projectNo;
            contractParams.projects = houseInfor.projectCode;
            contractParams.projectRoom = houseInfor.zoneList && houseInfor.zoneList.map(item => {
                return {
                    id: item.zoneCode,
                    parentId: item.floorCode,
                    no: item.zoneNo,
                    estatePrice: item.estatePrice,
                    price: item.rentPrice,
                    area: item.zoneArea,
                    floorName: item.floorName,
                    name: `${item.floorName}-${item.zoneNo}-${item.zoneArea}㎡`
                }
            })
            // 租客合同
            const contractClause = data.rentClauseVo
            contractParams.years = contractClause.contractZonePriceList[0].priceList.length
            contractParams.contractTemplate = data.rentContractTemplateCode //租客合同模板
            if (this.state.isEdit) {
                contractParams.contractClauseCode = contractClause.encode // 编辑接口需要
            }
            contractParams.contractDeposit = contractClause.depositNum
            contractParams.contractPayment = contractClause.paymentNum
            contractParams.contractDropId = contractClause.paymentNumUnit ? String(contractClause.paymentNumUnit) : '1'
            contractParams.contractPaymentType = contractClause.advanceType
            contractParams.contractPaymentDate = contractClause.advanceDays
            contractParams.contractDelayPayment = contractClause.lateFees
            
            contractParams.firstBeginDay = contractClause.firstBeginDay?moment(contractClause.firstBeginDay) : undefined
            contractParams.firstChooseType = contractClause.firstChooseType || null
            contractParams.firstEndDay = contractClause.firstEndDay?moment(contractClause.firstEndDay) : undefined
            contractParams.firstFeeDays = contractClause.firstFeeDays || null
            contractParams.firstFeePointDate = contractClause.firstFeePointDate?moment(contractClause.firstFeePointDate) : undefined
            contractParams.firstRangeType = contractClause.firstRangeType || null

            contractParams.contractFeiList = contractClause.rentChangeFormList && contractClause.rentChangeFormList.map((item, index) => {
                return {
                    id: index + 1,
                    discount: item.discount,
                    increasingRate: item.increamentalRate
                }
            })
            contractParams.contractMargin = contractClause.billVoList && contractClause.billVoList.map((item, index) => {
                return {
                    id: index + 1,
                    contractMarginSelect: String(item.generateType),
                    contractMarginType: String(item.type),
                    contractMarginNum: item.amount,
                }
            })
            // 物业合同
            const estateClause = data.propertyClauseVo
            contractParams.estateTemplate = data.propertyContractTemplateCode // 物业合同模板
            if (this.state.isEdit) {
                contractParams.estateClauseCode = estateClause.encode // 编辑接口需要
            }
            contractParams.estateDeposit = estateClause.depositNum
            contractParams.estatePayment = estateClause.paymentNum
            contractParams.estateDropId = estateClause.paymentNumUnit ? String(estateClause.paymentNumUnit) : '1'
            contractParams.estatePaymentType = estateClause.advanceType
            contractParams.estatePaymentDate = estateClause.advanceDays
            contractParams.estateDelayPayment = estateClause.lateFees
            contractParams.estateFeiList = estateClause.rentChangeFormList.map((item, index) => {
                return {
                    id: index + 1,
                    discount: item.discount,
                    increasingRate: item.increamentalRate
                }
            })
            contractParams.estateMargin = estateClause.billVoList && estateClause.billVoList.map((item, index) => {
                return {
                    id: index + 1,
                    estateMarginSelect: String(item.generateType),
                    estateMarginType: String(item.type),
                    estateMarginNum: item.amount,
                }
            })
            const contractRentList = this.transRentlist(contractClause.contractZonePriceList, 1)
            const estateRentList = this.transRentlist(estateClause.contractZonePriceList, 2)
            contractParams.rentList = this.mergeList(contractRentList, estateRentList)
            data.billList && data.billList.forEach(bill => {
                if (bill.type !== 0) {
                    this.transContractData(bill)
                }
            })
            this.setState({
                contractParams
            }, () => {
                const formRef = this.state.formRef
                formRef.getDefaultData()
            })
        })
    }
    transRentlist = (list, start) => {
        let target = []
        list.forEach((per, index) => {
            let obj = {}
            obj.key = 2 * index + start
            obj.projectRoomName = per.zoneFullName
            obj.floorId = per.floorEncode
            obj.floorName = per.floorName
            obj.floorNo = per.floorNo
            obj.zoneId = per.zoneEncode
            obj.zoneNo = per.zoneNo
            obj.area = per.zoneArea
            obj.price = per.rentPrice
            per.priceList.forEach(item => {
                obj[`year${item.yearNumber}`] = item.price
            })
            target.push(obj)
        })
        return target
    }
    mergeList = (list1, list2) => {
        let target = []
        for(let i =0; i < list1.length; i++) {
            target.push(list1[i])
            target.push(list2[i])
        }
        return target
    }
    getInitData = () => {
        const initConfig = {
            api: API.contractInit
        }
        // 初始化枚举
        http(initConfig).then(initRes => {
            this.setState({
                initData: initRes.data
            })
        })
    }
    // 设置是否需要重新渲染,regenerateRentList是否需要重新生成折扣和租金明细
    // 到第三步之后就要变为false,以记录用户填写的折扣和租金信息
    setReGenerateStatus = (status, regenerateRentList) => {
        this.setState({
            reGenerate: status
        })
        if (typeof regenerateRentList === 'boolean') {
            this.setState({
                regenerateRentList
            })
        }
    }
    goNext = () => {
        let current = this.state.stepOptions.current
        let params = this.state.contractParams
        const formRef = this.state.formRef
        const isDraft = this.state.isDraft
        if (current === 1) { // 取子组件的根据项目获取的配置信息
            params.decisionObj = formRef.state.decisionObj
        }
        const $ref = this.state.componentRef.current
        if (current === this.state.stepOptions.nodeNum) {
            // 收支页面使用deepclone后数据不能联动，只能取组件最新的值！！
            this.state.contractInexData.contractInexList = $ref.getContractData()
            this.state.estateInexData.estateInexList = $ref.getEstateData()
            if (this.state.isEdit) { //走编辑接口
                this.editContract()
            } else {
                if (this.state.type === 1) {
                    this.addContract() // 新增
                } else if (this.state.type === 2) {
                    this.resignContract() // 续签
                } else {
                    this.alterContract() // 变更
                }
            }
            return
        }
        formRef.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                params = Object.assign({}, params, values)
                if ((!isDraft && !this.state.reGenerate && current < this.state.stepOptions.nodeNum) || (isDraft && !this.state.reGenerate && current < params.draftStep)) { // 各子组件影响改状态
                    current += 1
                    this.setState({
                        stepOptions: Object.assign({}, this.state.stepOptions, {current: current}),
                        contractParams: params
                    })
                } else {
                    if (current === 1) {
                        params.contractPayment = params.contractPayment || (params.decisionObj.periodDevideMethod === '2' ? 1 : undefined)
                        params.estatePayment = params.estatePayment || (params.decisionObj.periodDevideMethod === '2' ? 1 : undefined) //按合同周期，数值固定为1
                        params.estatePaymentDate = params.estatePaymentDate || params.decisionObj.aheadDays
                        params.contractPaymentDate = params.contractPaymentDate || params.decisionObj.aheadDays
                        params.contractTemplate = params.contractTemplate || params.decisionObj.defaultContractTemplate // 默认模板，选项目后的决策里返回
                        params.estateTemplate = params.estateTemplate || params.decisionObj.defaultEstateTemplate
                        params.contractDelayPayment = params.contractDelayPayment || params.decisionObj.delayPayment
                        params.estateDelayPayment = params.estateDelayPayment || params.decisionObj.delayPayment
                        params.rentList = !this.state.regenerateRentList ? params.rentList : this.generateRentList(values.projectRoom, formRef.state.projectName, params.decisionObj.estatePrice) // 根据项目-楼层信息生成租金明细（租客和物业）
                        params.projectRoom = values.projectRoom
                        params.customerCompanyLogo = formRef.state.logoUrl
                        params.projectName = formRef.state.projectName
                        params.projectNo = formRef.state.projectNo
                        if (formRef.state.departmentPersonNames) { // 有表明修改过，否则取默认的
                            params.contractSignDeptPersonNames = formRef.state.departmentPersonNames
                        }
                        current += 1
                        // params.draftStep = 2
                        this.setState({
                            contractParams: params,
                            stepOptions: Object.assign({}, this.state.stepOptions, {current: current})
                        })
                    } else if (current === 2) {
                        params.contractFeiList = formRef.state.contractFeiList
                        params.contractMargin = formRef.state.contractMargin
                        params.contractDropId = formRef.state.contractDropId
                        params.estateFeiList = formRef.state.estateFeiList
                        params.estateMargin = formRef.state.estateMargin
                        params.estateDropId = formRef.state.estateDropId
                        params.rentList = formRef.state.data
                        params.years = formRef.state.years
                        params.mediaInfo = formRef.state.mediaInfo
                        this.generateInexList(params).then(() => {
                            current += 1
                            // params.draftStep = 3
                            this.setState({
                                contractParams: params,
                                stepOptions: Object.assign({}, this.state.stepOptions, {current: current}),
                                regenerateRentList: false
                            })
                        })
                    }
                }
                // if (current !== 2) {
                //     current += 1
                //     params.draftStep += 1
                //     this.setState({
                //         contractParams: params,
                //         stepOptions: Object.assign({}, this.state.stepOptions, {current: current})
                //     })
                // }
            }
        })
    }
    // 上一步
    goPrev = () => {
        let current = this.state.stepOptions.current
        let params = this.state.contractParams
        if (current === 1) return
        current -= 1
        // params.draftStep -= 1
        this.setState({
            stepOptions: Object.assign({}, this.state.stepOptions, {current: current}),
            contractParams: params
        })
    }
    // 编辑
    editContract = () => {
        const config = {
            api: API.contractEdit,
            data: this.mapAttr()
        }
        http(config).then(res => {
            if (res.success) {
                message.success('提交成功')
                this.props.history.go(-1)
            }
        })
    }
    // 新增
    addContract = () => {
        const config = {
            api: API.contractAdd,
            data: this.mapAttr(),
            showLoading: true
        }
        http(config).then(res => {
            if (res.success) {
                message.success('提交成功')
                if (this.state.isDraft) { // 草稿回显的提交后，要清除草稿
                    const config = {
                        api: API.draftDelete,
                        path: {
                            bizType: 4
                        }
                    }
                    http(config).then(res => {
                    })
                }
                setTimeout(() => {
                    this.props.history.push('/app/operate/contract')
                }, 1000)
            }
        })
    }
    // 续签
    resignContract = () => {
        const config = {
            api: API.contractRenew,
            data: this.mapAttr(),
            showLoading: true
        }
        http(config).then(res => {
            if (res.success) {
                message.success('提交成功')
                setTimeout(() => {
                    this.props.history.push('/app/operate/contract')
                })
            }
        })
    }
    // 变更
    alterContract = () => {
        const config = {
            api: API.contractChange,
            data: this.mapAttr(),
            showLoading: true
        }
        http(config).then(res => {
            if (res.success) {
                message.success('提交成功')
                setTimeout(() => {
                    this.props.history.push('/app/operate/contract')
                })
            }
        })
    }
    // 根据项目-楼层信息生成租金明细（租客和物业）
    generateRentList = (list, name, estatePrice) => {
        let target = []
        list.forEach((per, index) => {
            let obj = {}
            let estateObj = {}
            obj.key = 2 * index + 1
            estateObj.key = 2 * index + 2
            obj.projectRoomName = estateObj.projectRoomName = `${name}-${per.floorName}层-${per.zoneNo}`
            obj.floorId = estateObj.floorId = per.parentId
            obj.floorName = estateObj.floorName = per.floorName
            obj.floorNo = estateObj.floorNo = per.no
            obj.zoneId = estateObj.zoneId = per.id
            obj.zoneNo = estateObj.zoneNo = per.zoneNo
            obj.area = estateObj.area = per.area
            obj.price = per.price
            estateObj.price = per.estatePrice || estatePrice
            target.push(obj)
            target.push(estateObj)
        })
        return target
    }
    // 租金 价格
    generatePriceList = (list, years) => {
        let target = []
        let obj = {}
        list.forEach((per, index) => {
            obj.floorEncode = per.floorId
            obj.floorName = per.floorName
            obj.floorNo = per.floorNo
            obj.zoneEncode = per.zoneId
            obj.zoneNo = per.zoneNo
            obj.zoneArea = Number(per.area)
            if (index % 2 === 0) { //租金
                obj.rentPrice = Number(per.price)
                obj.rentPriceList = this.getPriceList(per, years)
            } else {
                obj.estatePrice = Number(per.price)
                obj.estatePriceList = this.getPriceList(per, years)
                target.push(obj)
                obj = {}
            }
        })
        return target
    }
    getPriceList = (obj, years) => {
        let list = []
        for (let i = 1; i <= years; i++) {
            list.push({price: Number(obj[`year${i}`]), yearNumber: i})
        }
        return list
    }
    // 生成收支接口
    generateInexList = (params) => {
        return new Promise(resolve => {
            const config = {
                api: API.contractCompactBillDetail,
                data: this.mapInexAttr(params),
                showLoading: true
            }
            http(config).then(res => {
                if (res.success) { // 成功才能进入下一步
                    const resData = res.data
                    if (resData && resData.length) {
                        resData.forEach(per => {
                            this.transContractData(per)
                        })
                    }
                    resolve()
                }
            })
        })
    }
    transContractData = (item) => {
        const type = item.type
        const billList = item.billAllVoList
        const yearList = item.priceYearlyConfigs
        let key = 'contract'
        if (type === 2) {
            key = 'estate'
        }
        let targetData = this.state[`${key}InexData`]
        if (yearList && yearList.length) {
                targetData[`${key}PriceByYear`] = [...yearList]
        }
        if (billList && billList.length) {
            billList.forEach(per => {
                if (per.oldBill) {
                    targetData[`${key}InexOriginList`] = this.mapTableAttr(per.billList, false)
                } else {
                    targetData[`${key}InexList`] = this.mapTableAttr(per.billList, true)
                }
                this.setState({
                    [`${key}InexData`]: targetData
                })
            })
        }
    }
    mapTableAttr = (list, addDisabled) => {
        let target = []
        if (list && list.length) {
            list.forEach((per,i) => {
                let obj = {}
                obj.key = i + 1
                obj.bp = per.budgetType
                obj.generateType = per.generateType
                obj.feiType = per.billShowType === 2 ? 2 : 1 //用于区分是租金类型还是保证金类型
                obj.feeType = per.feeType // 收支的费用类型
                obj.rentType = per.billShowType
                obj.bpDate = per.payDate
                obj.rentPeriod = [per.beginDate, per.endDate]
                obj.money = per.payAmount
                obj.parseNum = /^\d*$/.test(per.bizPeriodNo) ? Number(per.bizPeriodNo) : per.bizPeriodNo
                obj.note = per.description
                obj.bizTypeSub = per.bizTypeSub // 前端没用到
                obj.billType = per.billType
                target.push(obj)
            })
        }
        if (addDisabled) this.addDisabledData(target)
        return target
    }
    // 时间选择限制上限为上一期的开始时间，下限为当前时间的结束时间
    addDisabledData = (list) => {
        let newList = list.map((per, index, all) => {
            let item = per
            let targetItem = undefined
            let maxParseNum = 1
            item.disabledDate = [undefined, item.rentPeriod[1]]
            const parseNum = per.parseNum
            if (per.rentType === 4) { // 后免租期，找期数最大的那一期
                all.forEach(each => {
                    if (/^\d*$/.test(each.parseNum)) {
                        maxParseNum = each.parseNum > maxParseNum ? each.parseNum : maxParseNum
                    }
                })
                targetItem = all.find(each => {
                    return each.parseNum === maxParseNum
                })
                item.disabledDate[0] = targetItem.rentPeriod[0]
            } else if (/^\d*$/.test(parseNum)) { // 第几期，当前是第一期就找前免租期，没免租期就自己，或者第n-1期
                targetItem = all.find(each => {
                    if (/^\d*$/.test(parseNum)) {
                        return parseNum === 1 ? each.rentType === 3 : each.parseNum === parseNum - 1
                    }
                })
                if (targetItem) {
                    item.disabledDate[0] = targetItem.rentPeriod[0]
                } else { // 没有前免租期
                    item.disabledDate[0] = item.rentPeriod[0]
                }
            } else {
                item.disabledDate = []
            }
            return item
        })
        return newList
    }
    transMargin = (margin, flag) => { // amount: 100,//保证金金额 , paymentDate: '2019-05-24',//收取日期 ,type: 1//保证金类型}, generateType: 
        let target = []
        margin.forEach(per => {
            let obj = {}
            obj.type = per[`${flag}MarginType`]
            obj.generateType = per[`${flag}MarginSelect`]
            if (per[`${flag}MarginSelect`] === '1') {
                obj.amount = per[`${flag}MarginNum`]
            }
            target.push(obj)
        })
        return target
    }
    transFei = (feiList, years) => {
        let target = []
        for (let i = 1; i <= years; i++) {
            target.push({discount: feiList[i - 1].discount, increamentalRate:feiList[i - 1].increasingRate , yearNumber: i})
        }
        return target
    }
    tranInex = (list) => {
        let target = []
        list.forEach(per => {
            let obj = {}
                obj.budgetType = per.bp
                obj.feeType = per.feeType // 收支的费用类型
                obj.payDate = per.bpDate.format('YYYY-MM-DD')
                obj.billShowType = per.rentType
                obj.beginDate = per.rentPeriod[0].format('YYYY-MM-DD')
                obj.endDate = per.rentPeriod[1].format('YYYY-MM-DD')
                obj.payAmount = obj.billShowType === 3 || obj.billShowType === 4 ? 0 : per.money
                obj.generateType = per.generateType
                obj.billType = per.billType
                obj.bizPeriodNo = typeof per.parseNum === 'number' ? String(per.parseNum) : per.parseNum
                obj.description = per.note
                obj.bizTypeSub = per.bizTypeSub // 前端没用到
                target.push(obj)
        })
        return target
    }
    // 添加合同 数据处理
    mapAttr = (isInex) => {
        const { contractParams, type } = this.state
        let query = this.mapInexAttr(contractParams, type)
        query.contractCode = contractParams.contractNumber
        //客户信息
        query.contractCustomer = {
            customerCode: contractParams.customerCode,
            address: contractParams.customerAddress,
            bankNo: contractParams.customerBankCard,
            blockEncode: contractParams.customerBlock,
            companyLogo: contractParams.customerCompanyLogo,
            contactName: contractParams.RelatedPerson,
            identityNumber: contractParams.RelatedPersonIdentity,
            phone: contractParams.RelatedPersonPhone.replace(/\s+/g, ''),
            creditNumber: contractParams.customerTaxNumber,
            customerName: contractParams.customerName,
            customerType: contractParams.customerType,
            email: contractParams.customerEmail,
            industryType: contractParams.customerIndustry || 0 // 默认其他
        }
        if (contractParams.customerLocation) {
            query.contractCustomer.provinceEncode = contractParams.customerLocation[0]
            query.contractCustomer.cityEncode = contractParams.customerLocation[1]
            query.contractCustomer.regionEncode = contractParams.customerLocation[2]
        }
        // 附件
        if (contractParams.mediaInfo && contractParams.mediaInfo.url) {
            query.mediaInfo = contractParams.mediaInfo
        }
        // 物业账单
        query.propertyContractBillList = this.tranInex(this.state.estateInexData.estateInexList)
        // 租客账单
        query.rentContractBillList = this.tranInex(this.state.contractInexData.contractInexList)
        query.deptCode = contractParams.contractSignPerson.split(',')[0]
        query.deptName = contractParams.contractSignDeptPersonNames.split('-')[0]
        query.signUserCode = contractParams.contractSignPerson.split(',')[1]
        query.signUserName = contractParams.contractSignDeptPersonNames.split('-')[1]
        query.source = 1 //1:PC 2:APP
        return query
    }
    // 确认收支接口参数
    mapInexAttr = (params, eType) => {
        let query = {}
        let type = eType || this.state.type
        if (this.state.isEdit) {
            query.encode = params.contractEncode
            if (params.parentEncode) { // 新增编辑时没有parentEncode
                query.parentEncode = params.parentEncode
            }
        } else if (type === 2 || type === 3) { // 新增时，如果是续签或变更parentEncode就是当前code
            query.parentEncode = params.contractEncode
        }
        if (type === 3) {
            query.changeEffectiveTime = params.contractAlterDate.format('YYYY-MM-DD') // 合同变更时间
        }
        query.contractZoneList = this.generatePriceList(params.rentList, params.years)
        query.startTime = params.contractDateRange[0].format('YYYY-MM-DD')
        query.endTime = params.contractDateRange[1].format('YYYY-MM-DD')
        if (params.exemptBeforeDateRange && params.exemptBeforeDateRange[1]) {
            query.freeEndTime = params.exemptBeforeDateRange[1].format('YYYY-MM-DD')
        }
        if (params.exemptAfterDateRange && params.exemptAfterDateRange[0]) {
            query.freeStartTime = params.exemptAfterDateRange[0].format('YYYY-MM-DD')
        }
        query.projectCode = params.projects
        query.projectName = params.projectName
        query.projectNo = params.projectNo
        // 物业条款
        query.propertyContractClause = {
            encode: params.estateClauseCode,
            advanceDays: params.estatePaymentDate ? Number(params.estatePaymentDate) : 0,//提前天数/具体日期 ,
            advanceType: params.estatePaymentType,// 付款类型：1：提前付款天数；2：固定付款日期 ,
            depositNum: params.estateDeposit,//支付方式_押金月数（押几） ,
            //保证金信息 ,
            earnestMoneyFormList: this.transMargin(params.estateMargin, 'estate'),
            lateFees: params.estateDelayPayment,//滞纳金比例（n%/天） n% * 10000 ,
            paymentNum: params.estatePayment,//支付方式_支付月数（付几） ,
            paymentNumUnit: params.estateDropId,// 付几）单位（1月2季3年） ,
            //各年份租金递增率及折扣信息 ,
            rentChangeFormList: this.transFei(params.estateFeiList, params.years),
            templateEncode: params.estateTemplate,//合同模板id ,
            // firstChooseType: params.firstChooseType1,
            // firstFeeDays: params.firstFeeDays1,
            // firstFeePointDate: params.firstFeePointDate1,
            // firstRangeType: params.firstRangeType1,
            // firstBeginDay: params.firstBeginDay1,
            // firstEndDay: params.firstEndDay1
        }
        // 租客条款
        query.rentContractClause = {
            encode: params.contractClauseCode,
            advanceDays: params.contractPaymentDate ? Number(params.contractPaymentDate) : 0,//提前天数/具体日期 ,
            advanceType: params.contractPaymentType,// 付款类型：1：提前付款天数；2：固定付款日期 ,
            depositNum: params.contractDeposit,//支付方式_押金月数（押几） ,
            //保证金信息 ,
            earnestMoneyFormList: this.transMargin(params.contractMargin, 'contract'),
            lateFees: params.contractDelayPayment,//滞纳金比例（n%/天） n% * 10000 ,
            paymentNum: params.contractPayment,//支付方式_支付月数（付几） ,
            paymentNumUnit: params.contractDropId,// 付几）单位（1月2季3年） ,
            //各年份租金递增率及折扣信息 ,
            rentChangeFormList: this.transFei(params.contractFeiList, params.years),
            templateEncode: params.contractTemplate,//合同模板id ,
            firstChooseType: params.firstChooseType || null,
            firstFeeDays: params.firstFeeDays || null,
            firstFeePointDate: params.firstFeePointDate?params.firstFeePointDate.format('YYYY-MM-DD'):null,
            firstRangeType: params.firstRangeType || null,
            firstBeginDay: params.firstBeginDay?params.firstBeginDay.format('YYYY-MM-DD'):null,
            firstEndDay: params.firstEndDay?params.firstEndDay.format('YYYY-MM-DD'):null
        }
        query.signTime = params.contractSignDate.format('YYYY-MM-DD')
        query.signType = this.state.type //1新签 2续签 3变更
        return query
    }
    // 保存草稿
    saveDraft = () => {
        const $ref = this.state.componentRef.current
        const formRef = this.state.formRef
        const current = this.state.stepOptions.current
        let params = this.state.contractParams
        params.draftStep = current
        if (formRef && formRef.props && formRef.props.form) { // current 为3 时没有form
            let fieldsValue = formRef.props.form.getFieldsValue()
            params = Object.assign({}, params, fieldsValue)
        }
        if (current === 1) {
            params.customerCompanyLogo = formRef.state.logoUrl
            params.projectName = formRef.state.projectName
            params.projectNo = formRef.state.projectNo
            if (formRef.state.departmentPersonNames) {
                params.contractSignDeptPersonNames = formRef.state.departmentPersonNames
            }
        } else if (current === 2) {
            params.contractFeiList = formRef.state.contractFeiList
            params.contractMargin = formRef.state.contractMargin
            params.contractDropId = formRef.state.contractDropId
            params.estateFeiList = formRef.state.estateFeiList
            params.estateMargin = formRef.state.estateMargin
            params.estateDropId = formRef.state.estateDropId
            params.rentList = formRef.state.data
            params.years = formRef.state.years
            params.mediaInfo = formRef.state.mediaInfo
        } else if (current === 3) {
            params.contractInexData = {
                contractInexList: this.dateListFormat($ref.getContractData()),
                contractInexOriginList: this.state.contractInexData.contractInexOriginList,
                contractPriceByYear: this.state.contractInexData.contractPriceByYear
            }
            params.estateInexData = {
                estateInexList: this.dateListFormat($ref.getEstateData()),
                estateInexOriginList: this.state.estateInexData.estateInexOriginList,
                estatePriceByYear: this.state.estateInexData.estatePriceByYear
            }
        }
        if (params.contractDateRange && params.contractDateRange.length) {
            //加个字段，不然state里的params也会format下同
            params.contractDateRangeDraft = params.contractDateRange.map(item => item.format('YYYY-MM-DD'))
        }
        params.exemptBeforeDateRangeDraft = []
        if (params.exemptBeforeDateRange && params.exemptBeforeDateRange[1]) {
            params.exemptBeforeDateRangeDraft = params.exemptBeforeDateRange.map(item => item.format('YYYY-MM-DD'))
        }
        params.exemptAfterDateRangeDraft = []
        if (params.exemptAfterDateRange && params.exemptAfterDateRange[0]) {
            params.exemptAfterDateRangeDraft = params.exemptAfterDateRange.map(item => item.format('YYYY-MM-DD'))
        }
        params.contractSignDateDraft = params.contractSignDate.format('YYYY-MM-DD')
        const config = {
            api: API.draftSave,
            data: {
                bizType: 4,
                content: JSON.stringify(params)
            }
        }
        http(config).then(res => {
            message.success('草稿保存成功')
        })
    } 
    dateListFormat = (list) => {
        let target = []
        list.forEach(per => {
            const item = {}
            Object.keys(per).forEach(key => {
                item[key] = per[key]
            })
            item.bpDate = item.bpDate.format('YYYY-MM-DD')
            item.rentPeriod = item.rentPeriod.map(each => each.format('YYYY-MM-DD'))
            if (item.disabledDate.length) {
                item.disabledDate = item.disabledDate.map(each => each.format('YYYY-MM-DD'))
            }
            target.push(item)
        })
        return target
    } 
    cancelAdd = () => {
        if (!this.props.isEdit && this.props.type === 1) {
            this.setState({
                showModal: true
            })
        } else {
            this.setState({
                showWarnModal: true
            })
        }
    }
    modalOperate = (flag) => {
        if (flag) {
            if (this.state.draftType === 1) {
                this.saveDraft()
            } else {
                let params = JSON.parse(this.state.tempDraft)
                if (params.contractDateRangeDraft && params.contractDateRangeDraft) {
                    params.contractDateRange = params.contractDateRangeDraft.map(item => moment(item))
                }
                if (params.exemptBeforeDateRangeDraft && params.exemptBeforeDateRangeDraft[1]) {
                    params.exemptBeforeDateRange = params.exemptBeforeDateRangeDraft.map(item => moment(item))
                }
                if (params.exemptAfterDateRangeDraft && params.exemptAfterDateRangeDraft[0]) {
                    params.exemptAfterDateRange = params.exemptAfterDateRangeDraft.map(item => moment(item))
                }
                if (params && params.contractInexData) {
                    let contractInexData = {
                        contractInexList: params.contractInexData.contractInexList,
                        contractInexOriginList: params.contractInexData.contractInexOriginList,
                        contractPriceByYear: params.contractInexData.contractPriceByYear
                    }
                    this.setState({contractInexData})
                }
                if (params && params.estateInexData) {
                    let estateInexData = {
                        estateInexList: params.estateInexData.estateInexList,
                        estateInexOriginList: params.estateInexData.contractInexOriginList,
                        estatePriceByYear: params.estateInexData.contractPriceByYear
                    }
                    this.setState({estateInexData})
                }
                params.contractSignDate = moment(params.contractSignDateDraft)
                params.firstBeginDay = params.firstFeePointDate?moment(params.firstBeginDay):null;
                params.firstEndDay = params.firstEndDay?moment(params.firstEndDay):null;
                params.firstFeePointDate = params.firstFeePointDate?moment(params.firstFeePointDate):null;
                this.setState({
                    contractParams: params,
                    reGenerate: params.draftStep < 3,
                    regenerateRentList: params.draftStep < 2
                }, () => {
                    const formRef = this.state.formRef
                    formRef.getDefaultData()
                })
            }
        } else {
            if (this.state.draftType === 1) { // 不保存时去列表页
                this.props.history.push('/app/operate/contract')
            } else { // 废弃草稿
                const config = {
                    api: API.draftDelete,
                    path: {
                        bizType: 4
                    }
                }
                http(config).then(res => {

                })
            }
        }
        this.setState({
            showModal: false
        })
        // 弹窗消失后再改变draftType
        setTimeout(() => {
            this.setState({
                draftType: 1
            })
        }, 500)
    }
    warnModalOperate = (value) => {
        if (value) {
            this.props.history.go(-1)
        } else {
            this.setState({
                showWarnModal: false
            })
        }
    }
    render() {
        const { componentRef, contractParams, type, initData, stepOptions, contractInexData, estateInexData } = this.state
        let component = ''
        const current = stepOptions.current
        if (current === 1) {
            component = <BaseInfo wrappedComponentRef={(form) => this.state.formRef = form} info={contractParams}
                                  type={type} isEdit={this.state.isEdit} init={initData} reGenerateStatus={this.setReGenerateStatus} />
        } else if (current === 2) {
            component = <ContractTerms wrappedComponentRef={(form) => this.state.formRef = form} decision={contractParams.decisionObj}
                                       regenerateRentList={this.state.regenerateRentList} type={type}
                                       info={contractParams} isEdit={this.state.isEdit} init={initData} reGenerateStatus={this.setReGenerateStatus} />
        } else {
            component = <IncomeExpense ref={componentRef} init={initData} isEdit={this.state.isEdit} info={contractParams}
                                       type={type} contractInexData={contractInexData} estateInexData={estateInexData} 
                                       decision={contractParams.decisionObj}/>
        }
        return (
            <div className="contract-add-page">
                <div className="contract-breadcrumb">
                    <Breadcrumb>
                        <BreadcrumbItem>操作中心</BreadcrumbItem>
                        <BreadcrumbItem><Link to='/app/operate/contract'>租客合同</Link></BreadcrumbItem>
                        <BreadcrumbItem>合同新增</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <div className="contract-steps">
                    <Steps steps={stepOptions} />
                </div>
                {component}
                <div className="contract-btns">
                    <Button type="default" onClick={this.goPrev} style={{display: current > 1 ? 'inline-block' : 'none'}}>上一步</Button>
                    <Button type="primary" onClick={this.goNext}>{current === 3 ? (this.state.isEdit ? '重新提交' : '提交') : '下一步'}</Button>
                    {!this.state.isEdit && type === 1 && <Button type="default" onClick={this.saveDraft}>保存草稿</Button>}
                    <span onClick={this.cancelAdd}>取消</span>
                </div>
                <Modal
                    visible={this.state.showModal}
                    closable={false}
                    maskClosable={false}
                    width={400}
                    title=" "
                    okText={this.state.draftType === 1 ? '保存草稿' : '使用草稿'}
                    cancelText={this.state.draftType === 1 ? '直接退出' : '废弃草稿'}
                    onCancel={this.modalOperate.bind(this, false)}
                    onOk={this.modalOperate.bind(this, true)}
                >
                <p>{this.state.draftType === 1 ? '是否将当前内容保存为草稿？' : '您还有未提交的项目，是否使用草稿？'}</p>
                </Modal>
                <Modal
                    visible={this.state.showWarnModal}
                    closable={false}
                    maskClosable={false}
                    width={400}
                    title=" "
                    onCancel={this.warnModalOperate.bind(this, false)}
                    onOk={this.warnModalOperate.bind(this, true)}
                >
                <p>您已修改当前页，是否舍弃更改？</p>
                </Modal>
            </div>
        );
    }
}

export default ContractAdd;