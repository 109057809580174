import React, { Component } from "react";
import { Form, Select, Input, Button, DatePicker, Row, Col } from "antd";
import moment from "moment";
import { API } from "@/http/api";
import http from "@/http";

const { Option } = Select;
const { RangePicker } = DatePicker;

class SearchForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: [
        {
          title: "设备一级类型",
          dataIndex: "primaryTypeCode",
          type: "select",
          onChange: this.onChange,
        },
        {
          title: "设备二级类型",
          dataIndex: "secondaryTypeCode",
          type: "select",
        },
        { title: "设备名称", dataIndex: "equipmentName", type: "input" },
        { title: "设备编号", dataIndex: "equipmentNumber", type: "input" },
        // 设备状态 0 闲置中 1 使用中
        {
          title: "设备状态",
          dataIndex: "useStatus",
          type: "select",
          dataSource: [
            { name: "闲置中", value: 0 },
            { name: "使用中", value: 1 },
          ],
        },
        // 出租状态 0未发布 1 审核中 2 已发布 3 审核驳回 4 已下架 5 平台下架
        {
          title: "出租状态",
          dataIndex: "releaseStatus",
          type: "select",
          dataSource: [
            { name: "未发布", value: 0 },
            { name: "审核中", value: 1 },
            { name: "已发布", value: 2 },
            { name: "审核驳回", value: 3 },
            { name: "已下架", value: 4 },
            { name: "平台下架", value: 5 },
          ],
        },
        { title: "创建人", dataIndex: "creator", type: "input" },
        {
          title: "创建人公司",
          dataIndex: "affiliatedEnterprise",
          type: "input",
        },
        // { title: "设备所在地", dataIndex: "status10", type: "location" },
        { title: "创建时间", dataIndex: "date", type: "dateRange" },
        //  0 自有 1 租赁
        {
          title: "产权",
          dataIndex: "relationshipType",
          type: "select",
          dataSource: [
            { name: "自有", value: 0 },
            { name: "租赁", value: 1 },
          ],
        },
      ],
    };
  }

  componentDidMount() {
    this.fetch();
  }

  fetch = () => {
    let { fields } = this.state;
    const config = {
      api: API.vAndEGetDictionary,
      path: { bizType: 3 },
    };
    http(config).then((res) => {
      if (res && res.code == 200) {
        let { data } = res;
        console.log(data);

        fields[0].dataSource = data.map((item) => ({
          ...item,
          value: item.categoryId,
        }));

        this.setState({ fields, dic: data });
      }
    });
  };

  typeMap = {
    select: function (params) {
      const ds = params.dataSource || [];
      return (
        <Select
          // allowClear
          placeholder="全部"
          style={{ width: "200px" }}
          onChange={params.onChange}
        >
          <Option key="-1" value={-1}>
            全部
          </Option>
          {ds.map((item) => (
            <Option key={item.value} value={item.value}>
              {item.name}
            </Option>
          ))}
        </Select>
      );
    },
    input: function () {
      return <Input style={{ width: "200px" }} />;
    },
    dateRange: function () {
      return <RangePicker style={{ width: "200px" }} />;
    },
    // location: function() {
    //   return <Input style={{ width: "200px" }} placeholder={""} />;
    // }
  };

  onChange = (e) => {
    let { fields, dic } = this.state;

    fields[1].dataSource = [];

    this.props.form.setFieldsValue({ secondaryTypeCode: undefined });

    const [checked] = dic.filter((item) => item.categoryId == e);
    if (checked) {
      fields[1].dataSource = checked.childList.map((item) => ({
        ...item,
        value: item.categoryId,
      }));
    }

    this.setState({
      fields,
    });
  };

  onSearch = () => {
    this.props.form.validateFields({ force: true }, (err, values) => {
      if (!err) {
        values.creationTimeStart = undefined;
        values.creationTimeEnd = undefined;
        if (values.date && values.date.length ) {
          values.creationTimeStart = moment(values.date[0]).format(
            "YYYY-MM-DD"
          );
          values.creationTimeEnd = moment(values.date[1]).format("YYYY-MM-DD");
        }

        // this.props.reset(values);
        this.props.onSearch(values);
      }
    });
  };

  reset = () => {
    const { form } = this.props;
    form.resetFields();
    this.onSearch();
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const { fields } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    };

    return (
      <Form {...formItemLayout} layout="inline">
        <Row gutter={24}>
          {fields.map((item, i) => (
            <Col
              span={8}
              key={i}
              // style={{ display: i < count ? "block" : "none" }}2
            >
              <Form.Item style={{width: '100%'}} label={item.title} key={item.title}>
                {getFieldDecorator(item.dataIndex)(
                  this.typeMap[item.type](item)
                )}
              </Form.Item>
            </Col>
          ))}
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: "right" }}>
            <Button type="primary" onClick={this.onSearch}>
              搜索
            </Button>
            <Button ghost className="reset" onClick={this.reset}>
              重置
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default Form.create({
  name: "EquipmentList",
  // onValuesChange: onValuesChanges,
})(SearchForm);
