import React, { Component } from 'react';
import {Row,Col,Form,Button,Icon} from 'antd';
import http from '@/http';
import { API } from '@/http/api';
import "./finance.less"

const Item = Form.Item;
const feeTypeList = ['定金','保证金','合同保证金','装修保证金','物业保证金','租金','手续费','服务费','保洁费','物业费','网费','水费','电费','滞纳金','违约金','搬家费','耗材费','其他费用'];
class BaseInfo extends Component {
    state = {

    }
    componentDidMount() {
    }

    render(){
        const { billInfo } = this.props;
        const htType = ['其他','租赁','物业'];
        let textDes = {};
        if(billInfo.budgetType == 1){
            textDes = {
                t1:'应收金额',
                t2:'应收时间',
                t3:'付款人'
            }
        }else{
            textDes = {
                t1:'应付金额',
                t2:'应付时间',
                t3:'收款人'
            }
        }
        // console.log(billInfo)
        return (
            <div className="approval-baseInfo">
                <div className="title two-side">
                    <span>{billInfo.floorMsg}</span>
                    <span style={{display:this.props.noshowOverdue?'none':''}}>
                        <i className="colory" style={{display:billInfo.overdue==1 && !this.props.noshowIcon?'':'none'}}><Icon type="warning" theme="filled" style={{ fontSize: '14px'}} />账单逾期</i> 
                        <Button type='link' style={{marginRight:'5px',display:billInfo.allPaid==0?'':'none'}}>未结清</Button>
                        <Button type='link' className="btn-success" style={{marginRight:'5px',display:billInfo.allPaid==1?'':'none'}}>已结清</Button>
                    </span>    
                    
                </div>
                <Row className="fs12 b12">
                    <Col span={8}>费用类型：{billInfo.feeType == 99?'其他费用':feeTypeList[billInfo.feeType-1]}</Col>
                    <Col span={8}>账单类型：{billInfo.billType==2?'运营':'合同（'+htType[billInfo.bizTypeSub]+')'}</Col>
                    <Col span={8}>{textDes.t1}：{billInfo.payAmount}元</Col>
                    
                </Row>
                <Row className="fs12 b12">
                    <Col span={8}>{textDes.t2}：{billInfo.payDate}</Col>
                    <Col span={8}>费用周期：{billInfo.beginDate||'-'}至{billInfo.endDate||'-'}</Col>
                    <Col span={8}>{textDes.t3}：{billInfo.customerName}  {billInfo.customerPhone}</Col>
                </Row>
                <Row className="fs12 b12">
                    <Col span={24} className="word-break">费用描述：{billInfo.description}</Col>
                </Row>
            </div>   
        )
    }
}

export default BaseInfo;