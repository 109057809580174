import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Button, Modal } from 'antd';
import DisplayAuth from 'CMPT/common/auth/DisplayAuth';
import AuthContainer from 'CMPT/common/auth/AuthContainer';
import AssignStaff from './AssignStaff';
import { AUTH_KEYS } from '@/utils/auth';
import http from '@/http';
import { API } from '@/http/api';
import { getReadAuth } from '@/utils/authConfig';
import styles from "./SettingOrganizationRoleRead.module.less";

const BreadcrumbItem = Breadcrumb.Item;

export default function SettingOrganizationRoleRead({ match: { params: { id } }, history }) {
    const [role, setRole] = useState({});
    const [authMap, setAuthMap] = useState(null);
    const [showDeleteConfirm, toggleDeleteConfirm] = useState(false);
    const [deleteLoading, toggleDeleteLoading] = useState(false);

    let assignStaffRef;

    useEffect(() => {
        http({
            api: API.roleDetail,
            path: {
                code: id
            },
            showLoading: true
        }).then(data => {
            const role = data.data || {};
            setRole(role);
            setAuthMap(getReadAuth(role.functionIds));
        });
    }, [id]);

    function displayAssign() {
        assignStaffRef.show(id, role.name);
    }

    function showDeleteRoleConfirm() {
        toggleDeleteConfirm(true);
    }

    function deleteRole() {
        toggleDeleteLoading(true);

        http({
            api: API.roleDel,
            path: {
                code: id
            },
            successTip: '删除成功',
            showLoading: true
        }).then(ajaxData => {
            toggleDeleteLoading(false);
            if (ajaxData.success) {
                hideDeleteRoleConfirm();
                history.replace('/app/crm/setting/role');
            }
        }).catch(error => {
            toggleDeleteLoading(false);
        });
    }

    function hideDeleteRoleConfirm() {
        toggleDeleteConfirm(false);
    }

    const { name, roleDesc, canEdit } = role;

    const editable = !!canEdit;

    return (
        <div className={styles.SettingOrganizationRoleRead}>
            <div className={styles.header}>
                <Breadcrumb>
                    <BreadcrumbItem>设置中心</BreadcrumbItem>
                    <BreadcrumbItem><Link to='/app/crm/setting/role'>角色管理</Link></BreadcrumbItem>
                    <BreadcrumbItem>角色详情</BreadcrumbItem>
                </Breadcrumb>
                <div className={styles.operate}>
                    {editable && <AuthContainer authCodes={AUTH_KEYS.SETTING_ORGANIZATION_ROLE_DELETE}><Button type='danger' onClick={showDeleteRoleConfirm}>删除</Button></AuthContainer>}
                    <AuthContainer authCodes={AUTH_KEYS.SETTING_ORGANIZATION_ROLE_ASSIGN}><Button onClick={displayAssign}>分配员工</Button></AuthContainer>
                    {editable && <AuthContainer authCodes={AUTH_KEYS.SETTING_ORGANIZATION_ROLE_MODIFY}><Button type="primary" ><Link to={'/app/crm/setting/role/modify/' + id}>编辑</Link></Button></AuthContainer>}
                </div>
            </div>
            <div className={styles.baseinfo}>
                <div className={styles.title}>角色信息</div>
                <div className={styles.name}>角色名称：{name}</div>
                <div>角色描述：{roleDesc}</div>
            </div>
            <div className={styles.authinfo}>
                <div className={styles.title}>角色权限</div>
                <DisplayAuth authMap={authMap} />
            </div>
            <AssignStaff ref={ref => assignStaffRef = ref} />
            <Modal
                title="确认删除此角色？"
                visible={showDeleteConfirm}
                okText='删除'
                okType='danger'
                confirmLoading={deleteLoading}
                closable={false}
                maskClosable={false}
                width={400}
                onOk={deleteRole}
                onCancel={hideDeleteRoleConfirm}
            >
            </Modal>
        </div>

    );
}
