/***
 * 一， 当前负责人： lvwenzhuo875  --> 功能有bug，或者 新功能需求 或者 好建议，就找他了
 *     历史贡献人:
 *
 * 二,  目标：CRM端目前出现多个 有限数据量下 进行拖动排序的功能。
 *           为方便拖动功能的实现，因此考虑抽取出一个组件来支持 拖动排序类型列表数据的展示。
 *
 * 三 说明：目标数据为有限数量，所以将table的默认页码 直接隐藏了。
 *         如果有新需求，请提出需求单来进一步完善。
 *
 * 四， 使用方法:
 *      1) 引入(依赖Table控件):
 *               import DragTable from "CMPT/widget/DragTable";
 *
 *      2)和正常的Table一致，你需要一个 Colums和:httpData，这两个数据必须有：
 *       
 *        state = {
 *            .....
 *            columns: this.createColumns(),
 *            httpData: [],
 *            ......
 *        }
 *
 *      3) 使用DragTable:
 *         <DragTable
 *           columns={this.state.columns}  ---> 字段声明
 *          dataSource={this.state.httpData}  --->数据来源
 *          onChange={this.onChange}   -->回调方法
 *        />
 *
 *      4) 当拖拽排序后，回调onChange。
 *         如下，newData和oldData内置数据都是一样的，只是前后顺序变了，而且数据源仍是调用方传入的:
 *           onChange = (newData, oldData) => {
 *             console.log("  onChange  new --->", newData);
 *             console.log("  onChange  old --->", oldData);
 *           };

 *
 *  五, 后续计划：
 *     对样式进行完善
 *
 *  六, 修改记录：
 *     1),  2020-03-17  初步实现
 *
 *
 *
 *
 */
import React, { Component } from "react";
import { Table, message } from "antd";
import { DndProvider, DragSource, DropTarget } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import update from "immutability-helper";
import "./DragTable.less";

let dragingIndex = -1;
class BodyRow extends React.Component {
  render() {
    const {
      isOver,
      connectDragSource,
      connectDropTarget,
      moveRow,
      ...restProps
    } = this.props;
    const style = { ...restProps.style, cursor: "move" };
    let { className } = restProps;
    if (isOver) {
      if (restProps.index > dragingIndex) {
        className += " drop-over-downward";
      }
      if (restProps.index < dragingIndex) {
        className += " drop-over-upward";
      }
    }
    return connectDragSource(
      connectDropTarget(
        <tr {...restProps} className={className} style={style} />
      )
    );
  }
}
const rowSource = {
  beginDrag(props) {
    dragingIndex = props.index;
    return { index: props.index };
  }
};
const rowTarget = {
  drop(props, monitor) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;
    // Don 't replace items with themselves if (dragIndex === hoverIndex) { return; }
    // Time to actually perform the action
    props.moveRow(dragIndex, hoverIndex); // Note: we're mutating the monitor item here! // Generally it's better to avoid mutations, // but it's good here for the sake of performance // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  }
};
const DragableBodyRow = DropTarget("row", rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver()
}))(
  DragSource("row", rowSource, connect => ({
    connectDragSource: connect.dragSource()
  }))(BodyRow)
);

class DragTable extends Component {
  state = {
    data: null,
    columns: null
  };
  components = {
    body: {
      row: DragableBodyRow
    }
  };
  moveRow = (dragIndex, hoverIndex) => {
    const { data } = this.state;
    const dragRow = data[dragIndex];

    let oldData = { ...data }; // 把旧的数据顺序 也返回

    let newState = update(this.state, {
      data: {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow]
        ]
      }
    });

    this.setState(newState, () => this.dispatchChange(dragIndex, hoverIndex,newState.data, oldData));
  };

  dispatchChange(dragIndex, hoverIndex,newData, oldData) {
    if (this.props.onChange) {
      this.props.onChange(dragIndex, hoverIndex,newData, oldData);
    }
  }

  componentDidMount() {
    let columns2 = this.props.columns;
    if (!columns2) {
      message.error("请提供字段声明");
      return;
    }

    let dataSource2 = this.props.dataSource;
    if (!dataSource2) {
      message.error("请提供数据来源");
      return;
    }

    let onChange = this.props.onChange;
    if (!onChange) {
      message.warn("请提供排序变化的Change回调函数");
    }

    let rowKey = this.props.rowKey;
    if (!rowKey) {
      message.warn("请提供一个roeKey");
    }

    this.setState({
      columns: columns2,
      data: dataSource2
    });
  }

  render() {
    if (!this.state.columns || !this.state.data) {
      return <div />;
    }

    return (
  
      <DndProvider backend={HTML5Backend}>
        <Table
          rowKey = {this.props.rowKey}
          columns={this.state.columns}
          dataSource={this.state.data}
          components={this.components}
          pagination={false}
          onRow={(record, index) => ({
            index,
            moveRow: this.moveRow
          })}
        />
      </DndProvider>
    );
  }
}

export default DragTable;
