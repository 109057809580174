import React, { Component } from 'react';
import { Upload, message, Icon, Tooltip, Row, Col } from 'antd';
import {
    API
} from '@/http/api';
import axios from 'axios';
import wordSrc from '@/images/files_word@2x.png';
import pdfSrc from '@/images/files_pdf@2x.png';
import excelSrc from '@/images/files_excel@2x.png';
import pptSrc from '@/images/files_ppt@2x.png';
import txtSrc from '@/images/files_txt@2x.png';
import unknowSrc from '@/images/files_unknown@2x.png';
import { formRequest } from '@/http';

//由于附件需要上传的文件比较多，特地封装一下，无特殊需求的 antd的upload应该可以满足需求
const fileTypeArray = ['image/jpeg', 'jpg', 'png']
class ImageUploadBiz extends Component {

    state = {
        fileList: [],
        imgInfo: null,
        loading: false,
        imageUrl: null
    }


    handleChange = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
    };

    beforeUpload = (file) => {

        // 检查文件后缀(文件类型)是否符合要求。  后续应该可以修改为 自定义文件类型. 20200506
        const fileNameToArray = file.name.split('.');
        const fileType = fileNameToArray[fileNameToArray.length - 1];
        const isFile = !!fileTypeArray.find(item => fileType && item == fileType.toLowerCase())
        if (!isFile) {
            message.error('只支持' + fileTypeArray.join() + '文件');
            return false;
        }

        // 检查文件大小体积
        if (!this.checkFileSize(file)) {
            return false;
        }

        return true;
    }

    /* start:  检查文件体积 */
    checkDefaultFileSize(file) {
        const isLt5M = file.size / 1024 <= 500;
        if (!isLt5M) {
            message.error('文件大小不能超过500kb');
            return false;
        }
        return true;
    }

    checkFileSize(file) {
        let maxSize = this.props.maxSize;
        if (!maxSize) {
            return this.checkDefaultFileSize(file);
        }

        //TODO 理论上暂时不用考虑 M , Kb之外的文件体积单位。如果有，请添加  20200506
        if (maxSize.endsWithIgnoreCase("M")) {
            let maxSizeM = parseInt(maxSize.substring(0, maxSize.length - 1))
            let isLt = file.size / (1024 * 1024) <= maxSizeM;
            if (!isLt) {
                message.error('文件大小不能超过' + maxSize);
                return false;
            }
        } else if (maxSize.endsWithIgnoreCase("kb")) {
            let maxSizeKb = parseInt(maxSize.substring(0, maxSize.length - 2))
            let isLt = file.size / 1024 <= maxSizeKb;
            if (!isLt) {
                message.error('文件大小不能超过' + maxSize);
                return false;
            }
        } else {
            return this.checkDefaultFileSize(file);
        }

        return true;
    }
    /* end:  检查文件体积 */

    doUploadFile(value) {
        value.action = API.uploadAdd.url;
        const file = value.file;
        const fileNameToArray = file.name.split('.');
        const fileType = fileNameToArray[fileNameToArray.length - 1];
        formRequest(value).then(res => {
            if (res.code == 0) {
                let data = res.data;
                if (!data) {
                    message.error('上传失败，请重试')
                    return;
                }
                let fileList = [];
                let fileInfo = {
                    mediaExt: fileType,
                    mediaKey: data.key,
                    name: data.fileName,
                    type: fileType,
                    url: data.publicUrl
                }
                fileList = [fileInfo];
                this.setState({
                    fileList: fileList,
                    imgInfo: fileInfo,
                    imageUrl: data.publicUrl,
                    loading: false,
                }, () => {
                    this.props.imgUp(this.state.fileList)
                })
            }
        })
    }
    componentWillReceiveProps(nextProps) {
        if (!nextProps.imgList || nextProps.imgList.length == 0) {
            //console.log(nextProps);
            // console.log('image return')
            return;
        }
        const fileType = nextProps.imgList.length && nextProps.imgList[0].mediaExt;
        this.setState({
            imageUrl: nextProps.imgList[0].url,
            fileName: nextProps.imgList[0].name
        })
    }
    render() {
        const uploadButton = (
            <div style={{ height: '93px' }}>
                {!this.state.loading ? <span style={{ fontSize: '24px' }} className='icon iconfont iconupload'></span> : <Icon style={{ fontSize: '42px' }} type='loading' />}
                <div className="ant-upload-text" style={{ margin: 'auto', width: '76%' }}>
                    拖拽至此 或 点击上传
              </div>
            </div>
        );
        const imageUrl = this.state.imageUrl;
        return (
            <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={this.beforeUpload}
                action='http://oms.st.idf10.com.cn/web/distribute_file/upload'

                customRequest={this.doUploadFile.bind(this)}
                showUploadList={false}
                onChange={this.handleChange}
            >
                {imageUrl ? <img style={{ width: '120px', height: '90px' }} src={imageUrl} alt="avatar" /> : uploadButton}
            </Upload>
        )
    }
}

export default ImageUploadBiz
