import React, { Component } from 'react'
import { Breadcrumb, Icon, Row, Col, Button, Popover, Modal, Input, message } from 'antd';
import Tip from 'CMPT/common/Tip';
import "./finance.less"
import Table from 'CMPT/common/Table';
import ApprovalChain from 'CMPT/common/ApprovalChain';
import { Link } from 'react-router-dom';
import HistoryPayRecord from './HistoryPayRecord';
import BaseInfo from './BaseInfo'
import http from '@/http';
import { API } from '@/http/api';
const BreadcrumbItem = Breadcrumb.Item;
const { TextArea } = Input;

const iInfo = {
    t1: '收',
    t2: '付'
}
const oInfo = {
    t1: '付',
    t2: '收'
}

class Approval extends Component {
    state = {
        billInfo: {},
        stepList: [],
        auditStatus: 0,
        columns: [
            {
                title: '时间',
                dataIndex: 'createTime',
            }, {
                title: '操作人',
                dataIndex: 'createName',
            }, {
                title: '操作类型',
                dataIndex: 'operateType',
            }, {
                title: '备注',
                dataIndex: 'content'
            }
        ],
        data: [],
        showPass: false,
        showReject: false,
        passReason: "",
        rejectReason: '',
        textBO: {
            pt: "确认通过此账单？",
            rt: "确认驳回此账单？"
        },
        textDes: {
            t1: '收',
            t2: '付'
        },
        isShouFu: false,
        approvalType: 0,
        isRefund: false,
        paymentEncode: undefined,
        paymentInfo: {},
        refundEncode: undefined,
        refundInfo: {},
        searchType: 0,
    }
    componentDidMount() {
        if (this.state.paymentEncode) {
            this.getPaymentInfo();
            if (this.state.searchType == 1) {
                this.getAuditFlowList(34, this.state.paymentEncode)
            }
            if (this.state.searchType == 2) {
                this.getAuditFlowList(32, this.state.paymentEncode)
            }
        } else if (this.state.refundEncode) {
            this.getRefundInfo();
            this.getAuditFlowList(33, this.state.refundEncode)
        } else {
            this.getAuditFlowList(31, this.props.match.params.id)
            this.getInfo();
        }
    }
    componentWillMount() {
        if (this.props.location.search) {
            let arr = this.props.location.search.substr(1).split('&');
            let type = undefined;
            let paymentEncode = undefined;
            let refundEncode = undefined;
            if (arr[0]) {
                type = arr[0].split('=')[1];
            }
            if (arr[1]) {
                console.log(arr[1].split('=')[1])
                paymentEncode = ('paymentEncode' == arr[1].split('=')[0]) ? arr[1].split('=')[1] : undefined;
                refundEncode = ('refundEncode' == arr[1].split('=')[0]) ? arr[1].split('=')[1] : undefined;
            }
            this.setState({
                paymentEncode: paymentEncode,
                refundEncode: refundEncode,
                searchType: type
            })
            // console.log(arr)
            if (type == 1) {
                this.setState({
                    textDes: iInfo,
                    isShouFu: true,
                    approvalType: 1
                })
            }
            else if (type == 2) {
                this.setState({
                    textDes: oInfo,
                    isShouFu: true,
                    approvalType: 2
                })
            }
            else if (type == 3) {
                this.setState({
                    isRefund: true,
                    approvalType: 3
                })
            }
        }
    }
    getAuditFlowList(type, code) {
        http({
            api: API.billGetAuditFlowList,
            data: {
                encode: code,
                type: type
            }
        }).then(res => {
            if (res.data) {
                this.setState({
                    stepList: res.data
                })
            }
        })
    }
    getRefundInfo() {
        http({
            api: API.billrefundGetById,
            data: {
                encode: this.state.refundEncode
            }
        }).then(res => {
            if (res.data) {
                this.setState({
                    refundInfo: res.data,
                    billInfo: res.data.billVo,
                })
            }
        })
    }
    getPaymentInfo() {
        http({
            api: API.billpayGetById,
            data: {
                encode: this.state.paymentEncode
            }
        }).then(res => {
            if (res.data) {
                this.setState({
                    paymentInfo: res.data,
                    billInfo: res.data.billVo,
                })
            }
        })
    }
    getInfo() {
        http({
            api: API.billGetById,
            data: {
                encode: this.props.match.params.id
            }
        }).then(res => {
            if (res.data) {
                this.setState({
                    billInfo: res.data
                })
            }
        })
    }
    changeValue(key, value) {
        let data = this.state
        data[key] = value.target.value
        this.setState(data)
    }
    showModal(val) {
        if (val == 1) {
            this.setState({
                showPass: true
            })
        }
        if (val == 2) {
            this.setState({
                showReject: true
            })
        }
    }
    passCancel() {
        this.setState({
            showPass: false
        })
    }
    ok0() {
        http({
            api: API.billAudit,
            data: {
                code: this.props.match.params.id,
                auditStatus: 1,
                desc: this.state.passReason
            }
        }).then(res => {
            if (res.code == '0') {
                message.success('操作成功');
                this.setState({
                    showPass: false
                })
                this.props.history.push({ pathname: '/app/operate/finance/approval' });
            }
        })
    }
    ok1() {
        http({
            api: API.billpayAudit,
            data: {
                code: this.state.paymentEncode,
                auditStatus: 1,
                desc: this.state.passReason
            }
        }).then(res => {
            if (res.code == '0') {
                message.success('操作成功');
                this.setState({
                    showPass: false
                })
                this.props.history.push({ pathname: '/app/operate/finance/approval' });
            }
        })
    }
    ok2() {
        http({
            api: API.billrefundAudit,
            data: {
                code: this.state.refundEncode,
                auditStatus: 1,
                desc: this.state.passReason
            }
        }).then(res => {
            if (res.code == '0') {
                message.success('操作成功');
                this.setState({
                    showPass: false
                })
                this.props.history.push({ pathname: '/app/operate/finance/approval' });
            }
        })
    }
    passOk() {
        if (this.state.approvalType == 0) {
            this.ok0();
        }
        if (this.state.approvalType == 1 || this.state.approvalType == 2) {
            this.ok1();
        }
        if (this.state.approvalType == 3) {
            this.ok2();
        }
    }
    rejectCancel() {
        this.setState({
            showReject: false
        })
    }
    rej0() {
        http({
            api: API.billAudit,
            data: {
                code: this.props.match.params.id,
                auditStatus: 2,
                desc: this.state.rejectReason
            }
        }).then(res => {
            if (res.code == '0') {
                message.success('操作成功');
                this.setState({
                    showPass: false
                })
                this.props.history.push('/app/operate/finance/approval?type=1');
            }
        })
    }
    rej1() {
        http({
            api: API.billpayAudit,
            data: {
                code: this.state.paymentEncode,
                auditStatus: 2,
                desc: this.state.rejectReason
            }
        }).then(res => {
            if (res.code == '0') {
                message.success('操作成功');
                this.setState({
                    showPass: false
                })
                this.props.history.push('/app/operate/finance/approval?type=2');
            }
        })
    }
    rej2() {
        http({
            api: API.billrefundAudit,
            data: {
                code: this.state.refundEncode,
                auditStatus: 2,
                desc: this.state.rejectReason
            }
        }).then(res => {
            if (res.code == '0') {
                message.success('操作成功');
                this.setState({
                    showPass: false
                })
                this.props.history.push('/app/operate/finance/approval?type=3');
            }
        })
    }
    rejectOk() {
        if (this.state.rejectReason) {
            if (this.state.approvalType == 0) {
                this.rej0();
            }
            if (this.state.approvalType == 1 || this.state.approvalType == 2) {
                this.rej1();
            }
            if (this.state.approvalType == 3) {
                this.rej2();
            }
        } else {
            message.error('请输入驳回原因')
        }
    }
    render() {
        const { billInfo, textBO, isShouFu, textDes, paymentInfo, refundInfo, isRefund } = this.state;
        const auditComponent = (
            <Popover overlayClassName="bo-audit-popover" content={<ApprovalChain stepList={this.state.stepList} />} trigger="click" placement="bottomRight">
                <div className="clickPopover"><i className="iconfont iconapprove"></i><span>查看审批流程</span></div>
            </Popover>
        )
        const ImgList = paymentInfo.photos && paymentInfo.photos.map(obj => {
            return (
                <img src={obj.url} key={obj.id} />
            )
        })
        let auditObj = {};
        if (this.state.paymentEncode) {
            auditObj = paymentInfo;
        } else if (this.state.refundEncode) {
            auditObj = refundInfo;
        } else {
            auditObj = billInfo;
        }
        const titleTxt = ['账单', '收款', '付款', '退款'];
        const payChannel = ['现金', '刷卡', '转账', '支付宝', '微信'];
        return (
            <div className="contract-list-out cx-approval">
                <Modal
                    className="passModal"
                    title={textBO.pt}
                    closable={false}
                    visible={this.state.showPass}
                    onOk={this.passOk.bind(this)}
                    okText='通过'
                    width={400}
                    onCancel={this.passCancel.bind(this)}
                >
                    <TextArea rows={4} maxLength={200} placeholder={'请输入备注 (选填)'} onChange={this.changeValue.bind(this, 'passReason')} />
                </Modal>
                <Modal
                    className="passModal"
                    title={textBO.rt}
                    closable={false}
                    visible={this.state.showReject}
                    onOk={this.rejectOk.bind(this)}
                    okText='驳回'
                    width={400}
                    onCancel={this.rejectCancel.bind(this)}
                >
                    <TextArea rows={4} maxLength={200} placeholder={'请输入驳回原因 (必填)'} onChange={this.changeValue.bind(this, 'rejectReason')} />
                </Modal>
                <div className="contract-header flexCenter">
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link style={{ color: '#333' }} to={{ pathname: "/app/operate/finance/approval" }}>
                                财务收支
                            </Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <Link style={{ color: '#333' }} to={{ pathname: "/app/operate/finance/approval" }}>
                                财务审批
                            </Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem>{titleTxt[this.state.searchType]}审批</BreadcrumbItem>
                    </Breadcrumb>
                    <span style={{ display: isShouFu ? '' : 'none' }}>
                        <HistoryPayRecord {...this.props} {...this.state} />
                    </span>
                </div>
                <div style={{ display: auditObj.auditStatus == 0 ? '' : 'none' }}>
                    <Tip type="wait" title="审批中" hint={true} secondTitle={'当前审批人：' + auditObj.auditUserName} hintNode={auditComponent} />
                </div>
                <div style={{ display: auditObj.auditStatus == 1 ? '' : 'none' }}>
                    <Tip type="warn" title="审批驳回" hint={true} hintNode={auditComponent} des={auditObj.description} />
                </div>
                <div style={{ display: auditObj.auditStatus == 2 ? '' : 'none' }}>
                    <Tip type="pass" title="审批通过" hint={true} hintNode={auditComponent} />
                </div>
                <BaseInfo {...this.state} {...this.props} noshowOverdue={true} />
                <div style={{ display: isShouFu ? '' : 'none' }}>
                    <div className="approval-baseInfo">
                        <div className="title">{textDes.t1}款信息</div>
                        <Row className="fs12 b12">
                            <Col span={8}>本次{textDes.t1}款金额：{paymentInfo.amount}元</Col>
                            <Col span={8}>{textDes.t1}款日期：{paymentInfo.date}</Col>
                        </Row>
                        <Row className="fs12 b12">
                            <Col span={8}>支付途径：{paymentInfo.channel == 9 ? '其他' : payChannel[paymentInfo.channel - 1]}</Col>
                            <Col span={8}>银行流水：{paymentInfo.bankStatementNo}</Col>
                            <Col span={8}>票据号：{paymentInfo.receiptNo}</Col>
                        </Row>
                        <Row className="fs12 b12">
                            <Col span={8}>{textDes.t1}款银行：{paymentInfo.companyBank}</Col>
                            <Col span={8}>{textDes.t1}款账号：{paymentInfo.companyAccount ? paymentInfo.companyAccount : ''}</Col>
                        </Row>
                        <Row className="fs12 b12">
                            <Col span={24}>备注：{paymentInfo.description}</Col>
                        </Row>
                        <Row className="fs12 b12">
                            <Col className="b12" span={24}>相关附件：</Col>
                            <Col span={24}>
                                {ImgList}
                            </Col>
                        </Row>
                    </div>
                    <div className="approval-baseInfo">
                        <div className="title">{textDes.t2}款信息</div>
                        <Row className="fs12 b12">
                            <Col span={8}>{textDes.t2}款人：{paymentInfo.customerName}</Col>
                            <Col span={8}>{textDes.t2}款人手机号：{paymentInfo.customerPhone}</Col>
                            <Col span={8}>{textDes.t2}款银行：{paymentInfo.bank}</Col>
                        </Row>
                        <Row className="fs12 b12">
                            <Col span={8}>{textDes.t2}款账号：{paymentInfo.account ? paymentInfo.account : ''}</Col>
                        </Row>
                    </div>
                </div>
                <div style={{ display: isRefund ? '' : 'none' }}>
                    <div className="approval-baseInfo">
                        <div className="title">退款信息</div>
                        <Row className="fs12 b12">
                            <Col span={8}>退款金额：{refundInfo.amount}元</Col>
                            <Col span={8}>退款申请日期：{refundInfo.createTime}</Col>
                        </Row>
                        <Row className="fs12 b12">
                            <Col span={24}>退款原因：{refundInfo.reason}</Col>
                        </Row>
                    </div>
                </div>
                <div className="approval-btn">
                    <Button type="dashed" onClick={this.showModal.bind(this, 1)}>通过</Button>
                    <Button type="danger" onClick={this.showModal.bind(this, 2)}>驳回</Button>
                </div>
            </div>
        )
    }
}

export default Approval;