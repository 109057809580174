import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Button, Input, Row, Col, Select, Icon, message, DatePicker } from 'antd';
import moment from 'moment';
import { changeObjkey, Judgetype,getNewPhone } from '@/utils'
import http from '@/http'
import reg from '@/utils/regex'
import { API } from '@/http/api'
import BsCascader from 'CMPT/common/Cascader'
import BreadcrumbHeader from 'CMPT/common/breadcrumb/breadcrumb'
import BsUpload from 'CMPT/common/Upload'
import './addorder.less'
const { phoneNew} = { ...reg }
const { TextArea } = Input;
const { Option } = Select;
const { Item } = Form;

const dateFormat = 'YYYY-MM-DD HH:mm';
const fiftertime = (length, fifrercn) => {
    const result = [];
    for (let i = 0; i < length; i++) {
        result.push(i)
    }

    return Judgetype(fifrercn) === 'function' ? fifrercn(result) : result;
};
const keyMapCascader = {key:'value',value:'label'};
const imgType = ['png','jpg','jpeg','bmp'];

class WrappedApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projects: [],
            projectcount: false,
            service: [],
            optionsurgentlevelType: [],
            addorderObj: {
                address: null, // 地址
                applicantName: null, // 上报人
                bizType: null, // 维修类型（1级大类(1-屋内设施、2-公共设施))
                description: null, // 问题描述
                mobile : null, // 手机号码
                // pictureInfoList: [
                //     {
                //         mediaExt: null, // 文件扩展名
                //         mediaKey: null, // 文件系统key
                //         name: null, // 文件名称
                //         type: null, // 文件类型
                //         url: null, // 文件路径
                //     }
                // ],// 照片信息
                pictureInfoList: [],
                processTime: null, // 预计处理时间 yyyy-MM-dd HH:mm:ss
                projectEncode: null, // 项目加密code
                type: null, // 维修类型（2级用户自定义 ）
                urgentLevel: null, // 紧急程度 （1紧急、2一般）
                zoneEncode: null, //房间加密code
            }
        }
    }
    //数据选中后改变state
    changeaddModal(type,val) {
        const addorderdata = this.state.addorderObj;
        let targetVal = null;
        if (val && val.target) targetVal = val.target.value;

        switch (type) {
            case 1:
                addorderdata.projectEncode = val[0] ? val[0] : null;
                addorderdata.zoneEncode = val[2] ? val[2] : null;
                break;
            case 2:
                addorderdata.address = targetVal;
                break;
            case 3:
                addorderdata.bizType = val[0] ? val[0] : null;
                addorderdata.type = val[1] ? val[1] : null;
                break;
            case 4:
                addorderdata.urgentLevel = Number(val);
                break;
            case 5:
                addorderdata.description = targetVal;
                break;
            case 6:
                const { fileList }= val;
                const pictureInfo = fileList.length > 0 ? fileList.map(item => {
                    let list = {};
                    if(item.response) {
                        const { file } = item.response;
                        const typelist = file.fileName.split('.');
                        const type = typelist && typelist[typelist.length-1];
                        list = {
                            name: file.fileName,
                            mediaExt: type,
                            type: type,
                            mediaKey: file.key,
                            url: file.publicUrl  //`${process.env.FILE_DOMAIN}show/${file.key}`
                        }
                    }
                    return list
                }) : fileList;

                addorderdata.pictureInfoList = pictureInfo;
                break;
            case 7:
                addorderdata.applicantName = targetVal;
                break;
            case 8:
                addorderdata.mobile = targetVal.replace(/\s+/g, '');
                break;
            case 9:
                addorderdata.processTime = val ? val.format('YYYY-MM-DD HH:mm:ss') : null;
            default:
                break;
        }

        this.setState({
            addorderObj:addorderdata
        })
    }
    PopupVisibleChange(val) {
        if (!this.state.projectcount && val) {
            const projectsFilter = this.state.projects.filter(item=>item.children.length>0);
            setTimeout(()=>{
                this.setState({
                    projects: projectsFilter,
                    projectcount: true
                })
            },0)
        }
    }
    // Can not select days before today
    disabledDate(current) {
        return current && current < moment().startOf('day');
    }
    //日期选择以30分钟为限制
    disabledRangeTime() {
        return {
            disabledHours: () => {
                const time = new Date().getHours();
                return time && fiftertime(24,(obj) => {
                    return obj.filter(item => item <= time)
                });
            },
            disabledMinutes: () => {
                const time = new Date().getMinutes();
                return time && fiftertime(60,(obj) => {
                    return obj.filter(item => Number(item) !== 0 && Number(item) !== 30)
                });
            }
        }
    }
    //处理options数据类型
    orderOptionsList(options) {
        return this.state[options].map(d => <Option key={d.value}>{d.label}</Option>)
    }
    //点击确认
    onClickOk(event) {
        event.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.postDateSave(this.state.addorderObj);
            }
        });
    }
    //点击取消
    leavePage() {
        this.props.history.replace({
            pathname:"/app/operate/serviceCenter/logistics/maintain"
        })
    }
    //在离开页面前判断是否数据有更改
    beforeleaveJudgeData() {
        const addorderObj = this.state.addorderObj;
        return Object.values(addorderObj).some(item => item)
    }
    componentWillMount() {
        this.getmaintainInit();
        this.getitemInit()
    }

    //请求初始化枚举
    getmaintainInit() {
        const config = {
            api: API.maintainInit,
            showLoading: true
        }
        http(config).then(result => {
            const { data } = result;
            let { maintainTypeEnum, urgentLevelEnum } = data;

            //维修服务
            maintainTypeEnum = maintainTypeEnum.map(item => {
                const list = changeObjkey(item,keyMapCascader);
                this.loadDataService(item.key,list);
                return list
            });

            //紧急程度
            urgentLevelEnum = urgentLevelEnum.map(item => changeObjkey(item,keyMapCascader));

            setTimeout(()=>{
                this.setState({
                    service: maintainTypeEnum,
                    optionsurgentlevelType: urgentLevelEnum
                })
            },0)
        })

    }
    //请求初始化的项目
    getitemInit() {
        const config = {
            api: API.commonProjectfilter
        }
        http(config).then(result => {
            const { data } = result;
            const json = data.map(item => {
                const list = changeObjkey(item,{encode:'value',name:'label'},true);
                this.loadDataProject(item.encode,list);
                return list
            });

            setTimeout(()=>{
                this.setState({
                    projects: json
                })
            },0)
        })
    }
    //请求服务类型二级
    loadDataService(selectedOptions,obj) {
        const url = Number(selectedOptions) === 1 ? API.sysconfigListRoomEquipment : API.sysconfigListPublicEquipment;
        const config = {
            api: url
        };

        http(config).then(result => obj.children = result.data && result.data.map(item => changeObjkey(item,{id:'value',name:'label'},true)))
    }
    //请求房源项目二级
    loadDataProject(selectedOptions,obj) {
        const config = {
            api: API.commonFloorZoneFilter,
            data: {
                projectEncode: selectedOptions
            },
            showLoading: true
        };
        obj.children = [];
        http(config).then(result => result.data && result.data.length > 0 && result.data.forEach(item => {
            if (item.zones && item.zones.length > 0) {
                const list = changeObjkey(item,{encode:'value',floorNo:'label'},true);
                list.children = item.zones.map(itemChild => changeObjkey(itemChild,{zoneNo:'label',encode:'value'},true));

                obj.children.push(list)
            }
        }))
    }
    //请求保存
    postDateSave(data) {
        const config = {
            api: API.maintainAdd,
            data: {
                ...data
            },
            showLoading: true
        };
        http(config).then(result => result.success && (message.success(`保存成功`) && this.leavePage()))
    }
    cancle() {
        this.props.history.go(-1)
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const breadlist = [{name:'维修服务',route:'/app/operate/serviceCenter/logistics/maintain'},{name:'新增工单'}];
        const optionsurgentlevelType = this.orderOptionsList('optionsurgentlevelType');
        return (
            <div className='maintain-addorder'>
                <div className='maintain-addorder-header'>
                    <BreadcrumbHeader breadlist={breadlist}></BreadcrumbHeader>
                </div>
                <Form onSubmit={this.onClickOk.bind(this)}>
                    <div className='maintain-addorder-body'>
                        <div className='maintain-addorder-body-orderinfo'>
                            <div className='maintain-addorder-body-title'>工单信息</div>
                            <Item label="所属项目">
                                {getFieldDecorator('projectEncode', {
                                    rules: [{ required: true, message: '请选择所属项目' }],
                                })(
                                    <BsCascader
                                        style={{width:240}}
                                        options={this.state.projects}
                                        placeholder="请选择"
                                        onChange={this.changeaddModal.bind(this,1)}
                                        onPopupVisibleChange={this.PopupVisibleChange.bind(this)}>
                                    </BsCascader>
                                )}
                            </Item>
                            <Item label="地址">
                                {getFieldDecorator('address', {
                                    rules: [{ required: true, message: '请输入地址' }],
                                })(
                                    <Input
                                        style={{width:240}}
                                        placeholder="请输入地址"
                                        maxLength={50}
                                        onChange={this.changeaddModal.bind(this,2)}/>
                                )}
                            </Item>
                            <Item label="维修类型">
                                {getFieldDecorator('bizType', {
                                    rules: [{ required: true, message: '请选择维修类型' }],
                                })(
                                    <BsCascader
                                        style={{width:240}}
                                        options={this.state.service}
                                        placeholder="维修类型"
                                        onChange={this.changeaddModal.bind(this,3)}>
                                    </BsCascader>
                                )}
                            </Item>
                            <Item label="紧急程度">
                                {getFieldDecorator('urgentLevel', {
                                    rules: [{ required: true, message: '请选择紧急程度' }],
                                })(
                                    <Select
                                        style={{width: 240}}
                                        placeholder="请选择"
                                        suffixIcon={<Icon type="caret-down" />}
                                        onSelect={this.changeaddModal.bind(this,4)}>
                                        {optionsurgentlevelType}
                                    </Select>
                                )}
                            </Item>
                            <Item label="问题描述">
                                {getFieldDecorator('description')(
                                    <TextArea
                                        maxLength={200}
                                        style={{resize:'none'}}
                                        onChange={this.changeaddModal.bind(this,5)}>
                                    </TextArea>
                                )}
                            </Item>
                            <Item label="图片上传 支持png、jpg、jpeg、bmp格式，最多5张" className='row-special'>
                                {getFieldDecorator('pictureInfoList',{
                                    rules: [{
                                        message: '支持png、jpg、jpeg、bmp格式，不能超过20mb，最多5张',
                                        validator: (rule, value, callback)=>{
                                            if (value) {
                                                const { fileList } = value;
                                                if (fileList) {
                                                    if (fileList.length > 5) callback(false);
                                                    fileList.forEach(item => {
                                                        const type = item.name.split('.');
                                                        if (!imgType.includes(type[type.length-1])) callback(false);
                                                        if (item.size >= 20971520) callback(false)
                                                    });
                                                }
                                            }
                                            callback()
                                        }
                                    }],
                                })(
                                    <BsUpload   action={API.uploadAdd.url}
                                                style={{width: 120}}
                                                listType={'picture'}
                                                onChange={this.changeaddModal.bind(this,6)}>
                                    </BsUpload>
                                )}
                            </Item>
                        </div>
                        <div className='maintain-addorder-body-moreinfo'>
                            <div className='maintain-addorder-body-title'>更多信息</div>
                            <Row className='row-special-row'>
                                <Col span={2}>
                                    <Item label="上报人信息">
                                        {getFieldDecorator('applicantName', {
                                            rules: [{ required: true, message: '请输入上报人' }],
                                        })(
                                            <Input
                                                placeholder="请输入上报人"
                                                maxLength={10}
                                                onChange={this.changeaddModal.bind(this,7)}/>
                                        )}
                                    </Item>
                                </Col>
                                <Col span={3} offset={1}>
                                    <Item>
                                        {getFieldDecorator('mobile', {
                                            rules: [{
                                                required: true,
                                                message: '请输入正确的手机号码' ,
                                                pattern:phoneNew
                                            }],
                                            getValueFromEvent: e => (getNewPhone(e))
                                        })(
                                            <Input
                                                placeholder="请输入手机号码"
                                                maxLength={13}
                                                onChange={this.changeaddModal.bind(this,8)}/>
                                        )}
                                    </Item>
                                </Col>
                            </Row>
                            <Item label="预计处理时间">
                                {getFieldDecorator('ordertime', {
                                    rules: [{ required: true, message: '请选择处理时间' }],
                                })(
                                    <DatePicker
                                        style={{width: 240}}
                                        format={dateFormat}
                                        showToday={false}
                                        showTime={{format:'HH:mm',defaultValue: moment(`${new Date().getHours()+1}:00`, 'HH:mm')}}
                                        disabledDate={this.disabledDate.bind(this)}
                                        disabledTime={this.disabledRangeTime.bind(this)}
                                        onChange={this.changeaddModal.bind(this,9)}
                                    />
                                )}
                            </Item> 
                        </div>
                    </div>

                    <div className='maintain-addorder-footer'>
                        <Item>
                            <Button type='primary' htmlType="submit">确认</Button>
                        </Item>
                        <Item>
                            <Button onClick={this.cancle.bind(this)}>取消</Button>
                        </Item>
                    </div>
                </Form>
            </div>
        )
    }
}

const AddOrder = Form.create({ name: 'addcoordinated' })(WrappedApp);

export default withRouter(AddOrder)
