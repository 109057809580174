import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Button, Input, Modal } from 'antd';
import moment from 'moment';
import Table from 'CMPT/common/Table';
import AuthContainer from 'CMPT/common/auth/AuthContainer';
import AssignStaff from './AssignStaff';
import http from '@/http';
import { API } from '@/http/api';
import './SettingOrganizationRole.less';
import userUtils from '@/utils/user';
import { AUTH_KEYS } from "@/utils/auth";
const { checkAuthByKey } = userUtils;
const canShowAddAuth = checkAuthByKey(AUTH_KEYS.SETTING_CENTER_MANAGE_ROLE_MANAGE_ADD); // 新增权限；
const BreadcrumbItem = Breadcrumb.Item;
const Search = Input.Search;

export default function SettingOrganizationRole() {
    const [data, setData] = useState({ datas: [], totalRecord: 0, pageNo: 1 });

    const [showDeleteConfirm, toggleDeleteConfirm] = useState(false);
    const [deleteLoading, toggleDeleteLoading] = useState(false);
    const [delTitle, setDelTitle] = useState();
    
    const currentRoleCode = useRef();
    const currentPageSize = useRef(10);
    const currentLikeName = useRef();


    let assignStaffRef;

    useEffect(() => {
        resetCurrent();

        loadTable();
    }, []);

    function resetCurrent() {
        currentRoleCode.current = null;
        currentPageSize.current = 10;
        currentLikeName.current = null;
    }

    function loadTable(pageNo = 1) {
        http({
            api: API.authRoleGetList,
            data: {
                pageNo,
                pageSize: currentPageSize.current,
                name: currentLikeName.current || null
            },
            showLoading: true
        }).then(res => {
            setData(res.data || { datas: [], totalRecord: 0, pageNo: 1 });
        });
    }

    const columns = [{
        title: '角色名称',
        dataIndex: 'name',
        // render(text, record) {
        //     return (<Link to={'/app/crm/setting/role/read/' + record.id} className='role-name'>{text}</Link>);
        // }
    }, {
        title: '角色描述',
        dataIndex: 'roleDesc',
    }, {
        title: '创建时间',
        dataIndex: 'createTime',
        render(text) {
            return formatTime(text);
        }
    }, {
        title: '操作',
        dataIndex: 'id',
        key: 'id',
        render(text, record, index) {
            const roleCode = record.id;
            const roleName = record.name;
            const canEdit = !!record.canEdit;
            return [
                <Link key='0' to={'/app/crm/setting/role/modify/' + roleCode} className='role-operate'>编辑</Link>,
                <span key='1' className='role-operate' onClick={() => showDeleteRoleConfirm(roleCode,roleName)}>删除</span>,
                // canEdit && <AuthContainer key='modify' authCodes={AUTH_KEYS.SETTING_ORGANIZATION_ROLE_MODIFY}><Link to={'/app/crm/setting/role/modify/' + roleCode} className='role-operate'>编辑</Link></AuthContainer>,
                // canEdit && <AuthContainer key='delete' authCodes={AUTH_KEYS.SETTING_ORGANIZATION_ROLE_DELETE}><span className='role-operate' onClick={() => showDeleteRoleConfirm(roleCode,roleName)}>删除</span></AuthContainer>,
            ]
        }
    }];

    function displayAssign(code, name) {
        assignStaffRef.show(code, name);
    }

    function showDeleteRoleConfirm(code,roleName) {
        currentRoleCode.current = code;
        let text = "确认删除【"+ roleName +"】角色？";
        setDelTitle(text);
        toggleDeleteConfirm(true);
    }

    function deleteRole() {
        if (currentRoleCode.current) {
            toggleDeleteLoading(true);

            http({
                api: API.authRoleDelete,
                path: {
                    id: currentRoleCode.current
                },
                successTip: '删除成功',
                showLoading: true
            }).then(ajaxData => {
                toggleDeleteLoading(false);
                if (ajaxData.success) {
                    hideDeleteRoleConfirm();
                    loadTable();
                }
            }).catch(error => {
                toggleDeleteLoading(false);
            });
        }
    }

    function hideDeleteRoleConfirm() {
        toggleDeleteConfirm(false);
        currentRoleCode.current = null;
    }

    function search(likeName) {
        currentLikeName.current = likeName;
        loadTable(1);
    }

    function changePager(pageNo, pageSize) {
        currentPageSize.current = pageSize;
        loadTable(pageNo, pageSize);
    }

    return (
        <div className='setting-organization-role'>
            <div className='role-header'>
                <Breadcrumb>
                    <BreadcrumbItem>设置中心</BreadcrumbItem>
                    <BreadcrumbItem>角色管理</BreadcrumbItem>
                </Breadcrumb>
                {/* <AuthContainer authCodes={AUTH_KEYS.SETTING_ORGANIZATION_ROLE_NEW}> */}
                    {
                        canShowAddAuth ?
                        <Link to='/app/crm/setting/role/new' className='new-role-btn' >
                            <Button type="primary">
                                新增
                            </Button>
                        </Link>
                        : null

                    }
                {/* </AuthContainer> */}
            </div>
            <div className='role-main'>
                <Search
                    placeholder="请输入角色名称"
                    onSearch={search}
                    className='role-search'
                />
                <Table rowKey={record => record.id}  showEmpty selfProcessData={data.datas || []} columns={columns} total={data.totalRecord} current={data.pageNo} change={changePager} />
            </div>
            <AssignStaff ref={ref => assignStaffRef = ref} />
            <Modal
                title={delTitle}
                visible={showDeleteConfirm}
                okText='删除'
                confirmLoading={deleteLoading}
                closable={false}
                maskClosable={false}
                width={400}
                onOk={deleteRole}
                onCancel={hideDeleteRoleConfirm}
            >
            </Modal>
        </div>
    );
}

function formatTime(time) {
    if (time) {
        return moment(time).format('YYYY-MM-DD HH:mm');
    }
}
