import React, { Component } from 'react';
import { Breadcrumb, Tabs, Badge, Button } from 'antd';
import './index.less';
import { API } from '@/http/api';
import http from '@/http';
import userUtil from "@/utils/user";

// 引入 ECharts 主模块
import echarts from 'echarts/lib/echarts';
// 引入柱状图
import  'echarts/lib/chart/bar';
// 引入提示框和标题组件
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';

const { TabPane } = Tabs;
const BreadcrumbItem = Breadcrumb.Item;

class DataOverView extends Component {

    state = {
        platData: [
            { tit: '累计充值/元', num: 0 },
            { tit: '账期待结/元', num: 0 },
            { tit: '累计购买数', num: 0 },
            { tit: '剩余数', num: 0 },
            { tit: '已使用', num: 0 },
        ],
        userData: [
            { tit: '累计充值/元', num: 0 },
            { tit: '账期待结/元', num: 0 },
            { tit: '累计购买数', num: 0 },
            { tit: '剩余数', num: 0 },
            { tit: '已使用', num: 0 },
        ],
    }

    componentDidMount() {
        
        this.getStatisticsPayData()

        // this.initChat()
    }

    getStatisticsPayData = () => {
        const config = {
            api: API.valueAddServiceGetStatisticsPayData,
            showLoading: true
        };
        http(config).then(res => {
            
            if (res && res.code == 200) {
                let { platData, userData } = this.state;
                let { platformPayData, userPayData, payNumberDataList, payPriceDataList } = res.data;
                platData[0].num = platformPayData.totalAmount;
                platData[1].num = platformPayData.delayAmount;
                platData[2].num = platformPayData.totalNumberStr;
                platData[3].num = platformPayData.balanceNumberStr;
                platData[4].num = platformPayData.usedNumberStr;

                userData[0].num = userPayData.totalAmount;
                userData[1].num = userPayData.delayAmount;
                userData[2].num = userPayData.totalNumberStr;
                userData[3].num = userPayData.balanceNumberStr;
                userData[4].num = userPayData.usedNumberStr;
                this.setState({platData, userData})
                let userPayData1 = []; // 用户购买量/使用量 图表日期
                let userPayData2 = []; // 用户购买量/使用量 购买
                let userPayData3 = []; // 用户购买量/使用量 使用
                payNumberDataList.forEach(item => {
                    userPayData1.push(item.statisticsDate);
                    userPayData2.push(item.totalNumber);
                    userPayData3.push(item.usedNumber);
                });
                let userPriceData1 = []; // 用户充值 日期
                let userPriceData2 = []; // 用户充值 金额
                payPriceDataList.forEach(item => {
                    userPriceData1.push(item.statisticsDate);
                    userPriceData2.push(item.totalAmount);
                });
                this.initChat(userPayData1, userPayData2, userPayData3, userPriceData1, userPriceData2)
            }

        });
    }

    initChat = (d1, d2, d3, t1, t2) => {
        // 基于准备好的dom，初始化echarts实例
        var myChart = echarts.init(document.getElementById('userDataEchartsDom'));
        var myChart1 = echarts.init(document.getElementById('userRecharge'));
        // 绘制图表
        myChart.setOption({
            title: { text: '用户购买量/使用量', textStyle: {
                color: '#2B2C33',
                fontWeight: '500',
                fontSize: 16
            } },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            xAxis: {
                data: d1
            },
            yAxis: {},
            series: [{
                name: '购买',
                type: 'bar',
                data: d2,
                barWidth: '10%',
                itemStyle: {
                    // 普通样式。
                    normal: {
                      // 点的颜色。
                      color: 'rgba(51, 149, 255, 1)'
                    },
                }
            }, {
                name: '使用',
                type: 'bar',
                data: d3,
                barWidth: '10%',
                itemStyle: {
                    // 普通样式。
                    normal: {
                      // 点的颜色。
                      color: '#00BF7A'
                    },
                }
            }]
        });

        myChart1.setOption({
            title: { text: '用户充值/元', textStyle: {
                color: '#2B2C33',
                fontWeight: '500',
                fontSize: 16
            } },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            xAxis: {
                data: t1
            },
            yAxis: {},
            series: [{
                name: '购买',
                type: 'bar',
                data: t2,
                barWidth: '10%',
                itemStyle: {
                    // 普通样式。
                    normal: {
                      // 点的颜色。
                      color: 'rgba(51, 149, 255, 1)'
                    },
                }
            }]
        });
    }

    goBuy = () => {
        this.props.history.push(`/app/crm/valueaddService/trajectoryFind/goBuy`)
    }

    addRecord = () => {
        this.props.history.push(`/app/crm/valueaddService/trajectoryFind/addRecord`)
    }

    render() {
        const {platData, userData} = this.state;
        return (

            <div className='data-over-content'>
                <div className='num_box'>
                    <div className='tit_box'>平台数据</div>
                    <div className='num_line'>
                        <div className='num_mibox'>
                            {
                                platData && platData.map(item => {
                                    return <div className='num_item' key={item.tit}>
                                        <span className='num'>{item.num}</span>
                                        <span className='tit'>{item.tit}</span>
                                    </div>
                                })
                            }
                        </div>
                        <div className='btn_box'>
                            <Button type="primary" onClick={this.goBuy}>添加购买</Button>
                            <Button onClick={this.addRecord}>添加记录</Button>
                        </div>
                    </div>
                </div>

                <div className='num_box'>
                    <div className='tit_box'>用户数据（SaaS平台用户）</div>
                    <div className='num_line'>
                        <div className='num_mibox'>
                            {
                                userData && userData.map(item => {
                                    return <div className='num_item' key={item.tit}>
                                        <span className='num'>{item.num}</span>
                                        <span className='tit'>{item.tit}</span>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>

                <div className='echarts_userdata' >
                    <div className='ect' id='userDataEchartsDom'></div>
                </div>

                <div className='echarts_userdata'>
                    <div className='ect' id='userRecharge'></div>
                </div>
            </div>


        );
    }
}

export default DataOverView;