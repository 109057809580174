import React, { Component } from 'react';
import {  Button } from 'antd';
import Table from 'CMPT/common/Table';
import Item from 'antd/lib/list/Item';

class IntentionTable extends Component {
    state = {
        selectedRowKeys: [],
        count:0
    }
    rowSelectionChange=(selectedRowKeys,selectedRows)=>{
        this.setState({
            selectedRowKeys,
            count:selectedRowKeys.length
        },()=>this.props.rowSelectionChange(selectedRows))
    }

    onChange = (page, pageSize,sort) => {
        this.props.onChange(page, pageSize,sort);
        this.cancel();
    }

    cancel = () =>{
        this.setState({
            selectedRowKeys: [],
            count: 0
        },()=> {
            this.props.rowSelectionChange([])
        })
    }
    
    render() {
        const tableConfig = {
            rowKey:'encode',
            rowSelection:{
                selectedRowKeys: this.state.selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                    this.rowSelectionChange(selectedRowKeys,selectedRows);
                },
                getCheckboxProps: record => ({
                //   disabled: !record.dispatchBatch, // Column configuration not to be checked
                  name: record.encode,
                })
            },
            selfProcessData:this.props.tableConfig.data,
            columns:this.props.tableConfig.columns,
            current:this.props.tableConfig.current,
            total:this.props.tableConfig.total,
            pageSize:this.props.tableConfig.pageSize,
            hideDefaultSelections:true,
            change:this.onChange
        }
        return (
            <div className="intention-table">
                <div className="intention-table-option">
                    {/* { this.props.hasAuth ?  */}
                    <><Button style={{marginRight:'12px'}} type="link" onClick={this.props.onBatch}>批量启用</Button>
                    <Button type="link" onClick={this.props.offBatch}>批量停用</Button></>
                    {/* : null } */}
                </div>
                <div className="intention-table-infos">
                    已选择<span>{this.state.count}</span>项 <a href="javascript:void(0)" className="intention-select-cancel"   onClick={this.cancel}>取消</a>
                </div>
                <div className="intention-table-contents">
                    <Table {...tableConfig} />
                </div>
            </div>
        );
    }
}

export default IntentionTable;