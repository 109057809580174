import {
    AJAX_TYPE
} from '../config';

// 服务中心-维修

const prefix = 'maintain';

export default {
    // 维修验收
    [`${prefix}AcceptanceDone`]: {
        url: '/web/maintain/acceptanceDone',
        type: AJAX_TYPE.POST
    },
    // 新增维修
    [`${prefix}Add`]: {
        url: '/web/maintain/add',
        type: AJAX_TYPE.POST
    },
    // 费用清单合计
    [`${prefix}BillCount`]: {
        url: '/web/maintain/billCount/:encode',
        type: AJAX_TYPE.GET
    },
    // 费用清单列表
    [`${prefix}ListBill`]: {
        url: '/web/maintain/listBill/:encode',
        type: AJAX_TYPE.GET
    },
    // 维修取消
    [`${prefix}Cancel`]: {
        url: '/web/maintain/cancel',
        type: AJAX_TYPE.POST
    },
    // 删除维修
    [`${prefix}Delete`]: {
        url: '/web/maintain/delete/:encode',
        type: AJAX_TYPE.POST
    },
    // 费用清单新增
    [`${prefix}AddBill`]: {
        url: '/web/maintain/addBill',
        type: AJAX_TYPE.POST
    },
    // 费用清单删除
    [`${prefix}DeleteBill`]: {
        url: '/web/maintain/deleteBill',
        type: AJAX_TYPE.POST
    },
    // 费用清单编辑
    [`${prefix}EditBill`]: {
        url: '/web/maintain/editBill',
        type: AJAX_TYPE.POST
    },
    // 维修派单
    [`${prefix}DispatchDone`]: {
        url: '/web/maintain/dispatchDone',
        type: AJAX_TYPE.POST
    },
    // 编辑维修
    [`${prefix}Edit`]: {
        url: '/web/maintain/edit',
        type: AJAX_TYPE.POST
    },
    // 查看维修信息
    [`${prefix}Get`]: {
        url: '/web/maintain/get/:encode',
        type: AJAX_TYPE.GET
    },
    // 可查看维修工集合,支持联想
    [`${prefix}GetLaborList`]: {
        url: '/web/maintain/getLaborList',
        type: AJAX_TYPE.POST
    },
    // 维修列表过滤条件
    [`${prefix}Init`]: {
        url: '/web/maintain/init',
        type: AJAX_TYPE.GET
    },
    // 维修管理列表
    [`${prefix}List`]: {
        url: '/web/maintain/list',
        type: AJAX_TYPE.POST
    },
    // 维修处理完成
    [`${prefix}ProcessDone`]: {
        url: '/web/maintain/processDone',
        type: AJAX_TYPE.POST
    },
    // 维修状态统计
    [`${prefix}StatusCount`]: {
        url: '/web/maintain/statusCount',
        type: AJAX_TYPE.POST
    },
    // 维修开始处理
    [`${prefix}WaitProcessDone`]: {
        url: '/web/maintain/waitProcessDone',
        type: AJAX_TYPE.POST
    },
}
