import React, { Component } from "react";
import Table from 'CMPT/common/Table';
import http from '@/http';
import { API } from '@/http/api';
import {PromotionStateLabel,PromotionOffStateFlag} from  'CMPT/common.js';
import { Link } from 'react-router-dom';
import { Breadcrumb, Tooltip } from "antd";
import "./List.less"

const BreadcrumbItem = Breadcrumb.Item;

class List extends React.Component {
    state = {
        total: 0, // 当前数据的总数
        currentPageCount: 0, // 当前页的数量
        pageNo: 1, // 当前所展示的页码
        currentPageCount: 0, //当前页面的数量

        selectParams: {  // 接口入参，必须放这里。方便分页时保存信息
            //authenticationStatus: this.props.state,// 审批状态
            pageNo: 1, // 需要查询的页数
            pageSize: 10, // 每页条数
        },
        columns: [{
            title: '序号',
            key: "index",//(text,record,index)=>index,
            render: (text, record, index) => <span>{index + 1}</span>
        }, {
            title: '活动名称',
            dataIndex: 'promotionName',
            key: 'promotionName',
            render: (text, record) => {
                let value;
                if (text && text.length > 20) {
                    value = <Tooltip placement="top" title={text}>{text.slice(0, 20)}...</Tooltip>
                } else {
                    value = text
                }
                return (<span>{value ? value : '-'}</span>)
            }
        },
        {
            title: '是否限制供应商范围',
            dataIndex: 'supplierLimitFlag',
            key: 'supplierLimitFlag',
            render: (value, record) => <span>{record.supplierLimitFlag===1 ? '是' : '否'}</span>
        },
        {
            title: '供应商范围',
            dataIndex: 'supplierNumber',
            key: 'supplierNumber',
            render: (value, record) =>{ 
                return record.supplierLimitFlag!==1 ? <span>全部</span> :(record.supplierNumber?<span className="table-operate-btn">
            <Link to={'/app/crm/staff-site-activity-list/detail/' + record.code +"?tab=2&supplierLimitFlag="+(record.supplierLimitFlag===1)+ "&pageNo=" + this.state.pageNo + "&currentPageCount=" + this.state.currentPageCount+"&name="+record.promotionName} className="operate">查看</Link>
                </span>:<span>无</span>)}
        },
        {
            title: '活动用户范围',
            dataIndex: 'promotionRangeDesc',
            key: 'promotionRangeDesc',
            render: (value) => <span>{value ? value : '-'}</span>
        }, {
            title: '活动渠道',
            dataIndex: 'promotionChannelDesc',
            key: 'promotionChannelDesc',
            render: (value) => <span>{value ? value : '-'}</span>
        }, {
            title: '活动报名时间',
            dataIndex: 'registerStartAndEndimeDesc',
            key: 'registerStartAndEndimeDesc',
            render: (value) => <span>{value ? value : '-'}</span>
        }, {
            title: '活动有效期',
            dataIndex: 'promotionStartAndEndimeDesc',
            key: 'promotionStartAndEndimeDesc',
            render: (value) => <span>{value ? value : '-'}</span>
        }, {
            title: '开启状态',
            dataIndex: 'offStatus',
            key: 'offStatus',
            render: (value) => <span>{value ? (value == PromotionOffStateFlag.OPEN ? '开启' : '关闭') : '-'}</span>  // 二选一的这种状态，写死 没有问题
        }, {
            title: '活动状态',
            dataIndex: 'status',
            key: 'status',
            render: (value) => <span>{value ? (PromotionStateLabel[value] ? PromotionStateLabel[value] : '') : '-'}</span>
        }, {
            title: '操作',
            key: 'operate',
            render: (text, record, index) => {
                return (<span className="table-operate-btn">
                    <Link to={'/app/crm/staff-site-activity-list/detail/' + record.code + "?supplierLimitFlag="+(record.supplierLimitFlag===1)+"&pageNo=" + this.state.pageNo + "&currentPageCount=" + this.state.currentPageCount+"&name="+record.promotionName} className="operate">查看详情</Link>
                </span>)
            }
        }
        ],
        data: []
    };


    getLists() {
        const config = {
            api: API.promotionList,
            data: {},
            showLoading: true
        };
        http(config).then(res => {
            if (res && res.success && res.data) {
                this.setState({
                    data: res.data
                });
            }
        });
    };


    //初始化
    componentDidMount() {
        this.getLists();
    };


    render() {

        const myState = this.state;
        const selectParams = myState.selectParams


        return (<div className="staff-site-activity-all">
            <div className='staff-site-activity-header'>
                <Breadcrumb>
                    <BreadcrumbItem>活动管理</BreadcrumbItem>
                </Breadcrumb>
            </div>
            <div className='staff-site-activity-list'>
                <Table rowKey="code" columns={myState.columns} selfProcessData={myState.data} total={myState.total} change={this.onChangePage} current={selectParams.pageNo} />

            </div>

        </div>);
    }
}


export default List;

