import React, { Component, Suspense } from "react";
import { matchPath } from "react-router";
import { Layout, ConfigProvider } from "antd";
import { Spin } from "antd";
import Header from "CMPT/Header";
import SiderMenu from "CMPT/SiderMenu";
import RouteMap from "@/route";
import getRouteConfig from "@/route/config";
import ProjectSiderMenu from "CMPT/project/detail/SiderMenu";

const EMPTY = {};
class Main extends Component {
  secondLevelOpenKeys;
  secondLevelSelectedKey;

  getMenu() {
    const routeConfig = getRouteConfig();

    this.secondLevelOpenKeys = [];
    this.secondLevelSelectedKey = "";

    const {
      location: { pathname },
    } = this.props;

    let firstLevelSelectedKey = "";
    const firstLevelMenu = [];
    routeConfig["/app"].subs.forEach((route) => {
      const baseRouteConfig = routeConfig[route];
      if (!baseRouteConfig.hideInMenu) {
        if (!firstLevelSelectedKey && pathname.startsWith(route)) {
          firstLevelSelectedKey = route; // 匹配的一级菜单
        }
        // firstLevelMenu.push(baseRouteConfig);
      }
    });

    let secondLevelMenu = [];
    if (firstLevelSelectedKey) {
      if (["/app/project"].includes(firstLevelSelectedKey)) {
        // 动态侧边菜单
        secondLevelMenu = (
          <Suspense fallback={<div>Loading...</div>}>
            <ProjectSiderMenu
              history={this.props.history}
              pathname={this.props.location.pathname}
            />
          </Suspense>
        );
      } else {
        secondLevelMenu = this.getSubMenu(firstLevelSelectedKey).subs;
        this.secondLevelOpenKeys.shift();
      }
    }

    return {
      firstLevelMenu,
      firstLevelSelectedKey,
      secondLevelMenu,
    };
  }

  getSubMenu = (route) => {
    const routeConfig = getRouteConfig();
    const { icon, iconfont, title, subs, component } = routeConfig[route];
    const {
      location: { pathname },
    } = this.props;
    let subMenu = [];
    // 路由配置了组件则其子路由不配置为子菜单
    if (!component && subs && subs.length) {
      if (pathname.startsWith(route)) {
        this.secondLevelOpenKeys.push(route);
      }
      subMenu = subs.map(this.getSubMenu);
    } else {
      if (pathname.startsWith(route)) {
        this.secondLevelSelectedKey = route;
      }
    }

    return { route, icon, iconfont, title, subs: subMenu };
  };

  getMatchComponent() {
    const routeConfig = getRouteConfig();
    const {
      location: { pathname },
    } = this.props;
    if (routeConfig[pathname]) {
      // 没有路径参数
      return routeConfig[pathname];
    }
    for (const [route, config] of Object.entries(routeConfig)) {
      if (config.component) {
        const match = matchPath(pathname, {
          path: route,
          exact: !config.dispense,
        });
        if (match) {
          return config;
        }
      }
    }
    return EMPTY;
  }

  render() {
    const { firstLevelMenu, firstLevelSelectedKey, secondLevelMenu } =
      this.getMenu();

    const matchComponent = this.getMatchComponent();
    const limitWidth = !matchComponent.autoWidth;
    const showSiderMenu = !matchComponent.hideSiderMenu;

    const layoutClassName = "main-layout " + (limitWidth ? "limit-max" : "");

    return (
      <Layout id="main" style={{ width: "100%" }}>
        <Header
          selectedKey={firstLevelSelectedKey}
          firstLevelMenu={firstLevelMenu}
        />
        <Layout>
          {/* <Spin spinning={true} /> */}
          {showSiderMenu && (
            <SiderMenu
              selectedKey={this.secondLevelSelectedKey}
              defaultOpenKeys={this.secondLevelOpenKeys}
              secondLevelMenu={secondLevelMenu}
            />
          )}
          <Layout.Content className={layoutClassName}>
            <ConfigProvider autoInsertSpaceInButton={false}>
              <RouteMap route="/app" />
            </ConfigProvider>
          </Layout.Content>
        </Layout>
      </Layout>
    );
  }
}

export default Main;
