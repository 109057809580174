import React, { Component } from 'react';
import { Breadcrumb, Tabs, Button, Form, Input, Radio, DatePicker, message, Select, Modal } from 'antd';
import { Link } from "react-router-dom";
import './index.less';
import ImgPreview from 'CMPT/common/ImgPreview';
import UploadVoucher from './uploadVoucher';
import { API } from '@/http/api';
import http from '@/http';
import userUtil from "@/utils/user";
import moment from "moment";
import debounce from 'lodash/debounce';
import Table from "CMPT/common/Table";
const { RangePicker } = DatePicker;
const BreadcrumbItem = Breadcrumb.Item;
const { TabPane } = Tabs;

const { Search, TextArea } = Input;

const Option = Select.Option
const RadioGroup = Radio.Group
class UserOrder extends Component {

    state = {
        searchValue: '',
        columns: [
            {
                title: "操作人",
                key: "accountName",
                align: "center",
                dataIndex: "accountName",
                render: (text, record) => {
                    return (
                      <span>{text + '/' + record.accountPhone}</span>
                    );
                },
            },
            {
                title: "所属公司/组织代码",
                key: "supplierName",
                align: "center",
                dataIndex: "supplierName",
                render: (text, record) => {
                    return (
                        <>
                        <span>{text}</span><br/>
                        <span>{record.unifiedSocialCode}</span>
                        </>
                    );
                },
            },
            {
                title: "操作时间", // 0 表示待审核状态
                key: "createDate",
                align: "center",
                dataIndex: "createDate",
                render: (text, record) => {
                  return (
                    <span>{text ? moment(text).format("YYYY-MM-DD HH:mm") : "-"}</span>
                  );
                },
            },
            {
              title: "渠道/来源",
              key: "tenantCodeName",
              align: "center",
              dataIndex: "tenantCodeName",
            },
            {
                title: "使用次数",
                key: "usedNumber",
                align: "center",
                dataIndex: "usedNumber",
              },
            {
              title: "接口类型",
              key: "trackQueryTypeName",
              align: "center",
              dataIndex: "trackQueryTypeName",
            //   render: (text) => {
            //     return <span>{text}</span>;
            //   },
            },
            {
              title: "车牌号",
              key: "plate",
              align: "center",
              dataIndex: "plate",
            },
            {
              title: "状态",
              key: "statusName",
              align: "center",
              dataIndex: "statusName",
            },
            // {
            //   title: "付款凭证",
            //   key: "payPhotos",
            //   align: "center",
            //   dataIndex: "payPhotos",
            //     render: (text) => {
            //     return text ? <div className='img_show_btn' onClick={() => this.imgShow(text)}><span>
            //         <i className='iconfont icon-zukehetong'></i>
            //         共{text.length}张
            //     </span></div> : '-';
            //   },
            // },
            // {
            //     title: "状态",
            //     key: "stateName",
            //     align: "center",
            //     dataIndex: "stateName",
            // },
            // {
            //     title: "操作",
            //     key: "code",
            //     align: "center",
            //     dataIndex: "code",
            //     render: (text, record) => {
            //         if (record.stateName) {
            //             if (record.stateName == '账期待付款') {
            //                 return <div className='oprate img_show_btn' 
            //                 onClick={() => {
            //                     this.setState({
            //                         payMoneyVoucherModal: true,
            //                         upVCode: record.code
            //                     })
            //                 }}>
            //                   <span>上传凭证</span>
            //               </div>
            //             } else if (record.stateName == '待审核') {
            //                 return <>
            //                 <div className='oprate' >
            //                     <span className='agree' onClick={() => this.auditDepositModal('10', record.code)}>通过</span>
            //                     <span className='reject_btn' onClick={() => {this.auditDepositModal('20', record.code)}}>驳回</span>
            //                 </div>
            //               </>
            //             } else if (record.stateName == '成功' || record.stateName == '失败') {
            //                 return <div className='oprate' onClick={() => this.goRecord(record.code)}>操作记录</div>
            //             }
            //         }
            //     },
            // },
        ],
        params: {
            pageSize: 10,
            pageNo: 1,
        },
        addRecordList: [],
        currentIndex: 0,
        payMoneyVoucherModal: false,
        upVCode: '',
        sourceList: [],
        supplierArray: []
    }

    componentDidMount() {
        this.auditList()
        this.getSourceList()
    }

    auditDepositModal = (auditStatus, code) => {
        if (auditStatus == '10') {
            Modal.confirm({
                title: '提示',
                icon: 'none',
                content: <div className='confirm_text'>
                    <div className='confirm_1'>确定已收到款吗？</div>
                    <div className='confirm_2'>说明：确定后将自动为该用户充值</div>
                </div>,
                onOk: () => {
                    this.auditDeposit(code, auditStatus)
                }
            })
        } else {
            Modal.confirm({
                title: '提示',
                icon: 'none',
                content: <div>
                    <TextArea
                        rows={4}
                        onChange={this.onRejectInput}
                        placeholder="请输入驳回理由 （100字以内）"
                        maxLength={100}
                        />
                    <span>说明：驳回后用户可重新发起订单付款</span>
                </div>,
                onOk: () => {
                    this.auditDeposit(code, auditStatus)
                }
            })
        }
    }
    onRejectInput = (e) => {
        this.state.auditRemark = e.target.value;
    };

    goRecord = (code) => {
        this.props.history.push(`/app/crm/valueaddService/trajectoryFind/operaterRecord/${code}`)
    }

    auditDeposit = (code, auditStatus) => {
        const config = {
            api: API.valueAddServiceAuditDeposit,
            showLoading: true,
            data: {
                code,
                auditStatus
            }
        };
        let { auditRemark } = this.state;
        if (auditStatus == '20') {
            config.data.auditRemark = auditRemark;
        }
        http(config).then(res => {
            if (res.code == 200) {
                message.success('操作成功')
                this.auditList()
            }
        })
    }

    getSourceList = () => {
        const config = {
            api: API.valueAddServiceGetSourceList,
            showLoading: true,
        };
        http(config).then(res => {
            if (res.code == 200) {
                this.setState({
                    sourceList: res.data
                })
            }
        })
    }

    imgShow = (list) => {
        let imgList = list.map(item => {
            return item.url
        })
        this.setState({
            imgList,
            previewVisible: true
        })
    }
    closeImagePreview = () => {
        this.setState({
            previewVisible: false
        })
    }

    
    auditList = () => {
        let  {params} = this.state;
        const config = {
            api: API.valueAddServiceCrmUsedRecordsList,
            showLoading: true,
            data: params
        };
        http(config).then(res => {
            if (res.code == 200) {
                res.data.total = Number(res.data.total)
                this.setState({
                    addRecordList: res.data
                })
            }
        })
    }

    rangeChange = (value, date) => {
        let params = this.state.params;
        params.startDate = date[0];
        params.endDate = date[1];
        this.setState({
            params,
            startDateMoment: value[0],
            endDateMoment: value[1]
        },() => {
            this.auditList();
        })
    }

    changeSource = (e) => {
        let params = this.state.params;
        params.tenantCode = e;
        this.setState({
            params,
        },() => {
            this.auditList();
        })
    }

    changeSupplier = (e) => {
        let params = this.state.params;
        params.supplierId = e;
        this.setState({
            params,
        },() => {
            this.auditList();
        })
    }

    searchChange = (e) => {
        this.setState({
            searchValue: e.target.value
        })
    }

    onSearchOn = (e) => {
        let {params} = this.state;
        params.keyword = e;
        this.setState({
            params
        }, () => {
            this.auditList()
        })
    }

    resetSearch = () => {
        let {params} = this.state;
        params.keyword = '';
        params.startDate = '';
        params.endDate = '';
        params.tenantCode = undefined;
        params.supplierId = undefined;
        this.setState({
            params,
            startDateMoment: undefined,
            endDateMoment: undefined,
            searchValue: ''
        }, () => {
            this.auditList()
        })
    }

    payMoneyVoucherModalOk = (payPhotos) => {
        console.log(payPhotos, 'payPhotos');
        let {upVCode} = this.state;
        const config = {
            api: API.valueAddServiceDepositUpload,
            showLoading: true,
            data: {
                code:upVCode,
                payPhotos
            }
        };
        http(config).then(res => {
            if (res.code == 200) {
                message.success('上传成功');
                this.setState({
                    payMoneyVoucherModal: false
                }) 
                this.auditList()
            }
        })
    }

    payMoneyVoucherModalCancel = () => {
        this.setState({
            payMoneyVoucherModal: false
        }) 
    }
    
    inputSearch = (e) => {
        this.searchData(e)
    }

    searchData(name) {
        const config = {
            api: API.invoiceSupplierList, 
            path: { supplierName: name || 'null' } // null 表示 全部数据 

        };

        // setup 2:请求，并初步处理
        let _this = this;
        http(config).then(res => {
            if (res && res.code == 200) {
                _this.setState({
                    supplierArray: res.data
                });
            }
        });
    }


    //分页
    onChangePage = (pageNo, pageSize, sort) => {
        this.setState(
        {
            params: {
            ...this.state.params,
            pageNo: pageNo,
            pageSize: pageSize,
            },
        },
        () => {
            this.auditList();
        }
        );
    };


    render() {
        const {searchValue, params, addRecordList, columns, previewVisible, currentIndex, imgList, startDateMoment, endDateMoment, payMoneyVoucherModal, sourceList, supplierArray } = this.state;
        const { getFieldDecorator } = this.props.form;
        return (

            <div className="enterprise-auth-con-1">
                {/* <div className='enterprise-auth-header'>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link
                                style={{ color: "#333" }}
                                to={{
                                    pathname: "/app/crm/valueaddService/trajectoryFind",
                                }}
                            >
                                数据概览
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>添加记录</Breadcrumb.Item>
                    </Breadcrumb>
                </div> */}
                <div className='tab_box'>
                    {/* <Tabs defaultActiveKey="0" className='tabs' style={{margin: 0}} onChange={this.changeTabs} >
                        <TabPane tab="全部"  key="0" className='tab'>
                        </TabPane>
                        <TabPane tab="待审批"  key="1" className='tab'>
                        </TabPane>
                        <TabPane tab="已成功"  key="2" className='tab'>
                        </TabPane>
                        <TabPane tab="账期待付款"  key="4" className='tab'>
                        </TabPane>
                        <TabPane tab="已失败"  key="3" className='tab'>
                        </TabPane>
                    </Tabs> */}

                    <div className='table_box' style={{paddingTop: '24px'}}>
                        <div className="goods_fliter">
                            <RangePicker  value={[startDateMoment,endDateMoment]} onChange={this.rangeChange} style={{ marginRight: '12px'}} />
                            <Select allowClear optionFilterProp="label" onChange={this.changeSource} value={params.tenantCode}
                                placeholder="来源" style={{ width: '100px',display:'inline-block' }}>
                                {sourceList.map(item => (
                                    <Option key={item.tenantCode} value={item.tenantCode} label={item.tenantName}>
                                        <span>{item.tenantName || ''}</span>
                                    </Option>
                                ))}
                            </Select>
                            <Select allowClear showSearch optionFilterProp="label" value={params.supplierId} onChange={this.changeSupplier} onSearch={debounce(this.inputSearch, 1000)}
                                placeholder="企业名称-输入搜索" style={{ width: '300px',display:'inline-block' }}>
                                {supplierArray.map(item => (
                                    <Option key={item.supplierId} value={item.supplierId} label={item.supplierName}>
                                        <span><span>{item.supplierName || ''}</span>&nbsp;&nbsp;&nbsp;<span>{item.unifiedSocialCode || ''}</span>&nbsp;&nbsp;&nbsp;<span>{(item.userName || '') + (item.userName ? '/' : "") + (item.userPhone || '')}</span></span>
                                    </Option>
                                ))}
                            </Select>
                            <Search
                                placeholder="请输入姓名/手机号码/订单编号"
                                enterButton="搜索"
                                className="goods_search nomargin"
                                value={searchValue}
                                // size="large"
                                onChange={value => {this.searchChange(value)}}
                                onSearch={value => {this.onSearchOn(value)}}
                            />
                            <span className="goods_reset" onClick={this.resetSearch}>重置</span>
                        </div>
                        <Table
                            columns={columns}
                            selfProcessData={addRecordList && addRecordList.datas}
                            total={addRecordList && addRecordList.total}
                            change={this.onChangePage}
                            current={addRecordList && addRecordList.pageNo}
                        />
                    </div>
                </div>
                
            </div>

        );
    }
}

const UserTOrder = Form.create({ name: "UserOrder" })(UserOrder);
export default UserTOrder;