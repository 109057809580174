import React, { Component } from 'react'
import { API } from '@/http/api';
import http from '@/http';
import Select from 'CMPT/common/Select';
import moment from 'moment';
import { Button, Input, InputNumber, Row, Col, DatePicker, Icon, Modal, Form, Radio, message } from 'antd';
const Item = Form.Item;
const RadioGroup = Radio.Group
class SignoutModal extends Component {
    state = {
        show: false,
        formRef: '',
        componentRef: '', // 获取子组件状态
        showModal: false,
        info: {
            contractCode: '',
            allPaid: true,
            contractDateRange: []
        },
        params: {}
    }
    componentDidMount() {
        this.setState({
            formRef: React.createRef()
        })
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            show: nextProps.show
        })
    }
    signoutCancel = (flag) => {
        const { close } = this.props
        this.setState({
            show: false,
            showModal: false
        })
        close(flag)
    }
    confirmSignout = () => {
        this.state.formRef.current.validateFields((err, values) => {
            if (!err) {
                const { info } = this.props
                const signoutFei = this.state.componentRef.state.signoutFei
                const list = signoutFei && signoutFei.map(item => {
                    return {
                        encode: item.marginId,
                        budgetType: item.feiType,
                        bizTypeSub: item.bizTypeSub,
                        feeType: item.marginType,
                        payAmount: item.marginNum,
                        payDate: item.marginDate ? item.marginDate.format('YYYY-MM-DD') : undefined
                    }
                })
                let params = {
                    enCode: info.contractCode,
                    refundRentTime: values.signoutTime.format('YYYY-MM-DD'),
                    refundRentType: values.signoutType,
                    remark: values.signoutReason,
                    billList: list || []
                }
                this.setState({
                    params
                })
                this.setState({
                    showModal: true
                })
            }
        })
    }
    close = () => {
        this.setState({
            showModal: false
        })
    }
    delete = (flag) => {
        let params = this.state.params
        params.autoProcessBill = flag
        const config = {
            api: API.contractRentWithdrawalApply,
            data: params
        }
        http(config).then(res => {
            if (res.success) {
                message.success('提交成功')
                this.signoutCancel(true)
            }
        })
    }
    render() {
        const { info } = this.props
        return (
            <Modal
                visible={this.state.show}
                closable={false}
                maskClosable={false}
                destroyOnClose={true}
                width="640px"
                title="退租申请"
                onCancel={this.signoutCancel}
                onOk={this.confirmSignout}
            >
                <div className="contract-modal-content">
                    <Row>
                        <Col span={14} className="modal-col">
                            <span className="span-title">租客名称：</span>
                            <span className="span-content">{info.customerName}</span>
                        </Col>
                        <Col span={10} className="modal-col">
                            <span className="span-title">费用结算：</span>
                            <span className="span-content">{info.allPaid ? '已结清' : '未结清'}</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={14} className="modal-col">
                            <span className="span-title">合同期限：</span>
                            <span className="span-content">{info.contractDateRange[0]} 至 {info.contractDateRange[1]}</span>
                        </Col>
                        <Col span={10} className="modal-col">
                            <span className="span-title">合同编号：</span>
                            <span className="span-content">{info.contractNumber}</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} className="modal-col">
                            <span className="span-title">房源信息：</span>
                            <span className="span-content">{info.projectRoomNames}</span>
                        </Col>    
                    </Row>
                    <ModalForm ref={this.state.formRef} wrappedComponentRef={(form) => this.state.componentRef = form}
                               allPaid={info.allPaid} info={info}/>
                    <Modal
                        visible={this.state.showModal}
                        closable={false}
                        maskClosable={false}
                        width={400}
                        title=" "
                        wrapClassName="contract-tips-modal"
                        okText="保留, 稍后手动修改"
                        cancelText="确认, 直接删除"
                        onCancel={this.delete.bind(this, true)}
                        onOk={this.delete.bind(this, false)}
                    >
                    <p>退租成功后是否将所有未收款自动作废？</p>
                    <Icon type="close" onClick={this.close} />
                    </Modal>
                </div>
            </Modal>
        )
    }
}
class mForm extends Component {
    state = {
        marginTypeList: [],
        allTypeList: [],
        signoutFei: []
    }
    componentDidMount() {
        this.getContractInit()
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.info !== nextProps.info) {
            this.setState({
                signoutFei: nextProps.info.signoutFei || []
            })
        }
    }
    getContractInit = () => {
        const initConfig = {
            api: API.contractInit
        }
        // 初始化枚举
        http(initConfig).then(initRes => {
            const { feeType, type } = initRes.data
            let marginTypeList = type.map(item => {
                return {
                    value: `${item.key}`, name: item.value
                }
            })
            let allTypeList = feeType.map(item => {
                return {
                    value: `${item.key}`, name: item.value
                }
            })
            this.setState({
                marginTypeList,
                allTypeList
            })
        })
    }
    minusMargin = (index) => {
        let signoutFei = this.state.signoutFei
        signoutFei.splice(index, 1)
        this.setState({
            signoutFei
        })
    }
    setMarginData = (index, attr, e) => {
        let value = attr === 'feiType' ? e.target.value : e
        let signoutFei = this.state.signoutFei
        signoutFei[index][attr] = value
        this.setState({
            signoutFei
        })
    }
    addMargin = () => {
        let signoutFei = this.state.signoutFei
        signoutFei.push({
            feiType: 1,
            bizTypeSub: 0,
            marginType: '2',
            marginNum: '',
            marginDate: moment()
        })
        this.setState({
            signoutFei
        })
    }
    signoutDisabledDate = (value) => {
        const startDate = this.props.info.contractDateRange[0]
        const endDisabledDate = this.props.info.endDisabledDate
        return value.valueOf() < moment(startDate).valueOf() || (endDisabledDate ? value.valueOf() > moment(endDisabledDate).subtract(1, 'days').valueOf() : false)
    }
    marginValidator = (max, rule, value, cb) => {
        if (value < max) {
            cb(`金额不能小于${max}`)
        } else {
            cb()
        }
    }
    render() {
        const { getFieldDecorator } = this.props.form
        const { signoutFei } = this.state
        const info = this.props.info
        return (
            <Form>
                <Row>
                    <Col span={14}>
                        <Item label="退租类型">
                            {getFieldDecorator('signoutType', {
                                rules: [{
                                    required: true, message: '请选择',
                                }],
                                initialValue: info.signoutType
                            })(
                                <RadioGroup name="radiogroup">
                                    <Radio value={1} style={{fontSize: '12px'}}>正常退租</Radio>
                                    <Radio value={2} style={{fontSize: '12px'}}>非正常退租</Radio>
                                </RadioGroup>
                            )}
                        </Item>
                    </Col>
                    <Col span={10}>
                    <Item label="预计搬离时间">
                            {getFieldDecorator('signoutTime', {
                                rules: [{
                                    required: true, message: '请选择',
                                }],
                                initialValue: info.signoutTime
                            })(
                                <DatePicker style={{width: '100%'}} disabledDate={this.signoutDisabledDate} />
                            )}
                        </Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Item label="退租原因">
                            {getFieldDecorator('signoutReason', {
                                rules: [{
                                    required: true, message: '请选择',
                                }],
                                initialValue: info.signoutReason
                            })(
                                <Input.TextArea maxLength={200} />
                            )}
                        </Item>
                    </Col>
                </Row>
                <Row style={{display: this.props.allPaid ? 'none' : 'block'}}>
                    <Col span={24}>
                        <Item label="退租费用" className="signout-item">
                            {signoutFei ? signoutFei.map((per, index) => {
                                return <Row gutter={8} key={index} style={{marginBottom: "6px"}}>
                                    <Icon onClick={this.minusMargin.bind(this, index)} type="minus-circle" className="margin-minus"
                                          style={{color: "#E94437", fontSize: "16px", display:per.bizTypeSub != 0 ? 'none' : 'inline-block'}} theme="filled" />
                                    <Col span={3} style={{marginTop: "2px"}}>
                                    <RadioGroup defaultValue={per.feiType} buttonStyle="solid" disabled={per.bizTypeSub != 0}
                                                onChange={this.setMarginData.bind(this, index, 'feiType')}>
                                        <Radio.Button value={1}>收</Radio.Button>
                                        <Radio.Button value={2}>支</Radio.Button>
                                    </RadioGroup>
                                    </Col>
                                    <Col span={6}>
                                        <Select data={per.bizTypeSub != 0 ? this.state.marginTypeList : this.state.allTypeList} disabled={per.bizTypeSub != 0}
                                                onChange={this.setMarginData.bind(this, index, 'marginType')} value={per.marginType} />
                                    </Col>
                                    <Col span={4} className="signout-col">
                                        <Item>
                                            {getFieldDecorator(`marginNum${index}`, {
                                                rules: [{
                                                    required: true, message: '请输入',
                                                }, {
                                                    validator: this.marginValidator.bind(this, per.paidAmount + per.auditPaidAmount)
                                                }],
                                                initialValue: per.marginNum
                                            })(
                                                <InputNumber onChange={this.setMarginData.bind(this, index, 'marginNum')} min={0.01} max={99999999.99} />
                                            )}
                                            <span className="input-unit">元</span>
                                        </Item>
                                    </Col>
                                    <Col span={7}>
                                        <DatePicker style={{width: "100%"}} onChange={this.setMarginData.bind(this, index, 'marginDate')} value={per.marginDate} allowClear={false} />
                                    </Col>
                                </Row>
                            }) : null}
                        </Item>
                    </Col>
                </Row>
                <Row className="signout-row" style={{display: this.props.allPaid ? 'none' : 'block'}}>
                    <span onClick={this.addMargin}>
                        <Icon style={{color: "#FBBC06", fontSize: "16px"}} type="plus-circle" theme="filled" />
                        添加费用
                    </span>
                </Row>
            </Form>
        )
    }
}
const ModalForm = Form.create({ name: 'mForm' })(mForm)
export default SignoutModal