import { API } from '@/http/api';
import http from '@/http';

function myWorkBillList(){
    let  config = {
        api: API.myWorkBillList
    }
    return http(config)
}

function myWorkBillTemplateList(){
    let  config = {
        api: API.myWorkBillTemplateList
    }
    return http(config)
}

function myWorkComplainList(){
    let  config = {
        api: API.myWorkComplainList
    }
    return http(config)
}

function myWorkContactReport(){
    let  config = {
        api: API.myWorkContactReport
    }
    return http(config)
}

// function myWorkContactReport(){
//     let  config = {
//         api: API.myWorkContactReport
//     }
//     return http(config)
// }

function myWorkContractList(){
    let  config = {
        api: API.myWorkContractList
    }
    return http(config)
}

function myWorkContractTemplateList(){
    let  config = {
        api: API.myWorkContractTemplateList
    }
    return http(config)
}

function myWorkMaintinList(){
    let  config = {
        api: API.myWorkMaintinList
    }
    return http(config)
}

function listMaterial(){
    let  config = {
        api: API.sysconfigListMaterial
    }
    return http(config)    
}

function listPublicEquipment(){
    let  config = {
        api: API.sysconfigListPublicEquipment
    }
    return http(config)      
}

function listRoomEquipment(){
    let  config = {
        api: API.sysconfigListRoomEquipment
    }
    return http(config)      
}

export {
    myWorkBillList,
    myWorkBillTemplateList,
    myWorkComplainList,
    myWorkContactReport,
    myWorkContractList,
    myWorkContractTemplateList,
    myWorkMaintinList,
    listMaterial,
    listPublicEquipment,
    listRoomEquipment
}