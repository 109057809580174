addLogConfig();

function addLogConfig() {
    const _noop = () => {};
    let success = _noop;
    let info = _noop;
    let warn = _noop;
    let error = _noop;
    if (process.env.NODE_ENV === 'development') {
        success = successLog;
        info = infoLog;
        warn = warnLog;
        error = errorLog;
    }
    /* global $$_SS:writable, $$_II:writable, $$_WW:writable, $$_EE:writable */
    $$_SS = success;
    $$_II = info;
    $$_WW = warn;
    $$_EE = error;
}

function getCallStack() {
    const error = new Error('get call stack');
    const stack = error.stack;
    const paths = stack.split('\n');
    return paths[4].trim();
}

function successLog() {
    const defaultStyle = 'color:#8bec19;font-size:14px';
    colorfulDebug(console.log, defaultStyle, arguments);
}

function infoLog() {
    const defaultStyle = 'color:#0573EC;font-size:14px';
    colorfulDebug(console.info, defaultStyle, arguments);
}

function warnLog() {
    const defaultStyle = 'color:#FFC107;font-size:16px;';
    colorfulDebug(console.warn, defaultStyle, arguments);
}

function errorLog() {
    const defaultStyle = 'color:#ff0000;font-size:18px';
    colorfulDebug(console.error, defaultStyle, arguments);
}

function colorfulDebug(consoleFun, defaultStyle, args) {
    const callPath = getCallStack();
    console.group(callPath);

    for (const arg of args) {
        if (arg) {
            let msg = arg;
            let style = defaultStyle;

            if (hasLogConfig(arg)) {
                msg = arg.log;
                style = arg.style;
            }

            if (msg instanceof Error) {
                consoleFun('%c' + msg.stack, style);
            } else if (typeof msg === 'object') {
                try {
                    consoleFun('%c' + JSON.stringify(msg, null, 4), style);
                } catch (e) {
                    consoleFun('%c' + msg, style);
                }
            } else {
                consoleFun('%c' + msg, style);
            }
        }
    }

    console.groupEnd();
}

function hasLogConfig(arg) {
    const keys = Object.keys(arg);
    if (keys.length === 2) {
        return keys.includes('log') && keys.includes('style');
    }
    return false;
}