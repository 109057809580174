import {
    AJAX_TYPE
} from '../config';

// 系统基础

const prefix = 'sysconfig';

export default {
    // 新增耗材配置
    [`${prefix}AddMaterial`]: {
        url: '/web/sys/config/addMaterial',
        type: AJAX_TYPE.POST
    },
    // 新增公共设施报修
    [`${prefix}AddPublicEquipment`]: {
        url: '/web/sys/config/addPublicEquipment',
        type: AJAX_TYPE.POST
    },
    // 新增屋内设施报修
    [`${prefix}AddRoomEquipment`]: {
        url: '/web/sys/config/addRoomEquipment',
        type: AJAX_TYPE.POST
    },
    // 删除耗材配置
    [`${prefix}DeleteMaterial`]: {
        url: '/web/sys/config/deleteMaterial',
        type: AJAX_TYPE.POST
    },
    // 删除公共设施报修
    [`${prefix}DeletePublicEquipment`]: {
        url: '/web/sys/config/deletePublicEquipment',
        type: AJAX_TYPE.POST
    },
    // 删除屋内设施报修
    [`${prefix}DeleteRoomEquipment`]: {
        url: '/web/sys/config/deleteRoomEquipment',
        type: AJAX_TYPE.POST
    },
    // 编辑耗材配置
    [`${prefix}EditMaterial`]: {
        url: '/web/sys/config/editMaterial',
        type: AJAX_TYPE.POST
    },
    // 编辑公共设施报修
    [`${prefix}EditPublicEquipment`]: {
        url: '/web/sys/config/editPublicEquipment',
        type: AJAX_TYPE.POST
    },
    // 编辑屋内设施报修
    [`${prefix}EditRoomEquipment`]: {
        url: '/web/sys/config/editRoomEquipment',
        type: AJAX_TYPE.POST
    },
    // 配置查询耗材
    [`${prefix}ListMaterial`]: {
        url: '/web/sys/config/listMaterial',
        type: AJAX_TYPE.GET
    },
    // 配置查询公共设施报修
    [`${prefix}ListPublicEquipment`]: {
        url: '/web/sys/config/listPublicEquipment',
        type: AJAX_TYPE.GET
    },
    // 配置查询屋内设施报修
    [`${prefix}ListRoomEquipment`]: {
        url: '/web/sys/config/listRoomEquipment',
        type: AJAX_TYPE.GET
    },
}
