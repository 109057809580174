import http from '@/http'
import { API } from '@/http/api';
import { Modal } from 'antd';


const addProject = (history) => {
    const config = {
        api: API.draftQuery,
        path: {
           bizType: 1 
        },
        showLoading: true,
    }
    http(config).then(result => {
        if (result.success && result.data != null) {
            Modal.confirm({
                icon: false,
                content: '您还有未提交的项目，是否使用草稿？',
                okText: '使用草稿',
                onOk: () => {
                    history.push('/app/project/add?hasDraft=1');
                },
                cancelText: '废弃草稿',
                onCancel: () => {
                    const config = {
                        api: API.draftDelete,
                        path: {
                           bizType: 1 
                        },
                        showLoading: true,
                    }
                    http(config).then(() => {
                        history.push('/app/project/add');
                    });
                }
            });
        } else {
            history.push('/app/project/add');
        }
    })
}

export {
    addProject
}