import {
    useEffect,
    useRef,
    useCallback
} from "react";
/**
 * 弹层可见(visible为真值)时在document上添加click监听，此时点击外部(点击弹层或触发器外的DOM)会调用cb
 * @param {function} cb 点击外部的回调，使用useCallback包裹，以防cb变化导致弹层关闭时无法移除点击监听
 * @returns [triggerRef, overlayRef] triggerRef 触发器ref，overlayRef 弹层ref。ref需要放到DOM上
 */
export default function useOuterSide(visible, cb) {
    const triggerRef = useRef();

    const overlayRef = useRef();

    useEffect(() => {
        if (visible) {
            document.addEventListener('click', clickDocument, false);
        } else {
            document.removeEventListener('click', clickDocument, false);
        }
    }, [visible]);

    const clickDocument = useCallback(function clickDocument(e) {
        const {
            target
        } = e;
        if (!contains(triggerRef.current, target) && !contains(overlayRef.current, target)) {
            cb(e);
        }
    }, [cb]);

    return [triggerRef, overlayRef];
}

function contains(root, target) {
    if (root) {
        while (target) {
            if (target === root) {
                return true;
            }
            target = target.parentNode;
        }
    }
    return false;
}