import Table from "CMPT/common/Table";
import http from "@/http";
import { API } from "@/http/api";
import { formatLinkSearch } from "@/utils";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "antd";
import SearchForm from "./searchForm";
import "./index.less";
import moment from "moment";

const approveStatus = {
  0: "审核中",
  10: "审核通过",
  20: "审核驳回",
  21: "强制下架"
};

// 筛选项默认值
const defaultParams = {
  pageNo: 1, // 需要查询的页数
  pageSize: 10, // 每页条数
};
class ServiceInfomation extends Component {
  state = {
    total: 0, // 当前数据的总数
    pageNo: 1, // 当前所展示的页码
    currentPageCount: 0, //当前页面的数量
    selectParams: {
      ...defaultParams,
    },
    columns: [
      {
        title: "名称",
         key: "companyName",
        dataIndex: "companyName",
        render: (text, record) => {
          return <span>{text||'-'}</span>;
        },
      },
      {
        title: "身份",
         key: "corpIdentityTypeName",
        dataIndex: "corpIdentityTypeName",
        render: (text, record) => {
          return <span>{text||'-'}</span>;
        },
      },
      // {
      //   title: "业务区域",
      //    key: "releaseStatusName",
      //   dataIndex: "releaseStatusName",
      //   render: (text, record) => {
      //     return <span>{text||'-'}</span>;
      //   },
      // },
      // {
      //   title: "业务范围",
      //    key: "releaseStatusName",
      //   dataIndex: "releaseStatusName",
      //   render: (text, record) => {
      //     return <span>{text||'-'}</span>;
      //   },
      // },
      {
        title: "联系人",
         key: "contactsName",
        dataIndex: "contactsName",
        render: (text, record) => {
          return <span>{text||'-'}</span>;
        },
      },
      {
        title: "联系方式",
        key: "contactsMobile",
        dataIndex: "contactsMobile",
        render: (text, record) => {
          return <div style={{wordWrap: "break-word",maxWidth:"200px"}}>{text||'-'}</div>;
        },
      },{
        title: "状态",
         key: "auditStatusName",
        dataIndex: "auditStatusName",
        render: (text, record) => {
          return <span>{text||'-'}</span>;
        },
      },
      {
        title: "审批类型",
        key: "auditType",
        dataIndex: "auditType",
        render: (text, record) => {
          return <span>{text || '-'}</span>;
        },
      },
      {
        title: "提交人",
        key: "createUser",
        dataIndex: "createUser",
        render: (text, record) => {
          return <span>{text || '-'}</span>;
        },
      },


      {
        title: "提交时间",
        key: "createDate",
        dataIndex: "createDate",
        render: (text, record) => {
          return <span>{moment(text).format('YYYY-MM-DD HH:mm:ss')||'-'}</span>;
        },
      },
      {
        title: "操作",
        key: "operate",
        render: (text, record, index) => {
          return (
            <span className="table-operate-btn">
              <Link
                to={"/app/crm/sourcing/serviceInformationDetail/" + record.code}
                className="operate"
              >
                {record.auditStatus == 1 ? '强制下架' : '查看详情'}
              </Link>
            </span>
          );
        },
      },
    ],
    tableData: [],
  };

  componentWillReceiveProps (newVal, oldVal) {
    this.init()
  }

  componentWillMount() {
    if (this.props.location) {
      // 获取参数
      const search = this.props.location.search;
      const formatData = formatLinkSearch(search);

      // 解析
      let pageNo1 = null;
      if (formatData && formatData.backPageNo) {
        pageNo1 = formatData.backPageNo;
      }

      // 赋值
      this.setState({
        selectParams: {
          auditStatus: this.props.state, // 审批状态
          pageNo: pageNo1, // 当前页数
          pageSize: 10, // 每页条数
        },
      });
    }
  }

  //初始化
  componentDidMount() {
    this.init();
  }

  init = () => {
    const httpParams = { ...this.state.selectParams, };
    for (let [key, value] of Object.entries(httpParams)) {
      if (value === -1 || value === "") {
        //    console.log('[key, value]',[key, value])
        httpParams[key] = undefined;
      }
    }

    // 从组件属性中提取 审核状态的属性
   const  auditStatus = this.props.auditStatus;
   if(auditStatus!=-1){// -1表示全部，我们不传 auditStatus字段即可
       httpParams.auditStatus =  auditStatus;
   }


    const config = {
      api: API.serviceInfoGetList,
      data: { ...httpParams },
      showLoading: true,
    };

    http(config).then((res) => {
      if (res.code == 200) {
        const data = res.data;
        this.setState({
          tableData: data.datas || [],
          total: data.total,
          pageNo: data.pageNo,
          currentPageCount: data.datas ? data.datas.length : 0,
          selectParams: { ...this.state.selectParams, pageNo: data.pageNo },
        });
      }
    });
  };

  // 搜索
  onValuesChange = (allValues) => {
    let  newParams =  { ...this.state.selectParams, ...allValues };
    if(newParams.supplierId== -1){// -1表示全部。所以这里 移除这个supplierId字段。后端接口 null表示全部
      delete  newParams.supplierId  
    }

    this.setState(
      { selectParams: newParams},
    );
  };

  //分页
  onChangePage = (pageNo, pageSize, sort) => {
    this.setState(
      {
        selectParams: {
          ...this.state.selectParams,
          pageNo: pageNo,
          pageSize: pageSize,
        },
      },
      () => {
        this.init();
      }
    );
  };

  // 搜索
  /*onValuesChange = (allValues) => {
    console.log("  onValuesChange   222  " );

    this.setState({
      selectParams: { ...this.state.selectParams, ...allValues },
    });
  };*/

  // 重置
  reset = (allValues) => {
    console.log(allValues, 'allvalue')
    this.setState(
      {
        selectParams: { ...this.state.selectParams, ...allValues },
      },
      () => {
        this.init();
      }
    );
  };

  render() {
    const selectParams = this.state.selectParams;
    return (
      <div className="enterprise-auth-list">
        <div className="searchBox">
          <SearchForm
            onValuesChange={this.onValuesChange}
            types={false}
            reset={this.reset}
            onSearch={() => {
              this.init();
            }}
          />
        </div>
        <Table
          columns={this.state.columns}
          rowKey={(record, index) => record.code}
          selfProcessData={this.state.tableData}
          total={+this.state.total}
          change={this.onChangePage}
          current={selectParams.pageNo}
        />
      </div>
    );
  }
}

export default ServiceInfomation;
