/**
 * 忘记密码组件
 */
import React, { Component } from "react";
import { Form, Input, Button, message } from "antd";
import { API } from '@/http/api';
import http, { ajax } from '@/http';
import Regexp from '@/utils/regex';
import md5 from 'js-md5';
import storage, { STORAGE_KEYS } from '@/utils/storage';
import "./Experience.less";

const { TIME_READ_VERIFICATION } = STORAGE_KEYS;

class LoginForm extends React.Component {

  state = {
    loading: false,
    countDownTime: 60,
    showCountDown: false,
  };

  componentDidMount() {
    const lastTimeReadVerification = storage.get(TIME_READ_VERIFICATION);
    if (lastTimeReadVerification) {
      const countDown = 60 - parseInt((Date.now() - lastTimeReadVerification) / 1000);
      if (countDown > 0) {
        this.startCountDown(countDown);
      } else {
        storage.remove(TIME_READ_VERIFICATION);
      }
    }
  }

  sendVerificationCode = () => {
    const {
      showCountDown,
    } = this.state;

    if (!showCountDown) {
      this.props.form.validateFields(['mobile'], (err, { mobile }) => {
        if (!err) {
          ajax({
            api: API.userGetCodeForget,
            data: { mobile: mobile },
            successTip: '验证码已发送'
          }).then(res => {
            if (res.success) {
              this.startCountDown();
            }
          });
        }
      });
    }
  }


  startCountDown = (countDown) => {
    this.setState({
      showCountDown: true
    });

    if (!countDown) {
      countDown = 60;
      storage.set(TIME_READ_VERIFICATION, Date.now());
    }

    this.setState({
      countDownTime: countDown
    });

    this.timer = setInterval(() => {
      if (this.state.countDownTime > 0) {
        this.setState((prevState, props) => (
          {
            countDownTime: prevState.countDownTime - 1
          }
        ));
      } else {
        storage.remove(TIME_READ_VERIFICATION);
        this.setState({
          showCountDown: false
        })
        clearInterval(this.timer);
      }
    }, 1000);
  }


  componentWillUnmount() {
    clearInterval(this.timer);
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ loading: true });

        const request = {
          ...values
        };
        delete request.confirmPassword;
        request.newPassword = md5(request.newPassword);
        ajax({
          api: API.userForgetPwd,
          data: request,
          showLoading: true,
          successTip: '重置密码成功！'
        }).then(data => {
          this.setState({ loading: false });
          if (data.success) {
            storage.remove(TIME_READ_VERIFICATION);
            this.props.history.replace("/login");
          }
        }).catch(error => {
          this.setState({ loading: false });
        });
      }
    });
  };

  validateConfirmPw = (_, value, cb) => {
    const newPassword = this.props.form.getFieldValue('newPassword');
    if (newPassword === value) {
      cb();
    } else {
      cb('再次确认新密码错误！');
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        <div className="title">申请免费体验</div>
        <Form.Item>
          {getFieldDecorator("sdf", {
            rules: [{
              required: true, message: '请输入公司名称',
            }]
          })(
            <Input
              autoComplete="off"
              className="login-input"
              placeholder="请输入公司名称"
            />
          )}
        </Form.Item>
        <Form.Item className='test'>
          {getFieldDecorator("mobile", {
            rules: [{
              type: 'string', pattern: Regexp.phone, message: '请检查手机号格式',
            }, { required: true, message: "11" }]
          })(
            <Input
              autoComplete="off"
              className="login-input"
              placeholder="请选择省份"
            />
          )}
        </Form.Item>

        <Form.Item className='test test1'>
          {getFieldDecorator("mobile", {
            rules: [{
              type: 'string', pattern: Regexp.phone, message: '请检查手机号格式',
            }, { required: true, message: "11" }]
          })(
            <Input
              autoComplete="off"
              className="login-input"
              placeholder="请选择城市"
            />
          )}
        </Form.Item>


        <Form.Item>
          {getFieldDecorator("mobile", {
            rules: [{
              type: 'string', pattern: Regexp.phone, message: '请检查手机号格式',
            }, { required: true, message: "请输入手机号" }]
          })(
            <Input
              autoComplete="off"
              className="login-input"
              placeholder="请输入手机号"
            />
          )}
        </Form.Item>
       
        <Button className="reset-btn" type="primary" htmlType="submit" loading={this.state.loading} block>提交</Button>

        <div className='hint' style={{marginTop:'24px'}}>请填写真实信息，方便我们专业人员与您对接！</div>
        <div className='hint' style={{marginTop:'10px'}}>更多问题请电洽 <span>400-123-456</span> 咨询</div>
      </Form>
    );
  }
}

const WrappedLoginForm = Form.create({ name: 'LoginForm' })(LoginForm);

class Login extends Component {
  render() {
    return (
      <div className="page-findPassword">
        <Button className="back-login-btn" href="#/login">返回登录页</Button>
        <WrappedLoginForm {...this.props}></WrappedLoginForm>
      </div>
    );
  }
}

export default Login;
