import React, { Component } from 'react';
import { Breadcrumb, Icon, Button } from 'antd';
import InfoCard from '../../common/card/InfoCard';
import './SettingAccountInfo.less';
import http from '@/http';
import { API } from '@/http/api';

export default class AccountInfo extends React.Component {

    getAccountInfo = () => {
        http({
            api: API.userInfo
        }).then(res => {
            const {
                name,
                email,
                mobile,
                identityNumber,
                companyName,
                departmentName
            } = res.data || {};

            this.setState({
                data: {
                    name,
                    email,
                    mobile,
                    identityNumber,
                    companyName,
                    departmentName,
                },
            })
        })
    }



    constructor(props) {
        super(props);
        this.getAccountInfo();
        this.state = {
            data: {
                name: null,
                email: null,
                mobile: null,
                identityNumber: null,
                companyName: null,
                departmentName: null,
            },
            infoKey: {
                name: "姓名：",
                email: { desc: "邮箱：", readonly: true },
                mobile: { desc: "绑定手机号：", readonly: true },
                identityNumber: { desc: "身份证号码：", readonly: true },
                companyName: "公司：",
                departmentName: "部门：",
            }
        }
    }

    render() {
        let items = [];
        const { infoKey, data } = this.state;
        for (let key in data) {
            const config = infoKey[key];
            let title = config;
            let infoClass = 'account-info';
            if (config.readonly) {
                title = config.desc;
                infoClass += ' readonly';
            }
            items.push((<div className={infoClass}>{title}{data[key]}</div>))
        }
        return (
            <div className='setting-account'>
                <Breadcrumb>
                    <Breadcrumb.Item>账户设置</Breadcrumb.Item>
                </Breadcrumb>
                <InfoCard title={'个人信息'}>
                    {items}
                </InfoCard>
                <InfoCard title={'登录信息'}>
                    <div className="account-info">登录密码
                    <span className="account-update" onClick={() => this.props.history.replace("/app/account/password/change?mobile=" + this.state.data.mobile)}>修改</span>
                    </div>
                </InfoCard>
            </div>)
    }
}