import { API } from "@/http/api";
import http from "@/http";
import { showErrorMessage } from "@/utils/globalMessageReminder";
import { formatLinkSearch } from "@/utils";
import React, { Component } from "react";
import "./EnterpriseAuthDetail.less";
import {
  convertAuthenticationToLabel,
  CustomerCreateType
} from "CMPT/operate/customerConfig.js";
import EnterpriseMC from "./EnterpriseMC";
import AttachmentDownload from 'CMPT/common/AttachmentDownload';
import {
  Layout,
  Breadcrumb,
  Button,
  Row,
  Col,
  Tooltip,
  Modal,
  Input
} from "antd";
import { Link } from "react-router-dom";
import wordSrc from "@/images/files_word@2x.png";
import pdfSrc from "@/images/files_pdf@2x.png";
import excelSrc from "@/images/files_excel@2x.png";
import pptSrc from "@/images/files_ppt@2x.png";
import txtSrc from "@/images/files_txt@2x.png";
import unknowSrc from "@/images/files_unknown@2x.png";
import { CommonFlag, AccountTypeFlag } from "CMPT/common.js";
import moment from "moment";



import userUtils from '@/utils/user';
import { AUTH_KEYS } from "@/utils/auth";
const { checkAuthByKey } = userUtils;
const canShowEditAuth = checkAuthByKey(AUTH_KEYS.CUSTOMER_MANAGE_EDIT); // 类目编辑； 

const { Content } = Layout;

class EnterpriseCustomerDetail extends Component {
  state = {
    pageNo: null, // 当前企业在前一个列表中的页码
    currentageCount: null, // 前一个页面中 列表内当前页的数量

    code: null, // 公司ID
    companyName: null,
    creditCode: null,
    businessLicenseUrl: null,
    isSupplier: null,
    isGarden: null,
    category: null,
    categoryDesc: null,
    managementModel: null,
    personalName: null,
    personalIdentity: null,
    personalPhone: null,
    personalEmail: null,
    authenticationTime: 1,
    authenticationStatus: null, // 认证状态（0、待认证 1、通过 2、驳回）
    rejectionReason: null,
    applyBy: null,
    applyTime: null,
    managementModelDesc: null, //经营模式，1生产厂家 2经销批发 3商业服务 4招商代理 5其它
    authenticationTime: null,
    shortName: null,
    havaEmail: null,
    enterpriseEmailDomain: null,
    attachUrl: null,
    acountType: null,
    contractStartDate: null,
    contractEndDate: null,
    signDate: null,
    completeFlag: 1, // 是否完善信息 1否 2 是
    enterpriseFlag: 1, // 显示企业资质信息1 还是 经营资质信息2
    enterpriseCompleteVo: {
      openAccountPermit: [], //开户许可证
      normalTaskCert: [], //纳税人资格证
      legalPersonCert: [], //法人正面
      legalPersonCertRevert: [], // 法人反面
      proxyPersonCert: [], // 代理人正面
      proxyPersonCertRevert: [], // 代理人反面
      authorityCert: [], // 授权书
      onlineProduct: [], // 
      taxCheckIn: [], // 税务登记证
      organizationCode: [] //组织机构代码证
    },
    manageInfo: [
      // {
      //   key: 1,
      //   first: '一级办公用品',
      //   second: '二级办公用品',
      //   file: {
      //     fileName: '基本资质',
      //     url: 'https://filev2d.idf10.com/download/23452345',
      //     mediaExt: 'docx'
      //   }
      // },
    ],
    manageParams: {
      pageNo: 1,
      pageSize: 10,
      total: 0,
      enterpriseCode:this.props.match.params.encode
    },

    errorTitle: null, // 审核时失败信息 对话框的title
    errorMsg: null, // 审核时失败信息
    showErrorFlag: false, // 是否展示 审核失败信息

    backPageNo: 1 //返回列表页面的页码
  };

  componentDidMount() {
    const search = this.props.history.location.search
    let tab = 1
    if (search) {
      tab = this.getQueryParams(search).tab ? Number(this.getQueryParams(search).tab) : 1
    }
    this.setState({
      enterpriseFlag: tab
    }, () => {
      this.getDetail()
      this.getManageInfo() // 获取经营资质列表
    })
  }
  getQueryParams = (str) => {
    let splitList = str.split('?')[1].split('&')
    let obj = {}
    splitList.forEach(per => {
      let perSplit = per.split('=')
      obj[perSplit[0]] = perSplit[1]
    })
    return obj
  }
  getDetail = () => {
    const config = {
      api: API.crmCustomerDetail,
      path: {
        encode: this.props.match.params.encode
      },
      showLoading: true
    };

    http(config).then(res => {
      if (res && res.success && res.data) {
        let data = res.data;
        const stateData = this.state
        this.setState(
          {
            ...stateData,
            ...data
          }
        );
      }
    });
  };

  getManageInfo = () => {
    const config = {
      api: API.crmCustomerManageInfo,
      data: this.state.manageParams
    }
    http(config).then(res => {
      if (res && res.success) {
        let params = this.state.manageParams
        params.total = res.data.totalRecord
        params.pageNo = res.data.pageNo
        this.setState({
          manageParams: params,
          manageInfo: res.data.datas && res.data.datas.map((per, index) => {
            return {
              key: index + 1,
              first: per.levelOneName,
              second: per.levelTwoName,
              file: {
                encode: per.mediaVo && per.mediaVo.encode,
                fileName: per.mediaVo && per.mediaVo.name,
                url: per.mediaVo && per.mediaVo.url,
              }
            }
          }) || [],
        })
      }
    })
  }
  /* 审核，通过或者驳回时 需要 */
  showErrorInfoDialog(title, msg) {
    this.setState({
      errorTitle: title,
      errorMsg: msg,
      showErrorFlag: true
    });
  }

  hiddenErrorInfoDialog = () => {
    this.setState({
      errorTitle: null,
      errorMsg: null,
      showErrorFlag: false
    });
  };

  watchBigImage = src => {
    this.setState({
      bigImgSrc: src
    });
  };

  addErrorInfoDialog() {
    return (
      <Modal
        title={this.state.errorTitle}
        visible={true}
        footer={[
          <Button
            key="primary"
            style={{ float: "none" }}
            type="primary"
            onClick={this.hiddenErrorInfoDialog}
            size="large"
          >
            我知道了
          </Button>
        ]}
        closable={false}
        maskClosable={false}
        width={400}
        onOk={this.hiddenErrorInfoDialog}
      >
        <div>{this.state.errorMsg}</div>
      </Modal>
    );
  }

  goUpgrade() {
    let parames =
      "?isSupplier=" +
      (this.state.isSupplier ? true : false) +
      "&isGarden=" +
      (this.state.isGarden ? true : false);

    this.props.history.push(
      "/app/crm/client/upgrade/" + this.props.match.params.encode + parames
    );
  }

  goEdit() {
    let parames =
      "?isSupplier=" +
      (this.state.isSupplier ? true : false) +
      "&isGarden=" +
      (this.state.isGarden ? true : false);

    this.props.history.push(
      "/app/crm/client/newAddEdit?encode=" + this.props.match.params.encode
    );
  }

  checkCustomerUpgrade() {
    let authTo =
      "1" +
      (this.state.isSupplier ? ",2" : "") +
      (this.state.isGarden ? ",3" : "");
    const config = {
      api: API.enterpriseUpgradeJudge,
      data: {
        sys: 2, // 2 表示CRM
        enterpriseCode: this.state.code,
        authenticationTo: authTo
      },
      showLoading: true
    };

    http(config).then(res => {
      if (res && res.success) {
        this.goUpgrade();
      } else if (res && res.code === "300103") {
        this.showErrorInfoDialog(
          "提示",
          res.msg ? res.msg : "此客户正在申请升级，请先进行审批"
        );
      }
    });
  }



  showSuppplyInfo() {
    let { commission, completeFlag } = this.state;
    return (
      <React.Fragment>
        {/* <Row gutter={20}>
          <Col className="gutter-row" span={12}>
            主营类目：{this.state.categoryDesc}
          </Col>
        </Row> */}
        <Row gutter={20}>
          <Col className="gutter-row" span={12}>
          资料是否完善：{completeFlag == 2 ? '是' : '否'}
          </Col>
        </Row>
        <Row gutter={5}>
          <Col className="gutter-row" span={12}>
            佣金比例：{commission ? (commission + '%') : ''}
          </Col>
        </Row>
        <Row gutter={20}>
          <Col className="gutter-row" span={12}>
            经营模式：{this.state.managementModelDesc}{" "}
            {[
              /*经营模式，1生产厂家 2经销批发 3商业服务 4招商代理 5其它 */
            ]}
          </Col>
        </Row>{" "}
      </React.Fragment>
    );
  }

  shwoGraderInfo() {
    const intentionFilesList = this.state.fileInfoList
      ? this.state.fileInfoList.map((item, index) => (
        <Col key={index} span={4}>
          {" "}
          {item.mediaExt == "jpg" || item.mediaExt == "png" ? (
            <a className="file-link" target="_blank" href={item.url}>
              {" "}
              <img
                style={{ width: "120px", height: "90px" }}
                src={item.url}
                alt=""
              />{" "}
            </a>
          ) : (
              <a className="file-link" target="_blank" href={item.url}>
                {" "}
                <img
                  src={
                    item.mediaExt == "doc" || item.mediaExt == "docx"
                      ? wordSrc
                      : item.mediaExt == "xlsx"
                        ? excelSrc
                        : item.mediaExt == "jpg" || item.mediaExt == "png"
                          ? item.url
                          : unknowSrc
                  }
                  alt=""
                />
              </a>
            )}{" "}
          {item.name}{" "}
        </Col>
      ))
      : null;

    return (
      <React.Fragment>
        <Row gutter={20}>
          <Col className="gutter-row" span={12}>
            总部地址：{this.state.addressDesc}
          </Col>
        </Row>
        <Row gutter={20}>
          <Col className="gutter-row" span={24}>
            账号类型：{" "}
            {this.state.acountType == AccountTypeFlag.FORMAL ? "正式" : "试用"}
          </Col>
        </Row>

        <Row gutter={20}>
          <Col className="gutter-row" span={12}>
            项目数量：{this.state.projectNumber}
          </Col>
        </Row>

        {this.state.acountType == AccountTypeFlag.TRIAL ? (
          <Row gutter={20}>
            <Col className="gutter-row" span={24}>
              试用期：{" "}
              {this.state.contractStartDate + "到" + this.state.contractEndDate}
            </Col>
          </Row>
        ) : (
            ""
          )}

        {this.state.acountType == AccountTypeFlag.FORMAL ? (
          <Row gutter={20}>
            <Col className="gutter-row" span={24}>
              合同有效期：{" "}
              {this.state.contractStartDate + "到" + this.state.contractEndDate}
            </Col>
          </Row>
        ) : (
            ""
          )}

        {this.state.acountType == AccountTypeFlag.FORMAL &&
          this.state.signDate ? (
            <Row gutter={20}>
              <Col className="gutter-row" span={24}>
                签约时间： {this.state.signDate}
              </Col>
            </Row>
          ) : (
            ""
          )}

        {this.state.acountType == AccountTypeFlag.FORMAL &&
          this.state.fileInfoList &&
          this.state.fileInfoList.length > 0 ? (
            <div>
              <label>附件</label>
              <Row style={{ paddingTop: "20px" }}>{intentionFilesList}</Row>
            </div>
          ) : null}
      </React.Fragment>
    );
  }
  showEnterpriseInfo = (value) => {
    this.setState({
      enterpriseFlag: value
    })
  }
  tableChange = (pageNo, pageSize) => {
    console.log(pageNo, pageSize)
    let params = this.state.manageParams
    params.pageNo = pageNo
    params.pageSize = pageSize
    this.setState({
      manageParams: params
    }, () => this.getManageInfo())
  }

  render() {
    let state = this.state;
    const { bigImgSrc, enterpriseCompleteVo } = state;

    return (
      <Content className="customer-detail-content">
        <div className="customer-detail-head flexbox">
          <Breadcrumb className="customer-detail-head-bread">
            <Breadcrumb.Item>
              <Link
                style={{ color: "#333" }}
                to={{ pathname: "/app/crm/client" }}
              >
                客户管理
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>客户详情</Breadcrumb.Item>
          </Breadcrumb>
          {/* <ul className="customer-detail-head-data data-detail">
                        {this.checkDispatchAuth(state) ? <li className="data-li"> <Button type="primary" onClick={this.showDeleteConfirm}>删除</Button> </li> : null}
                        <li className="data-li"> <Button type="primary" onClick={this.assign}>{state.acountStatus ? '停用' : '启用'}</Button> </li>
                        {this.checkEditAuth(state) ? <li className="data-li ">  <Button type="link" onClick={() => this.props.history.push('/app/crm/client/edit/' + id)}>编辑</Button>   </li> : null}
        </ul> */}
        </div>
        <Row gutter={24} className="customer-detail-content">
          {state.isSupplier && state.completeFlag == 2 ? <div className="customer-tabs">
            <ul>
              <li className={state.enterpriseFlag === 1 ? "active" : ""} onClick={this.showEnterpriseInfo.bind(this, 1)}>企业资质信息</li>
              <li className={state.enterpriseFlag === 2 ? "active" : ""} onClick={this.showEnterpriseInfo.bind(this, 2)}>经营资质信息</li>
            </ul>
          </div> : ''}
          {state.enterpriseFlag === 1 ? <Col span={24} className="customer-detail-left">
            {[
              /* 企业信息相关 */
            ]}
            <div className="customer-detail-body">
              <label>企业信息</label>
              <div>
                <Row gutter={20}>
                  <Col className="gutter-row" span={12}>
                    企业名称：{state.companyName}
                  </Col>
                </Row>
                <Row gutter={20}>
                  <Col className="gutter-row" span={12}>
                    企业简称：{state.shortName}
                  </Col>
                </Row>
                <Row gutter={20}>
                  <Col className="gutter-row" span={12}>
                    统一社会信用代码：{state.creditCode}
                  </Col>
                </Row>

                <Row gutter={20}>
                  <Col className="gutter-row" span={24}>
                    营业执照 :
                  </Col>
                </Row>
                <Row>
                  <Col className="gutter-row gutter-row-pic" span={4}>
                    <img
                      style={{ width: "120px", height: "90px" }}
                      src={state.businessLicenseUrl}
                      alt=""
                      onClick={() => {
                        this.watchBigImage(state.businessLicenseUrl);
                      }}
                    />
                  </Col>
                </Row>

                {state.enterpriseDesc ? (
                  <Row gutter={20}>
                    <Col className="gutter-row" span={24}>
                      企业简介：{state.enterpriseDesc}
                    </Col>
                  </Row>
                ) : (
                    ""
                  )}

                {state.contactName ? (
                  <Row gutter={20}>
                    <Col className="gutter-row" span={24}>
                      联系人姓名：{state.contactName}
                    </Col>
                  </Row>
                ) : (
                    ""
                  )}

                {state.contactPhone ? (
                  <Row gutter={20}>
                    <Col className="gutter-row" span={24}>
                      联系人手机号：{state.contactPhone}
                    </Col>
                  </Row>
                ) : (
                    ""
                  )}

                <Row gutter={20}>
                  <Col className="gutter-row" span={24}>
                    是否有企业邮箱：
                    {state.havaEmail && state.enterpriseEmailDomain
                      ? "是"
                      : "无"}
                  </Col>
                </Row>

                {state.havaEmail && state.enterpriseEmailDomain ? (
                  <Row gutter={20}>
                    <Col className="gutter-row" span={24}>
                      企业邮箱域名：{state.enterpriseEmailDomain}
                    </Col>
                  </Row>
                ) : (
                    ""
                  )}
              </div>
            </div>

            {[
              /* 管理员信息相关 */
            ]}
            <div className="customer-detail-body">
              <label>管理员信息</label>
              <div>
                <Row gutter={20}>
                  <Col className="gutter-row" span={24}>
                    姓名：{state.personalName}
                  </Col>
                </Row>
                <Row gutter={20}>
                  <Col className="gutter-row" span={24}>
                    身份证：{state.personalIdentity}
                  </Col>
                </Row>
                <Row gutter={20}>
                  <Col className="gutter-row" span={24}>
                    手机号：{state.personalPhone}
                  </Col>
                </Row>

                <Row gutter={20}>
                  <Col className="gutter-row" span={24}>
                    邮箱：{state.personalEmail}
                  </Col>
                </Row>
              </div>
            </div>

            {[
              /* 企业身份相关 */
            ]}
            <div className="customer-detail-body">
              <label>企业身份</label>
              <Row gutter={20}>
                <Col className="gutter-row" span={24}>
                  企业身份：{" "}
                  {convertAuthenticationToLabel(state.authenticationTo)}
                </Col>
              </Row>

              {state.isSupplier == CommonFlag.Yes ? this.showSuppplyInfo() : ""}

              {state.isGarden == CommonFlag.Yes ? this.shwoGraderInfo() : ""}
            </div>
            {state.isSupplier && state.completeFlag == 2 ? <>
            
              <div className="customer-detail-body">
                <label>开户许可证</label>
                <Row gutter={20}>
                  <Col className="gutter-row gutter-row-pic" span={4}>
                    {enterpriseCompleteVo.openAccountPermit && enterpriseCompleteVo.openAccountPermit.map(per => {
                      return <img
                          className="body-image"
                          key={per.encode}
                          src={per.url}
                          onClick={() => {this.watchBigImage(per.url)}}
                        />
                      })
                    }
                  </Col>
                </Row>
              </div>
              <div className="customer-detail-body">
                <label>一般纳税人资格证</label>
                <Row gutter={20}>
                  <Col className="gutter-row gutter-row-pic" span={4}>
                    {enterpriseCompleteVo.normalTaskCert && enterpriseCompleteVo.normalTaskCert.map(per => {
                        return <img
                            className="body-image"
                            key={per.encode}
                            src={per.url}
                            onClick={() => {this.watchBigImage(per.url)}}
                          />
                        })
                      }
                  </Col>
                </Row>
              </div>
              <div className="customer-detail-body">
                <label>法人身份证正反面</label>
                <Row gutter={20}>
                    {enterpriseCompleteVo.legalPersonCert && enterpriseCompleteVo.legalPersonCert.map(per => {
                        return <img
                            className="body-image"
                            key={per.encode}
                            src={per.url}
                            onClick={() => {this.watchBigImage(per.url)}}
                          />
                        })
                      }
                    {enterpriseCompleteVo.proxyPersonCertRevert && enterpriseCompleteVo.proxyPersonCertRevert.map(per => {
                        return <img
                            className="body-image"
                            key={per.encode}
                            src={per.url}
                            onClick={() => {this.watchBigImage(per.url)}}
                          />
                        })
                      }
                </Row>
              </div>
              <div className="customer-detail-body">
                <label>代理人身份证正反面</label>
                <Row gutter={20}>
                    {enterpriseCompleteVo.proxyPersonCert && enterpriseCompleteVo.proxyPersonCert.map(per => {
                        return <img
                            className="body-image"
                            key={per.encode}
                            src={per.url}
                            onClick={() => {this.watchBigImage(per.url)}}
                          />
                        })
                      }
                    {enterpriseCompleteVo.proxyPersonCertRevert && enterpriseCompleteVo.proxyPersonCertRevert.map(per => {
                        return <img
                            className="body-image"
                            key={per.encode}
                            src={per.url}
                            onClick={() => {this.watchBigImage(per.url)}}
                          />
                        })
                      }
                </Row>
                <Row gutter={20} className="special-row">
                  <p>授权书</p>
                  {enterpriseCompleteVo.authorityCert && enterpriseCompleteVo.authorityCert.map(per => {
                    return <div key={per.encode}>
                      <AttachmentDownload file={{url: per.url, fileName: per.name}} />
                    </div>
                  })}
                </Row>
              </div>
              <div className="customer-detail-body">
                <label>税务登记证</label>
                <Row gutter={20}>
                  <Col className="gutter-row gutter-row-pic" span={4}>
                    {enterpriseCompleteVo.taxCheckIn && enterpriseCompleteVo.taxCheckIn.map(per => {
                        return <img
                            className="body-image"
                            key={per.encode}
                            src={per.url}
                            onClick={() => {this.watchBigImage(per.url)}}
                          />
                        })
                      }
                  </Col>
                </Row>
              </div>
              <div className="customer-detail-body">
                <label>组织机构代码证</label>
                <Row gutter={20}>
                  <Col className="gutter-row gutter-row-pic" span={4}>
                    {enterpriseCompleteVo.organizationCode && enterpriseCompleteVo.organizationCode.map(per => {
                        return <img
                            className="body-image"
                            key={per.encode}
                            src={per.url}
                            onClick={() => {this.watchBigImage(per.url)}}
                          />
                        })
                      }
                  </Col>
                </Row>
              </div>
            </> : null}

            <div className="customer-detail-body">
              <label>创建信息</label>
              <Row gutter={20}>
                <Col className="gutter-row" span={24}>
                  创建方式：{" "}
                  {state.dataCreateBy
                    ? CustomerCreateType[state.dataCreateBy]
                    : "-"}
                </Col>
              </Row>
              <Row gutter={20}>
                <Col className="gutter-row" span={24}>
                  创建时间：{" "}
                  {this.state.createTime
                    ? moment(this.state.createTime).format("YYYY-MM-DD HH:mm")
                    : "-"}
                </Col>
              </Row>
            </div>





            <div className="operate" style={{ marginBottom: 20 }}>
              {[
                /*  能进入这个界面，一定是认证通过的  */
              ]}
              {this.state.authenticationStatus == 1 && canShowEditAuth ? <Button type="primary" onClick={() => this.goEdit()}>
                编辑
             </Button> : ""}

              {[
                /*  这里 需要判断下 当前是否是供应商+园区方，是，不展示 升级 按钮 (519隐藏) */
              ]}
              {false && this.state.authenticationStatus == 1 && (!this.state.isSupplier || !this.state.isGarden)
                ? <Button type="primary" onClick={() => this.checkCustomerUpgrade()}> 企业账号升级 </Button>
                : ""}

            </div>
          </Col> : <Col>
            <div className="customer-detail-body">
              <EnterpriseMC data={state.manageInfo} onChange={this.tableChange} current={state.manageParams.pageNo} total={state.manageParams.total} onlineProduct={enterpriseCompleteVo.onlineProduct}/>
            </div>
          </Col>}
        </Row>

        {this.state.showErrorFlag ? this.addErrorInfoDialog() : ""}

        {bigImgSrc ? (
          <div
            className="big_img"
            onClick={() => {
              this.setState({ bigImgSrc: null });
            }}
          >
            <img src={bigImgSrc} alt="" />
          </div>
        ) : null}
      </Content>
    );
  }
}

export default EnterpriseCustomerDetail;
