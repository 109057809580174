import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import {
  HelpChannel,
  convertValueToEnumLabel
} from "CMPT/common.js";
import userUtils from '@/utils/user';
import { AUTH_KEYS } from "@/utils/auth";
const { checkAuthByKey } = userUtils;
const canShowEditAuth = checkAuthByKey(AUTH_KEYS.HELP_CENTER_MANAGE_CLASS_MANAGE_EDIT); // 类目编辑； 
const canShowDeleteAuth = checkAuthByKey(AUTH_KEYS.HELP_CENTER_MANAGE_CLASS_MANAGE_DELETE); // 类目删除；
const Card = ({
  id,
  text,
  index,
  moveCard,
  adds,
  unfold,
  child,
  onUnfold,
  addsson,
  delcatalog,
  edit,
  channel
}) => {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: "card",
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    }
  });
  const [{ isDragging }, drag] = useDrag({
    item: { type: "card", id, index },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  console.log(opacity);
  return (
    <div ref={ref} style={{ opacity }}>
      {/* <div onClick={()=>{ adds()}}>sdsd</div>
         黄瓜大傻逼 {text} */}
      <div
        className="cardItem"
        onDoubleClick={() => {
          //onUnfold(index);
        }}
      >
        <div className="cardItemLeft">
          <img
            src={require("../../images/iconCatalog.png")}
            width="24"
            alt="img"
          />{" "}
        </div>
        <div className="cardItemright">
          <div className="cardItemParent">
            <div className="cardHandle">{index}</div>
            <div className="cardItemParentTitle">{text}</div>
            <div className="cardChannel">{channel ? convertValueToEnumLabel(channel.split(","), HelpChannel) : ''}</div>
            <div className="cardHandle">
              {
                canShowEditAuth?
                <span
                  onClick={() => {
                    edit(text, id, channel);
                  }}
                >
                  编辑
                </span> 
                : null
              }
              {/* <span>添加子目录</span> */}
              {
                canShowDeleteAuth ?
                <span
                  onClick={() => {
                    delcatalog(id);
                  }}
                >
                  删除
                </span>
                : null
              }
            </div>
          </div>
          {/* <div style={{ display: unfold ? 'block' : 'none' }}>
                        {
                            child ? child.map((item) => {
                                return (
                                    <div className='cardItemSon'>
                                        <div className='cardItemLeft'><img src={require('../../images/delete@2x.png')} alt='img' /> </div>
                                        <div className='cardItemright'>
                                            <div className='cardItemParent'>
                                                <div className='cardItemParentTitle'>{item.tetx}</div>
                                                <div className='cardHandle'><span>编辑</span><span onClick={()=>{addsson()}}>添加子目录</span><span>删除</span></div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) : null
                        }
                    </div> */}
        </div>
      </div>
    </div>
  );
};
export default Card;
