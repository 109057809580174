import { AJAX_TYPE } from "../config";

// 公告

const prefix = "goods";

export default {
  [`${prefix}List`]: {
    url: "/api/supply/web/crm/itemRecommend/list",
    type: AJAX_TYPE.POST
  },
 

  [`${prefix}InsertBatch`]: {
    url: "/api/supply/web/crm/itemRecommend/insertBatch",
    type: AJAX_TYPE.POST
  },

    // 修改
    [`${prefix}Update`]: {
      url: "/api/supply/web/crm/itemRecommend/update",
      type: AJAX_TYPE.POST
    },
 
  [`${prefix}Delete`]: {
    url: "/api/supply/web/crm/itemRecommend/delete",
    type: AJAX_TYPE.POST
  },



  

};
