import React from 'react';
import { getUuid } from '@/utils';
import { Modal, Form, InputNumber, Input, Button, Icon } from 'antd';
import InfoCard from '../../../common/card/InfoCard';
import EditableTable from '../EditableTable';
import { API } from '@/http/api';
import http from '@/http';
import './FloorInfo.less';

export default class FloorInfo extends React.Component {

    constructor(props) {
        super(props);
        this.columns = [
            { title: '楼层序号', key: 'code', editable: true, required: true, width: 119 },
            { title: '楼层名称', key: 'name', editable: true, required: true, suffix: '层', width: 216 },
            { title: '楼层面积(m²)', key: 'area', editable: true, required: true, width: 216 },
            { title: '楼层单价 (元/m²/日)', key: 'price', editable: true, required: true, width: 216 },
            { title: '层高 (米)', key: 'height', editable: true, width: 216 },
            {
                title: '操作',
                key: 'operation',
                editable: false,
                width: 87,
                className:'btn-row-center',
                render: (text, record) => (                    
                    <Form.Item style={{ margin: 0 }}>
                        <a className={"btn-row-delete" + (this.state.dataSource.length <= 1 ? " btn-row-delete-disabled" : '')}
                            href="javascript:;"
                            onClick={() => this.handleDelete(record.key,record.encode)} >删除</a>
                    </Form.Item>
                ),
            }];
        this.state = {
            batchFloorAddModalVisible: false,
            dataSource: props.dataSource
        }
    }

    componentWillReceiveProps(props) {
        this.setState({
            dataSource: props.dataSource
        });
    }

    handleDelete = (key,encode) => {      
        
        if(this.state.dataSource.length<=1) {
            return false;
        }
        
        http({
            api: API.zoneGet_list,
            path: {}, // 路径参数
            data: {floorEncode:key}, // 请求参数
            showLoading: true // 是否显示全局加载
        }).then(res => {
            if(res.success){
                var content = ''
                if(res.data&&res.data.length>0){
                    content = '当前楼层已录入区域信息，统计数据中将不统计已删除楼层及区域数据，操作无法恢复，请确认是否删除？'
                }else{
                    content = '操作无法恢复，请确认是否删除？'
                }
                Modal.confirm({
                    icon: false,
                    content,                    
                    onOk: () => {                
                        if(!encode){
                            const dataSource = [...this.state.dataSource];                            
                            this.setState({
                                dataSource: dataSource.filter(item => item.key !== key)
                            },()=>{
                                this.props.onChange(this.state.dataSource);
                            });            
                            return false;
                        }    
                        http({
                            api: API.floorDelete,
                            path: {floorEncode:key}, // 路径参数
                            data: {}, // 请求参数
                            showLoading: true // 是否显示全局加载
                        }).then(res => {
                            if(res.success){
                                const dataSource = [...this.state.dataSource];
                                this.setState({
                                    dataSource: dataSource.filter(item => item.key !== key)
                                },()=>{
                                    this.props.onChange(this.state.dataSource);
                                });
                            }
                        })
                        
                    },
                    onCancel: () => { }
                });
            }
        })        
    }

    showModal() {
        this.setState({
            batchFloorAddModalVisible: true,
        });
    }

    floorSingleAdd() {
        const dataSource = [...this.state.dataSource];
        const codeList = dataSource.map(per => per.code)
        const max = Math.max.apply(null, codeList) + 1

        dataSource.unshift({
            key: getUuid(),
            code: max,
            name: max < 0 ? `B${-max}` : '' + max,
            area: null,
            price: null,
            height: null,
        });
        if (typeof this.props.onChange === 'function') {
            this.props.onChange(dataSource);
        }
    }

    onBatchAdd = (dataSource) => {
        if (typeof this.props.onChange === 'function') {
            this.props.onChange(dataSource);
        }
    }

    onTableChange = (record) => {
        const dataSource = [
            ...this.state.dataSource
        ];
        let repeatCount = 0;
        dataSource.forEach(item => {
            if (item.code === record.code) {
                ++repeatCount;
            }
        });
        if (repeatCount < 2) {
            const index = dataSource.findIndex(item => item.key === record.key);
            if (index != -1) {
                dataSource[index] = {
                    ...record
                };
            }
        }
        if (typeof this.props.onChange === 'function') {
            this.props.onChange(dataSource);
        }
    }
    onTableBlur = (attr) => {
        if (attr === 'code') {
            let dataSource = this.state.dataSource
            dataSource = dataSource.sort((a, b) => {
                return b.code - a.code
            })
            this.props.onChange(dataSource)
        }
    }

    render() {
        
        return (
            <InfoCard title={'楼层信息'}>
                <div className="operation">
                    <span className="btn-add" onClick={this.showModal.bind(this)}>
                        <i className="iconfont iconadd_circle"></i>
                        批量添加
                    </span>
                    <span className="btn-add" onClick={this.floorSingleAdd.bind(this)}>
                        <i className="iconfont iconadd_circle"></i>
                        单层添加
                    </span>
                    {this.state.batchFloorAddModalVisible && <FloorAddModal
                        onCancel={() => this.setState({
                            batchFloorAddModalVisible: false,
                        })}
                        onConfirm={this.onBatchAdd}
                        dataSource={this.state.dataSource} />}
                </div>
                <EditableTable columns={this.columns} dataSource={this.state.dataSource} onChange={this.onTableChange} onBlur={this.onTableBlur} />
            </InfoCard>
        );
    }
}

class FloorAddModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: true,
            dataSource: props.dataSource,
            constraint: {
                floor: {
                    status: 'success',
                    message: ''
                },
                area: {
                    status: 'success',
                    message: ''
                },
                price: {
                    status: 'success',
                    message: ''
                }
            },
            param: {
                min: null,
                max: null,
                area: null,
                price: null,
                height: null
            }
        };
    }

    componentWillReceiveProps(props) {
        this.setState({
            visible: true,
            dataSource: props.dataSource
        });
    }

    onCancel() {
        this.setState({
            visible: false,
        });
        this.props.onCancel();
    }

    checkFloor() {
        const param = this.state.param;
        if (!param.min || !param.max) {
            this.setState({
                constraint: {
                    ...this.state.constraint,
                    floor: {
                        status: 'error',
                        message: '必填'
                    }
                }
            });
            return false;
        }
        if (param.min > param.max) {
            this.setState({
                constraint: {
                    ...this.state.constraint,
                    floor: {
                        status: 'error',
                        message: '最高层不能小于最低层'
                    }
                }
            });
            return false;
        }

        let newData = [...this.state.dataSource];
        for (let item of newData) {
            if (item.key >= param.min && item.key <= param.max) {
                this.setState({
                    constraint: {
                        ...this.state.constraint,
                        floor: {
                            status: 'error',
                            message: '楼层范围与已有楼层重复'
                        }
                    }
                });
                return false;
            }
        }

        return true;
    }

    checkArea() {
        const param = this.state.param;
        if (!param.area) {
            this.setState({
                constraint: {
                    ...this.state.constraint,
                    area: {
                        status: 'error',
                        message: '必填'
                    }
                }
            });
            return false;
        }

        return true;
    }
    checkPrice() {
        const param = this.state.param;
        if (!param.price) {
            this.setState({
                constraint: {
                    ...this.state.constraint,
                    price: {
                        status: 'error',
                        message: '必填'
                    }
                }
            });
            return false;
        }
        return true;
    }

    resetCheck() {
        this.setState({
            constraint: {
                floor: {
                    status: 'success',
                    message: ''
                },
                area: {
                    status: 'success',
                    message: ''
                },
                price: {
                    status: 'success',
                    message: ''
                }
            }
        })
    }

    onConfirm() {
        let newData = [...this.state.dataSource];
        const param = this.state.param;
        if (!this.checkFloor() || !this.checkArea() || !this.checkPrice()) {
            return;
        }
        this.resetCheck();
        for (let i = param.min; i <= param.max; ++i) {
            newData.unshift({
                key: i,
                code: i,
                name: i < 0 ? `B${-i}` : '' + i,
                area: param.area,
                price: param.price,
                height: param.height,
            });
        }
        if (typeof this.props.onConfirm == 'function') {
            this.props.onConfirm(newData);
        }
        this.onCancel();
    }

    render() {
        const constraint = this.state.constraint;
        const param = this.state.param;
        const {
            min,
            max,
            area,
            price,
            height,
        } = param;
        return (
            <Modal destroyOnClose={true}
                closable={false}
                footer={null}
                onCancel={this.onCancel.bind(this)}
                visible={this.state.visible}>
                <div className="modal-floor-title">
                    批量添加
                    </div>
                <Form layout={'vertical'} className="modal-form-floor">
                    <Form.Item label={'楼层范围'}
                        required
                        validateStatus={constraint.floor.status}
                        help={constraint.floor.message}>
                        <Input.Group compact>
                            <InputNumber value={min}
                                min={-20}
                                max={200}
                                style={{ width: "42%" }}
                                placeholder={'最底层'}
                                onChange={(value) => {
                                    this.setState({ param: { ...param, min: value==0?'':value } });
                                    this.resetCheck();
                                }} />
                            <span style={{ width: "16%", textAlign: "center",color: '#E0E4F1' }}>～</span>
                            <InputNumber value={max}
                                min={-20}
                                max={200}
                                style={{ width: "42%" }}
                                placeholder={'最高层'}
                                onChange={(value) => {
                                    this.setState({ param: { ...param, max: value==0?'':value } });
                                    this.resetCheck();
                                }} />
                        </Input.Group>
                    </Form.Item>
                    <Form.Item label={'楼层面积'} required
                        validateStatus={constraint.area.status}
                        help={constraint.area.message}>
                        <InputNumber value={area}
                            style={{ width: "100%" }}
                            placeholder={'请输入'}
                            min={1}
                            max={99999999}
                            suffix={"m²"}
                            onChange={(value) => {
                                this.setState({ param: { ...param, area: value } });
                                this.resetCheck();
                            }} />
                    </Form.Item>
                    <Form.Item label={'楼层单价'} required
                        validateStatus={constraint.price.status}
                        help={constraint.price.message}>
                        <InputNumber value={price}
                            style={{ width: "100%" }}
                            placeholder={'请输入'}
                            suffix={"元/m²/日"}
                            min={1}
                            max={9999}
                            onChange={(value) => {
                                this.setState({ param: { ...param, price: value } });
                                this.resetCheck();
                            }} />
                    </Form.Item>
                    <Form.Item label={'层高'}>
                        <InputNumber value={height}
                            style={{ width: "100%" }}
                            placeholder={'请输入'}
                            suffix={"m"}
                            min={0}
                            max={99}
                            onChange={(value) => this.setState({ param: { ...param, height: value } })} />
                    </Form.Item>
                </Form>

                <div className="btn-modal-floor">
                    <Button type="primary" onClick={this.onConfirm.bind(this)}>
                        确定
                        </Button>
                    <Button type="link" onClick={this.onCancel.bind(this)}>
                        取消
                        </Button>
                </div>
            </Modal>
        );

    }
}


