const sha256 = require("js-sha256").sha256; //引入sha256库
function sort_ASCII(obj){
    var arr = [];
    var num = 0;
    for (let i in obj) {
        arr[num] = i;
        num++;
    }
    var sortArr = arr.sort();
    var sortObj = {};
    for (let i in sortArr) {
        sortObj[sortArr[i]] = obj[sortArr[i]];
    }
    return sortObj;
}
 
 
 
 
function obj2qs(obj){
    if (!obj && !Object.keys(obj).length) {
        return "";
    } else {
        var arr = [];
        for (let key in obj) {
            arr.push(key + "=" + obj[key]);
        }
        return arr.join("&");
    }
}
 
//生成随机整数
function RandomTwo(){
    const math = parseInt(Math.random() * 100);
    if (math > 9) {
        return math;
    } else {
        return math + 10;
    }
}
 
//转换base64加密密码
function toBase64(str) {
    return window.btoa(encodeURIComponent(escape(str)));
}
 
//md5算法加签样例
// function getSign(data) {
//     let noce =  RandomTwo();
//     let { password } = data;
//     if (password) {
//         data.password = toBase64(password);
//     }
//     var loginForm = {
//         ...data,
//         "noce":noce,
//         "alg": "MD5",
//         "timestamp": parseInt(new Date().getTime() / 1000)
//     }
//     let forsigin = { ...loginForm };
//     forsigin = sort_ASCII(forsigin);
//     forsigin = obj2qs({ ...forsigin, key: "secret-haha" });
//     const sign = SparkMD5.hash(forsigin);
//     return { ...loginForm, sign };
// }
 
//sha256算法加签样例
 
export function getSignSha256(data) {
    let noce =  RandomTwo();
    let { password } = data;
    if (password) {
        data.password = toBase64(password);
    }
    var loginForm = {
        ...data,
        "noce":noce,
        "alg": "SHA256",
        "timestamp": parseInt(new Date().getTime() / 1000)
    }
    let forsigin = { ...loginForm };
    forsigin = sort_ASCII(forsigin);
    forsigin = obj2qs({ ...forsigin, key: "secret-haha" });
    const sign = sha256(forsigin);
    return { ...loginForm, sign };
}
 
 
// function loginLocal() {
//     var url = "http://i.st15.idf10.com.cn/modules/api/account/common/2/login";
//     var loginForm = {
//         loginNo: "admin",
//         password: "pwd123456"
//     }
//     var info = getSignSha256(loginForm);
//     console.info(info)
//     $.ajax({
//         url: url,
//         type: 'POST',
//         contentType: "application/json;charset=utf-8",
//         data: JSON.stringify(info),
//         xhrFields: {withCredentials: true}
//     });
// }