import Enum from "@/utils/Enum";

// 认证身份
export const IdentityType = new Enum(
  // 4.26 --- 全部、建设施工、供应商、司机
  { alias: "PURCHASE", text: "建设施工", value: 1 },
  { alias: "WULIU", text: "物流公司", value: 2 },
  { alias: "SUPPLY", text: "供应商", value: 3 },
  { alias: "ZULIN", text: "租赁公司", value: 5 },
  // { alias: "DRIVER", text: "司机", value: 20 },
  // { alias: "PURCHASE", text: "采购商", value: '1' },
  // { alias: "SUPPLY", text: "供应商", value: '1,2' },
  // { alias: "GARDEN", text: "园区方", value: '1,3' },
  // { alias: "GSUPPLY", text: "供应商+园区方", value: '1,2,3' },
);

// 认证类型
export const AuthenticationTypes = new Enum(
  { alias: "FIRST ", text: "初次认证", value: 1 },
  { alias: "RECRTIFICATION ", text: "重新认证", value: 2 },
  { alias: "RESUBMIT", text: "重新提交", value: 8 },
  { alias: "UPGRADE", text: "升级认证", value: 9 },
);

// 类型筛选
export const CertType = new Enum(
  { alias: "PURCHASE ", text: "采购商", value: 0 },
  { alias: "PRELIMINARY ", text: "分步-初审", value: 1 },
  { alias: "ONESTEP", text: "一步到位", value: 2 },
  { alias: "IMPROVEINFO", text: "分步-完善供应商资料", value: 3 },
);

// 审批状态
export const AuthenticationStatus = new Enum(
  { alias: "READY", text: "待审批", value: 0 },
  { alias: "SUCCESS ", text: "审批通过", value: 1 },
  { alias: "REJECT", text: "审批驳回", value: 2 },
);