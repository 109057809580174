import React, { Component } from 'react';
import { Tag, Input, Icon, Button } from 'antd';
import http from '@/http';
import { API } from '@/http/api';
import './departmentstaff.less'
import { get } from 'https';
import Item from 'antd/lib/list/Item';
const { Search } = Input;
let count = 0;
let num =0
class departmentstaff extends Component {
  state = {
    isShow: false,
    department: null,
    staff: [],
    staffAll: [],
    selectStaff: [],
    staffFlag: []
  }
  componentDidMount() {
    let that = this
    this.getData();
    num=0
    document.getElementById('root').onclick = function (e) {
      if (e.target.className !== 'departmentSelect'
        && e.target.className !== 'ant-input'
        && e.target.dataset.icon !== "search"
        && e.target.className !== 'departmentBtn'
        && e.target.tagName !== 'path'
        && e.target.tagName !== 'svg'
        && e.target.className !== 'departmentName'
        && e.target.className !== 'departmentBottom'
        && e.target.className !== 'left'
        && e.target.className !== 'right'
        && e.target.className !== 'staff'
        && e.target.className !== 'departmentContent'
        && e.target.className !== 'departmentText'
        && e.target.className !== 'nostaff'
        && e.target.innerText !== '重置'

      ) {
        that.setState({ isShow: false })
      }
    }
  }
  componentWillReceiveProps(next, pre) {
    if (next.deptVoList.length > 0&&num===0) {
      let deptVoList = next.deptVoList
      for (let i = 0, len = deptVoList.length; i < len; i++) {
        if (deptVoList[i].text) {
          deptVoList[i].idCode = 'dep-' + deptVoList[i].idCode
        }
      }
      this.setState({
        selectStaff: deptVoList
      })
    } else {
      return null
    }
  }
  getData() {
    http({
      api: API.departmentGetDeptTree,
    }).then(res => {
      if (res.data) {
        res.data.idCode = 'dep-' + res.data.idCode //部门id与人员i相同
        this.getNewId(res.data)
        this.getDataNew(res.data)
        res.data.active = true;
        this.getStaff(res.data.idCode)
        this.setState({
          department: res.data
        }, () => {
          this.unfold(this.state.department, this.state.department.idCode)
        })
      }
    });
  }

  getStaff(code) {
    code = code.slice(4)
    http({
      api: API.departmentStaff,
      data: {
        departmentEncode: code,
        pageNo: 1,
        pageSize: 100000
      }
    }).then(res => {
      if (res.data) {
        let data = res.data.datas
        this.setState({
          staffAll: data,
          staff: data,
          staffFlag: data,
        })
      }
    });
  }
  click(dep) {
    num++
    this.getActive(this.state.department, dep.idCode)
    count += 1;
    setTimeout(() => {
      this.getStaff(dep.idCode)
      if (count === 2) {
        this.getroleName(dep)
      }
      count = 0;
      this.props.getDataState(this.getDepartmentstaff())
    }, 300);
  }

  getDepartmentstaff() {
    const { selectStaff } = this.state;
    let data = {
      userIds: [],
      departmentIds: []
    }
    for (let i = 0, len = selectStaff.length; i < len; i++) {
      if (selectStaff[i].text) {
        data.departmentIds.push(selectStaff[i].idCode.slice(4))
      } else {
        data.userIds.push(selectStaff[i].idCode)
      }
    }
    return data
  }



  getDataNew(data, id = '', type = '') {
    if (!type) data.isShow = (id === data.idCode && !data.isShow) || (id !== data.idCode && data.isShow)
    if (data.children.length > 0) {
      for (let i = 0; i < data.children.length; i++) {
        data.children[i].isShow = (id === data.children[i].idCode && !data.children[i].isShow) || (id !== data.children[i].idCode && data.children[i].isShow)
        if (data.children[i].children.length > 0) {
          this.getDataNew(data.children[i], id, 'type')
        }
      }
    } else {
      data.isShow = false
    }
  }

  getNewId(data) {
    if (data.children.length > 0) {
      for (let i = 0; i < data.children.length; i++) {
        data.children[i].idCode = 'dep-' + data.children[i].idCode
        if (data.children[i].children.length > 0) {
          this.getNewId(data.children[i])
        }
      }
    }
  }

  getActive(data, id = '') {
    data.active = id === data.idCode
    if (data.children.length > 0) {
      for (let i = 0; i < data.children.length; i++) {
        data.children[i].active = id === data.children[i].idCode
        if (data.children[i].children.length > 0) {
          this.getActive(data.children[i], id)
        }
      }
    }
  }
  close(index) {
    let selectStaff = this.state.selectStaff;
    selectStaff.splice(index, 1)
    this.setState({
      selectStaff,
    }, () => {
      this.props.getDataState(this.getDepartmentstaff())
    })
  }
  getroleName(item) {
    const { selectStaff } = this.state;
    console.log(this.state.selectStaff)
    if (selectStaff.length === 0) {
      selectStaff.push(item)
    } else {
      let falg = false
      for (let i = 0; i < selectStaff.length; i++) {
        if (selectStaff[i].idCode === item.idCode) {
          falg = true;
          break
        }
      }
      if (!falg) {
        selectStaff.push(item)
      }
    }
    this.setState({
      selectStaff,
    }, () => {
      this.props.getDataState(this.getDepartmentstaff())
    })
  }

  unfold(department, id) {
    this.getDataNew(department, id)
    this.setState({
      department
    })
  }
  getree(department) {
    if (department.children.length > 0) {
      return department.children.map((item) => {
        return (department.isShow ?
          (<div style={{ marginLeft: '10px' }} key={item.idCode} className='departmentText'>
            <div className='departmentText'>
              <div className='departmentText' style={item.children.length === 0 ? { paddingLeft: '5px' } : {}}><Icon type={item.isShow ? 'minus' : item.children.length === 0 ? 'icon' : 'plus'} onClick={() => { this.unfold(this.state.department, item.idCode) }} /> <span className={item.active ? "departmentName" : 'departmentText'} onClick={() => { this.click(item) }}>{item.text}</span></div>
              {this.getree(item)}
            </div>
          </div>) : null
        )
      })
    }
  }
  search(value) {
    const { staffAll, staffFlag } = this.state;
    if (value) {
      let list = staffAll.filter(item => {
        if (item.name.indexOf(value) > -1) {
          return item
        }
      })
      this.setState({
        staff: list
      })
    } else {
      this.setState({
        staff: staffFlag
      })
    }
  }

  render() {
    const { isShow, department, staff, selectStaff } = this.state
    return (
      <div className="departmentstaff">
        <div className='departmentShow' onClick={(e) => { this.setState({ isShow: true }) }}>
          {
            selectStaff.map((item, index) => {
              return (
                <Tag closable onClose={() => { this.close(index) }} key={item.idCode}>{item.name || item.text}</Tag>
              )
            })
          }

          {
            isShow ? (<div className='departmentSelect'>
              <Search
                placeholder="人员"
                onChange={e => this.search(e.target.value)}
                style={{ width: '94%', marginLeft: '3%', marginTop: '8px' }}
              />
              <div className='departmentContent'>
                <div className='left'>
                  {
                    department ? (<div className='departmentText'>
                      <div className='departmentText'><Icon type={department.isShow ? 'minus' : department.children.length === 0 ? 'icon' : 'plus'} onClick={() => { this.unfold(this.state.department, department.idCode) }} /> <span className={department.active ? "departmentName" : 'departmentText'} onClick={() => { this.click(department) }}>{department.text}</span></div>
                      {this.getree(department)}
                    </div>) : null
                  }

                </div>
                <div className='right'>
                  {
                    staff.length > 0 ? (
                      <div className='departmentText'>
                        {staff.map((item) => {
                          return <div className="staff" onClick={() => { this.getroleName(item) }} key={item.idCode}>{item.name}</div>
                        })}
                      </div>
                    ) : (<div className='nostaff'>暂无人员~</div>)
                  }
                </div>

              </div>
              <div className='departmentBottom'>
                <div className='left'> <Icon type="bulb" style={{ marginRight: '5px', fontSize: '16px' }} /><span className='departmentText'>双击选择部门所有人员，单机选择人员</span></div>
                <div className='right'>
                  <Button type="dashed" size='small' onClick={() => { this.setState({ selectStaff: [] }) }} >重置</Button> <Button onClick={() => { this.setState({ isShow: false }) }} type="primary" size='small'>确定</Button>
                </div>
              </div>
            </div>) : null
          }
        </div>
      </div>
    );
  }
}

export default departmentstaff;
