import React, { Component } from 'react';
import { Checkbox, Row, Col, Tooltip } from 'antd';
import './ReceiptTemplateEdit.less'
const CheckboxGroup = Checkbox.Group;

class ChooseProject extends Component {
    constructor(props) {
        super(props)
        this.state = {
            plainOptions: props.projectList || [],
            checkedList: props.value ||props.checkedList|| [], //回填的
            indeterminate: true,
            checkAll: false
        }
        console.log(props.checkedList)
        console.log(this.state.checkedList)
    }
    componentWillReceiveProps(nextProps){
        this.setState({
            checkedList: nextProps.checkedList, //回填的
        })
    }
    onChange(checkedList) {
        this.setState({
            checkedList,
            indeterminate: !!checkedList.length && (checkedList.length < this.state.plainOptions.length),
            checkAll: checkedList.length === this.state.plainOptions.length
        }, () => {
            console.log(this.state.checkedList)  
            this.props.getProjectList(this.state.checkedList)
            this.props.onChange(this.state.checkedList)
            
        });

    }
    onCheckAllChange(e) {
        const newOption = this.state.plainOptions.map((item, index) => {
            return item.value
        })
        this.setState({
            checkedList: e.target.checked ? newOption : [],
            indeterminate: false,
            checkAll: e.target.checked
        }, () => {
            // console.log(this.state.checkedList)
            this.props.getProjectList(this.state.checkedList)
            this.props.onChange(this.state.checkedList)
        });
    }
    render() {
        const checkBoxList = this.state.plainOptions.map((item, index) =>
            <Col span={12} key={index}>
                <div style={{ height: '35px' }}>              
                    <Checkbox value={item.value}>                
                        {item.label.length > 7 ?  <Tooltip placement="top" title={item.label}>{item.label}</Tooltip> : item.label}
                    </Checkbox>
                </div>
            </Col>
        )
        return (
            <div className="choose-project">
                <div className="choose-all">
                    <span className="require">适用范围{this.props.require ?<i> * </i>:<i> </i>}</span>
                    {this.props.multiple?
                        <Checkbox
                            indeterminate={this.state.indeterminate}
                            onChange={this.onCheckAllChange.bind(this)}
                            checked={this.state.checkAll}
                        >
                            全选
                        </Checkbox>
                        :
                        null
                    }
                </div>

                <div className="checkgroup">
                    <Checkbox.Group style={{ width: '100%' }} value={this.state.checkedList} onChange={this.onChange.bind(this)}>
                        <Row>
                            {checkBoxList}
                        </Row>
                    </Checkbox.Group>
                </div>

            </div>
        );
    }
}

export default ChooseProject;