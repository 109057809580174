import React, { Component } from 'react';
import { Breadcrumb, Button,Row,Form,Popover,Icon,Modal,Input,message,Col} from 'antd';
import BaseInfo from '../financeApproval/BaseInfo'
import Tip from 'CMPT/common/Tip';
import ApprovalChain from 'CMPT/common/ApprovalChain';
import EditRefund from'./EditRefund';
import { AUTH_KEYS } from '@/utils/auth';
import AuthContainer from 'CMPT/common/auth/AuthContainer';
import http from '@/http';
import { API } from '@/http/api';
import "../financeApproval/finance.less"
import './bill.less'

const BreadcrumbItem = Breadcrumb.Item;
const Item = Form.Item;
const { TextArea } = Input;

class RefundDetail extends Component {
    state = {
        showDeleteModal:false,
        billInfo:{},
        refundInfo:{},
        stepList: [],
    }
    componentDidMount(){
        this.getInfo();
        this.getRefundInfo();
        this.getAuditFlowList();
    }
    getAuditFlowList(){
        http({
            api: API.billGetAuditFlowList,
            data:{
                encode:this.props.match.params.code,
                type:33
            }
        }).then(res => {
            if(res.data){
                this.setState({
                    stepList:res.data
                })
            }           
        })
    }
    getInfo(){
        http({
            api: API.billGetById,
            data:{
                encode:this.props.match.params.id
            }
        }).then(res => {
            if(res.data){
                this.setState({
                    billInfo:res.data
                })
            }           
        })
    }
    getRefundInfo(){
        http({
            api: API.billrefundGetById,
            data:{
                encode:this.props.match.params.code
            }
        }).then(res => {
            if(res.data){
                this.setState({
                    refundInfo:res.data
                })
            }           
        })
    }
    deleteBill(){
        http({//GET /web/bill/refund/dropRefund
            api: API.billrefundDropRefund,
            data:{
                refundCode:this.props.match.params.code
            }
        }).then(res => {
            if(res.success){
                message.success('账单删除成功');
                this.props.history.push('/app/operate/finance/bill?type=3');
            }           
        })
    }
    delete(){
        this.setState({showDeleteModal:true});
    }
    handleOk(){
        this.deleteBill();
        this.setState({
            showDeleteModal:false
        })
    }
    handleCancel(){
        this.setState({showDeleteModal:false})
    }
    render(){
        const { billInfo,refundInfo } = this.state;
        // billInfo.refundEncode = refundInfo.refundEncode
        const { getFieldDecorator } = this.props.form;
        const auditComponent = (
            <Popover overlayClassName="bo-audit-popover" content={<ApprovalChain stepList={this.state.stepList}/>} trigger="click" placement="bottomRight">
                <div className="clickPopover"><i className="iconfont iconapprove"></i><span>查看审批流程</span></div>
            </Popover>
        )
        return (
            <div className="billWrap">
            <Form>
                <Modal
                    className="passModal"
                    title='确认删除此退款记录'
                    closable={false}
                    visible={this.state.showDeleteModal}
                    onOk={this.handleOk.bind(this)}
                    okText='确定'
                    width={400}
                    onCancel={this.handleCancel.bind(this)}
                >                 
                </Modal>
                <div className="bill-header">
                    <Breadcrumb>
                        <BreadcrumbItem>财务收支</BreadcrumbItem>
                        <BreadcrumbItem>账单管理</BreadcrumbItem>
                        <BreadcrumbItem>退款详情</BreadcrumbItem>
                    </Breadcrumb>
                    <div className="bill-header">
                        <AuthContainer authCodes={AUTH_KEYS.OPERATE_FINANCE_BILL_DEL}>
                            <Button type="link" className="btn-warning ml20" onClick={this.delete.bind(this)}>删除</Button>
                        </AuthContainer>    
                        <AuthContainer authCodes={AUTH_KEYS.OPERATE_FINANCE_BILL_REFUND}>
                            <span className="ml20" style={{display:refundInfo.editFlag==1?'':'none'}}>
                                <EditRefund type={2} billInfo={refundInfo} action={null}/> 
                            </span>  
                        </AuthContainer>                          
                    </div>
                </div>
                <div style={{display:refundInfo.auditStatus==0?'':'none'}}>
                    <Tip type="wait" title="审批中" hint={true} secondTitle={'当前审批人：'+refundInfo.auditUserName} hintNode={auditComponent}/>
                </div>
                <div style={{display:refundInfo.auditStatus==1?'':'none'}}>
                    <Tip type="warn" title="审批驳回" hint={true} hintNode={auditComponent} des={refundInfo.auditComment}/>
                </div>
                <div style={{display:refundInfo.auditStatus==2?'':'none'}}>
                    <Tip type="pass" title="审批通过" hint={true} hintNode={auditComponent}/>
                </div>
                <BaseInfo {...this.state} {...this.props} noshowOverdue={true}/>
                <div>
                    <div className="approval-baseInfo">
                        <div className="title">退款信息</div>
                        <Row className="fs12 b12">
                            <Col span={8}>退款金额：{refundInfo.amount}元</Col>
                            <Col span={8}>退款申请日期：{refundInfo.createTime}</Col>
                        </Row>
                        <Row className="fs12 b12">
                            <Col span={24}>退款原因：{refundInfo.reason}</Col>
                        </Row>
                    </div>
                </div>
                
                </Form>
            </div>            
        )
    }
}

export default Form.create({ name: 'refundDetail' })(RefundDetail);