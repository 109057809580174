import React, { Component } from 'react';
import { Form, Row, Col, Input, Select, Icon } from 'antd';

const { Option }= Select;
const { Item } = Form;

export default class SettingServiceadd extends Component{
    constructor(props) {
        super(props);
        this.state = {
            serviceType: [
                {
                    value: 1,
                    name: '屋内设施报修配置'
                },
                {
                    value: 2,
                    name: '公共设施报修配置'
                },
                {
                    value: 3,
                    name: '耗材配置'
                }
            ]
        }
    }

    //处理options数据类型
    orderOptionsList(options) {
        return this.state[options].map(d => <Option key={d.value}>{d.name}</Option>)
    }

    render() {
        const { defaultValue, handleChange, defaultValueList, isshowType } = this.props;
        const { getFieldDecorator } = this.props.form;
        const { itemname, itemnums } = defaultValueList;
        const optionsList = this.orderOptionsList('serviceType');

        return (
            <div className='maintain-addorder maintain-form'>
                <Form>
                    {
                        isshowType ? (<div>
                            <Item label="类型">
                                {getFieldDecorator('projectType', {
                                    rules: [{ required: true, message: '请选择类型' }],
                                    initialValue: String(defaultValue)
                                })(
                                    <Select style={{width: '100%'}}
                                            placeholder="请选择"
                                            suffixIcon={<Icon type="caret-down" />}
                                            disabled
                                            onChange={handleChange.bind(this,1)}>
                                        {optionsList}
                                    </Select>
                                )}
                            </Item>
                        </div>) : null
                    }
                    <Item label={Number(defaultValue) === 3 ? '耗材名称' : '保修项目名称'}>
                        {getFieldDecorator('projectName', {
                            rules: [{ required: true, message: Number(defaultValue) === 3 ? '请输入耗材名称' : '请输入保修项目名称' }],
                            initialValue: itemname
                        })(
                            <Input placeholder="请输入"
                                   maxLength={20}
                                   onChange={handleChange.bind(this,2)}/>
                        )}
                    </Item>
                    {
                        Number(defaultValue) === 3 ? (
                            <Item label='数量单位'>
                                {getFieldDecorator('projectNum', {
                                    rules: [{ required: true, message: '请输入数量单位'}],
                                    initialValue: itemnums
                                })(
                                    <Input placeholder="请输入"
                                           maxLength={10}
                                           onChange={handleChange.bind(this,3)}/>
                                )}
                            </Item>
                        ) : (
                            <Item label='超时设置(小时)'>
                                {getFieldDecorator('projectNum', {
                                    rules: [{
                                        required: true,
                                        validator: (rule, value, callback)=>{
                                            if (value>0 && value<=168 && /^[0-9]+([.]{1}[0-9])?$/.test(value)){
                                                callback()
                                            } else {
                                                callback(false)
                                            }
                                        },
                                        message: '请输入正确的超时设置(小时)',
                                    }],
                                    initialValue: itemnums
                                })(
                                    <Input placeholder="请输入"
                                           onChange={handleChange.bind(this,3)}/>
                                )}
                            </Item>
                        )
                    }
                </Form>
            </div>
        )
    }
}
