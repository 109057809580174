import React, { Component, useState } from 'react';
import { Tree, Icon, Input, Row, Col, Dropdown, Menu, Button, message, Modal } from 'antd';
import { Link } from 'react-router-dom';
import catalogImg from '@/images/iconCatalog.png'
import './CategoryTree.less';
// import WatchGoods from './WatchGoods';
// import AddGoods from './AddGoods';
import { API } from '@/http/api';
import http, { ajax } from '@/http';
import storage from '@/utils/storage';

import userUtils from '@/utils/user';
import { AUTH_KEYS } from "@/utils/auth";
const { checkAuthByKey } = userUtils;
const categoryManageAuth = checkAuthByKey(AUTH_KEYS.RULE_MANAGE_CATEGORY_MANAGE); // 管理目录


const { TreeNode } = Tree;
var datas = [] // 暂存的自己及父级的id 层级数组 例如：[爷爷级Id, 父级Id, 自己Id]
class CategoryTree extends Component {
	state = {
		treeData: [],
		addModal: false, // 添加分类商品 弹窗
		watchModal: false, // 查看分类商品 弹窗
		modalCategoryItemId: null, // 当前code
		firstLeveId: [],
		expandedKeys: []
	}
	componentDidMount () {
		this.setState({
			treeData: [...this.props.gData]
		})
		this.onExpand()
		// this.getFirstLeveId(this.props.gData)
	}
	componentWillReceiveProps (nextProps) {
		if (nextProps.gData && nextProps.gData.length > 0) {
			this.getFirstLeveId(nextProps.gData)
		}
	}
	// 获取一级id
	getFirstLeveId = (gData) => {
		var arr = [];
		gData && gData.map(item => {
			arr.push(item.key);
		})
		this.setState({
			firstLeveId: arr
		})
	}
	// 递归 获得自己的层级id数组
	getItemAndParentsIdArr(arr, key) {
		for (let i = 0; i < arr.length; i++) {
			if (arr[i] && arr[i].key === key) {
				datas.push(arr[i]);
				return true;
			} else {
				if (
					arr[i] &&
					arr[i].children &&
					this.getItemAndParentsIdArr(arr[i].children, key)
				) {
					datas.push(arr[i]);
					return true;
				}
			}
		}
	}
	  
	  // 获得自己的父级id
	getFirstParentId(strings, key) {
		let stringsArr = strings.split("/");
		let index = stringsArr.indexOf(key.toString());
		let parenId;
		if (index !== 0) {
		  parenId = stringsArr[index - 1];
		} else {
		  parenId = stringsArr[index];
		}
		return parenId;
	}

	onDrop = info => {

		const dropKey = info.node.props.eventKey; // 目标节点的id
		const dragKey = info.dragNode.props.eventKey; // 当前拖动的节点
		const dropPos = info.node.props.pos.split('-');
		const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
		const loop = (data, key, callback) => {
			data.forEach((item, index, arr) => {
				if (item && item.key === key) {
					return callback(item, index, arr);
				}
				if (item && item.children) {
					return loop(item.children, key, callback);
				}
			});
		};
		const data = [...this.props.gData];
		// Find dragObject
		let dragObj;
		const { firstLeveId } = this.state;
		if (
			firstLeveId.indexOf(dropKey) > -1
			&&
			firstLeveId.indexOf(dragKey) > -1
		) { // 一级排序
			loop(data, dragKey, (item, index, arr) => {
				arr.splice(index, 1);
				dragObj = item;
			});
			if (dropPosition == 0) {
				message.warn('排序请拖动至目标类目上方或下方');
				return;
			}
			if (!info.dropToGap) {
				// Drop on the content
				loop(data, dropKey, item => {
					item.children = item.children || [];
					// where to insert 示例添加到尾部，可以是随意位置
					item.children.push(dragObj);
				});
			} else if (
				(info.node.props.children || []).length > 0 && // Has children
				info.node.props.expanded && // Is expanded
				dropPosition === 1 // On the bottom gap
			) {
				loop(data, dropKey, item => {
					item.children = item.children || [];
					// where to insert 示例添加到尾部，可以是随意位置
					item.children.unshift(dragObj);
				});
			} else {
				let ar;
				let i;
				loop(data, dropKey, (item, index, arr) => {
					ar = arr;
					i = index;
				});
				if (dropPosition === -1) {
					ar.splice(i, 0, dragObj);
				} else {
					ar.splice(i + 1, 0, dragObj);
				}
			}
			this.props.initTreeData(data)
			this.catoragySort(true, data)
		} else {
			// 如果拖动到内部
			if (!info.dropToGap) {
				loop(data, dropKey, item => {
					item.children = item.children || [];
					// where to insert 示例添加到尾部，可以是随意位置
					item.children.push(dragObj);
				});
			} else if (
				(info.node.children || []).length > 0 && // Has children
				info.node.expanded && // Is expanded
				dropPosition === 1 // On the bottom gap
			) {
			} else {
				// 获得拖拽节点的层级id数组 datas
				datas = [];
				this.getItemAndParentsIdArr(data, dragKey);
				let dragKeyString = datas
					.reverse()
					.map((v, i) => {
						return v.key;
					})
					.join("/");

				// 拖拽节点父级id
				let dragKeyParentId = this.getFirstParentId(dragKeyString, dragKey);
				/**
				 * --------------------------------分割线---------------------------------------
				 */
				// 获得目标节点的层级id数组 datas
				datas = [];
				this.getItemAndParentsIdArr(data, dropKey);
				let dropKeyString = datas
					.reverse()
					.map((v, i) => {
						return v.key;
					})
					.join("/");

				// 目标节点父级id
				let dropKeyParentId = this.getFirstParentId(dropKeyString, dropKey);
				/**
				 * --------------------------------分割线---------------------------------------
				 */
				// 只允许在同父级id下进行拖动
				// console.log(dragKeyParentId, dropKeyParentId, '===========')
				if (dragKeyParentId === dropKeyParentId) {
					loop(data, dragKey, (item, index, arr) => {
						arr.splice(index, 1);
						dragObj = item;
					});
					let ar;
					let i;
					loop(data, dropKey, (item, index, arr) => {
						ar = arr;
						i = index;
					});
					if (dropPosition === -1) {
						ar.splice(i, 0, dragObj);
					} else {
						ar.splice(i + 1, 0, dragObj);
					}
					// this.gData = data;
					// console.log(data, dropKeyParentId, '二级拖动完成')
					this.props.initTreeData(data)
					this.catoragySort(false, data, dropKeyParentId)
				} else {
					message.warn('不可跨分类排序')
				}
			}
		}
	};

	catoragySort = (isFirstLeve, data, dropKeyParentId) => {
		let arr = [];
		if (isFirstLeve) {
			data && data.map(item=> {
				arr.push(item.code)
			})
		} else {
			data && data.map(item=> {
				if (item.code == dropKeyParentId) {
					item.children && item.children.map(child => {
						arr.push(child.code)
					})
				}
			})
		}
		// let sortList = arr.join(',');
		// console.log(sortList, 'sortList')
		const ajaxConfig = {
			codeList: arr
		}
        http({
			api: API.ruleCategorySort,
			data: ajaxConfig,
			successTip: '排序成功'
		}).then(res => {
			if (res.success) {
				this.props.getTreeData()
			} else {

			}
		});
	}

	onDragStart = info => {
		// console.log(info, this.props.gData, '000000000000000000000000');
		
	}
	// 展开/关闭
	onExpand = info => {
		console.log(info, 'info')
		this.setState({expandedKeys: info || []})
	}

	openLine = item => {
		if (item && item.children && item.children.length == 0) {
			return;
		}
		const { expandedKeys } = this.state;
		if (expandedKeys.indexOf(item.code) == -1) {
			expandedKeys.push(item.code);
		} else {
			expandedKeys.remove(item.code);
		}
		this.setState({expandedKeys})
	}

	// 删除节点
	deleteItem = (item) => {
		const modalContent = item.level == 1 ? 
			`注：一级目录下有子目录无法进行删除。若要删除目录请清空子目录。` 
			: '注：目录中有规则文章无法进行删除，若要删除目录请清空规则文章。';
		Modal.confirm({
			title: '确认删除目录吗？',
			content: modalContent,
			icon: null,
			onOk: () => {
				this.goDeleteItem(item)
			}
		})
	}
	// 去删除节点 
	goDeleteItem = (item) => {
		const ajaxConfig = {
			code: item.code
		}
        http({
			api: API.ruleCategoryDelete,
			data: ajaxConfig,
            successTip: '删除成功'
		}).then(res => {
			if (res.success) {
				this.props.getTreeData()
			}
		});
	}

	goRuleList = (item) => {
		window.location.href = `/#/app/crm/rulecenter/rulelist/${item.code}`
	}

	renderTreeNodes = data => data && data.map((item) => {
		const itemTitle = (
			<div className="item_line">
				{
					item ?
					<Row>
						<Col span={2} style={{textAlign: "center"}}>
							{
								item.level == 1 ?
								<i className="icon iconfont icon-approve"></i>
								: null
							}
						</Col>
						<Col span={14}>
							{item.level == 2 ? <i className="second_icon"></i> : null}
							{item.level == 2 ? 
								<span className="title_span second_level" onClick={()=>this.goRuleList(item)} >{item.title}</span> : 
								<span className="title_span" onClick={()=>this.openLine(item)}>{item.title}</span>
							}
							
						</Col>
						<Col span={8}>
							{
								item.level == 2 ?
								<>
									{/* {
										AddRuleAuth?
										<Button className="btn save_btn" type="primary" onClick={()=>this.goRuleList(item)}>添加规则</Button>
										: null
									} */}
								</>
								:
								<>
								{
									categoryManageAuth ?
									<Button className="btn save_btn" type="primary" onClick={()=>this.props.showModalGoCategoryDoing(2, item)}>添加子目录</Button>
									: null
								}
								</>
							}
							{
								categoryManageAuth?
								<Button className="btn save_btn" type="primary" onClick={()=>this.props.showModalGoCategoryDoing(item.level == 2 ? 3 : 1, item)}>编辑</Button>
								: null
							}
							{
								categoryManageAuth?
								<Button className="btn" onClick={()=> this.deleteItem(item)}>删除</Button>
								: null
							}
						</Col>
					</Row>
					: null
				}
			</div>
		)
		
		if (item && item.children && item.children[0]) {
			return (
				<TreeNode className="tree_node" title={itemTitle} key={item.code}  dataRef={item}>
					{this.renderTreeNodes(item.children)}
				</TreeNode>
			);
		}
		return <TreeNode key={item ? item.code : null} className="tree_node" title={itemTitle}/>;
	})

	render () {
		const { gData } = this.props;
		const { expandedKeys } = this.state;
		return (
			<div className="category_tree">
				<div className="ant-table-thead">
					<Row>
						<Col span={2} style={{textAlign: "center"}}>
							排序
						</Col>
						<Col span={14}>
							目录名称
						</Col>
						<Col span={8}>
							操作
						</Col>
					</Row>
				</div>
				<Tree
					className="draggable-tree"
					draggable
					blockNode
					onDragStart={this.onDragStart}
					onDrop={this.onDrop}
					onExpand={this.onExpand}
					expandedKeys={expandedKeys}
				>
					{/* {loop(this.state.gData)} */}
					{this.renderTreeNodes(gData)}
				</Tree>
				
				
			</div>
		);
	}
}
export default CategoryTree;