import React, { Component } from 'react';
import moment from 'moment';
import Select from '@/components/common/Select'
import { DatePicker,Form, Icon, Input, Button } from 'antd';

const Search = Input.Search;
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
let creatTimeValue = [];


class IntentionSearch extends Component {
    
    state = {
        fuzzyKey :undefined,
        status:undefined,
        channelType:undefined,
        rangeDate:[]
    }

    handleSubmit = () => {
        console.log(this.state)
        this.props.changeParam(this.state);
    
      }
      setKeywords = (e) => {
        this.setState({
            fuzzyKey: e.target.value
        })
    }
      changeKeywords = (value) => {
        this.setState({
            fuzzyKey:value
        },() =>{
            this.handleSubmit()
        }) 
                
      }

      handleChangeStatus = (value) => {
          console.log(value);
        this.setState({
            status:value
        }, () =>{
            this.handleSubmit()
        }) 
       
      }

      handleChangeChannelType = (value) =>{
        this.setState({
            channelType:value
        },() =>{
            this.handleSubmit()
        })
      }

      handleChangeTime = (value, date) => {
        creatTimeValue = [value[0],value[1]]
        this.setState({
            rangeDate:[date[0] || undefined, date[1] || undefined]
        },()=> {
            this.handleSubmit()
        })           
      }

      reset = () => {
        creatTimeValue = []
        this.setState({
            fuzzyKey :undefined,
            status:undefined,
            customerType:undefined,
            channelType:undefined,
            rangeDate:[]
        },() => {
            this.handleSubmit();
        })
       
      }

    render() {
        const { fuzzyKey, status, channelType} = this.state
        return (
            <div className="intention-form" layout="inline">
                <div>
                    <Search
                        placeholder="请输入客户名称/编号/跟进人姓名"
                        value={fuzzyKey}
                        onChange={this.setKeywords}
                        onSearch={this.changeKeywords}
                        allowClear={true}
                        style={{ width: 240 }}  />   
                </div>
                <div>
                    <Select  style={{width: '104px'}} data={this.props.statusConfigArray} notFoundContent={this.props.fetching ? <div style={{textAlign:'center',height:'100px',lineHeight:'100px'}}>加载中...</div>:<div className='ant-empty ant-empty-normal ant-empty-small'><div class="ant-empty-image"></div><p class="ant-empty-description">暂无数据</p></div>}  value={status} placeholder="状态" selectMethod={this.handleChangeStatus} />   
                </div>
                <div>
                    <Select  style={{width: '104px'}} data={this.props.intentionChannelTypeConfigArray} notFoundContent={this.props.fetching ? <div style={{textAlign:'center',height:'100px',lineHeight:'100px'}}>加载中...</div>:<div className='ant-empty ant-empty-normal ant-empty-small'><div class="ant-empty-image"></div><p class="ant-empty-description">暂无数据</p></div>} value={channelType} placeholder="渠道" selectMethod={this.handleChangeChannelType} /> 
                </div>
                <div>
                    <label style={{marginRight:'10px'}}>创建时间</label>
                    <RangePicker value={creatTimeValue} onChange={this.handleChangeTime}/>   
                </div>
                <div>
                    <a href="javascript:void(0)" style={{cursor: 'pointer',fontSize: '12px',textDecoration:'underline',textUnderlinePosition: 'under',border:'none',color: '#333',lineHeight:'32px'}} type="link" onClick={this.reset}>重置</a>
                </div> 
                             
            </div>
        );
    }
}

const IntentionSearchForm = Form.create({ name: 'intention_seach' })(IntentionSearch);

export default IntentionSearchForm;