import {
    AJAX_TYPE
} from '../config';

 
const prefix = 'consult';

export default {
    // 咨询列表
    [`${prefix}List`]: {
        url: '/api/isx/v1/basis/consultingService/acceptance/listForPage',
        type: AJAX_TYPE.POST
    },
    // 咨询详情
     [`${prefix}Detail`]: {
        url: '/api/isx/v1/basis/consultingService/acceptance/detail/:code',
        type: AJAX_TYPE.GET
    },
    // 受理咨询
     [`${prefix}Acceptance`]: {
        url: '/api/isx/v1/basis/consultingService/acceptance/acceptance',
        type: AJAX_TYPE.POST
    },
    // 审核列表
    [`${prefix}AuditList`]: {
        url: '/api/isx/v1/basis/consultingServiceAudit/list/:code',
        type: AJAX_TYPE.GET
    },
    // 咨询类型 列表
    [`${prefix}TypeList`]: {
        url: '/api/isx/v1/basis/consultingServiceType/list',
        type: AJAX_TYPE.GET
    },

    // 角标数量
    [`${prefix}Count`]: {
        url: '/web/crm/mark/consultingServiceMgr',
        type: AJAX_TYPE.POST
    }
}