import React,{Component} from 'react'
import { Icon, Popover, Button } from 'antd';
class ApprovalChain extends Component{
    render(){
        const {stepList } = this.props;
        
        return (
            <div className="stepY">
            {
                 stepList && stepList.map((item,index) =>{
                    //审批人               
                    let person = item.users && item.users.map((newItem,newIndex) => {
                        let icon = null;
                        let pAudit = null;
                        if(newItem.auditStatus == 30) {
                            icon = <Icon type='check' style={{color: '#E94437',border: '1px solid #E94437'}}/>
                            pAudit = <p><span style={{color: '#E94437', marginRight: '6px'}}>●</span>审批通过</p>
                        } else {
                            if(newItem.auditStatus == 20 || newItem.auditStatus == 50) {
                                icon = <Icon type='exclamation' style={{color: '#E94437',border: '1px solid #E94437'}}/>
                                pAudit = <p><span style={{color: '#E94437', marginRight: '6px'}}>●</span>审批驳回</p>
                            }else if(newItem.auditStatus == 99) {
                                icon = <Icon type='minus' style={{color: '#333',border: '1px solid #333'}}/>
                                pAudit = <p><span style={{color: '#838694', marginRight: '6px'}}>●</span>审批终止</p>
                            }else {
                                icon = <Icon type='check' style={{display: 'none'}}/>
                                pAudit = null
                            }
                        }                       
                        return (
                            <div className="approverLists" key={newIndex}>
                                <Popover overlayClassName="bo-audit-inner-popover" content={
                                    <div style={{fontSize:'12px'}}>
                                        {pAudit}
                                        <p>部门：{newItem.deptName}</p>
                                        <p>邮箱：{newItem.email}</p>
                                    </div>
                                }>
                                    <div className="people">{icon}{newItem.name}</div>
                                </Popover>
                                <div className="flag">{item.type == 3 ? '/' : '+'}</div>
                            </div>
                        )
                    })
                    if(item.reached && item.processed && (!item.rejectFlag && !item.terminated)) { //通过                   
                        return (
                            <div className="commonLine pass" key={index}>
                                <div className="showIcon">
                                    <Icon type="check-circle" theme="filled" style={{color: '#FBBC06', fontSize: "24px"}} />
                                </div>
                                <div>
                                    <div className="name">{item.name}</div>
                                    <div className="desc">{item.nodeDesc}</div>
                                    <div className="approver">审批人：
                                        {person}
                                    </div>
                                </div>
                                                        
                            </div>
                        )   
                    }
                    if(item.reached && !item.processed) { //待审批
                        return (
                            <div className="commonLine pedding" key={index}>
                                <div className="pedding-inner"></div>
                                <div>
                                    <div className="name">{item.name}</div>
                                    <div className="desc">{item.nodeDesc}</div>
                                    <div className="approver">审批人：
                                        {person}
                                    </div>
                                </div>                         
                            </div>
                        )
                    }
                    if(item.rejectFlag && !item.terminated) { //驳回
                        return (
                            <div className="commonLine reject" key={index}>
                                <div className="showIcon">
                                    <Icon type="minus-circle" style={{color: '#e94437', fontSize: "24px"}} />
                                </div>
                                <div>
                                    <div className="name">{item.name}</div>
                                    <div className="desc">{item.nodeDesc}</div>
                                    <div className="approver">审批人：
                                        {person}
                                    </div>
                                </div>                           
                            </div>
                        )                                
                    }
                    if(!item.rejectFlag && item.terminated) { //终止
                        return (
                            <div className="commonLine reject" key={index}>
                                <div className="showIcon">
                                    <Icon type="minus-circle" style={{color: '#333', fontSize: "24px"}} />
                                </div>
                                <div>
                                    <div className="name">{item.name}</div>
                                    <div className="desc">{item.nodeDesc}</div>
                                    <div className="approver">审批人：
                                        {person}
                                    </div>
                                </div>                           
                            </div>
                        )                                
                    }
                    if(!item.reached) {
                        return (
                            <div className="commonLine static" key={index}>
                                <div className="dots"></div>
                                <div>
                                    <div className="name staticName">{item.name}</div>
                                    <div className="desc">{item.nodeDesc}</div>
                                    <div className="approver">审批人：
                                        {person}
                                    </div>
                                </div>
                            </div>
                        )
                    }
                })
            }
            </div>
        )
    }
}

export default ApprovalChain