import React, { Component } from 'react';
import { Tooltip } from "antd";
import PropTypes from 'prop-types';
import "./MultiLineText.less";


//TODO   需求还需要再理理，进行扩展，应该还可以加个 Button。 比如有些情况下 会有 跳转到其他页面的需求
export default class MultiLineText extends React.Component {

    /* <Tooltip placement="top" title={text}>
                {text.slice(0, 20)}...
              </Tooltip> */

    render() {
        let body = <div
             className="my_multi"
            style={{ WebkitLineClamp: this.props.line_number + '' }}
        > {this.props.value ? this.props.value : ''}
        </div>;

        if (this.props.showTips && this.props.value && this.props.value.length > 0) {
            return (<Tooltip  placement="top" title={this.props.value}>  {body}</Tooltip >);

        } else {
            return body;
        }
    }
}


MultiLineText.propTypes = {
    value: PropTypes.string.isRequired,
    line_number: PropTypes.number.isRequired,
    showTips: PropTypes.bool
}

MultiLineText.defaultProps = {
    line_number: 2
}