export default class BseAuthModel {
    constructor(type) {
      this.type = type;
    }
  
    initFromJson(jsonData, parent, factory) {
      this.parent = parent;
  
      this.id = jsonData.id;
      this.name = jsonData.name;
      this.isChecked = false;//初始化时 设置为false
    }
  
    convertCheckStatus(checkedArray) {
      if (checkedArray && Array.isArray(checkedArray)) {
        this.isChecked = checkedArray.indexOf(this.id) > -1;//大于-1 就是存在了
      }
    }
  
    value(valueArray) {
      if (this.isChecked) {
        valueArray.push(this.id);
      }
    }
  
    onBeforeUpdateUi() {
      // 默认 do nothing
    }
  
    isHasError() {
      return undefined;
    }
  
    /**
     * 抽象方法
     */
    render() {
      // do nothing
    }
  
    //  当用户点击时，触发
    onCheckChange = (e) => {
      let newChecked = e.target.checked;
      this.touchCheckChange(newChecked);
      this.dispatchCheckDown();// 叶子节点的实现为 空体
      this.dispatchCheckUp();// 顶级节点 则不需要再往上 传递
      this.applyHostUpdate();
    }
  
    // 通知其父节点，去更新widget。 不断的递归上传，直至最高层 节点
    applyHostUpdate() {
      if (this.parent) {
        this.parent.applyHostUpdate();
      }
    }
  
    /**
     * 用户点击 CheckBox， 对状态对应的数据 进行变更。
     * 非用户点击的Checkbox变化，不要调用这个方式
     * @param {*} checked 
     */
    touchCheckChange(checked) {
      this.isChecked = checked;
    }
  
    /**
     * set方法，仅仅更新下 checked
     * @param {*} checked 
     */
    setChecked(checked) {
      this.isChecked = checked;
    }
  
    /**
     * 自身已经change了，然后向其父节点传递自身的checked变化。
     * 
     * @param {*} checked 
     */
    dispatchCheckUp() {
      // 所有节点都可以向上传递，包括叶子节点.置于 父亲节点 是否响应，就不知道了
      if (this.parent && this.parent.onCheckPassUp) {
        this.parent.onCheckPassUp(this);
      }
    }
  
    /* 子节点已经change，通知自己。
     * 此时处理逻辑：一般是父节点 检查下自己的所有子节点
     * @param {*} checked 
     */
    onCheckPassUp(child) {
    }
  
  
    /**
    * 父节点已经change，此时父节点通知自己要服从父类的变化。 
    * 一般是子节点被强制和父节点保持一致. 而按钮类型的权限 则不响应 父类的选中事件
    * @param {*} checked 
    */
    onCheckPassDown(parent) {
    }
  
    /**
  * 向下分发自身的checked变化
  * @param {*} checked 
  */
    dispatchCheckDown() {
      // 抽象方法。  do nothing
      // ComposeAuthModel 才有实际动作
      // 叶子节点 不会有任何动作
    }
  }