import React, { Component } from 'react';
import { Breadcrumb, Tabs } from 'antd';
import './EnterpriseUpgradeList.less';
import EnterpriseUpgradeList from 'CMPT/enterpriseUpgrade/EnterpriseUpgradeList';

const { TabPane } = Tabs;
const BreadcrumbItem = Breadcrumb.Item;

class EnterpriseUpgradeLists extends Component {

    render() {

        return (

            <div className="enterprise-auth-con">
                <div className='enterprise-auth-header'>
                    <Breadcrumb>
                        <BreadcrumbItem>企业升级审批</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <div><Tabs defaultActiveKey="1"     className='tabs'>
                    <TabPane tab="待审批" key="1" className='tab' >
                        <EnterpriseUpgradeList state="0"/>   {/* 0表示待审核 */}
                    </TabPane>
                    <TabPane tab="已通过" key="2" className='tab' >
                        <EnterpriseUpgradeList state="1"/>  {/* 1 表示已通过 */}
                    </TabPane>
                    <TabPane tab="已驳回" key="3" className='tab'>
                        <EnterpriseUpgradeList state="2"/>  {/* 2表示已驳回  */}
                    </TabPane>
                </Tabs>
                </div>

            </div>


        );
    }
}

export default EnterpriseUpgradeLists;