import React, { Component } from "react";
import {Breadcrumb, Button, Form, Row, Col, Input, Radio, Modal } from "antd";
import Select from 'CMPT/common/Select'
import { API } from "@/http/api";
import http from "@/http";
import "./BackendCategoryAdd.less";
import { FormatSearch } from "CMPT/common.js";
import { Link } from 'react-router-dom';

const RadioGroup = Radio.Group
class BackendCategoryAdd extends Component {
    state = {
        cards: [],
        firstLevelCategories: [],
        secondLevelCategories: [],
        params: {
            code: null,
            isEdit: false,         
            selectedCategoryType: undefined,
            selectedFirstLevelCode: undefined,
            selectedSecondLevelCode: undefined,
            categoryName: undefined,
            remark: undefined,
            status: undefined
        },
        categoryTypes: [
            { value: 1, name: '一级类目' },
            { value: 2, name: '二级类目' },
            { value: 3, name: '三级类目' }
        ],
        submitContent: undefined,
        visible: false,
        level: undefined
    }
    componentDidMount() {
        let pathname = this.props.location.pathname
        // 编辑
        if (pathname == '/app/crm/category/backend/edit') {
            let search = this.props.history.location.search
            let result = FormatSearch(search)
            this.setState({ 
                params: {
                    ...this.state.params,
                    isEdit: true,
                    code: result.code
            } })
        }
        this.queryCrmCategoryList();
    }
    queryCrmCategoryList = () => {
        http({
            api: API.categoryTree,
            showLoading: true
        }).then(res => {
            if (res.success) {
                this.setState({
                    cards: res.data || [],
                    firstLevelCategories: res.data.map((item) => {
                        return { value: item.code, name: item.name };
                    })
                })
                if(this.state.params.isEdit) {
                    this.queryCategoryDetail(this.state.params.code)
                } 

            }
        })
    }
    queryCategoryDetail(code) {
        http({
            api: API.categoryQueryDetail,
            path: {code},
            showLoading: true
        }).then(({success, data}) => {
            if(success) {
                this.setState({
                    params: {
                        ...this.state.params,
                        selectedCategoryType: data.level,
                        selectedFirstLevelCode: data.oneLevel && data.oneLevel.code,
                        selectedSecondLevelCode: data.twoLevel && data.twoLevel.code,
                        categoryName: data.categoryName,
                        remark: data.remark,
                        status: data.status
                    },
                    level: data.level
                })
                if(data.level == 3) {
                    let secondLevelCategories = this.state.secondLevelCategories
                    let childList = this.state.cards.filter((item) => item.code == data.oneLevel.code)[0].childList
                    secondLevelCategories = childList && childList.map((item) => {
                        return { value: item.code, name: item.name };
                    })
                    this.setState({secondLevelCategories })
                }
            }
        })
    }
    selectChange = (attr, data) => {
        let params = this.state.params
        let secondLevelCategories = this.state.secondLevelCategories
        if (attr == 'selectedFirstLevelCode') {
            if(data) {
                let childList = this.state.cards.filter((item) => item.code == data)[0].childList
                secondLevelCategories = childList && childList.map((item) => {
                    return { value: item.code, name: item.name };
                })
            }       
            this.props.form.setFieldsValue({
                selectedSecondLevelCode: undefined
            })
        }
        if (attr == 'selectedCategoryType' && data == 1) {
            params.selectedFirstLevelCode = undefined;
            params.selectedSecondLevelCode = undefined;
        }

        params[attr] = data
        this.setState({ params, secondLevelCategories })
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                let data = {
                    ...values
                };
                let params = this.state.params

                let parentCode = null
                if (params.selectedCategoryType == 2) {
                    parentCode = params.selectedFirstLevelCode
                } else if (params.selectedCategoryType == 3) {
                    parentCode = params.selectedSecondLevelCode
                }

                let categoryName = data.categoryName
                let remark = data.remark || null
                let isOpen = 1
                if (params.selectedCategoryType == 3) {
                    isOpen = data.isOpen
                }
                let code = params.code

                let item = {
                    code,
                    categoryName,
                    parentCode,
                    isOpen,
                    remark
                }             
                //修改二级以及三级时，二次弹窗确认
                if(this.state.params.isEdit && (this.state.level == 2 || this.state.level == 3)) {
                    this.setState({
                        submitContent: item,
                        visible: true
                    })
                } else {
                    this.setState({
                        submitContent: item
                    }, this.submit)
                    
                }
            }
        });
    }
    submit() {
        let item = this.state.submitContent
        if(!this.state.params.isEdit) {
            delete item.code
        }
        http({
            api: this.state.params.isEdit ? API.categoryEdit : API.categoryAdd,
            successTip: this.state.params.isEdit ? "更新成功" : "添加成功！",
            data: item,
            showLoading: true
        }).then(res => {
            if (res.success) {
                this.props.history.go(-1);
            }
        })
    }
    handleOk = () => {
        this.submit()
    }
    handleCancel = () => {
        this.setState({
            visible: false
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { params, visible, level} = this.state;
        return (
            <div className="intention-list intention-content">
                <div className="intention-list-head flexbox">
                    <Breadcrumb className="intention-list-head-bread">
                        <Breadcrumb.Item>商品类目</Breadcrumb.Item>
                        <Breadcrumb.Item><Link to='/app/crm/category/backend'>后台类目</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {this.state.params.isEdit ? "编辑" : "新增"}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <Form onSubmit={this.handleSubmit}>
                    <div className="intention-add-body">
                        <div>
                            <Form.Item label="类目级别" >
                                {getFieldDecorator(
                                    "selectedCategoryType", {
                                        rules: [{
                                            required: true, message: '请选择',
                                        }],
                                        initialValue: params.selectedCategoryType
                                    }
                                )(
                                    <Select data={this.state.categoryTypes} 
                                            placeholder="请选择"  
                                            allowClear 
                                            disabled={params.isEdit}
                                            onChange={this.selectChange.bind(this, 'selectedCategoryType')} 
                                            style={{ width: '150px' }} />
                                )
                                }
                            </Form.Item>

                            {(params.selectedCategoryType == 1 || typeof(params.selectedCategoryType) == "undefined") ? null : (params.selectedCategoryType == 2 ? ((<Form.Item label="一级类目">
                                {getFieldDecorator(
                                    "selectedFirstLevelCode", {
                                        rules: [{
                                            required: true, message: '请选择',
                                        }],
                                        initialValue: params.selectedFirstLevelCode
                                    }
                                )(
                                    <Select
                                        style={{ width: "150px" }}
                                        placeholder="请选择"
                                        allowClear
                                        setFieldsValue={params.selectedFirstLevelCode}
                                        data={this.state.firstLevelCategories}
                                        onChange={this.selectChange.bind(this, 'selectedFirstLevelCode')}
                                    />
                                )
                                }
                            </Form.Item>)) : ((

                                <Row>
                                    <Col span={5}>
                                        <Form.Item label="一级类目">
                                            {getFieldDecorator(
                                                "selectedFirstLevelCode", {
                                                    rules: [{
                                                        required: true, message: '请选择',
                                                    }],
                                                    initialValue: params.selectedFirstLevelCode
                                                }
                                            )(
                                                <Select
                                                    style={{ width: "150px" }}
                                                    placeholder="请选择"
                                                    allowClear
                                                    data={this.state.firstLevelCategories}
                                                    onChange={this.selectChange.bind(this, 'selectedFirstLevelCode')}
                                                />
                                            )
                                            }
                                        </Form.Item>
                                    </Col>
                                    <Col span={5}>
                                        <Form.Item label="二级类目">
                                            {getFieldDecorator(
                                                "selectedSecondLevelCode", {
                                                    rules: [{
                                                        required: true, message: '请选择',
                                                    }],
                                                    initialValue: params.selectedSecondLevelCode
                                                }
                                            )(
                                                <Select
                                                    style={{ width: "150px" }}
                                                    placeholder="请选择"
                                                    allowClear
                                                    data={this.state.secondLevelCategories}
                                                    selectMethod={this.selectChange.bind(this, 'selectedSecondLevelCode')}
                                                />
                                            )
                                            }
                                        </Form.Item>
                                    </Col>
                                </Row>
                            )))}

                            <Form.Item label="类目名称">
                                {getFieldDecorator('categoryName', {
                                    rules: [{
                                        required: true, message: '请输入',
                                    }],
                                    initialValue: params.categoryName
                                })(
                                    <Input placeholder="请输入" maxLength={30} style={{ width: 340 }} />
                                )}
                            </Form.Item>

                            {
                                params.selectedCategoryType == 3 ? (<div>
                                    <Form.Item label="是否启用">
                                        {getFieldDecorator('isOpen', {
                                            rules: [{
                                                required: true, message: '请选择',
                                            }],
                                            initialValue: params.status
                                        })(
                                            <RadioGroup name="radiogroup">
                                                <Radio value={1} style={{ fontSize: '12px' }}>是</Radio>
                                                <Radio value={0} style={{ fontSize: '12px' }}>否</Radio>
                                            </RadioGroup>
                                        )}
                                    </Form.Item>
                                </div>) : null
                            }

                            <Form.Item label="类目描述">
                                {getFieldDecorator('remark', {
                                    initialValue: params.remark
                                })(
                                    <Input placeholder="请输入" maxLength={30} style={{ width: 340 }} />
                                )}
                            </Form.Item>
                        </div>
                    </div>
                    <div className="btn-group">
                        <Button type="primary" htmlType="submit">
                            确定
                        </Button>{" "}
                        <Button
                            type="link"
                            onClick={() =>
                                this.props.history.go(-1)
                            }
                        >
                            取消
                        </Button>
                    </div>
                </Form>
                <Modal
                    closable={false}
                    visible={visible}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    okText='确定'
                    cancelText='取消'
                    width={400}
                >
                    <p>{`该类目${level == 2 ? '及其子类目' : ''}将转移到新到类目下，涉及到商品的类目名称也会全部更新，确认修改吗？`}</p>
                </Modal>
            </div>
        );
    }
}

const BackendCategoryAddForm = Form.create({ name: "BackendCategoryAdd" })(BackendCategoryAdd);

export default BackendCategoryAddForm;
