import http from '@/http';
import { API } from '@/http/api';
import React, { Component } from 'react';
import { Modal, Select, Input, Radio, message } from "antd";

import debounce from 'lodash/debounce';
const { TextArea } = Input;
const { Option } = Select;


class Dialog extends Component {

    state = {
        supplierArray: [], //企业列表
        selectedSupplier: undefined // 选中的企业

    };

    componentDidMount() {
        this.initData();
    }

    inputSearch = (e) => {
        console.log(e, 'eeee'); 
        this.initData(e)
    }


    /**
     * 拉取下 企业数据
     */
    initData(name) {
        const config = {
            api: API.invoiceSupplierList, 
            path: { supplierName: name || 'null' } // null 表示 全部数据 

        };

        // setup 2:请求，并初步处理
        let _this = this;
        http(config).then(res => {
            if (res && res.code == 200) {
                _this.setState({
                    supplierArray: res.data
                });
            }
        });
    }

    /**
     * 下拉框选择的回调
     * @param {*} value 
     */
    changeSupplier = (value) => {
        this.setState({
            selectedSupplier: value
        });
    }

    /**
     * 执行提交
     */
    tryAdd = () => {
        // setup 1: 校验
        let { selectedSupplier } = this.state;

        // setup 2:组装参数
        const config = {
            api: API.invoiceAdd,
            path: { supplierId: selectedSupplier },
            showLoading: true
        };


        // setup 2:请求，并初步处理
        http(config).then(res => {
            if (res && res.code == 200) {
                this.props.refresh && this.props.refresh();
                this.close();
            } else {
                message.error(res.message||'操作失败');
            }
        });
    }



    /**
     * 回调父组件，关闭本身
     */
    close = () => {
        this.props.close && this.props.close();
    }


    render() {
        let { supplierArray } = this.state;
        return (
            <Modal
                visible={true}
                closable={false}
                maskClosable={false}
                width={700}
                title="添加企业"
                onCancel={this.close}
                onOk={this.tryAdd}
            >
                <div>

                    <div style={{ marginBottom: '10px' }}>
                        <span style={{ marginRight: '10px',display:'inline-block' }}>企业名称:</span>
                        <Select allowClear showSearch optionFilterProp="label" onChange={this.changeSupplier} onSearch={debounce(this.inputSearch, 1000)}
                            placeholder="企业名称" style={{ width: '600px',display:'inline-block' }}>
                            {supplierArray.map(item => (
                                <Option key={item.supplierId} value={item.supplierId} label={item.supplierName}>
                                    <span><span>{item.supplierName || ''}</span>&nbsp;&nbsp;&nbsp;<span>{item.unifiedSocialCode || ''}</span>&nbsp;&nbsp;&nbsp;<span>{(item.userName || '') + (item.userName ? '/' : "") + (item.userPhone || '')}</span></span>
                                </Option>
                            ))}
                        </Select>
                    </div>

                </div>
            </Modal>

        );
    }
}


export default Dialog;