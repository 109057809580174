import {
    AJAX_TYPE
} from '../config';

const prefix = 'userManager';

export default {
    // 角色新增
    [`${prefix}ListOld`]: {
        url: '/web/crm/user/queryManageList',
        type: AJAX_TYPE.POST
    },
    [`${prefix}List`]: {
        url: '/web/crm/user/queryRemoteUserList',
        type: AJAX_TYPE.POST
    },

    [`${prefix}SetStatus`]: {
        url: '/web/crm/user/updateUserStatus',
        type: AJAX_TYPE.POST
    },
        

    // 角色删除
    [`${prefix}Detail`]: {
        url: '/web/crm/user/manageDetail/:userCode',
        type: AJAX_TYPE.GET
    } , [`${prefix}MyLabel`]: {
        url: '/web/crm/label/getListByUserId/:userCode',
        type: AJAX_TYPE.GET
    },
    [`${prefix}Export`]: {
        url: '/web/crm/user/exportRemoteUserList',
        type: AJAX_TYPE.POST
    },
    [`${prefix}ExportAuth`]: {
        url: '/web/crm/user/exportisUser',
        type: AJAX_TYPE.GET
    }
 
}