import React, { Component } from 'react';
import {
    Select, Icon,Row, Col
} from 'antd';

const { Option, OptGroup } = Select;

export default class SelectGroup extends Component {
    // componentwillreceiveprops(){

    // }
    handle = (value) => {
        const { onChange } = this.props
        onChange(value)
    }
    render() {
        const groupHtml = this.props.data.map(item=>{
            return <OptGroup key={item.encode} label={item.name}>
                    {
                        item.zones.map(zone=>{
                            return <Option key={zone.encode} value={`${item.encode}%${zone.encode}`}>{zone.zoneNo}</Option>
                            // return <Option key={zone.encode} value={JSON.stringify({floorEncode:item.encode,zoneEncode:zone.encode})}>{zone.zoneNo}</Option>
                        })
                    }
            </OptGroup>
        })
      
        return (
            <Select value={this.props.value} mode="multiple"  style={this.props.style} onChange={this.handle}>
                {groupHtml}
             </Select>           
        )
    }
}