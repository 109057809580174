import React, { Component } from "react";
import { Breadcrumb, Button } from "antd";
import { Link } from 'react-router-dom';
import http from '@/http';
import { API } from '@/http/api';
import { convertAuthenticationToLabel } from 'CMPT/operate/customerConfig'
import { DivConvert, EnumConvert, RowTwoConvert, CustomFunctionConvert } from '@/utils/detail_convert.js'
import { VerifyTypeConfig } from "./userConfig.js";
import UseLabelManagerModal from "./UseLabelManagerModal";
import './UserInfo.less';



class UserInfo extends React.Component {
    state = {
        userCode: null,//
        datas: null,
        isShowUserLabelManager: false,
        convertMap: [
            new RowTwoConvert(new DivConvert('createTime', '创建时间'), new DivConvert('companyName', '企业名称')),
            new RowTwoConvert(new DivConvert('mobile', '手机号'), new DivConvert('shortName', '企业简称')),
            new RowTwoConvert(new DivConvert('name', '姓名'), new CustomFunctionConvert('authenticationTo', '企业类型', convertAuthenticationToLabel)),
            new RowTwoConvert(new DivConvert('nickName', '昵称'), new DivConvert('email', '邮箱')/*new EnumConvert('vertifyStatus', '核验状态', VerifyTypeConfig)*/),
            new RowTwoConvert( new DivConvert('identityNumber', '身份证'),new DivConvert('labelName', '标签'))
        ]

    };


    onRefresh=()=>{
        this.getDetail();
    }

    getDetail() {
        let dataParams = { userCode: this.state.userCode };
        const config = {
            api: API.userManagerDetail,
            path: dataParams,
            showLoading: true
        };
        http(config).then(res => {
            if (res && res.success && res.data) {
                if(!res.data.labelName){
                    res.data.labelName='无';
                }

                if(!res.data.vertifyStatus){
                    res.data.vertifyStatus='2';
                }

                

                this.setState({
                    datas: res.data
                });
            }

        });
    };

    componentDidMount() {
        this.state.userCode = this.props.match.params.userCode;
        this.getDetail();
    }

    showLabelManager = () => {
        this.setState({
            isShowUserLabelManager: true
        });
    }

    closeLabelManager = () => {
        this.setState({
            isShowUserLabelManager: false
        });
    }


    render() {
        let { isShowUserLabelManager, userCode, datas, convertMap } = this.state;

        return <div className="enterprise-auth-con">
            <div className='enterprise-auth-header'>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link style={{ color: '#333' }} to={{ pathname: '/app/crm/user' }} >
                            用户管理
                            </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        查看详情
                        </Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className='content_box'>
                {
                    datas && convertMap.map((item, index) => {
                        return item.convert(datas, index);
                    })
                }

                <Button
                    type="primary"
                    style={{ marginTop: "20px" }}
                    onClick={this.showLabelManager.bind(this)}
                >
                    标签管理
                  </Button>

            </div>
            {userCode && isShowUserLabelManager ?
                <UseLabelManagerModal
                    userCode={userCode}
                    userName={datas['name']}
                    userPhone={datas['mobile']}
                    onRefresh={this.onRefresh}
                    onCancel={this.closeLabelManager} /> : ''}

        </div>;
    }
}


export default UserInfo;