import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import "./EnterpriseAccountOpeningDetail.less";
import { API } from "@/http/api";
import http from "@/http";
import { Breadcrumb, Button, Modal, Input, message, Select, Spin } from "antd";
import IMGurl from "@/images/empty_schedule@2x.png";
import { showErrorMessage } from "@/utils/globalMessageReminder";
import { formatLinkSearch } from "@/utils";
import moment from "moment";
import ImgPreview from 'CMPT/common/ImgPreview';

const { TextArea } = Input;
const { Option } = Select;

const merchantInfoData = [
  // 商户信息
  { label: "商户名称", field: "name" },
  { label: "商户统一社会信用代码", field: "unifiedSocialCode" },
  { label: "商户简称", field: "shortName" },
  { label: "企业类型", field: "signTypeName" },
  { label: "经营地址", field: "address" },
];

const legalInfoData = [
  // 法人信息
  { label: "法人姓名", field: "legalName" },
  { label: "法人证件类型", field: "legalLicenceTypeName" },
  { label: "法人证件号", field: "legalLicenceNo" },
];

const contactInfoData = [
  // 联系人信息
  { label: "联系人姓名", field: "contactName" },
  { label: "联系人证件号", field: "contactLicenceNo" },
  { label: "联系人手机号", field: "contactMobile" },
  { label: "联系人邮箱", field: "contactEmail" },
  { label: "客服电话", field: "servicePhone" },
];

const settlementAccount = [
  // 结算账户
  { label: "银行账户类型", field: "bankAccountTypeName" },
  { label: "开户总行", field: "bankCodeName" },
  { label: "银行账户号码", field: "bankCardNo" },
];

const openAccountPersonal = [
  { label: "姓名", field: "signName" },
  { label: "手机号", field: "mobile" },
  { label: "收款页简称", field: "shortName" },
  { label: "经营地址", field: "address" },
  { label: "证件类型", field: "legalLicenceTypeName" },
  { label: "证件号", field: "legalLicenceNo" },
];
// const commissionRate = [ // 佣金费率
//   { label: "费率方式", field: "userName" },
//   { label: "单笔百分比", field: "mobile" },
//   { label: "银行账户号码", field: "mobile" },
//   { label: "单笔固定值", field: "mobile" },
//   { label: "单笔封顶值", field: "mobile" },
// ]

class EnterpriseAccountOpeningDetail extends Component {
  state = {
    code: null, // 当前页面的ID,从前一个页面传递过来的
    pageNo: null, // 当前企业在前一个列表中的页码
    currentageCount: null, // 前一个页面中 列表内当前页的数量
    backPageNo: 1, //返回列表页面的页码
    rejectInput: "", // 驳回原因
    data: [],
    // goodsOwnerCard: null,
    showRejectConfirmFlag: false, // 是否展示 驳回 对话框
    auditRecords: [], // 审核记录
    rateMethod: [
      // 费率方式
      { name: "单笔百分比", value: "1" },
      // { name: '单笔固定值', value: 2 },
      // { name: '单笔固定值+单笔百分比', value: 3 },
      // { name: '单笔百分比+封顶值', value: 4 },
    ],
    rateType: "", // 选择费率类型
    percentRate: null, // 单笔百分比
    fixedAmount: null, // 单笔固定值
    capAmount: null, // 单笔封顶值
    previewVisible: false,
    IMGURL: "", // 缩放图
    spinning: false, // 是否为加载中
    addressStr: '',
  };
  componentWillMount() {
    this.cDetail();

    if (this.props.location.search) {
      // 获取参数
      const search = this.props.location.search;
      const formatData = formatLinkSearch(search);

      // 解析
      let code1 = this.props.match.params.code;
      let pageNo1 = null;
      let currentageCount1 = null;

      if (formatData && formatData.pageNo) {
        pageNo1 = formatData.pageNo;
      }
      if (formatData && formatData.currentPageCount) {
        currentageCount1 = formatData.currentPageCount;
      }

      // 赋值
      this.setState({
        code: code1,
        pageNo: pageNo1,
        backPageNo: pageNo1, //如果什么都不做，返回列表的原始页码即可。否则，需要判断跳转哪一页，尤其删除一个页面中的唯一一条数据
        currentageCount: currentageCount1,
      });
    }
  }
  componentDidMount() {}

  cDetail = () => {
    // console.log(this.props, 'this.props')
    const config = {
      api: API.accountDetailForCrm,
      path: { code: this.props.match.params.code },
    };
    http(config).then((res) => {
      if (res && res.code == 200) {
        let { data } = res;
        let addressStr = ''
        if (data.detailOpenAccountApplyPersonVO) {
          const { provinceName, cityName, districtName, address } = data.detailOpenAccountApplyPersonVO && data.detailOpenAccountApplyPersonVO
          // 个人-经营地址拼接字段
          addressStr = provinceName || cityName || districtName ? provinceName + cityName + districtName + address : address
        }
        this.setState({
          data,
          percentRate: data.percentRate,
          fixedAmount: data.fixedAmount,
          capAmount: data.capAmount,
          rateType: data.rateType ? String(data.rateType) : "请选择费率方式",
          addressStr,
        });
      }
    });
  };

  // 提交开户申请
  showPassAuthDialog = () => {
    const { code, percentRate, fixedAmount, capAmount, rateType } = this.state;
    if (!rateType || rateType === "请选择费率方式") {
      message.error("请选择费率方式");
      return;
    }
    if (!percentRate) {
      message.error("请输入单笔百分比");
      return;
    }
    this.setState({
      spinning: true,
    });
    let config = {
      api: API.accountSubmit,
      data: {
        code,
        // capAmount,
        // fixedAmount,
        percentRate,
        rateType,
      },
    };
    http(config).then((res) => {
      if (res.code == 200) {
        message.success("操作成功");
        this.props.history.goBack();
      } else {
        message.error(res.message);
      }

      this.setState({
        spinning: false,
      });
    });
  };

  cencel = () => {
    console.log("点击取消...");
  };
  /* start: 审核驳回的相关逻辑  */

  showRejectAuthDialog = () => {
    this.setState({ showRejectConfirmFlag: true });
  };

  // 设置图片
  closeImagePreview = () => {
    this.setState({ previewVisible: false });
  };
  // // 设置图片缩放
  // cImage = (value) => {
  //   if (!value) return;
  //   this.setState({
  //     previewVisible: true,
  //     IMGURL: value,
  //   });
  // };
  // 设置图片缩放
  cImage = (value, currentIndex = 0) => {
    if (!value) return;
    this.setState({
      previewVisible: true,
      // IMGURL: value,
      imgList: [value],
      currentIndex: currentIndex
    });
  };
  cSelect = (value) => {
    console.log(value, "value");
    this.setState({
      rateType: value,
    });
  };

  cInput1 = (e) => {
    e.persist();

    const { value } = e.target;
    const reg = /^-?[0-9]*(\.[0-9]*)?$/;
    if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
      this.setState({
        percentRate: value,
      });
    }
  };
  cInput2 = (e) => {
    this.setState({
      fixedAmount: Number(e.target.value),
    });
  };
  cInput3 = (e) => {
    this.setState({
      capAmount: Number(e.target.value),
    });
  };
  render() {
    const {
      IMGURL,
      data,
      rateType,
      rateMethod,
      percentRate,
      fixedAmount,
      capAmount,
      previewVisible,
      spinning,
      addressStr,
      currentIndex,
      imgList
    } = this.state;
    return (
      <div className="accountInfo">
        <Spin className="spin" spinning={spinning}>
          <div>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link
                  to={{ pathname: "/app/crm/account_open", search: "?tab=1" }}
                >
                  商户开户管理
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>开户详情</Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <div className="userInfo_body">
            {data.openAccountStatus == 3 ? (
              <div className="reject">
                <div className="reason">
                  驳回原因：
                  {data.yeePayRejectionReason
                    ? data.yeePayRejectionReason
                    : "--"}
                </div>
                <div className="date">
                  驳回时间：
                  {data.yeePayRejectionDate ? data.yeePayRejectionDate : "--"}
                </div>
              </div>
            ) : null}
            {/* 企业 */}
            {data.openAccountType === 1 && (
              <div>
                <div>
                  <h2>
                    <i></i> 商户信息
                  </h2>
                  <div>
                    <ul>
                      {merchantInfoData &&
                        merchantInfoData.map((item) => {
                          return (
                            <li key={item.field}>
                              <span>{item.label}</span>
                              <span className="span">{data[item.field]}</span>
                            </li>
                          );
                        })}
                      <li className="body_img">
                        <span>商户营业执照</span>
                        <img
                          src={
                            data.userBusinessLicenseList.length &&
                            data.userBusinessLicenseList[0].url
                          }
                          alt=""
                          onClick={() =>
                            this.cImage(data.userBusinessLicenseList[0].url)
                          }
                        />
                      </li>
                    </ul>
                  </div>
                </div>
                <div>
                  <h2>
                    <i></i> 法人信息
                  </h2>
                  <div>
                    <ul>
                      {legalInfoData &&
                        legalInfoData.map((item) => {
                          return (
                            <li key={item.field}>
                              <span>{item.label}</span>
                              <span className="span">{data[item.field]}</span>
                            </li>
                          );
                        })}
                      <li className="body_img">
                        <span>证件正面照</span>
                        <img
                          src={
                            data.legalLicencePhotoFrontList.length &&
                            data.legalLicencePhotoFrontList[0].url
                          }
                          alt=""
                          onClick={() =>
                            this.cImage(data.legalLicencePhotoFrontList[0].url)
                          }
                        />
                      </li>
                      {data.legalLicenceTypeName == "护照" ? null : (
                        <li className="body_img">
                          <span>证件背面照</span>
                          <img
                            src={
                              data.legalLicencePhotoBackList.length &&
                              data.legalLicencePhotoBackList[0].url
                            }
                            alt=""
                            onClick={() =>
                              this.cImage(data.legalLicencePhotoBackList[0].url)
                            }
                          />
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
                <div>
                  <h2>
                    <i></i> 联系人信息
                  </h2>
                  <div>
                    <ul>
                      {contactInfoData &&
                        contactInfoData.map((item) => {
                          return (
                            <li key={item.field}>
                              <span>{item.label}</span>
                              <span className="span">{data[item.field]}</span>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
                <div>
                  <h2>
                    <i></i> 结算账户
                  </h2>
                  <div>
                    <ul>
                      {settlementAccount &&
                        settlementAccount.map((item) => {
                          return (
                            <li key={item.field}>
                              <span>{item.label}</span>
                              <span className="span">{data[item.field]}</span>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              </div>
            )}
            {/* 个人 */}
            {data.openAccountType === 0 && (
              <div>
                <div>
                  <h2>
                    <i></i>开户信息
                  </h2>
                  <div>
                    <ul>
                      {openAccountPersonal &&
                        openAccountPersonal.map((item) => {
                          return (
                            <li key={item.field}>
                              <span>{item.label}</span>
                              <span className="span">
                                {item.field === "address"
                                  ? addressStr
                                  : data.detailOpenAccountApplyPersonVO[
                                      item.field
                                    ]}
                              </span>
                            </li>
                          );
                        })}
                      <li className="body_img">
                        <span>证件正面照</span>
                        <img
                          src={
                            data.detailOpenAccountApplyPersonVO
                              .legalLicencePhotoFrontList[0] &&
                            data.detailOpenAccountApplyPersonVO
                              .legalLicencePhotoFrontList[0].url
                          }
                          alt=""
                          onClick={() =>
                            this.cImage(
                              data.detailOpenAccountApplyPersonVO
                                .legalLicencePhotoFrontList[0].url
                            )
                          }
                        />
                      </li>
                      {data.detailOpenAccountApplyPersonVO
                        .legalLicenceTypeName == "护照" ? null : (
                        <li className="body_img">
                          <span>证件背面照</span>
                          <img
                            src={
                              data.detailOpenAccountApplyPersonVO
                                .legalLicencePhotoBackList[0] &&
                              data.detailOpenAccountApplyPersonVO
                                .legalLicencePhotoBackList[0].url
                            }
                            alt=""
                            onClick={() =>
                              this.cImage(
                                data.detailOpenAccountApplyPersonVO
                                  .legalLicencePhotoBackList[0].url
                              )
                            }
                          />
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
                <div>
                  <h2>
                    <i></i> 结算账户
                  </h2>
                  <div>
                    <ul>
                      {settlementAccount &&
                        settlementAccount.map((item) => {
                          return (
                            <li key={item.field}>
                              <span>{item.label}</span>
                              <span className="span">
                                {
                                  data.detailOpenAccountApplyPersonVO[
                                    item.field
                                  ]
                                }
                              </span>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              </div>
            )}

            <div>
              <h2>
                <i></i> 佣金费率
              </h2>
              <div>
                <ul>
                  <li>
                    <span className="money_span">费率方式</span>
                    <span>
                      <Select
                        allowClear
                        value={rateType}
                        placeholder="请选择费率方式"
                        disabled={data.openAccountStatus == 0 ? false : true}
                        style={{ width: "200px", fontSize: "13px" }}
                        onChange={this.cSelect}
                      >
                        {/* {rateMethod.map(item => (
                        <Option key={item.value} value={item.value}>
                          {item.name}
                        </Option>
                        ))} */}
                        <Option value="1">单笔百分比</Option>
                      </Select>
                    </span>
                  </li>
                  <li>
                    <span className="money_span">单笔百分比</span>
                    <span>
                      <Input
                        style={{ width: "200px" }}
                        placeholder="请输入单笔百分比"
                        value={percentRate}
                        onChange={this.cInput1}
                        disabled={data.openAccountStatus == 0 ? false : true}
                      />
                    </span>
                  </li>
                  {/* <li>
                  <span className="money_span">单笔固定值</span>
                  <span>
                    <Input
                      style={{ width: '200px' }}
                      placeholder='请输入单笔固定值'
                      value={fixedAmount}
                      onChange={this.cInput2}
                      disabled={data.openAccountStatus == 0 ? false : true}
                    />
                  </span>
                </li>
                <li>
                  <span className="money_span">单笔封顶值</span>
                  <span>
                    <Input
                      style={{ width: '200px' }}
                      placeholder='请输入单笔封顶值'
                      value={capAmount}
                      onChange={this.cInput3}
                      disabled={data.openAccountStatus == 0 ? false : true}
                    />
                  </span>
                </li> */}
                </ul>
              </div>
            </div>
          </div>
          {data.openAccountStatus == 0 ? (
            <div className="detail_btn">
              <Button
                type="primary"
                onClick={this.showPassAuthDialog.bind(this)}
              >
                {" "}
                提交开户申请{" "}
              </Button>
              <Button onClick={() => this.props.history.goBack()}>
                {" "}
                取消{" "}
              </Button>
            </div>
          ) : data.openAccountStatus == 2 || data.openAccountStatus == 3 ? (
            <div className="detail_btn">
              <Button onClick={() => this.props.history.goBack()}>
                {" "}
                返回{" "}
              </Button>
            </div>
          ) : null}
        </Spin>
        {/* <Modal
          visible={previewVisible}
          title=" "
          footer={null}
          onCancel={this.closeImagePreview}
        >
          <img
            alt="图片预览"
            style={{ width: "100%", margin: "0 0 24px" }}
            src={IMGURL}
          />
        </Modal> */}
        <ImgPreview show={previewVisible} current={currentIndex} imgList={imgList} onClose={this.closeImagePreview}/>

      </div>
    );
  }
}
export default EnterpriseAccountOpeningDetail;
