import React, { Component , createRef} from 'react';
import { Breadcrumb, Input, Form,Button, message } from 'antd';
import Select from "CMPT/common/Select";
import http from '@/http';
import { API } from '@/http/api';
import { Link } from 'react-router-dom';
import { FormatSearch } from "CMPT/common.js";
//引入wangeditor
import Editor from "CMPT/common/widget/Editor";

import './addOreditAnnouncement.less';
import { listen } from 'ol/events';

const BreadcrumbItem = Breadcrumb.Item;
const Option = Select.Option

class addOreditAnnouncement extends Component {
    state = {
        messageContents: null,
        afficheTypeId: undefined,
        afficheType: undefined,
        afficheTypeList: [],
        title:undefined,
        encode:undefined,
        id:undefined,
    }
    //初始化
    componentDidMount() {
        this.getAfficheType("")
        // 获取 参数type
        let search = this.props.history.location.search;
        var result = FormatSearch(search);
        // 赋值
        this.setState({
            encode: result.type,
            id:result.id
        },()=>result.type =="edit" ?this.getDetile() : '' );
        
    }

    // 如果是编辑进来，显示数据
    getDetile(){
        const config = {
            api: API.AnnouncementInfo,
            path:{
                id:this.state.id
            }
        };
        console.log(config);
        http(config).then(res => {
            if (res.code == 0) {
                console.log(res.data);
                this.setState({
                    title:res.data.title,
                    afficheTypeId:res.data.afficheTypeId,
                    afficheType:res.data.afficheTypeName,
                    messageContents:res.data.content
                });
                console.log("this.state",this.state);
            }else{
                // message.error(res.msg);
            }
                
        });
    }

    // 下拉选择绑定
    handleChangeStatus = e => {
        this.setState({
          afficheTypeId: e
        });
    };


    // 获取公告类型下拉
    getAfficheType(value) {
        const config = {
            api: API.AnnouncementTypelist,
            data:{
                name:value,
                type:0,
            }
        };
        http(config).then(res => {
            if (res.code == 0) {
                let data = res.data.map(item => {
                    item.name = item.typeName;
                    item.value = item.id;
                    return item;
                });
                this.setState({
                    afficheTypeList: data
                })
            }
        });
    }

    // 新增提交
    addSubmit(values){
        console.log("走add",values);
        let from = {}
        console.log(from);
        from.content=values.messageContents
        from.title=values.title
        from.type=0
        from.id=0
        from.afficheTypeId=values.afficheType
          const config = {
            api: API.AnnouncementInsert,
            data: from
          };
        http(config).then(res => {
            if (res.code == 0) {
                message.success('新增公告成功！');
                this.props.history.push("/app/crm/mallsetting/platformAnnouncement")
            }else{
                // message.error(res.msg);
            }
        });
    }
    // 修改提交
    editSubmit(values){
        console.log("走edit",this.state.afficheTypeId);
        let from = {}
        from.content=values.messageContents
        from.title=values.title
        from.type = 0
        from.id = this.state.id
        from.afficheTypeId=this.state.afficheTypeId
        const config = {
            api: API.AnnouncementEdit,
            data: from
        };
        console.log(from);
        http(config).then(res => {
            if (res.code == 0) {
                message.success('公告修改成功！');
                this.props.history.push("/app/crm/mallsetting/platformAnnouncement")
            }else{
                // message.error(res.msg);
            }
        });
    }
    // 表单提交
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
          if (!err) {
            if(this.state.encode =="add"){
                this.addSubmit(values);
            }
            if(this.state.encode =="edit"){
                this.editSubmit(values);
            }
          }
        });
    };

    htmlChange = msgContent => {
      this.state.messageContents = msgContent;
    };

    onSearch = (value) => {
        console.log(value);
        this.getAfficheType(value)
      };

    // 判断公告内容是否为空
    checkEditor = (rule, value) => {
        if (this.state.messageContents) {
          return Promise.resolve();
        }
        return Promise.reject("请填写公告内容");
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { afficheType,title } = this.state;
        const typeVer = {
            rules: [{ required: true, message: "请选择" }],
            initialValue: afficheType
        };
        const titleVer = {
            rules: [{ required: true, max: 50, message: "不得超过50字"  }],
            initialValue: title
        };
        return (
            <div className="channel-nav-con">
                <div className='channel-nav-header'>
                    <Breadcrumb>
                        <BreadcrumbItem>平台公告</BreadcrumbItem>
                        <BreadcrumbItem>
                            <Link
                                to={{
                                    pathname: "/app/crm/mallsetting/platformAnnouncement",
                                }}
                            >
                                公告发布
                            </Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem>{ this.state.encode == "add" ? "新增公告" : "编辑公告" } </BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <div className="channel-nav-list">
                    <Form onSubmit={this.handleSubmit} className='set_form'
                        layout="vertical"
                    >   
                        <Form.Item label="公告标题">
                            {getFieldDecorator("title", titleVer)(
                                <Input
                                    style={{ width: "300px" }}
                                    maxLength={50}
                                    placeholder="请输入公告标题(最多50字)"
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="公告类型">
                        {getFieldDecorator(
                            "afficheType",typeVer)(
                            this.state.afficheTypeList ? (
                                <Select
                                showSearch
                                allowClear
                                style={{ width: "204px" }}
                                placeholder="请选择公告类型"
                                data={this.state.afficheTypeList}
                                selectMethod={this.handleChangeStatus}
                                onSearch={this.onSearch}
                                filterOption={true}
                                >
                                </Select>
                            ) : null
                        )}
                        </Form.Item>
                        <Form.Item label="公告详情">
                            {/* 使用wangeditor */}
                            {getFieldDecorator("messageContents",
                                {rules: [{ validator: this.checkEditor }]
                                })(
                                <Editor
                                    onChange={this.htmlChange}
                                    key={this.state.messageContents?'1':'0'}
                                    default={this.state.messageContents}
                                />
                            )}
                        </Form.Item>
                        <div className="btn-group">
                            <Button type="primary" htmlType="submit">
                            发布
                            </Button>{" "}
                            <Button
                                type="link"
                                onClick={() =>
                                    this.props.history.push("/app/crm/mallsetting/platformAnnouncement")
                                }
                                >
                                取消
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>


        );
    }
}

export default Form.create({ name: "addOreditAnnouncement" })(addOreditAnnouncement);
