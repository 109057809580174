import React, { Component } from "react";
import Table from 'CMPT/common/Table';
import http from '@/http';
import { API } from '@/http/api';
import { Modal, Input, message, Checkbox, Tooltip, Button } from "antd";

import PageInfo from "@/utils/pageInfo.js";

class AddSupplierModal extends Modal {
    state = {
        pageInfo: new PageInfo(),
        data: [],
        filterParms: {
            name: undefined
        },
        columns: this.createColumns(),
        checkedItem: []//切换筛选条件，需要重置这个数组
    };


    createColumns() {
        return [{
            title: '选择',
            key: "code",
            render: (text, record, index) =>
                <Checkbox
                    key={'chb_' + record.code}
                    defaultChecked={this.state.checkedItem.indexOf(record.code) >= 0}
                    onChange={e => this.switchCheck(e, record.code)} />
        }, {
            title: '供应商名称',
            dataIndex: 'companyName',
            key: 'companyName',
            render: (text, record) => {
                let value;
                if (text && text.length > 20) {
                    value = <Tooltip placement="top" title={text}>{text.slice(0, 20)}...</Tooltip>
                } else {
                    value = text
                }
                return (<span>{value ? value : '-'}</span>)
            }
        }, {
            title: '企业简称',
            dataIndex: 'shortName',
            key: 'shortName',
            render: (text, record) => {
                let value;
                if (text && text.length > 20) {
                    value = <Tooltip placement="top" title={text}>{text.slice(0, 20)}...</Tooltip>
                } else {
                    value = text
                }
                return (<span>{value ? value : '-'}</span>)
            }
        }, {
            title: '店铺名称',
            dataIndex: 'shopName',
            key: 'shopName',
            render: (text, record) => {
                let value;
                if (text && text.length > 20) {
                    value = <Tooltip placement="top" title={text}>{text.slice(0, 20)}...</Tooltip>
                } else {
                    value = text
                }
                return (<span>{value ? value : '-'}</span>)
            }
        }
        ];
    }

    componentDidMount() {
        this.queryList();
    };

    queryList() {
        let myState = this.state;
        // setup 1: 构造基本入参
        let dataParam = {
            code: this.props.promotionCode
        };

        if (myState.filterParms.name) {
            dataParam.shopName = myState.filterParms.name; // 参数shopName不单单指 店铺名称
        }


        // setup 2: 构造接口入参
        const config = {
            api: API.promotionGetListForAddPromotionShop,
            data: {
                ...dataParam,
                ...myState.pageInfo.paramForSearch()
            },
            showLoading: true
        };

        http(config).then(res => {
            if (res && res.success && res.data && res.data.datas) {
                myState.pageInfo.changeFromJson(res.data);

                this.setState({
                    data: res.data.datas
                });
            }
        });
    }


    switchCheck = (e, item) => {
        let checked = e.target.checked;
        let items = this.state.checkedItem;

        if (checked) {
            items.push(item);
        } else {
            items.remove(item);
        }
    };

    onChangePage = (pageNo, pageSize, sort) => {
        let pageInfo = this.state.pageInfo;
        pageInfo.changeForClick(pageNo, pageSize);
        this.setState({ pageInfo }, this.queryList);
    };

    queryListByName = () => {
        let pageInfo = this.state.pageInfo;
        pageInfo.resetPageInfo();

        // 把已选择列表 更新下
        this.setState({ pageInfo, checkedItem: [] }, this.queryList);
    }

    reset = () => {
        let filterParms = this.state.filterParms;
        Object.keys(filterParms).forEach(key => {
            filterParms[key] = undefined
        })

        let pageInfo = this.state.pageInfo;
        pageInfo.resetPageInfo();

        this.setState({ pageInfo, filterParms, checkedItem: [] }, this.queryList);
    }

    isShowReset = () => {
        return this.state.filterParms.name;
    }


    onChangeName = e => {
        const { value } = e.target;
        let filterParms = this.state.filterParms;
        filterParms.name = value;

        this.setState({ filterParms });
    }

    trySubmit = () => {
        if (!this.state.checkedItem || this.state.checkedItem.length < 1) {
            message.error('请至少选择一个供应商');
            return;
        }

        this.doSubmit();
    }

    doSubmit = () => {
        const config = {
            api: API.promotionAddPromotionJoinShop,
            data: {
                promotionCode: this.props.promotionCode,
                shopCodes: this.state.checkedItem
            },
            showLoading: true
        };

        http(config).then(res => {

            if (res && res.success) {

                if (this.props.onClose) {
                    this.props.onClose();
                }

                if (this.props.onRefresh) {
                    this.props.onRefresh();
                }


            }
        });

    }

    render() {
        let myState = this.state;
        return <Modal
            style={{minWidth:'800px'}}
            {...this.props}
            visible={true}
            title='添加供应商'
            onOk={this.trySubmit.bind(this)}
        >
            <div style={{ paddingTop: '5px' }}>
                <span  >{'添加(' + this.props.promotionName + ')供应商:'}</span>
                <div style={{ marginTop: '15px' }}>
                    <Input placeholder="供应商名称/企业简称/店铺名称"
                        value={myState.filterParms.name}
                        onChange={this.onChangeName}
                        style={{ width: '280px' }} />
                    <Button type="primary" onClick={this.queryListByName.bind(this)}>搜索</Button>

                    {this.isShowReset() ? <span style={{ cursor: 'pointer', marginLeft: '20px', fontSize: '12px', textDecoration: 'underline', textUnderlinePosition: 'under' }}
                        onClick={this.reset.bind(this)}>
                        重置
                     </span> : ''}
                </div>

                <Table rowKey="code"
                    columns={myState.columns}
                    {...this.state.pageInfo.paramForTable()}
                    change={this.onChangePage}
                    selfProcessData={myState.data}
                />
            </div>
        </Modal>
    }

}


export default AddSupplierModal;