import React, { Component } from "react";
import BseAuthModel from "./BseAuthModel.js";
import { ChildCheckType } from "./AuthModel.js";
/**
 * 非 叶子节点 ，都应该继承这个
 */
export default class ComposeAuthModel extends BseAuthModel {
  constructor(level) {
    super(level);
    this.childCheckType = ChildCheckType.NONE;
  }

  initFromJson(jsonData, parent, factory) {
    super.initFromJson(jsonData, parent, factory);

    // 解析children
    let childrenJson = jsonData.children;
    let childrenArray = [];
    if (childrenJson && Array.isArray(childrenJson) && childrenJson.length > 0) {
      childrenJson.map(item => {
        let child = factory.createAuthModel(item, this);
        if (child) {
          childrenArray.push(child);
        }
      });
    }

    this.children = childrenArray;
  }

  value(valueArray) {
    super.value(valueArray);
    if (this.children && this.children.length > 0) {
      this.children.map(item => {
        item.value(valueArray);
      });
    }
  }

  /**
   * 更新UI前 检查下，目前主要为了 调用 checkChildrenCheckState 20200606
   */
  onBeforeUpdateUi() {
    this.checkChildrenCheckState();

    if (this.children && this.children.length > 0) {
      this.children.map(item => {
        if (item.checkChildrenCheckState) {
          item.checkChildrenCheckState();
        }
      });
    }
  }

  // 组装其 子节点的 UI内容。目前是组装到一个 数组里
  createChildrenUiInner() {
    let result = undefined;
    if (this.children && this.children.length > 0) {
      result = [];
      this.children.map(item => {
        let resultTemp = item.render();
        if (resultTemp) {
          result.push(resultTemp);
        }
      });
    }
    return result;
  }


  // 复合节点，要检查下 子节点被选中的个数。 判断父节点的checkbox 图标如何展示
  checkChildrenCheckState() {
    let count = this.children.length;
    let countTrue = 0;

    for (var i = 0; i < count; i++) {
      let item = this.children[i];
      if (item && item.isChecked) {
        countTrue++;
      }
    }

    // 返回
    if (countTrue === 0) {//都是没有选中的
      this.childCheckType = ChildCheckType.NONE;
    } else if (countTrue === count) {//都是选中的
      this.childCheckType = ChildCheckType.ALL;
    } else {
      // 能走到这里，说明既有选中的，也有没有选中的
      this.childCheckType = ChildCheckType.BOTH;
    }
  }


  dispatchCheckDown() {
    // 向其子孙节点 分发消息：状态变更
    if (this.children && this.children.length > 0) {
      this.children.map(item => {
        if (item && item.onCheckPassDown) {
          item.onCheckPassDown(this);
        }
      });
    }
  }



  /**
   *  父节点的变化 可能是自身被点击 或者 其祖父节点状态更新导致其父节点被更新
 * 父节点变化并通知到自己。这个时候处理逻辑是： 自己服从父节点， 同时向下传递自身的checked变化，或者 不响应. 
 * @param {*} checked 
 */
  onCheckPassDown(parent) {
    this.setChecked(parent.isChecked);
    this.dispatchCheckDown();
  }


  convertCheckStatus(checkedArray) {
    super.convertCheckStatus(checkedArray);

    if (this.children) {
      this.children.map(item => {
        if (item) {
          item.convertCheckStatus(checkedArray);
        }
      });
    }

    this.checkChildrenCheckState();
  }

  isHasError() {
    // 没有错误，则判断children是否有error
    if (this.children && this.children && this.children.length > 0) {
      for (var i = 0; i < this.children.length; i++) {
        let item = this.children[i];
        if (item) {
          let error = item.isHasError();
          if (error) {
            return error;
          } else {
            continue;
          }
        }
      }
    }
    return undefined;
  }
}