import Table from "CMPT/common/Table";
import http from "@/http";
import { API } from "@/http/api";
import { formatLinkSearch } from "@/utils";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "antd";
import SearchForm from "./searchForm";
import "./index.less";
import moment from "moment";

const approveStatus = {
  0: "审核中",
  1: "审核通过",
  2: "审核驳回",
  3: "强制下架"
};

// 筛选项默认值
const defaultParams = {
  pageNo: 1, // 需要查询的页数
  pageSize: 10, // 每页条数
  auditEndTime: "", //审核结束时间
  auditStartTime: "", //审核开始时间
  //auditStatus: 0, // 审核状态 0-审核中 1-审核通过 2-审核驳回/审核不通过
  infoName: "", // 信息名称
  infoType: "", // 信息类型 0-求租 ,1-设备
  submitterId: undefined, // 提交人 
  supplierId: undefined // 所属企业
};
class VehicleAuthList extends Component {
  state = {
    total: 0, // 当前数据的总数
    pageNo: 1, // 当前所展示的页码
    currentPageCount: 0, //当前页面的数量
    selectParams: {
      ...defaultParams,
    },
    columns: [
      /*{
        title: "申请编号",
        width: "120px",
        key: "code",
        dataIndex: "code",
        render: (text, record) => {
          return <span>{text}</span>;
        },
      },*/
      {
        title: "信息名称",
        key: "infoName",
        dataIndex: "infoName",
        render: (text, record) => {
          return <div style={{wordWrap: "break-word",maxWidth:"200px"}}>{text||'-'}</div>;
        },
      },{
        title: "状态",
         key: "releaseStatusName",
        dataIndex: "releaseStatusName",
        render: (text, record) => {
          return <span>{text||'-'}</span>;
        },
      },
      {
        title: "信息类型",
        key: "infoType",
        dataIndex: "infoType",
        render: (text, record) => {
          return <span>{text === 1 ? "设备" : "求租"}</span>;
        },
      },
      {
        title: "提交人",
        key: "submitterId",
        dataIndex: "submitterId",
        render: (text, record) => {
            var account = record.submitterId;
            var name = record.createUserName;
            var value= (name||'')+(name?'/':'')+(account||'')
          return <span>{value||'--'}</span>;
        },
      },

      {
        title: "所属企业",
        key: "supplierName",
        dataIndex: "supplierName",
        render: (text, record) => {
          return <span>{text||'--'}</span>;
        },
      },

      {
        title: "提交时间",
        key: "submitTime",
        dataIndex: "submitTime",
        render: (text, record) => {
          return <span>{text||'-'}</span>;
        },
      },
      /*{
        title: "审核时间",
        key: "auditTime",
        dataIndex: "auditTime",
        render: (text, record) => {
          return <span>{text||'--'}</span>;
        },
      },
      {
        title: "审核人账号",
        key: "auditorId",
        dataIndex: "auditorId",
        render: (text, record) => {
          return <span>{text||'--'}</span>;
        },
      },*/
      {
        title: "审核状态",
        key: "auditStatus",
        dataIndex: "auditStatus",
        render: (text, record, index) => {
          return <span>{approveStatus[text]||'-'}</span>;
        },
      },
      {
        title: "操作",
        key: "operate",
        render: (text, record, index) => {
          return (
            <span className="table-operate-btn">
              <Link
                to={"/app/crm/sourcing/detail/" + record.code+ "?infoType=" + record.infoType}
                className="operate"
              >
                {record.auditStatus == 1 ? '强制下架' : '查看详情'}
              </Link>
            </span>
          );
        },
      },
    ],
    data: [],
  };

  componentWillMount() {
    if (this.props.location) {
      // 获取参数
      const search = this.props.location.search;
      const formatData = formatLinkSearch(search);

      // 解析
      let pageNo1 = null;
      if (formatData && formatData.backPageNo) {
        pageNo1 = formatData.backPageNo;
      }

      // 赋值
      this.setState({
        selectParams: {
          auditStatus: this.props.state, // 审批状态
          pageNo: pageNo1, // 当前页数
          pageSize: 10, // 每页条数
        },
      });
    }
  }

  //初始化
  componentDidMount() {
    this.init();
  }

  init = () => {
    const httpParams = { ...this.state.selectParams, infoType: this.props.infoType };
    for (let [key, value] of Object.entries(httpParams)) {
      if (value === -1 || value === "") {
        //    console.log('[key, value]',[key, value])
        httpParams[key] = undefined;
      }
    }

    // 从组件属性中提取 审核状态的属性
   const  auditStatus = this.props.auditStatus;
   if(auditStatus!=-1){// -1表示全部，我们不传 auditStatus字段即可
       httpParams.auditStatus =  auditStatus;
   }


    const config = {
      api: API.enterpriseAuthQueryRentRecord,
      data: { ...httpParams },
      showLoading: true,
    };

    http(config).then((res) => {
      if (res.code == 200) {
        const data = res.data;
        this.setState({
          data: data.datas || [],
          total: data.total,
          pageNo: data.pageNo,
          currentPageCount: data.datas ? data.datas.length : 0,
          selectParams: { ...this.state.selectParams, pageNo: data.pageNo },
        });
      }
    });
  };

  // 搜索
  onValuesChange = (allValues) => {
    let  newParams =  { ...this.state.selectParams, ...allValues };
    if(newParams.supplierId== -1){// -1表示全部。所以这里 移除这个supplierId字段。后端接口 null表示全部
      delete  newParams.supplierId  
    }

    this.setState(
      { selectParams: newParams},
    );
  };

  //分页
  onChangePage = (pageNo, pageSize, sort) => {
    this.setState(
      {
        selectParams: {
          ...this.state.selectParams,
          pageNo: pageNo,
          pageSize: pageSize,
        },
      },
      () => {
        this.init();
      }
    );
  };

  // 搜索
  /*onValuesChange = (allValues) => {
    console.log("  onValuesChange   222  " );

    this.setState({
      selectParams: { ...this.state.selectParams, ...allValues },
    });
  };*/

  // 重置
  reset = (allValues) => {
    this.setState(
      {
        selectParams: { ...this.state.selectParams, ...defaultParams },
      },
      () => {
        this.init();
      }
    );
  };

  render() {
    const selectParams = this.state.selectParams;
    return (
      <div className="enterprise-auth-list">
        <div className="searchBox">
          <SearchForm
            onValuesChange={this.onValuesChange}
            types={false}
            reset={this.reset}
            onSearch={() => {
              this.init();
            }}
          />
        </div>
        <Table
          columns={this.state.columns}
          rowKey={(record, index) => record.code}
          selfProcessData={this.state.data}
          total={+this.state.total}
          change={this.onChangePage}
          current={selectParams.pageNo}
        />
      </div>
    );
  }
}

export default VehicleAuthList;
