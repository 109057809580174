import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Breadcrumb, Button, Icon, Radio, Dropdown, Modal,Table } from 'antd';
import http from '@/http';
import { API } from '@/http/api';
import { AUTH_KEYS } from '@/utils/auth';
import userUtil from '@/utils/user';
import useActionLoading from 'CMPT/common/hooks/useActionLoading';
import useOuterSide from 'CMPT/common/hooks/useOuterSide';
import './SettingApproveConfig.less';

const BreadcrumbItem = Breadcrumb.Item;

export default function SettingApproveConfig() {
    const [data, setData] = useState([]);

    const [columns, setColumns] = useState([]);

    const [showConfirm, toggleConfirm] = useState(false);
    const [loading, toggleLoading] = useState(false);

    const confirmOkRef = useRef();

    useEffect(() => {
        http({
            api: API.flowTemplateConfigList,
            showLoading: true
        }).then(ajaxData => {
            const data = ajaxData.data || [];
            setData(data);
            getColumns(data.length, data);
        });
    }, []);

    function getColumns(rowLength, data) {
        const keyMap = {
            '收支审批': { dataIndex: 'balance' },
            '账单审批': { dataIndex: 'bill' },
            '合同审批': { dataIndex: 'contract' },
            '合同模板审批': { dataIndex: 'contractTemplate' },
            '作废合同审批': { dataIndex: 'invalid' },
            '退款审批': { dataIndex: 'refund' },
            '收据模板审批': { dataIndex: 'receiptTemplate' },
            '退租审批': { dataIndex: 'surrender' },
            '供应商审批': { dataIndex: 'supplier' },
        }

        if (rowLength === 1) {
            const { project } = data[0];
            if (project && !project.name) {
                const columns = [{
                    title: '供应商审批',
                    dataIndex: keyMap['供应商审批'].dataIndex,
                    render(text) {
                        return <ManageSelect type={10} defaultSelected={text} displayConfirm={displayConfirm} />
                    }
                }];
                setColumns(columns);
                return;
            }
        }

        http({
            api: API.flowTemplateConfigInit,
            showLoading: true
        }).then(ajaxData => {
            if (ajaxData.data) {
                const types = ajaxData.data.type || [];
                types.forEach(({ key, value }) => {
                    if (keyMap[value]) {
                        keyMap[value].type = key;
                    }
                });
                const columns = Object.entries(keyMap).map(([title, config]) => {
                    let render = (text, row, index) => {
                        const project = row.project;
                        return <ManageSelect projectId={project && project.id} type={config.type} defaultSelected={text} displayConfirm={displayConfirm} />;
                    };
                    if (title === '供应商审批') {
                        render = (text, row, index) => {
                            if (index) {
                                return {
                                    props: { rowSpan: 0 },
                                };
                            } else {
                                return {
                                    props: { rowSpan: rowLength },
                                    children: <ManageSelect type={config.type} defaultSelected={text} displayConfirm={displayConfirm} />
                                }
                            }
                        }
                    }
                    return {
                        title,
                        dataIndex: config.dataIndex,
                        render
                    }
                });
                columns.unshift({
                    dataIndex: 'project',
                    render(project) {
                        return project && project.name;
                    }
                });
                setColumns(columns);
            } else {
                setColumns([]);
            }
        });
    }

    function displayConfirm(methods) {
        toggleConfirm(true)
        confirmOkRef.current = methods;
    }

    function confirm() {
        toggleLoading(true);
        confirmOkRef.current.onOk().finally(() => {
            toggleLoading(false);
            toggleConfirm(false);
        });
    }

    function hideConfirm() {
        toggleConfirm(false);
        confirmOkRef.current.onCancel();
    }

    const isOnlySupplier = columns.length === 1;

    return (
        <div className='setting-approve-config'>
            <Breadcrumb>
                <BreadcrumbItem>审批管理</BreadcrumbItem>
                <BreadcrumbItem>审批模板配置</BreadcrumbItem>
            </Breadcrumb>
            <div className={'config-main ' + (isOnlySupplier ? 'only-supplier' : '')}>
                <div className='config-info'><i className='icon iconfont iconinformation1'/>修改后对于进行中的审批工单将按原审批模板继续流转，新建的审批工单将按照新模板执行</div>
                <Table dataSource={data} columns={columns} pagination={false} rowKey='name' bordered />
            </div>
            <Modal
                title="设置为不需审批后，新增的【收支项/账单/合同/合同模板/作废合同/退款/收据模板/退租/供应商】将变为即时生效，审批驳回的记录将不可再编辑，是否确定？"
                visible={showConfirm}
                confirmLoading={loading}
                okText='确认更改'
                closable={false}
                width={400}
                maskClosable={false}
                onOk={confirm}
                onCancel={hideConfirm}
            />
        </div>
    );
}

function ManageSelect({ type, projectId, defaultSelected, displayConfirm }) {
    const [visible, toggleVisible] = useState(false);
    const [value, setValue] = useState(getDefaultValue(defaultSelected));
    const [confirmValue, setConfirmValue] = useState(getDefaultValue(defaultSelected));
    const [options, setOptions] = useState([]);
    const [loading, takeConfigAction] = useActionLoading();
    const clickOuterSide = useCallback(function clickOuterSide(e) {
        toggleVisible(false);
    }, [toggleVisible]);
    const [triggerRef, overlayRef] = useOuterSide(visible, clickOuterSide);

    useEffect(() => {
        if (visible) {
            http({
                api: API.flowTemplateQueryConfig,
                showLoading: true,
                data: {
                    type,
                    projectId
                }
            }).then(ajaxData => {
                const data = ajaxData.data || [];
                data.unshift({
                    encode: '',
                    name: '不需审批'
                });
                setOptions(data);
            });
        }
    }, [visible]);

    function toggleShow() {
        if (userUtil.checkAuthByKey(AUTH_KEYS.SETTING_APPROVE_CONFIG)) {
            toggleVisible(!visible);
        }
    }

    function confirm() {
        if (value !== confirmValue) {
            if (value === '') {
                toggleVisible(false);
                displayConfirm({ onOk: configApprove, onCancel: cancel });
            } else {
                configApprove(value);
            }
        } else {
            toggleVisible(false);
        }
    }

    function configApprove() {
        return takeConfigAction({
            api: API.flowTemplateConfiguration,
            showLoading: true,
            data: {
                oldEncode: confirmValue,
                newEncode: value,
                projectId,
                type
            },
            successTip: '配置成功'
        }, () => {
            visible && toggleVisible(false);
            setConfirmValue(value);
        });
    }

    function cancel() {
        setValue(confirmValue);
        visible && toggleVisible(false);
    }

    function change(e) {
        setValue(e.target.value);
    }

    return (<Dropdown overlay={<div className='approve-config-select' ref={overlayRef} >
        <Radio.Group value={value} onChange={change}>
            {
                options.map(({ encode, name }) => (<Radio value={encode} key={encode}>{name}</Radio>))
            }
        </Radio.Group>
        <div className='approve-config-select-operate'>
            <Button size='small' type='primary' onClick={confirm} loading={loading}>确认</Button>
            <Button size='small' onClick={cancel}>取消</Button>
        </div>
    </div>} trigger={['click']} visible={visible}>
        <span onClick={toggleShow} ref={triggerRef} className={confirmValue && 'approve-config-highlight'}>{getValueDesc(options, confirmValue, defaultSelected)}</span>
    </Dropdown>);
}

function getDefaultValue(defaultSelected) {
    if (defaultSelected) {
        return defaultSelected.encode;
    }
    return '';
}

function getValueDesc(options, value, defaultSelected) {
    for (const { encode, name } of options) {
        if (encode === value) {
            return name;
        }
    }

    if (defaultSelected) {
        return defaultSelected.name;
    }

    return '不需审批';
}