import React, { Component } from 'react';
import InexTable from './InexTable';
import InexTableStatic from './InexTableStatic';
import { deepclone } from '@/utils'
import moment from 'moment';

import { Row, Icon } from 'antd';
class IncomeExpense extends Component {
    state = {
        params: {
            bpid: '1', // 1收 2付
        },
        contractData: [], // rentType 资金类型 1 租金；2 保证金； 3 免租期前， 4 免租期后;  feiType 费用类型，取自字典 1 租金类型；2 保证金类型
        contractOriginData: [],
        estateData: [],
        estateOriginData: [],
        contractCopy: [], // 租客合同备份
        estateCopy: [], // 物业合同备份
        isFloder: false,
        estateIsFolder: false,
        rowClass: 'contract-row-class',
        estateRowClass: 'estate-row-class',
        showHeader: true,
        estateRef: '',
        contractRef: ''
    }
    // TODO: 物业合同也需要备份
    componentDidMount() {
        const { info } = this.props;
        console.log(info, 'infofff')
        const { contractInexList  } = this.props.contractInexData
        const { estateInexList } = this.props.estateInexData
        const contractCopy = deepclone(contractInexList)
        const estateCopy = deepclone(estateInexList)
        const type = this.props.type
        this.setState({
            contractData: contractInexList,
            contractCopy,
            estateData: estateInexList,
            estateCopy,
            showHeader: type === 1,
            contractRef: React.createRef(),
            estateRef: React.createRef(),
        })
    }
    getContractData = () => {
        const $ref = this.state.contractRef.current
        return $ref.state.rentData
    }
    getEstateData = () => {
        const $ref = this.state.estateRef.current
        return $ref.state.rentData
    }
    // 重置
    resetContract = (type) => {
        if (type === 'contract') {
            const contractCopy = deepclone(this.state.contractCopy)
            this.setState({
                contractData: contractCopy
            })
        } else {
            const estateCopy = deepclone(this.state.estateCopy)
            this.setState({
                estateData: estateCopy
            })
        }
    }
    folderTable = (type) => {
        const { rowClass, estateRowClass } = this.state
        const name = 'contract-table-row-hide'
        let className = type === 'isFloder' ? rowClass : estateRowClass
        let allClasses = document.getElementsByClassName(className)
        if (!this.state[type]) {
            for (let i = 0; i < allClasses.length; i++) {
                allClasses[i].classList.add(name)
            }
        } else {
            for (let i = 0; i < allClasses.length; i++) {
                allClasses[i].classList.remove(name)
            }
        }
        this.setState({
            [type]: !this.state[type]
        })
    }
    render() {
        const { contractInexOriginList, contractPriceByYear  } = this.props.contractInexData
        const { estateInexOriginList, estatePriceByYear } = this.props.estateInexData
        const { decision } = this.props
        let contractAlterCom = null
        let estateAlterCom = null
        if (this.props.type === 3 || this.props.type === 2) {
            contractAlterCom = <div>
                <InexTableStatic tableData={contractInexOriginList} rowClassName={this.state.rowClass} />
                <div className="contract-table-arrow" onClick={this.folderTable.bind(this, 'isFloder')}>
                    {this.state.isFloder ? '展开' : '收起'}历史收付<Icon type={this.state.isFloder ? 'down' : 'up'} />
                </div>
            </div>
            estateAlterCom = <div>
                <InexTableStatic tableData={estateInexOriginList} rowClassName={this.state.estateRowClass} />
                <div className="contract-table-arrow" onClick={this.folderTable.bind(this, 'estateIsFolder')}>
                    {this.state.estateIsFolder ? '展开' : '收起'}历史收付<Icon type={this.state.estateIsFolder ? 'down' : 'up'} />
                </div>
            </div>
        }
        return (
            <div className="contract-inex-page">
                <div className="contract-table-detail">
                    <div className="contract-block contract-inex-body">
                        <Row className="info-title">
                            <span>租赁收付明细</span>
                            <span className="info-title-btn" onClick={this.resetContract.bind(this, 'contract')}>
                                <Icon type="reload" style={{color: '#FBBC06', fontSize: '14px'}} />
                                恢复默认
                            </span>
                        </Row>
                        {contractAlterCom}
                        <InexTable ref={this.state.contractRef} tableData={this.state.contractData} showHeader={this.state.showHeader} decision={decision}
                                   contractPriceList={contractPriceByYear} init={this.props.init} type="contract" />
                    </div>
                    <div className="contract-block contract-inex-body">
                        <Row className="info-title">
                            <span>物业收付明细</span>
                            <span className="info-title-btn" onClick={this.resetContract.bind(this, 'estate')}>
                                <Icon type="reload" style={{color: '#FBBC06', fontSize: '14px'}} />
                                恢复默认
                            </span>
                        </Row>
                        {estateAlterCom}
                        <InexTable ref={this.state.estateRef} tableData={this.state.estateData} showHeader={this.state.showHeader} decision={decision}
                                   contractPriceList={estatePriceByYear} init={this.props.init} type="estate" />
                    </div>
                </div>
            </div>
        );
    }
}

export default IncomeExpense;