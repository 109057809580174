import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import ISelect from 'CMPT/common/Select';
import Table from 'CMPT/common/Table';
import http from '@/http';
import { API } from '@/http/api';
import { Input, Row, Col, DatePicker, Icon, Select ,Spin,message,Modal,Button} from 'antd';
import "../financeApproval/finance.less"
const { RangePicker } = DatePicker;
const Option = Select.Option;
const { TextArea } = Input;
const payChannel = ['现金','刷卡','转账','支付宝','微信','','','','其他','','','','','','']

class CandPDetail extends Component {
    state = {
        showModal:false,
        detail:{},
        textDes:{
            t1:'收',
            t2:'付'
        },
        paymentInfo:{}
    }
    componentDidMount(){
        // this.getHisInfo();

    }
    componentWillMount(){
        let type = this.props.location.search.substr(1).split('=')[1];
        if(type == 1){
            this.setState({
                textDes:{
                    t1:'收',
                    t2:'付'
                }
            })
        }
        else if(type == 2){
            this.setState({
                textDes:{
                    t1:'付',
                    t2:'收'
                }
            })
        }
        else{
            this.props.history.push({pathname:'/404'});
            console.log('404')
        }
    }
    getPaymentInfo(){
        http({
            api: API.billpayGetById,
            data:{
                encode:this.props.encode
            }
        }).then(res => {
            if(res.data){
                this.setState({
                    paymentInfo:res.data
                })
            }           
        })
    }
    getHisInfo(){
        http({
            // api:API.billpayGetPaymentList,
            data:{
                encode:this.props.encode
            }
        }).then((res)=>{
            this.setState({
                detail:res.data
            })
        })
    }
    hisCancel(){
        this.setState({
            showModal:false
        })
    }
    showHis(){
        this.setState({
            showModal:true
        })
        this.getPaymentInfo();
    }
    render(){
        const {textDes,paymentInfo} = this.state;
        // paymentInfo.photos = [{
        //     mediaExt: "jpeg",
        //     mediaKey: "122320490483590324",
        //     name: "timg.jpeg" ,
        //     type: "jpeg" ,
        //     url: "http://30.76.226.204:8286/node/download/view/public/show/122320490483590324"}
        // ]
        const ImgList = paymentInfo.photos && paymentInfo.photos.map(obj=>{
            return (
                <img src={obj.url} key={obj.mediaKey}/>
            )
        })
        return (
            <div>
                <Modal
                    className="showModal"
                    title='收付款详情'
                    closable={false}
                    visible={this.state.showModal}
                    width={800}
                    height={477}
                    footer={null}
                    closable
                    maskClosable
                    onCancel={this.hisCancel.bind(this)}
                >     
                    <div className="fs12 cx-his-modal">
                        <div className="approval-baseInfo">
                            <div className="title">{textDes.t1}款信息</div>
                            <Row className="fs12 b12">
                                <Col span={8}>支付途径：{payChannel[paymentInfo.channel-1]}</Col>
                                <Col span={8}>{textDes.t1}款金额：{paymentInfo.amount}元</Col>
                                <Col span={8}>票据号：{paymentInfo.receiptNo}</Col>
                            </Row>
                            <Row className="fs12 b12">
                                <Col span={8}>银行流水：{paymentInfo.bankStatementNo}</Col>
                                <Col span={8}>{textDes.t1}款银行：{paymentInfo.companyBank}</Col>
                                <Col span={8}>{textDes.t1}款账号：{paymentInfo.companyAccount}</Col>
                            </Row>
                            <Row className="fs12 b12">
                                <Col span={24}>备注：{paymentInfo.description}</Col>
                            </Row>
                            <Row className="fs12">
                                <Col className="b12" span={24}>相关凭证：</Col>
                                <Col span={24}>
                                    {ImgList}
                                </Col>                       
                            </Row> 
                        </div>
                        <div className="approval-baseInfo" style={{paddingTop:'0px',marginTop:'-10px'}}>
                            <div className="title">{textDes.t2}款信息</div>
                            <Row className="fs12 b12">
                                <Col span={8}>{textDes.t2}款人：{paymentInfo.customerName}</Col>
                                <Col span={8}>{textDes.t2}款人手机号：{paymentInfo.customerPhone}</Col>
                                <Col span={8}>{textDes.t2}款银行：{paymentInfo.bank}</Col>
                            </Row>
                            <Row className="fs12 b12">
                                <Col span={8}>{textDes.t2}款账号：{paymentInfo.account}</Col>
                            </Row>
                        </div>
                    </div>        
                </Modal>
                <span className="table-operate-btn" onClick={this.showHis.bind(this)}>收付款详情</span>
            </div>
        )
    }
}

export default CandPDetail;