import React from 'react'
import { Modal, Input, Button, Checkbox, message } from 'antd'
import Table from 'CMPT/common/Table'
import './index.less'
import { IdentityType } from "CMPT/enterpriseAuth/enum";

import http from '@/http';
import { API } from '@/http/api';

const prefix = 'test-account-pop'

class TestAccountPopPage extends React.Component {

    state = {
        serachCode: '',
        total: 0,
        params: {
            pageNo: 1,
            pageSize: 10,
            keyWord: '',
        },
        userCodeList: [],
        columns: [
            {key: 'no', render: (record) => {
                return <Checkbox code={record.code} onChange={(e) => this.onCheckBoxChange(e)}></Checkbox>
            }},
            { title: '姓名', dataIndex: 'name' },
            { title: '昵称', dataIndex: 'nickName' },
            { title: '手机号', dataIndex: 'mobile' },
            { title: '创建时间', dataIndex: 'userCreateTime' },
            { title: '企业名称', dataIndex: 'companyName' },
            { title: '企业类型', dataIndex: 'authenticationTo', render: text => IdentityType.getTextFromValue(text) || '-' }
        ],
        data: []
    }

    // checkbox逻辑处理
    onCheckBoxChange (e) {
        let check = e.target.checked
        let code = e.target.code
        let userCodeList = this.state.userCodeList
        if (check){
            userCodeList.push(code)
        }else{
            let index = userCodeList.indexOf(code)
            index >=0 && userCodeList.splice(index,1)
        }
        this.setState({userCodeList: userCodeList})
    }
    componentWillMount() {
        this.onLoadList()
    }
    onSerachChange(value) {
        this.setState((state) => ({
            params: { ...state.params, keyWord: value }
        }))
    }
    onSureClick() {
        Modal.confirm({
            title: '确认',
            icon: '',
            content: '测试账号添加之后，不能再从测试账号中删除，请确认是否加勾选账号添加到测试账号',
            onOk: () => {
                this.onAddTestAccount()
            }
        })
    }
    //分页
    onChangePage = (pageNo, pageSize, sort) => {
        this.setState((state) => ({
            params: { ...state.params, pageNo: pageNo, pageSize: pageSize }
        }), () => {
            this.onLoadList()
        })
    }
    // 添加网络请求
    onAddTestAccount () {

        http({
            api: API.testAccountTrans,
            data: {
                userCodeList: [...this.state.userCodeList]
            },
            showLoading: true,
        }).then(res => {
            if (res.success){
                message.success('添加成功')
                this.props.addSuccess()
            }else {
                message.error('添加失败，请重试')
            }
        })
    }
    onLoadList () {
        http({
            api: API.testAccountFormalList,
            data: {
                ...this.state.params
            },
            showLoading: true,
        }).then(res => {
            if (res.success){
                this.setState({data: res.data.datas || [], total: res.data.totalRecord,userCodeList: []})
            }
        })
    }
    render() {
        const { data, columns, total, params,rowSelection,userCodeList } = this.state;
        return (
            <Modal title='添加测试账号' visible={true} cancelText='取消' okText='确定' okButtonProps={{disabled: userCodeList.length == 0}} onCancel={() => this.props.onCancel()} onOk={() => this.onSureClick()} width={900}>
                <div className={prefix + '-content'}>
                    <div className={prefix + '-filter-wrapper'}>
                        <Input value={params.keyWord} placeholder='姓名/昵称/手机号/企业名称' allowClear style={{ width: 250 }} onChange={(e) => this.onSerachChange(e.target.value)} />
                        <Button className={prefix + '-item'} type='primary' onClick={() => this.onLoadList()}>搜索</Button>
                    </div>
                    <div className={prefix + '-table-wrapper'}>
                        <Table
                            rowKey='code'
                            columns={columns}
                            data={data}
                            total={total}
                            change={this.onChangePage}
                            pageSize={params.pageSize}
                            current={params.pageNo} 
                            rowSelection={rowSelection}
                            />
                    </div>
                </div>
            </Modal>
        );
    }
}
export default TestAccountPopPage