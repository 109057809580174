import React, { Component } from "react";
import { Breadcrumb, message, Modal, Tabs, Table } from "antd";
import "./Detail.less";
import SortTree from './SortTree';
import { API } from '@/http/api';
import http from '@/http';
import NewChannelNav from "./NewChannelNav";
import NewFuChannelNav from "./NewFuChannelNav";
import {formatLinkSearch} from "@/utils"
import moment from 'moment';

import userUtils from '@/utils/user';
import { AUTH_KEYS } from "@/utils/auth";
const { checkAuthByKey } = userUtils;
const { TabPane } = Tabs;
const addAuth = checkAuthByKey(AUTH_KEYS.GOODS_CATEGORY_MANAGE_DAOHANG_MANAGE_ADD); // 新增
const sortAuth = checkAuthByKey(AUTH_KEYS.GOODS_CATEGORY_MANAGE_DAOHANG_MANAGE_SORT); // 排序

const BreadcrumbItem = Breadcrumb.Item;

function getUrlTypeValue(type) {
  let splitContent = window.location.href.split('?')[1] || '';
  let splitArr = splitContent.split('&') || [];
  let obj = {};
  splitArr.length > 0 && splitArr.forEach((item) => {
    let objArr = item.split('=');
    let key = objArr[0];
    let value = objArr[1];
    obj[key] = value;
  });
  return obj[type];
}

class ChannelNavigationDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAbled: false,
            sourceData: {}, //右边页面展示的数据
            detailData: {}, //详情的所有数据
            id: '',
            channelName: '',
            type: '',
            isStartRender: false,
            tabActive: 1,
            columns: [
              {
                  title: '副类目',
                  dataIndex: 'navbarName',
                  key: 'navbarName',
              },{
                  title: '关联类目',
                  dataIndex: 'relationNav',
                  key: 'relationNav',
              },  
              {
                  title: '子类目',
                  dataIndex: 'relationChildNav',
                  key: 'relationChildNav',
              }, {
                  title: '操作',
                  key: 'operate',
                  render: (text, record, index) => {
                      return (<span className="table-operate-btn">
                          <span className="operate" onClick={() => this.goDeleteFuNav(record)}>删除</span>
                          <span className='operate' style={{marginLeft: '6px'}} onClick={() => this.showNavFu(record)} >编辑</span>
                      </span>)
                  }
              }
          ],
          fuNavbarVos: [],
          navigationConfig: {
              navigationStyle: 0,
              showFlag: 1
          },
        }
    }

    componentWillMount() {
      this.getData();
      this.GetNavigationConfig()
    }

     // 获取导航设置
     GetNavigationConfig() {
      const config = {
          api: API.channelNavGetNavigationConfig,
          showLoading: true
      };
      http(config).then(res => {
          console.log(res);
          if(res.success){
              this.setState ({
                  navigationConfig: {
                      navigationStyle: res.data.navigationStyle,
                      showFlag: res.data.showFlag ? 1 : 0
                  }
              })
          }
         
      })
  }

    goDeleteFuNav = (record) => {
      Modal.confirm({
        content: '确认删除该频道导航吗？',
        onOk: this.goDeleteNav.bind(this, record.code)
      })
    }

    // 获取数据
    getData = () => {
      this.setState({
        isStartRender: false
      });
      let typeValue = getUrlTypeValue('type');
      http({
        api: API.channelNavDetail,
        path: {
          typeValue
        }
      }).then(res => {
        if (res.success && res.data) {
          const data = res.data;
          this.setState({
            detailData: data,
            id: data.id,
            channelName: data.channelName,
            type: getUrlTypeValue('type')
          }, () => {
            this.setState({
              isStartRender: true
            });
          });
        }
      });
    }
    // 排序
    sort = () => {
      this.setState({
        isAbled: true
      })
    }

    onSave = () => {
      this.onCancel();
      this.getData(); // 重新获取数据
    }

    onCancel = () => {
      this.setState({
        isAbled: false
      });
    }

    onOperate = (data) => {
      console.log(data, 'data');
      this.setState({
        sourceData: JSON.parse(JSON.stringify(data))
      }, () => {
        this.getStyle();
        let typeValue = getUrlTypeValue('type');
        typeValue == 19 && this.GetSubNavbarDetail()
      });
    }

    showNav = (id=null) => {
        this.editModal && this.editModal.show(id);
      }

    showNavFu = (id=null) => {
      if (id) {
        this.setState({
          fuSourceData: id
        }, () => {
          this.editModalFu && this.editModalFu.show(id);
        })
      } else {
        this.editModalFu && this.editModalFu.show(id);

      }
    }

    deleteNav = () => {
      Modal.confirm({
        content: '确认删除该频道导航吗？',
        onOk: () => {
          this.goDeleteNav()
        }
      })
      
    }

    goDeleteNav = (fuNavCode) => {
      console.log(this.state.sourceData, 'sourceData')
      const {sourceData} = this.state;
      http({
        api: API.channelNavNavbarDelete,
        data: {
          type: fuNavCode ? 22 : getUrlTypeValue('type'),
          navbarCode: fuNavCode ? fuNavCode : sourceData.code
        }
      }).then(res => {
        if (res.code == 200) {
          message.success('删除成功')
          this.getData()
        }
      });
    }

    GetSubNavbarDetail = () => {
      const {sourceData} = this.state;
      http({
        api: API.channelNavGetSubNavbarDetail,
        path: {
          code: sourceData.code
        }
      }).then(res => {
        if (res.success) {
          let fuNavbarVos = res.data.navbarVos ? res.data.navbarVos.map(item => {
            item.relationNav = '';
            item.relationChildNav = '';
            item.categoryVos && item.categoryVos.forEach(li => {
              item.relationNav += li.name + '  ';
              li.childList && li.childList.forEach(ch => {
                item.relationChildNav += ch.name + '  ';
              })
            })
            return item;
          }) : [];
          this.setState({
            fuNavbarVos
          })
        }
      });
    }

    getStyle = () => {
      const left = this.refs.left;
      const right = this.refs.right;
      if (left.offsetHeight > right.offsetHeight) {
        left.style.borderRight = "1px solid #d3d3d3";
        right.style.borderLeft = "none";
      } else {
        right.style.borderLeft = "1px solid #d3d3d3";
        left.style.borderRight = "none";
      }
    }

    tabsChange = (e) => {
      this.setState({
        tabActive: e
      })
    }

    render() {
      const { isAbled, sourceData = {}, channelName, detailData, type, id, isStartRender, tabActive, fuNavbarVos, fuSourceData, navigationConfig} = this.state;
      const channelType = getUrlTypeValue('type');
      const params = formatLinkSearch(this.props.location.search);
      console.log(params, '[arams');
      return (
            <div className="channel-detail">
              <div className="channel-detail-header">
                  <Breadcrumb>
                      <BreadcrumbItem>商品类目</BreadcrumbItem>
                      <BreadcrumbItem>频道导航</BreadcrumbItem>
                  </Breadcrumb>
                  {
                    addAuth ?
                    <span className="channel-add" onClick={this.showNav.bind(this,null)}>新增频道导航</span>
                    : null
                  }
              </div>
              <div className="channel-detail-content">
                <div className="left" ref="left">
                    <p className="left-header">
                      {
                        channelName
                      }
                       {
                        !isAbled && sortAuth ? <span onClick={this.sort}><i className="icon iconfont icon-approve"/>排序</span> : ''
                       }
                    </p>
                    {
                      isStartRender ? 
                        <SortTree 
                          sourceData={detailData.navbarVos || []}
                          isAbled={isAbled}
                          type={type}
                          id={id}
                          onSave={this.onSave}
                          onCancel={this.onCancel}
                          onOperate = {this.onOperate}
                          ref={this.treeModal}
                        />
                      : ''
                    }
                </div>
                <div className="right" ref="right">
                  <div className="right-header">
                    <Tabs defaultActiveKey="1" onChange={this.tabsChange}>
                      <TabPane tab="主类目" key="1"></TabPane>
                      {channelType == 19 && navigationConfig.navigationStyle == 1 ? <TabPane tab="副类目" key="2"></TabPane> : ''}
                    </Tabs>
                    {tabActive == 1 ? <div className="right_header_box">
                      <div className="right_line">
                        <span>
                          {
                            sourceData.navbarName
                          }
                        </span>
                        <div>
                          <span className="edit" onClick={this.deleteNav.bind(this,params.type)}>
                            删除
                          </span>
                          <span className="edit" onClick={this.showNav.bind(this,params.type)}>
                            编辑
                          </span>
                        </div>
                      </div>
                      <div className="desc_msg">
                        <span className="header-span">操作人： {sourceData.operationUser || '-'}</span>
                        <span className="header-span">最后更新时间： { sourceData.operationTime ? moment(sourceData.operationTime).format('YYYY-MM-DD HH:mm:ss') : '-'}</span>
                      </div> 
                    </div>
                    :
                    <div className="right_header_box fu_header_box">
                      <div className="desc_msg">
                        <span className="header-span">操作人： {sourceData.operationUser || '-'}</span>
                        <span className="header-span">最后更新时间： { sourceData.operationTime ? moment(sourceData.operationTime).format('YYYY-MM-DD HH:mm:ss') : '-'}</span>
                      </div> 
                      <span className="edit" onClick={this.showNavFu.bind(this, null)}>
                        添加副类目
                      </span>
                    </div>
                  }
                    
                  </div>
                  <div className="right-content">
                    {
                      tabActive == 1 ? 
                      <div className="zhu_table">
                        <div className="zhu_header">
                          <div className="one_line">关联类目</div>
                          <div className="two_line">子类目</div>
                        </div>
                        {
                          sourceData.categoryVos && sourceData.categoryVos.length > 0 && sourceData.categoryVos.map((item, index) => {
                            if (item.status) {
                              return <div className="zhu_tr" key={index}>
                                <div className="one_line">{item.name}</div>
                                <div className="two_line">
                                {
                                  item.childList && item.childList.length > 0 && item.childList.map((i, I) => {
                                    if (i.status) {
                                      return (
                                        <span key={I}>{i.name}</span>
                                      )
                                    }
                                  })
                                }
                                </div>
                              </div>
                            }
                          })
                        }
                        
                      </div>
                      :
                      <Table 
                        rowKey="code" 
                        columns={this.state.columns} 
                        dataSource={fuNavbarVos}
                        total={fuNavbarVos && fuNavbarVos.length} 
                      />
                    }
                  </div>
                </div>
              </div>
              <NewChannelNav
                wrappedComponentRef={ref => this.editModal=ref}
                okCb={this.getData}
                channelName={channelName}
                channelType
                params={params}
                sourceData={sourceData}
              />
              <NewFuChannelNav
                wrappedComponentRef={ref => this.editModalFu=ref}
                okCb={this.getData}
                channelName={sourceData.navbarName}
                channelType
                zhuSourceData={sourceData}
                params={params}
                sourceData={fuSourceData}
              />
              
            </div>
        );
    }
}
// <ul>  // old-主类目
//   {
//     sourceData.categoryVos && sourceData.categoryVos.length > 0 && sourceData.categoryVos.map((item, index) => {
//       if (item.status) {
//         return (
//           <li key={index}>
//             <p>
//               {item.name}
//             </p>
//             <p>
//               {
//                 item.childList && item.childList.length > 0 && item.childList.map((i, I) => {
//                   if (i.status) {
//                     return (
//                       <span key={I}>{i.name}</span>
//                     )
//                   }
//                 })
//               }
//             </p>
//           </li>
//         )
//       } 
//     })
//   }
// </ul>
export default ChannelNavigationDetail;
