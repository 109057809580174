import React, { Component } from "react";
import { Link } from 'react-router-dom';
import EmptyView from "CMPT/widget/EmptyView";
import {   message } from 'antd';
import Table from 'CMPT/common/Table';
import { API } from "@/http/api";
import {IntentionType} from "../enum.js";
import http from "@/http";
import FilterPanel from "CMPT/common/FilterPanel";
import { FilterType } from '@/utils/fliter';
import TableRenderHelp from "@/utils/TableRenderHelp.js";
import "./List.less";
 



 class EquipmentIntentionList extends Component {


  state = {

    filterConfig:this.createFilterConfig(),
    columns: this.createColumns(),
 

    filterParm:{},
    httpData: [],
     tempData: {
      isLoading: true,
    },

    total: 0, // 当前数据的总数
    pageNo: 1, // 当前所展示的页码
    pageSize: 10, // 每页条数 
  };



  onChangePage = (pageNo, pageSize, sort) => {
    this.setState({
         pageNo , pageSize 
    } ,this.doQueryList)
}

 
  onFilterParmChange=(filterParm)=>{
    this.setState({filterParm,pageNo:1},this.doQueryList);// 每次搜索，从第1页取数据
  }


  createQueryParam = ( ) => {
    let {filterParm,pageNo,pageSize} = this.state;
 
    let param = {
      pageNo , 
      pageSize ,
      intentionStatus :this.props.status
    };
 

    filterParm&&Object.keys(filterParm).forEach(key => {
      if (filterParm[key] || filterParm[key]===0 ) {
        param[key] = filterParm[key];
      }
    });

    return param;
  }

  doQueryList = () => {

    // setup 1:基本入参
    const config = {
      api: API.equipmentIntentionList,
      data: this.createQueryParam( ),
      showLoading: true
    };

    // setup 2:请求，并初步处理
    http(config).then(res => {
      let tempData = this.state.tempData;
      tempData.isLoading = false;

      if (res  &&res.code==200 &&res.data && res.data.datas) {
        let data = res.data;

        this.setState({
          httpData: data.datas || [],
          tempData,
          total: Number(data.total),
          pageNo: data.pageNo
         });
      }else{
        message.error(res.message || '列表查询失败');
      }
    });
  };

 
  createRow1(){
    return {
      childList:[
        {
          type:FilterType.INPUT,
          label:'意向单编号',
          config:{
            field:'code',
            placeholder:''
          }
        },
        {
          type:FilterType.INPUT,
          label:'信息名称',
          config:{
            field:'infoName',
            placeholder:''
          }
        },
        {
          type:FilterType.ENUM_SELECT,
          label:'意向单类型',
          config:{
            field:'businessType',
            enumData:IntentionType
          }
        },      

      ]
    }
  }

createRow2(){
    return {
      childList:[
   
   
        {
          type: FilterType.API_SELECT,
          label:'发布人公司',
          config:{
            field:'equipmentSupplierId',
            api:API.enterpriseAllList,
            showSearch:true,
            valueField:'id',
            nameField:'name'
           }
        }, 
        {
          type: FilterType.API_SELECT,
          label:'发起人公司',
          config:{
            field:'demandSupplierId',
            api:API.enterpriseAllList,
            showSearch:true,
            valueField:'id',
            nameField:'name'
           }
        },
        {
          type:FilterType.TIME_RANGE,
          label:'发送时间',
          config:{
            field:['createdStartTime','createdEndTime'],
          }
        }
  

      ]
    };
  }
 


 

  createFilterConfig(){
    return {
       config:[
        this.createRow1(),
        this.createRow2()     
       ]

    } ;
  }

  createColumns() {
    let help = new TableRenderHelp();
    return [

      help.createCommonColumn("意向单编号", "code", "code"),
 
     /* {
        title: "设备名称",
        key: "equipmentNames",
        dataIndex: "equipmentNames",
        render: (value, record, index) => {
          if(!value || !value.length){
              return <span>--</span>;
          }
          return (
              value.map((item,index1)=>{
                return (<MultiLineText key={index1} showTips={true} value={item} />);
               }
            )
          );
        }
      },*/
      help.createCommonColumn("信息名称", "infoName", "infoName"),
      help.createCommonColumn("发布人账号", "equipmentParty", "equipmentParty"),
      help.createCommonColumn("发布人所属公司", "equipmentPartyCompany", "equipmentPartyCompany"),
      help.createCommonColumn("意向单发起人", "leasing", "leasing"),
      help.createCommonColumn("发起人公司", "leasingCompany", "leasingCompany"),
      help.createCommonColumn("发送时间", "createDate", "createDate"),
      help.createCommonColumn("意向单类型", "businessTypeName", "businessTypeName"),
      help.createCommonColumn("意向单状态", "intentionStatusName", "intentionStatusName"),

      {
        title: "操作",
        key: "operate",
        fixed: 'right',
        render: (text, record, index) => {
          return (
            <Link to={'/app/crm/orderManage/equipment_intention/detail/' + record.code} className="table-operate-btn">{'详情'}</Link>
        )
        }
      }
    ];
  };

   


  componentDidMount() {
    //this.doQueryList();
  }

  componentWillUnmount() {
 }

 

  render() {
    const {  filterConfig,tempData,httpData,columns,pageNo,total} = this.state;
    const {pageId}= this.props;
 

    let isLoading =  tempData.isLoading;
    return (
      <div className="equipment_panel" >

       <FilterPanel data={filterConfig} pageId={pageId}  onFilterChange={this.onFilterParmChange}/>

        { httpData &&  httpData.length > 0 ? (
          <Table  
          rowKey={(record, index) => record.code}
          columns={ columns}
          selfProcessData={httpData} 
          total={ total} 
          change={this.onChangePage}
          current={ pageNo} 
          />
        ) : (isLoading ? "" : <EmptyView tips="暂无数据" />)}
 
      </div>
    );
  }
}

export default EquipmentIntentionList;
