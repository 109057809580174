function parseBlobReponse(exportFileName,res,errCallback){

    let reader = new FileReader()
    reader.onload = e => {
        if (e.target.result.indexOf('"success":false') !== -1) {
            const resultParse = JSON.parse(e.target.result)
            errCallback&&errCallback(resultParse.msg);
        } else {
            const content = res
            const blob = new Blob([content], { type: 'application/vnd.ms-excel' })
            const fileName = exportFileName || '导出文件';
            if ('download' in document.createElement('a')) { // 非IE下载
                const elink = document.createElement('a')
                elink.download = fileName
                elink.style.display = 'none'
                elink.href = URL.createObjectURL(blob)
                document.body.appendChild(elink)
                elink.click()
                URL.revokeObjectURL(elink.href) // 释放URL 对象
                document.body.removeChild(elink)
            } else { // IE10+下载
                navigator.msSaveBlob(blob, fileName)
            }
        }
    }
    reader.readAsText(res)

 
}


export  {
    parseBlobReponse
}