import React, { Component } from "react";
import { Button, Breadcrumb, Row, Input, DatePicker, message,Modal,Radio } from "antd";
import Table from "CMPT/common/Table";
import Select from "CMPT/common/Select";
import { Link } from "react-router-dom";
import PageInfo from "@/utils/pageInfo.js";
import { convertEnumToMap } from "CMPT/common.js";
import TableRenderHelp from "@/utils/TableRenderHelp.js";
import { API } from "@/http/api";
import {parseBlobReponse} from "@/utils/downloadHelper.js";
import moment from 'moment';
import {
  EnterPriseType,
  EnterPriseTypeList,
  EnterPriseStatus,
  AuditStatus,
  convertAuthenticationToLabel,
  // convertisSelfSupport,
  convertTypeToLabel,
  convertStatusToLabel,
} from "./EnterPriseConfig";
import http from "@/http";
import "./EnterPriseList.less";

const { RangePicker } = DatePicker;
const InputSearch = Input.Search;
const BreadcrumbItem = Breadcrumb.Item;

class EnterPriseList extends React.Component {
  state = {
    data: [],
    queryIndex: undefined,


    isExportAuth:false,// 是否具有导出权限

    checkedItem: [], //切换筛选条件，需要重置这个数组。  无论怎么分页，记录都在这里.
    currentPageRowKeys: [], //当前页的所有数据
    currentPageSelectedRowKeys: [], // 当前页中 被选中的数据

    columns: this.createColumns(),
    pageInfo: new PageInfo(),
    selectedUser: undefined, //  当前被选中的用户，要对其进行标签管理。

    isBatchSet: false,
    isBatchRevoke: false,

    startDateMoment: undefined,
    endDateMoment: undefined,

    selectedLabelArray: [],

    filterSearchParam: {
      createBeginTime: undefined,
      createEndTime: undefined,
      keyword: undefined,
      userLabelType: undefined,
      status: undefined, // 企业状态，是否被禁用
      identity: undefined, // 企业身份
      authenticationStatus: undefined, // 认证状态 也就是审核状态

      // SupportShow:false,//自营的弹框显示
      // supportObj: undefined,//编辑价格模式开关的对象
      // supportValue: 0,// 存储下最新的自营，没有变化时就不用调用api
    },
  };

  componentDidMount() {
    this.getEnterPriselist();
    this.checkExportPermission();
  }

  // 点击自营时的弹框
  // setSupport = (item) => {
  //   // this.GetNavigationConfig();
  //   this.setState({ supportObj: item, supportValue: item.isSelfSupport ==null? 0:item.isSelfSupport, SupportShow: true});
  //   console.log(this.state.supportValue);
  // }

  // // 选择自营后重新赋值
  // onsupportChange = (e) => {
  //   this.setState({ supportValue: e.target.value });
  // }

  // //还原值
  // hiddenPriceSwitchDialog = () => {
  //   this.setState({ supportObj: undefined, supportValue: 0 });
  // }

  // //设置自营的值
  // onOkModal = () => {
  //   // 检查参数
  //   let { supportValue,supportObj} = this.state;

  //   if (supportObj.isSelfSupport == supportValue || !supportObj) { // 如果用户选择的新值 和 原始值一致，就没必要去请求api了
  //     this.hiddenPriceSwitchDialog();
  //     return;
  //   }

  //   //构造参数
  //   const config = {
  //       api: API.enterpriseSupportStatus,
  //       data: {
  //           idCode: supportObj.id,
  //           isSelfSupport: supportValue,
  //       },
  //       showLoading: true
  //   }

  //   // 解析
  //   http(config).then(res => {
  //     console.log(res);
  //       if (res && res.success) {
  //         message.success('修改成功！');
  //         this.setState({ SupportShow: false});
  //         this.hiddenPriceSwitchDialog();
  //         this.props.refresh && this.props.refresh();
  //       }else{
  //         message.error(res.msg);
  //       }
  //   })
  // }

    // 判断下是否具有导出企业数据的权限
    checkExportPermission(){
      const config = {
        api: API.userManagerExportAuth,
         showLoading: false
      };
  
      // 请求
      http(config).then(res => {
        if (res && res.success  ) {
            this.setState({isExportAuth:'true'==res.data});
        }
      });
  
    }

  resetParams = () => {
    let filterSearchParam = this.state.filterSearchParam;
    Object.keys(filterSearchParam).forEach((key) => {
      filterSearchParam[key] = undefined;
    });

    let pageInfo = this.state.pageInfo;
    pageInfo.resetPageInfo();

    this.setState(
      {
        pageInfo,
        filterSearchParam,
        startDateMoment: undefined,
        endDateMoment: undefined,
        checkedItem: [], // 条件变化，需要重置下 checkedItem
      },
      this.getEnterPriselist
    );
  };

  onSelectChange = (currentPageSelectedRowKeys) => {
    // 参数
    let { checkedItem, currentPageRowKeys } = this.state;

    // 先把已经选中 加入到checkedItem
    if (currentPageSelectedRowKeys && currentPageSelectedRowKeys.length > 0) {
      currentPageSelectedRowKeys.forEach((item) => {
        if (checkedItem.indexOf(item) < 0) {
          //不在里面，就推进去
          checkedItem.push(item);
        }
      });
    }

    // 把没有选中的，从checkedItem移除
    if (currentPageRowKeys && currentPageRowKeys.length > 0) {
      currentPageRowKeys.forEach((item) => {
        if (currentPageSelectedRowKeys.indexOf(item) < 0) {
          // 不在里面，说明 没有被选中
          let index = checkedItem.indexOf(item); //存在，说明以前选中了，现在不需要选中了，需要移除掉
          if (index >= 0) {
            checkedItem.splice(index, 1); //去移除
          }
        }
      });
    }

    // 更新
    this.setState({ currentPageSelectedRowKeys, checkedItem });
  };

  openCloseChange = (checked, record) => {
    const config = {
      api: API.enterpriseSetStatus,
      data: {
        idCode: record.code,
        status: record.status == 1 ? 0 : 1,
      },
      showLoading: true,
    };

    // 请求
    http(config).then((res) => {
      if (res.success) {
        this.getEnterPriselist();
      }
    });
  };

  createColumns() {
    let help = new TableRenderHelp();
    return [
      help.createCommonColumn("名称", "companyName", "companyName"),

      help.createRenderColumn(
        "身份",
        "identity",
        "identity",
        (text, record) => {
          return <span>{convertTypeToLabel(text)}</span>;
        }
      ),

      help.createRenderColumn(
        "认证状态",
        "authenticationStatus",
        "authenticationStatus",
        (text, record) => {
          return <span>{convertAuthenticationToLabel(text)}</span>;
        }
      ),
      help.createCommonColumn("统一社会信用代码", "creditCode", "creditCode"),
      help.createCommonColumn("成员数量", "employeeCount", "employeeCount"),
      help.createCommonColumn("申请人", "applyInfo", "applyInfo"),
      // help.createRenderColumn(
      //   "是否自营",
      //   "isSelfSupport",
      //   "isSelfSupport",
      //   (text, record) => {
      //     return <span>{convertisSelfSupport(text)}</span>;
      //   }
      // ),
      help.createCommonColumn("超管", "supervisorInfo", "supervisorInfo"),
      // help.createCommonColumn("联系电话", "createUserPhone", "createUserPhone"),
      help.createCommonColumn("创建时间", "createTime", "createTime"),
      help.createOperateColumn("操作", (text, record, index) => {
        return (
          <div>
              <Link
                to={
                  "/app/crm/enterprise/enterpriseDetail/" +
                  record.id +
                  "?pageNo=" +
                  this.state.pageInfo.pageNo +
                  "&currentPageCount=" +
                  this.state.pageInfo.totalPage +
                  "is=" +
                  1
              }
            >
              <span className="text-color-highlight">查看</span>
            </Link>
            {/* {<span className='operate' style={{marginLeft: '6px',color:'blue',cursor: 'pointer'}} onClick={() => { this.setSupport(record) }} >自营设置</span>} */}
          </div>
        );
      }),
    ];
  }

  // 导出用户数据，最多1000条
  export=()=>{
    // setup 1: 构造基本参数
    let myState = this.state;
    let myData = {
      ...myState.filterSearchParam,
      ...myState.pageInfo.paramForSearch(),
    };

    // 我们最多可以导出1000条，进行提示
    message.info("最多导出1000条企业数据");


    // setup 2: 构造 接口入参
    const config = {
      api: API.enterpriseExport,
      data: myData,
      responseType: 'blob',
      showLoading: true
    };

    // 请求
    http(config).then(res => {
         res&&parseBlobReponse('企业列表'+moment().locale('zh-cn').format('YYYYMMDDHHmmss'),res,(errorMsg)=>{ message.error(errorMsg) });
    });

 }

  getEnterPriselist() {
    // setup 1: 构造基本参数
    let myState = this.state;
    let myData = {
      ...myState.filterSearchParam,
      ...myState.pageInfo.paramForSearch(),
    };

    // setup 2: 构造 接口入参
    const config = {
      api: API.enterpriseList,
      data: myData,
      showLoading: true,
    };

    // 请求
    http(config).then((res) => {
      if (res && res.success && res.data) {
        if (!res.data.datas) {
          res.data.datas = [];
        }

        // 抽取 所有的code ，后面判断选中与否
        let { currentPageRowKeys, checkedItem } = this.state;
        let currentPageSelectedRowKeys = [];
        currentPageRowKeys = [];
        res.data.datas.forEach((item) => {
          currentPageRowKeys.push(item.code);

          if (checkedItem.indexOf(item.code) >= 0) {
            //之前被选中了
            currentPageSelectedRowKeys.push(item.code);
          }

          if (item.authUserLabelList && item.authUserLabelList.length) {
            item["authUserLabel"] = item.authUserLabelList.join("、");
          } else {
            item["authUserLabel"] = "个人";
          }

          if (item.suppplierNameList && item.suppplierNameList.length) {
            item["suppplierNameLabel"] =
              item.suppplierNameList[0] +
              (item.suppplierNameList.length > 1 ? "..." : "");
          } else {
            item["suppplierNameLabel"] = "否";
          }

          // if(item.isSelfSupport != null){
          //   this.setState({ supportValue: item.isSelfSupport });
          // }else{
          //   this.setState({ supportValue: 0 });
          // }
          
        });

        this.setState({
          queryIndex: new Date().getTime(),
          pageInfo: myState.pageInfo.changeFromJson(res.data),
          data: res.data.datas,
          currentPageRowKeys,
          currentPageSelectedRowKeys,
        });
      }
    });
  }

  refresh = () => {
    this.getEnterPriselist();
  };

  // 根据时间 进行筛选
  handleDate = (value, date) => {
    // 时间筛选
    let filterSearchParam = this.state.filterSearchParam;
    filterSearchParam.createBeginTime = date[0];
    filterSearchParam.createEndTime = date[1];

    // 条件有变化，页码需要重置
    let pageInfo = this.state.pageInfo;
    pageInfo.resetPageInfo();

    // 重新查询
    this.setState(
      {
        filterSearchParam,
        pageInfo,
        startDateMoment: value[0],
        endDateMoment: value[1],
        checkedItem: [],
      },
      this.getEnterPriselist
    );
  };

  handleEnterpriseStatus = (value) => {
    // 时间筛选
    let filterSearchParam = this.state.filterSearchParam;
    filterSearchParam.status = value;

    // 条件有变化，页码需要重置
    let pageInfo = this.state.pageInfo;
    pageInfo.resetPageInfo();

    // 重新查询
    this.setState(
      {
        filterSearchParam,
        pageInfo,
        checkedItem: [],
      },
      this.getEnterPriselist
    );
  };

  handleEnterPriseType = (value) => {
    // 时间筛选
    let filterSearchParam = this.state.filterSearchParam;
    filterSearchParam.identity = value;

    // 条件有变化，页码需要重置
    let pageInfo = this.state.pageInfo;
    pageInfo.resetPageInfo();

    // 重新查询
    this.setState(
      {
        filterSearchParam,
        pageInfo,
        checkedItem: [],
      },
      this.getEnterPriselist
    );
  };

  handleAuditType = (value) => {
    // 时间筛选
    let filterSearchParam = this.state.filterSearchParam;
    filterSearchParam.authenticationStatus = value;

    // 条件有变化，页码需要重置
    let pageInfo = this.state.pageInfo;
    pageInfo.resetPageInfo();

    // 重新查询
    this.setState(
      {
        filterSearchParam,
        pageInfo,
        checkedItem: [],
      },
      this.getEnterPriselist
    );
  };

  // 关键词筛选
  setKeywords = ({ target: { value } }) => {
    let filterSearchParam = this.state.filterSearchParam;
    filterSearchParam.keyword = value ? value.replace(/^\s+|\s+$/g, "") : "";

    let pageInfo = this.state.pageInfo;
    pageInfo.resetPageInfo();

    this.setState({ filterSearchParam: filterSearchParam, pageInfo });
  };

  searchByKeywords = () => {
    this.setState(
      {
        checkedItem: [],
      },
      this.getEnterPriselist
    );
  };

  // 页码切换
  tableChange = (pageNo, pageSize, sort) => {
    let pageInfo = this.state.pageInfo;
    pageInfo.changeForClick(pageNo, pageSize);
    this.setState({ pageInfo }, this.getEnterPriselist);
  };

  // 弹出 批量设置
  tryBatchSet = () => {
    this.setState({
      selectedLabelArray: [], // 批量设置和批量取消，共用了这个变量，所以需要重置下
      isBatchSet: true,
    });
  };

  // 关闭 批量设置
  cancelOrCloseBatchSet = () => {
    this.setState({
      isBatchSet: false,
    });
  };

  tryBatchRevoke = () => {
    this.setState({
      selectedLabelArray: [], // 批量设置和批量取消，共用了这个变量，所以需要重置下
      isBatchRevoke: true,
    });
  };

  cancelOrCloseRevoke = () => {
    this.setState({
      isBatchRevoke: false,
    });
  };

  // 批量设置
  doBatchSet = () => {
    this.doBatch(true);
  };

  doBatch = (isSet) => {
    // 参数
    let { selectedLabelArray, checkedItem } = this.state;
    const config = {
      api: isSet ? API.labelBatchSet : API.labelBatchCancel,
      data: { labelList: selectedLabelArray, userList: checkedItem },
      showLoading: true,
    };

    // 网络请求
    http(config).then((res) => {
      if (res && res.success) {
        isSet ? this.cancelOrCloseBatchSet() : this.cancelOrCloseRevoke();
        this.setState(
          { checkedItem: [], currentPageSelectedRowKeys: [] },
          this.refresh
        );
      }
    });
  };

  // 批量取消
  doBatchRevoke = () => {
    this.doBatch(false); // false就是取消
  };

  // 用户点击了批量设置标签，但需要先检测数据是否符合要求
  tryShowBatchSetModal = () => {
    if (!this.isHaveCheckedUser()) {
      return;
    }

    this.tryBatchSet();
  };

  isHaveCheckedUser = () => {
    let checkedItem = this.state.checkedItem;
    if (!checkedItem || checkedItem.length < 1) {
      message.error("请选择用户");
      return false;
    }

    return true;
  };

  // 用户点击了批量取消标签，但需要先检测数据是否符合要求
  tryShowBatchRevokeModal = () => {
    if (!this.isHaveCheckedUser()) {
      return;
    }

    this.tryBatchRevoke();
  };

  showUserLabelManager = (selectedUser) => {
    this.setState({ selectedUser });
  };

  render() {
    // const { SupportShow, supportValue} = this.state;
    // const { getFieldDecorator } = this.props.form;
    let mySate = this.state;
    const {
      pageInfo,
      startDateMoment,
      endDateMoment,
      selectedUser,
      currentPageSelectedRowKeys,
      isExportAuth
    } = this.state;

    const rowSelection = {
      selectedRowKeys: currentPageSelectedRowKeys,
      onChange: this.onSelectChange,
    };

    return (
      <div className="userlist-content">
        <div className="enterprise-auth-header">
          <Breadcrumb>
            <BreadcrumbItem>企业管理</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div className="userlist-body">
          <Row>
            {/*<span>
               <Select style={{ marginRight: "5px" }}
                style={{ width: "104px", marginRight: "12px" }}
                data={convertEnumToMap(EnterPriseStatus, false)}
                value={mySate.filterSearchParam.status}
                allowClear
                placeholder="状态"
                onChange={this.handleEnterpriseStatus}
              />
          </span>*/}
            <span>
              <Select
                style={{ marginRight: "5px" }}
                style={{ width: "150px", marginRight: "12px" }}
                data={EnterPriseTypeList}
                value={mySate.filterSearchParam.identity}
                allowClear
                placeholder="企业身份"
                onChange={this.handleEnterPriseType}
              />
            </span>

            <span>
              <Select
                style={{ marginRight: "5px" }}
                style={{ width: "104px", marginRight: "12px" }}
                data={convertEnumToMap(AuditStatus, false)}
                value={mySate.filterSearchParam.authenticationStatus}
                allowClear
                placeholder="认证状态"
                onChange={this.handleAuditType}
              />
            </span>

            <span>
              <span style={{ marginRight: "5px" }}>创建时间</span>
              <RangePicker
                value={[startDateMoment, endDateMoment]}
                onChange={this.handleDate}
                style={{ width: "250px", marginRight: "12px" }}
              />
            </span>

            <span>
              <InputSearch
                placeholder="企业名称、统一社会信用代码"
                onChange={this.setKeywords}
                onSearch={this.searchByKeywords}
                value={mySate.filterSearchParam.keyword}
                style={{ width: "280px" }}
                maxLength={50}
              />
            </span>

            <span
              span={3}
              className="reset"
              style={{
                cursor: "pointer",
                fontSize: "12px",
                textDecoration: "underline",
                textUnderlinePosition: "under",
                color: "#333",
              }}
              onClick={this.resetParams.bind(this)}
            >
              <a>重置</a>
            </span>
            <Button type="link" style={{'marginLeft':'10px',display:isExportAuth?'inline-block':'none'}} onClick={this.export}>导出</Button>
          </Row>
          <Table
            change={this.tableChange}
            rowKey="code"
            {...pageInfo.paramForTable()}
            scroll={{ x: "max-content" }}
            selfProcessData={this.state.data}
            columns={mySate.columns}
          />
        </div>
        {/* <Modal
            visible={SupportShow}
            closable={false}
            maskClosable={false}
            width={600}
            title="自营设置"
            onCancel={() => {
                this.setState({ SupportShow: false })
            }}
            onOk={this.onOkModal}
        >
            <div>
                <div style={{ display: "block", marginBottom: '10px' }}>
                    <span style={{ marginRight: '20px' }}>自营设置:</span>
                    <Radio.Group onChange={this.onsupportChange} value={supportValue}>
                        <Radio value={0}>非自营</Radio>
                        <Radio value={1}>自营</Radio>
                    </Radio.Group>
                </div>
            </div>
        </Modal> */}
      </div>
    );
  }
}

export default EnterPriseList;
