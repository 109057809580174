import http from "@/http";
import { API } from "@/http/api";
import { formatLinkSearch } from "@/utils";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table from "CMPT/common/Table";
import moment from "moment";
import { Breadcrumb, Button, Modal, Input, message, Spin, Form } from "antd";
import "./EnterpriseAccountOpeningDetail.less";
import { showErrorMessage } from "@/utils/globalMessageReminder";
const { TextArea } = Input;
const agreementType = {
  0: "标准协议",
  1: "定制协议",
};
// 筛选项默认值
const defaultParams = {
  pageNo: 1, // 需要查询的页数
  pageSize: 10, // 每页条数
  auditObject: 100, // 货源100 运力101
  name: "", // 商户名称
  unifiedSocialCode: "", // 统一社会信用代码
  // type: '', // 商户类型
  contactName: "", // 联系人姓名
  contactMobile: "", // 联系人手机号
  // contactEmail: '', // 联系人邮箱
  status: "", // 状态
  submitDateState: "", // 需求开始时间
  submitDateEnd: "", // 需求结束时间
  completeDateStart: "", // 申请开始时间
  completeDateEnd: "", // 申请结束时间
};
class AgreementPDF extends Component {
  state = {
    code: null,
    total: 0, // 当前数据的总数
    pageNo: 1, // 当前所展示的页码
    currentPageCount: 0, //当前页面的数量
    selectParams: {
      // 接口入参，必须放这里。方便分页时保存信息
      auditStatus: this.props.state, // 审批状态
      ...defaultParams,
    },

    data: [],
    urlData: [],
    IMGURL: "", // 缩放图
    previewVisible: false,
    spinning: false,
    columns: [
      {
        title: "协议编号",
        key: "agreementNum",
        align: "center",
        dataIndex: "agreementNum",
      },
      {
        title: "类型",
        key: "agreementType",
        align: "center",
        dataIndex: "agreementType",
        render: (text) => {
          return <span>{agreementType[text]}</span>;
        },
      },
      {
        title: "协议名称",
        key: "agreementName",
        align: "center",
        dataIndex: "agreementName",
      },
      {
        title: "备注",
        key: "remarks",
        align: "center",
        dataIndex: "remarks",
      },
      {
        title: "更新人",
        key: "editUserName",
        align: "center",
        dataIndex: "editUserName",
      },
      {
        title: "更新时间", // 0 表示待审核状态
        key: "editDate",
        align: "center",
        dataIndex: "editDate",
        render: (text, record) => {
          return (
            <span>{text ? moment(text).format("YYYY-MM-DD HH:mm") : "-"}</span>
          );
        },
      },
    ],
    agreementListdata: [],
    agreementChangeRemarkModal: false,
  };

  componentWillMount() {
    if (this.props.location) {
      // 获取参数
      const search = this.props.location.search;
      const formatData = formatLinkSearch(search);

      // 解析
      let pageNo1 = null;
      if (formatData && formatData.backPageNo) {
        pageNo1 = formatData.backPageNo;
      }
      // 赋值
      this.setState(
        {
          code: this.props.match.params.code,
          selectParams: {
            auditStatus: this.props.state, // 审批状态
            pageNo: pageNo1, // 当前页数
            pageSize: 10, // 每页条数
          },
        },
        () => {
          this.init();
          this.initIMG();
          this.getRecordMsg()
        }
      );
    }
  }

  //初始化
  componentDidMount() {}
  getRecordMsg = () => {
    const { code } = this.state;
    const config = {
      api: API.accountGetRecordList,
      data: {
        code,
      },
    };

    http(config).then((res) => {
      if (res.code == 200) {
        this.setState({ auditRecords: res.data })
      }
    });
  }
  // 获取协议
  init = () => {
    const { code } = this.state;
    const config = {
      api:
        this.props.location.search.charAt(
          this.props.location.search.length - 1
        ) != "4"
          ? API.accountGetAgreement
          : API.accountCreateAgreement,
      data: {
        code,
      },
    };

    http(config).then((res) => {
      if (res.code == 200) {
        const data = res.data;
        this.setState({
          data,
        });
      }
    });
  };
  // 获取商户协议图片
  initIMG = () => {
    const { code } = this.state;
    const config = {
      api: API.accountGetAgreementImage,
      data: {
        code,
      },
    };

    http(config).then((res) => {
      if (res.code == 200) {
        const data = res.data;
        this.setState({
          urlData: data,
        });
      }
    });
  };

  ok = () => {
    this.setState({
      spinning: true,
    });
    const { code } = this.state;
    const config = {
      api: API.accountConfirmAgreement,
      data: {
        code,
        // remark: '',
      },
    };

    http(config).then((res) => {
      if (res.code == 200) {
        message.success("操作成功");
        this.init();
      } else {
        message.error(res.message);
      }
      this.setState({
        spinning: false,
      });
    });
  };
  submitAgreement = () => {
    this.setState({
      spinning: true,
    });
    const { code } = this.state;
    const config = {
      api: API.accountSubmitAgreement,
      data: {
        code,
        // remark: '',
      },
    };

    http(config).then((res) => {
      if (res.code == 200) {
        message.success("操作成功");
        this.init();
      }
      this.setState({
        spinning: false,
      });
    });
  };
  cencel = () => {};

  /* start: 审核驳回的相关逻辑  */
  gotoReject = () => {
    // 不能为null
    if (!this.state.rejectInput) {
      showErrorMessage("请输入驳回原因");
      return;
    }

    // 去掉两边可能的空格
    var rejectInput = this.state.rejectInput.replace(/(^\s*)|(\s*$)/g, "");
    if (rejectInput.length < 1) {
      showErrorMessage("请输入有效的驳回原因");
      return;
    }
    let config = {
      api: API.accountRejectAgreement,
      data: {
        code: this.props.match.params.code,
        remark: this.state.rejectInput,
      },
    };
    http(config).then((res) => {
      if (res.code == 200) {
        this.setState({ showRejectConfirmFlag: false });
        message.success("操作成功");
        this.init();
      } else {
        this.setState({ showRejectConfirmFlag: false });
        message.error(res.message);
      }
    });
  };
  // 驳回原因
  onRejectInput = (e) => {
    this.setState({
      rejectInput: e.target.value,
    });
  };
  hidenRejectAuthDialog = () => {
    this.setState({ showRejectConfirmFlag: false });
  };
  showRejectAuthDialog = () => {
    this.setState({ showRejectConfirmFlag: true });
  };
  closeImagePreview = () => {
    this.setState({ previewVisible: false });
  };
  cImage = (value) => {
    this.setState({ previewVisible: true, IMGURL: value });
  };

  changeAgreement= () => {
    let config = {
      api: API.accountAgreementListForPage,
      data: {
        pageNo: 1,
        pageSize: 20,
        accountType: this.state.data.accountType
      },
    };
    http(config).then((res) => {
      if (res.code == 200) {
        this.setState({
          agreementListdata: res.data.datas,
          total: res.data.total
        })
      }
    });
    this.setState({ agreementModelShow: true })
  }
  selectAgreementOk = () => {
    const { agreementListdata, selectedRowKeys, data } = this.state;
    this.props.form.setFieldsValue({
      agreementName: agreementListdata[selectedRowKeys[0]].agreementName,
      userName: data.name
  })
    this.setState({ agreementChangeRemarkModal: true, agreementModelShow: false })
  }
  onChangePage = (e)=> {

  }
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }

  agreementRemarkOk = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        console.log(values, 'valueee')
        this.goChangeAgreement(values.remark)
      }
    });
  };
  goChangeAgreement = (remark) => {
    const { agreementListdata, selectedRowKeys } = this.state;
    let config = {
      api: API.accountEditAgreement,
      data: {
        code: this.state.code,
        remark,
        agreementNum: agreementListdata[selectedRowKeys[0]].agreementNum
      },
    };
    http(config).then((res) => {
      if (res.code == 200) {
        const data = res.data;
        message.success("操作成功");
        this.initIMG();
        this.getRecordMsg()
        this.setState({ agreementChangeRemarkModal: false, data })
      }
    });
  }


  watchRecord = () => {
    const { auditRecords } = this.state;

    const recordContent =
      auditRecords && auditRecords.length > 0 ? (
        auditRecords.map((item) => {
          return (
            <div className="record_item">
              <div className="status">变更协议</div>
              <div className="info_item">
                <span>操作人：</span>
                <span>{item.editUserName}</span>
              </div>
              <div className="info_item">
                <span>原协议名称：</span>
                <a style={{ color: '#2E4AFF' }} target="_blank" href={item.oldAgreementUrl} download={item.oldAgreementName}>{item.oldAgreementName}</a>
              </div>
              <div className="info_item">
                <span>时间：</span>
                <span>
                  {moment(item.editDate).format("YYYY-MM-DD HH:mm:ss")}
                </span>
              </div>
              <div className="info_item">
                <span>备注：</span>
                <span>{item.remarks}</span>
              </div>
            </div>
          );
        })
      ) : (
        <div className="log-no-data">
          <div className="log-no-data-text">暂无数据</div>
        </div>
      );

    const recordHtml = <div className="sp_records">{recordContent}</div>;

    Modal.info({
      title: "协议变更记录",
      icon: null,
      content: recordHtml,
    });
  };



  render() {
    const selectParams = this.state.selectParams;
    const { urlData, IMGURL, data, previewVisible, spinning, selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      type: 'radio',
      onChange: this.onSelectChange,
    };
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="accountInfo">
        <Spin className="spin" spinning={spinning}>
          <div>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link
                  to={{
                    pathname: "/app/crm/account_open",
                    search: "?tab=1",
                  }}
                >
                  商户开户管理
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>协议详情</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className="userInfo_body">
            {data.openAccountStatus == 3 ? (
              <div className="reject">
                <div className="reason">
                  驳回原因：
                  {data.rejectionReason ? data.rejectionReason : "--"}
                </div>
                <div className="date">
                  驳回时间：{data.rejectionDate ? data.rejectionDate : "--"}
                </div>
              </div>
            ) : null}

            {/* PDF */}
            <iframe
              src={data.pdfUrl}
              title="iframe"
              width="100%"
              height="600"
            ></iframe>

            {data.openAccountStatus != 4 ? (
              <div className="check">
                查看盖章协议：
                {urlData &&
                  urlData.map((item, index) => (
                    <img
                      src={item.url}
                      key={index}
                      alt=""
                      style={{ width: "150px", margin: "0 8px 0" }}
                      onClick={() => this.cImage(item.url)}
                    />
                  ))}
                <Modal
                  visible={previewVisible}
                  title="查看盖章协议"
                  footer={null}
                  onCancel={this.closeImagePreview}
                >
                  <img
                    alt="图片预览"
                    style={{ width: "100%", margin: "0 0 24px" }}
                    src={IMGURL}
                  />
                </Modal>
              </div>
            ) : null}

            {!!data.contractDeliveryAddressVO && (
              <div className="address">
                <div className="address_title">收件地址：</div>
                <div>
                  <p>
                    收件人：{data.contractDeliveryAddressVO.receiver} /
                    {data.contractDeliveryAddressVO.mobile}
                  </p>
                  <p>地址：{data.contractDeliveryAddressVO.address}</p>
                </div>
              </div>
            )}

            <span style={{ color: '#2E4AFF', cursor: 'pointer' }} onClick={this.watchRecord}>
              {" "}
              协议变更记录{" "}
            </span>


            {data.openAccountStatus == 4 ? (
              <div className="detail_btn">
                <Button type="primary" onClick={this.submitAgreement}>
                  {" "}
                  确认，发送开户通知{" "}
                </Button>
                <Button onClick={() => this.props.history.goBack()}>
                  {" "}
                  取消{" "}
                </Button>
              </div>
            ) : data.openAccountStatus == 1 ||
              data.openAccountStatus == 5 ||
              data.openAccountStatus == 6 ? (
              <div className="detail_btn">
                {
                  (data.openAccountStatus == 5 || data.openAccountStatus == 6) ? (
                      <Button type="primary" onClick={this.changeAgreement}>
                        {" "}
                        变更协议{" "}
                      </Button>) : ''
                }
                <Button onClick={() => this.props.history.goBack()}>
                  {" "}
                  返回{" "}
                </Button>
              </div>
            ) : data.openAccountStatus == 7 ? (
              <div className="detail_btn">
                <Button type="primary" onClick={this.ok}>
                  {" "}
                  确认协议{" "}
                </Button>
                <Button onClick={this.showRejectAuthDialog.bind(this)}>
                  {" "}
                  驳回{" "}
                </Button>
              </div>
            ) : null}
            <Modal
              title="驳回"
              visible={this.state.showRejectConfirmFlag}
              okText="确定"
              cancelText="取消"
              closable={false}
              maskClosable={false}
              onOk={this.gotoReject}
              onCancel={this.hidenRejectAuthDialog}
              width={400}
            >
              <TextArea
                rows={4}
                onChange={this.onRejectInput}
                placeholder="请输入驳回理由 （100字以内）"
                maxLength={100}
              />
            </Modal>
          </div>
        </Spin>
        <Modal
          title="选择协议模版"
          visible={this.state.agreementModelShow}
          okText="确定"
          cancelText="取消"
          onOk={this.selectAgreementOk}
          onCancel={()=> {this.setState({ agreementModelShow: false })}}
          width={1000}
        >
          <Table
            rowSelection={rowSelection}
            columns={this.state.columns}
            selfProcessData={this.state.agreementListdata}
            total={this.state.total}
            change={this.onChangePage}
            current={1}
          />
        </Modal>
        <Modal
              title="提示"
              visible={this.state.agreementChangeRemarkModal}
              okText="确定"
              cancelText="取消"
              onOk={this.agreementRemarkOk}
              onCancel={()=> {this.setState({ agreementChangeRemarkModal: false })}}
              width={400}
            >

              <Form layout="horizontal" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onSubmit={this.agreementRemarkOk}>
                <Form.Item label="客户名称">
                  {getFieldDecorator("userName")(
                    <Input style={{ width: "200px" }} disabled placeholder={""} />
                  )}
                </Form.Item>
                <Form.Item label="变更协议名称">
                  {getFieldDecorator("agreementName")(
                    <Input style={{ width: "200px" }} disabled placeholder={""} />
                  )}
                </Form.Item>
                <Form.Item label="变更原因">
                  {getFieldDecorator("remark")(
                    <TextArea
                      rows={4}
                      placeholder="变更原因"
                      maxLength={100}
                    />
                  )}
                </Form.Item>
              </Form>

            </Modal>
      </div>
    );
  }
}
export default Form.create({
  name: "AgreementPDF",
})(AgreementPDF);
