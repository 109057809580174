import Table from 'CMPT/common/Table';
import http from '@/http';
import { API } from '@/http/api';

import { formatLinkSearch } from '@/utils'
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';

import SearchForm from './searchForm'
import { signType, statusType } from "./enum";
import './ContractManagementList.less';

import userUtils from '@/utils/user';
import { AUTH_KEYS } from "@/utils/auth";
const { checkAuthByKey } = userUtils;
const canShowAuth = checkAuthByKey(AUTH_KEYS.SIGNING_MANAGE_EXAMINE); // 签约审核；

class ContractManagementList extends Component {
    state = {
        total: 0, // 当前数据的总数
        pageNo: 1, // 当前所展示的页码
        currentPageCount: 0, //当前页面的数量
        selectParams: {  // 接口入参，必须放这里。方便分页时保存信息            
            pageNo: 1, // 需要查询的页数
            pageSize: 10, // 每页条数    
            // 企业名称
            enterpriseName: "",
            // 合同状态
            status: this.props.state,
        },
        columns: [
            {
                title: '序号',
                key: "code",
                width: "60px",
                render: (text, record, index) => {
                    return (<span>{index + 1}</span>);
                },
            },
            {
                title: '企业名称',
                dataIndex: 'enterpriseName',
                key: 'enterpriseName',
                render: (text, record) => { //待提交状态的，打开编辑页
                    let value;
                    if (text && text.length > 20) {
                        value = <Tooltip placement="top" title={text}>{text.slice(0, 20)}...</Tooltip>
                    } else {
                        value = text
                    }

                    return (<span   >{value}</span>);

                }
            }, {
                title: 'ID服务号',
                dataIndex: 'serialNo',
                key: 'serialNo',
                render: (text, record) => {
                    return (<span>{text ? text : '-'}</span>)
                }
            }, {
                title: '协议名称',
                dataIndex: 'contractName',
                key: 'contractName',
                render: (text, record) => {
                    let value;
                    if (text && text.length > 20) {
                        value = <Tooltip placement="top" title={text}>{text.slice(0, 20)}...</Tooltip>
                    } else {
                        value = text
                    }
                    return (<span>{value ? value : '-'}</span>)
                }
            },
            {
                title: '法人',
                dataIndex: 'ownerName',
                key: 'ownerName',
                render: (text, record) => {
                    return (<span>{text}</span>);
                }
            },
            {
                title: '企业管理员',
                dataIndex: 'adminName',
                key: 'adminName',
                render: (text, record) => {
                    return (<span>{text}</span>);
                }
            },
            {
                title: '联系方式',
                dataIndex: 'adminPhone',
                key: 'adminPhone',
                render: (text, record) => {
                    return (<span>{text}</span>);
                }
            },
            {
                title: '方式',
                dataIndex: 'signType',
                key: 'signType',
                render: (text, record) => {
                    return (<span style={text<3?{color:text==1?'red':'green'}:null}>{signType.getTextFromValue(text) || "-"}</span>);
                }
                
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                render: text => statusType.getTextFromValue(text) || "-"
            },

            {
                title: '操作',
                key: 'operate',
                render: (text, record, index) => {
                    return (<span className="table-operate-btn">
                        {
                            canShowAuth ?
                            <Link to={'/app/crm/contract-management/detail/' + record.contractId + "?pageNo=" + this.state.pageNo + "&currentPageCount=" + this.state.currentPageCount} className="operate">查看详情</Link>
                            : null
                        }
                    </span>)
                }
            }
        ],
        data: []
    }

    componentWillMount() {
        if (this.props.location) {
            // 获取参数
            const search = this.props.location.search
            const formatData = formatLinkSearch(search)

            // 解析
            let pageNo1 = null;
            if (formatData && formatData.backPageNo) {
                pageNo1 = formatData.backPageNo
            }

            // 赋值
            this.setState({
                selectParams: {

                    pageNo: pageNo1, // 当前页数
                    pageSize: 10, // 每页条数
                }
            });
        }
    }

    //初始化
    componentDidMount() {
        this.getLists();
    }

    //签约列表
    getLists() {
        const httpParams = { ...this.state.selectParams };
        for (let [key, value] of Object.entries(httpParams)) {

            if (value === -1 || value === '') {
                console.log('[key, value]', [key, value])
                httpParams[key] = undefined;
            }
        }
        const config = {
            api: API['sign_getContractList'],
            data: { ...httpParams },
            showLoading: true
        };
        http(config).then(res => {
            if (res.success) {
                const data = res.data;
                this.setState({
                    data: data.datas || [],
                    total: data.totalRecord,
                    pageNo: data.pageNo,
                    currentPageCount: data.datas ? data.datas.length : 0,
                    selectParams: { ...this.state.selectParams, pageNo: data.pageNo }
                })
            }

        })
    }

    //分页
    onChangePage = (pageNo, pageSize, sort) => {
        this.setState({
            selectParams: { ...this.state.selectParams, pageNo: pageNo, pageSize: pageSize }
        }, () => {
            this.getLists()
        })
    }

    // 搜索
    onValuesChange = (allValues) => {
        this.setState({ selectParams: { ...this.state.selectParams, ...allValues } })
    }

    reset = (allValues) => {
        this.setState({
            selectParams: { 
                pageNo: 1, // 需要查询的页数
                pageSize: 10, // 每页条数    
                // 企业名称
                enterpriseName: "",
                // 合同状态
                status: this.props.state,
             }
        }, () => {
            this.getLists();
        })
    }

    render() {
        const selectParams = this.state.selectParams
        return (
            <div className='enterprise-auth-list'>
                <div className='searchBox'>
                    <SearchForm onValuesChange={this.onValuesChange} reset={this.reset} onSearch={() => { this.getLists() }} />
                </div>
                <Table scroll={{ x: 1300 }} rowKey="contractId" columns={this.state.columns} selfProcessData={this.state.data} total={this.state.total} change={this.onChangePage} current={selectParams.pageNo} />
            </div>

        );
    }
}

export default ContractManagementList;