import React, { Component } from 'react';
import './ContractTerms.less'
import { API } from '@/http/api';
import http from '@/http';
import { getUuid } from '@/utils';
import FileUpload from 'CMPT/common/FileUpload';
import BOSelect from 'CMPT/common/Select';
import moment from 'moment';
import { Form, Row, Col, Select, Input, InputNumber, DatePicker, Table, Icon, Button, Dropdown, Menu } from 'antd';
import { NumericInput } from 'CMPT/project/detail/capital/NumericInput'
const Item = Form.Item;
const { RangePicker } = DatePicker;
class CotractTerms extends Component {
    state = {
        years: 0,
        commonSpan: 12,
        columns: [
            {
                title: '房源',
                dataIndex: 'projectRoomName',
                align: 'center',
                rowSpan: 2,
                width: 180,
                fixed: 'left',
                render: (value, row, index) => {
                    const obj = {
                        children: value,
                        props: {rowSpan: 2}
                    }
                    if (index % 2 !== 0) {
                        obj.props.rowSpan = 0
                    }
                    return obj

                }
            }, {
                title: '面积(㎡)',
                dataIndex: 'area',
                align: 'center',
                rowSpan: 2,
                width: 100,
                fixed: 'left',
                render: (value, row, index) => {
                    const obj = {
                        children: value,
                        props: {rowSpan: 2}
                    }
                    if (index % 2 !== 0) {
                        obj.props.rowSpan = 0
                    }
                    return obj
                }
            }, {
                title: '单价',
                dataIndex: 'price',
                align: 'center',
                width: 220,
                fixed: 'left',
                render: (value, row, index) => {
                    const text = index % 2 === 0 ? '租金单价' : '物业单价'
                    const unit = index % 2 === 0 ? '元/㎡/天' : '元/㎡/月'
                    // return (<span className="contract-table-price">
                    //     <span className="text">{text}</span>
                    //     <InputNumber defaultValue={value} onChange={this.savePrice.bind(this, index)}
                    //         placeholder={index % 2 === 1 ? '必填' : ''} min={0} />
                    //     <span className="unit">{unit}</span>
                    // </span>)
                    return (<span className="contract-table-price">
                        <span className="text">{text}</span>
                        <NumericInput defaultValue={value}  onChange={this.savePrice.bind(this, index)}
                            placeholder={index % 2 === 1 ? '必填' : ''} />
                        <span className="unit">{unit}</span>
                    </span>)
                }
            }
        ],
        data: [],
        // 租客和物业结构一致，后面考虑抽出公共组件！！
        contractFeiList: [], // {[{discount}], [{discount,increasingRate, increasingDiscount}]}
        estateFeiList: [], // 折扣以及增长率
        contractFei: { // 租客租金折扣默认值
            discount: 10,
            increasingRate: 0,
        },
        estateFei: { // 物业租金折扣默认值
            discount: 10,
            increasingRate: 0,
        },
        contractMargin: [], // 保证金
        estateMargin: [],
        firstChooseType: undefined,
        contractPaymentType: undefined,
        estatePaymentType: undefined,
        currentDrop: '',
        contractDropId: '1',
        contractDropName: '月',
        estateDropId: '1',
        estateDropName: '月',
        contractTemplateList: [],
        estateTempLateList: [],
        mediaInfo: {
            mediaExt: '',
            mediaKey: '',
            name: '',
            url: ''
        },
        // 挂载的时候不触发regenerate方法
        mountStatus: false,
        tableScrollX: 500
    }
    componentDidMount() {
        const { info, regenerateRentList, init: {paymentNumUnit} } = this.props
        console.log(info, 'info')
        this.getTemplateList(info.projects)
        let years = info.contractDateRange[1].diff(info.contractDateRange[0], 'years', true)
        years = Math.ceil(years)
        if (!regenerateRentList) {
            let columns = this.state.columns
            for (let i = 1; i <= years; i++) {
                const yearAttr = `year${i}`
                columns.push({
                    title: `第${i}年`,
                    dataIndex: yearAttr,
                    align: 'center',
                    width: years > 7 ? 100 : null
                })
            }
            this.setState({
                years,
                columns,
                contractFeiList: info.contractFeiList,
                estateFeiList: info.estateFeiList,
                data: info.rentList,
                tableScrollX: 500 + 100 * years
            })
        } else {
            this.setDefaultValue(years)
        }
        if (info.contractMargin && info.contractMargin.length) {
            this.setState({
                contractMargin: info.contractMargin,
                estateMargin: info.estateMargin,
            })
        }
        if (info.contractDropId || info.estateDropId) {
            let contractItem = paymentNumUnit.find(per => {
                return String(per.key) === info.contractDropId
            })
            let estateItem = paymentNumUnit.find(per => {
                return String(per.key) === info.estateDropId
            })
            this.setState({
                contractDropName: contractItem ? contractItem.value : '月',
                estateDropName: estateItem ? estateItem.value : '月',
                contractDropId: info.contractDropId || '1',
                estateDropId: info.estateDropId || '1'
            })
        }
        if (info.contractPaymentType) {
            this.setState({
                contractPaymentType: info.contractPaymentType,
                estatePaymentType: info.estatePaymentType
            })
        }
        if (info.mediaInfo) {
            this.setState({
                mediaInfo: info.mediaInfo
            })
        }
        if (info.firstChooseType) {
            this.setState({
                firstChooseType: info.firstChooseType
            })
        }
        if (info.firstRangeType) {
            this.setState({
                firstRangeType: info.firstRangeType
            })
        }
        setTimeout(() => {
            this.setState({
                mountStatus: true
            })
        }, 500)
    }
    getTemplateList = (id) => {
        const config = {
            api: API.templateAllList,
            data: {
                type: 1,
                projectCode: id
            }
        }
        http(config).then(res => {
            let list = res.data
            let contract = []
            let estate = []
            if (list && list.length) {
                list.forEach(per => {
                    if (per.type === 101) { // 租客
                        contract.push({name: per.name, value: per.encode})
                    } else {
                        estate.push({name: per.name, value: per.encode})
                    }
                })
            }
            this.setState({
                contractTemplateList: contract,
                estateTemplateList: estate
            })
        })
    }
    setDefaultValue = (years) => {
        // 年租金计算规则
        // 基础价格 * 折扣 * （1 + 递增税率）都是在前一年的折扣上再递增
        let columns = this.state.columns
        let contractFeiList = []
        let estateFeiList = []
        let data = this.props.info.rentList
        let contractFei = this.state.contractFei
        let estateFei = this.state.estateFei
        for (let i = 1; i <= years; i++) {
            const yearAttr = `year${i}`
            columns.push({
                title: `第${i}年`,
                dataIndex: yearAttr,
                align: 'center',
                width: years > 7 ? 100 : null
            })
            if (i === 1) {
                contractFeiList.push({
                    id: getUuid(),
                    discount: contractFei.discount
                })
                estateFeiList.push({
                    id: getUuid(),
                    discount: estateFei.discount
                })
            } else {
                contractFeiList.push({
                    id: getUuid(),
                    discount: contractFei.discount,
                    increasingRate: contractFei.increasingRate,
                })
                estateFeiList.push({
                    id: getUuid(),
                    discount: estateFei.discount,
                    increasingRate: estateFei.increasingRate,
                })
            }
        }
        this.generateEstateMargin()
        this.generateContractMargin()
        this.setState({
            years,
            columns,
            data,
            contractFeiList,
            estateFeiList,
            tableScrollX: 500 + 100 * years
        }, () => {
            this.calucatePrice()
        })
    }
    calucatePrice = () => {
        let data = this.state.data
        let contractFeiList = this.state.contractFeiList
        let estateFeiList = this.state.estateFeiList
        for (let i = 0; i < this.state.years; i++) {
            const yearAttr = `year${i + 1}`
            data.forEach((item, j) => {
                let discount = contractFeiList[i].discount || 10
                let rate = contractFeiList[i].increasingRate
                if (j % 2 === 1) { // 奇数行是租客，偶数行是物业 
                    discount = estateFeiList[i].discount || 10
                    rate = estateFeiList[i].increasingRate
                }
                if (i === 0) { // 第一年只有折扣，第二年开始在前一年的折扣上递增
                    item[yearAttr] = (Number(item.price) * discount / 10).toFixed(2)
                } else {
                    item[yearAttr] = (Number(item[`year${i}`]) * (1 + rate / 100) * discount / 10).toFixed(2)
                }
            })
        }
        this.setState({
            data
        })
    }
    savePrice = (index, value) => {
        if (this.state.mountStatus) {
            this.props.reGenerateStatus(true)
        }
        let contractFeiList = this.state.contractFeiList
        let estateFeiList = this.state.estateFeiList
        let data = this.state.data
        data[index].price = value
        for (let i = 0; i < this.state.years; i++) {
            const yearAttr = `year${i + 1}`
            let discount = contractFeiList[i].discount || 10
            let rate = contractFeiList[i].increasingRate
            if (index % 2 === 1) { // 奇数行是租客，偶数行是物业
                discount = estateFeiList[i].discount || 10
                rate = estateFeiList[i].increasingRate
            }
            if (i === 0) { // 第一年只有折扣，第二年开始在前一年的折扣上递增
                data[index][yearAttr] = (Number(data[index].price) * discount / 10).toFixed(2)
            } else {
                data[index][yearAttr] = (Number(data[index][`year${i}`]) * (1 + rate / 100) * discount / 10).toFixed(2)
            }
        }
        this.setState({data})
    }
    generateEstateMargin = () => {
        if (this.state.mountStatus) {
            this.props.reGenerateStatus(true)
        }
        let estateMargin = this.state.estateMargin
        estateMargin.push({
            id: getUuid(),
            estateMarginType: '2',
            estateMarginSelect: '1',
            estateMarginNum: ''
        })
        this.setState({estateMargin})
    }
    generateContractMargin = () => {
        if (this.state.mountStatus) {
            this.props.reGenerateStatus(true)
        }
        let contractMargin = this.state.contractMargin
        contractMargin.push({
            id: getUuid(),
            contractMarginType: '2',
            contractMarginSelect: '1',
            contractMarginNum: ''
        })
        this.setState({contractMargin})
    }
    minusContractMargin = (id, e) => {
        if (this.state.mountStatus) {
            this.props.reGenerateStatus(true)
        }
        let contractMargin = this.state.contractMargin
        const index = contractMargin.findIndex(per => {
            return per.id = id
        })
        if (index !== -1) {
            contractMargin.splice(index, 1)
        }
        this.setState({contractMargin})
    }
    minusEstateMargin = (id, e) => {
        if (this.state.mountStatus) {
            this.props.reGenerateStatus(true)
        }
        let estateMargin = this.state.estateMargin
        const index = estateMargin.findIndex(per => {
            return per.id = id
        })
        if (index !== -1) {
            estateMargin.splice(index, 1)
        }
        this.setState({estateMargin})
    }
    contractChange = (index, attr, value) => {
        if (this.state.mountStatus) {
            this.props.reGenerateStatus(true)
        }
        let contractFeiList = this.state.contractFeiList
        contractFeiList[index][attr] = value
        this.setState({
            contractFeiList
        }, () => {
            this.calucatePrice()
        })
    }
    estateChange = (index, attr, value) => {
        if (this.state.mountStatus) {
            this.props.reGenerateStatus(true)
        }
        let estateFeiList = this.state.estateFeiList
        estateFeiList[index][attr] = value
        this.setState({
            estateFeiList
        }, () => {
            this.calucatePrice()
        })
    }
    contractMarginChange = (id, attr, value, e) => {
        if (this.state.mountStatus) {
            this.props.reGenerateStatus(true)
        }
        let contractMargin = this.state.contractMargin
        const index = contractMargin.findIndex(per => {
            return per.id === id
        })
        if (index !== -1) {
            contractMargin[index][attr] = value
        }
        this.setState({contractMargin})
    }
    estateMarginChange = (id, attr, value, e) => {
        if (this.state.mountStatus) {
            this.props.reGenerateStatus(true)
        }
        let estateMargin = this.state.estateMargin
        const index = estateMargin.findIndex(per => {
            return per.id === id
        })
        if (index !== -1) {
            estateMargin[index][attr] = value
        }
        this.setState({estateMargin})
    }
    // 首期款涵盖范围 选择
    firstRangeTypeSelect = (isSecond, id) => {
        if (this.state.mountStatus) {
            this.props.reGenerateStatus(true)
        }
        const { info } = this.props;
        isSecond?this.setState({firstRangeType1: id}):this.setState({firstRangeType: id})
        id === 3 && setTimeout(() => {
            console.log(info.contractDateRange[0])
            var beginRangeDate = (info.exemptBeforeDateRange && info.exemptBeforeDateRange[1])?this.addBeginRangeDate(info.exemptBeforeDateRange[1]):info.contractDateRange[0];
            if(isSecond) {
                this.props.form.setFieldsValue({
                    firstBeginDay1: beginRangeDate
                })
            }
            else {
                this.props.form.setFieldsValue({
                    firstBeginDay: beginRangeDate
                })
            }

        }, 0);
    }
    addBeginRangeDate = (value) => {
        const targetValue = value.format('YYYY-MM-DD')
        return moment(targetValue).add(1, 'days')
    }
    
    // 首期缴费期限 选择
    firstChooseTypeSelect = (isSecond, id) => {
        console.log(isSecond, id, '====')
        if (this.state.mountStatus) {
            this.props.reGenerateStatus(true)
        }
        isSecond?this.setState({firstChooseType1: id}):this.setState({firstChooseType: id})
    }
    // 付款日期选择
    contractPaymentTypeSelect = (id) => {
        if (this.state.mountStatus) {
            this.props.reGenerateStatus(true)
        }
        this.setState({
            contractPaymentType: id
        })
    }
    estatePaymentTypeSelect = (id) => {
        if (this.state.mountStatus) {
            this.props.reGenerateStatus(true)
        }
        this.setState({
            estatePaymentType: id
        })
    }
    copyContract = () => {
        const { setFieldsValue, getFieldValue } = this.props.form
        const { contractFeiList, estateFeiList } = this.state 
        const keyList = ['Deposit', 'Payment', 'PaymentType', 'PaymentDate', 'DelayPayment']
        for (let key of keyList) {
            let objKey = `estate${key}`
            setFieldsValue({[objKey]: getFieldValue(`contract${key}`)})
        }
        for (let i = 0; i < contractFeiList.length; i++) {
            if (i > 0) {
                estateFeiList[i].increasingRate = contractFeiList[i].increasingRate
            }
            estateFeiList[i].discount = contractFeiList[i].discount
        }
        this.setState({
            estateDropId: this.state.contractDropId,
            estateDropName: this.state.contractDropName,
            estateFeiList
        }, () => {
            this.calucatePrice()
        })
    }
    payDropdown = (flag) => {
        this.setState({
            currentDrop: flag
        })
    }
    currentSelect = ({key, item}) => {
        if (this.state.mountStatus) {
            this.props.reGenerateStatus(true)
        }
        if (this.state.currentDrop === 'contract') {
            this.setState({
                contractDropId: key,
                contractDropName: item.props.children
            })
        } else {
            this.setState({
                estateDropId: key,
                estateDropName: item.props.children
            })
        }
    }
    onChangeAction = () => {
        if (this.state.mountStatus) {
            this.props.reGenerateStatus(true)
        }
    }
    transKVData = (list, keyObj) => {
        let target = []
        if (list) {
            list.forEach(per => {
                target.push({value: `${per[keyObj.value]}`, name: `${per[keyObj.name]}`})
            })
        }
        return target
    }
    setMedia = (info) => {
        const mediaInfo = {
            mediaKey: info.fileKey,
            type: info.fileType,
            name: info.fileName,
            url: info.fileUrl
        }
        this.setState({
            mediaInfo
        })
    }
    disabledRangeTime = endValue => {
        const { info } = this.props;
        return endValue.valueOf() >= info.contractDateRange[1].valueOf() || endValue.valueOf() < info.contractDateRange[0].valueOf();
    }
    render() {
        const { info, form, init, decision, isEdit } = this.props;
        console.log(info, '=========================================================')
        const bigType = this.props.type;
        console.log(bigType, 'bigtype')
        const { paymentNumUnit, type } = init
        const { getFieldDecorator } = form
        const { estateFei, contractFei, mediaInfo } = this.state
        const marginList = this.transKVData(type, {name: 'value', value: 'key'})
        const menu = 
            (
                <Menu onClick={this.currentSelect}>
                    {paymentNumUnit && paymentNumUnit.map(per => {
                        return <Menu.Item key={per.key}>{per.value}</Menu.Item>
                    })}
                </Menu>
            )
        return (
            <div className="contract-terms-page">
                <div className="info-body">
                    <Form>
                        <div className="contract-block">
                            <Row className="info-title"><span>租赁条款</span></Row>
                            <Row gutter={24}>
                                <Col span={6}>
                                    <Item label="租赁合同模板">
                                        {getFieldDecorator('contractTemplate', {
                                            initialValue: info.contractTemplate
                                        })(
                                            <BOSelect data={this.state.contractTemplateList || []} placeholder="请选择" />
                                        )}
                                    </Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={this.state.commonSpan} className="contract-terms-col">
                                    <Item label="支付方式" className="contract-item-required">
                                        <Row gutter={10}>
                                            <Col span={12} className="contract-terms-inner-col col-inputnumber">
                                                <span className="terms-span-name">押</span>
                                                <Item className="inner-col-item">
                                                    {getFieldDecorator('contractDeposit', {
                                                        rules: [
                                                            {required: true, message: '请输入'},
                                                            {pattern: /^(?:1[0-2]|[1-9])$/, message: '请输入1-12的正整数'}
                                                        ],
                                                        initialValue: info.contractDeposit
                                                    })(
                                                        <InputNumber placeholder="请输入" onChange={this.onChangeAction} min={0} />
                                                    )}
                                                    <span className="input-unit">月</span>
                                                </Item>
                                            </Col>
                                            <Col span={12} className="contract-terms-inner-col col-inputnumber">
                                                <span className="terms-span-name">付</span>
                                                <Item className="inner-col-item">
                                                    {getFieldDecorator('contractPayment', {
                                                        rules: [
                                                            {required: true, message: '请输入'},
                                                            {pattern: /^(?:1[0-2]|[1-9])$/, message: '请输入1-12的正整数'}
                                                        ],
                                                        initialValue: info.contractPayment
                                                    })(
                                                        <InputNumber placeholder="请输入" onChange={this.onChangeAction}
                                                                      min={0} />
                                                                    //   disabled={decision.periodDevideMethod === '2'}
                                                    )}
                                                    <span className="input-unit">
                                                        <Dropdown overlay={menu} trigger={['click']} onVisibleChange={this.payDropdown.bind(this, 'contract')}>
                                                            <span className="ant-dropdown-current">
                                                                {this.state.contractDropName} <Icon type="caret-down" />
                                                            </span>
                                                        </Dropdown>
                                                    </span>
                                                </Item>
                                            </Col>
                                        </Row>
                                    </Item>
                                </Col>
                            </Row>
                            {
                                bigType == 3?
                                null
                                :
                                <>
                                
                                    <Row gutter={24}>
                                        <Col span={this.state.commonSpan} className="contract-terms-col">
                                            <Item label="首期款缴费期限">
                                                <Row gutter={10}>
                                                    <Col span={12} className="contract-terms-inner-col col-inputnumber">
                                                        <Item>
                                                            {getFieldDecorator('firstChooseType', {
                                                                initialValue: info.firstChooseType
                                                            })(
                                                                <Select allowClear placeholder="请选择" onChange={this.firstChooseTypeSelect.bind(this, false)}>
                                                                    <Select.Option value={1}>合同签订后</Select.Option>
                                                                    <Select.Option value={2}>租期开始前</Select.Option>
                                                                    <Select.Option value={3}>租期开始后</Select.Option>
                                                                    <Select.Option value={4}>指定日期</Select.Option>
                                                                </Select>
                                                            )}
                                                        </Item>
                                                    </Col>
                                                    <Col span={12} className="contract-terms-inner-col col-inputnumber">
                                                        <Item>
                                                        {
                                                            this.state.firstChooseType === 4 ?
                                                            getFieldDecorator('firstFeePointDate', {
                                                                initialValue: info.firstFeePointDate
                                                            })(
                                                                <DatePicker  />
                                                            )
                                                            :
                                                            (
                                                                <>
                                                                {
                                                                    getFieldDecorator('firstFeeDays', {
                                                                        rules: [
                                                                            {pattern: /^\+?[1-9][0-9]*$/, message: '请输入正整数'}
                                                                        ],
                                                                        initialValue: info.firstFeeDays
                                                                    })(
                                                                        <InputNumber size="small" className='input-number'/>
                                                                    )
                                                                }
                                                                <span className="input-unit">天内缴费</span>
                                                                </>
                                                                
                                                            )
                                                            
                                                        }
                                                        </Item>
                                                    </Col>
                                                </Row>
                                            </Item>
                                        </Col>
                                    </Row>

                                    <Row gutter={24}>
                                        <Col span={this.state.commonSpan} className="contract-terms-col">
                                            <Item label="首期款涵盖范围">
                                                <Row gutter={10}>
                                                    <Col span={8} className="contract-terms-inner-col col-inputnumber">
                                                        <Item>
                                                            {getFieldDecorator('firstRangeType', {
                                                                initialValue: info.firstRangeType
                                                            })(
                                                                <Select allowClear placeholder="请选择" onChange={this.firstRangeTypeSelect.bind(this, false)}>
                                                                    <Select.Option value={1}>首个计费周期</Select.Option>
                                                                    <Select.Option value={2}>前两个计费周期</Select.Option>
                                                                    <Select.Option value={3}>指定缴费日期</Select.Option>
                                                                </Select>
                                                            )}
                                                        </Item>
                                                    </Col>
                                                    <Col span={16} className="contract-terms-inner-col col-inputnumber">
                                                        <Item>
                                                            {
                                                                this.state.firstRangeType === 3 ?
                                                                <Row gutter={8}>
                                                                    <Col span={11}>
                                                                    {
                                                                        getFieldDecorator('firstBeginDay', {
                                                                            initialValue: info.firstBeginDay
                                                                        })(
                                                                            <DatePicker disabled placeholder="选择开始时间"/>
                                                                        )
                                                                    }
                                                                    </Col>
                                                                    <Col span={1}>-</Col>
                                                                    <Col span={11}>
                                                                    {
                                                                        getFieldDecorator('firstEndDay', {
                                                                            initialValue: info.firstEndDay
                                                                        })(
                                                                            <DatePicker placeholder="选择结束时间" disabledDate={this.disabledRangeTime.bind(this)} />
                                                                        )
                                                                    }
                                                                    </Col>
                                                                </Row>
                                                                :
                                                                null
                                                            }
                                                        </Item>
                                                    </Col>
                                                </Row>
                                            </Item>
                                        </Col>
                                    </Row>
                                </>
                            }                                    
                            

                            <Row gutter={24}>
                                <Col span={this.state.commonSpan} className="contract-terms-col">
                                    <Item label="付款日期" className="contract-item-required">
                                        <Row gutter={8}>
                                            <Col span={14}>
                                                <Item>
                                                    {getFieldDecorator('contractPaymentType', {
                                                        rules: [
                                                            {required: true, message: '请选择'}
                                                        ],
                                                        initialValue: info.contractPaymentType
                                                    })(
                                                        <Select placeholder="请选择" onChange={this.contractPaymentTypeSelect}>
                                                            <Select.Option value={1}>提前付款天数</Select.Option>
                                                            <Select.Option value={2}>固定付款日期</Select.Option>
                                                        </Select>
                                                    )}
                                                </Item>
                                            </Col>
                                            <Col span={10} className="contract-terms-inner-col col-inputnumber">
                                                <Item>
                                                    {getFieldDecorator('contractPaymentDate', {
                                                        rules: [
                                                            {required: true, message: '请输入'}
                                                        ],
                                                        initialValue: info.contractPaymentDate
                                                    })(
                                                        <InputNumber min={this.state.contractPaymentType === 2 ? 1 : 0} max={this.state.contractPaymentType === 2 ? 31 : undefined}
                                                                     onChange={this.onChangeAction} placeholder="请输入" />
                                                    )}
                                                    <span className="input-unit">{this.state.contractPaymentType === 2 ? '号' : '天'}</span>
                                                </Item>
                                            </Col>
                                        </Row>
                                    </Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24} className="contract-terms-col">
                                    <Item label="租赁租金年递增率及折扣">
                                        <Row gutter={24}>
                                            {this.state.contractFeiList.map((per, index) => {
                                                return <span key={per.id}>
                                                    {index > 0 ?
                                                        <Col span={3} className="contract-terms-inner-col col-inputnumber">
                                                            <span className="terms-span-name">第{index + 1}年递增</span>
                                                            <Item className="inner-col-item padding-more">
                                                            {getFieldDecorator(`contractIncreasingRate${index + 1}`, {
                                                                initialValue: per.increasingRate
                                                            })(
                                                                <InputNumber min={0} max={100} onChange={this.contractChange.bind(this, index, 'increasingRate')} placeholder="请输入" />
                                                            )}
                                                            <span className="input-unit">%</span>
                                                            </Item>
                                                        </Col>
                                                        : null
                                                    }
                                                    <Col span={3} className="contract-terms-inner-col col-inputnumber">
                                                        <span className="terms-span-name">{index === 0 ? '第1年' : null}折扣</span>
                                                        <Item className="inner-col-item" style={{paddingLeft: index > 0 ? '34px' : '70px'}}>
                                                            {getFieldDecorator(`contractIncrasingDiscount${index + 1}`, {
                                                                initialValue: per.discount
                                                            })(
                                                                <InputNumber min={0.01} max={10} onChange={this.contractChange.bind(this, index, 'discount')} placeholder="请输入" />
                                                            )}
                                                            <span className="input-unit">折</span>
                                                        </Item>
                                                    </Col>
                                                </span>
                                            })}
                                        </Row>
                                    </Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={10} className="contract-terms-col">
                                    <Item label="保证金费用">
                                    {this.state.contractMargin.length ? this.state.contractMargin.map((per, i) => {
                                            return <Row gutter={8} key={per.id} style={{marginBottom: '16px'}}>
                                            <Icon onClick={this.minusContractMargin.bind(this, per.id)} type="minus-circle" className="margin-minus" style={{color: "#E94437", fontSize: "16px"}} theme="filled" />
                                            <Col span={8}>
                                                <Item>
                                                {getFieldDecorator(`per.contractMarginType${i}`, {
                                                    rules: [
                                                        {required: true, message: '请选择'}
                                                    ],
                                                    initialValue: per.contractMarginType
                                                })(
                                                    <BOSelect  data={marginList} placeholder="请选择"  onChange={this.contractMarginChange.bind(this, per.id, 'contractMarginType')}/>
                                                )}
                                                </Item>
                                            </Col>
                                            <Col span={8}>
                                            {getFieldDecorator(`per.contractMarginSelect${i}`, {
                                                    rules: [
                                                        {required: true, message: '请选择'}
                                                    ],
                                                    initialValue: per.contractMarginSelect
                                                })(
                                                    <Select onChange={this.contractMarginChange.bind(this, per.id, 'contractMarginSelect')}>
                                                        <Select.Option value="1">自定义金额</Select.Option>
                                                        <Select.Option value="2">根据账单自动生成</Select.Option>
                                                    </Select>
                                                )}
                                            </Col>
                                            {this.state.contractMargin.find(item => item.id === per.id).contractMarginSelect === '2' ? null : 
                                            <Col span={6} className="contract-terms-inner-col col-inputnumber">
                                                <Item>
                                                    {getFieldDecorator(`per.contractMarginNum${i}`, {
                                                        rules: [
                                                            {required: true, message: '请输入'}
                                                        ],
                                                        initialValue: per.contractMarginNum
                                                    })(
                                                        <InputNumber onChange={this.contractMarginChange.bind(this, per.id, 'contractMarginNum')}
                                                                     placeholder="请输入" precision={2} min={0} />
                                                    )}
                                                    <span className="input-unit">元</span>
                                                </Item>
                                            </Col>
                                            }
                                            
                                        </Row>
                                        }) : null }
                                        <Row className="contract-terms-inner-row">
                                            <span onClick={this.generateContractMargin}>
                                                <Icon style={{color: "#FBBC06", fontSize: "16px"}} type="plus-circle" theme="filled" />
                                                添加费用
                                            </span>
                                        </Row>
                                    </Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={4} className="contract-terms-col">
                                    <Item label="滞纳金比例">
                                        <Col className="contract-terms-inner-col col-inputnumber inner-input-padding">
                                            {getFieldDecorator('contractDelayPayment', {
                                                initialValue: info.contractDelayPayment
                                            })(
                                                <InputNumber placeholder="请输入" onChange={this.onChangeAction} min={0}/>
                                            )}
                                            <span className="input-unit unit-nopadding">%/天</span>
                                        </Col>
                                    </Item>
                                </Col>
                            </Row>
                        </div>
                        <div className="contract-block">
                            <Row className="info-title">
                                <span>物业条款</span>
                                <Button className="info-title-btn button" onClick={this.copyContract}>复制租赁合同</Button>
                            </Row>
                            <Row gutter={24}>
                                <Col span={this.state.commonSpan}>
                                    <Item label="物业合同模板">
                                        {getFieldDecorator('estateTemplate', {
                                            initialValue: info.estateTemplate
                                        })(
                                            <BOSelect data={this.state.estateTemplateList || []} placeholder="请选择" />
                                        )}
                                    </Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={this.state.commonSpan} className="contract-terms-col">
                                    <Item label="支付方式" className="contract-item-required">
                                        <Row gutter={8}>
                                            <Col span={12} className="contract-terms-inner-col col-inputnumber">
                                            <span className="terms-span-name">押</span>
                                            <Item className="inner-col-item">
                                                {getFieldDecorator('estateDeposit', {
                                                    rules: [
                                                        {required: true, message: '请输入'},
                                                        {pattern: /^(?:1[0-2]|[1-9])$/, message: '请输入1-12的正整数'}
                                                    ],
                                                    initialValue: info.estateDeposit
                                                })(
                                                    <InputNumber placeholder="请输入" onChange={this.onChangeAction} min={0} />
                                                )}
                                                <span className="input-unit">月</span>
                                                </Item>
                                            </Col>
                                            <Col span={12} className="contract-terms-inner-col col-inputnumber">
                                            <span className="terms-span-name">付</span>
                                            <Item className="inner-col-item">
                                                {getFieldDecorator('estatePayment', {
                                                    rules: [
                                                        {required: true, message: '请输入'},
                                                        {pattern: /^(?:1[0-2]|[1-9])$/, message: '请输入1-12的正整数'}
                                                    ],
                                                    initialValue: info.estatePayment
                                                })(
                                                    <InputNumber placeholder="请输入" onChange={this.onChangeAction}
                                                                  min={0}/>
                                                                //   disabled={decision.periodDevideMethod === '2'}
                                                )}
                                                <span className="input-unit">
                                                    <Dropdown overlay={menu} trigger={['click']} onVisibleChange={this.payDropdown.bind(this, 'estate')}>
                                                        <span className="ant-dropdown-current">
                                                            {this.state.estateDropName} <Icon type="caret-down" />
                                                        </span>
                                                    </Dropdown>
                                                </span>
                                                </Item>
                                            </Col>
                                        </Row>
                                    </Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={this.state.commonSpan} className="contract-terms-col">
                                    <Item label="付款日期" className="contract-item-required">
                                        <Row gutter={8}>
                                            <Col span={14}>
                                            <Item>
                                                {getFieldDecorator('estatePaymentType', {
                                                    rules: [
                                                        {required: true, message: '请选择'}
                                                    ],
                                                    initialValue: info.estatePaymentType
                                                })(
                                                    <Select placeholder="请选择" onChange={this.estatePaymentTypeSelect}>
                                                        <Select.Option value={1}>提前付款天数</Select.Option>
                                                        <Select.Option value={2}>固定付款日期</Select.Option>
                                                    </Select>
                                                )}
                                                </Item>
                                            </Col>
                                            <Col span={10} className="contract-terms-inner-col col-inputnumber">
                                            <Item>
                                                {getFieldDecorator('estatePaymentDate', {
                                                    rules: [
                                                        {required: true, message: '请输入'}
                                                    ],
                                                    initialValue: info.estatePaymentDate
                                                })(
                                                    <InputNumber min={this.state.estatePaymentType === 2 ? 1 : 0} max={this.state.estatePaymentType === 2 ? 31 : 365}
                                                                 onChange={this.onChangeAction} placeholder="请输入" />
                                                )}
                                                <span className="input-unit">{this.state.estatePaymentType === 2 ? '号' : '天'}</span>
                                                </Item>
                                            </Col>
                                        </Row>
                                    </Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24} className="contract-terms-col">
                                    <Item label="物业租金年递增率及折扣">
                                        <Row gutter={8}>
                                            {this.state.estateFeiList.map((per, index) => {
                                                return <span key={per.id}>
                                                    {index > 0 ? <span>
                                                        <Col span={3} className="contract-terms-inner-col col-inputnumber">
                                                            <span className="terms-span-name">第{index + 1}年递增</span>
                                                            <Item className="inner-col-item padding-more">
                                                            {getFieldDecorator(`estateIncreasingRate${index + 1}`, {
                                                                initialValue: per.increasingRate
                                                            })(
                                                                <InputNumber min={0} max={100} onChange={this.estateChange.bind(this, index, 'increasingRate')} placeholder="请输入" />
                                                            )}
                                                            <span className="input-unit">%</span>
                                                            </Item>
                                                        </Col>
                                                        </span> : null
                                                    }
                                                    <Col span={3} className="contract-terms-inner-col col-inputnumber">
                                                    <span className="terms-span-name">{index === 0 ? '第1年' : null}折扣</span>
                                                    <Item className="inner-col-item" style={{paddingLeft: index > 0 ? '34px' : '70px'}}>
                                                        {getFieldDecorator(`estateIncrasingDiscount${index + 1}`, {
                                                            initialValue: per.discount
                                                        })(
                                                            <InputNumber min={0.01} max={10} onChange={this.estateChange.bind(this, index, 'discount')} placeholder="请输入" />
                                                        )}
                                                        <span className="input-unit">折</span>
                                                        </Item>
                                                    </Col>
                                                </span>
                                            })}
                                        </Row>
                                    </Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={10} className="contract-terms-col">
                                    <Item label="保证金费用">
                                        {this.state.estateMargin.length ? this.state.estateMargin.map((per, i) => {
                                            return <Row gutter={8} key={per.id} style={{marginBottom: '16px'}}>
                                            <Icon onClick={this.minusEstateMargin.bind(this, per.id)} type="minus-circle" className="margin-minus" style={{color: "#E94437", fontSize: "16px"}} theme="filled" />
                                            <Col span={8}>
                                                <Item>
                                                {getFieldDecorator(`per.estateMarginType${i}`, {
                                                    rules: [
                                                        {required: true, message: '请选择'}
                                                    ],
                                                    initialValue: per.estateMarginType
                                                })(
                                                    <BOSelect  data={marginList} placeholder="请选择"  onChange={this.estateMarginChange.bind(this, per.id, 'estateMarginType')}/>
                                                )}
                                                </Item>
                                            </Col>
                                            <Col span={8}>
                                            {getFieldDecorator(`per.estateMarginSelect${i}`, {
                                                    rules: [
                                                        {required: true, message: '请选择'}
                                                    ],
                                                    initialValue: per.estateMarginSelect
                                                })(
                                                    <Select onChange={this.estateMarginChange.bind(this, per.id, 'estateMarginSelect')}>
                                                        <Select.Option value="1">自定义金额</Select.Option>
                                                        <Select.Option value="2">根据账单自动生成</Select.Option>
                                                    </Select>
                                                )}
                                            </Col>
                                            {this.state.estateMargin.find(item => item.id === per.id).estateMarginSelect === '2' ? null : 
                                            <Col span={6} className="contract-terms-inner-col col-inputnumber">
                                            <Item>
                                                {getFieldDecorator(`per.estateMarginNum${i}`, {
                                                    rules: [
                                                        {required: true, message: '请输入'}
                                                    ],
                                                    initialValue: per.estateMarginNum
                                                })(
                                                    <InputNumber onChange={this.estateMarginChange.bind(this, per.id, 'estateMarginNum')}
                                                                 placeholder="请输入" precision={2} min={0} />
                                                )}
                                                <span className="input-unit">元</span>
                                                </Item>
                                            </Col>
                                            }
                                        </Row>
                                        }) : null }
                                        <Row className="contract-terms-inner-row">
                                            <span onClick={this.generateEstateMargin}>
                                                <Icon style={{color: "#FBBC06", fontSize: "16px"}} type="plus-circle" theme="filled" />
                                                添加费用
                                            </span>
                                        </Row>
                                    </Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={4} className="contract-terms-col">
                                    <Item label="滞纳金比例">
                                        <Col className="contract-terms-inner-col inner-input-padding col-inputnumber">
                                            {getFieldDecorator('estateDelayPayment', {
                                                initialValue: info.estateDelayPayment
                                            })(
                                                <InputNumber placeholder="请输入" min={0} onChange={this.onChangeAction} />
                                            )}
                                            <span className="input-unit unit-nopadding">%/天</span>
                                        </Col>
                                    </Item>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                    <div className="contract-table-detail">
                        <div className="contract-block">
                            <Row className="info-title"><span>租金明细</span></Row>
                            <Table columns={this.state.columns} dataSource={this.state.data} pagination={false}
                                   scroll={{ x: this.state.tableScrollX > 1200 ? this.state.tableScrollX : null }} />
                        </div>
                        <div className="contract-block">
                            <Row className="info-title"><span>上传合同附件</span></Row>
                            <div className="contract-terms-upload">
                                <p>仅支持10M以内的Word, PDF文件</p>
                                <FileUpload onChange={this.setMedia} info={mediaInfo}></FileUpload>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Form.create({ name: 'CotractTerms' })(CotractTerms);