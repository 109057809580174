import {
    AJAX_TYPE
} from '../config';

// 用户计划日程

const prefix = 'task';

export default {
    // 添加类型为日程的行程计划
    [`${prefix}Add`]: {
        url: '/web/task/add',
        type: AJAX_TYPE.POST
    },
    // 取消日程计划
    [`${prefix}Cancel`]: {
        url: '/web/task/cancel/:code',
        type: AJAX_TYPE.POST
    },
    // 已完成的日程列表
    [`${prefix}CompletedTasks`]: {
        url: '/web/task/completedTasks',
        type: AJAX_TYPE.POST
    },
    // 修改行程计划
    [`${prefix}Edit`]: {
        url: '/web/task/edit',
        type: AJAX_TYPE.POST
    },
    // 日程状态切换，当未完成点击操作变成已完成，已完成再操作变成未完成
    [`${prefix}SwitchStatus`]: {
        url: '/web/task/switchStatus/:code',
        type: AJAX_TYPE.POST
    },
    // 获取今日提醒事项列表
    [`${prefix}TodayTasks`]: {
        url: '/web/task/todayTasks',
        type: AJAX_TYPE.GET
    },
    // 未完成的日程列表
    [`${prefix}UnCompleteTasks`]: {
        url: '/web/task/unCompleteTasks',
        type: AJAX_TYPE.POST
    },
}