import { AJAX_TYPE } from "../config";

// 公告

const prefix = "category";

export default {
  [`${prefix}Tree`]: {
    url: "/web/mgr/category/queryCrmCategoryTree",
    type: AJAX_TYPE.GET
  },
  [`${prefix}List`]: {
    url: "/web/mgr/category/queryCrmCategoryList",
    type: AJAX_TYPE.POST
  },
  [`${prefix}Add`]: {
    url: "/web/mgr/category/add",
    type: AJAX_TYPE.POST
  },
  [`${prefix}Edit`]: {
    url: "/web/mgr/category/edit",
    type: AJAX_TYPE.POST
  },
  [`${prefix}Close`]: {
    url: "/web/mgr/category/close",
    type: AJAX_TYPE.POST
  },
  [`${prefix}Open`]: {
    url: "/web/mgr/category/open",
    type: AJAX_TYPE.POST
  },
  [`${prefix}Delete`]: {
    url: "/web/mgr/category/delete",
    type: AJAX_TYPE.POST
  },
  [`${prefix}QueryById`]: {
    url: "/web/mgr/category/queryById/:code",
    type: AJAX_TYPE.GET
  },
  [`${prefix}Batchsort`]: {
    url: "/web/mgr/category/batchsort",
    type: AJAX_TYPE.POST
  },
  [`${prefix}QueryDetail`]: {
    url: "/web/mgr/category/queryByCode/:code",
    type: AJAX_TYPE.GET
  },

  [`${prefix}Move`]: {
    url: "/web/mgr/category/move",
    type: AJAX_TYPE.POST
  },

  [`${prefix}Transfer`]: {
    url: "/web/mgr/category/categoryTransfer",
    type: AJAX_TYPE.POST
  },

  
};
