import React, { Component } from "react";
import {
  Breadcrumb,
  Table,
  Descriptions,
  Button,
  Input,
  message,
  Modal,
} from "antd";
import moment from "moment";
import qs from "qs";
import { Link } from "react-router-dom";
import ImgPreview from 'CMPT/common/ImgPreview';
import { API } from "@/http/api";
import http from "@/http";
import "./index.less";

const { TextArea } = Input;

export default class RentDetail extends Component {
  state = {
    code: "",
    infoType: "",
    rentDetail: {},
    auditRemark: "",
    isAuditReject: 1,
    auditInfo: "",
    liyouModal: false,
  };

  componentWillMount() {
    const match = this.props.match;
    const { infoType } = qs.parse(this.props.location.search.substr(1));
    if (match && match.params && match.params.id) {
      this.setState(
        {
          infoType,
          code: match.params.id,
        },
        () => {
          this.getDetail();
          this.getAuditRecords();
        }
      );
    }
  }

  // 获取详情数据
  getDetail = () => {
    const config = {
      api: API.InviteTendersDetail,
      data: {
        tenderCode: this.state.code,
        // infoType: this.state.infoType,
      },
      showLoading: true,
    };
    http(config).then((res) => {
      if (res && res.code == "0") {
        console.log("res.data", res.data);
        this.setState({
          rentDetail: res.data || {},
        });
      }
    });
  };

  changeAuditRemark = (e) => {
    this.setState({
      auditRemark: e.target.value,
    });
  };

  // 审核
  onVerify = (auditStatus) => {
    // 0:待审核 1:审核通过 2:驳回 3:违规下架
    let param = {
      result: +auditStatus,
      code: this.state.code,
    };
    if (auditStatus === "2") {
      param.reason = this.state.auditInfo;
    }
    const config = {
      api: API.InviteTendersAudit,
      data: param,
      showLoading: true,
    };
    http(config).then((res) => {
      if (res && res.code == "0") {
        message.success(auditStatus === "2" ? "已驳回" : "已通过");
        this.props.history.push(`/app/crm/sourcing?tab=7`);
      }
    });
  };

  // 显示驳回后者下架弹窗
  showLiyouModal = (status) => {
    // 0:待审核 1:审核通过 2:驳回 3:违规下架
    this.setState({ liyouModal: true, btnStatus: status });
  };

  // 输入驳回或者下架理由
  textareaChange = (e) => {
    this.setState({
      auditInfo: e.target.value,
    });
  };

  // 强制下架
  forceOffShelves = () => {
    let param = {
      code: this.state.code,
      reason: this.state.auditInfo,
    };
    const config = {
      api: API.InviteTendersForceOffShelves,
      data: param,
      showLoading: true,
    };

    http(config).then((res) => {
      if (res && res.code === '0') {
        message.success("已强制下架");
        this.props.history.push(`/app/crm/sourcing?tab=7`);
      }
    });
  };

  // 弹窗 确定
  handleOk = () => {
    const actions = new Map([
      [2, () => this.onVerify("2")],
      [3, () => this.forceOffShelves()],
    ]);

    const action = actions.get(this.state.btnStatus);

    action.call(this);
  };

  // 获取审批记录
  getAuditRecords = () => {
    let that = this;
    const config = {
      api: API.InviteTendersAuditLog,
      data: {
        tenderCode: this.state.code,
      },
      showLoading: true,
    };
    http(config).then((res) => {
      if (res && res.code == '0') {
        let auditRecords = res.data || [];
        that.setState({
          auditRecords,
        });
      }
    });
  };

  watchRecord = () => {
    const { auditRecords } = this.state;

    const recordContent =
      auditRecords && auditRecords.length > 0 ? (
        auditRecords.map((item) => {
          return (
            <div className="record_item">
              <div className="status">{item.auditStatusName}</div>
              {item.reason ? (
                <div className="info_item">
                  <span>理由：</span>
                  <span>{item.reason}</span>
                </div>
              ) : (
                ""
              )}
              <div className="info_item">
                <span>审批人：</span>
                <span>{item.auditUserName}</span>
              </div>
              <div className="info_item">
                <span>时间：</span>
                <span>
                  {moment(item.createTime).format("YYYY-MM-DD HH:mm:ss")}
                </span>
              </div>
            </div>
          );
        })
      ) : (
        <div className="log-no-data">
          <div className="log-no-data-text">暂无数据</div>
        </div>
      );

    const recordHtml = <div className="sp_records">{recordContent}</div>;

    Modal.info({
      title: "审批记录",
      icon: null,
      content: recordHtml,
    });
  };

    // 设置图片
    closeImagePreview = () => {
      console.log("设置图片", this.state.previewVisible);
      this.setState({ previewVisible: false });
    };
    // 设置图片缩放
    cImage = (imgList, currentIndex =0, isForArr) => {
      console.log('imgList', imgList)
      imgList = isForArr ? imgList.map(item => {
        return item.url || item.src || item;
      }) : imgList;
      this.setState({
        previewVisible: true,
        // IMGURL: value,
        imgList,
        currentIndex: currentIndex
      });
    };

  render() {
    let { rentDetail, btnStatus, auditInfo, previewVisible, currentIndex, imgList } = this.state;
    const map = { 1: "公开招标", 2: "邀请招标" };

    // 1：待发布 2：进行中 3：报名截止 4：已成交 5：已作废  6：投标截止
    const statusMap = {
      1: "待发布",
      2: "进行中",
      3: "报名截止",
      4: "已成交",
      5: "已作废",
      6: "投标截止",
    };

    return (
      <div className="sourcing-detail-page">
        <div>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link
                to={{
                  pathname: "/app/crm/sourcing",
                  search: `?tab=7`,
                }}
              >
                信息审核
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>招标信息</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="sourcing-content">
          {rentDetail && (
            <Descriptions bordered title="详情信息" className="table-part">
              <Descriptions.Item label="标题" span={3}>
                {rentDetail.name}
              </Descriptions.Item>
              <Descriptions.Item label="发布时间" span={3}>
                {rentDetail.createTime}
              </Descriptions.Item>
              <Descriptions.Item label="发布类型">
                {map[rentDetail.type] || "-"}
              </Descriptions.Item>
              <Descriptions.Item label="发布人">
                {rentDetail.createUserName || "-"}
              </Descriptions.Item>
              <Descriptions.Item label="所属企业">
                {rentDetail.createUserCorp || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="所属地区">
                {rentDetail.provinceName || "-"}
              </Descriptions.Item>
              <Descriptions.Item label="报名截止时间">
                {rentDetail.applyEndTime || "-"}
              </Descriptions.Item>
              <Descriptions.Item label="开标时间">
                {rentDetail.openTime || "-"}
              </Descriptions.Item>
              <Descriptions.Item label="业务分类">
                {rentDetail.categoryName || "-"}
              </Descriptions.Item>
              <Descriptions.Item label="招标类型">
                {map[rentDetail.type] || "-"}
              </Descriptions.Item>
              <Descriptions.Item label="项目进展">
                {statusMap[rentDetail.status] || "-"}
              </Descriptions.Item>

              {rentDetail.status === 4 && <Descriptions.Item label="中标公告" span={3}>
                <a href={rentDetail.detailUrl}>查看中标公告详情</a>
              </Descriptions.Item>}

              {rentDetail.status === 5 && <Descriptions.Item label="废标公告" span={3}>
                <a href={rentDetail.detailUrl}>查看中废标公告详情</a>
              </Descriptions.Item>}

              <Descriptions.Item label="招标主体" span={3}>
                {rentDetail.tenderOrganization}
              </Descriptions.Item>

              <Descriptions.Item label="项目概况" span={3}>
                {rentDetail.projectOverview}
              </Descriptions.Item>
              <Descriptions.Item label="报名条件" span={3}>
                {rentDetail.applicationCondition}
              </Descriptions.Item>
              <Descriptions.Item label="联系方式" span={3}>
                <p>联系人：{rentDetail.contactName}</p>
                <p>联系电话：{rentDetail.contactPhone}</p>
                <p>地址：{rentDetail.contactAddress || "-"}</p>
                <p>邮箱：{rentDetail.contactEmail || "-"}</p>
              </Descriptions.Item>
              <Descriptions.Item label="招标文件" span={3}>
                {rentDetail.tenderFiles && rentDetail.tenderFiles.map( item => {
                   if(item.type == 'png' || item.type == 'jpg'){
                    return ( <p><a onClick={(e) => {e.preventDefault(); this.cImage([item.url])}} href={item.url}>{item.name}</a></p> )
                }
                  return <p><a href={item.url} download={item.name}>{item.name}</a></p>
  })}
              </Descriptions.Item>
            </Descriptions>
          )}

          {rentDetail.auditStatus !== 0 ? (
            <div className="approval-record" onClick={this.watchRecord}>
              审批记录
            </div>
          ) : null}
        </div>
        <div className="action-part">
          {rentDetail.auditStatus === 0 && (
            <Button
              className="action-button-active"
              onClick={() => {
                this.onVerify("1");
              }}
            >
              审核通过
            </Button>
          )}
          {rentDetail.auditStatus === 0 && (
            <Button
              className="action-button-active"
              onClick={() => {
                // 0:待审核 1:审核通过 2:驳回 3:违规下架
                //
                // this.onVerify("2");
                this.showLiyouModal(2);
              }}
            >
              审核驳回
            </Button>
          )}
          {rentDetail.auditStatus === 1 && (
            <Button
              className="action-button-active"
              onClick={() => {
                // this.forceOffShelves();
                this.showLiyouModal(3);
              }}
            >
              强制下架
            </Button>
          )}
          <Button
            className="action-button-active"
            onClick={this.onTapForceOffShelves}
          >
            <Link
              to={{
                pathname: "/app/crm/sourcing",
                search: `?tab=7`,
              }}
            >
              返回
            </Link>
          </Button>
        </div>

        <ImgPreview show={previewVisible} current={currentIndex} imgList={imgList} onClose={this.closeImagePreview}/>

        <Modal
          title={btnStatus == 2 ? "驳回" : "违规下架"}
          visible={this.state.liyouModal}
          okButtonProps={{ disabled: !auditInfo }}
          onOk={this.handleOk}
          onCancel={() => {
            this.setState({ liyouModal: false, auditInfo: null });
          }}
        >
          <div>
            <span className="modal_tit">
              {btnStatus == 2 ? "驳回理由" : "下架理由"}
            </span>
            <TextArea
              maxLength={100}
              value={auditInfo}
              onChange={this.textareaChange}
              placeholder={
                btnStatus == 2
                  ? "请输入驳回理由（100字以内）"
                  : "请输入下架理由（100字以内）"
              }
            ></TextArea>
          </div>
        </Modal>
      </div>
    );
  }
}
