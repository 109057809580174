import React, { Component } from 'react';
import { Upload, message, Icon, Tooltip,Row, Col } from 'antd';
import {
    API
} from '@/http/api';
import axios from 'axios';
import wordSrc from '@/images/files_word@2x.png';
import pdfSrc from '@/images/files_pdf@2x.png';
import excelSrc from '@/images/files_excel@2x.png';
import pptSrc from '@/images/files_ppt@2x.png';
import txtSrc from '@/images/files_txt@2x.png';
import unknowSrc from '@/images/files_unknown@2x.png';
import {formRequest} from '@/http';
import { spawn } from 'child_process';

//由于附件需要上传的文件比较多，特地封装一下，无特殊需求的 antd的upload应该可以满足需求
const fileTypeArray = ['rar', 'zip', '7z', 'doc', 'docx', 'xlsx', 'image/jpeg', 'jpg', 'png','mp4']
const hasPicFile = ['doc', 'docx', 'xlsx']
class ImageUploadBiz extends Component {

    state = {
        fileList:[],
        imgInfo:null,
        loading:false,
        imageUrl:null,
        fileName:null
    }


    handleChange = info => {
        if (info.file.status === 'uploading') {
          this.setState({ loading: true });
          return;
        }
      };

    beforeUpload = (file) => {
        const fileNameToArray = file.name.split('.');
        const fileType = fileNameToArray[fileNameToArray.length - 1];
        const isFile = !!fileTypeArray.find(item => item == fileType)
        if (!isFile) {
            message.error('只支持' + fileTypeArray.join() + '文件');
        }
        const isLt5M = file.size / 1024 / 1024 <= 500;
        if (!isLt5M) {
            message.error('文件大小不能超过5MB');
        }
        return isFile && isLt5M;
    }

    doUploadFile(value) {
        value.action = API.uploadAdd.url;
        const file = value.file;
        const fileNameToArray = file.name.split('.');
        const fileType = fileNameToArray[fileNameToArray.length - 1];
        formRequest(value).then(res=>{
           if(res.code==0){
               let data = res.data;
               if(!data){
                   message.error('上传失败，请重试')
                   return;
               }
               let fileList = [];
               let fileInfo = {
                   mediaExt: fileType,
                   mediaKey: data.key,
                   name: data.fileName,
                   type: fileType,
                   url:data.publicDownloadUrl
               }
               fileList = [fileInfo];
               console.log(fileType);
               console.log(fileType == 'jpg'||'png' ? data.publicDownloadUrl : unknowSrc)
               this.setState({
                   fileList:fileList,
                   imgInfo:fileInfo,
                   imageUrl:fileType == 'doc' || fileType == 'docx' ? wordSrc : fileType == 'xlsx' ? excelSrc : fileType == 'txt' ? txtSrc : fileType == 'jpg'||fileType =='png' ? data.publicDownloadUrl : unknowSrc,
                   loading: false,
                   fileName:data.fileName
               },()=>{
                    this.props.fileUp(this.state.fileList)
               })
           }
        })
    }

    componentWillReceiveProps(nextProps){
        if(!nextProps.fileList||nextProps.fileList.length==0){
            return;
        }
        const fileType = nextProps.fileList.length&&nextProps.fileList[0].mediaExt;
        this.setState({
            imageUrl:fileType == 'doc' || fileType == 'docx' ? wordSrc : fileType == 'xlsx' ? excelSrc : fileType == 'txt' ? txtSrc : fileType == 'jpg'||fileType =='png' ? nextProps.fileList[0].url : unknowSrc,
            fileName:nextProps.fileList[0].name
        })
    }

    render() {
        const uploadButton = (
            <div>
                { !this.state.loading  ? <span style={{ fontSize: '24px' }} className='icon iconfont iconupload'></span> : <Icon style={{ fontSize: '42px' }} type='loading' />} 
                <div className="ant-upload-text" style={{margin: 'auto', width: '76%'}}>
                    拖拽至此 或 点击上传
              </div>
            </div>
        );
        const imageUrl = this.state.imageUrl;
        const fileName = this.state.fileName;
        // const fileNameToArray = fileName.split('.');
        // const fileType = fileNameToArray[fileNameToArray.length - 1];
        return (
                    <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        beforeUpload={this.beforeUpload}
                        action={API.uploadAdd.url}
                        customRequest={this.doUploadFile.bind(this)}
                        showUploadList={false}
                        onChange={this.handleChange}
                    >
                        {imageUrl ? <img style={{width:'48px',height:'48px'}} src={imageUrl} alt="avatar" /> : uploadButton} <br/>
                       { fileName ? <p style={{width:'100px',height:'18px',textOverflow:'ellipsis',overflow:'hidden',whiteSpace: 'nowrap'}}> {fileName}</p> : null}
                    </Upload>
        )
    }
}

export default ImageUploadBiz
