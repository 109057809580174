import React, { Component, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Form, Breadcrumb, Button, Input, Select, Tooltip, Icon, message, TreeSelect } from 'antd';
import Editor from 'wangeditor';
import http from '@/http';
import storage from '@/utils/storage';
import { API } from '@/http/api';
import userUtil from "@/utils/user";
import Dep from '../../common/departmentstaff'
import './SettingAnnounceForm.less';

const BreadcrumbItem = Breadcrumb.Item;
const FormItem = Form.Item;
const Option = Select.Option;

class SettingAnnounceManageForm extends Component {
    constructor(props) {
        super(props)
    }
    state = {
        deptVoList: [],
        announceTypeList: [
            {
                "key": 1,
                "value": "通知"
            },
            {
                "key": 2,
                "value": "节日"
            },
            {
                "key": 3,
                "value": "奖励"
            },
            {
                "key": 4,
                "value": "惩罚"
            }
        ],
        formData: {
            type: '',
            title: '',
            content: '',
            pureText: '',
            userIds: [],
            deptIds: [],
            encode: this.props.match.params.id
        },
        formPath: this.props.match.path,
        encode: this.props.match.params.id,
        formTab: '新增公告',
        peopleList: [],
    }
    componentDidMount() {

        // this.initEnums();
        const encode = this.state.encode;
        if (encode) {
            this.initManage(encode);
            this.setState({
                formTab: '编辑公告'
            })
        } else {

            this.setState({
                formTab: '新增公告'
            })
        }
        this.initEditor();
    }

    // 编辑 获取回显信息
    initManage(id) {
        http({
            api: API.announceDetail,
            path: {
                encode: id
            },
            showLoading: true
        }).then(ajaxData => {
            if (ajaxData.data) {
                const data = ajaxData.data;
                let newData = this.getDepartmentstaff(data.deptVoList)
                let formdata = {
                    title: data.title,
                    content: data.content,
                    pureText: data.pureText,
                    type: data.type,
                    userIds: newData.userIds,
                    deptIds: newData.deptIds,
                    encode: id
                }
                this.setState({
                    formData: formdata,
                    deptVoList: data.deptVoList
                })
                this.initEditor();
            }
        });
    }

    getDepartmentstaff(deptVoList) {
        let data = {
            userIds: [],
            deptIds: [],
        }
        for (let i = 0, len = deptVoList.length; i < len; i++) {
            if (deptVoList[i].text) {
                data.deptIds.push(deptVoList[i].idCode)
            } else {
                data.userIds.push(deptVoList[i].idCode)
            }
        }   
        return data
    }


    // // 获取接收人下拉列表
    // initEnums() {
    //     http({
    //         api: API.userGetList,
    //         data: {
    //             departmentEncode: storage.get('USER').departmentIdCode
    //         },
    //         showLoading: true
    //     }).then(ajaxData => {
    //         const peopleList = ajaxData.data.datas;
    //         this.setState({
    //             peopleList: this.transDeptData(peopleList),
    //         });
    //     });
    // }
    transDeptData(list) {
        let transList = []
        list.forEach(per => {
            transList.push({ value: `${per.idCode}-${per.departmentEncode}`, name: `${per.departmentName}-${per.roleName}` })
        })
        return transList
    }

    // 公告类型
    resetAnnounceType = (value) => {
        this.setState({
            formData: {
                ...this.state.formData,
                type: value
            }
        })
    }

    // 公告标题 
    resetAnnounceTitle = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                title: e.target.value
            }
        })
    }

    //  选择  接收人
    // resetAnnouncePeople = (value) => {
    //     console.log(value)
    //     let userIds = [];
    //     let departmentIds = [];
    //     value.forEach(per => {
    //         const str = per.split('-');
    //         userIds.push(str[0]);
    //         departmentIds.push(str[1]);
    //     })
    //     this.setState({
    //         formData: {
    //             ...this.state.formData,
    //             userIds: userIds,
    //             departmentIds: departmentIds
    //         }
    //     })
    // }

    // 取消
    cancel = () => {
        if (this.props.history.length > 1) {
            this.props.history.goBack();
        }
    }
    // 提交
    submit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const encode = this.state.encode;
                let api;
                if (encode) {
                    api = API.announceEdit;
                } else {
                    api = API.announceAdd;
                }
                console.log(this.state.formData)
                http({
                    api,
                    data: this.state.formData,
                    showLoading: true,
                    successTip: '保存成功'
                }).then(ajaxData => {
                    // toggleSubmitLoading(false);
                    if (ajaxData.success) {
                        this.props.history.replace('/app/setting/announce');
                    }
                }).catch(error => {
                    // toggleSubmitLoading(false);
                });
            }
        })



    }

    initEditor() {
        const elem = this.refs.editorElem;
        let editor = new Editor(elem);
        this.editor = editor;

        editor.customConfig.zIndex = 100;
        // 图片上传地址
        editor.customConfig.uploadImgServer = API.uploadAdd.url;
        // 限制一次最多上传 5 张图片
        editor.customConfig.uploadImgMaxLength = 5;
        // 上传图片
        const user = userUtil.get();
        // editor.customConfig.uploadImgParams = {
        //     // 如果版本 <=v3.1.0 ，属性值会自动进行 encode ，此处无需 encode
        //     // 如果版本 >=v3.1.1 ，属性值不会自动 encode ，如有需要自己手动 encode
        //     token: user.token,
        // }
        editor.customConfig.uploadImgHeaders = {
            // 'Content-Type': 'multipart/form-data',
            'token': user.token,
        }
        editor.customConfig.uploadFileName = 'file'
        editor.customConfig.uploadImgHooks = {
            before: function (xhr, editor, files) {
                console.log(xhr)
                console.log(editor)
                console.log(files)
                // 图片上传之前触发
                // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象，files 是选择的图片文件

                // 如果返回的结果是 {prevent: true, msg: 'xxxx'} 则表示用户放弃上传
                // return {
                //     prevent: true,
                //     msg: '放弃上传'
                // }
            },
            success: function (xhr, editor, result) {
                console.log(xhr)
                console.log(editor)
                console.log(result)
                // 图片上传并返回结果，图片插入成功之后触发
                // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象，result 是服务器端返回的结果
            },
            fail: function (xhr, editor, result) {
                console.log(xhr)
                console.log(editor)
                console.log(result)
                // 图片上传并返回结果，但图片插入错误时触发
                // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象，result 是服务器端返回的结果
            },
            error: function (xhr, editor) {
                console.log(xhr)
                console.log(editor)
                // 图片上传出错时触发
                // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象
            },
            timeout: function (xhr, editor) {
                console.log(xhr)
                console.log(editor)
                // 图片上传超时时触发
                // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象
            },

            // 如果服务器端返回的不是 {errno:0, data: [...]} 这种格式，可使用该配置
            // （但是，服务器端返回的必须是一个 JSON 格式字符串！！！否则会报错）
            customInsert: function (insertImg, result, editor) {
                // 图片上传并返回结果，自定义插入图片的事件（而不是编辑器自动插入图片！！！）
                // insertImg 是插入图片的函数，editor 是编辑器对象，result 是服务器端返回的结果
                // 举例：假如上传图片成功后，服务器端返回的是 {url:'....'} 这种格式，即可这样插入图片：
                var url = result.data.publicUrl
                insertImg(url)

                // result 必须是一个 JSON 格式字符串！！！否则报错
            }
        }

        // 使用 onchange 函数监听内容的变化，并实时更新到 state 中
        editor.customConfig.onchange = (html) => {
            // 转义内容中的特殊字符
            const htmlText = editor.txt.text().replace(/&lt;/g, "<").replace(/&gt;/g, ">").replace(/&amp;/g, "&").replace(/&nbsp;/g, " ");
            this.setState({
                formData: {
                    ...this.state.formData,
                    content: html,
                    pureText: htmlText
                }
            })
            console.log(this.state)
            //将html值设为form表单的desc属性值
            // this.props.form.setFieldsValue({
            //     'desc': html
            // });
        }
        editor.customConfig.menus = [
            'head', // 标题
            'bold', // 粗体
            'fontSize', // 字号
            'fontName', // 字体
            'italic', // 斜体
            'underline', // 下划线
            'strikeThrough', // 删除线
            'foreColor', // 文字颜色
            // 'backColor', // 背景颜色
            'link', // 插入链接
            'list', // 列表
            'justify', // 对齐方式
            // 'quote', // 引用
            // 'emoticon', // 表情
            'image', // 插入图片
            // 'table', // 表格
            // 'video', // 插入视频
            // 'code', // 插入代码
            'undo', // 撤销
            'redo' // 重复
        ]
        editor.create()
        editor.txt.html(this.state.formData.content)
    }

    getDataState(data) {
        this.setState({
            formData: {
                ...this.state.formData,
                userIds: data.userIds,
                deptIds: data.departmentIds,
            }
        })

    }
    render() {
        const { getFieldDecorator } = this.props.form

        // const { form: { getFieldDecorator, getFieldValue }, announceTypeList = [], peopleList = [], resetAnnounceType, resetAnnouncePeople } = this.props;
        return (

            <div className='setting-announce-manage-form'>
                <Breadcrumb>
                    <BreadcrumbItem><Link to='/app/setting/announce'>公告列表页</Link></BreadcrumbItem>
                    <BreadcrumbItem>{this.state.formTab}</BreadcrumbItem>
                </Breadcrumb>
                <div className='manage-form-info'>
                    <Form >
                        <FormItem
                            label="公告类型"
                        >
                            {getFieldDecorator('type', {
                                rules: [{
                                    required: true, message: '公告类型不能为空！',
                                }],
                                initialValue: this.state.formData.type
                            })(
                                <Select placeholder="请选择" className='manage-select' onChange={this.resetAnnounceType}>
                                    {
                                        this.state.announceTypeList.map(({ key, value }) => (<Option value={key} key={key}>{value}</Option>))
                                    }
                                </Select>
                            )}
                        </FormItem>
                        <FormItem
                            label="公告标题"
                        >
                            {getFieldDecorator('title', {
                                rules: [{
                                    required: true, message: '公告标题不能为空！',
                                }],
                                initialValue: this.state.formData.title
                            })(
                                <Input placeholder="请输入" className='manage-input' onChange={this.resetAnnounceTitle} maxLength={30} />
                            )}
                        </FormItem>
                        <FormItem>
                            <div>
                                <span className='newlabel'>公告内容</span>
                                <div ref='editorElem' style={{ textAlign: 'left', marginTop: '8px' }} />
                            </div>

                        </FormItem>
                        <FormItem>
                            <div>
                                <span className='newlabel'>接收人</span>
                                <Dep getDataState={(data) => { this.getDataState(data) }} deptVoList={this.state.deptVoList}></Dep>
                            </div>
                        </FormItem>
                    </Form>
                </div>
                <div className='manage-form-operate'>
                    <Button type="primary" onClick={this.submit.bind(this)} size='large'>提交</Button>
                    <Button onClick={this.cancel} size='large'>取消</Button>
                </div>
            </div>
        )
    }
}

export default Form.create({ name: 'mForm' })(SettingAnnounceManageForm);
