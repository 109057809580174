import {
    AJAX_TYPE
} from '../config';

// Company.Company

const prefix = 'companyinitCompany';

export default {
    // 公司初始化
    [`${prefix}`]: {
        url: '/web/company/initCompany',
        type: AJAX_TYPE.POST
    },
}