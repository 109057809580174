import React, { Component } from "react";
import { Link } from "react-router-dom";
import { API } from "@/http/api";
import http from "@/http";
import AuthContainer from "CMPT/common/auth/AuthContainer";
import Card from "./Card";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import CatalogAdd from "./CatalogAdd";
// import CatalogSonAdd from './CatalogSonAdd'
import CatalogDel from "./CatalogDel";
import { Breadcrumb, Button } from "antd";
import "./Catalog.less";

import userUtils from '@/utils/user';
import { AUTH_KEYS } from "@/utils/auth";
const { checkAuthByKey } = userUtils;
const canShowAddAuth = checkAuthByKey(AUTH_KEYS.HELP_CENTER_MANAGE_CLASS_MANAGE_CREATE); // 新增类目；

const BreadcrumbItem = Breadcrumb.Item;
class List extends Component {
  state = {
    editId: "",
    title: "",
    channel: null,
    cards: null,
    parent: {
      visible: false
    },
    delcatalog: {
      visible: false
    }
  };

  getData = () => {
    const config = {
      api: API.helpCatalogList,
      showLoading: true,
      data: {
        catalogType: this.props.type //类目类型，1商城 2供应商 3园区
      }
    };
    http(config).then(res => {
      this.setState({
        cards: res.data
      });
    });
  };

  componentDidMount() {
    this.getData();
  }

  onUnfold(index) {
    let { cards } = this.state;
    cards[index].unfold = !cards[index].unfold;
    this.setState({
      cards
    });
  }

  assign = () => {
    this.setState({
      parent: {
        visible: false
      }
    });
    // this.getData()
  };

  goAdd = () => {
    this.setState({
      title: "",
      channel: null,
      parent: {
        visible: true
      }
    });
  };

  delcatalog = id => {
    this.setState({
      editId: id,
      delcatalog: {
        visible: true
      }
    });
  };
  delassign = () => {
    // this.getData()
    this.setState({
      delcatalog: {
        visible: false
      }
    });
  };
  sort() {
    const { cards } = this.state;
    let sort = cards.map(item => {
      return item.id;
    });
    const config = {
      api: API.helpUpdateCatalogList,
      data: {
        sortList: sort.join(",")
      }
    };
    http(config).then(res => {
      console.log(res);
    });
  }
  edit(text, id, channelTemp) {
    this.setState({
      editId: id,
      title: text,
      channel: channelTemp,
      parent: {
        visible: true
      }
    });
  }
  renderCard(card, index) {
    return (
      <Card
        key={card.id}
        index={index}
        id={card.id}
        text={card.catalogName}
        channel={card.channel}
        edit={this.edit.bind(this)}
        // child={card.child}
        // unfold={card.unfold}
        moveCard={this.moveCard.bind(this)}
        // onUnfold={this.onUnfold.bind(this)}
        // addsson={this.addsson}
        delcatalog={this.delcatalog.bind(this)}
      />
    );
  }
  moveCard(dragIndex, hoverIndex) {
    let { cards } = this.state;
    cards.splice(
      dragIndex,
      1,
      ...cards.splice(hoverIndex, 1, cards[dragIndex])
    );
    this.setState({
      cards
    });

    this.sort();
  }
  render() {
    const { cards } = this.state;
    return (
      <div className="contract-list-out">
        {/* <div className="contract-header"> */}
          {/*  <Breadcrumb>
            <BreadcrumbItem>帮助中心</BreadcrumbItem>
            <BreadcrumbItem>目录管理</BreadcrumbItem>
          </Breadcrumb>
          <AuthContainer authCodes={AUTH_KEYS.OPERATE_CONTRACT_ADD}> */}
          
          {/* </AuthContainer> */}
        {/* </div> */}
        {
          canShowAddAuth ?
          <Button className="add_btn" type="primary" onClick={this.goAdd}>
            新增
          </Button> : null
        }
        
        <div className="contract-content">
          {cards && cards.length > 0 ? (
            <div className="intention-body">
              <div className="catalogTit">
                <div>序号</div>
                <div>类目名称</div>
                <div>渠道</div>
                <div>操作</div>
              </div>
              <DndProvider backend={HTML5Backend}>
                {cards.map((card, i) => this.renderCard(card, i))}
              </DndProvider>
            </div>
          ) : (
              <div className="intention-body nodata">
                <div>
                  {" "}
                  <img src={require("../../images/nodata.png")} alt="" />
                  <p>暂无数据</p>
                </div>
              </div>
            )}
        </div>

        {this.state.parent.visible ?
          <CatalogAdd
            refresh={this.getData}
            title={this.state.title}
            catalogType={this.props.type}
            editId={this.state.editId}
            assignCancel={this.assign}
            channel={this.state.channel}
          ></CatalogAdd> : ''}


        {/* <CatalogSonAdd visible={this.state.sonAdd.visible} editId={this.state.editId} assignCancel={this.assigns}></CatalogSonAdd> */}
        <CatalogDel
          visible={this.state.delcatalog.visible}
          refresh={this.getData}
          editId={this.state.editId}
          assignCancel={this.delassign}
        ></CatalogDel>
      </div>
    );
  }
}

export default List;
