import { API } from '@/http/api';
import http from '@/http';
import { showErrorMessage, } from '@/utils/globalMessageReminder';
import { formatLinkSearch } from '@/utils';
import { convertAuthenticationToLabel } from 'CMPT/operate/customerConfig'
import React, { Component } from 'react';
import Table from 'CMPT/common/Table';
import Select from 'CMPT/common/Select';
import { Layout, Breadcrumb, Button, Row, Col, Tabs, Modal, Input,DatePicker } from 'antd';
import { Link } from 'react-router-dom';

import './Source.less';


class Source extends Component {
    state = {
        params: {
            pageSize: 10,
            pageNo: 1,
            keyWords: '',
            beginTime: undefined,
            endTime:undefined,
            loginFlag:undefined,
            total: 1,
            resourceType:''
        },

        orderDate:[],
        data:[],
        qdList:[{
            value:'',
            name:'全部'
        },{
            value:1,
            name:'web'
        },{
            value:2,
            name:'小程序'
        }],
        loginList:[{
            value:'',
            name:'全部'
        },{
            value:1,
            name:'已登陆'
        },{
            value:0,
            name:'未登陆'
        }],
        columns:[{
            title:'序号',
            key: "code",//(text,record,index)=>index,
            render:(text,record,index)=>{
                   return ( <span>{index+1}</span>);
            },
        },{
            title: '企业名称',
            dataIndex: 'companyName',
            key: 'companyName',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        },{
            title: '主要产品',
            dataIndex: 'productName',
            key: 'productName',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        },{
            title: '联系人',
            dataIndex: 'contactName',
            key: 'contactName',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        },{
            title: '联系电话',
            dataIndex: 'contactPhone',
            key: 'contactPhone',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        },{
            title: '提交时间',
            dataIndex: 'applyTime',
            key: 'applyTime',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        },{
            title: '提交渠道',
            dataIndex: 'resourceType',
            key: 'resourceType',
            render: (text, record) => {
                return (<span>{['web','小程序'][text-1]}</span>);
            }
        },{
            title: '登录状态',
            dataIndex: 'loginFlag',
            key: 'loginFlag',
            render: (text, record) => {
                return (<span>{['未登陆','已登陆'][text]}</span>);
            }
        },{
            title: '提交人昵称',
            dataIndex: 'applyName',
            key: 'applyName',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        },{
            title: '提交人手机号',
            dataIndex: 'applyPhone',
            key: 'applyPhone',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        },{
            title: '提交人所属企业',
            dataIndex: 'applyCompanyName',
            key: 'applyCompanyName',
            render: (text, record) => {
                return (<span>{text}</span>);
            }
        },{
            title: '企业类型',
            dataIndex: 'applyCompanyType',
            key: 'applyCompanyType',
            render: (text, record) => {
                return (<span>{['采购商','供应商','园区运营方'][text-1]}</span>);
            }
        }]
    }

    componentWillMount() {
        
    }

    componentDidMount() {
        this.getList();
    }
    dateRangeChange = (attr, value, list) => {
        console.log(attr, value, list)
        let params = this.state.params
        params.beginTime = list[0]
        params.endTime = list[1]
        // params[attr + 'Moment'] = value
        this.setState({
            params,
            orderDate:value
        })
    }
    selectChange = (attr, data) => {
        let params = this.state.params
        params[attr] = data
        this.setState({ params })
    }
    inputChange = (e)=>{
        // console.log(e.target.value)
        let params = this.state.params
        params.keyWords = e.target.value
        this.setState({ params })
    }
    onChangePage = (pageNo, pageSize, sort) => {
        this.setState({
            params: {
                pageSize: pageSize,
                pageNo: pageNo,
                total: this.state.params.total,
                keyWords: this.state.params.keyWords,
                beginTime: this.state.params.beginTime,
                endTime:this.state.params.endTime,
                loginFlag:this.state.params.loginFlag,                        
                resourceType:this.state.params.resourceType
            }
        },() => {
        this.getList()
        })
    }
    search = ()=>{
        console.log(this.state.params)
        this.getList();
    }
    reset = ()=>{
        this.setState({
            params: {
                pageSize: 10,
                pageNo: 1,
                total: 0,
                keyWords: '',
                beginTime: '',
                endTime:'',
                loginFlag:'',                        
                resourceType:''
            },
            orderDate:[]
        },() => {
        this.getList()
        })
    }
    getList = () => {
        const config = {
            api: API.enterpriseAuthQueryCustomerResourceList,
            data: this.state.params,
            showLoading: true
        };

        http(config).then(res => {
            if (res.code == 0) {
                let data = res.data.datas;

                this.setState({
                    data,
                    params: {
                        pageSize: res.data.pageSize,
                        pageNo: res.data.pageNo,
                        total: res.data.totalRecord,
                        keyWords: this.state.params.keyWords,
                        beginTime: this.state.params.beginTime,
                        endTime:this.state.params.endTime,
                        loginFlag:this.state.params.loginFlag,                        
                        resourceType:this.state.params.resourceType
                    },

                }, () => {
                    console.log(res.data)
                });
            }
        })
    } 

    


    // addRejectAuthDialog() {
    //     return <Modal
    //         title="驳回"
    //         visible={true}
    //         okText='确定'
    //         cancelText='取消'
    //         closable={false}
    //         maskClosable={false}
    //         onOk={this.gotoReject}
    //         onCancel={this.hidenRejectAuthDialog}
    //         width={400} >
    //         <TextArea rows={4} onChange={this.onRejectInput} placeholder='请输入驳回理由 （100字以内）' maxLength={100} />
    //     </Modal>;

    // }


    render() {
        const { params,orderDate } = this.state;
        
        return (
            <div className="cus-source">
                <div className="search-row">
                    <span className="row-span">
                        <span className="search-text">提交时间</span>
                        <DatePicker.RangePicker style={{ width: '280px', paddingLeft: '34px' }}
                            onChange={this.dateRangeChange.bind(this, 'orderDate')} value={orderDate}/>
                    </span>
                    <span className="row-span">
                        <span className="search-text">提交渠道</span>
                        <Select data={this.state.qdList} placeholder="下单平台"
                                selectMethod={this.selectChange.bind(this, 'resourceType')} style={{ width: '124px' }} />
                    </span>
                    <span className="row-span">
                        <span className="search-text">登入状态</span>
                        <Select data={this.state.loginList} placeholder="下单平台"
                                selectMethod={this.selectChange.bind(this, 'loginFlag')} style={{ width: '124px' }} />
                    </span>
                    <span>
                        <Input placeholder="企业名称/主要产品/联系人/联系电话/提交人手机号"
                            style={{ width: '300px' }} maxLength={20} onChange={this.inputChange}/>
                    </span>
                    <span>
                        <Button onClick={this.search}>搜索</Button>
                    </span>
                    <span>
                        <a href="javascript:;" onClick={this.reset}>重置</a>
                    </span>                   
                </div>
                <div>
                <Table  rowKey="code" columns={this.state.columns} selfProcessData={this.state.data} total={params.total} change={this.onChangePage} current={params.pageNo} />
                </div>
            </div>
        )
    }

}

export default Source;