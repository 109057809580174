import {
  AJAX_TYPE
} from '../config';

export default {
  // 操作日志列表
  [`getLogList`]: {
    url: '/web/crm/log/getList',
    type: AJAX_TYPE.POST
  },

  // 操作日志列表操作模块
  [`getModuleList`]: {
    url: '/web/crm/log/init',
    type: AJAX_TYPE.GET
  },
}