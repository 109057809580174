import React, { Component } from 'react';
import { Breadcrumb, Tabs, Badge } from 'antd';
import './EnterpriseAuthList.less';
import EnterpriseAuthList from 'CMPT/enterpriseAuth/EnterpriseAuthList';
import DriverAuthList from 'CMPT/enterpriseAuth/DriverAuthList';
import VehicleAuthList from 'CMPT/enterpriseAuth/VehicleAuthList';
import OperatorAuthList from 'CMPT/enterpriseAuth/OperatorAuthList'
import { API } from '@/http/api';
import http from '@/http';
import userUtil from "@/utils/user";
const {isUs} = userUtil;
const { TabPane } = Tabs;
const BreadcrumbItem = Breadcrumb.Item;

class EnterpriseAuthLists extends Component {

    state = {
        menuNumber: null
    }

    componentDidMount() {
        this.getTabsCount()
    }

    getTabsCount () {
        const config = {
            api: API.menuNumberRecogniseMgr,
        }
        http(config).then(res => {
            if (res && res.success && res.data) {
                this.setState({ menuNumber: res.data})
            }
        })
    }

    changeTabs = () => {
        this.getTabsCount()
    }

    render() {
        console.log(isUs, 'isUsisUs');
        const {menuNumber} = this.state;
        return (

            <div className="enterprise-auth-con">
                <div className='enterprise-auth-header'>
                    <Breadcrumb>
                        <BreadcrumbItem>认证管理</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <div>
                    <Tabs defaultActiveKey="1" className='tabs' style={{margin: 0}} onChange={this.changeTabs} >
                        <TabPane tab={<Badge count={menuNumber && menuNumber.enterprise}> 企业认证 </Badge>}  key="1" className='tab'>
                            <div>
                                <Tabs defaultActiveKey="1" onChange={this.changeTabs}>
                                    <TabPane tab={<Badge count={menuNumber && menuNumber.enterprise}> 待审批 </Badge>} key="1" className='tab' >
                                        <EnterpriseAuthList state="0" is="1" />   {/* 0表示待审核 */}
                                    </TabPane>
                                    <TabPane tab="已通过" key="2" className='tab' >
                                        <EnterpriseAuthList state="10" is="1" />  {/* 1 表示已通过 */}
                                    </TabPane>
                                    <TabPane tab="已驳回" key="3" className='tab'>
                                        <EnterpriseAuthList state="20" is="1" />  {/* 2表示已驳回  */}
                                    </TabPane>
                                </Tabs>
                        </div>
                        </TabPane>
                        {
                            isUs && 
                                <TabPane tab={<Badge count={menuNumber && menuNumber.driver}> 司机认证 </Badge>} key="2" className='tab' >
                                    <div>
                                        <Tabs defaultActiveKey="1" onChange={this.changeTabs} >
                                            <TabPane tab={<Badge count={menuNumber && menuNumber.driver}> 待审批 </Badge>} key="1" className='tab' >
                                                <DriverAuthList state="0" is="2" />   {/* 0表示待审核 */}
                                            </TabPane>
                                            <TabPane tab="已通过" key="2" className='tab' >
                                                <DriverAuthList state="10" is="2" />  {/* 10 表示已通过 */}
                                            </TabPane>
                                            <TabPane tab="已驳回" key="3" className='tab'>
                                                <DriverAuthList state="20" is="2" />  {/* 20表示已驳回  */}
                                            </TabPane>
                                        </Tabs>
                                </div>
                                </TabPane>}
                                {
                            isUs &&   <TabPane tab={<Badge count={menuNumber && menuNumber.operator}> 操作员认证 </Badge>} key="3" className='tab' >
                                    <div>
                                        <Tabs defaultActiveKey="1" onChange={this.changeTabs} >
                                            <TabPane tab={<Badge count={menuNumber && menuNumber.operator}> 待审批 </Badge>} key="1" className='tab' >
                                                <OperatorAuthList state="0" is="4" />   {/* 0表示待审核 */}
                                            </TabPane>
                                            <TabPane tab="已通过" key="2" className='tab' >
                                                <OperatorAuthList state="10" is="4" />  {/* 10 表示已通过 */}
                                            </TabPane>
                                            <TabPane tab="已驳回" key="3" className='tab'>
                                                <OperatorAuthList state="20" is="4" />  {/* 20表示已驳回  */}
                                            </TabPane>
                                        </Tabs>
                                </div>
                                </TabPane>}
                                {
                            isUs && <TabPane tab={<Badge count={menuNumber && menuNumber.vehicle}> 车辆认证 </Badge>} key="4" className='tab' >
                                    <div>
                                        <Tabs defaultActiveKey="1" onChange={this.changeTabs} >
                                            <TabPane tab={<Badge count={menuNumber && menuNumber.vehicle}> 待审批 </Badge>} key="1" className='tab' >
                                                <VehicleAuthList state="0" is="3" />   {/* 0表示待审核 */}
                                            </TabPane>
                                            <TabPane tab="已通过" key="2" className='tab' >
                                                <VehicleAuthList state="10" is="3" />  {/* 10 表示已通过 */}
                                            </TabPane>
                                            <TabPane tab="已驳回" key="3" className='tab'>
                                                <VehicleAuthList state="20" is="3" />  {/* 20表示已驳回  */}
                                            </TabPane>
                                        </Tabs>
                                </div>
                                </TabPane>
                        }
                </Tabs>
                </div>
                

            </div>


        );
    }
}

export default EnterpriseAuthLists;