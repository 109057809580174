import React, { Component } from 'react';
import { Link } from 'react-router-dom'

class JobItemContract extends Component {
    // {    pathname:"/app/operate/serviceCenter/logistics/complaint/orderDetail",    hash: `#${row.encode}`}
    // {    pathname:"/app/operate/serviceCenter/logistics/maintain/orderDetail",    hash: `#${row.encode}`}

    render() {
        const linkObj = this.props.type == 1 ? { pathname: "/app/operate/serviceCenter/logistics/complaint/orderDetail", hash: `#${this.props.encode}` } : { pathname: "/app/operate/serviceCenter/logistics/maintain/orderDetail", hash: `#${this.props.encode}` }
        return (
            <Link to={linkObj} className="job-item job-item-order">
                <p className="job-item-title">
                    {this.props.name}
                    {
                        this.props.status == 1 ?
                            <span className="job-item-order-label urgent">紧急</span> :
                            <span className="job-item-order-label commonly">一般</span>
                    }
                </p>
                <p className="job-item-text">
                    {this.props.location}
                </p>
                <p>
                    预计处理时间：{this.props.time}
                </p>
            </Link>
        )
    }
}

export default JobItemContract
