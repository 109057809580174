import {
    AJAX_TYPE
} from '../config';


const prefix = 'sourcing';

export default {
    // 获取询价列表
    [`${prefix}GetList`]: {
        url: '/web/mall/inquiryClient/selectListRemoteList',
        type: AJAX_TYPE.POST
    },
    // 获取询价详情
    [`${prefix}GetDetail`]: {
        url: '/web/crm/inquiry/detail',
        type: AJAX_TYPE.POST
    },
    // 敏感词校验
    [`${prefix}SensitiveCheck`]: {
        url: '/web/crm/inquiry/sensitiveCheck',
        type: AJAX_TYPE.POST
    },
    // 审核列表
    [`${prefix}AuditList`]: {
        url: '/web/crm/inquiry/selectAuditLogByInquiryIdList',
        type: AJAX_TYPE.POST
    },
    // 获取所有的企业
    [`${prefix}EnterpriseList`]: {
        url: '/web/mall/enterprise/getInquiryAuthList',
        type: AJAX_TYPE.POST
    },

    // 获取发布状态列表
    [`${prefix}InquiryStatusList`]: {
        url: '/web/crm/inquiry/getInquiryStatusList',
        type: AJAX_TYPE.GET
    },

    // 审核：通过或者驳回
    [`${prefix}Audit`]: {
        url: '/web/crm/inquiry/audit',
        type: AJAX_TYPE.POST
    },
    // 强制下架
    [`${prefix}ForceOffShelves`]: {
        url: '/web/crm/inquiry/forceOffShelves',
        type: AJAX_TYPE.POST
    },
    
    // 运力审核------------------
    // 运力列表
    [`${prefix}TcAuditByPage`]: {
        url: '/api/isx/v1/admin/tcAudit/queryAuditByPage',
        type: AJAX_TYPE.POST
    },
    // 运力审核单详情
    [`${prefix}TcAuditGetAuthDetail`]: {
        url: '/api/isx/v1/admin/tcAudit/getAuthDetail',
        type: AJAX_TYPE.POST
    },
    // 处理审核单
    [`${prefix}TcAuditDoAudit`]: {
        url: '/api/isx/v1/admin/tcAudit/doAudit',
        type: AJAX_TYPE.POST
    },
    // 审核日志
    [`${prefix}TcAuditAuditRecord`]: {
        url: '/api/isx/v1/admin/tcAudit/auditRecord/:code',
        type: AJAX_TYPE.GET
    },
    // 运力强制下架
    [`${prefix}TcAuditForcedDown`]: {
        url: '/api/isx/v1/admin/tcAudit/forcedDown',
        type: AJAX_TYPE.POST
    },
    // 发货审核------------------
    // 发货列表
    [`${prefix}QueryAuditByPage`]: {
        url: '/api/isx/v1/admin/freightAudit/queryAuditByPage',
        type: AJAX_TYPE.POST
    },
    // 发货审核单详情
    [`${prefix}GetAuthDetail`]: {
        url: '/api/isx/v1/admin/freightAudit/getAuthDetail',
        type: AJAX_TYPE.POST
    },
    // 处理审核单
    [`${prefix}FreightAuditDoAudit`]: {
        url: '/api/isx/v1/admin/freightAudit/doAudit',
        type: AJAX_TYPE.POST
    },
    // 审核日志
    [`${prefix}FreightAuditAuditRecord`]: {
        url: '/api/isx/v1/admin/freightAudit/auditRecord/:code',
        type: AJAX_TYPE.GET
    },
    // 货物强制下架
    [`${prefix}FreightAuditForcedDown`]: {
        url: '/api/isx/v1/admin/freightAudit/forcedDown',
        type: AJAX_TYPE.POST
    },
    // ==================== 出租经营信息 start =====================
    // 列表
    [`${prefix}BusinessInformationList`]: {
        url: '/api/isx/v1/admin/audit/businessInformation/queryAuditByPage',
        type: AJAX_TYPE.POST
    },
    // 详情
    [`${prefix}BusinessInformationDetail`]: {
        url: '/api/isx/v1/admin/audit/businessInformation/getBusinessInformationDetail/:code',
        type: AJAX_TYPE.GET
    },
    // 审核
    [`${prefix}AuditBusinessInformation`]: {
        url: '/api/isx/v1/admin/audit/businessInformation/auditBusinessInformation',
        type: AJAX_TYPE.POST
    },


    
}