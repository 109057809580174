import React, { Component } from "react";
import { Breadcrumb, Tabs } from "antd";
import GoodsSetting from "./GoodsSetting";
import "./BusinessSetting.less";
import userUtil from "@/utils/user";
const {isUs} = userUtil;
const { TabPane } = Tabs;
const BreadcrumbItem = Breadcrumb.Item;

class BusinessSetting extends Component {
  render() {
    return (
      <div className="banner_all">
        <div>
          <Breadcrumb>
            <BreadcrumbItem>业务设置</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div>
          <Tabs defaultActiveKey="1" className="tabs">
            <TabPane tab="商品设置" key="1" className="tab">
              <GoodsSetting source="1" {...this.props} />
            </TabPane>
          </Tabs>
        </div>
      </div>
    );
  }
}

export default BusinessSetting;
