import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { API } from '@/http/api';
import http from '@/http';
import userUtil from '@/utils/user';
import ContractList from './list/ContractList';
import AuditList from './audit/AuditList';
import { AUTH_KEYS } from '@/utils/auth';
import AuthContainer from 'CMPT/common/auth/AuthContainer';
import { Breadcrumb, Button, Tabs  } from 'antd';
const BreadcrumbItem = Breadcrumb.Item;
const TabPane = Tabs.TabPane;
class List extends Component {
    state = {
        total: {
            contract: 0,
            audit: 0
        },
        activeTabKey: '1'
    }
    componentWillMount() {
        let search = this.props.history.location.search
        if (search) {
            const formatData = this.formatSearch(search)
            if (formatData.type) {
                this.setState({
                    activeTabKey: '2'
                })
            }
        }
    }
    componentDidMount() {
        const config = {
            api: API.contractSumTotal,
            showLoading: true
        }
        http(config).then(res => {
            this.setState({
                total: {contract: res.data.contractSumTotal, audit: res.data.approveContractSumTotal}
            })
        })
    }
    formatSearch = (ss) => {
        const ssSplit = ss.split('?')[1].split('&')
        let obj = {}
        ssSplit.forEach(per => {
            let perSplit = per.split('=')
            obj[perSplit[0]] = perSplit[1]
        })
        return obj
    }
    goAdd = () => {
        this.props.history.push('/app/operate/contract/add')
    }
    changeTab = (tab) => {
        this.setState({
            activeTabKey: tab
        })
    }
    render() {
        const { total } = this.state
        return (
            <div className="contract-list-out">
                <div className="contract-header">
                    <Breadcrumb>
                        <BreadcrumbItem>操作中心</BreadcrumbItem>
                        <BreadcrumbItem>租客合同</BreadcrumbItem>
                    </Breadcrumb>
                    <AuthContainer authCodes={AUTH_KEYS.OPERATE_CONTRACT_ADD}>
                        <Button type="primary" onClick={this.goAdd}>新增合同</Button>
                    </AuthContainer>
                    
                </div>
                <div className="contract-content">
                    <Tabs activeKey={this.state.activeTabKey} animated={false} onChange={this.changeTab}>
                        {userUtil.hasAuth(99020101) && <TabPane tab={"合同列表(" + total.contract + ")"} key="1">
                            <ContractList history={this.props.history}/>
                        </TabPane>}
                        {total.audit > 0 && userUtil.hasAuth(99020102) && <TabPane tab={"审批列表(" + total.audit + ")"} key="2">
                            <AuditList history={this.props.history} />
                        </TabPane>}
                    </Tabs>
                </div>
            </div>
        );
    }
}

export default List;