import React, { Component } from 'react';
import { Modal, Button, Row, Col, message, DatePicker, Form, Input, TimePicker } from 'antd';
import debounce from 'lodash/debounce';
import moment from "moment";
import { taskEdit } from './service/taskService'
const { TextArea } = Input;


class EditSchedule extends Component {
    handleOk = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            let fieldsObject = this.props.form.getFieldsValue();
            fieldsObject.planDate = fieldsObject.planDate.format('YYYY-MM-DD')
            fieldsObject.planTime = fieldsObject.planTime.format('HH:mm')
            fieldsObject.code = this.props.data.encode;
            if (!err) {
                taskEdit(fieldsObject).then(res=>{
                    if(res.code == 0){
                        message.success('修改日程成功')
                        // console.log(this.props)
                        this.props.getList()
                        this.props.cancel()
                    }
                })
            }
        });
    }

    handleCancel = () => {
        this.props.cancel();
    }


    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            let fieldsObject = this.props.form.getFieldsValue();
            fieldsObject.planDate = fieldsObject.planDate.format('YYYY-MM-DD')
            fieldsObject.planTime = fieldsObject.planDate.format('HH:mm')
            if (!err) {
                taskEdit(fieldsObject).then(res=>{
                    if(res.code == 0){
                        message.success('新增日程成功')
                        this.porps.getList()
                        this.props.cancel()
                    }
                })
            }
        });
    }
    componentWillReceiveProps(nextProps){
        if(nextProps.data.encode&&nextProps.data.encode!==this.props.data.encode){
            let {planDate,planTime,taskName} = {...nextProps.data}
            nextProps.form.setFieldsValue({
                planDate:moment(planDate,'YYYY-MM-DD'),
                planTime:moment(planTime,'HH:mm'),
                taskName:taskName
            })
        }
    }

    render() {
        const {
            getFieldDecorator, getFieldsError, getFieldError, isFieldTouched,
        } = this.props.form;
        const config = {
            rules: [
                { required: true, message: '请选择' }
            ]
        }
        return (
            <Modal
                title="添加日程"
                okText="保存"
                visible={this.props.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                className="schedule-follow-modal"
                width={400}
            >
                <Form onSubmit={this.handleSubmit}>
                    <Row gutter={24}>
                        <Col className="gutter-row" span={24}>
                            <Form.Item label="日期">
                                {getFieldDecorator('planDate', config)(
                                    <DatePicker style={{ width: '100%' }} placeholder="请选择" format='YYYY-MM-DD' />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={12}>
                        <Col className="gutter-row" span={24}>
                            <Form.Item label="时间">
                                {getFieldDecorator('planTime', config)(
                                    <TimePicker style={{ width: '108px' }} format='HH:mm' allowClear={false} placeholder="请选择" onChange={this.handleChange} />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={80}>
                        <Col className="gutter-row" span={24}>
                            <Form.Item label="任务">
                                {getFieldDecorator('taskName', {
                                    rules: [
                                        { required: true, message: '请输入' },
                                        { max:30, message:'不能超过30' }
                                    ]
                                })(
                                    <TextArea maxLength={20} placeholder="请输入" autosize={{ minRows: 4, maxRows: 4 }} />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

            </Modal>
        );
    }
}
const EditScheduleForm = Form.create({ name: 'EditScheduleForm' })(EditSchedule);

export default EditScheduleForm;