import React, { Component } from 'react';
import { Form, Select, Input, Button, DatePicker } from 'antd';
import moment from 'moment';


class SearchForm extends Component{

  reset = () => {
    const { form } = this.props;
    form.resetFields();
    this.props.form.validateFields(
      { force: true },
      (err, values) => {
        if (!err) {
          this.props.reset(values);
        }
      },
    );
  }

  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <Form layout='inline'>
        <Form.Item label="">
          {getFieldDecorator('keyword', {
            rules: [{ required: false, message: 'Please select your country!' }],
          })(
            <Input
              style={{width: '250px'}}
              placeholder='企业名称'
            />
          )}
        </Form.Item>
        <Form.Item label="">
          <Button type='primary' onClick={this.props.onSearch}>搜索</Button>
        </Form.Item>
        <Form.Item label="">
          <a className='reset' onClick={this.reset}>重置</a>
        </Form.Item>
      </Form>
    );
  }
}

const onValuesChanges = (props, changedValues, allValues) => {
  let result = {...allValues,pageNo: 1}
 
  for(let key in result){
    if(result[key] == undefined){
      result[key] = ''
    }
  }
  props.onValuesChange(result)
}

export default Form.create({ name: "EnterpriseAuthList", onValuesChange: onValuesChanges })(SearchForm);
