import React, { Component } from 'react';
import { Row,Col,Select,Form ,Input,DatePicker,Upload,Icon,InputNumber,message} from 'antd';
import http from '@/http';
import { API } from '@/http/api';
import BsUpload from '@/components/common/Upload'
import CxUpload from './Upload'
import moment from 'moment';
import 'moment/locale/zh-cn';

import "../../financeApproval/finance.less"
import '../bill.less'

moment.locale('zh-cn');
const Item = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;
const imgType = ['png','jpg','jpeg','bmp'];
const payChannel = [{
    name:'现金',
    value:1
},{
    name:'刷卡',
    value:2
},{
    name:'转账',
    value:3
},{
    name:'支付宝',
    value:4
},{
    name:'微信',
    value:5
},{
    name:'其他',
    value:9
}];

class Collection extends Component {
    state = {
        pictureInfoList:[]
    }
    componentDidMount() {
    }
    changeaddModal(type,val) {       
        const { file }= val;
        if(file.response) {
            const obj = file.response.file;
            const pictureInfo = {
                uid: obj.sKey,
                name: obj.sFilename,
                mediaExt: obj.sExt,
                type: obj.sExt,
                mediaKey: obj.sKey,
                url: `${process.env.IMG_VIEWDOMAIN}house/${obj.sKey}/4000x3000.${obj.sExt}`
            };
            console.log(pictureInfo);
        }
    }
    imgUp = fileList => {
        setTimeout(()=>{
            this.props.form.setFieldsValue({
                photos:fileList
            })
        },0)
    }
    changeNumber(e){
        this.props.form.setFieldsValue({
            payAmount:1
        })
    }
    render(){
        const { billInfo,textDes,resetBill } = this.props;
        const { getFieldDecorator } = this.props.form;
        const optionsChannel = payChannel.map(per => {
            return <Option value={per.value} key={per.value}>{per.name}</Option>
        })
        
        return (
            <div className="approval-baseInfo"> 
                <div className="title">{textDes.title1}</div>
                    <Row className="fs12 mb24">
                        {textDes.name1}：{billInfo.paidAmount}元 （{textDes.name2}：{billInfo.couldPayAmount}元）
                    </Row>
                    <Row className="fs12 mb9">
                        <Col span={8}>
                            <Item label={textDes.payAmount} className="w237">
                                {getFieldDecorator('payAmount', {
                                    initialValue:resetBill.amount,
                                    rules: [{
                                        required: true, message: '请输入金额',
                                    }]
                                })(
                                    <Input placeholder="请输入" suffix="元" type="number"/>       
                                )}
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item label={textDes.date} className="w237">
                                {getFieldDecorator('date', {
                                    initialValue:resetBill.date?moment(resetBill.date):undefined,
                                    rules: [{
                                        required: true, message: '请选择日期',
                                    }]
                                })(
                                    <DatePicker placeholder="请选择" className="w237"/>
                                )}
                            </Item>
                        </Col>
                    </Row> 
                    <Row className="fs12 mb9">
                        <Col span={8}>
                            <Item label="支付途径" style={{ width: '237px'}}>
                                {getFieldDecorator('paymentChannel', {
                                    initialValue:resetBill.channel,
                                    rules: [{
                                        required: true, message: '支付途径不能为空',
                                    }]
                                })(
                                    <Select placeholder="请选择">
                                        {optionsChannel}
                                    </Select>               
                                )}
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item label="票据号" className="w237">
                                {getFieldDecorator('receiptNo', {
                                    initialValue:resetBill.receiptNo,
                                    rules: [{
                                        required: true, message: '票据号不能为空',
                                    }]
                                })(
                                    <Input placeholder="请输入" maxLength={30}/>                      
                                )}
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item label="银行流水号" className="w237">
                                {getFieldDecorator('bankStatementNo', {
                                    initialValue:resetBill.bankStatementNo,
                                    rules: [{
                                        required: false, message: '请输入',
                                    }]
                                })(
                                    <Input placeholder="请输入" maxLength={20}/>                    
                                )}
                            </Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <Item label={textDes.companyBank} className="w237">
                                {getFieldDecorator('companyBank', {
                                    initialValue:resetBill.companyBank,
                                    rules: [{
                                        required: false, message: '请输入',
                                    }]
                                })(
                                    <Input placeholder="XX银行XX支行" autoComplete="off" maxLength={30}/>                    
                                )}
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item label={textDes.companyAccount} className="w237">
                                {getFieldDecorator('companyAccount', {
                                    initialValue:resetBill.companyAccount,
                                    rules: [{
                                        required: false, message: '请输入',
                                    }]
                                })(
                                    <Input placeholder="请输入" autoComplete="off" maxLength={20}/>                    
                                )}
                            </Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} className="delMaohao">                        
                            <Item label="相关凭证  注: 请上传5M以内的PNG、JPEG、JPG">
                                {getFieldDecorator('photos',{
                                })(
                                    <CxUpload imgUp={this.imgUp} fileList={resetBill.fileList}/>                                    
                                )}
                            </Item>
                        </Col>
                    </Row>
                    <Row>
                    <Col span={24}>
                        <Item label={textDes.description}>
                            {getFieldDecorator('description', {
                                initialValue:resetBill.description,
                                rules: [{
                                    required: false, message: '请输入',
                                }]
                            })(
                                <TextArea rows={4} placeholder="请输入" maxLength={200}/>   
                            )}
                        </Item>
                    </Col>
                </Row>          
                </div>    
        )
    }
}

export default Collection;