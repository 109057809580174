import React, { Component } from "react";
import { Modal,Checkbox, message } from "antd";
 import Table from "CMPT/common/Table";
import http from "@/http";
import { API } from "@/http/api";
import FilterPanel from "CMPT/common/FilterPanel";
import TableRenderHelp from "@/utils/TableRenderHelp.js";
import { FilterType } from '@/utils/fliter';
import ImgPreview from 'CMPT/common/ImgPreview';
import "./index.less";


class chooseShop extends Component {
    
    state={

      total: 0, // 当前数据的总数
      pageNo: 1, // 当前所展示的页码
      currentPageCount: 1, //当前页面的数量
      pageSize:10,

      selectData:[],//当前约定，返回数据 都能被选中
      columns:this.createColumns(), // 列表的字段集合
      httpData:[], // 列表展示 所用数据

      filterConfig:this.createFilterConfig(),// 过滤条的配置

      imgList: [],
      currentImg: undefined,
    }


    onSelectChange(e,data){
        var checked = e.target.checked;
        let {selectData,httpData} = this.state;

        // 选中的那一项
        data.checked = checked;
 

        // 已选中
        if(checked){
            if(selectData.indexOf(data)<0){
                selectData.push(data);
                this.setState({httpData,selectData});
            }
        }else{
            if(selectData.indexOf(data)>=0){
                selectData.remove(data);
                this.setState({httpData,selectData});
            }
        }


    }

    /**
     * 列表字段
     * @returns 
     */
    createColumns(){
        let help = new TableRenderHelp();
  
        return [
            help.createRenderColumn(
                "",  "code", "code", 
                (text, record) => {
                  return   <Checkbox checked={record.checked}    onChange={(e)=>this.onSelectChange(e,record )}/> ;
                }
              ),
            help.createRenderColumn(
                "Logo",  "logo", "logo", 
                (text, record) => {
                  var logo =  record.activityLogo?record.activityLogo:record.logo;
                  return  logo? <img src={logo} onClick={logo ? () => { this.showImgPreview(logo) }:undefined} style={{ width: "100px", height: "100px" }} alt="Logo"/> :<span>--</span>;;
                }
              ),
            help.createTextColumn("企业名称", "enterpriseName"),
            help.createTextColumn("企业简称", "enterpriseShortName"),
            help.createTextColumn("店铺名称", "shopName"),
           // help.createTextColumn("状态", "auditStatusName"),
            help.createTextColumn("注册时间", "updateTime"),
            {
              title: '操作',
              render: (record, index) => <div>
                   {/** TODO  后期应该对 快速点击 做屏蔽，同时 对请求做 等待处理 */}
                <span className="text-color-highlight" style={{marginRight:'16px', marginTop:'10px',whiteSpace: 'nowrap'}} onClick={() => { this.doAddByOne(record) }}>添加</span> 
                  <span className="text-color-highlight" style={{marginRight:'16px', marginTop:'10px',whiteSpace: 'nowrap'}} onClick={() => { this.addAndTopByOne (record) }}>添加并置顶</span> 
               </div>
          }
        ];
  
      }

       /**
        * 创建 接口所需参数
        * @returns 
        */
      createQueryParam = () => {
        let {   pageNo, pageSize,filterParm} = this.state;
        let param = {   pageSize,pageNo,status:10};
     
        Object.keys(filterParm).forEach(key => {
          if (filterParm[key]) {
            param[key] = filterParm[key];
          }
        });
     
        return param;
      }

      goQuery=()=>{
        this.setState({ selectData:[]});

        // setup 1:基本入参
        const config = {
         api: API.brandShopList,
         data:  this.createQueryParam(),
        };
       
       // setup 2:请求，并初步处理
       http(config).then(res => {
         if (res && res.success && res.data) {
             this.convertHttpData(res.data);
         }
       });

   }

 

  /**
   * 针对 接口返回数据 进行调整
   * @param {*} data 
   */
   convertHttpData(data){

     var  httpData= data.datas||[];

      // 一开始，肯定是 不选中 状态
      var imgList=[]
      if(httpData.length){
      httpData.forEach(item=>{
        item.checked = false; 

        var logo =  item.activityLogo?item.activityLogo:item.logo;
        if( logo){
          imgList.push( logo);
        }
  
       })
     }



    this.setState({
      imgList,
      httpData,
      selectData:[],
      total: data.totalRecord
    });
   }
     

   closeImgPreview = () => {
    this.setState({
        currentImg: undefined
    })
}

showImgPreview = (currentImg) => {
  this.setState({ currentImg  })
}

   /**
    * 将列表中的数据全部选中。  这个列表的每个数据 必定是 可选的，不存在 无法选中的情况
    * @param {*} e 
    * @returns 
    */
      selectAll=(e)=>{
        let {httpData} = this.state;
        if(!httpData || !httpData.length){
            return;
        }

        const checked = e.target.checked;
         var selectData=[];// 如果未全选，这里就是0长度的数组

       
          httpData.forEach(function(e) {
            e.checked = checked;

            if(checked){//当前 全选，才加入数组
               selectData.push(e);
            }
           });
      


         this.setState({selectData,httpData});
      }

      /**
       * 已选中的数据 ，进行添加并置顶
       */
      addAndTopBatch=()=>{
        let {selectData}=this.state;
        this.addAndTop(selectData);
    }

    /**
     * 已选中的数据 ，进行添加 
     */
    doAddBatch=()=>{
      let {selectData}=this.state;
 
      this.doAdd(selectData);
    }

    /**
     * 针对某一条 进行添加并置顶
     * @param {*} data 
     */
    addAndTopByOne=(data)=>{
       // 构造成一个数组，作为一个集合去操作
       this.addAndTop(new Array(data));
    }

        /**
     * 针对某一条 进行添加
     * @param {*} data 
     */
    doAddByOne=(data)=>{
             // 构造成一个数组，作为一个集合去操作
       this.doAdd(new Array(data));
    }

    /**
     * 针对集合，进行 添加置顶操作
     * @param {*} dataArray 
     */
    addAndTop=(dataArray)=>{
        var data=[];
        if(dataArray&&dataArray.length){
        dataArray.forEach(item=>{
            var newItem ={...item }
            newItem.flagStatus = 1;
            newItem.checked = undefined;

            data.push(newItem); //1 标识 添加并且置顶
         });
        }


        this.doUpdate(data);
     
    }

      /**
     * 针对集合，进行 添加操作
     * @param {*} dataArray 
     */
    doAdd=(dataArray)=>{
      var data=[];
      if(dataArray&&dataArray.length){
        dataArray.forEach(item=>{
          var newItem ={...item }
          newItem.flagStatus = 0;//0 标识 添加不置顶
          data.push(newItem);
         });
      }


       this.doUpdate(data);
    }

    
    /**
     * 添加/置顶实际是 update操作
     * @param {*} data 
     */
    doUpdate=(dataSource)=>{
       // 不能带时间
       var data = [];
       if(dataSource&&dataSource.length){
        dataSource.forEach(item=>{
          var newItem = {...item};

          newItem.checked = undefined;
          newItem.updateTime = undefined;//0 标识 添加不置顶

          data.push(newItem);
          });
       }
       
         // setup 1:基本入参
     const config = {
       api: API.brandShopBatch,
       data 
      };

    // setup 2:请求，并初步处理
      http(config).then(res => {
        if (res && res.success && res.data) {
          message.success("添加成功");
           this.goQuery();
           if(this.props.refresh){
             this.props.refresh();
          }
        }
    });
  }

 
    /**
     * 创建 筛选面板参数
     * @returns 
     */
    createFilterConfig(){
        return {
           config:[
            this.createFilterRow()
            ],
            btnLast:true
    
        } ;
      }
    
    
      createFilterRow(){
        return {
          childList:[
    
            {
                type:FilterType.DATE_RANGE,
                label:'注册日期',
                config:{
                  field:['startDate','endDate'],
                }
              },      
            {
              type:FilterType.INPUT,
              //label:'意向单编号',
              config:{
                field:'searchName',
                placeholder:'请输入企业名称,店铺名称'
              }
            },
            
    
          ]
        }
      }


      /**
       * 筛选面板的回调
       * @param {*} filterParm 
       */
     onFilterParmChange=(filterParm)=>{
        this.setState({filterParm,pageNo:1},this.goQuery);// 每次搜索，从第1页取数据
     }

  
      //分页
      onChangePage = (pageNo, pageSize, sort) => {
        this.setState(
          {
            pageNo: pageNo,
            pageSize: pageSize,
          },this.goQuery );
      };


      /**
       * 关闭当前窗口
       */
    close=()=>{
        if(this.props.close){
            this.props.close();
        }
            
    }

    /**
     * 
     * 列表底部 功能按钮
     * @returns 
     */
    createFooter=()=>{
        let {httpData,selectData} = this.state;
        var isAllSelected =  httpData? (selectData.length==httpData.length && selectData.length>0):false;
        var isNoneSelected = !(selectData&&selectData.length);

        return (<div className="footer">
            <Checkbox  checked={isAllSelected} onChange={ this.selectAll} /> 
            <span className={ "text-color-highlight"} style={{ marginLeft:'2px'}}  >全选</span>  
        <span   className={isNoneSelected?"text-color-disable": "text-color-highlight"} style={{marginRight:'16px',marginLeft:'16px', whiteSpace: 'nowrap'}} onClick={ isNoneSelected?null:this.doAddBatch   }>添加</span> 
        <span className={isNoneSelected?"text-color-disable": "text-color-highlight"} style={{marginRight:'16px', whiteSpace: 'nowrap'}} onClick={ isNoneSelected?null:this.addAndTopBatch   }>添加并置顶</span>
         </div>);
    }

    render() {

      let { columns,httpData,filterConfig,pageNo,total,currentImg,imgList} = this.state;

        return (<Modal 
            title={`添加推荐店铺(仅能添加已通过状态)`}
            visible={true}
            cancelText=""
            okText="提交"
            onCancel={this.close}
            width={1200} 
            footer={ []  }
            className="chooseshop_modal">
             
             
            <FilterPanel data={filterConfig}   onFilterChange={this.onFilterParmChange}/>
            <ImgPreview show={(currentImg&&imgList&&imgList.length)?true:false} currentImg={currentImg} imgList={imgList} onClose={this.closeImgPreview}/>

           <Table
           columns={ columns}
          rowKey={(record, index) => record.code}
          selfProcessData={httpData}
          total={total}
          change={this.onChangePage}
          current={ pageNo}
          footer={  this.createFooter}
        />
            
            </Modal>);
    }
}

export default chooseShop;
