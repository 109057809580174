import React, { Component } from "react";
import { Form, Input, Button, message } from "antd";
import "./Login.less";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import FindPassword from "./FindPassword";
import Experience from "./Experience";
import { ajax, login, loginImg, logins } from "@/http";
import { API } from "@/http/api";
import md5 from "js-md5";
import { getSignSha256 } from "../utils/sign";
import userUtil from "@/utils/user";
import Script from "react-load-script";
import Cookies from "js-cookie";

class LoginForm extends React.Component {
  state = {
    loading: false,
  };

  onLoadScript() {}

  componentWillMount() {
    //console.log( '  componentWillMount   ---> '  );
  }

  componentWillUpdate() {
    // console.log( '  componentWillUpdate   ---> '  );
  }

  componentWillUnmount() {
    // console.log( '  componentWillUnmount   ---> '  );
  }

  componentDidUpdate() {
    // 组件更新后触发
    //console.log( '  componentDidUpdate   ---> '  );
  }

  componentWillReceiveProps(nextProps) {
    // 接收到新的props时触发
    //console.log( '  componentWillReceiveProps   ---> '  );
  }

  componentDidMount() {
    // 如果扫码登录失败，后端会传递过来一个错误信息，而且有效期为5秒
    var errorMsg = Cookies.get("idf10-login-error-msg");
    if (errorMsg != null && errorMsg != undefined && errorMsg.trim() != "") {
      message.error(errorMsg);
    }
  }

  handleScriptCreate() {
    //this.setState({ scriptLoaded: false })
    //console.log( '  handleScriptCreate   ---> '  );
  }

  handleScriptError() {
    //this.setState({ scriptError: true })
    //console.log( '  handleScriptError   ---> '  );
  }

  handleScriptLoad() {
    //this.setState({ scriptLoaded: true })
    window.WwLogin({
      id: "wx_reg",
      appid: userUtil.wxQrCodeObj.CorpId,
      agentid: userUtil.wxQrCodeObj.AgentId,
      redirect_uri: userUtil.wxQrCodeObj.RedirectUri,
      state: "ok",
      href: "https://sys-static-1304570942.cos.ap-nanjing.myqcloud.com/static/css_wx.css",
    });
  }

  render() {
    return (
      <div className="login-form-box">
        <div className="login-form">
          {/* <div>{userUtil.wxQrCodeObj.AgentId}</div>
          <div>{userUtil.wxQrCodeObj.RedirectUri}</div> */}
          <div className="title-qr">企业微信扫码登录</div>
          <div id="wx_reg"></div>
        </div>
        <Script
          url="https://rescdn.qqmail.com/node/ww/wwopenmng/js/sso/wwLogin-1.0.0.js"
          onCreate={this.handleScriptCreate.bind(this)}
          onError={this.handleScriptError.bind(this)}
          onLoad={this.handleScriptLoad.bind(this)}
        />
      </div>
    );
  }
}

const WrappedLoginForm = Form.create({ name: "LoginForm" })(LoginForm);

class Login extends Component {
  render() {
    return (
      <div className="page-login">
        <div className="logo">
          <div className="logn-name">
            {" "}
            <img
              src={require("../images/logo-idf10.svg")}
              style={{ width: "130px", marginRight: "10px", marginTop: "3px" }}
              alt=""
            />{" "}
            运营管理平台
          </div>
          <div className="slogan"></div>
        </div>
        <Switch>
          <Route path="/login/findPassword" component={FindPassword} />
          <Route path="/login/experience" component={Experience} />
          {/* 考虑 后续 切换 登录方式。 */}
          <Route component={WrappedLoginForm} />
        </Switch>
      </div>
    );
  }
}

export default Login;
