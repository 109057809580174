import {
    AJAX_TYPE
} from '../config';

// 装修

const prefix = 'zonedecoration';

export default {
    // 录入装修信息
    [`${prefix}Add`]: {
        url: '/web/zone/decoration/add',
        type: AJAX_TYPE.POST
    },
    // 删除更新信息
    [`${prefix}Delete`]: {
        url: '/web/zone/decoration/delete',
        type: AJAX_TYPE.GET
    },
    // 获取区域下所有的装修管理
    [`${prefix}GetByZoneId`]: {
        url: '/web/zone/decoration/getByZoneId',
        type: AJAX_TYPE.GET
    },
    // 更新装修信息
    [`${prefix}Update`]: {
        url: '/web/zone/decoration/update',
        type: AJAX_TYPE.POST
    },
}