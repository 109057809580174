import http from '@/http';
import {
    useState
} from "react";

/**
 * 使用场景：http请求之前将某一控件置为加载中，请求处理完成之后取消加载中状态，除此之外没有其他改变该控件为加载中的方式。
 */
export default function useActionLoading() {
    const [loading, toggleLoading] = useState(false);

    /**
     * 返回promise
     * @param {*} httpConfig http配置
     * @param {*} cb 请求成功后回调
     */
    function takeAction(httpConfig, cb) {
        toggleLoading(true);
        return http(httpConfig).then(ajaxData => {
            toggleLoading(false);
            if (ajaxData.success && cb) {
                cb(ajaxData);
            }
            return ajaxData;
        }).catch(error => {
            toggleLoading(false);
            return Promise.reject(error);
        });
    }

    return [loading, takeAction];
}