import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import {
  HelpChannel,
  convertValueToEnumLabel
} from "CMPT/common.js";
import { Button } from "antd";

import userUtils from '@/utils/user';
import { AUTH_KEYS } from "@/utils/auth";
const { checkAuthByKey } = userUtils;
const ruleManageAuth = checkAuthByKey(AUTH_KEYS.RULE_MANAGE_RULE_MANAGE); // 管理规则

const Card = ({
  id,
  title,
  index,
  status,
  createTime,
  updateTime,
  moveCard,
  edit,
  goChangeStatus,
  watchRule
}) => {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: "card",
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    }
  });
  const [{ isDragging }, drag] = useDrag({
    item: { type: "card", id, index },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <div ref={ref} style={{ opacity }}>
      {/* <div onClick={()=>{ adds()}}>sdsd</div>
         黄瓜大傻逼 {title} */}
      <div
        className="cardItem"
        onDoubleClick={() => {
          //onUnfold(index);
        }}
      >
        <div className="">
          <img
            src={require("../../images/iconCatalog.png")}
            width="24"
            alt="img"
          />{" "}
        </div>
        <div className="li_1 li">{title}</div>
        <div className="li_2 li">{createTime}</div>
        <div className="li_3 li">{updateTime}</div>
        <div className="li_4 li">{status == 1 ? '未发布' : '已发布'}</div>
        <div className="li_5 li">
          {
            status == 1 ?
            <>
              {
                ruleManageAuth?
                <>
                  <Button className="btn" type="primary" onClick={() => {goChangeStatus(1, id)}} >发布</Button>
                  <Button className="btn" type="primary" onClick={() => {edit(id)}} >编辑</Button>
                  <Button className="btn" onClick={() => {goChangeStatus(0, id)}} >删除</Button>
                </>
                : null
              }
            </>
            :
            <>
              <Button className="btn" type="primary" onClick={() => {watchRule(id)}} >查看</Button>
              {
                ruleManageAuth?
                <>
                  <Button className="btn" onClick={() => {goChangeStatus(2, id)}} >下架</Button>
                </>
                : null
              }
            </>
          }
          </div>
      </div>
    </div>
  );
};
export default Card;
