import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import ISelect from 'CMPT/common/Select';
import Table from 'CMPT/common/Table';
import http from '@/http';
import { API } from '@/http/api';
import { Input, Row, Col, DatePicker, Icon, Select, Spin, message, Modal } from 'antd';
import Print from './Print'
import "../financeApproval/finance.less"
const { RangePicker } = DatePicker;
const Option = Select.Option;
const { TextArea } = Input;

const feeTypeList = ['定金', '保证金', '合同保证金', '装修保证金', '物业保证金', '租金', '手续费', '服务费', '保洁费', '物业费', '网费', '水费', '电费', '滞纳金', '违约金', '搬家费', '耗材费', '其他费用'];
const billTypeList = ['合同', '运营'];
const success = () => {
    message.success('催缴成功，我们将以短信形式通知付款人');
};
const info = () => {
    message.info('每小时只能催缴一次，请稍候再试');
};
class ReceiptList extends Component {
    state = {
        billInfo: {},
        showReceiptModal: false,
        showRefundModal: false,
        countData: {
            all: 0,
            allPaid: 0,
            notPaid: 0,
            overdue: 0
        },
        fetching: false,
        dateValue: [],
        currentIndex: 0,
        dateType: [
            {
                name: '开据时间',
                value: 2
            }],
        payChannel: [{
            name: '现金',
            value: 1
        }, {
            name: '刷卡',
            value: 2
        }, {
            name: '转账',
            value: 3
        }, {
            name: '支付宝',
            value: 4
        }, {
            name: '微信',
            value: 5
        }, {
            name: '其他',
            value: 9
        }],
        projects: [],
        fetchingprojects:true,
        man: [],
        bill: [
            { value: 1, name: '收据' },
        ],
        pay: [
            { value: 1, name: '已结清' },
            { value: 2, name: '代收款' },
            { value: 3, name: '一笔收款驳回' },
            { value: 4, name: '一笔收款审批中' }
        ],//1 已结清 2代收款 3一笔收款驳回 4一笔收款审批中
        auditStatusList: [
            { value: 0, name: '审批中' },
            { value: 1, name: '已驳回' },
            { value: 2, name: '已通过' }
        ],
        columns: [
            {
                title: '区域名称',
                width: 300,
                dataIndex: 'floorStr',
            },
            {
                title: '费用类型',
                width: 200,
                key: 'billType',
                render(obj) {
                    return (
                        <div>
                            <span className="fee-type">{obj.feeType == 99 ? '其他费用' : feeTypeList[obj.feeType - 1]}</span>
                        </div>
                    )
                }
            }, {
                title: '费用周期',
                width: 200,
                key: 'beginDate',
                render(value) {
                    if (value.beginDate && value.endDate) {
                        return (
                            <div>{value.beginDate}至{value.endDate}</div>
                        )
                    } else {
                        return '-'
                    }
                }
            }, {
                title: '实收金额(元)',
                dataIndex: 'paidAmount',
            }, {
                title: '付款人信息',
                width: 200,
                key: 'customerName',
                render: (value) => {
                    return (
                        <div>{value.customerName}   {value.customerPhone}</div>
                    )
                }

            }, {
                title: '开据金额(元)',
                width: 200,
                dataIndex: 'receiptAmount',
            }, {
                title: '开据时间',
                width: 200,
                dataIndex: 'receiptDate',
                sorter: true
            }, {
                title: '票据类型',
                width: 200,
                key: 'endDate',
                render: () => {
                    return (
                        <div>收据</div>
                    )
                }

            },
            {
                title: '操作',
                key: 'receiptEncode',
                fixed: 'right',
                render: (value) => {
                    return (
                        <div>
                            <Print receiptInfo={value} />
                        </div>
                    )
                }
            }
        ],
        data: [],
        queryParams: {
            pageNo: 1,
            pageSize: 10,
            keyword: undefined, // 搜索关键字
            projectEncodes: [], //项目id
            feeTypes: [], //费用类型
            endDate: undefined,
            beginDate: undefined,
            dateType: undefined,
            receiptEncode: undefined, //票据类型
            orderByList: [2]
        },
        popAmount: '',
        tuiAmount: '',
        tuiReason: '',
    }
    componentDidMount() {
        this.getProjectList();
        this.getList()
    }
    getList = () => {
        http({
            api: API.billReceiptlist,
            data: this.state.queryParams
        }).then(res => {
            if (res.data) {
                res.data.datas.map((obj, key) => {
                    obj.id = key + 1;
                })
                let tempData = this.state.queryParams;
                tempData.total = res.data.total;
                tempData.pageNo = res.data.pageNo;
                tempData.pageSize = res.data.pageSize;

                this.setState({
                    data: res.data.datas,
                    queryParams: tempData
                })
            }
        })
    }
    keyWordSearch = (value) => {
        let queryParams = this.state.queryParams
        queryParams.pageNo = 1
        queryParams.keyword = value
        this.setState({
            queryParams
        })
        this.getList()
    }
    selectChange = (attr, data) => {
        // console.log('+++++++++')
        // console.log(attr,data);
        let queryParams = this.state.queryParams
        queryParams.pageNo = 1
        queryParams[attr] = data
        this.setState({ queryParams })
        this.getList()
    }
    resetParams = () => {
        let data = Object.assign({}, this.state.queryParams, {
            pageNo: 1,
            pageSize: 10,
            keyword: undefined, // 搜索关键字
            projectEncodes: [], //项目id
            feeTypes: [], //费用类型
            endDate: undefined,
            beginDate: undefined,
            dateType: undefined,
            receiptEncode: undefined, //票据类型
            orderByList: [2]
        });
        this.setState({ queryParams: data, dateValue: [] }, () => {
            this.getList();
        });
    }
    setKeyWord = (e) => {
        let data = Object.assign({}, this.state.queryParams, { keyword: e.target.value });
        this.setState({ queryParams: data });
    }
    onChange = (pageNo, pageSize, sort) => {
        console.log(sort)
        let queryParams = this.state.queryParams
        if (sort) {
            if (sort.columnKey == "receiptDate") {
                if (sort.order == "ascend") {
                    queryParams.orderByList = [1]
                }
                if (sort.order == "descend") {
                    queryParams.orderByList = [2]
                }
            }
        }
        queryParams.pageNo = pageNo
        queryParams.pageSize = pageSize
        this.setState({ queryParams })
        this.getList()
    }
    //选择处理时间类型
    choosedateType(e) {
        let data = Object.assign({}, this.state.queryParams, { dateType: e, pageNo: 1, pageSize: 10 })
        this.setState({ queryParams: data }, () => {
            if (this.state.queryParams.dateType && this.state.queryParams.beginDate && this.state.queryParams.endDate) {
                this.getList();
            }
        });
    }
    //改变上报时间
    onChangedate(date, options) {
        console.log(date);
        let data = Object.assign({}, this.state.queryParams, { beginDate: options[0], endDate: options[1], pageNo: 1, pageSize: 10 })
        this.setState({ queryParams: data, dateValue: date }, () => {
            if (this.state.queryParams.beginDate && this.state.queryParams.endDate) {
                this.getList();
            }
            if (this.state.queryParams.beginDate == '' && this.state.queryParams.endDate == '') {
                this.getList();
            }
        });
    }
    getProjectList() {
        http({
            api: API.commonProjectfilter,
        }).then(res => {
            console.log("loadCaptcha--------------", res.data);
            this.setState({
                projects: res.data,
                fetchingprojects:false
            })
        })
    }
    getCustomerList(value) {
        console.log(value)
        http({
            api: API.userGetList,
            data: {
                nameLike: value,
                pageSize: null
            }
        }).then(res => {
            console.log(res);
            this.setState({
                man: res.data.datas
            })
        })
    }

    notFount() {
        return (
            <div style={{ textAlign: 'center', height: '100px', lineHeight: '100px' }}>加载中...</div>
        )
    }
    notFoundContent() {
        return (
            <div className='ant-empty ant-empty-normal ant-empty-small'><div className="ant-empty-image"></div><p className="ant-empty-description">暂无数据</p></div>)
    }
    render() {
        const { queryParams: params, fetching, countData, billInfo,fetchingprojects } = this.state;
        const options = this.state.projects.map(per => {
            return <Option value={per.encode} key={per.encode}>{per.name}</Option>
        })
        const optionsFee = feeTypeList.map((per, index) => {
            return <Option value={index + 1} key={per}>{per}</Option>
        })
        return (
            <div className="finance">
                <div className="contract-list">
                    <div className="contract-search">
                        {/* <Row gutter={12} style={{ marginBottom: '12px',display:'flex' }}> */}
                        <div className="contract-list-row">
                            <span className='w240'>
                                <Input.Search placeholder="请输入付款人姓名/手机号/账单关联房间号" onChange={this.setKeyWord}
                                    onSearch={this.keyWordSearch} value={params.keyword} className='w240' />
                            </span>
                            <span className='w104'>
                                <ISelect data={this.state.bill} placeholder="票据类型" value={params.receiptEncode}
                                    selectMethod={this.selectChange.bind(this, 'receiptEncode')} className='w104' />
                            </span>
                            <span className='w104' style={{marginRight:'0px'}}>
                                <ISelect
                                    data={this.state.dateType}
                                    className='w104'
                                    value={params.dateType}
                                    placeholder="时间类型"
                                    suffixIcon={<Icon type="caret-down" />}
                                    onSelect={this.choosedateType.bind(this)}>
                                </ISelect>
                            </span>
                            <span className='w240 cx-date-style'>
                                <RangePicker onChange={this.onChangedate.bind(this)} className='w240' value={this.state.dateValue} />
                            </span>
                            {/* </Row>
                    <Row gutter={12} style={{ marginBottom: '12px',display:'flex' }}> */}
                            <span className='w288'>
                                <Select dropdownClassName="cx-dropdown-style" mode="multiple"  otFoundContent={fetchingprojects ? this.notFount() : this.notFoundContent()} placeholder="项目名称" value={params.projectEncodes} className='w288' onChange={this.selectChange.bind(this, 'projectEncodes')}>
                                    {options}
                                </Select>
                            </span>
                            <span className='w288'>
                                <Select mode="multiple" placeholder="费用类型" value={params.feeTypes} className='w288' onChange={this.selectChange.bind(this, 'feeTypes')}>
                                    {optionsFee}
                                </Select>
                            </span>
                            <Col span={3} className="reset">
                                <a style={{ cursor: 'pointer', fontSize: '12px', textDecoration: 'underline', textUnderlinePosition: 'under', color: '#333' }} onClick={this.resetParams.bind(this)}>重置</a>
                            </Col>
                            {/* </Row> */}
                        </div>
                    </div>
                    <div className="contract-table OperateMaintaintable-body">
                        <Table scroll={{ x: 1300 }} rowKey={record => record.id} columns={this.state.columns} selfProcessData={this.state.data} total={params.total} change={this.onChange} current={params.pageNo} />
                    </div>
                </div>
            </div>
        )
    }
}

export default ReceiptList;