import React, { Component } from 'react';
import {Row,Col,Select,Form ,Input,Checkbox,DatePicker,InputNumber} from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import http from '@/http';
import { API } from '@/http/api';
import "../../financeApproval/finance.less"
import '../bill.less'
moment.locale('zh-cn');
const { RangePicker } = DatePicker;
const Item = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;
const costType = [
    { value: 1, name: '定金' },
    { value: 2, name: '保证金' },
    { value: 3, name: '合同保证金' },
    { value: 4, name: '装修保证金' },
    { value: 5, name: '物业保证金' },
    { value: 6, name: '租金' },
    { value: 7, name: '手续费' },
    { value: 8, name: '服务费' },
    { value: 9, name: '保洁费' },
    { value: 10, name: '物业费' },
    { value: 11, name: '网费' },
    { value: 12, name: '水费' },
    { value: 13, name: '电费' },
    { value: 14, name: '滞纳金' },
    { value: 15, name: '违约金' },
    { value: 16, name: '搬家费' },
    { value: 17, name: '耗材费' },
    { value: 99, name: '其他费用' }
];
const patter = '^[0-9]{1,8}([.][0-9]{1,2})?$,message:"只能输入小数点前8位，小数点后两位"';

class BillInfo extends Component {
    state = {

    }
    componentDidMount() {
        // console.log(this.props.form.getFieldValue('billCategory'))
    }
    render(){
        const { getFieldDecorator } = this.props.form;
        const { textDes,resetBill } = this.props;
        const optionsFee = costType.map(per => {
            return <Option value={per.value} key={per.value}>{per.name}</Option>
        })
        const isChecked = this.props.form.getFieldValue('billCategoryMap');
        return (
            <div className="approval-baseInfo relative">   
                <Item label="" className="shoufu">
                    {getFieldDecorator('billCategoryMap', {
                        initialValue:false,
                    })(
                        <Checkbox><span className="fs12">{textDes.billCategoryMap}</span></Checkbox>
                    )}
                </Item>             
                <div className="title">账单信息</div>
                <Row className="fs12 mb9">
                    <Col span={8}>
                        <Item label="费用类型" style={{ width: '237px'}}>
                            {getFieldDecorator('feeType', {
                                initialValue:resetBill.feeType,
                                rules: [{
                                    required: true, message: '请选择',
                                }]
                            })(
                                <Select placeholder="请输入">
                                    {optionsFee}
                                </Select>                        
                            )}
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item label="金额" style={{ width: '237px'}}>
                            {getFieldDecorator('paidAmount', {
                                initialValue:resetBill.paidAmount,
                                rules: [{
                                    required: true, message: '金额不能为空'
                                }]
                            })(
                                <Input placeholder="请输入" style={{ width: '237px'}} precision={2}  suffix="元"/>       
                            )}
                        </Item>
                    </Col>
                    <Col span={8} className={isChecked?'displayNone':''}>
                        <Item label={textDes.payDate} style={{ width: '237px'}}>
                            {getFieldDecorator('payDate', {
                                initialValue:resetBill.payDate?moment(resetBill.payDate):undefined,
                                rules: [{
                                    required: !isChecked, message: '时间不能为空',
                                }]
                            })(
                                <DatePicker placeholder="请输入" style={{ width: '237px'}}/>       
                            )}
                        </Item>
                    </Col>
                    <Col span={8} className={isChecked?'':'displayNone'}>
                        <Item label={textDes.paidDate} style={{ width: '237px'}}>
                            {getFieldDecorator('paidDate', {
                                rules: [{
                                    required: isChecked, message: '时间不能为空',
                                }]
                            })(
                                <DatePicker format="YYYY-MM-DD" placeholder="请输入" style={{ width: '237px'}}/>       
                            )}
                        </Item>
                    </Col>
                </Row>   
                <Row>
                    <Col span={8} className="cx-maohao">
                        <Item label="费用周期" style={{ width: '237px'}}>
                            {getFieldDecorator('beginEndDate', {
                                initialValue:resetBill.beginEndDate,
                                rules: [{
                                    required: false, message: '请选择',                                   
                                }]
                            })(
                                <RangePicker format="YYYY-MM-DD" placeholder={['开始时间', '结束时间']} style={{ width: '237px'}}/>       
                            )}
                        </Item>
                    </Col>
                </Row>      
                <Row>
                    <Col span={24} className="cx-maohao">
                        <Item label="费用描述">
                            {getFieldDecorator('description', {
                                initialValue:resetBill.description,
                                rules: [{
                                    required: false, message: '请输入',max:200
                                }]
                            })(
                                <TextArea rows={4} placeholder="请输入" maxLength={200}/> 
                            )}
                        </Item>
                    </Col>
                </Row>                      
            </div>           
        )
    }
}

export default BillInfo;