import React from 'react';
import { Breadcrumb, Icon, Select, Form, Button, Row, Col, Modal, InputNumber, Input } from 'antd';
import InfoCard from '../../../common/card/InfoCard';
import FormItem from 'antd/lib/form/FormItem';
import EditableTable from '../EditableTable';
import './AddRegion.less';
import http from '@/http';
import { API } from '@/http/api';
const INVESTMENT_STATUS = {
    0: '不可招商',
    1: '可招商',
};

export default class AddRegion extends React.Component {
    constructor(props) {
        super(props);
        
        const { pathname, search } = this.props.location;
        const path = pathname.split('/');
        if (search) {
            const query = {};
            decodeURI(search).replace('?', '').split('&').forEach(item => {
                const kv = item.split('=');
                query[kv[0]] = kv[1];
            });
            this.selectFloorCode = query['floorEncode'];
        }
        
        this.state = {
            columns:[],
            floors: [
                {
                    key: 0,
                    name: '',
                    area: 0,
                    price: 0
                }
            ],
            selectFloor: {
                key: null,
                name: null,
                area: null,
                price: null,
                floorEncode: null
            },
            projectEncode: path[path.length - 1],
            partZoneList: [
                {
                    key: 1,
                    buildingArea: null,
                    unitPrice: null,
                    innerArea: null,
                    merchantStatus: 1,
                    remark: null,
                    // rentStatus: 0,
                    useArea: null,
                    zoneNo: null,
                    constraint: {
                        message: null,
                        status: null
                    }
                }],
            batchAddModalVisible: false
        }
    }
    showModal = () => {
        this.setState({
            batchAddModalVisible: true,
        });
    }
    getProjectSetting = () => {
        // 获取项目配置
        const defaultConfig = {
            api: API.decisionByProjectEncode,
            path: {
                projectEncode: "qeZDWfgpvSs"
            },
            data: {
                bizType: '1'
            }
        }
        http(defaultConfig).then(res => {
            
            
            var defaultSquare = res.data.length?res.data[res.data.length-1].description:'建筑面积'
            this.defaultSquare=defaultSquare
            const isEditable = (record) => !record.encode;
            const options = [];
            for (let k in INVESTMENT_STATUS) {
                options.push(<Select.Option key={k}>{INVESTMENT_STATUS[k]}</Select.Option>)
            }
            var columns = [
                {
                    title: '门牌号',
                    key: 'zoneNo',
                    isEditable: isEditable,
                    required: true,
                    width: 120
                },
                {
                    title: '招商状态',
                    key: 'merchantStatus',
                    isEditable: isEditable,
                    required: true,
                    width: 120,
                    render: (text, record) => (
                        <Form.Item style={{ margin: 0 }}>
                            <Select disabled={!isEditable(record)} style={{ width: "100%" }} onSelect={(v, o) => this.onMerchantSelected(record, v)} value={INVESTMENT_STATUS[record.merchantStatus]}>{options}</Select>
                        </Form.Item>
                    ),
                },
                {
                    title: '建筑面积(m²)',
                    key: 'buildingArea',
                    isEditable: isEditable,
                    required: true,
                    width: 120
                },
                {
                    title: '预计单价 (元/m²/日)',
                    key: 'unitPrice',
                    isEditable: isEditable,
                    required: true,
                    width: 128
                },
                {
                    title: '使用面积 (m²)',
                    key: 'useArea',
                    isEditable: isEditable,
                    required: defaultSquare=="使用面积",
                    width: 120
                },
                {
                    title: '套内面积 (m²)',
                    key: 'innerArea',
                    isEditable: isEditable,
                    required: defaultSquare=="套内面积",
                    width: 120
                },
                { title: '备注', key: 'remark', isEditable: isEditable, width: 280 },
                {
                    title: '操作',
                    key: 'operation',
                    width: 64,
                    render: (text, record) => (
                        <Form.Item style={{ margin: 0 }}>
                            {isEditable(record) ? (<a className={"btn-row-delete" + (this.state.partZoneList.length <= 1 ? " btn-row-delete-disabled" : '')}
                                href="javascript:;"
                                onClick={() => {
                                    if(this.state.partZoneList.length > 1){
                                        this.handleDelete(record.key)
                                    }} } >删除</a>) : null}
    
                        </Form.Item>
                    ),
                }];            
            this.setState({
                columns
            })
        })
    }
    onSelectChanged = (key, option) => {
        const index = this.state.floors.findIndex((item) => {
            return item.key == key;
        });
        const selectFloor = this.state.floors[index];
        this.setState({
            selectFloor: selectFloor
        })
        this.getZoneList(selectFloor);
    }

    handleDelete = (key) => {
        const partZoneList = [...this.state.partZoneList];

        this.setState({
            partZoneList: partZoneList.filter(item => item.key !== key)
        })
    }

    onRegionChange = (newRecord) => {
        const { partZoneList } = this.state;
        partZoneList[partZoneList.findIndex(item => item.key === newRecord.key)] = newRecord;
        this.setState({
            partZoneList: partZoneList
        });
    }

    onMerchantSelected = (record, value) => {
        const partZoneList = [...this.state.partZoneList];
        const currentItem = partZoneList[partZoneList.findIndex(item => item.key === record.key)];
        currentItem.merchantStatus = value;
        this.setState({
            partZoneList: partZoneList
        })
    }

    singleAdd = () => {
        let dataSource = [
            ...this.state.partZoneList,
        ];

        dataSource.push({
            key: dataSource[dataSource.length - 1].key + 1,
            buildingArea: null,
            unitPrice: null,
            innerArea: null,
            merchantStatus: 1,
            remark: null,
            // rentStatus: 0,
            useArea: null,
            zoneNo: null
        });
        this.setState({
            partZoneList: dataSource
        });
    }

    checkConstraint = () => {
        let result = true;
        const zoneNoMap = {};
        const partZoneList = [...this.state.partZoneList];
        const checkResult = partZoneList//.filter(item => !item.encode)
            .map((item, index) => {
                item.constraint = null;
                if (item.zoneNo) {
                    let indexArr = zoneNoMap[item.zoneNo];
                    if (!indexArr) {
                        zoneNoMap[item.zoneNo] = indexArr = [];
                    }
                    indexArr.push(index);
                }
                if(!item.encode){
                    this.checkConstraintByKey(item, 'zoneNo');
                    
                    this.checkConstraintByKey(item, 'buildingArea');
                    if(this.defaultSquare=="建筑面积"){                        
                    }else if(this.defaultSquare=="使用面积"){                        
                        this.checkConstraintByKey(item, 'useArea');
                    }else if(this.defaultSquare=="套内面积"){
                        this.checkConstraintByKey(item, 'innerArea');
                    }
                    
                    
                    this.checkConstraintByKey(item, 'unitPrice');
                }
                return item;
            }).filter(item => item.constraint);
        if (checkResult.length > 0) {
            result = false;
        }

        for (let item in zoneNoMap) {
            const items = zoneNoMap[item];
            if (items.length > 1) {
                for (let i = 1; i < items.length; ++i) {
                    const newItem = partZoneList[items[i]];
                    newItem.constraint = {
                        ...newItem.constraint,
                        zoneNo: {
                            message: '门牌号已存在',
                            status: 'error'
                        }
                    }
                }
                result = false;
            }
        }

        this.setState({
            partZoneList: partZoneList
        })

        return result;
    }

    checkConstraintByKey = (item, key) => {
        if (!item[key]) {
            item.constraint = {
                ...item.constraint,
                [key]: {
                    message: '必填',
                    status: 'error'
                }
            }
        }
    }

    submit = () => {        
        const { partZoneList, selectFloor, projectEncode } = this.state;
        if (!this.checkConstraint()) {
            return;
        }
        const newRequest = {
            floorEncode: selectFloor.floorEncode,
            projectEncode: projectEncode,
            partZoneList: partZoneList
                .filter(item => !item.encode)
                .map(item => ({
                    buildingArea: item.buildingArea,
                    unitPrice: item.unitPrice,
                    innerArea: item.innerArea,
                    merchantStatus: parseInt(item.merchantStatus),
                    remark: item.remark,
                    rentStatus: parseInt(item.merchantStatus),
                    useArea: item.useArea,
                    zoneNo: item.zoneNo,
                }))
        }

        http({
            api: {
                url: '/web/zone/add',
                type: 'post'
            },
            data: newRequest,
            showLoading: true
        }).then(res => {
            if (res.success) {
                Modal.confirm({
                    icon: false,
                    content: '提交成功，是否继续添加区域？',
                    okText: '立即添加',
                    onOk: () => {
                        window.location.reload();
                    },
                    cancelText: '暂不添加',
                    onCancel: () => {                                                                 
                        this.props.history.push({ pathname: `/app/project/detail/${projectEncode}`})
                    }
                });
            }
        });
    }

    cancel = () => {
        window.history.back();
    }

    componentDidMount() {
        this.getProjectSetting()
        http({
            api: {
                url: '/web/projects/:projectEncode',
                type: 'get'
            },
            path: {
                projectEncode: this.state.projectEncode,
            },
            showLoading: true
        }).then(res => {
            if (res.success) {
                let selectIndex = 0;
                const floors = (res.data.floors || []).map((item, index) => {
                    if (item.encode === this.selectFloorCode) {
                        selectIndex = index;
                    }
                    return {
                        key: item.sort,
                        name: item.name,
                        area: item.area,
                        price: item.price,
                        floorEncode: item.encode
                    };
                });
                if (floors && floors.length > 0) {
                    this.setState({
                        floors: floors,
                        selectFloor: floors[selectIndex]
                    });
                    this.getZoneList(floors[selectIndex]);
                }
            }
        });
    }

    getZoneList = (floor) => {
        http({
            api: {
                url: `/web/zone/get_list?floorEncode=${floor.floorEncode}`,
                type: 'get'
            },
            showLoading: true
        }).then(res => {
            if (res.success) {
                if (!res.data) {
                    res.data = [];
                }
                res.data.push({
                    key: res.data.length + 1,
                    buildingArea: null,
                    unitPrice: null,
                    innerArea: null,
                    merchantStatus: 1,
                    remark: null,
                    // rentStatus: 0,
                    useArea: null,
                    zoneNo: null,
                    constraint: {
                        message: null,
                        status: null
                    }
                });
                this.setState({
                    partZoneList: res.data.map((item, index) => ({
                        key: index,
                        buildingArea: item.buildingArea,
                        unitPrice: item.unitPrice,
                        innerArea: item.innerArea,
                        merchantStatus: item.merchantStatus,
                        remark: item.remark,
                        // rentStatus: 0,
                        encode: item.encode,
                        useArea: item.useArea,
                        zoneNo: item.zoneNo
                    })),
                })
            }
        })
    }

    onBatchConfirm = (newData) => {
        const partZoneList = [
            ...this.state.partZoneList,
            ...newData
        ];

        this.setState({
            partZoneList: partZoneList
        });
    }

    render() {
        const options = this.state.floors.map(d => <Select.Option key={d.key}>{d.key}</Select.Option>);
        const orderMessageStyle = {
            marginLeft: "24px",
            fontSize: "12px"
        }
        const selectFloor = this.state.selectFloor;
        return (
            <div className="region-add" style={{ padding: "0 24px"}}>
                <Breadcrumb>
                    <Breadcrumb.Item>全部项目</Breadcrumb.Item>
                    <Breadcrumb.Item>添加区域</Breadcrumb.Item>
                </Breadcrumb>

                <InfoCard className="first-block" visible={false}>
                    <Form layout={"inline"}>
                        <FormItem className="order-selection" label="楼层序号" required>
                            <Select
                                className="selection"
                                value={selectFloor.key}
                                defaultActiveFirstOption={true}
                                filterOption={false}
                                onChange={this.onSelectChanged}
                                notFoundContent={null}
                            >
                                {options}
                            </Select>
                            <span style={orderMessageStyle}>楼层名称：{selectFloor.name}</span>
                            <span style={orderMessageStyle}>楼层面积：{selectFloor.area}㎡</span>
                            <span style={orderMessageStyle}>楼层单价：{selectFloor.price}元/m²/日</span>
                        </FormItem>
                    </Form>
                </InfoCard>
                <InfoCard className="second-block" title={"区域信息"}>
                    <div className="operation" style={{display: 'flex'}}>
                        <span className="btn-add" onClick={this.showModal} style={{display: 'flex',alignItems: 'center'}}>
                            <i className="iconfont iconadd_circle"></i>
                            批量添加
                    </span>
                        <span className="btn-add" onClick={this.singleAdd} style={{display: 'flex',alignItems: 'center'}}>
                            <i className="iconfont iconadd_circle"></i>
                            单层添加
                    </span>
                        {this.state.batchAddModalVisible ? (<RegionAddModal
                            visible={this.state.batchAddModalVisible}
                            onCancel={() => this.setState({
                                batchAddModalVisible: false,
                            })}
                            onConfirm={this.onBatchConfirm}
                            dataSource={this.state.partZoneList} />) : null}

                    </div>
                    <EditableTable columns={this.state.columns} dataSource={this.state.partZoneList} onChange={this.onRegionChange} />
                </InfoCard>
                <div className="btn-group-opration">
                    <Button type="primary" onClick={this.submit}>提交</Button>
                    <Button type="link" onClick={this.cancel}>取消</Button>
                </div>
            </div>
        );
    }
}



class RegionAddModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: props.visible,
            dataSource: props.dataSource,
            constraint: {
                count: {
                    status: 'success',
                    message: ''
                },
                prefix: {
                    status: 'success',
                    message: ''
                },
                digit: {
                    status: 'success',
                    message: ''
                }
            },
            param: {
                count: null,//区域个数
                prefix: null,//前缀
                digit: null,//位数
                price: null,//统一定价
            }
        };
    }

    componentWillReceiveProps(props) {
        this.setState({
            visible: props.visible,
            dataSource: props.dataSource
        });
    }

    checkConstraintByKey = (key) => {
        const param = this.state.param;
        if (!param[key]) {
            let constraint = {
                ...this.state.constraint,
            };
            constraint[key] = {
                status: 'error',
                message: '必填'
            };
            this.setState({
                constraint: constraint
            });
            return false;
        }
        return true;
    }

    resetConstraint() {
        for (let key in this.state.constraint) {
            this.resetConstraintByKey(key);
        }
    }

    resetConstraintByKey = (key) => {
        let constraint = {
            ...this.state.constraint
        };
        constraint[key] = {
            status: 'success',
            message: ''
        };
        this.setState({
            constraint: constraint,
        });
    }

    onCancel() {
        this.setState({
            visible: false,
        });
        if (typeof this.props.onCancel == 'function') {
            this.props.onCancel();
        }
    }

    onConfirm() {
        const { dataSource } = this.state;
        const newData = [];
        const param = this.state.param;

        console.log(dataSource,param)

        // var existList = ['0201', '0205', '11002']
        // function autoNumber(no, length, num) {
        //     var autoList = []
        //     var innerNum = num
        //     for (var i = 1; i <= innerNum; i++) {
        //         var targetNum = no + (Array(length + 1).join(0) + i).slice(-length);
        //         if (existList.indexOf(targetNum) === -1) {
        //             autoList.push(targetNum)
        //         } else {
        //             innerNum += 1
        //         }
        //     }
        //     console.log(autoList)
        // }
        
        
        for (let key in this.state.constraint) {
            if (!this.checkConstraintByKey(key)) {
                return;
            }
        }
        // 校验区域个数和位数        
        if(param.digit<param.count.toString().length){            
            let constraint = {
                ...this.state.constraint
            };
            constraint['digit'] = {
                status: 'error',
                message: '位数小于区域个数位数'
            };
            this.setState({
                constraint: constraint,
            });
            return false
        }
        const firstKey = dataSource[dataSource.length - 1].key + 1;        
        for (let i = 1; i <= param.count; ++i) {

            newData.push({
                key: firstKey + i,
                buildingArea: null,
                unitPrice: param.price,
                innerArea: null,
                merchantStatus: 1,
                remark: null,
                useArea: null,
                zoneNo: `${param.prefix}${(Array(param.digit).join('0') + i).slice(-param.digit)}`
            });
        }
        if (typeof this.props.onConfirm == 'function') {
            this.props.onConfirm(newData);
        }
        this.onCancel();
    }

    render() {
        const constraint = this.state.constraint;
        const param = this.state.param;
        const {
            count,
            prefix,
            digit,
            price,
        } = param;

        return (
            <Modal destroyOnClose={true}
                closable={false}
                footer={null}
                width={400}
                onCancel={this.onCancel.bind(this)}
                visible={this.state.visible}>
                <div className="modal-floor-title">
                    批量添加区域
                    </div>
                <Form layout={'vertical'} className="modal-form-region">
                    <Row gutter={8}>
                        <Col span={8}>
                            <Form.Item label={'区域个数'}
                                required
                                validateStatus={constraint.count.status}
                                help={constraint.count.message}>
                                <InputNumber value={count}
                                    style={{ width: "100%" }}
                                    placeholder={'请输入'}
                                    min={1}
                                    max={100}
                                    onChange={(value) => {
                                        this.setState({ param: { ...param, count: isNaN(parseInt(value))?'':parseInt(value) } });
                                        this.resetConstraintByKey('count');
                                    }} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label={'前缀'}
                                required
                                validateStatus={constraint.prefix.status}
                                help={constraint.prefix.message}>
                                <Input value={prefix}
                                    style={{ width: "100%" }}
                                    placeholder={'请输入'}
                                    maxLength={3}
                                    onChange={(value) => {
                                        this.setState({ param: { ...param, prefix: value.target.value } });
                                        this.resetConstraintByKey('prefix');
                                    }} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label={'位数'}
                                required
                                validateStatus={constraint.digit.status}
                                help={constraint.digit.message}>
                                <InputNumber value={digit}
                                    style={{ width: "100%" }}
                                    placeholder={'请输入'}
                                    min={1}
                                    max={5}
                                    onChange={(value) => {
                                        this.setState({ param: { ...param, digit: isNaN(parseInt(value))?'':parseInt(value) } });
                                        this.resetConstraintByKey('digit');
                                    }} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <div className="tip">例：区域个数15，前缀”B1-”，位数“3”，则生成的门牌号依次为B1-001, B1-002, … , B1-015</div>

                    <Form.Item label={'统一定价'}>
                        <InputNumber value={price}
                            style={{ width: "100%" }}
                            placeholder={'请输入'}
                            min={1}
                            max={9999}
                            suffix={'元/m²/日'}
                            onChange={(value) => this.setState({ param: { ...param, price: value } })} />
                    </Form.Item>

                </Form>

                <div className="btn-modal-region">
                    <Button type="primary" onClick={this.onConfirm.bind(this)}>
                        确定
                        </Button>
                    <Button type="link" onClick={this.onCancel.bind(this)}>
                        取消
                        </Button>
                </div>
            </Modal>
        );

    }
}