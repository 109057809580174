import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom';
import ISelect from 'CMPT/common/Select';
import Table from 'CMPT/common/Table';
import { AUTH_KEYS } from '@/utils/auth';
import AuthContainer from 'CMPT/common/auth/AuthContainer';
import http from '@/http';
import { API } from '@/http/api';
import { Input, Row, Col, DatePicker, Icon, Select, TreeSelect, Spin, message, Modal } from 'antd';
import "../financeApproval/finance.less"
import user from '@/utils/user'
const { isManager } = user;
const { RangePicker } = DatePicker;
const Option = Select.Option;
const { TextArea } = Input;

const SHOW_PARENT = TreeSelect.SHOW_PARENT;
const feeTypeList = ['定金', '保证金', '合同保证金', '装修保证金', '物业保证金', '租金', '手续费', '服务费', '保洁费', '物业费', '网费', '水费', '电费', '滞纳金', '违约金', '搬家费', '耗材费', '其他费用'];
const billTypeList = ['合同', '运营'];
const success = () => {
    message.success('催缴成功，我们将以短信形式通知付款人');
};
const info = () => {
    message.info('每小时只能催缴一次，请稍候再试');
};
class PayBill extends Component {
    state = {
        billInfo: {},
        showReceiptModal: false,
        showRefundModal: false,
        countData: {
            all: 0,
            allPaid: 0,
            notPaid: 0,
            overdue: 0
        },
        fetching: false,
        dateValue: [],
        currentIndex: 0,
        dateType: [{
            name: '应付时间',
            value: 1
        },
        {
            name: '实付时间',
            value: 3
        }],
        payChannel: [{
            name: '现金',
            value: 1
        }, {
            name: '刷卡',
            value: 2
        }, {
            name: '转账',
            value: 3
        }, {
            name: '支付宝',
            value: 4
        }, {
            name: '微信',
            value: 5
        }, {
            name: '其他',
            value: 9
        }],
        projects: [],
        fetchingprojects:true,
        man: [],
        bill: [
            { value: 1, name: '合同' },
            { value: 2, name: '运营' }
        ],
        pay: [
            { value: 1, name: '已结清' },
            { value: 2, name: '待付款' },
            { value: 3, name: '1笔付款驳回' },
            { value: 4, name: '1笔付款审批中' },

        ],//1 已结清 2代收款 3一笔收款驳回 4一笔收款审批中
        auditStatusList: [
            { value: 0, name: '审批中' },
            { value: 1, name: '审批驳回' },
            { value: 2, name: '审批通过' }
        ],
        columns: [
            {
                title: '区域名称',
                dataIndex: 'floorMsg',
                width: 300,
            }, {
                title: '账单/费用类型',
                width: 200,
                key: 'billType',
                render(obj) {
                    return (
                        <div>
                            <span className="bill-type" style={{ background: obj.billType == 1 ? '#434BA3' : "#B76C1E" }}>{billTypeList[obj.billType - 1]}</span>
                            <span className="fee-type">{obj.feeType == 99 ? '其他费用' : feeTypeList[obj.feeType - 1]}</span>
                        </div>
                    )
                }
            }, {
                title: '应付金额(元)',
                width: 200,
                dataIndex: 'payAmount',
            }, {
                title: '应付时间',
                width: 200,
                key: 'payDate',
                sorter: true,
                render: (obj) => {
                    return (
                        <div>{obj.payDate}
                            {/* <i className="colory" style={{display:obj.overdue==1?'':'none',marginLeft:'5px'}}><Icon type="warning" theme="filled"/></i>  */}
                        </div>
                    )
                }
            }, {
                title: '实付时间',
                width: 200,
                dataIndex: 'paidDate',
                sorter: true,
                render: (value) => {
                    return (
                        <div>{value ? value : '-'}</div>
                    )
                }
            }, {
                title: '费用周期/期数',
                render(value) {
                    if (value.beginDate && value.endDate) {
                        let bizTemp = Number(value.bizPeriodNo);
                        let str = '';
                        if (bizTemp) {
                            str = '第' + value.bizPeriodNo + '期';
                        } else {
                            str = value.bizPeriodNo;
                        }
                        return (
                            <div>
                                <div>{str}</div>
                                <div>{value.beginDate}至{value.endDate}</div>
                            </div>
                        )
                    } else {
                        return '-'
                    }
                }
            }, {
                title: '收款人',
                key: 'customerName',
                render: (obj) => {
                    return (
                        <div>
                            <div>{obj.customerName}</div>
                            <div>{obj.customerPhone}</div>
                        </div>
                    )
                }
            }, {
                title: '账单状态',
                dataIndex: 'auditStatus',
                render(value) {
                    const auditStatusList = ['审批中', '审批驳回', '审批通过'];
                    let color = ''
                    switch (value) { // 0 待审批，1 已驳回 2 通过 3 不通过
                        case 0:
                            color = '#FBBC06'
                            break
                        case 1:
                            color = '#E94437'
                            break;
                        case 3:
                            color = '#EE423D'
                            break
                        default:
                            color = '#E94437'
                    }
                    return (
                        <div><span style={{ color: color, marginRight: '6px' }}>●</span>{auditStatusList[value]}</div>
                    )
                }

            }, {
                title: '付款状态',
                key: "paymentReceiveAudiStatus",
                render: (obj) => {
                    const payList = ['已结清', '待付款', '1笔付款被驳回', '1笔付款审批中'];
                    let color = ''
                    switch (obj.paymentReceiveAuditStatus) {
                        case 1:
                            color = '#E94437'
                            break
                        case 2:
                            color = '#FBBC06'
                            break;
                        case 3:
                            color = '#E94437'
                            break
                        case 4:
                            color = '#FBBC06'
                            break
                        default:
                            color = ''
                    }
                    return (
                        <div><span style={{ color: color, marginRight: '6px', display: obj.paymentReceiveAuditStatus ? '' : 'none' }}>●</span>{obj.paymentReceiveAuditStatus ? payList[obj.paymentReceiveAuditStatus - 1] : '-'}</div>
                    )
                }

            }, {
                title: '创建时间',
                dataIndex: 'createTime',
                sorter: true

            }, {
                title: '创建人',
                dataIndex: 'createUserName',

            },
            {
                title: '操作',
                fixed: 'right',
                render: (value) => {
                    return (
                        <div>
                            <AuthContainer authCodes={AUTH_KEYS.OPERATE_FINANCE_BILL_VIEW}>
                                <Link to={'/app/operate/finance/bill/detail/' + value.encode + '?type=2'} className="table-operate-btn">详情</Link>
                            </AuthContainer>
                            <AuthContainer authCodes={AUTH_KEYS.OPERATE_FINANCE_BILL_EDIT}>
                                <Link to={'/app/operate/finance/bill/add?type=' + value.budgetType + '&billEncode=' + value.encode} className="table-operate-btn" style={{ display: value.editFlag == 1 ? '' : 'none' }}>编辑</Link>
                            </AuthContainer>
                            {/* <AuthContainer authCodes={AUTH_KEYS.OPERATE_FINANCE_BILL_RECEIPT}>
                                <span className="table-operate-btn" onClick={this.receipt.bind(this,value)} style={{display:value.receiptFlag==1?'':'none'}}>开收据</span>
                            </AuthContainer>
                            <AuthContainer authCodes={AUTH_KEYS.OPERATE_FINANCE_BILL_REFUND}>
                                <span className="table-operate-btn" onClick={this.refund.bind(this,value)} style={{display:value.refundFlag==1?'':'none'}}>退款</span>
                            </AuthContainer> */}
                            <AuthContainer authCodes={AUTH_KEYS.OPERATE_FINANCE_BILL_PAY}>
                                <span className="table-operate-btn" style={{ display: value.payFlag == 1 ? '' : 'none' }} onClick={this.isBohuiStatus.bind(this, value)}>付款</span>
                                {/* <Link to={'/app/operate/finance/bill/make-collection/' + value.encode+'?type=2'} ></Link> */}
                            </AuthContainer>
                            <AuthContainer authCodes={AUTH_KEYS.OPERATE_FINANCE_BILL_CALL}>
                                <span className="table-operate-btn" onClick={this.callPill.bind(this, value.encode)} style={{ display: value.remindFlag == 1 ? '' : 'none' }}>催缴</span>
                            </AuthContainer>
                        </div>
                    )
                }
            }
        ],
        data: [],
        queryParams: {
            pageNo: 1,
            pageSize: 10,
            keyword: undefined, // 搜索关键字
            projectEncodes: [], //项目id
            feeTypes: [], //费用类型
            auditStatus: undefined, //审核状态
            billType: undefined, // 账单类型
            payType: undefined, // 收付类型
            endDate: undefined,
            beginDate: undefined,
            dateType: undefined,
            paymentStatusFilter: 0,
            channel: undefined, //支付途径
            createUserEncode: undefined, //创建人
            budgetType: 2, //付款
            orderByList: [2],
            paymentStatus: undefined,
        },
        popAmount: '',
        tuiAmount: '',
        tuiReason: '',
    }
    componentDidMount() {
        this.getProjectList();
        this.getList(true);
        if (isManager()) {
            this.getCustomerList();
        }
    }
    isBohuiStatus(obj) {
        console.log(obj)
        if (obj.paymentReceiveAuditStatus == 4) {
            message.error('有付款尚在审批中，请确认后再重新尝试');
        } else if (obj.paymentStatus == 2 && obj.paymentAudit == false) {
            message.error('请先删除上次收支记录');
        } else {
            this.props.history.push('/app/operate/finance/bill/make-collection/' + obj.encode + '?type=2')
        }
    }
    changePopAmountValue(key, value) {
        let data = this.state
        data[key] = value.target.value;
        this.setState(data)
    }
    receipt(value) {
        // console.log(value);
        this.setState({ showReceiptModal: true, billInfo: value })
    }
    receiptOk() {
        if (this.state.popAmount) {
            http({
                api: API.billReceipt,
                data: {
                    billEncode: this.state.billInfo.encode,
                    amount: this.state.popAmount
                }
            }).then(res => {
                if (res.data) {
                    // console.log("loadCaptcha--------------", res.data);
                    message.success('操作成功');
                    this.setState({ showRefundModal: false })
                }
            })
        } else {
            message.error('请输入收据金额');
        }

    }
    receiptCancel() {
        this.setState({ showReceiptModal: false })
    }
    refund(value) {
        this.setState({ showRefundModal: true, billInfo: value })
    }
    refundOk() {
        if (!this.state.tuiAmount) {
            message.error('请输入退款金额');
            return false;
        }
        if (!this.state.tuiReason) {
            message.error('请输入退款原因');
            return false;
        }
        http({
            api: API.billrefundRefund,
            data: {
                billEncode: this.state.billInfo.encode,
                amount: this.state.tuiAmount,
                reason: this.state.tuiReason,
                refundEncode: ''
            }
        }).then(res => {
            if (res.data) {
                console.log("loadCaptcha--------------", res.data.datas);
                message.success('操作成功');
                this.setState({ showRefundModal: false })
            }
        })
    }
    refundCancel() {
        this.setState({ showRefundModal: false })
    }
    getPaymentCount() {
        http({
            api: API.billPaymentCount,
            data: this.state.queryParams
        }).then(res => {
            if (res.data) {
                console.log("loadCaptcha--------------", res.data);
                this.setState({
                    countData: res.data
                })
            }
        })
    }
    callPill(value) {
        console.log(value);
        http({
            api: API.billRemind,
            data: {
                billEncode: value
            }
        }).then(res => {
            if (res.data) {
                // console.log("loadCaptcha--------------", res.data.success);
                if (res.data.success == 1) {
                    success();
                }
                if (res.data.success == 0) {
                    info();
                }
            }
        })
    }
    getList = (value) => {
        http({
            api: API.billGetList,
            data: this.state.queryParams
        }).then(res => {
            if (res.data) {
                // console.log("loadCaptcha--------------", res.data.datas);
                let tempData = this.state.queryParams;
                tempData.total = res.data.total;
                tempData.pageNo = res.data.pageNo;
                tempData.pageSize = res.data.pageSize;
                this.setState({
                    data: res.data.datas,
                    queryParams: tempData
                })
            }
        })
        if (value) {
            this.getPaymentCount();
        }
    }
    keyWordSearch = (value) => {
        let queryParams = this.state.queryParams
        queryParams.pageNo = 1
        queryParams.keyword = value
        this.setState({
            queryParams
        })
        this.getList(true)
    }
    selectChange = (attr, data) => {
        // console.log('+++++++++')
        // console.log(attr,data);
        let queryParams = this.state.queryParams
        queryParams.pageNo = 1
        queryParams[attr] = data
        this.setState({ queryParams })
        this.getList(true)
    }
    resetParams = () => {
        let data = Object.assign({}, this.state.queryParams, {
            pageNo: 1,
            pageSize: 10,
            keyword: undefined, // 搜索关键字
            projectEncodes: [], //项目id
            feeTypes: [], //费用类型
            auditStatus: undefined, //审核状态
            billType: undefined, // 账单类型
            payType: undefined, // 收付类型
            endDate: undefined,
            beginDate: undefined,
            dateType: undefined,
            paymentStatusFilter: 0,
            createUserEncode: undefined,
            channel: undefined,
            budgetType: 2, //付款
            orderByList: [2],
            paymentStatus: undefined,
        });
        this.setState({ queryParams: data, dateValue: [] }, () => {
            this.getList(true);
        });
    }
    setKeyWord = (e) => {
        let data = Object.assign({}, this.state.queryParams, { keyword: e.target.value });
        this.setState({ queryParams: data });
    }
    onChange = (pageNo, pageSize, sort) => {
        console.log(sort)
        let queryParams = this.state.queryParams
        if (sort) {
            if (sort.columnKey == "payDate") {
                if (sort.order == "ascend") {
                    queryParams.orderByList = [15]
                }
                if (sort.order == "descend") {
                    queryParams.orderByList = [16]
                }
            }
            if (sort.columnKey == "createTime") {
                if (sort.order == "ascend") {
                    queryParams.orderByList = [1]
                }
                if (sort.order == "descend") {
                    queryParams.orderByList = [2]
                }
            }
            if (sort.columnKey == "paidDate") {
                if (sort.order == "ascend") {
                    queryParams.orderByList = [21]
                }
                if (sort.order == "descend") {
                    queryParams.orderByList = [22]
                }
            }
        }
        queryParams.pageNo = pageNo
        queryParams.pageSize = pageSize
        this.setState({ queryParams })
        this.getList()
    }
    //选择处理时间类型
    choosedateType(e) {
        let data = Object.assign({}, this.state.queryParams, { dateType: e, pageNo: 1, pageSize: 10 })
        this.setState({ queryParams: data }, () => {
            if (this.state.queryParams.dateType && this.state.queryParams.beginDate && this.state.queryParams.endDate) {
                this.getList(true);
            }
        });
    }
    //改变上报时间
    onChangedate(date, options) {
        console.log(date);
        let data = Object.assign({}, this.state.queryParams, { beginDate: options[0], endDate: options[1], pageNo: 1, pageSize: 10 })
        this.setState({ queryParams: data, dateValue: date }, () => {
            if (this.state.queryParams.dateType && this.state.queryParams.beginDate && this.state.queryParams.endDate) {
                this.getList(true);
            }
            if (this.state.queryParams.beginDate == '' && this.state.queryParams.endDate == '') {
                this.getList(true);
            }
        });

    }
    changetab(value) {//修改tab
        let data = Object.assign({}, this.state.queryParams, { paymentStatusFilter: value, pageNo: 1 });
        this.setState({ queryParams: data }, () => {
            this.getList();
        });
    }
    getProjectList() {
        http({
            api: API.commonProjectfilter,
        }).then(res => {
            console.log("loadCaptcha--------------", res.data);
            this.setState({
                projects: res.data,
                fetchingprojects:false
            })
        })
    }
    getCustomerList(value) {
        console.log(value)
        http({
            api: API.userGetList,
            data: {
                nameLike: value,
                pageSize: null
            }
        }).then(res => {
            console.log(res);
            this.setState({
                man: res.data.datas
            })
        })
    }
    handleChange(value) {
        this.setState({
            fetching: false,
        }, () => { this.getList(true) });
    }

    notFount() {
        return (
            <div style={{ textAlign: 'center', height: '100px', lineHeight: '100px' }}>加载中...</div>
        )
    }
    notFoundContent() {
        return (
            <div className='ant-empty ant-empty-normal ant-empty-small'><div className="ant-empty-image"></div><p className="ant-empty-description">暂无数据</p></div>)
    }


    render() {
        const { queryParams: params, fetching, countData, billInfo ,fetchingprojects} = this.state;
        const options = this.state.projects.map(per => {
            return <Option value={per.encode} key={per.encode}>{per.name}</Option>
        })
        const optionsFee = feeTypeList.map((per, index) => {
            if (per == '其他费用') {
                return <Option value={99} key={99}>{per}</Option>
            } else {
                return <Option value={index + 1} key={per}>{per}</Option>
            }
        })
        const manOptions = this.state.man && this.state.man.map(per => {
            return <Option value={per.idCode} key={per.idCode}>{per.name}-{per.departmentName}</Option>
        })
        return (
            <div className="finance">
                <Modal
                    className="passModal"
                    title='申请退款'
                    closable={false}
                    visible={this.state.showRefundModal}
                    onOk={this.refundOk.bind(this)}
                    okText='确定'
                    width={400}
                    onCancel={this.refundCancel.bind(this)}
                >
                    <div>
                        <Row className="fs12 b12">
                            退款金额<span className="redPoint">*</span>
                        </Row>
                        <Row className="fs12 b12">
                            <Input placeholder="请输入" type="number" value={this.state.tuiAmount} onChange={this.changePopAmountValue.bind(this, 'tuiAmount')} suffix="元" />
                        </Row>
                        <Row className="fs12">
                            退款原因<span className="redPoint">*</span>
                        </Row>
                        <TextArea rows={4} value={this.state.tuiReason} onChange={this.changePopAmountValue.bind(this, 'tuiReason')} placeholder="请输入" style={{ marginTop: '8px' }} maxLength={200} />
                    </div>
                </Modal>
                <Modal
                    className="passModal"
                    title='开收据'
                    closable={false}
                    visible={this.state.showReceiptModal}
                    onOk={this.receiptOk.bind(this)}
                    okText='确定'
                    width={400}
                    onCancel={this.receiptCancel.bind(this)}
                >
                    <div>
                        <Row className="fs12 b12">
                            实收金额：{billInfo.paidAmount}元
                    </Row>
                        <Row className="fs12 b12">
                            已开收据金额：{billInfo.receiptAmount}元
                    </Row>
                        <Row className="fs12 b12">
                            待开收据金额：{billInfo.couldReceiptAmount}元
                    </Row>
                        <Row className="fs12 b12">
                            本次收据金额<span className="redPoint">*</span>
                        </Row>
                        <Input placeholder="请输入" type="number" value={this.state.popAmount} onChange={this.changePopAmountValue.bind(this, 'popAmount')} />
                    </div>
                </Modal>
                <div className="contract-list">
                    <div className="contract-search">
                        <div className="contract-list-row">
                            <span className='w240'>
                                <Input.Search placeholder="请输入收款人姓名/手机号/账单关联房间号" onChange={this.setKeyWord}
                                    onSearch={this.keyWordSearch} value={params.keyword} className='w240' />
                            </span>
                            <span className='w104'>
                                <ISelect data={this.state.bill} placeholder="账单类型" value={params.billType}
                                    selectMethod={this.selectChange.bind(this, 'billType')} className='w104' />
                            </span>
                            <span className='w104'>
                                <ISelect data={this.state.payChannel} placeholder="支付途径" value={params.channel}
                                    selectMethod={this.selectChange.bind(this, 'channel')} className='w104' />
                            </span>
                            <span className='w104'>
                                <ISelect data={this.state.auditStatusList} placeholder="账单状态" value={params.auditStatus}
                                    selectMethod={this.selectChange.bind(this, 'auditStatus')} className='w104' />
                            </span>
                            <span className='w104'>
                                <ISelect data={this.state.pay} placeholder="付款状态" value={params.paymentStatus}
                                    selectMethod={this.selectChange.bind(this, 'paymentStatus')} className='w104' />
                            </span>
                            <span className='w104'  style={{marginRight:'0px'}}>
                                <ISelect
                                    data={this.state.dateType}
                                    className='w104'
                                    value={params.dateType}
                                    placeholder="时间类型"
                                    suffixIcon={<Icon type="caret-down" />}
                                    onSelect={this.choosedateType.bind(this)}>
                                </ISelect>
                            </span>
                            <span className='w206 cx-date-style'>
                                <RangePicker onChange={this.onChangedate.bind(this)} className='w206' value={this.state.dateValue} />
                            </span>
                            <span className='w104'>
                                <Select
                                    disabled={!isManager()}
                                    value={params.createUserEncode}
                                    onChange={this.selectChange.bind(this, 'createUserEncode')}
                                    showSearch
                                    allowClear
                                    showArrow={false}
                                    placeholder="创建人员"
                                    notFoundContent={fetching ? <Spin size="small" /> : null}
                                    filterOption={false}
                                    onSearch={this.getCustomerList.bind(this)}
                                    notFoundContent='无匹配结果'
                                    className='w104'
                                >
                                    {manOptions}
                                </Select>
                            </span>


                            {/* <Row gutter={12} style={{ marginBottom: '12px',display:'flex' }}> */}
                            <span className='w288'>
                                <Select dropdownClassName="cx-dropdown-style" mode="multiple"  otFoundContent={fetchingprojects ? this.notFount() : this.notFoundContent()} placeholder="项目名称" value={params.projectEncodes} className='w288' onChange={this.selectChange.bind(this, 'projectEncodes')}>
                                    {options}
                                </Select>
                            </span>
                            <span className='w288 ml12'>
                                <Select mode="multiple" placeholder="费用类型" value={params.feeTypes} className='w288' onChange={this.selectChange.bind(this, 'feeTypes')}>
                                    {optionsFee}
                                </Select>
                            </span>
                            <Col span={3} className="reset">
                                <a onClick={this.resetParams.bind(this)}>重置</a>
                            </Col>
                        </div>
                        <Row style={{ 'marginTop': '16px' }}>
                            <span className={`tabClass ${this.state.queryParams.paymentStatusFilter === 0 ? "activeTab" : null}`} onClick={this.changetab.bind(this, 0)}>全部({countData.all})</span>
                            <span className={`tabClass ${this.state.queryParams.paymentStatusFilter === 1 ? "activeTab" : null}`} onClick={this.changetab.bind(this, 1)}>未结清({countData.notPaid})</span>
                            <span className={`tabClass ${this.state.queryParams.paymentStatusFilter === 3 ? "activeTab" : null}`} onClick={this.changetab.bind(this, 3)}>已结清({countData.allPaid})</span>
                        </Row>
                    </div>
                    <div className="contract-table OperateMaintaintable-body">
                        <Table rowKey={record => record.encode} scroll={{ x: 1300 }} columns={this.state.columns} selfProcessData={this.state.data} total={params.total} change={this.onChange} current={params.pageNo} />
                    </div>
                </div>
            </div>
        )
    }
}

export default PayBill