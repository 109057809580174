import { API } from '@/http/api';
import http from '@/http';

function customerInit(){
    let  config = {
        api: API.customerInit
    }
    return http(config)
}

function areaProvincesCity(){
    let  config = {
        api: API.areaProvincesCity
    }
    return http(config).then(res => {
        let data = res.data;
        return data; 
    })   

}

function areaInit(){
    let  config = {
        api: API.areaChinaArea
    }
    return http(config).then(res => {
        let data = res.data;
        return data; 
    })   

}

function subAreas(data){
    let  config = {
        api: API.areaSubAreas,
        data:data
    }
    return http(config)
}

function usergetList(value = null){
    let  config = {
        api: API.userGetList,
        data:{
            nameLike:value,
            pageSize:null
        }
    }  
    
    return http(config).then(res => {
        return res.data.datas;
    })
}

function customerCustomerStatusCount(){
    let  config = {
        api: API.customerCustomerStatusCount,
        data:{}
    }  
    
    return http(config)    
}


function customerCheck(data){
    let  config = {
        api: API.customerCheck,
        showLoading:true,
        data:data
    }  
    
    return http(config)
}


function customerAdd(data){
    let  config = {
        api: API.customerAdd,
        showLoading:true,
        data:data
    }  
    
    return http(config)
}

function customerEdit(data){
    let  config = {
        api: API.customerEdit,
        showLoading:true,
        data:data
    }  
    
    return http(config)
}

function customerGetList(data){
    let  config = {
        api: API.customerList,
        showLoading:true,
        data:data
    }  
    
    return http(config)
}

function customerDispatch(data){
    let  config = {
        api: API.customerDispatch,
        showLoading:true,
        data:data
    }  
    
    return http(config)    
}

function customerDispatchBatch(data){
    let  config = {
        api: API.customerDispatchBatch,
        showLoading:true,
        data:data
    }  
    
    return http(config)    
}

function customerGet(path){
    let  config = {
        api: API.customerGet,
        showLoading:true,
        path:path
    }  
    
    return http(config)    
}


function customerGetCustomerList(data){
    let  config = {
        api: API.customerGetCustomerList,
        // showLoading:true,
        data:data
    }      
    return http(config)
}

function getAddUserList(value) {
    let  config = {
        api: API.customerGetAddUserList,
        data:{
            nameLike:value,
            pageSize:null
        }
    }  
    
    return http(config).then(res => {
        return res.data.datas;
    })    
}

function getEditUserList(value) {
    let  config = {
        api: API.customerGetEditUserList,
        data:{
            nameLike:value,
            pageSize:null
        }
    }  
    
    return http(config).then(res => {
        return res.data.datas;
    })    
}

function getDispatchBatchUserList(value) {
    let  config = {
        api: API.customerGetDispatchBatchUserList,
        data:{
            nameLike:value,
            pageSize:null
        }
    }  
    
    return http(config).then(res => {
        return res.data.datas;
    })    
}

function getDispatchUserList(value) {
    let  config = {
        api: API.customerGetDispatchUserList,
        data:{
            nameLike:value,
            pageSize:null
        }
    }  
    
    return http(config).then(res => {
        return res.data.datas;
    })    
}


export {
    areaProvincesCity,
    customerInit,
    areaInit,
    subAreas,
    usergetList,
    customerCheck,
    customerAdd,
    customerGetList,
    customerDispatch,
    customerDispatchBatch,
    customerGet,
    customerCustomerStatusCount,
    customerEdit,
    customerGetCustomerList,
    getAddUserList,
    getEditUserList,
    getDispatchBatchUserList,
    getDispatchUserList
}



