import React, { Component } from 'react';
import { Layout, Breadcrumb, Button, Form, Checkbox, Input, Modal } from 'antd';
import Select from 'CMPT/common/Select'
import { Link } from 'react-router-dom'
import FileUploadBiz from 'CMPT/common/FileUploadVideo'
import { API } from '@/http/api';
import http from '@/http';
import {
    showLoading as loading,
    hideLoading as loadingEnd,
    showErrorMessage,
    showSuccessMessage
} from '@/utils/globalMessageReminder';

const { confirm } = Modal;

const { Content } = Layout;
const { TextArea } = Input;
class IssueAdd extends Component {
    state = {
        catalogCode: undefined,
        catalogId: 0,
        catalogName: "string",
        catalogList: [],
        topFlag: 0,
        encode: '',
        fileInfoList: [],
        fileName: "",
        videoUrl: "",
        videoPalyTime: "",
        videoPicUrl: "",
        videoTitle: "",
        videoType: "",
        fileList: []
    }

    handleChangeStatus = (e) => {
        let name = this.state.catalogList.find(item => item.id == e).name
        this.setState({
            catalogCode: e,
            catalogName: name
        })
    }
    getCatalogList() {
        const config = {
            api: API.helpCatalogList,
            data: {}
        }
        http(config).then(res => {
            if (res.success) {
                let data = res.data.map((item) => {
                    item.name = item.catalogName
                    item.value = item.id
                    return item
                })
                this.setState({
                    catalogList: data
                })
            }
        })
    }

    onchanggeCheckbox(e) {
        if (e.target.checked) {
            this.setState({
                topFlag: 1
            })
        } else {
            this.setState({
                topFlag: 0
            })
        }
    }

    submit(values) {
        if (!this.state.fileList[0] || !this.state.fileList[0].url) {
            showErrorMessage('请稍等，视频上传还没完成')
            return
        }
        let data = {
            ...values,
            catalogName: this.state.catalogName,
            topFlag: this.state.topFlag,
            videoUrl: this.state.fileList[0].url,
            videoPalyTime: this.state.fileList[0].duration,
            videoPicUrl: this.state.fileList[0].videoPicUrl,
            moduleId: 1,
            videoType: this.state.fileList[0].type
        }
        console.log(data)
        const config = {
            api: API.helpVideoAdd,
            successTip: '添加成功！',
            data: data
        }
        http(config).then(res => {
            if (res.success) {
                this.props.history.push('/app/crm/helphandle/video')
            }
        })
    }
    undateSubmit(values) {
        if (!this.state.fileList[0] || !this.state.fileList[0].url) {
            showErrorMessage('请稍等，视频上传还没完成')
            return
        }
        let data = {
            ...values,
            topFlag: this.state.topFlag,
            encode: this.state.encode,
            catalogName: this.state.catalogName,
            videoUrl: this.state.fileList[0].url,
            videoPalyTime: this.state.fileList[0].duration,
            videoPicUrl: this.state.fileList[0].videoPicUrl,
            moduleId: 1,
            videoType: this.state.fileList[0].type
        }
        const config = {
            api: API.helpVideoUpdateVideo,
            successTip: '编辑成功！',
            data: data
        }
        http(config).then(res => {
            if (res.success) {
                this.props.history.push('/app/crm/helphandle/video')
            }
        })
    }
    handleSubmit = (e) => {
        let self = this;
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                if (this.state.encode) {
                    this.undateSubmit(values)
                } else {
                    this.submit(values)
                }
                // confirm({
                //     title: `确认发布视频？`,
                //     content: '',
                //     onOk() {
                //         if (self.state.encode) {
                //             self.undateSubmit(values)
                //         } else {
                //             self.submit(values)
                //         }
                //     },
                //     onCancel() {},
                //   });
            } else {
                
            }
        });
    }
    getDetails(encode) {
        const config = {
            api: API.helpVideoDetail,
            data: {
                encode
            }
        }
        http(config).then(res => {
            if (res.success) {
                setTimeout(() => {
                    this.props.form.setFieldsValue({
                        videoTitle: res.data.videoTitle,
                        videoDesc: res.data.videoDesc,
                        catalogCode: res.data.catalogId,
                        catalogName: res.data.catalogName
                    })
                    this.setState({
                        topFlag: res.data.topFlag,
                        fileInfoList: [{
                            mediaExt: res.data.videoType,
                            uid: res.data.id,
                            url: res.data.videoUrl
                        }],
                        fileList: [{
                            url: res.data.videoUrl,
                            type: res.data.videoType,
                            duration: res.data.videoPalyTime,
                            videoPicUrl: res.data.videoPicUrl
                        }]
                    })
                }, 100)

            }
        })
    }

    componentDidMount() {
        this.getCatalogList()
        if (JSON.stringify(this.props.match.params) != '{}') {
            this.setState({
                encode: this.props.match.params.id
            }, () => {
                this.getDetails(this.props.match.params.id)
            })
        } else {
            this.setState({
                encode: ''
            })
        }
    }
    fileUp = fileList => {
        this.setState({ fileList: fileList,fileInfoList:fileList })
    }

    render() {
        const {
            getFieldDecorator,
        } = this.props.form;
        const config = {
            rules: [{ required: true, message: '请选择' }],
        }
        return (
            <Content className="intention-list intention-content">
                <div className="intention-list-head flexbox">
                    <Breadcrumb className="intention-list-head-bread">
                        <Breadcrumb.Item>
                            帮助中心
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to='/app/crm/helphandle/video'>视频教程</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{this.state.encode ? '编辑' : '新增'}</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <Form onSubmit={this.handleSubmit}>
                    <div className="intention-add-body">
                        <div>
                            <Form.Item label="标题" >
                                {getFieldDecorator('videoTitle', {
                                    rules: [{ required: true, message: '请输入'},{ max: 12, message: '不得超过12字'}],
                                })(
                                    <Input style={{ width: '240px' }} maxLength={12} placeholder="请输入" />
                                )}
                                <Checkbox onChange={(e) => { this.onchanggeCheckbox(e) }} checked={this.state.topFlag === 1 ? true : false} style={{ marginLeft: '30px' }}>置顶</Checkbox>
                            </Form.Item>

                            <Form.Item label="目录" >
                                {getFieldDecorator('catalogCode', config)(
                                    this.state.catalogList ? <Select style={{ width: '240px' }} placeholder='请选择' data={this.state.catalogList} selectMethod={this.handleChangeStatus} /> : null
                                )}
                            </Form.Item>


                            <Form.Item label="上传视频">
                                {getFieldDecorator('vedioInfoList')(
                                    <FileUploadBiz fileList={this.state.fileInfoList} fileUp={this.fileUp} />
                                )}
                            </Form.Item>
                            <Form.Item label="视频简介">
                                {getFieldDecorator('videoDesc', {
                                    rules: [{ required: true, message: '请输入'},{ max: 24, message: '不得超过24字'}],
                                })(
                                    <TextArea autoSize={{ minRows: 4, maxRows: 6 }} style={{ width: '480px' }} maxLength={24} placeholder="请输入" />
                                )}
                            </Form.Item>
                        </div>
                    </div>
                    <div className="btn-group">
                        <Button type="primary" htmlType="submit">发布</Button> <Button type="link" onClick={() => this.props.history.push('/app/crm/helphandle/video')}>取消</Button>
                    </div>
                </Form>
            </Content>
        );
    }
}

const IssueAddForm = Form.create({ name: 'IssueAdd' })(IssueAdd);

export default IssueAddForm;