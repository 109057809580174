/*
* @param auth:权限
* @param ModelName:整个组件的外层className
* @param wrapClassName:modal框外层className
* @param type:按钮类型默认为 primary
* @param name:按钮名称
* @param title:model框名称
* @param visible:model隐藏显示的blean值
* @param modellist:model框内容
* @param showModal:显示model框的方法
* @param destroyOnClose:是否摧毁组件内容 默认不摧毁
* @param onOK:点击确认的方法
* @param onCancel:点击取消的方法
* */

import React, { Component } from 'react';
import { Button, Modal } from 'antd';
import { AUTH_KEYS } from '@/utils/auth';
import AuthContainer from 'CMPT/common/auth/AuthContainer';

export default class Modelmodel extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const modellist = this.props.Drawermodel;
        const { ButtonNode } = this.props;

        return (
            <div className={this.props.ModelName}>
                <AuthContainer authCodes={AUTH_KEYS[this.props.auth]}>
                    {
                        !ButtonNode ? <Button type={this.props.buttontype} onClick={this.props.showModal.bind(this)}>{this.props.name}</Button>
                            : <span>{ButtonNode}</span>
                    }
                </AuthContainer>
                <Modal
                    wrapClassName={this.props.wrapClassName}
                    title={this.props.title}
                    visible={this.props.visible}
                    width={400}
                    destroyOnClose={this.props.destroyOnClose}
                    onOk={this.props.handleOk.bind(this)}
                    onCancel={this.props.handleCancel.bind(this)}>
                    {modellist}
                </Modal>
            </div>
        );
    }
}
