import React, { Component } from 'react';
import { Breadcrumb, Button, Form, message } from 'antd';
import BaseInfo from '../financeApproval/BaseInfo'
import HistoryPayRecord from '../financeApproval/HistoryPayRecord'
import PayInfo from './newComponent/PayInfo'
import { Link } from 'react-router-dom';
import Collection from './newComponent/Collection'
import http from '@/http';
import { API } from '@/http/api';
import "../financeApproval/finance.less"
import './bill.less'

const BreadcrumbItem = Breadcrumb.Item;
const iInfo = {
    billCategoryMap: "实收",
    payDate: "应收时间",
    paidDate: "实收时间",
    customerName: '付款人',
    customerPhone: '付款人手机号',
    paymentMessage: {
        companyBank: '收款银行',
        companyAccount: '收款账号',
        description: '收款备注',
        bank: '付款银行',
        account: '付款账号',

    },
    date: '收款日期',
    companyBank: '收款银行',
    payAmount: "本次收款金额",
    companyAccount: '收款账号',
    description: "收款备注",

    title1: "收款信息",
    title2: "付款信息",

    tagname: '收款',
    name1: '实收金额',
    name2: '剩余应收',
    hisName: '历史收款信息'
}
const oInfo = {
    billCategoryMap: "实付",
    payDate: "应付时间",
    paidDate: "实付时间",
    customerName: '收款人',
    customerPhone: '收款人手机号',
    paymentMessage: {
        companyBank: '付款银行',
        companyAccount: '付款账号',
        description: '付款备注',
        bank: '收款银行',
        account: '收款账号',

    },
    date: '付款日期',
    companyBank: '付款银行',
    payAmount: "本次付款金额",
    companyAccount: '付款账号',
    description: "付款备注",

    title1: "付款信息",
    title2: "收款信息",
    tagname: '付款',
    name1: '实付金额',
    name2: '剩余应付',
    hisName: '历史付款信息'
}

class MakeCollection extends Component {
    state = {
        billInfo: {},
        showMore: true,
        textDes: {},
        resetBill: {},
        reset: false,
        photos: [],
        loading: false,
    }
    componentWillMount() {
        let type = this.props.location.search.substr(1).split('=')[1];
        if (type == 1) {
            this.setState({
                textDes: iInfo
            })
        }
        else if (type == 2) {
            this.setState({
                textDes: oInfo
            })
        }
        else {
            this.props.history.push({ pathname: '/404' });
            console.log('404')
        }
    }
    componentDidMount() {
        // console.log(this.props.match);
        this.getInfo();
        this.getRejectedPayment();
    }
    getRejectedPayment() {
        http({
            api: API.billpayGetRejectedPayment,
            data: {
                billEncode: this.props.match.params.id
            }
        }).then(res => {
            if (res.data) {
                this.setState({
                    resetBill: res.data,
                    reset: true
                })
                if (res.data.fileList) {
                    setTimeout(() => {
                        this.props.form.setFieldsValue({
                            photos: res.data.fileList
                        })
                    }, 0)
                }
            }
        })
    }
    getInfo() {
        http({
            api: API.billGetById,
            data: {
                encode: this.props.match.params.id
            }
        }).then(res => {
            if (res.data) {
                console.log("loadCaptcha--------------", res.data);
                this.setState({
                    billInfo: res.data
                })
            }
        })
    }
    save(params) {
        console.log(params)
        this.setState({
            loading: true
        })
        if (this.state.reset) {
            params.paymentEncode = this.state.resetBill.paymentEncode;
            http({
                api: API.billpayRepay,
                data: params
            }).then((res) => {
                if (res.code == '0') {
                    message.success('提交成功');
                    this.submitCancel();
                }
                this.setState({
                    loading: false
                })
            })
        } else {
            http({
                api: API.billpayPay,
                data: params
            }).then((res) => {
                if (res.code == '0') {
                    message.success('提交成功');
                    this.submitCancel();
                }
                this.setState({
                    loading: false
                })
            })
        }
    }
    submitCancel(){
        // this.props.history.push({pathname:'/app/operate/finance/bill'});
        this.props.history.go(-1)
    }
    submit() {
        this.props.form.validateFields((err, values) => {
            console.log('Received values of values: ', values);
            if (!err) {
                if (values.date) {
                    values.date = values.date.format('YYYY-MM-DD');
                }
                values.bank = values.paymentMessage.bank;
                values.account = values.paymentMessage.account;
                values.billEncode = this.props.match.params.id;
                if ((values.payAmount - this.state.billInfo.couldPayAmount) > 0) {
                    message.error('收款金额不得大于应收金额')
                    return false;
                }
                if (values.payAmount < 0) {
                    message.error('收款金额不能为负数');
                    return false;
                }
                // console.log(this.state.billInfo.couldPayAmount - values.payAmount)
                this.save(values);
            }
        });
    }
    render() {
        const { textDes } = this.state;
        return (
            <div className="billWrap">
                <div className="bill-header">
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link style={{ color: '#333' }} to={{ pathname: "/app/operate/finance/bill" }}>
                                财务收支
                            </Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <Link style={{ color: '#333' }} to={{ pathname: "/app/operate/finance/bill" }}>
                                账单管理
                            </Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem>{textDes.tagname}</BreadcrumbItem>
                    </Breadcrumb>
                    <HistoryPayRecord {...this.state} {...this.props} btnname={textDes.hisName} />
                </div>

                <Form>
                    <BaseInfo {...this.state} {...this.props} noshowOverdue={true} />
                    <Collection {...this.state} {...this.props} />
                    <PayInfo {...this.state} {...this.props} />
                    <div className="submit">
                        <Button type="primary" onClick={this.submit.bind(this)} loading={this.state.loading}>提交</Button>
                        <Button type="link" style={{ 'marginLeft': '16px' }} onClick={this.submitCancel.bind(this)}>取消</Button>
                    </div>
                </Form>
            </div>
        )
    }
}

export default Form.create({ name: 'makeCollection' })(MakeCollection);