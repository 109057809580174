/*
*
* */
import React, { Component } from 'react';
import { Icon, message } from 'antd';
export default class MultiCascader extends Component {
    state = {
        ulWidth: '100%',
        secondUlwidth: '0',
        currentLevelId: '',
        data: [],
        dataSecond: [],
        selectedIds: [],
        selectedItems: [], // {id: 11, parentId: 1 , name: '2层-${name}'}
        downShow: false
    }
    componentWillReceiveProps(nextProps) {
        const { value, data, onChange } = nextProps
        if (nextProps.data !== this.props.data) {
            console.log('选中',value,data)
            if (value && value.length) {
                let selectedItems = []
                let ids = []
                value.forEach(per => {
                    ids.push(per.id)
                    ids.push(per.parentId)
                    const parent = data.find(item => {
                        return item.id === per.parentId
                    })
                    if (parent) {
                        const child = parent.child.find(ii => {
                            return ii.id === per.id
                        })
                        if(this.props.noShowArea){
                            selectedItems.push({id: child.id,no: parent.no, floorName: parent.name, parentId: child.parentId,area:child.area,price: child.price, name: `${parent.name}-${child.name}`,zoneNo:child.zoneNo})
                        }else{
                            selectedItems.push({id: child.id,no: parent.no, floorName: parent.name, parentId: child.parentId,area:child.area,price: child.price, name: `${parent.name}-${child.name}-${child.area}`,zoneNo:child.zoneNo})
                        }
                        
                    }
                })
                if (onChange) {
                    onChange(selectedItems, true)
                }
                this.setState({
                    selectedIds: ids,
                    selectedItems
                })
            } else {
                this.setState({
                    selectedIds: [],
                    selectedItems: []
                })
            }
            this.setState({
                data: data || [],
                dataSecond: [],
                ulWidth: '100%'
            })
        }
    }
    setSecond = (item) => {
        this.setState({
            ulWidth: '30%',
            secondUlwidth: '70%',
            dataSecond: item.child,
            currentLevelId: item.id
        })
    }
    doAllAction = (item) => {
        const { onChange } = this.props
        let data = this.state.data
        let dataSecond = this.state.dataSecond
        let selectedItems = this.state.selectedItems
        let ids = this.state.selectedIds
        const parentItem = data.find(per => {
            return per.id === item.parentId
        })
        if (item.isSelectAll) { // 全选时点击需要取消选中
            parentItem.child.forEach(per => {
                const childIndex = ids.findIndex(i => {
                    return i === per.id
                })
                if (childIndex !== -1) {
                    ids.splice(childIndex, 1)
                }
                const parentIndex = ids.findIndex(i => {
                    return i === item.parentId
                })
                if (parentIndex !== -1) {
                    ids.splice(parentIndex, 1)
                }
                const itemIndex = selectedItems.findIndex(ii => {
                    return ii.id === per.id
                })
                if (itemIndex !== -1) {
                    selectedItems.splice(itemIndex, 1)
                }
            })
            item.isSelectAll = false
        } else {
            parentItem.child.forEach(per => {
                const childIndex = ids.findIndex(i => {
                    return i === per.id
                })
                if (childIndex === -1 && per.id !== 'all-rooms') { // 不在的时候添加
                    ids.push(per.id)
                    ids.push(item.parentId)
                    if(this.props.noShowArea){
                        selectedItems.push({id: per.id, no: parentItem.no, floorName: parentItem.name, parentId: per.parentId,area: per.area, price: per.price, name: `${parentItem.name}-${per.name}`,zoneNo:per.zoneNo})
                    }else{
                        selectedItems.push({id: per.id, no: parentItem.no, floorName: parentItem.name, parentId: per.parentId,area: per.area, price: per.price, name: `${parentItem.name}-${per.name}${per.area ? `-${per.area}` : ''}`,zoneNo:per.zoneNo})
                    }
                } 
            })
            item.isSelectAll = true
        }
        dataSecond.splice(0, 1, item)
        if (onChange) {
            onChange(selectedItems)
        }
        this.setState({
            ids,
            selectedItems,
            dataSecond
        })
    }
    select = (item, e) => {
        const { onChange, noShowArea } = this.props
        if (e) {
            e.stopPropagation()
        }
        if (item.id === 'all-rooms') {
            if (!item.canSelect) return
            this.doAllAction(item)
            return 
        } else if (!item.area && !noShowArea) {
            // message.warning('请先补充计租面积')
            return
        }
        let data = this.state.data
        let selectedItems = this.state.selectedItems
        let ids = this.state.selectedIds
        const index = ids.findIndex(per => {
            return per === item.id
        })
        if (index === -1) {
            ids.push(item.id)
            ids.push(item.parentId)
            const parent = data.find(per => {
                return per.id === item.parentId
            })
            if(this.props.noShowArea){
                selectedItems.push({id: item.id, no: parent.no, floorName: parent.name, parentId: item.parentId,area: item.area, price: item.price, name: `${parent.name}-${item.name}`,zoneNo:item.zoneNo})
            }else{
                selectedItems.push({id: item.id, no: parent.no, floorName: parent.name, parentId: item.parentId,area: item.area, price: item.price, name: `${parent.name}-${item.name}${item.area ? `-${item.area}` : ''}`,zoneNo:item.zoneNo})
            }
            
        } else {
            ids.splice(index, 1)
            const parentIndex = ids.findIndex(per => {
                return per === item.parentId
            })
            ids.splice(parentIndex, 1)
            const selectedIndex = selectedItems.findIndex(per => {
                return per.id === item.id
            })
            selectedItems.splice(selectedIndex, 1)
        }
        this.setState({
            selectedIds: ids,
            selectedItems: selectedItems
        }, () => {
            this.checkSelectAll()
        })
        if (onChange) {
            onChange(selectedItems)
        }
    }
    checkSelectAll = () => {
        const dataSecond = this.state.dataSecond
        const itemFirst = dataSecond[0]
        if (itemFirst && itemFirst.canSelect) {
            const selectedItems = this.state.selectedItems
            const selectedNum = selectedItems.filter(per => {
                return per.parentId === itemFirst.parentId
            })
            if (itemFirst.canSelectNum === selectedNum.length) {
                itemFirst.isSelectAll = true
            } else {
                itemFirst.isSelectAll = false
            }
            this.setState({
                dataSecond
            })
        }
    }
    firstLevelClass = (id) => {
        const { selectedIds, currentLevelId } = this.state
        let className = ''
        if (selectedIds.indexOf(id) !== -1) {
            className += 'active'
        }
        if (currentLevelId === id) {
            className += ' current'
        }
        return className
    }
    blockClick = () => {
        this.setState({
            downShow: !this.state.downShow
        })
    }
    transNameArea = (item) => {
        if(this.props.noShowArea){
            return item.name
        }else{
            if (item.id === 'all-rooms') {
                if (item.isSelectAll) {
                    return <span style={{color: '#FBBC06'}}>{item.name}</span>
                } else if (item.canSelect) {
                    return item.name
                }
                return <span style={{color: '#5D637B'}}>{item.name}</span>
            }
            if (!item.area && item.id !== 'all-rooms') {
                return <span style={{color: '#5D637B'}}>{item.name}-未知m² (需完善面积信息后才可选择)</span>
            }
            return `${item.name}-${item.area}㎡`
        }       
    }
    render() {
        const { placeholder } = this.props
        return(
            <div className="bo-cascader">
                <div className="bo-cascader-block" onClick={this.blockClick}>
                    {this.state.selectedItems.length ? <ul>
                        {this.state.selectedItems.map(per => {
                            return <li key={per.id}>{per.name}<Icon type="close" onClick={this.select.bind(this, per)} /></li>
                        })}
                        </ul> : <span className="bo-cascader-placeholder">{placeholder}</span>
                    }
                </div>
                <div className={this.state.downShow ? 'bo-cascader-down block' : 'bo-cascader-down none'}>
                    <ul style={{width: this.state.ulWidth}}>
                        {this.state.data.map(per => {
                            return <li key={per.id} className={this.firstLevelClass(per.id)}
                                        onClick={this.setSecond.bind(this, per)}>{per.name}层</li>
                        })}
                    </ul>
                    {this.state.dataSecond.length ?<ul style={{width: this.state.secondUlwidth}}>
                        {this.state.dataSecond.map(item => {
                            return <li key={item.id} className={this.state.selectedIds.indexOf(item.id) !== -1 ? 'active' : ''}
                                        onClick={this.select.bind(this, item)}>
                                        {this.transNameArea(item)}
                                        <Icon style={{display: this.state.selectedIds.indexOf(item.id) !== -1 ? 'inline' : 'none' }} type="check" />
                                    </li>
                        })}
                    </ul> : null}
                </div>
                <div className={this.state.downShow ? 'bo-cascaser-mask' : ''} onClick={this.blockClick}></div>
            </div>
        )
    }
}
