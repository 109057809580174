import React from "react";
import { Breadcrumb, Form, Input, Button, Radio, Select } from "antd";

import { API } from "@/http/api";
import http from "@/http";

import "./detail.less";

class Detail extends React.PureComponent {
  state = {
    code: null,
    roleList: [],
    organizationList: []
  };

  componentDidMount() {
    const { match } = this.props;
    this.fetchRoleAll();
    // 获取组织列表 枚举
    this.accountGetOrganizationList()
    if (match && match.params && match.params.id) {
      this.fetchDetail(match.params.id);
      this.setState(
        {
          code: match.params.id,
        },
        () => {
          // this.getGoodDetail()
        }
      );
    }
  }

  // 获取所有组织列表
  accountGetOrganizationList = () => {
    const config = {
      api: API.accountGetOrganizationList,
      showLoading: true,
    };
    http(config).then((res) => {
      if (res && res.success) {
        this.setState({
          organizationList: res.data
        })
      }
    });
  }

  fetchRoleAll = () => {
    const config = {
      api: API.accountRoleAll,
    };
    http(config).then((res) => {
      if (res && res.success && res.data) {
        console.log(res.data);
        this.setState({ roleList: res.data });
      }
    });
  };

  fetchDetail = (code) => {
    const config = {
      api: API.accountDetail,
      data: {
        code,
      },
      showLoading: true,
    };
    http(config).then((res) => {
      if (res && res.success && res.data) {
        this.props.form.setFieldsValue(res.data);
      }
    });
  };

  fetchAdd = (data) => {
    const config = {
      api: API.accountAdd,
      data: data,
      showLoading: true,
    };
    http(config).then((res) => {
      if (res && res.success) {
        history.go(-1);
      }
    });
  };

  fetchUpdate = (data) => {
    const config = {
      api: API.accountUpdate,
      data: data,
      showLoading: true,
    };
    http(config).then((res) => {
      if (res && res.success) {
        history.go(-1);
      }
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const { code } = this.state;

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { status, roleId } = values;
        if (code) {
          this.fetchUpdate({
            code,
            status,
            roleId,
          });
        } else {
          console.log("Received values of form: ", values);
          this.fetchAdd(values);
        }
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { code } = this.state;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 8, offset: 1 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };
    return (
      <div className="enterprise-auth-con">
        <div className="enterprise-auth-header">
          <Breadcrumb>
            <Breadcrumb.Item>设置中心</Breadcrumb.Item>
            <Breadcrumb.Item>账号管理</Breadcrumb.Item>
            <Breadcrumb.Item>{code ? "编辑账号" : "新增账号"}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="content_box">
          <div>账号信息</div>
          <Form {...formItemLayout} className="account-manage-form">
            {/* <Form.Item label="员工UM账号">
              {getFieldDecorator("umAccount", {
                rules: [
                  {
                    required: true,
                    message: "请输入员工UM账号",
                  },
                  {
                    max: 40,
                    message: "请输入员工UM账号",
                  },
                ],
              })(<Input disabled={code} />)}
            </Form.Item> */}
            <Form.Item label="姓名">
              {getFieldDecorator("name", {
                rules: [
                  {
                    required: true,
                    message: "请输入姓名",
                  },
                  {
                    max: 20,
                    message: "请输入姓名",
                  },
                ],
              })(<Input placeholder="请输入姓名" disabled={code} />)}
            </Form.Item>
            <Form.Item label="手机号码">
              {getFieldDecorator("phone", {
                rules: [
                  {
                    required: true,
                    message: "请输入手机号码",
                  },
                  {
                    len: 11,
                    message: "请输入手机号码",
                  },
                ],
              })(<Input placeholder="请输入手机号码" disabled={code} />)}
            </Form.Item>

            
            <Form.Item label="所属部门">
              {getFieldDecorator("organizationCode", {
                rules: [
                  {
                    required: true,
                    message: "请选择所属部门",
                  },
                ],
              })(
                <Select placeholder="请选择所属部门">
                  {this.state.organizationList && this.state.organizationList.map((item) => (
                    <Select.Option value={item.organizationCode}>{item.organizationName}</Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            {/* <Form.Item label="账号角色">
              {getFieldDecorator("roleId", {
                rules: [
                  {
                    required: true,
                    message: "请选择账号角色",
                  },
                ],
              })(
                <Select placeholder="请选择账号角色">
                  {this.state.roleList.map((item) => (
                    <Select.Option value={item.id}>{item.name}</Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item> */}
            
              <Form.Item label="账号状态">
                {getFieldDecorator("status", {
                  rules: [
                    {
                      required: true,
                      message: "请输入账号状态",
                    },
                  ],
                })(
                  <Radio.Group>
                    <Radio value={1}>启用</Radio>
                    <Radio value={0}>停用</Radio>
                  </Radio.Group>
                )}
              </Form.Item>
            {/* {!code && (
              <Form.Item wrapperCol={{ offset: 5 }}>
                <p>
                  员工UM账号、员工姓名、员工手机号码一经创建无法修改，请保证信息准确性确认账号信息!
                </p>
              </Form.Item>
            )} */}
            <Form.Item {...tailFormItemLayout}>
              <Button type="primary" onClick={this.handleSubmit}>
                {code ? "确定" : "确认账号信息"}
              </Button>
              <Button style={{ marginLeft: 20 }} onClick={() => history.go(-1)}>
                取消
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

export default Form.create()(Detail);
