import React, { Component } from 'react'
import http from '@/http';
import { API } from '@/http/api';
import { Select ,message,Modal,Form,Button,Input} from 'antd';
const Item = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

class EditRefund extends Component {
    state = {
        showPrintModal:false,
        payChannel:[],
        shoujuModal:false,
        encode:'',
        billInfo:{}
    }
    componentDidMount(){

    }
    getRejectRefund(){
        http({
            api: API.billrefundGetById,
            data:{
                encode:this.props.billInfo.refundEncode
            }
        }).then(res => {
            if(res.data){
                this.setState({
                    billInfo:res.data
                })
            }           
        })
    }
    printOk(){
        this.props.form.validateFields((err, values) => {
            const { billInfo } = this.state;
            http({
                api: API.billrefundUpdate,
                data:{
                    billEncode:billInfo.billEncode,
                    amount:values.amount,
                    reason:values.reason,
                    refundEncode:billInfo.refundEncode
                }
            }).then(res => {
                if(res.success){
                    message.success('编辑成功');
                    this.props.action();
                }                    
            })
            this.setState({
                showPrintModal:false
            })
        })
        
    }
    printCancel(){
        this.setState({
            showPrintModal:false
        })
    }
    showPrint(){
        // console.log(this.props.billInfo)
        this.setState({
            showPrintModal:true
        })
        this.getRejectRefund();
    }
    render(){
        const { billInfo } = this.state;
        const type = this.props.type;
        const { getFieldDecorator } = this.props.form;
        return(
            <div>
                <Modal
                    className="passModal"
                    title='退款'
                    closable={false}
                    visible={this.state.showPrintModal}
                    onOk={this.printOk.bind(this)}
                    okText='确定'
                    width={400}
                    onCancel={this.printCancel.bind(this)}
                >     
                    <div>
                        <Item label="退款金额" style={{ width: '240px'}}>
                            {getFieldDecorator('amount', {
                                initialValue:billInfo.amount,
                                rules: [{
                                    required: true, message: '请选择',
                                }]
                            })(
                                <Input placeholder="请输入" suffix="元"/>              
                            )}
                        </Item>
                        <Item label="退款原因" style={{ width: '336px'}}>
                            {getFieldDecorator('reason', {
                                initialValue:billInfo.reason,
                                rules: [{
                                    required: true, message: '请选择',
                                }]
                            })(
                                <TextArea rows={4}  placeholder="请输入"/>                       
                            )}
                        </Item>
                    </div>        
                </Modal>
                <span className="table-operate-btn" onClick={this.showPrint.bind(this)} style={{display:type==1?'':'none'}}>编辑</span>
                <Button type="primary" onClick={this.showPrint.bind(this)} style={{display:type==2?'':'none'}}>编辑</Button>
            </div>
        )
    }
}

export default Form.create({ name: 'EditRefund' })(EditRefund);