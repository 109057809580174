
import React from 'react';
import { Card } from 'antd';
import './InfoCard.less';

export default function InfoCard(props) {
    let visible = true;
    if ('visible' in props) {
        visible = props.visible;
    }
    return (
        <Card className={`card ${props.className}`}>
            <div style={{ display: visible ? 'block' : 'none' }} className="title">{props.title}</div>
            {props.children}
        </Card>
    );
}