import { AJAX_TYPE } from "../config";

// 部门管理
const prefix = "departmentManagement";

export default {
	// 部门管理-树
	[`${prefix}Tree`]: {
		url: "/web/crm/organization/getOrganizationTreeList",
		type: AJAX_TYPE.GET,
	},
	// 部门管理-列表
	[`${prefix}List`]: {
		url: "/web/crm/organization/getOrganizationPageList",
		type: AJAX_TYPE.POST,
	},
};
