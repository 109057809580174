import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import "./EquipmentDetail.less";
import { API } from "@/http/api";
import http from "@/http";
import { Breadcrumb, Button, Modal, Input, message, Select, Spin } from "antd";
import IMGurl from "@/images/empty_schedule@2x.png";
import { showErrorMessage } from "@/utils/globalMessageReminder";
import { formatLinkSearch } from "@/utils";
import moment from "moment";
import WorkListItem from "../components/WorkListItem";

const { TextArea } = Input;
const { Option } = Select;

const basicsInfoData = [
  // 基础信息
  { label: "设备编号", field: "equipmentNumber" },
  { label: "设备名称", field: "equipmentName" },
  { label: "产权", field: "relationshipTypeName" },
  { label: "设备规格", field: "" },
  { label: "设备类型", field: "strType" },
  { label: "品牌/型号", field: "brandModel" },
  { label: "出厂日期", field: "dateOfProduction" },
  { label: "设备所在地", field: "equipmentSeat" },
  { label: "铭牌编号/发动机号", field: "licensePlateNumber" },
  { label: "发动机号", field: "engineNumber" },
  { label: "铲斗容量", field: "stand" },
  { label: "最大挖掘深度", field: "deep" },
  { label: "创建时间", field: "creationTime" },
  { label: "创建人", field: "creator" },
  { label: "创建人公司", field: "affiliatedEnterprise" },
];

const controlInfoData = [
  // 操控信息
  { label: "操作员", field: "operatorName" },
  { label: "GPS设备码", field: "sensorCoding" },
  { label: "设备状态", field: "useStatusName" },
  { label: "出租状态", field: "releaseStatusName" },
];
class EquipmentDetail extends Component {
  state = {
    code: null, // 当前页面的ID,从前一个页面传递过来的
    pageNo: null, // 当前企业在前一个列表中的页码
    currentageCount: null, // 前一个页面中 列表内当前页的数量
    backPageNo: 1, //返回列表页面的页码
    data: [],
    
    previewVisible: false,
    IMGURL: "", // 缩放图
  };
  componentWillMount() {
    this.cDetail();

    if (this.props.location.search) {
      // 获取参数
      const search = this.props.location.search;
      const formatData = formatLinkSearch(search);

      // 解析
      let code1 = this.props.match.params.code;
      let pageNo1 = null;
      let currentageCount1 = null;

      if (formatData && formatData.pageNo) {
        pageNo1 = formatData.pageNo;
      }
      if (formatData && formatData.currentPageCount) {
        currentageCount1 = formatData.currentPageCount;
      }

      // 赋值
      this.setState({
        code: code1,
        pageNo: pageNo1,
        backPageNo: pageNo1, //如果什么都不做，返回列表的原始页码即可。否则，需要判断跳转哪一页，尤其删除一个页面中的唯一一条数据
        currentageCount: currentageCount1,
      });
    }
  }
  componentDidMount() {}

  cDetail = () => {
    const config = {
      api: API.vAndEEquipmentDetail,
      path: { equipmentReleaseCode: this.props.match.params.code },
    };
    http(config).then((res) => {
      if (res && res.code == 200) {
        let { data } = res;

        let strType = data.primaryTypeName + " " + data.secondaryTypeName; //设备类型
        let brandModel = data.brandName + "/" + data.modelName; // 品牌/型号
        let equipmentSeat = data.provinceName + data.cityName + data.areaName; //设备所在地
        let operatorName = data.operatorList.length
          ? data.operatorList[0].operatorName
          : ""; //操作员
        if (data.extendJson) {
          let extendJsonData = JSON.parse(data.extendJson)
          console.log(extendJsonData, 'extendJsonData--------------------');
          extendJsonData.forEach((item, idx) => {
            if (item.label == '最大挖掘深度(mm)') { // 最大挖掘深度(mm)
              data.deep = item.field
            } else if (item.label == "标准铲斗容量(m3)"){ // 铲斗容量（m3）
              data.stand = item.field
            }
          })
        }


        data.strType = strType;
        data.brandModel = brandModel;
        data.equipmentSeat = equipmentSeat;
        data.operatorName = operatorName;
        data.dateOfProduction = moment(data.dateOfProduction).format(
          "YYYY-MM-DD"
        );
        data.creationTime =  moment(data.creationTime).format(
          "YYYY-MM-DD hh:mm:ss"
        );
        this.setState({
          data,
        });
      }
    });
  };

  cencel = () => {
  };

  // 设置图片
  closeImagePreview = () => {
    this.setState({ previewVisible: false });
  };
  // 设置图片缩放
  cImage = (value) => {
    if (!value) return;
    this.setState({
      previewVisible: true,
      IMGURL: value,
    });
  };
  render() {
    const {
      IMGURL,
      data,
     
      previewVisible,
    } = this.state;
    return (
      <div className="accountInfo">
        <div>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link
                to={{
                  pathname: "/app/crm/vehicleAndEquipment",
                  search: "?tab=1",
                }}
              >
                设备管理
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>设备详情</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div className="userInfo_body">
          <div>
            <div>
              <h2>
                <i></i> 基础信息
              </h2>
              <div>
                <ul>
                  {basicsInfoData.length > 0 &&
                    basicsInfoData.map((item1) => {
                      return (
                        <li key={item1.label}>
                          <span>{item1.label}</span>
                          <span className="span">{data[item1.field]  || '-'}</span>
                        </li>
                      );
                    })}
                  <li className="body_img">
                    <span>设备图片</span>
                    {data.imageList &&
                      data.imageList.map((item2, idx) => {
                        return (
                          <img
                            key={idx}
                            src={item2.url}
                            alt=""
                            onClick={() => this.cImage(item2.url)}
                          />
                        );
                      })}
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <h2>
                <i></i> 证件信息
              </h2>
              <div>
                <ul>
                  <li className="body_img">
                    <span>设备合格证</span>
                    {/* {data.equipmentCertificate &&
                      data.equipmentCertificate.map((item3, index) => {
                        return (
                          <img
                            key={index}
                            src={item3.url}
                            alt=""
                            onClick={() => this.cImage(item3.url)}
                          />
                        );
                      })} */}
                      {data.equipmentCertificate && data.equipmentCertificate.length ? data.equipmentCertificate.map((item, index) => 
                      (<img src={item.url} key={index} alt="" onClick={() => this.cImage(item.url)} />)) : '-'}
                  </li>
                  <li className="body_img">
                    <span>环保合格证书</span>
                    {/* {data.environmentalCertificate &&
                      data.environmentalCertificate.map((item4, idx) => {
                        return (
                          <img
                          key={idx}
                            src={item4.url}
                            alt=""
                            onClick={() => this.cImage(item4.url)}
                          />
                        );
                      })} */}
                      {data.environmentalCertificate && data.environmentalCertificate.length ? data.environmentalCertificate.map((item, index) => 
                      (<img src={item.url} key={index} alt="" onClick={() => this.cImage(item.url)} />)) : '-'}
                  </li>

                  <li className="body_img">
                    <span>车辆行驶证</span>
                    {/* {data.licenseFront &&
                      data.licenseFront.map((item, index) => (
                        <img
                          key={index}
                          src={item.url}
                          alt=""
                          onClick={() => this.cImage(item.url)}
                        />
                      ))}
                      {data.licenseBack &&
                        data.licenseBack.map((item, index) => (
                          <img
                            key={index}
                            src={item.url}
                            alt=""
                            onClick={() => this.cImage(item.url)}
                          />
                      ))} */}
                      {data.licenseFront && data.licenseFront.length ? data.licenseFront.map((item, index) => 
                      (<img src={item.url} key={index} alt="" onClick={() => this.cImage(item.url)} />)) : '-'}

                      {data.licenseBack &&
                        data.licenseBack.map((item, index) => (
                          <img
                            key={index}
                            src={item.url}
                            alt=""
                            onClick={() => this.cImage(item.url)}
                          />
                      ))}
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <h2>
                <i></i> 操控信息
              </h2>
              <div>
                <ul>
                  {controlInfoData &&
                    controlInfoData.map((item5) => {
                      return (
                        <li key={item5.label}>
                          <span>{item5.label}</span>
                          <span className="span">{data[item5.field] ||'-'}</span>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
            <div>
              <h2>
                <i></i> 工作计划
              </h2>
              <div className="work-list-wrapper">
                <div className="work-list">
                  {data.workList &&
                    data.workList.map((item, idx) => {
                      return (
                        <WorkListItem showButton key={idx} {...item} />
                      );
                    })}
                </div>
              </div>
            </div>
          </div>

          {/* 个人 */}
          <div>
            <div>
              <h2>
                <i></i>历史工作
              </h2>
                <div className="work-list">
                  {data.historyWorkList &&
                    data.historyWorkList.map((item,idx) => {
                      return <WorkListItem key={idx} {...item} />;
                    })}
                </div>
            </div>
          </div>
        </div>
        <div className="detail_btn">
          <Button onClick={() => this.props.history.goBack()}> 返回 </Button>
        </div>
        <Modal
          visible={previewVisible}
          title=" "
          footer={null}
          onCancel={this.closeImagePreview}
        >
          <img
            alt="图片预览"
            style={{ width: "100%", margin: "0 0 24px" }}
            src={IMGURL}
          />
        </Modal>
      </div>
    );
  }
}
export default EquipmentDetail;
