import React, { Component } from "react";
import {  Button,Modal,Input } from "antd";
 import Table from "CMPT/common/Table";
import http from "@/http";
import { API } from "@/http/api";
import Upload from 'CMPT/common/Upload';
import FilterPanel from "CMPT/common/FilterPanel";
import TableRenderHelp from "@/utils/TableRenderHelp.js";
import { FilterType } from '@/utils/fliter';
import ImgPreview from 'CMPT/common/ImgPreview';
import ChooseShop from './chooseShop/index';
import "./index.less";

class TopBrand extends Component {

  constructor(props) {
    super(props);
     this.uploadRef = React.createRef();// 引用 隐藏的 Upload组件
    }
       

    state = {
        type:1,//目前用不上
        total: 0, // 当前数据的总数
        pageNo: 1, // 当前所展示的页码
        currentPageCount: 1, //当前页面的数量
        pageSize:10,
        filterParm:{},//备份，当分页时，可以拿出来 和 过滤板块的数据整合


        columns:this.createColumns(),//列表字段集合
        httpData:[],//列表所用数据
        filterConfig:this.createFilterConfig(),// 过滤条的配置
        btnVisibleMap:{}, // logo右侧的"更改按钮"是否展示

        isShowChooseShop:false, // 是否展示 添加窗口
        selectedUploadCode:undefined, // 针对更换Logo，保存在 选中的一条数据对应的code

        imgList: [],
        currentImg: undefined,

        isShowChangeBrandName:false,// 是否展示修改品牌名称的对话框
         item4ChangeBrandName:undefined,// 即将修改品牌名称的 数据。 取code和旧名称使用
         newBrandName:undefined // 新的品牌名称
    };

   
    /**
     * 图片上传的回调
     * @param {*} value 
     */
    changeLogo= (value) => {
      if (value.file.status === 'done') {
          const item = value.file.response.file
          this.changeLogoHttp(item.absolutePath);
      }
  }

  /**
   * 更换某条数据的Logo
   * @param {*} imgUrl 
   * @returns 
   */
  changeLogoHttp=(imgUrl)=>{
    // 校验入参
     if(!imgUrl){
          return;
     }

     // 参数
    let {selectedUploadCode} = this.state;
    const config = {
      api: API.brandUpdate,
      data:  {
            code:selectedUploadCode,
            activityLogo:imgUrl
            }
     };
    
    // setup 2:请求，并初步处理
    http(config).then(res => {
      if (res && res.success && res.data) {
            this.goQuery();// 刷新
       }
    });
  }

 
   /**
    * 手动 触发文件选择
    * @param {*} code 
    */
    triggerChangeLogo(code){
      this.setState({selectedUploadCode:code},()=>{
        if(this.uploadRef){
          this.uploadRef.current.click();//模拟点击，触发 图片选择
         }
      })
        
    }

    goChangeName(){
      let {item4ChangeBrandName} = this.state;
      this.hiddenChangeBrandName();


      // 参数
      let {newBrandName} = this.state;
      const config = {
        api: API.brandUpdate,
        data:  {
          code:item4ChangeBrandName.code,
          brandName:newBrandName 
        }
       };
      
      // setup 2:请求，并初步处理
      let that  = this;
      http(config).then(res => {
        if (res && res.success && res.data) {
              this.goQuery();// 刷新

              this.setState({ newBrandName:undefined});// 删除 无效缓存

         }
      });
    }

    onBrandnameChange(value){
      this.setState({newBrandName:value});
    }
    
    triggerChangeBrandName(item){
       this.setState({ isShowChangeBrandName:true,item4ChangeBrandName:item});
    }

    hiddenChangeBrandName(){
      this.setState({ isShowChangeBrandName:false,item4ChangeBrandName:undefined});
    }

    /**
     * 鼠标滑过，展示 “更改”按钮
     * @param {*} code 
     */
    onMouseOver(code){
      var btnVisibleMap={};
      btnVisibleMap[code]= true;

      this.setState({  btnVisibleMap    });
    }

    /**
     * 隐藏 "更改" 按钮
     * @param {*} code 
     */
    handleMouseOut(code){
      this.setState({ 
        btnVisibleMap:{} // 都不显示 "更改" 按钮
      });
    }

    /**
     * 字段集合
     * @returns 
     */
    createColumns(){
      let help = new TableRenderHelp();

      return [
          help.createTextColumn("名称", "enterpriseName"),
          help.createTextColumn("企业简称", "enterpriseShortName"),
          help.createRenderColumn(
            "品牌名称",  "brandName", "brandName",
            (text, record) => {
              return   <div><span>{text||'--'}</span>
              <span className="text-color-highlight" size="small" style={{marginLeft:'8px'}}  onClick={() => { this.triggerChangeBrandName(record) }}>更改</span>
            </div>;
            }
          ),

          help.createTextColumn("认证状态", "certification"),
          help.createTextColumn("店铺名称", "shopName"),
          help.createRenderColumn(
            "公司Logo",  "logo", "logo", 
            (text, record) => {
              return   text?<img src={text} style={{ width: "100px", height: "100px" }} onClick={text ? () => { this.showImgPreview(text) }:undefined}   alt="公司Logo"/>
                       :<span>--</span>;
            }
          ),
          help.createRenderColumn(
            "活动Logo",  "activityLogo", "activityLogo",
            (text, record) => {
              return   <span  onMouseOver={()=>{this.onMouseOver(record.code)}} onMouseOut={()=>{this.handleMouseOut(record.code) }} >
              {text?<img  src={text}  onClick={text ?() => { this.showImgPreview(text) }:undefined}  style={{ width: "100px", height: "100px" }} alt="暂无活动logo" />:<span>--</span>}
              <span className="text-color-highlight" size="small" style={{marginLeft:'8px',color:'',visibility: this.state.btnVisibleMap[record.code] ?'visible':'hidden'}}  onClick={() => { this.triggerChangeLogo(record.code) }}>更改</span>
              </span>;
            }
          ),
          help.createTextColumn("更新人", "updateUser"),
          help.createTextColumn("更新时间", "updateTime"),
          
          {
            title: '操作',
            render: (record, index) => <div>
                 {/** TODO  后期应该对 快速点击 做屏蔽，同时 对请求做 等待处理 */}
                { (!record.flagStatus) &&<span className="text-color-highlight" style={{marginRight:'16px', whiteSpace: 'nowrap'}} onClick={() => { this.doTop(record) }}>置顶</span> }
                {  (record.flagStatus>0) && <span className="text-color-highlight" style={{marginRight:'16px', marginTop:'10px',whiteSpace: 'nowrap'}} onClick={() => { this.cancelTop(record) }}>取消置顶</span>}
                <span className="text-color-highlight" style={{marginRight:'16px',marginTop:'10px',whiteSpace: 'nowrap'}} onClick={() => { this.delete(record) }}>移除</span>
            </div>
        }
      ];

    }

    
    /**
     * 置顶某条数据
     * @param {*} data 
     */
    doTop=(data)=>{
      this.doUpdateTop({flagStatus:1,code:data.code});//1是去设置
    }

    /**
     * 取消置顶
     * @param {*} data 
     */
    cancelTop=(data)=>{
      this.doUpdateTop({flagStatus:0,code:data.code});//0是取消
    }

    /**
     * 置顶与否 实际是个更新操作
     * @param {*} data 
     */
    doUpdateTop=(data)=>{
       const config = {
        api: API.brandTop,
        data,
       };
      
      // setup 2:请求，并初步处理
      http(config).then(res => {
        if (res && res.success && res.data) {
              this.goQuery();
         }
      });
    }

    /**
     * 移除
     * @param {*} data 
     */
    delete=(data)=>{
      const config = {
        api: API.brandDelete,
        data:  {code:data.code},
       };
      
       http(config).then(res => {
        if (res && res.success && res.data) {
              this.goQuery();
         }
      });
    }

      //分页
  onChangePage = (pageNo, pageSize, sort) => {
    this.setState( {
        pageNo: pageNo,
        pageSize: pageSize,
      },this.goQuery );
  };

  /**
   * 选择窗口的回调
   */
  refresh4Choose=()=>{
    this.setState( { pageNo: 1  },this.goQuery );  //从第一页开始搜索
  }

  /**
   * 顶部的类型点击回调。目前用不上 20211015
   * @param {*} value 
   * @returns 
   */
  tabsChange = (value) => {
    let {type} = this.state;
    if(type==value){
      return ;
    }
    
    this.setState({ type:value  }, this.goQuery);
  }

  /**
   * 接口构造参数
   * @returns 
   */
  createQueryParam = () => {
    let {   pageNo, pageSize,filterParm} = this.state;
    let param = {   pageSize,pageNo};
 
    Object.keys(filterParm).forEach(key => {
      if (filterParm[key]) {
        param[key] = filterParm[key];
      }
    });
 
    return param;
  }

  /**
   * 去查询
   */
  goQuery=()=>{
 // setup 1:基本入参
 const config = {
  api: API.brandList,
  data:  this.createQueryParam(),
 };

// setup 2:请求，并初步处理
http(config).then(res => {
  if (res && res.success && res.data) {
      this.convertHttpData(res.data);
  }
});

}

/**
 * 数据转化 处理
 * @param {*} data 
 */
convertHttpData(data){
  var httpData=data.datas;
  var imgList=[]
  if(httpData&&httpData.length){
    httpData.forEach(item=>{
      if(item.logo){
        imgList.push(item.logo);
      }

      if(item.activityLogo){
        imgList.push(item.activityLogo);
      }

    });
  }

  this.setState({
    httpData,
    imgList,
    total: data.totalRecord
  });
 }

 
 

  onFilterParmChange=(filterParm)=>{
    this.setState({filterParm,pageNo:1},this.goQuery);// 每次搜索，从第1页取数据
  }


  createFilterConfig(){
    return {
       config:[
        this.createFilterRow()
        ],
        btnLast:true
    } ;
  }


  createFilterRow(){
    return {
      childList:[

        /*{
          type:FilterType.ENUM_SELECT,
          //label:'意向单类型',
          config:{
            field:'businessType',
            enumData:AuthStatus
          }
        },   */   
        {
          type:FilterType.INPUT,
          //label:'意向单编号',
          config:{
            field:'searchName',
            placeholder:'请输入企业名称,企业简称'
          }
        },
        

      ]
    }
  }

  /**
   * 展示窗口
   */
  showChooseShop=()=> {
    this.setState({ 
      isShowChooseShop:true
    });
  }


  closeImgPreview = () => {
    this.setState({  currentImg: undefined })
}

showImgPreview = (currentImg) => {
  this.setState({ currentImg  })
}

  /**
   * 隐藏展示窗口
   */
  hiddenChooseShop=()=>{
    this.setState({ 
      isShowChooseShop:false
    });
  }

    render() {
        let {type,columns,httpData,filterConfig,pageNo,total,isShowChooseShop,currentImg,imgList,isShowChangeBrandName,item4ChangeBrandName} = this.state;

        return (
          <div className="top_brand_panel">
           <div className="my_left_right_">
             
           <ul className="status-tab"  >
             <li   className={type === 1 ? 'active' : ''} onClick={() => { this.tabsChange(1) }}>小程序</li>
           </ul>

           <Button   style={{ marginLeft: "20px"  }}  type="primary"  onClick={  this.showChooseShop }  > 新增 </Button>

        </div>

        <FilterPanel data={filterConfig}   onFilterChange={this.onFilterParmChange}/>

        <Table
          columns={ columns}
          rowKey={(record, index) => record.code}
          selfProcessData={httpData}
          total={total}
          change={this.onChangePage}
          current={ pageNo}
        />


         {  isShowChangeBrandName&&<Modal title='修改品牌名称' visible={true}   onCancel={() => this.hiddenChangeBrandName()} onOk={()=>this.goChangeName() } width={450}>
            <Input defaultValue={item4ChangeBrandName.brandName||''}  maxLength={30}   allowClear style={{ width: 350 }} onChange={(e) => this.onBrandnameChange(e.target.value)} />
           </Modal>}
         {isShowChooseShop&& <ChooseShop  refresh={this.refresh4Choose} close={this.hiddenChooseShop}/> }

         <ImgPreview show={(currentImg&&imgList&&imgList.length)?true:false} currentImg={currentImg} imgList={imgList} onClose={this.closeImgPreview}/>


           <Upload action={API.uploadAdd.url}   beforeUpload={this.handleBeforeUpload}  accept=".png,.jpg"
                onChange={ this.changeLogo  }  showUploadList={false}   style={{display:'none'}} >
                     <span   ref={this.uploadRef}/>
            </Upload>

          </div>
        );
      }
}


export default TopBrand;
