/**
 * 图片可以预览 wushaonan511 Asdfg#2580
 * 能够用于form校验和值的获取
 */
import React, { useState, useImperativeHandle } from 'react';

import { Upload, message, Modal } from 'antd';

import { formRequest } from '@/http';
import { API } from '@/http/api';

const imageTypes = [ 'jpeg', 'jpg', 'png', 'bmp' ];

function CrmUpload({ accept, listType = 'picture-card', max = 1, value = {}, maxSize, onChange }, ref) {
	// 使用时有这个提示 Function components cannot be given refs. Attempts to access this ref will fail
	// 所以加这个补丁
	useImperativeHandle(ref, () => ({}));

	const [ previewConfig, setPreviewConfig ] = useState({ previewVisible: false });

	function handleChange({ file, fileList }) {
		if (file.status) {
			if (file.status === 'done') {
				const { originFileObj } = file;
				const { mediaExt, mediaKey, url } = originFileObj;
				file.url = url;
				file.mediaExt = mediaExt;
				file.mediaKey = mediaKey;
			}
			onChange(max === 1 ? (file.status === 'removed' ? {} : file) : fileList);
		}
	}

	function beforeUpload(file) {
		if (accept) {
			const fileType = file.name.split('.').pop();
			if (!new RegExp(`\.${fileType}(,|$)`, 'i').test(accept)) {
				message.error('不支持的文件格式');
				return false;
			}
		}

		if (maxSize) {
			let isOverSize = false;

			if (maxSize.endsWithIgnoreCase('M')) {
				let maxSizeM = parseInt(maxSize.substring(0, maxSize.length - 1));
				isOverSize = file.size > maxSizeM * 1024 * 1024;
			} else if (maxSize.endsWithIgnoreCase('kb')) {
				let maxSizeKb = parseInt(maxSize.substring(0, maxSize.length - 2));
				isOverSize = file.size > maxSizeKb * 1024;
			}

			if (isOverSize) {
				message.error('最大' + maxSize);
				return false;
			}
		}

		return true;
	}

	function customRequest(params) {
		const { file, onSuccess, onError } = params;
		formRequest({ file, action: API.uploadAdd.url })
			.then((res) => {
				if (res.code === '0') {
					let data = res.data;
					if (!data) {
						message.error('上传失败，请重试');
						onError();
						return;
					}
					const fileType = file.name.split('.').pop();
					file.mediaExt = fileType;
					file.mediaKey = data.key;
					// file.type = fileType;
					// file.url = data.publicUrl;
					file.url = data.publicDownloadUrl;
					onSuccess();
				} else {
					onError();
				}
			})
			.catch((err) => {
				onError(err);
			});
	}

	async function handlePreview(file) {
		if (file.name) {
			const fileType = file.name.split('.').pop().toLowerCase();

			if (!imageTypes.includes(fileType)) {
				return;
			}
		}

		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj);
		}

		setPreviewConfig({
			previewImage: file.url || file.preview,
			previewVisible: true,
			previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
		});
	}

	function closeImagePreview() {
		setPreviewConfig({ previewVisible: false });
	}

	const uploadButton =
		listType === 'text' ? (
			<span style={{ cursor: 'pointer', fontSize: '12px', textDecoration: 'underline', color: '#2E4AFF' }}>点击上传</span>
		) : (
			<div>
				<span style={{ fontSize: '24px' }} className="icon iconfont iconupload" />
				<div className="ant-upload-text" style={{ margin: 'auto' }}>
					拖拽至此 或 点击上传
				</div>
			</div>
		);

	const { previewVisible, previewTitle, previewImage } = previewConfig;

	let fileList = value;

	if (max === 1) {
		fileList = [];
		if (value.name || value.url) {
			// 有name可能在上传，有url可能是回显
			if (!value.uid) {
				value.uid = '1';
			}
			fileList = [ value ];
		}
	} else {
		if (!Array.isArray(value)) {
			fileList = [];
		} else {
			for (let i = 0; i < fileList.length; i++) {
				const file = fileList[i];
				if (!file.uid) {
					file.uid = `${i}`;
				}
			}
		}
	}

	return (
		<React.Fragment>
			<Upload
				listType={listType}
				accept={accept}
				multiple={max > 1}
				fileList={fileList}
				onChange={handleChange}
				beforeUpload={beforeUpload}
				customRequest={customRequest}
				onPreview={handlePreview}
			>
				{fileList.length >= max ? null : uploadButton}
			</Upload>
			<Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={closeImagePreview}>
				<img alt="图片预览" style={{ width: '100%', margin: '0 0 24px' }} src={previewImage} />
			</Modal>
		</React.Fragment>
	);
}

export default React.forwardRef(CrmUpload);

function getBase64(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});
}
