import React, { Component } from 'react';
import { Row, Col, Table, Modal, Icon, Form, Input, Select, DatePicker, message } from 'antd';
import moment from 'moment';
import http from '@/http'
import { API } from '@/http/api'
import { Judgetype } from '@/utils'

const FormItem = Form.Item;
const EditableContext = React.createContext();
const { Option }= Select;
const { TextArea } = Input;
const dateFormat = 'YYYY-MM-DD';
//处理options数据类型
const orderOptionsList = (options) => {
    return options.map(d => <Option key={d.value}>{d.name}</Option>)
}
let costtypeLists = [],bearerLists = [],count = 0;

class EditableCell extends Component {
    getInput(form) {
        const costtypeList = orderOptionsList(costtypeLists);
        const bearerList = orderOptionsList(bearerLists);
        const { record } = this.props;
        const { costmoney, Inexpenditure, handlepeople, handlephone } = record;

        switch (this.props.dataIndex) {
            case 'costtype':
                return <Select
                            style={{width:'90px'}}
                            suffixIcon={<Icon type="caret-down" />}>
                    {costtypeList}
                </Select>
            case 'bearer':
                return <Select
                            style={{width:'80px'}}
                            suffixIcon={<Icon type="caret-down" />}
                            onChange={this.handleSelectChange.bind(this,form,'bearer')}>
                    {bearerList}
                </Select>
            case 'costInfo':
                return <Row style={{width:'240px'}}>
                    <Col span={7}>
                        <FormItem>
                            {form.getFieldDecorator('Inexpenditure', {
                                initialValue: Inexpenditure,
                                rules: [{ required: true, message: '请选收支' }],
                            })(<Input disabled />)}
                        </FormItem>
                    </Col>
                    <Col span={11} offset={1}>
                        <FormItem>
                            {form.getFieldDecorator('costmoney', {
                                rules: [{
                                    required: true,
                                    validator: (rule, value, callback)=>{
                                        if (value>0 && value<100000000 && /^[0-9]+([.]{1}[0-9]{1,2})?$/.test(value)){
                                            callback()
                                        } else {
                                            callback(false)
                                        }
                                    },
                                    message: '请输入正确的金额',
                                }],
                                initialValue: costmoney
                            })(<Input style={{width:'92px'}} placeholder='请输入金额' />)}
                        </FormItem>
                    </Col>
                </Row>
            case 'handledate':
                return  <DatePicker style={{width:'130px'}} />
            case 'PayerInfo':
                return <Row>
                    <Col span={7}>
                        <FormItem>
                            {form.getFieldDecorator('handlepeople', {
                                initialValue: handlepeople,
                                rules: [{ required: true, message: '请输入姓名' }],
                            })(<Input maxLength={10} placeholder='姓名' />)}
                        </FormItem>
                    </Col>
                    <Col span={12} offset={1}>
                        <FormItem>
                            {form.getFieldDecorator('handlephone', {
                                initialValue: handlephone,
                                rules: [{
                                    required: true,
                                    message: '请输入正确的手机号码' ,
                                    pattern: /^1\d{10}$/
                                }],
                            })(<Input maxLength={11} style={{width:'92px'}} placeholder='联系方式' />)}
                        </FormItem>
                    </Col>
                </Row>
            case 'usehandledate':
                return <TextArea
                    autosize
                    style={{resize:'none',width:'138px'}}
                    maxLength={50}
                    placeholder='请输入'/>
            default:
                break
        }
    };

    handleSelectChange = (form,type,value) => {
        switch (type) {
            case 'bearer':
                form.setFieldsValue({
                    Inexpenditure: Number(value) === 1 ? '收' : '支'
                });
                break;
            default:
                break
        }
    };

    render() {
        const {
            editing,
            dataIndex,
            title,
            record,
            ...restProps
        } = this.props;
        return (
            <EditableContext.Consumer>
                {(form) => {
                    const { getFieldDecorator } = form;

                    return (
                        <td {...restProps}>
                            {editing ? (
                                <FormItem style={{ margin: 0 ,height:40}}>
                                    {getFieldDecorator(dataIndex, {
                                        initialValue: dataIndex === 'handledate'? record['momentdate'] : record[dataIndex],
                                        rules: ['costtype','bearer','handledate'].includes(dataIndex) ? [{required: true, message: `请填选${title}`}] : null
                                    })(this.getInput(form))}
                                </FormItem>
                            ) : restProps.children}
                        </td>
                    );
                }}
            </EditableContext.Consumer>
        );
    }
}

class EditableTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabledata: [],
            editingKey: ''
        };
        this.columns = [
            {
                title: '序号',
                dataIndex: 'key'
            },{
                title: <div className="require">费用类型<i> * </i></div>,
                editable: true,
                dataIndex: 'costtype',
                nametitle: '费用类型'
            },{
                title: <div className="require">承担方<i> * </i></div>,
                editable: true,
                dataIndex: 'bearer',
                nametitle: '承担方'
            },{
                title: <div className="require">费用收支(元)<i> * </i></div>,
                editable: true,
                dataIndex: 'costInfo'
            },{
                title: <div className="require">应收/付日期<i> * </i></div>,
                editable: true,
                dataIndex: 'handledate',
                nametitle: '日期'
            },{
                title: <div className="require">付款人信息<i> * </i></div>,
                editable: true,
                dataIndex: 'PayerInfo'
            },{
                title: '说明',
                editable: true,
                dataIndex: 'usehandledate'
            },{
                title: '操作',
                dataIndex: 'operation',
                render: (text, record) => {
                    const { editingKey } = this.state;
                    const editable = this.isEditing(record);
                    return (
                        <div>
                            {[5,6].includes(Number(this.props.status)) ? null :
                                (
                                    <span>
                                        {editable ? (
                                            <span>
                                  <EditableContext.Consumer>
                                    {form => (
                                        <a
                                            style={{color:'#FBBC06',marginRight: '12px'}}
                                            href="javascript:;"
                                            onClick={() => this.save(form, record)}
                                        >
                                            保存
                                        </a>
                                    )}
                                  </EditableContext.Consumer>
                                </span>
                                                 ) : (
                                                     <a style={{color:'#FBBC06',marginRight: '12px'}} disabled={editingKey !== ''} onClick={() => this.edit(record.key)}>编辑</a>
                                                 )}
                                        <a style={{color:'#FBBC06'}} disabled={editingKey !== ''} onClick={this.detele.bind(this,record.encode)}>删除</a>
                                    </span>
                                )
                            }
                        </div>
                    );
                },
            },
        ];
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.encode && count === 0 && this.props.from ==='list') {
            this.getmaintainList(nextProps.encode);
            costtypeLists = nextProps.costtypeLists;
            bearerLists = nextProps.bearerLists;
            count++
        }
        return true
    }
    componentWillUnmount() {
        count = 0
    }
    componentWillMount() {
        if (this.props.encode && count === 0 && this.props.from ==='detail') {
            this.getmaintainList(this.props.encode);
            costtypeLists = this.props.costtypeLists;
            bearerLists = this.props.bearerLists;
            count++
        }
    }

    isEditing = record => record.key === this.state.editingKey;
    add() {
        const tablelist = this.state.tabledata;
        const tablelistLength = tablelist.length;
        const addtable = {
            key: null
        }
        addtable.key = tablelistLength + 1 > 9 ? String(tablelistLength + 1) : '0' + (tablelistLength + 1);
        tablelist.push(addtable)

        this.setState({
            tabledata: tablelist,
            editingKey: addtable.key
        })
    }
    save(form, record) {
        form.validateFields((error, row) => {
            if (error) {
                return;
            }
            const rowlist = {};
            rowlist.amount = parseFloat(row.costmoney);
            rowlist.dueDate = Judgetype(row.handledate) === 'string' ? row.handledate : row.handledate.format(dateFormat);
            rowlist.feeType = isNaN(Number(row.costtype)) ? Number(costtypeLists.filter(itemkey => String(itemkey.name) === String(row.costtype))[0].value) : Number(row.costtype);
            rowlist.orderEncode = this.props.encode;
            rowlist.payType = isNaN(Number(row.bearer)) ? Number(bearerLists.filter(itemkey => String(itemkey.name) === String(row.bearer))[0].value) : Number(row.bearer);;
            rowlist.payerMobile = row.handlephone;
            rowlist.payerName = row.handlepeople;
            rowlist.remark = row.usehandledate;

            const newData = [...this.state.tabledata];
            const index = newData.findIndex(item => record.key === item.key);
            if (newData[index].Inexpenditure) {
                //编辑
                rowlist.encode = record.encode;
                this.postEditorder(rowlist)
            } else {
                //新增
                this.postAddorder(rowlist)
            }
        });
    }
    edit(key) {
        this.setState({ editingKey: key });
    }
    detele(code) {
        Modal.confirm({
            className: 'costorder-showdeteleModal',
            content: '确认删除?',
            icon: <Icon type="question-circle" style={{display: 'none'}}/>,
            maskClosable: true,
            onOk: this.deteleOrder.bind(this, code)
        })
    }
    deteleOrder(code) {
        if (code) {
            const config = {
                api: API.maintainDeleteBill,
                data: {
                    encodeList: [code],
                    orderEncode: this.props.encode
                }
            };
            http(config).then(result => result.success && (message.success(`删除成功`) && this.getmaintainList(this.props.encode)))
        }
    }

    //请求费用清单list
    getmaintainList(encode) {
        const config = {
            api: API.maintainListBill,
            path: {
                encode
            },
            showLoading: true
        }

        http(config).then(result => {
            const { data } = result;
            const config = data.map((item,index) => {
                return {
                    key: index+1>9 ? `${index+1}` : `0${index+1}`,
                    costtype: costtypeLists.filter(itemkey => Number(itemkey.value) === item.feeType)[0].name,
                    costmoney: item.amount,
                    costInfo: <div><span style={{marginRight:'12px'}}>{item.payType === 1 ? '收' : '支'}</span><span>{item.amount}</span></div>,
                    bearer: bearerLists.filter(itemkey => Number(itemkey.value) === item.payType)[0].name,
                    Inexpenditure: item.payType === 1 ? '收' : '支',
                    handledate: moment(item.dueDate).format(dateFormat),
                    momentdate: moment(moment(item.dueDate).format(dateFormat),dateFormat),
                    PayerInfo: <div><span style={{marginRight:'12px'}}>{item.payerName}</span><span>{item.payerMobile}</span></div>,
                    handlepeople: item.payerName,
                    handlephone: item.payerMobile,
                    usehandledate: item.remark,
                    encode: item.encode,
                    operation: null
                }
            })

            this.setState({
                tabledata: config,
                editingKey: ''
            })
        })
    }
    //请求费用清单新增
    postAddorder(data) {
        const config = {
            api: API.maintainAddBill,
            data: {
                ...data
            },
            showLoading: true
        };
        http(config).then(result => result.success && (message.success(`新增成功`) && this.getmaintainList(this.props.encode)))
    }
    //请求费用清单编辑
    postEditorder(data) {
        const config = {
            api: API.maintainEditBill,
            data: {
                ...data
            },
            showLoading: true
        };
        http(config).then(result => result.success && (message.success(`编辑成功`) && this.getmaintainList(this.props.encode)))
    }

    render() {
        const components = {
            body: {
                cell: EditableCell,
            },
        };

        const columns = this.columns.map((col) => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    dataIndex: col.dataIndex,
                    title: col.nametitle,
                    editing: this.isEditing(record),
                }),
            };
        });

        let countmoney = 0;
        this.state.tabledata.forEach(item => {
            if (item.Inexpenditure === '收') {
                countmoney+= isNaN(item.costmoney) ? 0 : item.costmoney
            } else if (item.Inexpenditure === '支') {
                countmoney-= isNaN(item.costmoney) ? 0 : item.costmoney
            }
        })

        const totalfooter = <div className='totalfooter'>
                                <span>合计: </span>
                                <span>{countmoney > 0 ? '+': ''}</span>
                                <span>{countmoney.toFixed(2)}</span>
                                <span>元</span>
                            </div>;

        return (
            <EditableContext.Provider value={this.props.form}>
                <Row className='maintain-addorder-addorderbutton'>
                    <Col >
                        {[5,6].includes(Number(this.props.status)) ? null : (
                            <a onClick={this.add.bind(this)} disabled={this.state.editingKey !== ''}><Icon type="plus-circle" theme="filled" /><span>新增费用</span></a>
                        )}
                    </Col>
                </Row>
                <Table
                    components={components}
                    dataSource={this.state.tabledata}
                    columns={columns}
                    rowClassName="editable-row"
                    pagination={false}
                    footer={() => totalfooter}
                />
            </EditableContext.Provider>
        );
    }
}

const CostOrder = Form.create()(EditableTable);

export default CostOrder
