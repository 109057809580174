import React, { Component } from "react";
import { Modal, message, Form, Radio, Input, Icon, Button } from "antd";
import Upload from "CMPT/common/Upload";
import { API } from "@/http/api";
import http from "@/http";
import "./GoodsSetting.less";

class GoodsSetting extends Component {
  state = {
    auditType: 0,
  };

  componentDidMount() {
    this.getGoodsConfig()
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        console.log(values, 'ssss');
        this.updateGoodsConfig(values)
      }
    });
  };



  getGoodsConfig = () => {
    const config = {
        api: API.commonGetGoodsConfig,
        showLoading: true
    };
    http(config).then(res => {
        if (res.success) {
          this.setState(res.data)
        }
      
    })
  }


  updateGoodsConfig = (values) => {
    const config = {
        api: API.commonUpdateGoodsConfig,
        data: values,
        showLoading: true
    };
    http(config).then(res => {
        if(res.success){
            message.success('设置成功')
        }
      
    })
  }

  handleCancel = () => {
    this.props.history.go(-1)
  }


  render() {
    let my = this;
    const { getFieldDecorator } = this.props.form;
    const { auditType } = this.state;
    return (
      <Form className="yqy-form" labelCol={{ span: 4 }}
      wrapperCol={{ span: 16 }} onSubmit={this.handleSubmit}>
          <Form.Item label="商品审核设置">
              {getFieldDecorator("auditType", {
                  initialValue: auditType,
                  rules: [
                      { required: true },
                    ]
              })( 
                    <Radio.Group className='radio_box'>
                        <Radio value={0} className="radio_item">自动通过上架</Radio>
                        <Radio value={1} className="radio_item">审核通过后上架</Radio>
                    </Radio.Group>
              )}
              
          </Form.Item>

          <div className="desc">说明：设置为自动通过商品则自动上线，设置为审核通过后上架，则供应商发布商品需先经运营审核，审核通过后才能上架</div>
          <div className="desc">若在店铺管理设置某个店铺商品免审，则走免审策略</div>

          <div className="btn-group">
            <Button type="primary" htmlType="submit">
              提交
            </Button>
            <Button onClick={this.handleCancel} size="large">
              取消
            </Button>
          </div>
        </Form>
    );
  }
}

export default Form.create({ name: "GoodsSetting" })(GoodsSetting);
