import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { Form, Input } from 'antd';
import Select from 'CMPT/common/Select'

const { TextArea } = Input;
const { Item } = Form;

class SendOrder extends Component {
    render() {
        const { chooseProject, urgent, form } = this.props;
        const { getFieldDecorator } = form;

        return (
            <div className='maintain-addorder maintain-form'>
                <Form>
                    <Item label="受理人">
                        {getFieldDecorator('urgent', {
                            rules: [{ required: true, message: '请选择受理人' }]
                        })(
                            <Select
                                data={urgent}
                                placeholder="请选择"
                                selectMethod={chooseProject.bind(this,1)}>
                            </Select>
                        )}
                    </Item>
                    <Item label="备注">
                        {getFieldDecorator('remark')(
                            <TextArea
                                maxLength={200}
                                style={{resize:'none'}}
                                onChange={chooseProject.bind(this,2)}>
                            </TextArea>
                        )}
                    </Item>
                </Form>
            </div>
        )
    }
}

export default withRouter(SendOrder)
