import React, { Component } from 'react';
import { API } from '@/http/api';
import http from '@/http';
import { Breadcrumb, message, Radio, Button } from 'antd';
import './ProjectDecision.less'
class ProjectDecision extends Component {
    state = {
        setting: {
            displayStyle: '1',
            terminalArea: '1'
        },
        defaultCode: 'qeZDWfgpvSs' // code写死，不能更改
    }
    componentDidMount() {
        this.getProjectSetting()
    }
    getProjectSetting = () => {
        // 获取项目配置
        const defaultConfig = {
            api: API.decisionByProjectEncode,
            path: {
                projectEncode: this.state.defaultCode
            },
            data: {
                bizType: '1'
            }
        }
        http(defaultConfig).then(res => {
            let setting = {
                displayStyle: '1',
                terminalArea: '1'
            }
            if (res.data && res.data.length) {
                res.data.forEach(per => {
                    const key = this.mapKey(per.key, true)
                    setting[key] = per.value
                })
            }
            this.setState({
                setting
            })
        })
    }
    saveSetting = () => {
        console.log(this.state.setting)
        const config = {
            api: API.decisionEdit,
            data: {
                projectEncode: this.state.defaultCode,
                bizType: '1',
                decisionList: this.transData(this.state.setting)
            }
        }
        http(config).then(res => {
            console.log(res.data)
            if (res.success) {
                message.success('保存成功')
            }
        })
    }
    transData = (obj) => {
        let target = []
        Object.keys(obj).forEach(key => {
            let value = obj[key]
            target.push({key: this.mapKey(key), value: value})
        })
        return target
    }
    selectRadio = (attr, value) => {
        console.log(attr, value)
        let setting = this.state.setting
        setting[attr] = value.target.value
        this.setState({setting})
    }
    mapKey = (key, reverse) => {
        if (reverse) {
            return {
                project_namespace_display: 'displayStyle',
                project_cal_rent_area: 'terminalArea'
            }[key]
        }
        return {
            displayStyle: 'project_namespace_display',
            terminalArea: 'project_cal_rent_area'
        }[key]
    }
    render() {
        const { setting } = this.state
        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
        }
        return (
            <div className="decision-page">
                <div className="decision-bread">
                    <Breadcrumb>
                        <Breadcrumb.Item>设置中心</Breadcrumb.Item>
                        <Breadcrumb.Item>决策</Breadcrumb.Item>
                        <Breadcrumb.Item>项目决策</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className="project-decision-block">
                    <div className="decision-row" style={{marginBottom: '34px'}}>
                        <p>项目空间展现形式<span>*</span></p>
                        <Radio.Group value={setting.displayStyle} onChange={this.selectRadio.bind(this, 'displayStyle')}>
                            <Radio style={radioStyle} value='1'>
                                实时信息GIS展示（推荐）
                            </Radio>
                            <Radio style={radioStyle} value='2'>
                                实时信息平铺展示
                            </Radio>
                        </Radio.Group>
                    </div>
                    <div className="decision-row">
                        <p>项目计租面积<span>*</span>注：建筑面积为必填项，若选择其他面积则对应面积改为必填，需重新补充对应面积。</p>
                        <Radio.Group value={setting.terminalArea} onChange={this.selectRadio.bind(this, 'terminalArea')}>
                            <Radio style={radioStyle} value='1'>
                                建筑面积（推荐）
                            </Radio>
                            <Radio style={radioStyle} value='2'>
                                使用面积
                            </Radio>
                            <Radio style={radioStyle} value='3'>
                                套内面积
                            </Radio>
                        </Radio.Group>
                    </div>
                </div>
                <div className="project-decision-bottom">
                    <Button type="primary" onClick={this.saveSetting}>保存</Button>
                </div>
            </div>
        );
    }
}

export default ProjectDecision;