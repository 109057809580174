import { AJAX_TYPE } from "../config";

// 公告

const prefix = "brand";

export default {
  [`${prefix}List`]: {
    url: "/api/supply/web/crm/shopFeatured/list",
    type: AJAX_TYPE.POST
  },
  [`${prefix}ShopList`]: {
    url: "/api/supply/web/crm/shopFeatured/shopList",
    type: AJAX_TYPE.POST
  },

  [`${prefix}ShopBatch`]: {
    url: "/api/supply/web/crm/shopFeatured/insertBatch",
    type: AJAX_TYPE.POST
  },

    // 修改
    [`${prefix}Update`]: {
      url: "/api/supply/web/crm/shopFeatured/update",
      type: AJAX_TYPE.POST
    },
  // 置顶与否
  [`${prefix}Top`]: {
    url: "/api/supply/web/crm/shopFeatured/updateTopStatus",
    type: AJAX_TYPE.POST
  },
  [`${prefix}Delete`]: {
    url: "/api/supply/web/crm/shopFeatured/delete",
    type: AJAX_TYPE.POST
  },



  

};
