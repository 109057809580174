import React, { useEffect, useState } from "react";
import { Breadcrumb, Select, Tabs, Badge } from "antd";
import SearchForm from "./SearchForm";
import Table from "CMPT/common/Table";
import { Link } from "react-router-dom";

import http from "@/http";
import { API } from "@/http/api";

const { TabPane } = Tabs;

// 筛选项默认值
const defaultParams = {
  pageNo: 1, // 需要查询的页数
  pageSize: 10, // 每页条数
  // auditEndTime: "", //审核结束时间
  // auditStartTime: "", //审核开始时间
  // auditStatus: 0, // 审核状态 0-审核中 1-审核通过 2-审核驳回/审核不通过
  // infoName: "", // 信息名称
  auditStatusList: [0]
};

export default function List(props) {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  // const [pageSize, setPageSize] = useState(10)
  const [currentPageCount, setCurrentPageCount] = useState(0);
  const [selectParams, setSelectParams] = useState(defaultParams);

  const columns = [
    {
      title: "标题",
      // width: "120px",
      key: "name",
      dataIndex: "name",
      render: (text, record) => {
        return <span>{text}</span>;
      },
    },
    {
      title: "类型",
      key: "type",
      dataIndex: "type",
      render: (text, record) => {
        const map = { 1: "公开招标", 2: "邀请招标" };
        return <span>{map[text] || "-"}</span>;
      },
    },

    {
      title: "招标状态",
      key: "status",
      dataIndex: "status",
      render: (text, record) => {
        // 0:待审核 1:审核通过 2:驳回 3:违规下架
        const statusMap = {1: '待发布', 2:"进行中", 3:"报名截止", 4:"已成交", 5:"已作废",  6:"投标截止"};

        return <span>{statusMap[text] || "-"}</span>;
      },
    },
    {
      title: "审核状态",
      key: "auditStatus",
      dataIndex: "auditStatus",
      render: (text, record) => {
        // 0:待审核 1:审核通过 2:驳回 3:违规下架
        const map = ["待审核", "审核通过", "驳回", "违规下架"];
        return <span>{map[text] || "-"}</span>;
      },
    },
    {
      title: "发布人",
      key: "createUserName",
      dataIndex: "createUserName",
      render: (text, record) => {
            var name = text;
            var phone = record.createUserPhone;
            var value = (name||'')+(name?'/':'')+(phone||'')

        return <span>{value ||  '-'}</span>;
      },
    },
    {
      title: "所属企业",
      key: "createUserCorp",
      dataIndex: "createUserCorp",
      render: (text, record) => {
        return <span>{text || '-'}</span>;
      },
    },
    {
      title: "发布时间",
      key: "createTime",
      dataIndex: "createTime",
    },
    {
      title: "操作",
      key: "operate",
      render: (text, record, index) => {
        return (
          <span className="table-operate-btn">
            <Link
              to={"/app/crm/sourcing/invite_tenders_detail/" + record.code}
              className="operate"
            >
              查看
            </Link>
          </span>
        );
      },
    },
  ];

  useEffect(() => {
    init();
  }, [selectParams]);

  function init() {
    const httpParams = {
      ...selectParams,
    };
    for (let [key, value] of Object.entries(httpParams)) {
      if (value === -1 || value === "") {
        //    console.log('[key, value]',[key, value])
        httpParams[key] = undefined;
      }
    }

    const config = {
      api: API.InviteTendersList,
      data: { ...httpParams },
      showLoading: true,
    };

    http(config).then((res) => {
      if (res.code == "0") {
        const data = res.data;

        setData(data.datas || []);
        setTotal(data.totalRecord);
        setPageNo(data.pageNo);
        setCurrentPageCount(data.datas ? data.datas.length : 0);
      }
    });
  }

  // 搜索
  function onValuesChange(allValues) {
    setSelectParams({ ...selectParams, ...allValues });
  }

  // 状态选择
  function onTabsChange(status) {
    const auditStatusList = status? [status]: []
    setSelectParams({ ...selectParams, auditStatusList });
  }

  //分页
  function onChangePage(pageNo, pageSize, sort) {
    setSelectParams({
      ...selectParams,
      pageNo: pageNo,
      pageSize: pageSize,
    });

    setPageNo(pageNo);
  }

  // 重置
  function reset(allValues) {
    setSelectParams({ ...selectParams, ...allValues });
  }
// 0:待审核 1:审核通过 2:驳回 3:违规下架
  return (
    <div>
      <Tabs defaultActiveKey="0" onChange={onTabsChange}>
        <TabPane tab="全部" key="" className="tab"></TabPane>
        <TabPane
          tab= {"待审批"+(props.countNumber?"("+props.countNumber+")":'')}
          key={0}
          className="tab"
        ></TabPane>
        <TabPane tab="已通过" key={1} className="tab"></TabPane>
        <TabPane tab="已驳回" key={2} className="tab"></TabPane>
        <TabPane tab="违规下架" key={3} className="tab"></TabPane>
      </Tabs>

      <div className="enterprise-auth-list">
        <div className="searchBox">
          <SearchForm
            onValuesChange={onValuesChange}
            types={false}
            reset={reset}
            onSearch={() => {
              init();
            }}
          />
        </div>
        <Table
          columns={columns}
          rowKey={(record, index) => record.code}
          selfProcessData={data}
          total={+total}
          change={onChangePage}
          current={pageNo}
        />
      </div>
    </div>
  );
}
