import React, { Component } from 'react';
import { Form, Input, Rate } from 'antd';
import Select from 'CMPT/common/Select'

const { TextArea } = Input;
const { Item } = Form;

export default class AcceptanceOrder extends Component {
    render() {
        const { Accresult, result } = this.props;
        const { getFieldDecorator } = this.props.form;
        let list = null;
        if (Accresult === 1) {
            list = <div>
                <Item label="备注">
                    {getFieldDecorator('remark')(
                        <TextArea
                            maxLength={200}
                            style={{resize:'none'}}
                            onChange={this.props.chooseProject.bind(this,3)}>
                        </TextArea>
                    )}
                </Item>
                <Item label="评价">
                    {getFieldDecorator('rate',{
                        rules: [{required: true, message: '请选择评价',}],
                    })(
                        <Rate onChange={this.props.chooseProject.bind(this,2)}/>
                    )}
                </Item>
            </div>
        } else if (Accresult === 2) {
            list = <div>
                <Item label="不通过原因">
                    {getFieldDecorator('notresult',{
                        rules: [{required: true, message: '请输入不通过原因',}],
                    })(
                        <TextArea
                            maxLength={200}
                            style={{resize:'none'}}
                            onChange={this.props.chooseProject.bind(this,4)}>
                        </TextArea>
                    )}
                </Item>
            </div>
        }

        return (
            <div className='maintain-addorder maintain-form'>
                <Form>
                    <Item label="验收结果">
                        {getFieldDecorator('result',{
                            rules: [{required: true, message: '请选择验收结果',}],
                        })(
                            <Select
                                data={result}
                                placeholder="请选择"
                                selectMethod={this.props.chooseProject.bind(this,1)}>
                            </Select>
                        )}
                    </Item>
                    {list}
                </Form>
            </div>
        )
    }
}
