import React, { Component } from 'react'
import './RentStatePopView.less'
import { RENT_STATUS } from './CapitalConfig'
import http from '@/http'
import { API } from '@/http/api';

/**
 * 变更出租状态
 */
export default class RentStatePopView extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    doChangeRentState = () => {
        const config = {
            api: API.zoneUpdateRentStatus,
            data: {
                zoneId: this.props.zoneDetail.encode,
                rentStatus: this.props.zoneDetail.rentStatus == RENT_STATUS.NotRent.value ? RENT_STATUS.Rented.value : RENT_STATUS.NotRent.value
            }
        }
        http(config).then(result => {
            this.props.handlerBackRentState();
        })
    }

    render() {
        return (
            <div className='rent-v'>
                确认变更为
                <div className='rent-focus'>【{this.props.zoneDetail.rentStatus == RENT_STATUS.NotRent.value ? '已出租' : '未出租'}】</div>
                状态？
            </div>
        );
    }
}