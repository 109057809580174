import React, { Component } from 'react';
import moment from 'moment';
import { API } from '@/http/api';
import http from '@/http';
import Select from '@/components/common/Select'
import { DatePicker, div, Icon, Input, Cascader, Row } from 'antd';
const Search = Input.Search;
const { RangePicker } = DatePicker;
const dateformat = 'YYYY-MM-DD';
let creatTimeValue = [];

class CustomerSearch extends Component {

    componentDidMount() {
        this.loadProvince();
    }

    state = {
        fuzzyKey: undefined,
        acountStatus: undefined,
        acountType: undefined,
        cityEncode: undefined,
        rangeDate: [],
        contractType: undefined,
        cityArray:[],
        checkCity:[]
    }

    handleSubmit = () => {
        console.log(this.state)
        this.props.changeParam(this.state);
    }
    setKeywords = (e) => {
        this.setState({
            fuzzyKey: e.target.value
        })
    }
    changeKeywords = (value) => {
        this.setState({
            fuzzyKey: value
        }, () => {
            this.handleSubmit()
        })
    }

    handleChangeStatus = (value) => {
        this.setState({
            acountStatus: value
        }, () => {
            this.handleSubmit()
        })
    }


    handleChangeType = (value) => {
        this.setState({
            acountType: value
        }, () => {
            this.handleSubmit()
        })
    }

    handleChangeCity = value => {
        // console.log(value);
        if (value.length > 1) {
            this.setState({
                checkCity:value,
                cityEncode: value[1]
            }, () => {
                this.handleSubmit()
            })
        }
    }

     // 省份列表
    loadProvince = () => {
        http({
            api: API.areaProvinces,
            showLoading: false
        }).then(res => {
            if (res.success) {
                this.setState({
                    cityArray: res.data.map(item => {
                        item.isLeaf = false;
                        return item;
                    })
                })
            }
        });
    }
    
    loadRegion = (selectedOptions) => {
        console.log(selectedOptions)
        const targetOption = selectedOptions[selectedOptions.length - 1];
        http({
            api: API.areaSubAreas,
            data: {
                encode: targetOption.code
            },
            showLoading: false
        }).then(res => {
            targetOption.children = res.data;
            if (selectedOptions.length < 2) {
                res.data.forEach(item => {
                    item.isLeaf = false;
                    return item;
                });
            }
            this.setState({ cityArray: [...this.state.cityArray] });
        });
    }
    //修改省市
    onAddressRegionChange = (value, selectedOptions) => {
        console.log(value)
        console.log(selectedOptions)
        const { dataSource } = this.state;
        const newDataSource = {
            ...dataSource,
            cityEncode: selectedOptions[1],
        };
        this.setState({
            dataSource: newDataSource
        });
        if (selectedOptions.length > 1) {
            // () => {
                this.handleSubmit()
            // }
        }
    }

    //选择处理时间类型
    choosedateType(value) {
        this.setState({
            contractType: value
        }, () => {
            if (this.state.contractType && this.state.rangeDate[0] && this.state.rangeDate[1]) {
                this.handleSubmit();
            }
        })
    }

    handleChangeTime = (value, date) => {
        creatTimeValue = [value[0], value[1]]
        this.setState({
            rangeDate: [date[0] || undefined, date[1] || undefined]
        }, () => {
            this.handleSubmit()
        })
    }

    reset = () => {
        creatTimeValue = []
        this.setState({
            fuzzyKey: undefined,
            acountStatus: undefined,
            acountType: undefined,
            cityEncode: undefined,
            contractType: undefined,
            rangeDate: [],
            checkCity:[]
        }, () => {
            this.handleSubmit();
        })
    }


    render() {
        const { fuzzyKey, acountStatus, acountType, cityEncode } = this.state
        return (

            <div>
                <Row className="customer-search-group">
                    <div>
                        <Search
                            placeholder="请输入公司码/企业名称/联系人/联系方式"
                            value={fuzzyKey}
                            onChange={this.setKeywords}
                            onSearch={this.changeKeywords}
                            allowClear={true}
                            style={{ width: 300, marginRight: '12px' }} />
                    </div>
                    <div>
                        <Select 
                        style={{ width: '104px', marginRight: '12px' }} 
                        data={this.props.statusConfigArray} 
                        value={acountStatus} 
                        allowClear 
                        placeholder="状态" 
                        onChange={this.handleChangeStatus} />
                    </div>
                    <div>
                        <Cascader
                            style={{ width: '204px', marginRight: '12px' }} 
                            fieldNames={{ label: 'name', value: 'code', children: 'subAreas' }}
                            options={this.props.cityTypeConfigArray} 
                            suffixIcon={<Icon type="caret-down" />} 
                            allowClear={false}
                            onChange={this.handleChangeCity} 
                            placeholder="城市" value={this.state.checkCity}/>
                    </div>
                    {/* <div>
                        <Select 
                        style={{ width: '104px', marginRight: '12px' }} 
                        data={this.props.cityTypeConfigArray} 
                        value={cityEncode} 
                        allowClear={false}
                        placeholder="城市" 
                        onChange={this.handleChangeCity} />
                    </div> */}
                    <div>
                        <Select 
                        style={{ width: '124px', marginRight: '12px' }} 
                        data={this.props.customerTypeConfigArray} 
                        value={acountType} 
                        allowClear 
                        placeholder="账号类型" 
                        onChange={this.handleChangeType} />
                    </div>
                    <div style={{ marginRight: '0px' }}>
                        <Select
                            style={{ width: '124px', marginRight: '0' }}
                            data={this.props.dateTypeArray}
                            value={this.state.contractType}
                            placeholder="时间类型"
                            suffixIcon={<Icon type="caret-down" />}
                            onSelect={this.choosedateType.bind(this)}>
                        </Select>
                    </div>
                    <div className='cx-date-style'>
                        <RangePicker style={{ width: '300px', marginRight: '12px' }} value={creatTimeValue} onChange={this.handleChangeTime} />
                    </div>
                    <div>
                        <a href="javascript:void(0)" style={{ cursor: 'pointer', fontSize: '12px', textDecoration: 'underline', textUnderlinePosition: 'under', border: 'none', color: '#333', lineHeight: '32px' }} type="link" onClick={this.reset}>重置</a>
                    </div>
                </Row>
            </div>
        );
    }
}

// const CustomerSearchdiv = div.create({ name: 'intention_seach' })(CustomerSearch);

export default CustomerSearch;