import React, { Component } from 'react';
import { Breadcrumb, Modal, Button, Input, Tree, List, message, Form, Select } from 'antd';
import './RuleList.less';
import CategoryTree from './category/CategoryTree'
import { API } from '@/http/api';
import http, { ajax } from '@/http';
import storage from '@/utils/storage';
// import message from '../../../http/apilist/message';
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import Card from "./Card";


import userUtils from '@/utils/user';
import { AUTH_KEYS } from "@/utils/auth";
const { checkAuthByKey } = userUtils;
const ruleManageAuth = checkAuthByKey(AUTH_KEYS.RULE_MANAGE_RULE_MANAGE); // 管理规则

const { TreeNode } = Tree;
const BreadcrumbItem = Breadcrumb.Item;
const { Option } = Select;
const pageClass = 'rule_list';

class RuleList extends React.Component {
    state = {
        cards: [],
        categoryCode: null,
        parentTitle: '',
        title: '',
        htmlDetail: null
	}
   
	componentDidMount () {
        const match= this.props.match;
        if (match && match.params && match.params.code) {
            this.setState({categoryCode: match.params.code}, ()=>{
                this.getRuleList()
                this.getCategoryInfo()
            })
        }
    }
    // 获取列表
    getRuleList = (status) => {
        let ajaxData = {
            categoryCode: this.state.categoryCode,
            status: status || null
        }
        http({
            api: API.ruleCategoryRuleList,
            data: ajaxData,
			showLoading: true
		}).then(res => {
			if (res.success && res.data) {
				this.setState({
                    cards: [...res.data]
                })
			} else {
                this.setState({
                    cards: []
                })
            }
		});
    }

    // 获取规则详情
	getCategoryInfo = () => {
		http({
			api: API.ruleCategoryGetInfo,
			data: {
				code: this.state.categoryCode
            },
			showLoading: true
		}).then(res => {
			if (res.success && res.data) {
				this.setState({
                    title: res.data.title,
                    parentTitle: res.data.parentCategoryName
                })
			}
		});
	}
    
    renderCard = (card, index) => {
        return (
          <Card
            key={card.code}
            id={card.code}
            index={index}
            title={card.title}
            status={card.status}
            createTime={card.createTime}
            updateTime={card.updateTime}
            edit={this.edit}
            moveCard={this.moveCard}
            goChangeStatus={this.goChangeStatus}
            watchRule={this.watchRule}          />
        );
    }
    // 移动排序
    moveCard = (dragIndex, hoverIndex) => {
        let { cards } = this.state;
        cards.splice(
          dragIndex,
          1,
          ...cards.splice(hoverIndex, 1, cards[dragIndex])
        );
        this.setState({
          cards
        });
    
        this.sort();
    }
    // 排序
    sort = () => {
        const { cards } = this.state;
        let sort = cards.map(item => {
          return item.code;
        });
        const config = {
          api: API.ruleCategoryRuleSort,
          data: {
            codeList: sort
          },
          successTip: '排序成功' 
        };
        http(config).then(res => {
        //  console.log(res);
        });
    }
    // 发布状态修改
    selectChange = (value) => {
        this.getRuleList(value)
    }
    // 新建 
    creatRule = () => {
        window.location.href = `/#/app/crm/rulecenter/addRule/${this.state.categoryCode}`
    }
    // 编辑
    edit = (code) => {
        window.location.href = `/#/app/crm/rulecenter/editRule/${this.state.categoryCode}/${code}`
    }
    // 删除/发布/下架
    goChangeStatus = (type, code) => {
        let typeMsg = null;
        if (type == 0) {
            typeMsg = {
                title: '删除',
                api: API.ruleCategoryRuleDelete,
                successTip: '删除成功'
            };
        } else if (type == 1) {
            typeMsg = {
                title: '发布',
                api: API.ruleCategoryRulePublish,
                successTip: '发布成功'
            };
        } else {
            typeMsg = {
                title: '下架',
                api: API.ruleCategoryRuleOffShelves,
                successTip: '下架成功'
            };
        }
        Modal.confirm({
            title: typeMsg.title,
            icon: null,
            content: `确认${typeMsg.title}规则内容吗？`,
            onOk: () => {
                this.goNextChange(typeMsg, code)
            }
        })
    }
    // ajax删除/发布/下架
    goNextChange = (typeMsg, code) => {
        const config = {
            api: typeMsg.api,
            data: { code },
            successTip: typeMsg.successTip 
          };
          http(config).then(res => {
            if (res.success) {
                this.getRuleList()
            }
          });
    }
    // 查看详情
    watchRule = (code) => {
          if (!code) {
            // 新增时,encode为空
            return;
        }

        // make config 
        const config = {
            api: API.ruleCategoryRuleDetail,
            data: {  code }
        };

        // query 
        http(config).then(res => {
            if (res && res.success) {
                let htmlValue = res.data.content;
                if(htmlValue){
                    htmlValue= htmlValue.rollbackXss();
                }

                this.setState({
                    htmlDetail:htmlValue
                });
            }
        });
    }

    render() {
        const { cards, title, parentTitle } = this.state;
        return (
            <div className="rule_list">
                <div className={`${pageClass}-header`}>
					<Breadcrumb>
						<BreadcrumbItem onClick={()=>{this.props.history.replace('/app/crm/rulecenter')}}>规则中心</BreadcrumbItem>
						<BreadcrumbItem>{title}</BreadcrumbItem>
					</Breadcrumb>
                    
				</div>
                <div className={`${pageClass}-content`}>
                    <div className="tit_line">
                        <div className="msg_title">
                            <span>{parentTitle}</span>
                            ->
                            <span>{title}({cards.length})</span>
                        </div>
                        {
                            ruleManageAuth?
                            <Button className={`${pageClass}-edit`} type="primary" onClick={this.creatRule}>创建内容</Button>
                            : null
                        }
                    </div>
                    <div className="filter">
                        <span>发布状态</span>
                        <Select placeholder="请选择" style={{width: 120}} allowClear={true} onChange={this.selectChange}>
                            <Option key="1" value={1}>未发布</Option>
                            <Option key="2" value={2}>已发布</Option>
                        </Select>
                    </div>
                    {cards && cards.length > 0 ?
                        <div className="intention-body">
                            <div className="catalogTit">
                                <div style={{width: 30}}></div>
                                <div className="li_1 li">规则标题</div>
                                <div className="li_2 li">创建时间</div>
                                <div className="li_3 li">最后更新</div>
                                <div className="li_4 li">发布状态</div>
                                <div className="li_5 li">操作</div>
                            </div>
                            <DndProvider backend={HTML5Backend}>
                                {cards.map((card, i) => this.renderCard(card, i))}
                            </DndProvider>
                        </div>
                        :
                        <div className="intention-body nodata">
                            <div>
                                {" "}
                                <img src={require("../../images/nodata.png")} alt="" />
                                <p>暂无数据</p>
                            </div>
                        </div>
                    }
                </div>


         {  this.state.htmlDetail ? (
          <Modal
            title="预览"
            visible={true}
            className="assign-follow-modal"
            footer={null}
            width={1000}
            height={1000}
            onCancel={() => {
              this.setState({  htmlDetail: null });
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: this.state.htmlDetail }} />
          </Modal>
        ) : (
            ""
          )}
			</div>
        );
    }
}

export default RuleList;