import React, { Component } from 'react';
import { Tabs, Icon, message } from 'antd';
import TodayMission from './TodayMission';
import DateWork from './DateWork'
import AddSchedule from './AddSchedule'
import EditSchedule from './EditSchedule'
import { taskToday, unCompleteTasks,taskCompletedTasks } from './service/taskService'

const TabPane = Tabs.TabPane;

class Schedule extends Component {
    callback = (key) => {

    }

    cancelScedule = () => {
        this.setState({
            visible: false
        })
    }

    cancelEditScedule =  () => {
        this.setState({
            eVisible: false
        })
    }

    state = {
        visible: false,
        todayList: [],
        umCompleteList: [],
        completeList:[],
        beginDate: null,
        endDate: null,
        keyWords: null,
        orderByList: [
            0
        ],
        umPageNo: 1,
        umPageSize: 20,
        pageNo: 1,
        pageSize: 20,
        editData:{},
        eVisible:false,
        totalComplete:1,
        totalUmComplete:1
    }

    showAddSchedule = () => {
        this.setState({
            visible: true
        })
    }

    componentDidMount() {
        this.getList()
        this.getUmCompleteList();
        this.getCompleteList();
    }

    getList = () => {
        taskToday().then(res => {
            if (res.code == 0) {
                if (res.data) {
                    this.setState({
                        todayList: res.data
                    })
                }
            }
        })
    }

    getAllList = ()=>{
        this.getList()
        this.workChange({})
    }

    getUmCompleteList = () => {
        let data = {
            beginDate: this.state.beginDate,
            endDate: this.state.endDate,
            keyWords: this.state.keyWords,
            pageNo: this.state.umPageNo,
            pageSize: this.state.umPageSize         
        }
        unCompleteTasks(data).then(res=>{
            if(res.code == 0){
                if(res.data){
                    this.setState({
                        umCompleteList:res.data.datas,
                        totalUmComplete:res.data.totalPages
                    })
                }
            }
        })
    }

    getCompleteList = () => {
        let data = {
            beginDate: this.state.beginDate,
            endDate: this.state.endDate,
            keyWords: this.state.keyWords,
            pageNo: this.state.pageNo,
            pageSize: this.state.pageSize         
        }
        taskCompletedTasks(data).then(res=>{
            if(res.code == 0){
                if(res.data){
                    this.setState({
                        completeList:res.data.datas,
                        totalComplete:res.data.totalPages
                    })
                }
            }
        })
    }

    workChange = (config) => {
        this.setState({
            beginDate: config.beginDate||null,
            endDate: config.endDate||null,
            keyWords: config.keyWords||null,   
            umPageNo: 1,
            umPageSize: 20,
            pageNo: 1,
            pageSize: 20        
        },()=>{
            this.getUmCompleteList();
            this.getCompleteList();            
        })
    }

    editItem =(v) => {
        console.log(v);
            this.setState({
                editData:v,
                eVisible:true
            })
    }

    getMoreUmComplete = () => {
        let pageNo = this.state.umPageNo+1
        let data = {
            beginDate: this.state.beginDate,
            endDate: this.state.endDate,
            keyWords: this.state.keyWords,
            pageNo: pageNo,
            pageSize: this.state.umPageSize         
        }
        unCompleteTasks(data).then(res=>{
            if(res.code == 0){
                if(res.data&&res.data.datas.length > 0){
                    let completeList = this.state.umCompleteList
                    completeList = completeList.concat(res.data.datas)
                    this.setState({
                        umCompleteList:completeList,
                        umPageNo:pageNo
                    })
                }else{
                    message.warning('没有更多了')
                }
            }
        })
    }

    getMoreComplete = () => {
        let pageNo = this.state.pageNo+1
        let data = {
            beginDate: this.state.beginDate,
            endDate: this.state.endDate,
            keyWords: this.state.keyWords,
            pageNo: pageNo,
            pageSize: this.state.pageSize         
        }
        taskCompletedTasks(data).then(res=>{
            if(res.code == 0){
                if(res.data&&res.data.datas.length > 0){
                    let completeList = this.state.completeList
                    completeList = completeList.concat(res.data.datas)
                    this.setState({
                        completeList:completeList,
                        pageNo:pageNo
                    })
                }else{
                    message.warning('没有更多了')
                }
            }
        })
    }

    render() {
        const visible = this.state.visible;
        const yet = this.props.yet;
        const pageData = {
            complete:{
                pageNo:this.state.pageNo,
                total:this.state.totalComplete
            },
            umCompleteList:{
               pageNo:this.state.umPageNo,
               total:this.state.totalUmComplete                
            }
        }
        // const yetWidth = window.innerWidth - 248
        return (
            <div className="Schedule" style={!yet ? {width:'100%',maxWidth:'952px',position:'relative',right:0,top:0,float:'right'} : null}>
                <Tabs defaultActiveKey="1" onChange={this.callback}>
                    <TabPane tab="今日任务" key="1">
                        <TodayMission data={this.state.todayList} editItem={this.editItem} getList={this.getAllList} />
                    </TabPane>
                    <TabPane tab="日程" key="2">
                        <DateWork umCompleteList={this.state.umCompleteList} pageData={pageData} editItem={this.editItem} completeList={this.state.completeList} getUmCompleteList={this.getAllList} getCompleteList={this.getAllList} onChange={this.workChange} getMoreComplete={this.getMoreComplete} getMoreUmComplete={this.getMoreUmComplete} />
                    </TabPane>
                </Tabs>
                <div className="schedule-date-add" onClick={this.showAddSchedule}>
                    <span className="icon iconfont iconadd_circle"></span>
                    添加
                </div>
                <AddSchedule visible={this.state.visible} getList={this.getAllList} cancel={this.cancelScedule} />
                <EditSchedule visible={this.state.eVisible} getList={this.getAllList} data={this.state.editData} cancel={this.cancelEditScedule} />
            </div>
        )
    }
}

export default Schedule
