/**
 * map 组件
 */
import React, { Component } from "react";
import echarts from "echarts";
import "./Maps.less";
import {numberFormat} from '@/utils/fliter';
import {message } from 'antd';
import { API } from '@/http/api';
import http from '@/http';
import axios from "axios";
import AuthContainer from 'CMPT/common/auth/AuthContainer';
import { AUTH_KEYS } from '@/utils/auth';
import userUtil from '@/utils/user';
import project_photo from '@/images/project_photo@2x.jpg';
/**
 * 资源
 * https://gallery.echartsjs.com/editor.html?c=xr1IEt3r4Q
 * https://blog.csdn.net/u011669700/article/details/81287787
 * http://datav.aliyun.com/tools/atlas/#&lat=33.521903996156105&lng=104.29849999999999&zoom=4
 * http://geojson.io/#map=2/20.0/0.0
 */
// https://openlayers.org/ 
import {GeoJSON} from 'ol/format';

// 判定项目是否在区域内
function isIntersectsCoordinate(geoJson,coordinate){

    const features = new GeoJSON().readFeatures(geoJson)
    return  features.some(feature=>{
        var result = feature.getGeometry().intersectsCoordinate(coordinate)
        console.log(result)
        return result
    })

}

// 所有地图及对应的adcode
let AllGeo = null
// 渲染中国地图





export default class Map extends Component {
    componentWillMount(){
        var _this = this
        echarts.extendsMap = function(id, opt) {
            // 创建实例
            if(!document.getElementById(id)){
                return false
            }
            var chart = this.init(document.getElementById(id));
        
            
        
            var defaultOpt = {
                mapName: "china", // 地图展示
                goDown: false, // 是否下钻        
                activeArea: [], // 区域高亮,同echarts配置项
                data: [],
                // 下钻回调(点击的地图名、实例对象option、实例对象)
                callback: function(name, option, instance) {}
            };
            if (opt) opt = this.util.extend(defaultOpt, opt);
        
            // 层级索引
            var name = [opt.mapName];
            var idx = 0;
            
        
        
            var handleEvents = {
                /**
                 * i 实例对象
                 * o option
                 * n 地图名
                 **/
                resetOption: function(i, o, n,res) {
                    console.log(i,o,n,res)
                    const geoJson = res
                    // 过滤只有当前区域内的项目显示
                    var data = []
                    
                    
                    if(n=="中华人民共和国"){
                        o.geo.top= "30%";
                        o.geo.zoom=1.8
                        data=opt.data
                    }else{
                        o.geo.top= "middle";
                        o.geo.zoom=1

                        
                        data =opt.data.filter(res=>{
                            
                            var [a,b] =res.coordinate                                                        
                            
                            if(isIntersectsCoordinate(geoJson,[b,a])){                                
                                return res 
                            }
                        })
                    }
                    
                    o.series[0].data = handleEvents.initSeriesData(data);

                    o.geo.map = n;
                    o.geo.roam= true;

                    i.clear();
                    i.setOption(o);
                    
                    // this.zoomAnimation();                    
                    opt.callback(n, o, i,res);
                },
        
             
        
                // 设置effectscatter
                initSeriesData: function(data) {
                    var temp = [];
                    for (var i = 0; i < data.length; i++) {
                        var [a,b] = data[i].coordinate
                        var geoCoord = [b,a];
                        // console.log(geoCoord)
                        if (geoCoord) {
                            temp.push({
                                rate: data[i].rentalRate,
                                value: geoCoord.concat(data[i].value),
                                area: data[i].availableArea,
                                buildingName: data[i].name,
                                amount: data[i].onlineAveragePrice ,
                                contractNum: data[i].contractCnt,
                                imgUrl: data[i].pictureInfoList[0]&&data[i].pictureInfoList[0].url
                            });
                        }
                    }
                    return temp;
                },
                // 动画过渡
                zoomAnimation: function() {
                    var count = null;
                    var zoom = function(per) {
                        if (!count) count = per;
                        count = count + per;
                        // console.log(per,count);
                        chart.setOption({
                            geo: {
                                zoom: count
                            }
                        });
                        if (count < 1)
                            window.requestAnimationFrame(function() {
                                zoom(0.2);
                            });
                    };
                    window.requestAnimationFrame(function() {
                        zoom(0.2);
                    });
                }
            };
        
            var option = {        
                tooltip: {
                    show: true,
                    trigger: "item",
                    alwaysShowContent: false,
                    backgroundColor: "rgba(50,50,50,0.7)",
                    hideDelay: 100,
                    triggerOn: "mousemove",
                    confine:true,
                    enterable: true,
                    position: function(point, params, dom, rect, size) {
                        // 固定在顶部
                        return [point[0] - 30, point[1] + 30];
                    },
                    formatter: function(params, ticket, callback) {
                        return `
                            <style>
                                .tip-content{
                                    background: #fff;
                                    border: 1px solid #535465;
                                    box-shadow: 0 4px 15px 0 rgba(0,0,0,0.40);
                                    border-radius: 2px;
                                    padding: 16px;
                                    font-size: 12px;
                                    color: #E0E4F1;
                                    line-height: 24px;
                                }
                                .tip-content .title{
                                    font-size: 14px;
                                    color: #E0E4F1;
                                    line-height: 20px;
                                    margin-top: 16px;
                                    margin-bottom: 8px;
                                }
                            </style>
                            <div class="tip-content">
                                <img width="200px" height="112px" src="${params.data
                                    .imgUrl ||project_photo}" />
                                <div class="title">${params.data.buildingName}</div>
                                <div>可招商面积：${params.data.area||0}㎡</div>
                                <div>在租比例：${params.data.rate||0}%</div>
                                <div>实时均价：${params.data.amount||0}元/㎡/日</div>
                                <div>承租合同：${params.data.contractNum||0}个</div>
                            </div>
                        `;
                    }
                },
   
                geo: {
                    map: opt.mapName,
                    roam: true,
                    top:'30%',
                    zoom:1.8,
                    label: {
                        normal: {
                            show: true,                            
                            textStyle: {
                                color: "#333",
                                fontSize:13
                            }
                        },
                        emphasis: {
                            textStyle: {
                                color: "#333"
                            }
                        }
                    },                    
                    itemStyle: {
                        normal: {
                            borderColor: "#535465",
                            borderWidth: 1,
                            areaColor: "#23273F",                                
                        },
                        emphasis: {
                            areaColor: "#2d3148",
                            borderColor: "#535465",
                            borderWidth: 1,
                        },
                        
                    },
                    
                },
                series: [
                    {
                        type: "effectScatter",
                        coordinateSystem: "geo",
                        showEffectOn: "render",
                        rippleEffect: {
                            period: 15,
                            scale: 4,
                            brushType: "fill"
                        },
                        geoIndex:0,
                        hoverAnimation: true,
                        itemStyle: {
                            normal: {
                                color: "#FFC848",
                                shadowBlur: 10,
                                shadowColor: "#333"
                            }
                        },
                        data: handleEvents.initSeriesData(opt.data)
                    }
                ]
            };    
            chart.setOption(option);
            // 添加事件
            chart.on("click", function(params) {
                console.log('点击',params)
                var parentName = null
                var level = ''
                
                name = params.name
                
                // 名字换adcode
                var adcodes = []
                for(var i = 0;i<AllGeo.length;i++){
                    if(AllGeo[i].name==params.name){
                        adcodes.push(AllGeo[i])
                    }
                }
                if(!name){
                    return false
                }
                if(adcodes.length==0){
                    console.warn('未找到对应区域')
                    message.info('未找到对应区域测绘图')
                    return false
                }
                if(params.name!=_this.state.country&&params.name!=_this.state.province&&params.name!=_this.state.city&&params.name!=_this.state.region){

                    if(!_this.state.province){
                        parentName = '中华人民共和国'                        
                        _this.setState({
                            province:params.name
                        })
                    }else if(!_this.state.city){
                        parentName = _this.state.province
                        _this.setState({
                            city:params.name
                        })
                    }else if(!_this.state.region){
                        parentName = _this.state.city
                        _this.setState({
                            region:params.name
                        })
                    }
                }
                // 避免同名
                if(adcodes.length>1){
                    for(var i = 0;i<adcodes.length;i++){
                        if(adcodes[i].parent.includes(parentName)){
                            this.setMap({name:name,adcode:adcodes[i].adcode});        
                        }
                    }   
                }else{
                    this.setMap({name:name,adcode:adcodes[0].adcode});
                }
                
                console.log(adcodes)                
            });
            // 提供方法直接渲染某个地区的地图模型
            chart.setMap = function({name:mapName,adcode}) {
                console.log(`获取${mapName} Geo数据`)
                var _self = this;
                
                    
                
                axios.get(process.env.PUBLIC_URL+`/geoatlas/geo/${adcode}.json`).then(res=>{

                    echarts.registerMap(mapName, res);
                    handleEvents.resetOption(_self, option, mapName,res);
                })
                    
                
            };
            // 返回地图实例
            return chart;
        };
    }
    state={
        country:'中华人民共和国',
        province:'',
        city:'',
        region:'',
        
    }
    _chart=null
    mapClick=(name,level)=>{
        var parentName = null
        if(level=='country'){
            this.setState({
                country:name,
                province:'',
                city:'',
                region:''
            })
        }else if(level=='province'){
            this.setState({
                country:'中华人民共和国',
                province:name,
                city:'',
                region:''
            })
            parentName="中华人民共和国"
        }else if(level=='city'){
            this.setState({
                country:'中华人民共和国',
                province:this.state.province,
                city:name,
                region:''
            })
            parentName=this.state.province
        }else{
            this.setState({
                country:'中华人民共和国',
                province:this.state.province,
                city:this.state.city,
                region:name
            })
            parentName=this.state.city
        }
        // 名字换adcode
        var adcodes = []
        for(var i = 0;i<AllGeo.length;i++){
            if(AllGeo[i].name==name){
                adcodes.push(AllGeo[i])
            }
        }
        // 避免同名
        if(adcodes.length>1){
            for(var i = 0;i<adcodes.length;i++){
                if(adcodes[i].parent==parentName){
                    this._chart.setMap({name:name,adcode:adcodes[i].adcode});        
                }
            }   
        }else{
            this._chart.setMap({name:name,adcode:adcodes[0].adcode});
        }        
    }
    getData=()=>{
        return  new Promise((resolve,reject)=>{

            const config = {
                api: API.projectstatsGeo,
                showLoading: true,
            }
            http(config).then(result => {
                if (result.success && result.data.length > 0) {
                    
                    resolve(result.data)
                } 
            })
        })
        
    }
    renderMap = () => {
        var _this = this
        
        Promise.all([axios.get(process.env.PUBLIC_URL+'/geoatlas/geo/100000.json'),this.getData(),axios.get(process.env.PUBLIC_URL+'/geoatlas/all.json')]).then(res=>{            
            const geoJsonChain = res[0]
            

        
            echarts.registerMap("中华人民共和国", geoJsonChain);
            this._chart = echarts.extendsMap("mapMain", {            
                mapName: "中华人民共和国", // 地图名
                text: "商办项目",
                goDown: true, // 是否下钻
                // 下钻回调
                callback: (name, option, instance,res)=> {
                    console.log(`${name}下钻回调`);                                        
                },
                // 数据展示
                
                data: res[1].filter(res=>{
                            
                    var [a,b] =res.coordinate                                                        
                    
                    if(isIntersectsCoordinate(geoJsonChain,[b,a])){                                
                        return res 
                    }
                })

            });
            AllGeo = res[2]
        })
        
    };
    
    componentDidMount() {
        setTimeout(() => {
            this.renderMap();   
        },100);
    }
    toContract=()=>{
        if(userUtil.hasAuth(9902010101)){
            this.props.history.push('/app/operate/contract?source=projectall')
        }
    }
    render() {
        return (
            <div className="map-box">
                <div className="map-city">
                    <span onClick={this.mapClick.bind(this,this.state.country,'country')}>{this.state.country}</span>
                    {this.state.province?(<p> <i></i><span onClick={this.mapClick.bind(this,this.state.province,'province')}>{this.state.province}</span></p>):null}
                    {this.state.city?(<p> <i></i><span onClick={this.mapClick.bind(this,this.state.city,'city')}>{this.state.city}</span></p>):null}
                    {this.state.region?(<p> <i></i><span onClick={this.mapClick.bind(this,this.state.region,'region')}>{this.state.region}</span></p>):null}
                </div>
                <div className="map-info">
                    <div className="map-main" id="mapMain" />
                    <div className="map-project">
                        <div className="map-project-item">
                            <p>项目数量</p>
                            <p><span>{numberFormat(this.props.projectInfo.projectCnt)}</span> 栋</p>
                        </div>
                        <div className="map-project-item">
                            <p>可招商面积</p>
                            <p><span>{numberFormat(this.props.projectInfo.availableArea)}</span> ㎡</p>
                        </div>
                        <div className="map-project-item">
                            <p>出租率</p>
                            <p><span>{this.props.projectInfo.rentalRate}</span> %</p>
                        </div>
                        <div className="map-project-item" onClick={this.toContract}>
                            <p>承租合同数量</p>
                            <p><span>{numberFormat(this.props.projectInfo.contractCnt)}</span> 份
                            <AuthContainer key="1" authCodes={9902010101}>                                                                       
                                <span className="iconfont iconarrow_line_right"></span>
                            </AuthContainer>    
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
