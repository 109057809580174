import React, { Component } from 'react'
import { Input, DatePicker, Select, Icon, Table, Modal, message } from 'antd';
import './CapitalCommon.less'
import './DecoratePopView.less'
import { DECORATE_CHIOCE } from './CapitalConfig'
import moment from 'moment';
import http from '@/http'
import { API } from '@/http/api';

const dateFormat = 'YYYY-MM-DD';
const Option = Select.Option;
const SELFCODE = 'DECORATE';    //自定义encode前缀

/**
 * 管理装修
 */
export default class DecoratePopView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDeleteItemShow: false,    //显示删除弹窗
            zoneDetail: this.props.zoneDetail,
            decorateAll: [],    //初始获取所有的装修管理
            apiAddData: [],         //录入装修信息
            apiUpdateData: [],      //更新装修信息
            selfCodeCount: 0,    //添加装修通过count来自定义encode
            delEncode: null,
            columns: [
                {
                    title: <div className='tab-name'>装修项目名称<div className='tab-star'>*</div></div>,
                    dataIndex: 'name',
                    key: 'name',
                    align: 'center',
                    render: (value, row, index) => {
                        return <Input placeholder='请输入' value={row.name} onChange={e => this.inputName(e, row)} maxLength={8} />
                    }
                }, {
                    title: <div className='tab-name'>开始时间<div className='tab-star'>*</div></div>,
                    dataIndex: 'beginTime',
                    key: 'beginTime',
                    align: 'center',
                    render: (value, row, index) => {
                        let time = moment(row.beginTime).format(dateFormat)
                        return <DatePicker value={(row && row.beginTime) ? moment(time, dateFormat) : null} onChange={e => this.inputBeginTime(e, row)} />
                    }
                }, {
                    title: <div className='tab-name'>结束时间<div className='tab-star'>*</div></div>,
                    dataIndex: 'endTime',
                    key: 'endTime',
                    align: 'center',
                    render: (value, row, index) => {
                        let time = moment(row.endTime).format(dateFormat)
                        return <DatePicker value={(row && row.endTime) ? moment(time, dateFormat) : null} onChange={e => this.inputEndTime(e, row)} />
                    }
                }, {
                    title: <div className='tab-name'>装修状态<div className='tab-star'>*</div></div>,
                    dataIndex: 'status',
                    key: 'status',
                    align: 'center',
                    render: (value, row, index) => {
                        return <Select placeholder='请输入' style={{ minWidth: '100px' }}
                            value={row.status} onChange={e => this.inputStatus(e, row)}>
                            {
                                DECORATE_CHIOCE.All.map(d => (
                                    <Option key={d.value} value={d.value}>{d.name}</Option>
                                ))
                            }
                        </Select>
                    }
                }, {
                    title: <div className='tab-name'>备注</div>,
                    dataIndex: 'remark',
                    key: 'remark',
                    align: 'center',
                    render: (value, row, index) => {
                        return <Input placeholder='请输入' value={row.remark} onChange={e => this.inputRemark(e, row)} maxLength={100} />
                    }
                }, {
                    title: <div className='tab-name'>操作</div>,
                    dataIndex: 'opera',
                    key: 'opera',
                    align: 'center',
                    render: (value, row, index) => {
                        return <a href="javascript:void" style={{ color: '#FBBC06' }} onClick={this.deleteMargin.bind(this, row)}>删除</a>
                    }
                }
            ],
            dataSource: []
        }
    }

    componentDidMount() {
        this.props.onRef(this);
        this.getDecorateAll();
    }

    doAddUpdate = () => {
        let that = this;
        const dataSource = [...this.state.dataSource];
        this.setState({
            apiAddData: dataSource.filter(item => item.encode.startsWith(SELFCODE)),
            apiUpdateData: dataSource.filter(item => !item.encode.startsWith(SELFCODE))
        }, function () {
            const addData = that.state.apiAddData
            const updateData = that.state.apiUpdateData
            const configAdd = {
                api: API.zonedecorationAdd,
                data: addData
            }
            const configUpdate = {
                api: API.zonedecorationUpdate,
                data: updateData
            }
            if (addData && addData.length > 0) {
                http(configAdd).then(result => {
                    if (result.success) {
                        that.doUpdate(configUpdate, updateData);                        
                    }
                })
            } else {
                that.doUpdate(configUpdate, updateData);
            }
        });
    }

    doUpdate = (configUpdate, updateData) => {
        if (updateData && updateData.length > 0) {
            http(configUpdate).then(result => {
                if (result.success) {
                    this.props.handlerBackDecorate();
                    this.props.doSearch()
                }
            })
        } else {
            this.props.handlerBackDecorate();
            this.props.doSearch()
        }
    }

    async getDecorateAll() {
        const config = {
            api: API.zonedecorationGetByZoneId,
            data: { zoneEncode: this.state.zoneDetail.encode }
        }
        http(config).then(result => {
            this.setState({
                decorateAll: result.data
            }, function () {
                this.initDataSource()
            })
        })
    }

    initDataSource() {
        console.log('initDataSource')
        let dataSource = this.state.dataSource
        const decorateAll = this.state.decorateAll;
        if (decorateAll && decorateAll.length > 0) {
            for (let item of decorateAll) {
                dataSource.push({
                    encode: item.encode,
                    name: item.name,
                    beginTime: item.beginTime,
                    endTime: item.endTime,
                    status: item.status,
                    remark: item.remark,
                    zoneEncode: item.zoneEncode
                })
            }

            this.setState({
                dataSource
            })
        }
    }

    findIndex(encode) {
        return this.state.dataSource.findIndex(item => item.encode === encode);
    }

    //--------内容监测--Start-----------
    inputName = (e, row) => {
        console.log('name = ' + row.encode + ", " + e.target.value)
        const dataSource = this.state.dataSource
        dataSource[this.findIndex(row.encode)].name = e.target.value
        this.setState({
            dataSource
        })
    }

    inputBeginTime = (e, row) => {
        console.log('beginTime = ' + row.encode + ', ' + moment(e).format(dateFormat))
        const dataSource = this.state.dataSource
        dataSource[this.findIndex(row.encode)].beginTime = moment(e).format(dateFormat)
        this.setState({
            dataSource
        })
    }

    inputEndTime = (e, row) => {
        console.log('endTime = ' + row.encode + ', ' + moment(e).format(dateFormat))
        const dataSource = this.state.dataSource
        dataSource[this.findIndex(row.encode)].endTime = moment(e).format(dateFormat)
        this.setState({
            dataSource
        })
    }

    inputStatus = (e, row) => {
        console.log('status = ' + row.encode + ', ' + e)
        const dataSource = this.state.dataSource
        dataSource[this.findIndex(row.encode)].status = e
        this.setState({
            dataSource
        })
    }

    inputRemark = (e, row) => {
        console.log('remark = ' + row.encode + ", " + e.target.value)
        const dataSource = this.state.dataSource
        dataSource[this.findIndex(row.encode)].remark = e.target.value
        this.setState({
            dataSource
        })
    }

    //--------内容监测--End-----------

    addMargin = () => {
        if (this.state.selfCodeCount >= 20) {
            message.warning('最多只能添加20条')
            return
        }
        let codeCount = this.state.selfCodeCount + 1;
        this.setState({
            selfCodeCount: codeCount
        }, function () {
            let dataSource = this.state.dataSource
            dataSource.push({
                encode: SELFCODE + this.state.selfCodeCount,
                name: null,
                beginTime: null,
                endTime: null,
                status: 0,
                remark: null,
                zoneEncode: this.state.zoneDetail.encode
            })
            this.setState({
                dataSource
            })
        })
    }

    deleteMargin = (row) => {
        this.setState({
            isDeleteItemShow: true,
            delEncode: row.encode
        })
    }

    deleteItemCancel = () => {
        this.setState({
            isDeleteItemShow: false
        })
    }

    deleteItemOk = () => {
        const dataSource = [...this.state.dataSource];
        this.setState({
            isDeleteItemShow: false,
        })
        if (this.state.delEncode.startsWith(SELFCODE)) {
            this.setState({
                dataSource: dataSource.filter(item => item.encode !== this.state.delEncode)
            })
        } else {
            const config = {
                api: API.zonedecorationDelete,
                data: { decorationId: this.state.delEncode }
            }
            http(config).then(result => {
                if (result.success) {
                    this.setState({
                        dataSource: dataSource.filter(item => item.encode !== this.state.delEncode)
                    })
                }
            })
        }
    }

    deleteItemView() {
        return <Modal
            visible={this.state.isDeleteItemShow}
            centered
            destroyOnClose
            closable={false}
            maskClosable={false}
            width={400}
            onCancel={this.deleteItemCancel}
            onOk={this.deleteItemOk}>
            <div>确认删除？</div>
        </Modal>
    }

    render() {        
        return (
            <div className='decorate-table-detail'>
                <div>
                    <div style={{ fontSize: '18px' }}>管理装修</div>
                    <div className='space-bw' style={{ marginBottom: '10px' }}>
                        <div />
                        <a href="javascript:;" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', color: '#E0E4F1' }} onClick={this.addMargin} >
                            <Icon style={{ color: "#FBBC06", fontSize: "16px" }} type="plus-circle" theme="filled" />
                            <div style={{ fontSize: '12px', marginLeft: '4px' }}>添加</div>
                        </a>
                    </div>
                    <Table rowKey="encode" columns={this.state.columns} dataSource={this.state.dataSource} pagination={false} />
                </div>
                {this.deleteItemView()}
            </div>
        )
    }
}