import React, { Component } from 'react'
import { Form, Input, Button, Modal } from 'antd';
import Select from 'CMPT/common/Select';
import './EditAreaPopView.less'
import { RENT_STATUS, MERCHANT_STATUS } from './CapitalConfig'
import http from '@/http'
import { API } from '@/http/api';
import AuthContainer from 'CMPT/common/auth/AuthContainer';
import { AUTH_KEYS } from '@/utils/auth';
import userUtil from '@/utils/user';
const FormItem = Form.Item;
const { TextArea } = Input;

/**
 * 编辑区域
 */
class EditAreaPopView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDeleteAreaShow: false,
            detail: this.props.zoneDetail,
            businessDatas: MERCHANT_STATUS.All,
            rentStateDatas: RENT_STATUS.All,
            hideRentState:this.props.zoneDetail.merchantStatus==0
        }        
    }
    componentWillMount(){
        this.getProjectSetting()
    }
    //选择招商状态
    chooseBusinessState = (data) => {
        if(data==0){
            // 不可招商隐藏出租状态
            this.setState({
                hideRentState:true
            })            
        }else{
            this.setState({
                hideRentState:false
            })
        }
        setTimeout(() => {
            this.props.form.setFieldsValue({
                merchantStatus: data
            })
        }, 0)
    }

    //选择出租状态
    chooseRentState = (data) => {
        setTimeout(() => {
            this.props.form.setFieldsValue({
                rentStatus: data
            })
        }, 0)
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.doEdit(values);
            }
        });
    }

    async doEdit(values) {
        const detail = this.state.detail;
        const config = {
            api: API.zoneEdit,
            data: {
                "buildingArea": values.buildingArea ? Number(values.buildingArea) : null,
                "companyEncode": detail.companyEncode,
                "encode": detail.encode,
                "unitPrice": values.unitPrice ? Number(values.unitPrice) : null,
                "floorEncode": detail.floorEncode,
                "innerArea": values.innerArea ? Number(values.innerArea) : null,
                "merchantStatus": values.merchantStatus,
                "projectEncode": detail.projectEncode,
                "zoneNo": values.zoneNo,
                "remark": values.remark,
                "rentStatus": values.rentStatus||0,
                "useArea": values.useArea ? Number(values.useArea) : null
            }
        }
        http(config).then(result => {
            if (result.success) {
                this.props.handlerBackEditAreaClick('ok')
            }
        })
    }

    cancelEdit = () => {
        this.props.handlerBackEditAreaClick('cancel')
    }

    deleteArea = () => {
        this.setState({
            isDeleteAreaShow: true
        })
    }

    deleteAreaCancel = () => {
        this.setState({
            isDeleteAreaShow: false
        })
    }

    deleteAreaOk = () => {
        this.doDelete();
    }

    async doDelete() {
        const config = {
            api: API.zoneDelete,
            path: { zoneEncode: this.state.detail.encode }
        }
        http(config).then(result => {
            this.setState({
                isDeleteAreaShow: false
            })
            if (result.success) {
                this.props.handlerBackEditAreaDelete();
            }
        })
    }

    deleteAreaView() {
        return <Modal
            visible={this.state.isDeleteAreaShow}
            centered
            destroyOnClose
            closable={false}
            maskClosable={false}
            width={400}
            onCancel={this.deleteAreaCancel}
            onOk={this.deleteAreaOk}>
            <div>确认删除此区域？</div>
        </Modal>
    }
    getProjectSetting = () => {
        // 获取项目配置
        const defaultConfig = {
            api: API.decisionByProjectEncode,
            path: {
                projectEncode: "qeZDWfgpvSs"
            },
            data: {
                bizType: '1'
            }
        }
        http(defaultConfig).then(res => {            
            this.setState({
                defaultSquare : res.data.length?res.data[res.data.length-1].description:'建筑面积'
            },()=>{
                console.log(this.state.defaultSquare)
            })
        })
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const detail = this.state.detail;

        return (
            <Form onSubmit={this.handleSubmit} className="editForm">
                <div style={{ fontSize: '18px',fontWeight: 600 , marginBottom: '20px' }}>编辑区域</div>
                <FormItem label="门牌号">
                    {getFieldDecorator('zoneNo', {
                        rules: [{
                            required: true, message: '请填写门牌号!'
                        }],
                        initialValue: detail.zoneNo
                    })(
                        <Input autoComplete="off" placeholder="请输入" maxLength={8} />
                    )}
                </FormItem>
                <FormItem>
                    <FormItem label="招商状态" style={{ display: 'inline-block' }}>
                        {getFieldDecorator('merchantStatus', {
                            rules: [{
                                required: true, message: '请选择!'
                            }],
                            initialValue: detail.merchantStatus
                        })(
                            <Select style={{ width: '277px' }} data={this.state.businessDatas} selectMethod={this.chooseBusinessState} />
                        )}
                    </FormItem>
                    {
                        !this.state.hideRentState?(
                            <FormItem label="出租状态" style={{ display: 'inline-block', marginLeft: '20px' }}>
                                {getFieldDecorator('rentStatus', {
                                    rules: [{
                                        required: true, message: '请选择!'
                                    }],
                                    initialValue: detail.rentStatus||''
                                })(
                                    <Select style={{ width: '277px' }} data={this.state.rentStateDatas} selectMethod={this.chooseRentState} />
                                )}
                            </FormItem>
                        ):null
                    }
                    
                </FormItem>
                <FormItem>
                    <FormItem label="建筑面积" style={{ display: 'inline-block' }}>
                        {getFieldDecorator('buildingArea', {
                            rules: [{
                                required: true, message: '请填写建筑面积!'
                            }],
                            initialValue: detail.buildingArea
                        })(
                            <Input autoComplete="off" placeholder="请输入" min={1} max={99999999} style={{ width: '277px' }}
                                suffix={
                                    <div>m²</div>
                                } />
                        )}
                    </FormItem>
                    <FormItem label="预计单价" style={{ display: 'inline-block', marginLeft: '20px' }}>
                        {getFieldDecorator('unitPrice', {
                            rules: [{
                                required: true, message: '请填写预计单价!'
                            }],
                            initialValue: detail.unitPrice
                        })(
                            <Input autoComplete="off" placeholder="请输入" min={1} max={9999} style={{ width: '277px' }}
                                suffix={
                                    <div>元/m²/日</div>
                                } />
                        )}
                    </FormItem>
                </FormItem>
                <FormItem>
                    <FormItem label="使用面积" style={{ display: 'inline-block' }}>
                        {getFieldDecorator('useArea', {
                            rules: [{
                                required: this.state.defaultSquare=="使用面积"?true:false,
                                message: '请填写使用面积!'
                            }],
                            initialValue: detail.useArea
                        })(
                            <Input autoComplete="off" placeholder="请输入" min={1} max={99999999} style={{ width: '277px' }}
                                suffix={
                                    <div>m²</div>
                                } />
                        )}
                    </FormItem>
                    <FormItem label="套内面积" style={{ display: 'inline-block', marginLeft: '20px' }}>
                        {getFieldDecorator('innerArea', {
                            rules: [{
                                required: this.state.defaultSquare=="套内面积"?true:false,
                                message: '请填写套内面积!'
                            }],
                            initialValue: detail.innerArea
                        })(
                            <Input autoComplete="off" placeholder="请输入" min={1} max={99999999} style={{ width: '277px' }}
                                suffix={
                                    <div>m²</div>
                                } />
                        )}
                    </FormItem>
                </FormItem>
                <FormItem label="备注">
                    {getFieldDecorator('remark', {
                        rules: [{
                            required: false
                        }],
                        initialValue: detail.remark
                    })(
                        <TextArea rows={4} maxLength={100} placeholder="请输入" />
                    )}
                </FormItem>
                <div className='space-bw' style={{ marginBottom: '20px' }}>
                    <AuthContainer key="1" authCodes={AUTH_KEYS.PROJECT_REGION_OPERATE_DELETE}>
                        <a href="javascript:;" onClick={this.deleteArea} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', color: '#E0E4F1' }}>
                            <span className="icon iconfont">&#xe7be;</span>
                            <div style={{ marginLeft: '3px', fontSize: '13px' }}>删除区域</div>
                        </a>
                    </AuthContainer> 
                    <span>
                        <Button type="primary" style={{marginRight: '10px',color:'#272A3A'}}
                            htmlType="submit">
                            确定
                        </Button>
                        <Button onClick={this.cancelEdit}>取消</Button>
                    </span>
                </div>
                {this.deleteAreaView()}
            </Form>
        )
    }
}

export default Form.create({ name: 'EditAreaPopView' })(EditAreaPopView);