import React, { Component } from 'react';
import {
    Table, Pagination, Dropdown, Icon, Menu
} from 'antd';
export default class BOTable extends Component {
    state = {
        pageSize: this.props.pageSize||10,
        current: 1
    }
    componentDidMount() {
        this.setState({
            current: this.props.current || 1
        })
    }
    componentWillReceiveProps(nextProps, nextStatus) {
        this.setState({
            current: nextProps.current || 1
        })
    }
    itemRender = (current, type, originalElement) => {
        if (type === 'prev') {
            return <span className="ant-pagination-page-change">上一页</span>;
        } if (type === 'next') {
            return <span className="ant-pagination-page-change">下一页</span>;
        }
        return originalElement;
    }
    currentSelect = ({ item, key, selectedKeys }) => {
        key = parseInt(key)
        const { change } = this.props
        this.setState({
            pageSize: key,
            current: 1
        })
        change(1, key)
    }
    paginationChange = (page) => {
        const { change } = this.props
        const pageSize = this.state.pageSize
        this.setState({
            current: page
        })
        change(page, pageSize)
    }
    tableChange = (pagination, filters, sorter) => {
        const { change } = this.props
        change(this.state.current, this.state.pageSize, sorter)
    }
    render() {
        const { loading,rowKey, selfProcessData, data, columns, total, pagination, rowSelection, onSelect, footer, showEmpty } = this.props
        console.log('this.props....', this.props)
        const defaultData = data && data.map(item => {
                Object.keys(item).forEach(childItem => {
                    const json = item[childItem];
                    if (json === null || json === undefined || json === '') item[childItem] = '-'
                });
                return item
            }
        );
        const useData = selfProcessData || defaultData || [];

        if (showEmpty && (!useData || !useData.length)) {
            return <div className='no-data'><div className="no-data-text">暂无数据</div></div>
        }

        const menu = (
            <Menu onClick={this.currentSelect}>
                <Menu.Item key={10} style={{fontSize: '12px'}}>10条</Menu.Item>
                <Menu.Item key={20} style={{fontSize: '12px'}}>20条</Menu.Item>
                <Menu.Item key={50} style={{fontSize: '12px'}}>50条</Menu.Item>
            </Menu>
        );
        let _Pagination = (
            <div className="bo-pagination">
                <span className="bo-pagination-current">
                    当前页第<span>1-{useData.length}</span>条，共{total || 0}条
                    </span>
                <span>
                    每页显示： <Dropdown overlay={menu} trigger={['click']}>
                        <span className="ant-dropdown-current">
                            {this.state.pageSize}条 <Icon type="caret-down" />
                        </span>
                    </Dropdown>
                </span>
                <div className="bo-pagination-item">
                    <Pagination total={total} itemRender={this.itemRender}
                        current={this.state.current} onChange={this.paginationChange}
                        pageSize={this.state.pageSize}
                    />
                </div>
            </div>
        )
        if (pagination === false || total <= 10) {
            _Pagination = null
        }
        return (
            <div className="bo-table">
                <Table loading={loading} rowSelection={rowSelection} columns={columns} dataSource={useData} pagination={false} {...this.props} onChange={this.tableChange} />

                {_Pagination}

            </div>
        )
    }
}
