import MenuAuthModel from "./MenuAuthModel.js";
import SubMenuAuthModel from "./SubMenuAuthModel.js";
import OperateAuthModel from "./OperateAuthModel.js";
import { AuthType } from "./AuthModel.js";

export default class AuthModelFactory {
  createAuthModel(jsonData, parent) {
    let model = undefined;

    let type = jsonData.type;
    switch (type) {
      case AuthType.MENU:
        model = new MenuAuthModel(type);
        break;
      case AuthType.SUB_MENU:
        model = new SubMenuAuthModel(type);
        break;
      case AuthType.OPERATION:
        model = new OperateAuthModel(type);
        break;
    }

    if (model) {
      model.initFromJson(jsonData, parent, this);
    }

    return model;
  }
}