import React, { Component } from "react";
import { Checkbox } from 'antd';
import Enum from "@/utils/Enum";
import AuthModelFactory from "./AuthModelFactory.js";
import '../AuthSetting.less';

const AuthType = new Enum(
  { alias: "MENU", text: "一级菜单", value: 1 },
  { alias: "SUB_MENU", text: "二级菜单", value: 2 },
  { alias: "OPERATION", text: "按钮操作", value: 5 }
);

const ChildCheckType = new Enum(
  { alias: "NONE", text: "一个也没有选中", value: 1 },
  { alias: "ALL", text: "全部选中", value: 2 },
  { alias: "BOTH", text: "有选中 也有 未选中的", value: 3 }
);

/**
 * 抽象出来的一个权限组，封装后端给出的权限数组
 * 
 * 它作为整体 出现在AuthSetting的逻辑中
 */
class RootAuthModel {

  constructor(host) {
    this.host = host;
    this.isLoaded = false;// 默认没有初始化
  }

  /**
   * 是否完成数据加载
   */
  isStateOk() {
    return this.isLoaded;
  }

  convertCheckStatus(checkedArray) {
    // checkedArray 可能为空内容
    if (this.modelArray && Array.isArray(checkedArray) && checkedArray.length > 0) {
      this.modelArray.map(item => {
        if (item) {
          item.convertCheckStatus(checkedArray);
        }
      });
    }

    this.isLoaded = true;
  }

  value() {
    let valueArray=[];
    if (this.modelArray && this.modelArray.length > 0) {
      this.modelArray.map(item => {
        item.value(valueArray);
      })
    }
    return valueArray;
  }

  isHasError() {
    // 没有错误，则判断children是否有error
    if (this.modelArray && this.modelArray.length > 0) {
      for (var i = 0; i < this.modelArray.length; i++) {
        let item = this.modelArray[i];
        if (item) {
          let error = item.isHasError();
          if (error) {
            return error;
          } 
        }
      }
    }

    return undefined;
  }

  onBeforeUpdateUi() {
    if (this.modelArray && this.modelArray.length > 0) {
      this.modelArray.map(item => {
        item.onBeforeUpdateUi();
      })
    }
  }

  // 由最顶层元素 去回调 Component，进而更新 整个Model树
  applyHostUpdate() {
    this.onBeforeUpdateUi();

    if (this.host) {
      this.host.applyUpdateAuthUi(this);
    }
  }

  //  从后端返回的数据，进行转化本地Model
  initFromJson(jsonData) {
    let modelArray = [];

    let authFactory = new AuthModelFactory();
    if (jsonData && Array.isArray(jsonData)) {
      jsonData.map(item => {
        let modelChild = authFactory.createAuthModel(item, this);
        if (modelChild) {
          modelArray.push(modelChild);
        }
      });
    }

    this.modelArray = modelArray;
  }

  // 返回UI 
  render() {
    return (
      <div className="auth_box" key={this}>
        {
          this.modelArray && this.modelArray.map(item => {
            return item ? item.render() : '';
          })
        }
      </div>
    );
  }
}

export { RootAuthModel, ChildCheckType,AuthType };