import React, { Component } from 'react';
import { Layout, Menu, Breadcrumb, Button, Row, Col, Timeline } from 'antd';
import { Link } from 'react-router-dom'
import { numberFormat } from '@/utils/fliter';
import IntentionTable from './IntentionTable';
import moment from 'moment';
import { channelTypeConfig, industryTypeConfig, areaTypeConfig, followupFlagConfig, followStatusConfig, lostReasonTypeConfig } from './intentionConfig'
import Table from 'CMPT/common/Table';
import AssignFollower from './AssignFollower';
import AddFollow from './AddFollow';
import { customerGetCustomerList, usergetList } from './service/customerService';
import { intentionInit, getProjectDetailInfoByName, intentionAdd, intentionGet } from './service/intentionService'
import wordSrc from '@/images/files_word@2x.png';
import pdfSrc from '@/images/files_pdf@2x.png';
import excelSrc from '@/images/files_excel@2x.png';
import pptSrc from '@/images/files_ppt@2x.png';
import txtSrc from '@/images/files_txt@2x.png';
import unknowSrc from '@/images/files_unknown@2x.png';
import { changeObjkey,getNewPhone } from '@/utils/index'
import {
    AUTH_KEYS
} from '@/utils/auth';
import userUtil from "@/utils/user";
import './intention.less'


const { Header, Content, Footer } = Layout;

class IntentionDetail extends Component {

    state = {
        info: {
            "encode": null,
            "code": null,
            "userName": null,
            "customerName": null,
            "status": null,
            "industryType": null,
            "investmentMoney": null,
            "areaType": null,
            "signDate": null,
            "channelType": null,
            "channelContact": null,
            "channelPhone": null,
            "visitDate": null,
            "intentionRate": null,
            "lostReasonType": null,
            "followupFlag": null,
            "startDate": null,
            "endDate": null,
            "quitDate": null,
            "preSignDate": null,
            "createTime": null,
            "modifiedTime": null,
            "intentionZoneList": [
                // {
                //   "encode": "H0Hs6ltsads",
                //   "floorEncode": "IBCJnWzI5_o",
                //   "floorName": "12 lou",
                //   "floorNo": "12F",
                //   "zoneEncode": "peoQ_dwWmKo",
                //   "zoneNo": "A1501",
                //   "createTime": 1557392153000,
                //   "modifiedTime": null
                // },
                // {
                //     "encode": "H0Hs6ltsads",
                //     "floorEncode": "IBCJnWzI5_o",
                //     "floorName": "12 lou",
                //     "floorNo": "12F",
                //     "zoneEncode": "peoQ_dwWmKo",
                //     "zoneNo": "A1501",
                //     "createTime": 1557392153000,
                //     "modifiedTime": null
                //   }
            ],
            "fileInfoList": [
                // {
                //   "encode": "nWb7AUY6EiM",
                //   "bizType": 4,
                //   "url": "http://img.idf10.cn/view/house/54c2f1a1eb6f12d681a5c7078421a5500cee02ad/900x675.jpg",
                //   "name": "aaaaaaa",
                //   "type": "1",
                //   "mediaKey": "54c2f1a1eb6f12d681a5c7078421a5500cee02ad",
                //   "mediaExt": "jpg",
                //   "createTime": 1557392153000,
                //   "modifiedTime": 1557392153000
                // },
                // {
                //   "encode": "kwvs6hB8SYY",
                //   "bizType": 4,
                //   "url": "http://img.idf10.cn/view/house/54c2f1a1eb6f12d681a5c7078421a5500cee02ad/900x675.jpg",
                //   "name": "aaaaaaa",
                //   "type": "1",
                //   "mediaKey": "54c2f1a1eb6f12d681a5c7078421a5500cee02ad",
                //   "mediaExt": "jpg",
                //   "createTime": 1557392153000,
                //   "modifiedTime": 1557392153000
                // }
            ],
            "intentionFollowupList": [
                // {
                //   "encode": "KX-ymzbr5Wk",
                //   "status": 10,
                //   "type": 1,
                //   "userName": "1",
                //   "followupTime": 1546300800000,
                //   "followupRemark": "1",
                //   "createTime": 1557368774000,
                //   "modifiedTime": null,
                //   "lostReasonType": null
                // },
                // {
                //     "encode": "KX-ymzbr5Wk",
                //     "status": 10,
                //     "type": 1,
                //     "userName": "1",
                //     "followupTime": 1546300800000,
                //     "followupRemark": "1",
                //     "createTime": 1557368774000,
                //     "modifiedTime": null,
                //     "lostReasonType": null
                //   }
            ],
            "operateLogList": [
                // {
                //     "encode": "7FJN98bBkCs",
                //     "bizType": 3,
                //     "operateType": 2,
                //     "content": "更改描述",
                //     "createName": "张三",
                //     "createTime": 1557392153000,
                //     "modifiedTime": null
                //   },
                //   {
                //     "encode": "WeWByKnq7eA",
                //     "bizType": 3,
                //     "operateType": 2,
                //     "content": "更改描述",
                //     "createName": "张三",
                //     "createTime": 1557371971000,
                //     "modifiedTime": null
                //   },
                //   {
                //     "encode": "6uWny_2h7fU",
                //     "bizType": 3,
                //     "operateType": 3,
                //     "content": "创建意向",
                //     "createName": "张三",
                //     "createTime": 1557368774000,
                //     "modifiedTime": null
                //   }
            ]
        },
        columns: [
            {
                title: '操作时间',
                dataIndex: 'createTime',
                key: 'createTime',
                width: '30%',
                render: value => <span href="javascript:;">{moment(value).format('YYYY-MM-DD HH:mm:ss')}</span>
            },
            {
                title: '操作人',
                dataIndex: 'createName',
                width: '35%',
                key: 'createName'
            },
            {
                title: '操作意见',
                dataIndex: 'content',
                width: '35%',
                key: 'content'
            }
        ],
        assign: {
            visible: false,
            id: this.props.match.params.id
        },
        follow: {
            visible: false,
            id: this.props.match.params.id
        },
        intentionStatusConfigArray: [],
        industryTypeConfigArray: [],
        customerTypeConfigArray: [],
        intentionChannelTypeConfigArray: [],
        lostReasonTypeConfigArrray: [],
        areaTypeConfigArray: [],
        followupTypeConfigArray: [],
        followupFlagConfigArray: []
    }

    assign = () => {
        this.setState({
            assign: {
                visible: !this.state.assign.visible,
                batch: false,
                id: [{ encode: this.props.match.params.id }]
            }
        })
    }

    follow = () => {
        this.setState({
            follow: {
                visible: !this.state.follow.visible,
                id: [{ encode: this.props.match.params.id, status: this.state.info.status }]
            }
        })
    }

    checkEditAuth = (record) => {
        let userInfo = userUtil.get()
        if (!userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_INTENTION_EDIT)) {
            return false
        } else {
            if (userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_INTENTION_EDIT_ALL)) {
                console.log(1)
                return true
            }
            else if (userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_INTENTION_EDIT_SELF) && userInfo.idCode == record.userEncode) {
                console.log(2)
                return true
            } else {
                return false
            }
        }
    }

    checkDispatchAuth = record => {
        let userInfo = userUtil.get()
        if (!userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_INTENTION_DISPATCH)) {
            return false
        } else {
            if (userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_INTENTION_DISPATCH_ALL)) {
                return true
            }
            else if (userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_INTENTION_DISPATCH_SELF) && userInfo.idCode == record.userEncode) {
                return true
            } else {
                return false
            }
        }
    }

    checkFollowAuth = record => {
        let userInfo = userUtil.get()
        if (!userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_INTENTION_FOLLOW)) {
            return false
        } else {
            if (userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_INTENTION_FOLLOW_ALL)) {
                return true
            }
            else if (userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_INTENTION_FOLLOW_SELF) && userInfo.idCode == record.userEncode) {
                return true
            } else {
                return false
            }
        }
    }

    getDetail = () => {
        intentionGet({ encode: this.props.match.params.id }).then(res => {
            if (res.code == 0) {
                this.setState({
                    info: res.data
                })
            }
        })
    }

    componentDidMount() {
        // this.getCustomerList({});
        // this.getUserList();
        // this.getProjectList('');
        intentionInit().then(res => {
            console.log(res);
            let data = res.data;
            let { intentionStatusEnum, customerTypeEnum, industryTypeEnum, intentionChannelTypeEnum, lostReasonTypeEnum, areaTypeEnum, followupTypeEnum, followupFlagEnum } = { ...data }
            let intentionStatusConfigArray = intentionStatusEnum.map(item => changeObjkey(item, { value: 'name', key: 'value' }))
            let customerTypeConfigArray = customerTypeEnum.map(item => changeObjkey(item, { value: 'name', key: 'value' }))
            let industryTypeConfigArray = industryTypeEnum.map(item => changeObjkey(item, { value: 'name', key: 'value' }))
            let intentionChannelTypeConfigArray = intentionChannelTypeEnum.map(item => changeObjkey(item, { value: 'name', key: 'value' }))
            let lostReasonTypeConfigArrray = lostReasonTypeEnum.map(item => changeObjkey(item, { value: 'name', key: 'value' }))
            let areaTypeConfigArray = areaTypeEnum.map(item => changeObjkey(item, { value: 'name', key: 'value' }))
            let followupTypeConfigArray = followupTypeEnum.map(item => changeObjkey(item, { value: 'name', key: 'value' }))
            let followupFlagConfigArray = followupFlagEnum.map(item => changeObjkey(item, { value: 'name', key: 'value' }))
            this.setState({
                intentionStatusConfigArray: intentionStatusConfigArray,
                industryTypeConfigArray: industryTypeConfigArray,
                customerTypeConfigArray: customerTypeConfigArray,
                intentionChannelTypeConfigArray: intentionChannelTypeConfigArray,
                lostReasonTypeConfigArrray: lostReasonTypeConfigArrray,
                areaTypeConfigArray: areaTypeConfigArray,
                followupTypeConfigArray: followupTypeConfigArray,
                followupFlagConfigArray: followupFlagConfigArray
            }, () => {
                this.getDetail();
            })
        })
    }



    render() {
        console.log(this.state)
        // const intentionStatusConfigArray = this.state.intentionStatusConfigArray.map(item=>{
        //     let obj = {}
        //     obj[item.value] = item.name
        //     return obj
        // }),
        // industryTypeConfigArray = this.state.industryTypeConfigArray.map(item=>{
        //     let obj = {}
        //     obj[item.value] = item.name
        //     return obj
        // }),
        // customerTypeConfigArray = this.state.customerTypeConfigArray.map(item=>{
        //     let obj = {}
        //     obj[item.value] = item.name
        //     return obj
        // }),
        // intentionChannelTypeConfigArray = this.state.intentionChannelTypeConfigArray.map(item=>{
        //     let obj = {}
        //     obj[item.value] = item.name
        //     return obj
        // }),
        // lostReasonTypeConfigArrray = this.state.lostReasonTypeConfigArrray.map(item=>{
        //     let obj = {}
        //     obj[item.value] = item.name
        //     return obj
        // }),
        // areaTypeConfigArray = this.state.areaTypeConfigArray.map(item=>{
        //     let obj = {}
        //     obj[item.value] = item.name
        //     return obj
        // }),
        // followupTypeConfigArray = this.state.followupTypeConfigArray.map(item=>{
        //     let obj = {}
        //     obj[item.value] = item.name
        //     return obj
        // }),
        // followupFlagConfigArray= this.state.followupFlagConfigArray.map(item=>{
        //     let obj = {}
        //     obj[item.value] = item.name
        //     return obj
        // })
        // console.log();
        const info = this.state.info;;
        const stateKeys = Object.keys(info);
        stateKeys.map(item => {
            if (info[item] === null || info[item] === '') {
                info[item] = '-'
            }
        })
        // console.log(this.state.followupFlagConfigArray)
        // console.log(info.followupFlag);
        // console.log()
        const dateformat = 'YYYY-MM-DD';
        const intentionZoneList = info.intentionZoneList && info.intentionZoneList != '-' ? info.intentionZoneList.map((item, index) => <span key={index}> {index == 0 ? item.floorName + item.zoneNo : ',' + item.floorName + item.zoneNo}</span>) : null;
        const intentionFilesList = info.fileInfoList ? info.fileInfoList.map((item, index) => <Col key={index} span={4}> {item.mediaExt == 'jpg' || item.mediaExt == 'png' ? <a className="file-link" target="_blank" href={item.url}> <img style={{ width: '120px', height: '90px' }} src={item.url} alt="" /> </a> : <a className="file-link" target="_blank" href={item.url}> <img src={item.mediaExt == 'doc' || item.mediaExt == 'docx' ? wordSrc : item.mediaExt == 'xlsx' ? excelSrc : item.mediaExt == 'jpg' || item.mediaExt == 'png' ? item.url : unknowSrc} alt="" /></a>} {item.name} </Col>) : null
        const operateLogList = info.operateLogList;
        const columns = this.state.columns;
        const tableconfig = {
            rowKey: 'encode',
            data: operateLogList,
            columns: columns,
            pagination: false
        }
        const id = this.props.match.params.id;
        const intentionStatusConfigArray = this.state.intentionStatusConfigArray;
        console.log(intentionStatusConfigArray)
        const followList = info.intentionFollowupList.map((item, index) => <Timeline.Item key={index} dot={<span className="intetion-follow-dot"></span>}>
            <p className="intention-follow-bold">{moment(item.followupTime).format('YYYY-MM-DD HH:mm:ss')} <span>{intentionStatusConfigArray.find(status => status.value == item.status)["name"]}</span>  </p>
            {item.lostReasonType ? <p>流失原因：{lostReasonTypeConfig[item.lostReasonType]}</p> : null}
            {item.followupFlag ? <p className="intention-follow-bold">{followupFlagConfig[item.followupFlag]}</p> : null}
            <p>跟进人：{item.userName}</p>
            <p>跟进结果：{item.followupRemark}</p>
        </Timeline.Item>)
        return (
            <Content className="intention-list intention-content">
                <div className="intention-list-head flexbox">
                    <Breadcrumb className="intention-list-head-bread">
                        <Breadcrumb.Item>
                            <Link style={{ color: '#333' }} to={{ pathname: "/app/operate/invitation/intention" }}>
                                招商中心
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link style={{ color: '#333' }} to={{ pathname: "/app/operate/invitation/intention" }}>
                                意向管理
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            意向详情
                        </Breadcrumb.Item>

                        {/* <Breadcrumb.Item>招商中心</Breadcrumb.Item>
                    <Breadcrumb.Item>意向管理</Breadcrumb.Item> */}
                    </Breadcrumb>
                    <ul className="intention-list-head-data data-detail">

                        {this.checkEditAuth(info) ? <li className="data-li"> <Button type="link" onClick={() => this.props.history.push('/app/operate/invitation/intention/edit/' + id)}>编辑</Button>  </li> : null}


                        {this.checkDispatchAuth(info) ? <li className="data-li"> <Button type="link" onClick={this.assign}>分配</Button> </li> : null}


                        {this.checkFollowAuth(info) ? <li className="data-li">  <Button type="primary" onClick={this.follow}>跟进</Button>  </li> : null}

                    </ul>
                </div>

                <div className="intention-detail-body">
                    <div className="intention-detail-body-leftchild">
                        <div>
                            <label>意向信息</label>
                            <div>
                                <Row gutter={12}>
                                    <Col className="gutter-row" span={12}>
                                        意向编号：{info.code}
                                    </Col>
                                    <Col className="gutter-row" span={12}>
                                        客户名称：{info.customerName}
                                    </Col>
                                </Row>
                                <Row gutter={12}>
                                    <Col className="gutter-row" span={12}>
                                        来访渠道：{info.channelType && info.channelType != '-' && this.state.followupTypeConfigArray.find(item => item.value == info.channelType)["name"] || info.channelType}
                                    </Col>
                                    <Col className="gutter-row" span={12}>
                                        来访时间：{info.visitDate && info.visitDate != '-' && moment(info.visitDate).format(dateformat) || info.visitDate}
                                    </Col>
                                </Row>
                                <Row gutter={12}>
                                    <Col className="gutter-row" span={12}>
                                        渠道联系人：{info.channelContact}
                                    </Col>
                                    <Col className="gutter-row" span={12}>
                                        渠道联系人手机：{getNewPhone(info.channelPhone)}
                                    </Col>
                                </Row>
                                <Row gutter={12}>
                                    <Col className="gutter-row" span={12}>
                                        投资产业：{info.industryType && info.industryType != '-' && this.state.industryTypeConfigArray.find(item => item.value == info.industryType)["name"] || info.industryType}
                                    </Col>
                                    <Col className="gutter-row" span={12}>
                                        投资规模：{info.investmentMoney != '-' ? `${info.investmentMoney}万元` : info.investmentMoney}
                                    </Col>
                                </Row>
                                <Row gutter={12}>
                                    <Col className="gutter-row" span={12}>
                                        意向区域：{info.intentionZoneList && info.intentionZoneList != '-' && info.intentionZoneList.map(i => i.zoneNo).join() || '-'}
                                    </Col>
                                    <Col className="gutter-row" span={12}>
                                        意向面积：{info.areaType && info.areaType != '-' && this.state.areaTypeConfigArray.find(item => item.value == info.areaType)["name"] || info.areaType}
                                    </Col>
                                </Row>
                                <Row gutter={12}>
                                    <Col className="gutter-row" span={24}>
                                        意向铺位：{intentionZoneList || '-'}
                                    </Col>
                                </Row>
                                <Row gutter={12}>
                                    <Col className="gutter-row" span={12}>
                                        预计起租日期：{info.startDate && info.startDate != '-' && moment(info.startDate).format(dateformat) || info.startDate}
                                    </Col>
                                    <Col className="gutter-row" span={12}>
                                        预计到租日期：{info.endDate && info.endDate != '-' && moment(info.endDate).format(dateformat) || info.endDate}
                                    </Col>
                                </Row>
                                <Row gutter={12}>
                                    <Col className="gutter-row" span={12}>
                                        预计退租日期：{info.quitDate && info.quitDate != '-' && moment(info.quitDate).format(dateformat) || info.quitDate}
                                    </Col>
                                    <Col className="gutter-row" span={12}>
                                        预计签约日期：{info.preSignDate && info.preSignDate != '-' && moment(info.preSignDate).format(dateformat) || info.preSignDate}
                                    </Col>
                                </Row>
                                <Row gutter={12}>
                                    <Col className="gutter-row" span={12}>
                                        意向签订日期：{info.signDate && info.signDate != '-' && moment(info.signDate).format(dateformat) || info.signDate}
                                    </Col>
                                    <Col className="gutter-row" span={12}>
                                        是否已签意向合同：{info.followupFlag && info.followupFlag != '-' && this.state.followupFlagConfigArray.find(item => item.value == info.followupFlag)["name"] || info.followupFlag}
                                    </Col>
                                </Row>
                                <Row gutter={12}>
                                    <Col className="gutter-row" span={12}>
                                        成交几率：{info.intentionRate && info.intentionRate != '-' && `${info.intentionRate}%` || info.intentionRate}
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        {info.fileInfoList.length > 0 ? <div>
                            <label>附件</label>
                            <Row style={{ paddingTop: '20px' }}>
                                {intentionFilesList}
                            </Row>
                        </div> : null}
                        {
                            operateLogList.length > 0 ? <div>
                                <label>操作记录</label>
                                <div style={{ paddingTop: '20px' }}>
                                    <Table  {...tableconfig} />
                                </div>
                            </div> : null
                        }

                    </div>
                    <div className="intention-detail-body-rightchild">
                        <div>
                            <label>跟进记录</label>
                            <div style={{ paddingTop: '20px' }}>
                                <Timeline>
                                    {followList}
                                </Timeline>
                            </div>
                        </div>
                    </div>
                </div>
                {userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_INTENTION_DISPATCH) ? <AssignFollower visible={this.state.assign.visible} id={this.state.assign.id} assignCancel={this.assign} batch={this.state.assign.batch} getIntentionList={this.getDetail} isCustomer={false} /> : null}
                {userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_INTENTION_FOLLOW) ? <AddFollow intentionStatus={this.state.intentionStatusConfigArray} followupType={this.state.followupTypeConfigArray} getIntentionList={this.getDetail} followupFlag={this.state.followupFlagConfigArray} lostReasonType={this.state.lostReasonTypeConfigArrray} visible={this.state.follow.visible} id={this.state.follow.id} getIntentionList={this.getDetail} followCancel={this.follow} isCustomer={false} /> : null}
            </Content>
        );
    }
}

export default IntentionDetail;