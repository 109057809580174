import React, { Component } from 'react';
import { Modal } from 'antd';
import { API } from '@/http/api';
import http from '@/http';

class CatalogDel extends Component {
    deleteRole() {
        const config = {
            api: API.helpCatalogDel,
            showLoading: true,
            data: {
                encode: this.props.editId,
            }
        }
        http(config).then(res => {
            if (res.success) {
                this.handleCancel()
                this.props.refresh();
            }
        })
    }
    handleCancel = () => {
        this.props.assignCancel();
    }
    render() {
        return (
            <Modal
                title="删除该目录后后子目录也将被删除，是否继续？"
                visible={this.props.visible}
                okText='删除'
                maskClosable={false}
                width={400}
                onOk={this.deleteRole.bind(this)}
                onCancel={this.handleCancel}
                className="assign-follow-modal-del"
            >
            </Modal>
        );
    }
}
export default CatalogDel;