
import React, { Component } from "react";
import { Row, Col } from 'antd';
import LineAverage from "CMPT/widget/LineAverage";


export class BaseConvert {
    constructor(key, label) {
        this.label = label;// 状态值
        this.key = key;
    }

    convert(source, index) {

    }

}

export class DivConvert extends BaseConvert {
    constructor(key, label) {
        super(key, label);// 状态值
    }

    convert(source, index) {
        return <div key={index} style={{ paddingTop: '6px', fontSize: '12px' }}>{this.label} : {source[this.key] ? source[this.key] : '-'}</div>;
    }

}


export class UrlConvert extends BaseConvert {
    constructor(key, label) {
        super(key, label);// 状态值
    }

    convert(source, index) {

        return <div key={index} style={{ paddingTop: '20px', fontSize: '12px' }}> {this.label} : <a href={source[this.key] ? source[this.key] : '-'} target="_blank" style={{ color: '#2E4AFF' }}>{source[this.key] ? source[this.key] : ''}</a></div>;
    }

}

export class ImgConvert extends BaseConvert {
    constructor(key, label) {
        super(key, label);// 状态值
    }

    convert(source, index) {
        let imgs = source[this.key];
        let imgArray = (!imgs || imgs.length < 1) ? null : imgs.split(";");

        return <div key={index} style={{ paddingTop: '20px', fontSize: '13px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row', flexWrap: 'nowrap' }}>
            <span> {this.label} :</span>
            {
                (!imgArray || imgArray.length < 1) ? <span>-</span> :
                    imgArray.map((item, index) => {

                        return <img key={'img' + index} src={item} style={{ width: '100px', height: '100px', padding: '10px' }} ></img>;
                    })

            }

        </div>;
    }
}


export class RowTwoConvert extends BaseConvert {

    constructor(left, right) {
        super('', '');// 状态值
        this.left = left;
        this.right = right;
    }

    convert(source, index) {
  
            return <LineAverage key={index}  >
                  {this.left&&this.left.convert(source, 0)}
                  {this.right&&this.right.convert(source, 1)}
        </LineAverage>;
    }
}

export class RowThreeConvert extends BaseConvert {

    constructor(left,middle, right) {
        super('', '');// 状态值
        this.left = left;
        this.right = right;
        this.middle = middle;
    }

    convert(source, index) {
  
            return <LineAverage key={index}  >
                  {this.left&&this.left.convert(source, 0)}
                  {this.middle&&this.middle.convert(source, 1)}
                  {this.right&&this.right.convert(source, 2)}
        </LineAverage>;
    }
}


export class EnumConvert extends BaseConvert {
    constructor(key, label,enumMap) {
        super(key, label);// 状态值
        this.enumMap=enumMap;
    }

    convert(source, index) {
        let data = source[this.key];
        return <div key={index} style={{ paddingTop: '20px', fontSize: '12px' }}>{this.label} : { data? (this.enumMap[data]||'-') : '-'}</div>;
    }

}


export class CustomFunctionConvert extends BaseConvert {
    constructor(key, label,func) {
        super(key, label);// 状态值
        this.func=func;
    }

    convert(source, index) {
        let data = source[this.key];
        return <div key={index} style={{ paddingTop: '20px', fontSize: '12px' }}>{this.label} : { data? (this.func(data)||'-') : '-'}</div>;
    }

}