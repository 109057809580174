import React, { Component } from 'react';
import { Layout, Menu, Breadcrumb, Button, Form, Icon, Input, Row, Col, DatePicker, Upload, Radio, message, Tooltip, Slider, InputNumber, } from 'antd';
import Select from 'CMPT/common/Select'
import SelectGroup from 'CMPT/common/SelectGroup'
import { Link } from 'react-router-dom'
import MultiCascader from 'CMPT/common/MultiCascader';
import reg from '@/utils/regex'
import debounce from 'lodash/debounce';
import { getNewPhone } from '@/utils';
import moment from 'moment';
import { customerGetCustomerList, usergetList } from './service/customerService';
import { intentionInit, getProjectDetailInfoByName, intentionAdd, intentionGetAddUserList } from './service/intentionService'
import { changeObjkey } from '@/utils/index'
import FileUploadBiz from 'CMPT/common/FileUploadBiz'
import axios from 'axios';
import user from '@/utils/user'
import './intention.less'

const { Header, Content, Footer } = Layout;
const { TextArea } = Input;
const { phone, idCard, telephone, socialCreditCode, website, phoneNew } = { ...reg }

class AddIntention extends Component {

    state = {
        customerList: [],
        userList: [],
        followerList: [],
        projectList: [],
        imageUrl: null,
        collapse: false,
        intentionRate: 0,
        intentionStatusConfigArray: [],
        industryTypeConfigArray: [],
        customerTypeConfigArray: [],
        intentionChannelTypeConfigArray: [],
        lostReasonTypeConfigArrray: [],
        areaTypeConfigArray: [],
        followupTypeConfigArray: [],
        followupFlagConfigArray: [],
        projectList: [],
        projectDataList: [],
        floorList: [],
        cascaderData: [],
        fileList: [],
        customerName: null,
        userName: null,
        status: null,
        fileInfoList: [],
        channelType: null,
        lastStatus: null
    }


    getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    handleChange = value => {
        // console.log(value.format('YYYY-MM-DD'))
    }

    handleChangeStatus = value => {
        this.setState({
            status: value
        })
    }

    handleChangeChannelType = value => {
        this.setState({
            channelType: value
        })
    }

    handleChangeGroup = value => {
        this.props.form.setFieldsValue({
            intentionZoneList: value
        })
    }

    handleChangeProject = value => {
        let _project = this.state.projectDataList.find(item => item.encode == value)
        let floors = _project.floors;
        let targetList = []

        floors.forEach(per => {
            if (per.zones && per.zones.length) {
                let obj = {}
                obj.name = per.name
                obj.id = per.encode
                obj.no = per.floorNo
                obj.child = []
                per.zones.forEach(zo => {
                    let zoObj = {}
                    zoObj.name = zo.zoneNo
                    zoObj.id = zo.encode
                    zoObj.parentId = zo.floorEncode
                    zoObj.price = zo.unitPrice
                    zoObj.area = zo.rentalArea
                    zoObj.zoneNo = zo.zoneNo
                    obj.child.push(zoObj)
                })
                targetList.push(obj)
            }
        })
        this.setState({
            floorList: floors,
            cascaderData: targetList
        }, () => {
            //    console.log(this.state.floorList)
        })
    }

    onRateChange = value => {
        this.props.form.setFieldsValue({
            intentionRate: value
        })
    }

    handleUploadChange = (info) => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            this.getBase64(info.file.originFileObj, imageUrl => this.setState({
                imageUrl,
                loading: false,
            }));
        }
    }


    handleChangeCustomer = value => {
        console.log(this.state.customerList.find(item => item.value == value)["name"]);
        this.setState({
            customerName: this.state.customerList.find(item => item.value == value)["text"]
        })
    }

    handleChangeUser = value => {
        this.setState({
            userName: this.state.userList.find(item => item.value == value)["name"]
        })
    }

    componentDidMount() {
        Promise.all([this.getCustomerList({}), this.getUserList(), this.getProjectList('')]).then(() => {
            intentionInit().then(res => {
                let data = res.data;
                let { intentionStatusEnum, customerTypeEnum, industryTypeEnum, intentionChannelTypeEnum, lostReasonTypeEnum, areaTypeEnum, followupTypeEnum, followupFlagEnum } = { ...data }
                let intentionStatusConfigArray = intentionStatusEnum.map(item => changeObjkey(item, { value: 'name', key: 'value' }))
                let customerTypeConfigArray = customerTypeEnum.map(item => changeObjkey(item, { value: 'name', key: 'value' }))
                let industryTypeConfigArray = industryTypeEnum.map(item => changeObjkey(item, { value: 'name', key: 'value' }))
                let intentionChannelTypeConfigArray = intentionChannelTypeEnum.map(item => changeObjkey(item, { value: 'name', key: 'value' }))
                let lostReasonTypeConfigArrray = lostReasonTypeEnum.map(item => changeObjkey(item, { value: 'name', key: 'value' }))
                let areaTypeConfigArray = areaTypeEnum.map(item => changeObjkey(item, { value: 'name', key: 'value' }))
                let followupTypeConfigArray = followupTypeEnum.map(item => changeObjkey(item, { value: 'name', key: 'value' }))
                let followupFlagConfigArray = followupFlagEnum.map(item => changeObjkey(item, { value: 'name', key: 'value' }))
                this.setState({
                    intentionStatusConfigArray: intentionStatusConfigArray,
                    industryTypeConfigArray: industryTypeConfigArray,
                    customerTypeConfigArray: customerTypeConfigArray,
                    intentionChannelTypeConfigArray: intentionChannelTypeConfigArray,
                    lostReasonTypeConfigArrray: lostReasonTypeConfigArrray,
                    areaTypeConfigArray: areaTypeConfigArray,
                    followupTypeConfigArray: followupTypeConfigArray,
                    followupFlagConfigArray: followupFlagConfigArray,
                    lastStatus: intentionStatusConfigArray[intentionStatusConfigArray.length - 1].value
                }, () => {
                    this.props.form.setFieldsValue({
                        status: 10,
                        followupTime: moment(new Date())
                    })
                    let userInfo = user.get();
                    let defaultUser = this.state.userList.find(item => item.value == userInfo.idCode)
                    let search = this.props.location.search;
                    let searchObj = {};
                    search && search.split('?')[1].split('&').map(item => {
                        let tmpArray = item.split('=')
                        searchObj[tmpArray[0]] = tmpArray[1];
                    })
                    this.props.form.setFieldsValue({
                        customerEncode: searchObj['customerCode']
                    })
                    if (searchObj['projectEncode']) {
                        this.props.form.setFieldsValue({
                            projectEncode: searchObj['projectEncode'],
                            intentionZoneList: [`${searchObj['floorEncode']}%${searchObj['encode']}`]
                        }, () => {
                            this.handleChangeProject(searchObj['projectEncode'])
                        })
                    }
                    let customer = this.state.customerList.find(item => item.value == searchObj['customerCode'])
                    customer && this.setState({
                        customerName: customer.text
                    })
                    if (defaultUser) {
                        this.setState({
                            userName: defaultUser.name
                        })
                        this.props.form.setFieldsValue({
                            userEncode: defaultUser.value
                        }, () => {
                            console.log(this.props.form.getFieldsValue())
                        })
                    }
                })
            })
        })
    }


    getCustomerList = data => {
        return customerGetCustomerList(data).then(res => {
            if (res.code == 0) {
                let data = res.data;
                data && this.setState({
                    customerList: data.map(item => {
                        return {
                            name: item.name + '-' + item.mobile + '-' + (item.customerType == 1 ? '公司' : '个人'),
                            value: item.encode,
                            text: item.name
                        }
                    })
                }, () => {
                    console.log(this.state.customerList)
                })
            }
        })
    }

    getUserList = value => {
        return intentionGetAddUserList().then(res => {
            this.setState({
                userList: res.map(item => {
                    return { value: item.idCode, name: item.name, account: item.mobile }
                }),
                followerList: res.map(item => {
                    return { value: item.idCode, name: item.name, account: item.mobile }
                }),
            })
        });
    }

    getProjectList = value => {
        return getProjectDetailInfoByName({ projectName: value }).then(res => {
            if (res.code == 0) {
                let data = res.data;
                this.setState({
                    projectList: data.map(item => {
                        return {
                            name: item.name,
                            value: item.encode
                        }
                    }),
                    projectDataList: data,
                    // floorList:data[0].floors
                })
            }
        })
    }

    searchUser = (value) => {
        let followerList = []
        if (value === '') {
            followerList = this.state.followerList
        } else {
            followerList = this.state.followerList.filter(i => i.name.includes(value))
        }
        this.setState({
            userList: followerList
        })
    }

    searchCustomer = value => {
        this.getCustomerList({ fuzzyKey: value });
    }

    handleCustomerSearch = debounce(this.searchCustomer, 800)

    handleSearch = debounce(this.searchUser, 800)

    fileUp = fileList => this.setState({ fileList: fileList })

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                // console.log(this.props.form.getFieldsValue());
                let fieldsObject = this.props.form.getFieldsValue();
                fieldsObject.channelPhone= fieldsObject.channelPhone.replace(/\s+/g, '')
                fieldsObject.fileInfoList = this.state.fileList;
                fieldsObject.userName = this.state.userName;
                fieldsObject.customerName = this.state.customerName;
                fieldsObject.followupTime = fieldsObject.followupTime.format('YYYY-MM-DD HH:mm:ss')
                fieldsObject.startDate = fieldsObject.startDate ? fieldsObject.startDate.format('YYYY-MM-DD') : null
                fieldsObject.endDate = fieldsObject.endDate ? fieldsObject.endDate.format('YYYY-MM-DD') : null
                fieldsObject.quitDate = fieldsObject.quitDate ? fieldsObject.quitDate.format('YYYY-MM-DD') : null
                fieldsObject.signDate = fieldsObject.signDate ? fieldsObject.signDate.format('YYYY-MM-DD') : null
                fieldsObject.preSignDate = fieldsObject.preSignDate ? fieldsObject.preSignDate.format('YYYY-MM-DD') : null
                fieldsObject.intentionZoneList = fieldsObject.intentionZoneList ? fieldsObject.intentionZoneList.map(item => {
                    // let _tmpArray = item.split('%')
                    return {
                        floorEncode: item.parentId,
                        zoneEncode: item.id
                    }
                }) : []
                fieldsObject.fileInfoList = this.state.fileInfoList;
                for (let i in fieldsObject) {
                    if (!fieldsObject[i]) {
                        fieldsObject[i] = null
                    }
                }
                intentionAdd(fieldsObject).then(res => {
                    if (res.code == 0) {
                        message.success("新增意向成功")
                        this.props.history.push('/app/operate/invitation/intention')
                    }

                })

            }
        });
    }

    fileUp = fileList => {
        this.setState({
            fileInfoList: fileList
        })
    }
    phone(e){
        console.log(e.target.value)
    }
    render() {
        // console.log(this.props);
        const {
            getFieldDecorator, getFieldsError, getFieldError, isFieldTouched,
        } = this.props.form;
        const config = {
            rules: [{ required: true, message: '请选择' }],
        }
        const { intentionRate,  } = this.state;
        return (
            <Content className="intention-list intention-content">
                <div className="intention-list-head flexbox">
                    <Breadcrumb className="intention-list-head-bread">
                        <Breadcrumb.Item>
                            <Link style={{ color: '#333' }} to={{ pathname: "/app/operate/invitation/intention" }}>
                                招商中心
                            </Link>
                        
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link style={{ color: '#333' }} to={{ pathname: "/app/operate/invitation/intention" }}>
                                意向管理
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>新增意向</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <Form onSubmit={this.handleSubmit}>
                    <div className="intention-add-body">
                        <div>
                            <Row gutter={80}>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item label="客户名称">
                                        {getFieldDecorator('customerEncode', config)(
                                            <Select style={{ width: '100%' }} data={this.state.customerList} placeholder="请选择" showSearch selectMethod={this.handleChangeCustomer} onSearch={this.handleCustomerSearch} filterOption={false} />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item label="来访渠道">
                                        {getFieldDecorator('channelType', config)(
                                            <Select style={{ width: '100%' }} data={this.state.intentionChannelTypeConfigArray} placeholder="请选择" selectMethod={this.handleChangeChannelType} />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={80}>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item label="渠道联系人">
                                        {getFieldDecorator('channelContact', {
                                            rules: [
                                                { max: 10, message: '不能超过10个字' }
                                            ]
                                        })(
                                            <Input style={{ width: '100%' }} maxLength={10} placeholder="请输入" />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item label="渠道联系人手机">
                                        {getFieldDecorator('channelPhone', {
                                            rules: [{
                                                pattern: phoneNew, message: '请填写正确的手机号'
                                            }],
                                            getValueFromEvent: e => (getNewPhone(e))
                                        })(
                                            <Input style={{ width: '100%' }}  onChange={(e)=>{this.phone(e)}} maxLength={13} placeholder="请输入" />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={80}>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item label="跟进人">
                                        {getFieldDecorator('userEncode', config)(
                                            <Select style={{ width: '100%' }} placeholder="请选择" showSecondTip='true' data={this.state.userList} selectMethod={this.handleChangeUser} showSearch onSearch={debounce(this.searchUser, 1000)} filterOption={false} />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item label="跟进方式">
                                        {getFieldDecorator('followupType', config)(
                                            <Select style={{ width: '100%' }} data={this.state.followupTypeConfigArray} placeholder="请选择" selectMethod={this.handleChange} />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={80}>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item label="跟进状态">
                                        {getFieldDecorator('status', config)(
                                            <Select style={{ width: '100%' }} data={this.state.intentionStatusConfigArray} placeholder="请选择" selectMethod={this.handleChangeStatus} />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item label="跟进时间">
                                        {getFieldDecorator('followupTime', config)(
                                            <DatePicker style={{ width: '100%' }} showTime format='YYYY-MM-DD HH:mm:ss' onChange={this.handleChange} />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={80} style={{ display: this.state.status == this.state.lastStatus ? 'block' : 'none' }}>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item label="流失原因">
                                        {getFieldDecorator('lostReasonType', {
                                            rules: [
                                                {
                                                    required: this.state.status == this.state.lastStatus,
                                                    message: '请选择'
                                                }
                                            ]
                                        })(
                                            <Select style={{ width: '100%' }} data={this.state.lostReasonTypeConfigArrray} placeholder="请选择" selectMethod={this.handleChange} />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item label="是否继续跟进">
                                        {getFieldDecorator('followupFlag', {
                                            rules: [
                                                {
                                                    required: this.state.status == this.state.lastStatus,
                                                    message: '请选择是否继续跟进'
                                                }
                                            ]
                                        })(
                                            <Radio.Group onChange={this.onChange} >
                                                {
                                                    this.state.followupFlagConfigArray.map(item => <Radio key={item.value} value={item.value}>{item.name}</Radio>)
                                                }
                                            </Radio.Group>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={80}>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item label="来访时间">
                                        {getFieldDecorator('visitDate', {
                                            rules: [
                                                { required: this.state.channelType == 2, message: '请选择' }
                                            ]
                                        })(
                                            <DatePicker style={{ width: '100%' }} format='YYYY-MM-DD' onChange={this.handleChange} />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={80}>
                                <Col className="gutter-row" span={24}>
                                    <Form.Item label="跟进结果">
                                        {getFieldDecorator('followupRemark', { rules: [{ required: true, message: '请填写跟进结果' }, { max: 100, message: '不能超过100字' }] })(
                                            <TextArea placeholder="请输入" maxLength={100} autosize={{ minRows: 4, maxRows: 4 }} />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={80}>
                                <Col className="gutter-row" span={24}>
                                    <Form.Item label="附件">
                                        {getFieldDecorator('file', {})(
                                            <FileUploadBiz fileList={this.state.fileInfoList} fileUp={this.fileUp} />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>

                        </div>
                        <div className="middle-line">
                            <span className="gray-line"></span>
                            <a className="text-color-highlight" href="javascript:void(0)" onClick={() => this.setState({ collapse: !this.state.collapse })}>
                                {!this.state.collapse ? '展开' : '收起'}
                            </a>
                            <span className="gray-line"></span>
                        </div>
                        <div style={{ display: this.state.collapse ? 'block' : 'none' }}>
                            <Row gutter={80}>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item label="投资产业">
                                        {getFieldDecorator('industryType', {})(
                                            <Select style={{ width: '100%' }} data={this.state.industryTypeConfigArray} placeholder="请选择" selectMethod={this.handleChange} />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item label="投资规模">
                                        {getFieldDecorator('investmentMoney', {
                                            rules: [{
                                                pattern: telephone, message: '10位以内整数'
                                            }]
                                        })(
                                            <Input style={{ width: '100%' }}
                                                placeholder="请输入"
                                                maxLength={10}
                                                suffix={
                                                    <Tooltip title="Extra information">
                                                        <span>万元</span>
                                                    </Tooltip>
                                                }
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={80}>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item label="意向项目">
                                        {getFieldDecorator('projectEncode', {})(
                                            <Select style={{ width: '100%' }} data={this.state.projectList} placeholder="请选择" selectMethod={this.handleChangeProject} showSearch optionFilterProp="children" />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item label="意向面积">
                                        {getFieldDecorator('areaType', {})(
                                            <Select style={{ width: '100%' }} data={this.state.areaTypeConfigArray} placeholder="请选择" selectMethod={this.handleChange} />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={80}>
                                {/* <Col className="gutter-row" span={16}>
                                    <Form.Item label="意向区域">
                                        {getFieldDecorator('intentionZoneList', {})(
                                            <SelectGroup style={{ width: '100%' }} data={this.state.floorList} placeholder="请选择" handleChangeGroup={this.handleChangeGroup} />
                                        )}
                                    </Form.Item>
                                </Col> */}
                                <Col className="gutter-row" span={16}>
                                    <Form.Item label="意向区域">
                                        {getFieldDecorator('intentionZoneList', {})(
                                            <MultiCascader notFoundContent='无匹配结果' data={this.state.cascaderData} placeholder="请先选择项目" noShowArea={true} />
                                        )}
                                    </Form.Item>
                                </Col>

                            </Row>
                            <Row gutter={80}>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item label="预计起租日期">
                                        {getFieldDecorator('startDate', {})(
                                            <DatePicker style={{ width: '100%' }} onChange={this.handleChange} />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item label="预计到租日期">
                                        {getFieldDecorator('endDate', {})(
                                            <DatePicker style={{ width: '100%' }} onChange={this.handleChange} />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item label="预计退租日期">
                                        {getFieldDecorator('quitDate', {})(
                                            <DatePicker style={{ width: '100%' }} onChange={this.handleChange} />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={80}>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item label="预计签约日期">
                                        {getFieldDecorator('preSignDate', {})(
                                            <DatePicker style={{ width: '100%' }} onChange={this.handleChange} />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item label="意向签订日期">
                                        {getFieldDecorator('signDate', {})(
                                            <DatePicker style={{ width: '100%' }} onChange={this.handleChange} />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={26}>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item label="成交几率">
                                        {getFieldDecorator('intentionRate', {})(
                                            <Slider
                                                min={0}
                                                max={100}
                                                onChange={this.onRateChange}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    <div style={{ paddingTop: '40px' }}>
                                        <Form.Item>
                                            {getFieldDecorator('intentionRate', {
                                            })(
                                                <InputNumber
                                                    min={0}
                                                    max={100}
                                                    formatter={intentionRate => `${intentionRate}%`}
                                                    parser={intentionRate => intentionRate.replace('%', '')}
                                                    onChange={this.onRateChange}
                                                />
                                            )}
                                        </Form.Item>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                    </div>
                    <div className="btn-group">
                        <Button type="primary" htmlType="submit">提交</Button> <Button type="link" onClick={() => this.props.history.push('/app/operate/invitation/intention')}>取消</Button>
                    </div>
                </Form>
            </Content>
        );
    }
}

const IntentionAddForm = Form.create({ name: 'intention_add' })(AddIntention);

export default IntentionAddForm;