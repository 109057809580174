import {
  AJAX_TYPE
} from '../config';

const prefix = 'channelNav';

export default {
  // 获取频道导航列表
  [`${prefix}List`]: {
    url: "/web/crm/channel/getAll",
    type: AJAX_TYPE.GET
  },

  // 获取频道导航详情
  [`${prefix}Detail`]: {
    url: "/web/crm/channel/getDetail/:typeValue",
    type: AJAX_TYPE.GET
  },

  // 获取频道导航详情
  [`${prefix}DetailNew`]: {
    url: "/web/mall/categoryClient/getDetail/:typeValue",
    type: AJAX_TYPE.GET
  },

  

  // 排序
  [`${prefix}Sort`]: {
    url: "/web/crm/channel/navbarSort",
    type: AJAX_TYPE.POST
  },

  // 获取一级和二级类目
  [`${prefix}OneAndTwoLevel`]: {
    url: `/web/crm/channel/queryOneAndTwoLevel?t=${new Date().getTime()}`,
    type: AJAX_TYPE.GET
  },

  // 获取导航绑定的一级和二级类目
  [`${prefix}LevelByType`]: {
    url: "/web/crm/channel/queryOneAndTwoLevelByType/:typeValue",
    type: AJAX_TYPE.GET
  },

  // 编辑导航
  [`${prefix}EditName`]: {
    url: "/web/crm/channel/editName",
    type: AJAX_TYPE.POST
  },

  

  // 新增导航
  [`${prefix}AddNavbar`]: {
    url: "/web/crm/channel/addNavbar",
    type: AJAX_TYPE.POST
  },

  // 小程序新增导航
  [`${prefix}AddNavbarForMINI`]: {
    url: "/web/mall/categoryClient/addNavbar",
    type: AJAX_TYPE.POST
  },

  // 编辑导航
  [`${prefix}EditNavBar`]: {
    url: "/web/mall/categoryClient/editNavbar",
    type: AJAX_TYPE.POST
  },

  // 删除导航
  [`${prefix}NavbarDelete`]: {
    url: "/web/mall/categoryClient/navbarDelete",
    type: AJAX_TYPE.POST
  },


  // 获取类目导航展示
  [`${prefix}GetNavigationConfig`]: {
    url: "/web/crm/channel/getNavigationConfig",
    type: AJAX_TYPE.POST
  },

  // 更新类目导航展示
  [`${prefix}UpdateNavigationConfig`]: {
    url: "/web/crm/channel/updateNavigationConfig",
    type: AJAX_TYPE.POST
  },

  // 获取副类目详情
  [`${prefix}GetSubNavbarDetail`]: {
    url: "/web/crm/channel/getSubNavbarDetail/:code",
    type: AJAX_TYPE.GET
  },
  


  

  

  



}