import {
    AJAX_TYPE
} from '../config';
import userUtils from '@/utils/user'
// user service

const prefix = 'user';
// const URL = {
//     requestId: {
//         develop: 'http://i.st15.idf10.com.cn/modules/api/account/common/request/id/get',
//         product: 'http://account.ipo.com/modules/api/account/common/request/id/get',
//         idf10: 'http://xiejian.an2.idf10.cn/modules/api/account/common/request/id/get',
//     },
//     login: {
//         develop: 'http://i.st15.idf10.com.cn/modules/api/account/common/2/extra/login',
//         product: 'http://account.ipo.com/modules/api/account/common/2/extra/login',
//         idf10: 'http://xiejian.an2.idf10.cn/modules/api/account/common/2/extra/login',
//     },
//     imageCode: {
//         develop: 'http://i.st15.idf10.com.cn/modules/api/account/common/image/code/get',
//         product: 'http://account.ipo.com/modules/api/account/common/image/code/get',
//         idf10: 'http://xiejian.an2.idf10.cn/modules/api/account/common/image/code/get',
//     }
// }

// function getUrl(key) {
//     if (window.location.hostname === 'finance.proxy.ipo.com') {
//         return URL[key].product
//     }
//     else if (window.location.hostname === 'fin-platform.an2.idf10.cn') {
//         return URL[key].idf10
//     }
//     else {
//         return URL[key].develop
//     }
// }
export default {

    // 获取requestId
    [`${prefix}RequestId`]: {
        url: `${userUtils.UCUrl}/modules/api/account/common/request/id/get`,
        type: AJAX_TYPE.GET
    },
    // 登录
    [`${prefix}Login`]: {
        url: `${userUtils.UCUrl}/modules/api/account/common/2/extra/login`,
        type: AJAX_TYPE.POST
    },
    // 图形验证码
    [`${prefix}ImageCode`]: {
        url: `${userUtils.UCUrl}/modules/api/account/common/image/code/get`,
        type: AJAX_TYPE.GET
    },


    // web用户登录
    [`${prefix}UmLoginout`]: {
        url: '/web/crm/login/umLoginout',
        type: AJAX_TYPE.GET
    },

    // 添加用户
    [`${prefix}Add`]: {
        url: '/web/user/add',
        type: AJAX_TYPE.POST
    },
    // 修改密码
    [`${prefix}ChangePwd`]: {
        url: '/web/user/changePwd',
        type: AJAX_TYPE.POST
    },
    // 用户删除
    [`${prefix}Delete`]: {
        url: '/web/user/delete/:idCode',
        type: AJAX_TYPE.GET
    },
    // 用户详情
    [`${prefix}Detail`]: {
        url: '/web/user/detail/:idCode',
        type: AJAX_TYPE.GET
    },
    // 用户编辑
    [`${prefix}Edit`]: {
        url: '/web/user/edit',
        type: AJAX_TYPE.POST
    },
    // 忘记密码
    [`${prefix}ForgetPwd`]: {
        url: '/web/user/forgetPwd',
        type: AJAX_TYPE.POST
    },
    // 忘记密码获取短信验证码
    [`${prefix}GetCodeForget`]: {
        url: '/web/user/getCodeForget',
        type: AJAX_TYPE.GET
    },
    // 修改密码获取短信验证码
    [`${prefix}GetCodeModify`]: {
        url: '/web/user/getCodeModify',
        type: AJAX_TYPE.GET
    },
    // 用户列表查询
    [`${prefix}GetList`]: {
        url: '/web/user/getList',
        type: AJAX_TYPE.POST
    },
    // app用户登录
    [`${prefix}LoginForApp`]: {
        url: '/web/user/loginForApp',
        type: AJAX_TYPE.POST
    },
    // web用户登录
    [`${prefix}LoginForWeb`]: {
        url: '/web/user/loginForWeb',
        type: AJAX_TYPE.POST
    },
    // app用户退出
    [`${prefix}LogoutApp`]: {
        url: '/web/user/logoutApp',
        type: AJAX_TYPE.GET
    },
    // web用户退出
    [`${prefix}LogoutWeb`]: {
        url: '/web/user/logoutWeb',
        type: AJAX_TYPE.GET
    },
    // 用户启用关闭:status 0 关闭 1 开启
    [`${prefix}SetStatus`]: {
        url: '/web/user/setStatus/:id',
        type: AJAX_TYPE.GET
    },
    //用户信息
    [`${prefix}Info`]: {
        url: '/web/user/account/info',
        type: AJAX_TYPE.GET
    },




    // web用户登录
    [`${prefix}LoginForCrm`]: {
        url: '/web/crm/login/unifiedLoginForCrm',
        type: AJAX_TYPE.POST
    },

    // 新的用户登录
       [`${prefix}NewLogin`]: {
        url: '/web/crm/login/login',
        type: AJAX_TYPE.POST
    },

    // 针对 用户扫码登录，需要通过cooike来获取用户信息
    [`${prefix}LoginInfo`]: {
        url: '/web/crm/login/getLoginInfo',
        type: AJAX_TYPE.GET
    },

     // 需要针对注销，做一些清理工作
    [`${prefix}LoginOut`]: {
        url: '/web/crm/login/loginOut',
        type: AJAX_TYPE.GET
    },


    // 短信登录，发送验证码
    [`userLoginSendMSG`]: {
        url: 'api/isx/hdf/sso/getVerifyKaptcha/webTicket',
        type: AJAX_TYPE.POST
    },

    // 短信登录
    [`userCrmPhoneLoginTenant`]: {
        url: '/api/isx/hdf/sso/crmPhoneLoginTenant',
        type: AJAX_TYPE.POST
    },

    // 获取平台logo
    [`userGetUserLogo`]: {
        url: '/api/open/v1/tenant/getTenantInfo',
        type: AJAX_TYPE.GET
    },





}
