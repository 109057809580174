import { TAG_DATA_STATUS, BIZ_DATA_STATUS, RENT_STATUS } from './CapitalConfig'

//区域item标签名称和样式名称
const getDataStatus = (dataStatusItem, type) => {
    let className = 'none';
    let tagName = '';

    if (dataStatusItem) {
        let days = dataStatusItem.decription > 99 ? '99+' : dataStatusItem.decription;

        switch (dataStatusItem.code) {
            case TAG_DATA_STATUS.ForbidRent.value:     //不可租
                className = 'not-rent';
                tagName = '不可招商';
                break;
            case TAG_DATA_STATUS.NotRent.value:     //未出租
                break;
            case TAG_DATA_STATUS.Rented.value:     //已出租
                break;
            case TAG_DATA_STATUS.Decorating.value:     //装修中
                className = 'decorating';
                tagName = '装修中';
                break;
            case TAG_DATA_STATUS.Debt.value:     //欠费
                className = 'overdue';
                tagName = `欠${days}天`;
                break;
            case TAG_DATA_STATUS.UnUsed.value:     //空置
                className = 'empty';
                tagName = `空${days}天`;
                break;
            case TAG_DATA_STATUS.Expired.value:     //X天到期
                className = 'expire';
                tagName = `${days}天到期`;
                break;
        }
    }
    return type == 0 ? className : tagName;
}

const getBizStatus = (bizStatus) => {
    let status = '';
    for (let item of BIZ_DATA_STATUS.All) {
        if (bizStatus == item.value) {
            status = item.name;
            break;
        }
    }
    return status;
}

const getRentStatus = (rentStatus) => {
    let className = '';
    switch (rentStatus) {
        case RENT_STATUS.ForbidRent.value:     //不可租
            className = 'border-forbid';
            break;
        case RENT_STATUS.NotRent.value:     //未出租
            className = 'border-no';
            break;
        case RENT_STATUS.Rented.value:     //已出租
            className = 'border-yes';
            break;
    }
    return `${className}  item-col`;
}

const getItemColor = (rentStatus) => {
    return rentStatus == RENT_STATUS.ForbidRent.value ? '#838694' : '#E0E4F1';
}

export {
    getDataStatus,
    getBizStatus,
    getRentStatus,
    getItemColor
}