import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { API } from "@/http/api";
import http from "@/http";
import Table from "CMPT/common/Table";
import { Tree } from "antd";

import "./organizationInfo.less";

const { TreeNode } = Tree;

export default withRouter(function OrganizationInfo({ code, props }) {
  const [currentOrganizationCode, setCurrentOrganizationCode] = useState(null);

  // 分页参数
  const [params, setParams] = useState({
    pageNo: 1, // 页码
    pageSize: 10, // 每页条数
  });

  // 列表数据
  const [tableInfo, setTableInfo] = useState({
    list: [],
    total: 1,
  });

  const [treeData, setTreeData] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParentExpandedKeys] = useState(true);
  const [selectedKeys, setSelectedKeys] = useState([]);

  const columns = [
    {
      title: "员工姓名",
      key: "realName",
      dataIndex: "realName",
    },
    {
      title: "手机号码",
      key: "mobile",
      dataIndex: "mobile",
    },
    {
      title: "部门名称",
      key: "organizationName",
      dataIndex: "organizationName",
    },
    {
      title: "角色名称",
      key: "roleList",
      dataIndex: "roleList",
      render: (text) => {
        return (
          <div>
            {typeof text === "object" &&
              text.map((item, index) => {
                return (
                  <span key={index}>
                    {item.roleName}
                    {index === text.length - 1 ? "" : "、"}
                  </span>
                );
              })}
          </div>
        );
      },
    },
  ];

  // 获取人员列表
  function getList() {
    http({
      api: API.enterpriseGetAdminUserList,
      data: {
        ...params,
        currentOrganizationCode: currentOrganizationCode,
        organizationCompanyCode: currentOrganizationCode ? "" : code,
      },
      showLoading: true,
    }).then((res) => {
      if (res.code === 200) {
        const { datas, total } = res.data;
        setTableInfo({
          list: datas,
          total: +total,
        });
      }
    });
  }

  // 获取组织树
  function queryCategoryTree() {
    http({
      api: API.enterpriseGetOrgTree,
      data: {
        organizationCompanyCode: code,
      },
      showLoading: true,
    }).then((res) => {
      if (res.code === 200) {
        let allLevel = [
          {
            organizationCode: res.data.organizationCode,
            organizationName: res.data.organizationName,
            childrenList: res.data.childrenList,
          },
        ];

        setTreeData(allLevel);
      }
    });
  }

  function onExpand(expandedKeys) {
    // console.log(expandedKeys);
    // this.setState({
    //   expandedKeys,
    //   autoExpandParent: false,
    // });
  }

  function onSelect(selectedKeys) {
    setCurrentOrganizationCode(selectedKeys[0]);
  }

  /**
   * 切换页码或每页条数
   * @param {Number} pageNo 页码
   * @param {Number} pageSize 每页条数
   */
  function changePage(pageNo, pageSize) {
    setParams({ ...params, pageNo, pageSize });
  }

  // 只展示一、二级
  function renderTreeNodes(data) {
    return data.map((item) => {
      if (item.childrenList) {
        return (
          <TreeNode
            title={item.organizationName}
            key={item.organizationCode}
            dataRef={item}
          >
            {renderTreeNodes(item.childrenList)}
          </TreeNode>
        );
      }
      return (
        <TreeNode
          title={item.organizationName}
          key={item.organizationCode}
          dataRef={item}
        />
      );
    });
  }

  useEffect(() => {
    getList();
  }, [params, currentOrganizationCode]);

  useEffect(() => {
    queryCategoryTree();
  }, []);

  return (
    <>
      <div className="userInfo organizationInfo">
        <div className="userInfo_body">
          <div>
            <h2>
              <i></i> 组织人员
            </h2>
          </div>
        </div>

        <div>
          <div className="organizationBox">
            <div className="org_tree">
              <Tree
                onExpand={onExpand}
                // expandedKeys={expandedKeys}
                onSelect={onSelect}
                // selectedKeys={selectedKeys}
              >
                {renderTreeNodes(treeData)}
              </Tree>
            </div>
            <div className="admin_table">
              <Table
                data={tableInfo.list}
                current={params.pageNo}
                pageSize={params.pageSize}
                total={tableInfo.total}
                columns={columns}
                change={changePage}
                scroll={{ x: true }}
                rowKey="identityId"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
