export const CommonFlag = {
  Yes: 1,
  No: 0,
};

//"活动状态 1活动中 2活动结束")
export const PromotionStateFlag = {
  ON_GOING: 1,
  FINISH: 2,
};

/**  活动状态对应的label */
export const PromotionStateLabel = {
  1: "活动中",
  2: "活动结束",
};

/** 活动 开启状态 */
export const PromotionOffStateFlag = {
  OFF: 0,
  OPEN: 1,
};

export const AccountTypeFlag = {
  FORMAL: 1, // 正式
  TRIAL: 0, // 试用
};

// 注意 value和上面的AccountTypeFlag 对应起来
export const AccountTypeMap = {
  1: "正式",
  0: "试用",
};

export const HelpChannel = {
  1: "Web",
  2: "小程序",
  3: "iOS",
  4: "Android",
  //目前暂时不用钉钉  '5':'钉钉'
};

export const GoodsChannel = {
  1: "商城",
  2: "直供",
};

export const PricingType = {
  0: "标准",
  1: "标准/询价",
};

export const PricingSwitch = {
  0: "标准",
  1: "标准/询价",
};

export const FormatSearch = (ss) => {
  if (!ss) {
    return {};
  }

  const ssSplit = ss.split("?")[1].split("&");
  if (!ssSplit || !Array.isArray(ssSplit) || ssSplit.length < 1) {
    return {};
  }

  let obj = {};
  ssSplit.forEach((per) => {
    let perSplit = per.split("=");
    obj[perSplit[0]] = perSplit[1];
  });
  return obj;
};

export const convertEnumToMap = (enumMap, isNeedAll) => {
  var arry = [];

  if (isNeedAll) {
    arry.push({ name: "全部", value: "" });
  }

  for (let key in enumMap) {
    arry.push({ name: enumMap[key], value: key });
  }

  return arry;
};

export const convertEnumToMap2 = (enumMap, isNeedAll) => {
  var arry = [];

  if (isNeedAll) {
    arry.push({ label: "全部", value: "" });
  }

  for (let key in enumMap) {
    arry.push({ label: enumMap[key], value: key });
  }

  return arry;
};

export const convertValueToEnumLabel = (dataArray, enumMap, separator) => {
  if (!dataArray || dataArray.length < 0) {
    return "";
  }

  let sepValue = separator ? separator : ",";

  let result = "";
  dataArray.map((value) => {
    for (let key in enumMap) {
      if (key == value) {
        result += enumMap[key];
        result += sepValue;
        break;
      }
    }
  });

  if (result.lastIndexOf(sepValue) == result.length - 1) {
    result = result.substr(0, result.length - 1);
  }

  //result = result.replace(/sepValue/g, " " + sepValue + ' ')

  return result;
};
