import React, { Component } from 'react';
import { Tooltip } from 'antd';
var clipboardJS = require('clipboard')

new clipboardJS('.copy');
class CopyKey extends Component {

    render() {

        return (
            <div className="copy-key">
                <div className="copy-key-title">
                    {this.props.data.name}
                </div>
                <div className="copy-key-list">
                    {this.props.data.list.map(res=>{
                        return (
                            <CopySingle data={res.value} copyValue={res.key} key={res.key}/>
                        )
                        
                    })}
                </div>
            </div>
        );
    }
}

export default CopyKey;


class CopySingle extends Component {
    
    state = {
        visible: false,
    }

    hide = () => {
        this.setState({
            visible: false,
        });
    }

    handleVisibleChange = (visible) => {
        this.setState({ visible });
        setTimeout(() => {
            this.hide()
        }, 2000);
    }
    render() {

        return (
            
            <Tooltip title="已复制到剪贴板"   trigger="click"
            visible={this.state.visible}
            onVisibleChange={this.handleVisibleChange}
            >
                <span className={"copy" }  data-clipboard-text={this.props.copyValue}>{this.props.data}</span>
            </Tooltip >
                
        );
    }
}

