import Enum from "@/utils/Enum";


global.filterHttpTempData = { };// 用来缓存 筛选框的网络数据。防止一个接口 在同一个页面引用多个相同api对应筛选组件时多次加载

function numberFormat(value){

    return (value || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');

}

//这个方法用来把json配置转成一个数组 用来做select的下拉项
function objectarrayToArray(obj,_name = "name", _value = "value"){
    let keysArray = Object.keys(obj);
    return keysArray.map((item) => {
        let _obj = {};
        _obj[_name] = obj[item];
        _obj[_value] = item;
        return _obj;
    })
}


// 条件筛选 类型
 const FilterType = new Enum(
    { alias: "ENUM_SELECT", text: "枚举类型下拉选择框", value: 1 },
    { alias: "DATE_RANGE", text: "日期区间选择", value: 2 },
    { alias: "INPUT", text: "输入框（不带搜索）", value: 3 },
    { alias: "PCD", text: "省市区(三级查询)", value: 4 },
    { alias: "TIME_RANGE", text: "时间区间选择", value: 5 },
    { alias: "API_SELECT", text: "下拉选择框(Api抓取数据)", value: 6 }
  );

export  {
    numberFormat,FilterType,objectarrayToArray
}
