import React, { Component } from "react";
import { Breadcrumb, Table, Descriptions, Button, Input, message, Modal } from "antd";
import moment from 'moment'
import qs from 'qs'
import { Link } from "react-router-dom";
import { API } from "@/http/api";
import http from "@/http";
import ImgPreview from 'CMPT/common/ImgPreview';

import "./index.less";

const { TextArea } = Input;

export default class RentDetail extends Component {
  state = {
    code: "",
    infoType: "",
    rentDetail: {},
    auditRemark: "",
    imgList : [], 
    currentIndex: 0, 
    showImgPreview: false
  };

  componentWillMount() {
    const match = this.props.match;
    const { infoType } = qs.parse(this.props.location.search.substr(1))
    if (match && match.params && match.params.id) {
      this.setState(
        {
          infoType,
          code: match.params.id,
        },
        () => {
          this.getDetail();
          this.getAuditRecords();
        }
      );
    }
  }

  // 获取详情数据
  getDetail = () => {
    const config = {
      api: API.enterpriseAuthQueryRentDetail,
      data: {
        code: this.state.code,
        infoType: this.state.infoType,
      },
      showLoading: true,
    };
    http(config).then((res) => {
      if (res && res.code === 200) {
        this.setState({
          rentDetail: res.data || {},
        });
      }
    });
  };

  changeAuditRemark = (e) => {
    this.setState({
      auditRemark: e.target.value,
    });
  };

  // 审核
  onVerify = (auditStatus) => {
    if ((auditStatus === "2" || auditStatus === "3") && !this.state.auditRemark) {
      message.error("请输入审核意见");
      return;
    }
    let param = {
      auditStatus: auditStatus,
      code: this.state.code,
    };
    if (auditStatus === "2" || auditStatus === "3") {
      param.auditRemark = this.state.auditRemark;
    }
    const config = {
      api: API.enterpriseAuthQueryRentOperate,
      data: param,
      showLoading: true,
    };
    http(config).then((res) => {
      if (res && res.code === 200) {
        message.success(auditStatus === "2" ? "已驳回" : auditStatus === "3" ? "已强制下架" : "已通过");
        this.props.history.push(`/app/crm/sourcing?tab=${this.state.infoType == 1 ? 5 : 6}`);
      }
    });
  };

  // 获取审批记录
  getAuditRecords = () => {
      let that = this;
      const config = {
          api: API.enterpriseAuthVehicleAuditRecord,
          path: {
              code: this.state.code
          },
          showLoading: true
      }
      http(config).then(res => {
          if (res && res.code == 200) {
              let auditRecords = res.data || []
              that.setState({
                  auditRecords
              })
          }
      })
  }

  watchRecord = () => {
      const { auditRecords } = this.state;

      const recordContent = auditRecords && auditRecords.length > 0 ? auditRecords.map(item => {
          return <div className="record_item">
              <div className="status">{item.auditStatusName}</div>
              {item.auditRemark ? <div className="info_item">
                  <span>理由：</span>
                  <span>{item.auditRemark}</span>
              </div> : ''}
              <div className="info_item">
                  <span>审批人：</span>
                  <span>{item.editUserName}</span>
              </div>
              <div className="info_item">
                  <span>时间：</span>
                  <span>{moment(item.editDate).format('YYYY-MM-DD HH:mm:ss')}</span>
              </div>
          </div>
      }) :
          <div className='log-no-data'><div className="log-no-data-text">暂无数据</div></div>;

      const recordHtml = <div className="sp_records">{recordContent}</div>;

      Modal.info({
          title: '审批记录',
          icon: null,
          content: recordHtml
      });
  }

  imageShow = (imgList, currentIndex) => {
    this.setState({
      imgList,
      currentIndex,
      showImgPreview: true
    })
  }
  closeImgPreview= () => {
    this.setState({
      showImgPreview: false
    })
  }

  render() {
    let { rentDetail, auditRemark, infoType } = this.state;
    console.log(infoType, 'infoType')
    return (
      <div className="sourcing-detail-page">
        <div>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={{ pathname: "/app/crm/sourcing", search: `?tab=${infoType == 1 ? 5 : 6}` }}>
                信息审核
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{infoType == 1 ? '出租信息' : '求租信息'}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="sourcing-content">
          {rentDetail.equipmentDetailVo && (
            <Descriptions bordered title="设备详情" className="table-part">
              <Descriptions.Item label="设备编号">
                {rentDetail.equipmentDetailVo.equipmentNumber || "-"}
              </Descriptions.Item>
              <Descriptions.Item label="申请编号">
                {rentDetail.code || "-"}
              </Descriptions.Item>
              <Descriptions.Item label="设备类型">{`${rentDetail.equipmentDetailVo.primaryTypeName} / ${rentDetail.equipmentDetailVo.secondaryTypeName}`}</Descriptions.Item>
              <Descriptions.Item label="出租名称">
                {rentDetail.equipmentDetailVo.equipmentName || "-"}
              </Descriptions.Item>
              <Descriptions.Item label="品牌/型号">
                {rentDetail.equipmentDetailVo.brandName? rentDetail.equipmentDetailVo.brandName +'/'+rentDetail.equipmentDetailVo.modelName : "-"}
              </Descriptions.Item>
              <Descriptions.Item label="出厂日期">
                {rentDetail.equipmentDetailVo.dateOfProduction || "-"}
              </Descriptions.Item>
              <Descriptions.Item label="设备所在地">
                {`${rentDetail.equipmentDetailVo.provinceName}/${rentDetail.equipmentDetailVo.cityName}/${rentDetail.equipmentDetailVo.areaName}` ||
                  "-"}
              </Descriptions.Item>
              <Descriptions.Item label="期望价格">
                {rentDetail.equipmentDetailVo.expectedPriceType == 1? (rentDetail.equipmentDetailVo.price || "-"): '面议'}
              </Descriptions.Item>
              <Descriptions.Item label="设备简介">
                {rentDetail.equipmentDetailVo.remark || "-"}
              </Descriptions.Item>
              <Descriptions.Item label="设备图片" span={3}>
                {rentDetail.equipmentDetailVo.imageList.map((item, index) => {
                  return <img key={index} src={item} alt="" onClick={this.imageShow.bind(this,rentDetail.equipmentDetailVo.imageList, index)} className="big_img_shebei"/>;
                })}
              </Descriptions.Item>
              <Descriptions.Item label="设备联系人" span={3}>
                {rentDetail.equipmentDetailVo.leaseContact}
              </Descriptions.Item>
              <Descriptions.Item label="手机号" span={3}>
                {rentDetail.equipmentDetailVo.leaseMobile}
              </Descriptions.Item>
              <Descriptions.Item label="提交时间" span={3}>
                {rentDetail.submitTime}
              </Descriptions.Item>
              <Descriptions.Item label="提交人账号" span={3}>
                {rentDetail.submitterId}
              </Descriptions.Item>
              <Descriptions.Item label="审核意见" span={3}>
                <TextArea
                  onChange={this.changeAuditRemark}
                  style={{ width: "100%", padding: "20px" }}
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                ></TextArea>
              </Descriptions.Item>
            </Descriptions>
          )}

          {
            rentDetail.restDemandDetail && (
              <Descriptions bordered title="求租详情" className="table-part">
                <Descriptions.Item label="状态">
                  {rentDetail.restDemandDetail.releaseStatusName || "-"}
                </Descriptions.Item>
                <Descriptions.Item label="联系人">
                  {(rentDetail.restDemandDetail.contactsName || '-') + (rentDetail.restDemandDetail.contactsName?'/':'') + (rentDetail.restDemandDetail.contactsPhone || '-')}
                </Descriptions.Item>
                <Descriptions.Item label="所属企业">
                  {rentDetail.restDemandDetail.supplierName || "-"}
                </Descriptions.Item>
                <Descriptions.Item label="需求信息" span={3}>
                  {
                    rentDetail.restDemandDetail.brandEquipmentTypeList.map(item => (
                      <p>类型：{item.primaryTypeName}/{item.secondaryTypeName}，品牌：{item.brandList.map(item => item.brandName + '/' + item.modelName).join('、')}，台数：{item.demandQuantity}</p>
                    ))
                  }
                </Descriptions.Item>
                
                {/* <Descriptions.Item label="设备类型">
                {`${rentDetail.restDemandDetail.primaryTypeName} / ${rentDetail.restDemandDetail.secondaryTypeName}`}
                </Descriptions.Item>
                <Descriptions.Item label="品牌/型号（最多选3个）">
                {rentDetail.restDemandDetail.brandList.map((item, index) => {
                  return <p>{item.brandName+ ' / '+item.modelName}</p>;
                })}
                </Descriptions.Item>
                <Descriptions.Item label="台数">
                  {rentDetail.restDemandDetail.demandQuantity || "-"}
                </Descriptions.Item> */}
                <Descriptions.Item label="结算方式">
                  { rentDetail.restDemandDetail.paymentMethodName || "-" }
                </Descriptions.Item>
                <Descriptions.Item label="租用日期">
                  {moment(rentDetail.restDemandDetail.rentDateStart).format('YYYY-MM-DD') + '~' + moment(rentDetail.restDemandDetail.rentDateEnd).format('YYYY-MM-DD') || "-"}
                </Descriptions.Item>
                <Descriptions.Item label="施工地">
                {`${rentDetail.restDemandDetail.provinceName}/${rentDetail.restDemandDetail.cityName}/${rentDetail.restDemandDetail.areaName}` || "-" }
                </Descriptions.Item>
                <Descriptions.Item label="项目名称">
                  {rentDetail.restDemandDetail.projectName || "-"}
                </Descriptions.Item>
                <Descriptions.Item label="提交人账号"  >
                  {rentDetail.submitterId || "-"}
                </Descriptions.Item>
                <Descriptions.Item label="提交日期">
                  {rentDetail.submitTime || "-"}
                </Descriptions.Item>
                <Descriptions.Item label="求租简介" span={3}>
                  {rentDetail.restDemandDetail.remark || "-"}
                </Descriptions.Item>
           
                <Descriptions.Item label="审核意见" span={3}>
                  <TextArea
                    defaultValue={rentDetail.auditRemark}
                    onChange={this.changeAuditRemark}
                    style={{ width: "100%", padding: "20px" }}
                    name=""
                    id=""
                    cols="30"
                    rows="10"
                  ></TextArea>
                </Descriptions.Item>
              </Descriptions>
              
            )
          }
          {
            rentDetail.auditStatus !== 0 ? 
            <div className="approval-record" onClick={this.watchRecord}>审批记录</div>
            : null
          }
        </div>
        <div className="action-part">
          {rentDetail.auditStatus === 0 && (
            <Button
              className="action-button-active"
              onClick={() => {
                this.onVerify("1");
              }}
            >
              审核通过
            </Button>
          )}
          {rentDetail.auditStatus === 0 && (
            <Button
              className="action-button-active"
              onClick={() => {
                this.onVerify("2");
              }}
            >
              审核驳回
            </Button>
          )}
          {rentDetail.auditStatus === 1 && (
            <Button
              className="action-button-active"
              onClick={() => {
                this.onVerify("3");
              }}
            >
              强制下架
            </Button>
          )}
          <Button
            className="action-button-active"
            onClick={this.onTapForceOffShelves}
          >
            <Link to={{ pathname: "/app/crm/sourcing", search: `?tab=${infoType == 1 ? 5 : 6}` }}>
              返回
            </Link>
          </Button>
        </div>
        <ImgPreview show={this.state.showImgPreview} current={this.state.currentIndex} imgList={this.state.imgList} onClose={this.closeImgPreview}/>
      </div>
    );
  }
}
