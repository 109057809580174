import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { message } from 'antd';
import Table from 'CMPT/common/Table';
import AuthContainer from 'CMPT/common/auth/AuthContainer';
import http from '@/http';
import { API } from '@/http/api';
import { AUTH_KEYS } from '@/utils/auth';
import userUtil from '@/utils/user';

import { Icon, Menu, Dropdown  } from 'antd';
class RXDetail extends Component {
    state = {
        columns: [
            {
                title: '账单/费用类型',
                dataIndex:'feeTypeStr',
                render(value){
                    return (
                        <div>
                            <span className="bill-type" style={{background:'#434BA3'}}>合同</span>
                            <span className="fee-type">{value}</span>
                        </div>
                    )
                }
            }, {
                title: '应收/付金额(元)',
                dataIndex: 'payAmount', // 收：E94437 支：05C985
                render: (value, row) => {
                    if (row.budgetType === 1) {
                        return <span style={{color: '#E94437'}}>+{value}</span>
                    }
                    return <span style={{color: '#05C985'}}>-{value}</span>
                }
            }, {
                title: '应收/付时间',
                dataIndex: 'payDate',
                render: (value) =>  value || '-'
            }, {
                title: '实收/付时间',
                dataIndex: 'paidDate',
                render: (value) =>  value || '-'
            },{
                title: '费用周期',
                render(value){
                    if(value.beginDate && value.endDate){
                        return (
                            <div>{value.beginDate}至{value.endDate}</div>
                        )
                    }else{
                        return '-'
                    }                   
                }
            }, {
                title: '收/付款人',
                dataIndex: 'customerName',
                
            }, {
                title: '账单状态',
                dataIndex: 'auditStatus',
                render(value){
                    const auditStatusList = ['审批中','审批驳回','审批通过'];
                    return (
                        <div>{auditStatusList[value]}</div>
                    )
                }
                
            },{
                title: '收/付款状态',
                dataIndex: 'paymentReceiveAuditStatus',
                render:(value, row)=>{
                    const payList = ['已结清','待付款','一笔付款被驳回','一笔付款审批中'];
                    const receiveList = ['已结清','待收款','一笔收款被驳回','一笔收款审批中']
                    if (row.budgetType === 1) {
                        return <span>{receiveList[value - 1]}</span>
                    }
                    return <span>{payList[value-1]}</span>
                }
                
            }, {
                title: '创建时间',
                dataIndex: 'createTime',
                
            }, {
                title: '创建人',
                dataIndex: 'createUserName',
                
            }, 
            {
                title: '操作',
                render:(value, row)=>{
                    if (row.status === 0) {
                        return <AuthContainer authCodes={AUTH_KEYS.OPERATE_FINANCE_BILL_VIEW}>
                                    <Link to={'/app/operate/finance/bill/detail/' + row.key + '?type=' + row.budgetType} className="table-operate-btn">详情</Link>
                               </AuthContainer>
                    }
                    return ''
                }
            }
        ],
        data: [],
        typeList: [
            { type: 1, name: '租赁收付' },
            { type: 2, name: '物业收付' },
            { type: 0, name: '其他收付' }
        ],
        currentType: 1,
        proCode: '',
        cuCode: '',
        tName: ''
    }
    componentDidMount() {
        this.getRelatedContract(this.props.info.contractCode)
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.info !== this.props.info) {
            this.getRelatedContract(nextProps.info.contractCode)
        }
    }
    getRelatedContract = (code) => {
        if(!userUtil.hasAuth(AUTH_KEYS.OPERATE_FINANCE_BILL_VIEW)) {
            message.error('您无查看权限')
            return
        }
        const { info } = this.props
        this.setState({
            proCode: info.projectEncode,
            cuCode: info.customerEncode,
            tName: encodeURI(info.customerName)
        })
        const config = {
            api: API.contractBillList,
            data: {
                bizEncode: code,
                bizTypeSub: this.state.currentType
            },
            showLoading: true
        }
        http(config).then(res => {
            let list = res.data && res.data.map(item => {
                item.key = item.encode
                return item
            }) || []

            this.setState({
                data: list
            })
        })
    }
    changeCurrentType = (type, e) => {
        this.setState({
            currentType: type
        }, () => {
            this.getRelatedContract(this.props.info.contractCode)
        })
    }
    render() {
        const { cuCode, proCode, tName } = this.state
        const { contractCode, status } = this.props.info
        const menu = (
            <Menu className="cx-menu-class">
              <Menu.Item>
                  <Link to={`/app/operate/finance/bill/add?type=1&coCode=${contractCode}&cuCode=${cuCode}&proCode=${proCode}&tName=${tName}`}>收款账单</Link>
              </Menu.Item>
              <Menu.Item>
                <Link to={`/app/operate/finance/bill/add?type=2&coCode=${contractCode}&cuCode=${cuCode}&proCode=${proCode}&tName=${tName}`}>付款账单</Link>
              </Menu.Item>
            </Menu>
        );
        return (
            <div className="rxdetail contract-info">
               <div className="rxdetail-header">
                    <div className="rxdetail-header-list">
                        {this.state.typeList.map(per => {
                            return <div key={per.type} className={this.state.currentType === per.type ? 'rxdetail-header-item active' : 'rxdetail-header-item'}
                                        onClick={this.changeCurrentType.bind(this, per.type)}>
                                    {per.name}</div>
                        })}
                    </div>
                    <div className="rxdetail-header-add-item" style={{display: [20, 30, 50].indexOf(status) !== -1 ? 'block' : 'none'}}>
                        <AuthContainer authCodes={AUTH_KEYS.OPERATE_FINANCE_BILL_ADD}>
                            <Dropdown overlay={menu} placement="bottomRight">
                                <span><Icon type="plus-circle" theme="filled" />添加其他收付</span>
                            </Dropdown>
                        </AuthContainer>
                        
                    </div>
               </div>
               <div className="rxdetail-table">
                    <Table pagination={false} columns={this.state.columns} selfProcessData={this.state.data} />
               </div>
            </div>
        );
    }
}

export default RXDetail;