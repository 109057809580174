import React, { Component } from 'react';
import { Breadcrumb, Modal, Form, Radio, message } from 'antd';
import Table from 'CMPT/common/Table';
import http from '@/http';
import { API } from '@/http/api';
import { Link } from 'react-router-dom';
import moment from 'moment';

import './ChannelNavList.less';

const BreadcrumbItem = Breadcrumb.Item;

class EnterpriseAuthLists extends Component {
    state = {
        total:0,
        navigationConfig: {
            navigationStyle: 0,
            showFlag: 1
        },
        navModalShow: false,
        columns: [
            {
                title: '频道名称',
                dataIndex: 'channelName',
                key: 'channelName',
            },{
                title: '操作人',
                dataIndex: 'operationUser',
                key: 'operationUser',
            },  
            {
                title: '操作日期',
                dataIndex: 'operationTime',
                key: 'operationTime',
                render: (text, record) => {
                    return (<span>{moment(text).format('YYYY-MM-DD')}</span>);
                }
            }, {
                title: '频道描述',
                dataIndex: 'channelDesc',
                key: 'channelDesc',
            }, {
                title: '操作',
                key: 'operate',
                render: (text, record, index) => {
                    return (<span className="table-operate-btn">
                        <Link to={'/app/crm/category/channelNavigation/detail?type=' + record.typeValue + '&id=' + record.id} className="operate">查看详情</Link>
                        {record.typeValue == 19 ? <span className='operate' style={{marginLeft: '6px'}} onClick={this.setNavStyle} >设置样式</span> : null}
                    </span>)
                }
            }
        ],
        data: []
    }
    //初始化
    componentDidMount() {
        this.getList();
        // this.GetNavigationConfig();
    }

    setNavStyle = () => {
        this.GetNavigationConfig();
        this.setState({
            navModalShow: true
        })
    }

    //查询列表
    getList() {
        const config = {
            api: API.channelNavList,
            data: {},
            showLoading: true
        };
        http(config).then(res => {
            if(res.success){
                this.setState({
                    data: res.data || [],
                })
            }
           
        })
    }
    // 获取导航设置
    GetNavigationConfig() {
        const config = {
            api: API.channelNavGetNavigationConfig,
            showLoading: true
        };
        http(config).then(res => {
            console.log(res);
            if(res.success){
                this.setState ({
                    navigationConfig: {
                        navigationStyle: res.data.navigationStyle,
                        showFlag: res.data.showFlag ? 1 : 0
                    }
                })
            }
           
        })
    }
    // 确认，设置导航
    onOkModal =()=> {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log(values, 'values ');
                const config = {
                    api: API.channelNavUpdateNavigationConfig,
                    data: {
                        navigationStyle: values.navigationStyle,
                        showFlag: values.showFlag ? true: false
                    },
                    showLoading: true
                };
                http(config).then(res => {
                    if(res.success){
                        message.success('设置成功')
                        this.setState({
                            navModalShow: false
                        })
                    }
                    
                })
            }
        })
    }

    render() {
        const { navigationConfig, navModalShow } = this.state;
        const { getFieldDecorator } = this.props.form;
        return (
            <div className="channel-nav-con">
                <div className='channel-nav-header'>
                    <Breadcrumb>
                        <BreadcrumbItem>商品类目</BreadcrumbItem>
                        <BreadcrumbItem>频道导航</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <div className="channel-nav-list">
                    <Table 
                        rowKey="typeValue" 
                        columns={this.state.columns} 
                        selfProcessData={this.state.data}
                        total={this.state.total} 
                    />
                </div>
                <Modal
                    visible={navModalShow}
                    closable={false}
                    maskClosable={false}
                    width={600}
                    title="设置类目展示"
                    onCancel={() => {
                        this.setState({ navModalShow: false })
                    }}
                    onOk={this.onOkModal}
                >
                    <Form className='set_form'
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 16 }}>
                        <Form.Item label="类目样式">
                            {getFieldDecorator("navigationStyle", {
                                initialValue: navigationConfig.navigationStyle,
                                rules: [
                                    { required: true },
                                  ]
                            })(
                                <Radio.Group className='radio_box'>
                                    <Radio value={0} className="radio_item">
                                        <span>单行展示</span><br/>
                                        <img className='img' src={require('../../images/set/sec_old.png')} alt="" />
                                    </Radio>
                                    <Radio value={1} className="radio_item">
                                        <span>主副展示</span><br/>
                                        <img className='img' src={require('../../images/set/sec_new.png')} alt="" />
                                    </Radio>
                                </Radio.Group>
                            )}
                            
                        </Form.Item>
                        <Form.Item label="是否展开">
                            {getFieldDecorator("showFlag", {
                                initialValue: navigationConfig.showFlag,
                                rules: [
                                    { required: true },
                                  ]
                            })(
                                <Radio.Group className='radio_box'>
                                    <Radio value={1} className="radio_item">是</Radio>
                                    <Radio value={0} className="radio_item">否</Radio>
                                </Radio.Group>
                            )}
                            
                        </Form.Item>
                    </Form>
                </Modal>
            </div>


        );
    }
}
export default Form.create({ name: "EnterpriseAuthLists" })(EnterpriseAuthLists);
