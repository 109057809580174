import React, { Component } from 'react';
import { Tooltip, Modal } from 'antd';
import './clickimg.less'

export default class ClickImg extends Component{
    constructor(props) {
        super(props)
    }

    showImgmodal(src) {
        Modal.confirm({
            className: 'cardlist-showImgmodal',
            content: <img src={src} alt="example"/>,
            maskClosable: true
        })
    }

    render() {
        const { cardlist } = this.props;

        return (
            <div>
                {
                    cardlist.map((item,key) =>
                        <div {...this.props} key={key}>
                            <Tooltip title="点击可预览大图">
                                <img   src={item}
                                       alt="example"
                                       onClick={this.showImgmodal.bind(this,item)}>
                                </img>
                            </Tooltip>
                        </div>)
                }
            </div>
        )
    }
}
