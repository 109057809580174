import React, { Component } from "react";
import Table from 'CMPT/common/Table';
import http from '@/http';
import { API } from '@/http/api';
import AddSupplierModal from './AddSupplierModal';
import Select from 'CMPT/common/Select';
import { showErrorMessage, } from '@/utils/globalMessageReminder';
import { Input, Breadcrumb, Tooltip, Modal, Button } from "antd";
import { CommonFlag } from "CMPT/common.js";
import "./ShopList.less"
const BreadcrumbItem = Breadcrumb.Item;
const { TextArea } = Input;

class ShopList extends React.Component {
    state = {
        code: null,// 活动code
        promotionName: null,
        selectParams: {
            pageParams: {  // 接口入参，必须放这里。方便分页时保存信息
                pageNo: 1, // 需要查询的页数
                pageSize: 10, // 每页条数
                totalRecord: 0, // 当前数据的总数
                totalPage: 0,// 页的数量
            },
            shopName: '',//防止null 
            zige: '' //默认 全部
        },


        oldSearchValue: {
            shopName: '',
            zige: '' //默认 全部
        },//把旧的搜索条件
        zigeLabelValue: [{ name: '全部', value: '' }, { name: '正常', value: '1' }, { name: '已关停', value: '2' }],

        columns: this.createColumns(), // table行列 配置

        willOpenShop: null,//准备开启的店铺。  有则展示Modal，无则隐藏Modal
        willCloseShop: null,//准备关闭的店铺。  有则展示Modal，无则隐藏Modal

        closeInput: null,//关停资格的原因。

        willRemoveGoods: null,//要被移除的商品。有则展示Modal，无则隐藏Modal
        willRemoveSupplier: null,//要被移除的供应商。有则展示Modal，无则隐藏Modal
        willAddSupplier: false,

        data: [] // null, 现在无后端数据，暂时mock
    };


    componentWillMount() {
        this.state.code = this.props.code;
        this.state.promotionName = this.props.name;
    }



    createColumns() {
        return [{
            title: '序号',
            key: "index",
            render: (text, record, index) => <span>{index + 1}</span>
        }, {
            title: '供应商名称',
            dataIndex: 'companyName',
            key: 'companyName',
            render: (text, record) => {
                let value;
                if (text && text.length > 20) {
                    value = <Tooltip placement="top" title={text}>{text.slice(0, 20)}...</Tooltip>
                } else {
                    value = text
                }
                return (<span>{value ? value : '-'}</span>)
            }
        }, {
            title: '企业简称',
            dataIndex: 'shortName',
            key: 'shortName',
            render: (text, record) => {
                let value;
                if (text && text.length > 20) {
                    value = <Tooltip placement="top" title={text}>{text.slice(0, 20)}...</Tooltip>
                } else {
                    value = text
                }
                return (<span>{value ? value : '-'}</span>)
            }
        }, {
            title: '店铺名称',
            dataIndex: 'shopName',
            key: 'shopName',
            render: (text, record) => {
                let value;
                if (text && text.length > 20) {
                    value = <Tooltip placement="top" title={text}>{text.slice(0, 20)}...</Tooltip>
                } else {
                    value = text
                }
                return (<span>{value ? value : '-'}</span>)
            }
        },
        {
            title: '参与商品数',
            dataIndex: 'number',
            key: 'number',
            render: (value) => <span>{value||'0'}</span>
        }, {
            title: '活动资格',
            dataIndex: 'status',
            key: 'status',
            render: (value, record, index) => {
                return (value == CommonFlag.Yes ? <span>正常</span> : <span style={{ color: 'red' }}>已关停</span>)
            }
        }, {
            title: '操作',
            key: 'operate',
            render: (text, record, index) => {
                return (
                    <>
                        {(record.status != CommonFlag.Yes && record.number > 0) ? <span className="table-operate-btn" onClick={this.onRemoveGoods.bind(this, record)}>移除活动商品</span> : ''}
                        {(record.status != CommonFlag.Yes && !record.number) ? <span className="table-operate-btn" onClick={this.onRemoveSupplier.bind(this, record)}>移除供应商</span> : ''}

                        <span className="table-operate-btn" onClick={this.onClickShop.bind(this, record)}>{record.status == CommonFlag.Yes ? '关停' : '开启'}活动资格</span>
                    </>)
            }
        }
        ];
    }

    onRefresh = () => {
        this.getShopList();
    }

    getShopList() {
        if (!this.state.selectParams.shopName) {
            this.state.selectParams.shopName = '';
        }

        // 判断页码是否要变更，并存储起来，下次一起判断
        var oldSearchValue = this.state.oldSearchValue;
        if (oldSearchValue.shopName != this.state.selectParams.shopName
            || oldSearchValue.zige != this.state.selectParams.zige) {
            oldSearchValue.shopName = this.state.selectParams.shopName;
            oldSearchValue.zige = this.state.selectParams.zige;
            this.state.selectParams.pageParams.pageNo = 1;
        }

        //  入参
        let dataParams = {
            code: this.state.code,
            pageNo: this.state.selectParams.pageParams.pageNo,
            pageSize: this.state.selectParams.pageParams.pageSize,
            shopName: this.state.selectParams.shopName,
            status: this.state.selectParams.zige,
        };

        // 接口配置
        const config = {
            api: API.promotionShopList,
            data: dataParams,
            showLoading: true
        };



        // 接口调用
        http(config).then(res => {
            if (res && res.success && res.data) {
                this.state.selectParams.pageParams.pageNo = res.data.pageNo;
                this.state.selectParams.pageParams.pageSize = res.data.pageSize;
                this.state.selectParams.pageParams.totalRecord = res.data.totalRecord;
                this.state.selectParams.pageParams.totalPage = res.data.totalPage;

                this.setState({
                    data: res.data.datas,
                });
            }

        });

    }
    componentDidMount() {
        this.getShopList();
    }

    onRemoveGoods = (item) => {
        this.setState({
            willRemoveGoods: item
        });
    }

    closeRemoveGoods = () => {
        this.setState({
            willRemoveGoods: null
        });
    }
    cancelRemoveGoods = () => {
        this.setState({
            willRemoveGoods: null
        });
    }

    onRemoveSupplier = (item) => {
        this.setState({
            willRemoveSupplier: item
        });
    }

    closeRemoveSupplier = () => {
        this.setState({
            willRemoveSupplier: null
        });
    }

    cancelRemoveSupplier = () => {
        this.setState({
            willRemoveSupplier: null
        });
    }

    onAddSupplier() {
        this.setState({
            willAddSupplier: true
        });
    }

    closeAddSupplier = () => {
        this.setState({
            willAddSupplier: false
        });
    }

    cancelAddSupplier = () => {
        this.setState({
            willAddSupplier: false
        });
    }


    onClickShop(item) {
        if (item.status == CommonFlag.Yes) {
            this.setState({
                willCloseShop: item,
                willOpenShop: null,
                closeInput: null
            });
            return;
        } else {
            this.setState({
                willOpenShop: item,
                willCloseShop: null,
                closeInput: null
            });
            return;
        }
    }

    gotoHttpClose() {
        this.doHttpOpenOff({
            code: this.state.willCloseShop.code,
            status: 2, // 状态 1正常 2已关停
            offReasons: this.state.closeInput
        });
    }

    gotoHttpOpen() {
        this.doHttpOpenOff({
            code: this.state.willOpenShop.code,
            status: 1 // 状态 1正常 2已关停
        });

    }

    doHttpOpenOff(dataParams) {
        let my = this;

        const config = {
            api: API.promotionOpenOff,
            data: dataParams,
            showLoading: true
        };

        http(config).then(res => {
            if (res && res.success) {
                my.closeOpenOffModal();
                my.getShopList();
            }

        })
    }

    onCloseInput = (e) => {
        this.state.closeInput = e.target.value;
    };

    createOpenModal() {
        return <Modal
            title="取消关停"
            visible={true}
            okText='确定'
            closable={false}
            maskClosable={false}
            onOk={this.gotoHttpOpen.bind(this)}
            width={400}
            onCancel={this.closeOpenOffModal.bind(this)}  >
            <p>是否确认取消关停?</p>
        </Modal>;
    }


    closeOpenOffModal() {
        this.setState({
            willOpenShop: null,
            willCloseShop: null,
            closeInput: null
        });
    }

    createOffInputModal() {
        return <Modal
            title="关停活动资格"
            visible={true}
            okText='确定'
            cancelText='取消'
            closable={false}
            maskClosable={false}
            onOk={this.readyToClose.bind(this)}
            onCancel={this.closeOpenOffModal.bind(this)}
            width={400} >
            <TextArea rows={4} onChange={this.onCloseInput} placeholder='请输入关停理由 （100字以内）' maxLength={100} />
        </Modal>;
    }

    createAddSupplierModal = () => {
        return <AddSupplierModal
            onClose={this.closeAddSupplier}
            onCancel={this.cancelAddSupplier}
            onRefresh={this.onRefresh}
            promotionCode={this.state.code}
            promotionName={this.state.promotionName} />;
    }
    createRemoveGoodsModal() {
        let item = this.state.willRemoveGoods;

        return <Modal
            title="移除活动商品"
            visible={true}
            okText='确定移除'
            cancelText='取消'
            closable={false}
            maskClosable={false}
            onOk={this.gotoDelGoods.bind(this)}
            onCancel={this.cancelRemoveGoods.bind(this)}
            width={400} >
            <span>{'当前供应商' + "\"" + item.shortName + "\"共" + item.number + "个商品参与活动，是否确定移除(移除商品后可进一步将供应商从活动中移除)"}</span>
        </Modal>;
    }

    createRemoveSupplierModal() {
        let item = this.state.willRemoveSupplier;

        return <Modal
            title="移除供应商"
            visible={true}
            okText='确定'
            cancelText='取消'
            closable={false}
            maskClosable={false}
            onOk={this.gotoDelSupplier.bind(this)}
            onCancel={this.cancelRemoveSupplier.bind(this)}
            width={400} >
            <span>{"是否确定将供应商" + "\"" + item.shortName + "\"从活动\"" + this.state.promotionName + "\"中移除"}</span>
        </Modal>;
    }

    readyToClose() {
        if (!this.state.closeInput) {
            showErrorMessage("请输入关停原因");
            return;
        }

        this.gotoHttpClose();
    }

    gotoDelGoods() {
        const config = {
            api: API.promotionRemoveItems,
            data: { code: this.state.willRemoveGoods.code },
            showLoading: true
        };

        http(config).then(res => {
            if (res && res.success) {
                this.closeRemoveGoods();
                this.onRefresh();
            }

        })
    }

    gotoDelSupplier() {
        const config = {
            api: API.promotionRemoveSupplier,
            data: { code: this.state.willRemoveSupplier.code },
            showLoading: true
        };

        http(config).then(res => {
            if (res && res.success) {
                this.closeRemoveSupplier();
                this.onRefresh();
            }

        })
    }


    onChangePage = (pageNo, pageSize, sort) => {
        this.state.selectParams.pageParams.pageNo = pageNo;
        this.state.selectParams.pageParams.pageSize = pageSize;
        this.getShopList();
    }

    onChangeZigeStatus = attr => {
        this.state.selectParams.zige = attr;
    }

    onChangeShopName = e => {
        const { value } = e.target;
        this.state.selectParams.shopName = value;
    }


    render() {
        let my = this;
        const myState = this.state;

        return (<div className="staff-site-shop-all">

            <div className='staff-site-shop-list'>
                <div className="staff-search">
                    <span>
                        <Select label='活动资格' data={myState.zigeLabelValue} defaultValue={myState.selectParams.zige} selectMethod={this.onChangeZigeStatus.bind(this)}
                            style={{ width: '120px', marginRight: '5px' }} />


                        <Input placeholder="请输入店铺名称/供应商名称/企业简称" onChange={this.onChangeShopName}
                            style={{ width: '280px' }} />


                        <Button type="primary" style={{ marginRight: '5px' }} onClick={this.getShopList.bind(this)}>搜索</Button>
                    </span>
                    {this.props.supplierLimitFlag?<span>
                        <Button type="primary" style={{ marginRight: '25px' }} onClick={this.onAddSupplier.bind(this)}>添加供应商</Button>
                    </span>:''}
                </div>


                <Table rowKey="code" columns={myState.columns}
                    selfProcessData={myState.data}
                    total={myState.selectParams.pageParams.totalRecord}
                    change={this.onChangePage}
                    pageSize={myState.selectParams.pageParams.pageSize}
                    current={myState.selectParams.pageParams.pageNo} />

                {myState.willOpenShop ? my.createOpenModal() : ''}
                {myState.willCloseShop ? my.createOffInputModal() : ''}
                {myState.willRemoveSupplier ? my.createRemoveSupplierModal() : ''}
                {myState.willRemoveGoods ? my.createRemoveGoodsModal() : ''}
                {myState.willAddSupplier ? my.createAddSupplierModal() : ""}
            </div>

        </div>);
    }
}


export default ShopList;

