import { AJAX_TYPE } from "../config";

// 公告

const prefix = "banner";

export default {
  [`${prefix}List`]: {
    url: "/web/crm/banner/list",
    type: AJAX_TYPE.GET
  },
  [`${prefix}Add`]: {
    url: "/web/crm/banner/add",
    type: AJAX_TYPE.POST
  },
  [`${prefix}Edit`]: {
    url: "/web/crm/banner/edit/:encode",
    type: AJAX_TYPE.POST
  },
  [`${prefix}Del`]: {
    url: "/web/crm/banner/delete/:encode",
    type: AJAX_TYPE.DELETE
  },
  [`${prefix}OpenClose`]: {
    url: "/web/crm/banner/openOrClose/:encode",
    type: AJAX_TYPE.POST
  },
  [`${prefix}Sort`]: {
    url: "/web/crm/banner/sort",
    type: AJAX_TYPE.POST
  }, 
  [`${prefix}Position`]: {
    url: "/web/crm/banner/getPosition/:source",
    type: AJAX_TYPE.Get
  },
  [`${prefix}PositionSort`]: {
    url: "/web/crm/banner/getPositionSort/:source",
    type: AJAX_TYPE.Get
  },
};
