/**
 * 发送埋点
 * @param {*} type DOM事件类型
 * @param {*} name 埋点事件名称
 * @param {*} params 事件额外参数
 */
export default function sendEventPoint(type, name, params) {
    $$_SS('发送埋点', type, name, params)
}

/**
 * 埋点使用的DOM事件类型
 */
export const EVENT_TYPES = {
    LOAD: 'load',
    CLICK: 'click',
}

/**
 * 埋点事件名称
 */
export const EVENT_NAMES = {
    PAGE_LOAD: 'PAGE_LOAD',
}