import { AJAX_TYPE } from "../config";

// 公告

const prefix = "invoice";

export default {
  /**
   * 开通列表
   */
  [`${prefix}List`]: {
    url: "/api/isx/v1/shipped/openInvoicingList",
    type: AJAX_TYPE.POST
  },
  /**
   * 网货详情
   */
  [`${prefix}Detail`]: {
    url: '/api/isx/v1/shipped/openInvoicingDetail/:code',
    type: AJAX_TYPE.GET
   }, 
   /**
    * 根据名称查询运输方企业信息
    */
   [`${prefix}SupplierList`]: {
    url: "/api/isx/v1/basis/supplier/querySupplier/:supplierName",
    type: AJAX_TYPE.GET
  },

    /**
     * 添加企业开票权限
     */
  [`${prefix}Add`]: {
    url: "/api/isx/v1/shipped/openTicketCompetence/:supplierId",
    type: AJAX_TYPE.GET
  }

};
