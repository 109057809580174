import {
    AJAX_TYPE
} from '../config';

const prefix = 'tyc_';
export default {

    // 查看天眼查企业基本信息
    [`${prefix}baseinfo`]: {
        url: '/web/crm/enterprise/tyc/baseinfo/:encode',
        type: AJAX_TYPE.GET
    },
    // 天眼查公司行政处罚
    [`${prefix}aadminstrativesanction`]: {
        url: '/web/crm/enterprise/tyc/aadminstrativesanction',
        type: AJAX_TYPE.POST
    },
    // 天眼查经营异常
    [`${prefix}abnormaloperation`]: {
        url: '/web/crm/enterprise/tyc/abnormaloperation',
        type: AJAX_TYPE.POST
    },

    // 天眼查欠税公告
    [`${prefix}arrearsnotice`]: {
        url: '/web/crm/enterprise/tyc/arrearsnotice',
        type: AJAX_TYPE.POST
    },

    // 天眼查公司变更记录
    [`${prefix}changelog`]: {
        url: '/web/crm/enterprise/tyc/changelog',
        type: AJAX_TYPE.POST
    },

    // 天眼查公司法院公告
    [`${prefix}courtnotic`]: {
        url: '/web/crm/enterprise/tyc/courtnotic',
        type: AJAX_TYPE.POST
    },

    // 天眼查行政处罚-信用中国
    [`${prefix}creditchina`]: {
        url: '/web/crm/enterprise/tyc/creditchina',
        type: AJAX_TYPE.POST
    },

    // 天眼查失信人
    [`${prefix}dishonestperson`]: {
        url: '/web/crm/enterprise/tyc/dishonestperson',
        type: AJAX_TYPE.POST
    },

    // 天眼查被执行人
    [`${prefix}executedby`]: {
        url: '/web/crm/enterprise/tyc/executedby',
        type: AJAX_TYPE.POST
    },

    // 天眼查历史经营异常
    [`${prefix}historcalabnormaloperation`]: {
        url: '/web/crm/enterprise/tyc/historcalabnormaloperation',
        type: AJAX_TYPE.POST
    },

    // 天眼查历史法院公告
    [`${prefix}historcalcourtnotice`]: {
        url: '/web/crm/enterprise/tyc/historcalcourtnotice',
        type: AJAX_TYPE.POST
    },

    // 天眼查历史历史失信人
    [`${prefix}historcaldishonest`]: {
        url: '/web/crm/enterprise/tyc/historcaldishonest',
        type: AJAX_TYPE.POST
    },

    // 天眼查历史历史被执行人
    [`${prefix}historcalexecuted`]: {
        url: '/web/crm/enterprise/tyc/historcalexecuted',
        type: AJAX_TYPE.POST
    },

    // 天眼查历史开庭公告
    [`${prefix}historcalopencourt`]: {
        url: '/web/crm/enterprise/tyc/historcalopencourt',
        type: AJAX_TYPE.POST
    },

    // 天眼查历史行政处罚
    [`${prefix}historcalpunish`]: {
        url: '/web/crm/enterprise/tyc/historcalpunish',
        type: AJAX_TYPE.POST
    },

    // 天眼查历史法律诉讼
    [`${prefix}historicallitigation`]: {
        url: '/web/crm/enterprise/tyc/historicallitigation',
        type: AJAX_TYPE.POST
    },

    // 天眼查司法协助
    [`${prefix}judicialassistance`]: {
        url: '/web/crm/enterprise/tyc/judicialassistance',
        type: AJAX_TYPE.POST
    },

    // 天眼查公司法律诉讼
    [`${prefix}legalproceeding`]: {
        url: '/web/crm/enterprise/tyc/legalproceeding',
        type: AJAX_TYPE.POST
    },

    // 天眼查税收违法
    [`${prefix}taxviolation`]: {
        url: '/web/crm/enterprise/tyc/taxviolation',
        type: AJAX_TYPE.POST
    },

    // 天眼查商标信息
    [`${prefix}trademarkinfo`]: {
        url: '/web/crm/enterprise/tyc/trademarkinfo',
        type: AJAX_TYPE.POST
    },
    // 天眼查严重违法
    [`${prefix}illegalinfo`]: {
        url: '/web/crm/enterprise/tyc/illegalinfo',
        type: AJAX_TYPE.POST
    },
    // 天眼查企业信息刷新最新 
    [`${prefix}flush`]: {
        url: '/web/crm/enterprise/tyc/flush',
        type: AJAX_TYPE.POST
    },
    // 天眼查 搜索 企业信息
    [`${prefix}search`]: {
        url: '/web/crm/enterprise/tyc/search/:word',
        type: AJAX_TYPE.POST
    },
}
