import React, { Component } from 'react';
import './index.less';
import project_empty_placeholder from '@/images/project/project_empty_placeholder@2x.png';
import project_empty_bar from '@/images/project/project_empty_bar@2x.png';
import project_empty_circle1 from '@/images/project/project_empty_circle1@2x.png';
import project_empty_circle2 from '@/images/project/project_empty_circle2@2x.png';
import project_empty_circle3 from '@/images/project/project_empty_circle3@2x.png';
import project_empty_wave from '@/images/project/project_empty_wave@2x.png';

import { addProject } from '@/utils/draft';

export default class NoProject extends Component{

    firstRowItems = (length) => {
        let items = [];
        for (var i = 0; i < length; i++) {
            items.push(<div className="first-row-bg" key={i}><img src={project_empty_placeholder}/></div>);
        }
        return (
            items
        );
    };

    addNewProject = () => {
        addProject(this.props.history)
    };

    render() {
        return (
            <div className="no-project">
                <div className="no-project-bg">
                    <div className="first-row">
                        {this.firstRowItems(5)}
                    </div>
                    <div className="second-row">
                        <div className="second-row-child">
                            <div className="bar-container"><img src={project_empty_bar}/></div>
                        </div>
                        <div className="second-row-child">
                            <div className="circle-container"><img src={project_empty_circle1}/></div>
                            <div className="circle-container"><img src={project_empty_circle2}/></div>
                            <div className="circle-container"><img src={project_empty_circle3}/></div>
                        </div>
                    </div>
                    <div className="third-row"><img src={project_empty_wave}/></div>
                </div>

                <div className="no-project-info">
                    <p>您暂未添加项目，请先进行 
                        <a className="no-project-link" onClick={()=> this.props.history.push('/app/setting/system/decision/project')}>配置</a>
                    </p>
                    <p>完成配置后即可
                        <a className="no-project-link" onClick={this.addNewProject}>新建项目</a>
                        管理您的商业资产。
                    </p>
                </div>
            </div>
        );
    }
}
