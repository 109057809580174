import React, { Component } from 'react';
import { Row, Col, Tabs,message } from 'antd';
import Chartpie from './chart/Pie';
import Chartline from './chart/Line';
import CapitalManager from './capital/CapitalManager'
import { numberFormat } from '@/utils/fliter';
import storage from '@/utils/storage';
import './Detail.less';
import http from '@/http'
import { API } from '@/http/api';
import { projectstatsCustomer, monthInfo } from './chart/ChartUtil'
import AuthContainer from 'CMPT/common/auth/AuthContainer';
import { AUTH_KEYS } from '@/utils/auth';
import userUtil from '@/utils/user';
import moment from 'moment';

class ProjectInfo extends React.Component {

    state = {
        projectInfo: {
            totalFloorCnt: 0,
            elevatorQuantity: 0,
            buildingArea: 0,
            contractCnt: 0,
            address: '',
            introduction: '',
            name: '',
            imageUrl: ''
        }
    }

    getProjectsDetailInfo = (encode) => {
        const config = {
            api: API.ProjectsInfo,
            path: {
                projectEncode: encode
            },
            showLoading: true,
        }
        http(config).then(result => {
            if (result.success) {
                Object.assign(this.state.projectInfo, result.data);
                this.setState({
                    projectInfo: this.state.projectInfo,
                    imageUrl: this.state.projectInfo.pictureInfoList.length > 0 ? this.state.projectInfo.pictureInfoList[0].url : ''
                });
            }
        })
    }

    componentDidMount() {
        this.getProjectsDetailInfo(this.props.encode);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.encode !== nextProps.encode) {
            let encode = nextProps.encode;
            this.getProjectsDetailInfo(encode);
        }
    }

    gotoEditProject = () => {
        this.props.history.push(`/app/project/edit/${this.props.encode}?name=${this.state.projectInfo.name}`)
    }

    render() {
        return (
            <div className="project-info">
                <div className="cover-image">
                    {this.state.imageUrl == ''?(<span className="empty-image">暂无图片</span>):null}
                    {this.state.imageUrl == '' ? null : <div className="cover-image-div" style={{backgroundImage:`url(${this.state.imageUrl})`}} ></div>}                    
                    {/* {<img style={{ display: this.state.imageUrl == '' ? 'none' : 'block' }} src={this.state.imageUrl} />} */}
                </div>
                <div className="project-info-list">
                    <AuthContainer key="1" authCodes={AUTH_KEYS.PROJECT_EDIT_ENTRANCE}>                                       
                        <a className="goto-editPage-btn" onClick={this.gotoEditProject}>编辑项目</a>
                    </AuthContainer>    
                    <Row>
                        <Col span={6}>总楼层：{numberFormat(this.state.projectInfo.totalFloorCnt)}层</Col>
                        <Col span={6}>电梯数：{this.state.projectInfo.elevatorQuantity&&numberFormat(this.state.projectInfo.elevatorQuantity)||'-'}台</Col>
                        <Col span={12}>建筑面积：{numberFormat(this.state.projectInfo.buildingArea)}㎡</Col>
                        <Col span={24}>详细地址：{this.state.projectInfo.address}</Col>
                        <Col span={24}>项目介绍：</Col>
                        <Col span={24} style={{marginTop: '6px'}}>
                            <span className="project-introduce" style={{'WebkitBoxOrient': 'vertical'}}>
                                {this.state.projectInfo.introduction}
                            </span>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

class ProjectChart extends React.Component {

    state = {
        pieOptions: {
            id: 'rent-company',
            title: '承租企业画像',
            text: '承租企业',
            lineColor: '#333',
            pieRadius: ['40%', '70%'],
            data: [],
            width: '100%',
            height: 250
        },
        projectInfo: {
            availableArea: 0,
            rentalRate: 0,
            onlineAveragePrice: 0,
            contractCnt: 0
        }
    }

    getProjectstatsCustomer = (encode) => {
        const config = {
            api: API.projectstatsCustomer,
            path: {
                projectEncode: encode
            },
            showLoading: true,
        }
        http(config).then(result => {
            if (result.success && result.data.stats&&result.data.stats.length > 0) {
                let pieOptions = this.state.pieOptions;
                pieOptions.data = projectstatsCustomer(result.data.stats);
                this.setState({
                    pieOptions
                });
            }
        })
    }

    getProjectstatsInfo = (encode) => {
        const config = {
            api: API.projectstatsInfo,
            path: {
                projectEncode: encode
            },
            showLoading: true,
        }
        http(config).then(result => {
            if (result.success) {
                Object.assign(this.state.projectInfo, result.data);
                this.setState({
                    projectInfo: this.state.projectInfo
                });
            }
        })
    }

    componentDidMount() {
        // 1. 承租企业画像
        this.getProjectstatsCustomer(this.props.encode);

        // 2. 实时信息
        this.getProjectstatsInfo(this.props.encode);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.encode !== nextProps.encode) {
            let encode = nextProps.encode;
            this.getProjectstatsCustomer(encode);
            this.getProjectstatsInfo(encode);
            this.setState({
                pieOptions: {
                    id: 'rent-company',
                    title: '承租企业画像',
                    text: '承租企业',
                    lineColor: '#333',
                    pieRadius: ['40%', '70%'],
                    data: [],
                    width: '100%',
                    height: 250
                },
            });
        }
    }
    toContract=()=>{
        if(userUtil.hasAuth(9902010101)){
            this.props.history.push(`/app/operate/contract?proCode=${this.props.encode}`)
        }
    }
    render() {
        return (
            <div className="project-chart">
                <div className="info-card-list">
                    <div className="project-info-card">
                        <p className="info-title">可招商面积</p>
                        <p><span className="info-value">{numberFormat(this.state.projectInfo.availableArea)}</span><span className="info-unit">㎡</span></p>
                    </div>
                    <div className="project-info-card">
                        <p className="info-title">出租率</p>
                        <p><span className="info-value">{this.state.projectInfo.rentalRate}</span><span className="info-unit">%</span></p>
                    </div>
                    <div className="project-info-card">
                        <p className="info-title">签约均价</p>
                        <p><span className="info-value">{numberFormat(this.state.projectInfo.onlineAveragePrice)}</span><span className="info-unit">元/㎡/日</span></p>
                    </div>
                    <div className="project-info-card" onClick={this.toContract}>
                        <p className="info-title">承租合同数量</p>
                        <p><span className="info-value">{numberFormat(this.state.projectInfo.contractCnt)}</span><span className="info-unit">份</span>                        
                            <AuthContainer key="1" authCodes={9902010101}>                                                                       
                                <span className="iconfont iconarrow_line_right"></span>
                            </AuthContainer>    
                        </p>
                    </div>
                </div>
                <Chartpie options={this.state.pieOptions} />
            </div>
        )
    }
}

// 单个项目详情组件
export default class ProjectDetail extends Component {

    state = {
        lineOptions: {
            id: 'rent-rate',
            title: '出租率',
            text: '出租率',
            showReminder: true,
            reminderInfo: '出租率按合同有效期间存在有效合同的区域面积之和/当前招商面积之和计算',
            showMonthPicker: true,
            mainColor: '#27C097',
            lineColor: '#535465',
            fontColor: '#333',
            xaxisData: [],
            yaxisData: [],
            width: '100%',
            height: 420
        },
        encode: this.props.match.params.id,
        isOpenRangePicker: false,
        monthValues: [moment(monthInfo().halfYearBefore, 'YYYY-MM月'), moment(monthInfo().current, 'YYYY-MM月')],
        tabKey: (storage.get('tabKey')) ? storage.get('tabKey') : (userUtil.hasAuth(AUTH_KEYS.PROJECT_OVERVIEW)?"1":((userUtil.hasAuth(AUTH_KEYS.PROJECT_EAM)?"2":"0")))
    }
    // tab 组件样式表  通过 tabBarStyle 属性传入组件内部
    tabBarStyle = {
        textAlign: 'center',
        borderBottom: 'none'
    }
    componentDidMount() {
        this.getProjectDetail(this.props.match.params.id);

        let from = moment(this.state.monthValues[0]).format('YYYY-MM-01');
        let to = moment(this.state.monthValues[1]).format('YYYY-MM-01');
        this.getProjectstatsRentalRate(this.props.match.params.id, from, to);
        this.getProjectInfo(this.props.match.params.id)
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.match.params.id !== nextProps.match.params.id) {
            this.setState({
                encode: nextProps.match.params.id
            });
            this.getProjectDetail(nextProps.match.params.id);
            this.getProjectInfo(nextProps.match.params.id)
            // 切换每一个项目，需要重置
            let from = moment(monthInfo().halfYearBefore, 'YYYY-MM月');
            let to = moment(monthInfo().current, 'YYYY-MM月');
            this.setState({
                monthValues: [from, to],
                isOpenRangePicker: false
            });

            from = from.format('YYYY-MM-01');
            to = to.format('YYYY-MM-01');
            this.getProjectstatsRentalRate(nextProps.match.params.id, from, to);
        }
    }

    getProjectstatsRentalRate = (encode, from, to) => {
        const config = {
            api: API.projectstatsRentalRate,
            path: {
                projectEncode: encode,
            },
            data: {
                from,
                to
            },
            showLoading: true,
        }
        http(config).then(result => {
            if (result.success) {
                let lineOptions = this.state.lineOptions;
                lineOptions.xaxisData = result.data.xaxis;
                lineOptions.yaxisData = result.data.ratesList;
                this.setState({
                    lineOptions
                });
            }
        })
    }

    getProjectDetail = (projectId) => {
        console.log(`获取项目${projectId}的详情`)
    }

    handleOpenChange = () => {
        this.setState({
            isOpenRangePicker: true
        });
    };
    clearRangePicker=()=>{
        var monthValues = [moment(monthInfo().halfYearBefore, 'YYYY-MM月'), moment(monthInfo().current, 'YYYY-MM月')]
        let from = moment(monthValues[0]).format('YYYY-MM-01');        
        let to = moment(monthValues[1]).format('YYYY-MM-01');        
        this.getProjectstatsRentalRate(this.props.match.params.id, from, to);
        this.setState({
            monthValues,
            isOpenRangePicker: false
        });
    }
    handleMonthPanelChange = (monthValues) => {
        let from = moment(monthValues[0]).format('YYYY-MM-01');
        if(monthValues[0].unix()<moment().subtract(2,'y').unix()){
            message.warning('只能选择两年内的统计数据');
            from= moment().subtract(2,'y').format('YYYY-MM-01');
            monthValues[0] = moment().subtract(2,'y')
        }        
        let to = moment(monthValues[1]).format('YYYY-MM-01');
        this.getProjectstatsRentalRate(this.props.match.params.id, from, to);

        this.setState({
            monthValues,
            isOpenRangePicker: false
        });
    };

    tabKeySave(key) {
        storage.set('tabKey', key)
        this.setState({
            tabKey: key
        })
    }
    getProjectInfo = (encode) => {
        const config = {
            api: API.ProjectsInfo,
            path: {
                projectEncode: encode,
            }
        }
        http(config).then(result => {
            if (result.success) {
                this.setState({
                    title:result.data.regionName+'-'+result.data.name
                })
            }
        })
    }
    render() {
        const TabPane = Tabs.TabPane;
        if(userUtil.hasAuth(AUTH_KEYS.PROJECT_OVERVIEW)&&userUtil.hasAuth(AUTH_KEYS.PROJECT_EAM)){
            console.log(this.state)
            return (
                <div className="projectDetail-page">
                    <div className="region-title">{this.state.title}</div>
                <Tabs tabBarStyle={this.tabBarStyle} activeKey={this.state.tabKey} onChange={this.tabKeySave.bind(this)}>
                  
                        <TabPane tab="项目概况" key="1">
                            <Row gutter={12} style={{ marginTop: '12px', marginBottom: '12px' }}>
                                <Col span={12}>
                                    <ProjectInfo encode={this.state.encode} history={this.props.history} />
                                </Col>
                                <Col span={12}>
                                    <ProjectChart history={this.props.history} encode={this.state.encode} />
                                </Col>
                            </Row>
                            <Row style={{paddingBottom: '40px'}}>
                                <Chartline options={this.state.lineOptions}
                                    isOpenRangePicker={this.state.isOpenRangePicker}
                                    monthValues={this.state.monthValues}
                                    handleOpenChange={this.handleOpenChange.bind(this)}
                                    handleMonthPanelChange={this.handleMonthPanelChange.bind(this)}          
                                    clearRangePicker={this.clearRangePicker.bind(this)}
                                />
                            </Row>
                        </TabPane>
                  
                        <TabPane tab="区域管理" key="2">
                            <CapitalManager encode={this.state.encode} history={this.props.history} />
                        </TabPane>
                  
                </Tabs>
            </div>    
            )
        }else if(userUtil.hasAuth(AUTH_KEYS.PROJECT_OVERVIEW)){
            return (
                <div className="projectDetail-page">                   
                <div className="region-title">{this.state.title}</div>         
                <Tabs tabBarStyle={this.tabBarStyle} activeKey={this.state.tabKey} onChange={this.tabKeySave.bind(this)}>
                  
                        <TabPane tab="项目概况" key="1">
                            <Row gutter={12} style={{ marginTop: '12px', marginBottom: '12px' }}>
                                <Col span={12}>
                                    <ProjectInfo encode={this.state.encode} history={this.props.history} />
                                </Col>
                                <Col span={12}>
                                    <ProjectChart encode={this.state.encode} />
                                </Col>
                            </Row>
                            <Chartline options={this.state.lineOptions}
                                isOpenRangePicker={this.state.isOpenRangePicker}
                                monthValues={this.state.monthValues}
                                handleOpenChange={this.handleOpenChange.bind(this)}
                                handleMonthPanelChange={this.handleMonthPanelChange.bind(this)}
                                clearRangePicker={this.clearRangePicker.bind(this)}                                 
                            />
                        </TabPane>
                  
                        
                  
                </Tabs>
            </div>    
            )
        }else if(userUtil.hasAuth(AUTH_KEYS.PROJECT_EAM)){
            return (
                <div className="projectDetail-page">
                <div className="region-title">{this.state.title}</div>
                <Tabs tabBarStyle={this.tabBarStyle} activeKey={this.state.tabKey} onChange={this.tabKeySave.bind(this)}>                                    
                    
                        <TabPane tab="区域管理" key="2">
                            <CapitalManager encode={this.state.encode} history={this.props.history} />
                        </TabPane>
                    
                </Tabs>
            </div>
            )
        }else {
            return (
                <div></div>
            )
        }    
    }
}
