import React, { Component } from 'react'
import Select from 'CMPT/common/Select';
import { Input, Row, Col, Modal, Form } from 'antd';
const Item = Form.Item;
class AbandonModal extends Component {
    state = {
        show: false,
        showModal: false,
        reason: ''
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {
            this.setState({
                show: nextProps.show,
                reason: nextProps.info.cancelNote
            })
        }
    }
    cancel = (flag) => {
        const { close } = this.props
        this.setState({
            show: false
        })
        close(flag)
    }
    confirm = () => {
        this.setState({
            showModal: true
        })
    }
    close = () => {
        this.setState({
            showModal: false
        })
    }
    delete = () => {
        const { close } = this.props
        this.setState({
            showModal: false
        })
        close(true, this.state.reason)
    }
    setReason = (value) => {
        this.setState({
            reason: value.target.value
        })
    }
    tridf10Info = () => {
        const info = this.props.info
        let list = []
        info.projectRooms.forEach(per => {
            list.push(`${info.projectName}-${per.floor}-${per.name}`)
        })
        return list.join(',')
    }
    render() {
        const { info } = this.props
        return (
            <div className="contract-modal-page">
                <Modal
                    visible={this.state.show}
                    closable={false}
                    maskClosable={false}
                    width="640px"
                    title="作废申请"
                    onCancel={this.cancel}
                    onOk={this.confirm}
                >
                    <div className="contract-modal-content">
                        <Row>
                            <Col span={14} className="modal-col">
                                <span className="span-title">租客名称：</span>
                                <span className="span-content">{info.customerName}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={14} className="modal-col">
                                <span className="span-title">合同期限：</span>
                                <span className="span-content">{info.contractDateRange[0]} 至 {info.contractDateRange[0]}</span>
                            </Col>
                            <Col span={10} className="modal-col">
                                <span className="span-title">合同编号：</span>
                                <span className="span-content" style={{color: "#FBBC06"}}>{info.contractNumber || '--'}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} className="modal-col">
                                <span className="span-title">房源信息：</span>
                                <span className="span-content">{this.tridf10Info()}</span>
                            </Col>    
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Item label="作废原因">
                                    <Input.TextArea onChange={this.setReason} value={this.state.reason} maxLength={200} />
                                </Item>
                            </Col>
                        </Row>
                    </div>
                </Modal>
                <Modal
                    visible={this.state.showModal}
                    closable={false}
                    maskClosable={false}
                    width={400}
                    title=" "
                    wrapClassName="contract-tips-modal"
                    onCancel={this.close}
                    onOk={this.delete}
                >
                <p>合同作废后所有账单将自动作废？ </p>
                </Modal>
            </div>
        )
    }
}
export default AbandonModal