import React, { Component } from "react";
import { Form, Input, Button, message, Popover } from "antd";
import "./Login.less";
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import FindPassword from "./FindPassword";
import Experience from './Experience'
import { ajax, login, loginImg, logins } from '@/http';
import http from '@/http';
import { API } from '@/http/api';
import md5 from 'js-md5';
import { getSignSha256 } from '../utils/sign'
import userUtil from "@/utils/user";
const { isUs, mallUrlForLink, omsUrlForLink,ishfjl,istflq,istfgc,istfsn,islqemall } = userUtil;
// crm旧的登录，单独admin账号，后台写死
class LoginForm extends React.Component {

  state = {
    loading: false,
    captchaImage: null,
    showCaptcha: false,
    isForUc: false,
    btnMsg: '获取验证码',
    
  };

  // checkAccount = () => {
  //   this.props.form.validateFields(['account'], (error, { account }) => {
  //     if (!error) {
  //       this.loadCaptcha(account);
  //     }
  //   });
  // }

  // loadCaptcha = (account) => {
  //   ajax({
  //     api: API.verifyCaptcha,
  //     data: {
  //       account
  //     },
  //   }).then(res => {
  //     if (!res.success) {
  //       this.loadValidateCode();
  //     }
  //   })
  // }

  loadValidateCode = () => {

    this.setState({
      showCaptcha: true,
      captchaImage: 'http://10.59.75.41:8286/paasmanage/ucapi/account/common/imgcode?imgCodeType=2&imgType=2&t=' + new Date()
    });
    // })
  }

  handleSubmit = e => {
    e.preventDefault();
    var _this = this
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        /*if (values.account === 'admin' && values.password === 'abc123') {
          const userInfo = {
            userName: 'admin',
            departmentName: '研发部',
            idCode: "gIbvkLBYXAo",
            isManager: true
          }
          userUtil.login(userInfo, ()=> {
            message.success('登录成功')
            this.setState({ loading: false });
            this.props.history.replace("/");
          });
        } else {
          message.error('用户名或密码错误')
          this.setState({ loading: false });
        }
        return */

        const requestData = {
          "account": values.account,
         // "password": md5(values.password),
          "password": values.password

        };
        ajax({
          api: API.userNewLogin,
          data: requestData,
        }).then(res => {
          _this.setState({ loading: false });
          if (res.success && res.userName) {
            // 把 登录信息保存起来，并切换主界面
            const userInfo = {
              userName: res.userName,
              departmentName: '研发部',
              idCode: "gIbvkLBYXAo",
              isManager: true
            }
            userUtil.login(userInfo, ()=> {
              message.success('登录成功')
              this.setState({ loading: false });
              this.props.history.replace("/");
            });
          }else{
            message.error('用户名或密码错误')
          }
        }).catch(error => {
          _this.setState({ loading: false });
        });

        return
        // if (values.account === "admin" && values.password === "abc123") {
        //   const userInfo = {
        //     userName: 'admin',
        //     departmentName: '研发部',
        //     idCode: "gIbvkLBYXAo",
        //     isManager: true
        //   }
        //   userUtil.login(userInfo, () => {
        //     message.success('登录成功')
        //     this.props.history.replace("/");
        //   });
        // }
        return
        const request = {
          // "device": 1,
          // "enableJwt": false,
          // "imgAuthCode": values.picCode,
          "loginNo": values.account,
          // "mode": 2,
          "password": values.password,
          // "sourceType": 2,
          // "tokenExpire": 1,
        };
        var info = getSignSha256(request);
        logins(info).then(res => {
          console.log(res, 'logins cb')
          this.setState({ loading: false });
          if (res.success) {
            this.props.history.replace("/");
          } else {
            this.loadValidateCode();
          }
        }).catch(err => {
          this.setState({ loading: false });
          this.loadValidateCode();
        });
      }
    });
  };


  handleSubmitLogin = e => {
    e.preventDefault();
    var _this = this
    this.props.form.validateFields((err, values) => {
      console.log(values, 'values');
      const config = {
          api: API.userCrmPhoneLoginTenant,
          data: {
            phoneNumber: values.phone,
            verifyCode: values.verifyCode
          },
          showLoading: true
      }
      http(config).then((res) => {
          if (res.code == 200) {
            message.success('登录成功');
            this.props.history.replace("/");
          }
      })
    })
  }
  sendMsgCode = () => {
    this.props.form.validateFields(['phone'], (error, { phone }) => {
      if (!error) {
        if (window.TencentCaptcha) {
          // 显示图形验证码
          var captcha1 = new window.TencentCaptcha("2000256394", (res) => {
            console.log(res, "ressssss");
            if (res.ret == 0) {
              // 滑块成功回调
              this.violdTicketSendSMS(res, phone);
            }
          });
          captcha1.show(); // 显示验证码
        }
      }
    });
  }

  // 验证验证码，发送短信
  violdTicketSendSMS = (result, phone) => {


    const config = {
        api: API.userLoginSendMSG,
        data: {
          phone: phone,
          ticket: result.ticket,
          randStr: result.randstr,
          ip: '',
        },
        showLoading: true
    }
    http(config).then((res) => {
        if (res.code == 200) {
          message.success('发送成功');
          this.getMsgCodeBtnSm()
        }
    })
  }

  // 验证码 倒计时
  getMsgCodeBtnSm = () => {
    let num = 60;
    this.state.msgBtnDidabled = true;
    this.setState({
      btnMsg: `${num}s`
    })
    var timer = setInterval(() => {
      if (num === 1) {
        clearInterval(timer);
        this.state.msgBtnDidabled = false;
        this.setState({
          btnMsg: "重新发送"
        })
        return;
      }
      num--;
      this.setState({
        btnMsg: `${num}s`
      })
    }, 1000);
  }

  render() {
    const {btnMsg} = this.state;
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="login-form-box forsd">
        <div className="login_content">
          <div className="sd_msg">
            <div className="big_sd">平台运营中心</div>
            <div className="sm_sd">工程建设全场景、全生态一站式采购服务</div>
          </div>

          <Form onSubmit={this.handleSubmitLogin} className="login-form">
            <Form.Item label="手机号码">
              {getFieldDecorator("phone", {
                rules: [
                  { required: true, message: "请输入手机号码" },
                  { pattern: /^1[0-9]{10}$/, message: "请输入正确的手机号码" }
                ]
              })(
                <Input
                  autoComplete="off"
                  className="login-input"
                  placeholder="请输入手机号码"
                // onBlur={this.checkAccount}
                />
              )}
            </Form.Item>
            <Form.Item label="验证码" className="code_item">
              {getFieldDecorator("verifyCode", {
                rules: [{ required: true, message: "请输入手机验证码" }]
              })(
                <Input
                  autoComplete="off"
                  className="login-input"
                  placeholder="请输入手机验证码"
                />
              )}
              <span className="getcode_btn" onClick={this.sendMsgCode}>{btnMsg}</span>
              {/* <a className="login-form-forgot" href="#/login/findPassword">忘记密码？</a> */}
            </Form.Item>
            {this.state.showCaptcha && <Form.Item style={{ marginBottom: 0 }} label="验证码">
              {getFieldDecorator("picCode", {
                rules: [{ required: true, message: "请输入校验码" }]
              })(
                <Input
                  autoComplete="off"
                  className="login-input"
                  placeholder="请输入图形验证码"
                />
              )}
              <img onClick={this.loadValidateCode} className="captchaImage" src={this.state.captchaImage} alt="captcha" />
            </Form.Item>}
            <Button style={{ marginTop: '24px', height: '40px', fontSize: '16px' }} type="primary" htmlType="submit" loading={this.state.loading} block>登录</Button>
            {/* <div className='tips'><span>没有账号？</span><span><a href="#/login/experience">申请免费体验</a> </span></div> */}
          </Form>
        </div>
      </div>
    );
  }
}

const WrappedLoginForm = Form.create({ name: 'LoginForm' })(LoginForm);

const popcontent = <img class="pop_img" src="https://img.idf10.com/%E6%95%B0%E9%80%9A%E5%8D%81%E6%96%B9ICP%E8%AE%B8%E5%8F%AF%E8%AF%81.jpg" alt=""></img>;

const menuArr = [
  {
    name: ishfjl ? '华发景龙网': istflq?'天府沥青网':istfsn?'天府水泥网':istfgc?'天府钢材网':islqemall?'路桥e站':'天府建材网',
    link: mallUrlForLink,
  },
  {
    name: ishfjl ? '华发景龙网招投标': istflq?'天府沥青网招投标':istfsn?'天府水泥网招投标':istfgc?'天府钢材网招投标':islqemall?'路桥e站招投标':'天府建材网招投标',
    link: mallUrlForLink + '/tender/tenderNotice',
  },
  {
    name: ishfjl ? '华发景龙网供应商中心':istflq?'天府沥青网供应商中心':istfsn?'天府水泥网供应商中心':istfgc?'天府钢材网供应商中心':islqemall?'路桥e站供应商中心':'天府建材网供应商中心',
    link: omsUrlForLink,
  },
  {
    name: ishfjl ? '华发景龙网寻源询价': istflq?'天府沥青网寻源询价':istfsn?'天府水泥网寻源询价':istfgc?'天府钢材网寻源询价':islqemall?'路桥e站寻源询价':'天府建材网寻源询价',
    link: mallUrlForLink + '/source/',
  },
  {
    name: ishfjl ? '华发景龙网采购中心': istflq?'天府沥青网采购中心':istfsn?'天府水泥网采购中心':istfgc?'天府钢材网采购中心':islqemall?'路桥e站采购中心':'天府建材网采购中心',
    link: mallUrlForLink + '/center/order',
  }
]
const companyMsg = [
  {
    key: '客服电话',
    value: '4000711718'
  },
  {
    key: '商务合作',
    value: islqemall ? '13438081157' : '18038180505    bizco@idf10.com'
  },
  {
    key: '总部地址',
    value: islqemall ? '四川省成都市武侯区九兴大道14号凯乐国际2栋7楼': '广东省深圳市南山区滨海大道3398号赛西科技大厦1905'
  },
]
class Login extends Component {
  goLink = (item) => {
    window.open(item.link)
  }
  render() {
    return (
      <div className="page-login forSd">
        {ishfjl ?
          <div className="logo forSd">
            <div className="logn-name"> <img src='https://file.idf10.com/mp/logo_hfjl_s.png' alt="" /> </div>
            <div className="slogan">华发景龙网运营中心</div>
          </div>
          :
          istflq ?
          <div className="logo forSd">
            <div className="logn-name"> <img src={isUs ? require('../images/logo-idf10.svg') : 'https://file.idf10.com/mp/logo_config.png'} alt="" /> </div>
            <div className="slogan">天府沥青网运营中心</div>
          </div>
          :
          istfsn ?
          <div className="logo forSd">
            <div className="logn-name"> <img src={isUs ? require('../images/logo-idf10.svg') : 'https://file.idf10.com/mp/logo_config.png'} alt="" /> </div>
            <div className="slogan">天府水泥网运营中心</div>
          </div>
          :
          istfgc ?
          <div className="logo forSd">
            <div className="logn-name"> <img src={isUs ? require('../images/logo-idf10.svg') : 'https://file.idf10.com/mp/logo_config.png'} alt="" /> </div>
            <div className="slogan">天府钢材网运营中心</div>
          </div>
          :
          islqemall ?
          <div className="logo forSd">
            <div className="logn-name"> <img src={isUs ? require('../images/logo-idf10.svg') : 'https://file.idf10.com/mp/lqez_logo.jpg'} alt="" /> </div>
            <div className="slogan">路桥e站运营中心</div>
          </div>
          :
          <div className="logo forSd">
            <div className="logn-name"> <img src={isUs ? require('../images/logo-idf10.svg') : 'https://file.idf10.com/mp/logo_config.png'} alt="" /> </div>
            <div className="slogan">{isUs ? '运营管理平台' : '天府建材网运营中心'}</div>
          </div>
        }

        <Switch>
          <Route path="/login/findPassword" component={FindPassword} />
          <Route path="/login/experience" component={Experience} />
          <Route component={WrappedLoginForm} />
        </Switch>
        <div className="footer">
          {/* <img src={require('../images/login/footer.png')} alt="" /> */}
          <div className="footer_content">
            <div className="c_1">
              <div className="tit">
                服务入口
              </div>
              <div className="menu">
                {
                  menuArr.map(item => {
                    return <span onClick={() => this.goLink(item)}>{item.name}</span>
                  })
                }
              </div>
            </div>
            <div className="c_2">
                <div className="tit">技术支持</div>
                <div className="cont">
                  {
                    companyMsg.map(item => {
                      return <div className="line_msg">{item.key}:{item.value}</div>
                    })
                  }
                </div>
            </div>
          </div>
          <div className="f_line">
            <span class="btm_msg">©2021数通十方（深圳）数字科技有限公司版权所有&nbsp;&nbsp;丨&nbsp;&nbsp;
              <Popover content={popcontent} placement="top">
                <span>ICP许可证编号：粤B2-20211243 </span>
              </Popover>
              &nbsp;&nbsp;丨&nbsp;&nbsp; 
              <a href="https://beian.miit.gov.cn/" target="_blank">
                ICP备案号：粤ICP备2021060024号 
              </a>
              <img src={require('../images/footer/police-badge.png')} class="police-badge" />
              <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502007406" target="_blank">
                公安备案号：44030502007406
              </a>&nbsp;&nbsp;丨&nbsp;&nbsp;
              <a href={require('../images/footer/business-license.png')} target="_black">
                  营业执照
              </a>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;