import {
    AJAX_TYPE
} from '../config';

// 项目

const prefix = 'Projects';

export default {
    // 创建项目-草稿
    [`${prefix}Draft`]: {
        url: '/web/project/draft',
        type: AJAX_TYPE.POST
    },
    // 创建项目-清除草稿
    [`${prefix}DraftClear`]: {
        url: '/web/project/draft',
        type: AJAX_TYPE.DELETE
    },
    // 项目空间-项目列表
    [`${prefix}List`]: {
        url: '/web/projects',
        type: AJAX_TYPE.GET
    },
    // 创建项目
    [`${prefix}Create`]: {
        url: '/web/projects',
        type: AJAX_TYPE.POST
    },
    // 编辑项目
    [`${prefix}Edit`]: {
        url: '/web/projects',
        type: AJAX_TYPE.PUT
    },
    // 项目空间-项目列表-地理视图
    [`${prefix}Geo`]: {
        url: '/web/projects/geo',
        type: AJAX_TYPE.GET
    },
    // 项目空间-概要信息
    [`${prefix}Info`]: {
        url: '/web/projects/:projectEncode',
        type: AJAX_TYPE.GET
    },
    // 删除项目
    [`${prefix}Delete`]: {
        url: '/web/projects/:projectEncode',
        type: AJAX_TYPE.DELETE
    },
    // 项目空间-项目概况
    [`${prefix}Search`]: {
        url: '/web/project/search',
        type: AJAX_TYPE.POST
    },
    //根据项目名称获取项目以及区域信息web/common/zone/getProjectDetailInfoByName?
    [`${prefix}getProjectDetailInfoByName`]: {
        url: 'web/common/zone/getProjectDetailInfoByName',
        type: AJAX_TYPE.GET
    },
    //根据项目ENCODE获取项目以及区域信息web/common/zone/getProjectDetailInfoByName?
    [`${prefix}getProjectDetailInfoById`]: {
        url: '/web/common/zone/getProjectDetailInfoById',
        type: AJAX_TYPE.GET
    },
}
