import React, { Component } from 'react';
import { Button,Modal, Form, Input } from 'antd';
import { Breadcrumb, Icon, Radio } from 'antd';
import Steps from 'CMPT/common/Steps';
import ChooseProject from './ChooseProject';
import { Link } from 'react-router-dom';
import storage from '@/utils/storage';
import http from '@/http';
import { API } from '@/http/api';
import { Select } from 'antd';

const { Option } = Select;
const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const BreadcrumbItem = Breadcrumb.Item;
class ReceiptTemBaseInfo extends Component {
    templateId=undefined
    constructor(props) {
        super(props)
        this.state = {
            // 模板名称
            templateName: '',
            
            templateTypeList: [],
            // 使用范围项目信息
            projectId: [],
            // 获取项目列表
            projectList: [],
            steps: {
                nodeNum: 2,
                current: 1,
                currentNodeStatus: 2,
                nodeNameList: ['基本信息', '上传模板']
            },
        }

    }
    componentWillUnmount() {
        setTimeout(() => {
            window.onbeforeunload = null
        }, 0);

    }
    componentWillMount() {
        setTimeout(() => {
            window.onbeforeunload = function (e) {
                e = e || window.event;
    
                // 兼容IE8和Firefox 4之前的版本
                if (e) {
                    e.returnValue = '您编辑的信息尚未提交，您确定要离开吗？';
                }
    
                // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
                return '您编辑的信息尚未提交，您确定要离开吗？';
            };
        }, 100);
        const stateFromSecond = this.props.history.location.state
        // 第二部返回时
        if (stateFromSecond) {
            this.setState({
                projectId:stateFromSecond.projectId            
            })
        }
        
        
        // 获取模板类型|项目列表
        const template_init = storage.get('template_init')
        if (template_init) {
            this.setState({
                projectList: template_init.projectList.map(item => { return { value: item.id, label: item.name } }),                
            })
        } else {
            http({
                api: API.templateInit,
                path: {}, // 路径参数
                data: {}, // 请求参数            
                showLoading: false // 是否显示全局加载
            }).then(res => {
                storage.set('template_init', res.data)
                // 
                this.setState({
                    projectList: res.data.projectList.map(item => { return { value: item.id, label: item.name } }),
                })
            })
        }
        // 判断是否为编辑        
        const templateId = this.props.history.location.search&&this.props.history.location.search.replace('?edit=','')
        this.templateId = templateId
        if(templateId&&!stateFromSecond){
            // 请求信息回填
            http({
                api: API.templateGet,
                path: {}, // 路径参数
                data: {
                    encode:templateId
                }, // 请求参数
                showLoading: false // 是否显示全局加载
            }).then(res => {
                console.log(res.data.projectList.map(res => res.id))
                // 赋值
                
                
                this.setState({
                    projectId:res.data.projectList.map(res => res.id),
                    stateFromSecond:{
                        fileExt: res.data.media.mediaExt,
                        fileKey: res.data.media.mediaKey,
                        fileName: res.data.media.name,
                        fileType: res.data.media.type
                    }
                },()=>{
                    this.props.form.setFieldsValue({ templateName: res.data.name,projectId:res.data.projectList.map(res => res.id) })
                })
            })
        }

    }
    componentDidMount(){
        // console.log(this.props.history.location.state)
        const stateFromSecond = this.props.history.location.state
        // 第二部返回时
        if (stateFromSecond) {
            this.setState({
                stateFromSecond:stateFromSecond,
            })
            this.props.form.setFieldsValue({
                templateName: stateFromSecond.templateName,                
            });
        }
    }

    // 获取适用范围
    getProjectList = (list) => {
        console.log(list)
        if(!Array.isArray(list)){
            list = [list]
        }
        this.setState({
            projectId : list
        })
    }
    chackTemplate=(rule, value, callback)=>{
        // console.log(rule)
        // console.log(value)
        // console.log( callback)
        // 获取模板名// 获取模板类型
        
        const {templateName} = this.props.form.getFieldsValue()
        
        if(!templateName){
            callback('请输入模板名称')
        }else {
            // 校验模板名称
            http({
                api: API.templateCheckName,
                path: {}, // 路径参数
                data: {
                    name: templateName,
                    encode: this.templateId,
                    type: 2,
                }, // 请求参数            
                showLoading: false // 是否显示全局加载
            }).then(res => {                    
                if(!res.success){
                    callback('模板名不能重复')
                }else{
                    callback()
                }
            })
        }
        
        
    }
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                this.setState({
                    templateName:values.templateName,
                    
                },()=>{
                    this.props.history.push({ pathname: "/app/setting/template/receipttemplate/receipteditsecond",search: (this.templateId?'?edit='+this.templateId:''), state: {stateFromSecond:this.state.stateFromSecond, templateName: this.state.templateName || this.templateName ,projectId: this.state.projectId} })
                })
                
            }
        });
    };
    chackProject=(rule, value, callback)=>{
        const {projectId} = this.props.form.getFieldsValue()
        console.log(projectId)
        if(projectId.length==0){
            callback('请选择适用范围')
        }else if(projectId.length>1){
            callback('只能选一个项目')
        }else {
            callback()
        }
    }
    cancel=()=>{
        this.setState({
            visible:true,
        })
    }
    handleOk=()=>{
        this.props.history.go(-1)
    }
    handleCancel=()=>{
        this.setState({
            visible:false,
        })
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        
        const options = this.state.projectList.map(res=>{            
            return <Option key={res.value} value={res.value}>{res.label}</Option>
        })
        return (
            <div className="template-edit">
                <Modal
                    closable={false}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    okText='确认'
                    width={400}         
                    onCancel={this.handleCancel}           
                >
                    <p>当前页面未保存，是否离开？</p>
                </Modal>
                <Breadcrumb>
                    <BreadcrumbItem>
                            <Link style={{ color: '#333' }} to={{ pathname: "/app/setting/template/receipttemplate" }}>
                                模版管理
                            </Link>
                    
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                    <Link style={{ color: '#333' }} to={{ pathname: "/app/setting/template/receipttemplate" }}>
                    收据模版
                            </Link>
                    
                    </BreadcrumbItem>
                    <BreadcrumbItem>新增模版</BreadcrumbItem>
                </Breadcrumb>
                <Steps steps={this.state.steps} />
                <div className="steps-content">
                    <Form>
                        <div className="receipt-baseinfo">
                            <div className="title">基本信息</div>
                            <div className="template-name">
                                <Form.Item label="模板名称"                                
                                >
                                    {getFieldDecorator('templateName', {
                                        rules: [
                                            {
                                                required: true,
                                                // message: '请输入模板名称',
                                                validator:this.chackTemplate
                                            },

                                        ],
                                        initialValue:undefined
                                    })(<Input placeholder="请输入" maxLength={20} autoComplete="off" />)}
                                </Form.Item>
                            </div>
                            <div className="project-id">
                                <Form.Item label="适用项目">
                                    {getFieldDecorator('projectId', {
                                        rules: [
                                            {
                                                required: true,
                                                message: '请选择适用范围',
                                                // validator:this.chackProject
                                            },
                                        ],
                                        initialValue:Array.isArray(this.state.projectId)? this.state.projectId[0]: this.state.projectId
                                    })(
                                        <Select placeholder="请选择" style={{ width: 240 }} onChange={this.getProjectList}>
                                            {options}
                                        </Select>
                                        // <ChooseProject multiple={false} require={true} checkedList={this.state.projectId} projectList={this.state.projectList} getProjectList={this.getProjectList} />            
                                    )}
                                </Form.Item>                                
                            </div>
                        </div>
                        <div className="receipt-btn">
                            
                            <Button onClick={this.handleSubmit} type="primary">下一步, 上传模版</Button>
                            <Button onClick={this.cancel}>取消</Button>
                        </div>

                    </Form>
                </div>
            </div>

        );
    }
}
export default Form.create({ name: 'ReceiptTemBaseInfo' })(ReceiptTemBaseInfo);
