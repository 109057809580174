import {
    AJAX_TYPE
} from '../config';

// 地区

const prefix = 'area';

export default {
    // 所有的省市区
    [`${prefix}ChinaArea`]: {
        url: '/web/area/chinaArea',
        type: AJAX_TYPE.GET
    },
    // 所有的国家
    [`${prefix}Countries`]: {
        url: '/web/area/countries',
        type: AJAX_TYPE.GET
    },
    // 所有的省
    [`${prefix}Provinces`]: {
        url: '/web/area/provinces',
        type: AJAX_TYPE.GET
    },
    // 所有的下一级地区
    [`${prefix}SubAreas`]: {
        url: '/web/area/subAreas',
        type: AJAX_TYPE.GET
    },
    // 所有的省市
    [`${prefix}ProvincesCity`]: {
        url: '/web/area/provincesCity',
        type: AJAX_TYPE.GET
    },


    // 所有的省市区
    [`${prefix}ChinaAreaToPlatform`]: {
        url: '/web/area/chinaAreaToPlatform',
        type: AJAX_TYPE.GET
    },

    
}