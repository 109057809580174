import React, { Component } from 'react';
import { Breadcrumb, Button, Row, Col, DatePicker, Input,message } from 'antd';
import Select from 'CMPT/common/Select';
import Table from 'CMPT/common/Table';
import http from '@/http';
import { API } from '@/http/api';
const BreadcrumbItem = Breadcrumb.Item;
const { RangePicker } = DatePicker;
const Search = Input.Search;
var moment = require('moment');
class RegionalReport extends Component {
    componentWillMount() {
        this.initData()
        this.getList()
    }
    initData = () => {
        const config = {
            api: API.reportformRegionalInit,
            data: this.state.selectParams,
            showLoading: true
        };
        http(config).then(res => {
            console.log(res)
            this.setState({
                merchantStatusEnum: [{ "value": null, "name": '全部' }].concat(res.data.merchantStatusEnum.map(item => {
                    return { "value": item.key, "name": item.value }
                })),
                rentStatusEnum: res.data.rentStatusEnum.map(item => {
                    return { "value": item.key, "name": item.value }
                }),
            })
        })
    }
    getList = () => {
        const config = {
            api: API.reportformRegional,
            data: {
                createTimeFrom: this.state.createTimeFrom,// (string, optional): 创建时间-起始 ,
                createTimeTo: this.state.createTimeTo,// (string, optional): 创建时间-结束 ,
                keywords: this.state.keywords,// (string, optional): 搜索关键字 ,
                merchantStatus: this.state.currentMerchant,// (integer, optional): 招商状态 ,
                // rentStatus: this.state.currentRent,// (integer, optional): 出租状态 ,
                pageNo: this.state.pageNo || 1,// (integer, optional),
                pageSize: this.state.pageSize || 10,// (integer, optional),
            },
            showLoading: true
        };
        http(config).then(res => {

            this.setState({
                data: res.data.datas,
                total: res.data.totalRecord,
            })
        })
    }
    state = {        
        createTimeFrom:moment().subtract(1, 'years').add(1,'days').format('YYYY-MM-DD'),
        createTimeTo:moment().format('YYYY-MM-DD'),
        currentMerchant: null,
        currentRent: null,
        keywords:null,
        pageNo:1,
        pageSize:10,
        "merchantStatusEnum": [
            {
                "value": 0,
                "name": "不招商"
            },
            {
                "value": 1,
                "name": "可招商"
            }
        ],
        "rentStatusEnum": [
            {
                "value": 0,
                "name": "不可租"
            },
            {
                "value": 1,
                "name": "未出租"
            },
            {
                "value": 2,
                "name": "已出租"
            }
        ],
        total: 0,        
        columns: [
            {
                title: '创建时间',
                dataIndex: 'createTime'
            }, {
                title: '区域号',
                dataIndex: 'zoneNo'
            }, {
                title: '所属项目名称',
                dataIndex: 'projectName'
            }, {
                title: '所属项目编号',
                dataIndex: 'projectNo'
            }, {
                title: '招商状态',
                dataIndex: 'merchantStatus',
                render: (text) => {return text==1?"可招商":"不招商"}
            }, {
                title: '建筑面积(m²)',
                dataIndex: 'buildingArea'
            }, {
                title: '预计单价(元/m²)',
                dataIndex: 'unitPrice'
            }, {
                title: '使用面积(m²)',
                dataIndex: 'useArea'
            }, {
                title: '套内面积(m²)',
                dataIndex: 'innerArea'
            }, {
                title: '备注',
                dataIndex: 'remark',
                render: (text) => {return text?(text.length>10?(text.slice(0,10)+'...'):text):'--'}
            }
        ],
        data: []

    }
    handleDate = (moment,date) => {
        
        this.setState({
            createTimeFrom:date[0],
            createTimeTo:date[1]
        },()=>{
            this.getList();
        })
    }
    changeSearch = (value) => {
        this.setState({
            keywords: value,
            pageNo:1,
            pageSize:10
        }, () => {
            this.getList()
        })
    }
    onChange = (page, pageSize) => {
        console.log('contract list', page, pageSize)
        this.setState({
            pageNo: page,
            pageSize: pageSize
        }, () => {
            this.getList()
        })
    }
    chooseMerchantStatusEnum = (res) => {
        this.setState({
            currentMerchant: res,
            pageNo:1,
            pageSize:10
        }, () => {
            this.getList()
        })
    }
    chooseRentStatusEnum = (res) => {
        this.setState({
            currentRent: res
        }, () => {
            this.getList()
        })
    }
    reset=()=>{
        this.setState({
            createTimeFrom:moment().subtract(1, 'years').add(1,'days').format('YYYY-MM-DD'),
            createTimeTo:moment().format('YYYY-MM-DD'),
            currentMerchant: null,
            currentRent: null,
            keywords:null,
            pageNo:1,
            pageSize:10,
            isReset:true,
        },()=>{
            this.setState({
                isReset:false,
            })
            this.getList()
        })
    }
    export = () => {
        const config = {
            api: API.reportformRegionalExport,
            data: {
                createTimeFrom: this.state.createTimeFrom,// (string, optional): 创建时间-起始 ,
                createTimeTo: this.state.createTimeTo,// (string, optional): 创建时间-结束 ,
                keywords: this.state.keywords,// (string, optional): 搜索关键字 ,
                merchantStatus: this.state.currentMerchant,// (integer, optional): 招商状态 ,
                // rentStatus: this.state.currentRent,// (integer, optional): 出租状态 ,
                pageNo: this.state.pageNo || 1,// (integer, optional),
                pageSize: this.state.pageSize || 10,// (integer, optional),
            },
            responseType:'blob',
            showLoading: true
        };
        http(config).then(res => {
            if (res.size < 1024) {
                message.error('下载异常，请稍后重试')
            } else { 
            const content = res
            const blob = new Blob([content],{type: 'application/vnd.ms-excel'})
            const fileName = `区域报表${moment(new Date()).format('YYYY-MM-DD HH:mm')}.xlsx`
            if ('download' in document.createElement('a')) { // 非IE下载
                const elink = document.createElement('a')
                elink.download = fileName
                elink.style.display = 'none'
                elink.href = URL.createObjectURL(blob)
                document.body.appendChild(elink)
                elink.click()
                URL.revokeObjectURL(elink.href) // 释放URL 对象
                document.body.removeChild(elink)
            } else { // IE10+下载
                navigator.msSaveBlob(blob, fileName)
            }
        }
        })
    }
    render() {
        return (
            <div className="report-con">
                <div className='report-header'>
                    <Breadcrumb>
                        <BreadcrumbItem>报表中心</BreadcrumbItem>
                        <BreadcrumbItem> 区域报表</BreadcrumbItem>
                    </Breadcrumb>
                    <Button type="primary" className='download' onClick={this.export}>下载报表</Button>
                </div>
                <div className='report-list'>
                    <div className="report-search">

                        {
                            !this.state.isReset?(
                                <div>
                                    <Search style={{ float: 'left', width: 240, marginRight: 12 }}
                                        placeholder="请输入项目名称/项目编号/区域号"
                                        onSearch={this.changeSearch}
                                    />

                                    <Select style={{ float: 'left', width: 104, marginRight: 12 }} data={this.state.merchantStatusEnum} placeholder="招商状态" selectMethod={this.chooseMerchantStatusEnum} />

                                    {/* <Select style={{ float: 'left', width: 104, marginRight: 12 }} data={this.state.rentStatusEnum} placeholder="出租状态" selectMethod={this.chooseRentStatusEnum} /> */}

                                    <div className="select-time" style={{ float: 'left', width: 270, marginRight: 12 }}>
                                        <span className="inner-col-span">创建日期</span>
                                        <RangePicker disabledDate={disabledDate} onChange={this.handleDate.bind(this)} style={{ width: 206 }} 
                                        defaultValue={[moment().subtract(1, 'years').add(1,'days'), moment()]} allowClear={false}
                                        />
                                    </div>
                                </div>
                            ):(null)
                        }

                        <span className="report-reset" style={{ float: 'left', cursor: 'pointer', lineHeight: '30px' }} onClick={this.reset}>重置</span>

                    </div>
                    <Table scroll={{ x: 1300 }} columns={this.state.columns} current={this.state.pageNo} data={this.state.data} total={this.state.total} change={this.onChange} />
                </div>
            </div>
        );
    }
}
export default RegionalReport;


function disabledDate(current) {
    // Can not select days before today and today
    return current > moment() || current < moment().subtract(5, 'years'); // February 28;
}