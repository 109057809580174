import React, { Component } from "react";
import { Modal, message, Form, InputNumber, Input, Icon, Button } from "antd";
import Upload from "CMPT/common/Upload";
import { API } from "@/http/api";
import http from "@/http";
import "./YqyGoodsAddEdit.less";

class YqyGoodsAddEdit extends Component {
  state = {
    marketPrice: 0,
    imgUrl: null
  };

  handleBeforeUpload = file => {
    const size = file.size;
    if (size / 1024 / 1024 > 2) {
      message.error("请上传2M以内的图片");
      return false;
    }
  };

  setGoodsImgUrl = value => {
    if (value.file.status === "done") {
      let item = value.file.response.file;

      this.props.form.setFieldsValue({
        imgUrl: item.publicUrl
      });

      this.setState({
        imgUrl: item.publicUrl
      });
    }
  };

  removeImg = e => {
    if (e) e.stopPropagation();

    this.props.form.setFieldsValue({
      imgUrl: null
    });
    this.setState({
      imgUrl: null
    });
  };

  innerPriceValidator = (rule, value, callback) => {
    if (!this.state.marketPrice) {
      callback(`请先设置下市场价`);
    } else if (!value) {
      callback(`请输入内购价(0.01~99999.99)`);
    } else if (value > this.state.marketPrice) {
      callback(`金额不能大于市场价${this.state.marketPrice}`);
    } else {
      callback();
    }
  };

  setMarketData = value => {
    this.state.marketPrice = value;
  };

  componentDidMount() {
    let data = this.props.data;

    if (data) {
      this.props.form.setFieldsValue({
        title: data.title,
        innerPrice: data.innerPrice,
        marketPrice: data.marketPrice,
        sorting: data.sorting,
        imgUrl: data.imgUrl
      });

      this.setState({
        marketPrice: data.marketPrice,
        imgUrl: data.imgUrl
      });
    }
  }

  doAddEdit = formData => {
    if (this.props.data) {
      formData.encode = this.props.data.encode;
    } else {
      formData.type = this.props.type;
      formData.source = this.props.source;
      formData.sorting = this.props.listCount;
    }

    // feateure
    let feature = {
      imgUrl: this.state.imgUrl,
      innerPrice: formData.innerPrice,
      marketPrice: formData.marketPrice,
      title: formData.title
    };
    formData.feature = feature;

    // delete key
    delete formData.imgUrl;
    delete formData.innerPrice;
    delete formData.marketPrice;
    delete formData.title;


    const config = {
      api: this.props.data ? API.yqyEdit : API.yqyAdd,
      showLoading: true,
      data: formData
    };
    http(config).then(res => {
      if (res.success) {
        this.handleCancel();
        this.props.refresh();
      }
    });
  };

  handleCancel = () => {
    this.props.cancelDialog();
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let fieldsObject = this.props.form.getFieldsValue();

        this.doAddEdit({ ...fieldsObject });
      }
    });
  };
  render() {
    let my = this;
    const { getFieldDecorator } = this.props.form;

    return (
      <Modal
        title={this.props.data ? "编辑商品" : "添加商品"}
        visible={true}
        okText="确定"
        cancelText="取消"
        closable={false}
        maskClosable={false}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        width={500}
        footer={[]}
      >
        <Form className="yqy-form" onSubmit={this.handleSubmit}>
          <Form.Item label="商品标题">
            {getFieldDecorator("title", {
              rules: [
                { required: true, message: "请输入商品标题" },
                { max: 50, message: "商品标题不能超过50位" }
              ]
            })(<Input placeholder="请输入(最长50位)" maxLength={50} />)}
          </Form.Item>

          <Form.Item label="商品图片">
            {getFieldDecorator("imgUrl", {
              rules: [{ required: true, message: "请上传商品图片" }]
            })(
              <Upload
                action={API.uploadAdd.url}
                beforeUpload={this.handleBeforeUpload}
                accept=".png,.jpg"
                onChange={this.setGoodsImgUrl}
                showUploadList={false}
                listType="picture-card"
              >
                {this.state.imgUrl && (
                  <div className="upload-img">
                    <img src={this.state.imgUrl} alt="" />
                    <Icon type="close-circle" onClick={this.removeImg} />
                  </div>
                )}
              </Upload>
            )}
          </Form.Item>

          <Form.Item label="市场价">
            {getFieldDecorator(`marketPrice`, {
              rules: [
                {
                  required: true,
                  message: "请输入市场价(0.01~99999.99)"
                }
              ]
            })(
              <InputNumber
                onChange={this.setMarketData.bind(my)}
                min={0.01}
                max={99999.99}
              />
            )}
          </Form.Item>

          <Form.Item label="内购价" required>
            {getFieldDecorator(`innerPrice`, {
              rules: [
                {
                  validator: this.innerPriceValidator
                }
              ]
            })(<InputNumber min={0.01} max={99999.99} />)}
          </Form.Item>



          <div className="btn-group">
            <Button type="primary" htmlType="submit">
              提交
            </Button>
            <Button onClick={this.handleCancel} size="large">
              取消
            </Button>
          </div>
        </Form>
      </Modal>
    );
  }
}

export default Form.create({ name: "YqyGoodsAddEdit" })(YqyGoodsAddEdit);
