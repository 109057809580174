import React from 'react';
import {
    Table, Input, Form,
} from 'antd';

const FormItem = Form.Item;

const EditableRow = ({ form, index, ...props }) => (
    <tr {...props} />
);

class HeaderCell extends React.Component {
    render() {
        const {
            title,
            required,
            width,
        } = this.props;

        return (
            <th width={width} style={{ fontSize: '12px', textAlign: 'center' }}>
                <span className="ant-table-header-column">
                    <div>
                        <span className="ant-table-column-title">{title}</span>
                        {required ? (<span className="required" style={{ color: '#E94437', fontWeight: "normal", marginLeft: "4px" }}>*</span>) : null}
                    </div>
                </span>
            </th>
        );
    }
}

class EditableCell extends React.Component {

    onChange = (value) => {        
        const { record, dataIndex, onChange } = this.props;        
        
        
        const newRecord = { ...record };
        newRecord.constraint = {
            ...newRecord.constraint,
            dataIndex: null
        };
        if (value.target) {
            if(dataIndex == 'code'){                

                if((value.target.value.length==1&&value.target.value[0]=='-')||!isNaN(Number(value.target.value))){
                    
                    if(value.target.value==0){
                        newRecord[dataIndex] = ''
                    }else if(value.target.value>200){
                        newRecord[dataIndex] = 200
                    }else if(value.target.value<-20){
                        newRecord[dataIndex] = -20
                    }else{
                        newRecord[dataIndex] = value.target.value;
                    }
                }
            }else if(dataIndex == 'name'){
                if(value.target.value.length<=5){
                    newRecord[dataIndex] = value.target.value;
                }
            }else if(dataIndex == 'area'||dataIndex == 'buildingArea'||dataIndex == 'useArea'||dataIndex == 'innerArea'){
                if(value.target.value==0){
                    newRecord[dataIndex] = ''
                }else
                if(value.target.value>0&&value.target.value<=99999999){
                    
                    newRecord[dataIndex] = value.target.value.split('.')[0]+(value.target.value.split('.')[1]!==undefined?('.'+value.target.value.split('.')[1].slice(0,2)):'');
                }
            }else if(dataIndex == 'price'||dataIndex == 'unitPrice'){
                if(value.target.value==0){
                    newRecord[dataIndex] = ''
                }else
                if(value.target.value>0&&value.target.value<=9999){
                    newRecord[dataIndex] = value.target.value.split('.')[0]+(value.target.value.split('.')[1]!==undefined?('.'+value.target.value.split('.')[1].slice(0,2)):'');
                }
            }else if(dataIndex == 'height'){
                if(value.target.value==0){
                    newRecord[dataIndex] = ''
                }else
                if(value.target.value>0&&value.target.value<=99){
                    newRecord[dataIndex] = value.target.value;
                }
            }else{

                newRecord[dataIndex] = value.target.value;
            }

        } else {
            newRecord[dataIndex] = value;
        }
        if (typeof onChange == 'function') {
            onChange(newRecord);
        }
    }
    onBlur = () => {
        const { dataIndex, onBlur } = this.props
        if (typeof onBlur === 'function') {
            onBlur(dataIndex)
        }
    }

    render() {
        const {
            editable,
            suffix,
            dataIndex,
            record,
            width,
            render,
            title,
            children
        } = this.props;

        const constraint = (record.constraint || {})[dataIndex];
        const { status, message } = constraint || {};

        return (
            <td width={width}>
                {render ? children : (<FormItem validateStatus={status} help={message} style={{ margin: 0 }}>
                    <Input
                        disabled={!editable}
                        value={record[dataIndex]}
                        placeholder={editable ? '请输入' : null}
                        suffix={suffix}
                        title={title}
                        maxLength={title=='备注'?100:(title=="门牌号"?8:null)}                        
                        // min={(title=='预计单价 (元/m²/日)'||title=='建筑面积(m²)'||title=='使用面积 (m²)'||title=='套内面积 (m²)'?1:null)}
                        // max={title=='预计单价 (元/m²/日)'?9999:(title=='建筑面积(m²)'||title=='使用面积 (m²)'||title=='套内面积 (m²)'?99999999:null)}
                        onChange={this.onChange}
                        onBlur={this.onBlur}
                    />
                </FormItem>)}
            </td>
        );
    }
}

export default class EditableTable extends React.Component {
    constructor(props) {
        super(props);
        const { columns, dataSource } = props;                
        this.state = {
            dataSource: dataSource
        };
    }

    componentWillReceiveProps(props) {
        this.setState({
            ...this.state,
            ...props
        })
    }

    render() {
        const { dataSource } = this.state;
        const components = {
            header: {
                cell: HeaderCell,
            },
            body: {
                row: EditableRow,
                cell: EditableCell,
            },
        };
        const columns = this.props.columns.map((col) => {            
            return {
                ...col,
                onHeaderCell: record => ({
                    record,
                    key: col.key,
                    dataIndex: col.key,
                    title: col.title,
                    required: col.required,
                    suffix: col.suffix,
                    message: col.message,
                    width: col.width
                }),
                onCell: record => ({
                    record,
                    editable: typeof col.isEditable === 'function' ? col.isEditable(record) : col.editable,
                    key: col.key,
                    dataIndex: col.key,
                    title: col.title,
                    suffix: col.suffix,
                    onChange: this.props.onChange,
                    onBlur: this.props.onBlur,
                    width: col.width,
                    render: col.render
                }),
            };
        });
        return (
            <Table
                className="table-floor"
                components={components}
                rowClassName={() => 'editable-row'}
                dataSource={dataSource}
                columns={columns}
                pagination={false}
            />
        );
    }
}
