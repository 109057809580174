import React, { Component } from 'react';
import { Form, Input } from 'antd';
import http from '@/http'
import { API } from '@/http/api'

const { TextArea } = Input;
const { Item } = Form;
let count = 0;

export default class CancelOrder extends Component{
    constructor(props) {
        super(props);
        this.state = {
            cost : null
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.encode && count === 0) {
            this.getmaintainInit(nextProps.encode);
            count++
        }
        return true
    }
    componentWillUnmount() {
        count = 0
    }

    //请求费用清单数量
    getmaintainInit(encode) {
        const config = {
            api: API.maintainBillCount,
            path: {
                encode
            }
        }
        http(config).then(result => {
            const { data } = result;
            let cost = null;
            if (data) {
                cost = 1
            } else {
                cost = 0
            }
            setTimeout(()=>{
                this.setState({
                    cost
                })
            },0)
        })
    }

    render() {
        const { chooseProject } = this.props;
        const { getFieldDecorator } = this.props.form;

        return (
            <div className='maintain-addorder maintain-form'>
                <Form>
                    <Item label="取消原因">
                        {getFieldDecorator('remark',{
                            rules: [{required: true, message: '请输入取消原因',}],
                        })(
                            <TextArea
                                style={{resize:'none'}}
                                maxLength={200}
                                onChange={chooseProject.bind(this,1)} />
                        )}
                    </Item>
                </Form>
                <div className='row-special-tips'>
                    {this.state.cost === 0 ? '温馨提示：暂未添加费用清单，若取消工单，工单将被关闭，且不生成账单。' : '温馨提示：若取消工单，工单将被关闭，且生成最终账单。'}
                </div>
            </div>
        )
    }

}
