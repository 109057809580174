import { AUTH_KEYS } from "@/utils/auth";
import userUtil from "@/utils/user";
import storage from "@/utils/storage";
import { ConfigContext } from "antd/lib/config-provider/context";

const config = [
	{
		// auth: AUTH_KEYS.OPERATE,
		title: "目录",
		route: "/crm",
		icon: "",
		subs: [
			/* {
        auth: AUTH_KEYS.CUSTOMER_MANAGE,
        // auth: "",
        title: "客户管理",
        route: "/client",
        icon: "",
        component: "CustomerTabs",
        icon: "icon-Customer",
        autoWidth: true,
        subs: [
          {
            auth: AUTH_KEYS.CUSTOMER_MANAGE_ADD,
            // auth: "",
            title: "新增客户",
            route: "/add",
            icon: "",
            autoWidth: true,
            component: "AddCustomer"
          },
          {
            // auth: AUTH_KEYS.OPERATE_INVITATION_CLIENT_ADD,
            auth: "",
            title: "新增客户",
            route: "/newAddEdit",
            icon: "",
            autoWidth: true,
            component: "NewAddEditCustomer"
          },
          {
            auth: AUTH_KEYS.CUSTOMER_MANAGE_EDIT,
            auth: "",
            title: "编辑客户",
            route: "/edit/:id",
            icon: "",
            autoWidth: true,
            component: "EditCustomer"
          },
          {
            // auth: AUTH_KEYS.OPERATE,
            title: "客户详情",
            route: "/:id",
            icon: "",
            autoWidth: true,
            component: "CustomerDetail"
          },
          {
            title: "详情",
            route: "/newdetail/:encode",
            icon: "",
            autoWidth: true,
            component: "EnterpriseCustomerDetail"
          },
          {
            title: "升级",
            route: "/upgrade/:encode",
            icon: "",
            autoWidth: true,
            component: "EnterpriseUpgrade"
          }
        ]
      },*/
			{
				auth: AUTH_KEYS.USER_MANAGE,
				// auth: "",
				title: "用户管理",
				route: "/user",
				icon: "",
				component: "UserManager",
				icon: "icon-menu_customerx",
				autoWidth: true,
				subs: [
					{
						// auth: AUTH_KEYS.OPERATE_INVITATION_CLIENT_ADD,
						auth: "",
						title: "客户详情",
						route: "/detail/:userCode",
						icon: "",
						autoWidth: true,
						component: "UserInfo",
					},
				],
			},

			{
				auth: AUTH_KEYS.USER_MANAGE,
				// auth: "",
				title: "企业管理",
				route: "/enterprise",
				component: "EnterpriseList",
				icon: "icon-company",
				autoWidth: true,
				subs: [
					{
						auth: "",
						title: "企业信息",
						route: "/enterpriseDetail/:code",
						icon: "",
						autoWidth: true,
						component: "EnterpriseDetail",
					},
				],
			},

			{
				auth: AUTH_KEYS.GOODS_MANAGE,
				// auth: "",
				title: "商品管理",
				route: "/goodsManage",
				icon: "icon-Products",
				component: "GoodsManage",
				autoWidth: true,
				subs: [
					{
						// auth: AUTH_KEYS.OPERATE,
						title: "商品详情",
						route: "/:id",
						icon: "",
						autoWidth: true,
						component: "GoodsDetail",
					},
				],
			},
			{
				auth: AUTH_KEYS.GOODS_CATEGORY_MANAGE,
				title: "商品类目",
				route: "/category",
				icon: "icon-diantileimu",
				autoWidth: true,
				subs: [
					{
						title: "类目管理",
						route: "/backend",
						notUsHide: true,
						auth: AUTH_KEYS.GOODS_CATEGORY_MANAGE_AFTER_MANAGE,
						icon: "",
						autoWidth: true,
						component: "BackendCategory",
						subs: [
							{
								auth: AUTH_KEYS.GOODS_CATEGORY_MANAGE_AFTER_MANAGE_ADD,
								title: "新增",
								route: "/add",
								icon: "",
								autoWidth: true,
								component: "BackendCategoryAdd",
							},
							{
								auth: AUTH_KEYS.GOODS_CATEGORY_MANAGE_AFTER_MANAGE_CHANGE,
								title: "编辑",
								route: "/edit",
								icon: "",
								autoWidth: true,
								component: "BackendCategoryAdd",
							},
						],
					},
					{
						title: "频道导航",
						route: "/channelNavigation",
						auth: AUTH_KEYS.GOODS_CATEGORY_MANAGE_DAOHANG_MANAGE,
						icon: "",
						autoWidth: true,
						component: "ChannelNavList",
						subs: [
							{
								auth: "",
								title: "查看详情",
								route: "/detail",
								icon: "",
								component: "ChannelNavigationDetail",
							},
						],
					},
				],
			},
			{
				auth: AUTH_KEYS.SOURCING_MANAGE,
				title: "信息审核",
				route: "/sourcing",
				component: "SorcingManage",
				icon: "icon-hetong",
				autoWidth: true,
				subs: [
					{
						auth: "",
						title: "询价详情",
						route: "/source_detail/:id",
						icon: "",
						autoWidth: true,
						component: "SourcingDetail",
					},
					{
						auth: "",
						title: "招标详情",
						route: "/invite_tenders_detail/:id",
						icon: "",
						autoWidth: true,
						component: "InviteTendersDetail",
					},
					{
						auth: "",
						title: "设备审核详情",
						route: "/detail/:id",
						icon: "",
						autoWidth: true,
						component: "RentDetail",
					},
					{
						auth: "",
						title: "发货审核详情",
						route: "/goodsDetail/:code",
						icon: "",
						autoWidth: true,
						component: "GoodsAuditDetail",
					},
					{
						title: "运力审核详情",
						route: "/capacityDetail/:code",
						auth: "",
						icon: "",
						autoWidth: true,
						component: "CapacityAuditDetail",
					},
					{
						auth: "",
						title: "出租经营信息详情",
						route: "/LeaseOperateMsgDetail/:code",
						icon: "",
						autoWidth: true,
						component: "LeaseOperateMsgDetail",
					},
					{
						auth: "",
						title: "服务信息详情",
						route: "/serviceInformationDetail/:code",
						icon: "",
						autoWidth: true,
						component: "serviceInformationDetail",
					},
				],
			},
			{
				auth: AUTH_KEYS.ENTERPRISE_MANAGE,
				title: "认证管理",
				route: "/enterprise_auth",
				icon: "icon-menu_commercialx",
				autoWidth: true,
				component: "EnterpriseAuthLists",
				subs: [
					{
						title: "认证详情",
						route: "/detail/:code",
						auth: "",
						icon: "",
						autoWidth: true,
						component: "EnterpriseAuthDetail",
					},
					{
						title: "审核记录",
						route: "/record/:code/:is",
						auth: "",
						icon: "",
						autoWidth: true,
						component: "EnterpriseAuthRecord",
					},
					{
						title: "天眼查辅助数据",
						route: "/tianyan/:code",
						auth: "",
						icon: "",
						autoWidth: true,
						component: "TianYanCha",
					},
				],
			},
			{
				//auth: AUTH_KEYS.OPERATION_LOG_MANAGE,
				title: "咨询/反馈",
				route: "/consultList",
				icon: "icon-service",
				autoWidth: true,
				component: "ConsultList",
				notUsHide: true,
				subs: [
					{
						title: "详情",
						route: "/detail/:code",
						icon: "",
						autoWidth: true,
						component: "ConsultDetail",
					},
				],
			},

			{
				//auth: AUTH_KEYS.GOODS_MANAGE,
				// auth: "",
				title: "推荐设置",
				route: "/outstanding",
				icon: "icon-tuijian",
				autoWidth: true,
				subs: [
					{
						auth: AUTH_KEYS.MALL_CONTENT_MANAGE_BANNER_MANAGE,
						title: "Banner管理",
						route: "/banner",
						//  icon: "icon-CMS",
						autoWidth: true,
						component: "BannerManager",
					},
					{
						// auth: AUTH_KEYS.OPERATE,
						title: "商城智采",
						route: "/mall",
						icon: "",
						autoWidth: true,
						component: "Outstanding",
					},
				],
			},

			{
				//auth: AUTH_KEYS.GOODS_MANAGE,
				// auth: "",
				title: "商城设置",
				route: "/mallsetting",
				icon: "icon-shangcheng",
				autoWidth: true,
				subs: [
					{
						auth: AUTH_KEYS.MALL_CONTENT_MANAGE_BANNER_MANAGE,
						title: "业务设置",
						route: "/businesssetting",
						//  icon: "icon-CMS",
						autoWidth: true,
						component: "BusinessSetting",
					},
					{
						title: "平台公告",
						route: "/platformAnnouncement",
						autoWidth: true,
						component: "PlatformAnnouncement",
						subs: [
							{
								title: "公告",
								route: "/addOreditAnnouncement",
								autoWidth: true,
								component: "addOreditAnnouncement",
							},
							{
								title: "新闻",
								route: "/addOreditJournalism",
								autoWidth: true,
								component: "addOreditJournalism",
							},
						],
					},
				],
			},

			{
				auth: AUTH_KEYS.ENTERPRISE_ORDER_MANAGE,
				// auth: "",
				title: "订单管理",
				route: "/orderManage",
				icon: "icon-Contract",
				component: "OrderManage",
				autoWidth: true,
				subs: [
					{
						// auth: AUTH_KEYS.OPERATE,
						title: "商品详情",
						route: "/:id",
						icon: "",
						autoWidth: true,
						component: "OrderDetail",
					},

					{
						auth: "",
						title: "物流订单详情",
						route: "/logistics_detail/:code",
						icon: "",
						autoWidth: true,
						component: "LogisticsOrderDetail",
					},
					{
						auth: "",
						title: "设备租赁订单详情",
						route: "/equipment_order/detail/:code",
						icon: "",
						autoWidth: true,
						component: "EquipmentOrderDetail",
					},
					{
						auth: "",
						title: "设备租赁意向单详情",
						route: "/equipment_intention/detail/:code",
						icon: "",
						autoWidth: true,
						component: "EquipmentIntentionDetail",
					},
				],
			},
			{
				auth: '',
				// auth: "",
				title: "合同备案",
				route: "/contract",
				icon: "",
				component: "ContractFiling",
				icon: "icon-menu_customerx",
				autoWidth: true,
				subs: [
					{
						// auth: AUTH_KEYS.OPERATE_INVITATION_CLIENT_ADD,
						auth: "",
						title: "备案详情",
						route: "/detail/:code",
						icon: "",
						autoWidth: true,
						component: "ContractNewDetail",
					},
					{
						// auth: AUTH_KEYS.OPERATE,
						title: "原材料采供详情",
						route: "/contractRaw/:code",
						icon: "",
						autoWidth: true,
						component: "ContractRaw",
					},
					{
						// auth: AUTH_KEYS.OPERATE,
						title: "成品材料采供详情",
						route: "/contractFin/:code",
						icon: "",
						autoWidth: true,
						component: "ContractFin",
					},
				],
			},
			{
				auth: AUTH_KEYS.ENTERPRISE_ORDER_MANAGE,
				// auth: "",
				title: "车辆设备",
				route: "/vehicleAndEquipment",
				icon: "icon-transport",
				component: "VehicleManage",
				autoWidth: true,
				notUsHide: true,
				subs: [
					{
						// auth: AUTH_KEYS.OPERATE,
						title: "车辆管理",
						route: "/:id",
						icon: "",
						autoWidth: true,
						component: "VehicleManage",
					},
					{
						title: "车辆详情",
						route: "/VehicleDetail/:code",
						auth: "",
						icon: "",
						autoWidth: true,
						component: "VehicleDetail",
					},
					{
						// auth: AUTH_KEYS.OPERATE,
						title: "设备管理",
						route: "/:id",
						icon: "",
						autoWidth: true,
						component: "EquipmentManage",
					},
					{
						title: "订单详情",
						route: "/equipmentDetail/:code",
						auth: "",
						icon: "",
						autoWidth: true,
						component: "EquipmentDetail",
					},
				],
			},

			/**  0530版本去掉
       {
        auth: AUTH_KEYS.CUSTOMER_SOURCE_MANAGE,
        title: "客源管理",
        route: "/customer_source",
        icon: "icon-menu_commercialx",
        autoWidth: true,
        component: "CustomerSource"
      },*/
			/*
       * 0514版本去掉
      {
        auth: "", //AUTH_KEYS.OPERATE_SERVICE_SUPPLIER_SUPPLIERLISTS,
        title: "企业认证升级管理",
        route: "/enterprise_upgrade",
        icon: "icon-Shop",
        autoWidth: true,
        component: "EnterpriseUpgradeLists",
        subs: [
          {
            title: "升级详情",
            route: "/detail/:encode",
            auth: "",
            icon: "",
            autoWidth: true,
            component: "EnterpriseUpgradeDetail"
          }
        ]
      },*/
			/***  0530版本临时去掉

      {
        auth: AUTH_KEYS.SIGNING_MANAGE,
        title: "签约管理",
        route: "/contract-management",
        icon: "icon-menu_approval",
        autoWidth: true,
        component: "ContractManagementList",
        subs: [
          {
            title: "详情",
            route: "/detail/:code",
            auth: "",
            icon: "",
            autoWidth: true,
            component: "ContractManagementDetail"
          }
        ]
      },*/
			{
				auth: AUTH_KEYS.ACTIVITY_MANAGE,
				// auth: "", //AUTH_KEYS.OPERATE_SERVICE_SUPPLIER_SUPPLIERLISTS,
				title: "活动管理",
				route: "/staff-site-activity-list",
				icon: "icon-Vip",
				autoWidth: true,
				notUsHide: true,
				component: "StaffSiteActivityList",
				subs: [
					{
						title: "详情",
						route: "/detail/:code",
						auth: "",
						icon: "",
						autoWidth: true,
						component: "StaffSiteActivityDetail",
					},
				],
			},
			{
				// auth: AUTH_KEYS.ACTIVITY_MANAGE,
				// auth: "", //AUTH_KEYS.OPERATE_SERVICE_SUPPLIER_SUPPLIERLISTS,
				title: "增值服务",
				route: "/valueaddService",
				icon: "icon-pinpai",
				autoWidth: true,
				notUsHide: true,
				subs: [
					{
						title: "轨迹查询",
						route: "/trajectoryFind",
						icon: "",
						autoWidth: true,
						component: "TrajectoryFind",
						subs: [
							{
								auth: "",
								title: "添加购买",
								route: "/goBuy",
								icon: "",
								component: "BuyForm",
							},
							{
								auth: "",
								title: "代充",
								route: "/subCharge",
								icon: "",
								component: "BuyForm",
							},
							{
								auth: "",
								title: "添加记录",
								route: "/addRecord",
								icon: "",
								component: "AddRecord",
							},
							{
								auth: "",
								title: "操作记录",
								route: "/operaterRecord/:code",
								icon: "",
								component: "OperaterRecord",
							},


						],
					},
				],
			},
			{
				// auth: "",
				auth: AUTH_KEYS.HELP_CENTER_MANAGE,
				title: "帮助中心",
				route: "/helphandle",
				icon: "icon-Help",
				notUsHide: true,
				subs: [
					{
						// auth: "",
						auth: AUTH_KEYS.HELP_CENTER_MANAGE_CLASS_MANAGE,
						// title: '目录管理',
						title: "类目管理",
						route: "/catalog",
						icon: "",
						autoWidth: true,
						component: "NewCatalogTab",
					} /* {
                auth: '',
                title: '视频教程',
                route: '/video',
                icon: '',
                autoWidth: true,
                component: 'HelpVideo',
                autoWidth: true,
                subs: [{
                    auth: '',
                    title: '新增',
                    route: '/add',
                    icon: '',
                    component: 'HelpVideoAdd',
                }, {
                    auth: '',
                    title: '编辑',
                    route: '/editor/:id',
                    icon: '',
                    component: 'HelpVideoAdd',
                }]
            },*/,
					{
						auth: AUTH_KEYS.HELP_CENTER_MANAGE_QUESTION_MANAGE,
						title: "常见问题",
						route: "/issue",
						icon: "",
						autoWidth: true,
						//  component: 'HelpIssue',
						component: "HelpIssueTab",
						subs: [
							{
								auth: "",
								title: "新增",
								route: "/add",
								icon: "",
								component: "HelpIssueAdd",
							},
							{
								auth: "",
								title: "编辑",
								route: "/editor/:id",
								icon: "",
								component: "HelpIssueAdd",
							},
							{
								auth: "",
								title: "反馈",
								route: "/feedback",
								icon: "",
								component: "Feedback",
							},
						],
					},
				],
			},
			{
				auth: AUTH_KEYS.RULE_MANAGE,
				title: "规则中心",
				route: "/rulecenter",
				icon: "icon-guize",
				autoWidth: true,
				component: "RuleCenter",
				notUsHide: true,
				subs: [
					{
						title: "规则列表",
						route: "/rulelist/:code",
						auth: "",
						icon: "",
						autoWidth: true,
						component: "RuleList",
					},
					{
						auth: "",
						title: "新增",
						route: "/addRule/:categoryCode",
						icon: "",
						component: "RuleAddEdit",
					},
					{
						auth: "",
						title: "编辑",
						route: "/editRule/:categoryCode/:code",
						icon: "",
						component: "RuleAddEdit",
					},
				],
			},
			{
				auth: AUTH_KEYS.SETTING_CENTER_MANAGE,
				// auth: "",
				title: "系统设置",
				route: "/setting",
				icon: "icon-Setting",
				autoWidth: true,
				subs: [
					{
						// auth: "",
						auth: AUTH_KEYS.SETTING_CENTER_MANAGE_ACCOUNT_MANAGE,
						title: "账号管理",
						route: "/account",
						icon: "",
						autoWidth: true,
						component: "AccountManage",
						subs: [
							{
								title: "新增",
								route: "/detail",
								icon: "",
								autoWidth: true,
								component: "AccountManageDetail",
							},
							{
								title: "角色详情",
								route: "/detail/:id",
								icon: "",
								autoWidth: true,
								component: "AccountManageDetail",
							},
						],
					},
					{
						auth: AUTH_KEYS.SETTING_CENTER_MANAGE_ROLE_MANAGE,
						auth: "",
						title: "角色管理",
						route: "/role",
						icon: "",
						autoWidth: true,
						notUsHide: true,
						component: "SettingOrganizationRole",
						subs: [
							{
								// auth: AUTH_KEYS.SETTING_ORGANIZATION_ROLE_NEW,
								auth: "",
								title: "新增角色",
								route: "/new",
								icon: "",
								component: "SettingOrganizationRoleForm",
							},
							{
								// auth: AUTH_KEYS.SETTING_ORGANIZATION_ROLE_MODIFY,
								auth: "",
								title: "编辑角色",
								route: "/modify/:id",
								icon: "",
								component: "SettingOrganizationRoleForm",
							},
							{
								title: "角色详情",
								route: "/read/:id",
								icon: "",
								component: "SettingOrganizationRoleRead",
							},
						],
					},
					/*{
                // auth: AUTH_KEYS.SETTING_ORGANIZATION_STAFF,
                title: '员工管理',
                route: '/staff',
                icon: '',
                component: 'SettingOrganizationStaff',
                autoWidth: true,
                subs: [{
                    // auth: AUTH_KEYS.SETTING_ORGANIZATION_STAFF_NEW,
                    title: '新增员工',
                    route: '/new',
                    icon: '',
                    component: 'SettingOrganizationStaffForm',
                }, {
                    // auth: AUTH_KEYS.SETTING_ORGANIZATION_STAFF_MODIFY,
                    title: '编辑员工',
                    route: '/modify/:id',
                    icon: '',
                    component: 'SettingOrganizationStaffForm',
                }, {
                    title: '员工详情',
                    route: '/read/:id',
                    icon: '',
                    component: 'SettingOrganizationStaffRead',
                }]
            },*/
					{
						// auth: "",
						auth: AUTH_KEYS.SETTING_CENTER_MANAGE_POLICY_MANAGE,
						title: "决策管理",
						notUsHide: true,
						route: "/customer",
						icon: "",
						autoWidth: true,
						component: "CustomerDecision",
					},
					{
						// auth: "",
						auth: "",
						title: "部门管理",
						route: "/department",
						icon: "",
						autoWidth: true,
						component: "Department",
					},
				],
			},
			{
				auth: AUTH_KEYS.ACCOUNT_OPEN_MANAGE,
				title: "开户管理",
				route: "/account_open",
				icon: "icon-menu_template",
				autoWidth: true,
				notUsHide: true,
				component: "AccountOpenAudit",
				subs: [
					{
						title: "开户详情",
						route: "/detail/:code",
						auth: "",
						icon: "",
						autoWidth: true,
						component: "EnterpriseAccountOpeningDetail",
					},
					{
						title: "开户协议",
						route: "/agreement/:code",
						auth: "",
						icon: "",
						autoWidth: true,
						component: "AgreementPDF",
					},
					{
						title: "网货列表",
						route: "/invoiceList",
						auth: "",
						icon: "",
						autoWidth: true,
						component: "InvoiceList",
					},
					{
						title: "网货详情",
						route: "/invoiceDetail/:code",
						auth: "",
						icon: "",
						autoWidth: true,
						component: "InvoiceDetail",
					},
				],
			},
			/*  {
        auth: AUTH_KEYS.MALL_CONTENT_MANAGE,
        title: "商城内容管理",
        route: "/homeContent",
        icon: "icon-CMS",
        autoWidth: true,
        subs: [
          {
            auth: AUTH_KEYS.MALL_CONTENT_MANAGE_BANNER_MANAGE,
            title: "Banner管理",
            route: "/banner",
            icon: "icon-CMS",
            autoWidth: true,
            component: "BannerManager",
          } ,
          {
            auth: "",
            title: "一起赢商品",
            route: "/yqy_goods",
            icon: "",
            autoWidth: true,
            component: "YqyGoodsManager"
          },
        ],
      },*/
			/***  0530版本临时去掉 */
			{
				auth: AUTH_KEYS.OPERATION_LOG_MANAGE,
				title: "客户操作日志",
				route: "/operateLog",
				icon: "icon-menu_reports",
				autoWidth: true,
				component: "OperateLog",
			},

			/*,
      {
        auth: "",
        title: "测试账号",
        route: "/testAccount",
        icon: "icon-menu_reports",
        autoWidth: true,
        component: "TestAccountPage"
      }*/
			// {
			//   auth: "",
			//   title: "测试demo",
			//   route: "/testDemo",
			//   icon: "setting",
			//   autoWidth: true,
			//   component: "TestDemo"
			// }
		],
	},
];
let newConfg = [];
newConfg = JSON.parse(JSON.stringify(config));
if (!userUtil.isUs) {
	newConfg[0].subs = [];
	config[0].subs.forEach(item => {
		if (!item.notUsHide) {
			let subss = [];
			item.subs &&
				item.subs.forEach(su => {
					if (!su.notUsHide) {
						subss.push(su);
					}
				});
			item.subs = subss;
			newConfg[0].subs.push(item);
		}
	});
}
console.log(newConfg, "newConfg");

const appConfig = {
	route: "/app",
	subs: newConfg,
};

function setRoute(config = appConfig) {
	if (config.subs) {
		const baseRoute = config.route;
		for (const subConfig of config.subs) {
			subConfig.route = baseRoute + subConfig.route;
			setRoute(subConfig);
		}
	}
}

setRoute();

function getUserAuthRouteConfig(config, routeConfig) {
	// let authCodes = userUtil.getAuths()
	// let user = userUtil.get()
	// if (user && (!authCodes || authCodes.length === 0)) { // 没有权限，清storage,跳登录页
	//   storage.clear()
	//   window.location.href = '/#/login'
	//   return
	// }
	const baseRoute = config.route;
	const baseRouteConfig = {
		...config,
	};
	baseRouteConfig.subs = [];
	if (config.subs) {
		for (const subConfig of config.subs) {
			// if (!subConfig.auth || userUtil.checkAuthByKey(subConfig.auth)) {
			baseRouteConfig.subs.push(subConfig.route);
			getUserAuthRouteConfig(subConfig, routeConfig);
			// }
		}
	}

	routeConfig[baseRoute] = baseRouteConfig;
}

let userAuthRouteConfig = {};

export function refreshRoute() {
	userAuthRouteConfig = {};
	getUserAuthRouteConfig(appConfig, userAuthRouteConfig);
}

refreshRoute(); // 直接刷新页面

// console.info(userAuthRouteConfig)

export default () => userAuthRouteConfig;
