let EnterPriseType = {
  1: "建设施工",
  101: "建设施工(授权委托)",
  201: "建设施工(无资质)",
  2: "运输方",
  202: "运输方(个人)",
  5: "出租方",
  105: "出租方(授权委托)",
  205: "出租方(个人)",
  3: "供应商",
  8:"技术服务",
  108:'技术服务（授权委托）',
  208:'技术服务（个人）'
};

const EnterPriseTypeList = [
  {
    name: "建设施工",
    value: 1,
  },
  {
    name: "建设施工(授权委托)",
    value: 101,
  },
  {
    name: "建设施工(无资质)",
    value: 201,
  },
  {
    name: "运输方",
    value: 2,
  },
  {
    name: "运输方(个人)",
    value: 202,
  },
  {
    name: "出租方",
    value: 5,
  },
  {
    name: "出租方(授权委托)",
    value: 105,
  },
  {
    name: "出租方(个人)",
    value: 205,
  },
  {
    name: "供应商",
    value: 3,
  },
];

let EnterPriseStatus = {
  1: "正常",
  0: "禁用",
};

// 0-审核中,10-审核通过,20-审核拒绝
let AuditStatus = {
  0: "待审核",
  10: "审核通过",
  20: "审核拒绝",
};

function convertAuthenticationToLabel(authenticationStatus) {
  if (
    (typeof authenticationStatus != "number" && !authenticationStatus) ||
    isNaN(authenticationStatus)
  ) {
    return "-";
  }

  var label = AuditStatus[authenticationStatus];
  if (label) {
    return label;
  }

  return "-";
}
// //0-非自营  1-自营
// let SelfSupportStatus = {
//   0: "非自营",
//   1: "自营",
// };
// function convertisSelfSupport(isSelfSupport) {
//   if (
//     (typeof isSelfSupport != "number" && !isSelfSupport) ||
//     isNaN(isSelfSupport)
//   ) {
//     return "-";
//   }

//   var label = SelfSupportStatus[isSelfSupport];
//   if (label) {
//     return label;
//   }

//   return "-";
// }

function convertTypeToLabel(identitiy) {
  if ((typeof identitiy != "number" && !identitiy) || isNaN(identitiy)) {
    return "-";
  }

  var label = EnterPriseType[identitiy];
  if (label) {
    return label;
  }

  return "-";
}

function convertStatusToLabel(statusValue) {
  if ((typeof statusValue != "number" && !statusValue) || isNaN(statusValue)) {
    return "-";
  }

  var label = EnterPriseStatus[statusValue];
  if (label) {
    return label;
  }

  return "-";
}

export {
  EnterPriseType,
  EnterPriseTypeList,
  EnterPriseStatus,
  AuditStatus,
  convertAuthenticationToLabel,
  // convertisSelfSupport,
  convertTypeToLabel,
  convertStatusToLabel,
};
