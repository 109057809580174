import React, { Component } from 'react';
import axios from 'axios';
import { Upload } from 'antd';
import userUtil from "@/utils/user";

const { Dragger } = Upload;

export default class BsUpload extends Component {

    doUploadImage(value) {
        const fromData = new FormData();
        fromData.append('file', value.file);

 
        var _this = this;
        var reader = new FileReader();
        reader.readAsArrayBuffer(value.file);
        reader.onload = function () {
            // console.log(reader.result);
            _this.doNormalUpload(reader.result, value);
        };
    }

    doNormalUpload(byteArray, value) {

          axios.defaults.crossDomain = true;
           axios.defaults.withCredentials = true; //设置cross跨域 并设置访问权限 允许跨域携带cookie信息
          axios.defaults.headers.common["Authorization"] = ""; // 设置请求头为 Authorization

        var fileName = value.file.name;
        var fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1);


        axios({
            method: 'put',
            url: "/api/open/fileUpload" + (fileExtension ? ("?suffix=" + fileExtension) : ''),
            data: new Blob([byteArray]),
            headers: {
                'Content-Type': 'multipart/form-data', // 关键
            },
        }).then((res) => {
            //console.log('xxx', '  file upload-->', res);
            value.onSuccess({ file: res.data });
        }).catch(err => {
            value.onError(err);
        });

    }

    render() {
        return (
            <div>
                <Dragger {...this.props}
                    customRequest={this.doUploadImage.bind(this)}>
                    {this.props.children ? this.props.children : (<div className="ant-upload-block">
                        <p className="ant-upload-drag-icon">
                            <i className={'icon iconfont iconupload'} style={{ color: ' #4E5672', fontSize: 24 }} />
                        </p>
                        <p className="ant-upload-text">拖拽至此 或 点击上传</p>
                    </div>)}
                </Dragger>
            </div>
        )
    }
}
