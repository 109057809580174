import React, { Component } from "react";
import { Button, Modal, Input } from "antd";
import { Link } from 'react-router-dom';
import Table from "CMPT/common/Table";
import http from "@/http";
import { API } from "@/http/api";
import FilterPanel from "CMPT/common/FilterPanel";
import TableRenderHelp from "@/utils/TableRenderHelp.js";
import { FilterType } from '@/utils/fliter';
import ImgPreview from 'CMPT/common/ImgPreview';
import AddDidalog from './addDialog/index';
import "./index.less";

class InvoiceList extends Component {




  state = {
    type: -1,//目前用不上. -1 默认全部，在上传参数时  会置空
    total: 0, // 当前数据的总数
    pageNo: 1, // 当前所展示的页码
    currentPageCount: 1, //当前页面的数量
    pageSize: 10,
    filterParm: {},//备份，当分页时，可以拿出来 和 过滤板块的数据整合


    columns: this.createColumns(),//列表字段集合
    httpData: [],//列表所用数据
    filterConfig: this.createFilterConfig(),// 过滤条的配置

    showAddDialog:false,// 是否展示新增窗口
  };


  /**
   * 组件加载完成，就去查询下现有数据
   */
  componentDidMount() {
    this.goQuery();
  }




  /**
   * 字段集合
   * @returns 
   */
  createColumns() {
    let help = new TableRenderHelp();

    return [
      help.createTextColumn("企业名称", "supplierName"),
      help.createTextColumn("统一社会信用代码", "unifiedSocialCode"),
      {
        title: '联系人',
        render: (record, index) => <div>
          <span  >{(record.contactName || '') + (record.contactName ? '/' : '') + (record.contactPhone || '-')}</span>
        </div>
      },


      help.createTextColumn("申请人", "applyUserName"),
      help.createTimeColumn("申请提交时间", "applyTime"),
      help.createTextColumn("网货平台", "platformName"),
      help.createTextColumn("网货审批状态", "threePartAuditStatusName"),
      help.createTextColumn("操作人", "editUserName"),
      help.createTimeColumn("更新时间", "editDate"),
      {
        title: '操作',
        render: (record, index) =>  
    <span className="table-operate-btn">
                                    <Link to={`/app/crm/account_open/invoiceDetail/${record.code}`+ "?pageNo=" + this.state.pageNo} className="operate">查看</Link>
        </span>
        
      }
    ];

  }






  //分页
  onChangePage = (pageNo, pageSize, sort) => {
    this.setState({
      pageNo: pageNo,
      pageSize: pageSize,
    }, this.goQuery);
  };



  /**
   * 顶部的类型点击回调。 
   * @param {*} value 
   * @returns 
   */
  tabsChange = (value) => {
    let { type } = this.state;
    if (type == value) {
      return;
    }

    this.setState({ type: value }, this.goQuery);
  }

  /**
   * 接口构造参数
   * @returns 
   */
  createQueryParam = () => {
    let { pageNo, pageSize, filterParm, type } = this.state;
    let param = { pageSize, pageNo };

    if (type != -1) { // -1表示全部，也就不用传状态参数
      param.threePartAuditStatus = type;
    }

    Object.keys(filterParm).forEach(key => {
      if (filterParm[key]) {
        param[key] = filterParm[key];
      }
    });

    return param;
  }

  /**
   * 去查询
   */
  goQuery = () => {
    // setup 1:基本入参
    const config = {
      api: API.invoiceList,
      data: this.createQueryParam(),
    };

    // setup 2:请求，并初步处理
    http(config).then(res => {
      if (res && res.code==200 ) {
        this.convertHttpData(res.data);
      }
    });

  }


  refresh = () => {
    // 不修改任何 筛选条件
    this.goQuery();
 }

  /**
   * 数据转化 处理
   * @param {*} data 
   */
  convertHttpData(data) {
    var httpData = data.datas;


    this.setState({
      httpData,
      total: parseInt(data.total),
      pageNo:data.pageNo
    });
  }




  onFilterParmChange = (filterParm) => {
    this.setState({ filterParm, pageNo: 1 }, this.goQuery);// 每次搜索，从第1页取数据
  }


  createFilterConfig() {
    return {
      config: [
        this.createFilterRow()
      ],
      btnLast: true
    };
  }


  createFilterRow() {
    return {
      childList: [

        /*{
          type:FilterType.ENUM_SELECT,
          //label:'意向单类型',
          config:{
            field:'businessType',
            enumData:AuthStatus
          }
        },   */
        {
          type: FilterType.INPUT,
          //label:'意向单编号',
          config: {
            field: 'searchName',
            placeholder: '请输入企业名称,企业简称'
          }
        },


      ]
    }
  }

  /**
   * 展示窗口
   */
  showAddDialog = () => {
    this.setState({
      showAddDialog: true
    });
  }


  

  /**
   * 隐藏展示窗口
   */
  hiddenAddDialog = () => {
    this.setState({
       showAddDialog: false
    });
  }

  render() {
    let { type, columns, httpData, filterConfig, pageNo, total, showAddDialog } = this.state;

    return (
      <div className="invoice_panel">
        <div className="my_left_right_">

          <ul className="status-tab"  >
            <li className={type === -1 ? 'active' : ''} onClick={() => { this.tabsChange(-1) }}>全部</li>
            <li className={type === 0 ? 'active' : ''} onClick={() => { this.tabsChange(0) }}>未提交资料</li>
            <li className={type === 1 ? 'active' : ''} onClick={() => { this.tabsChange(1) }}>约货待审核</li>

            <li className={type === 2 ? 'active' : ''} onClick={() => { this.tabsChange(2) }}>约货已通过</li>

            <li className={type === 3 ? 'active' : ''} onClick={() => { this.tabsChange(3) }}>约货驳回</li>

          </ul>

          <Button style={{ marginLeft: "20px" }} type="primary" onClick={this.showAddDialog}  > 新增 </Button>

        </div>

        {/* <FilterPanel data={filterConfig}   onFilterChange={this.onFilterParmChange}/>  */}

        <Table
          columns={columns}
          rowKey={(record, index) => record.code}
          selfProcessData={httpData}
          total={total}
          change={this.onChangePage}
          current={pageNo}
        />

        {showAddDialog?<AddDidalog refresh={this.refresh} close={this.hiddenAddDialog}/>:''}
      </div>
    );
  }
}


export default InvoiceList;
