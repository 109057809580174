import React, { Component } from 'react';
import { Breadcrumb, Icon, Button, Modal,message } from 'antd';
import BaseInfo from './BaseInfo';
import { Link } from 'react-router-dom';
import FloorInfo from './FloorInfo';
import storage from '@/utils/storage'
import http from '@/http';
import moment from 'moment';
import AuthContainer from 'CMPT/common/auth/AuthContainer';
import { AUTH_KEYS } from '@/utils/auth';
import userUtil from '@/utils/user';
export default class ProjectAdd extends Component {

    constructor(props) {
        super(props);
        const { pathname, search } = this.props.location;
        const path = pathname.split('/');
        this.projectName = '全部项目';
        if (search) {
            const query = {};
            decodeURI(search).replace('?', '').split('&').forEach(item => {
                const kv = item.split('=');
                query[kv[0]] = kv[1];
            });
            this.projectName = query['name'];
            this.hasDraft = query['hasDraft'];
        }

        if ('edit' === path[path.length - 2]) {
            this.projectId = path[path.length - 1];
        }
        this.state = {
            constraint: {
                name: {
                    message: null,
                    status: null
                },
                address: {
                    message: null,
                    status: null
                },
                buildingArea: {
                    message: null,
                    status: null
                },
                floorArea: {
                    message: null,
                    status: null
                },
                floorCount: {
                    message: null,
                    status: null
                },
                contract:{
                    message: null,
                    status: null                   
                },
                elevatorCount:{
                    message: null,
                    status: null                      
                }
            },
            baseInfo: {//基础信息
                name: null,//项目名称
                code: null,//项目编号
                address: {//详细地址
                    province: null,//省份
                    city: null,//城市
                    district: null,
                    detail: null,//详细地址
                },
                point: null,
                cover: {//封面图片
                    mediaExt: null,
                    mediaKey: null,
                    name: null,
                    type: null,
                    url: null
                },
                buildingArea: null,//建筑面积
                floorArea: null,//占地面积
                propertyCosts: null,//物业费
                floorCount: null,//楼层数
                elevatorCount: null,//电梯数
                completionTime: null,//竣工时间
                contract: null,//联系方式
                desc: null,//项目介绍
            },
            floorInfo: [//楼层信息
                {
                    key: 1,
                    code: 1,
                    name: '1',
                    area: null,
                    price: null,
                    height: null,
                }
            ]
        }
    }

    componentDidMount() {
        if (this.projectId) {
            http({
                api: {
                    url: '/web/projects/:projectEncode',
                    type: 'get'
                },
                path: {
                    projectEncode: this.projectId
                },
                showLoading: true
            }).then(res => {
                if (res.success) {
                    const data = res.data;
                    this.parseResponseBody(data);
                }
            });
        } else {
            if (this.hasDraft == 1) {
                http({
                    api: {
                        url: '/web/draft/1',
                        type: 'get'
                    }
                }).then(res => {
                    //TODO 读取草稿
                    if (res.success) {
                        this.parseResponseBody(JSON.parse(res.data));
                    }
                })
            }
        }
    }

    cancel = () => {
        if(this.projectId){
            this.props.history.goBack();
            return false;
        }
        Modal.confirm({
            icon: false,
            content: '是否将当前内容保存为草稿？',
            okText: '保存草稿',
            onOk: () => {
                this.draftSave();
            },
            cancelText: '直接退出',
            onCancel: () => {
                this.props.history.goBack();
            }
        });
    }

    parseResponseBody = (data) => {
        const pictureInfoList = data.pictureInfoList[0] || {
            mediaExt: null,
            mediaKey: null,
            name: null,
            type: null,
            url: null
        };
        const baseInfo = {//基础信息
            name: data.name,//项目名称
            code: data.projectNo,//项目编号
            address: {//详细地址
                province: {
                    code: data.provinceEncode,
                    name: data.provinceName,
                },//省份
                city: {
                    code: data.cityEncode,
                    name: data.cityName,
                },//城市
                district: {
                    code: data.regionEncode,
                    name: data.regionName,
                },
                detail: data.address//详细地址
            },
            point: {
                lat: data.coordinate[0],
                lng: data.coordinate[1]
            },
            cover: pictureInfoList,//封面图片
            buildingArea: data.buildingArea,//建筑面积
            floorArea: data.area,//占地面积
            propertyCosts: data.propertyFee,//物业费
            floorCount: data.totalFloorCnt,//楼层数
            elevatorCount: data.elevatorQuantity,//电梯数
            completionTime: data.completionDate&&moment(data.completionDate)||null,//竣工时间
            contract: data.phone,//联系方式
            desc: data.introduction,//项目介绍
        };

        let floorInfo = data.floors.map(item => ({
            key: item.encode,
            code: item.floorNo,
            name: item.name,
            area: item.area,
            price: item.price,
            height: item.height,
            encode: item.encode
        }));
        if (!floorInfo || floorInfo.length == 0) {
            floorInfo = [
                {
                    key: 1,
                    code: 1,
                    name: '1',
                    area: null,
                    price: null,
                    height: null,
                }
            ]
        }
        this.setState({
            baseInfo: baseInfo,
            floorInfo: floorInfo
        });
    }

    buildRequestBody = () => {
        const { baseInfo, floorInfo } = this.state;
        const address = baseInfo.address;
        const { province, city, district } = address;
        const { point } = baseInfo;
        if(!point) {
            message.error('请选择详细地址');
            return false
        }
        const { lat, lng } = point||{}
        return {
            address: address.detail,
            area: baseInfo.floorArea,
            buildingArea: baseInfo.buildingArea,
            cityEncode: city && city.code,
            cityName: city && city.name,
            // "companyEncode": "string",
            completionDate: baseInfo.completionTime,
            coordinate: [lat, lng],
            elevatorQuantity: parseInt(baseInfo.elevatorCount),
            encode: this.projectId,
            floors: [
                ...floorInfo.map((item,index) => {
                    return {
                        area: item.area,
                        // "companyId": 0,
                        // createBy: 0,
                        floorId: parseInt(item.key),
                        floorNo: item.code,
                        height: parseFloat(item.height),
                        // modifiedBy: 0,
                        name: item.name,
                        price: parseFloat(item.price),
                        projectId: parseInt(baseInfo.code),
                        sort: index,
                        encode: item.encode
                    }
                })
            ],
            pictureInfoList: [
                baseInfo.cover
            ],
            introduction: baseInfo.desc,
            name: baseInfo.name,
            phone: baseInfo.contract||null,
            projectNo: baseInfo.code||null,
            propertyFee: parseFloat(baseInfo.propertyCosts),
            provinceEncode: province && province.code,
            provinceName: province && province.name,
            regionEncode: district && district.code,
            regionName: district && district.name,
            totalFloorCnt: parseInt(baseInfo.floorCount)
        };
    }

    checkRequestConstraint = () => {
        let result = true;
        function checkByKey(key, value) {
            if (value!==''&&value!==null) {
                constraint[key] = {
                    status: null,
                    message: null
                }
                return true;
            }
            constraint[key] = {
                status: 'error',
                message: '必填'
            }
            return false;
        }
        const {
            name,
            address,
            buildingArea,
            floorArea,
            floorCount,
        } = this.state.baseInfo;
        const constraint = { ...this.state.constraint };
        if (!checkByKey('name', name)) {
            result = false;
        }
        if (!checkByKey('buildingArea', buildingArea)) {
            result = false;
        }
        if (!checkByKey('floorArea', floorArea)) {
            result = false;
        }
        if (!checkByKey('floorCount', floorCount)) {
            result = false;
        }
        if (!address || !address.province || !address.city || !address.district || !address.detail) {
            constraint.address = {
                status: 'error',
                message: '必填'
            }
            result = false;
        }else{
            constraint.address = {
                status: null,
                message: null
            }
        }


        this.setState({
            constraint: constraint
        });

        return result;
    }

    checkPatternConstraint = () => {
        const patternObj = {
            'name':{
                reg:/^[\w\W]{2,15}$/,
                message:'项目名称2-15个字符'
            },
           'buildingArea':{
                reg:/^([1-9]|[0-9]{2,8}(\.\d{1,2})?)$/,
                message:'建筑面积为1-99999999㎡'
           },
           'floorArea':{
                reg:/^([1-9]|[0-9]{2,8}(\.\d{1,2})?)$/,
                message:'占地面积为1-99999999㎡'
            },
            'elevatorCount':{
                reg:/^\d{0,3}$/,
                message:'电梯数应为整数'                
            },
            'contract':{
                reg:/^\d{7,14}$/,
                message:'联系方式应为7-14位数字'  
            },
            'floorCount':{
                reg:/^([1-9]|[0-9]{2,3})$/,
                message:'总楼层1-999'                  
            }
        }
        // const {
        //     buildingArea,
        //     floorArea,
        //     elevatorCount,
        //     floorCount,
        //     contract
        // } = this.state.baseInfo;
        const baseInfoObj =  this.state.baseInfo;
        const constraint = { ...this.state.constraint };
        let result = true;
        const keyArray = Object.keys(patternObj);
        keyArray.forEach(key => {
            if(baseInfoObj[key]!==''&&baseInfoObj[key]!==null&&baseInfoObj[key]!==undefined){
                if(!patternObj[key].reg.test(baseInfoObj[key])){
                    constraint[key] = {
                        status: 'error',
                        message: patternObj[key].message
                    }
                    result = false
                }else{
                    constraint[key] = {
                        status: null,
                        message: null
                    }                    
                }
            }            
        })
        // if(buildingArea!==''&&buildingArea!==null){
        //     if(!patternObj.buildingArea.reg.test(buildingArea)){
        //         constraint['buildingArea'] = {
        //             status: 'error',
        //             message: patternObj.buildingArea.message
        //         }
        //         result = false
        //     }
        // }
        // if(floorArea!==''&&floorArea!==null){
        //     if(!patternObj.floorArea.reg.test(floorArea)){
        //         constraint['floorArea'] = {
        //             status: 'error',
        //             message: patternObj.floorArea.message
        //         }
        //         result = false
        //     }
        // }
        // if(floorCount!==''&&floorCount!==null){
        //     if(!patternObj.floorCount.reg.test(floorCount)){
        //         constraint['floorCount'] = {
        //             status: 'error',
        //             message: patternObj.floorCount.message
        //         }
        //         result = false
        //     }
        // }
        // if(floorCount!==''&&floorCount!==null){
        //     if(!patternObj.floorCount.reg.test(floorCount)){
        //         constraint['floorCount'] = {
        //             status: 'error',
        //             message: patternObj.floorCount.message
        //         }
        //         result = false
        //     }
        // }

        this.setState({
            constraint: constraint
        });
        
        return result;     
    }

    checkFloorConstraint = () => {
        let result = true;
        const floorInfo = [...this.state.floorInfo];
        function checkByKey(item, key) {
            if (!item[key]) {
                item.constraint = {
                    ...item.constraint,
                    [key]: {
                        message: '必填',
                        status: 'error'
                    }
                }
            }
        }
        const floorCodeMap = {};
        const checkResult = floorInfo.map((item, index) => {
            item.constraint = null;
            if (item.code) {
                let indexArr = floorCodeMap[item.code];
                if (!indexArr) {
                    floorCodeMap[item.code] = indexArr = [];
                }
                indexArr.push(index);
            }
            checkByKey(item, 'code');
            checkByKey(item, 'name');
            checkByKey(item, 'area');
            checkByKey(item, 'price');
            return item;
        }).filter(item => item.constraint);
        if (checkResult.length > 0) {
            result = false;
        }
        for (let item in floorCodeMap) {
            const items = floorCodeMap[item];
            if (items.length > 1) {
                for (let i = 1; i < items.length; ++i) {
                    const newItem = floorInfo[items[i]];
                    newItem.constraint = {
                        ...newItem.constraint,
                        code: {
                            message: '楼层已存在',
                            status: 'error'
                        }
                    }
                }
                result = false;
            }
        }
        if (!result) {
            this.setState({ floorInfo: floorInfo });
        }
        return result;
    }

    submit = () => {

        if(!this.checkPatternConstraint()){
            return ;
        }
        if (!this.checkRequestConstraint()) {
            return;
        }
        if (!this.checkFloorConstraint()) {
            return;
        }


        const api = this.projectId ? {
            url: '/web/projects',
            type: 'put'
        } : {
                url: '/web/projects',
                type: 'post'
            };
        const body = this.buildRequestBody()
        if(!body) return false
        http({
            api: api,
            data: body,
            showLoading: true
        }).then(res => {
            if (res.success) {
                if (this.projectId) {
                    this.props.history.push("/app/project/detail");
                } else {
                    Modal.confirm({
                        icon: false,
                        content: '提交成功，是否继续添加区域？',
                        okText: '立即添加',
                        onOk: () => {
                            this.props.history.push('/app/project/region/add/' + res.data.projectEncode);
                        },
                        cancelText: '暂不添加',
                        onCancel: () => {
                            storage.set('tabKey','2')
                            this.props.history.push({ pathname: `/app/project/detail/${res.data.projectEncode}`})
                        }
                    });
                }
            }

        });
    }

    /**
     * 保存草稿
     */
    draftSave = () => {
        http({
            api: {
                url: '/web/draft/save',
                type: 'post'
            },
            data: {
                content: JSON.stringify(this.buildRequestBody()),
                bizType: 1
            },
            showLoading: true
        }).then(res => {
            if (res.success) {
                Modal.info({
                    icon: false,
                    content: '草稿保存成功，下次新建项目时可使用该草稿',
                    okText: '我知道了',
                    onOk: () => {

                    },
                });
            }
        }).catch(err => {
            console.log(err);
        });
    }

    onBaseInfoChange = (value) => {
        // console.log(value)
        this.setState({
            ...this.state,
            baseInfo: value
        });
    }
    mapMove=(value)=>{
        console.log(value)
        this.setState({            
            baseInfo: value
        });
    }
    onFloorInfoChange = (value) => {
        this.setState({
            ...this.state,
            floorInfo: value
        });
    }
    deleteProject = () => {
        Modal.confirm({
            icon: false,
            content: '删除项目后统计数据中将不统计已删除项目的数据，操作无法恢复，确认删除？',
            okText: '删除',
            onOk: () => {
                http({
                    api: {
                        url: '/web/projects/:projectEncode',
                        type: 'delete'
                    },
                    path: {
                        projectEncode: this.projectId,
                    },
                    showLoading: true
                }).then(res => {
                    if (res.success) {
                        this.props.history.push("/app/project/detail");
                    }
                }).catch(err => {

                });
            },
            cancelText: '取消',
            onCancel: () => {

            }
        });
    }

    render() {
        const { baseInfo, floorInfo, constraint } = this.state;
        return (
            <div className="project-add-page" style={{ padding: "0 24px" ,width:'1200px'}}>
                <Breadcrumb>
                    <Breadcrumb.Item>
                    <Link style={{color:'#333'}} to={{ pathname: "/app/project/detail"}}>
                        {this.projectName || '全部项目'}                        
                    </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{this.projectId ? '编辑项目' : '新建项目'}</Breadcrumb.Item>
                </Breadcrumb>
                <AuthContainer key="1" authCodes={AUTH_KEYS.PROJECT_DELETE}>                                       
                    {this.projectId ? (<Button type="danger" onClick={this.deleteProject} className="btn-delete-project">删除项目</Button>) : null}
                </AuthContainer> 
                <BaseInfo constraint={constraint} dataSource={baseInfo} onChange={this.onBaseInfoChange} moveMap={this.mapMove}/>
                <FloorInfo dataSource={floorInfo} onChange={this.onFloorInfoChange} />
                <div className="btn-group-opration">
                    <AuthContainer key="1" authCodes={AUTH_KEYS.PROJECT_EDIT}>                                                               
                        <Button type="primary" onClick={this.submit}>{this.projectId ? '重新提交' : '提交'}</Button>
                    </AuthContainer>
                    {this.projectId ? null : <Button type="link" onClick={this.draftSave}>保存草稿</Button>}
                    <span onClick={this.cancel}>取消</span>
                </div>
            </div>
        );
    }
}

