import React, { Component } from 'react';
import { Breadcrumb, Radio, Switch, Input, InputNumber, Button, message } from 'antd';
import regex from '@/utils/regex';
class ContractDefault extends Component {
    state = {
        setting: {}
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            setting: nextProps.setting
        })
    }
    checkResult = (attr, value) => {
        let setting = this.state.setting
        setting[attr] = value
        this.setState({setting})
    }
    selectDefault = (attr, e) => {
        let setting = this.state.setting
        setting[attr] = e.target.value
        this.setState({setting})
    }
    checkValid = (e) => {
        if (!regex.characterOrNum.test(e.target.value)) {
            message.warning('只能输入字母或数字')
            // let setting = this.state.setting
            // setting.serverNumber = ''
            // this.setState({setting})
        }
    }
    render() {
        const { isDefault } = this.props
        const { setting } = this.state
        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
        };
        return (
            <div className="contract-default">
                <div className="default-top" style={{display: isDefault ? 'none' : 'block'}}>
                    <p>是否使用默认决策配置<span>*</span></p>
                    <Radio.Group value={setting.chooseDefault} onChange={this.selectDefault.bind(this, 'chooseDefault')}>
                        <Radio style={radioStyle} value='1'>
                            是
                        </Radio>
                        <Radio style={radioStyle} value='0'>
                            否
                        </Radio>
                    </Radio.Group>
                </div>
                <div className="default-content" style={{display: !isDefault && setting.chooseDefault === '1' ? 'none' : 'block'}}>
                    <div className="block">
                        <div className="title">自定义合同编号</div>
                        <div className="check">
                            {setting.serverNumberOn ? '开启' : '关闭'} <Switch checked={setting.serverNumberOn} onChange={this.checkResult.bind(this, 'serverNumberOn')} />
                        </div>
                        <div className="rule" style={{display: setting.serverNumberOn ? 'block' : 'none'}}>
                            <Input placeholder="自定义标示" value={setting.serverNumber} onBlur={this.checkValid} maxLength={10}
                                   onChange={this.selectDefault.bind(this, 'serverNumber')} />
                            <span>+ 年/月/日 + 序号 （示例：idf10-20190422-1）</span>
                        </div>
                    </div>
                    <div className="block">
                        <div className="title">物业条款计费方式</div>
                        <Radio.Group value={setting.estateCalculateMethod} onChange={this.selectDefault.bind(this, 'estateCalculateMethod')}>
                            <Radio style={radioStyle} value='1'>
                                按月计费
                            </Radio>
                            <Radio style={radioStyle} value='2'>
                                按实际天数计费
                            </Radio>    
                        </Radio.Group>
                    </div>
                    <div className="block">
                        <div className="title">提前付款天数</div>
                        <div className="input-unit">
                            <InputNumber value={setting.aheadDays} placeholder="请输入" onChange={this.checkResult.bind(this, 'aheadDays')} min={0} />
                            <span className="unit">天</span>
                        </div>
                    </div>
                    <div className="block">
                        <div className="title">滞纳金</div>
                        <div className="input-unit">
                            <InputNumber value={setting.delayPayment} placeholder="请输入" onChange={this.checkResult.bind(this, 'delayPayment')} min={0} />
                            <span className="unit">%</span>
                        </div>
                    </div>
                    <div className="block">
                        <div className="title">物业单价</div>
                        <div className="input-unit unit-padding">
                            <InputNumber value={setting.estatePrice} placeholder="请输入" onChange={this.checkResult.bind(this, 'estatePrice')} precision={2} min={0} />
                            <span className="unit">元/m²·月</span>
                        </div>
                    </div>
                    <div className="block">
                        <div className="title">租期划分方式</div>
                        <div className="input-unit">
                            <Radio.Group value={setting.periodDevideMethod} onChange={this.selectDefault.bind(this, 'periodDevideMethod')}>
                                <Radio style={radioStyle} value='1'>
                                    按自然周期划分
                                </Radio>
                                <Radio style={radioStyle} value='2'>
                                    按合同周期划分
                                </Radio>
                            </Radio.Group>
                        </div>
                    </div>
                </div>
                <div className="default-bottom">
                    <Button type="primary" onClick={this.props.save.bind(this, setting)}>保存</Button>
                </div>
            </div>
        );
    }
}

export default ContractDefault;