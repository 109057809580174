import React, { Component } from 'react';
import { Layout, Menu, Breadcrumb, Button, Tooltip, Switch, message, Modal } from 'antd';
import { Link } from 'react-router-dom'
import { numberFormat } from '@/utils/fliter';
import CustomerSearch from './CustomerSearch';
import IntentionTable from './IntentionTable';
import { industryTypeConfig } from './intentionConfig';
import { sourceTypeConfig, statusConfig, customerTypeConfig, dateTypeConfig } from './customerConfig'
import { objectarrayToArray } from '@/utils/fliter';
import { changeObjkey } from '@/utils/index';
import moment from 'moment';
import { API } from '@/http/api';
import http from '@/http';
import { customerInit, areaInit, areaProvincesCity, customerAdd, customerGetList } from './service/customerService';
import AssignFollower from './AssignFollower';
import AddFollow from './AddFollow';
import {
    AUTH_KEYS
} from '@/utils/auth';
import userUtil from "@/utils/user";
import './intention.less';

const { confirm } = Modal;

const { Header, Content, Footer } = Layout;

const customerTypeConfigArray = objectarrayToArray(customerTypeConfig),
    statusConfigArray = objectarrayToArray(statusConfig),
    dateTypeArray = objectarrayToArray(dateTypeConfig);

class OperateClient extends Component {

    componentWillMount(){
        if(!userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_CLIENT_DISPATCH)&&userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_CLIENT_DISPATCH_BATCH)){
            this.setState({
                assign:{
                    visible:false,
                    batch:true,
                    id:[]                       
                }
            })
        }
    }

    componentDidMount() {
        this.getCustomer();
        // 获取城市下拉数据
        areaProvincesCity().then(res => {
            this.setState({
                cityTypeConfigArray: res
            })
        })
    }

    getCustomer = () => {
        this.getCustomerList()
    }

    state = {
        fetching:true,
        customerTypeConfigArray: customerTypeConfigArray,
        cityTypeConfigArray: [],
        statusConfigArray: statusConfigArray,
        dateTypeArray: dateTypeArray,
        searchObj: {
            fuzzyKey: undefined,
            acountStatus: undefined,
            acountType: undefined,
            contractType: undefined,
            cityEncode: undefined,
            contractStartDate: undefined,
            contractEndDate: undefined
        },
        orderByList: 2,
        columns: [
            {
                title: '公司码',
                dataIndex: 'companyId',
                width: '8%'
            }, 
            {
                title: '企业名称',
                dataIndex: 'enterpriseName',
                render: (text, record, index) => <a className="text-color-highlight" href="javascript:;" onClick={() => this.props.history.push('/app/crm/client/' + record.encode)}>{text}</a>,
                width: '12%',
            },
            {
                title: '城市',
                dataIndex: 'cityName',
                width: '10%',
            },
            {
                title: '联系人',
                dataIndex: 'contactName',
                width: '9%',
            },
            {
                title: '联系方式',
                dataIndex: 'contactPhone',
                width: '8%',
            },
            {
                title: '账号类型',
                dataIndex: 'acountType',
                width: '10%',
                render: acountType =>
                    <span>
                        {acountType==1 ? '正式' : '试用'}
                    </span>
            },
            {
                title: '状态',
                dataIndex: 'acountStatus',
                width: '10%',
                render: (text, record, index) => {
                    const { encode, stateLoading ,enterpriseName} = record;
                    const checked = !!text;
                    return <><span className='status-tip' style={{marginRight:'16px'}}>{checked ? '启用' : '停用'}</span><Switch checked={checked} loading={stateLoading} onChange={() => this.showConfirm(checked, encode, index,enterpriseName)} /></>
                }
            },
            {
                title: '合同开始时间',
                dataIndex: 'contractStartDate',
                width:'12%',
                render: createTime =>
                    <span>
                    { createTime?moment(createTime).format('YYYY-MM-DD'):'-' }
                    </span>,
                // sorter: true,
            },
            {
                title: '合同结束时间',
                dataIndex: 'contractEndDate',
                width:'12%',
                render: createTime =>
                    <span>
                        { createTime?moment(createTime).format('YYYY-MM-DD'):'-' }
                    </span>,
                // sorter: true,
            },
            {
                title: '操作',
                width: '9%',
                render: record => 
                    <div>  
                        <a className="text-color-highlight" href="javascript:;" onClick={() => this.props.history.push('/app/crm/client/edit/' + record.encode)} >编辑</a>
                        <a className="text-color-highlight" href="javascript:;" onClick={() => this.showDeleteConfirm(record)}>删除</a>
                        {/* { this.checkEditAuth(record) ? <a className="text-color-highlight" href="javascript:;" onClick={() => this.props.history.push('/app/client/invitation/client/edit/' + record.encode)} >编辑</a> : null}  
                        { this.checkDispatchAuth(record) ? <a className="text-color-highlight" href="javascript:;" onClick={() => this.showDeleteConfirm(record)}>删除</a> : null } */}
                    </div>
            }
        ],
        showDeleteConfirmFlag:false,
        deleteLoading:false,
        currentCustomerId:{
            current:null
        },
        data: [

        ],
        pageSize: 10,
        pageNo: 1,
        total: 0,
        selectRows: [],//用于批量处理
        checkRows: [],//用于单选
        assign: {
            visible: false,
            batch: false,
            id: []
        },
        follow: {
            visible: false,
            batch: false,
            id: []
        }
    }

    getSearchChange = (searchParams) => {
        let _searchParams = JSON.parse(JSON.stringify(searchParams));
        if (searchParams.rangeDate.length > 0) {
            _searchParams.contractStartDate = searchParams.rangeDate[0];
            _searchParams.contractEndDate = searchParams.rangeDate[1];
        } else {
            _searchParams.contractStartDate = undefined;
            _searchParams.contractEndDate = undefined;
        }
        console.log(searchParams);
        console.log(_searchParams);
        this.setState({
            searchObj: {
                fuzzyKey: _searchParams.fuzzyKey,
                acountType: _searchParams.acountType,
                acountStatus: _searchParams.acountStatus,
                cityEncode: _searchParams.cityEncode,
                contractType: _searchParams.contractType,
                contractStartDate: _searchParams.contractStartDate,
                contractEndDate: _searchParams.contractEndDate
            },
            pageNo:1
        }, () => this.getCustomerList())
    }

    // 启用、停用
    changeCustomerStatus = (checked, encode, index,enterpriseName) => {
        let ids = [];
        ids.push(encode);
        http({
            api: API.customerOperate,
            data: {
                acountStatus: checked ? 0 : 1,
                ids:ids
            },
            showLoading: true,
            successTip: '操作成功'
        }).then(result => {
            if (result.success) {
                // person.status = checked;
                this.getCustomerList();
            }
        }).catch(error => {
            
        });
    }

    showConfirm(checked, encode, index,enterpriseName) {
        let title = !checked ? '启用' : '停用';
        let self = this;
        confirm({
          title: `确认${title}【${enterpriseName}】的OMS系统账号？`,
          content: '',
          onOk() {
            self.changeCustomerStatus(checked, encode, index,enterpriseName)
          },
          onCancel() {},
        });
      }

    checkEditAuth = (record)=>{
        let userInfo = userUtil.get()
        if(!userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_CLIENT_EDIT)){
            return false
        }else{
            if(userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_CLIENT_EDIT_ALL)){
                return true
            }
            else if(userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_CLIENT_EDIT_SELF)&&userInfo.idCode==record.userEncode){
                return true
            }else{
                return false
            }
        }
    }

    checkDispatchAuth = record => {
        let userInfo = userUtil.get()
        if(!userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_CLIENT_DISPATCH)){
            return false
        }else{
            if(userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_CLIENT_DISPATCH_ALL)){
                return true
            }
            else if(userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_CLIENT_DISPATCH_SELF)&&userInfo.idCode==record.userEncode){
                return true
            }else{
                return false
            }
        }        
    }

    checkFollowAuth = record => {
        let userInfo = userUtil.get()
        if(!userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_CLIENT_FOLLOW)){
            return false
        }else{
            if(userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_CLIENT_FOLLOW_ALL)){
                return true
            }
            else if(userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_CLIENT_FOLLOW_SELF)&&userInfo.idCode==record.userEncode){
                return true
            }else{
                return false
            }
        }        
    }

    // 显示删除弹框
    showDeleteConfirm = (record) => {
        console.log(record)
        this.setState({
            showDeleteConfirmFlag:true,
            currentCustomerId:{
                current:record.encode
            }
        })
    }
    // 删除
    deleteCustomer = () => {
        const currentCustomerId = this.state.currentCustomerId;
        if (currentCustomerId.current) {
            this.setState({
                deleteLoading:true
            })
            http({
                api: API.customerDelete,
                path: {
                    encode: currentCustomerId.current
                },
                showLoading: true,
                successTip: '删除成功'
            }).then(result => {
                this.setState({
                    deleteLoading:false
                })
                if (result.success) {
                    this.hideDeleteConfirm();
                    this.getCustomerList();
                }
            }).catch(erorr => {
                this.setState({
                    deleteLoading:false
                })
            });
        }
    }
    // 隐藏删除弹框
    hideDeleteConfirm = () => {
        this.setState({
            showDeleteConfirmFlag:false,
            currentCustomerId:{
                current:null
            }
        })
    }

    editCustomerStatus = record => {
        this.setState({
            assign: {
                visible: true,
                batch: false,
                id: [record]
            }
        })
    }

    editFollow = record => {
        this.setState({
            follow: {
                visible: true,
                id: [record]
            }
        })
    }

    rowSelectionChange = (selectedRowKeys) => {
        this.setState({
            selectRows: selectedRowKeys
        })
    }


    onChange = (page, pageSize, sort) => {
        console.log(sort);
        let order = sort && sort.order == 'ascend' ? 1 : 2
        this.setState({
            pageNo: page,
            pageSize: pageSize,
            orderByList: order
        }, () => this.getCustomerList())

    }

    getCustomerList = () => {
        let postData = JSON.parse(JSON.stringify(this.state.searchObj));
        console.log(postData)
        // debugger
        postData.pageSize = this.state.pageSize;
        postData.pageNo = this.state.pageNo;
        // postData.orderByList = [this.state.orderByList];
        customerGetList(postData).then(res => {
            if (res.code == 0) {
                let data = res.data
                let userInfo = userUtil.get();
                this.setState({
                    pageNo: data.pageNo,
                    pageSize: data.pageSize,
                    data: data.datas.map(item=>{
                        item.dispatchBatch = false;
                        if(!userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_CLIENT_DISPATCH_BATCH)){
                            item.dispatchBatch = false;
                        }else{
                            if(userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_CLIENT_DISPATCH_BATCH_ALL)){
                                item.dispatchBatch = true
                            }
                            else if(userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_CLIENT_DISPATCH_BATCH_SELF)&&userInfo.idCode==item.userEncode){
                                item.dispatchBatch = true
                            }else{
                                item.dispatchBatch = false
                            }                            
                        }
                        // console.log(item);
                        return item;
                    }),
                    total: data.totalRecord
                })
            }
        })
    }

    assign = () => {
        this.setState({
            assign: {
                visible: !this.state.assign.visible,
                batch: this.state.assign.batch,
                id: this.state.assign.id
            }
        })
    }

    follow = () => {
        this.setState({
            follow: {
                visible: !this.state.follow.visible,
                id: this.state.follow.id
            }
        })
    }
    // 启用
    onBatch = () => {
        this.assignBatch(0);
    }
    // 批量停用
    offBatch = () => {
        this.assignBatch(1);
    }
    // 启用停用
    assignBatch = (flag) => {
        if (this.state.selectRows.length <= 0) {
            message.warning('当前没选中任何行');
            return;
        }
        let ids = [];
        let companyNames = [];
        this.state.selectRows.map((item,index) => {
            ids.push(item.encode)
            companyNames.push(item.enterpriseName)
        })
        this.setState({
            assign: {
                visible: !this.state.assign.visible,
                batch: true,
                ids: ids,
                companyNames: companyNames,
                flag:flag
            }
        })
    }
    // 
    assignCancel = () => {
        this.setState({
            assign: {
                visible: false,
            }
        })
    }

    render() {
        const tableConfig = {
            columns: this.state.columns,
            data: this.state.data,
            current: this.state.pageNo,
            total: this.state.total,
            pageSize: this.state.pageSize
        }
        return (
            <Content className="intention-list intention-content">
                <div className="intention-list-head newflexbox">
                    <Breadcrumb className="intention-list-head-bread">
                        <Breadcrumb.Item>
                            <Link style={{ color: '#333' }} to={{ pathname: "/app/crm/client" }}>
                                客户管理
                            </Link>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <ul className="intention-list-head-data">
                        <li>
                            {/* {userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_CLIENT_ADD) ?  */}
                            <Button type="primary"      history={this.props.history}  onClick={() => this.props.history.push('/app/crm/client/add')}>新增</Button>
                             {/* : null} */}
                        </li>
                    </ul>
                </div>

                <div className="intention-body">
                    <CustomerSearch customerTypeConfigArray={this.state.customerTypeConfigArray} fetching={this.state.fetching} cityTypeConfigArray={this.state.cityTypeConfigArray} statusConfigArray={this.state.statusConfigArray} dateTypeArray={this.state.dateTypeArray} defaultObj={this.state.searchObj} changeParam={this.getSearchChange} />
                    <IntentionTable tableConfig={tableConfig} rowSelectionChange={this.rowSelectionChange} onChange={this.onChange} onBatch={this.onBatch} offBatch={this.offBatch} getCustomerList={this.getCustomerList} hasAuth={userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_CLIENT_DISPATCH_BATCH)} />
                </div>
                {/* {userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_CLIENT_DISPATCH)||userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_CLIENT_DISPATCH_BATCH) ?   */}
                <AssignFollower visible={this.state.assign.visible} ids={this.state.assign.ids} companyNames={this.state.assign.companyNames} status={this.state.assign.status} companyName={this.state.assign.name} flag={this.state.assign.flag} assignCancel={this.assignCancel} batch={this.state.assign.batch} getCustomerList={this.getCustomer} isCustomer={true} />
                 {/* : null } */}
                {/* <AddFollow visible={this.state.follow.visible} id={this.state.follow.id} followCancel={this.follow} isCustomer={true}/> */}
                
                <Modal
                    title="确认删除数通十方的企业账号？"
                    content="删除后，该公司码下管理员账号及所有子账号将不能使用"
                    visible={this.state.showDeleteConfirmFlag}
                    okText='删除'
                    confirmLoading={this.state.deleteLoading}
                    closable={false}
                    maskClosable={false}
                    onOk={this.deleteCustomer}
                    width={400}
                    onCancel={this.hideDeleteConfirm}
                >
                </Modal>
            </Content>
        );
    }
}

export default OperateClient;
