import React, { Component } from 'react';
import { Breadcrumb, Button,Row,Radio,Form,message} from 'antd';
import Region from './newComponent/Region'
import Tenant from './newComponent/Tenant'
import BillInfo from './newComponent/BillInfo'
import { Link } from 'react-router-dom';
import PayInfo from './newComponent/PayInfo'
import CollectionInfo from './newComponent/CollectionInfo'
import moment from 'moment';

import http from '@/http';
import { API } from '@/http/api';

import 'moment/locale/zh-cn';

import "../financeApproval/finance.less"
import './bill.less'

moment.locale('zh-cn');
const BreadcrumbItem = Breadcrumb.Item;
const Item = Form.Item;
const iInfo = {
    billCategoryMap:"实收",
    payDate:"应收时间",
    paidDate:"实收时间",
    customerName:'付款人',
    customerPhone:'付款人手机号',
    paymentMessage:{
        companyBank:'收款银行',
        companyAccount:'收款账号',
        description:'收款备注',
        bank:'付款银行',
        account:'付款账号',

    },
    title1:"收款信息",
    title2:"付款信息",
}
const oInfo = {
    billCategoryMap:"实付",
    payDate:"应付时间",
    paidDate:"实付时间",
    customerName:'收款人',
    customerPhone:'收款人手机号',
    paymentMessage:{
        companyBank:'付款银行',
        companyAccount:'付款账号',
        description:'付款备注',
        bank:'收款银行',
        account:'收款账号',

    },
    title1:"付款信息",
    title2:"收款信息",
}

class NewInBill extends Component {
    state = {
        shouDes:{
            t1:'实收', 
            t2:'应收时间',
            t3:'实收时间',
            t4:'收款信息',
            t5:'付款信息'
        },
        billType:1,
        projects:[],
        cascaderData: [],
        projectEncode2:undefined,
        textDes:{},
        resetBill:{
            canEdit: true
        },
        reset:false,
        billEncode:'',
        loading:false,
    }
    componentWillMount(){
        if(this.props.location.search){
            // let arr = this.props.location.search.substr(1).split('&');
            const search = this.props.location.search
            const formatData = this.formatSearch(search)
            let type = undefined;
            let billEncode = undefined;
            if(formatData.type){
                type = formatData.type;
            }
            if(formatData.billEncode){
                billEncode = formatData.billEncode;
                this.setState({
                    billEncode:billEncode,
                    reset:true
                })
            }
            if (formatData.coCode) { // 合同code，表明是从合同页跳转而来
                let resetBill = {}
                resetBill.mergeCode = `${formatData.proCode},${formatData.coCode}`
                resetBill.customerEncode = formatData.cuCode
                resetBill.customerObj = `${formatData.cuCode},${decodeURI(formatData.tName)}`
                resetBill.billType = 2
                resetBill.canEdit = false
                this.setState({
                    resetBill,
                    billType: 2
                })
            }
            if(type == 1){
                this.setState({
                    textDes:iInfo
                })
            }
            else if(type == 2){
                this.setState({
                    textDes:oInfo
                })
            }
            else{
                this.props.history.push({pathname:'/404'});
            }    
        }
    }
    componentDidMount(){
        if(this.state.reset){
            this.getResetInfo();
        }
    }
    formatSearch = (ss) => {
        const ssSplit = ss.split('?')[1].split('&')
        let obj = {}
        ssSplit.forEach(per => {
            let perSplit = per.split('=')
            obj[perSplit[0]] = perSplit[1]
        })
        return obj
    }
    getResetInfo(){
        http({
            api:API.billGetById,
            data:{
                encode:this.state.billEncode
            }
        }).then((res)=>{
            if(res.code == '0'){
                let data = res.data;
                data.beginEndDate = [data.beginDate?moment(data.beginDate):'',data.endDate?moment(data.endDate):''];
                if(data.billType == 1){
                    data.projectEncode1 = data.projectEncode
                }
                this.setState({
                    resetBill:this.transResponse(data),
                    billType:data.billType
                })
            }           
        })
    }
    transResponse = (obj) => {
        let newObj = obj
        newObj.floorTemp = JSON.parse(obj.floorZoneMsg)
        if(obj.projectEncode && obj.contractEncode){
            newObj.mergeCode = `${obj.projectEncode},${obj.contractEncode}`
        }
        
        newObj.billType = obj.billType === 1 ? 2 : 1;
        if(obj.customerEncode && obj.tenantName){
            newObj.customerObj = `${obj.customerEncode},${obj.tenantName}`
        }        
        newObj.paidAmount = newObj.payAmount;
        console.log(newObj)
        return newObj
    }
    changeRadio(e){
        console.log(e.target.value)
        this.setState({
            billType:e.target.value
        })
    }
    save(params){
        this.setState({
            loading:true
        })
        if(this.state.reset){
            params.encode = this.state.billEncode;
            http({
                api:API.billUpdate,
                data:params
            }).then((res)=>{
                if(res.code == '0'){
                    message.success('提交成功');
                    this.submitCancel();
                } 
                this.setState({
                    loading:false
                })          
            })
        }else{
            http({
                api:API.billCreate,
                data:params
            }).then((res)=>{
                if(res.success){
                    message.success('提交成功');
                    this.submitCancel();
                }
                this.setState({
                    loading:false
                })
            })
        }       
    }
    // 改造floorTemp数据类型
    changeDataType(value){
        let tempObj = {};
        for(let obj of value){
            if(tempObj[obj.parentId]){
                tempObj[obj.parentId].push({
                    encode:obj.id,
                    zoneNo:obj.zoneNo
                })
            }else{
                tempObj[obj.parentId] = [{
                    encode:obj.id,
                    zoneNo:obj.zoneNo
                }]
            }
        }
        let tempArray = [];
        for(let key in tempObj){
            console.log(key)
            tempArray.push({
                encode:key,
                zones:tempObj[key]
            })
        }
        return tempArray;
    }
    submitCancel(){
        this.props.history.push({pathname:'/app/operate/finance/bill'});
    }
    submit(){
        this.props.form.validateFields((err, values) => {
            console.log('Received values of values: ', values);
                if(!err){
                if(values.photos){
                    values.paymentMessage.photos = values.photos;
                }
                if(values.tenantObj){
                    let objSplit = values.tenantObj.split(',')
                    values.customerEncode = objSplit[0];
                    values.tenantName = objSplit[1];
                }           
                if(values.payDate){
                    values.payDate = values.payDate.format('YYYY-MM-DD');
                }
                if(values.paidDate){
                    values.paidDate = values.paidDate.format('YYYY-MM-DD');
                    values.payDate = values.paidDate;
                }
                if(values.beginEndDate && values.beginEndDate[0] && values.beginEndDate[1]){
                    values.beginDate = values.beginEndDate[0].format('YYYY-MM-DD');
                    values.endDate = values.beginEndDate[1].format('YYYY-MM-DD');
                }
                values.billCategory = values.billCategoryMap?2:1;
                const search = this.props.location.search
                const formatData = this.formatSearch(search)
                if(formatData.type){
                    values.billBudgetType = Number(formatData.type);
                }               

                
                values.payAmount = values.paidAmount;
                if(values.billType == 1){
                    values.projectEncode = values.projectEncode1;
                    values.floor = values.floorTemp?JSON.stringify(this.changeDataType(values.floorTemp)):undefined;
                    // values.floor = {
                    //     floor:values.floorTemp?JSON.stringify(this.changeDataType(values.floorTemp)):undefined,
                    //     floorTemp:values.floorTemp
                    // }
                    // values.floor = JSON.stringify(values.floor);
                }
                if(values.billType == 2){
                    if(values.contractObj){
                        let contractSplit = values.contractObj.split(',');
                        values.projectEncode = contractSplit[0];
                        values.contractEncode = contractSplit[1];
                        // values.floor = contract.floorZoneVoList?JSON.stringify(contract.floorZoneVoList):undefined;
                    }                
                }
                values.paidAmount = Number(values.paidAmount);
                values.payAmount = Number(values.payAmount);
                values.billType = 3 - values.billType
                this.save(values);
                console.log('Received values of values: ', values);
            }
        });
    }
    render(){
        const { getFieldDecorator } = this.props.form;
        const { resetBill } = this.state;
        return (
            <div className="billWrap">
                <Breadcrumb>
                    <BreadcrumbItem>
                    <Link style={{ color: '#333' }} to={{ pathname: "/app/operate/finance/bill" }}>
                                    财务收支
                                 </Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                    <Link style={{ color: '#333' }} to={{ pathname: "/app/operate/finance/bill" }}>
                                    账单管理
                                </Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>新增账单</BreadcrumbItem>
                </Breadcrumb>
                <Form>
                    <div className="approval-baseInfo">      
                        <div className="title">区域信息</div>
                        <Row className="fs12 mb9">
                            <Item label="账单类型">
                                {getFieldDecorator('billType', {
                                    initialValue:resetBill.billType?resetBill.billType:1,
                                    rules: [{
                                        required: true, message: '请选择',
                                    }]
                                })(
                                    <Radio.Group onChange={this.changeRadio.bind(this)} disabled={!resetBill.canEdit}>
                                        <Radio value={1}>运营</Radio>
                                        <Radio value={2}>合同</Radio>
                                    </Radio.Group>                          
                                )}
                            </Item>
                            <div className={this.state.billType == 2?'displayNone':''}>
                                <Region {...this.state} {...this.props}/>
                            </div>
                            <div className={this.state.billType == 1?'displayNone':''}>
                                <Tenant {...this.state} {...this.props}/>
                            </div>
                        </Row>
                    </div>
                    <BillInfo {...this.state} {...this.props}/>
                    <div className={this.props.form.getFieldValue('billCategoryMap')?'':'displayNone'}>
                        <CollectionInfo {...this.state} {...this.props}/>
                    </div>                    
                    <PayInfo {...this.state} {...this.props}/>
                    <div className="submit">
                        <Button type="primary" onClick={this.submit.bind(this)} loading={this.state.loading}>提交</Button>
                        <Button type="link" style={{'marginLeft':'16px'}} onClick={this.submitCancel.bind(this)}>取消</Button>
                    </div>
                </Form>
            </div>            
        )
    }
}

export default Form.create({ name: 'newInBill' })(NewInBill); 