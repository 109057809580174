import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import "./enterpriseAuth.less";

export default withRouter(function EnterpriseAuth({ data, props }) {
  let DATA;
  let QIYE_STATUS;

  const enterpriseInfoData = [
    { label: "企业名称", field: "name" },
    { label: "企业简称", field: "shortName" },
    { label: "统一社会信用代码", field: "unifiedSocialCode" },
    { label: "经营地址", field: "workAddress" },
    { label: "企业类型", field: "typeName" },
    { label: "注册所在地", field: "areaName" },
    { label: "营业期限", field: "registerValidDate" },
    // { label: "营业执照", field: "registerCardAttachmentURL" },
  ];
  // 建设施工(授权委托)
  const enterpriseInfo_101 = [
    { label: "名称", field: "name" },
    { label: "简称", field: "shortName" },
    { label: "所在地", field: "areaName" },
    { label: "委托期限", field: "registerValidDate" },
    { label: "委托单位", field: "deputeCorp" },
  ];
  // 建设施工(无资质)
  const enterpriseInfo_201 = [
    { label: "名称", field: "name" },
    { label: "所在地", field: "areaName" },
    { label: "姓名", field: "legalManName" },
    { label: "联系电话", field: "legalManPhone" },
    { label: "证件类型", field: "legalIdCardType" },
    { label: "证件号码", field: "legalIdCardNumber" },
  ];
  // 出租方(授权委托)
  const enterpriseInfo_105 = [
    { label: "名称", field: "name" },
    { label: "简称", field: "shortName" },
    { label: "所在地", field: "areaName" },
    { label: "委托期限", field: "registerValidDate" },
    { label: "委托单位", field: "deputeCorp" },
    // { label: "证件类型", field: "legalIdCardType" },
  ];
  // 出租方(个人)
  const enterpriseInfo_205 = [
    { label: "姓名", field: "legalManName" },
    { label: "手机号码", field: "legalManPhone" },
    { label: "紧急联系人", field: "emergencyContact" },
    { label: "紧急联系人手机号码", field: "emergencyMobile" },
    { label: "身份证号码", field: "legalIdCardNumber" },
  ];

  // 运输方(个人)
  const enterpriseInfo_202 = [
    { label: "姓名", field: "legalManName" },
    { label: "手机号码", field: "legalManPhone" },
    { label: "紧急联系人", field: "emergencyContact" },
    { label: "紧急联系人手机号码", field: "emergencyMobile" },
    { label: "身份证号码", field: "legalIdCardNumber" },
  ];

  let TITLE = "";

  let enterpriseMsg;
  let qualityReport;
  let transportOperationPermit;
  let operatingLicense;

  if (data.data && data.data.auditFormDetail) {
    // 企业信息
    DATA = data.data.auditFormDetail.mainInfo;
    if (DATA.identityTypeCode == 101) {
      // 建设施工(授权委托)
      TITLE = "基本";
    } else if (DATA.identityTypeCode == 201) {
      // 建设施工(无资质)
      TITLE = "基本";
    } else if (DATA.identityTypeCode == 105) {
      // 出租方(授权委托)
      TITLE = "基本";
    } else if (DATA.identityTypeCode == 205) {
      // 出租方(个人)
      TITLE = "基本";
    } else if (DATA.identityTypeCode == 202) {
      // 运输方(个人)
      TITLE = "基本";
    }else if (DATA.identityTypeCode == 8 ||DATA.identityTypeCode == 108 || DATA.identityTypeCode == 208 ) {
      // 运输方(个人)
      TITLE = "基本";
    }
    // 企业信息---------------
    // 处理日期
    let dateTime =
      DATA.registerValidFrom && DATA.registerValidTo
        ? moment(DATA.registerValidFrom).format("YYYY-MM-DD") +
          "~" +
          moment(DATA.registerValidTo).format("YYYY-MM-DD")
        : "";

     let areaInfo =  (DATA.identityTypeCode == 8 ||DATA.identityTypeCode == 108 || DATA.identityTypeCode == 208 )  ? DATA.businessDetailAddress:DATA.areaFullName;
    enterpriseMsg = {
      name: DATA.name,
      unifiedSocialCode: DATA.unifiedSocialCode,
      typeName: DATA.typeName,
      shortName: DATA.shortName,
      areaName:
      areaInfo && areaInfo.indexOf("/") == -1
          ? areaInfo
          : areaInfo &&
          areaInfo.substring(0, areaInfo.indexOf("/")),
      registerValidDate: DATA.registerValidLongTerm ? "长期" : dateTime,
      deputeCorp: DATA.deputeCorp,
      legalManPhone: DATA.legalManPhone,
      emergencyContact: DATA.emergencyContact,
      emergencyMobile: DATA.emergencyMobile,
      legalManName: DATA.legalManName,
      legalIdCardType:
        DATA.legalIdCardType == 1
          ? "身份证"
          : DATA.legalIdCardType == 2
          ? "护照"
          : "其他",
      legalIdCardNumber: DATA.legalIdCardNumber,
    };
    qualityReport = [];
    DATA.configAttachmentList &&
      DATA.configAttachmentList.map((item) => {
        if (item.subModuleType == 1) {
          // 企业【运输资质】
          QIYE_STATUS = 1;
          transportOperationPermit = item.attachments[0].url;
        }
        if (item.subModuleType == 2) {
          //  企业【经营信息】
          QIYE_STATUS = 2;
          item.attachments &&
            item.attachments.map((it) => qualityReport.push(it.url));
        }
      });
    DATA.configAttachmentList &&
      DATA.configAttachmentList.map((item) => {
        return (operatingLicense =
          item.attachments[0] && item.attachments[0].url);
      });
  }

  return (
    <div className="userInfo" style={{ padding: 0 }}>
      {DATA ? (
        <div className="userInfo_header">
          <div className="userInfo_title">
            认证为
            <span>{DATA.name}</span>
            <span className="flag">{DATA.identityTypeName}</span>
          </div>
          <p>
            超级管理员：
            {`${data.data.supplierOrgDTO.masterName}/${data.data.supplierOrgDTO.masterPhone}`}
          </p>
          {/* <p>
            提交时间：
            {moment(data.data.createDate).format("YYYY-MM-DD HH:mm:ss")}
          </p> */}
          <div className="userInfo_right">
            <span>
              {DATA.auditStatus == -1
                ? "待完善"
                : DATA.auditStatus == 10
                ? "已通过"
                : DATA.auditStatus == 20
                ? "未通过"
                : "审核中"}
            </span>
            <p className="grey">
              创建时间：
              {moment(data.data.createDate).format("YYYY-MM-DD HH:mm:ss")}
            </p>
          </div>
        </div>
      ) : null}

      <div className="userInfo_body">
        {DATA ? (
          <>
            <div>
              <h2>
                <i></i> {TITLE ? TITLE : "企业"}信息
              </h2>
              {/* 建设施工、运输方、 */}
              {DATA.identityTypeCode == 1 ||
              DATA.identityTypeCode == 2 ||
              DATA.identityTypeCode == 3 ||
              DATA.identityTypeCode == 8 ||
              DATA.identityTypeCode == 5 ? (
                <ul>
                  {enterpriseInfoData &&
                    enterpriseInfoData.map((item) => {
                      if (item.field == "workAddress") {
                        return (
                          <li key={item.field}>
                            <span>{item.label}</span>
                            <span className="span">
                              {DATA["provinceName"]}
                              {DATA["cityName"]}
                              {DATA["areasName"]}
                              {DATA["businessDetailAddress"]}
                            </span>
                          </li>
                        );
                      } else {
                        return (
                          <li key={item.field}>
                            <span>{item.label}</span>
                            <span className="span">
                              {enterpriseMsg[item.field]||'-'}
                            </span>
                          </li>
                        );
                      }
                    })}
                  <li className="body_img">
                    <span style={{ width: "75px" }}>营业执照</span>
                    <img
                      src={
                        DATA.registerCardAttachment &&
                        DATA.registerCardAttachment.url
                      }
                      alt=""
                      onClick={() =>
                        props.cImage(
                          DATA.registerCardAttachment &&
                            DATA.registerCardAttachment.url
                        )
                      }
                    />
                  </li>
                </ul>
              ) : null}
              {/* 建设施工 -- 授权委托 */}
              {DATA.identityTypeCode == 101 || DATA.identityTypeCode == 108 ? (
                <ul>
                  {enterpriseInfo_101.map((item) => {
                    return (
                      <li key={item.field}>
                        <span>{item.label}</span>
                        <span className="span">
                          {enterpriseMsg[item.field]||'-'}
                        </span>
                      </li>
                    );
                  })}
                  <li className="body_img">
                    <span>授权文件照片</span>
                    {DATA.authenticationAttachmentList &&
                      DATA.authenticationAttachmentList.map((item, index) => (
                        <img
                          key={index}
                          src={item.url}
                          alt=""
                          onClick={() => props.cImage(item.url)}
                        />
                      ))}
                  </li>
                </ul>
              ) : null}
              {/* 建设施工 -- 无资质 */}
              {DATA.identityTypeCode == 201 || DATA.identityTypeCode == 208 ? (
                <ul>
                  {enterpriseInfo_201.map((item) => {
                    return (
                      <li key={item.field}>
                        <span>{item.label}</span>
                        <span className="span">
                          {enterpriseMsg[item.field]||'-'}
                        </span>
                      </li>
                    );
                  })}
                  <li className="body_img">
                    <span>证件照片</span>
                    {DATA.legalIdCardType == 1 ? (
                      <>
                       { DATA.frontIdCard && DATA.frontIdCard.url&&DATA.frontIdCard.url.length>0?<img
                          src={ DATA.frontIdCard.url }
                          alt=""
                          onClick={() =>
                            props.cImage(
                              DATA.frontIdCard && DATA.frontIdCard.url
                            )
                          }
                        />:''}
                        {DATA.backIdCard && DATA.backIdCard.url&&DATA.backIdCard.url.length>0?<img
                          src={ DATA.backIdCard.url }
                          alt=""
                          onClick={() =>
                            props.cImage(DATA.backIdCard && DATA.backIdCard.url)
                          }
                        /> :''}
                      </>
                    ) : (
                      DATA.passportCard && DATA.passportCard.url&&DATA.passportCard.url.length>0?<img
                        src={ DATA.passportCard.url }
                        alt=""
                        onClick={() =>
                          props.cImage(
                            DATA.passportCard && DATA.passportCard.url
                          )
                        }
                      />:'' 
                    )}
                  </li>
                </ul>
              ) : null}
              {/* 企业出租方 -- 授权委托 */}
              {DATA.identityTypeCode == 105 ? (
                <ul>
                  {enterpriseInfo_105.map((item) => {
                    return (
                      <li key={item.field}>
                        <span>{item.label}</span>
                        <span className="span">
                          {enterpriseMsg[item.field] ||'-' }
                        </span>
                      </li>
                    );
                  })}
                  <li className="body_img">
                    <span>授权文件照片</span>
                    {DATA.authenticationAttachmentList &&
                      DATA.authenticationAttachmentList.map((item, index) => (
                        <img
                          key={index}
                          src={item.url}
                          alt=""
                          onClick={() => props.cImage(item.url)}
                        />
                      ))}
                  </li>
                </ul>
              ) : null}
              {/* 企业出租方 -- 个人 */}
              {DATA.identityTypeCode == 205 ? (
                <>
                  <ul>
                    {enterpriseInfo_205.map((item) => {
                      return (
                        <li key={item.field}>
                          <span>{item.label}</span>
                          <span className="span">
                            {enterpriseMsg[item.field]||'-'}
                          </span>
                        </li>
                      );
                    })}

                    <li className="body_img">
                      <span>证件照片</span>
                      <img
                        src={DATA.frontIdCard && DATA.frontIdCard.url}
                        alt=""
                        onClick={() =>
                          props.cImage(DATA.frontIdCard && DATA.frontIdCard.url)
                        }
                      />
                      <img
                        src={DATA.backIdCard && DATA.backIdCard.url}
                        alt=""
                        onClick={() =>
                          props.cImage(DATA.backIdCard && DATA.backIdCard.url)
                        }
                      />
                    </li>
                    <li className="body_img">
                      <span>驾驶证件</span>
                      <img
                        src={DATA.driverLicense && DATA.driverLicense.url}
                        alt=""
                        onClick={() =>
                          props.cImage(
                            DATA.driverLicense && DATA.driverLicense.url
                          )
                        }
                      />
                    </li>
                    <li className="body_img">
                      <span>特殊从业资格证</span>
                      {DATA.specialQualificationCertificate &&
                        DATA.specialQualificationCertificate.map(
                          (item, index) => (
                            <img
                              key={index}
                              src={item.url}
                              alt=""
                              onClick={() => props.cImage(item.url)}
                            />
                          )
                        )}
                    </li>
                  </ul>
                  <h2>
                    <i></i> 产权信息
                  </h2>
                  <ul>
                    <li className="body_img">
                      <span>设备产权信息</span>
                      {DATA.equipmentPropertyInformation &&
                        DATA.equipmentPropertyInformation.map((item, index) => (
                          <img
                            key={index}
                            src={item.url}
                            alt=""
                            onClick={() => props.cImage(item.url)}
                          />
                        ))}
                    </li>
                  </ul>
                </>
              ) : null}
            </div>

            {QIYE_STATUS && QIYE_STATUS == 1 ? (
              <div>
                <h2>
                  <i></i> 运输资质
                </h2>
                <ul>
                  <li className="body_img">
                    <span>道路运输经营许可证</span>
                    <img
                      src={transportOperationPermit}
                      alt=""
                      onClick={() => props.cImage(transportOperationPermit)}
                    />
                  </li>
                </ul>
              </div>
            ) : null}
            {QIYE_STATUS && QIYE_STATUS == 2 ? (
              <div>
                <h2>
                  <i></i> 经营信息
                </h2>
                <ul>
                  <li>
                    <span>主营品类</span>
                    <span className="span">{DATA.mainBusinessName}</span>
                  </li>
                  <li>
                    <span>税率</span>
                    <span className="span">{DATA.taxRate ? (DATA.taxRate).replaceAll("/","%,")+"%" : '-'}</span>
                  </li>
                  <li className="body_img">
                    <span>产品质检报告</span>
                    {qualityReport &&
                      qualityReport.map((item, index) => (
                        <img
                          key={index}
                          src={item}
                          alt=""
                          onClick={() => props.cImage(item)}
                        />
                      ))}
                  </li>
                </ul>
              </div>
            ) : null}

            {/* 运输方 -- 个人 */}
            {DATA.identityTypeCode == 202 ? (
              <>
                <ul>
                  {enterpriseInfo_202.map((item) => {
                    return (
                      <li key={item.field}>
                        <span>{item.label}</span>
                        <span className="span">
                          {enterpriseMsg[item.field]||'-'}
                        </span>
                      </li>
                    );
                  })}

                  <li className="body_img">
                    <span>证件照片</span>
                    <img
                      src={DATA.frontIdCard && DATA.frontIdCard.url}
                      alt=""
                      onClick={() =>
                        props.cImage(DATA.frontIdCard && DATA.frontIdCard.url)
                      }
                    />
                    <img
                      src={DATA.backIdCard && DATA.backIdCard.url}
                      alt=""
                      onClick={() =>
                        props.cImage(DATA.backIdCard && DATA.backIdCard.url)
                      }
                    />
                  </li>
                  <li className="body_img">
                    <span>驾驶证件</span>
                    <img
                      src={DATA.driverLicense && DATA.driverLicense.url}
                      alt=""
                      onClick={() =>
                        props.cImage(
                          DATA.driverLicense && DATA.driverLicense.url
                        )
                      }
                    />
                  </li>
                </ul>
              </>
            ) : null}
            {/*  运输方展示--营运信息 */}
            {DATA.identityTypeCode == 2 || DATA.identityTypeCode == 202 ? (
              <div>
                <h2>
                  <i></i> 营运信息
                </h2>
                <ul>
                  <li className="body_img">
                    <span style={{ width: "90px" }}>营运许可证</span>
                    {operatingLicense ? (
                      <img
                        src={operatingLicense}
                        alt=""
                        onClick={() => props.cImage(operatingLicense)}
                      />
                    ) : null}
                  </li>
                </ul>
                <ul>
                  <li className="body_img">
                    <span style={{ width: "90px" }}>其他许可证</span>
                    {DATA.otherTransportLicense &&
                      DATA.otherTransportLicense.map((item, index) => (
                        <img
                          key={index}
                          src={item.url}
                          alt=""
                          onClick={() => props.cImage(item.url)}
                        />
                      ))}
                  </li>
                </ul>
              </div>
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  );
});
