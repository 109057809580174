import React, { Component } from 'react';
import "./TitlePanel.less";

class TitlePanel extends Component {


    renderChild = (child, index) => { // 控制内容的分发

        return <div   key={index}>{child}</div>

    }

    render() {


        return (<div className="my_title_panel">
            {  this.props.title?<div className="my_title">
                <span>{this.props.title}</span>
            </div>:""
           }
            {Array.isArray(this.props.children) ?
                this.props.children.map((child, index) => {
                    return this.renderChild(child, index)
                }) : this.props.children && this.renderChild(this.props.children)}
        </div>);
    }

}


export default TitlePanel;