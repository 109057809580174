import React, { Component } from "react";
import { Breadcrumb, Tabs, Button } from "antd";
import NewCatalog from "./NewCatalog";
import Catalog from "./Catalog";
import "./NewCatalogTab.less";
const { TabPane } = Tabs;
const BreadcrumbItem = Breadcrumb.Item;

class NewCatalogTab extends Component {
  render() {
    return (
      <div className="new_help_all">
        <div>
          <Breadcrumb>
            <BreadcrumbItem>帮助中心</BreadcrumbItem>

            <BreadcrumbItem>类目管理</BreadcrumbItem>
          </Breadcrumb>
        </div>

        <div>
          <Tabs defaultActiveKey="1" className="tabs">
            <TabPane tab="商城" key="1" className="tab">
              <Catalog type="1" />
              {/* 0表示待审核 */}
            </TabPane>

            <TabPane tab="供应商管理" key="2" className="tab">
              <Catalog type="2" />
              {/* 1 表示已通过 */}
            </TabPane>


            {/*  屏蔽园区管理  */}
            {/*   <TabPane tab="园区管理" key="3" className="tab">
              <Catalog type="3" />*/}
              {/* 2表示已驳回 */}
           {/*  </TabPane>*/}
          </Tabs>
        </div>
      </div>
    );
  }
}

export default NewCatalogTab;
