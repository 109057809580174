import React, { Component } from "react";
import { API } from "@/http/api";
import http from "@/http";
import CustomerDecision1 from "./CustomerDecision1";
import {
  Breadcrumb,
  message,
  Button,
  InputNumber,
  Tabs,
  Input,
  Form,
  Row,
  Col
} from "antd";
import reg from "@/utils/regex";
import "./CustomerDecision.less";

const { TabPane } = Tabs;
 
class CustomerDecision extends Component {
   

  render() {
    return (
      <div className="decision-page">
        <div className="decision-bread">
          <Breadcrumb>
            <Breadcrumb.Item>设置中心</Breadcrumb.Item>
            <Breadcrumb.Item>决策管理</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div  >
           {/*}  <Tabs defaultActiveKey="1" className="tabs" >
            <TabPane tab="决策管理" key="1" className="tab" >
              <CustomerDecision1 />
            </TabPane>
             </Tabs>*/}
          <CustomerDecision1 />
        </div>
 
      </div>
    );
  }
}
export default  CustomerDecision ;
