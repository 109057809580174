import React, { Component } from 'react';
import { Breadcrumb, Modal, Form, Input,Button, message , } from 'antd';
import Table from 'CMPT/common/Table';
import http from '@/http';
import { API } from '@/http/api';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Pagination from "CMPT/common/Pagination";

import './AnnouncementType.less';

const BreadcrumbItem = Breadcrumb.Item;

class AnnouncementType extends Component {
    state = {
        total:0,
        navigationConfig: {
            navigationStyle: 0,
            showFlag: 1
        },
        navModalShow: false,
        columns: [
            {
                title: '类型名称',
                dataIndex: 'typeName',
                key: 'typeName',
            },
			{
                title: '操作人',
                dataIndex: 'editUserName',
                key: 'editUserName',
            },
			{
                title: '操作时间',
                dataIndex: 'editDate',
                key: 'editDate',
                render: (text, record) => {
                    return (<span>{moment(text).format('YYYY-MM-DD')}</span>);
                }
            }, 
			{
                title: '操作',
                key: 'operate',
                render: (text, record, index) => {
                    return (<span className="table-operate-btn">
                        {<span className='operate' style={{marginLeft: '6px'}}  onClick={() => this.edittype(record.typeName,record.id)} >编辑</span>}
                        {<span className='operate' style={{marginLeft: '6px',color:'red'}}  onClick={() => this.deletype(record.id)} >删除</span>}
                    </span>)
                }
            }
        ],
        data: [],
        total:0,
        params: {
            pageSize: 10,
            pageNo: 1,
        },
        showDelect:false,
        id:undefined,
        typeName:undefined,
        // 0-编辑，1-新增
        editOrInsert:0, 
    }
    //初始化
    componentDidMount() {
        this.getList();
    }

    // 分页重新赋值
    paginationChange = (pageNo, pageSize) => {
        let params = this.state.params;
        params.pageNo = pageNo;
        params.pageSize = pageSize;
        this.setState({params}, this.getList)
    }

    // 获取列表
    getList = () => {
        const params = this.state.params
        const config = {
            api: API.AnnouncementTypeQueryPage,
            data: {
                pageNo: params.pageNo,
                pageSize: params.pageSize,
                type:0,
            },
        }

        http(config).then(res => {
            if (res.code == 0) {
                console.log(res);
                this.setState({
                    data: [...res.data.datas],
                    total: res.data.totalPages
                })
            }
        })
    } 

    changeinput = e => {
        this.setState({
            typeName:e.target.value
        })
      };

    // 删除类型
    deletype = (ids) => {
        this.setState({
            showDelect: true,
            id:ids,
        });
    }
    onOkModal=()=>{
        const config = {
            api: API.AnnouncementTypeRemove,
            path: {
                id:this.state.id
            },
        }

        http(config).then(res => {
            if (res.code == 0) {
                message.success('删除公告类型成功！');
                this.setState({ showDelect: false })
                this.getList();
            }else{
                // message.error(res.msg);
                this.setState({ showDelect: false })
                this.getList();
            }
        })
    }

    edittype = (name,ids)=>{
        this.setState({
            navModalShow: true,
            id:ids,
            typeName:name,
            editOrInsert:0,
        });
    }

    //新增、编辑类型
    onOkInsert = ()=> {
        console.log(this.state);
        if(this.state.editOrInsert == 0){
            const config = {
                api: API.AnnouncementTypeEdit,
                data:{
                    id:this.state.id,
                    type: 0,
                    typeName: this.state.typeName,
                }
            };
            console.log("config的值",config);
            http(config).then(res => {
                if (res.code == 0) {
                    this.setState({
                        navModalShow: false
                    });
                    message.success('编辑类型成功！');
                    this.getList();
                }else{
                    this.setState({
                        navModalShow: false
                    });
                    // message.error(res.msg);
                    this.getList();
                }
            });
        }else{
            const config = {
                api: API.AnnouncementTypeInsert,
                data:{
                    type: 0,
                    typeName: this.state.typeName
                }
            };
            console.log("config的值",config);
            http(config).then(res => {
                if (res.code == 0) {
                    this.setState({
                        navModalShow: false
                    });
                    message.success('新增类型成功！');
                    this.getList();
                }else{
                    this.setState({
                        navModalShow: false
                    });
                    // message.error(res.msg);
                    this.getList();
                }
            });
        }
        
    }
    

    render() {
        const {  navModalShow } = this.state;
        return (
            <div className="channel-nav-con">
                <div className='channel-nav-header'>
                    {
                        <Button className="account_manage_create" type="primary" onClick={() => {this.setState({ navModalShow: true,editOrInsert:1,typeName:'',})}}>添加类型</Button>
                    }
                </div>
                <div className="channel-nav-list">
                    <Table 
                        rowKey="typeValue" 
                        columns={this.state.columns} 
                        selfProcessData={this.state.data}
                        total={this.state.total} 
                    />
                    <Pagination change={this.paginationChange.bind(this)} total={this.state.total} current={this.state.params.pageNo} />
                </div>
                <Modal
                    visible={navModalShow}
                    closable={false}
                    maskClosable={false}
                    width={600}
                    title={this.state.editOrInsert == 0 ? "编辑类型" : "添加类型"}
                    onCancel={() => {
                        this.setState({ navModalShow: false })
                    }}
                    onOk={this.onOkInsert}
                >
                    <Form className='set_form'
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 16 }}>
                        <Form.Item label="类型名称">
                            <Input type="text" value={this.state.typeName} placeholder="请输入(最多4个字)" onChange={e=>{this.changeinput(e)}}/>
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal
                    visible={this.state.showDelect}
                    closable={false}
                    maskClosable={false}
                    width={600}
                    title="提示"
                    onCancel={() => {
                        this.setState({ showDelect: false })
                    }}
                    onOk={this.onOkModal}
                >
                    <p>确定删除该类型么？</p>
                </Modal>
            </div>


        );
    }
}
export default AnnouncementType;
