import React, { Component } from 'react';
import axios from 'axios';
import { Upload, Icon, message } from 'antd';
import wordSrc from '@/images/files_word@2x.png';
import pdfSrc from '@/images/files_pdf@2x.png';
import './fileupload.less'
import userUtil from "@/utils/user";
import { API } from '@/http/api';
const { Dragger } = Upload;

export default class BsUpload extends Component {
    state = {
        loading: false,
        fileType: ''
    };
    doUploadImage(value) {
        const fromData = new FormData()
        fromData.append('file', value.file)
        const user = userUtil.get();
        const config = {
            onUploadProgress: progressEvent => {
                value.onProgress(progressEvent.loaded / progressEvent.total * 100 | 0);
            },
            headers: {
                ...value.headers,
                token: user.token,
                'Content-Type': 'multipart/form-data'
            }
        }
        axios.post(value.action, fromData, config)
            .then(res => {
                console.log('success', res)
                value.onSuccess(res);
            }).catch(err => {
                console.log('error', err)
                value.onError(err);
            });
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.info !== this.props.info) {
            this.setState({
                imageUrl: nextProps.info.type && (nextProps.info.type === 'word' ? wordSrc : pdfSrc),
                fileName: nextProps.info.name
            })
        }
    }
    beforeUpload = (file) => {
        // return true
        console.log(file)
        this.setState({
            _filename:file.name
        })
        // 判断文件是不是word/pdf
        const isWord = /application\/.*word/.test(file.type)
        const isPdf = /application\/.*pdf/.test(file.type)
        // console.log(isWord,isPdf)
        if (isWord) {
            this.setState({
                fileType: 'word'
            })
        }
        if (isPdf) {
            this.setState({
                fileType: 'pdf'
            })
        }

        if (!(isWord || isPdf)) {
            message.error('请上传10M以内的word或pdf文件');
        }
        const isLt10M = file.size / 1024 / 1024 < 10;
        if (!isLt10M) {
            message.error('请上传10M以内的word或pdf文件');
        }
        return (isWord || isPdf) && isLt10M;
    }
    handleChange = (info) => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            this.setState({
                loading: false,
                fileName: info.file.response.data.fileName
            })

            // console.log(info.file.response.file)
            if (this.state.fileType == 'pdf') {
                this.setState({
                    imageUrl: pdfSrc
                })
            } else {
                this.setState({
                    imageUrl: wordSrc
                })
            }
            this.props.onChange({
                fileType: this.state.fileType,
                fileMd5:info.file.response.data.md5,
                fileName:info.file.response.data.fileName,
                fileKey:info.file.response.data.key,
                fileUrl:info.file.response.data.publicUrl
            })
        }


    }
    render() {
        const { onChange, ...child } = { ...this.props }
        const uploadButton = (
            <div>
                <Icon type={this.state.loading ? 'loading' : 'upload'} />
                <div className="ant-upload-text"  style={{margin: 'auto', width: '64%'}}>拖拽至此 或 点击上传</div>
            </div>
        );
        return (
            <div className="bsDragger">
                <Dragger
                    beforeUpload={this.beforeUpload}
                    onChange={this.handleChange}
                    name={this.state._filename}
                    listType="picture-card"
                    className="avatar-uploader"
                    withCredentials={true}
                    showUploadList={false}
                    headers={{ 'Content-Type': 'multipart/form-data' }}
                    action={API.uploadAdd.url}
                    customRequest={this.doUploadImage.bind(this)}
                    {...child}
                >
                    {this.props.children ? this.props.children : (
                        <div>

                            {this.state.imageUrl ? <img src={this.state.imageUrl} alt={this.state.fileName} /> : uploadButton}
                            {this.state.fileName ? <p style={{ width: '120px' }}>{this.state.fileName}</p> : null}
                        </div>

                    )}
                </Dragger>
            </div>
        )
    }
}
