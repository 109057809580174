import React, { Component } from 'react';
import { Row,Col,Select,Form ,Input ,Icon,Upload ,message} from 'antd';
import http from '@/http';
import { API } from '@/http/api';
import BsUpload from '@/components/common/Upload'
import CxUpload from './Upload'
import "../../financeApproval/finance.less"
import '../bill.less'


const Item = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;
const imgType = ['png','jpg','jpeg','bmp'];
const payChannel = [{
    name:'现金',
    value:1
},{
    name:'刷卡',
    value:2
},{
    name:'转账',
    value:3
},{
    name:'支付宝',
    value:4
},{
    name:'微信',
    value:5
},{
    name:'其他',
    value:9
}];
class CollectionInfo extends Component {
    state = {
        fileList: [
            {
              uid: '-1',
              name: 'xxx.png',
              status: 'done',
              url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
            },
          ],
    }
    handleChange = ({ fileList }) => this.setState({ fileList });
    componentDidMount() {
    }
    changeaddModal(type,val) {       
        const { file }= val;
        if(file.response) {
            const obj = file.response.file;
            const pictureInfo = {
                uid: obj.sKey,
                name: obj.sFilename,
                mediaExt: obj.sExt,
                type: obj.sExt,
                mediaKey: obj.sKey,
                url: `${process.env.IMG_VIEWDOMAIN}house/${obj.sKey}/4000x3000.${obj.sExt}`
            };
            console.log(pictureInfo);
        }
    }
    imgUp = fileList => {
        setTimeout(()=>{
            this.props.form.setFieldsValue({
                photos:fileList
            })
        },0)
    }
    render(){
        const isChecked = this.props.form.getFieldValue('billCategoryMap');
        const {createParams:params , textDes} = this.props;
        const { getFieldDecorator } = this.props.form;
        const optionsChannel = payChannel.map(per => {
            return <Option value={per.value} key={per.value}>{per.name}</Option>
        })
        const { fileList } = this.state;
        const uploadButton = (
            <div>
              <Icon type="plus" />
              <div className="ant-upload-text">Upload</div>
            </div>
          );
        return (
            <div className="approval-baseInfo"> 
                <div className="title">{textDes.title1}</div>
                    <Row className="fs12 mb9">
                        <Col span={8}>
                            <Item label="支付途径" style={{ width: '237px'}}>
                                {getFieldDecorator('paymentMessage.channel', {
                                    rules: [{
                                        required: isChecked, message: '请选择',
                                    }]
                                })(
                                    <Select placeholder="请选择">
                                        {optionsChannel}
                                    </Select>               
                                )}
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item label="票据号" style={{ width: '237px'}}>
                                {getFieldDecorator('paymentMessage.receiptNo', {
                                    // initialValue:params.customerPhone,
                                    rules: [{
                                        required: isChecked, message: '票据号不能为空',
                                    }]
                                })(
                                    <Input placeholder="请输入" maxLength={30}/>                      
                                )}
                            </Item>
                        </Col>
                        <Col span={8} className="cx-maohao">
                            <Item label="银行流水号" style={{ width: '237px'}}>
                                {getFieldDecorator('paymentMessage.bankStatement', {
                                    rules: [{
                                        required: false, message: '请输入',
                                    }]
                                })(
                                    <Input placeholder="请输入" maxLength={20}/>                      
                                )}
                            </Item>
                        </Col>
                    </Row> 
                    <Row className="fs12 mb9" className="cx-maohao">
                        <Col span={8}>
                            <Item label={textDes.paymentMessage.companyBank} style={{ width: '237px'}}>
                                {getFieldDecorator('paymentMessage.companyBank', {
                                    // initialValue:params.customerName,
                                    rules: [{
                                        required: false, message: '请输入',
                                    }]
                                })(
                                    <Input placeholder="XX银行XX支行" autoComplete="off" maxLength={30}/>                    
                                )}
                            </Item>
                        </Col>
                        <Col span={8} className="cx-maohao">
                            <Item label={textDes.paymentMessage.companyAccount} style={{ width: '237px'}}>
                                {getFieldDecorator('paymentMessage.companyAccount', {
                                    // initialValue:params.customerName,
                                    rules: [{
                                        required: false, message: '请输入',
                                    }]
                                })(
                                    <Input placeholder="请输入" autoComplete="off" maxLength={20}/>                    
                                )}
                            </Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} className="delMaohao">
                            <Item label="相关凭证  注: 请上传5M以内的PNG、JPEG、JPG">
                                {getFieldDecorator('photos',{
                                })(
                                    <CxUpload imgUp={this.imgUp}/>                                    
                                )}
                            </Item>
                                {/* {getFieldDecorator('paymentMessage.photosTemp',{
                                    rules: [{
                                        // message: '支持png、jpg、jpeg、bmp格式，最多5张',
                                        validator: (rule, value, callback)=>{
                                            if (value) {
                                                const { fileList } = value;
                                                if (fileList) {
                                                    if (fileList.length > 3) callback(false);
                                                    fileList.forEach(item => {
                                                        if (!imgType.includes(item.type.split('/')[1])) callback(false)
                                                    });
                                                }
                                            }
                                            callback()
                                        }
                                    }],
                                })(
                                    <BsUpload   action={API.uploadAdd.url}                                               
                                                listType={'picture-card'}
                                                onChange={this.changeaddModal.bind(this,6)}>
                                    </BsUpload>
                                )} */}
                        </Col>
                    </Row>
                    <Row>
                    <Col span={24} className="cx-maohao">
                        <Item label={textDes.paymentMessage.description}>
                            {getFieldDecorator('paymentMessage.description', {
                                rules: [{
                                    required: false, message: '请输入',
                                }]
                            })(
                                <TextArea rows={4} placeholder="请输入" maxLength={200}/>   
                            )}
                        </Item>
                    </Col>
                </Row>          
                </div>    
        )
    }
}

export default CollectionInfo;