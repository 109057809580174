import React, { Component } from 'react';
import { Breadcrumb, Timeline, Button } from 'antd';
import { Link } from 'react-router-dom';
import { API } from '@/http/api';
import http from '@/http';
import moment from 'moment';

const BreadcrumbItem = Breadcrumb.Item;

class OperaterRecord extends Component {
    state = {
        recordData: []
    }


    componentDidMount() {
        this.init();
    }

    init = () => {
        console.log(this.props, 'this.props')
        let config = null
        config = {
            api: API.valueAddServiceAuditRecord,
            path: {
                code: this.props.match.params.code
            },
        };
        
        http(config).then(res => {
            console.log(res, 'res1111111111111111111')
            if (res.code == 200) {
                let recordData = res.data;
                this.setState({
                    recordData
                });
            }
        })
    }
    

    render() {
        const { recordData } = this.state
        console.log(recordData, 'recordData', this.props)
        return (

            <div className="enterprise-auth-con">
                <div className='enterprise-auth-header'>
                    <Breadcrumb className="customer-detail-head-bread">
                        <Breadcrumb.Item>
                            {/* <Link style={{ color: '#333' }} to={{ pathname: '/app/crm/enterprise_auth', search: 'backPageNo=' + this.state.backPageNo }} > */}
                            {/* <Link style={{ color: '#333' }} to={{ pathname: '/app/crm/enterprise_auth'}} > */}
                                轨迹查询
                            {/* </Link> */}
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link style={{ color: '#333' }} to={`/app/crm/valueaddService/trajectoryFind?tab=2`} >
                                用户订单
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                                操作记录
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div style={{backgroundColor: '#fff', padding: 24}}>
                    <Timeline>
                        {recordData.length>0 && recordData.map(item => 
                            (<Timeline.Item color="gray" key={item.id}>{moment(item.editDate).format('YYYY-MM-DD HH:mm:ss')}
                                {item.auditStatus == 10 ? (<h2 style={{ color: '#2E4AFF' }}>{item.auditStatusName}</h2>) : (<h2 style={{ color: '#FE3351' }}>{item.auditStatusName}</h2>)}
                                <p>审核人：{item.createUserName}</p>
                                {item.auditRemark && <p>原因：{item.auditRemark}</p>}
                            </Timeline.Item>)
                        )}

                    </Timeline>
                    <Button onClick={()=> this.props.history.goBack()}>返回</Button>
                </div>
            </div>


        );
    }
}

export default OperaterRecord; 