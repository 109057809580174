import Enum from "@/utils/Enum";

// 商户类型
export const AccountType = new Enum(
  { alias: "SUPPLY", text: "供应商", value: 1 },
  { alias: "WULIU", text: "物流公司", value: 2 },
  { alias: "SHEBEI", text: "设备公司", value: 3 }
);

// 审核状态
export const AuditStatus = new Enum(
  { alias: "NEW", text: "新申请", value: 0 },
  { alias: "REVIEW", text: "易宝审核中", value: 2 },
  { alias: "REJECT", text: "易宝审核驳回", value: 3 },
  { alias: "SUCCESS ", text: "易宝审核通过", value: 4 },
  { alias: "SIGING", text: "待签署", value: 5 },
  { alias: "UNDER_REVIEW", text: "平台审核中", value: 7 },
  { alias: "PLATFORMREJECT", text: "平台驳回", value: 6 },
  { alias: "AUTHENTICATION", text: "待微信实名认证", value: 8 },
  { alias: "COMPLETE", text: "已开户", value: 1 },
);
