import React, { Component } from "react";
import {  Button, Input } from "antd";
import { Link } from 'react-router-dom';
 import Table from "CMPT/common/Table";
import http from "@/http";
import { API } from "@/http/api";
 import TableRenderHelp from "@/utils/TableRenderHelp.js";
import { FilterType } from '@/utils/fliter';
 import ChooseGoods from './chooseGoods/index';
 import Select from 'CMPT/common/Select';
 import "./index.less";
 const { Search } = Input;



class TopGoods extends Component {

  
    state = {
        type:1,
        total: 3, // 当前数据的总数
        currentPageCount: 1, //当前页面的数量
       // filterParm:{},


       searchValue: '',// 筛选框中的关键词输入
       categoryOneList: [], //筛选框中 一级分类
       categoryTwoList: [],//筛选框中 二级分类
       categoryThreeList: [],//筛选框中 三级分类
       shopList: [], // 筛选框中的店铺列表

        params: { //参数
          categoryOne: undefined,
          categoryTwo: undefined,
          categoryThree: undefined,
          shop: undefined,
          pageNo: 1, // 当前所展示的页码
          pageSize:10,
      },


        columns:this.createColumns(),// 列表字段集合
        httpData:[],// 列表展示 数据
  
        isShowChooseGoods:false, // 是否展示弹窗
     };

    componentDidMount () {
      this.getCategory()
      this.searchShop()
      this.getGoodsList();
    }

  

    /**
     * 查询分类
     */
    getCategory = () => {
      const config = {
          api: API.commonCategory,
      }
      http(config).then(res => {
          if (res.success) {
              let categoryOneList = res.data && res.data.map(per => {
                  return {
                      name: per.name,
                      value: per.code,
                      childList: per.childList && per.childList.map(ii => {
                          return {
                              name: ii.name,
                              value: ii.code,
                              childList:ii.childList && ii.childList.map(iii => {
                                return {
                                    name: iii.name,
                                    value: iii.code,
                                 }
                            })
                          }
                      })
                  }
              }) || []
              this.setState({
                  categoryOneList
              })
          }
      })
  }

 

  onSearchOn = (keywords) => {
    this.setState({activeKey: '', searchValue: keywords}, () => {
        this.getGoodsList()
    })
}

     // 输入搜索框
     searchChange = (e) => {
      this.setState({searchValue: e.target.value})
  }

   

    createColumns(){
      let help = new TableRenderHelp();

      return [
          help.createTextColumn("商品编号", "itemNo"),
          help.createTextColumn("商品分类", "categoryName"),
           help.createRenderColumn(
            "商品名称",  "itemName", "itemName", 
            (text, record) => {
              return   <span className="table-operate-btn">
              <Link to={'/app/crm/goodsManage/'+record.itemCode} target="_blank" className="operate">{text}</Link>
              </span>;
            }
          ),
          help.createTextColumn("渠道", "channel"),
          help.createTextColumn("价格", "salePrice"),
          help.createTextColumn("店铺", "shopName"),
          help.createTextColumn("状态", "status"),
          help.createTextColumn("操作人", "updateUser"),
          help.createTextColumn("更新时间", "updateTime"),
          help.createTextColumn("添加时间", "createTime"),
          {
            title: '操作',
            render: (record, index) => <div>
              {/** 二选一  */}
                {/** TODO  后期应该对 快速点击 做屏蔽，同时 对请求做 等待处理 */}
               {  !(record.flagStatus?true:false)&&<span className="text-color-highlight" style={{marginRight:'16px',whiteSpace: 'nowrap'}} onClick={() => { this.doTop(record) }}>置顶</span> }
               {  (record.flagStatus?true:false)&& <span className="text-color-highlight" style={{marginRight:'16px',marginTop:'10px',whiteSpace: 'nowrap'}} onClick={() => { this.cancelTop(record) }}>取消置顶</span>}
                <span className="text-color-highlight" style={{marginRight:'16px',marginTop:'10px',whiteSpace: 'nowrap'}} onClick={() => { this.delete(record) }}>移除</span>
            </div>
        }
      ];

    }

   

    /**
     * 置顶
     * @param {*} data 
     */
    doTop=(data)=>{
      this.doUpdateTop({flagStatus:1,code:data.code});//1是去设置
    }

    /**
     * 取消置顶
     * @param {}} data 
     */
    cancelTop=(data)=>{
      this.doUpdateTop({flagStatus:0,code:data.code});//0是取消
    }

        /**
     * 置顶与否 实际是个更新操作
     * @param {*} data 
     */
         doUpdateTop=(data)=>{
          const config = {
           api: API.goodsUpdate,
           data,
          };
         
         // setup 2:请求，并初步处理
         http(config).then(res => {
           if (res && res.success && res.data) {
                 this.getGoodsList();
            }
         });
       }

    delete=(data)=>{
      const config = {
        api: API.goodsDelete,
        data:  {code:data.code},
       };
      
       http(config).then(res => {
        if (res && res.success && res.data) {
              this.getGoodsList();
         }
      });
    }

      //分页
  onChangePage = (pageNo, pageSize, sort) => {
    let {params} = this.state;
    params.pageNo =pageNo;
    params.pageSize =pageSize;

    this.setState( {params },this.getGoodsList );
  };

  /**
   * 选择窗口的 算下回调
   */
  refresh4Choose=()=>{
    let {params} = this.state;
    params.pageNo =1;

    this.setState( { params}, this.getGoodsList   );
  }


  /**
   * 顶部tab切换，目前没有用
   * @param {*} value 
   * @returns 
   */
  tabsChange = (value) => {
    let {type} = this.state;
    if(type==value){
      return ;
    }
      
    this.setState({ type:value  }, this.getGoodsList);
  }

  /**
   * 构建查询参数
   * @returns 
   */
  createQueryParam = () => {
    let {type, params,filterParm} = this.state;
    let param = {type,  pageSize:params.pageSize,pageNo:params.pageNo};
 
    Object.keys(filterParm).forEach(key => {
      if (filterParm[key]) {
        param[key] = filterParm[key];
      }
    });
 
    return param;
  }
 
/**
 * 分类选择
 * @param {*} attr 
 * @param {*} data 
 */
 selectChange = (attr, data) => {
  if (attr === 'categoryOne') {
      const categoryOneList = this.state.categoryOneList
      const categoryTwoList = categoryOneList.find(per => per.value === data)
      if (categoryTwoList) {
          this.setState({
              categoryTwoList: categoryTwoList.childList
          })
      }
  }else if(attr === 'categoryTwo'){
    const categoryTwoList = this.state.categoryTwoList
    const selected = categoryTwoList.find(per => per.value === data)
    if (selected) {
        this.setState({
             categoryThreeList: selected.childList
        })
    }
  }
  let params = this.state.params
  params[attr] = data
  if (data === 99) {
      params[attr] = undefined
  }
  params.pageNo = 1
  this.setState({ params }, this.getGoodsList)
}
 
 
/**
 * 获取商品列表
 */ 
getGoodsList = () => {
  const {params,searchValue} = this.state;
  const cateCode = params.categoryThree || (params.categoryTwo || params.categoryOne );

  const config = {
      api: API.goodsList,
      data: {
          keyword:  searchValue,
          orderByList: [0],
          orderList: [0],
          pageNo :params.pageNo,
          pageSize: params.pageSize,
          status: 2,//this.state.activeKey,
          categoryCode: cateCode,
          shopCodeList: params.shop ? [params.shop] : null
      },
      showLoading: true
  }

  http(config).then(res => {
      if (res && res.success && res.data && res.data.datas) {
          this.setState({
              httpData: [...res.data.datas],
              total: res.data.totalRecord
          })
      }
  })
} 

/**
 * 下拉框 ，输入搜索  店铺
 * @param {*} keyWord 
 */
searchShop = (keyWord) => {
  const config = {
      api: API.commonShopList,
  }
  if (keyWord) {
      config.data = {
          shopName: keyWord
      }
  }
  http(config).then(res => {
      if (res&&res.success) {
          const shopList = res.data && res.data.map(per => {
              return {
                  value: per.code,
                  name: per.shopName
              }
          }) || []
          this.setState({shopList})
      }
  })
}


  // 重置
  resetSearch = () => {
    var params= {
      categoryOne: undefined,
      categoryTwo: undefined,
      categoryThree: undefined,
      shop: undefined,
      pageSize: 10,
      pageNo: 1,
  }
    this.setState({ 
      searchValue: "",
      params },  
      this.getGoodsList
     );
  };

  /**
   * 筛选面板的配置
   * @returns 
   */
  createFilterConfig(){
    return {
       config:[
        this.createFilterRow()
        ],
        btnLast:true

    } ;
  }


  createFilterRow(){
    return {
      childList:[
        {
          type:FilterType.INPUT,
          //label:'意向单编号',
          config:{
            field:'keyword',
            placeholder:'请输入商品名称,商品编码'
          }
        },
        

      ]
    }
  }

  /**
   * 展示 窗口
   */
  showChooseGoods=()=> {
    this.setState({ 
      isShowChooseGoods:true
    });
  }

  /**
   * 隐藏窗口
   */
  hiddenChooseGoods=()=>{
    this.setState({ 
      isShowChooseGoods:false
    });
  }

    render() {
        let {type,columns,httpData,searchValue,total,isShowChooseGoods,params,shopList,categoryOneList,categoryTwoList,categoryThreeList} = this.state;
        let {pageNo} = params;

        return (
          <div className="top_goods_panel">
           <div className="my_left_right_">
             
           <ul className="status-tab"  >
             <li   className={type === 1 ? 'active' : ''} onClick={() => { this.tabsChange(1) }}>web/小程序</li>
           </ul>

           <Button
              style={{ marginLeft: "20px"  }}
              type="primary"
              onClick={  this.showChooseGoods }  >
              添加商品
            </Button>

           </div>
           {/** 先复制过来 实现。后续 改为组件  20211014  */}
           <div className="goods_fliter">
                        <Select data={ categoryOneList} placeholder="一级分类" value={params.categoryOne}
                            selectMethod={this.selectChange.bind(this, 'categoryOne')} style={{ width: '124px' }} />
                        <Select data={ categoryTwoList} placeholder="二级分类" value={params.categoryTwo}
                            selectMethod={this.selectChange.bind(this, 'categoryTwo')} style={{ width: '124px' }} />
                      <Select data={ categoryThreeList} placeholder="三级分类" value={params.categoryThree}
                            selectMethod={this.selectChange.bind(this, 'categoryThree')} style={{ width: '124px' }} />

                        <Select data={ shopList} placeholder="店铺" value={params.shop} showSearch
                                filterOption={false} onSearch={this.searchShop}
                                selectMethod={this.selectChange.bind(this, 'shop')} style={{ width: '124px' }} />
                        <Search
                            placeholder="请输入商品名称,商品编码"
                            enterButton="搜索"
                            className="goods_search"
                            value={searchValue}
                            // size="large"
                            onChange={value => {this.searchChange(value)}}
                            onSearch={value => {this.onSearchOn(value)}}
                        />
                        <span className="goods_reset" onClick={this.resetSearch}>重置</span>
                    </div>
 
           <Table
          columns={ columns}
          rowKey={(record, index) => record.encode}
          selfProcessData={httpData}
          total={total}
          change={this.onChangePage}
          current={ pageNo}
        />


        {isShowChooseGoods&& <ChooseGoods  refresh={this.refresh4Choose}close={this.hiddenChooseGoods}/> }
 

          </div>
        );
      }
}


export default TopGoods;
