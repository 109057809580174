import React, { Component, Fragment } from 'react';
import { Icon } from 'antd';
export default class ImgPreview extends Component {
    state = {
        current: 0,
        rotateDeg: 0
    }
    componentWillReceiveProps(nextProps) {
        var current = -1;
        if(nextProps.currentImg){
              var imgList = this.props.imgList;
              current = imgList.indexOf(nextProps.currentImg);
        }
        
        if(current>=0){
            this.setState({ current   })
        }else {
            this.setState({
                current: nextProps.current
            })
        }

    }
    goPre = () => {
        const length = this.props.imgList.length
        let current = this.state.current
        if (current === 0) {
            current = length - 1
        } else {
            current -= 1
        }
        this.setState({
            current,
            rotateDeg: 0
        })
    }
    goNext = () => {
        const length = this.props.imgList.length
        let current = this.state.current
        if (current === length - 1) {
            current = 0
        } else {
            current += 1
        }
        this.setState({
            current,
            rotateDeg: 0
        })
    }
    goRotate = () => {
        let rotateDeg = this.state.rotateDeg
        rotateDeg += 90
        this.setState({
            rotateDeg
        })
    }
    render() {
        const { imgList, show, onClose } = this.props
        const { current, rotateDeg } = this.state
        return (
            <Fragment>
                {show && <div className="bo-img-preview">
                    <Icon type="close-circle" style={{color: '#FFF'}} theme="filled" onClick={onClose} />
                    <div className="img-mask"></div>
                    <img src={imgList[current]} style={{transform: `rotate(${rotateDeg}deg)`}} />
                    <div className="btns">
                        <span onClick={this.goPre}>上一张</span>
                        <span onClick={this.goRotate}>翻转</span>
                        <span onClick={this.goNext}>下一张</span>
                    </div>
                </div>
                }
            </Fragment>
        )
    }
}