import React, { Component } from "react";
import { Breadcrumb, Select, Tabs } from "antd";
import "./index.less";
import EnterpriseAccountOpening from "./EnterpriseAccountOpening/EnterpriseAccountOpening";
import Invoice from "CMPT/invoice/list/index";
import { formatLinkSearch } from "@/utils";
// import GoodsAuditList from './GoodsAudit/GoodsAuditList';
// import CapacityAuditList from './CapacityAudit/CapacityAuditList';

const BreadcrumbItem = Breadcrumb.Item;
const { Option } = Select;
const { TabPane } = Tabs;



export default class Sourcing extends Component {
  state = {
    currentTab: "1",
  };

  componentWillMount() {
    let { search } = this.props.location;
    if (search) {
      const formatData = formatLinkSearch( search);

      this.setState({
        currentTab: formatData.tab||1,
      });
    }
  }

  changeTabs = (value) => {
     this.setState({
      currentTab: value,
    });
  };

  render() {
    return (
      <div className="sourcing-page">
        <Breadcrumb >
          <BreadcrumbItem>开户管理</BreadcrumbItem>
        </Breadcrumb>
      
          <Tabs
              activeKey={this.state.currentTab}
              onChange={this.changeTabs}
              className="tabs"
              style={{ margin: 0 }}
            >
              <TabPane tab="商户开户管理" key="1" className="tab">
                <EnterpriseAccountOpening />
              </TabPane>
              <TabPane tab="网货开户管理" key="2" className="tab">
                  <Invoice />
              </TabPane>
            </Tabs>  
      </div>
    );
  }
}
