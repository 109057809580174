// 用户操作模块
import storage, {
    STORAGE_KEYS
} from './storage';
import Cookies from 'js-cookie'

let mallUrl = '';  // 商城服务地址
let mallUrlForLink = ""; // 商城地址；
let omsUrlForLink = ''; // 供应商地址
let UCUrl = ''; // UC请求地址
let downloadUrl = ''; // 文件下载地址
let wxQrCodeObj = {
    CorpId:  'ww56977558856bc590',
    AgentId: '1000012',
    RedirectUri:'http%3A%2F%2Fcrm.ipo.com%2Fweb%2Fcrm%2Flogin%2Fqrcode'
};
let isUs = true;
let ishfjl = true;
let istflq = true;
let istfdc = true;
let istfsn = true;
let istfgc = true;
let islqemall = true;


let host = window.location.host; // host;
let origin = window.location.origin;
// 获取 domain，拿到 .ipo.com / .idf10.com / .id10.com.cn 等后缀；
let dianIndex = host.indexOf('.'); // 
let domainStr = host.substring(dianIndex, host.length);
console.log(domainStr, 'domainStr');
// 获取前缀，先判断是否有 ‘-’，有 ‘-’ 需要拼接；无 ‘-’ ，判断是uat的 ipo.com 还是生产的 idf10.com；
let gangIndex = host.indexOf('-');
let isYsc = host.indexOf('ipo.com') > -1;
let hasGang = gangIndex > -1;
let gangUrl = hasGang ? host.substring(0, gangIndex + 1) : '';
// let httpStr = isYsc ? 'http://' : 'https://';
let httpStr = origin.indexOf("https") > -1 ? "https://" : "http://";

mallUrl = '/api/supply';
mallUrlForLink = domainStr.indexOf('shudaowl') > -1 ? `https://tfdc.shudaowl.com` : `${httpStr}${gangUrl}${hasGang ? 'mall' : 'www'}${domainStr}`;
omsUrlForLink = `${httpStr}${gangUrl}${'supplier'}${domainStr}`;
downloadUrl = '';
wxQrCodeObj = {
    CorpId:  'ww56977558856bc590',
    AgentId: host.indexOf('id10.com.cn') > -1? '1000031' : isYsc ? '1000012' : '1000011',
    RedirectUri:`${httpStr}${host}/web/crm/login/qrcode`
};

istflq = host.indexOf('tflq') > -1
istfdc = host.indexOf('tfdc') > -1
istfsn = host.indexOf('tfsn') > -1
istfgc = host.indexOf('tfgc') > -1
ishfjl = host.indexOf('hfjl') > -1
islqemall = host.indexOf('lqe') > -1
isUs = host.indexOf('.ipo.com') > -1 || !hasGang;


wxQrCodeObj.RedirectUri = encodeURI(wxQrCodeObj.RedirectUri)




const userKey = STORAGE_KEYS.USER;
const TokenKey ='idf10-admin-oauth2-access-token';

const emptyObject = {};

function login(userInfo, cb) {
    storage.set(userKey, userInfo);
    cb && cb()
}

function isLogin() {
    return true;
    const user = get();
 

    var token = Cookies.get(TokenKey);
     if(token!= null  && token!= undefined  && token.trim() != ""){
        return true;
    }

    return   false;
}

function logout() {
    // TODO:是否有用户偏好不可删除
    Cookies.remove(TokenKey,{path:'/',domain:'ipo.com'});
    
    storage.clear();
}

function get() {
    return storage.get(userKey);
}

function getName() {
    const user = get() || emptyObject;
    return user.userName || '';
    //return user.extraPrincipal || '';
}

function getAuths() {
    const user = get() || emptyObject;
    return user.functionList || [];
}

function checkAuthByKey(authKey) {
    /*const userAuths = getAuths();
    return userAuths.includes(authKey);*/
    //TODO 暂时放开所有功能，便于调试。后续上线 需要回退此代码 20210529
    return true;
}

function hasAuth(authCodes, hasAny = true) {
    /*if (!Array.isArray(authCodes)) {
        authCodes = [authCodes];
    }

    const userAuths = getAuths();
    if (hasAny) {
        return authCodes.some(code => userAuths.includes(code));
    }

    return authCodes.every(code => userAuths.includes(code));*/
    
     //TODO 暂时放开所有功能，便于调试。后续上线 需要回退此代码 20210529
     return true;
}

function isManager(){
    const user = get() || emptyObject;
    return user.isManager;
}

function userMsgSet (value, cb) {
    const user = get() || emptyObject;
    user.functionList = value;
    login(user)
    cb && cb()
}

export default {
    login,
    isLogin,
    logout,
    get,
    getName,
    getAuths,
    checkAuthByKey,
    hasAuth,
    isManager,
    mallUrl,
    mallUrlForLink,
    UCUrl,
    downloadUrl,
    userMsgSet,
    wxQrCodeObj,
    isUs,
    ishfjl,
    istflq,
    istfsn,
    islqemall,
    istfgc,
    istfdc,
    omsUrlForLink
}