import React,{Component} from 'react'
import { Icon } from 'antd';
class Tip extends Component{

    render(){
        var theme = 'green'
        var icon = <Icon type="check" />
        if(this.props.type=='pass'){
            theme='green'
            icon = <Icon type="check" />
        }else if(this.props.type=='wait'){
            theme='yellow'
            icon = <Icon type="ellipsis" />
        }else if(this.props.type=='warn'){
            theme='red'
            icon = <Icon type="exclamation" />
        }else if(this.props.type=='minus'){
            theme='gray'
            icon = <Icon type="minus" />
        }
        // 提示
        var hint = null
        if(this.props.hint){
            hint=(
                <div className="tip-content-hint">                    
                    {this.props.hintNode}
                </div>
            )
        }
        return (
            <div className={"bo-tip "+theme}>
                <div className="tip-status">
                    {icon}
                </div>
                <div className="tip-content">
                    <div className="tip-content-header">
                        <div className="tip-content-title">
                            <span className="tip-content-first-title">{this.props.title}</span> <span className="tip-content-second-title">{this.props.secondTitle}</span>
                            {hint}
                        </div>
                        
                    </div>
                    {
                        this.props.des?<div className="tip-content-des">
                            {this.props.des}
                        </div>:null
                    }
                    
                </div>
            </div>
        )
    }
}

export default Tip