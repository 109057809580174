import React, { Component } from 'react';

class NotData extends Component {
    render() {
        return (
            <div className="no-job no-data" style={{padding:'72px 0 115px 0'}}>
                    <img style={{width:'240px'}} src={require('@/images/placeholder_empty@2x.png')} alt=""/>
                    <p>
                        暂无待办事项
                    </p>
            </div>
        )
    }
}

export default NotData
