import React, { Component } from "react";
import http from "@/http";
import { API } from "@/http/api";
import { Button, Modal } from "antd";
import Table from "CMPT/common/Table";
import NewCatalogAdd from "./NewCatalogAdd";
import "./NewCatalog.less";
import userUtils from '@/utils/user';
import { AUTH_KEYS } from "@/utils/auth";
const { checkAuthByKey } = userUtils;
const canShowbianjiAuth = checkAuthByKey(AUTH_KEYS.HELP_CENTER_MANAGE_CLASS_MANAGE_EDIT); // 编辑权限；
const canShowDeleteAuth = checkAuthByKey(AUTH_KEYS.HELP_CENTER_MANAGE_CLASS_MANAGE_DELETE); // 删除权限；

class NewCatalog extends Component {
  state = {
    // 修改，编辑
    showEditAdd: false, // id为空 ，表示 新增
    editId: null, // 新增时为空
    editTitle: null, // 新增时为空

    // 页码
    pageNo: 1,
    pageSize: 10,
    total: 0,

    // 预设 删除
    willDeleteId: null,
    showDeleteConfirmFlag: false,

    httpSourceData: [],
    columns: [
      {
        title: "序号",
        key: "id",
        render: (text, record, index) => {
          return <span>{index + 1}</span>;
        }
      },
      {
        title: "类目名称",
        dataIndex: "catalogName"
      },
      {
        title: "操作",

        render: record => (
          <div>
            {
              canShowbianjiAuth ? 
              <a
                className="text-color-highlight"
                onClick={() => this.gotoEdit(record)}
              >
                编辑
              </a> : null
            }
            {
              canShowDeleteAuth ?
              <a
                className="text-color-highlight"
                onClick={() => this.showDeleteConfirm(record)}
              >
                删除
              </a> : null
            }
          </div>
        )
      }
    ]
  };

  // 显示删除弹框
  showDeleteConfirm = record => {
    this.setState({
      showDeleteConfirmFlag: true,
      willDeleteId: record.id
    });
  };

  hideDeleteConfirm = () => {
    this.setState({
      showDeleteConfirmFlag: false,
      willDeleteId: null
    });
  };

  queryList = () => {
    let params = {
      catalogType: this.props.type, //类目类型，1商城 2供应商 3园区
      pageSize: this.state.pageSize,
      pageNo: this.state.pageNo
    };
    const config = {
      api: API.newHelpList,
      data: params
    };
    http(config).then(res => {
      if (
        res &&
        res.success &&
        res.data &&
        res.data.datas &&
        res.data.datas.length > 0
      ) {
        let newData = [...res.data.datas];
        this.setState({
          httpSourceData: newData,
          pageNo: res.data.pageNo,
          pageSize: res.data.pageSize,
          total: res.data.totalRecord
        });
      }
    });
  };

  doDelete = () => {
    let my = this;

    const config = {
      api: API.newHelpCatalogDel,
      data: { encode: my.state.willDeleteId },
      showLoading: true
    };

    http(config).then(res => {
      my.hideDeleteConfirm();
      if (res && res.success) {
        my.queryList();
      }
    });
  };

  gotoAdd = () => {
    this.setState({
      showEditAdd: true, // id为空 ，表示 新增
      editId: null, // 新增时为空
      editTitle: null // 新增时为空
    });
  };

  cancelEdit = () => {
    this.setState({
      showEditAdd: false, // id为空 ，表示 新增
      editId: null, // 新增时为空
      editTitle: null // 新增时为空
    });
    // this.getData()
  };

  gotoEdit = record => {
    this.setState({
      showEditAdd: true, // id为空 ，表示 新增
      editId: record.id, // 新增时为空
      editTitle: record.catalogName // 新增时为空
    });
  };

  onChange = (page, pageSize, sorter) => {
    let order = sorter && sorter.order == "ascend" ? 1 : 2;

    this.setState(
      {
        pageNo: page,
        pageSize: pageSize,
        orderByList: order
      },
      () => {
        this.queryList();
      }
    );
  };

  componentDidMount() {
    this.queryList();
  }

  //
  render() {
    const { pageNo, pageSize, total, httpSourceData } = this.state;

    return (
      <div>
        <div className="btns">
          <Button
            className="add"
            style={{ marginRight: "150px" }}
            onClick={this.gotoAdd}
            type="primary"
          >
            新增
          </Button>
        </div>
        <div className="infor-table">
          <Table
            columns={this.state.columns}
            data={httpSourceData}
            pageSize={pageSize}
            total={total}
            change={this.onChange}
            current={pageNo}
          />
        </div>

        <Modal
          title="确认提示"
          content="是否确认删除"
          visible={this.state.showDeleteConfirmFlag}
          okText="删除"
          closable={false}
          maskClosable={false}
          onOk={this.doDelete}
          width={400}
          onCancel={this.hideDeleteConfirm}
        >
          <p>是否确认删除?</p>
        </Modal>

        {this.state.showEditAdd ? (
          <NewCatalogAdd
            refresh={this.queryList}
            catalogType={this.props.type}
            visible={true}
            title={this.state.editTitle}
            editId={this.state.editId}
            assignCancel={this.cancelEdit}
          ></NewCatalogAdd>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default NewCatalog;
