import {
    AJAX_TYPE
} from '../config';

// 模板

const prefix = 'template';

export default {
    // 模板新增
    [`${prefix}Add`]: {
        url: '/web/template/add',
        type: AJAX_TYPE.POST
    },
    // 模板名称校验
    [`${prefix}CheckName`]: {
        url: '/web/template/checkName',
        type: AJAX_TYPE.POST
    },
    // 模板复制
    [`${prefix}Copy`]: {
        url: '/web/template/copy',
        type: AJAX_TYPE.POST
    },
    // 模板数量
    [`${prefix}Count`]: {
        url: '/web/template/count',
        type: AJAX_TYPE.POST
    },
    // 模板删除
    [`${prefix}Delete`]: {
        url: '/web/template/delete',
        type: AJAX_TYPE.GET
    },
    // 模板详情
    [`${prefix}Get`]: {
        url: '/web/template/get',
        type: AJAX_TYPE.GET
    },
    // 获取模板变量  
    [`${prefix}GetVariable`]: {
        url: '/web/template/getVariable',
        type: AJAX_TYPE.GET
    },
    // 模板初始化
    [`${prefix}Init`]: {
        url: '/web/template/init',
        type: AJAX_TYPE.GET
    },
    // 模板列表
    [`${prefix}List`]: {
        url: '/web/template/list',
        type: AJAX_TYPE.POST
    },
    // 带默认模板的列表
    [`${prefix}AllList`]: {
        url: '/web/template/allList',
        type: AJAX_TYPE.POST
    },
    // 通过
    [`${prefix}Pass`]: {
        url: '/web/template/pass',
        type: AJAX_TYPE.POST
    },
    // 驳回
    [`${prefix}Reject`]: {
        url: '/web/template/reject',
        type: AJAX_TYPE.POST
    },
    // 模板修改
    [`${prefix}Update`]: {
        url: '/web/template/update',
        type: AJAX_TYPE.POST
    },
    [`${prefix}DownLoad`]: {
        url: '/web/template/downLoad',
        type: AJAX_TYPE.POST
    },
    // 审核详情
    [`${prefix}VerifyInfo`]: {
        url: '/web/template/verifyInfo',
        type: AJAX_TYPE.GET
    },
}