import React from 'react';
import { Checkbox, Select, Icon } from 'antd';

import './SettingAuthRow.less';

const SelectOption = Select.Option;

/**
 * 权限复选框改变处理
 * 数据权限选择器改变处理
 * disabled处理
 * TODO:style处理
 * @param {*} props
 */
export default function SettingAuthRow({ authMap, authCode, changeCheck, changeSelect }) {
    const auth = authMap[authCode];
    if (auth) {
        const subAuthCodes = auth.subs;
        let hasNoGrandChildren = true;
        let children;
        if (subAuthCodes && subAuthCodes.length) {
            // 下级权限
            children = subAuthCodes.map(subAuthCode => {
                const subAuth = authMap[subAuthCode];
                if (subAuth.subs && subAuth.subs.length) {
                    hasNoGrandChildren = false;
                }

                return <SettingAuthRow authMap={authMap} authCode={subAuthCode} key={subAuthCode} changeCheck={changeCheck} changeSelect={changeSelect} />;
            });
        }

        const authScope = auth.scope;
        let scopeSelect;
        if (authScope && authScope.length) {
            // 数据权限
            scopeSelect = (
                <Select disabled={auth.scopeDisabled} value={auth.scopeValue} onChange={select} suffixIcon={<Icon type="caret-down" />} size='small'>
                    {authScope.map(({ value, desc }) => <SelectOption value={value} key={value}>{desc}</SelectOption>)}
                </Select>
            );
        }

        function select(value) {
            changeSelect(value, authCode);
        }

        function change({ target: { checked } }) {
            changeCheck(checked, authCode);
        }

        return (
            <div className={`setting-auth-row level-${auth.level}`}>
                <Checkbox checked={auth.checked} disabled={auth.disabled} indeterminate={auth.indeterminate} onChange={change}>{auth.desc}</Checkbox>
                {scopeSelect}
                {children && <div className={'sub-auth ' + (hasNoGrandChildren ? 'flex' : 'row')}  >{children}</div>}
            </div>
        );
    }
    return null;
}