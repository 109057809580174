import React, { Component, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Form, Breadcrumb, Button, Input } from 'antd';
import SettingAuth from 'CMPT/common/auth/SettingAuth';
import AuthSetting from 'CMPT/common/auth/AuthSetting';
import http from '@/http';
import { API } from '@/http/api';
import getAuthConfig from '@/utils/authConfig';
import styles from './SettingOrganizationRoleForm.module.less';

const BreadcrumbItem = Breadcrumb.Item;
const FormItem = Form.Item;
const { TextArea } = Input;

export default function SettingOrganizationRoleForm({ match, history }) {
    const [isNew, toggleNew] = useState(true);
    const [submitLoading, toggleSubmitLoading] = useState(false);

    const [role, setRole] = useState(null);
    const [authMapInit, setAuthMapInit] = useState(null);

    const [roleId, setRoleId] = useState(null);

    const initRoleDone = useRef(true);

    let baseForm, settingAuth;

    useEffect(() => {
        resetCurrent();

        const isNew = match.path.endsWith('/role/new');
        toggleNew(isNew);
        if (!isNew) {
            const id = match.params.id;
            setRoleId(id)
            initModifyForm(id);
        } else {
            initNewForm();
        }
    }, [match.path]);

    function resetCurrent() {
        initRoleDone.current = true;
    }

    function initNewForm() {
        setAuthMapInit([]);
    }

    function initModifyForm(id) {
        const config = {
            api: API.authRoleDetail,
            path:{id },
            showLoading: true
        };
        http(config).then(data => {
            const role = data.data || {};
            setRole(role);
            initRoleDone.current = false;
            setAuthMapInit(role.functionIds);
        });
    }

    function submit() {
        baseForm.props.form.validateFields((errors, values) => {
            if (!errors) {
                // 检查权限设置
                let functionIds = settingAuth.value();
                if(functionIds==='error'){
                    return;
                }

                // 菊花圈
                toggleSubmitLoading(true);

        
                // 入参
                let api = API.authRoleAdd;
                let data = {
                    ...values,
                    functionIds
                }
                if (!isNew) {
                    api = API.authRoleEdit;
                    data.id = match.params.id;
                }

                // 去请求
                http({
                    api,
                    data,
                    successTip: '保存成功',
                    showLoading: true
                }).then(ajaxData => {
                    toggleSubmitLoading(false);
                    if (ajaxData.success) {
                        // 保存成功后跳转到列表
                        history.replace('/app/crm/setting/role');
                    }
                }).catch(error => {
                    toggleSubmitLoading(false);
                });
            }
        });
    }

 

    function cancel() {
        if (history.length > 1) {
            history.goBack();
        }
    }

    function setForm(form) {
        baseForm = form;
        if (!initRoleDone.current && form) {
            initRoleDone.current = true;
            const { name, roleDesc } = role;
            baseForm.props.form.setFieldsValue({
                name,
                roleDesc
            });
        }
    }

    return (
        <div className={styles.SettingOrganizationRoleForm}>
            <Breadcrumb>
                <BreadcrumbItem>组织管理</BreadcrumbItem>
                <BreadcrumbItem><Link to='/app/crm/setting/role'>角色管理</Link></BreadcrumbItem>
                <BreadcrumbItem>{isNew ? '新增角色' : '编辑角色'}</BreadcrumbItem>
            </Breadcrumb>
            <div className={styles.baseinfo}>
                <div className={styles.title}>角色信息</div>
                <WrapperRoleBaseInfoForm wrappedComponentRef={setForm} />
            </div>
            <div className={styles.authinfo}>
                <div className={styles.title}>角色权限</div>
                {authMapInit?<AuthSetting  authMapInit={authMapInit} ref={ref => settingAuth = ref} />:''}
            </div>
            <div className={styles.operate}>
                <Button type="primary" onClick={submit} loading={submitLoading} size='large'>保存</Button>
                <Button onClick={cancel} size='large'>取消</Button>
            </div>
        </div>

    );
}

class RoleBaseInfoForm extends Component {
    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Form >
                <FormItem
                    label="角色名称"
                >
                    {getFieldDecorator('name', {
                        rules: [{
                            max: 10, message: '角色名称不超过10个字',
                        }, {
                            required: true, message: '角色名称不能为空',
                        }],
                    })(
                        <Input placeholder="请输入" className={styles.name} />
                    )}
                </FormItem>
                <FormItem
                    label="角色描述"
                >
                    {getFieldDecorator('roleDesc', {
                        rules: [{
                            max: 15, message: '角色描述不超过15个字',
                        }, {
                            required: true, message: '角色描述不能为空',
                        }],
                    })(
                        <TextArea placeholder="请输入" className={styles.desc} />
                    )}
                </FormItem>
            </Form>
        );
    }
}

const WrapperRoleBaseInfoForm = Form.create({ name: 'RoleBaseInfoForm' })(RoleBaseInfoForm);
