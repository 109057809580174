import React, { Component } from 'react';
import { Popover, Icon } from 'antd';
import Tip from 'CMPT/common/Tip';
import ApprovalChain from 'CMPT/common/ApprovalChain';

class SupperlierInfo extends Component {
    state = {
        stepList: []
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            stepList: nextProps.data.nodes || []
        })
    }
    render() {
        const data = this.props.data;
        const auditComponent = (
            <Popover overlayClassName="bo-audit-popover" content={<ApprovalChain stepList={this.state.stepList}/>} trigger="click" placement="bottomRight">
                <div className="clickPopover"><i className="iconfont iconapprove"></i><span>查看审批流程</span></div>
            </Popover>
        )
        let person = null;
        this.state.stepList && this.state.stepList.map((item,index) =>{
            if(item.reached && !item.processed) {
                let personName = item.users && item.users.map((newItem,newIndex) => {  
                    if(!newItem.processed){
                        return newItem.name + (item.type == 3 ? ' / ' : ' + ')
                    } 
                })
                person='当前审批人：'+personName.join('')
            }
        })
        const rejectReason =  data.auditRemark ? <div>驳回原因: {data.auditRemark}</div> : ''
        const passReason = data.auditRemark ? <div>备注: {data.auditRemark}</div> : ''
        let tip = null;
         switch(data.auditStatus) {  //{0->-;10->待提交;20->审批中;30->审批驳回;40->审批通过}               
                case 10:
                    tip = <Tip type="minus" title="待提交" hint={true}/>
                    break
                case 20:
                    tip = <Tip type="wait" title="审批中" hint={true} hintNode={auditComponent} secondTitle={person && person.substring(0,person.length-2)}/>
                    break
                case 30:
                    tip = <Tip type="warn" title="审批驳回" hint={true} hintNode={auditComponent} des={rejectReason}/>
                    break
                case 40:
                    tip = <Tip type="pass" title="审批通过" hint={true} hintNode={auditComponent} des={passReason}/>
                    break
                default:
                    tip = null
                }
        return (
            <div>
                {tip}              
                <div className="supplier-baseInfo">
                    <div className="title">账户信息</div>
                    <div className="lists">供应商名称：{data.name}</div>
                    <div className="lists">商品信息：{data.goodsInfo}</div>
                    <div className="lists">唯一标识：{data.creditTypeDesc}<span style={{padding: '0px 6px'}}></span>{data.creditNumber}</div>
                    <div className="lists">地址：{data.countryName}{data.provinceName}{data.cityName}{data.regionName}{data.address}</div>
                    <div className="lists">联系人信息：{data.contactName}<span style={{padding: '0px 6px'}}></span>{data.contactPhone}</div>
                    <div className="lists">对接人：{data.userName  || '-'}</div>
                    <div className="lists">评分：{data.score  || '-'}</div>
                    <div className="lists">说明：{data.remark  || '-'}</div>
                </div>
                <div className="supplier-baseInfo">
                    <div className="title">收款信息</div>
                    <div className="lists">收款方式: {data.payeeTypeDesc || '-'}</div>
                    <div className="lists">收款人姓名: {data.payee  || '-'}</div>
                    <div className="lists">收款人账户: {data.payeeAccount  || '-'}</div>
                </div>
            </div>
        );
    }
}

export default SupperlierInfo;