import {
    AJAX_TYPE
} from '../config';

// 公告

const prefix = 'announce';

export default {
    // 新建公告
    [`${prefix}Add`]: {
        url: '/web/announcement/add',
        type: AJAX_TYPE.POST
    },
    // 修改公告
    [`${prefix}Edit`]: {
        url: '/web/announcement/edit',
        type: AJAX_TYPE.POST
    },
    // 删除公告
    [`${prefix}Delete`]: {
        url: '/web/announcement/delete/:encode',
        type: AJAX_TYPE.DELETE
    },
    // 查看公告
    [`${prefix}Detail`]: {
        url: '/web/announcement/get/:encode',
        type: AJAX_TYPE.GET
    },
    // 公告列表  
    [`${prefix}List`]: {
        url: '/web/announcement/list',
        type: AJAX_TYPE.POST
    },
    // 通知人员列表
    [`${prefix}NoticeUserList`]: {
        url: '/web/announcement/notice_user_list',
        type: AJAX_TYPE.POST
    },
    // 通知中心  我的公告列表
    [`${prefix}MyList`]: {
        url: '/web/announcement/myList',
        type: AJAX_TYPE.POST
    },
}