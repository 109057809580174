import React, { Component } from 'react';
import moment from 'moment'
import JobNumber from './JobNumber'
import JobPane from './JobPane'
import NotData from './NotData'
import {
    myWorkBillList,
    myWorkBillTemplateList,
    myWorkComplainList,
    myWorkContractList,
    myWorkContractTemplateList,
    myWorkMaintinList,
    listMaterial
} from './service/jobService'
import user from '@/utils/user'
const { hasAuth } = {...user}

class NotYet extends Component {
    // 0 待我审批合同 1 我发起待审批合同  2 待我审批账单  3 份待我审批模板 4 份我发起待审批模板 5  个待派单工单 6 个待验收工单
    state = {

        myWorkContractList: {
            title: '待审批合同',
            data: [
            //     {
            //     type: 0,
            //     count: 0,
            //     data: [
            //         // {
            //         //     name: '租客  沈洁洁',
            //         //     location: '万科光电大厦  1层102/101',
            //         //     time: '2019-09-02'
            //         // }, {
            //         //     name: '租客  沈洁洁',
            //         //     location: '万科光电大厦  1层102/101',
            //         //     time: '2019-09-02'
            //         // }
            //     ]
            // },
            // {
            //     type: 1,
            //     count: 0,
            //     data: [
            //         // {
            //         //     name: '租客  沈洁洁',
            //         //     templateType: '租赁合同',
            //         //     location: '万科光电大厦  1层102/101',
            //         //     time: '2019-09-02'
            //         // }, {
            //         //     name: '租客  沈洁洁',

            //         //     location: '万科光电大厦  1层102/101',
            //         //     time: '2019-09-02'
            //         // }
            //     ]
            // }
            ]
        },
        myWorkBillList: {
            title: '待审批账单',
            data: [{
                type: 2,
                count: 0,
                data: [
                    // {
                    //     status: 0,
                    //     name: '3000.00元',
                    //     location: '万科光电大厦  1层102/101',
                    //     userName: '张三',
                    //     time: '2019-09-02'
                    // }, {
                    //     status: 1,
                    //     name: '3000.00元',
                    //     location: '万科光电大厦  1层102/101',
                    //     userName: '张三',
                    //     time: '2019-09-02'
                    // }
                ]
            }
            ]
        },
        myWorkContractTemplateList: {
            title: '待审批合同模板',
            data: [{
                type: 3,
                count: 0,
                data: [
                    // {
                    //     name: 'A栋大厦新签合同模板1',
                    //     templateType: '物业合同',
                    //     location: '万科光电大厦  1层102/101',
                    //     userName: '张三三',
                    //     time: '2019-09-02'
                    // }, {
                    //     name: '3000.00元',
                    //     templateType: '物业合同',
                    //     location: '万科光电大厦  1层102/101',
                    //     userName: '王五五',
                    //     time: '2019-09-02'
                    // }
                ]
            }
            ]
        },
        myWorkBillTemplateList: {
            title: '待审批收据模板',
            data: [{
                type: 4,
                count: 0,
                data: [
                    // {
                    //     name: 'B栋大厦新签收据模板1',
                    //     location: '万科光电大厦  1层102/101',
                    //     userName: '张三三',
                    //     time: '2019-09-02'
                    // }, {
                    //     name: 'B栋大厦新签收据模板1',
                    //     location: '万科光电大厦  1层102/101',
                    //     userName: '王五五',
                    //     time: '2019-09-02'
                    // }
                ]
            }
            ]
        },
        myWorkMaintinList: {
            title: '待处理维修工单',
            data: [
            //     {
            //     type: 5,
            //     count: 0,
            //     data: [
            //         // {
            //         //     name: '屋内设施 水管',
            //         //     status: 1,
            //         //     location: '万科光电大厦  1层102/101',
            //         //     time: '2019-09-02'
            //         // }, {
            //         //     name: '公共设施 水管',
            //         //     status: 2,
            //         //     location: '万科光电大厦  1层102/101',
            //         //     time: '2019-09-02'
            //         // }
            //     ]
            // },
            // {
            //     type: 6,
            //     count: 0,
            //     data: [
            //         // {
            //         //     status: 1,
            //         //     name: '屋内设施 地板',
            //         //     location: '万科光电大厦  1层102/101',
            //         //     time: '2019-09-02'
            //         // }, {
            //         //     status: 2,
            //         //     name: '公共设施 围栏',
            //         //     location: '万科光电大厦  1层102/101',
            //         //     time: '2019-09-02'
            //         // }
            //     ]
            // }
            ]
        },
        myWorkBillComplainList: {
            title: '待处理投诉工单',
            data: [
            //     {
            //     type: 7,
            //     count: 0,
            //     data: [
            //         // {
            //         //     status: 1,
            //         //     name: '公共设施脏乱差，打电话没人修…',
            //         //     location: '万科光电大厦  1层102/101',
            //         //     time: '2019-09-02'
            //         // }, {
            //         //     status: 2,
            //         //     name: '公共设施脏乱差，打电话没人修，打电话打电话',
            //         //     location: '万科光电大厦  1层102/101',
            //         //     time: '2019-09-02'
            //         // }
            //     ]
            // },
            // {
            //     type: 8,
            //     count: 0,
            //     data: [
            //         // {
            //         //     status: 1,
            //         //     name: '公共设施脏乱差，打电话没人修…',
            //         //     location: '万科光电大厦  1层102/101',
            //         //     time: '2019-09-02'
            //         // }, {
            //         //     status: 2,
            //         //     name: '公共设施脏乱差，打电话没人修，打电话打电话',
            //         //     location: '万科光电大厦  1层102/101',
            //         //     time: '2019-09-02'
            //         // }
            //     ]
            // }
            ]
        }
    }

    getContractList = () => {
        myWorkContractList().then(res => {
            if (res.code == 0) {
                const { myContract, waitToApprove } = { ...res.data }
                let _myContract = {
                    type: 0,
                    count: myContract.totalCount||0,
                    data: [
                    ]
                }
                _myContract.data = myContract.data&&myContract.data.map(item => {
                    return {
                        name: `租客  ${item.customerNames.join('')}`,
                        location: item.projectNames.join(''),
                        time: moment(item.commitDateTime).format('YYYY-MM-DD'),
                        status: item.signType,
                        auditType:item.auditType,
                        encode:item.encode
                    }
                })
                let _waitToApprove = {
                    type: 1,
                    count: waitToApprove&&waitToApprove.totalCount||0,
                    data: [
                    ]
                }
                _waitToApprove.data = waitToApprove&&waitToApprove.data&&waitToApprove.data.map(item => {
                    return {
                        name: `租客  ${item.customerNames.join('')}`,
                        location: item.projectNames.join(''),
                        time: moment(item.commitDateTime).format('YYYY-MM-DD'),
                        status: item.signType,
                        auditType:item.auditType,
                        encode:item.encode
                    }
                })
                let myWorkContractList = {
                    title: '待审批合同',
                    data: [
                      
                    ]                    
                }
                if(_waitToApprove.count>0){
                    myWorkContractList.data.push(_waitToApprove)
                }
                if(_myContract.count>0){
                    myWorkContractList.data.push(_myContract)
                }
                this.setState({
                    myWorkContractList: myWorkContractList
                })
            }
        })
    }

    getBillList = () => {
        myWorkBillList().then(res => {
            if (res.code == 0) {
                if (res.data) {
                    let myWorkBillList = {
                        title: '待审批账单',
                        data: [{
                            type: 2,
                            count: res.data.totalCount||0,
                            data: [
                                // {
                                //     status: 0,
                                //     name: '3000.00元',
                                //     location: '万科光电大厦  1层102/101',
                                //     userName: '张三',
                                //     time: '2019-09-02'
                                // }, {
                                //     status: 1,
                                //     name: '3000.00元',
                                //     location: '万科光电大厦  1层102/101',
                                //     userName: '张三',
                                //     time: '2019-09-02'
                                // }
                            ]
                        }
                        ]
                    }
                    myWorkBillList.data[0].data = res.data.data&&res.data.data.map(item => {
                        return {
                            status: item.budgetType,
                            name: item.couldPayAmount + '元',
                            location: item.floorMsg,
                            userName: item.createUserName,
                            time: moment(item.createTime).format('YYYY-MM-DD'),
                            billType:item.billType,
                            encode:item.encode
                        }
                    })
                    this.setState({
                        myWorkBillList : myWorkBillList
                    })
                }
            }
        })
    }

    getContractTemplateList = () => {
        myWorkContractTemplateList().then(res => {
            if (res.code == 0 && res.data) {
                let data = res.data.data;
                let templateList = data&&data.map(item => {
                    return {
                        title: item.projectList[0].name + item.name,
                        templateType: item.typeDesc,
                        name: item.createName,
                        time: moment(item.createTime).format('YYYY-MM-DD'),
                        encode:item.encode,
                        type:3
                    }
                })
                console.log('templateList')
                console.log(templateList)
                this.setState({
                    myWorkContractTemplateList: {
                        title: '待审批合同模板',
                        data: [{
                            type: 3,
                            count: res.data.totalCount||0,
                            data: templateList
                        }]
                    },
                })

            }
        })
    }

    getBillTemplateList = () => {
        myWorkBillTemplateList().then(res => {
            if (res.code == 0) {
                let data = res.data.data;
                let billTemplateList = data&&data.map(item => {
                    return {
                        title: item.projectList[0].name + item.name,
                        location: item.projectList[0].name,
                        name: item.createName,
                        time: moment(item.createTime).format('YYYY-MM-DD'),
                        encode:item.encode,
                        type:4
                    }
                })
                this.setState({
                    myWorkBillTemplateList: {
                        title: '待审批收据模板',
                        data: [{
                            type: 4,
                            count: res.data.totalCount||0,
                            data: billTemplateList
                        }
                        ]
                    },
                })
            }
        })
    }
    getBillComplainList = () => {
        // listMaterial().then(res=>{
        //     console.log(res)
        // })
        myWorkComplainList().then(res => {
            if (res.code == 0) {
                if (res.data) {
                    const { checkObj, dispatchObj } = { ...res.data }
                    let myCheckObj = {
                        type: 8,
                        count: checkObj.totalCount||0,
                        data: [
                        ]
                    }
                    myCheckObj.data = checkObj.data&&checkObj.data.map(item => {
                        return {
                            status: item.urgentLevel,
                            name: item.complainContent,
                            location: item.projectHouseName,
                            time:moment(item.processTime).format('YYYY-MM-DD HH:mm'),
                            encode:item.encode,
                            type:1
                        }
                    })
                    let myDispatchObj = {
                        type: 7,
                        count: dispatchObj.totalCount||0,
                        data: [
                        ]
                    }
                    myDispatchObj.data = dispatchObj.data&&dispatchObj.data.map(item => {
                        return {
                            status: item.urgentLevel,
                            name: item.complainContent,
                            location: item.projectHouseName,
                            time: moment(item.processTime).format('YYYY-MM-DD HH:mm'),
                            encode:item.encode,
                            type:1
                        }
                    })
                    let myWorkBillComplainList = {
                        title: '待处理投诉工单',
                        data: [
                           
                        ]
                    }
                    if(myDispatchObj.count>0){
                        myWorkBillComplainList.data.push(myDispatchObj)
                    }
                    if(myCheckObj.count>0){
                        myWorkBillComplainList.data.push(myCheckObj)
                    }
                    this.setState({
                        myWorkBillComplainList: myWorkBillComplainList
                    })
                }
            }
        })
    }

    getMaintinList = () => {
        myWorkMaintinList().then(res => {
            if (res.code == 0) {
                const { checkObj, dispatchObj } = { ...res.data }
                let myCheckObj = {
                    type: 6,
                    count: checkObj.totalCount||0,
                    data: []
                }
                myCheckObj.data = checkObj.data&&checkObj.data.map(item => {
                    let projectVo = item.projectVo;
                    let location = projectVo && (projectVo.name + projectVo.floors[0].name+projectVo.floors[0].zones[0].zoneNo) || '暂无关联项目'
                    return {
                        name: item.bizTypeName+'  '+item.typeName,
                        status: item.urgentLevel,
                        location: location,
                        time: moment(item.processTime).format('YYYY-MM-DD HH:mm'),
                        encode:item.encode,
                        type:2
                    }
                })
                let myDispatchObj = {
                    type: 5,
                    count: dispatchObj.totalCount||0,
                    data: []
                }
                myDispatchObj.data = dispatchObj.data&&dispatchObj.data.map(item => {
                    let projectVo = item.projectVo;
                    let location = projectVo && (projectVo.name + projectVo.floors[0].name+projectVo.floors[0].zones[0].zoneNo) || '暂无关联项目'
                    return {
                        name: item.bizTypeName+'  '+item.typeName,
                        status: item.urgentLevel,
                        location: location,
                        time: moment(item.processTime).format('YYYY-MM-DD HH:mm'),
                        encode:item.encode,
                        type:2
                    }
                })
                let myWorkMaintinList = {
                    title: '待处理维修工单',
                    data: [
                       
                    ]
                }
                if(myDispatchObj.count>0){
                    myWorkMaintinList.data.push(myDispatchObj)
                }
                if(myCheckObj.count>0){
                    myWorkMaintinList.data.push(myCheckObj)
                }
                this.setState({
                    myWorkMaintinList: myWorkMaintinList
                })
            }
        })
    }


    componentDidMount() {
        /*
            维修服务_查看 990204010101 
            投诉管理_查看 990204010201
            模板管理_合同模板 99030201
            模板管理_收据模板 99030202
            财务收支_账单管理_查看 9902030301
            审批列表 99020102
            合同列表 99020101
        */
       hasAuth(990204010101)&&(this.getMaintinList())
       hasAuth(99020101)&&(this.getContractList())
       hasAuth(9902030301)&&(this.getBillList())
       hasAuth(99030201)&&(this.getContractTemplateList())
        hasAuth(99030202)&&(this.getBillTemplateList())
        hasAuth(990204010201)&&(this.getBillComplainList())
        
    }

    render() {
        const { myWorkContractList, myWorkBillList, myWorkContractTemplateList, myWorkBillComplainList, myWorkBillTemplateList, myWorkMaintinList } = { ...this.state };
        let jobData = []
        let dataCount = 0;
        if (myWorkContractList.data.length > 0) {
            jobData.push(myWorkContractList)
            dataCount++
        }
        if (myWorkBillList.data[0].count > 0) {
            jobData.push(myWorkBillList)
            dataCount++
        }
        if (myWorkContractTemplateList.data[0].count > 0) {
            jobData.push(myWorkContractTemplateList)
            dataCount++
        }
        if (myWorkBillTemplateList.data[0].count > 0) {
            jobData.push(myWorkBillTemplateList)
            dataCount++
        }
        if (myWorkMaintinList.data.length>0) {
            jobData.push(myWorkMaintinList)
            dataCount++
        }
        if (myWorkBillComplainList.data.length>0) {
            jobData.push(myWorkBillComplainList)
            dataCount++
        }
        const jobPaneHtml = dataCount >0 ? jobData&&jobData.map((item, index) => <JobPane key={index} {...item} />) :  <NotData />
        return (
            <div className="not-yet">
              {   hasAuth(99020101) ? <JobNumber /> : null }
                {jobPaneHtml}  
            </div>
        )
    }
}

export default NotYet
