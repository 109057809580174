import Enum from "@/utils/Enum";

// 签约方式
export const signType = new Enum(  
  { alias: "ONLINE", text: "线上", value: 1 },
  { alias: "OFFLINE", text: "线下", value: 2 },
  { alias: "UNKNOW", text: "未知", value: 3 },  
);

// 状态
export const statusType = new Enum(
  { alias: "UNDER_REVIEW", text: "未确认", value: 1 },
  { alias: "STAMP_CENTER_SEALING", text: "待审核", value: 2 },
  { alias: "SUPPLIER_SEALING", text: "印章中心待盖章", value: 3 },
  { alias: "SUPPLIER_RESEAL", text: "供应商待盖章", value: 4 },
  { alias: "SUCCESS", text: "供应商重新盖章", value: 5 },
  { alias: "FAILURE", text: "签约成功", value: 6 },
  { alias: "CANCEL", text: "签约失败", value: 7 },
  { alias: "PENDING_SEAL", text: "线下等待盖章", value: 8 },
);
