import React, { Component } from "react";
import { Breadcrumb, Tabs } from "antd";
import Announcement from "./Announcement/Announcement";
import AnnouncementType from "./AnnouncementType/AnnouncementType";
import Journalism from "./Journalism/Journalism";
import JournalismType from "./JournalismType/JournalismType";
import "./index.less";
import userUtil from "@/utils/user";
const {isUs} = userUtil;
const { TabPane } = Tabs;
const BreadcrumbItem = Breadcrumb.Item;

class index extends Component {
  render() {
    return (
      <div className="banner_all">
        <div>
          <Breadcrumb>
            <BreadcrumbItem>平台公告</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div>
          <Tabs defaultActiveKey="1" className="tabs">
            <TabPane tab="公告发布" key="1" className="tab">
              <Announcement />
            </TabPane>
            <TabPane tab="公告类型设置" key="2" className="type">
              <AnnouncementType />
            </TabPane>
            <TabPane tab="新闻发布" key="3" className="news">
              <Journalism />
            </TabPane>
            <TabPane tab="新闻类型设置" key="4" className="newtype">
              <JournalismType />
            </TabPane>
          </Tabs>
        </div>
      </div>
    );
  }
}

export default index;
