// 获取审核状态对应的描述
const getAuditStatusDesc = (status) => {// 1：待审核，2：已通过，3：已驳回，4：强制下架
    switch (status) {
        case 1:
            return '待审核';
        case 2:
            return '已通过';
        case 3:
            return '已驳回';
        case 4:
            return '违规下架';

        default:
            return '-';
    }
}

// 获取审核状态对应的操作
const getAuditStatusActions = (status) => {// 1：待审核，2：已通过，3：已驳回，4：强制下架 
    switch (status) {
        case 1:
            return ['审核']
        case 2:
            return ['强制下架']

        default:
            return ['-']
    }
}

// 获取发布状态对应的描述
const getPublishStatusDesc = (status) => {//状态0：待发布，1：已发布，2：待开始，3：进行中，4：已结束，5：已成交，6：未成交，7：已作废，8：违规下架，9：已删除
    switch (status) {
        case 0:
            return '待发布';
        case 1:
            return '已发布';
        case 2:
            return '待开始';
        case 3:
            return '进行中';
        case 4:
            return '已结束';
        case 5:
            return '已成交';
        case 6:
            return '未成交';
        case 7:
            return '已作废';
        case 8:
            return '违规下架';
        case 9:
            return '已删除';

        default:
            return '-';
    }
}

// 获取产品类型的描述 产品类型,1: 现货/标准品,2: 加工/订制品
const getProductTypeName = (type) => {
    switch (type) {
        case 1:
            return '现货/标准品'
        case 2:
            return '加工/订制品'
        default:
            return '-'
    }
}

// 获取询价方式,1：公开询价 
const getInquiryTypeDesc = (type) => {
    switch (type) {
        case 1:
            return '公开询价'
        default:
            return '-'
    }
}

// 获取报价方式，报价方式，1：密封报价，2：非密封报价
const getQuoteTypeDesc = (type) => {
    switch (type) {
        case 1:
            return '密封报价'
        case 2:
            return '非密封报价'
        default:
            return '-'
    }
}

export {
    getAuditStatusDesc,
    getAuditStatusActions,
    getPublishStatusDesc,
    getProductTypeName,
    getInquiryTypeDesc,
    getQuoteTypeDesc
}

