import React, { Component } from "react";
import { Modal, Form, Input, Tree, message } from "antd";
import { API } from "@/http/api";
import http from "@/http";
import { node } from "prop-types";

const { Search } = Input;
const { TreeNode } = Tree;

class NewChannelNav extends Component {
  payload = null;
  parentKey="";
  state = {
    modalVisibility: false,
    loading: false,
    treeData:[],
    expandedKeys: [],
    checkedKeys: [],
    selectedKeys: [],
    searchValue: '',
    autoExpandParent: false,
    checkedInfo: []
  };
  
  getLevelList(){
   let config = {
      api: API.categoryTree,
      // api: API.channelNavOneAndTwoLevel,
      showLoading: true,
    }
    http(config).then(res => {
      if(res.success){
        this.setState({
          treeData: res.data
        })
      }
    })
  }
  formateSourceData = () => {
    const {treeData,checkedKeys=[]} = this.state;
    console.log('checkedkeys', checkedKeys)
    let parentKey={};
    let nodes = [];
    // checkedKeys.reduce((item,cur)=>{
    //   for (let i = 0; i < treeData.length; i++) {
    //     const nodes = treeData[i];
    //     if (nodes.childList.some(node => node.code == cur)) {
    //       const checkedNode = nodes.childList.filter(j => j.code == cur);
    //       parentKey[nodes.code] = parentKey[nodes.code] || [];
    //       parentKey[nodes.code].push(...checkedNode);  
    //     }
    //   }
    // },[]) 
    // for (let [key, value] of Object.entries(parentKey)) {
    //   const halfNodes = treeData.filter(item => item.code == key);
    //   nodes.push({...halfNodes[0], childList:value});
    // }
    // console.log(nodes, '编辑，没够炫')
    return;
    return nodes;
  }

  formateTreeData = () => {
    const { checkedInfo: {halfCheckedKeys,checkedNodes=[]}, treeData, checkedKeys } = this.state;
    console.log('halfCheckedKeys', halfCheckedKeys)
    console.log('checkedNodes', checkedNodes)
    let nodesCodes = [];
    checkedKeys.forEach(item => {
      nodesCodes.push(item)
    })
    checkedNodes && checkedNodes.forEach(item => {
      if (nodesCodes.indexOf(item.key) == -1) {
        nodesCodes.push(item.key)
      }
    })
    nodesCodes = nodesCodes.map(item => {
      for (let i = 0; i < treeData.length; i++) {
        if (item == treeData[i].code) {
          return treeData[i];
        }
        let level2Arr = treeData[i].childList;
        if (level2Arr) {
          for (let a = 0; a < level2Arr.length; a++) {
            if (item == level2Arr[a].code) {
              return level2Arr[a];
            }
            let level3Arr = level2Arr[a].childList;
            if (level3Arr) {
              for (let b = 0; b < level3Arr.length; b++) {
                if (item == level3Arr[b].code) {
                  return level3Arr[b];
                }
              }
            }
          }
        }
      }
    })
    // let halfCheckedNode = {};
    // let nodes = checkedNodes.reduce((filterNodes,cur)=>{
    //   const curProps = cur.props;
    //   if(curProps.children){ // 有children，代表二级类目全部选择
    //     filterNodes.push(curProps.dataRef)
    //     curProps.children.reduce((filterNodesChild, curChild) => {
    //       const curChildProps = curChild.props;
    //       if (curChildProps.children) {
    //         filterNodesChild.push(curChildProps.dataRef);
    //       }
    //       return filterNodesChild;
    //     })
    //   } else if(curProps.parentKey){  // 代表二级类目
    //     // 判断当前类目的父级是否在halfCheckedKeys
    //     if(halfCheckedKeys.indexOf(curProps.parentKey)> -1){
    //       // 部分选中的二级类目
    //       halfCheckedNode[curProps.parentKey] = halfCheckedNode[curProps.parentKey] || [];
    //       halfCheckedNode[curProps.parentKey].push(curProps.dataRef);          
    //     }
    //   }
    //   return filterNodes;
    // },[]) 

    // for (let [key, value] of Object.entries(halfCheckedNode)) {
    //   const halfNodes = treeData.filter(item => item.code == key);
    //   // halfNodes[0].childList = value;
    //   nodes.push({...halfNodes[0], childList:value});
    // }
    // console.log(nodes, '勾选了')
    return nodesCodes;
  }

  handleOk = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const payload = this.payload;
        const {checkedInfo=[],checkedKeys=[]} = this.state;
        const { okCb, errorCb, sourceData,params={}, channelType, zhuSourceData } = this.props;
        let config = {
          api: channelType== 1 ? API.channelNavAddNavbarForMINI : API.channelNavAddNavbar,
          // showLoading: true,
          data:{
            id:Number(params.id),
            type:22,
            ...values,
            code: zhuSourceData.code,
            list: this.formateTreeData()
            // list: payload && checkedInfo.length==0 && checkedKeys.length > 0 ? this.formateSourceData() : this.formateTreeData()// checkedInfo为空，checkedKeys不为空，直接获取编辑时的数据
          }
        }
    
        if(payload){ // 编辑
          config.api = API.channelNavEditNavBar;
          config.data.code = sourceData.code;          
        }
        this.setState({
          loading: true
        });
        http(config).then(res => {
          this.setState({ loading: false, modalVisibility: false });
          if (res.code == 200 || res.success) {
            message.success("操作成功");
            okCb && okCb();
          } else {
            errorCb && errorCb(res, payload);
          }
          this.hide();
        }).catch(error => {
          this.setState({loading: false, modalVisibility: false });
          this.hide();
          errorCb && errorCb(error, payload);
        });
      }
    });
  };

  onCancel = () => {
    this.hide();
    const { cancelCb } = this.props;
    cancelCb && cancelCb();
  };

  show = (payload = null) => {
    this.payload = payload;
    const {sourceData={} } = this.props;
    let expandKeys=[];

    // init 编辑时的数据
    if(payload){
      console.log(sourceData, 'sourceData.categoryVos', payload)
      expandKeys = sourceData.categoryVos&&sourceData.categoryVos.reduce((nodes,cur)=>{
        if (cur.childList) {
          cur.childList.forEach(item => {
            nodes.push(item.code);
          })
        } else {
          nodes.push(cur.code)
        }
        return nodes;
      },[]);
    }
    
    this.getLevelList();
    this.setState({
      modalVisibility: true,
      expandedKeys:expandKeys,
      checkedKeys:expandKeys,
    });
  };

  hide = () => {
    this.props.form.resetFields();
    this.setState({
      modalVisibility: false,
      treeData:[],
      expandedKeys: [],
      checkedKeys: [],
      selectedKeys: [],
      searchValue: '',
      checkedInfo: [] 
    });
  };

  // 字符长度判断
getStringLength = (str) => {  
    var slength=0;  
    for(let i=0;i<str.length;i++){  
     if ((str.charCodeAt(i)>=0) && (str.charCodeAt(i)<=255))  
       slength=slength+1;  
     else  
       slength=slength+2;  
    }   
    return slength;  
  };

  fiterSearchNodes = (tree=[],value) => {
    let parentKey=[];
    for (let i = 0; i < tree.length; i++) {
      const item = tree[i];
      if (value != '' && item.name.indexOf(value) > -1) {
         parentKey.push(item.code);
      }else if(item.childList){
        if (value != '' &&item.childList.some(node => node.name.indexOf(value) > -1)) {
           parentKey.push(item.code);;
        } else if (this.fiterSearchNodes( item.childList,value)) {
           this.fiterSearchNodes(item.childList,value);
        }
      }
      
    }
    return parentKey;
  }


  onChange = e => {
    const value = e && e.target.value;
    const { treeData=[] } = this.state;
    const expandedKeys = this.fiterSearchNodes(treeData,value);

    this.setState({
      expandedKeys,
      searchValue: value,
      autoExpandParent: value?true:false,
    });
  };

  // 只展示一、二级
  renderTreeNodes = data =>{
    const { searchValue } = this.state;
    return data.map(item => {
      const index = item.name.indexOf(searchValue);
      const beforeStr = item.name.substr(0, index);
      const afterStr = item.name.substr(index + searchValue.length);
      const tit =
        index > -1 ? (
          <span>
            {beforeStr}
            <span className="site-tree-search-value">{searchValue}</span>
            {afterStr}
          </span>
        ) : (
          <span>{item.name}</span>
        );
        if (item.childList) {
          this.parentKey=item.code;
            return (
                <TreeNode title={tit} key={item.code} dataRef={item}>
                    {this.renderTreeNodes(item.childList)}
                </TreeNode>
            );
        }
        return <TreeNode title={tit} key={item.code} dataRef={item} parentKey={this.parentKey} />;
    })
  }

    onExpand = expandedKeys => {
      this.setState({
          expandedKeys,
          autoExpandParent: false,
      });
    }

    onCheck = (checkedKeys,info=[]) => {
      console.log(info, 'infog')
      const {form } = this.props;
      this.setState({ checkedKeys, checkedInfo: info }, () => {
        form.setFieldsValue({list : checkedKeys})
      });
    }

    onSelect = (selectedKeys, b, c) => {
      console.log(selectedKeys, 'sle=======', b, c)
      this.setState({ selectedKeys });
    }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { modalVisibility, loading, autoExpandParent, checkedKeys, selectedKeys, expandedKeys, treeData } = this.state;
    const {sourceData={} } = this.props;
    let expandKeys = sourceData.categoryVos&&sourceData.categoryVos.reduce((nodes,cur)=>{
      nodes.push(cur.code)
      return nodes;
    },[]);
    
    return (
      <Modal
        title={this.payload ? "编辑副类目" : "新增副类目"}
        visible={modalVisibility}
        confirmLoading={loading}
        onOk={this.handleOk}
        onCancel={this.onCancel}
        width={"600px"}
        className="channel-nav-modal"
      >
        <Form className="login-form">
          <Form.Item label="主类目名称">
            <Input
              className="login-input"
              value={this.props.channelName}
              disabled
            />
          </Form.Item>
          <Form.Item label="副类目名称">
            {getFieldDecorator("name", {
              initialValue: this.payload ? sourceData.navbarName:"",
              rules: [
                { required: true, message: "请输入副类目名称" },
                { validator: (rule,value,callback) => {
                  if(value && (this.getStringLength(value) < 2 || this.getStringLength(value) > 28)){
                    callback("最少2个字，最多14个字");
                    return;
                  }
                  callback();
                }}
              ]
            })(
              <Input
                className="login-input"
                placeholder="副类目名称将在商城展示，请按照规则填写"
                maxLength={24}
              />
            )}
          </Form.Item>
          <Form.Item label="对应类目">
            {/* <span className="catalog-tip">选择对应的后台二级类目后，商城频道导航会直接应用其三级类目</span> */}
            <Search style={{ marginBottom: 8 }} placeholder="搜索类目" onChange={this.onChange} value={this.state.searchValue} />
           {
             this.state.treeData.length > 0 &&  getFieldDecorator("list", {
              initialValue: this.payload ? expandKeys: "",
              rules: [
                { required: true, message: "请输入对应类目" },
              ]
            })(
              <Tree
                checkable
                onExpand={this.onExpand}
                expandedKeys={expandedKeys}
                autoExpandParent={autoExpandParent}
                onCheck={this.onCheck}
                checkedKeys={checkedKeys}
                onSelect={this.onSelect}
                selectedKeys={selectedKeys}
                className="catalog-tree"
              >
              {this.renderTreeNodes(this.state.treeData)}
              </Tree>
            )
           } 
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default Form.create({ name: "NewChannelNav" })(NewChannelNav);
