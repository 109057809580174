import React, { Component } from 'react';
import Table from 'CMPT/common/Table';
import { Breadcrumb } from 'antd';
import SearchForm from './searchForm'
import { API } from '@/http/api';
import http from '@/http';
import './index.less'
const BreadcrumbItem = Breadcrumb.Item;

const mock = {
  nickName: 'saaa',
  phone: '15666666666',
  model: 'aaaaa',
  action: '登陆',
  result: '成功',
  detail: 'sfafawefaeafaefa',
  ip: '10.2.3.222',
  time: '2015-05-02'
}

class OperateLog extends Component{
  constructor(props){
    super(props)
    this.state = {
      data: [],
      total: 16,
      params: {
        pageNo: 1,
        pageSize: 10,
        startDate: '',
        endDate: ''
      }
    }
  }

  componentDidMount(){
    this.getLists()
  }

  //查询列表
  getLists() {
    const { params } = this.state

    const config = {
        api: API.getLogList,
        data: params,
        showLoading: true
    };
    http(config).then(res => {
      if(res.success){
        this.setState((state)=>{
          return {
            data: res.data.datas || [],
            total: res.data.totalRecord,
            params: {...state.params, pageNo: res.data.pageNo}
          }
        })
      }
    })
  }

  //分页
  onChangePage = (pageNo, pageSize, sort) => {
    this.setState((state)=>({
      params: {...state.params, pageNo: pageNo, pageSize: pageSize}
    }),() => {
      this.getLists()
    })
  }

  // 搜索
  onValuesChange = (allValues) => {
    this.setState((state) => {
      return {
        params: {...state.params,...allValues}
      }
    })
  }

  reset = (allValues) => {
    this.setState((state) => {
      return {
        params: {...state.params,...allValues}
      }
    },() => {
      this.getLists()
    })
  }

  render() {
    const { data, total } = this.state
    const { pageNo, pageSize } = this.state.params
    const columns = [
      {
        title: '序号',
        key: "no",//(text,record,index)=>index,
        width: "60px",
        render: (text, record, index) => {
          return (<span>{index + 1}</span>);
        }
      },{
        title: '昵称',
        dataIndex: 'nickName',
      },{
        title: '手机号',
        dataIndex: 'mobile',
      },{
        title: '操作模块',
        dataIndex: 'module',
      },{
        title: '操作动作',
        dataIndex: 'action',
      },{
        title: '操作结果',
        dataIndex: 'result',
      },{
        title: '日志详情',
        dataIndex: 'logInfo',
        width: "300px",
      },{
        title: '操作IP',
        dataIndex: 'ip',
      },{
        title: '操作时间',
        dataIndex: 'createTime',
      }
    ]
    return (
      <div className="operateLog">
        <Breadcrumb>
          <BreadcrumbItem>客户操作日志</BreadcrumbItem>
        </Breadcrumb>
        <div className='searchBox'>
          <SearchForm onValuesChange={this.onValuesChange} reset={this.reset} onSearch={() => {this.getLists()}}/>
        </div>
        <div className='operateLogTable'>
          <Table
            rowKey="sort"
            columns={columns}
            selfProcessData={data}
            total={total}
            change={this.onChangePage}
            pageSize={pageSize}
            current={pageNo}
          />
        </div>
      </div>
    );
  }
}

export default OperateLog