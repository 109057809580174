import React, { Component } from 'react';
import { Modal, Button, Row, Col, message } from 'antd';
import Select from 'CMPT/common/Select';
import { API } from '@/http/api';
import http from '@/http';
import {
    usergetList, customerDispatch, customerDispatchBatch, getDispatchBatchUserList,
    getDispatchUserList
} from './service/customerService';
import {
    intentionDispatch, intentionDispatchBatch, intentionGetDispatchBatchUserList,
    intentionGetDispatchUserList
} from './service/intentionService';
import debounce from 'lodash/debounce';



class AssignFollower extends Component {
    handleOk = () => {
        if (this.props.isCustomer) {
            this.assignCustomer()
        } else {
            this.assignIntention()
        }
    }

    assignCustomer = () => {
        if (!this.props.batch) {
            let ids = [];
            ids.push(this.props.id[0].encode);
            http({
                api: API.customerOperate,
                data: {
                    acountStatus: this.props.status ? 0 : 1,
                    ids:ids
                },
                showLoading: true,
                successTip: '操作成功'
            }).then(result => {
                if (result.success) {
                    this.props.getDetail();
                    this.handleCancel();
                }
            }).catch(error => {
                
            });
        } else {
            http({
                api: API.customerOperate,
                data: {
                    acountStatus: this.props.flag ? 0 : 1,
                    ids:this.props.ids
                },
                showLoading: true,
                successTip: '操作成功'
            }).then(result => {
                if (result.success) {
                    this.props.getCustomerList();
                    this.handleCancel();
                }
            }).catch(error => {
                
            });
        }
    }

    assignIntention = () => {
        let user = this.state.user;
        if (!this.props.batch) {
            let data = {
                "encode": this.props.id[0].encode,
                "userEncode": user.value,
                "userName": user.name
            }
            intentionDispatch(data).then(res => {
                if (res.code == 0) {
                    message.success('分配成功')
                    this.props.getIntentionList(user)
                    this.handleCancel()
                }
            })
        } else {
            console.log('customerDispatchBatch');
            let data = {
                "encodeList": this.props.id&&this.props.id.map(item => item.encode),
                "userEncode": user.value,
                "userName": user.name
            }
            intentionDispatchBatch(data).then(res => {
                if (res.code == 0) {
                    message.success('批量分配成功')
                    this.props.getIntentionList(user)
                    this.handleCancel()
                }
            })
        }
    }

    handleChangeUser = (value) => {
        this.setState({
            user: value
        })
    }

    handleCancel = () => {
        this.props.assignCancel();
    }

    handleChangeStatus = (value) => {
        console.log('handleChangeStatus：'+value);
        let user = this.state.userList.find(item => item.value == value);
        this.setState({
            user: user
        })

    }

    searchUser = (batch,isCustomer) => {
        const value = null;
        if(isCustomer){
            if(batch){
                this.searchCustomerBatchUser(value)
            }else{
                this.searchCustomerUser(value)
            }
        }else{
            if(batch){
                this.searchIntentionBatchUser(value)
            }else{
                this.searchIntentionUser(value)
            }
        }
        
    }

    searchCustomerUser = (value) => {
        getDispatchUserList(value).then(res => {
            this.setState({
                userList: res&&res.map(item => {
                    return { value: item.idCode, name: item.name, account: item.mobile }
                }),
                followUserList: res&&res.map(item => {
                    return { value: item.idCode, name: item.name, account: item.mobile }
                }),
            })
        });
    }

    searchCustomerBatchUser = (value) => {
        getDispatchBatchUserList(value).then(res => {
            this.setState({
                userList: res&&res.map(item => {
                    return { value: item.idCode, name: item.name, account: item.mobile }
                }),
                followUserList: res&&res.map(item => {
                    return { value: item.idCode, name: item.name, account: item.mobile }
                }),
            })
        });        
    }

    searchIntentionUser = (value) => {
        intentionGetDispatchUserList(value).then(res => {
            this.setState({
                userList: res&&res.map(item => {
                    return { value: item.idCode, name: item.name, account: item.mobile }
                }),
                followUserList: res&&res.map(item => {
                    return { value: item.idCode, name: item.name, account: item.mobile }
                }),
            })
        });
    }

    searchIntentionBatchUser = (value) => {
        intentionGetDispatchBatchUserList(value).then(res => {
            this.setState({
                userList: res&&res.map(item => {
                    return { value: item.idCode, name: item.name, account: item.mobile }
                }),
                followUserList: res&&res.map(item => {
                    return { value: item.idCode, name: item.name, account: item.mobile }
                }),
            })
        });
    }




    handleSearch = (value) => {
        let followerList = []
        if(value===''){
            followerList = this.state.followUserList
        }else{
            followerList = this.state.followUserList.filter(i=>i.name.includes(value))
        }
        this.setState({
            userList:followerList
        })
    }

    state = {
        userList: [
        ],
        followUserList:[],
        user: undefined,
        title:'',
        content:'账号'
    }

    componentDidMount() {
        console.log(this.props)

        const batch = this.props.batch;
        const isCustomer = this.props.isCustomer;
        // this.searchUser(batch,isCustomer);
    }
    componentWillReceiveProps(nextProps){
        const batch = nextProps.batch;
        const isCustomer = nextProps.isCustomer;
        // this.searchUser(batch,isCustomer);
    }
    render() {
        console.log(this.props)
        const contentCompanyName = this.props.companyName;
        let commonStatus = '';
        let titleText = '';
        let contentText = '';
        if(this.props.batch){
            commonStatus = this.props.flag==1?'批量停用':'批量启用';
            titleText = '确认'+ commonStatus+'以下公司的OMS系统账号？';
            contentText = this.props.companyNames.join('、');
        }else{
            commonStatus = this.props.status==1?'停用':'启用';
            titleText = '确认'+ commonStatus+'【'+ contentCompanyName +'】的OMS系统账号？';
            contentText = '';
        }

        // const userListHtml = this.state.userList.map((item,index)=>  <Option key={item.idCode} value={item.idCode}><Row>  <Col className="gutter-row" style={{textAlign:"left"}} span={12}>{item.name}</Col><Col className="gutter-row" style={{textAlign:"right"}} span={12}>{item.mobile}</Col></Row></Option>)
        // const userListHtml = this.state.userList.map(item=>  <Option key={item.idCode} value={item.idCode}><div>{item.name}</div></Option>)
        return (
            <Modal
                title={titleText}
                content={contentText}
                visible={this.props.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
            >
                <p>{contentText}</p>
            </Modal>
        );
    }
}

export default AssignFollower;