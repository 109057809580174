import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Button, Input, Modal, Icon, Select, DatePicker, Tooltip } from 'antd';
import moment from 'moment';
import LogisticsTabnums from 'CMPT/common/tabnums/tabnums'
import Table from 'CMPT/common/Table';
import http from '@/http';
import { API } from '@/http/api';
import './SettingAnnounceCount.less'

const BreadcrumbItem = Breadcrumb.Item;
const Search = Input.Search;

export default function SettingAnnounceCount({ match: { params: { type,id } }, history }) {
    // console.log({history})
    // console.log({match})
    const [data, setData] = useState({ datas: [], total: 0, pageNo: 1 });
    const [enums, setEnums] = useState({ status: [], type: '' });

    const [typeValue, setTypeValue] = useState();
    const [stateValue, setStateValue] = useState();
    const [createTimeValue, setCreateTimeValue] = useState();

    useEffect(() => {
        loadTable(type);
    }, [typeValue, stateValue, createTimeValue]);


    // 传参
    let loadData = {
        pageNo:1,
        pageSize: 10,
        encode: id,
        type: type
    };
    function loadTable() {
        http({
            api: API.announceNoticeUserList,
            data: loadData,
            showLoading: true
        }).then(data => {
            setData(data.data || ({ datas: [], total: 0, pageNo: 1 }));
        });
    }

    let columns = [ {
        title: '编号',
        render:(text,record,index)=>`${index+1}`
    },  {
        title: '部门名称',
        dataIndex: 'deptName',
    }, {
        title: '人员名称',
        dataIndex: 'userName',
    }, {
        title: '查看状态',
        dataIndex: 'readFlag',
        render(text) {
            if(text){
                return '已读';
            }else{
                return '未读';
            }
        }
    }];

    let timeBlock = {};
    if (type != 1) {
        timeBlock = {
            title: '查看时间',
            dataIndex: 'createTime',
            render(text) {
                if (text) {
                    return formatTime(text);
                } else {
                    return "-"
                }
            }
        }
        columns.push(timeBlock)
    }
    let tabstatus = type;
    const tabnums = [
        {
            name: '全部人员',
            value: 3,
        },
        {
            name: '未读人员',
            value: 1,
        },
        {
            name: '已读人员',
            value: 2,
        }
    ];

    //tabs选中哪一项
    function getnumindex(val) {
        tabstatus = val;
        history.push({
            pathname: '/app/setting/announce/count/' + val + '/' +id,
        })
        loadData.type = val;
        loadTable();
    }
    // 分页
    function changePager(pageNo, pageSize) {
        loadData.pageNo = pageNo;
        loadData.pageSize = pageSize;
        loadData.type = type;
        loadTable();
    }

    return (
        <div className='setting-announce-manage'>
            <div className='manage-header'>
                <Breadcrumb>
                    <BreadcrumbItem><Link to='/app/setting/announce'>公告列表页</Link></BreadcrumbItem>
                    <BreadcrumbItem>接收情况</BreadcrumbItem>
                </Breadcrumb>
            </div>
            <div className='manage-tab'>
                <LogisticsTabnums
                    nums={tabnums}
                    activeKey={tabstatus || String(tabnums[0].value)}
                    getnumindex={getnumindex.bind(this)}>
                </LogisticsTabnums>
            </div>
            <div className='manage-main'>
                <Table showEmpty selfProcessData={data.datas || []} current={data.pageNo} total={data.totalRecord} columns={columns} change={changePager} rowKey='userName' />
            </div>
        </div>
    );
}

function formatTime(time) {
    if (time) {
        return moment(time).format('YYYY-MM-DD');
    }
    return '';
}
