import { AJAX_TYPE } from "../config";

// Role.Role

const prefix = "enterpriseUpgrade";

export default {
  // 分页
  [`${prefix}List`]: {
    url: "/web/crm/enterprise/upgrade/list",
    type: AJAX_TYPE.POST
  },

  // 企业的审核详情
  [`${prefix}Detail`]: {
    url: "/web/crm/enterprise/upgrade/get/:encode",
    type: AJAX_TYPE.GET
  },

  // 通过 或者  驳回
  [`${prefix}Do`]: {
    url: "/web/crm/enterprise/upgrade/authentication",
    type: AJAX_TYPE.POST
  },
  [`${prefix}Add`]: {
    url: "/web/crm/enterprise/upgrade/add",
    type: AJAX_TYPE.POST
  },
  [`${prefix}Edit`]: {
    url: "/web/crm/enterprise/upgrade/edit",
    type: AJAX_TYPE.POST
  },
  [`${prefix}CategoryQuery`]: {
    url: "/web/mgr/category/queryTop",
    type: AJAX_TYPE.GET
  },
  [`${prefix}CategoryTree`]: {
    url: "/web/crm/item/getCategoryTree",
    type: AJAX_TYPE.GET
  },
  [`${prefix}Judge`]: {
    url: "/web/crm/enterprise/upgrade/judge",
    type: AJAX_TYPE.POST
  }
};
