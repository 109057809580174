import React, { Component } from 'react'
import './ContractDetail.less'
import {
    Breadcrumb, Table,
} from "antd";
import {Link} from "react-router-dom";
import { API } from "@/http/api";
import http from "@/http";

class ContractDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            detail: null,
        }
    }

    componentDidMount() {
        this.getDetail()
    }

    getDetail = () => {
        const code = location.href.split('/')[location.href.split('/').length-1]
        const config = {
            api: API.orderContractDetail,
            data: {
               code
            },
            showLoading: true
        }
        http(config).then(res => {
            if(res.code == 200) {
                const detail = res.data;
                if(detail.remittanceCycle == null){
                    detail.remittanceCycle = "0"
                }
                if(detail.additionalFileList == null || detail.additionalFileList == undefined){
                    detail.additionalFileList = new Array();              
                }
                 this.setState({
                     detail: detail
                     
                 })
            }
        })
    }
    render(){
        const { detail } = this.state
        return(
            <div className='userlist-content'>
                <div className='enterprise-auth-header'>
                    <Breadcrumb className="customer-detail-head-bread">
                        <Breadcrumb.Item>
                            <Link
                                style={{ color: "#333" }}
                                to={{
                                    pathname: "/app/crm/contract",
                                }}
                            >
                                合同备案
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>备案详情</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="userlist-body">
                        <div className='contractDetail'>
                            <h2 className='contractDetail-title'>{detail&&detail.projectName}</h2>
                            <div className='contractDetail-label-wrapper'>
                                <span className='contractDetail-label'>合同备案号</span>
                                <span className='contractDetail-content'>{detail&&detail.orderNo}</span>
                            </div>
                            <div className='contractDetail-label-wrapper'>
                                <span className='contractDetail-label'>采购方</span>
                                <span className='contractDetail-content'>{detail&&detail.subjectName}</span>
                            </div>
                            <div className='contractDetail-label-wrapper'>
                                <span className='contractDetail-label'>供货方</span>
                                <span className='contractDetail-content'>{detail&&detail.supplierName}</span>
                            </div>
                            <div className='contractDetail-label-wrapper'>
                                <span className='contractDetail-label'>供货方所在地</span>
                                <span className='contractDetail-content'>{detail&&detail.supplierPlace}</span>
                            </div>
                            <div className='contractDetail-label-wrapper'>
                                <span className='contractDetail-label'>项目名称</span>
                                <span className='contractDetail-content'>{detail&&detail.projectName}</span>
                            </div>
                            <div className='contractDetail-label-wrapper'>
                                <span className='contractDetail-label'>项目类型</span>
                                <span className='contractDetail-content'>{detail&&detail.projectTypeName}</span>
                            </div>
                            <div className='contractDetail-label-wrapper'>
                                <span className='contractDetail-label'>送货地点</span>
                                <span className='contractDetail-content'>{detail&&detail.projectPlace}</span>
                            </div>
                            <div className='contractDetail-label-wrapper'>
                                <span className='contractDetail-label'>合同金额</span>
                                <span className='contractDetail-content'>{detail&&detail.contractPrice}</span>
                            </div>
                            <div className='contractDetail-label-wrapper'>
                                <span className='contractDetail-label'>回款周期</span>
                                <span className='contractDetail-content'>{detail&&detail.remittanceCycle}&nbsp;&nbsp;天</span>
                            </div>
                            <div className='contractDetail-label-wrapper'>
                                <span className='contractDetail-label'>备注</span>
                                <span className='contractDetail-content'>{detail&&detail.remarks}</span>
                            </div>
                        </div>
                        <div>
                            <h2 className='contractDetail-title'>合同文件</h2>
                            <div>
                                {
                                    detail&&detail.contractFileList.map((item, index) => {
                                        return(
                                            <a href={item.url} key={index} className='contract-link'>{item.name}</a>
                                        )
                                    })
                                }
                            </div>
                            <h2 className='contractDetail-title'>附件协议</h2>
                            <div>
                                {
                                    detail&&detail.additionalFileList.map((item, index) => {
                                        return(
                                            <a href={item.url} key={index} className='contract-link'>{item.name}</a>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        )
    }
}

export default ContractDetail
