import {
    AJAX_TYPE
} from '../config';

// 区域

const prefix = 'ruleCategory';

export default {
    // 规则类目
    [`${prefix}GetList`]: {
        url: '/web/crm/ruleCategory/queryList',
        type: AJAX_TYPE.GET
    },
    // 新增一级
    [`${prefix}Add`]: {
        url: '/web/crm/ruleCategory/add',
        type: AJAX_TYPE.POST
    },
    // 目录详情
    [`${prefix}GetInfo`]: {
        url: '/web/crm/ruleCategory/get',
        type: AJAX_TYPE.GET
    },
    // 目录修改
    [`${prefix}Update`]: {
        url: '/web/crm/ruleCategory/update',
        type: AJAX_TYPE.POST
    },
    // 目录删除
    [`${prefix}Delete`]: {
        url: '/web/crm/ruleCategory/delete',
        type: AJAX_TYPE.POST
    },
    // 目录排序
    [`${prefix}Sort`]: {
        url: '/web/crm/ruleCategory/sort',
        type: AJAX_TYPE.POST
    },
    // 规则-列表
    [`${prefix}RuleList`]: {
        url: '/web/crm/rule/queryList',
        type: AJAX_TYPE.GET
    },
    // 规则-排序
    [`${prefix}RuleSort`]: {
        url: '/web/crm/rule/sort',
        type: AJAX_TYPE.POST
    },
    // 规则-新增
    [`${prefix}RuleAdd`]: {
        url: '/web/crm/rule/add',
        type: AJAX_TYPE.POST
    },
    // 规则-编辑
    [`${prefix}RuleEdit`]: {
        url: '/web/crm/rule/update',
        type: AJAX_TYPE.POST
    },
    // 规则-详情
    [`${prefix}RuleDetail`]: {
        url: '/web/crm/rule/get',
        type: AJAX_TYPE.GET
    },
    // 规则-删除
    [`${prefix}RuleDelete`]: {
        url: '/web/crm/rule/delete',
        type: AJAX_TYPE.POST
    },
    // 规则-发布
    [`${prefix}RulePublish`]: {
        url: '/web/crm/rule/publish',
        type: AJAX_TYPE.POST
    },
    // 规则-发布
    [`${prefix}RuleOffShelves`]: {
        url: '/web/crm/rule/offShelves',
        type: AJAX_TYPE.POST
    },
    
    
    
    
    
}