import React, { Component, Fragment } from 'react';
import { API } from '@/http/api';
import http from '@/http';
import { Modal, message, Button } from 'antd';
import ImgPreview from 'CMPT/common/ImgPreview';
import OrderConfirmContent from './OrderConfirmContent';
const { confirm } = Modal;

export default class OrderCredentialsCheck extends Component{
    state = {
        imgList: [],
        showImgPreview: false,
        reason: ''
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props && nextProps.show) {
            const params = {
                api: API.orderCertificationList,
                showLoading: true,
                path: {
                    orderCode: nextProps.orderCode
                }
            }
            http(params).then(res => {
                console.log('credentials', res)
                if (res.success) {
                    this.setState({
                        imgList: res.data && res.data.map(per => per.url) || []
                    })
                }
            })
        }
    }
    setImg = (index) => {
        this.setState({
            currentIndex: index,
            showImgPreview: true
        })
    }
    closeImgPreview = () => {
        this.setState({
            showImgPreview: false
        })
    }
    pass = () => {
        this.onAudit(2)
    }
    prevent = () => {
        confirm({
            content: <OrderConfirmContent label="请输入审核不通过原因" placeholder="审核不通过原因（必填）" onChange={this.setReason} length={100} />,
            className: 'order-modal-confirm',
            okText: '确定',
            onOk: () => {
                if (!this.state.reason) {
                    message.warning('请输入审核不通过原因')
                    return Promise.reject()
                }
                this.onAudit(3)
            },
            onCancel: () => {
                console.log('Cancel');
            },
        });
    }
    onAudit = (result) => {
        const params = {
            api: API.orderCertificationAudit,
            showLoading: true,
            data: {
                orderMainCode: this.props.orderCode,
                auditRemark: this.state.reason,
                auditResult: result
            }
        }
        http(params).then(res => {
            if (res.success) {
                message.success('审核成功')
                if (result === 3) {
                    this.setState({
                        reason: ''
                    })
                }
                this.props.onAudit()
            }
        })
    }
    setReason = (e) => {
        this.setState({
            reason: e.target.value
        })
    }
    render() {
        const { showImgPreview, currentIndex, imgList } = this.state
        const show = this.props.show
        return (
            <Fragment>
                <Modal
                    visible={show}
                    title="付款凭证审核"
                    wrapClassName="order-credentials-check-modal"
                    onCancel={this.props.onCancel.bind(this, false)}
                    footer={null}
                >
                    <div className="credentials-body">
                        <p>请审核买家上传的付款凭证</p>
                        {this.state.imgList.map((per, index) => {
                            return <img src={per} key={index} onClick={this.setImg.bind(this, index)} />
                        })}
                        <div className="modal-btns">
                            <Button type="primary" onClick={this.pass}>审核通过</Button>
                            <Button type="danger" onClick={this.prevent}>审核不通过</Button>
                        </div>
                    </div>
                </Modal>
                <ImgPreview show={showImgPreview} current={currentIndex} imgList={imgList} onClose={this.closeImgPreview}/>
            </Fragment>
        );
    }
}
