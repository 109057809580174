import React, { Component } from 'react';
import { Breadcrumb, Modal, Form, Radio,Button, message } from 'antd';
import Table from 'CMPT/common/Table';
import http from '@/http';
import { API } from '@/http/api';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Pagination from "CMPT/common/Pagination";

import './Journalism.less';

const BreadcrumbItem = Breadcrumb.Item;

class Journalism extends Component {
    state = {
        total:0,
        columns: [
            {
                title: '新闻类型',
                dataIndex: 'afficheTypeName',
                key: 'afficheTypeName',
            },{
                title: '新闻名称',
                dataIndex: 'title',
                key: 'title',
            }, 
			{
                title: '操作人',
                dataIndex: 'editUserName',
                key: 'editUserName',
            },
			{
                title: '操作时间',
                dataIndex: 'editDate',
                key: 'editDate',
                render: (text, record) => {
                    return (<span>{moment(text).format('YYYY-MM-DD')}</span>);
                }
            }, 
			{
                title: '操作',
                key: 'operate',
                render: (text, record, index) => {
                    return (<span className="table-operate-btn">
                        <Link to={'/app/crm/mallsetting/platformAnnouncement/addOreditJournalism?type=edit&id=' + record.id} className="operate">编辑</Link>
                        <span className='operate' style={{marginLeft: '6px',color:'red'}}  onClick={() => this.deleAnnouncement(record.id)} >删除</span>
                    </span>)
                }
            }
        ],
        data: [],
        total:0,
        params: {
            pageSize: 10,
            pageNo: 1,
        },
        showDelect:false,
        id:undefined,
    }
    //初始化
    componentDidMount() {
        this.getList();
    }

    paginationChange = (pageNo, pageSize) => {
        let params = this.state.params;
        params.pageNo = pageNo;
        params.pageSize = pageSize;
        this.setState({params}, this.getList)
    }

    deleAnnouncement = (id) => {
        this.setState({ showDelect: true, id:id})
    }
    onOkModal = ()=>{
        const config = {
            api: API.AnnouncementRemove,
            path: {id:this.state.id,},
        }
        http(config).then(res => {
            if (res.code == 0) {
                message.success('删除新闻成功！');
                this.setState({ showDelect: false })
                this.getList();
            }else{
                // message.error(res.msg);
                this.setState({ showDelect: false })
                this.getList();
            }
        })
    }

    // 获取列表
    getList = () => {
        const params = this.state.params
        const config = {
            api: API.AnnouncementQueryPage,
            data: {
                pageNo: params.pageNo,
                pageSize: params.pageSize,
                type:1,
            },
            showLoading: true
        }

        http(config).then(res => {
            console.log(res);
            if (res.code == 0) {
                this.setState({
                    data: [...res.data.datas],
                    total: res.data.totalPages
                })
            }else{
                // message.error(res.msg);
            }
        })
    } 

    render() {
        return (
            <div className="channel-nav-con">
                <div className='channel-nav-header'>
					{
						<Button className="account_manage_create" type="primary">
                            <Link to={"/app/crm/mallsetting/platformAnnouncement/addOreditJournalism"+"?type=add"}>新增新闻</Link>
						</Button>
					}
                </div>
                <div className="channel-nav-list">
                    <Table 
                        rowKey="typeValue" 
                        columns={this.state.columns} 
                        selfProcessData={this.state.data}
                        total={this.state.total} 
                    />
                    <Pagination change={this.paginationChange.bind(this)} total={this.state.total} current={this.state.params.pageNo} />
                </div>
                <Modal
                    visible={this.state.showDelect}
                    closable={false}
                    maskClosable={false}
                    width={600}
                    title="删除新闻"
                    onCancel={() => {
                        this.setState({ showDelect: false })
                    }}
                    onOk={this.onOkModal}
                >
                    <p>确定删除该新闻吗？</p>
                </Modal>
            </div>


        );
    }
}
export default Journalism;
