import React, { Component } from "react";
import { Breadcrumb, Button, Input, InputNumber, Modal } from 'antd';
import Select from '@/components/common/Select'
import Table from 'CMPT/common/Table';
import moment from 'moment'
import { API } from '@/http/api';
import http from '@/http';
import './Video.less'
const BreadcrumbItem = Breadcrumb.Item;
const { Search } = Input;
class Video extends Component {
    state = {
        encode: '',
        visible: false,
        catalogCode: undefined,
        videoTitle: undefined,
        catalogList: [],
        selfProcessData: null,
        tableConfig: {
            rowKey: 'id',
            columns: [
                {
                    title: '标题',
                    dataIndex: 'videoTitle',
                    render: (text, record) => {
                        return record.topFlag === 1 ? <span><span className='stick'>顶</span><span>{text}</span></span> : <span>{text}</span>
                    },
                    width: '12.7%'
                },
                {
                    title: '目录',
                    dataIndex: 'catalogName',
                    width: '10%',
                    render: status =>
                        <span>
                            {status}
                        </span>
                },
                {
                    title: '创建时间',
                    dataIndex: 'createTime',
                    width: '15%',
                    render: projectVo => {
                        return <span>
                            {moment(projectVo).format('YYYY-MM-DD HH:mm')}
                        </span>
                    }
                },

                {
                    title: '最后更新时间',
                    dataIndex: 'modifiedTime',
                    width: '15%',
                    render: (text, record) =>
                        <span>
                            {moment(record.modifiedTime||record.createTime).format('YYYY-MM-DD HH:mm')}
                        </span>
                },
                {
                    title: '查看量',
                    dataIndex: 'seeCount',
                    width: '5%',
                    align:'right',
                    render: status =>
                        <span>
                            {status||0}
                        </span>
                },
                {
                    title: '排序',
                    dataIndex: 'sort',
                    width: '5%',
                    render: (sort, record) =>
                        <span>
                            {
                                record.sortShow ? <InputNumber value={record.sort} autoFocus size='small' maxLength={3} onChange={(e) => { this.onchangInput(e, record.sortIndex) }} onBlur={() => this.sortBlur(record)} /> : <span>{record.sort}</span>
                            }
                        </span>
                },
                {
                    title: '操作',
                    render: (record, index) => <div>
                        <span className="text-color-highlight" style={{marginRight:'16px'}} onClick={() => { this.edit(record) }}>编辑</span>
                        <span className="text-color-highlight" style={{marginRight:'16px'}} onClick={() => { this.sort(record) }}>排序</span>
                        <span className="text-color-highlight" style={{marginRight:'16px'}} onClick={() => { this.videoDel(record) }}>删除</span>
                    </div>
                }
            ],
            current: 1,
            total: 10,
            pageSize: 10,

        }
    }

    edit(record){
        this.props.history.push('/app/crm/helphandle/video/editor/'+record.id)
    }
    videoDel = (record) => {
        this.setState({
            visible: true,
            encode: record.id
        })
    }
    deleteRole = () => {
        const config = {
            api: API.helpVideoDel,
            showLoading: true,
            successTip: '删除成功！',
            data: {
                encode: this.state.encode
            }
        }
        http(config).then(res => {
            if (res.success) {
                this.handleCancel();
                this.getData();
            }
        })
    }
    handleCancel = () => {
        this.setState({
            visible: false
        })
    }



    sortNewData(data) {
        return data.map((item, index) => {
            item.sortIndex = index;
            item.sortShow = false;
            return item
        })
    }

    getData = () => {
        const config = {
            api: API.helpVideoList,
            showLoading: true,
            data: {
                pageSize: this.state.tableConfig.pageSize,
                pageNo: this.state.tableConfig.current,
                catalogCode: this.state.catalogCode,
                videoTitle: this.state.videoTitle,
            }
        }
        http(config).then(res => {
            if (res.success) {
                let { tableConfig } = this.state
                tableConfig.total = res.data.totalRecord
                this.setState({
                    selfProcessData: this.sortNewData(res.data.datas),
                    tableConfig: tableConfig
                }, () => { console.log(this.state.tableConfig) })


            }
        })
    }
    getCatalogList() {
        const config = {
            api: API.helpCatalogList,
            data: {}
        }
        http(config).then(res => {
            if (res.success) {
                let data = res.data.map((item) => {
                    item.name = item.catalogName
                    item.value = item.id
                    return item
                })
                this.setState({
                    catalogList: data
                })
            }
        })
    }


    componentDidMount() {
        this.getData();
        this.getCatalogList();
    }

    sort = (record) => {
        this.isSortShow(record)
    }
    isSortShow(record) {
        const { selfProcessData } = this.state
        selfProcessData[record.sortIndex].sortShow = !selfProcessData[record.sortIndex].sortShow
        this.setState({
            selfProcessData
        })
    }

    sortBlur(record) {
        this.isSortShow(record)
        console.log(record)
        const config = {
            api: API.helpVideoUpdateVideo,
            data: {
                sort: record.sort,
                encode: record.id,
            }
        }
        http(config).then(res => {
            if (res.success) {
                this.getData()
            }
        })
        // 掉排序的接口
    }
    onchangInput = (e, index) => {
        const { selfProcessData } = this.state
        selfProcessData[index].sort = e
        this.setState({
            selfProcessData
        })
    }





    onChanges = (page, pageSize, sort) => {
        let { tableConfig } = this.state
        tableConfig.current = page
        tableConfig.pageSize = pageSize
        this.setState({
            tableConfig
        }, () => {
            this.getData();
        })
    }



    setKeywords = (e) => {
        this.setState({
            videoTitle: e.target.value
        }, () => {
            this.getData()
        })
    }
    changeKeywords=() =>{
        this.getData()
    }
    handleChangeStatus = (e) => {
        this.setState({
            catalogCode: e
        }, () => {
            this.getData()
        })
    }
    reset = () => {
        this.setState({
            catalogCode: undefined,
            videoTitle: undefined,
        }, () => {
            this.getData()
        })
    }
    render() {
        return (
            <div className="contract-list-out">
                <Modal
                    title="确定是否删除？"
                    visible={this.state.visible}
                    okText='删除'
                    maskClosable={false}
                    width={400}
                    onOk={this.deleteRole}
                    onCancel={this.handleCancel}
                    className="assign-follow-modal-del"
                >
                </Modal>

                <div className="contract-header">
                    <Breadcrumb>
                        <BreadcrumbItem>帮助中心</BreadcrumbItem>
                        <BreadcrumbItem>视频教程</BreadcrumbItem>
                    </Breadcrumb>
                    {/* <AuthContainer authCodes={AUTH_KEYS.OPERATE_CONTRACT_ADD}> */}
                    <Button type="primary" onClick={() => { this.props.history.push('/app/crm/helphandle/video/add') }}>创建</Button>
                    {/* </AuthContainer> */}

                </div>
                <div className="contract-content">
                    <div className='intention-body'>
                        <div className="intention-form" layout="inline" style={{ marginBottom: '30px' }}>
                            <div>
                                <Search
                                    placeholder="搜索标题名称"
                                    value={this.state.videoTitle}
                                    onChange={this.setKeywords}
                                    onSearch={this.changeKeywords}
                                    style={{ width: 340 }} />
                            </div>
                            <div>
                                {/* <Select  style={{width: '104px'}} data={this.props.statusConfigArray} notFoundContent={this.props.fetching ? <div style={{textAlign:'center',height:'100px',lineHeight:'100px'}}>加载中...</div>:<div className='ant-empty ant-empty-normal ant-empty-small'><div class="ant-empty-image"></div><p class="ant-empty-description">暂无数据</p></div>}  value={status} placeholder="状态" selectMethod={this.handleChangeStatus} />    */}

                                <Select style={{ width: '204px' }} placeholder='全部目录' value={this.state.catalogCode} data={this.state.catalogList} selectMethod={this.handleChangeStatus} />

                            </div>
                            <div>
                                <span style={{ cursor: 'pointer', fontSize: '12px', textDecoration: 'underline', textUnderlinePosition: 'under', border: 'none', color: '#333', lineHeight: '32px' }} type="link" onClick={this.reset}>重置</span>
                            </div>

                        </div>
                        <div className="intention-table-contents">
                            {
                                this.state.selfProcessData ? <Table rowKey="id" change={this.onChanges} {...this.state.tableConfig} selfProcessData={this.state.selfProcessData} /> : null
                            }

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default Video;