import React, { Component } from "react";
import { Checkbox } from 'antd';
import ComposeAuthModel from "./ComposeAuthModel.js";
import { ChildCheckType, AuthType } from "./AuthModel.js";
import '../AuthSetting.less';
export default class MenuAuthModel extends ComposeAuthModel {
  render() {
    return <div key={'menu_' + this.id} className="auth_line_box">
      <div className="auth_line">
        <Checkbox key={'chb_' + this.id} className={this.childCheckType == ChildCheckType.BOTH ? 'ant-checkbox-indeterminate' : ''} checked={this.isChecked} onChange={this.onCheckChange}>{this.name}</Checkbox>
      </div>
      {this.createChildrenUI()}
    </div>;
  }


  isHasError() {
    //自身选中，有至少一个子权限，但却没有一个子权限 被选中，报错
    if (this.isChecked && this.children && this.children.length > 0) {
      if (this.children[0].type != AuthType.SUB_MENU) {
        // 目前只考虑 二级菜单是否有选中。  按钮级权限一个 不选 都是可以的
        return undefined;
      }

      let hasTrue = false;// 是否至少有一个为true;
      for (var i = 0; i < this.children.length; i++) {
        let item = this.children[i];
        if (item && item.isChecked) {
          hasTrue = true;
          break;
        }
      }
      if (!hasTrue) {
        return "请给" + this.name + "至少选择一个子权限";
      }
    }

    return super.isHasError();
  }

  dispatchCheckUp() {
    // 顶级菜单就不用 往上传递了
  }

  onCheckPassUp(child) {
    if (child.type == AuthType.OPERATION) {
      if (child.isChecked) {
        //有一个就会被选中
        // 一级菜单 不需要再往上传递
        if (!this.isChecked) {
          this.setChecked(true);
        }
        return;
      }
    } else if (child.type == AuthType.SUB_MENU) {
      if (child.isChecked) {
        //有一个就会被选中
        // 一级菜单 不需要再往上传递
        if (!this.isChecked) {
          this.setChecked(true);
        }
        return;
      } else {
        // 这里 child当前没有被选中。就需要查看下 其他兄弟child的选中状态
        let hasTrue = false;// 是否至少有一个为true;
        for (var i = 0; i < this.children.length; i++) {
          if (this.children[i] && this.children[i].isChecked) {
            hasTrue = true;
            break;
          }
        }

        // 改变状态
        this.setChecked(hasTrue ? true : false);
      }
    }
  }




  createChildrenUI() {
    let result = this.createChildrenUiInner();
    if (result) {
      let firstChild = this.children[0];
      if (!firstChild) {
        // 基本不会发生
        return '';
      } else if (firstChild.type == AuthType.OPERATION) {
        return <div className="level_5_box"  >{result}</div>;
      } else if (firstChild.type == AuthType.SUB_MENU) {
        return <div  >{result}</div>;
      }
    } else {
      return '';
    }
  }

}


