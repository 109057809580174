import area from "./apilist/area";
import bill from "./apilist/bill";
import billpay from "./apilist/billpay";
import billrefund from "./apilist/billrefund";
import common from "./apilist/common";
import contract from "./apilist/contract";
import customer from "./apilist/customer";
import decision from "./apilist/decision";
import dev from "./apilist/dev";
import floor from "./apilist/floor";
import flowTemplate from "./apilist/flowTemplate";
import intention from "./apilist/intention";
import job from "./apilist/job";
import maintain from "./apilist/maintain";
import ordercomplain from "./apilist/ordercomplain";
import project from "./apilist/project";
import statementcontract from "./apilist/statementcontract";
import statementproject from "./apilist/statementproject";
import statementzone from "./apilist/statementzone";
import sysconfig from "./apilist/sysconfig";
import template from "./apilist/template";
import companyinitCompany from "./apilist/companyinitCompany";
import department from "./apilist/department";
import functionAuth from "./apilist/function";
import message from "./apilist/message";
import myWork from "./apilist/myWork";
import projectstats from "./apilist/projectstats";
import role from "./apilist/role";
import supplier from "./apilist/supplier";
import task from "./apilist/task";
import user from "./apilist/user";
import verify from "./apilist/verify";
import zone from "./apilist/zone";
import zonedecoration from "./apilist/zonedecoration";
import draft from "./apilist/draft";
import upload from "./apilist/upload";
import reportform from "./apilist/reportform";
import announce from "./apilist/announce";
import announcement from "./apilist/announcement";

import help from "./apilist/help";
import newHelp from "./apilist/newHelp";

import enterpriseAuth from "./apilist/enterprise_auth";
import enterpriseUpgrade from "./apilist/enterprise_upgrade";
import enterprise from "./apilist/enterprise";

import promotion from "./apilist/promotion";
import order from "./apilist/order";
import item from "./apilist/item";
import crmCustomer from "./apilist/crmcustomer";
import operateLog from "./apilist/operateLog";

import userUtils from "@/utils/user";
import account from "./apilist/account";
import sign from "./apilist/sign";
import express from "./apilist/express";

import yqy from "./apilist/yqy";
import banner from "./apilist/banner";
import ruleCategory from "./apilist/ruleCategory";
import auth from "./apilist/auth";

import category from "./apilist/category";
import channelNav from "./apilist/channelNav";
import tianyancha from "./apilist/tianyancha";
import userManager from "./apilist/userManager";
import label from "./apilist/label";
import sourcing from "./apilist/sourcing";

import testAccount from "./apilist/testAccount";
import vehicleAndEquipment from "./apilist/vehicleAndEquipment";

import menuNumber from "./apilist/menuNumber";

import equipment from "./apilist/equipment";

import InviteTenders from "./apilist/InviteTenders";
import topBrand from "./apilist/top_brand";
import topGoods from "./apilist/top_goods";
import top_special_goods from "./apilist/top_special_goods";
import consult from "./apilist/consult";

import service_info from "./apilist/service_info";

import invoice from "./apilist/invoice";
import departmentManagement from "./apilist/departmentManagement";
import valueAddService from "./apilist/valueAddService";



const mgrReg = "/web/mgr";
const mallReg = "/web/mall";
const crmReg = "/web/crm";
const baseMallURL =
	process.env.NODE_ENV == "development" ? "/api/supply" : userUtils.mallUrl;
const baseCrmURL =
	process.env.NODE_ENV == "development" ? "" : userUtils.crmUrl;

const config = {
	...newHelp,
	...help,
	...area,
	...bill,
	...billpay,
	...billrefund,
	...common,
	...contract,
	...customer,
	...decision,
	...dev,
	...floor,
	...flowTemplate,
	...intention,
	...job,
	...maintain,
	...ordercomplain,
	...project,
	...statementcontract,
	...statementproject,
	...statementzone,
	...sysconfig,
	...template,
	...companyinitCompany,
	...department,
	...functionAuth,
	...message,
	...myWork,
	...projectstats,
	...role,
	...supplier,
	...task,
	...user,
	...verify,
	...zone,
	...zonedecoration,
	...draft,
	...upload,
	...reportform,
	...announce,
	...announcement,
	...enterpriseAuth,
	...enterpriseUpgrade,
	...promotion,
	...order,
	...item,
	...crmCustomer,
	...operateLog,
	...account,
	...sign,
	...express,
	...yqy,
	...banner,
	...ruleCategory,
	...auth,
	...category,
	...channelNav,
	...tianyancha,
	...userManager,
	...label,
	...testAccount,
	...sourcing,
	...enterprise,
	...menuNumber,
	...equipment,
	...vehicleAndEquipment,
	...InviteTenders,
	...topBrand,
	...topGoods,
	...top_special_goods,
	...consult,
	...service_info,
	...invoice,
	...departmentManagement,
	...valueAddService,
};
Object.keys(config).forEach(key => {
	if (
		config[key].url.indexOf(mgrReg) !== -1 ||
		config[key].url.indexOf(mallReg) !== -1
	) {
		// 走mall
		if (!config[key].url.startsWith("http")) {
			config[key].url = baseMallURL + config[key].url;
		}
	}
});

export const API = Object.freeze(config);
