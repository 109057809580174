import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Button, Icon, Input, Row, Col ,DatePicker,Checkbox,Modal,Tooltip,message  } from 'antd';
import debounce from 'lodash/debounce';
import Select from 'CMPT/common/Select';
import Table from 'CMPT/common/Table';
import DeleteSupplier from './DeleteSupplier';
import http from '@/http';
import { API } from '@/http/api';
import AuthContainer from 'CMPT/common/auth/AuthContainer';
import { AUTH_KEYS } from '@/utils/auth';
import userUtil from '@/utils/user';
import './SupplierLists.less';
const BreadcrumbItem = Breadcrumb.Item;
const Search = Input.Search;
const { RangePicker } = DatePicker; 

class SupplierLists extends Component {
    state = {
        fetching:true,
        showDelete: false, //删除
        total:0,
        startDateMoment: undefined,
        endDateMoment: undefined,
        checked: false,
        showAudit: false,
        users: [],//对接人枚举
        auditStatusLists: [],//审批状态枚举
        selectParams: {
            name: undefined,//供应商名称
            userCode: undefined, //对接人
            auditStatus: undefined, //审核状态
            startDate: undefined, //开始时间
            endDate: undefined, //结束时间
            type: undefined, //2仅显示待我审批
            pageNo: 1,// 当前页数
            pageSize: 10,// 每页条数
        },
        columns: [
            {
                title: '供应商名称',
                dataIndex: 'name',
                render:(text, record) => { //待提交状态的，打开编辑页
                    let value;
                    if (text.length > 20) {
                        value =  <Tooltip placement="top" title={text}>{text.slice(0, 20)}...</Tooltip>                     
                    } else {
                        value = text
                    }
                    if(record.auditStatus == 10) {
                        // <Link to={ `/app/operate/serviceCenter/supplier/edit/${record.key}?auditStatus=${record.auditStatus}`} className="operate">{value}</Link>
                        return (<span className="operate" style={{cursor: 'pointer'}} onClick={this.goEdit.bind(this, record.key, record.auditStatus)}>{value}</span>);
                    } else {
                        return (<Link to={`/app/operate/serviceCenter/supplier/detail/${record.key}?fromPage=0`} className="operate">{value}</Link>);
                    }
                }
            }, {
                title: '商品信息',
                dataIndex: 'goodsInfo',
                render: (text, record) => {
                    let value;
                    if (text && text.length > 20) {
                        value =  <Tooltip placement="top" title={text}>{text.slice(0, 20)}...</Tooltip>                     
                    } else {
                        value = text
                    }
                    return (<span>{value?value:'-'}</span>)
                }
            }, {
                title: '联系人信息',
                key: 'contactInfo',
                render(text, record){
                    return (
                        <div>
                        <div> {record.contactName}</div>
                        <div> {record.contactPhone}</div>
                      </div>
                    )           
                  }
            }, {
                title: '审批状态',
                dataIndex: 'auditStatus',
                render(text, record, index){
                    let color = ''
                    let status = ''
                    switch(text) {  //{0->-;10->待提交;20->审批中;30->审批驳回;40->审批通过}
                        case 0:
                            return <div>-</div>
                        case 10:
                            color = '#FBBC06 '
                            status = '待提交'
                            break
                        case 20:
                            color = '#FBBC06'
                            status = '审批中'
                            break
                        case 30:
                            color = '#E94437'
                            status = '审批驳回'
                            break
                        default:
                            color = '#E94437'
                            status = '审批通过'
                    }
                    return <div><span style={{color: color, marginRight: '6px'}}>●</span>{status}</div>
                }
            }, {
                title: '创建时间',
                dataIndex: 'createTimeStr',
            }, {
                title: '对接人',
                dataIndex: 'userName',
                render(text, record){
                    return (
                        <div>
                        <Tooltip placement="top" title={record.dockingUserName}>{record.dockingRealName}</Tooltip>
                      </div>
                    )           
                  }
            }, {
                title: '评分',
                dataIndex: 'score',
                sorter: true,
                render: (text, record, index) => {
                    return (<span>{text?text:'-'}</span>)
                }
            }, {
                title: '操作',
                key: 'operate',
                render: (text, record, index) => {
                    if(record.auditFlag == 1) {
                        return (<span className="table-operate-btn"><Link to={`/app/operate/serviceCenter/supplier/detail/${record.key}?fromPage=1`} className="operate">审批</Link></span>)
                    } else {
                        if(record.auditStatus == 0|| record.auditStatus == 10 || record.auditStatus == 40) {  //10,30编辑页显示提交审批,0,40编辑页不可以提交审批
                            return (
                                <AuthContainer authCodes={AUTH_KEYS.OPERATE_SERVICE_SUPPLIER_SUPPLIEREDIT}>
                                     <div>
                                        <span className="table-operate-btn"><Link to={ `/app/operate/serviceCenter/supplier/edit/${record.key}?auditStatus=${record.auditStatus}`} className="operate">编辑</Link></span>
                                        <span className="table-operate-btn" onClick={this.deleteSupplier.bind(this,record.key,record.name)}>删除</span>
                                    </div>
                                </AuthContainer>                     
                            )
                        } else if(record.auditStatus == 30) {
                            return (
                                <AuthContainer authCodes={AUTH_KEYS.OPERATE_SERVICE_SUPPLIER_SUPPLIEREDIT}>
                                     <div>
                                        <span className="table-operate-btn" style={{display: this.state.auditConfig ? '' : 'none'}}><Link to={ `/app/operate/serviceCenter/supplier/edit/${record.key}?auditStatus=${record.auditStatus}`} className="operate">编辑</Link></span>
                                        <span className="table-operate-btn" onClick={this.deleteSupplier.bind(this,record.key,record.name)}>删除</span>
                                    </div>
                                </AuthContainer>                       
                            )
                        } else {
                            return '-'
                        }
                    } 
                } 
            }
        ],
        data: []
    }
    //初始化
    componentWillMount() {
        const config = {
            api: API.supplierInit,
            showLoading: false
        };
        http(config).then(res => {        
                const data = res.data;
                if(!data.hasConfig) this.state.columns.splice(2,1);        
                this.setState({
                    fetching:false,
                    users: [{ value: '', name: '全部' }].concat( data.users&& data.users.map(item => { return { value: item.encode, name: item.name } }) ) ,
                    auditStatusLists: [{ value: '', name: '全部' }].concat( data.auditStatus&& data.auditStatus.map(item => { return { value: item.key, name: item.value } }) ),
                    columns: this.state.columns,
                    auditConfig: data.auditConfig,  //当前是否开启审批
                    hasConfig: data.hasConfig //是否配置过审批
                },() => {
                    this.getLists()
               })               
        })
        this.showAuditTask()
    }
    //判断是否有审批任务
    showAuditTask() {
        const config = {
            api: API.supplierQueryList,
            data: {type: 2},
            showLoading: false
        };
        http(config).then(res => {          
                if(res.data.datas.length != 0) {
                    this.setState({
                        showAudit: true
                    })
                }                 
        })
    }
    //模糊搜索对接人
    onSearchUser = (val) => {          
            const config = {
                api: API.supplierUserList,
                data: {name: val},
                showLoading: false
            };  
            http(config).then(res => {
                    this.setState({
                    users: val && res.data ? 
                            res.data&& res.data.map(item => { return { value: item.encode, name: item.name }})
                            :
                            [{ value: '', name: '全部' }].concat( res.data&& res.data.map(item => { return { value: item.encode, name: item.name } }))
                    })
            })       
    }
    //根据供应商名称搜索
    onSearchName = (val) => {
        this.getListFun({type: 'name', data: val})
    }
    setName = (e) => {
        let selectParams = this.state.selectParams;
        selectParams.name = e.target.value;
        this.setState({
            selectParams
        })
    }
    //选择对接人
    chooseUser = (data) => {
        if(data === '') {
            data = undefined
        }
        this.getListFun({type: 'userCode', data})
    }
    //审核状态选择
    chooseAuditStatus = (data) => {
        if(data === '') {
            data = undefined
        }
        this.getListFun({type:'auditStatus', data})
    }
   //创建时间
    handleDate = (value, date) => {
        let selectParams = this.state.selectParams;
        selectParams.pageNo = 1;
        selectParams.startDate = date[0];
        selectParams.endDate = date[1];
        this.setState({
            selectParams,
            startDateMoment: value[0],
            endDateMoment: value[1]
        },() => {
            this.getLists();
        })
      //  console.log(value[1].format('YYYY-MM-DD'))
    }
    //选择仅显示待我审批
    chooseMyAudit = (e) => {
        let selectParams = this.state.selectParams;
        selectParams.pageNo = 1;
        if(e.target.checked) {
            selectParams.type = 2;
        } else {
            selectParams.type = '';
        }
        this.setState({
            selectParams,
            checked: e.target.checked
        },() => {
            this.getLists();
        })
    }
    //查询列表
    getLists() {
        this.setState( {showDelete: false})
        const config = {
            api: API.supplierQueryList,
            data: this.state.selectParams,
            showLoading: true
        };
        http(config).then(res => {       
                const data = res.data;
                //表格数据
                const list = data.datas && data.datas.map(item => {
                    return {
                        key: item.encode,
                        name: item.name,
                        contactName: item.contactName,
                        contactPhone: item.contactPhone,
                        createTimeStr: item.createTimeStr,
                        dockingRealName: item.dockingRealName || '-',
                        dockingUserName: item.dockingUserName || '-',
                        auditStatus: item.auditStatus,
                        auditFlag:item.auditFlag,
                        goodsInfo: item.goodsInfo,
                        score: item.score                   
                    }
                })
                this.setState({
                    data: list || [],
                    total: data.total
                })                  
        })
    }
    //删除供应商
    deleteSupplier = (id, name) => {
        this.setState({
            showDelete:true,
            supplierId: id,
            name
        }) 
    }
    //
    goEdit = (key, auditstatus) => {
        if (userUtil.hasAuth(AUTH_KEYS.OPERATE_SERVICE_SUPPLIER_SUPPLIEREDIT)) {
            this.props.history.push(`/app/operate/serviceCenter/supplier/edit/${key}?auditStatus=${auditstatus}`)
        } else {
            message.error('当前用户没有编辑权限');
        }
     }
    //分页
    onChangePage = (pageNo, pageSize, sort) => {
        console.log(pageNo, pageSize, sort, '====')
        var order = [];
        if (sort && sort.columnKey == "score") {
            order = sort && sort.order == 'ascend' ? [27] : [28];
        }
        this.getListFun({type: 'orderByList', data: order},{type: 'pageNo', data: pageNo},{type:'pageSize', data: pageSize})
    }
    getListFun(obj1, obj2, obj3) {
        let selectParams = this.state.selectParams;
        selectParams.pageNo = 1;
        selectParams[obj1.type] = obj1.data;
        if(obj2) {
            selectParams[obj2.type] = obj2.data;
        }
        if(obj3) {
            selectParams[obj3.type] = obj3.data;
        }
        this.setState({
            selectParams
        },() => {
            this.getLists();
        })
    }
    reset = () => {
       this.setState( {
            startDateMoment: undefined,
            endDateMoment: undefined,
            checked: false,
            selectParams:  {
                name: undefined,//供应商名称
                userCode: undefined, //对接人
                auditStatus: undefined, //审核状态
                startDate: undefined, //开始时间           
                endDate: undefined, //结束时间
                type: undefined, //2仅显示待我审批
                pageNo: 1,// 当前页数
                pageSize: 10,// 每页条数
            }
       },() => {
        this.getLists();
        })      
    }

    notFount() {
        return (
            <div style={{ textAlign: 'center', height: '100px', lineHeight: '100px' }}>加载中...</div>
        )
    }
    notFoundContent() {
        return (
            <div className='ant-empty ant-empty-normal ant-empty-small'><div className="ant-empty-image"></div><p className="ant-empty-description">暂无数据</p></div>
        )
    }
    render() {
        const selectParams = this.state.selectParams
        return (
           
            <div className="supplier-con">
                <div className='supplier-header'>
                    <Breadcrumb>
                        <BreadcrumbItem>供应商列表页</BreadcrumbItem>
                    </Breadcrumb>
                    <AuthContainer authCodes={AUTH_KEYS.OPERATE_SERVICE_SUPPLIER_SUPPLIEREDIT}>
                        <Button type="primary" className='new-supplier-btn' ><Link to='/app/operate/serviceCenter/supplier/new'>添加供应商</Link></Button>
                    </AuthContainer>
                </div>
                <div className='supplier-list'>
                    <div className='manage-filter'>
                        <Search
                                placeholder="请输入供应商名称"
                                value={selectParams.name}
                                onSearch={this.onSearchName}
                                onChange={this.setName}
                                maxLength={20}
                                className='manage-search'
                            />
                        <Select style={{width:'104px'}} className='manage-filter-type'  notFoundContent={ this.state.fetching ? this.notFount() : this.notFoundContent()} data={this.state.users} placeholder="对接人" value={selectParams.userCode} selectMethod={this.chooseUser} showSearch  onSearch={debounce(this.onSearchUser,1000)} filterOption={false}/>
                        <Select style={{width:'104px'}} className='manage-filter-type'  notFoundContent={ this.state.fetching ? this.notFount() : this.notFoundContent()} data={this.state.auditStatusLists} placeholder="审批状态" value={selectParams.auditStatus} selectMethod={this.chooseAuditStatus} style={{display : this.state.hasConfig ? '' : 'none'}}/>
                        创建时间
                        <RangePicker className='manage-filter-date' value={[this.state.startDateMoment,this.state.endDateMoment]} onChange={this.handleDate} style={{width: '206px'}} />
                        <Checkbox onChange={this.chooseMyAudit} checked={this.state.checked} style={{display : this.state.showAudit && this.state.hasConfig ? '' : 'none'}}>仅显示待我审批</Checkbox>
                        <span style={{cursor: 'pointer',fontSize: '12px',textDecoration:'underline',textUnderlinePosition: 'under'}} onClick={this.reset}>重置</span>
                    </div>               
                    <Table columns={this.state.columns} selfProcessData={this.state.data} total={this.state.total} change={this.onChangePage} current={selectParams.pageNo}/>
                </div>
                <DeleteSupplier visible={this.state.showDelete} supplierId={this.state.supplierId} name={this.state.name} getNewLists={this.getLists.bind(this)}/>
            </div>
        );
    }
}

export default SupplierLists;