import {
    AJAX_TYPE
} from '../config';

// Role.Role

const prefix = 'authRole';

export default {
    // 所有权限list
    [`${prefix}AllFunc`]: {
        url: '/web/crm/promission/function/getAllFunctions',
        type: AJAX_TYPE.GET
    },
    // 登陆人的权限
    [`${prefix}MyFunc`]: {
        url: '/web/crm/promission/function/getMyFunctions',
        type: AJAX_TYPE.GET
    },
    // 角色详情
    [`${prefix}Detail`]: {
        url: '/web/crm/promission/role/detail/:id',
        type: AJAX_TYPE.GET
    },
    // 角色列表
    [`${prefix}GetList`]: {
        url: '/web/crm/promission/role/getList',
        type: AJAX_TYPE.POST
    },
    // 角色新增
    [`${prefix}Add`]: {
        url: '/web/crm/promission/role/add',
        type: AJAX_TYPE.POST
    },
    // 角色编辑
    [`${prefix}Edit`]: {
        url: '/web/crm/promission/role/edit',
        type: AJAX_TYPE.POST
    },
    // 角色删除
    [`${prefix}Delete`]: {
        url: '/web/crm/promission/role/del/:id',
        type: AJAX_TYPE.GET
    },
    
}