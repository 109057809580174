import React, { Component } from 'react';
import { Form, Input } from 'antd';

const { TextArea } = Input;
const { Item } = Form;

export default class CancelOrder extends Component{
    render() {
        const { chooseProject } = this.props;
        const { getFieldDecorator } = this.props.form;

        return (
            <div className='maintain-addorder maintain-form'>
                <Form>
                    <Item label="取消原因">
                        {getFieldDecorator('remark',{
                            rules: [{required: true, message: '请输入取消原因',}],
                        })(
                            <TextArea
                                style={{resize:'none'}}
                                maxLength={200}
                                onChange={chooseProject.bind(this,1)} />
                        )}
                    </Item>
                </Form>
            </div>
        )
    }
}
