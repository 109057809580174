import React, { Component } from 'react';
import { Row, Col, Icon } from 'antd'
import { Link } from 'react-router-dom'
import { myWorkContactReport } from './service/jobService'

class JobNumber extends Component {

    state = {
        untreatedExpired: 0,
        aboutToExpireThirty: 0,
        aboutToExpireThirtyToNinety: 0
    }

    componentDidMount() {
        myWorkContactReport().then(res => {
            if (res.code == 0) {
                let data = res.data
                if (data) {
                    this.setState({
                        untreatedExpired: data.untreatedExpired,
                        aboutToExpireThirty: data.aboutToExpireThirty,
                        aboutToExpireThirtyToNinety: data.aboutToExpireThirtyToNinety
                    })
                }
            }
        })
    }


    render() {
        return (
            <div className="job-number">
                <Row gutter={16}>
                    <Col className="gutter-row" span={8}>
                        <div className="job-number-uncheck">
                            <p>到期未处理合同</p>
                            <p>
                                <Link to={{ pathname: '/app/operate/contract',search:'source=myjob' }}>                
                                    <span className="big-num">{this.state.untreatedExpired}</span><span>份</span>
                                    <Icon style={{ marginLeft: '5px' }} type="right" />
                                 </Link>

                            </p>
                        </div>
                    </Col>
                    <Col className="gutter-row" span={8}>
                        <div className="job-number-thirty">
                            <p>30天内到期合同</p>
                            <p>
                                <span className="big-num">{this.state.aboutToExpireThirty}</span><span>份</span>
                                {/* <Icon type="right" /> */}
                            </p>
                        </div>
                    </Col>
                    <Col className="gutter-row" span={8}>
                        <div className="job-number-ninety">
                            <p>30-90天内到期合同</p>
                            <p>
                                <span className="big-num">{this.state.aboutToExpireThirtyToNinety}</span><span>份</span>
                                {/* <Icon type="right" /> */}
                            </p>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default JobNumber