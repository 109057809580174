import React, { Component } from 'react';
import { Form, Select, Input, Button, DatePicker } from 'antd';
import moment from 'moment';
import { API } from '@/http/api';
import http from '@/http';
import { Platform } from "../enum";

const { Option } = Select;

class SearchForm extends Component{
  state = { }

  reset = () => {
    const { form } = this.props;
    form.resetFields();
    this.props.form.validateFields(
      { force: true },
      (err, values) => {
        console.log(err, values, 'err, values')
        if (!err) {
          this.props.reset(values);
          // this.props.onSearch()
        }
      },
    );
  }
  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <Form layout='inline'>
        <Form.Item label="">
          {getFieldDecorator('queryText', )(
            <Input
              style={{width: '250px'}}
              placeholder={'请输入订单编号/货主运输方名称'}
            />
          )}
        </Form.Item>
        {/* <Form.Item label="">
          {getFieldDecorator('placeOrderPlatform')(
            <Select allowClear placeholder="下单平台" style={{width: '100px'}}>
              {Platform.toSelectArray().map(item => (
                <Option key={item.id} value={item.id}>
                  {item.text}
                </Option>
              ))}
            </Select>,
          )}
        </Form.Item> */}
        
        <Form.Item label="">
          <Button type='primary' onClick={this.props.onSearch}>搜索</Button>
        </Form.Item>
        <Form.Item label="">
          <a className='reset' onClick={this.reset}>重置</a>
        </Form.Item>
      </Form>
    );
  }
}

const onValuesChanges = (props, changedValues, allValues) => {
  let result = {...allValues,pageNo: 1}
  if(allValues.commitTime && allValues.commitTime.length > 0){
    result.commitTimeStart = moment(allValues.commitTime[0]).format('YYYY-MM-DD')
    result.commitTimeEnd = moment(allValues.commitTime[1]).format('YYYY-MM-DD')
  } else {
    result.commitTimeStart = ''
    result.commitTimeEnd = ''
  }
  delete result['commitTime']
  for(let key in result){
    if(result[key] == undefined){
      result[key] = ''
    }
  }
  props.onValuesChange(result)
}

export default Form.create({ name: "EnterpriseAuthList", onValuesChange: onValuesChanges })(SearchForm);
