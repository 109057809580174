import React, { Component } from 'react'
import './ContractDetail.less'
import {
    Breadcrumb, Table,
} from "antd";
import {Link} from "react-router-dom";
import { API } from "@/http/api";
import http from "@/http";

class ContractFin extends Component {
    constructor(props) {
        super(props)
        this.state = {
            detail: null,
        }
    }

    componentDidMount() {
        this.getDetail()
    }

    getDetail = () => {
        const code = location.href.split('/')[location.href.split('/').length-1]
        console.log(code);
        const config = {
            api: API.orderContractDetail,
            data: {
               code
            },
            showLoading: true
        }
        http(config).then(res => {
            if(res.code == 200) {
                 this.setState({
                     detail: res.data
                 })
            }
        })
    }
    render(){
        const { detail } = this.state
        return(
            <div className='userlist-content'>
                <div className='enterprise-auth-header'>
                    <Breadcrumb className="customer-detail-head-bread">
                        <Breadcrumb.Item>
                            <Link
                                style={{ color: "#333" }}
                                to={{
                                    pathname: "/app/crm/contract",
                                }}
                            >
                                合同备案
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>成品料采供详情</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="userlist-body">
                        <div className='contractDetail'>
                            <h2 className='contractDetail-title'>成品料采供详情</h2>
                            <div className='contractDetail-label-wrapper'>
                                <span className='contractDetail-label'>产品名称</span>
                                <span className='contractDetail-content'>{detail&&detail.finishedProName}</span>
                            </div>
                            <div className='contractDetail-label-wrapper'>
                                <span className='contractDetail-label'>规格型号</span>
                                <span className='contractDetail-content'>{detail&&detail.finishedModel}</span>
                            </div>
                            <div className='contractDetail-label-wrapper'>
                                <span className='contractDetail-label'>计量单位</span>
                                <span className='contractDetail-content'>{detail&&detail.finishedCompany}</span>
                            </div>
                            <div className='contractDetail-label-wrapper'>
                                <span className='contractDetail-label'>数量</span>
                                <span className='contractDetail-content'>{detail&&detail.finishedQuantity}</span>
                            </div>
                            <div className='contractDetail-label-wrapper'>
                                <span className='contractDetail-label'>采购单价（含税）</span>
                                <span className='contractDetail-content'>{detail&&detail.finishedPurchasePrice}</span>
                            </div>
                            <div className='contractDetail-label-wrapper'>
                                <span className='contractDetail-label'>运输单价</span>
                                <span className='contractDetail-content'>{detail&&detail.finishedTransportationPrice}</span>
                            </div>
                            <div className='contractDetail-label-wrapper'>
                                <span className='contractDetail-label'>综合到场价</span>
                                <span className='contractDetail-content'>{detail&&detail.finishedComprehensivePrice}</span>
                            </div>
                            <div className='contractDetail-label-wrapper'>
                                <span className='contractDetail-label'>增值税税率</span>
                                <span className='contractDetail-content'>{detail&&detail.finishedVatRate}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ContractFin
