import React, { Component } from 'react';
import './SettingOrganizationDepartment.less'
import {
    Form, Input, Tooltip, Icon, Cascader, Select, Row, Col, Checkbox, Button, AutoComplete, message,Modal
} from 'antd';
import { Breadcrumb, TreeSelect, Tree } from 'antd';
import http from '@/http';
import { API } from '@/http/api';
import userUtil from '@/utils/user';
import { AUTH_KEYS } from '@/utils/auth';
const { TextArea } = Input;
const { TreeNode } = Tree;
const Option = Select.Option;

class DepartmentForm extends Component {
    state = {
        confirmDirty: false,
        autoCompleteResult: [],
        projects: [
            
        ],
        // 父部门项目权限
        superior: [

        ],
        // 子部门项目权限
        subbase: [
            
        ],
        // 不包含的项目权限

        value: undefined,
        treeData: []
    };

    componentWillMount() {
        // 判断是否是编辑  
        const idCode = this.props.history.location.search&&this.props.history.location.search.replace('?edit=','')
        if(idCode){
            this.setState(
                {edit:true,
                    idCode}
            )
            // 获取项目
            http({
                api: API.departmentGetProjects,
                path: { departmentCode: idCode }, // 路径参数
                data: { isParent: true },
                showLoading: true // 是否显示全局加载
            }).then(res => {
                console.log(res)
                this.setState({
                    superior: res.data
                })
            })
            http({
                api: API.departmentDetail,
                path: {
                    idCode
                }, // 路径参数
                data: {},
                showLoading: true // 是否显示全局加载
            }).then(res => {
                
                // 数值回填                ???? 全量值
                
                this.props.form.setFieldsValue({
                    name:res.data.name,
                    department:res.data.parentIdEncode,
                    tele:res.data.phone,
                    jurisdiction:res.data.projectMap.map(item=>{return item.idCode}),
                    description:res.data.description
                })
            })
        }
        // 获取部门数
        http({
            api: API.departmentGetDeptTree,
            path: {}, // 路径参数
            data: {},
            showLoading: true // 是否显示全局加载
        }).then(res => {
            this.setState({
                treeData: [res.data]
            })

        })


    }
    renderSelect = (data) => {

    }
    onChange = (value) => {

        this.setState({ value });
        // 获取项目
        http({
            api: API.departmentGetProjects,
            path: { departmentCode: value }, // 路径参数
            data: { isParent: false },
            showLoading: true // 是否显示全局加载
        }).then(res => {
            console.log(res)
            this.setState({
                superior: res.data
            })
        })
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
            }
        });
    }

    handleChange = (value) => {
        console.log(`selected ${value}`);
        // this.props.form.setFieldsValue({
        //     name: value,
        // });
    }
    changeMethod = (value, option) => {
        console.log(value)
    }
    componentDidMount() {
        this.props.form.setFieldsValue({
            // jurisdiction: ['red','green'],
        });
    }
    handleSelect = (value, options) => {
        console.log(value, options)
    }
    saveDepartment = () => {
        
        this.props.form.validateFields((errors, values) => {
            console.log(errors)
            if(!errors){
                const { department, description, jurisdiction, name, tele } = this.props.form.getFieldsValue()
                // 保存部门
                let url = API.departmentAdd
                if(this.state.edit){
                    url = API.departmentEdit
                }
                http({
                    api: url,
                    path: {}, // 路径参数
                    data: {
                        idEncode:this.state.idCode,
                        description,// (string, optional): 部门描述 ,
                        name: name,// (string): 部门名称 ,                
                        parentIdEncode: department,// (string): 父部门Idcode ,
                        phone: tele,// (string, optional): 部门电话 ,
                        projectEncodes: jurisdiction,// (Array[string]): 项目集合code ,
        
                    },
        
                    showLoading: true // 是否显示全局加载
                }).then(res => {
                    console.log(res)
                    if (res.success) {
                        this.props.history.push("/app/setting/organization/department?from="+department)
                        message.success('部门保存成功！', () => {
                        })
                    }
                })
            }
        });
    }
    renderTreeNodes = data =>
        data.map(item => {
            if (item.children) {
                return (
                    <TreeNode title={item.text} value={item.idCode} key={item.idCode} dataRef={item}>
                        {this.renderTreeNodes(item.children)}
                    </TreeNode>
                );
            }
            return <TreeNode {...item} />;
        });
    
    handleCancel=()=>{
        this.setState({
            visible:false
        })
    }
    handleOk=()=>{
        this.setState({
            visible:false
        })
        this.props.history.go(-1)
    }
    saveCancel=()=>{
        this.setState({
            visible:true
        })
    }
    validatorTel=(rule, value, callback)=>{
        if(!value){
            callback()            
        }else{

            if(/^\d{7,14}$/.test(value)){
                callback()
            }else{
                callback('请填写正确的部门电话')
            }
        }
    }
    render() {
        const { getFieldDecorator } = this.props.form;


        const formItemLayout = {
            style: {
                width: '240px'
            },
        };
        const labelStyle = {
            "fontFamily": "PingFangSC-Regular",
            "fontSize": "12px",
            "color": "#E0E4F1"
        }
        return (
            <div className="department-form">
                <Modal
                    closable={false}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    okText='确认'
                    width={400}         
                    onCancel={this.handleCancel}           
                >
                    <p>当前页面未保存，是否离开？</p>
                </Modal>
                <div className="department-breadcrumb">
                    <Breadcrumb>
                        <Breadcrumb.Item>部门列表页</Breadcrumb.Item>
                        <Breadcrumb.Item>{this.state.edit?"编辑部门":"新增部门"}</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className="department-content-form">
                    <div className="department-main">
                        <Form onSubmit={this.handleSubmit} layout="vertical">

                            <Form.Item label="部门名称"  {...formItemLayout}

                            >
                                {getFieldDecorator('name', {
                                    rules: [{ required: true, message: '请输入部门名称!', whitespace: false, max: 10 }],
                                })(
                                    <Input maxLength={10} placeholder="请输入" autoComplete="off" />
                                )}
                            </Form.Item>


                            <Form.Item label="所属部门"   {...formItemLayout}
                            >
                                {getFieldDecorator('department', {
                                    rules: [{ required: true, message: '请选择所属部门!' }],
                                    initialValue: this.state.value
                                })(
                                    <TreeSelect
                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                        disabled={this.state.edit}
                                        placeholder="请选择"
                                        treeDefaultExpandAll
                                        onChange={this.onChange}
                                    >
                                        {this.renderTreeNodes(this.state.treeData)}
                                    </TreeSelect>
                                )}
                            </Form.Item>

                            <Form.Item label='部门电话'  {...formItemLayout}
                            >
                                {getFieldDecorator('tele', {
                                    rules: [{ required: false,validator:this.validatorTel,  whitespace: true }],
                                })(
                                    <Input placeholder="请输入" maxLength={13} autoComplete="off" />
                                )}
                            </Form.Item>




                            <Form.Item required={false}  {...formItemLayout}
                                label={(
                                    <span style={labelStyle}>
                                        项目权限
        </span>
                                )}
                            >
                                {getFieldDecorator('jurisdiction', {
                                    initialValue: [],
                                    rules: [{ required: false, message: '请输入项目权限!', type: 'array' }]
                                })(
                                    <Select
                                        style={{ width: "560px" }}
                                        mode="multiple"
                                        placeholder="请输入项目权限!"
                                        onChange={this.handleChange}
                                        optionFilterProp='children'
                                    >
                                        {
                                            this.state.superior.map(res => {
                                                return <Option key={res.idCode} value={res.idCode}>{res.name}</Option>
                                            })
                                        }
                                    </Select>
                                )}
                            </Form.Item>

                            <Form.Item required={false}
                                label={(
                                    <span style={labelStyle}>
                                        部门描述
        </span>
                                )}
                            >
                                {getFieldDecorator('description', {
                                    initialValue: '',
                                })(
                                    <TextArea placeholder="请输入" maxLength={15} rows={4} />
                                )}
                            </Form.Item>


                            <Form.Item className="btn_controller">
                            {
                                this.state.edit&&userUtil.hasAuth(AUTH_KEYS.SETTING_ORGANIZATION_DEPARTMENT_MODIFY)?
                                <Button type="primary" htmlType="submit" onClick={this.saveDepartment}>保存</Button>
                                :null
                            }
                            {
                                !this.state.edit&&userUtil.hasAuth(AUTH_KEYS.SETTING_ORGANIZATION_DEPARTMENT_NEW)?
                                <Button type="primary" htmlType="submit" onClick={this.saveDepartment}>保存</Button>
                                :null
                            }                             
                                <span style={{ width: 16, display: 'inline-block' }}></span>
                                <Button type="default" onClick={this.saveCancel}>取消</Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>

            </div>
        )
    }
}
export default Form.create({ name: 'DepartmentForm' })(DepartmentForm);




