import { API } from "@/http/api";
import http from "@/http";
import { showErrorMessage } from "@/utils/globalMessageReminder";
import { formatLinkSearch } from "@/utils";
import { convertAuthenticationToLabel } from "CMPT/operate/customerConfig";
import React, { Component, useState, useEffect } from "react";
import {
  Layout,
  Breadcrumb,
  Button,
  Row,
  Col,
  Tabs,
  Modal,
  Input,
  message,
  Form,
} from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import Table from "CMPT/common/Table";
import SingleImageViewer from "CMPT/common/widget/SingleImageViewer";
import { CommonFlag } from "CMPT/common.js";
import { CertType, AuthenticationStatus } from "./enum";
import qs from "qs";

import "./EnterpriseAuthDetail.less";

import userUtils from "@/utils/user";
import { AUTH_KEYS } from "@/utils/auth";

import UserInfo from "./enterpriseAuthDetail/userInfo";
import ImgPreview from 'CMPT/common/ImgPreview';
const { checkAuthByKey } = userUtils;
const canShowShenHeAuth = checkAuthByKey(AUTH_KEYS.ENTERPRISE_MANAGE_EXAMINE); // 审核权限；

const { TextArea } = Input;
const { Content } = Layout;
const { TabPane } = Tabs;

class EnterpriseAuthDetail extends Component {
  state = {
    data: {},
    code: null, // 当前页面的ID,从前一个页面传递过来的
    pageNo: null, // 当前企业在前一个列表中的页码
    currentageCount: null, // 前一个页面中 列表内当前页的数量
    backPageNo: 1, //返回列表页面的页码

    showPassConfirmFlag: false, // 是否展示通过 对话框
    showRejectConfirmFlag: false, // 是否展示 驳回 对话框
    rejectInput: "", //用户输入的驳回理由
    errorTitle: null, // 审核时失败信息 对话框的title
    errorMsg: null, // 审核时失败信息
    showErrorFlag: false, // 是否展示 审核失败信息

    state: 1, // 详情页审核状态
    previewVisible: false,
    IMGURL: "", // 缩放图
    cascaderOptions: null
  };

  componentWillMount() {
    this.cDetail();

    if (this.props.location.search) {
      // 获取参数
      const search = this.props.location.search;
      const formatData = formatLinkSearch(search);

      // 解析
      let code1 = this.props.match.params.code;
      let pageNo1 = null;
      let currentageCount1 = null;

      if (formatData && formatData.pageNo) {
        pageNo1 = formatData.pageNo;
      }
      if (formatData && formatData.currentPageCount) {
        currentageCount1 = formatData.currentPageCount;
      }

      // 赋值
      this.setState({
        code: code1,
        pageNo: pageNo1,
        backPageNo: pageNo1, //如果什么都不做，返回列表的原始页码即可。否则，需要判断跳转哪一页，尤其删除一个页面中的唯一一条数据
        currentageCount: currentageCount1,
      });
    }
  }

  componentDidMount() {
    this.areaInit()
    this.GetCompanyType()
  }
  // 获取省市区选项
  areaInit = () =>{
    const locationConfig = {
        api: API.areaChinaAreaToPlatform
    }
    // 省市区
    http(locationConfig).then(loRes => {
        this.setState({
            cascaderOptions: loRes.data
        })
    }) 

  }

  // 获取公司类型
  GetCompanyType = () =>{
    const locationConfig = {
        api: API.enterpriseAuthGetCompanyType
    }
    // 省市区
    http(locationConfig).then(loRes => {
      let arr = [];
      if(loRes.code === 200) {
        for(let i in loRes.data) {
          arr.push(loRes.data[i])
        }
      }
        this.setState({
            companyTypeArr: arr
        })
    }) 

  }

  cDetail = () => {
    // console.log(this.props, 'this.props')
    const search = qs.parse(this.props.location.search.substr(1));
    const IS = search.is;
    console.log("is", IS);
    if (IS == 1) {
      // console.log(this.props.location.search.charAt(this.props.location.search.length-1))
      const config = {
        api: API.enterpriseAuthGetSupplierAuthDetail,
        data: { id: this.props.match.params.code },
      };
      http(config).then((res) => {
        // console.log(res, 'res111111   企业详情')
        if (res.code == 200) {
          console.log("res.data", res.data);
          let data = res.data;
          data.auditStatus = data.auditFormDetail.mainInfo
            ? data.auditFormDetail.mainInfo.auditStatus
            : data.auditFormDetail.driverInfoVO.auditStatus;
          this.setState({
            data,
          });
        }
      });
    } else if (IS == 2) {
      const config = {
        api: API.enterpriseAuthDriverAuditInfo,
        data: { auditId: this.props.match.params.code },
      };
      http(config).then((res) => {
        // console.log(res, 'res222 司机详情')
        if (res.code == 200) {
          let data = res.data;
          data.auditStatus = data.audit.auditStatus;
          data.id = data.audit.id;
          data.driverId = data.driverInfo.id;
          data.userId = data.driverInfo.userId;
          data.code = 200;
          this.setState({
            data,
          });
        }
      });
    } else if (IS == 3) {
      const config = {
        api: API.enterpriseAuthVehicleGetAuthDetail,
        data: { code: this.props.match.params.code },
      };
      http(config).then((res) => {
        // console.log(res, 'res333  车辆详情')
        if (res.code == 200) {
          let data = res;
          data.auditStatus = data.data.auditStatus;
          this.setState({
            data,
          });
        }
      });
    } else if (IS == 4) {
      const config = {
        api: API.enterpriseAuthgetOperatorAuditDetail,
        path: { code: this.props.match.params.code },
      };
      http(config).then((res) => {
        // console.log(res, 'res333  操作员信息')
        if (res.code == 200) {
          let data = res;
          data.auditStatus = data.data.auditStatus;
          this.setState({
            data,
          });
        }
      });
    }
  };
  //！ 已经不使用
  getDetail = () => {
    const config = {
      api: API.enterpriseAuthDetail,
      path: {
        code: this.props.match.params.code,
      },
      showLoading: true,
    };

    http(config).then((res) => {
      if (res.code == 0) {
        let data = res.data;

        this.setState(
          {
            data,
          },
          () => {
            //console.log(res.data)
          }
        );
      }
    });
  };

  // 判断返回上一页面，列表应该去哪一页
  changeBackPageNo() {
    let backPageNo = 1;
    if (this.state.pageNo == 1) {
      // 原来是第一页，回去还看第一页
      backPageNo = 1;
    } else {
      // 这里是 除了1 之外的页码
      if (this.state.currentageCount == 1) {
        backPageNo = this.state.pageNo - 1;
      } else {
        backPageNo = this.state.pageNo;
      }
    }

    this.setState({
      backPageNo: backPageNo,
    });
  }

  /* start: 审核驳回的相关逻辑  */
  hidenRejectAuthDialog = () => {
    this.setState({ showRejectConfirmFlag: false });
  };

  showRejectAuthDialog = () => {
    this.setState({ showRejectConfirmFlag: true });
  };

  onRejectInput = (e) => {
    this.state.rejectInput = e.target.value;
    // console.log(this.state.rejectInput, 'this.state.rejectInput11111')
  };

  gotoReject = (associationOperationFlag) => {
    const search = qs.parse(this.props.location.search.substr(1));
    const IS = search.is;
    // 不能为null
    if (!this.state.rejectInput) {
      showErrorMessage("请输入驳回原因");
      return;
    }

    // 去掉两边可能的空格
    var rejectInput = this.state.rejectInput.replace(/(^\s*)|(\s*$)/g, "");
    if (rejectInput.length < 1) {
      showErrorMessage("请输入有效的驳回原因");
      return;
    }
    // console.log(this.state.rejectInput, 'this.state.rejectInput2222222222')
    // console.log(this.state.data, 'this.state.data')
    let config;
    if (IS == 1) {
      config = {
        api: API.enterpriseAuthAudit,
        data: {
          auditId: this.state.data.id,
          auditStatus: 20,
          auditRemark: this.state.rejectInput,
          associationOperationFlag,
        },
      };
    } else if (IS == 2) {
      config = {
        api: API.enterpriseAuthDoAudit,
        data: {
          auditId: this.state.data.id,
          auditStatus: 20,
          auditRemark: this.state.rejectInput,
          driverId: this.state.data.driverId,
          userId: this.state.data.userId,
        },
      };
    } else if (IS == 3) {
      config = {
        api: API.enterpriseAuthVehicleDoAudit,
        data: {
          code: this.state.data.data.auditCode,
          auditStatus: 20,
          auditRemark: this.state.rejectInput,
        },
      };
    } else if (IS == 4) {
      config = {
        api: API.enterpriseAuthAuditOperator,
        data: {
          code: this.state.data.data.code,
          auditStatus: 20,
          auditRemark: this.state.rejectInput,
        },
      };
    }

    http(config).then((res) => {
      // console.log(res, 'res1111111111111111111')
      if (res.code == 200) {
        this.setState({ showRejectConfirmFlag: false });
        message.success("操作成功，已短信通知对方");
        this.cDetail();
      } else {
        this.setState({ showRejectConfirmFlag: false });
        message.error(res.message);
      }
    });
  };

  // 驳回
  addRejectAuthDialog() {
    const { data } = this.state;
    let descStr = "";
    let footer = [
      <Button key="back" type="primary" onClick={() => this.gotoReject()}>
        驳回
      </Button>,
    ];

    if (data&&data.auditFormDetail&&data.auditFormDetail.informaticaDetailInfo) {
      const btnStr = "同时驳回经营信息";
      descStr = "该企业包含出租经营信息";
      footer.push(
        <Button
          key="submit"
          type="primary"
          onClick={() => this.gotoReject(true)}
        >
          {btnStr}
        </Button>
      );
    } else if (data&&data.auditFormDetail&&data.auditFormDetail.tcAuditDetail) {
      const btnStr = "同时驳回运力信息";
      descStr = "该企业包含运力信息";
      footer.push(
        <Button
          key="submit"
          type="primary"
          onClick={() => this.gotoReject(true)}
        >
          {btnStr}
        </Button>
      );
    } else if (data&&data.auditFormDetail&&data.auditFormDetail.serviceInformationInfo) {
      const btnStr = "同时驳回服务信息";
      descStr = "该企业包含服务信息";
      footer.push(
        <Button
          key="submit"
          type="primary"
          onClick={() => this.gotoReject(true)}
        >
          {btnStr}
        </Button>
      );
    } else {
      footer.push(
        <Button key="cancel" onClick={this.hidenRejectAuthDialog}>
          取消
        </Button>
      );
    }

    return (
      <Modal
        title="驳回"
        visible={true}
        okText="确定"
        cancelText="取消"
        closable={true}
        maskClosable={false}
        onOk={this.gotoReject}
        onCancel={this.hidenRejectAuthDialog}
        width={400}
        footer={footer}
      >
        {
          // 描述
          descStr && <p>{descStr}</p>
        }
        <TextArea
          rows={4}
          onChange={this.onRejectInput}
          placeholder="请输入驳回理由 （100字以内）"
          maxLength={100}
        />
      </Modal>
    );
  }
  /* end: 审核驳回的相关逻辑  */

  /* start: 审核通过的相关逻辑  */
  // 添加 审核通过的组件，但不是立即展示
  addPassAuthDialog() {
    return (
      <Modal
        title={"通过"}
        visible={true}
        okText="确定"
        cancelText="取消"
        closable={true}
        maskClosable={false}
        onOk={this.gotoPass}
        onCancel={this.hiddenPassAuthDialog}
        width={400}
      >
        <div>{"确认通过" + this.state.data.companyName + "的审核？"}</div>
      </Modal>
    );
  }

  // 运力 及 出租经营信息审核通过弹窗
  addPassDialog() {
    const { data } = this.state;
    let descStr = "";
    let footer = [
      <Button key="cancel" type="primary" onClick={() =>this.fetchPass()}>
        通过
      </Button>,
    ];

    if (data.auditFormDetail.informaticaDetailInfo) {
      const btnStr = "同时通过经营信息";
      descStr = "该企业包含出租经营信息";
      footer.push(
        <Button
          key="submit"
          type="primary"
          onClick={() => this.fetchPass(true)}
        >
          {btnStr}
        </Button>
      );
    } else if (data.auditFormDetail.tcAuditDetail) {
      const btnStr = "同时通过运力信息";
      descStr = "该企业包含运力信息";
      footer.push(
        <Button
          key="submit"
          type="primary"
          onClick={() => this.fetchPass(true)}
        >
          {btnStr}
        </Button>
      );
    } else if (data.auditFormDetail.serviceInformationInfo) {
      const btnStr = "同时通过服务信息";
      descStr = "该企业包含服务信息";
      footer.push(
        <Button
          key="submit"
          type="primary"
          onClick={() => this.fetchPass(true)}
        >
          {btnStr}
        </Button>
      );
    }

    return (
      <Modal
        title={"通过"}
        visible={true}
        okText="确定"
        closable={true}
        maskClosable={false}
        onOk={this.fetchPass}
        onCancel={this.hiddenPassDialog}
        width={400}
        footer={footer}
      >
        <div>{descStr}</div>
      </Modal>
    );
  }

  hiddenPassDialog = () => {
    this.setState({ showPassFlag: false })
  }

  // 运力 及 出租经营信息审核通过接口
  fetchPass = (associationOperationFlag) => {
    const { tmpParams = {} } = this.state
    const config = {
      api: API.enterpriseAuthAudit,
      data: {
        auditId: this.state.data.id,
        auditStatus: 10,
        associationOperationFlag,
        ...tmpParams
      },
    };

    http(config).then((res) => {
      if (res.code == 200) {
        message.success("操作成功，已短信通知对方");
        this.cDetail();
        this.hiddenPassDialog()
        this.setState({ tmpParams: {}})
      } else {
        message.error(res.message);
      }
    });
  }

  handlePass = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        if (values.workAddress) {
          values.provinceId = values.workAddress[0];
          values.cityId = values.workAddress[1];
          values.areasId = values.workAddress[2];
        }
        if (values.registerValidDate) {
          values.registerValidFrom = values.registerValidDate[0]
          values.registerValidTo = values.registerValidDate[1]
        }
        this.showPassAuthDialog(values)
      }
    });
  }

  // 去展示 审核通过 对话框
  showPassAuthDialog = (params) => {


    // this.setState({
    //     showPassConfirmFlag: true
    // });

    // return
    // console.log(this.state.data, 'this.state.data')
    let config;
    const search = qs.parse(this.props.location.search.substr(1));
    const IS = search.is;
    const { data } = this.state;

    if (IS == 1) {
      // 企业认证
      config = {
        api: API.enterpriseAuthAudit,
        data: {
          auditId: this.state.data.id,
          auditStatus: 10,
          ...params
        },
      };
      // 运力 及 出租经营信息
      if (
        data.auditFormDetail.informaticaDetailInfo ||
        data.auditFormDetail.tcAuditDetail ||
        data.auditFormDetail.serviceInformationInfo
      ) {
        this.setState({ showPassFlag: true, tmpParams: params });
        return;
      }

      // showPassFlag
      // return;
    } else if (IS == 2) {
      // 司机认证
      config = {
        api: API.enterpriseAuthDoAudit,
        data: {
          auditId: this.state.data.id,
          auditStatus: 10,
          driverId: this.state.data.driverId,
          userId: this.state.data.userId,
          ...params
        },
      };
    } else if (IS == 3) {
      config = {
        api: API.enterpriseAuthVehicleDoAudit,
        data: {
          auditStatus: 10,
          code: this.state.data.data.auditCode,
        },
      };
    } else if (IS == 4) {
      // 操作员
      config = {
        api: API.enterpriseAuthAuditOperator,
        data: {
          code: this.state.data.data.code,
          auditStatus: 10,
          ...params
        },
      };
    }
    http(config).then((res) => {
      if (res.code == 200) {
        message.success("操作成功，已短信通知对方");
        this.cDetail();
      } else {
        message.error(res.message);
      }
    });
  };

  hiddenPassAuthDialog = () => {
    this.setState({
      showPassConfirmFlag: false,
    });
  };

  gotoPass = () => {
    // 参数
    let selectParams = {
      code: this.state.code, //this.props.location.query.code,//this.props.match.params.id,
      authenticationStatus: 1,
    };

    // 去请求
    http({
      api: API.enterpriseAuthDo,
      data: selectParams,
      showLoading: true,
      successTip: "已审核通过",
    })
      .then((result) => {
        if (result.success) {
          this.hiddenPassAuthDialog();
          this.changeBackPageNo();
          this.getDetail();
        } else if (result.code === "9998") {
          this.showErrorInfoDialog("通过", result.msg);
        }
      })
      .catch((erorr) => {
        //
      });
  };
  /* end: 审核通过的相关逻辑  */

  /* 审核，通过或者驳回时 需要 */
  showErrorInfoDialog(title, msg) {
    this.setState({
      showPassConfirmFlag: false,
      showRejectConfirmFlag: false,
      errorTitle: title,
      errorMsg: msg,
      showErrorFlag: true,
    });
  }

  hiddenAuthErrorInfoDialog = () => {
    this.setState({
      showPassConfirmFlag: false,
      showRejectConfirmFlag: false,
      errorTitle: null,
      errorMsg: null,
      showErrorFlag: false,
    });
  };

  addErrorInfoDialog() {
    return (
      <Modal
        title={this.state.errorTitle}
        visible={true}
        footer={[
          <Button
            key="primary"
            style={{ float: "none" }}
            type="primary"
            onClick={this.hiddenAuthErrorInfoDialog}
            size="large"
          >
            我知道了
          </Button>,
        ]}
        closable={false}
        maskClosable={false}
        width={400}
        onOk={this.hiddenAuthErrorInfoDialog}
      >
        <div>{this.state.errorMsg}</div>
      </Modal>
    );
  }

  // 设置图片
  closeImagePreview = () => {
    console.log("设置图片", this.state.previewVisible);
    this.setState({ previewVisible: false });
  };
  // 设置图片缩放
  cImage = (imgList, currentIndex =0, isForArr) => {
    imgList = isForArr ? imgList.map(item => {
      return item ? (item.url || item.src || item) : '' ;
    }) : imgList;
    this.setState({
      previewVisible: true,
      // IMGURL: value,
      imgList,
      currentIndex: currentIndex
    });
  };

  render() {
    let {
      data,
      data: { applyInfoList = [] },
      previewVisible,
      currentIndex,
      imgList,
      IMGURL,
    } = this.state;

    const applyData =
      applyInfoList && applyInfoList.filter((item, index) => index % 2 === 0);
    const auditData =
      applyInfoList && applyInfoList.filter((item, index) => index % 2 !== 0);
    data.backPageNo = this.state.backPageNo;
    data.currentageCount = this.state.currentageCount;
    return (
      <Content className="customer-detail-content">
        <div className="customer-detail-head flexbox">
          <Breadcrumb className="customer-detail-head-bread">
            <Breadcrumb.Item>
              <Link
                style={{ color: "#333" }}
                to={{
                  pathname: "/app/crm/enterprise_auth",
                  search: "backPageNo=" + this.state.backPageNo,
                }}
              >
                用户审核
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>用户信息</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div gutter={24} className="">
          {/* <Modal
            visible={previewVisible}
            title=" "
            footer={null}
            onCancel={this.closeImagePreview}
          >
            <img
              alt="图片预览"
              style={{ width: "100%", margin: "0 0 24px" }}
              src={IMGURL}
            />
          </Modal> */}
          <ImgPreview show={previewVisible} current={currentIndex} imgList={imgList} onClose={this.closeImagePreview}/>
          {data.auditFormDetail || data.code == 200 ? (
            <Form layout="inline">
              <UserInfo form={this.props.form} data={this.state} props={this} onSubmit={this.handleUserInfoSubmit} />
            </Form>
          ) : null}

          {
            [
              /*认证状态（0、待认证 1、通过 2、驳回） */
            ]
          }
          {data.auditStatus == 0 ? (
            <div className="detail_btn">
              <Button
                type="primary"
                onClick={this.handlePass.bind(this)}
              >
                通过
              </Button>
              <Button onClick={this.showRejectAuthDialog.bind(this)}>
                驳回
              </Button>
            </div>
          ) : null}
        </div>
        {this.state.showPassConfirmFlag ? this.addPassAuthDialog() : ""}
        {this.state.showRejectConfirmFlag ? this.addRejectAuthDialog() : ""}
        {this.state.showErrorFlag ? this.addErrorInfoDialog() : ""}
        {this.state.showPassFlag ? this.addPassDialog() : ""}
      </Content>
    );
  }
}

export default Form.create()(EnterpriseAuthDetail);
