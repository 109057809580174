import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom';
import moment from 'moment';
import './enterpriseAuth.less'
import ImgPreview from 'CMPT/common/ImgPreview';
import { Input, Form, DatePicker, Cascader, Switch, Select } from 'antd';
const { RangePicker } = DatePicker;
const { Option } = Select;

export default withRouter(function EnterpriseAuth({ data, props, form }) {
  let DATA
  let QIYE_STATUS
  const { getFieldDecorator } = form;

  const enterpriseInfoData = [
    { label: "企业名称", field: "name", isEdit: true, formItemType: 'Input' },
    { label: "企业简称", field: "shortName", isEdit: true, formItemType: 'Input' },
    { label: "统一社会信用代码", field: "unifiedSocialCode", isEdit: true, formItemType: 'Input' },
    { label: "经营地址", field: 'workAddress', isEdit: true, formItemType: 'Cascader' },
    { label: "企业类型", field: "typeName", isEdit: true, formItemType: 'Select' },
    { label: "注册所在地", field: "areaName" },
    { label: "营业期限", field: "registerValidDate", isEdit: true, formItemType: 'DatePicker' },
    // { label: "营业执照", field: "registerCardAttachmentURL" },
  ]
  // 建设施工(授权委托)
  const enterpriseInfo_101 = [
    { label: "名称", field: "name", isEdit: true, formItemType: 'Input' },
    { label: "简称", field: "shortName", isEdit: true, formItemType: 'Input' },
    { label: "所在地", field: "businessDetailAddress" },
    { label: "施工期限", field: 'registerValidDate', isEdit: true, formItemType: 'DatePicker' },
    { label: "委托单位", field: "deputeCorp", isEdit: true, formItemType: 'Input' },
  ]
  // 建设施工(无资质)
  const enterpriseInfo_201 = [
    { label: "名称", field: "name", isEdit: true, formItemType: 'Input' },
    { label: "所在地", field: "businessDetailAddress" },
    { label: "姓名", field: 'legalManName', isEdit: true, formItemType: 'Input' },
    { label: "联系电话", field: "legalManPhone" },
    { label: "证件类型", field: "legalIdCardType" },
    { label: "证件号码", field: "legalIdCardNumber", isEdit: true, formItemType: 'Input' },
  ]
  // 出租方(授权委托)
  const enterpriseInfo_105 = [
    { label: "名称", field: "name", isEdit: true, formItemType: 'Input' },
    { label: "简称", field: "shortName", isEdit: true, formItemType: 'Input' },
    { label: "所在地", field: "businessDetailAddress" },
    { label: "委托期限", field: 'registerValidDate', isEdit: true, formItemType: 'DatePicker' },
    { label: "委托单位", field: "deputeCorp", isEdit: true, formItemType: 'Input' },
    // { label: "证件类型", field: "legalIdCardType" },
  ]
  // 出租方(个人)
  const enterpriseInfo_205 = [
    { label: "姓名", field: "legalManName", isEdit: true, formItemType: 'Input' },
    { label: "手机号码", field: "legalManPhone" },
    { label: "紧急联系人", field: "emergencyContact", isEdit: true, formItemType: 'Input', noRequire: true },
    { label: "紧急联系人手机号码", field: "emergencyMobile", isEdit: true, formItemType: 'Input', noRequire: true },
    { label: "身份证号码", field: "legalIdCardNumber", isEdit: true, formItemType: 'Input' },
  ]

  // 运输方(个人)
  const enterpriseInfo_202 = [
    { label: "姓名", field: "legalManName", isEdit: true, formItemType: 'Input' },
    { label: "手机号码", field: "legalManPhone" },
    { label: "紧急联系人", field: "emergencyContact", isEdit: true, formItemType: 'Input', noRequire: true },
    { label: "紧急联系人手机号码", field: "emergencyMobile", isEdit: true, formItemType: 'Input', noRequire: true },
    { label: "身份证号码", field: "legalIdCardNumber", isEdit: true, formItemType: 'Input' },
    { label: "所在地", field: "businessDetailAddress" },
  ]

    // 运力信息
    const capacityInfoName = [
      { label: "服务区域", field: "serviceAreasStr" },
      { label: "常运货物", field: "freightGoodsTypesStr" },
      { label: "服务特色", field: "tcServicesStr" },
      { label: "服务描述", field: "tcServicesRemark" },
      { label: "联系人", field: "contactUser" },
      { label: "联系方式", field: "telephone" },
      { label: "发票信息", field: "invoiceFlag" },
      { label: "可开发票", field: "freightInvoicesStr" },
      { label: "车队规模", field: "ttransScaleVOListStr" },
    ]

  // 经营信息
  const manageInfoDetail = [
    { label: "服务区域", field: "areaNameStr" },
    { label: "设备类型", field: "equipmentTypeStr" },
    { label: "服务特色", field: "sellingPointStr" },
    { label: "联系人", field: "contactsName" },
    { label: "联系方式", field: "contactsMobile" },
    { label: "设备规模", field: "equipmentScale" },
    { label: "服务介绍", field: "serviceProfile" },
  ]

  // 服务信息
  const serviceInfoDetail = [
    { label: "服务范围", field: "areaNameStr" },
    { label: "业务区域", field: "equipmentTypeStr" },
    { label: "联系人", field: "constantName" },
    { label: "联系方式", field: "constantPhone" },
    { label: "服务介绍", field: "serviceIntroduction" },
  ]

  let TITLE = ''

  let enterpriseMsg
  let qualityReport
  let transportOperationPermit
  let operatingLicense

  // 运力信息
  let tcAuditDetailInfo
  // 经营信息
  let inforMaticaDetailInfo;

  let serviceDataInfo;


  if (data.data && data.data.auditFormDetail) { // 企业信息
    DATA = data.data.auditFormDetail.mainInfo
    tcAuditDetailInfo = data.data.auditFormDetail.tcAuditDetail
    inforMaticaDetailInfo = data.data.auditFormDetail.informaticaDetailInfo;
    serviceDataInfo = data.data.auditFormDetail.serviceInformationInfo;
    if (DATA.identityTypeCode == 101) { // 建设施工(授权委托)
      TITLE = '基本'
    } else if (DATA.identityTypeCode == 201) { // 建设施工(无资质)
      TITLE = '基本'
    } else if (DATA.identityTypeCode == 105) { // 出租方(授权委托)
      TITLE = '基本'
    } else if (DATA.identityTypeCode == 205) { // 出租方(个人)
      TITLE = '基本'
    } else if (DATA.identityTypeCode == 202) { // 运输方(个人)
      TITLE = '基本'
    }

    // 企业信息---------------
    // 运力信息
    if (tcAuditDetailInfo) {
      let serviceAreasStr = '' //运力信息服务区域
      let freightInvoicesStr = ''
      let ttransScaleVOListStr = ''
      tcAuditDetailInfo.serviceAreas && tcAuditDetailInfo.serviceAreas.map(item => {
        return serviceAreasStr += item + '、'
      })
      tcAuditDetailInfo.serviceAreasStr = serviceAreasStr.length > 0 && serviceAreasStr.substr(0, serviceAreasStr.length - 1)
      let freightGoodsTypesStr = '' //运力信息货物类型
      tcAuditDetailInfo.freightGoodsTypes && tcAuditDetailInfo.freightGoodsTypes.map(item => {
        return freightGoodsTypesStr += item + '、'
      })
      tcAuditDetailInfo.freightGoodsTypesStr = freightGoodsTypesStr.length > 0 && freightGoodsTypesStr.substr(0, freightGoodsTypesStr.length - 1)
      let tcServicesStr = '' //运力信息服务特色
      tcAuditDetailInfo.tcServices && tcAuditDetailInfo.tcServices.map(item => {
        return tcServicesStr += item + '、'
      })
      tcAuditDetailInfo.tcServicesStr = tcServicesStr.length > 0 && tcServicesStr.substr(0, tcServicesStr.length - 1)
      tcAuditDetailInfo.invoiceFlag == 1 ? tcAuditDetailInfo.invoiceFlag = '可开发票' : tcAuditDetailInfo.invoiceFlag = '无需发票'
      tcAuditDetailInfo.freightInvoices && tcAuditDetailInfo.freightInvoices.map(item => {
        return freightInvoicesStr += item + '、'
      })
      tcAuditDetailInfo.freightInvoicesStr = freightInvoicesStr.length > 0 && freightInvoicesStr.substr(0, freightInvoicesStr.length - 1)

      tcAuditDetailInfo.ttransScaleList && tcAuditDetailInfo.ttransScaleList.map(item => {
        return ttransScaleVOListStr += item.dictName + item.scaleCount + '辆、'
      })
      ttransScaleVOListStr = '驾驶员' + tcAuditDetailInfo.driverScale + '人、' + ttransScaleVOListStr
      tcAuditDetailInfo.ttransScaleVOListStr = ttransScaleVOListStr.length > 0 && ttransScaleVOListStr.substr(0, ttransScaleVOListStr.length - 1)
    }

    // 经营信息
    if (inforMaticaDetailInfo) {
      let areaNameStr = '' //经营信息服务区域
      inforMaticaDetailInfo.areaName && inforMaticaDetailInfo.areaName.map(item => {
        return areaNameStr += item + '、'
      })
      inforMaticaDetailInfo.areaNameStr = areaNameStr.length > 0 && areaNameStr.substr(0, areaNameStr.length - 1)
      let equipmentTypeStr = '' //经营信息设备类型
      inforMaticaDetailInfo.equipmentType && inforMaticaDetailInfo.equipmentType.map(item => {
        return equipmentTypeStr += item + '、'
      })
      inforMaticaDetailInfo.equipmentTypeStr = equipmentTypeStr.length > 0 && equipmentTypeStr.substr(0, equipmentTypeStr.length - 1)
      let sellingPointStr = '' //经营信息服务特色
      inforMaticaDetailInfo.sellingPoint && inforMaticaDetailInfo.sellingPoint.map(item => {
        return sellingPointStr += item + '、'
      })
      inforMaticaDetailInfo.sellingPointStr = sellingPointStr.length > 0 && sellingPointStr.substr(0, sellingPointStr.length - 1)
    }

    if (serviceDataInfo) {
      let areaNameStr = '';
      serviceDataInfo.consultingServiceTypeList && serviceDataInfo.consultingServiceTypeList.map(item => {
        return areaNameStr += item.consultingServiceTypeName + '、'
      })
      serviceDataInfo.areaNameStr = areaNameStr.length > 0 && areaNameStr.substr(0, areaNameStr.length - 1)
      let equipmentTypeStr = '';
      serviceDataInfo.consultingServiceAreaList && serviceDataInfo.consultingServiceAreaList.map(item => {
        return equipmentTypeStr += item.name + '、'
      })
      serviceDataInfo.equipmentTypeStr = equipmentTypeStr.length > 0 && equipmentTypeStr.substr(0, equipmentTypeStr.length - 1);
      console.log(serviceDataInfo, 'serviceDataInfoserviceDataInfo')
    }



    // 处理日期
    let dateTime = DATA.registerValidFrom && DATA.registerValidTo ? moment(DATA.registerValidFrom).format('YYYY-MM-DD') + '~' + moment(DATA.registerValidTo).format('YYYY-MM-DD') : ''
    enterpriseMsg = {
      name: DATA.name,
      unifiedSocialCode: DATA.unifiedSocialCode,
      typeName: DATA.typeName,
      shortName: DATA.shortName,
      areaName: DATA.areaFullName && DATA.areaFullName.indexOf('/') == -1 ? DATA.areaFullName : DATA.areaFullName && DATA.areaFullName.substring(0, DATA.areaFullName.indexOf('/')),
      registerValidDate: DATA.registerValidLongTerm ? '长期' : dateTime,
      deputeCorp: DATA.deputeCorp,
      legalManPhone: DATA.legalManPhone,
      emergencyContact: DATA.emergencyContact,
      emergencyMobile: DATA.emergencyMobile,
      legalManName: DATA.legalManName,
      legalIdCardType: DATA.legalIdCardType == 1 ? '身份证' : DATA.legalIdCardType == 2 ? '护照' : '其他',
      legalIdCardNumber: DATA.legalIdCardNumber,
      auditStatus: DATA.auditStatus,
      businessDetailAddress: DATA.businessDetailAddress,
    }
    if (DATA.identityTypeCode == 108 || DATA.identityTypeCode == 208) {
      enterpriseMsg.areaName = DATA.provinceName + DATA.cityName + DATA.areasName + DATA.businessDetailAddress;
    }
    qualityReport = []
    DATA.configAttachmentList && DATA.configAttachmentList.map(item => {
      if (item.subModuleType == 1) { // 企业【运输资质】
        QIYE_STATUS = 1
        transportOperationPermit = item.attachments[0].url
      }
      if (item.subModuleType == 2) { //  企业【经营信息】
        QIYE_STATUS = 2
        item.attachments && item.attachments.map(it => qualityReport.push(it.url))
      }
    })
    DATA.configAttachmentList && DATA.configAttachmentList.map(item => {
      return operatingLicense = item.attachments[0] && item.attachments[0].url
    })
  }

  const dateFormat = 'YYYY-MM-DD';
  console.log(moment('2015/01/01', dateFormat), 'asdfasdfasdfasdfasdf')
  console.log(moment(DATA.registerValidFrom).format(dateFormat), '============')
  let registerValidFrom = DATA.registerValidFrom ? moment(DATA.registerValidFrom).format(dateFormat) : DATA.registerValidFrom;
  let registerValidTo = DATA.registerValidTo ? moment(DATA.registerValidTo).format(dateFormat) : DATA.registerValidTo;

  registerValidFrom = registerValidFrom ? moment(registerValidFrom, dateFormat) : registerValidFrom;
  registerValidTo = registerValidTo ? moment(registerValidTo, dateFormat) : registerValidTo;

  console.log('registerValidFrom, registerValidTo....', registerValidFrom, registerValidTo)
  let isLong = form.getFieldValue('registerValidLongTerm');
  if (isLong == 'undefined') {
    isLong = DATA.registerValidLongTerm
  }


  // 图片处理
  let openAccountLicenceCopyFileListUrl = []
  DATA.openAccountLicenceCopyFileList&&DATA.openAccountLicenceCopyFileList.map(item => {
    openAccountLicenceCopyFileListUrl.push(item.url)
  })

  let certificateOfBusinessPlaceFileListUrl = []
  DATA.certificateOfBusinessPlaceFileList&&DATA.certificateOfBusinessPlaceFileList.map(item => {
    certificateOfBusinessPlaceFileListUrl.push(item.url)
  })

  let keyAchievementsInRecentTwoYearsFileListUrl = []
  DATA.keyAchievementsInRecentTwoYearsFileList&&DATA.keyAchievementsInRecentTwoYearsFileList.map(item => {
    keyAchievementsInRecentTwoYearsFileListUrl.push(item.url)
  })

  let financialReportOfRecentYearFileListUrl = []
  DATA.financialReportOfRecentYearFileList&&DATA.financialReportOfRecentYearFileList.map(item => {
    financialReportOfRecentYearFileListUrl.push(item.url)
  })

  let certificateOfPaidInRegisteredCapitalFileListUrl = []
  DATA.certificateOfPaidInRegisteredCapitalFileList&&DATA.certificateOfPaidInRegisteredCapitalFileList.map(item => {
    certificateOfPaidInRegisteredCapitalFileListUrl.push(item.url)
  })

  let noMajorIllegalRecordInRecentThreeYearsFileListUrl = []
  DATA.noMajorIllegalRecordInRecentThreeYearsFileList&&DATA.noMajorIllegalRecordInRecentThreeYearsFileList.map(item => {
    noMajorIllegalRecordInRecentThreeYearsFileListUrl.push(item.url)
  })

  let applicationFileListUrl = []
  DATA.applicationFileList&&DATA.applicationFileList.map(item => {
    applicationFileListUrl.push(item.url)
  })

  let otherQualificationsFileListUrl = []
  DATA.otherQualificationsFileList&&DATA.otherQualificationsFileList.map(item => {
    otherQualificationsFileListUrl.push(item.url)
  })

  return (
    <div className="userInfo" style={{ "padding": 0 }}>
      {DATA ? (
        <div className="userInfo_header">
          <div className="userInfo_title">
            认证为
            <span>{DATA.name}</span>
            <span className="flag">{DATA.identityTypeName}</span>
          </div>
          <p>提交时间：{moment(data.data.createDate).format('YYYY-MM-DD HH:mm:ss')}</p>
          <div className="userInfo_right">
            <span>{DATA.auditStatus == -1 ? '待完善' : DATA.auditStatus == 10 ? '已通过' : DATA.auditStatus == 20 ? '未通过' : '审核中'}</span>
            <p>
              <Link to={'/app/crm/enterprise_auth/record/' + data.code + '/' + 1} >查看审核记录</Link>
            </p>
          </div>
        </div>
      ) : null}



      <div className="userInfo_body">
        {DATA ? (
          <>
            <div>
              <h2><i></i>  {TITLE ? TITLE : '企业'}信息</h2>
              {/* 运输方 -- 个人 */}
              {DATA.identityTypeCode == 202 ? (
                  <>
                    <ul>
                      {enterpriseInfo_202.map(item => {
                        if (enterpriseMsg.auditStatus == 0 && item.isEdit) {
                          if (item.formItemType == 'Input') {
                            return <li key={item.field}>
                              <Form.Item label={item.label}>
                                {getFieldDecorator(item.field, {
                                  rules: [{
                                    required: !item.noRequire, message: '请输入',
                                  }],
                                  initialValue: enterpriseMsg[item.field]
                                })(
                                    <Input
                                        placeholder="请输入"
                                    />
                                )}
                              </Form.Item>
                            </li>
                          } else {
                            return <li key={item.field}>
                              <Form.Item label={item.label}>
                                {getFieldDecorator(item.field, {
                                  rules: [{
                                    required: true, message: '请输入',
                                  }],
                                  initialValue: [registerValidFrom, registerValidTo]
                                })(
                                    <RangePicker />
                                )}
                              </Form.Item>
                            </li>
                          }
                        }
                        return <li key={item.field}><span>{item.label}</span><span className="span">{enterpriseMsg[item.field]}</span></li>;
                      })}

                      <li className="body_img"><span>证件照片</span>
                        <img src={DATA.frontIdCard && DATA.frontIdCard.url} alt="" onClick={() => props.cImage([DATA.frontIdCard && DATA.frontIdCard.url, DATA.backIdCard && DATA.backIdCard.url], 0)} />
                        <img src={DATA.backIdCard && DATA.backIdCard.url} alt="" onClick={() => props.cImage([DATA.frontIdCard && DATA.frontIdCard.url, DATA.backIdCard && DATA.backIdCard.url], 1)} />
                      </li>
                      <li className="body_img"><span>驾驶证件</span>
                        {(DATA.driverLicense && DATA.driverLicense.url) ? <img src={DATA.driverLicense && DATA.driverLicense.url} alt="" onClick={() => props.cImage([DATA.driverLicense.url, DATA.driverLicenseBack.url], 0)} /> : '-'}
                        {(DATA.driverLicenseBack && DATA.driverLicenseBack.url) ? <img src={DATA.driverLicenseBack && DATA.driverLicenseBack.url} alt="" onClick={() => props.cImage([DATA.driverLicense.url, DATA.driverLicenseBack.url], 1)} /> : ''}
                      </li>
                    </ul>
                  </>
              ) : null}
              {/* 建设施工、运输方、 */}
              {DATA.identityTypeCode == 1 || DATA.identityTypeCode == 2 || DATA.identityTypeCode == 3 || DATA.identityTypeCode == 5 || DATA.identityTypeCode == 8 ? (<ul>
                {enterpriseInfoData && enterpriseInfoData.map(item => {
                  if (enterpriseMsg.auditStatus == 0 && item.isEdit) {
                    if (item.formItemType == 'Input') {
                      return <li key={item.field}>
                        <Form.Item label={item.label}>
                          {getFieldDecorator(item.field, {
                            rules: [{
                              required: true, message: '请输入',
                            }],
                            initialValue: enterpriseMsg[item.field]
                          })(
                            <Input placeholder="请输入" />
                          )}
                        </Form.Item>
                      </li>
                    } else if (item.formItemType == 'DatePicker') {
                      return <Form.Item label={item.label} key={item.field}>

                        <Form.Item label="长期">
                          {getFieldDecorator('registerValidLongTerm', {
                            initialValue: DATA.registerValidLongTerm,
                            valuePropName: 'checked'
                          })(
                            <Switch />
                          )}
                        </Form.Item>
                        {
                          isLong ? '' :
                            <Form.Item>
                              {getFieldDecorator(item.field, {
                                rules: [{
                                  required: true, message: '请输入',
                                }],
                                initialValue: [registerValidFrom, registerValidTo]
                              })(
                                <RangePicker format={dateFormat} />
                              )}
                            </Form.Item>
                        }
                      </Form.Item>
                    } else if (item.formItemType == 'Select') {
                      return <li key={item.field}>
                        <Form.Item label={item.label}>
                          {getFieldDecorator(item.field, {
                            rules: [{
                              required: true, message: '请输入',
                            }],
                            initialValue: enterpriseMsg[item.field]
                          })(
                            <Select>
                              {
                                data.companyTypeArr && data.companyTypeArr.map(cp => {
                                  return <Option key={cp} value={cp}>{cp}</Option>
                                })
                              }
                            </Select>
                          )}
                        </Form.Item>
                      </li>
                    } else {
                      return <Form.Item label={item.label} key={item.field}>
                        <Form.Item>
                          {getFieldDecorator(item.field, {
                            rules: [{
                              required: true, message: '请输入',
                            }],
                            initialValue: [DATA['provinceId'], DATA['cityId'], DATA['areasId']]
                          })(
                            <Cascader fieldNames={{ label: 'name', value: 'id', children: 'subAreas' }} disabled options={data.cascaderOptions} placeholder="请选择" />
                          )}
                        </Form.Item>
                        <Form.Item>
                          {getFieldDecorator('businessDetailAddress', {
                            initialValue: DATA.businessDetailAddress,
                            rules: [{
                              required: true, message: '请输入',
                            }],
                          })(
                            <Input placeholder="请输入" maxLength={50} />
                          )}
                        </Form.Item>
                      </Form.Item>
                    }
                  }
                  if (item.field == 'workAddress') {
                    return <li key={item.field}><span>{item.label}</span><span className="span">{DATA['provinceName']}{DATA['cityName']}{DATA['areasName']}{DATA['businessDetailAddress']}</span></li>
                  } else {
                    return <li key={item.field}><span>{item.label}</span><span className="span">{enterpriseMsg[item.field]}</span></li>;
                  }
                })}
                <li className="body_img"><span style={{ width: '75px' }}>营业执照</span><img src={DATA.registerCardAttachment && DATA.registerCardAttachment.url} alt="" onClick={() => props.cImage([DATA.registerCardAttachment && DATA.registerCardAttachment.url], 0)} /></li>
              </ul>) : null}
              {/* 建设施工 -- 授权委托 */}
              {DATA.identityTypeCode == 101 ? (
                <ul>
                  {enterpriseInfo_101.map(item => {
                    if (enterpriseMsg.auditStatus == 0 && item.isEdit) {
                      if (item.formItemType == 'Input') {
                        return <li key={item.field}>
                          <Form.Item label={item.label}>
                            {getFieldDecorator(item.field, {
                              rules: [{
                                required: true, message: '请输入',
                              }],
                              initialValue: enterpriseMsg[item.field]
                            })(
                              <Input
                                placeholder="请输入"
                              />
                            )}
                          </Form.Item>
                        </li>
                      } else {
                        return <li key={item.field}>
                          <Form.Item label={item.label}>
                            {getFieldDecorator(item.field, {
                              rules: [{
                                required: true, message: '请输入',
                              }],
                              initialValue: [registerValidFrom, registerValidTo]
                            })(
                              <RangePicker />
                            )}
                          </Form.Item>
                        </li>
                      }
                    }
                    return <li key={item.field}><span>{item.label}</span><span className="span">{enterpriseMsg[item.field]}</span></li>;
                  })}
                  <li className="body_img"><span>授权文件照片</span>{DATA.authenticationAttachmentList && DATA.authenticationAttachmentList.map((item, index) => <img key={index} src={item.url} alt="" onClick={() => props.cImage(DATA.authenticationAttachmentList, index, true)} />)}</li>
                </ul>
              ) : null}
              {/* 建设施工 -- 无资质 */}
              {DATA.identityTypeCode == 201 ? (
                <ul>
                  {enterpriseInfo_201.map(item => {
                    if (enterpriseMsg.auditStatus == 0 && item.isEdit) {
                      if (item.formItemType == 'Input') {
                        return <li key={item.field}>
                          <Form.Item label={item.label}>
                            {getFieldDecorator(item.field, {
                              rules: [{
                                required: true, message: '请输入',
                              }],
                              initialValue: enterpriseMsg[item.field]
                            })(
                              <Input
                                placeholder="请输入"
                              />
                            )}
                          </Form.Item>
                        </li>
                      } else {
                        return <li key={item.field}>
                          <Form.Item label={item.label}>
                            {getFieldDecorator(item.field, {
                              rules: [{
                                required: true, message: '请输入',
                              }],
                              initialValue: [registerValidFrom, registerValidTo]
                            })(
                              <RangePicker />
                            )}
                          </Form.Item>
                        </li>
                      }
                    }
                    return <li key={item.field}><span>{item.label}</span><span className="span">{enterpriseMsg[item.field]}</span></li>;
                  })}
                  <li className="body_img"><span>证件照片</span>
                    {
                      DATA.legalIdCardType == 1 ? (
                        <>
                          <img src={DATA.frontIdCard && DATA.frontIdCard.url} alt="" onClick={() => props.cImage([DATA.frontIdCard && DATA.frontIdCard.url, DATA.backIdCard && DATA.backIdCard.url], 0)} />
                          <img src={DATA.backIdCard && DATA.backIdCard.url} alt="" onClick={() => props.cImage([DATA.frontIdCard && DATA.frontIdCard.url, DATA.backIdCard && DATA.backIdCard.url], 1)} />
                        </>
                      ) : (
                        <img src={DATA.passportCard && DATA.passportCard.url} alt="" onClick={() => props.cImage([DATA.passportCard && DATA.passportCard.url], 0)} />
                      )
                    }

                  </li>
                </ul>
              ) : null}
              {/* 企业出租方 -- 授权委托 */}
              {DATA.identityTypeCode == 105 || DATA.identityTypeCode == 108 ? (
                <ul>
                  {enterpriseInfo_105.map(item => {
                    if (enterpriseMsg.auditStatus == 0 && item.isEdit) {
                      if (item.formItemType == 'Input') {
                        return <li key={item.field}>
                          <Form.Item label={item.label}>
                            {getFieldDecorator(item.field, {
                              rules: [{
                                required: true, message: '请输入',
                              }],
                              initialValue: enterpriseMsg[item.field]
                            })(
                              <Input
                                placeholder="请输入"
                              />
                            )}
                          </Form.Item>
                        </li>
                      } else {
                        return <li key={item.field}>
                          <Form.Item label={item.label}>
                            {getFieldDecorator(item.field, {
                              rules: [{
                                required: true, message: '请输入',
                              }],
                              initialValue: [registerValidFrom, registerValidTo]
                            })(
                              <RangePicker />
                            )}
                          </Form.Item>
                        </li>
                      }
                    }
                    return <li key={item.field}><span>{item.label}</span><span className="span">{enterpriseMsg[item.field]}</span></li>;
                  })}
                  <li className="body_img"><span>授权文件照片</span>{DATA.authenticationAttachmentList && DATA.authenticationAttachmentList.map((item, index) => <img key={index} src={item.url} alt="" onClick={() => props.cImage(DATA.authenticationAttachmentList, index, true)} />)}</li>
                </ul>
              ) : null}
              {/* 企业出租方 -- 个人 */}
              {DATA.identityTypeCode == 205 ? (
                <>
                  <ul>
                    {enterpriseInfo_205.map(item => {
                      if (enterpriseMsg.auditStatus == 0 && item.isEdit) {
                        if (item.formItemType == 'Input') {
                          return <li key={item.field}>
                            <Form.Item label={item.label}>
                              {getFieldDecorator(item.field, {
                                rules: [{
                                  required: !item.noRequire, message: '请输入',
                                }],
                                initialValue: enterpriseMsg[item.field]
                              })(
                                <Input
                                  placeholder="请输入"
                                />
                              )}
                            </Form.Item>
                          </li>
                        } else {
                          return <li key={item.field}>
                            <Form.Item label={item.label}>
                              {getFieldDecorator(item.field, {
                                rules: [{
                                  required: true, message: '请输入',
                                }],
                                initialValue: [registerValidFrom, registerValidTo]
                              })(
                                <RangePicker />
                              )}
                            </Form.Item>
                          </li>
                        }
                      }
                      return <li key={item.field}><span>{item.label}</span><span className="span">{enterpriseMsg[item.field]}</span></li>;
                    })}

                    <li className="body_img"><span>证件照片</span>
                      <img src={DATA.frontIdCard && DATA.frontIdCard.url} alt="" onClick={() => props.cImage([DATA.frontIdCard && DATA.frontIdCard.url, DATA.backIdCard && DATA.backIdCard.url], 0)} />
                      <img src={DATA.backIdCard && DATA.backIdCard.url} alt="" onClick={() => props.cImage([DATA.frontIdCard && DATA.frontIdCard.url, DATA.backIdCard && DATA.backIdCard.url], 1)} />
                    </li>
                    <li className="body_img"><span>驾驶证件</span>
                      {(DATA.driverLicense && DATA.driverLicense.url) ? <img src={DATA.driverLicense && DATA.driverLicense.url} alt="" onClick={() => props.cImage([DATA.driverLicense && DATA.driverLicense.url], 0)} /> : "-"}

                    </li>
                    <li className="body_img"><span>特殊从业资格证</span>{DATA.specialQualificationCertificate && DATA.specialQualificationCertificate.map((item, index) => <img key={index} src={item.url} alt="" onClick={() => props.cImage(DATA.specialQualificationCertificate, index, true)} />)}</li>
                  </ul>
                  <h2><i></i>  产权信息</h2>
                  <ul>
                    <li className="body_img"><span>设备产权信息</span>{DATA.equipmentPropertyInformation && DATA.equipmentPropertyInformation.map((item, index) => <img key={index} src={item.url} alt="" onClick={() => props.cImage(DATA.equipmentPropertyInformation, index, true)} />)}</li>
                  </ul>
                </>
              ) : null}
            </div>

            {DATA.identityTypeCode == 2 ||  DATA.identityTypeCode == 202 ?
              (<div>
                <h2><i></i>  运输资质</h2>
                <ul>
                  <li className="body_img"><span>道路运输经营许可证</span><img src={transportOperationPermit} alt="" onClick={() => props.cImage([transportOperationPermit], 0)} /></li>
                </ul>
              </div>) : null
            }

            {
              DATA.identityTypeCode == 3 ? (
                  <>
                    <div>
                      <h2><i></i>  法人信息</h2>
                      <ul>
                        <li><span>法人姓名：</span><span className="span">{DATA.legalManName}</span></li>
                        <li><span>法人身份证号：</span><span className="span">{DATA.legalIdCardNumber}</span></li>
                        <li className="body_img">
                          <span>法人身份证：</span>
                          {DATA.legalIdentityCardFrontFileList && DATA.legalIdentityCardFrontFileList.map((item, index) => <img key={index} src={item.url} alt="" onClick={() => props.cImage([DATA.legalIdentityCardFrontFileList[0].url], index)} />)}
                          {DATA.legalIdentityCardBackFileList && DATA.legalIdentityCardBackFileList.map((item, index) => <img key={index} src={item.url} alt="" onClick={() => props.cImage([DATA.legalIdentityCardBackFileList[0].url], index)} />)}
                        </li>
                      </ul>
                    </div>

                    <div>
                      <h2><i></i>  开户信息</h2>
                      <ul>
                        <li><span>银行开户许可证编号：</span><span className="span">{DATA.openAccountLicenceNo}</span></li>
                        <li><span>银行账户号码：</span><span className="span">{DATA.bankAccount}</span></li>
                        <li><span>银行账户类型：</span><span className="span">{DATA.bankAccountTypeName}</span></li>
                        <li><span>开户总行：</span><span className="span">{DATA.bankName}</span></li>
                        <li className="body_img">
                          <span>银行开户许可证复印件：</span>
                          {DATA.openAccountLicenceCopyFileList && DATA.openAccountLicenceCopyFileList.map((item, index) => <img key={index} src={item.url} alt="" onClick={() => props.cImage(openAccountLicenceCopyFileListUrl, index)} />)}
                        </li>
                      </ul>
                    </div>

                    <div>
                      <h2><i></i>  其他信息</h2>
                      <ul>
                        <li className="body_img">
                          <span>经营场所证明：</span>
                          {DATA.certificateOfBusinessPlaceFileList && DATA.certificateOfBusinessPlaceFileList.map((item, index) => <img key={index} src={item.url} alt="" onClick={() => props.cImage(certificateOfBusinessPlaceFileListUrl, index)} />)}
                        </li>
                        <li className="body_img">
                          <span>近一年财务报告/报表：</span>
                          {DATA.financialReportOfRecentYearFileList && DATA.financialReportOfRecentYearFileList.map((item, index) => <img key={index} src={item.url} alt="" onClick={() => props.cImage(financialReportOfRecentYearFileListUrl, index)} />)}
                        </li>
                        <li className="body_img">
                          <span>近2年重点业绩：</span>
                          {DATA.keyAchievementsInRecentTwoYearsFileList && DATA.keyAchievementsInRecentTwoYearsFileList.map((item, index) => <img key={index} src={item.url} alt="" onClick={() => props.cImage(keyAchievementsInRecentTwoYearsFileListUrl, index)} />)}
                        </li>
                        <li className="body_img">
                          <span>注册资本实缴证明：</span>
                          {DATA.certificateOfPaidInRegisteredCapitalFileList && DATA.certificateOfPaidInRegisteredCapitalFileList.map((item, index) => <img key={index} src={item.url} alt="" onClick={() => props.cImage(certificateOfPaidInRegisteredCapitalFileListUrl, index)} />)}
                        </li>
                        <li className="body_img">
                          <span>近3年无重大违法记录：</span>
                          {DATA.noMajorIllegalRecordInRecentThreeYearsFileList && DATA.noMajorIllegalRecordInRecentThreeYearsFileList.map((item, index) => <img key={index} src={item.url} alt="" onClick={() => props.cImage(noMajorIllegalRecordInRecentThreeYearsFileListUrl, index)} />)}
                        </li>
                        <li className="body_word " style={{display: 'flex'}}>
                          <span style={{marginRight: 0}}>申请书/承诺函：</span>

                          {DATA.applicationFileList && DATA.applicationFileList.map((item, index) => {
                            return(
                                <div className='styWord'>
                                  <a href={item.url}>{item.name}</a>
                                  {/*<div className='styName'>{item.name}</div>*/}
                                </div>
                            )
                          })}
                        </li>
                        <li className="body_img">
                          <span>其他行业资质：</span>
                          {DATA.otherQualificationsFileList && DATA.otherQualificationsFileList.map((item, index) => <img key={index} src={item.url} alt="" onClick={() => props.cImage(otherQualificationsFileListUrl, index)} />)}
                        </li>
                      </ul>
                    </div>

                  </>
              ) : null
            }
            {DATA.identityTypeCode == 3 ?
              (<div>
                <h2><i></i>  经营信息</h2>
                <ul>
                  <li><span>主营品类</span><span className="span">{DATA.mainBusinessName}</span></li>
                  <li><span>税率</span><span className="span">{DATA.taxRate ? (DATA.taxRate).replaceAll("/","%,")+"%" : '-'}</span></li>
                  <li className="body_img">
                    <span>产品质检报告</span>
                    {qualityReport && qualityReport.map((item, index) => <img key={index} src={item} alt="" onClick={() => props.cImage(qualityReport, index)} />)}
                  </li>
                </ul>
              </div>) : null
            }

            {/* 技术服务 -- 个人 */}
            {DATA.identityTypeCode == 208 ? (
              <>
                <ul>
                  {enterpriseInfo_202.map(item => {
                    if (enterpriseMsg.auditStatus == 0 && item.isEdit) {
                      if (item.formItemType == 'Input') {
                        return <li key={item.field}>
                          <Form.Item label={item.label}>
                            {getFieldDecorator(item.field, {
                              rules: [{
                                required: !item.noRequire, message: '请输入',
                              }],
                              initialValue: enterpriseMsg[item.field]
                            })(
                              <Input
                                placeholder="请输入"
                              />
                            )}
                          </Form.Item>
                        </li>
                      } else {
                        return <li key={item.field}>
                          <Form.Item label={item.label}>
                            {getFieldDecorator(item.field, {
                              rules: [{
                                required: true, message: '请输入',
                              }],
                              initialValue: [registerValidFrom, registerValidTo]
                            })(
                              <RangePicker />
                            )}
                          </Form.Item>
                        </li>
                      }
                    }
                    return <li key={item.field}><span>{item.label}</span><span className="span">{enterpriseMsg[item.field]}</span></li>;
                  })}

                  <li className="body_img"><span>身份证正反面</span>
                    <img src={DATA.frontIdCard && DATA.frontIdCard.url} alt="" onClick={() => props.cImage([DATA.frontIdCard && DATA.frontIdCard.url, DATA.backIdCard && DATA.backIdCard.url], 0)} />
                    <img src={DATA.backIdCard && DATA.backIdCard.url} alt="" onClick={() => props.cImage([DATA.frontIdCard && DATA.frontIdCard.url, DATA.backIdCard && DATA.backIdCard.url], 1)} />
                  </li>
                </ul>
              </>
            ) : null}
            {/*  运输方展示--营运信息 */}
            {DATA.identityTypeCode == 2 || DATA.identityTypeCode == 202 ? (<div>
              <h2><i></i>  营运信息</h2>
              <ul>
                <li className="body_img"><span style={{ width: '90px' }}>营运许可证</span>{operatingLicense ? <img src={operatingLicense} alt="" onClick={() => props.cImage([operatingLicense])} /> : "-"}</li>
              </ul>
              <ul>
                <li className="body_img"><span style={{ width: '90px' }}>其他许可证</span>
                  {(DATA.otherTransportLicense && DATA.otherTransportLicense.map((item, index) => <img key={index} src={item.url} alt="" onClick={() => props.cImage(DATA.otherTransportLicense, index, true)} />)) || "-"}
                </li>
              </ul>
            </div>) : null}

            {DATA.identityTypeCode == 2 || DATA.identityTypeCode == 202 ? (<div>
              <h2><i></i>  运力信息</h2>
              <ul>
                {capacityInfoName && capacityInfoName.map(item => {
                  return (
                    <li key={item.field}><span>{item.label}</span><span className="span">{(tcAuditDetailInfo && tcAuditDetailInfo[item.field]) || "-"}</span></li>
                  )
                })}
                <li className="body_img"><span style={{ width: '75px' }}>相关图片</span>{tcAuditDetailInfo && tcAuditDetailInfo.photos && tcAuditDetailInfo.photos.length ? tcAuditDetailInfo.photos.map((item, index) =>
                  (<img src={item.url} key={index} alt="" onClick={() => props.cImage(tcAuditDetailInfo.photos, index, true)} />)
                ) : '-'}
                </li>
              </ul>
            </div>) : null}

            {DATA.identityTypeCode == 5 || DATA.identityTypeCode == 105 || DATA.identityTypeCode == 205 ? (<div>
              <h2><i></i> 经营信息</h2>
              <ul>
                {manageInfoDetail && manageInfoDetail.map(item => {
                  return (
                    <li key={item.field}><span>{item.label}</span><span className="span">{(inforMaticaDetailInfo && inforMaticaDetailInfo[item.field]) || "-"}</span></li>
                  )
                })}
                <li className="body_img"><span style={{ width: '75px' }}>相关图片</span>{inforMaticaDetailInfo && inforMaticaDetailInfo.urls ? inforMaticaDetailInfo.urls.map((item, index) =>
                  (<img src={item} key={index} alt="" onClick={() => props.cImage(inforMaticaDetailInfo.urls, index, true)} />)
                ) : '-'}
                </li>
              </ul>
            </div>) : null}

            {DATA.identityTypeCode == 8 || DATA.identityTypeCode == 108 || DATA.identityTypeCode == 208 ? (<div>
              <h2><i></i>服务信息</h2>
              <ul>
                {serviceInfoDetail.map(item => {
                  return (
                    <li key={item.field}><span>{item.label}</span><span className="span">{(serviceDataInfo && serviceDataInfo[item.field]) || "-"}</span></li>
                  )
                })}
                <li className="body_img">
                  <span>资质证书</span>
                  {serviceDataInfo.imageList && serviceDataInfo.imageList.map((item, index) => <img key={index} src={item.url} alt="" onClick={() => props.cImage(serviceDataInfo.imageList, index, true)} />)}
                </li>
              </ul>
            </div>) : null}


          </>
        ) : null
        }
      </div>
    </div>
  )
})
