// 常用正则集合，必须保证正则正确
export default {
  phoneNew: /^(13[0-9]|14[56789]|15[0-3,5-9]|166|17[0135678]|18[0-9]|19[89])\s\d{4}\s\d{4}$/,
  // phoneNew:/^1\s*[123456789]\s(\d\s*){9}$/,
  // 手机号
  // phone: /^1((3\d)|(4[579])|(5([0-3]|[5-9]))|66|7(3|[5-8])|(8\d)|(9[89]))\d{8}$/,
  phone: /^1[\d]{10}$/,
  // 身份证号
  idCard: /^[1-9]\d{7}((0\d)|(1[0-2]))(([012]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[12]\d{3}((0[1-9])|(1[0-2]))(([012]\d)|3[0-1])\d{3}([\d]|X)$/,
  // 固定电话
  // telephone: /^0\d{9,11}$/
  telephone: /^\d{0,50}$/,
  socialCreditCode: /(^[\da-zA-Z\-]{18}$)|(^[\da-zA-Z\-]{15}$)|(^[\da-zA-Z\-]{20}$)/,
  website: /^((ht|f)tps?):\/\/[\w\-]+(\.[\w\-]+)+([\w\-.,@?^=%&:\/~+#]*[\w\-@?^=%&\/~+#])?$/,
  password: /^[\da-zA-Z]{8,15}$/,
  zipCode: /^\d{6}$/, //邮编
  characterOrNum: /^[A-Za-z0-9]*$/,
  businessLicense: /^[\u4e00-\u9fa5a-zA-Z\d\(\)（）\-\.]+$/,
  personName: /^[\u4e00-\u9fa5a-zA-Z\d\(\)\-\.]+$/
};

export function capitalized(word = "") {
  // 所有单词的首字母转换为大写其余小写，如 aaS bEb cEF => Aas Beb Cef
  return word.replace(
    /\b\w*\b/g,
    ch => ch.substr(0, 1).toUpperCase() + ch.substr(1).toLowerCase()
  );
}
