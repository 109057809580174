import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import EnterpriseAuth from "./enterpriseAuth";
import DriveAuth from "./driveAuth";
import VehicleAuth from "./vehicleAuth";
import OperatorAuth from "./operatorAuth";
import "./userInfo.less";

export default withRouter(function UserInfo({ data, props }) {
  let DATA;
  let DRIVER_DATA;
  let VEHICLE_DATA;
  let OPERATOR_DATA;

  if (data.data && data.data.auditFormDetail) {
    // 企业信息
    DATA = data.data.auditFormDetail.mainInfo;
  } else if (data.data && data.data.driverInfo) {
    // 司机信息
    DRIVER_DATA = data.data;
  } else if (data.data && data.data.data.plate) {
    // 车辆信息
    VEHICLE_DATA = data.data.data;
  } else if (data.data && data.data.data.operatorName) {
    // 操作员
    OPERATOR_DATA = data.data.data;
  }

  return (
    <>
      {DATA ? <EnterpriseAuth data={data} props={props} /> : null}
      {DRIVER_DATA ? <DriveAuth data={data} props={props} /> : null}
      {VEHICLE_DATA ? <VehicleAuth data={data} props={props} /> : null}
      {OPERATOR_DATA ? <OperatorAuth data={data} props={props} /> : null}
    </>
  );
});
