import React, { Component } from 'react';
import { Form, Select, Input, Button, DatePicker } from 'antd';
import moment from 'moment';
import { API } from '@/http/api';
import http from '@/http';
import './index.less'

const { Option } = Select;
const { RangePicker } = DatePicker;

class SearchForm extends Component{
  constructor(props){
    super(props)
    this.state = {
      list: []
    }
  }

  componentDidMount(){
    this.getLists()
  }

   //查询操作模块
   getLists() {
    const config = {
        api: API.getModuleList,
    };
    http(config).then(res => {
      if(res.success){
        this.setState({
          list: res.data
        })
      }
    })
  }

  reset = () => {
    this.props.form.resetFields()
    let result = {
      pageNo: 1,
      startDate: '',
      endDate: '',
      module: '',
      result: ''
    }
    this.props.reset(result)
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const { list } = this.state
    return (
      <Form layout='inline'>
        <Form.Item label="操作模块">
          {getFieldDecorator('module', {
            rules: [{ required: false, message: 'Please select your country!' }],
          })(
            <Select allowClear placeholder="全部" style={{width: '100px'}}>
              {
                list.map((item) => {
                  return <Option value={item.value}>{item.name}</Option>
                })
              }
            </Select>,
          )}
        </Form.Item>
        <Form.Item label="操作结果">
          {getFieldDecorator('result', {
            rules: [{ required: false, message: 'Please select your country!' }],
          })(
            <Select allowClear placeholder="全部" style={{width: '100px'}}>
              <Option value="0">成功</Option>
              <Option value="1">失败</Option>
              <Option value="2">异常</Option>
            </Select>,
          )}
        </Form.Item>
        <Form.Item label="操作时间">
          {getFieldDecorator('time', {
            rules: [{ required: false, message: 'Please select your country!' }],
          })(
            <RangePicker
              style={{width: '280px'}}
              // showTime={{ format: 'HH:mm' }}
              format="YYYY-MM-DD"
            />
          )}
        </Form.Item>
        <Form.Item label="">
          {getFieldDecorator('keyword', {
            rules: [{ required: false, message: 'Please select your country!' }],
          })(
            <Input
              style={{width: '250px'}}
              placeholder='昵称/手机号/操作动作/操作IP/日志详情'
            />
          )}
        </Form.Item>
        <Form.Item label="">
          <Button type='primary' onClick={this.props.onSearch}>搜索</Button>
        </Form.Item>
        <Form.Item label="">
          <a className='reset' onClick={this.reset}>重置</a>
        </Form.Item>
      </Form>
    );
  }
}

const onValuesChanges = (props, changedValues, allValues) => {
  let result = {...allValues,pageNo: 1}
  if(allValues.time && allValues.time.length > 0){
    result.startDate = moment(allValues.time[0]).format('YYYY-MM-DD')
    result.endDate = moment(allValues.time[1]).format('YYYY-MM-DD')
  } else {
    result.startDate = ''
    result.endDate = ''
  }
  delete result['time']
  for(let key in result){
    if(result[key] == undefined){
      result[key] = ''
    }
  }
  props.onValuesChange(result)
}

export default Form.create({ name: "OperateLog", onValuesChange: onValuesChanges })(SearchForm);
