import React, { Component } from "react";
import { Layout, Breadcrumb, Button, Form, Checkbox, Input } from "antd";
import Select from "CMPT/common/Select";
import { Link } from "react-router-dom";
import { API } from "@/http/api";
import ChannelCheckboxGroup from "./ChannelCheckboxGroup";
import http from "@/http";
import { FormatSearch } from "CMPT/common.js";
import Editor from "CMPT/common/widget/Editor";
import "./IssueAdd.less";
 
const { Content } = Layout;
const { TextArea } = Input;
class IssueAdd extends Component {
  state = {
    optionChannelValue: '', // 问题 可以选择的范围
    initChannelValue: '', // 问题 已经选中的值
    type: null,
    messageContents: null,
    catalogCode: undefined,
    catalogList: [],
    topFlag: 0,
    encode: undefined, // 编辑时需要 该问题的encode
    catalogType: null
  };

  handleChangeStatus = e => {
    this.setState({
      catalogCode: e
    }, () => this.updageChanelCheckbox());

  };

  setOptionAndCheckOnly(myOptionChannelValue) {
    let hasSetDefaultOne = false;// 是否为唯一的渠道，默认选中
    if (myOptionChannelValue) {
      // 新增情况下，需要在类目只有一个渠道时，默认选中其所属类目的渠道
      let optionArray = myOptionChannelValue.split(',');
      if (optionArray && optionArray.length == 1) {
        // 需要默认设置 唯一的渠道 被选中
        this.props.form.setFieldsValue({ channel: myOptionChannelValue });
        this.setState({
          optionChannelValue: myOptionChannelValue,
          initChannelValue: myOptionChannelValue
        });
        hasSetDefaultOne = true;
      }
    }


    // 有2个及以上数量的渠道范围. 或者 编辑的情况下
    if (!hasSetDefaultOne) {
      this.setState({
        optionChannelValue: myOptionChannelValue
      });
    }
 
  }

  updageChanelCheckbox() {
    let { catalogCode, catalogList } = this.state;
    if (!catalogCode) {
      // 新增的情况下，没有 catalogCode
      return;
    }


    for (let i = 0, len = catalogList.length; i < len; i++) {
      if (catalogCode == catalogList[i].value) {
        this.setOptionAndCheckOnly(catalogList[i].channel);
        return;
      }
    }

  }

  getCatalogList() {
    const config = {
      api: API.helpCatalogList,
      data: {
        catalogType: this.state.catalogType
      }
    };
    http(config).then(res => {
      if (res.success) {
        let data = res.data.map(item => {
          item.name = item.catalogName;
          item.value = item.id;
          return item;
        });
        this.setState({
          catalogList: data
        }, () => this.afterCatalogInit());
      }
    });
  }

  afterCatalogInit() {
    if (!this.state.encode) {
      // 新增才初始化  渠道按钮
      this.updageChanelCheckbox();
    } else {
      this.getDetails();
    }
  }


  onchanggeCheckbox(e) {
    if (e.target.checked) {
      this.setState({
        topFlag: 1
      });
    } else {
      this.setState({
        topFlag: 0
      });
    }
  }

  addSubmit(values) {
    let data = {
      ...values,
      topFlag: this.state.topFlag
    };
    const config = {
      api: API.helpIssueAdd,
      successTip: "添加成功！",
      data: data
    };
    http(config).then(res => {
      if (res.success) {
        this.props.history.push("/app/crm/helphandle/issue");
      }
    });
  }
  undateSubmit(values) {
    let data = {
      ...values,
      topFlag: this.state.topFlag,
      encode: this.state.encode
    };
    const config = {
      api: API.helpUpdate,
      successTip: "编辑成功！",
      data: data
    };
    http(config).then(res => {
      if (res.success) {
        this.props.history.push("/app/crm/helphandle/issue");
      }
    });
  }
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        values.messageContents = this.state.messageContents;
        if (this.state.encode) {
          this.undateSubmit(values);
        } else {
          this.addSubmit(values);
        }
      }
    });
  };
  getDetails() {
    const config = {
      api: API.helpIssueDetail,
      data: {
        code: this.state.encode
      }
    };
    http(config).then(res => {
      if (res.success) {

        this.props.form.setFieldsValue({
          messageTitle: res.data.messageTitle,
          messageContents: res.data.messageContents,
          channel: res.data.channel
        });

        this.setState({
          topFlag: res.data.topFlag,
          messageTitle: res.data.messageTitle,
          messageContents: res.data.messageContents,
          catalogCode: res.data.catalogId,
          catalogName: res.data.catalogName,
          initChannelValue: res.data.channel
        }, () => this.updageChanelCheckbox());

      }
    });
  }

  htmlChange = msgContent => {
    this.state.messageContents = msgContent;
  };


  componentDidMount() {
    // 如果是编辑，encode会有值
    let encodeParmam = undefined;
    if (JSON.stringify(this.props.match.params) != "{}") {
      encodeParmam = this.props.match.params.id
    }


    // 获取 参数catalogType
    let search = this.props.history.location.search;
    var result = FormatSearch(search);


    // 赋值
    this.setState({
      catalogType: result.catalogType,
      encode: encodeParmam // 新增为undefined. 编辑时 才会有数值
    }, () => this.getCatalogList());
  }

  checkPrice = (rule, value) => {
    if (this.state.messageContents) {
      return Promise.resolve();
    }
    return Promise.reject("请填写问题内容");
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const { optionChannelValue, initChannelValue, catalogCode } = this.state;
    const config = {
      rules: [{ required: true, message: "请选择" }],
      initialValue: catalogCode
    };

    return (
      <Content className="intention-list intention-content">
        <div className="intention-list-head flexbox">
          <Breadcrumb className="intention-list-head-bread">
            <Breadcrumb.Item>帮助中心</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/app/crm/helphandle/issue">常见问题</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {" "}
              {this.state.encode ? "编辑" : "新增"}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <Form onSubmit={this.handleSubmit}>
          <div className="intention-add-body">
            <div>
              <Form.Item label="标题">
                {getFieldDecorator("messageTitle", {
                  rules: [{ required: true, max: 50, message: "不得超过50字" }]
                })(
                  <Input
                    style={{ width: "300px" }}
                    maxLength={50}
                    placeholder="请输入"
                  />
                )}
                {/* <Checkbox onChange={(e) => { this.onchanggeCheckbox(e) }} checked={this.state.topFlag === 1 ? true : false} style={{ marginLeft: '30px' }}>置顶</Checkbox>  */}
              </Form.Item>

              <Form.Item label="目录">
                {getFieldDecorator(
                  "catalogCode",
                  config
                )(
                  this.state.catalogList ? (
                    <Select
                      style={{ width: "204px" }}
                      placeholder="请选择"
                      data={this.state.catalogList}
                      selectMethod={this.handleChangeStatus}
                    />
                  ) : null
                )}
              </Form.Item>

              {optionChannelValue && catalogCode ? <Form.Item label="渠道">
                {getFieldDecorator("channel", {
                  initialValue: initChannelValue,
                  rules: [{ required: true, message: "请设置渠道信息" }]
                })(
                  <ChannelCheckboxGroup optionValue={optionChannelValue} initialValue={initChannelValue}
                    key={(catalogCode ? catalogCode : '') + optionChannelValue ? optionChannelValue : '' + initChannelValue ? initChannelValue : ''} />
                )}
              </Form.Item> :
                <Form.Item label="渠道">
                  <span style={{ color: '#FF0000', fontSize: "12px", marginLeft: '10px' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;请先选择问题所属类目</span>
                </Form.Item>
              }


              <Form.Item label="内容">
                {getFieldDecorator("messageContents",
                  {rules: [{ validator: this.checkPrice }]
                })(
                  <Editor
                    onChange={this.htmlChange}
                    key={this.state.messageContents?'1':'0'}
                    default={this.state.messageContents}
                  />
                )}
              </Form.Item>


            </div>
          </div>
          <div className="btn-group">
            <Button type="primary" htmlType="submit">
              发布
            </Button>{" "}
            <Button
              type="link"
              onClick={() =>
                this.props.history.push("/app/crm/helphandle/issue")
              }
            >
              取消
            </Button>
          </div>
        </Form>
      </Content>
    );
  }
}

const IssueAddForm = Form.create({ name: "IssueAdd" })(IssueAdd);

export default IssueAddForm;
