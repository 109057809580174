import React, { Component } from 'react';
import { Row, Col, Icon } from 'antd'
import JobItemContract from './JobItemContract'
import JobItemBill from './JobItemBill'
import JobItemTemplate from './JobItemTemplate'
import JobItemOrder from './JobItemOrder'
import { Link} from 'react-router-dom'

class JobList extends Component {
    state = {
        typeLinkUrl : {
            0:{pathname:'/app/operate/contract',search:'type=2'},
            1:{pathname:'/app/operate/contract',search:'type=1'},
            2:{pathname:'/app/operate/finance/approval',search:'type=1'},
            3:{pathname:'/app/setting/template/contracttemplate',search:'type=1'},
            4:{pathname:'/app/setting/template/receipttemplate',search:'type=1'},
            5:{pathname:'/app/operate/serviceCenter/logistics/maintain',hash:'1'},
            6:{pathname:'/app/operate/serviceCenter/logistics/maintain',hash:'4'},
            7:{pathname:'/app/operate/serviceCenter/logistics/complaint',hash:'1'},
            8:{pathname:'/app/operate/serviceCenter/logistics/complaint',hash:'3'}
        }
    }
    // 0  份待我审批合同 1  份我发起待审批合同  2  份待我审批账单  3  份待我审批模板 4  份待我审批模板(收据) 5  个待派单工单（维修） 6 个待验收工单（维修） 7  个待派单工单（投诉） 9  个待验收工单（投诉）
    render() {
        const data = this.props.data;
        // const dataArray = data.map(item => data.length == 0 ? [] : data.length > 1 ? [data[0], data[1]] : [data[0]])
        const dataArrayHtml = data.map((_data, index) => {
            let type = _data.type;
            let tempData =_data.data ? _data.data.length >1 ? [_data.data[0],_data.data[1]] :  _data.data : [];
            let itemHtml = type == 0 || type == 1 ? tempData.map((item, _index) => <Col key={_index} className="gutter-row" span={8}> <JobItemContract {...item} /></Col>) :
                type == 2 ? tempData.map((item, _index) => <Col key={_index} className="gutter-row" span={8}> <JobItemBill {...item} /></Col>) :
                    type == 3 || type == 4 ? tempData.map((item, _index) => <Col key={_index} className="gutter-row" span={8}> <JobItemTemplate {...item} /></Col>) :
                        tempData.map((item, _index) => <Col className="gutter-row" key={_index} span={8}> <JobItemOrder {...item} /></Col>)
            let countHtml = type == 1 ? <p className="job-item-title">共{_data.count}份待我审批合同</p> :
                type == 0 ? <p className="job-item-title">共{_data.count}份我发起待审批合同</p> :
                    type == 2 ? <p className="job-item-title">共{_data.count}待我审批账单</p> :
                        type == 3 ? <p className="job-item-title">共{_data.count}份待我审批模板</p> :
                            type == 4 ? <p className="job-item-title">共{_data.count}份待我审批模板</p> :
                                type == 5 || type == 7 ? <p className="job-item-title">共{_data.count}个待派单工单</p> : <p className="job-item-title">共{_data.count}个待验收工单</p>
            return <Row type="flex" align="top" key={index} gutter={16}>
                {itemHtml}
                {
                    _data.count > 2  ?  <Col className="gutter-row" span={8} push={(2-tempData.length)*8}>
                    <Link to={this.state.typeLinkUrl[type]} className={`job-item job-item-link  link-${type}`}>
                        {countHtml}
                        <div className="yellow-arrow">

                        </div>

                        <p className="link-to-page">
                            立刻去处理
                        </p>
                    </Link>
                </Col> :null
                }

            </Row>
        })
        return (
            <div className="job-list">
                {dataArrayHtml}
            </div>
        )
    }
}

export default JobList
