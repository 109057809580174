import Enum from "@/utils/Enum";

// 认证身份
export const IdentityType = new Enum(
  // 4.26 --- 全部、建设施工、供应商、司机
  { alias: "PURCHASE", text: "建设施工", value: 1 },
  { alias: "WULIU", text: "物流公司", value: 2 },
  { alias: "SUPPLY", text: "供应商", value: 3 },
  { alias: "ZULIN", text: "租赁公司", value: 5 },
  // { alias: "DRIVER", text: "司机", value: 20 },
  // { alias: "PURCHASE", text: "采购商", value: '1' },
  // { alias: "SUPPLY", text: "供应商", value: '1,2' },
  // { alias: "GARDEN", text: "园区方", value: '1,3' },
  // { alias: "GSUPPLY", text: "供应商+园区方", value: '1,2,3' },
);

// 认证类型
export const AuthenticationTypes = new Enum(
  { alias: "FIRST ", text: "初次认证", value: 1 },
  { alias: "RECRTIFICATION ", text: "重新认证", value: 2 },
  { alias: "RESUBMIT", text: "重新提交", value: 8 },
  { alias: "UPGRADE", text: "升级认证", value: 9 },
);

// 类型筛选
export const CertType = new Enum(
  { alias: "PURCHASE ", text: "采购商", value: 0 },
  { alias: "PRELIMINARY ", text: "分步-初审", value: 1 },
  { alias: "ONESTEP", text: "一步到位", value: 2 },
  { alias: "IMPROVEINFO", text: "分步-完善供应商资料", value: 3 },
);

// 审批状态
export const AuthenticationStatus = new Enum(
  { alias: "READY", text: "待审批", value: 0 },
  { alias: "SUCCESS ", text: "审批通过", value: 1 },
  { alias: "REJECT", text: "审批驳回", value: 2 },
);

// 审批状态
export const AuditType = new Enum(
  { alias: "ADD ", text: "新增", value: 1 },
  { alias: "EDIT", text: "修改", value: 2 },
);

// 平台
export const Platform = new Enum(
  { alias: "WX_APP ", text: "小程序", value: 0 },
);

/* start:设备相关枚举  */
// 设备类型
export const DeviceType = new Enum(
    { alias: "ADD ", text: "挖掘机械", value: 1 },
    { alias: "EDIT", text: "履带式挖掘", value: 2 },
);

// 设备类型
export const DeviceRentStatus = new Enum(
    { alias: "ADD ", text: "闲置中", value: 1 },
    { alias: "EDIT", text: "修改", value: 2 },
);

// 意向单状态
//0待确认/已发送 1已送回/待修改 2已成交/已成交 3已失效/已失效 4已取消/已取消
export const IntentionStatus = new Enum(
    { alias: "TBD", text: "待确认", value: 0 },
    { alias: "SEND_BACK_DONE", text: "已送回", value: 1 },
    { alias: "EDIT", text: "已成交", value: 2 },
    { alias: "INVALID", text: "已失效", value: 3 },
    { alias: "CANCEL", text: "已取消", value: 4 }

);



// 意向单类型
export const IntentionType = new Enum(
    { alias: "EQUIPMENT", text: "出租", value: 1 },
    { alias: "DEMAND", text: "租用", value: 2 }
);

// 订单类型
export const OrderType = new Enum(
  { alias: "EQUIPMENT", text: "出租", value: 1},
  { alias: "DEMAND", text: "租用", value: 0  }
);

// 设备租赁 订单类型
export const EquipmentOrderStatus = new Enum(
  { alias: "CANCEL", text: "已取消", value: 0 },
  { alias: "DONE", text: "已成交", value: 1 },
  { alias: "WAITING_CANCEL", text: "取消中", value: 2 },
  { alias: "WAITING_PAY", text: "等待付款", value: 3 },
  { alias: "PAYING", text: "付款中", value: 4 },
  { alias: "WAITING_OK", text: "待确认", value: 5 },
  { alias: "PAY_AGAIN ", text: "重新付款", value: 6 }
);


// 订单的支付类型
export const PayType = new Enum(
  { alias: "ONLINE", text: "线上支付", value: 0 },
  { alias: "OFFLINE", text: "线下支付", value: 1 },
 
);