import {
    AJAX_TYPE
} from '../config';

// Role.Role

const prefix = 'role';

export default {
    // 角色新增
    [`${prefix}Add`]: {
        url: '/web/role/add',
        type: AJAX_TYPE.POST
    },
    // 角色删除
    [`${prefix}Del`]: {
        url: '/web/role/del/:code',
        type: AJAX_TYPE.GET
    },
    // 角色详情
    [`${prefix}Detail`]: {
        url: '/web/role/detail/:code',
        type: AJAX_TYPE.GET
    },
    // 角色编辑
    [`${prefix}Edit`]: {
        url: '/web/role/edit',
        type: AJAX_TYPE.POST
    },
    // 分页
    [`${prefix}GetList`]: {
        url: '/web/role/getList',
        type: AJAX_TYPE.POST
    },
    // 角色分配
    [`${prefix}RoleUser`]: {
        url: '/web/role/roleUser',
        type: AJAX_TYPE.POST
    },
}