import React, { Component } from "react";
import { Breadcrumb, Tabs } from "antd";
import TopBrand  from "./topBrand";
import TopGoods from "./topGoods";
import TopSpecialGoods from "./topSpecialGoods";
import "./index.less";
import userUtils from '@/utils/user';
const { isUs,istfdc,istfsn,istfgc,islqemall } = userUtils;
const { TabPane } = Tabs;
const BreadcrumbItem = Breadcrumb.Item;

class Outstanding extends Component {
  render() {
    return (
      <div className="outstanding_all">
        <div>
          <Breadcrumb>
            <BreadcrumbItem>商城智采</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div>
          <Tabs defaultActiveKey="1" className="tabs">
             <TabPane tab="为你推荐" key="1" className="tab">
              <TopGoods />
           </TabPane>
           
            <TabPane tab={isUs  ? '十方精选' : istfdc || istfsn || istfgc || islqemall ?'地材名企':'名企名店'} key="2" className="tab">
               <TopBrand/>
             </TabPane>

             <TabPane tab={isUs  ? '特供专区' : '优选商品'} key="3" className="tab">
               <TopSpecialGoods/>
             </TabPane>
          </Tabs>
        </div>
      </div>
    );
  }
}

export default Outstanding;
