import React, { Component } from 'react';
import { API } from '@/http/api';
import http from '@/http';
import storage from '@/utils/storage';
import userUtil from '@/utils/user';
import { changeObjkey } from '@/utils';
import Select from 'CMPT/common/Select';
import Table from 'CMPT/common/Table';
import BOCascader from 'CMPT/common/Cascader';
import SignoutModal from './SignoutModal';
import moment from 'moment';
import { Input, DatePicker, Icon, Tag, Tooltip, Spin } from 'antd';
class ContractList extends Component {
    state = {
        fetchingOne: true,
        fetching: true,
        projects: [],
        fetchingprojects: true,
        floors: [],
        fetchingfloors: true,
        departmentPerson: [],
        deptOptions: [],
        fieldNames: {
            label: 'text',
            value: 'idCode',
            children: 'children'
        },
        // 成交方式
        dealMethod: [],
        customerType: [],
        rentStatus: [],
        auditStatus: [],
        category: [ //1：租期中 2：即将到期 3：即将搬入 4：已退租 5：到期未处理 6：退租未结算
            { id: 99, name: '全部', num: 0, desc: 'allData' },
            { id: 1, name: '租期中', num: 0, desc: 'leasing' },
            { id: 2, name: '即将到期', num: 0, desc: 'aboutToExpire' },
            { id: 3, name: '即将搬入', num: 0, desc: 'aboutToCheckIn' },
            { id: 4, name: '已退租', num: 0, desc: 'haveRefund' },
            { id: 5, name: '到期未处理', num: 0, desc: 'untreatedExpired' },
            { id: 6, name: '退租未结算', num: 0, desc: 'refundUnSettlement' }
        ],
        columns: [
            {
                title: '状态',
                dataIndex: 'statusName',
                width: 100
            }, {
                title: '审批状态',
                dataIndex: 'auditStatus',
                width: 120,
                render(value, row) {
                    let color = ''
                    switch (value) { // 0 待审批，1 已驳回 2 通过 3 不通过
                        case 0:
                            color = '#FBBC06'
                            break
                        case 1:
                            color = '#E94437'
                            break;
                        case 3:
                            color = '#838694'
                            break
                        default:
                            color = '#E94437'
                    }
                    return <div><span style={{ color: color, marginRight: '6px' }}>●</span>{row.auditStatusName}</div>
                }
            }, {
                title: '租客',
                dataIndex: 'customer',
                width: 180,
                render: (value) => {
                    let buttons = []
                    if (value.includes.contract) {
                        buttons.push(<span className="contract-table-btn" key="1">租</span>)
                    }
                    if (value.includes.estate) {
                        buttons.push(<span className="contract-table-btn btn-bg" key="2">物</span>)
                    }
                    let com = value.name
                    if (value.name.length > 10) {
                        com = <Tooltip placement="top" title={value.name}>{value.name.slice(0, 10)}...</Tooltip>
                    }
                    return <div>
                        <input type="text"/>
                        {buttons}
                        {com}
                    </div>
                }
            }, {
                title: '合同编号',
                dataIndex: 'contractNum',
                width: 180
            }, {
                title: '项目名称',
                dataIndex: 'projectName',
                width: 180,
                render: (value) => {
                    if (value.length > 10) {
                        return <Tooltip placement="top" title={value}>{value.slice(0, 10)}...</Tooltip>
                    }
                    return value
                }
            }, {
                title: '房间信息',
                dataIndex: 'roomName',
                width: 180,
                render: (value) => {
                    if (value.length > 10) {
                        return <Tooltip placement="top" title={value}>{value.slice(0, 10)}...</Tooltip>
                    }
                    return value
                }
            }, {
                title: '下期收租日',
                dataIndex: 'nextRentDate',
                width: 150,
            }, {
                title: '签约时间',
                dataIndex: 'signTime',
                defaultSortOrder: 'ascend',
                sorter: true,
                width: 150,
            }, {
                title: '签约人',
                dataIndex: 'signPeo',
                width: 120
            }, {
                title: '租约进度',
                dataIndex: 'signSchedule',
                width: 120
            }, {
                title: '合同开始日期',
                dataIndex: 'contractStartDate',
                width: 150,
            }, {
                title: '合同结束日期',
                dataIndex: 'contractEndDate',
                width: 150,
            }, {
                title: '操作',
                dataIndex: 'operate',
                fixed: 'right',
                width: 200,
                render: (value, row) => (
                    <div>
                        {value.map(per => {
                            return <span className="table-operate-btn" key={per} onClick={this.operate.bind(this, per, row.key)}>
                                {['查看', '变更', '退租', '续租'][per - 1]}
                            </span>
                        })}
                    </div>
                )
            }
        ],
        data: [ // status:'0 待生效 10已失效 20租期中 30已变更 40已退租 41 退租未结算 42已违约 43 违约未结算 50已续租 60已作废' auditStatus: '0审批中 1已驳回 2审批通过 3审批不通过'
        ],
        isFolder: true,
        signoutModal: false,
        signoutInfo: {
            contractCode: '',
            contractDateRange: [],
            signoutFei: []
        },
        queryParams: {
            page: 1,
            pageSize: 10,
            total: 0,
            currentCategory: 99,
            keyWord: '', // 搜索关键字
            projectId: undefined, //项目id
            floorIds: [], //楼层id
            rentStatus: undefined, //租约状态
            auditStatus: undefined, //审批状态
            dealMethod: undefined, // 成交方式
            customerType: undefined, // 客户类型
            userId: undefined, //部门人员
            orderFlag: null, // 签约时间升降序排列，默认升序
            startDate: [], // 合同开始时间
            startDateMoment: [], // 合同开始时间moment对象，用于插件回显
            endDate: [], // 合同结束时间， 用于接口请求
            endDateMoment: [], // moment对象
            abandonDate: [], //退房时间
            abandonDateMoment: [], // moment对象
            signDate: [], //签约时间
            signDateMoment: [] // moment对象
        }
    }
    componentWillMount() {
        let search = this.props.history.location.search
        if (search) {
            const formatData = this.formatSearch(search)
            let queryParams = this.state.queryParams
            if (formatData.proCode) {
                queryParams.projectId = formatData.proCode
                queryParams.currentCategory = 1
            } else if (formatData.source === 'projectall') {
                queryParams.currentCategory = 1
            } else if (formatData.source === 'myjob') {
                queryParams.currentCategory = 5
            }
            this.setState({
                queryParams
            })
        }
    }
    componentDidMount() {
        this.getList()
        // 获取统计数据
        this.getStatistics()
        // 获取枚举类型
        this.getTypeList()
        // 获取项目、部门等信息
        this.getSearchData()
    }
    formatSearch = (ss) => {
        const ssSplit = ss.split('?')[1].split('&')
        let obj = {}
        ssSplit.forEach(per => {
            let perSplit = per.split('=')
            obj[perSplit[0]] = perSplit[1]
        })
        return obj
    }
    getList = () => {
        const config = {
            api: API.contractSearch,
            showLoading: true,
            data: this.mapAttr()
        }
        http(config).then(res => {
            this.mapResponse(res.data.datas)
            this.setState({
                queryParams: Object.assign({}, this.state.queryParams, { total: res.data.totalRecord })
            })
        })
    }
    // 发起请求时字段隐射
    mapAttr = () => {
        const queryParams = this.state.queryParams
        let params = {}
        params.auditStatus = queryParams.auditStatus
        params.customerType = queryParams.customerType
        params.userEnCode = queryParams.userId
        params.floorEnCodes = queryParams.floorIds
        params.keyWords = queryParams.keyWord
        params.pageNo = queryParams.page
        params.pageSize = queryParams.pageSize
        params.projectEnCode = queryParams.projectId
        params.signType = queryParams.dealMethod
        params.status = queryParams.rentStatus
        params.orderFlag = queryParams.orderFlag
        if (queryParams.endDate && queryParams.endDate.length) {
            params.endEndTime = queryParams.endDate[1]
            params.endOnTime = queryParams.endDate[0]
        }
        if (queryParams.abandonDate && queryParams.abandonDate.length) {
            params.refundRentEndTime = queryParams.abandonDate[1]
            params.refundRentStartTime = queryParams.abandonDate[0]
        }
        if (queryParams.signDate && queryParams.signDate.length) {
            params.signEndTime = queryParams.signDate[1]
            params.signStartTime = queryParams.signDate[0]
        }
        if (queryParams.startDate && queryParams.startDate.length) {
            params.startEndTime = queryParams.startDate[1]
            params.startOnTime = queryParams.startDate[0]
        }
        if (queryParams.currentCategory !== 99) {
            params.statisticsFlag = queryParams.currentCategory
        }
        return params
    }
    mapResponse = (list) => {
        let data = []
        list.forEach(per => {
            let obj = {}
            obj.key = per.encode
            obj.status = per.status
            obj.statusName = per.statusName
            obj.auditStatus = per.auditStatus
            obj.auditStatusName = per.auditStatusName
            obj.customer = { name: per.customerNames.join('，'), includes: { contract: per.contractClause.rentClause, estate: per.contractClause.property } }
            obj.contractNum = per.contractCode || '-'
            obj.projectName = per.projectNames.join(',')
            obj.roomName = per.zoneNos.join(',')
            obj.nextRentDate = per.payDate || '-'
            obj.signTime = per.signTime
            obj.signPeo = per.signUserName
            obj.signSchedule = per.leaseRate || '-'
            obj.operate = []
            obj.contractStartDate = per.startTime
            obj.contractEndDate = per.endTime
            userUtil.hasAuth(9902010101) && obj.operate.push(1)
            if (per.status === 20 && [2, 3].indexOf(per.auditStatus) !== -1 && per.projectAccessible === 1) { // 具有当前项目才能进行退租、续租、变更
                userUtil.hasAuth(9902010102) && obj.operate.push(2)
                userUtil.hasAuth(9902010104) && obj.operate.push(3)
                userUtil.hasAuth(9902010103) && obj.operate.push(4)
            } else if ([30, 50].indexOf(per.status) !== -1 && (per.auditStatus === 2 || per.auditStatus === 3) && per.projectAccessible === 1) {
                userUtil.hasAuth(9902010104) && obj.operate.push(3) // 已变更\已续签可以退租
            }
            data.push(obj)
        })
        this.setState({ data })
    }
    getStatistics = () => {
        const config = {
            api: API.contractStatistics,
            showLoading: true,
            data: this.mapAttr()
        }
        http(config).then(res => {
            let category = this.state.category
            const statists = res.data
            Object.keys(statists).forEach(key => {
                category.forEach(per => {
                    if (per.desc === key) {
                        per.num = statists[key]
                        return
                    }
                })
            })
            this.setState({
                category
            })
        })
    }
    getTypeList = () => {
        const config = {
            api: API.contractSearchInit
        }
        http(config).then(res => {
            const { auditStatus, customerType, signType, status } = res.data
            this.setState({
                fetchingOne: false,
                dealMethod: this.generateList(signType),
                customerType: this.generateList(customerType),
                rentStatus: this.generateList(status),
                auditStatus: this.generateList(auditStatus)
            })
        })
    }
    generateList = (list, key, addUnit) => {
        let keyMap = { key: 'value', value: 'name' }
        if (key) {
            keyMap = key
        }
        let target = []
        list.forEach(per => {
            if (key && addUnit) {
                per.name += '层'
            }
            target.push(changeObjkey(per, keyMap, true))
        })
        return target
    }
    getSearchData = () => {
        const projectConfig = {
            api: API.commonProjectfilter
        }

        // 项目
        http(projectConfig).then(proRes => {
            const mapKey = { encode: 'value', name: 'name' }
            this.setState({
                fetchingprojects: false,
                projects: this.generateList(proRes.data, mapKey)
            })
        })

    }
    getDetPerData = () => {
        const departmentPerson = this.state.departmentPerson
        if (departmentPerson && departmentPerson.length) return
        const deptConfig = {
            api: API.userGetList,
            data: {
                departmentEncode: storage.get('USER').departmentIdCode
            }
        }
        // 部门人员
        http(deptConfig).then(deptRes => {
            this.setState({
                departmentPerson: this.transDeptData(deptRes.data.datas),
                fetching: false
            })
        })
    }
    transDeptData = (list) => {
        let transList = []
        list.forEach(per => {
            transList.push({ value: per.idCode, name: `${per.departmentName}-${per.name}` })
        })
        return transList
    }
    keyWordSearch = (value) => {
        let queryParams = this.state.queryParams
        queryParams.page = 1
        queryParams.keyWord = value
        this.setState({
            queryParams
        }, () => {
            this.getList()
            this.getStatistics()
        })
    }
    selectChange = (attr, data) => {
        let queryParams = this.state.queryParams
        queryParams.page = 1
        if (attr === 'floorIds') {
            queryParams[attr].push(data)
        } else {
            queryParams[attr] = data
            if (attr === 'projectId') {
                this.getFloorData(data)
            }
        }
        this.setState({ queryParams }, () => {
            this.getList()
            this.getStatistics()
        })
    }
    getFloorData = (id) => {
        const config = {
            api: API.commonFloorfilter,
            data: { projectEncode: id }
        }
        http(config).then(res => {
            const mapKey = { encode: 'value', name: 'name' }
            this.setState({
                fetchingfloors: false,
                floors: this.generateList(res.data, mapKey, true)
            })
        })
    }
    floorDeselect = (value) => {
        let queryParams = this.state.queryParams
        let floorIds = queryParams.floorIds
        const index = floorIds.findIndex(per => per === value)
        if (index !== -1) {
            floorIds.splice(index, 1)
        }
        queryParams.floorIds = floorIds
        this.setState({
            queryParams
        }, () => {
            this.getList()
            this.getStatistics()
        })
    }
    dateRangeChange = (attr, value, list) => {
        let queryParams = this.state.queryParams
        queryParams.page = 1
        queryParams[attr] = list
        queryParams[attr + 'Moment'] = value
        this.setState({
            queryParams
        }, () => {
            this.getList()
            this.getStatistics()
        })
    }
    resetParams = () => {
        let queryParams = this.state.queryParams
        Object.keys(queryParams).forEach(key => {
            if (key === 'currentCategory') {
                queryParams[key] = 99
            } else if (key === 'page' || key === 'pageSize') {
                queryParams.page = 1
                return
            } else if (key === 'orderFlag') {
                queryParams.orderFlag = null
            } else if (key === 'floorIds') {
                queryParams[key] = []
            } else {
                queryParams[key] = undefined
            }
        })
        this.setState({
            queryParams,
            floors: [],
        }, () => {
            this.getList()
            this.getStatistics()
        })
    }
    setKeyWord = (e) => {
        let queryParams = this.state.queryParams
        queryParams.keyWord = e.target.value
        this.setState({
            queryParams
        })
    }
    operate = (status, code) => {
        this.setState({
            currentContractCode: code
        })
        if (status === 1) {
            this.goDetail(code)
        } else if (status === 2) { // 变更
            this.props.history.push(`/app/operate/contract/add?code=${code}&type=3`)
        } else if (status === 3) {
            // 先调初始化的接口
            const config = {
                api: API.contractInitRentWithdrawalApply,
                path: {
                    enCode: code
                }
            }
            http(config).then(res => {
                const data = res.data
                let signoutInfo = {
                    contractCode: code,
                    allPaid: data.allPaid,
                    customerName: data.customerNames.join('，'),
                    contractDateRange: [data.startTime, data.endTime],
                    endDisabledDate: data.nextStartDate,
                    contractNumber: data.contractCode,
                    projectRoomNames: data.projectNames.join('，'),
                    signoutType: data.refundRentType || 1,
                    signoutTime: moment(data.refundRentTime),
                    signoutReason: data.remark,
                    signoutFei: data.billList && data.billList.map(item => {
                        return {
                            marginId: item.encode,
                            feiType: item.budgetType,
                            bizTypeSub: item.bizTypeSub, // 1 租赁收付 2 物业 3 其他收付
                            marginType: String(item.feeType),
                            marginNum: item.payAmount,
                            paidAmount: item.paidAmount,
                            auditPaidAmount: item.auditPaidAmount,
                            marginDate: moment(item.payDate)
                        }
                    })
                }
                this.setState({
                    signoutInfo
                })
            })
            this.setState({
                signoutModal: true
            })
        } else if (status === 4) {
            this.props.history.push(`/app/operate/contract/add?code=${code}&type=2`)
        }
    }
    onChange = (page, pageSize, sorter) => {
        let queryParams = this.state.queryParams
        queryParams.page = page
        queryParams.pageSize = pageSize
        if (sorter && sorter.field) {
            queryParams.orderFlag = 0
            if (sorter.order === 'descend') {
                queryParams.orderFlag = 1
            }
        }
        this.setState({ queryParams }, () => {
            this.getList()
        })
    }
    folder = () => {
        this.setState({
            isFolder: !this.state.isFolder
        })
    }
    modalClose = (flag) => {
        this.setState({
            signoutModal: false
        })
        if (typeof flag === 'boolean' && flag) {
            this.getList()
            this.getStatistics()
        }
    }
    changeCategory = (id) => {
        let queryParams = this.state.queryParams
        queryParams.currentCategory = id
        queryParams.page = 1
        this.setState({ queryParams }, () => {
            this.getList()
        })
    }
    goDetail = (id) => {
        const { history } = this.props
        history.push(`/app/operate/contract/detail/${id}?audit=0`)
    }


    notFount() {
        return (
            <div style={{ textAlign: 'center', height: '100px', lineHeight: '100px' }}>加载中...</div>
        )
    }
    notFoundContent() {
        return (
            <div className='ant-empty ant-empty-normal ant-empty-small'><div className="ant-empty-image"></div><p className="ant-empty-description">暂无数据</p></div>
        )
    }

    render() {
        const { isFolder, queryParams: params, fetching, fetchingOne, fetchingprojects, fetchingfloors } = this.state
        return (
            <div className="contract-list">
                <div className="contract-search">
                    <div className="contract-list-row">
                        <span>
                            <Input.Search placeholder="租客名称、租客手机号、区域、合同编号" onChange={this.setKeyWord}
                                onSearch={this.keyWordSearch} value={params.keyWord} style={{ width: '280px' }} />
                        </span>
                        <span>
                            <Select data={this.state.dealMethod} placeholder="成交方式" notFoundContent={fetchingOne ? this.notFount() : this.notFoundContent()} value={params.dealMethod}
                                selectMethod={this.selectChange.bind(this, 'dealMethod')} style={{ width: '104px' }} />
                        </span>
                        <span>
                            <Select data={this.state.customerType} placeholder="客户类型" notFoundContent={fetchingOne ? this.notFount() : this.notFoundContent()} value={params.customerType}
                                selectMethod={this.selectChange.bind(this, 'customerType')} style={{ width: '104px' }} />
                        </span>
                        <span>
                            <Select data={this.state.rentStatus} placeholder="租约状态" notFoundContent={fetchingOne ? this.notFount() : this.notFoundContent()} value={params.rentStatus}
                                selectMethod={this.selectChange.bind(this, 'rentStatus')} style={{ width: '104px' }} />
                        </span>
                        <span>
                            <Select data={this.state.auditStatus} placeholder="审批状态" notFoundContent={fetchingOne ? this.notFount() : this.notFoundContent()} value={params.auditStatus}
                                selectMethod={this.selectChange.bind(this, 'auditStatus')} style={{ width: '104px' }} />
                        </span>
                        {/* </div>
                    <div className="contract-list-row"> */}
                        <span>
                            <Select data={this.state.projects} placeholder="请选择项目" notFoundContent={fetchingprojects ? this.notFount() : this.notFoundContent()} value={params.projectId}
                                selectMethod={this.selectChange.bind(this, 'projectId')} style={{ width: '280px' }} />
                        </span>
                        <span>
                            <Select notFoundContent="请先选择项目" data={this.state.floors} notFoundContent={fetchingfloors ? this.notFount() : this.notFoundContent()} placeholder="请选择楼层" value={params.floorIds} mode="multiple"
                                selectMethod={this.selectChange.bind(this, 'floorIds')} onDeselect={this.floorDeselect} style={{ width: '220px' }} />
                        </span>
                        <span>

                            <Select data={this.state.departmentPerson} notFoundContent={fetching ? this.notFount() : this.notFoundContent()} placeholder="部门-人员" value={params.userId} onDropdownVisibleChange={this.getDetPerData}
                                selectMethod={this.selectChange.bind(this, 'userId')} style={{ width: '220px' }} />
                        </span>
                        <span className="contract-col" style={{ display: isFolder ? "inline-block" : 'none' }}>
                            <a onClick={this.resetParams}>重置</a>
                            <a onClick={this.folder}>展开<Icon type="down" style={{ color: '#FBBC06', fontSize: '12px', fontWeight: '600' }} /></a>
                        </span>

                        {/* <div style={{display: isFolder ? "none" : "block"}}>
                        <div gutter={12} style={{ marginBottom: '12px' }}> */}
                        <span className="inner-col" style={{ display: isFolder ? "none" : "block" }}>
                            <span className="inner-col-span" style={{ lineHeight: '30px' }}>开始时间</span>
                            <DatePicker.RangePicker style={{ width: '280px', paddingLeft: '64px' }} value={params.startDateMoment}
                                onChange={this.dateRangeChange.bind(this, 'startDate')} />
                        </span>
                        <span className="inner-col" style={{ display: isFolder ? "none" : "block" }}>
                            <span className="inner-col-span" style={{  lineHeight: '30px' }}>结束时间</span>
                            <DatePicker.RangePicker style={{ width: '292px', paddingLeft: '56px' }} value={params.endDateMoment}
                                onChange={this.dateRangeChange.bind(this, 'endDate')} />
                        </span>
                        {/* </div>
                        <div gutter={12}> */}
                        <span className="inner-col" style={{ display: isFolder ? "none" : "block" }}>
                            <span className="inner-col-span" style={{ lineHeight: '30px' }}>退房时间</span>
                            <DatePicker.RangePicker style={{ width: '280px', paddingLeft: '64px' }} value={params.abandonDateMoment}
                                onChange={this.dateRangeChange.bind(this, 'abandonDate')} />
                        </span>
                        <span className="inner-col" style={{ display: isFolder ? "none" : "block" }}>
                            <span className="inner-col-span" style={{ lineHeight: '30px' }}>签约时间</span>
                            <DatePicker.RangePicker style={{ width: '292px', paddingLeft: '56px' }} value={params.signDateMoment}
                                onChange={this.dateRangeChange.bind(this, 'signDate')} />
                        </span>
                        <span className="contract-col" style={{ display: isFolder ? "none" : "block" }}>
                            <a onClick={this.resetParams}>重置</a>
                            <a onClick={this.folder}>收起<Icon type="up" style={{ color: '#FBBC06', fontSize: '12px', fontWeight: '600' }} /></a>
                        </span>
                    </div>
                </div>
                <div className="contract-tag">
                    {this.state.category.map(per => {
                        return <Tag key={per.id} className={per.id === params.currentCategory ? 'active' : ''}
                            onClick={this.changeCategory.bind(this, per.id)}
                        >
                            {per.name}（{per.num}）
                                </Tag>
                    })}
                </div>
                <div className="contract-table">
                    <Table columns={this.state.columns} data={this.state.data}
                        total={params.total} change={this.onChange} current={params.page} scroll={{ x: 1700 }} />
                </div>
                <SignoutModal show={this.state.signoutModal} close={this.modalClose} info={this.state.signoutInfo} />
            </div>
        )
    }
}

export default ContractList