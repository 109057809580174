/******* 项目 start *******/
import ProjectIndex from "CMPT/project/index";
import ProjectOverview from "CMPT/project/detail/ProjectOverview";
import ProjectDetail from "CMPT/project/detail/Detail";
import ProjectAdd from "CMPT/project/add/project/Add";
import RegionAdd from "CMPT/project/add/region/AddRegion";
/******* 项目 end *******/
import Operate from "CMPT/operate";
import Myjob from "CMPT/myjob";
import MyjobUpcoming from "CMPT/myjob/MyjobUpcoming";
import MyjobSchedule from "CMPT/myjob/MyjobSchedule";
import Setting from "CMPT/setting";
import SettingOrganizationDepartment from "CMPT/setting/organization/department/SettingOrganizationDepartment";
import SettingOrganizationDepartmentForm from "CMPT/setting/organization/department/SettingOrganizationDepartmentForm";
import SettingOrganizationDepartmentDetail from "CMPT/setting/organization/department/SettingOrganizationDepartmentDetail";
import SettingApproveManage from "CMPT/setting/approve/manage/SettingApproveManage";
import SettingApproveManageForm from "CMPT/setting/approve/manage/SettingApproveManageForm";
import SettingApproveManageRead from "CMPT/setting/approve/manage/SettingApproveManageRead";
import SettingApproveConfig from "CMPT/setting/approve/config/SettingApproveConfig";
import SettingService from "CMPT/setting/system/settingservice/settingservice";
// 决策
import ContractDecision from "CMPT/setting/decision/ContractDecision";
import ProjectDecision from "CMPT/setting/decision/ProjectDecision";
/*****************操作************************/
//意向
import OperateIntention from "CMPT/operate/OperateIntention";
import AddIntention from "CMPT/operate/AddIntention";
import EditIntention from "CMPT/operate/EditIntention";
import IntentionDetail from "CMPT/operate/IntentionDetail";
//后勤保障
import OperateMaintain from "CMPT/serviceCenter/logistics/maintain/maintain";
import OperateMaintainOrderdetail from "CMPT/serviceCenter/logistics/maintain/orderDetail";
import OperateMaintainOrderAdd from "CMPT/serviceCenter/logistics/maintain/addorder";
import OperateComplaint from "CMPT/serviceCenter/logistics/complaint/complaint";
import OperateComplaintOrderdetail from "CMPT/serviceCenter/logistics/complaint/orderDetail";
import OperateComplaintOrderAdd from "CMPT/serviceCenter/logistics/complaint/addorder";

import ContractList from "CMPT/contract/List"; // 合同列表
import ContractAdd from "CMPT/contract/Add"; // 合同新增/编辑
import ContractDetail from "CMPT/contract/Detail"; // 合同详情
// 模板
import ContractTemplate from "CMPT/setting/template/ContractTemplate";
import ContractTemplateFirst from "CMPT/setting/template/ContractTemplateFirst";
import ContractTemplateSecond from "CMPT/setting/template/ContractTemplateSecond";
import ContractTemplateDetail from "CMPT/setting/template/ContractTemplateDetail";
import ReceiptTemplate from "CMPT/setting/template/ReceiptTemplate";
import ReceiptTemplateFirst from "CMPT/setting/template/ReceiptTemplateFirst";
import ReceiptTemplateSecond from "CMPT/setting/template/ReceiptTemplateSecond";
import ReceiptTemplateDetail from "CMPT/setting/template/ReceiptTemplateDetail";
import SupplierLists from "CMPT/serviceCenter/supplier/SupplierLists"; //供应商列表
import SupplierDetail from "CMPT/serviceCenter/supplier/SupplierDetail"; //供应商详情
import SupplierEdit from "CMPT/serviceCenter/supplier/SupplierEdit"; //供应商新增
import ProjectReport from "CMPT/reportForm/ProjectReport"; //项目报表
import RegionalReport from "CMPT/reportForm/RegionalReport"; //区域报表
import ContractReport from "CMPT/reportForm/ContractReport"; //合同报表
import IncomeExpenseReport from "CMPT/reportForm/IncomeExpenseReport"; //收支报表
import ResourceReport from "CMPT/reportForm/ResourceReport"; //资源台账报表
import ArrearsDetailedReport from "CMPT/reportForm/ArrearsDetailedReport"; //欠费明细报表
import SettingAccountInfo from "CMPT/setting/account/SettingAccountInfo"; //个人信息
import SettingChangePassword from "CMPT/setting/account/SettingChangePassword";
import HelpCenter from "CMPT/helpCenter/HelpCenter";
import MessageCenter from "CMPT/message/MessageCenter";

// 财务收支

import BillManage from "CMPT/finance/billManage/BillManage"; //
import FinanceApproval from "CMPT/finance/financeApproval/List"; //
import ReceiptManage from "CMPT/finance/receiptManage/ReceiptManage"; //
import Approval from "CMPT/finance/financeApproval/Approval"; //
import NewInBill from "CMPT/finance/billManage/NewInBill"; // 新增收款账单
import MakeCollection from "CMPT/finance/billManage/MakeCollection"; // 收款
import DetailInfo from "CMPT/finance/billManage/DetailInfo"; // 详情
import BillDetail from "CMPT/finance/financeApproval/Detail"; // 详情
import RefundDetail from "CMPT/finance/billManage/RefundDetail"; // 详情

// 公告管理
import SettingAnnounceManage from "CMPT/setting/announce/SettingAnnounceManage"; //企业公告
import SettingAnnounceForm from "CMPT/setting/announce/SettingAnnounceForm"; //新增公告
// import SettingAnnounceForm from 'CMPT/setting/announce/FormTest';//新增公告
import SettingAnnounceRead from "CMPT/setting/announce/SettingAnnounceRead"; //公告详情
import SettingAnnounceCount from "CMPT/setting/announce/SettingAnnounceCount"; //公告统计
import AnnounceRead from "CMPT/message/AnnounceRead"; //消息中心-公告详情

// 客户管理
import CustomerTabs from "CMPT/operate/CustomerTabs";
//import OperateClient from 'CMPT/operate/OperateClient';
import AddCustomer from "CMPT/operate/AddCustomer";
import EditCustomer from "CMPT/operate/EditCustomer";
import CustomerDetail from "CMPT/operate/CustomerDetail";

// 客源管理
import CustomerSource from "CMPT/customer/Source";

// 帮助中心

import HelpCatalog from "CMPT/help/Catalog"; //目录管理
import HelpVideo from "CMPT/help/Video"; //视频教程
import HelpIssue from "CMPT/help/Issue"; //常见问题
import HelpIssueTab from "CMPT/help/NewIssueTab";
import HelpVideoAdd from "CMPT/help/VideoAdd"; //视屏教程 新增、编辑
import HelpIssueAdd from "CMPT/help/IssueAdd"; //常见问题 新增、编辑

import Feedback from "CMPT/help/Feedback";
import NewCatalogTab from "CMPT/help/NewCatalogTab";

// 规则中心

import RuleCenter from "CMPT/ruleCenter/RuleCenter";
import RuleList from "CMPT/ruleCenter/RuleList";
import RuleAddEdit from "CMPT/ruleCenter/RuleAddEdit";

// 设置中心
import SettingOrganizationRole from "CMPT/setting/role/SettingOrganizationRole";
import SettingOrganizationRoleForm from "CMPT/setting/role/SettingOrganizationRoleForm";
import SettingOrganizationRoleRead from "CMPT/setting/role/SettingOrganizationRoleRead";
import SettingOrganizationStaff from "CMPT/setting/staff/SettingOrganizationStaff";
import SettingOrganizationStaffForm from "CMPT/setting/staff/SettingOrganizationStaffForm";
import SettingOrganizationStaffRead from "CMPT/setting/staff/SettingOrganizationStaffRead";
import CustomerDecision from "CMPT/setting/decision/CustomerDecision"; //客户决策
import AccountManage from "CMPT/setting/accountManage"; //
import AccountManageDetail from "CMPT/setting/accountManage/detail"; //
// 部门管理
import Department from "CMPT/setting/department";

import EnterpriseAuthLists from "CMPT/enterpriseAuth/EnterpriseAuthLists"; // 企业认证列表
import EnterpriseAuthDetail from "CMPT/enterpriseAuth/EnterpriseAuthDetail"; // 企业认证详情
import EnterpriseAuthRecord from "CMPT/enterpriseAuth/record"; // 企业审核记录
import NewAddEditCustomer from "CMPT/enterpriseAuth/NewAddEditCustomer";
import TianYanCha from "CMPT/enterpriseAuth/TianYanCha"; // 天眼查

import ContractManagementList from "CMPT/contractManagement/ContractManagementLists"; // 签约管理列表
import ContractManagementDetail from "CMPT/contractManagement/ContractManagementDetail"; // 签约管理详情

import EnterpriseUpgradeLists from "CMPT/enterpriseUpgrade/EnterpriseUpgradeLists"; // 企业认证列表
import EnterpriseUpgradeDetail from "CMPT/enterpriseUpgrade/EnterpriseUpgradeDetail"; // 企业认证详情

import EnterpriseCustomerDetail from "CMPT/enterpriseAuth/EnterpriseCustomerDetail"; // 新的客户详情
import EnterpriseUpgrade from "CMPT/enterpriseAuth/EnterpriseUpgrade"; // CRM后台 升级账号

import BannerManager from "CMPT/homeContent/BannerManager";
import YqyGoodsManager from "CMPT/homeContent/YqyGoodsManager";

import BusinessSetting from "CMPT/mallSetting/BusinessSetting";
import PlatformAnnouncement from "CMPT/mallSetting/PlatformAnnouncement/index";
import addOreditAnnouncement from "CMPT/mallSetting/PlatformAnnouncement/Announcement/addOreditAnnouncement";
import addOreditJournalism from "CMPT/mallSetting/PlatformAnnouncement/Journalism/addOreditJournalism";

import StaffSiteActivityList from "CMPT/staffSite/activity/List"; // 员工专享--活动列表
import StaffSiteActivityDetail from "CMPT/staffSite/activity/Detail"; //  员工专享--活动详情

import GoodsManage from "CMPT/goodsManage/index"; //  商品管理列表
import GoodsDetail from "CMPT/goodsManage/GoodsDetail"; // 商品详情

import OrderManage from "CMPT/orderManage/index"; //  商品管理列表
import OrderDetail from "CMPT/orderManage/OrderDetail"; // 商品详情
import VehicleManage from "CMPT/vehicleAndEquipment/index"; //  车辆设备
import VehicleDetail from "CMPT/vehicleAndEquipment/VehicleManage/VehicleDetail"; //  车辆详情
import EquipmentDetail from "CMPT/vehicleAndEquipment/EquipmentManage/EquipmentDetail"; //  设备详情
import LogisticsOrderDetail from "CMPT/orderManage/LogisticsOrder/LogisticsOrderDetail"; // 物流订单详情

import SorcingManage from "CMPT/sourcing/index"; // 询价管理列表
import SourcingDetail from "CMPT/sourcing/detail/index"; // 询价管理详情
import RentDetail from "CMPT/sourcing/RentInfo/detail/index"; // 出租信息详情
import GoodsAuditDetail from "CMPT/sourcing/GoodsAudit/GoodsAuditDetail"; // 发货信息详情
import CapacityAuditDetail from "CMPT/sourcing/CapacityAudit/CapacityAuditDetail"; // 发货信息详情
import InviteTendersDetail from "CMPT/sourcing/InviteTenders/detail"; // 招标信息详情
import serviceInformationDetail from "CMPT/sourcing/ServiceInfo/detail"; // 服务信息详情

// 客户操作日志
import OperateLog from "CMPT/operateLog";
import TestDemo from "CMPT/TestDemo";
// 商品分类管理
import BackendCategory from "CMPT/backendCategory/BackendCategory";
import BackendCategoryAdd from "CMPT/backendCategory/BackendCategoryAdd";

// 频道导航
import ChannelNavList from "CMPT/channelNavigation/ChannelNavList";
import ChannelNavigationDetail from "CMPT/channelNavigation/Detail";

// 用户管理
import UserManager from "CMPT/userManager/UserManager";
import UserInfo from "CMPT/userManager/UserInfo";

// 合同备案
import ContractFiling from "CMPT/contractFiling/ContractFiling"
import ContractNewDetail from "CMPT/contractFiling/ContractDetail"
import ContractRaw from "CMPT/contractFiling/ContractRaw"
import ContractFin from "CMPT/contractFiling/ContractFin"

import TestAccountPage from "CMPT/testAccount";
// 商户开户管理
import AccountOpenAudit from "CMPT/accountOpenAudit/index";
import EnterpriseAccountOpeningDetail from "CMPT/accountOpenAudit/EnterpriseAccountOpening/EnterpriseAccountOpeningDetail";
import AgreementPDF from "CMPT/accountOpenAudit/EnterpriseAccountOpening/AgreementPDF";

import EnterpriseList from "CMPT/enterprise/EnterPriseList";
import EnterpriseDetail from "CMPT/enterprise/EnterpriseDetail";

// 设备相关
import EquipmentOrderList from "CMPT/orderManage/EquipmentOrder/List";
import EquipmentOrderDetail from "CMPT/orderManage/EquipmentOrder/DetailInfo";
import EquipmentIntentionList from "CMPT/orderManage/EquipmentIntention/List";
import EquipmentIntentionDetail from "CMPT/orderManage/EquipmentIntention/DetailInfo";

// 出租经营信息

import LeaseOperateMsgDetail from "CMPT/sourcing/LeaseOperateMsg/LeaseOperateMsgDetail";

// 推荐(商品+店铺)相关
import Outstanding from "CMPT/outstanding/index";

// 咨询相关
import ConsultList from "CMPT/consult/List";
import ConsultDetail from "CMPT/consult/Detail";

// 网货相关
import InvoiceList from "CMPT/invoice/list/index";
import InvoiceDetail from "CMPT/invoice/detail/index";

// 增值服务
import TrajectoryFind from "CMPT/valueAddService/trajectoryFind/index"
import BuyForm from "CMPT/valueAddService/trajectoryFind/buyForm"
import AddRecord from "CMPT/valueAddService/trajectoryFind/addRecord"
import OperaterRecord from "CMPT/valueAddService/trajectoryFind/operaterRecord"



export default {
	// OperateClient,
	CustomerTabs,
	AddCustomer,
	EditCustomer,
	CustomerDetail,

	HelpCatalog,
	HelpVideo,
	HelpIssue,
	HelpIssueTab,
	HelpVideoAdd,
	HelpIssueAdd,

	SettingOrganizationRole,
	SettingOrganizationRoleForm,
	SettingOrganizationRoleRead,
	SettingOrganizationStaff,
	SettingOrganizationStaffForm,
	SettingOrganizationStaffRead,
	CustomerDecision,
	AccountManage,
	AccountManageDetail,
	Department,
	ProjectIndex,
	ProjectOverview,
	ProjectDetail,
	ProjectAdd,
	RegionAdd,
	Operate,
	Myjob,
	Setting,
	SettingOrganizationDepartment,
	SettingOrganizationDepartmentForm,
	SettingOrganizationDepartmentDetail,
	SettingApproveManage,
	SettingApproveManageForm,
	SettingApproveManageRead,
	SettingApproveConfig,
	SettingService,
	ContractDecision,
	ProjectDecision,
	OperateIntention,
	AddIntention,
	EditIntention,
	IntentionDetail,
	OperateMaintain,
	OperateMaintainOrderAdd,
	OperateMaintainOrderdetail,
	OperateComplaint,
	OperateComplaintOrderAdd,
	OperateComplaintOrderdetail,
	MyjobUpcoming,
	MyjobSchedule,
	ContractList,
	ContractAdd,
	ContractDetail,
	ContractTemplate,
	ReceiptTemplate,
	ReceiptTemplateDetail,
	ReceiptTemplateFirst,
	ReceiptTemplateSecond,
	ContractTemplateFirst,
	ContractTemplateSecond,
	ContractTemplateDetail,
	SupplierLists,
	SupplierDetail,
	SupplierEdit,
	ProjectReport,
	RegionalReport,
	ContractReport,
	ResourceReport,
	IncomeExpenseReport,
	ArrearsDetailedReport,
	SettingAccountInfo,
	SettingChangePassword,

	// finance
	ReceiptManage,
	BillManage,
	FinanceApproval,
	Approval,
	NewInBill,
	MakeCollection,
	DetailInfo,
	BillDetail,
	RefundDetail,

	CustomerSource,

	HelpCenter,
	MessageCenter,

	// 公告管理
	SettingAnnounceManage,
	SettingAnnounceForm,
	SettingAnnounceRead,
	SettingAnnounceCount,
	AnnounceRead,

	// 企业认证 审核
	EnterpriseAuthLists,
	EnterpriseAuthDetail,
	EnterpriseAuthRecord,
	NewAddEditCustomer,

	// 签约管理
	ContractManagementList,
	ContractManagementDetail,

	// 新的客户详情，后台升级账号
	EnterpriseCustomerDetail,
	EnterpriseUpgrade,

	// 账号升级认证
	EnterpriseUpgradeLists,
	EnterpriseUpgradeDetail,

	// 员工专享 活动列表
	StaffSiteActivityList,
	StaffSiteActivityDetail,

	GoodsManage,
	GoodsDetail,
	OrderManage,
	OrderDetail,
	LogisticsOrderDetail,

	VehicleManage,
	VehicleDetail,
	EquipmentDetail,

	BannerManager,
	YqyGoodsManager,

	BusinessSetting,
	PlatformAnnouncement,
	addOreditAnnouncement,
	addOreditJournalism,

	NewCatalogTab,
	Feedback,
	OperateLog,

	// 规则中心
	RuleCenter,
	RuleList,
	RuleAddEdit,
	TestDemo,
	BackendCategory,
	BackendCategoryAdd,

	// 频道导航
	ChannelNavList,
	ChannelNavigationDetail,
	TianYanCha,

	// 测试账号
	TestAccountPage,

	// 询价管理
	SorcingManage,
	SourcingDetail,
	RentDetail,
	GoodsAuditDetail,
	InviteTendersDetail,
	CapacityAuditDetail,
	serviceInformationDetail,
	UserManager,
	UserInfo,

	// 企业列表
	EnterpriseList,
	// 企业详情
	EnterpriseDetail,

	// 商户开户管理
	AccountOpenAudit,
	EnterpriseAccountOpeningDetail,
	AgreementPDF,

	// 设备租赁相关
	EquipmentOrderList,
	EquipmentOrderDetail,
	EquipmentIntentionList,
	EquipmentIntentionDetail,

	// 推荐相关
	Outstanding,

	// 出租经营信息
	LeaseOperateMsgDetail,

	// 咨询相关
	ConsultList,
	ConsultDetail,

	// 网货相关
	InvoiceList,
	InvoiceDetail,

	TrajectoryFind,
	BuyForm,
	AddRecord,
	OperaterRecord,

	ContractFiling,
	ContractNewDetail,
	ContractFin,
	ContractRaw
};
