import React, { Component } from 'react';
import { Modal, Button, Form, Icon, Input, DatePicker, Row, Col, Radio,message } from 'antd';
import Select from '@/components/common/Select';
import { followupFlagConfig, followStatusConfig, lostReasonTypeConfig } from './intentionConfig'
import { intentionFollowup } from './service/intentionService'
import { objectarrayToArray } from '@/utils/fliter';
import moment from 'moment'

const RadioGroup = Radio.Group;
const { TextArea } = Input;

class AddFollower extends Component {

    state = {
        status: null
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            let fieldsObject = this.props.form.getFieldsValue();
            fieldsObject.intentionEncode  = this.props.id[0].encode;
            fieldsObject.followupTime = fieldsObject.followupTime.format('YYYY-MM-DD HH:mm:ss')
            if (!err) {
                intentionFollowup(fieldsObject).then(res => {
                    if (res.code == 0) {
                        message.success("新增跟进成功")
                        this.props.getIntentionList();
                        this.handleCancel()
                    }
                })

            }
        });
    }

    handleCancel = () => {
        this.props.followCancel();
    }

    handleChangeStatus = (value) => {
        this.setState({
            status: value
        })
    }

    render() {
        const {
            getFieldDecorator, getFieldsError, getFieldError, isFieldTouched,
        } = this.props.form;
        const config = {
            rules: [{ required: true, message: '请选择' }],
        }
        const status = this.props.id.length&&this.props.id[0].status;
        const intentionStatus = status ? this.props.intentionStatus.filter(item=>item.value>status):this.props.intentionStatus;
        const lastStatus = intentionStatus&&intentionStatus.length ? intentionStatus[intentionStatus.length-1].value : -1;
        const followFlagHtml = this.props.followupFlag.map(item => <Radio key={item.value} value={item.value}>{item.name}</Radio>)
        return (
            <Modal
                title="跟进"
                visible={this.props.visible}
                onOk={this.handleSubmit}
                onCancel={this.handleCancel}
                width="568px"
            >
                <Form  onSubmit={this.handleSubmit}>
                    <Row gutter={12}>
                        <Col className="gutter-row" span={12}>
                            <Form.Item label="跟进状态">
                                {getFieldDecorator('status', config)(
                                    <Select style={{ width: '240px' }} data={intentionStatus} placeholder="请选择" selectMethod={this.handleChangeStatus} />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={12}>
                        <Col className="gutter-row" span={12}>
                            <Form.Item label="跟进方式">
                                {getFieldDecorator('type', config)(
                                    <Select style={{ width: '240px' }} data={this.props.followupType} placeholder="请选择" selectMethod={this.handleChange} />
                                )}
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12}>
                            <Form.Item label="跟进时间">
                                {getFieldDecorator('followupTime', config)(
                                    <DatePicker  style={{ width: '100%' }} showTime   format='YYYY-MM-DD HH:mm:ss' onChange={this.handleChange} />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={12} style={{ display: this.state.status == lastStatus ? 'block' : 'none' }}>
                        <Col className="gutter-row" span={12}>
                            <Form.Item label="流失原因">
                                {getFieldDecorator('lostReasonType', {
                                    rules: [
                                        {
                                            required: this.state.status == lastStatus,
                                            message: '请选择'
                                        }
                                    ]
                                })(
                                    <Select style={{ width: '240px' }} data={this.props.lostReasonType} placeholder="请选择" selectMethod={this.handleChange} />
                                )}
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12}>
                            <Form.Item label="是否继续跟进">
                                {getFieldDecorator('followupFlag', {
                                    rules: [
                                        {
                                            required: this.state.status == lastStatus,
                                            message: '请选择'
                                        }
                                    ]
                                })(
                                    <RadioGroup onChange={this.onChange}>
                                        {followFlagHtml}
                                    </RadioGroup>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={80}>
                        <Col className="gutter-row" span={24}>
                            <Form.Item label="跟进结果">
                                {getFieldDecorator('followupRemark', {
                                    rules:[{required:true,message:'请输入'}]
                                })(
                                    <TextArea placeholder="请输入" autosize={{ minRows: 4, maxRows: 4 }} />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    }
}

const AddFollowerForm = Form.create({ name: 'AddFollower' })(AddFollower);

export default AddFollowerForm;