import React, { Component } from 'react';
import { Breadcrumb, Button, Row, Col ,DatePicker,Input,message,Tooltip } from 'antd';
import Select from 'CMPT/common/Select';
import Table from 'CMPT/common/Table';
import { NumericInput } from '../project/detail/capital/NumericInput'
import http from '@/http';
import { API } from '@/http/api';
import './Report.less';
const BreadcrumbItem = Breadcrumb.Item;
const Search = Input.Search;
var moment = require('moment');


class ResourceReport extends Component {
    state = {
        columns: [
            {
                title: '项目',
                width: 160,
                dataIndex: 'projectName',
                fixed: 'left'
            }, {                
                title: '楼层',
                width: 80,
                dataIndex: 'floorNumber'
            }, {
                title: '门牌号',
                width: 100,
                dataIndex: 'houseNo'
            }, {
                title: '招商状态',
                width: 100,
                dataIndex: 'investmentStatus',
                render: (text) => {return text==1?"可招商":"不可招商"}
            }, {
                title: '出租状态',
                width: 100,
                dataIndex: 'rentStatus',
                render: (text) => { switch(text) { 
                        case 0 : return '不可租'; 
                        case 1 : return '未出租'; 
                        case 2 : return '已出租'; 
                    } 
                }
            }, {              
                title: '建筑面积(m²)',
                width: 150,
                dataIndex: 'buildingArea'
            }, {              
                title: '使用面积(m²)',
                width: 150,
                dataIndex: 'useArea'
            }, {              
                title: '套内面积(m²)',
                width: 150,
                dataIndex: 'compriseArea'
            }, {              
                title: '预计价格(元/m²/日)',
                width: 160,
                dataIndex: 'estimatePrice'
            }, {              
                title: '当前物业费(元/m²)',
                width: 150,
                dataIndex: 'currentPropertyFee'
            }, {              
                title: '签约价格(元/m²/日)',
                width: 160,
                dataIndex: 'signPrice'
            }, {              
                title: '签约物业费(元/m²)',
                width: 150,
                dataIndex: 'signPropertyFee'
            }, {              
                title: '合同编号',
                width: 150,
                dataIndex: 'contractNumber'
            }, {              
                title: '承租人',
                width: 120,
                dataIndex: 'tenantName'
            }, {              
                title: '合同周期',
                width: 190,
                dataIndex: 'contractPeriod'
            }
        ],
        data: [
        ],
        total:0,
        projectName: [
            {
                "value": 0,
                "name": "十方"
            },
            {
                "value": 1,
                "name": "招商"
            }
        ],
        merchantStatusEnum: [
            {
                "value": 0,
                "name": "不可招商"
            },
            {
                "value": 1,
                "name": "可招商"
            }
        ],
        rentStatusEnum: [
            {
                "value": 0,
                "name": "不可租"
            },
            {
                "value": 1,
                "name": "未出租"
            },
            {
                "value": 2,
                "name": "已出租"
            }
        ],
        isOkBtnShow: false,
        selectParams: {
            keywords:null, 
            pageNo: 1,                // 当前页数
            pageSize: 10,             // 每页条数
            projectId: null,          //项目id
            rentStatus: null,         //出租状态
            merchantStatus: null,     //招商状态
            areaMin: null,            //建筑面积-开始
            areaMax: null             //建筑面积-结束 
        }
    }
    // 组件挂载 请求的初始数据
    componentDidMount() {
        this.getLists();
        this.getProjiectName();
        this.getRentMerchantStatus();
    }
    //搜索框搜索
    onSearchName = (val) => {
        let selectParams = this.state.selectParams;
        selectParams.pageNo = 1;
        selectParams.keywords = val;
        this.setState({
            selectParams
        },() => {
            this.getLists();
        })
    }
    // 搜索框输入内容
    setName = (e) => {
        let selectParams = this.state.selectParams;
        selectParams.keywords = e.target.value;
        this.setState({
            selectParams
        })
    }
    // 切换分页
    onChangePage = (pageNo, pageSize) => {
        let selectParams = this.state.selectParams;
        selectParams.pageNo = pageNo;
        selectParams.pageSize = pageSize;
        this.setState({
            selectParams
        },() => {
            this.getLists();
        })
    }
    // 获取报表列表数据
    getLists() {
        const config = {
            api: API.reportformResource,
            data: this.state.selectParams,
            showLoading: true
        };
        http(config).then(res => {
            const data = res.data;
            const list = data && data.datas.map((item,key) => {
                return {
                    key: key, 
                    projectName: item.projectName ,
                    floorNumber: item.floorNo ,
                    houseNo: item.zoneNo ,
                    investmentStatus: item.merchantStatus ,
                    rentStatus: item.rentStatus ,
                    buildingArea: item.buildingArea,
                    useArea: item.useArea ,
                    compriseArea: item.innerArea ,
                    estimatePrice: item.unitPrice ,
                    currentPropertyFee: item.propertyManagementMoney,
                    signPrice: item.signMoney ,
                    signPropertyFee: item.signPropertyManagementMoney ,
                    contractNumber: item.contractCode ,
                    tenantName: item.customerName ,
                    contractPeriod: (item.startTime&&item.endTime ? (item.startTime + ' 至 ' + item.endTime) : item.startTime)
                }
            })
            this.setState({
                data: list || [],
                total: data && data.totalRecord
            }) 
        
        })
    }
    // 获取项目名称下拉数据
    getProjiectName() {
        const config = {
            api: API.commonProjectfilter,
            // data: this.state.selectParams,
            showLoading: true
        };
        http(config).then(res => {
            const data = res.data;
            const list = data.map((item,key) => {
                return {
                    value: item.encode, 
                    name: item.name 
                }
            })
            this.setState({
                projectName: list || []
            }) 
        
        })
    }
    // 获取出租状态和招商状态下拉数据
    getRentMerchantStatus() {
        const config = {
            api: API.commonRentMerchantSatus,
            showLoading: true
        };
        http(config).then(res => {
            const data = res.data;
            const merchantStatusEnum = data && data.merchantStatusEnum.map((item,key) => {
                return {
                    value: key, 
                    name: item.value
                }
            })
            const rentStatusEnum = data && data.rentStatusEnum.map((item,key) => {
                return {
                    value: key, 
                    name: item.value
                }
            })
            this.setState({
                merchantStatusEnum: merchantStatusEnum || [],
                rentStatusEnum: rentStatusEnum || []
            }) 
        
        })
    }
    // 项目名称筛选
    chooseProjectName = (res) => {
        this.setState({
            selectParams: {
                ...this.state.selectParams,
                projectId: res,
                pageNo:1
            }
        }, () => {
            this.getLists()
        })
    }
    // 招商状态筛选
    chooseMerchantStatusEnum = (res) => {
        this.setState({
            selectParams: {
                ...this.state.selectParams,
                merchantStatus: res,
                pageNo:1
            }
        }, () => {
            this.getLists()
        })
    }
    // 出租状态筛选
    chooseRentStatusEnum = (res) => {
        this.setState({
            selectParams: {
                ...this.state.selectParams,
                rentStatus: res,
                pageNo:1
            }
        }, () => {
            this.getLists()
        })
    } 
    // 输入最小面积
    selectMjMin = (value) => {
        this.setState({
            selectParams: {
                ...this.state.selectParams,
                areaMin: value,
                pageNo:1
            }
        }, function () {
            this.showOkBtn()
        })
    }
    // 输入最大面积
    selectMjMax = (value) => {
        this.setState({
            selectParams: {
                ...this.state.selectParams,
                areaMax: value,
                pageNo:1
            }
        }, function () {
            this.showOkBtn()
        })
    }
    // 输入面积数值，显示确定按钮
    showOkBtn() {
        this.setState({
            isOkBtnShow: (this.state.selectParams.areaMin != '' || this.state.selectParams.areaMax != '') ? true : false
        })
    }
    // 点击面积确定按钮
    doSearch() {
        const selectParams = this.state.selectParams;
        if(selectParams.areaMin&&selectParams.areaMax&&selectParams.areaMin*1>selectParams.areaMax*1){
            message.error('请输入正确的区间');
        }else {
            this.getLists();
        }
    }

    // 导出报表
    export = () => {
        const config = {
            api: API.reportformResourceExportReport,
            data: this.state.selectParams,
            responseType:'blob',
            showLoading: true
        };
        http(config).then(res => {
            if (res.size < 1024) {
                message.error('下载异常，请稍后重试')
            } else { 
            const content = res
            const blob = new Blob([content],{type: 'application/vnd.ms-excel'})
            const fileName = `资源台账报表${moment(new Date()).format('YYYY-MM-DD HH:mm')}.xlsx`
            if ('download' in document.createElement('a')) { // 非IE下载
                const elink = document.createElement('a')
                elink.download = fileName
                elink.style.display = 'none'
                elink.href = URL.createObjectURL(blob)
                document.body.appendChild(elink)
                elink.click()
                URL.revokeObjectURL(elink.href) // 释放URL 对象
                document.body.removeChild(elink)
            } else { // IE10+下载
                navigator.msSaveBlob(blob, fileName)
            }
        }
        })
    }
    // 重置 筛选条件
    reset = () => {
        this.setState( {
            isReset:true,
            isOkBtnShow: false,
            selectParams: {
                ...this.state.selectParams,
                keywords:null, 
                projectId: null,          //项目id
                rentStatus: null,         //出租状态
                merchantStatus: null,     //招商状态
                areaMin: null,            //建筑面积-开始
                areaMax: null             //建筑面积-结束 
            }
        },() => {
            this.setState({
                isReset:false,
            })
            this.getLists();
        })      
    }
    render() {
        const selectParams = this.state.selectParams;

        return (
            <div className="report-con">
                <div className='report-header'>
                    <Breadcrumb>
                        <BreadcrumbItem>报表中心</BreadcrumbItem>
                        <BreadcrumbItem>资源台账报表</BreadcrumbItem>
                    </Breadcrumb>
                    <Button type="primary" className='download' onClick={this.export}>下载报表</Button>
                </div>
                <div className='report-list'>             
                    {
                        !this.state.isReset?(
                            <div className='manage-filter'>
                                <Search
                                    placeholder="请输入承租人、门牌号"
                                    value={this.state.selectParams.keywords}
                                    onSearch={this.onSearchName}
                                    onChange={this.setName}
                                    className='manage-search'
                                    style={{ float: 'left', marginRight: 12 }} 
                                />
                                <Select style={{ float: 'left', width: 120, marginRight: 12 }} data={this.state.projectName} placeholder="项目名称" selectMethod={this.chooseProjectName} />
                                <Select style={{ float: 'left', width: 120, marginRight: 12 }} data={this.state.rentStatusEnum} placeholder="出租状态" selectMethod={this.chooseRentStatusEnum} />
                                <Select style={{ float: 'left', width: 120, marginRight: 12 }} data={this.state.merchantStatusEnum} placeholder="招商状态" selectMethod={this.chooseMerchantStatusEnum} />
                                建筑面积 <NumericInput className='input' placeholder="最小值"
                                    style={{ marginLeft: '8px', width: '110px' }}
                                    onChange={this.selectMjMin} value={selectParams.areaMin}
                                    suffix={
                                        <div>m²</div>
                                    }
                                /> ~ <NumericInput className='input' placeholder="最大值" style={{ width: '110px' }} onChange={this.selectMjMax} value={selectParams.areaMax}
                                    suffix={
                                        <div>m²</div>
                                    }
                                />
                                <Button style={{ background: '#FBBC06', color: '#000000', marginLeft: '10px', display: this.state.isOkBtnShow ? 'inline' : 'none' }} onClick={() => this.doSearch()}>确定</Button>
                                <span className="report-reset" style={{cursor: 'pointer', marginLeft: '10px'}} onClick={this.reset}>重置</span>
                            </div>
                        ):(null)
                    }
                    
                    <Table columns={this.state.columns} data={this.state.data} total={this.state.total} change={this.onChangePage} current={this.state.selectParams.pageNo} scroll={{ x: 2070 }}/>
                </div>
            </div>
        );
    }
}

export default ResourceReport;