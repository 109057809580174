import React, { Component, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Form, Breadcrumb, Button, Input, Select, Modal, Icon, message, TreeSelect } from 'antd';
import Select2 from 'CMPT/common/Select';
import SettingAuth from 'CMPT/common/auth/SettingAuth';
import Regexp from '@/utils/regex';
import http from '@/http';
import { API } from '@/http/api';
import { getUserAuthConfig } from '@/utils/authConfig';

import './SettingOrganizationStaffForm.less';


const BreadcrumbItem = Breadcrumb.Item;
const FormItem = Form.Item;
const Option = Select.Option;

export default function SettingOrganizationStaffForm({ match, history }) {
    const [isNew, toggleNew] = useState(true);

    const [staff, setStaff] = useState({});
    const [authMapInit, setAuthMapInit] = useState(null);

    const [stateValue, setStateValue] = useState();

    const [roleList, setRoleList] = useState([]);
    const [statusList, setStatusList] = useState([
        {
            idCode: '1',
            name: '启用',
        },
        {
            idCode: '2',
            name: '停用',
        },
    ]);

    const [submitLoading, toggleSubmitLoading] = useState(false);
    const [showAuth, toggleAuth] = useState(false);
    const [showNewSuccess, toggleNewSuccess] = useState(false);

    const lastRoleCode = useRef();
    const initStaffDone = useRef(true);
    const currentDepartmentCode = useRef();
    const timesOfChangeDepartment = useRef(0);

    let baseForm, settingAuth;

    useEffect(() => {
        resetCurrent();
        setStatusList([
            {
                idCode: '1',
                name: '启用',
            },
            {
                idCode: '2',
                name: '停用',
            },
        ]);

        const isNew = match.path.endsWith('/staff/new');
        toggleNew(isNew);
        if (isNew) {
            initNewForm();
        } else {
            const id = match.params.id;
            initModifyForm(id);
        }
    }, [match.path]);

    function resetCurrent() {
        lastRoleCode.current = null;
        initStaffDone.current = true;
        currentDepartmentCode.current = null;
        timesOfChangeDepartment.current = 0;
    }

    function initModifyForm(id) {
        getStaff(id);
        loadRole();
    }

    function getStaff(id) {
        http({
            api: API.userDetail,
            path: {
                idCode: id,
            },
            showLoading: true
        }).then(data => {
            const staff = data.data;
            initStaffDone.current = false;
            setStaff(staff);
        });
    }


    function loadRole() {
        http({
            api: API.roleGetList,
            data: {
                pageNo: 1,
                pageSize: 10
            },
            showLoading: true
        }).then(ajaxData => {
            const data = ajaxData.data;
            const datas = (data && data.datas) || [];
            setRoleList(datas);
        });
    }

    function initNewForm() {
        loadRole();
    }

    function submit() {
        baseForm.props.form.validateFields((errors, values) => {
            if (!errors) {
                toggleSubmitLoading(true);
                values.status = values.status == '停用' ? 2 : values.status == '启用' ? 1 : values.status
                const data = {
                    ...values
                }
                let api = API.userAdd;
                if (!isNew) {
                    data.id = match.params.id;
                    api = API.userEdit;
                }
                http({
                    api,
                    data,
                    successTip: '保存成功',
                    showLoading: true
                }).then(ajaxData => {
                    toggleSubmitLoading(false);
                    if (ajaxData.success) {
                        if (isNew) {
                            setStaff({
                                ...values,
                                name: ajaxData.data.name,
                                username: ajaxData.data.username,
                            });
                            toggleNewSuccess(true);
                        } else {
                            history.replace('/app/crm/setting/staff');
                        }
                    }
                }).catch(error => {
                    toggleSubmitLoading(false);
                });
            }
        });
    }



    function cancel() {
        if (history.length > 1) {
            history.goBack();
        }
    }

    function setForm(form) {
        baseForm = form;
        if (!initStaffDone.current && form) {
            const { name, identity, mobile, roleId, userName, status } = staff;
            let s = status == 1 ? '启用' : '停用'
            baseForm.props.form.setFieldsValue({
                name,
                identity,
                mobile,
                roleId,
                userName,
                status: s
            });
            initStaffDone.current = true;
        }
    }

    function gotIt() {
        toggleNewSuccess(false);
        history.replace('/app/crm/setting/staff');
    }


    const isEdit = !isNew;

    return (
        <div className='setting-organization-staff-form'>
            <Breadcrumb>
                <BreadcrumbItem>设置中心</BreadcrumbItem>
                <BreadcrumbItem><Link to='/app/crm/setting/staff'>员工管理</Link></BreadcrumbItem>
                <BreadcrumbItem>{isNew ? '新增员工' : '编辑员工'}</BreadcrumbItem>
            </Breadcrumb>
            <div className='staff-form-info'>
                {isEdit && <div className="staff-name">账号：{staff.username}</div>}
                <WrapperStaffBaseInfoForm wrappedComponentRef={setForm} isNew={isNew} roles={roleList} statuss={statusList} />
            </div>
            <div className='staff-form-operate'>
                <Button type="primary" onClick={submit} loading={submitLoading} size='large'>保存</Button>
                <Button onClick={cancel} size='large'>取消</Button>
            </div>
            <Modal
                title="账号创建成功"
                visible={showNewSuccess}
                onOk={gotIt}
                closable={false}
                maskClosable={false}
                footer={[
                    <Button style={{ "float": "none" }} type="primary" onClick={gotIt} size='large'>我知道了</Button>
                ]}
            >
                <div className='staff-info'>
                    <span>账号：{staff.username}</span>
                    <span>用户名：{staff.name}</span>
                </div>
                <div className='staff-pw-info'>密码：<span className='account-pw-tip'>初始密码已通过短信发送，</span> <span className='account-pw-warning'>请通知员工尽快修改密码！</span></div>
            </Modal>
        </div>

    );
}

class StaffBaseInfoForm extends Component {
    render() {
        const { form: { getFieldDecorator }, isNew, roles, statuss, displayAuth, stateValue, setStateValue, name, identity, mobile } = this.props;
        return (
            <Form >
                <FormItem
                    label="员工姓名"
                >
                    {getFieldDecorator('name', {
                        rules: [{
                            max: 20, message: '员工名称不超过20个字',
                        }, {
                            required: true, message: '员工名称不能为空',
                        }],
                    })(
                        <Input disabled={isNew ? false : true} placeholder="请输入" className="staff-input" maxLength={20} />
                    )}
                </FormItem>
                <FormItem
                    label="身份证号"
                >
                    {getFieldDecorator('identity', {
                        rules: [{
                            type: 'string', pattern: Regexp.idCard, message: '身份证号格式错误',
                        }, {
                            required: true, message: '身份证号不能为空',
                        }],
                    })(
                        <Input disabled={isNew ? false : true} placeholder="请输入" className="staff-input" maxLength={18} />
                    )}
                </FormItem>
                <FormItem
                    label="手机号"
                >
                    {getFieldDecorator('mobile', {
                        rules: [{
                            type: 'string', pattern: Regexp.phone, message: '手机号格式错误',
                        }, {
                            required: true, message: '手机号不能为空',
                        }],
                    })(
                        <Input placeholder="请输入" className="staff-input" maxLength={11} />
                    )}
                </FormItem>
                <FormItem
                    label="分配角色"
                >
                    {getFieldDecorator('roleId', {
                        rules: [{ required: true, message: '分配角色不能为空' }],
                    })(
                        <Select placeholder="请选择" className='staff-select' optionFilterProp="children" >
                            {
                                roles.map(({ id, name }) => (<Option value={id} key={id}>{name}</Option>))
                            }
                        </Select>
                    )}
                </FormItem>
                <FormItem
                    label="账号状态"
                >
                    {getFieldDecorator('status', {
                        rules: [{
                            required: true, message: '账号状态不能为空',
                        }],
                    })(
                        <Select placeholder="请选择" className='staff-select' optionFilterProp='children' >
                            {
                                statuss.map(({ idCode, name }) => (<Option value={idCode} key={idCode}>{name}</Option>))
                            }
                        </Select>
                    )}
                </FormItem>
            </Form>
        );
    }
}

const WrapperStaffBaseInfoForm = Form.create({ name: 'StaffBaseInfoForm' })(StaffBaseInfoForm);
