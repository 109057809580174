import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
    Layout, Menu, Icon
} from 'antd';
import './SiderMenu.less';

const { Item: MenuItem, SubMenu } = Menu;
const { Sider } = Layout;

class SiderMenu extends Component {
    constructor(props) {
        super();
        const { defaultOpenKeys } = props;

        let openKeys = [];
        if (defaultOpenKeys) {
            openKeys = [...defaultOpenKeys];
        }

        this.state = {
            openKeys
        }
    }

    componentDidUpdate(preProps) {
        if (this.props !== preProps) {
            const { defaultOpenKeys, secondLevelMenu } = this.props;

            if (Array.isArray(secondLevelMenu)) {
                let openKeys = []
                if (defaultOpenKeys) {
                    openKeys = [...defaultOpenKeys];
                }

                this.setState({
                    openKeys
                });
            }
        }
    }

    getSubMenu = (menu) => {
        const subMenu = menu.subs;
        if (subMenu && subMenu.length) {
            return (
                <SubMenu key={menu.route} title={<span>{getIcon(menu)} <span className='meun-title'>{menu.title}</span></span>}>
                    {
                        subMenu.map(this.getSubMenu)
                    }
                </SubMenu>);
        }
        return this.getMenu(menu);
    }

    getMenu = (menu) => {
        const icon = getIcon(menu);
        return (
            <MenuItem key={menu.route} className={icon && 'has-icon'}>
                <Link to={menu.route}>{icon}<span className='meun-title'>{menu.title}</span></Link>
            </MenuItem>);
    }

    onOpenChange = (openKeys) => {
        this.setState({
            openKeys
        });
    }

    render() {
        const { selectedKey, secondLevelMenu } = this.props;
        const { openKeys } = this.state;

        return (
            <Sider className="aside-menu">
                {Array.isArray(secondLevelMenu) && secondLevelMenu.length ? <Menu
                    openKeys={openKeys}
                    onOpenChange={this.onOpenChange}
                    mode="inline"
                    selectedKeys={[selectedKey]}
                >
                    {
                        secondLevelMenu.map(this.getSubMenu)
                    }
                </Menu> : secondLevelMenu}
            </Sider>
        );
    }
}

export default SiderMenu;

function getIcon({ icon, iconfont }) {
    if (iconfont) {
        return <i className={'icon iconfont ' + iconfont} />;
    }

    /* if (icon) {
        return <Icon type={icon} />;
    } */
    if (icon) {
        return <i className={'icon iconfont ' + icon} />;
    }
}
