import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import ISelect from 'CMPT/common/Select';
import Table from 'CMPT/common/Table';
import http from '@/http';
import { API } from '@/http/api';
import { Input, Row, Col, DatePicker, Icon, Select ,Spin,message,Modal,Button} from 'antd';
import "./finance.less"
const { RangePicker } = DatePicker;
const Option = Select.Option;
const { TextArea } = Input;
const payChannel = ['现金','刷卡','转账','支付宝','微信','','','','其他','','','','','','']

class HistoryPayRecord extends Component {
    state = {
        showModal:false,
        columns: [
            {
                title: '收款时间',
                dataIndex: 'date',
            }, {
                title: '收款金额(元)',
                dataIndex: 'amount',
            }, {
                title: '支付途径',
                dataIndex: 'channel',
                render:(value)=>{
                    return (
                        <div>{payChannel[value-1]}</div>
                    )
                }
            }
        ],
        textDes:{
            title:'历史收款信息',
            t1:"实收金额",
            t2:'剩余应收金额'
        },
        data: [],
    }
    componentWillReceiveProps(props){
        const {billInfo} = props;
        console.log('首付款',billInfo)
        let textDes = {
            title:'历史收款信息',
            t1:"实收金额",
            t2:'剩余应收金额'
        };
        if(billInfo.budgetType == 1){
            let temp = this.state.columns;
            temp[0].title = '收款时间';
            temp[1].title = '收款金额(元)';
            this.setState({
                columns:temp
            })
            textDes = {
                title:'历史收款信息',
                t1:"实收金额",
                t2:'剩余应收金额'
            }
        }
        if(billInfo.budgetType == 2){
            let temp = this.state.columns;
            temp[0].title = '付款时间';
            temp[1].title = '付款金额(元)';
            this.setState({
                columns:temp
            })
            textDes = {
                title:'历史付款信息',
                t1:"实付金额",
                t2:'剩余应付金额'
            }
        }
        this.setState({
            textDes:textDes
        })
    }
    componentDidMount(){
        
    }
    getHisInfo(){
        http({
            api:API.billpayGetPaymentList,
            data:{
                // budgetType:1, //1收2付
                billEncode:this.props.match.params.id,
                auditStatus:2
            }
        }).then((res)=>{
            if(res.code == '0'){
                this.setState({
                    data:res.data
                })
            }           
        })
    }
    hisCancel(){
        this.setState({
            showModal:false
        })
    }
    showHis(){
        this.getHisInfo();
        this.setState({
            showModal:true
        })
    }
    render(){
        const {billInfo} = this.props;
        const {textDes} = this.state;
        return (
            <div>
                <Modal
                    className="showModal"
                    title={textDes.title}
                    closable={false}
                    visible={this.state.showModal}
                    width={400}
                    footer={null}
                    closable
                    maskClosable
                    onCancel={this.hisCancel.bind(this)}
                >     
                    <div className="fs12 cx-his-modal">
                        <Row className="mb16">
                            <span>{textDes.t1}：{billInfo.paidAmount}元</span>  
                            <span style={{marginLeft:'32px'}}>{textDes.t2}：{billInfo.couldPayAmount}元</span>  
                        </Row>
                        <Row className="mb24">
                            <Table columns={this.state.columns} data={this.state.data} pagination={false}/>
                        </Row>
                    </div>        
                </Modal>
                <Button type="link" onClick={this.showHis.bind(this)}>{textDes.title}</Button>
            </div>
        )
    }
}

export default HistoryPayRecord;