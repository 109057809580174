import Table from 'CMPT/common/Table';
import http from '@/http';
import { API } from '@/http/api';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Tabs, Badge, message } from 'antd';
import EmptyView from "CMPT/widget/EmptyView";
import './List.less';
import TableRenderHelp from "@/utils/TableRenderHelp.js";
import FilterPanel from "CMPT/common/FilterPanel";
import { FilterType } from '@/utils/fliter';
import { Status } from "./enum";
import AcceptDialog from "./AcceptDialog"
import { formatLinkSearch } from "@/utils";

const { TabPane } = Tabs;
const BreadcrumbItem = Breadcrumb.Item;

class List extends Component {


    state = {

        acceptObj: undefined,//要受理的对象
        filterConfig: this.createFilterConfig(),
        columns: this.createColumns(),


        status: '0',// 默认是待受理
        filterParm: undefined,// 保存筛选面板返回的条件，翻页时 需要
        httpData: [],
        tempData: {
            isLoading: true,
        },

        total: 0, // 当前数据的总数
        pageNo: 1, // 当前所展示的页码
        pageSize: 10, // 每页条数 
    };

    onChangePage = (pageNo, pageSize, sort) => {
        this.setState({
            pageNo, pageSize
        }, this.doQueryList)
    }

    createColumns() {
        let help = new TableRenderHelp();
        return [
            help.createRenderColumn("信息名称", "demandInfo", "demandInfo", help.createRenderLongString(30)),
            help.createCommonColumn("咨询类型", "typeName", "typeName"),
            help.createCommonColumn("提交人", "contactsName", "contactsName"),
            help.createCommonColumn("所属企业", "contactsCompany", "contactsCompany"),
            help.createCommonColumn("业务区域", "contactsRegion", "contactsRegion"),
            help.createTimeColumn("提交时间", "submitDate", "submitDate"),
            help.createEnumColumn2("受理状态", "status", "status", Status),

            {
                title: "操作",
                key: "operate",
                fixed: 'right',
                render: (text, record, index) => {
                    return (
                        <div>
                            <Link to={{
                                pathname: '/app/crm/consultList/detail/' + record.consultingServiceCode,
                                search: "?tab=" + this.state.status + "&pageNo=" + this.state.pageNo
                            }}
                                className="table-operate-btn">查看</Link>


                            {/* 除了 受理完成、无效信息  其他的都可以受理的. 状态 0待受理 1受理中 2受理完成 3无法受理 4无效信息 */}
                            {record && typeof (record.status) != "undefined" && record.status != Status.SOLVED && record.status != Status.INVALID && <span className="table-operate-btn" onClick={() => this.showAcceptDialog(record)}
                                className="table-operate-btn">受理</span>}
                        </div>
                    )
                }
            }
        ];
    };

    showAcceptDialog = (item) => {
        this.setState({ acceptObj: item });
    }

    hiddenAcceptDialog = () => {
        this.setState({ acceptObj: undefined });
    }

    refresh = () => {
        // 不修改任何 筛选条件
        this.doQueryList();
        this.getTabsCount();
    }


    createFilterConfig() {
        return {
            config: [
                this.createRow1(),
            ],
            btnLast: true
        };
    }

    createRow1() {
        return {
            childList: [
                {
                    type: FilterType.INPUT,
                    label: '提交人',
                    config: {
                        field: 'contactsName',
                        placeholder: '姓名/手机号码'
                    }
                },
                {
                    type: FilterType.INPUT,
                    label: '',
                    config: {
                        field: 'contactsCompany',
                        placeholder: '所属企业'
                    }
                },
                {
                    type: FilterType.API_SELECT,
                    label: '',
                    config: {
                        placeholder: '咨询类型',
                        field: 'typeCode',
                        api: API.consultTypeList,
                        showSearch: true,
                        valueField: 'typeCode',
                        nameField: 'typeName'
                    }
                },
                {
                    type: FilterType.INPUT,
                    label: '',
                    config: {
                        field: 'contactsRegion',
                        placeholder: '业务区域'
                    }
                },
                {
                    type: FilterType.INPUT,
                    label: '',
                    config: {
                        field: 'demandInfo',
                        placeholder: '信息内容关键字'
                    }
                },

            ]
        }
    }

    createQueryParam = () => {
        let { filterParm, pageNo, pageSize, status } = this.state;

        let param = {
            pageNo,
            pageSize
        };


        if (status != -1) {//-1表示全部，就不需要传 这个属性
            param.status = status;
        }


        filterParm && Object.keys(filterParm).forEach(key => {
            if (filterParm[key] || filterParm[key] === 0) {
                param[key] = filterParm[key];
            }
        });


        return param;
    }


    doQueryList = () => {
        // setup 1:基本入参
        const config = {
            api: API.consultList,
            data: this.createQueryParam(),
            showLoading: true
        };


        // setup 2:请求，并初步处理
        http(config).then(res => {
            let tempData = this.state.tempData;
            tempData.isLoading = false;

            if (res && res.code == 200 && res.data && res.data.datas) {
                let data = res.data;

                this.setState({
                    httpData: data.datas || [],
                    tempData,
                    total: Number(data.total),
                    pageNo: data.pageNo
                });
            } else {
                message.error(res.message || '列表查询失败');
            }
        });
    };

    onFilterParmChange = (filterParm) => {
        this.setState({ filterParm, pageNo: 1 }, this.doQueryList);// 每次搜索，从第1页取数据
    }

    componentWillMount() {
        const formatData = formatLinkSearch(this.props.location.search);

        let tab = formatData.tab;
        let pageNo = formatData.pageNo;
        if (typeof (tab) != "undefined" && typeof (pageNo) != "undefined") { // 从详情返回的，这两个参数同时不为空
            this.setState({ status: tab, pageNo }, this.init);
        } else {
            this.init();
        }


    }

    init = () => {
        this.doQueryList();
        this.getTabsCount();
    }

    changeTabs = (value) => {
        this.setState({ status: value, pageNo: 1 }, this.doQueryList);
        this.getTabsCount()
    }

    getTabsCount = () => {
        const config = {
            api: API.consultCount,
        }
        http(config).then(res => {
            if (res && res.success && res.data) {
                this.setState({ menuNumber: res.data })
            }
        })
    }

    render() {
        const { status, filterConfig, tempData, httpData, columns, pageNo, total, menuNumber, acceptObj } = this.state;

        let isLoading = tempData.isLoading;
        // // 状态 0待受理 1受理中 2受理完成 3无法受理 4无效信息

        return (<div className='container'>
            <Breadcrumb>
                <BreadcrumbItem>{<Badge count={menuNumber && menuNumber.pendingCounts}> 咨询服务 </Badge>}</BreadcrumbItem>
            </Breadcrumb>

            <div className='content_box'>
                <Tabs defaultActiveKey={status + ''} onChange={this.changeTabs}>
                    <TabPane tab="全部" key="-1" className='tab'>
                    </TabPane>
                    <TabPane
                        tab={"待受理" + (menuNumber && menuNumber.pendingCounts ? "(" + menuNumber.pendingCounts + ")" : '')}
                        key="0" className='tab'>
                    </TabPane>
                    <TabPane
                        tab={"受理中" + (menuNumber && menuNumber.acceptingCounts ? "(" + menuNumber.acceptingCounts + ")" : '')}
                        key="1" className='tab'>
                    </TabPane>
                    <TabPane tab="受理完成" key="2" className='tab'>
                    </TabPane>
                    <TabPane tab="无法受理" key="3" className='tab'>
                    </TabPane>
                    <TabPane tab="无效信息" key="4" className='tab'>
                    </TabPane>
                </Tabs>
                <FilterPanel data={filterConfig} notInitNotify={true} onFilterChange={this.onFilterParmChange} />
                {httpData && httpData.length > 0 ? (
                    <Table
                        rowKey={(record, index) => (record.code + "_" + index)}
                        columns={columns}
                        selfProcessData={httpData}
                        total={total}
                        change={this.onChangePage}
                        current={pageNo}
                    />
                ) : (isLoading ? "" : <EmptyView tips="暂无数据" />)}
            </div>


            {acceptObj && <AcceptDialog item={acceptObj} refresh={this.refresh} close={this.hiddenAcceptDialog} />}
        </div>);
    }


}


export default List;