import {
    AJAX_TYPE
} from '../config';

// 楼栋

const prefix = 'express';

export default {
    // 售后物流
    [`${prefix}After`]: {
        url: '/web/mgr/express/query/after',
        type: AJAX_TYPE.POST
    },
    // 订单物流
    [`${prefix}Order`]: {
        url: '/web/mgr/express/query/order',
        type: AJAX_TYPE.POST
    },
}