import React, { Component } from "react";
import { Tooltip, Modal, message } from "antd";
import { Link } from "react-router-dom";

import Table from "CMPT/common/Table";
import http from "@/http";
import { API } from "@/http/api";
import { formatLinkSearch } from "@/utils";

import "./list.less";
import userUtils from '@/utils/user';
import { AUTH_KEYS } from "@/utils/auth";
import { nominalTypeHack } from "prop-types";
const { checkAuthByKey } = userUtils;
const canShowEditAuth = checkAuthByKey(AUTH_KEYS.SETTING_CENTER_MANAGE_ACCOUNT_MANAGE_EDIT); // 编辑权限；

class EnterpriseAuthList extends Component {
  state = {
    searchValue: "", // 搜索框内容

    total: 0, // 当前数据的总数
    currentPageCount: 0, // 当前页的数量
    pageNo: 1, // 当前所展示的页码
    currentPageCount: 0, //当前页面的数量

    selectParams: {
      // 接口入参，必须放这里。方便分页时保存信息
      authenticationStatus: this.props.state, // 审批状态
      pageNo: 1, // 需要查询的页数
      pageSize: 10, // 每页条数
    },
    columns: [
      {
        title: "序号",
        key: "code", //(text,record,index)=>index,
        render: (text, record, index) => {
          return <span>{index + 1}</span>;
        },
      },
      // {
      //   title: "员工UM账号",
      //   dataIndex: "umAccount",
      //   key: "umAccount",
      //   render: (text, record) => {
      //     //待提交状态的，打开编辑页
      //     let value;
      //     if (text && text.length > 20) {
      //       value = (
      //         <Tooltip placement="top" title={text}>
      //           {text.slice(0, 20)}...
      //         </Tooltip>
      //       );
      //     } else {
      //       value = text;
      //     }

      //     return <span>{value}</span>;
      //   },
      // },
      {
        title: "姓名",
        dataIndex: "name",
        key: "name",
        render: (text, record) => {
          return <span>{text}</span>;
        },
      },
      {
        title: "手机号",
        dataIndex: "phone",
        key: "phone",
        render: (text, record) => {

          return <span>{text ? text : "-"}</span>;
        },
      },
      {
        title: "部门",
        dataIndex: "organizationName",
        key: "organizationName",
        render: (text, record) => {

          return <span>{text ? text : "-"}</span>;
        },
      },
      // {
      //   title: "账号角色",
      //   dataIndex: "roleName",
      //   key: "roleName",
      //   render: (text, record) => {
      //     return <span>{text || "-"}</span>;
      //   },
      // },
      {
        title: "账号状态",
        dataIndex: "statusName",
        key: "statusName",
        render: (text, record) => {
          return <span>{text || "-"}</span>;
        },
      },
      // {
      //   title: "创建时间",
      //   dataIndex: "createTime",
      //   key: "createTime",
      //   render: (text, record) => {
      //     return <span>{text}</span>;
      //   },
      // },
      {
        title: "操作",
        key: "operate",
        render: (text, record, index) => {
          return (
            <div>
              <span className="table-operate-btn">
                {
                  canShowEditAuth ?
                  <Link
                    to={
                    `/app/crm/setting/account/detail/${record.code}`
                    }
                    className="operate"
                  >
                    编辑
                  </Link> : null
                }
                
              </span>
              <span className="table-operate-btn delete" onClick={() => {this.deleteUser(record)}}>删除</span>
            </div> 
          );
        },
      },
    ],
    data: [],
  };

  componentWillMount() {
    console.log("componentWillMount", this.props);
    if (this.props.location) {
      // 获取参数
      const search = this.props.location.search;
      const formatData = formatLinkSearch(search);

      // 解析
      let pageNo1 = null;
      let currentageCount1 = null;

      if (formatData && formatData.backPageNo) {
        pageNo1 = formatData.backPageNo;
      }

      // 赋值
      this.setState({
        selectParams: {
          authenticationStatus: this.props.state, // 审批状态
          pageNo: pageNo1, // 当前页数
          pageSize: 10, // 每页条数
        },
      });
    }
  }

  //初始化
  componentDidMount() {}

  //分页
  onChangePage = (pageNo, pageSize, sort) => {
    //console.log(pageNo, pageSize, sort, '====')

    this.getListFun(
      { type: "pageNo", data: pageNo },
      { type: "pageSize", data: pageSize }
    );
  };
  getListFun(obj1, obj2) {
    let selectParams = this.state.selectParams;
    // selectParams.pageNo = 1;

    if (obj1) {
      selectParams[obj1.type] = obj1.data;
    }
    if (obj2) {
      selectParams[obj2.type] = obj2.data;
    }
    this.setState(
      {
        selectParams,
      },
      () => {
        this.getLists();
      }
    );
  }
  deleteUser = (record) => {
    Modal.confirm({
      title: '删除员工',
      content: `确认删除该员工【${record.name}】吗？`,
      icon: 'none',
      onOk: () => {
        const config = {
          api: API.accountUpdate,
          data: {
            code: record.code,
            deleteFlag: 1
          },
          showLoading: true,
        };
        http(config).then((res) => {
          if (res && res.success) {
            message.success('删除成功');
            setTimeout(() => {
              this.props.getList()
            }, 1000);
          }
        });
      }
    })
  }

  render() {
    const selectParams = this.state.selectParams;
    const { searchValue } = this.state;
    return (
      <div className="enterprise-auth-list">
        <Table
          rowKey="code"
          columns={this.state.columns}
          selfProcessData={this.props.tableData}
          total={this.state.total}
          change={this.onChangePage}
          current={selectParams.pageNo}
        />
      </div>
    );
  }
}

export default EnterpriseAuthList;
