import React, { Component } from "react";
import { Breadcrumb, Tabs } from "antd";
import BannerList from "./BannerList";
import "./BannerManager.less";
import userUtil from "@/utils/user";
const {isUs} = userUtil;
const { TabPane } = Tabs;
const BreadcrumbItem = Breadcrumb.Item;

class BannerManager extends Component {
  render() {
    return (
      <div className="banner_all">
        <div>
          <Breadcrumb>
            <BreadcrumbItem>商城Banner管理</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div>
          <Tabs defaultActiveKey="1" className="tabs">
            <TabPane tab="web" key="1" className="tab">
              <BannerList source="1" />
            </TabPane>
            {isUs && <TabPane tab="小程序" key="2" className="tab">
              <BannerList source="2" />
            </TabPane>}
          </Tabs>
        </div>
      </div>
    );
  }
}

export default BannerManager;
