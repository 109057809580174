import {
    AJAX_TYPE
} from '../config';

// 我的工作事项

const prefix = 'myWork';

export default {
    // 待审批账单列表
    [`${prefix}BillList`]: {
        url: '/web/myWork/billList',
        type: AJAX_TYPE.GET
    },
    // 待审批收据模板
    [`${prefix}BillTemplateList`]: {
        url: '/web/myWork/billTemplateList',
        type: AJAX_TYPE.GET
    },
    // 待处理投诉
    [`${prefix}ComplainList`]: {
        url: '/web/myWork/complainList',
        type: AJAX_TYPE.GET
    },
    // 合同过期统计
    [`${prefix}ContactReport`]: {
        url: '/web/myWork/contactReport',
        type: AJAX_TYPE.GET
    },
    // 待审批合同列表
    [`${prefix}ContractList`]: {
        url: '/web/myWork/contractList',
        type: AJAX_TYPE.GET
    },
    // 待审批合同模板
    [`${prefix}ContractTemplateList`]: {
        url: '/web/myWork/contractTemplateList',
        type: AJAX_TYPE.GET
    },
    // 待处理维修工单
    [`${prefix}MaintinList`]: {
        url: '/web/myWork/maintinList',
        type: AJAX_TYPE.GET
    },
}