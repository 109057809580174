/**
 * 忘记密码组件
 */
import React, { Component } from "react";
import { Form, Input, Button, message } from "antd";
import { API } from '@/http/api';
import http, { ajax } from '@/http';
import Regexp from '@/utils/regex';
import md5 from 'js-md5';
import storage, { STORAGE_KEYS } from '@/utils/storage';
import { getSignSha256 } from '../utils/sign'
import "./FindPassword.less";

const { TIME_READ_VERIFICATION } = STORAGE_KEYS;

class LoginForm extends React.Component {

  state = {
    loading: false,
    countDownTime: 60,
    showCountDown: false,
  };

  componentDidMount() {
    const lastTimeReadVerification = storage.get(TIME_READ_VERIFICATION);
    if (lastTimeReadVerification) {
      const countDown = 60 - parseInt((Date.now() - lastTimeReadVerification) / 1000);
      if (countDown > 0) {
        this.startCountDown(countDown);
      } else {
        storage.remove(TIME_READ_VERIFICATION);
      }
    }
  }

  sendVerificationCode = () => {
    const {
      showCountDown,
    } = this.state;

    if (!showCountDown) {
      this.props.form.validateFields(['mobile'], (err, { mobile }) => {
        if (!err) {
          const host = window.location.host == 'oms-crm.idf10.com.cn' ? 'http://dcmanage.s.dcloud.com' : 'http://dcmanage.st15.idf10.com.cn'
          const config = {
            api: {
              url: host+'/paasmanage/ucapi/account/common/msg/code',
              type: 'POST'
            },
            successTip: '验证码已发送',
            headers: {
              appId: '2ef4c027eee911e98a1cd22e1d4c10ad',
            },
            data: { mobile: mobile, type: 5 },
          };
          ajax(config).then(res => {
            if (res.success) {
              this.startCountDown();
            }
          });

          // ajax({
          //   api: API.userGetCodeForget,
          //   data: { mobile: mobile },
          //   successTip: '验证码已发送'
          // }).then(res => {
          //   if (res.success) {
          //     this.startCountDown();
          //   }
          // });

        }
      });
    }
  }


  startCountDown = (countDown) => {
    this.setState({
      showCountDown: true
    });

    if (!countDown) {
      countDown = 60;
      storage.set(TIME_READ_VERIFICATION, Date.now());
    }

    this.setState({
      countDownTime: countDown
    });

    this.timer = setInterval(() => {
      if (this.state.countDownTime > 0) {
        this.setState((prevState, props) => (
          {
            countDownTime: prevState.countDownTime - 1
          }
        ));
      } else {
        storage.remove(TIME_READ_VERIFICATION);
        this.setState({
          showCountDown: false
        })
        clearInterval(this.timer);
      }
    }, 1000);
  }


  componentWillUnmount() {
    clearInterval(this.timer);
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        const data = {
          mobile: values.mobile,
          newPassword: values.newPassword,
          smsAuthCode: values.msgCode,
          sourceType: 2
        }
        var info = getSignSha256(data);
        const host = window.location.host == 'oms-crm.idf10.com.cn' ? 'http://dcmanage.s.dcloud.com' : 'http://dcmanage.st15.idf10.com.cn' //这里还要加判断'
        const config = {
          api: {
            url: host+'/paasmanage/ucapi/account/getBackPassword',
            type: 'POST'
          },
          successTip: '重置密码成功！',
          headers: {
            appId: '2ef4c027eee911e98a1cd22e1d4c10ad',
            'Content-Type': "application/json;charset=utf-8",
          },
          data: info
        }
        ajax(config).then(data => {
          this.setState({ loading: false });
          if (data.success) {
            storage.remove(TIME_READ_VERIFICATION);
            this.props.history.replace("/login");
          }
        }).catch(error => {
          this.setState({ loading: false });
        });




        // const request = {
        //   ...values
        // };

        // debugger
        // delete request.confirmPassword;
        // request.newPassword = md5(request.newPassword);


        // ajax({
        //   api: API.userForgetPwd,
        //   data: request,
        //   showLoading: true,
        //   successTip: '重置密码成功！'
        // }).then(data => {
        //   this.setState({ loading: false });
        //   if (data.success) {
        //     storage.remove(TIME_READ_VERIFICATION);
        //     this.props.history.replace("/login");
        //   }
        // }).catch(error => {
        //   this.setState({ loading: false });
        // });


      }
    });
  };

  validateConfirmPw = (_, value, cb) => {
    const newPassword = this.props.form.getFieldValue('newPassword');
    if (newPassword === value) {
      cb();
    } else {
      cb('再次确认新密码错误！');
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        <div className="title">重置密码</div>
        <Form.Item>
          {getFieldDecorator("mobile", {
            rules: [{
              type: 'string', pattern: Regexp.phone, message: '请检查手机号格式',
            }, { required: true, message: "请输入手机号" }]
          })(
            <Input
              autoComplete="off"
              className="login-input"
              placeholder="请输入手机号"
            />
          )}
          <span className={"find-verification-code" + (this.state.showCountDown ? ' show-count-down' : '')} onClick={this.sendVerificationCode} >{this.state.showCountDown ? this.state.countDownTime + 's' : '发送验证码'}</span>
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("msgCode", { rules: [{ required: true, message: "请输入验证码" }] })(
            <Input
              autoComplete="off"
              className="login-input"
              placeholder="请输入验证码"
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("newPassword", { rules: [{ required: true, type: 'string', pattern: Regexp.password, message: "密码为8-15位字母数字组合" }] })(
            <Input
              autoComplete="off"
              className="login-input"
              type='password'
              placeholder="设置新密码(8-15位字母数字组合)"
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("confirmPassword", { rules: [{ required: true, validator: this.validateConfirmPw, message: "再次确认新密码错误！" }] })(
            <Input
              autoComplete="off"
              className="login-input"
              type='password'
              placeholder="再次确认新密码"
            />
          )}
        </Form.Item>
        <Button className="reset-btn" type="primary" htmlType="submit" loading={this.state.loading} block>提交</Button>
      </Form>
    );
  }
}

const WrappedLoginForm = Form.create({ name: 'LoginForm' })(LoginForm);

class Login extends Component {
  render() {
    return (
      <div className="page-findPassword">
        <Button className="back-login-btn" href="#/login">返回登录页</Button>
        <WrappedLoginForm {...this.props}></WrappedLoginForm>
      </div>
    );
  }
}

export default Login;
