import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Button, Modal } from 'antd';
import DisplayAuthRow from 'CMPT/common/auth/DisplayAuthRow';
import AuthContainer from 'CMPT/common/auth/AuthContainer';
import { AUTH_KEYS } from '@/utils/auth';
import http from '@/http';
import { API } from '@/http/api';
import { getReadAuth } from '@/utils/authConfig';
import './SettingOrganizationStaffRead.less';

const BreadcrumbItem = Breadcrumb.Item;

export default function SettingOrganizationStaffRead({ match: { params: { id } }, history }) {
    const [staff, setStaff] = useState({});

    const [showDeleteConfirm, toggleDeleteConfirm] = useState(false);
    const [deleteLoading, toggleDeleteLoading] = useState(false);

    useEffect(() => {
        http({
            api: API.userDetail,
            path: {
                idCode: id,
            },
            showLoading: true
        }).then(data => {
            const staff = data.data;
            const projectAuth = (staff.projects || []).map(({ name }) => name);
            staff.projectAuth = projectAuth;
            staff.userAuth = getReadAuth(staff.functions);
            setStaff(staff);
        });
    }, [id]);

    function showDeleteStaffConfirm(idCode) {
        toggleDeleteConfirm(true);
    }

    function deleteStaff() {
        toggleDeleteLoading(true);

        http({
            api: API.userDelete,
            path: {
                idCode: id
            },
            showLoading: true,
            successTip: '删除成功'
        }).then(result => {
            toggleDeleteLoading(false);
            if (result.success) {
                hideDeleteStaffConfirm();
                history.replace('/app/setting/organization/staff');
            }
        }).catch(erorr => {
            toggleDeleteLoading(false);
        });
    }

    function hideDeleteStaffConfirm() {
        toggleDeleteConfirm(false);
    }

    const { name, userName, mobile, email, identityNumber, departmentName, roleName, userAuth, projectAuth = [] } = staff;

    const secondLevelAuth = [];

    if (userAuth) {
        for (const [code, auth] of Object.entries(userAuth)) {
            if (auth.level === 2) {
                secondLevelAuth.push(<DisplayAuthRow key={code} authMap={userAuth} authCode={code} />);
            }
        }
    }

    return (
        <div className='setting-organization-staff-read'>
            <div className='staff-read-header'>
                <Breadcrumb>
                    <BreadcrumbItem>组织管理</BreadcrumbItem>
                    <BreadcrumbItem><Link to='/app/setting/organization/staff'>员工管理</Link></BreadcrumbItem>
                    <BreadcrumbItem>员工详情</BreadcrumbItem>
                </Breadcrumb>
                <div className='operate'>
                    <AuthContainer authCodes={AUTH_KEYS.SETTING_ORGANIZATION_STAFF_DELETE}><Button type="danger" onClick={showDeleteStaffConfirm}>删除</Button></AuthContainer>
                    <AuthContainer authCodes={AUTH_KEYS.SETTING_ORGANIZATION_STAFF_MODIFY}><Button type="primary" ><Link to={'/app/setting/organization/staff/modify/' + id}>编辑</Link></Button></AuthContainer>
                </div>
            </div>
            <div className='staff-read-info'>
                <div className='info-title'>员工信息</div>
                <div className='info-detail'>员工姓名：{name}</div>
                <div className='info-detail readonly'>手机号：{mobile}</div>
                <div className='info-detail readonly'>邮箱：{email}</div>
                <div className='info-detail readonly'>身份证号：{identityNumber}</div>
                <div className='info-detail'>所属部门：{departmentName}</div>
                <div className='info-detail'>分配角色：{roleName}</div>
                {userAuth && <div className='info-detail auth'>
                    附加功能：
                    <div>
                        {
                            secondLevelAuth
                        }
                    </div>
                </div>}
                <div className='info-detail'>项目权限：{projectAuth.join('、')}</div>
            </div>
            <div className='staff-read-account'>
                <div className='account-title'>账户信息</div>
                <div className='account-info'>
                    <div className='account-name'>账号：{userName}</div>
                    <div className='account-pw'>密码：<span className='account-pw-tip'>密码已通过短信发送</span> <span className='account-pw-warning'>请通知员工尽快修改密码！</span></div>
                </div>
            </div>
            <Modal
                title="确认删除此员工？"
                visible={showDeleteConfirm}
                okText='删除'
                okType='danger'
                confirmLoading={deleteLoading}
                closable={false}
                maskClosable={false}
                onOk={deleteStaff}
                width={400}
                onCancel={hideDeleteStaffConfirm}
            >
            </Modal>
        </div>
    );

}