import React, { Component } from 'react';
import { Input } from 'antd';

export default class OrderConfirmContent extends Component{
    render() {
        const { label, placeholder, length } = this.props
        return (
            <div className="order-confirm-content">
                <p>{label}</p>
                <Input.TextArea placeholder={placeholder} onChange={this.props.onChange} maxLength={length} />
            </div>
        );
    }
}
