import { AJAX_TYPE } from "../config";

// 公告

const prefix = "help";

export default {
  // 新建公告
  [`${prefix}CatalogList`]: {
    url: "/web/crm/helpCatalog/getHelpCatalogList",
    type: AJAX_TYPE.POST
  },
  [`${prefix}CatalogAdd`]: {
    url: "/web/crm/helpCatalog/addCatalog",
    type: AJAX_TYPE.POST
  },
  [`${prefix}CatalogEdit`]: {
    url: "/web/crm/helpCatalog/updateCatalog",
    type: AJAX_TYPE.POST
  },
  [`${prefix}CatalogDel`]: {
    url: "/web/crm/helpCatalog/deleteCatalog",
    type: AJAX_TYPE.POST
  },
  [`${prefix}UpdateCatalogList`]: {
    url: "/web/crm/helpCatalog/updateCatalogList",
    type: AJAX_TYPE.POST
  },
  [`${prefix}VideoList`]: {
    url: "/web/helpVideo/getHelpVideoListByPage",
    type: AJAX_TYPE.POST
  },
  [`${prefix}VideoUpdateVideo`]: {
    url: "/web/helpVideo/updateVideo",
    type: AJAX_TYPE.POST
  },
  [`${prefix}VideoAdd`]: {
    url: "/web/helpVideo/addVideo",
    type: AJAX_TYPE.POST
  },

  [`${prefix}VideoDel`]: {
    url: "/web/helpVideo/deleteVideo",
    type: AJAX_TYPE.POST
  },
  [`${prefix}VideoDetail`]: {
    url: "/web/helpVideo/getVideoDetail",
    type: AJAX_TYPE.GET
  },
  [`${prefix}IssueList`]: {
    url: "/web/crm/helpMessage/getHelpMessageListByPage",
    type: AJAX_TYPE.POST
  },
  [`${prefix}IssueDel`]: {
    url: "/web/crm/helpMessage/deleteMessage",
    type: AJAX_TYPE.POST
  },
  [`${prefix}IssueSort`]: {
    url: "/web/crm/helpMessage/updateMessage",
    type: AJAX_TYPE.POST
  },
  [`${prefix}IssueAdd`]: {
    url: "/web/crm/helpMessage/addMessage",
    type: AJAX_TYPE.POST
  },
  [`${prefix}IssueDetail`]: {
    url: "/web/crm/helpMessage/getMessageDetailForEdit",
    type: AJAX_TYPE.GET
  },
  [`${prefix}Update`]: {
    url: "/web/crm/helpMessage/updateMessage",
    type: AJAX_TYPE.POST
  },
  [`${prefix}FeedBackListByPage`]: {
    url: "/web/helpFeedback/getMessageFeedbackListByPage",
    type: AJAX_TYPE.POST
  }
};
