import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { Breadcrumb, Button, Input, Modal, Icon, Select, DatePicker, Tooltip } from 'antd';
import moment from 'moment';
import Table from 'CMPT/common/Table';
import http from '@/http';
import storage from '@/utils/storage';
import { API } from '@/http/api';
import AuthContainer from 'CMPT/common/auth/AuthContainer';
import { AUTH_KEYS } from '@/utils/auth';
import userUtil from '@/utils/user';
import useActionLoading from 'CMPT/common/hooks/useActionLoading';
import './SettingAnnounceManage.less'

const BreadcrumbItem = Breadcrumb.Item;
const Search = Input.Search;
const Option = Select.Option;
const { RangePicker } = DatePicker;

export default function SettingAnnounceManage({ history }) {
    const [data, setData] = useState({ datas: [], total: 0, pageNo: 1 });
    const [enums, setEnums] = useState({ userIds: [] });

    const [searchValue, setSearchValue] = useState();
    const [type, setTypeValue] = useState();
    const [userCode, setStateValue] = useState();
    const [createTimeValue, setCreateTimeValue] = useState();

    const [showDeleteConfirm, toggleDeleteConfirm] = useState(false);
    const [deleteLoading, takeDeleteAction] = useActionLoading(false);

    const currentManageId = useRef();
    const currentPageSize = useRef(10);
    const currentSearchValue = useRef();
    const noAuthPeople = useRef([]);

    useEffect(() => {
        resetCurrent();
        initEnums();
    }, []);

    useEffect(() => {
        loadTable();
    }, [type, userCode, createTimeValue]);

    function resetCurrent() {
        currentManageId.current = null;
        currentPageSize.current = 10;
        currentSearchValue.current = null;
        noAuthPeople.current = [];
    }

    function initEnums() {
        http({
            api: API.userGetList,
            data: {
                departmentEncode: storage.get('USER').departmentIdCode
            }
        }).then(res => {
            const peopleList = res.data.datas;
            setEnums({
                userIds: transDeptData(peopleList),
            })
        });
    }
    function transDeptData(list) {
        let transList = []
        list.forEach(per => {
            transList.push({ value: `${per.departmentEncode}-${per.idCode}` , name: `${per.departmentName}-${per.name}` })
            // transList.push({ value: per.idCode, name: `${per.departmentName}-${per.name}` })
        })
        return transList
    }

    let createTimeEnd;
    let createTimeStart;
    if (createTimeValue && createTimeValue.length === 2) {
        createTimeStart = createTimeValue[0].format('YYYY-MM-DD');
        createTimeEnd = createTimeValue[1].format('YYYY-MM-DD');
    }
    const searchData = {
        pageNo:1,
        pageSize: currentPageSize.current,
        title: currentSearchValue.current || null,
        deptCode: '',
        userCode: userCode,
        type: type,
        startDate: createTimeStart,
        endDate: createTimeEnd
    }

    // 加载公告列表
    function loadTable() {
        http({
            api: API.announceList,
            data:searchData,
            showLoading: true
        }).then(data => {
            setData(data.data || ({ datas: [], total: 0, pageNo: 1 }));
        });
    }

    const columns = [ {
        title: '公告标题',
        dataIndex: 'title',
        width:'30%',
        className:'announceTitle',
        key: 'title',
        render(text, record) {
            return (<Link to={'/app/setting/announce/read/' + record.encode} className='manage-name'>{text}</Link>);
        }
    }, {
        title: '公告类型',
        dataIndex: 'type',
        width:'10%',
        key: 'type',
        render: (text) => { switch(text) { 
            case 1 : return '通知'; 
            case 2 : return '节日';  
            case 3 : return '奖励'; 
            case 4 : return '惩罚'; 
            } 
        }
    }, {
        title: <span>通知情况</span>,
        render(text, record) {
            const totalNumItem = <span>接收人 <Link key='modify' to={'/app/setting/announce/count/3/' + record.encode } className='manage-operate'>{text.totalNum}</Link></span>;
            const readNumItem = <span>已读 <Link key='modify' to={'/app/setting/announce/count/2/' + record.encode } className='manage-operate'>{text.readNum}</Link></span>;
            const unReadNumItem = <span>未读 <Link key='modify' to={'/app/setting/announce/count/1/' + record.encode} className='manage-operate'>{text.unReadNum}</Link></span>;
            return [ totalNumItem, readNumItem, unReadNumItem];
        },
        width:'25%',
    }, {
        title: '创建人',
        dataIndex: 'createByName',
        key: 'createByName',
        width:'10%',
    }, {
        title: '创建时间',
        dataIndex: 'createTime',
        key: 'createTime',
        width:'15%',
        render(text) {
            return formatTime(text);
        }
    }, {
        title: '操作',
        key: '5',
        width:'10%',
        render(text, record, index) {
                const manageId = record.encode;
                let modify;
                let remove;
                if (userUtil.checkAuthByKey(AUTH_KEYS.SETTING_ANNOUNCE_EDIT)) {
                    modify = <Link key='modify' to={'/app/setting/announce/modify/' + manageId} className='manage-operate'>编辑</Link>;
                }
                if (userUtil.checkAuthByKey(AUTH_KEYS.SETTING_ANNOUNCE_DELETE)) {
                    remove = <span key='delete' className='manage-operate' onClick={() => displayDelete(manageId)}>删除</span>;
                }
                return [ modify, remove];
        }
    }];

    // 公告类型
    const announceType = [
        {
            "key": 1,
            "value": "通知"
        },
        {
            "key": 2,
            "value": "节日"
        },
        {
            "key": 3,
            "value": "奖励"
        },
        {
            "key": 4,
            "value": "惩罚"
        }
    ];

    // 删除公告
    function displayDelete(encode) {
        currentManageId.current = encode;
        toggleDeleteConfirm(true);
    }

    function deleteAnnounce() {
        if (currentManageId.current) {
            takeDeleteAction({
                api: API.announceDelete,
                path: {
                    encode: currentManageId.current
                },
                showLoading: true,
                successTip: '删除成功'
            }, () => {
                hideDeleteConfirm();
                loadTable();
            });
        }
    }

    function hideDeleteConfirm() {
        toggleDeleteConfirm(false);
        currentManageId.current = null;
    }

    // 搜索
    function search(likeName) {
        currentSearchValue.current = likeName;
        searchData.title = likeName;
        loadTable();
    }

    // 搜索框搜索
    function changeSearch(e) {
        setSearchValue(e.target.value);
    }

    // 筛选公告类型
    function changeTypeValue(value) {
        value.forEach(per => {
            setTypeValue(`${value}`);
            searchData.type = `${value}`;
        })
        if(value.length<=0){
            searchData.type = "";
        }
        loadTable();
    }

    // 部门人员筛选
    function changeStateValue(value) {
        setStateValue(value);
        let codes = value.split('-');
        searchData.userCode = codes[1];
        searchData.deptCode = codes[0];
        loadTable();
    }

    // 时间筛选
    function changeDateRange(dates, datesStr) {
        // console.log(dates+'-'+datesStr)
        setCreateTimeValue(dates);
    }


    function reset() {
        searchData.pageNo=1;
        searchData.pageSize=10;
        searchData.title=null;
        searchData.userCode = "";
        searchData.deptCode="";
        searchData.type="";
        setCreateTimeValue(null);
        setSearchValue(null);
        setTypeValue(null);
        setStateValue(undefined);
        loadTable();
        //清除select多选的tags 触发allowclear的事件  ant-select-selection__clear
        let selectTags =  document.getElementsByClassName('ant-select-selection__clear')[0];
        if(selectTags){
            selectTags.click();
        }
    }

    // 分页操作
    function changePager(pageNo, pageSize) {
        searchData.pageNo = pageNo;
        searchData.pageSize = pageSize;
        loadTable();
    }


    return (
        <div className='setting-announce-manage'>
            <div className='manage-header'>
                <Breadcrumb>
                    <BreadcrumbItem>公告列表页</BreadcrumbItem>
                </Breadcrumb>
                <AuthContainer authCodes={AUTH_KEYS.SETTING_ANNOUNCE_ADD}>
                    <Link to='/app/setting/announce/new' className='new-manage-btn' >
                        <Button type="primary">
                            新增公告
                        </Button>
                    </Link>
                </AuthContainer>
            </div>
            <div className='manage-main'>
                <div className='manage-filter'>
                    <Search
                        placeholder="请输入公告标题"
                        value={searchValue}
                        onChange={changeSearch}
                        onSearch={search}
                        className='manage-search'
                    />
                    <Select 
                        allowClear
                        mode="multiple"
                        placeholder="公告类型" 
                        className='manage-filter-type' 
                        defaultValue={type}
                        onChange={changeTypeValue}
                        optionFilterProp="children">
                        {
                            announceType.map(({ key, value }) => (<Option value={key} key={key}>{value}</Option>))
                        }
                    </Select>

                    {/* <Select data={this.state.departmentPerson} notFoundContent={fetching ? this.notFount() : this.notFoundContent()} placeholder="部门-人员" value={params.userId} onDropdownVisibleChange={this.getDetPerData}
                                selectMethod={this.selectChange.bind(this, 'userId')} style={{ width: '220px' }} /> */}
                    <Select placeholder="部门-人员" style={{ width: '200px' }}  className='manage-filter-state' value={userCode} onChange={changeStateValue}>
                        {
                            enums.userIds.map(({ name, value }) => (<Option value={value} key={value}>{name}</Option>))
                        }
                    </Select>
                    创建时间
                    <RangePicker className='manage-filter-date' value={createTimeValue} onChange={changeDateRange} />
                    <span className='manage-filter-reset' onClick={reset}>重置</span>
                </div>
                <Table showEmpty selfProcessData={data.datas || []} current={data.pageNo} total={data.totalRecord} columns={columns} change={changePager} rowKey='encode' />
            </div>
            <Modal
                title="确定要进行删除操作？"
                visible={showDeleteConfirm}
                okText='删除'
                confirmLoading={deleteLoading}
                width={400}
                closable={false}
                maskClosable={false}
                onOk={deleteAnnounce}
                onCancel={hideDeleteConfirm}
            />
        </div>
    );
}

function formatTime(time) {
    if (time) {
        return moment(time).format('YYYY-MM-DD');
    }
    return '';
}
