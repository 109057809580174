let areaTypeConfig = {
    1:'100㎡以内',
    2:'100-300㎡',
    3:'300-500㎡',
    4:'500-1000㎡',
    5:'1000㎡以上'    
}

let  channelTypeConfig = {
    1:'线下-陌生拜访',
    2:'线下-上门',
    3:'线下-朋友介绍',
    4:'线上-第三方渠道',
    5:'线上-回访',
    0:'其他'
}

let industryTypeConfig = {
    1:'IT|通信|电子|互联网',
    2:'金融业',
    3:'房地产|建筑业',
    4:'商业服务',
    5:'贸易|批发|零售|租赁业',
    6:'文体教育|工艺美术',
    7:'服务业',
    8:'文化|传媒|娱乐|体育',
    9:'交通|运输|物流|仓储',
    10:'政府|非盈利机构',
    0:'其他行业'
}

let followupFlagConfig = {
    1:'继续跟进',
    2:'暂不跟进'
}

let followStatusConfig = {
    10:'新商机',
    20:'初次接触',
    30:'签订意向',
    40:'已预订',
    50:'已立项',
    60:'已签约',
    70:'已流失'
}

let lostReasonTypeConfig = {
    1:'需求变更',
    2:'无意向'
}


export {
    channelTypeConfig,industryTypeConfig,areaTypeConfig,followupFlagConfig,followStatusConfig,lostReasonTypeConfig
} 
