import React, { Component } from 'react';
import { Link} from 'react-router-dom'

class JobItemBill extends Component {
    render() {
        return (
            <Link to={{pathname:`/app/operate/finance/approval/detail/${this.props.encode}`}} className="job-item">
              <p className="job-item-title">
                 <span className="job-item-bill-type">{this.props.status == 1 ? '收' : '付'} </span>     {this.props.name}    
              </p>
              <p className="job-item-text">
                {this.props.location}
              </p>
              <p>
                  付款人：{this.props.userName}
              </p>
              <p>
                  发起时间：{this.props.time}
              </p>
            </Link>
        )
    }
}

export default JobItemBill
