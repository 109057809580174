import md5 from "js-md5";
import { API } from '@/http/api';
import http  from '@/http';
export function throttle(func, context, duration = 2000) {
    let firstCall = true;
    let lastRunTime;

    return function() {
        if (firstCall) {
            func.apply(context, arguments);
            firstCall = false;
            lastRunTime = Date.now();
        } else {
            const currentTime = Date.now();
            if (currentTime - lastRunTime >= duration) {
                func.apply(context, arguments);
                lastRunTime = currentTime;
            }
        }
    }
}

/*
 * 深度拷贝
 * @param obj 需拷贝对象
 * */
export const deepclone = (obj) => {
    const cloneObj = Judgetype(obj) === 'array' ? [] : {};
    Object.keys(obj).forEach(key => {
        if ((typeof obj[key] === 'object' || typeof obj[key] === 'function') && obj[key] != null) {
            cloneObj[key] = deepclone(obj[key])
        } else {
            cloneObj[key] = obj[key];
        }
    })
    return cloneObj
}

export const copyObject = (source,target,keyArray) => {
    if(!source || typeof(source) != "object"   || Array.isArray(source)
              || !target  || typeof(target) != "object"   || Array.isArray(target)
              || !keyArray || !Array.isArray(keyArray) || !keyArray.length){
        return ;
    }
    

    keyArray.forEach(key => {
         if(key && typeof(key) == "string"){
            target[''+key] = source[''+key];

         }
    });

}

/*
 * 判断类型
 * @param obj 任何类型数据
 * */
export function Judgetype(obj) {
    const TypesArr = ['Array', 'Date', 'RegExp', 'Object', 'Error'];
    let classtype = {};

    TypesArr.forEach(e => classtype['[object ' + e + ']'] = e.toLowerCase());

    if (obj == null) return String(obj)
    return typeof obj === 'object' ? classtype[Object.prototype.toString.call(obj)] || 'object' : typeof obj
}

/*
 * 函数防抖 高频率操作只在最后一次执行 适用用户输入在输入完后才校验
 * @param fn 回调函数
 * @param wait 等待时间
 * @param immediate 是否及时生效
 * */
export function debounce(fn, wait, immediate) {
    let timer = null;

    return function() {
        const args = arguments,
            context = this;

        if (immediate && !timer) {
            fn.apply(context, args)
        }

        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            fn.apply(context, args)
        }, wait)
    }
}

/*
 * 数组拆解 可将多维数组拆解成一维数组
 * */
Array.prototype.flat = function() {
    return this.toString().split(',').map(item => +item)
}

/*
 * 改变对象的key 因为后端给予的key与你需要的不一样
 * @param data后端给予的数据
 * @param keyMap
 * @param fliter 是否过滤掉其它不用的属性
 * 比如 data为{id:'11',name:'张三'},keyMap为 {id:'key', name:'value'} 转化为 {key:'11',value:'张三'}
 * **/
export function changeObjkey(data, keyMap, fliter) {

    const objs = Object.keys(data).reduce((newData, key) => {
        let newKey = fliter ? keyMap[key] : (keyMap[key] || key)
        newData[newKey] = data[key]
        return newData
    }, {})

    if (fliter) delete objs.undefined

    return objs
}

export function _typeof(type) {
    return Object.prototype.toString.call(type).replace('[object ', '').replace(']', '')
}
// Math.random()不安全，自己生成
Math.seed = new Date().getTime();
Math.randomNumberBySeed = function(max, min) {　　
        max = max || 1;　
        min = min || 0;　　
        Math.seed = (Math.seed * 9301 + 49297) % 233280;　　　　
        var rnd = Math.seed / 233280.0;　　
        return min + rnd * (max - min);
    }
    // 生成唯一id的方法，copy的乾寓
export function getUuid() {
    var CHARS = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');

    var chars = CHARS,
        uuid = [],
        i;
    var r;
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
    uuid[14] = '4';
    for (i = 0; i < 36; i++) {
        if (!uuid[i]) {
            r = 0 | Math.randomNumberBySeed() * 16;
            uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
        }
    }
    uuid = uuid.join('');
    //随机替换 “-”
    var chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    for (var i = 0; i < 4; i++) {
        var id = Math.ceil(Math.randomNumberBySeed() * 35);
        uuid = uuid.replace("-", chars[id]);
    }
    return uuid;
}

/* 电话号码 空格分位 */
let new_value = ''
export function getNewPhone(e) {
    let tel = ''
    if (e) {
        if (e.target) {
            tel = e.target.value.replace(/\s+/g, '').replace(/[^\d]/g, '').replace(/(\d{3})(?=\d)/, '$1 ').replace(/(\d{4})(?=\d)/, '$1 ')
            if ((tel.length === 6 || tel.length === 11) && tel.length > e.target.selectionEnd && new_value.length < tel.length) {
                tel = tel.substr(0, e.target.selectionEnd - 1)
            }
            new_value = tel
        } else {
            tel = e.replace(/\s+/g, '').replace(/[^\d]/g, '').replace(/(\d{3})(?=\d)/, '$1 ').replace(/(\d{4})(?=\d)/, '$1 ')
        }
    }
    return tel
}


/*  将Link中 ?a=b&c=d ，解析成 key/value对象 */
export function formatLinkSearch(ss) {
    if (!ss) {
        return '';
    }

    const ssSplit = ss.split('?')[1].split('&')
    let obj = {}
    ssSplit.forEach(per => {
        let perSplit = per.split('=')
        obj[perSplit[0]] = perSplit[1]
    })
    return obj
}


// ascii排序
export function sortASCII(obj) {
    const arr = [];
    let num = 0;
    let i;
    for (i in obj) {
        arr[num] = i;
        num++;
    }
    const sortArr = arr.sort();
    const sortObj = {};
    for (i in sortArr) {
        sortObj[sortArr[i]] = obj[sortArr[i]];
    }
    return sortObj;
}


// 对象转化为查询字符串
export function obj2qs(obj) {
    if (!obj && !Object.keys(obj).length) {
        return "";
    } else {
        const arr = [];
        for (const key in obj) {
            if (key.length < 50) {
                arr.push(`${key}=${obj[key]}`);
            }
        }
        return arr.join("&");
    }
}

// 生成随机整数
export function RandomTwo() {
    const math = parseInt(Math.randomNumberBySeed() * 100, 10);
    if (math > 9) {
        return math;
    } else {
        return math + 10;
    }
}

/**
 * 对数据对象进行加签
 * @param {object} data 数据对象
 * @param {string} key 客户端密钥
 * @param {string} alg 加签算法类型 md5 | sha256
 */
export function encryptData(data, key, alg) {
    const noce = RandomTwo();
    let cloneData = deepclone(data);
    const timestamp = parseInt(new Date().getTime() / 1000, 10);
    cloneData = Object.keys(cloneData).reduce((acc, k) => {
        let v = data[k];
        // 对象转为字符串才能计算 sign
        if (typeof v === "object") v = JSON.stringify(v);
        // 过滤掉值为空的字段
        return {...acc,
            ...(v === "" ? {} : {
                [k]: v
            })
        };
    }, {});
    // 加料
    cloneData = {
        ...cloneData,
        noce,
        alg,
        timestamp
    };
    let forSign = {...cloneData };
    // ASCII 排序
    forSign = sortASCII(forSign);
    // 转成查询字符串并加上秘钥
    forSign = obj2qs({...forSign, key });
    let sign;
    if (alg === "md5") {
        // 计算签名 - MD5
        sign = md5(forSign);
        return {...data, noce, alg, timestamp, sign };
    }
    // else if (alg === "sha256") {
    //   // 计算签名 - SHA256
    //   sign = sha256(forSign);
    //   return { ...data, noce, alg, timestamp, sign };
    // }
    else {
        return data;
    }
}



//判断当前字符串是否以str开始
if (typeof String.prototype.startsWith != 'function') {
    String.prototype.startsWith = function(str) {
        return this.slice(0, str.length) == str;
    };
}　

if (typeof String.prototype.startsWithIgnoreCase != 'function') {
    String.prototype.startsWithIgnoreCase = function(str) {
        let target = str.toLowerCase();
        return this.toLowerCase().slice(0, target.length) == target;
    };
}　　　
//判断当前字符串是否以str结束
if (typeof String.prototype.endsWith != 'function') {
    String.prototype.endsWith = function(str) {
        return this.slice(-str.length) == str;
    };
}

if (typeof String.prototype.endsWithIgnoreCase != 'function') {
    String.prototype.endsWithIgnoreCase = function(str) {
        let target = str.toLowerCase();
        return this.toLowerCase().slice(-target.length) == target;
    };
}


export function getLogisticsInfo(data, type) {
   return new Promise(resolve => http({
        api: type == 1 ? API.expressOrder:API.expressAfter,
        data: data,
    }).then(res => {
        if (res && res.code == 0) {
            const dataList = res.data || []
            const timeList = dataList.map((per, index) => {
                return {
                    id: index,
                    time: per.acceptTime,
                    text: per.acceptStation
                }
            })
            resolve(timeList)
        }
    }).catch(err => {
        console.log('error', err)
        resolve([])
    })) 
}
Array.prototype.remove = function(value) { 
    var index = this.indexOf(value); 
    if (index > -1) { 
        return  this.splice(index, 1); 
    }

};

String.prototype.replaceAll = function(oldStr, newStr) {
    return this.replace(new RegExp(oldStr,"gm"),newStr); 
 }

String.prototype.rollbackXss= function() { 
    if(  this.length<1){
       return this;
    }

    // 这里 javascript  eval 这些都不再撤回。
    let  value = this.replaceAll("& lt;","<");
    value = value.replaceAll("& gt;",">");
    value = value.replaceAll("& #39;","'");

    return value;
};
