import React, { Component } from "react";
import { Checkbox } from "antd";
import { API } from "@/http/api";
import http from "@/http";

class UserLabelGroup extends React.Component {

    state = {
        labelMap: [],
    }

    componentWillMount() {
        this.queryLabelMap();
    }

    onLabelSelectChange = (checkedValues) => {
        if (this.props.onLabelSelectChange) {
            this.props.onLabelSelectChange(checkedValues);
        }
    }

    queryLabelMap = () => {
        const config = {
            api: API.labelList,
            path: { type: 1 }, // 1表示 用户类型的标签
            showLoading: true
        };

        // 网络请求
        http(config).then(res => {
            if (res && res.success && res.data && res.data instanceof Array && res.data.length > 0) {
                // 转换数据
                let labelMap = [];
                res.data.map(item => {
                    labelMap.push({ label: item.labelName, value: item.labelCode });
                });

                // 去 呈现
                this.setState({ labelMap });
            }

        });
    }


    render() {
        return <Checkbox.Group
            options={this.state.labelMap}
            defaultValue={this.props.defaultValue||[]}
            onChange={this.onLabelSelectChange} />;
    }
}

export default UserLabelGroup;