import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import ISelect from 'CMPT/common/Select';
import Table from 'CMPT/common/Table';
import { AUTH_KEYS } from '@/utils/auth';
import AuthContainer from 'CMPT/common/auth/AuthContainer';
import http from '@/http';
import { API } from '@/http/api';
import { Input, Row, Col, DatePicker, Icon, Select ,Spin,message,Modal} from 'antd';
import EditRefund from './EditRefund';
import "../financeApproval/finance.less"
import  user from '@/utils/user'
const { isManager } = user;
const { RangePicker } = DatePicker;
const Option = Select.Option;
const { TextArea } = Input;

const feeTypeList = ['定金','保证金','合同保证金','装修保证金','物业保证金','租金','手续费','服务费','保洁费','物业费','网费','水费','电费','滞纳金','违约金','搬家费','耗材费','其他费用'];
const billTypeList = ['合同','运营'];
class RefundBill extends Component {
    state = {
        billInfo:{},
        currentBill:'',
        showDeleteModal:false,
        fetching: false,
        dateValue:[],
        currentIndex:0,
        dateType:[{
            name: '应收付时间',
            value: 1
        },
        {
            name: '创建时间',
            value: 2
        }],
        payChannel:[{
            name:'现金',
            value:1
        },{
            name:'刷卡',
            value:2
        },{
            name:'转账',
            value:3
        },{
            name:'支付宝',
            value:4
        },{
            name:'微信',
            value:5
        },{
            name:'其他',
            value:9
        }],
        projects: [],
        fetchingprojects:true,
        man: [],
        bill: [
            { value: 1, name: '合同' },
            { value: 2, name: '运营' }
        ],
        pay: [
            { value: 1, name: '已结清' },
            { value: 2, name: '代收款' },
            { value: 3, name: '1笔收款驳回' },
            { value: 4, name: '1笔收款审批中' }
        ],//1 已结清 2代收款 3一笔收款驳回 4一笔收款审批中
        refundStatusList: [
            { value: 0, name: '审批中' },
            { value: 1, name: '审批驳回' },
            { value: 2, name: '审批通过' }
        ],
        columns: [
            {
                title: '区域名称',
                dataIndex:'floorMsg', 
                width:300,                
            }, {
                title: '账单/费用类型',
                width:200,
                key:'billType',
                render(obj){
                    return (
                        <div>
                            <span className="bill-type" style={{background:obj.billType == 1?'#434BA3':"#B76C1E"}}>{billTypeList[obj.billType-1]}</span>
                            <span className="fee-type">{obj.feeType == 99?'其他费用':feeTypeList[obj.feeType-1]}</span>
                        </div>
                    )
                }
            }, {
                title: '实收金额(元)',
                width:200,
                dataIndex: 'paidAmount',
            }, {
                title: '退款金额(元)',
                width:200,
                dataIndex: 'amount'
            }, {
                title: '退款申请时间',
                width:200,
                dataIndex: 'createTime',
                sorter: true         
            },{
                title: '创建人',
                width:300,
                dataIndex: 'createUserName',
                
            }, {
                title: '退款状态',
                width:200,
                dataIndex: 'auditStatus',
                render(value){
                    const auditStatusList = ['审批中','审批驳回','审批通过'];
                    let color = ''
                    switch(value) { // 0 待审批，1 已驳回 2 通过 3 不通过
                        case 0:
                            color = '#FBBC06'
                            break
                        case 1:
                            color = '#E94437'
                            break;
                        case 3:
                            color = '#EE423D'
                            break
                        default:
                            color = '#E94437'
                    }
                    return (
                        <div><span style={{color: color, marginRight: '6px'}}>●</span>{auditStatusList[value]}</div>
                    )
                }
                
            }, 
            {
                title: '操作',
                fixed: 'right',
                render:(value)=>{
                    return (
                        <div className="flex">
                            <AuthContainer authCodes={AUTH_KEYS.OPERATE_FINANCE_BILL_VIEW}>
                                <Link to={'/app/operate/finance/bill/refundDetail/' + value.billEncode +'/'+value.refundEncode} className="table-operate-btn">详情</Link>
                            </AuthContainer>
                            <AuthContainer authCodes={AUTH_KEYS.OPERATE_FINANCE_BILL_REFUND}>
                                <span style={{display:value.editFlag==1?'':'none'}}>
                                    <EditRefund billInfo={value} type={1} action={this.getList.bind(this)}/>
                                </span>
                            </AuthContainer>
                            {/* <AuthContainer authCodes={AUTH_KEYS.OPERATE_FINANCE_BILL_DEL}>
                                <span className="table-operate-btn" onClick={this.delete.bind(this,value.refundEncode)} style={{display:value.auditStatus==1?'':'none'}}>作废</span>
                            </AuthContainer>                             */}
                        </div>
                    )
                }
            }
        ],
        data: [],
        queryParams: {
            pageNo: 1,
            pageSize: 10,
            projectEncodes: [], //项目id
            feeTypes: [], //费用类型
            billType: undefined, // 账单类型
            endDate:undefined,
            beginDate:undefined,
            createUserEncode:undefined, //创建人
            refundAuditStatus:undefined, //退款状态
            orderByList:[20],
            dateType:4
        },
    }
    componentDidMount() {
        this.getProjectList();
        this.getList()
        if(isManager()){
            this.getCustomerList();
        }    
    }
    deleteBill(){
        http({
            api: API.DropRefund,
            data:{
                refundCode:this.state.currentBill
            }
        }).then(res => {
            if(res.data){
                message.success('账单删除成功');
                this.getList();
            }           
        })
    }
    delete(value){
        this.setState({showDeleteModal:true,currentBill:value});
    }
    handleOk(){
        this.deleteBill();
        this.setState({
            showDeleteModal:false
        })
    }
    handleCancel(){
        this.setState({showDeleteModal:false})
    }
    getList = () => {
        http({
            api: API.billrefundGetList,
            data:this.state.queryParams
        }).then(res => {
            if(res.data){
                console.log("loadCaptcha--------------", res.data.datas);
                let tempData = this.state.queryParams;
                tempData.total = res.data.total;
                tempData.pageNo = res.data.pageNo;
                tempData.pageSize = res.data.pageSize;
                this.setState({
                    data:res.data.datas,
                    queryParams:tempData
                })
            }           
        })
    }
    selectChange = (attr, data) => {
        // console.log('+++++++++')
        // console.log(attr,data);
        let queryParams = this.state.queryParams
        queryParams.pageNo = 1
        queryParams[attr] = data
        this.setState({queryParams})
        this.getList()
    }
    resetParams = () => {
        let data = Object.assign({}, this.state.queryParams, { 
            pageNo: 1,
            pageSize: 10,
            projectEncodes: [], //项目id
            feeTypes: [], //费用类型
            billType: undefined, // 账单类型
            endDate:undefined,
            beginDate:undefined,
            createUserEncode:undefined, //创建人
            refundAuditStatus:undefined, //退款状态
            orderByList:[20],
            dateType:4
         });
        this.setState({queryParams:data,dateValue:[]},()=>{
            this.getList();
        });
    }
    onChange = (pageNo, pageSize,sort) => {
        console.log(sort)
        let queryParams = this.state.queryParams
        if(sort){
            if(sort.columnKey == "createTime"){
                if(sort.order == "ascend"){
                    queryParams.orderByList = [19]
                }
                if(sort.order == "descend"){
                    queryParams.orderByList = [20]
                }           
            }
        }
        
        queryParams.pageNo = pageNo
        queryParams.pageSize = pageSize
        this.setState({queryParams})
        this.getList()
    }
    //改变上报时间
    onChangedate(date,options) {
        console.log(date);
        let data = Object.assign({},this.state.queryParams,{beginDate:options[0],endDate:options[1],pageNo:1,pageSize:10})
        this.setState({queryParams:data,dateValue:date},()=>{
            this.getList();
        });       
    }
    getProjectList(){
        http({
            api: API.commonProjectfilter,
        }).then(res => {
            console.log("loadCaptcha--------------", res.data);
            this.setState({
                projects:res.data,
                fetchingprojects:false
            })
        })
    }
    getCustomerList(value){
        console.log(value)
        http({
            api: API.userGetList,
            data:{
                nameLike:value,
                pageSize:null
            }
        }).then(res => {
            console.log(res);
            this.setState({
                man:res.data.datas
            })
        })
    }
    notFount() {
        return (
            <div style={{ textAlign: 'center', height: '100px', lineHeight: '100px' }}>加载中...</div>
        )
    }
    notFoundContent() {
        return (
            <div className='ant-empty ant-empty-normal ant-empty-small'><div className="ant-empty-image"></div><p className="ant-empty-description">暂无数据</p></div>)
    }
    render() {
        const { queryParams: params,fetching,billInfo ,fetchingprojects} = this.state;
        const options = this.state.projects.map(per => {
            return <Option value={per.encode} key={per.encode}>{per.name}</Option>
        })
        const optionsFee = feeTypeList.map((per,index) => {
            if(per == '其他费用'){
                return <Option value={99} key={99}>{per}</Option>
            }else{
                return <Option value={index+1} key={per}>{per}</Option>
            }     
        })
        const manOptions = this.state.man && this.state.man.map(per => {
            return <Option value={per.idCode} key={per.idCode}>{per.name}-{per.departmentName}</Option>
        })
        return (
            <div className="finance">
            <Modal
                className="passModal"
                title='确认删除此账单'
                closable={false}
                visible={this.state.showDeleteModal}
                onOk={this.handleOk.bind(this)}
                okText='确定'
                width={400}
                onCancel={this.handleCancel.bind(this)}
            >                 
            </Modal>
            <div className="contract-list">
                <div className="contract-search">
                <div className="contract-list-row">
                        <span className='w104'>
                            <ISelect data={this.state.bill} placeholder="账单类型" value={params.billType}
                                    selectMethod={this.selectChange.bind(this, 'billType')} className='w104'/>
                        </span>
                        <span className='w104'>
                            <ISelect data={this.state.refundStatusList} placeholder="退款状态" value={params.refundAuditStatus}
                                    selectMethod={this.selectChange.bind(this, 'refundAuditStatus')} className='w104'/>
                        </span>
                        <span className='ml12'>
                            <span className='time-tag-name'>退款申请时间</span>                            
                        </span>
                        <span className='w206 cx-date-style'>
                            <RangePicker onChange={this.onChangedate.bind(this)} className='w206' value={this.state.dateValue}/>
                        </span>
                        <span className='w104'> 
                            <Select
                                disabled={!isManager()}
                                value={params.createUserEncode}
                                onChange={this.selectChange.bind(this, 'createUserEncode')}
                                showSearch
                                allowClear
                                showArrow={false}
                                placeholder="创建人员"
                                notFoundContent={fetching ? <Spin size="small" /> : null}
                                filterOption={false}
                                onSearch={this.getCustomerList.bind(this)}
                                notFoundContent='无匹配结果'
                                className='w104'
                            >
                                {manOptions}
                            </Select>
                        </span>                    
                    {/* </Row>
                    <Row gutter={12} style={{ marginBottom: '12px',display:'flex'}}> */}
                        <span className='w288'>
                            <Select dropdownClassName="cx-dropdown-style" mode="multiple" placeholder="项目名称"  otFoundContent={fetchingprojects ? this.notFount() : this.notFoundContent()} value={params.projectEncodes} className='w288' onChange={this.selectChange.bind(this, 'projectEncodes')}>
                                {options}
                            </Select>
                        </span>
                        <span className='w288'>
                            <Select mode="multiple" placeholder="费用类型" value={params.feeTypes} className='w288' onChange={this.selectChange.bind(this, 'feeTypes')}>
                                {optionsFee}
                            </Select>
                        </span>
                        <Col span={3} className="reset">
                            <a onClick={this.resetParams.bind(this)}>重置</a>
                        </Col>
                   </div>
                </div>
                <div className="contract-table OperateMaintaintable-body">
                    <Table rowKey={record=>record.refundEncode} scroll={{ x: 1300 }} columns={this.state.columns} selfProcessData={this.state.data} total={params.total} change={this.onChange} current={params.pageNo}/>
                </div>
            </div>
            </div>
        )
    }
}

export default RefundBill;