import React, { Component } from 'react';
import { API } from "@/http/api";
import { message } from 'antd';
import http from "@/http";
import { RootAuthModel } from './authmodel/AuthModel.js';
import './AuthSetting.less';
export default class AuthSetting extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        rootAuthModel: undefined
    }

    componentDidMount() {
        this.queryAllFunc();
    }

    queryAllFunc() {
        // setup 1:参数
        const config = {
            api: API.authRoleAllFunc,
            showLoading: true
        };

        // setup 2:请求，并初步处理
        http(config).then(res => {
            if (res && res.success && res.data) {
                this.convertAllFunc(res.data);
            }
        });

    }

    /**
     * 转换Json
     * @param {Json数据} jsonData 
     */
    convertAllFunc(jsonData) {
        let rootAuthModel = new RootAuthModel(this);
        rootAuthModel.initFromJson(jsonData);
        rootAuthModel.convertCheckStatus(this.props.authMapInit);
        this.setState({ rootAuthModel });
    }


    // 从 Model集合 来回调
    applyUpdateAuthUi = (newRootModel) => {
        this.setState({ rootAuthModel: newRootModel });
    }

    value = () => {
        let { rootAuthModel } = this.state;
        if (rootAuthModel) {
            let error = rootAuthModel.isHasError();
            if (error) {
                message.error(error);
                return 'error';
            } else {
                return rootAuthModel.value();
            }
        } else {
            return 'error';
        }

    }


    render() {
        let { rootAuthModel } = this.state;
        return (rootAuthModel && rootAuthModel.isStateOk()) ? rootAuthModel.render() : '';
    }

}
