import {
    AJAX_TYPE
} from '../config';

// project-common-controller

const prefix = 'common';

export default {
    // 筛选列表-楼层
    [`${prefix}Floorfilter`]: {
        url: '/web/common/floor/filter',
        type: AJAX_TYPE.GET
    },
    // 项目楼层-区域信息
    [`${prefix}FloorZoneFilter`]: {
        url: '/web/common/floor/zone/filter',
        type: AJAX_TYPE.POST
    },
    // 筛选列表-项目
    [`${prefix}Projectfilter`]: {
        url: '/web/common/project/filter',
        type: AJAX_TYPE.GET
    },
    // 筛选列表-区域
    [`${prefix}Zonefilter`]: {
        url: '/web/common/zone/filter',
        type: AJAX_TYPE.GET
    },// 根据项目ID获取项目下楼层和区域信息
    [`${prefix}ProjectDetailInfoById`]: {
        url: '/web/common/zone/getProjectDetailInfoById/:projectEncode',
        type: AJAX_TYPE.GET
    },
    // 筛选列表-出租状态和招商状态
    [`${prefix}RentMerchantSatus`]: {
        url: '/web/statement/recource/getRecourceInitData',
        type: AJAX_TYPE.GET
    },
    // 店铺
    [`${prefix}ShopList`]: {
        url: '/web/crm/item/getShopsByName',
        type: AJAX_TYPE.GET
    },
    // 分类
    [`${prefix}Category`]: {
        url: '/web/crm/item/getCategoryTree',
        type: AJAX_TYPE.GET
    },
    // 商品设置
    [`${prefix}GetGoodsConfig`]: {
        url: '/web/crm/item/getGoodsConfig',
        type: AJAX_TYPE.GET
    },
    // 商品设置
    [`${prefix}UpdateGoodsConfig`]: {
        url: '/web/crm/item/updateGoodsConfig',
        type: AJAX_TYPE.POST
    }


    
}
