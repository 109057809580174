import React, { Component } from 'react';
import { Breadcrumb,Button,Modal,message } from 'antd';
import http from '@/http';
import { API } from '@/http/api';
import AuthContainer from 'CMPT/common/auth/AuthContainer';
import { AUTH_KEYS } from '@/utils/auth';
class Detail extends Component{
    state={

    }
    componentWillMount(){
        const idCode = this.props.history.location.search&&this.props.history.location.search.replace('?code=','')
        this.setState({
            idCode
        })
        http({
            api: API.departmentDetail,
            path: {idCode}, // 路径参数
            data: {},
            showLoading: true // 是否显示全局加载
        }).then(res => {
            this.setState({
                name:res.data.name,
                department:res.data.parentName,
                phone:res.data.phone,
                projects:res.data.projectMap.map(res=>res.name).join(','),
                des:res.data.description
            })
        })
    }
    delete=()=>{
        this.setState({
            visible:true
        })
    }
    handleCancel=()=>{
        this.setState({
            visible:false
        })
    }
    handleOk=()=>{
        http({
            api: API.departmentDelete,
            path: {
                idCode:this.state.idCode
            }, // 路径参数
            data: {},
            showLoading: true // 是否显示全局加载
        }).then(res=>{
            console.log(res)
            if(res.success){
                this.setState({
                    visible:false
                })
                this.props.history.go(-1)
                message.success('删除成功',()=>{
                })
            }
        })
    }
    edit=()=>{
        this.props.history.push('/app/setting/organization/department/new?edit='+this.state.idCode)
    }
    render(){
        return (
            <div className="department-detail">
                <Modal
                    closable={false}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    okText='确认'
                    width={400}         
                    onCancel={this.handleCancel}           
                >
                    <p>是否确认删除</p>
                </Modal>
                <div className="department-breadcrumb">
                    <Breadcrumb>
                        <Breadcrumb.Item>部门列表页</Breadcrumb.Item>
                        <Breadcrumb.Item>{this.state.name}</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="department-btn">
                        <AuthContainer authCodes={AUTH_KEYS.SETTING_ORGANIZATION_DEPARTMENT_DELETE}>                            
                            <Button type="danger" onClick={this.delete}>删除</Button>
                        </AuthContainer>    
                        <AuthContainer authCodes={AUTH_KEYS.SETTING_ORGANIZATION_DEPARTMENT_MODIFY}>                            
                            <Button type="primary" onClick={this.edit}>编辑</Button>
                        </AuthContainer>    
                    </div>
                </div>
                <div className="department-content">
                    <div className="department-content-title">部门详情</div>
                    <div className="department-content-">部门名称：{this.state.name||'--'}</div>
                    <div className="department-content-">所属部门：{this.state.department||'--'}</div>
                    <div className="department-content-">部门电话：{this.state.phone||'--'}</div>
                    <div className="department-content-">项目权限：{this.state.projects||'--'}</div>
                    <div className="department-content-">部门描述：{this.state.des||'--'}</div>
                </div>
            </div>
        )
    }
}

export default Detail