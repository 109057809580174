import {
    AJAX_TYPE
} from '../config';



const prefix = 'promotion';

export default {
    // 分页
    [`${prefix}List`]: {
        url: '/web/crm/promotion/getList',
        type: AJAX_TYPE.POST
    },
    [`${prefix}Detail`]: {
        url: '/web/crm/promotion/get',
        type: AJAX_TYPE.POST
    },
    [`${prefix}ShopList`]: {
        url: '/web/crm/promotion/getJoinShops',
        type: AJAX_TYPE.POST
    },
    [`${prefix}OpenOff`]: {
        url: '/web/crm/promotion/offPromotionShop',
        type: AJAX_TYPE.POST
    },[`${prefix}GetListForAddPromotionShop`]: {
        url: '/web/crm/promotion/getListForAddPromotionShop',
        type: AJAX_TYPE.POST
    },[`${prefix}RemoveItems`]: {
        url: '/web/crm/promotion/removeItems',
        type: AJAX_TYPE.POST
    },[`${prefix}RemoveSupplier`]: {
        url: '/web/crm/promotion/removeSupplier',
        type: AJAX_TYPE.POST
    },[`${prefix}AddPromotionJoinShop`]: {
        url: '/web/crm/promotion/addPromotionJoinShop',
        type: AJAX_TYPE.POST
    }


    
}