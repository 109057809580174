/**
 * 商办权限梳理表：http://pms.ipo.com/pages/viewpage.action?pageId=138936933
 * 权限id：http://pms.ipo.com/pages/viewpage.action?pageId=138958258
 * 每个权限key对应相应权限的id值，如：设置中心("设置中心",9903L,99L,Boolean.FALSE,null)中第一个数字9903为设置中心的权限id，故SETTING: 9903
 */
export const AUTH_KEYS = Object.freeze({
    // 客户管理---
    CUSTOMER_MANAGE: 9901,
    CUSTOMER_MANAGE_ADD: 990101, // 新增---
    CUSTOMER_MANAGE_EDIT: 990102, // 客户编辑
    // 商品管理---
    GOODS_MANAGE: 9902,
    GOODS_MANAGE_EXAMINE: 990201, // 审核---
    // GOODS_MANAGE_UP: 990202, // 上架
    GOODS_MANAGE_DOWN: 990203, // 下架---
    // GOODS_MANAGE_DELETE: 990204, // 删除
    // 企业认证管理---
    ENTERPRISE_MANAGE: 9903,
    ENTERPRISE_MANAGE_EXAMINE: 990301, // 审核----
    // 订单管理
    ENTERPRISE_ORDER_MANAGE: 9904,
    // 活动管理---
    ACTIVITY_MANAGE: 9905,

    // 帮助中心---
    HELP_CENTER_MANAGE: 9906,
    // 类目管理---
    HELP_CENTER_MANAGE_CLASS_MANAGE: 990601, 
    HELP_CENTER_MANAGE_CLASS_MANAGE_CREATE: 99060101, // 类目创建---
    HELP_CENTER_MANAGE_CLASS_MANAGE_EDIT: 99060102, // 类目编辑----
    HELP_CENTER_MANAGE_CLASS_MANAGE_DELETE: 99060103, // 类目删除----
    // 常见问题
    HELP_CENTER_MANAGE_QUESTION_MANAGE: 990602, 
    HELP_CENTER_MANAGE_QUESTION_MANAGE_CREATE: 99060201, // 创建---
    HELP_CENTER_MANAGE_QUESTION_MANAGE_EDIT: 99060202, // 编辑---
    HELP_CENTER_MANAGE_QUESTION_MANAGE_DELETE: 99060203, // 删除----

    // 设置中心---
    SETTING_CENTER_MANAGE: 9907,
    // 账号管理---
    SETTING_CENTER_MANAGE_ACCOUNT_MANAGE: 990701, 
    SETTING_CENTER_MANAGE_ACCOUNT_MANAGE_ADD: 99070101, // 账号新增----
    SETTING_CENTER_MANAGE_ACCOUNT_MANAGE_EDIT: 99070102, // 账号编辑----
    // 角色管理----
    SETTING_CENTER_MANAGE_ROLE_MANAGE: 990702, 
    SETTING_CENTER_MANAGE_ROLE_MANAGE_ADD: 99070201, // 角色新增-----
    // 决策管理-----
    SETTING_CENTER_MANAGE_POLICY_MANAGE: 990703, 

    // 客源管理----
    CUSTOMER_SOURCE_MANAGE: 9908,

    // 规则中心-----
    RULE_MANAGE: 9909,
    RULE_MANAGE_CATEGORY_MANAGE: 990901, // 管理目录---
    RULE_MANAGE_RULE_MANAGE: 990902, // 管理规则---



    // 商城内容管理----
    MALL_CONTENT_MANAGE: 9910,
    MALL_CONTENT_MANAGE_BANNER_MANAGE: 991001, // banner管理-----
    MALL_CONTENT_MANAGE_BANNER_MANAGE_ADD: 99100101, // Banner新增
    MALL_CONTENT_MANAGE_BANNER_MANAGE_STATUS_CHANGE: 99100102, // 启用/禁用-----
    MALL_CONTENT_MANAGE_BANNER_MANAGE_CONTENT_CHANGE: 99100103, // banner修改-----


    // 客户操作日志-----
    OPERATION_LOG_MANAGE: 9911,

    // 签约管理-----
    SIGNING_MANAGE: 9912,
    SIGNING_MANAGE_EXAMINE: 991201, // 签约审核

    // 测试账号
    TEST_ACCOUNT_MANAGE: 9913,
    TEST_ACCOUNT_MANAGE_ADD: 991301, // 测试账号添加

    // 商品类目----
    GOODS_CATEGORY_MANAGE: 9914,

    GOODS_CATEGORY_MANAGE_AFTER_MANAGE: 991401, // 后台类目-----
    GOODS_CATEGORY_MANAGE_AFTER_MANAGE_ADD: 99140101, // 新增类目----
    GOODS_CATEGORY_MANAGE_AFTER_MANAGE_CHANGE: 99140101, // 修改类目----
    GOODS_CATEGORY_MANAGE_AFTER_MANAGE_STATUS_CHANGE: 99140101, // 启用/禁用-----
    GOODS_CATEGORY_MANAGE_AFTER_MANAGE_DELETE: 99140101, // 删除-----

    GOODS_CATEGORY_MANAGE_DAOHANG_MANAGE: 991402, // 频道导航------
    GOODS_CATEGORY_MANAGE_DAOHANG_MANAGE_ADD: 99140201, // 新增频道导航
    GOODS_CATEGORY_MANAGE_DAOHANG_MANAGE_SORT: 99140202, // 频道导航排序

    // 用户管理
    USER_MANAGE: 9915,

    // 询价管理
    SOURCING_MANAGE: 9917,
    SOURCING_MANAGE_AUDIT: 991701,
    SOURCING_MANAGE_OFFSHELVES: 991702,
    
    // 询价管理
    ACCOUNT_OPEN_MANAGE: 9918,
});
