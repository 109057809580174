import React, { Component } from 'react';
import { Layout, Menu, Breadcrumb, Button, Tooltip, message } from 'antd';
import { Link } from 'react-router-dom'
import { numberFormat } from '@/utils/fliter';
import user from '@/utils/user'
import IntentionSearch from './IntentionSearch';
import IntentionTable from './IntentionTable';
import { channelTypeConfig } from './customerConfig'
import { areaInit, usergetList, customerAdd, customerGetList, customerCustomerStatusCount } from './service/customerService';
import { intentionInit, intentionList, intentionStatusCount } from './service/intentionService'
import { changeObjkey } from '@/utils/index'
import { objectarrayToArray } from '@/utils/fliter'
import moment from 'moment'
import AssignFollower from './AssignFollower';
import AddFollow from './AddFollow';
import {
    AUTH_KEYS
} from '@/utils/auth';
import userUtil from "@/utils/user";
import './intention.less'

const { Header, Content, Footer } = Layout;
const channelTypeConfigArray = objectarrayToArray(channelTypeConfig);
const { hasAuth } = user;
class OperateIntention extends Component {
    state = {
        CustomerHead: [
            {
                name: '潜在客户',
                value: 0,
                encode: 'potential'
            },
            {
                name: '意向客户',
                value: 0,
                encode: 'intention'
            },
            {
                name: '签约客户',
                value: 0,
                encode: 'sign'
            },
            {
                name: '失败客户',
                value: 0,
                encode: 'failure'
            },
            {
                name: '流失客户',
                value: 0,
                encode: 'lost'
            },
        ],
        intentionStatus: [
            {
                name: '新商机',
                value: 0,
                encode: 'opportunity'
            },
            {
                name: '初次接触',
                value: 0,
                encode: 'first'
            },
            {
                name: '签订意向',
                value: 0,
                encode: 'intention'
            },
            {
                name: '已签约',
                value: 0,
                encode: 'sign'
            },
            {
                name: '已流失',
                value: 0,
                encode: 'lost'
            },
        ],
        intentionChannel: [
            { name: '线下-朋友推荐', value: 0 },
            { name: '线上-第三方4', value: 1 }
        ],
        searchObj: {
            fuzzyKey: undefined,
            status: undefined,
            customerType: undefined,
            industryType: undefined,
            rangeDate: []
        },
        pageNo: 1,
        total: 1,
        selectRows: [],//用于批量处理
        pageSize: 10,
        columns: [
            {
                title: '意向编号',
                dataIndex: 'code',
                render: (text, record, index) => <a className="text-color-highlight" href="javascript:;" onClick={() => this.props.history.push('/app/operate/invitation/intention/' + record.encode)}>{text}</a>,
                width: '12.7%'
            }, {
                title: '客户名称',
                dataIndex: 'customerName',
                width: '10%',
                render: (value) => {
                    if (value.length > 10) {
                        return <Tooltip placement="top" title={value}>{value.slice(0, 10)}...</Tooltip>
                    }
                    return value
                }
            },
            {
                title: '状态',
                dataIndex: 'status',
                width: '10%',
                render: status =>
                    <span>
                        {this.state.intentionStatusConfigArray.filter(item => item.value == status)[0]['name']}
                    </span>
            },
            {
                title: '意向项目',
                dataIndex: 'projectVo',
                width: '10%',
                render: projectVo => {
                    return <span>
                        {projectVo && projectVo.name || ''}
                    </span>
                }
            },
            {
                title: '意向行业',
                dataIndex: 'industryType',
                width: '10%',
                render: industryType => {
                    return <span>
                        {industryType && ((this.state.industryTypeConfigArray.length > 0 && this.state.industryTypeConfigArray.filter(item => item.value == industryType).length > 0) ? this.state.industryTypeConfigArray.filter(item => item.value == industryType)[0]['name'] : '--')}
                    </span>
                }

            },
            {
                title: '渠道',
                dataIndex: 'channelType',
                width: '10%',
                render: channelType => {
                    return <span>
                        {this.state.intentionChannelTypeConfigArray.filter(item => item.value == channelType)[0]['name']}
                    </span>
                }

            },
            {
                title: '跟进人',
                dataIndex: 'userName',
                width: '10%',
                render: userName =>
                    <span>
                        {userName}
                    </span>
            },
            {
                title: '创建时间',
                dataIndex: 'createTime',
                width: '11.5%',
                render: createTime =>
                    <span>
                        {moment(createTime).format('YYYY-MM-DD HH:mm')}
                    </span>,
                sorter: true
            },
            {
                title: '操作',
                render: record => <div>
                    {this.checkEditAuth(record) ? <a className="text-color-highlight" href="javascript:;" onClick={() => this.props.history.push('/app/operate/invitation/intention/edit/' + record.encode)}  >编辑</a> : null}
                    {this.checkDispatchAuth(record) ? <a className="text-color-highlight" href="javascript:;" onClick={() => this.editIntention(record)}>分配</a> : null}
                    {this.checkFollowAuth(record) ? <a className="text-color-highlight" href="javascript:;" onClick={() => this.editFollow(record)}>跟进</a> : null}
                </div>
            }
        ],
        data: [
        ],
        intentionStatusConfigArray: [],
        industryTypeConfigArray: [],
        customerTypeConfigArray: [],
        intentionChannelTypeConfigArray: [],
        fetching:true,
        lostReasonTypeConfigArrray: [],
        areaTypeConfigArray: [],
        followupTypeConfigArray: [],
        followupFlagConfigArray: [],
        assign: {
            visible: false,
            batch: false,
            id: []
        },
        follow: {
            visible: false,
            batch: false,
            id: []
        },
        orderByList: 2,
    }

    componentWillMount() {
        if (userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_INTENTION_DISPATCH_BATCH)) {
            this.setState({
                assign: {
                    visible: false,
                    batch: true,
                    id: []
                }
            })
        }
    }

    componentDidMount() {
        console.log(this.props);
        intentionInit().then(res => {
            let data = res.data;
            let { intentionStatusEnum, customerTypeEnum, industryTypeEnum, intentionChannelTypeEnum, lostReasonTypeEnum, areaTypeEnum, followupTypeEnum, followupFlagEnum } = { ...data }
            let intentionStatusConfigArray = intentionStatusEnum.map(item => changeObjkey(item, { value: 'name', key: 'value' }))
            let customerTypeConfigArray = customerTypeEnum.map(item => changeObjkey(item, { value: 'name', key: 'value' }))
            let industryTypeConfigArray = industryTypeEnum.map(item => changeObjkey(item, { value: 'name', key: 'value' }))
            let intentionChannelTypeConfigArray = intentionChannelTypeEnum.map(item => changeObjkey(item, { value: 'name', key: 'value' }))
            let lostReasonTypeConfigArrray = lostReasonTypeEnum.map(item => changeObjkey(item, { value: 'name', key: 'value' }))
            let areaTypeConfigArray = areaTypeEnum.map(item => changeObjkey(item, { value: 'name', key: 'value' }))
            let followupTypeConfigArray = followupTypeEnum.map(item => changeObjkey(item, { value: 'name', key: 'value' }))
            let followupFlagConfigArray = followupFlagEnum.map(item => changeObjkey(item, { value: 'name', key: 'value' }))
            this.setState({
                fetching:false,
                intentionStatusConfigArray: intentionStatusConfigArray,
                industryTypeConfigArray: industryTypeConfigArray,
                customerTypeConfigArray: customerTypeConfigArray,
                intentionChannelTypeConfigArray: intentionChannelTypeConfigArray,
                lostReasonTypeConfigArrray: lostReasonTypeConfigArrray,
                areaTypeConfigArray: areaTypeConfigArray,
                followupTypeConfigArray: followupTypeConfigArray,
                followupFlagConfigArray: followupFlagConfigArray
            }, () => this.getIntention())
            if (!userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_INTENTION_DISPATCH) && userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_INTENTION_DISPATCH_BATCH)) {
                this.setState({
                    assign: {
                        visible: false,
                        batch: true,
                        id: []
                    }
                })
            }
        })

    }

    checkEditAuth = (record) => {
        let userInfo = userUtil.get()
        if (!userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_INTENTION_EDIT)) {
            return false
        } else {
            if (userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_INTENTION_EDIT_ALL)) {
                console.log(1)
                return true
            }
            else if (userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_INTENTION_EDIT_SELF) && userInfo.idCode == record.userEncode) {
                console.log(2)
                return true
            } else {
                return false
            }
        }
    }

    checkDispatchAuth = record => {
        let userInfo = userUtil.get()
        if (!userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_INTENTION_DISPATCH)) {
            return false
        } else {
            if (userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_INTENTION_DISPATCH_ALL)) {
                return true
            }
            else if (userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_INTENTION_DISPATCH_SELF) && userInfo.idCode == record.userEncode) {
                return true
            } else {
                return false
            }
        }
    }

    checkFollowAuth = record => {
        let userInfo = userUtil.get()
        if (!userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_INTENTION_FOLLOW)) {
            return false
        } else {
            if (userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_INTENTION_FOLLOW_ALL)) {
                return true
            }
            else if (userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_INTENTION_FOLLOW_SELF) && userInfo.idCode == record.userEncode) {
                return true
            } else {
                return false
            }
        }
    }

    getIntentionCount = () => {
        intentionStatusCount().then(res => {
            if (res.code == 0) {
                let data = res.data;
                if (!data) {
                    return;
                }
                let intentionStatus = this.state.intentionStatus;
                let targetHead = intentionStatus.map(item => {
                    item.value = data[item.encode]
                    return item;
                })
                this.setState({ intentionStatus: intentionStatus })
            }
        })
    }

    getSearchChange = (searchParams) => {
        let _searchParams = JSON.parse(JSON.stringify(searchParams));
        if (searchParams.rangeDate.length > 0) {
            _searchParams.createTimeStart = searchParams.rangeDate[0];
            _searchParams.createTimeEnd = searchParams.rangeDate[1];
        } else {
            _searchParams.createTimeStart = undefined;
            _searchParams.createTimeEnd = undefined;
        }
        console.log(_searchParams);
        this.setState({
            searchObj: {
                fuzzyKey: _searchParams.fuzzyKey,
                status: _searchParams.status,
                channelType: _searchParams.channelType,
                createTimeStart: _searchParams.createTimeStart,
                createTimeEnd: _searchParams.createTimeEnd
            },
            pageNo: 1
        }, () => this.getIntentionList())
    }

    editIntention = record => {
        this.setState({
            assign: {
                visible: true,
                batch: false,
                id: [record]
            }
        })
    }

    editFollow = record => {
        this.setState({
            follow: {
                visible: true,
                batch: false,
                id: [record]
            }
        })
    }

    getIntentionList = () => {
        let postData = JSON.parse(JSON.stringify(this.state.searchObj));
        postData.pageSize = this.state.pageSize;
        postData.pageNo = this.state.pageNo;
        postData.orderByList = [this.state.orderByList];
        intentionList(postData).then(res => {
            if (res.code == 0) {
                let data = res.data;
                let userInfo = userUtil.get()
                this.setState({
                    pageNo: data.pageNo,
                    pageSize: data.pageSize,
                    data: data.datas.map(item => {
                        item.dispatchBatch = false;
                        if (!userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_INTENTION_DISPATCH_BATCH)) {
                            item.dispatchBatch = false;
                        } else {
                            if (userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_INTENTION_DISPATCH_BATCH_ALL)) {
                                item.dispatchBatch = true
                            }
                            else if (userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_INTENTION_DISPATCH_BATCH_SELF) && userInfo.idCode == item.userEncode) {
                                item.dispatchBatch = true
                            } else {
                                item.dispatchBatch = false
                            }
                        }

                        return item;
                    }),
                    total: data.total,
                })
            }
        })
    }
    getIntention = () => {
        this.getIntentionList();
        this.getIntentionCount();
    }
    assign = () => {
        this.setState({
            assign: {
                visible: !this.state.assign.visible,
                batch: this.state.assign.batch,
                id: this.state.assign.id
            }
        })
    }

    follow = () => {
        this.setState({
            follow: {
                visible: !this.state.follow.visible,
                id: this.state.follow.id
            }
        })
    }
    rowSelectionChange = (selectedRowKeys) => {
        this.setState({
            selectRows: selectedRowKeys
        })
    }
    assignBatch = () => {
        if (this.state.selectRows.length <= 0) {
            message.warning('当前没选中任何行');
            return;
        }
        this.setState({
            assign: {
                visible: !this.state.assign.visible,
                batch: true,
                id: this.state.selectRows
            }
        })
    }


    // onSelect=(changeableRowKeys)=>{
    //     console.log('onselect:'+changeableRowKeys)
    //     this.setState({
    //         selectRows:changeableRowKeys
    //     })
    // }
    onChange = (page, pageSize, sort) => {
        let order = sort && sort.order == 'ascend' ? 1 : 2
        this.setState({
            pageNo: page,
            pageSize: pageSize,
            orderByList: order
        }, () => this.getIntentionList())

    }
    render() {
        const tableConfig = {
            columns: this.state.columns,
            data: this.state.data,
            current: this.state.pageNo,
            total: this.state.total,
            pageSize: this.state.pageSize
        }
        return (
            <Content className="intention-list intention-content">
                <div className="intention-list-head flexbox">
                    <Breadcrumb className="intention-list-head-bread">
                        <Breadcrumb.Item>
                            <Link style={{ color: '#333' }} to={{ pathname: "/app/operate/invitation/intention" }}>
                                招商中心
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link style={{ color: '#333' }} to={{ pathname: "/app/operate/invitation/intention" }}>
                                意向管理
                            </Link>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <ul className="intention-list-head-data">
                        {this.state.intentionStatus.map((item, index) => {
                            return <li key={index} className="data-li">
                                <span>{item.name}：</span>
                                <span className="font-white-number">{numberFormat(item.value)}</span>
                            </li>
                        })}
                        <li>
                            {/* onClick={() => this.props.history.push('/app/operate/invitation/intentio'n/add)} */}
                            {userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_INTENTION_ADD) ? <Button type="primary">  <Link to={{ pathname: '/app/operate/invitation/intention/add', query: { day: 'Friday' } }}>新增意向 </Link></Button> : null}
                        </li>
                    </ul>
                </div>

                <div className="intention-body">
                    <IntentionSearch industryTypeConfigArray={this.state.industryTypeConfigArray} statusConfigArray={this.state.intentionStatusConfigArray} fetching={this.state.fetching} intentionChannelTypeConfigArray={this.state.intentionChannelTypeConfigArray} defaultObj={this.state.searchObj} changeParam={this.getSearchChange} />
                    <IntentionTable tableConfig={tableConfig} rowSelectionChange={this.rowSelectionChange} onChange={this.onChange} assignBatch={this.assignBatch} hasAuth={userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_INTENTION_DISPATCH_BATCH)} />
                </div>
                {userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_INTENTION_DISPATCH) || userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_INTENTION_DISPATCH_BATCH) ? <AssignFollower visible={this.state.assign.visible} id={this.state.assign.id} assignCancel={this.assign} batch={this.state.assign.batch} getIntentionList={this.getIntention} isCustomer={false} /> : null}
                {userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_INTENTION_FOLLOW) ? <AddFollow intentionStatus={this.state.intentionStatusConfigArray} followupType={this.state.followupTypeConfigArray} followupFlag={this.state.followupFlagConfigArray} getIntentionList={this.getIntention} lostReasonType={this.state.lostReasonTypeConfigArrray} visible={this.state.follow.visible} id={this.state.follow.id} followCancel={this.follow} isCustomer={false} /> : null}
            </Content>
        );
    }
}

export default OperateIntention;