import React, { Component } from 'react';
import { Breadcrumb,Icon,Button,Popover,message } from 'antd';
import './Template.less';
import Tip from 'CMPT/common/Tip';
import { Link } from 'react-router-dom'
import http from '@/http';
import { API } from '@/http/api';
import wordSrc from '@/images/files_word@2x.png';
import pdfSrc from '@/images/files_pdf@2x.png';
import DeleteTemplate from './DeleteTemplate';
import CopyTemplate from './CopyTemplate';
import ApproveTemplate from './ApproveTemplate';
import RejectTemplate from './RejectTemplate';
import ApprovalChain from 'CMPT/common/ApprovalChain';
import AuthContainer from 'CMPT/common/auth/AuthContainer';
import { AUTH_KEYS } from '@/utils/auth';
const BreadcrumbItem = Breadcrumb.Item;

class ReceiptTemplateDetail extends Component {
    state={
        data:{
            projectList:[{}],
            media:{type:'word'}
        },
        showDelete: false,
        showCopy: false,
        showApprove:false,
        showReject:false,
        stepList: []
    }
    componentWillMount(){        
        const location = this.props.history.location
        http({
            api: API.templateInit,
            path: {}, // 路径参数
            data: {}, // 请求参数            
            showLoading: true // 是否显示全局加载
        }).then(res => {
            
            // 初始化搜索选项
            this.setState({            
                projectList: res.data.projectList && res.data.projectList.map(item => { return { value: item.id, name: item.name } })  ,            
            })
        })
        this.setState({
            type:location.search.replace('?type=',''),
            templateId:(/templatedetail\/(.+)/).exec(location.pathname)[1]    
        },()=>{
            http({
                api: API.templateVerifyInfo,
                path: {}, // 路径参数
                data: {
                    encode:this.state.templateId
                }, // 请求参数
                showLoading: false // 是否显示全局加载
            }).then(res => {
                
                res.data.projectDes = res.data.projectList.reduce((prev,next)=>{
                    if(next){
                        return (prev?(prev+', '):prev) + next?(next.name+''):''
                    }else{
                        return (prev?(prev+', '):prev)
                    }
                },'')
                // console.log(res)
                this.setState({
                    data:res.data,
                    stepList: res.data.flows
                })
            })
        })
        
    }
    downfile=()=>{

        http({
            api: API.templateDownLoad,
            path: {}, // 路径参数
            data: {
                ...this.state.data.media
            }, // 请求参数
            responseType:'blob',
            showLoading: true // 是否显示全局加载
        }).then(res => {
            if (res.size < 1024) {
                message.error('下载异常，请稍后重试')
            } else {
            const content = res
            const blob = new Blob([content])
            const fileName = this.state.data.media.name
            if ('download' in document.createElement('a')) { // 非IE下载
                const elink = document.createElement('a')
                elink.download = fileName
                elink.style.display = 'none'
                elink.href = URL.createObjectURL(blob)
                document.body.appendChild(elink)
                elink.click()
                URL.revokeObjectURL(elink.href) // 释放URL 对象
                document.body.removeChild(elink)
            } else { // IE10+下载
                navigator.msSaveBlob(blob, fileName)
            }
        }
        })
    }
    reload=(e)=>{
        this.setState({
            showDelete: false,
            showCopy: false,            
            showApprove: false,            
            showReject: false,            
        })
        if(e){
            this.props.history.push('/app/setting/template/receipttemplate')
        }
        
    }
    render() {
        let person = null;
        this.state.stepList && this.state.stepList.map((item,index) =>{
            if(item.reached && !item.processed) {
                let personName = item.users && item.users.map((newItem,newIndex) => {  
                    if(!newItem.processed){
                        return newItem.name + (item.type == 3 ? ' / ' : ' + ')
                    } 
                })
                person='当前审批人：'+personName.join('')
            }
        })
        const auditComponent = (
            <Popover overlayClassName="bo-audit-popover" content={<ApprovalChain stepList={this.state.stepList}/>} trigger="click" placement="bottomRight">
                <div className="clickPopover"><i className="iconfont iconapprove"></i><span>查看审批流程</span></div>
            </Popover>
        )
        return (
            <div className="template-con">
                <DeleteTemplate visible={this.state.showDelete} templateId={this.state.templateId} reload={this.reload}/>                
                <CopyTemplate visible={this.state.showCopy} templateId={this.state.templateId} reload={this.reload} projectList={this.state.projectList}/>
                <ApproveTemplate type={this.state.data.type} visible={this.state.showApprove} templateId={this.state.templateId} reload={this.reload}/>
                <RejectTemplate type={this.state.data.type} visible={this.state.showReject} templateId={this.state.templateId} reload={this.reload}/>
                <div className='template-header'>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link style={{ color: '#333' }} to={{ pathname: "/app/setting/template/receipttemplate" }}>
                                模版管理
                            </Link>
                        
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <Link style={{ color: '#333' }} to={{ pathname: "/app/setting/template/receipttemplate" }}>
                                收据模版
                            </Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem>模板详情</BreadcrumbItem>
                    </Breadcrumb>
                    <div className="action-btn">
                        {
                            this.state.data.isDelete?
                            <AuthContainer key="1" authCodes={AUTH_KEYS.SETTING_TEMPLATE_RECEIPT_DELETE}>                            
                                <Button onClick={()=>{this.setState({showDelete:true})}} className='new-role-btn btn-warning'>删除</Button>
                            </AuthContainer>    
                            :null
                        }
                        {
                            this.state.data.isUpdate?
                            <AuthContainer key="2" authCodes={AUTH_KEYS.SETTING_TEMPLATE_RECEIPT_MODIFY}>                            
                                <Button onClick={()=>{this.props.history.push("/app/setting/template/receipttemplate/receipteditfirst?edit="+this.state.templateId)  }} className='new-role-btn'>编辑</Button>
                            </AuthContainer>    
                            :null
                        }
                        {
                            this.state.data.isCopy?
                            <AuthContainer key="3" authCodes={AUTH_KEYS.SETTING_TEMPLATE_RECEIPT_COPY}>                            
                                <Button type="primary" onClick={()=>{this.setState({showCopy:true})}} className='new-role-btn'>复制</Button>
                            </AuthContainer>    
                            :null
                        }
                        
                    </div>
                </div>
                {
                    this.state.data.status ==1?(
                        <Tip type="wait" title={this.state.data.statusDesc} hint={true} hintNode={auditComponent}  secondTitle={person && person.substring(0,person.length-2)}/>
                    ):null
                }
                {
                    this.state.data.status == 2?(
                        <Tip type="pass" title={this.state.data.statusDesc} hint={true} hintNode={auditComponent}/>
                    ):null
                }
                {
                    this.state.data.status == 3?(
                        <Tip type="warn" title={this.state.data.statusDesc} hint={this.state.data.rejectContent} hintNode={auditComponent} des={this.state.data.rejectContent}/>
                    ):null
                }
                {
                    this.state.data.status == 4?(
                        <Tip type="minus" title={this.state.data.statusDesc} hint={true} hintNode={auditComponent}/>
                    ):null
                }
                
                
                <div className="template-baseInfo">
                    <div className="title">基本信息</div>
                    <div className="lists">模版名称：{this.state.data.name}</div>
                    <div className="lists">模版类型：{this.state.data.typeDesc}</div>
                    <div className="lists">适用范围：{this.state.data.projectDes}</div>
                </div>
                <div className="template-baseInfo">
                    <div className="title">附件</div>
                    <div className="template-file">
                    <div className="template-download">
                        <AuthContainer key="1" authCodes={AUTH_KEYS.SETTING_TEMPLATE_RECEIPT_DOWNLOAD}>                                                            
                        <div onClick={this.downfile}>下载</div>
                        </AuthContainer>    
                    </div>
                        {this.state.data.media.type=='word'?<img src={wordSrc} alt={this.state.data.media.name}/>:<img src={pdfSrc} alt={this.state.data.media.name}/>}
                        <div className="template-download-filename">
                            {this.state.data.media.name}
                        </div>
                    </div>
                </div>
                {
                    this.state.data.isVerify?(
                        <div className="template-operate">
                            <Button type="dashed" onClick={()=>{this.setState({showApprove:true})}}>通过</Button>
                            <span></span>
                            <Button type="danger" onClick={()=>{this.setState({showReject:true})}}>驳回</Button>
                        </div>
                    ):null
                }
                
            </div>
        );
    }
}

export default ReceiptTemplateDetail;

