import React, { Component } from "react";
import { API } from "@/http/api";
import http from "@/http";
import { Button, Tooltip, Switch, Modal } from "antd";
import EmptyView from "CMPT/widget/EmptyView";
import YqyGoodsAddEdit from "./YqyGoodsAddEdit";
import DragTable from "CMPT/widget/DragTable";
import MultiLineText from "CMPT/widget/MultiLineText";
import "./YqyGoodsList.less";
import moment from "moment";

class NewYqyGoodsList extends Component {
  state = {
    columns: this.createColumns(),
    httpData: [],
    keyHttpData: '',

    tempData: {
      isLoading: true,
      // 所有的临时变量，放这里
      showAddEditDialog: false,
      willEditObj: null,

      willDelObj: null, //可能要删除的一条数据
      willCloseObj: null //可能要关闭的一条数据
    },

    searchParam: {
      type: this.props.type, //1是 web、2是 小程序
      source: this.props.source // 目前写死1，表示 一起赢
    }
  };

  componentDidMount() {
    this.queryList();
  }
  queryList = () => {
    let tempData = this.state.tempData;
    tempData.isLoading = true;
    this.setState({
      tempData
    }, () => this.doQueryList());

  }
  doQueryList = () => {
    let myState = this.state;

    // setup 1: 构造入参
    let dataParam = {
      ...myState.searchParam,
    };

    const config = {
      api: API.yqyList,
      data: dataParam,
      showLoading: true
    };

    // setup 2:请求，并初步处理
    http(config).then(res => {
      if (res && res.success && res.data && res.data.datas) {
        let listData = [];
        if (res.data.datas && res.data.datas.length > 0) {
          listData = res.data.datas.map(item => {
            item = { ...item, ...item.feature };
            delete item.feature;
            return item;
          });
        }

        let tempData = this.state.tempData;
        tempData.isLoading = false;
        this.setState({
          httpData: listData || [],
          keyHttpData: (new Date().getTime() + ''),
          tempData
        });
      }
    });
  };

  createColumns() {
    return [
      {
        title: "序号",
        key: "encode",
        render: (text, record, index) => {
          return <span>{index + 1}</span>;
        }
      },
      {
        title: "添加时间",
        dataIndex: "createTime",
        key: "createTime",
        render: (text, record) => {
          return <span>{moment(text).format("YYYY-MM-DD HH:mm")}</span>;
        }
      },
      {
        title: "商品图片",
        dataIndex: "imgUrl",
        key: "imgUrl",
        render: (text, record) => {
          return <img src={text} style={{ width: "100px", height: "100px" }} alt="" />;
        }
      },
      {
        title: "商品标题",
        dataIndex: "title",
        key: "title",
        render: (text, record) => {
          /*let value;
          if (text && text.length > 20) {
            value = (
              <Tooltip placement="top" title={text}>
                {text.slice(0, 20)}...
              </Tooltip>
            );
          } else {
            value = text;
          }

          return <span>{value}</span>;*/
          return <MultiLineText showTips={true} value={text} />
        }
      },
      {
        title: "市场价",
        dataIndex: "marketPrice",
        key: "marketPrice",
        render: (text, record) => {
          return <span>{text}</span>;
        }
      },
      {
        title: "内购价",
        dataIndex: "innerPrice",
        key: "innerPrice",
        render: (text, record) => {
          return <span>{text}</span>;
        }
      },

      {
        title: "状态",
        dataIndex: "status",
        width: "15%",
        render: (text, record, index) => {
          const { encode, stateLoading } = record;
          let checked = text == 1 ? true : false;

          return (
            <>
              <span className="status-tip">{checked ? "启用" : "停用"}</span>
              <Switch
                key={encode}
                checked={checked}
                loading={stateLoading}
                onChange={checked => this.openCloseChange(checked, record)}
              />
            </>
          );
        }
      },

      {
        title: "操作",
        key: "operate",
        render: (text, record, index) => {
          return (
            <div>
              <span
                className="text-color-highlight"
                style={{ marginRight: "16px" }}
                onClick={() => {
                  this.editGoods(record);
                }}
              >
                修改
              </span>
              {record.status != 1 ? (
                <span
                  className="text-color-highlight"
                  onClick={() => {
                    this.delGoods(record);
                  }}
                >
                  删除
                </span>
              ) : (
                  ""
                )}
            </div>
          );
        }
      }
    ];
  }

  openCloseChange = (checked, obj) => {
    if (checked) {
      this.doOpenClose(obj);
    } else {
      this.setState({
        willCloseObj: obj
      });
    }
  };

  doOpenClose = obj => {
    const config = {
      api: API.yqyOpenClose,
      path: { encode: obj.encode },
      showLoading: true
    };

    http(config).then(res => {
      if (res && res.success) {
        this.hideDialog();
        this.queryList();
      }
    });
  };

  editGoods = obj => {
    let newTempData = this.state.tempData;
    newTempData.willEditObj = obj;

    this.setState({ tempData: newTempData, showAddEditDialog: true });
  };

  addGoods = () => {
    let newTempData = this.state.tempData;
    newTempData.willEditObj = null;

    this.setState({ tempData: newTempData, showAddEditDialog: true });
  };

  delGoods = obj => {
    this.setState({
      willDelObj: obj
    });
  };

  gotoDel = () => {
    const config = {
      api: API.yqyDel,
      path: { encode: this.state.willDelObj.encode },
      showLoading: true
    };

    http(config).then(res => {
      if (res && res.success) {
        this.hideDialog();
        this.queryList();
      }
    });
  };

  gotoClose = () => {
    this.doOpenClose(this.state.willCloseObj);
  };

  hideDialog = () => {
    this.setState({
      willDelObj: null,
      willCloseObj: null,
      showAddEditDialog: false,
      willEditObj: null
    });
  };

  addDelDialog() {
    return (
      <Modal
        title={"通过"}
        visible={true}
        okText="确定"
        cancelText="取消"
        closable={false}
        maskClosable={false}
        onOk={this.gotoDel}
        onCancel={this.hideDialog}
        width={400}
      >
        <div>{"请确认是否删除" + this.state.willDelObj.title}</div>
      </Modal>
    );
  }

  addCloseDialog() {
    return (
      <Modal
        title={"通过"}
        visible={true}
        okText="确定"
        cancelText="取消"
        closable={false}
        maskClosable={false}
        onOk={this.gotoClose}
        onCancel={this.hideDialog}
        width={400}
      >
        <div>{"请确认是否关闭" + this.state.willCloseObj.title}</div>
      </Modal>
    );
  }

  addAddEditDialog() {
    return (
      <YqyGoodsAddEdit
        data={this.state.tempData.willEditObj}
        listCount={this.state.httpData ? this.state.httpData.length : 0}
        cancelDialog={this.hideDialog}
        type={this.props.type}
        refresh={this.queryList}
        source={this.props.source}
      />
    );
  }

  onChange = (dragIndex, hoverIndex,newData, oldData) => {
    // setup 1: 构造新的排序
    let sortListString = '';
    for (let i = 0, length = newData.length, last = length - 1; i < length; i++) {
      if (!newData[i] || !newData[i].encode) {
        continue;
      }

      sortListString += newData[i].encode;
      if (i < last) {
        sortListString += ',';
      }
    }

    // setup 2: 构造入参
    const config = {
      api: API.yqySort,
      data: { sortList: sortListString },
      showLoading: true
    };

    // setup 2:请求，并初步处理
    http(config).then(res => {
      //TODO  do nothing ??
    });
  };

  render() {
    let isLoading = this.state.tempData.isLoading;
    return (
      <div className="yqy_panel">
        <div className="yqy_header2">
          <Button
            style={{ marginLeft: "20px", display: isLoading ? 'none' : 'block' }}
            type="primary"
            onClick={() => this.addGoods()}
          >
            新增
          </Button>
        </div>
        {this.state.willCloseObj ? this.addCloseDialog() : ""}
        {this.state.willDelObj ? this.addDelDialog() : ""}
        {this.state.showAddEditDialog ? this.addAddEditDialog() : ""}
        {this.state.httpData && this.state.httpData.length > 0 ? (
          <DragTable
            key={this.state.keyHttpData}
            columns={this.state.columns}
            dataSource={this.state.httpData}
            onChange={this.onChange}
          />
        ) : (isLoading ? "" : <EmptyView tips="暂无商品" />)
        }
      </div>
    );
  }
}

export default NewYqyGoodsList;
