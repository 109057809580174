import React, { Component } from 'react';
import { Breadcrumb, Button, Row, Col, Select, DatePicker, Input, message, Tooltip, Icon } from 'antd';
import Table from 'CMPT/common/Table';
import http from '@/http';
import { API } from '@/http/api';
import { changeObjkey } from '@/utils';
import './Report.less';
import { NumericInput } from '../project/detail/capital/NumericInput'
import ISelect from 'CMPT/common/Select';
import storage from '@/utils/storage';
const BreadcrumbItem = Breadcrumb.Item;
const { RangePicker } = DatePicker;
const Search = Input.Search;
var moment = require('moment');

// const feeTypeList = ['定金', '保证金', '合同保证金', '装修保证金', '物业保证金', '租金', '手续费', '服务费', '保洁费', '物业费', '网费', '水费', '电费', '滞纳金', '违约金', '搬家费', '耗材费', '其他费用'];
// const billTypeList = ['合同', '运营'];
class ArrearsDetailedReport extends Component {
    state = {
        bill: [
            { value: 1, name: '合同' },
            { value: 2, name: '运营' }
        ],
        fee: [
            { value: 1, name: '定金' },
            { value: 2, name: '保证金'},
            { value: 3, name: '合同保证金' },
            { value: 4, name: '装修保证金' },
            { value: 5, name: '物业保证金' },
            { value: 6, name: '租金'},
            { value: 7, name: '手续费'},
            { value: 8, name: '服务费' },
            { value: 9, name: '保洁费' },
            { value: 10, name: '物业费' },
            { value: 11, name: '网费' },
            { value: 12, name: '水费' },
            { value: 13, name: '电费' },
            { value: 14, name: '滞纳金' },
            { value: 15, name: '违约金' },
            { value: 16, name: '搬家费' },
            { value: 17, name: '耗材费' },
            { value: 99, name: '其他费用' },
        ],
        columns: [
            {
                title: '应收日期',
                width: 150,
                dataIndex: 'dueDate',
                fixed: 'left'
            }, {
                title: '客户名称',
                width: 170,
                dataIndex: 'customerName',
                fixed: 'left',
                render: (text) => {
                    if (text && text.length > 13) {
                        return <Tooltip placement="top" title={text}>
                            <span className="lineEllipsis" style={{ 'WebkitBoxOrient': 'vertical' }}>{text.slice(0, 50)}...</span>
                        </Tooltip>
                    } else {
                        return text
                    }
                }
            }, {
                title: '客户类型',
                width: 120,
                dataIndex: 'customerTypeStr'
            }, {
                title: '项目',
                width: 150,
                dataIndex: 'projectName',
                render: (text) => {
                    if (text && text.length > 18) {
                        return <Tooltip placement="top" title={text}>
                            <span className="lineEllipsis" style={{ 'WebkitBoxOrient': 'vertical' }}>{text.slice(0, 50)}...</span>
                        </Tooltip>
                    } else {
                        return text
                    }
                }
            }, {
                title: '门牌号',
                width: 120,
                dataIndex: 'floorNo'
            }, {
                title: '账单类型',
                width: 120,
                dataIndex: 'billTypeStr'
            }, {
                title: '费用类型',
                width: 120,
                dataIndex: 'feeTypeStr'
                // key: 'feeType',
                // render(obj) {
                //     return (
                //         <div>
                //             <span className="bill-type" style={{ background: obj.billType == 1 ? '#434BA3' : "#B76C1E" }}>{billTypeList[obj.billType - 1]}</span>
                //             <span className="fee-type">{obj.feeType == 99 ? '其他费用' : feeTypeList[obj.feeType - 1]}</span>
                //         </div>
                //     )
                // }
            }, {
                title: '联系人',
                width: 150,
                dataIndex: 'contact'
            }, {
                title: '合同编号',
                width: 160,
                dataIndex: 'contractNo',
                // render: (text) => {
                //     if (text && text.length > 18) {
                //         return <Tooltip placement="top" title={text}>
                //             <span className="lineEllipsis" style={{ 'WebkitBoxOrient': 'vertical' }}>{text.slice(0, 50)}...</span>
                //         </Tooltip>
                //     } else {
                //         return text
                //     }
                // }
                
            }, {
                title: '合同状态',
                width: 100,
                dataIndex: 'contractStatusStr'
            }, {
                title: '合同周期',
                width: 150,
                dataIndex: 'contractPeriod'
            }, {
                title: '费用周期',
                width: 150,
                dataIndex: 'billPeriod'
            }, {
                title: '逾期天数',
                width: 120,
                dataIndex: 'overdueDays',
                render: (text, row) => (
                    <span style={{ color: 'red' }}>{text}</span>
                )
            }, , {
                title: '总额',
                width: 120,
                dataIndex: 'arrearsTotalAmount'
            }, {
                title: '已收',
                width: 120,
                dataIndex: 'receiveAmount'
            }, {
                title: '待收',
                width: 120,
                dataIndex: 'uncollectedAmount'
            }
        ],
        data: [
        ],
        total: 0,
        // startDateMoment: undefined,
        // endDateMoment: undefined,
        selectParams: {
            keyword: null,
            beginDate: moment().subtract(1, 'years').add(1, 'days').format('YYYY-MM-DD'),//应收日期起
            endDate: moment().format('YYYY-MM-DD'),//应收日期止
            projectEnCode: undefined,//项目ID
            customerType: undefined,//客户类型
            // departmentId: "",//部门人员
            billType: undefined,// 账单类型
            feeType: undefined,// 费用类型
            overdueMinMount :"",//欠费金额
            overdueMaxMount:"",
            pageNo: 1,// 当前页数
            pageSize: 10,// 每页条数
        },
        projects: [],
        customerTypeList: [],
        departmentPerson: [],
    }
    componentDidMount() {
        this.getLists()
        // 获取枚举类型
        this.getTypeList()
        // 获取项目、部门等信息
        this.getSearchData()
    }
    onSearchName = (val) => {
        let selectParams = this.state.selectParams;
        selectParams.pageNo = 1;
        selectParams.pageSize = 10;
        selectParams.keyword = val;
        this.setState({
            selectParams
        }, () => {
            this.getLists();
        })
    }
    // 点击欠费金额的确定按钮
    onSearch = (val) => {
        const selectParams = this.state.selectParams;
        if (selectParams.overdueMinMount && selectParams.overdueMaxMount && selectParams.overdueMinMount * 1 > selectParams.overdueMaxMount * 1 ) {
            message.error('请输入正确的区间');
        } else {
            this.getLists();
        }
    }
    setName = (e) => {
        let selectParams = this.state.selectParams;
        selectParams.keyword = e.target.value;
        this.setState({
            selectParams
        })
    }
    handleDate = (value, date) => {
        let selectParams = this.state.selectParams;
        selectParams.pageNo = 1;
        selectParams.pageSize = 10;
        selectParams.beginDate = date[0];
        selectParams.endDate = date[1];
        this.setState({
            selectParams,
            // startDateMoment: value[0],
            // endDateMoment: value[1]
        }, () => {
            this.getLists();
        })
    }
    selectMjMin = (value) => {
        this.setState({
            selectParams: {
                ...this.state.selectParams,
                overdueMinMount: value
            }
        }, function () {
            this.showOkBtn()
        })
    }

    selectMjMax = (value) => {
        this.setState({
            selectParams: {
                ...this.state.selectParams,
                overdueMaxMount: value
            }
        }, function () {
            this.showOkBtn()
        })
    }
    showOkBtn() {
        this.setState({
            isOkBtnShow: (this.state.selectParams.arrearsFrom != '' || this.state.selectParams.arrearsTo != '') ? true : false
        })
    }
    //选择项目-房源某项
    chooseProject(val) {
        let selectParams = this.state.selectParams;
        selectParams.projectEncode = val;
        selectParams.pageNo = 1;
        this.setState({
            selectParams
        }, () => {
            this.getLists();
        })
    }
    onChangePage = (pageNo, pageSize) => {
        let selectParams = this.state.selectParams;
        selectParams.pageNo = pageNo;
        selectParams.pageSize = pageSize;
        this.setState({
            selectParams
        }, () => {
            this.getLists();
        })
    }
    getTypeList = () => {
        const config = {
            api: API.contractSearchInit
        }
        http(config).then(res => {
            const { auditStatus, customerType, signType, status } = res.data
            this.setState({
                // dealMethod: this.generateList(signType),
                customerTypeList: this.generateList(customerType),
                // rentStatus: this.generateList(status),
                // auditStatus: this.generateList(auditStatus)
                // customerTypeList: res.data.customerType,
                // feeTypeList: this.generateList(feeTypeList),
            })
        })
    }
    generateList = (list, key, addUnit) => {
        let keyMap = { key: 'value', value: 'name' }
        if (key) {
            keyMap = key
        }
        let target = []
        list.forEach(per => {
            if (key && addUnit) {
                per.name += '层'
            }
            target.push(changeObjkey(per, keyMap, true))
        })
        return target
    }
    getSearchData = () => {
        const projectConfig = {
            api: API.commonProjectfilter
        }
        // 项目
        http(projectConfig).then(proRes => {
            const mapKey = { encode: 'value', name: 'name' }
            this.setState({
                projects: this.generateList(proRes.data, mapKey)
            })
        })

    }
    getDetPerData = () => {
        const departmentPerson = this.state.departmentPerson
        if (departmentPerson && departmentPerson.length) return
        const deptConfig = {
            api: API.userGetList,
            data: {
                departmentEncode: storage.get('USER').departmentIdCode
            }
        }
        // 部门人员
        http(deptConfig).then(deptRes => {
            this.setState({
                departmentPerson: this.transDeptData(deptRes.data.datas)
            })
        })
    }
    transDeptData = (list) => {
        let transList = []
        list.forEach(per => {
            transList.push({ value: per.idCode, name: `${per.departmentName}-${per.name}` })
        })
        return transList
    }
    selectChange = (attr, data) => {
        let selectParams = this.state.selectParams
        selectParams.page = 1
        // if (attr === 'floorIds') {
        //     selectParams[attr].push(data)
        // } else {
        // selectParams[attr] = data
        //     if (attr === 'projectEnCode') {
        //         this.getFloorData(data)
        //     }
        // }
        selectParams[attr] = data
        this.setState({ selectParams }, () => {
            this.getLists()
            // this.getStatistics()
        })
    }
    getLists() {
        const config = {
            api: API.reportformArrearsDetailedReport,
            data: this.state.selectParams,
            showLoading: true
        };
        http(config).then(res => {
            const data = res.data;
            const list = data && data.datas.map((item, key) => {
                var contact = item.contactName ? <div><div>{item.contactName}</div><div>{item.contactPhone}</div></div> : '-'; //联系人
                var contractPeriod = item.contractBeginDate ? <div><div>起：{item.contractBeginDate}</div><div>止：{item.contractEndDate}</div></div> : '-'; //合同周期
                var billPeriod = item.billBeginDate ? <div><div>起：{item.billBeginDate}</div><div>止：{item.billEndDate}</div></div> : '-'; //费用周期
                return {
                    key: key,
                    dueDate: item.date,
                    customerName: item.customerName,
                    customerTypeStr: item.customerTypeStr,
                    projectName: item.projectName,
                    floorNo: item.floorNo,
                    billTypeStr: item.billTypeStr,
                    feeTypeStr: item.feeTypeStr,
                    contact: contact,
                    contractNo: item.contractNo,
                    contractStatusStr: item.contractStatusStr,
                    contractPeriod: contractPeriod,
                    billPeriod: billPeriod,
                    overdueDays: item.overdueDays,
                    arrearsTotalAmount: item.arrearsTotalAmount,
                    receiveAmount: item.receiveAmount,
                    uncollectedAmount: item.uncollectedAmount,
                }
            })
            this.setState({
                data: list || [],
                total: data && data.totalRecord
            })

        })
    }
    export = () => {
        const config = {
            api: API.reportformArrearsDetailedReportExportReport,
            data: this.state.selectParams,
            responseType: 'blob',
            showLoading: true
        };
        http(config).then(res => {
            if (res.size < 1024) {
                message.error('下载异常，请稍后重试')
            } else {
                const content = res
                const blob = new Blob([content], { type: 'application/vnd.ms-excel' })
                const fileName = `欠费明细报表${moment(new Date()).format('YYYY-MM-DD HH:mm')}.xlsx`
                if ('download' in document.createElement('a')) { // 非IE下载
                    const elink = document.createElement('a')
                    elink.download = fileName
                    elink.style.display = 'none'
                    elink.href = URL.createObjectURL(blob)
                    document.body.appendChild(elink)
                    elink.click()
                    URL.revokeObjectURL(elink.href) // 释放URL 对象
                    document.body.removeChild(elink)
                } else { // IE10+下载
                    navigator.msSaveBlob(blob, fileName)
                }
            }
        })
    }
    reset = () => {
        this.setState({
            isReset: true,
            // startDateMoment: undefined,
            // endDateMoment: undefined,
            selectParams: {
                keyword: null,
                beginDate: moment().subtract(1, 'years').add(1, 'days').format('YYYY-MM-DD'),//应收日期起
                endDate: moment().format('YYYY-MM-DD'),//应收日期止
                projectEnCode: undefined,//项目ID
                customerType: undefined,//客户类型
                // departmentId: "",//部门人员
                billType: undefined,// 账单类型
                feeType: undefined,// 费用类型
                overdueMinMount: "",//欠费金额
                overdueMaxMount: "",
                pageNo: 1,// 当前页数
                // pageSize: 10,// 每页条数
                pageSize: this.state.selectParams.pageSize
            }
        }, () => {
            this.setState({
                isReset: false,
            })
            this.getLists();
        })
    }
    render() {
        return (
            <div className="report-con">
                <div className='report-header' style={{ display: 'block' }}>
                    <Breadcrumb>
                        <BreadcrumbItem>报表中心</BreadcrumbItem>
                        <BreadcrumbItem>欠费明细报表</BreadcrumbItem>
                    </Breadcrumb>
                    <Button type="primary" className='download' onClick={this.export}>下载报表</Button>
                </div>
                <div className='report-list'>
                    {
                        !this.state.isReset ? (
                            <div className='manage-filter'>
                                {/* <Row gutter={12} style={{ marginBottom: '12px', display: 'flex' }}> */}
                                <Search
                                    placeholder="请输入客户名称、联系人姓名/手机号、合同编号"
                                    value={this.state.selectParams.keyword}
                                    onSearch={this.onSearchName}
                                    onChange={this.setName}
                                    className='manage-search'
                                    style={{ width: '383px'}} 
                                />
                          
                                应收日期 <RangePicker className='manage-filter-date'
                                    defaultValue={[moment().subtract(1, 'years').add(1, 'days'), moment()]} allowClear={false} disabledDate={disabledDate}
                                    onChange={this.handleDate} style={{ width: '206px',marginLeft: '4px' }} />
                                {/* </Row>  */}
                                <Row gutter={12} style={{ marginTop: '12px', marginLeft: '0',  display: 'flex'}}>
                                    <ISelect data={this.state.projects} placeholder="项目名称" value={this.state.selectParams.projectEnCode}
                                        selectMethod={this.selectChange.bind(this, 'projectEnCode')} style={{ width: '120px', marginRight: '12px' }} />
                                <ISelect data={this.state.customerTypeList} placeholder="客户类型" value={this.state.selectParams.customerType}
                                        selectMethod={this.selectChange.bind(this, 'customerType')} style={{ width: '120px', marginRight: '12px' }} />
       
                                <ISelect data={this.state.bill} placeholder="账单类型" value={this.state.selectParams.billType}
                                        selectMethod={this.selectChange.bind(this, 'billType')} style={{ width: '120px', marginRight: '12px' }} />
                                <ISelect data={this.state.fee} placeholder="费用类型" value={this.state.selectParams.feeType}
                                        selectMethod={this.selectChange.bind(this, 'feeType')} style={{ width: '120px', marginRight: '12px' }} />
                                    <span style={{ lineHeight: '30px' }} >欠款金额</span>
                                    <NumericInput className='input' placeholder="最小值"
                                        style={{ width: '110px',marginLeft: '8px' }}
                                        onChange={this.selectMjMin} value={this.state.selectParams.overdueMinMount}
                                        suffix={
                                            <div>元</div>
                                        }
                                    />
                                    <span style={{ lineHeight: '30px' }} >~</span>
                                    <NumericInput className='input' placeholder="最大值" style={{ width: '110px', marginRight: '12px' }} onChange={this.selectMjMax} value={this.state.selectParams.overdueMaxMount}
                                        suffix={
                                            <div>元</div>
                                        }
                                    />
                                    <Button style={{ background: '#FBBC06', color: '#000000',marginRight: '12px', display: this.state.isOkBtnShow ? 'inline' : 'none' }} onClick={() => this.onSearch()}>确定</Button>
                                    <span className="report-reset" style={{ cursor: 'pointer', lineHeight: '30px' }} onClick={this.reset}>重置</span>
                                </Row>
                            </div>
                        ) : (null)
                    }

                    <Table columns={this.state.columns} data={this.state.data} total={this.state.total} change={this.onChangePage} current={this.state.selectParams.pageNo} scroll={{ x: 2070 }} />
                </div>
            </div>
        );
    }
}

export default ArrearsDetailedReport;

function disabledDate(current) {
    // Can not select days before today and today
    return current > moment() || current < moment().subtract(5, 'years'); // February 28;
}