import React, { Component } from "react";
import { Breadcrumb, Button, Input, InputNumber, Layout, Modal, Switch } from "antd";
import Select from "@/components/common/Select";
import Table from "CMPT/common/Table";
import { FormatSearch } from "CMPT/common.js";
import moment from "moment";
import { API } from "@/http/api";
import http from "@/http";
import "./Video.less";
const BreadcrumbItem = Breadcrumb.Item;
const { Search } = Input;
const { Content } = Layout;

class Feedback extends Component {
  state = {
    selectedReason: "",
    code: null,
    type: null,
    issueTitle: null,
    selfProcessData: null,
    pageConfig: {
      current: 1,
      total: 10,
      pageSize: 10
    },
    pageNo: 1,
    pageSize: 10,
    total: 0,
    reasonMap: {
      1: "太复杂，看不懂",
      2: "字太多，懒得看",
      3: "写的不够清楚",
      4: "根本不是我想看的"
    },
    columns: [
      {
        title: "序号",
        key: "code",
        render: (text, record, index) => {
          return <span>{index + 1}</span>;
        }
      },
      {
        title: "反馈用户昵称",
        dataIndex: "nickName"
      },
      {
        title: "反馈用户手机号",
        dataIndex: "mobile"
      },
      {
        title: "所属公司",
        dataIndex: "companyName"
      },
      {
        title: "反馈时间",
        dataIndex: "createTime",
        render: time => {
          return <span>{moment(time).format("YYYY-MM-DD HH:mm")}</span>;
        }
      },
      {
        title: "原因",
        dataIndex: "feedbackReason",
        render: value => {
          return (
            <span>
              {(value && value != '-') ? ((value < 1 || value > 4) ? "-" : this.state.reasonMap[value]) : "-"}
            </span>
          );
        }
      },
      {
        title: "详细意见",
        dataIndex: "feedbackReasonDetail",
        render: value => {
          return <span>{value ? value : "-"}</span>;
        }
      }
    ],
    reasonList: [
      { value: "", name: "全部" },
      { value: "1", name: "太复杂，看不懂" },
      { value: "2", name: "字太多，懒得看" },
      { value: "3", name: "写的不够清楚" },
      { value: "4", name: "根本不是我想看的" }
    ]
  };

  handleChangeStatus = value => {
    this.setState({
      selectedReason: value
    }, () => this.queryList());
  };

  queryList() {
    let { selectedReason, code } = this.state;

    const config = {
      api: API.helpFeedBackListByPage,
      data: {
        feedbackReason: (selectedReason && selectedReason.length > 0) ? selectedReason : undefined,
        helpMessageCode: code,
        feedbackType: 2 // 2表示 负反馈。 目前反馈列表值查看负反馈 20200424
      }
    };
    http(config).then(res => {
      if (res && res.success) {
        let newData = res.data.datas;

        this.setState({
          selfProcessData: newData,
          pageNo: res.data.pageNo,
          pageSize: res.data.pageSize,
          total: res.data.totalRecord
        });
      }
    });
  }

  componentDidMount() {
    let search = this.props.history.location.search;
    var result = FormatSearch(search);
    this.setState({
      code: result.code,
      type: result.type,
      issueTitle: result.title
    },
      () => this.queryList()
    );
  }

  tableChange = (page, pageSize, sort) => {
    let order = sort && sort.order == "ascend" ? 1 : 2;
    this.setState(
      {
        pageNo: page,
        pageSize: pageSize,
        orderByList: order
      },
      () => this.queryList()
    );
  };

  render() {
    const { pageNo, pageSize, total, selfProcessData } = this.state;
    let myState = this.state;

    return (
      <Content className="intention-list intention-content">
        <div className="intention-list-head flexbox">
          <Breadcrumb className="intention-list-head-bread">
            <BreadcrumbItem>常见问题</BreadcrumbItem>
            <BreadcrumbItem>问题反馈</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div className="intention-add-body">
          <div style={{ marginBottom: "20px" }}>
            <span style={{ marginRight: "20px" }}>问题:</span>
            <span>{myState.issueTitle}</span>
          </div>
          <div style={{ marginBottom: "20px" }}>
            {" "}
            <span style={{ marginRight: "20px" }}>原因:</span>
            <Select
              style={{ width: "204px" }}
              placeholder="全部"
              value={this.state.selectedReason}
              data={this.state.reasonList}
              selectMethod={this.handleChangeStatus}
            />
          </div>
          <div className="intention-table-contents">
            {this.state.selfProcessData ? (
              <Table
                rowKey="id"
                columns={this.state.columns}
                change={this.tableChange}
                data={selfProcessData}
                pageSize={pageSize}
                current={pageNo}
                total={total}
                selfProcessData={this.state.selfProcessData}
              />
            ) : null}
          </div>
        </div>

      </Content>
    );
  }
}

export default Feedback;
