import React, { Component } from 'react';
import { Breadcrumb, Tabs } from 'antd';
import './ContractManagementList.less';
import ContractManagementList from './ContractManagementList';

const { TabPane } = Tabs;
const BreadcrumbItem = Breadcrumb.Item;

class ContractManagementLists extends Component {
    state = {
        activeKey: 1
    }
    change = (activeKey) => {
        this.setState({
            activeKey
        })
    }
    render() {
        let activeKey = this.state.activeKey
        return (

            <div className="enterprise-auth-con">
                <div className='enterprise-auth-header'>
                    <Breadcrumb>
                        <BreadcrumbItem>签约管理</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <div><Tabs defaultActiveKey="1" className='tabs' onChange={this.change}>
                    <TabPane tab="全部" key="1" className='tab' >
                        {
                            activeKey == 1 ?
                                <ContractManagementList state={null} />
                                : null
                        }

                    </TabPane>
                    <TabPane tab="待审核" key="2" className='tab' >
                        {
                            activeKey == 2 ?
                                <ContractManagementList state={[2]} />
                                : null
                        }

                    </TabPane>
                    <TabPane tab="待盖章" key="3" className='tab'>
                        {
                            activeKey == 3 ?
                                <ContractManagementList state={[3, 4, 5, 8]} />
                                : null
                        }

                    </TabPane>
                    <TabPane tab="已签约" key="4" className='tab'>
                        {
                            activeKey == 4 ?
                                <ContractManagementList state={[6]} />
                                : null
                        }

                    </TabPane>
                    <TabPane tab="签约失败" key="5" className='tab'>
                        {
                            activeKey == 5 ?
                                <ContractManagementList state={[7]} />
                                : null
                        }

                    </TabPane>
                </Tabs>
                </div>

            </div>


        );
    }
}

export default ContractManagementLists;