import {
    throttle
} from './index';

import {
    message
} from 'antd';
import { loadavg } from 'os';

let loadingCount = 0;

export function showLoading() {
    loadingCount++;
    if (loadingCount === 1) {

            // 只在第一次调用时显示
            const loadingUi = document.querySelector('#main .ant-spin.ant-spin-spinning');

            if (!loadingUi) {

                const loadingUi = document.querySelector('#main .ant-spin.ant-spin-spinning');
                loadingUi && (loadingUi.style.display = 'block');

            } else {

                loadingUi.style.display = 'block'

            }

    }
}

export function hideLoading(mustClose) {
    if (mustClose) {
        loadingCount = 1;
    }

    if (loadingCount > 0) {
        // 必须是显示之后才能隐藏
        loadingCount--;
        if (loadingCount === 0) {


                const loadingUi = document.querySelector('#main .ant-spin.ant-spin-spinning');
                if (!loadingUi) {
                    const loadingUi = document.querySelector('#main .ant-spin.ant-spin-spinning');
                    loadingUi && (loadingUi.style.display = 'block');

                } else {

                    loadingUi.style.display = 'none'
                }

        }
    }
}

message.config({
    duration: 2,
    maxCount: 3,
});

export const showErrorMessage = throttle(function (msg) {
    msg && message.error(msg);
});

export const showSuccessMessage = throttle(function (msg) {
    //console.log('successssssssssssssssssssss')
    msg && message.success(msg,1);//产品说提示时间太长了 改成1秒就行
});
