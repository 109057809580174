import React, { Component } from 'react';
import { API } from '@/http/api';
import http from '@/http';
import { Breadcrumb, message, Tooltip } from 'antd';
import Default from './component/ContractDefault';
import './ContractDecision.less'
class ContractDecision extends Component {
    state = {
        projectList: [],
        defaultSettingProjects: [],
        currentProject: 'qeZDWfgpvSs', // 默认的code,写死
        isDefault: true,
        setting: {
            chooseDefault: '1',
            serverNumberOn: false,
            serverNumber: '',
            estateCalculateMethod: '1',
            aheadDays: 0,
            delayPayment: 0,
            estatePrice: 0.00,
            periodDevideMethod: '1'
        }
    }
    chooseProject = (id) => {
        this.setState({
            currentProject: id,
            isDefault: id === 'qeZDWfgpvSs'
        }, () => {
            this.getProjectSetting()
        })
    }
    componentDidMount() {
        this.getProjectSetting()
        const projectConfig = {
            api: API.commonProjectfilter
        }
        // 项目
        http(projectConfig).then(pro => {
            let projectList = pro.data && pro.data.map(per => {
                return {
                    name: per.name,
                    id: per.encode
                }
            }) || []
            this.setState({
                projectList: projectList
            }, () => {
                this.getDefaultSettingProjects()
            })
        })
    }
    getDefaultSettingProjects = () => {
        let projectCodes = this.state.projectList.map(per => per.id)
        if (projectCodes.length) {
            const config = {
                api: API.decisionDefaultSettingProjects,
                data: {
                    projectIds: projectCodes,
                    bizType: '4'
                }
            }
            http(config).then(res => {
                this.setState({
                    defaultSettingProjects: res.data || []
                })
            })
        }
    }
    getProjectSetting = () => {
        // 获取项目配置
        const defaultConfig = {
            api: API.decisionByProjectEncode,
            path: {
                projectEncode: this.state.currentProject
            },
            data: {
                bizType: '4'
            }
        }
        http(defaultConfig).then(res => {
            let setting = {
                chooseDefault: '1',
                serverNumberOn: false,
                serverNumber: '',
                estateCalculateMethod: '1',
                aheadDays: 0,
                delayPayment: 0,
                estatePrice: '',
                periodDevideMethod: '1'
            }
            if (res.data && res.data.length) {
                res.data.forEach(per => {
                    const key = this.mapKey(per.key, true)
                    let value = per.value
                    if (key === 'serverNumberOn') {
                        value = value === '1' ? true : false
                    }
                    setting[key] = value
                })
            }
            this.setState({
                setting
            })
        })
    }
    saveSetting = (obj) => {
        const transObj = obj
        let transData = []
        if (this.state.currentProject === 'qeZDWfgpvSs') {
            transObj.chooseDefault = '0'
        }
        if (transObj.chooseDefault === '1') {
            transData = [{key: 'choose_default', value: '1'}]
        } else {
            transData = this.transData(transObj)
        }
        const config = {
            api: API.decisionEdit,
            data: {
                projectEncode: this.state.currentProject,
                bizType: '4',
                decisionList: transData
            }
        }
        http(config).then(res => {
            console.log(res.data)
            if (res.success) {
                message.success('保存成功')
                this.getDefaultSettingProjects()
            }
        })
    }
    transData = (obj) => {
        let target = []
        Object.keys(obj).forEach(key => {
            let value = obj[key]
            if (typeof value === 'boolean') {
                value = value ? 1 : 0
            }
            target.push({key: this.mapKey(key), value: value})
        })
        return target
    }
    mapKey = (key, reverse) => {
        if (reverse) {
            return {
                choose_default: 'chooseDefault',
                defined_contract_code_switch: 'serverNumberOn',
                defined_code_str: 'serverNumber',
                property_terms_and_charges: 'estateCalculateMethod',
                days_of_advance_payment: 'aheadDays',
                late_fee: 'delayPayment',
                property_price: 'estatePrice',
                rent_period_division: 'periodDevideMethod'
            }[key]
        }
        return {
            chooseDefault: 'choose_default',
            serverNumberOn: 'defined_contract_code_switch',
            serverNumber: 'defined_code_str',
            estateCalculateMethod: 'property_terms_and_charges',
            aheadDays: 'days_of_advance_payment',
            delayPayment: 'late_fee',
            estatePrice: 'property_price',
            periodDevideMethod: 'rent_period_division'
        }[key]
    }
    showName = (str) => {
        if (str && str.length > 9) {
            return <Tooltip placement="top" title={str}>{str.slice(0, 9)}...</Tooltip>
        }
        return str
    }
    render() {
        const { currentProject, isDefault } = this.state
        return (
            <div className="decision-page">
                <div className="decision-bread">
                    <Breadcrumb>
                        <Breadcrumb.Item>设置中心</Breadcrumb.Item>
                        <Breadcrumb.Item>决策</Breadcrumb.Item>
                        <Breadcrumb.Item>合同决策</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className="decision-block">
                    <div className="decision-left">
                        <ul>
                            <li className={currentProject === 'qeZDWfgpvSs' ? 'active':''} onClick={this.chooseProject.bind(this, 'qeZDWfgpvSs')}>默认决策配置</li>
                            {this.state.projectList.map(per => {
                                return <li key={per.id} className={currentProject === per.id ? 'active':''}
                                           onClick={this.chooseProject.bind(this, per.id)}>
                                            <span className="name">{this.showName(per.name)}</span>
                                            <span className="tag"
                                                  style={{display: this.state.defaultSettingProjects.indexOf(per.id) !== -1 ? 'inline' : 'none'}}>
                                                  自定义
                                            </span>
                                        </li>
                            })}
                        </ul>
                    </div>
                    <div className="decision-right">
                        <Default isDefault={isDefault} setting={this.state.setting} save={this.saveSetting} />
                    </div>
                </div>
            </div>
        );
    }
}

export default ContractDecision;