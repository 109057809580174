import React from 'react'
import { Form, Input, Icon, Button, Row, Col, List, Tooltip, Checkbox } from 'antd';
import Select from 'CMPT/common/Select';
import './CombineAreaPopView.less'
import http from '@/http'
import { API } from '@/http/api';

const FormItem = Form.Item;
const { TextArea } = Input;

class CombineAreaPopView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isShowErrorSelectedInfo: false,
            selectedAreaList: [this.props.zoneVosData.encode] // 默认选中自己
        }
        
        this.businessDatas = [
            { value: 0, name: '不可招商' },
            { value: 1, name: '可招商' }
        ];
        this.rentStateDatas = [
            
            { value: 1, name: '未出租' },
            { value: 2, name: '已出租' }
        ];
        
    }
    componentWillMount(){
        if(this.props.zoneVosData.merchantStatus==0){
            this.setState({
                hideRentStatus:true
            })
        }
        this.getProjectSetting()
    }
    getProjectSetting = () => {
        // 获取项目配置
        const defaultConfig = {
            api: API.decisionByProjectEncode,
            path: {
                projectEncode: "qeZDWfgpvSs"
            },
            data: {
                bizType: '1'
            }
        }
        http(defaultConfig).then(res => {            
            this.setState({
                defaultSquare : res.data.length?res.data[res.data.length-1].description:'建筑面积'
            })
        })        
    }
    combineArea = (values) => {
        const config = {
            api: API.zoneMerge,
            data: values,
            showLoading: true,
        }
        http(config).then(result => {
            if (result.success) {
                this.props.handlerBackCombineAreaClick('ok');
            }
        })
    }

    handleSubmit = () => {
        if (this.state.selectedAreaList.length < 2) {
            this.setState({
                isShowErrorSelectedInfo: true
            });
            return;
        }

        this.props.form.validateFields((err, value) => {
            if (!err) {
                value.buildingArea = parseInt(value.buildingArea);
                value.innerArea = parseInt(value.innerArea);
                value.unitPrice = parseInt(value.unitPrice);
                value.useArea = parseInt(value.useArea);
                Object.assign(value, {
                    'zoneEncodes': [...this.state.selectedAreaList],
                    floorEncode: this.props.zoneVosData.floorEncode,
                    projectEncode: this.props.zoneVosData.projectEncode
                });
                value.rentStatus= value.rentStatus?value.rentStatus:0
                this.combineArea(value);
            }
        });
    }

    cancelEdit = () => {
        this.props.handlerBackCombineAreaClick('cancel')
    }

    collectAreas = (id) => {
        let pos = this.state.selectedAreaList.indexOf(id);
        if (pos < 0) {
            this.state.selectedAreaList.push(id);
        } else {
            this.state.selectedAreaList.splice(pos, 1);
        }

        this.setState({
            isShowErrorSelectedInfo: (this.state.selectedAreaList.length < 2),
            selectedAreaList: this.state.selectedAreaList
        });
    }
    merchantStatusChange=(value)=>{
        console.log(value)
        if(value==0){
            this.setState({
                hideRentStatus:true
            })
        }else{
            this.setState({
                hideRentStatus:false
            })
        }
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div className="combine-container">
                <Row>
                    <Col span={24}>
                        <div className="combine-title">合并区域</div>
                    </Col>
                </Row>
                <Row gutter={48}>
                    <Col span={11}>
                        <div className="combine-left-part">
                            <div className="combine-left-part-title">
                                <span>请勾选要合并的区域</span>
                                <Tooltip title="仅可对无合同且账务已结清的区域进行合并" placement="right" arrowPointAtCenter>
                                    <Icon type="question-circle" className="list-circle" style={{cursor: 'pointer'}}/>
                                </Tooltip>
                            </div>
                            <List
                                className="combine-left-part-list"
                                header={null}
                                footer={null}
                                dataSource={this.props.zoneVos}
                                split={false}
                                itemLayout="vertical"
                                renderItem={(item) => (
                                    <List.Item key={item.encode}>
                                        <Checkbox onChange={this.collectAreas.bind(this, item.encode)}
                                                  checked={this.state.selectedAreaList.indexOf(item.encode) >= 0}>
                                            {`${item.zoneNo} ${item.buildingArea}m² ${item.unitPrice}元/m²/日`}
                                        </Checkbox>
                                    </List.Item>
                                )}
                            />
                        </div>
                    </Col>
                    <Col span={13}>
                        <Form onSubmit={this.handleSubmit} className="editForm">
                            <FormItem>
                                <div className="combine-right-part">合并后区域</div>
                            </FormItem>
                            <FormItem label="门牌号">
                                {getFieldDecorator('zoneNo', {
                                    rules: [{
                                        required: true, message: '请填写门牌号!'
                                    }],
                                    initialValue: this.props.zoneVosData.zoneNo
                                })(
                                    <Input autoComplete="off" placeholder="请输入" maxLength={50} />
                                )}
                            </FormItem>
                            <FormItem>
                                <FormItem label="招商状态" style={{ display: 'inline-block' }}>
                                    {getFieldDecorator('merchantStatus', {
                                        rules: [{
                                            required: true, message: '请选择!'
                                        }],
                                        initialValue: this.props.zoneVosData.merchantStatus
                                    })(
                                        <Select style={{ width: '158px' }} data={this.businessDatas} onChange={this.merchantStatusChange}/>
                                    )}
                                </FormItem>
                                {
                                    !this.state.hideRentStatus?(<FormItem label="出租状态" style={{ display: 'inline-block', marginLeft: '20px' }}>
                                        {getFieldDecorator('rentStatus', {
                                            rules: [{
                                                required: true, message: '请选择!'
                                            }],
                                            initialValue: this.props.zoneVosData.rentStatus
                                        })(
                                            <Select style={{ width: '158px' }} data={this.rentStateDatas} />
                                        )}
                                    </FormItem>):null
                                }
                                
                            </FormItem>
                            <FormItem>
                                <FormItem label="建筑面积" style={{ display: 'inline-block' }}>
                                    {getFieldDecorator('buildingArea', {
                                        rules: [{
                                            required: true, message: '请填写建筑面积!'
                                        }],
                                        initialValue: this.props.zoneVosData.buildingArea
                                    })(
                                        <Input autoComplete="off" placeholder="请输入" style={{ width: '158px' }}
                                            suffix={
                                                <div>m²</div>
                                            } />
                                    )}
                                </FormItem>
                                <FormItem label="预计单价" style={{ display: 'inline-block', marginLeft: '20px' }}>
                                    {getFieldDecorator('unitPrice', {
                                        rules: [{
                                            required: true, message: '请填写预计单价!'
                                        }],
                                        initialValue: this.props.zoneVosData.unitPrice
                                    })(
                                        <Input autoComplete="off" placeholder="请输入" style={{ width: '158px' }}
                                            suffix={
                                                <div>元/m²/日</div>
                                            } />
                                    )}
                                </FormItem>
                            </FormItem>
                            <FormItem>
                                <FormItem label="使用面积" style={{ display: 'inline-block' }}>
                                    {getFieldDecorator('useArea', {
                                        rules: [{
                                            required: this.state.defaultSquare=="使用面积",
                                            message: '请填写使用面积!'
                                        }],
                                        initialValue: this.props.zoneVosData.useArea
                                    })(
                                        <Input autoComplete="off" placeholder="请输入" style={{ width: '158px' }}
                                            suffix={
                                                <div>m²</div>
                                            } />
                                    )}
                                </FormItem>
                                <FormItem label="套内面积" style={{ display: 'inline-block', marginLeft: '20px' }}>
                                    {getFieldDecorator('innerArea', {
                                        rules: [{
                                            required: this.state.defaultSquare=="套内面积",
                                            message: '请填写套内面积!'
                                        }],
                                        initialValue: this.props.zoneVosData.innerArea
                                    })(
                                        <Input autoComplete="off" placeholder="请输入" style={{ width: '158px' }}
                                            suffix={
                                                <div>m²</div>
                                            } />
                                    )}
                                </FormItem>
                            </FormItem>
                            <FormItem label="备注">
                                {getFieldDecorator('remark', {
                                    rules: [{
                                        required: false
                                    }],
                                    initialValue: this.props.zoneVosData.remark
                                })(
                                    <TextArea rows={4} maxLength={200} placeholder="请输入" />
                                )}
                            </FormItem>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <div className='space-bw' style={{ marginBottom: '20px' }}>
                            <span className="space-bw-first">{this.state.isShowErrorSelectedInfo ? '请至少选择两个区域进行合并' : ' '} </span>
                            <span>
                                <Button type="primary" style={{marginRight: '10px',color:'#272A3A'}}
                                    htmlType="submit" onClick={this.handleSubmit}>
                                    确定
                                </Button>
                                <Button onClick={this.cancelEdit}>取消</Button>
                            </span>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Form.create({ name: 'CombineAreaPopView' })(CombineAreaPopView);