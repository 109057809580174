import React, { Component } from 'react';
import { Layout, Menu, Breadcrumb, Button, Form, Icon, Input, Row, Col, DatePicker, Upload, Modal, Tooltip, Slider, InputNumber, Cascader, Select } from 'antd';
import Select2 from 'CMPT/common/Select';
import { API } from '@/http/api';
import http from '@/http';
import DateRange from 'CMPT/common/DateRange';
import FileUpload from 'CMPT/common/FileUpload'
import { industryTypeConfig, areaTypeConfig } from './intentionConfig';
import { channelTypeConfig, sourceTypeConfig, statusConfig, customerTypeConfig } from './customerConfig'
import { objectarrayToArray } from '@/utils/fliter';
import { customerAdd, customerCheck } from './service/customerService';
import { changeObjkey, getNewPhone } from '@/utils/index'
import ImageUploadBiz from 'CMPT/common/ImageUpLoadBiz'
import FileUploadBiz from 'CMPT/common/FileUploadBiz'
import { Link } from 'react-router-dom'
import reg from '@/utils/regex'
import debounce from 'lodash/debounce';
import axios from 'axios';
import user from '@/utils/user'
import './intention.less'
import {
    AUTH_KEYS
} from '@/utils/auth';
import userUtil from "@/utils/user";
import moment from 'moment';

const { Header, Content, Footer } = Layout;
const { Option } = Select;
const { TextArea } = Input;
const { phone, idCard, telephone, socialCreditCode, website, zipCode, phoneNew } = { ...reg }

class AddCustomer extends Component {

    componentDidMount() {
        this.loadProvince();
    }

    // 提交保存
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log(this.props.form.getFieldsValue());
                let fieldsObject = this.props.form.getFieldsValue();
                console.log(fieldsObject)
                if (fieldsObject.addressEncode) {
                    fieldsObject.provinceEncode = fieldsObject.addressEncode[0];
                    fieldsObject.cityEncode = fieldsObject.addressEncode[1];
                    fieldsObject.regionEncode = fieldsObject.addressEncode[2];
                }
                if (fieldsObject.contractDate && fieldsObject.contractDate.length > 1) {
                    fieldsObject.contractStartDate = moment(fieldsObject.contractDate[0]).format('YYYY-MM-DD')
                    fieldsObject.contractEndDate = moment(fieldsObject.contractDate[1]).format('YYYY-MM-DD')
                }
                if (fieldsObject.signDate && fieldsObject.signDate.length > 0) {
                    fieldsObject.signDate = moment(fieldsObject.signDate[0]).format('YYYY-MM-DD')
                }
                fieldsObject.adminPhone = fieldsObject.adminPhone.replace(/\s+/g, '');
                // fieldsObject.userName = this.state.userName;
                fieldsObject.pictureInfoList = this.state.pictureInfoList;
                fieldsObject.fileInfoList = this.state.fileInfoList;
                delete fieldsObject.addressEncode;
                for (let i in fieldsObject) {
                    if (!fieldsObject[i]) {
                        fieldsObject[i] = null
                    }
                }

                customerAdd(fieldsObject).then(res => {
                    if (res.code == 0) {
                        this.setState({
                            enterpriseName: fieldsObject.enterpriseName,
                            adminPhone: fieldsObject.adminPhone,
                            showConfirmFlag: true
                        })
                    }
                })
            }
        });
    }

    // 重复字段核查
    handleCheck = (e) => {
        e.preventDefault();
        let fieldsObject = this.props.form.getFieldsValue();
        if (fieldsObject.adminPhone) {
            fieldsObject.adminPhone = fieldsObject.adminPhone.replace(/\s+/g, '');
        }
        let checkData = {};
        checkData.adminPhone = fieldsObject.adminPhone;
        checkData.adminEmail = fieldsObject.adminEmail;
        checkData.adminIdentityCard = fieldsObject.adminIdentityCard;
        checkData.enterpriseName = fieldsObject.enterpriseName;
        customerCheck(checkData).then(res => {

        })
    }


    // 隐藏弹框
    hideConfirm = () => {
        this.setState({
            showConfirmFlag: false,
        });
        this.props.history.push('/app/crm/client')
    }

    state = {
        userList: [],
        imageUrl: null,
        collapse: false,
        intentionRate: 0,
        customerType: null,
        userName: null,
        remarkLength: 0,
        pictureInfoList: [],
        blockList: [],
        fileInfoList: [],
        followUserList: [],
        accountTypeArray: [
            {
                name: '正式',
                value: 1
            },
            {
                name: '试用',
                value: 0
            },
        ],
        region: [],
        contractDisabledDate: undefined,
        contractStartDate: null, //合同开始时间
        contractEndDate: null, // 合同结束时间

        dataSource: {//基础信息
            adminPhone: null, //管理员手机号
            adminEmail: null, //管理员邮箱
            adminIdentityCard: null, //管理员身份证号
            acountType: null, //账户类型

            enterpriseName: null, //企业名称
            projectNumber: null, //楼宇数量
            creditNumber: null, //统一社会信用代码
            provinceEncode: null, //省编码
            cityEncode: null, //市编码
            regionEncode: null, //区编码
            // address:null, //详细地址
            contactName: null, //联系人
            contactPhone: null, //联系方式
            pictureInfoList: null, //营业执照
            enterpriseDesc: null, //企业简介

            contractStartDate: null, //合同开始时间
            contractEndDate: null, // 合同结束时间
            signDate: null, //签约时间
            fileInfoList: null, //合同附件


            address: {//详细地址
                province: null,//省份
                city: null,//城市
                district: null,//区域
                detail: null,//详细地址
            },
            cover: {//封面图片
                mediaExt: null,
                mediaKey: null,
                name: null,
                type: null,
                url: null
            },
        },
        showConfirmFlag: false,
        contractDateConfig: {},
        detailList: []
    }


    getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    imgUp = fileList => {
        console.log('imgUpload')
        console.log(fileList)
        this.setState({
            pictureInfoList: fileList
        })
    }

    fileUp = fileList => {
        this.setState({
            fileInfoList: fileList
        })
    }


    handleCustomerTypeChange = value => {
        this.setState({
            acountType: value
        }, () => {
            if (value == 0) {
                this.setState({
                    contractDateConfig: {

                    }
                })
            }
            if (value == 1) {
                this.setState({
                    contractDateConfig: {
                        rules: [{ required: true, message: '请选择' }]
                    }
                })
            }
        })
    }

    onRateChange = value => {
        this.props.form.setFieldsValue({
            intentionRate: value
        })
    }

    handleUploadChange = (info) => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });

            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            this.getBase64(info.file.originFileObj, imageUrl => this.setState({
                imageUrl,
                loading: false,
            }));
        }
    }

    //修改省市区
    onAddressRegionChange = (value, selectedOptions) => {
        console.log(value)
        console.log(selectedOptions)
        const { dataSource } = this.state;
        const newDataSource = {
            ...dataSource,
            address: {
                ...dataSource.address,
                province: selectedOptions[0],
                city: selectedOptions[1],
                district: selectedOptions[2],
            }
        };
        this.setState({
            dataSource: newDataSource
        });
        if (selectedOptions.length > 2) {
            var local = new window.BMap.LocalSearch(this.map, options);
            local.search(selectedOptions[0].name + selectedOptions[1].name + selectedOptions[2].name);
            const options = {
                onSearchComplete: (results) => {
                    if (local.getStatus() == window.BMAP_STATUS_SUCCESS) {
                        this.setState({
                            showMap: true,
                        })
                        const point = results.getPoi(0).point;
                        this.map.centerAndZoom(point, 13);
                    }
                }
            };
        }


        if (typeof this.props.onChange == 'function') {
            this.props.onChange(newDataSource);
        }
        selectedOptions[2] && http({
            api: API.areaSubAreas,
            data: {
                encode: selectedOptions[2].code
            },
            showLoading: false
        }).then(res => {
            console.log(res)
        });
    }


    loadRegion = (selectedOptions) => {
        console.log(selectedOptions)
        const targetOption = selectedOptions[selectedOptions.length - 1];

        http({
            api: API.areaSubAreas,
            data: {
                encode: targetOption.code
            },
            showLoading: false
        }).then(res => {
            targetOption.children = res.data;
            if (selectedOptions.length < 2) {
                res.data.forEach(item => {
                    item.isLeaf = false;
                    return item;
                });
            }
            this.setState({ region: [...this.state.region] });
        });
    }

    onChange = (key, e) => {
        const dataSource = {
            ...this.state.dataSource
        }
        console.log(key, e.target.value)
        if (key === 'completionTime') {
            dataSource[key] = e;
        } else {

            // if(key=='floorArea'||key=='buildingArea'){
            dataSource[key] = e.target.value.split('.')[0] + (e.target.value.split('.')[1] !== undefined ? ('.' + e.target.value.split('.')[1].slice(0, 2)) : '');
            // }else{
            dataSource[key] = e.target.value;
            // }

        }
        if (typeof this.props.onChange == 'function') {
            this.props.onChange(dataSource);
        }
    }

    // 省份列表
    loadProvince = () => {
        http({
            api: API.areaProvinces,
            showLoading: false
        }).then(res => {
            if (res.success) {
                this.setState({
                    region: res.data.map(item => {
                        item.isLeaf = false;
                        return item;
                    })
                })
            }
        });
    }

    getRegionDefaultValue = () => {
        let def = [];

        const address = this.state.dataSource.address;
        if (address.province) {
            def.push(address.province.code);
        }
        if (address.city) {
            def.push(address.city.code);
        }
        if (address.district) {
            def.push(address.district.code);
        }
        return def;
    }

    onAddressDetailTextChange = (v) => {
        console.log(v)
        let _address = null;
        const dataSource = {
            ...this.state.dataSource,
            address: {
                ...this.state.dataSource.address,
                detail: _address ? _address.address : v
            },
            point: _address && _address.point
        }
        if (typeof this.props.onChange == 'function') {
            this.props.onChange(dataSource);
        }
    }

    cancel = () => {
        this.props.history.push('/app/crm/client');
    }

    // 企业简介
    remarkChange = (e) => {
        let _remark = this.props.form.getFieldValue('enterpriseDesc')
        this.setState({
            remarkLength: _remark ? _remark.length : 0
        })
    }

    // 合同有效期
    contractDateChange = (field, value) => {
        if (field === 'startValue') {
            this.setState({
                contractStartDate: value,
            })
        } else {
            this.setState({
                contractEndDate: value,
            })
        }
    }

    render() {
        const {
            getFieldDecorator, getFieldsError, getFieldError, isFieldTouched,
        } = this.props.form;
        const config = {
            rules: [{ required: true, message: '请选择' }],
        }
        const { contractDateConfig } = this.state;
        const uploadButton = (
            <div>
                <Icon style={{ fontSize: '42px' }} type={this.state.loading ? 'loading' : 'to-top'} />
                <div className="ant-upload-text">
                    支持拖拽 和 手机上传
                </div>
            </div>
        );
        const userListHtml = this.state.userList.map((item, index) => <Option key={index} value={item.idCode}>{item.name}</Option>)

        const customerType = this.state.customerType;
        const {
            address,
        } = this.state.dataSource;
        return (
            <div className="intention-list intention-content">
                <div className="intention-list-head flexbox">
                    <Breadcrumb className="intention-list-head-bread">
                        <Breadcrumb.Item>
                            <Link style={{ color: '#333' }} to={{ pathname: "/app/crm/client" }}>
                                客户管理
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>新增客户</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <Form onSubmit={this.handleSubmit}>
                    <div className="intention-add-body">
                        <h6>
                            账号信息
                        </h6>
                        <div>
                            <Row gutter={24}>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item
                                        label={
                                            <span>
                                                管理员手机号&nbsp;
                                                <Tooltip title="变更后，密码将以短信形式重新发送到新管理员手机号">
                                                    <Icon type="question-circle-o" />
                                                </Tooltip>
                                            </span>
                                        }
                                    >
                                        {getFieldDecorator('adminPhone', {
                                            rules: [{ required: true, message: '请输入' }, { pattern: phoneNew, message: '请输入正确的手机号' }],
                                            getValueFromEvent: e => (getNewPhone(e))
                                        })(
                                            <Input
                                                style={{ width: '100%' }}
                                                style={{ imeMode: "disabled" }}
                                                maxLength={13}
                                                placeholder="请输入"
                                            // onBlur={this.handleCheck}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item label="管理员邮箱">
                                        {getFieldDecorator('adminEmail', {
                                            rules: [{ required: true, message: '请输入' }, {
                                                type: 'email',
                                                message: '请输入正确的邮箱地址'
                                            }]
                                        })(
                                            <Input
                                                style={{ width: '100%' }}
                                                placeholder="请输入"
                                            // onBlur={this.handleCheck}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={8} >
                                    <Form.Item label="管理员身份证号" >
                                        {getFieldDecorator('adminIdentityCard', { rules: [{ required: true, message: '请输入' }, { pattern: socialCreditCode, message: '请输入正确的身份证号' }] })(
                                            <Input
                                                style={{ width: '100%' }}
                                                maxLength={18}
                                                placeholder="请输入"
                                            // onBlur={this.handleCheck}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row gutter={24}>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item label="账号类型">
                                        {getFieldDecorator('acountType', config)(
                                            <Select2 style={{ width: '100%' }} data={this.state.accountTypeArray} placeholder="请选择" selectMethod={this.handleCustomerTypeChange} />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    </div>

                    <div className="intention-add-body">
                        <h6>
                            企业信息
                        </h6>
                        <Row gutter={24}>
                            <Col className="gutter-row" span={8}>
                                <Form.Item label="企业名称">
                                    {getFieldDecorator('enterpriseName', {
                                        rules: [{ required: true, message: '请输入' }, { max: 30, message: '最大长度30' }],
                                    })(
                                        <Input
                                            style={{ width: '100%' }}
                                            maxLength={50}
                                            autoComplete="off"
                                            placeholder="请输入"
                                            maxLength={50}
                                        // onBlur={this.handleCheck}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={8}>
                                <Form.Item label="项目数量">
                                    {getFieldDecorator('projectNumber', {
                                        rules: [{
                                            pattern: /^[1-9][0-9]{0,4}$/, message: '只能输入五位数以内正整数'
                                        }, {
                                            max: 5, message: '最多五位数'
                                        }]
                                    })(
                                        <Input style={{ width: '100%' }} maxLength={5} placeholder="请输入" />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={8}>
                                <Form.Item label="统一社会信用代码">
                                    {getFieldDecorator('creditNumber', { rules: [{ required: true, pattern: socialCreditCode, message: '请输入18位数字或者数字字母社会信用代码' }] })(
                                        <Input style={{ width: '100%' }} maxLength={18} placeholder="请输入" />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col className="gutter-row" span={16}>
                                <Form.Item label={'总部地址'} required>
                                    <Input.Group>
                                        <Row gutter={24}>
                                            <Col span={8}>
                                                {getFieldDecorator('addressEncode', {})(
                                                    <Cascader
                                                        style={{ width: '100%' }}
                                                        loadData={this.loadRegion}
                                                        fieldNames={{ label: 'name', value: 'code', children: 'children' }}
                                                        allowClear={false}
                                                        changeOnSelect
                                                        value={this.getRegionDefaultValue()}
                                                        suffixIcon={<Icon type='caret-down' />}
                                                        placeholder={'省市区'}
                                                        options={this.state.region}
                                                        onChange={(value, selectedOptions) => { this.onAddressRegionChange(value, selectedOptions) }} />
                                                )}
                                            </Col>
                                            <Col span={12}>
                                                {getFieldDecorator('address', {
                                                    rules: address.detail,
                                                    initialValue: address.detail
                                                })(
                                                    <Input style={{ width: "100%" }} placeholder={'详细地址'} />
                                                )}
                                            </Col>
                                        </Row>
                                    </Input.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col className="gutter-row" span={8}>
                                <Form.Item label="联系人">
                                    {getFieldDecorator('contactName', { rules: [{ required: true, message: '请输入' }, { max: 20, message: '不得超过20字' }] })(
                                        <Input style={{ width: '100%' }} maxLength={20} placeholder="请输入" />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={8}>
                                <Form.Item label="联系方式">
                                    {getFieldDecorator('contactPhone', { rules: [{ required: true, message: '请输入' }, { pattern: phone, message: '请输入正确的手机号' }] })(
                                        <Input style={{ width: '100%' }} maxLength={11} placeholder="请输入" />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col className="gutter-row" span={24}>
                                <Form.Item label="营业执照">
                                    {getFieldDecorator('pictureInfoList', {})(
                                        <ImageUploadBiz imgUp={this.imgUp} />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col className="gutter-row" span={24}>
                                <Form.Item label="企业简介">
                                    {getFieldDecorator('enterpriseDesc', { rules: [{ max: 200, message: '不得超过200字' }] })(
                                        <TextArea placeholder="请输入" maxLength={200} autosize={{ minRows: 4, maxRows: 4 }} onChange={debounce(this.remarkChange, 1000)} />
                                    )}
                                </Form.Item>
                                <span style={{ float: 'right' }}>{this.state.remarkLength}/200</span>
                            </Col>
                        </Row>
                    </div>

                    <div className="intention-add-body">
                        <h6>
                            签约信息
                        </h6>
                        <Row gutter={24}>
                            <Col className="gutter-row" span={8}>
                                <Form.Item label="合同有效期">
                                    {getFieldDecorator('contractDate', contractDateConfig)(
                                        <DateRange
                                            onDateChange={this.contractDateChange}
                                            startDisabledDate={this.state.contractDisabledDate}
                                            startValue={this.state.contractStartDate}
                                            endValue={this.state.contractEndDate} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={8}>
                                <Form.Item label="签约时间">
                                    {getFieldDecorator('signDate', {})(
                                        <DatePicker style={{ width: '100%' }} allowClear={false} />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col className="gutter-row" span={24}>
                                <Form.Item label="附件">
                                    {getFieldDecorator('fileInfoList', {})(
                                        <FileUploadBiz fileUp={this.fileUp} />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>

                    <div className="btn-group">
                        <Button type="primary" htmlType="submit">提交</Button>
                        <Button onClick={this.cancel} size='large'>取消</Button>
                    </div>
                </Form>

                <Modal
                    title="账号创建成功"
                    content=""
                    visible={this.state.showConfirmFlag}
                    footer={[
                        <Button style={{ "float": "none" }} type="primary" onClick={this.hideConfirm} size='large'>我知道了</Button>
                    ]}
                    closable={false}
                    maskClosable={false}
                    width={400}
                    onOk={this.hideConfirm}
                >
                    <p style={{ fontSize: '12px' }}>公司名：{this.state.enterpriseName}</p>
                    <p style={{ fontSize: '12px' }}>管理员手机号：{this.state.adminPhone}，初始密码已通过短信发送, 请通知员工尽快修改密码！</p>
                </Modal>
            </div>
        );
    }
}

const CustomerAddForm = Form.create({ name: 'customer_add' })(AddCustomer);

export default CustomerAddForm;