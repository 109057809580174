import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom';
import moment from 'moment';

export default withRouter(function DriveAuth({ data, props }) {
  // console.log(data, 'this.props.state.state')
  let DRIVER_DATA
  let QIYE_FLAG = ''

  const userInfoData = [
    { label: "姓名", field: "displayName" },
    { label: "昵称", field: "nickName" },
    { label: "手机号码", field: "phone" },
    { label: "紧急联系人", field: "emergencyPerson" },
    { label: "紧急联系人手机号码", field: "emergencyPhone" },
    { label: "身份证号码", field: "cardNumber" },
  ]

  let vehicleInfoData = [
    { label: "车型", field: "type" },
    { label: "车牌号", field: "plate" },
    { label: "是否拖挂", field: "withTrailer" },
    { label: "空车质量", field: "emptyMass" },
    { label: "核载重量", field: "deadWeight" },
    { label: "车货总质量", field: "totalMass" },
    { label: "准牵引总质量", field: "tractionMass" },
    { label: "车长", field: "length" },
    { label: "车宽", field: "width" },
    { label: "车高", field: "hight" },
    { label: "车轴", field: "axle" },
    { label: "国标", field: "ns" },
    { label: "燃油", field: "fuel" },
    { label: "功能", field: "function" },
  ]

  let userMsg = {}
  let vehicleMsg = {}
  let cardNumberBack
  let cardNumberFront
  let driverLicense
  let driverLicenseBack
  let vechileImgList
  let businessLicenseImg
  let clivtaImg
  let vechileLicenseImg

  if (data.data && data.data.driverInfo) { // 司机信息
    DRIVER_DATA = data.data
    // console.log(DRIVER_DATA, 'DRIVER_DATA')
    if (DRIVER_DATA.driverInfo.withVehicle == 1) {
      QIYE_FLAG = '司机'
    } else {
      QIYE_FLAG = '司机（无车辆）'
    }
    // 司机信息---------------
    userMsg = {
      displayName: DRIVER_DATA.audit.displayName,
      nickName: DRIVER_DATA.audit.nickName,
      phone: DRIVER_DATA.audit.mobile,
      emergencyPerson: DRIVER_DATA.driverInfo.emergencyPerson,
      emergencyPhone: DRIVER_DATA.driverInfo.emergencyPhone,
      cardNumber: DRIVER_DATA.driverInfo.cardNumber,
    }
    // DRIVER_DATA.driverInfo.vehicleList && DRIVER_DATA.driverInfo.vehicleList.map(item => { })
    if (DRIVER_DATA.driverInfo.vehicleList.length > 0) {
      vehicleMsg = {
        type: DRIVER_DATA.driverInfo.vehicleList[0].typeName,
        plate: DRIVER_DATA.driverInfo.vehicleList[0].plate,
        withTrailer: DRIVER_DATA.driverInfo.vehicleList[0].withTrailer,
        emptyMass: DRIVER_DATA.driverInfo.vehicleList[0].emptyMass,
        deadWeight: DRIVER_DATA.driverInfo.vehicleList[0].deadWeight,
        totalMass: DRIVER_DATA.driverInfo.vehicleList[0].totalMass,
        tractionMass: DRIVER_DATA.driverInfo.vehicleList[0].tractionMass,
        length: DRIVER_DATA.driverInfo.vehicleList[0].length,
        width: DRIVER_DATA.driverInfo.vehicleList[0].width,
        hight: DRIVER_DATA.driverInfo.vehicleList[0].hight,
        axle: DRIVER_DATA.driverInfo.vehicleList[0].axleName,
        ns: DRIVER_DATA.driverInfo.vehicleList[0].nsName,
        fuel: DRIVER_DATA.driverInfo.vehicleList[0].fuelName,
        function: DRIVER_DATA.driverInfo.vehicleList[0].functionName,
      }
      vechileImgList = DRIVER_DATA.driverInfo.vehicleList[0].vechileImgList
      businessLicenseImg = DRIVER_DATA.driverInfo.vehicleList[0].businessLicenseImg
      clivtaImg = DRIVER_DATA.driverInfo.vehicleList[0].clivtaImg
      vechileLicenseImg = DRIVER_DATA.driverInfo.vehicleList[0].vechileLicenseImg
    }
    cardNumberBack = DRIVER_DATA.driverInfo.cardNumberBack
    cardNumberFront = DRIVER_DATA.driverInfo.cardNumberFront
    driverLicense = DRIVER_DATA.driverInfo.driverLicense
    driverLicenseBack = DRIVER_DATA.driverInfo.driverLicenseBack.url
    
  }

  return (
    <div className="userInfo" style={{ "padding": 0 }}>
      {DRIVER_DATA ? (
        <div className="userInfo_header">
        <div className="userInfo_title">
          认证为
          <span className="flag">{QIYE_FLAG}</span>
        </div>
        <p>提交时间：{moment(DRIVER_DATA.audit.submitDate).format('YYYY-MM-DD HH:mm:ss')}</p>
        <div className="userInfo_right">
          <span>{DRIVER_DATA.audit.auditStatusName}</span>
          <p>
            <Link to={'/app/crm/enterprise_auth/record/' + data.code+ '/'+2} >查看审核记录</Link>
          </p>
        </div>
      </div>
      ): null}
      
      <div className="userInfo_body">
        {DRIVER_DATA ? (
          <>
            <div>
              <h2><i></i>  个人信息</h2>
              <div>
                <ul>
                  {userInfoData && userInfoData.map(item => {
                    return (
                      <li key={item.field}><span>{item.label}</span><span className="span">{userMsg[item.field]}</span></li>
                    )
                  })}
                  <li className="body_img"><span>身份证正反面</span><img src={cardNumberBack} alt="" onClick={() => props.cImage(cardNumberBack)} /><img src={cardNumberFront} alt="" onClick={() => props.cImage(cardNumberFront)}/>
                    
                  </li>
                  <li className="body_img"><span>驾驶证正反面</span><img src={driverLicense} alt=""  onClick={() => props.cImage(driverLicense)}/><img src={driverLicenseBack} alt="" onClick={() => props.cImage(driverLicenseBack)}/></li>
                </ul>
              </div>
            </div>
            {DRIVER_DATA.driverInfo.vehicleList.length > 0 ? (<div>
              <h2><i></i>  车辆信息</h2>
              <ul>
                {vehicleInfoData && vehicleInfoData.map(item => {
                  return (
                    <li key={item.field}><span>{item.label}</span><span className="span">{vehicleMsg[item.field]}</span></li>
                  )
                })}
                <li className="body_img"><span>车辆照片</span>
                {vechileImgList && vechileImgList.map((item, index) => <img key={index} src={item} alt="" onClick={() => props.cImage(item)}/>)}
                </li>
                <li className="body_img"><span>营运许可证</span><img src={businessLicenseImg} alt="" onClick={() => props.cImage(businessLicenseImg)}/></li>
                <li className="body_img"><span>营运车辆强制险</span><img src={clivtaImg} alt="" onClick={() => props.cImage(clivtaImg)} /></li>
                <li className="body_img"><span>车辆行驶证</span><img src={vechileLicenseImg} alt="" onClick={() => props.cImage(vechileLicenseImg)} /></li>
              </ul>
            </div>) : null}
          </>
        ) : null
        }

      </div>
    </div>
  )
})