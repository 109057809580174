import React, { Component } from 'react';
import { Checkbox, Button, Icon } from 'antd';

class MissionItem extends Component {

    state = {
        checked: true,
        disabled: false,
    }; 
    onChange = e => {
        this.setState(
            {
                checked:!this.state.checked
            },()=>{
                setTimeout(()=>this.props.checkitem(this.props.value),2000)
            }
        )
       
        
    };
    delete = () => {
        this.props.deleteitem(this.props.value)
    }

    edit = () => {
        this.props.edititem(this.props.value)
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            checked:nextProps.checked
        })
    }

    componentDidMount(){
        this.setState({
            checked:this.props.checked
        })        
    }

    render() {
        return (
            <div className="mission-item">
                <Checkbox
                    checked={this.state.checked}
                    onChange={this.onChange}
                >
                </Checkbox>
                <a href="javascript:void(0)" className="mission-item-text"  onClick={this.edit}>
                 {this.props.text}
                </a>     
                <a className="mission-item-delete"  onClick={this.delete}>
                    <Icon type="delete" theme="filled" />                
                </a>
             </div>
        )
    }
}

MissionItem.defaultProps = {
    text:'处理维修部事情'
}

export default MissionItem
