import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { Form, Row, Col, message, Rate } from 'antd';
import moment from 'moment';
import http from '@/http'
import { API } from '@/http/api'
import { changeObjkey ,getNewPhone} from '@/utils'
import userUtil from '@/utils/user';
import { AUTH_KEYS } from '@/utils/auth'
import Modelmodel from 'CMPT/common/drawer/model'
import BreadcrumbHeader from 'CMPT/common/breadcrumb/breadcrumb'
import ClickImg from 'CMPT/common/clickimg/clickimg'
import SendOrder from './sendorder'
import CostOrder from './costorder'
import CancelOrder from './cancelorder'
import AcceptanceOrder from './acceptanceorder'
import './orderDetail.less'

const orderStatustextList = ['待派单','待处理','处理中','待验收','已完成','已取消'];
const orderStatuscolorList = ['#FBBC06','#FBBC06','#FBBC06','#FBBC06','#E94437','#333'];
const orderScheduletextList = ['上报','派单',' 开始处理',' 已解决','验收通过','取消工单'];
const FormatSet = 'YYYY-MM-DD HH:mm:ss';
const keyMapCascader = {key:'value',value:'label'};

class OperateOrderdetailform extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sendModelstatus: false,
            acceptanceModelstatus: false,
            costorderModelstatus: false,
            cancelModelstatus: false,
            orderDetail: {},
            orderscheduleList: [],
            sendorderdata: {},
            acceptanceorder: {},
            cancelorder: {},
            service: [],
            result: [],
            costtypeLists: [],
            bearerLists: [],
            servicerList: []
        }
    }
    //显示按钮
    showuseButton() {
        let modalList = [
            {
                auth: 'OPERATE_SERVICE_LOGISTICS_MAINTAIN_ACCEPTANCEORDER',
                name: '验收',
                title: '验收',
                buttontype: 'primary',
                ModelName: 'orderdetail-button',
                visible: this.state.acceptanceModelstatus,
                handleOk: this.handleacceptanceOk.bind(this),
                handleCancel: this.showacceptanceModal.bind(this),
                showModal: this.showacceptanceModal.bind(this),
                Drawermodel: <AcceptanceOrder encode={this.props.location.hash.substr(1)}
                                              form={this.props.form}
                                              Accresult={this.state.acceptanceorder.Accresult}
                                              result={this.state.result}
                                              chooseProject={this.changeacceptanceModal.bind(this)}>
                </AcceptanceOrder>
            },
            {
                auth: 'OPERATE_SERVICE_LOGISTICS_MAINTAIN_SENDORDER',
                name: this.state.orderDetail.orderDispatchFlag === 1 ? '重新派单' : '派单',
                title: '派单',
                buttontype: 'primary',
                ModelName: 'orderdetail-button',
                visible: this.state.sendModelstatus,
                handleOk: this.handlesendOk.bind(this),
                handleCancel: this.showsendModal.bind(this),
                showModal: this.showsendModal.bind(this),
                Drawermodel: <SendOrder form={this.props.form}
                                        urgent={this.state.servicerList}
                                        chooseProject={this.changesendModal.bind(this)}>
                </SendOrder>
            },
            {
                auth: 'OPERATE_SERVICE_LOGISTICS_MAINTAIN_CANCELORDER',
                name: '取消工单',
                title: '取消工单',
                buttontype: 'primary',
                ModelName: 'orderdetail-button red',
                visible: this.state.cancelModelstatus,
                handleOk: this.handlecancelOk.bind(this),
                handleCancel: this.showcancelModal.bind(this),
                showModal: this.showcancelModal.bind(this),
                Drawermodel: <CancelOrder encode={this.props.location.hash.substr(1)}
                                          form={this.props.form}
                                          chooseProject={this.changecancelModal.bind(this)}>
                </CancelOrder>
            },
            {
                auth: 'OPERATE_SERVICE_LOGISTICS_MAINTAIN_COSTORDER',
                name: '费用清单',
                title: '费用清单',
                ModelName: 'orderdetail-button yellow-border',
                wrapClassName: 'Operate-costorder',
                visible: this.state.costorderModelstatus,
                handleOk: this.showcostorderModal.bind(this),
                handleCancel: this.showcostorderModal.bind(this),
                showModal: this.showcostorderModal.bind(this),
                Drawermodel: <CostOrder status={this.state.orderDetail.orderstatus}
                                        encode={this.props.location.hash.substr(1)}
                                        from={'detail'}
                                        costtypeLists={this.state.costtypeLists}
                                        bearerLists={this.state.bearerLists}>
                </CostOrder>
            },
        ];
        const status = this.state.orderDetail.orderstatus;
        switch (status) {
            case 1:
                modalList.splice(0,1);
                break;
            case 2:
                modalList.splice(0,2);
                break;
            case 3:
                modalList.splice(0,2);
                break;
            case 4:
                modalList.splice(1,1);
                break;
            case 5:
                modalList.splice(0,3);
                break;
            case 0:
                modalList.splice(0,3);
                break;
            default:
                modalList = [];
                break
        }
        return modalList
    }

    //派单
    handlesendOk(event) {
        event.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const dataObj = {
                    encodeList: [this.props.location.hash.substr(1)],
                    userEncode: this.state.sendorderdata.sendOrder,
                    userName: this.state.sendorderdata.userName,
                    remark: this.state.sendorderdata.remarks
                };
                this.showsendModal();
                this.postsendDateSave(dataObj)
            }
        });
    }
    showsendModal() {
        if (userUtil.hasAuth(AUTH_KEYS.OPERATE_SERVICE_LOGISTICS_MAINTAIN_SENDORDER)) {
            this.setState({
                sendModelstatus: !this.state.sendModelstatus
            });
        } else {
            message.warning(`抱歉，您没有派单权限!`)
        }
    }
    changesendModal(type,val,option) {
        const sendorderdata = this.state.sendorderdata;
        let targetVal = null;
        if (val && val.target) targetVal = val.target.value;

        switch (type) {
            case 1:
                sendorderdata.sendOrder = val;
                sendorderdata.userName = this.state.servicerList.filter(item => item.value === val)[0].userName
                break;
            case 2:
                sendorderdata.remarks = targetVal;
                break;
            default:
                break
        }

        this.setState({
            sendorderdata
        })
    }

    //验收
    handleacceptanceOk(event) {
        event.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({
                    acceptanceorder: {}
                });
                const dataObj = changeObjkey(this.state.acceptanceorder,{Accresult:'acceptorResultType',rate:'acceptorRank',remarks:'acceptorRemark',reason:'acceptorReason'});
                dataObj.encodeList = [this.props.location.hash.substr(1)];
                this.showacceptanceModal();
                this.postaccepDateSave(dataObj)
            }
        });
    }
    showacceptanceModal() {
        this.setState({
            acceptanceModelstatus: !this.state.acceptanceModelstatus
        });
    }
    changeacceptanceModal(type,val,option) {
        const acceptanceorder = this.state.acceptanceorder;
        let targetVal = null;
        if (val && val.target) targetVal = val.target.value;

        switch (type) {
            case 1:
                acceptanceorder.Accresult = Number(val);
                break;
            case 2:
                acceptanceorder.rate = Number(val);
                break;
            case 3:
                acceptanceorder.remarks = targetVal;
                break;
            case 4:
                acceptanceorder.reason = targetVal;
                break;
            default:
                break
        }

        this.setState({
            acceptanceorder
        })
    }

    //费用清单
    showcostorderModal() {
        this.setState({
            costorderModelstatus: !this.state.costorderModelstatus
        });
    }

    //取消工单
    handlecancelOk(event) {
        event.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const dataObj = changeObjkey(this.state.cancelorder,{cancelresult:'cancelReason'});
                dataObj.encodeList = [this.props.location.hash.substr(1)];
                this.showcancelModal();
                this.postcancelDateSave(dataObj)
            }
        });
    }
    showcancelModal() {
        this.setState({
            cancelModelstatus: !this.state.cancelModelstatus
        });
    }
    changecancelModal(type,val,option) {
        const cancelorder = this.state.cancelorder;
        let targetVal = null;
        if (val && val.target) targetVal = val.target.value;

        switch (type) {
            case 1:
                cancelorder.cancelresult = targetVal;
                break;
            default:
                break
        }

        this.setState({
            cancelorder
        })
    }

    componentWillMount() {
        this.getmaintainInit()
    }
    //请求初始化枚举
    getmaintainInit() {
        const config = {
            api: API.maintainInit
        }
        http(config).then(result => {
            const { data } = result;
            let { maintainTypeEnum, orderCheckTypeEnum, feeTypeEnum, payTypeEnum } = data;

            //维修服务
            maintainTypeEnum = maintainTypeEnum.map(item => {
                const list = changeObjkey(item,keyMapCascader);
                this.loadDataService(item.key,list);
                return list
            });

            //通过不通过
            orderCheckTypeEnum = orderCheckTypeEnum.map(item => changeObjkey(item,{key:'value',value:'name'}));

            //费用类型
            feeTypeEnum = feeTypeEnum.map(item => changeObjkey(item,{key:'value',value:'name'}));

            //承担方类型
            payTypeEnum = payTypeEnum.map(item => changeObjkey(item,{key:'value',value:'name'}));

            setTimeout(()=>{
                this.setState({
                    service: maintainTypeEnum,
                    result: orderCheckTypeEnum,
                    costtypeLists: feeTypeEnum,
                    bearerLists: payTypeEnum
                })
            },0);

            this.getorderDetail(this.props.location.hash.substr(1))
        })

    }
    //请求维修工list
    postservicerList(data) {
        const config = {
            api: API.maintainGetLaborList,
            data
        }
        http(config).then(result => {
            const { data } = result;
            const servicerList = data && data.map(item => {
                const list = changeObjkey(item,{encode:'value'});
                list.name = <Row><Col span={4}>{item.name}</Col><Col span={8}>{item.mobile}</Col><Col span={8}>待维修共{item.wppCount}单</Col></Row>;
                list.userName = item.name;
                return list
            })
            setTimeout(()=>{
                this.setState({
                    servicerList
                })
            },0)
        })
    }
    //请求服务类型二级
    loadDataService(selectedOptions,obj) {
        const url = Number(selectedOptions) === 1 ? API.sysconfigListRoomEquipment : API.sysconfigListPublicEquipment;
        const config = {
            api: url
        };

        http(config).then(result => obj.children = result.data && result.data.map(item => changeObjkey(item,{id:'value',name:'label'},true)))
    }
    //请求详情数据.
    getorderDetail(encode) {
        const config = {
            api: API.maintainGet,
            path: {
                encode
            },
            showLoading: true
        }
        http(config).then(result => {
            const { data } = result;
            const service = this.state.service;
            const orderimglist = data.pictureInfoList.map(item => item.url);
            const ordertype = <div><span>{
                service.filter(item => item.value === data.bizType).length>0 && service.filter(item => item.value === data.bizType)[0].label}</span><span>{
                service.filter(item => item.value === data.bizType).length>0 && service.filter(item => item.value === data.bizType)[0].children
                &&
                (
                    service.filter(item => item.value === data.bizType)[0].children.filter(item => item.value === data.type).length>0
                    &&
                    '/' + service.filter(item => item.value === data.bizType)[0].children.filter(item => item.value === data.type)[0].label
                )}</span></div>;
            const projectEncodeFirst = data.projectVo && data.projectVo.name;
            const projectEncodeSecond = data.projectVo.floors && data.projectVo.floors[0].name;
            const projectEncodeThird = data.projectVo.floors[0].zones && data.projectVo.floors[0].zones[0].zoneNo;
            const orderDetail = {
                orderstatus: data.status,
                orderDispatchFlag: data.dispatchFlag,
                orderOvertime: data.timeoutFlag === 1 ? <span style={{fontSize: 12, color: '#FBBC06'}}><i style={{marginRight: '4px', verticalAlign: 'middle'}} className={'icon iconfont iconsign_warning'} />逾期</span> : null,
                orderStatusText: orderStatustextList[Number(data.status) - 1],
                orderStatusColor: orderStatuscolorList[Number(data.status) - 1],
                orderID: data.code,
                orderhouse: `${projectEncodeFirst}-${projectEncodeSecond}-${projectEncodeThird}`,
                orderaddress: data.address,
                orderurgentlevel: data.urgentLevel,
                orderdescription: data.description,
                ordertype,
                orderimglist,
                orderReportinfo: <div><span style={{marginRight:12}}>{data.applicantName}</span><span>{getNewPhone(data.mobile)}</span></div>,
                orderAcceptinfo: data.laborUserName ? <div><span style={{marginRight:12}}>{data.laborUserName}</span><span>{getNewPhone(data.laborMobile)}</span></div> : [6].includes(data.status) ? '' : null,
                orderAcceptdate: moment(data.processTime).format(FormatSet)
            };
            const orderscheduleList = data.maintainFollowupList.map(item => {
                const { contentVo } = item;
                let statusText = [];
                let statusNode = [];
                switch (item.type) {
                    case 6:
                        statusText = orderScheduletextList[Number(item.type) -1];
                        statusNode = [
                            {
                                name: '取消人',
                                isuse: item.userName,
                                node: item.userName
                            },
                            {
                                name: '取消原因',
                                isuse: contentVo.cancelReason,
                                node: contentVo.cancelReason
                            }
                        ];
                        break;
                    case 1:
                        statusText = orderScheduletextList[Number(item.type) -1];
                        statusNode = [
                            {
                                name: '操作人',
                                isuse: item.userName,
                                node: item.userName
                            },
                            {
                                name: '上报人信息',
                                isuse: contentVo.applicantName,
                                node: <span><span style={{marginRight:12}}>{contentVo.applicantName}</span><span>{getNewPhone(contentVo.mobile)}</span></span>
                            },
                            {
                                name: '预计处理时间',
                                isuse: contentVo.processTime,
                                node: moment(contentVo.processTime).format(FormatSet)
                            }
                        ];
                        break;
                    case 2:
                        statusText = orderScheduletextList[Number(item.type) -1];
                        statusNode = [
                            {
                                name: '操作人',
                                isuse: item.userName,
                                node: item.userName
                            },
                            {
                                name: '受理人信息',
                                isuse: contentVo.dispatchUserName,
                                node: <span><span style={{marginRight:12}}>{contentVo.dispatchUserName}</span><span>{ getNewPhone(contentVo.dispatchMobile)}</span></span>
                            },
                            {
                                name: '备注',
                                isuse: contentVo.dispatchRemark,
                                node: contentVo.dispatchRemark
                            }
                        ];
                        break;
                    case 3:
                        statusText = orderScheduletextList[Number(item.type) -1];
                        break;
                    case 4:
                        statusText = orderScheduletextList[Number(item.type) -1];
                        statusNode = [
                            {
                                name: '处理时长',
                                isuse: contentVo.dealMinute,
                                node: `${contentVo.dealMinute} 分钟`
                            },
                            {
                                name: '解决方案',
                                isuse: contentVo.solution,
                                node: contentVo.solution
                            },
                            {
                                name: '使用耗材',
                                isuse: contentVo.lossList && contentVo.lossList.length>0,
                                node: contentVo.lossList && contentVo.lossList.map(itemloss => `${itemloss.count}${itemloss.unit}${itemloss.name}`).toString().replace(',','、')
                            }
                        ];
                        break;
                    case 42:
                        statusText = '未解决';
                        statusNode = [
                            {
                                name: '处理时长',
                                isuse: contentVo.dealMinute,
                                node: `${contentVo.dealMinute} 分钟`
                            },
                            {
                                name: '未解决原因',
                                isuse: contentVo.unsolvedReason,
                                node: contentVo.unsolvedReason
                            },
                            {
                                name: '使用耗材',
                                isuse: contentVo.lossList && contentVo.lossList.length>0,
                                node: contentVo.lossList && contentVo.lossList.map(itemloss => `${itemloss.count}${itemloss.unit}${itemloss.name}`).toString().replace(',','、')
                            }
                        ];
                        break;
                    case 5:
                        statusText = orderScheduletextList[Number(item.type) -1];
                        statusNode = [
                            {
                                name: '验收人',
                                isuse: item.userName,
                                node: item.userName
                            },
                            {
                                name: '评价',
                                isuse: contentVo.acceptorRank,
                                node: <Rate disabled defaultValue={contentVo.acceptorRank}/>
                            },
                            {
                                name: '备注',
                                isuse: contentVo.acceptorRemark,
                                node: contentVo.acceptorRemark
                            }
                        ];
                        break;
                    case 52:
                        statusText = '验收不通过';
                        statusNode = [
                            {
                                name: '验收人',
                                isuse: item.userName,
                                node: item.userName
                            },
                            {
                                name: '不通过原因',
                                isuse: contentVo.acceptorReason,
                                node: contentVo.acceptorReason
                            }
                        ];
                        break;
                    default:
                        break
                }
                return {
                    date: moment(item.createTime).format(FormatSet),
                    statusText,
                    statusNode
                };
            })

            setTimeout(()=>{
                this.setState({
                    orderDetail,
                    orderscheduleList
                })
            },0)

            //请求维修工list
            if (Number(data.status) === 1) this.postservicerList({projectEncode: data.projectVo.encode})
        })
    }
    //请求派单保存
    postsendDateSave(data) {
        const config = {
            api: API.maintainDispatchDone,
            data: {
                ...data
            },
            showLoading: true
        };
        http(config).then(result => result.success && (message.success(`派单成功`) && this.getorderDetail(this.props.location.hash.substr(1))))
    }
    //请求验收保存
    postaccepDateSave(data) {
        const config = {
            api: API.maintainAcceptanceDone,
            data: {
                ...data
            },
            showLoading: true
        };
        http(config).then(result => result.success && (message.success(`验收成功`) && this.getorderDetail(this.props.location.hash.substr(1))))
    }
    //请求取消工单
    postcancelDateSave(data) {
        const config = {
            api: API.maintainCancel,
            data: {
                ...data
            },
            showLoading: true
        };
        http(config).then(result => result.success && (message.success(`取消成功`) && this.getorderDetail(this.props.location.hash.substr(1))))
    }

    render() {
        const { orderDetail, orderscheduleList } = this.state;
        const {
            orderStatusText,
            orderOvertime,
            orderStatusColor,
            orderhouse,
            orderaddress,
            orderdescription,
            orderurgentlevel,
            ordertype,
            orderimglist,
            orderReportinfo,
            orderAcceptinfo,
            orderAcceptdate
        } = orderDetail;
        // const breadlist = [{name:'维修服务',route:'/app/operate/serviceCenter/logistics/maintain'},{ name: this.state.orderDetail.orderID}];
        const breadlist = [{name:'维修服务',route:'/app/operate/serviceCenter/logistics/maintain'},{ name:'工单详情'}];
        const addorderButton =  <div>
            {this.showuseButton().map((item,key) => <Modelmodel {...item} key={key}></Modelmodel>)}
        </div>;

        return (
            <div className='maintain-orderDetail'>
                <div className='orderDetail-head'>
                    <BreadcrumbHeader breadlist={breadlist} buttonobj={addorderButton}></BreadcrumbHeader>
                </div>
                <div className='orderDetail-body'>
                    <Row>
                        <Col span={15}>
                            <div className='orderDetail-body-orderinfo'>
                                <div className='orderDetail-title'><span>工单信息</span>{orderurgentlevel === 1? <span className='urgentlevel'>紧急</span> : null}</div>
                                <div className='orderDetail-body-content'>
                                    <Row>
                                        <Col span={3}>所属项目:</Col>
                                        <Col span={12}>{orderhouse}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={3}>地址:</Col>
                                        <Col span={12}>{orderaddress}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={3}>维修类型:</Col>
                                        <Col span={12}>{ordertype}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={3}>紧急程度:</Col>
                                        <Col span={12}>{orderurgentlevel === 1? '紧急' : '一般'}</Col>
                                    </Row>
                                    {
                                        orderdescription ? (  <Row>
                                            <Col span={3}>问题描述:</Col>
                                            <Col span={12}>{orderdescription}</Col>
                                        </Row>) : null
                                    }
                                    {
                                        orderimglist && orderimglist.length>0 ? (
                                            <div>
                                                <Row>
                                                    <Col>图片上传:</Col>
                                                </Row>
                                                <Row>
                                                    <Col span={10}><ClickImg cardlist={orderimglist} className='orderDetail-body-content-img'></ClickImg></Col>
                                                </Row>
                                            </div>
                                        ) : null
                                    }
                                </div>
                            </div>
                            <div className='orderDetail-body-moreinfo'>
                                <div className='orderDetail-title'><span>更多信息</span></div>
                                <div className='orderDetail-body-content'>
                                    <Row>
                                        <Col span={3}>上报人信息:</Col>
                                        <Col span={12}>{orderReportinfo}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={3}>受理人信息:</Col>
                                        <Col span={12}>{orderAcceptinfo != null ? orderAcceptinfo : <span className='yellow-text' onClick={this.showsendModal.bind(this)}>暂无受理人信息 点击前往派单</span>}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={3}>预计处理时间:</Col>
                                        <Col span={12}>{orderAcceptdate}</Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col span={9} offset={1}>
                            <div className='orderDetail-body-orderschedule'>
                                <div className='orderDetail-title'><span>工单进度</span><span className='orderStatus' style={{color:orderStatusColor,borderColor:orderStatusColor}}>{orderStatusText}</span><span style={{float: 'right',marginLeft: 12}}>{orderOvertime}</span></div>
                                <div className='step-z'>
                                    {orderscheduleList.map((item,index) =>
                                        <div key={index} className='list-item'>
                                            <div className='list-left'>
                                                <div className='list-left-item list-left-time'><span>{item.date}</span><span>{item.statusText}</span></div>
                                                {item.statusNode.map((itemchild,indexs) => itemchild.isuse ? <div className='list-left-item' key={indexs}>{<div><span>{itemchild.name}:</span><span>{itemchild.node}</span></div>}</div> : null)}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

const OperateOrderdetail = Form.create({ name: 'OperateOrderdetailform' })(OperateOrderdetailform);

export default withRouter(OperateOrderdetail)
