import React, { Component } from "react";
import { Breadcrumb, Tabs } from "antd";
import Issue from "./Issue";
import "./NewIssueTab.less";
const { TabPane } = Tabs;
const BreadcrumbItem = Breadcrumb.Item;

class NewIssueTab extends Component {
  render() {
    return (
      <div className="new_issue_tabs">
        <div>
          <Breadcrumb>
            <BreadcrumbItem>帮助中心</BreadcrumbItem>

            <BreadcrumbItem>常见问题</BreadcrumbItem>
          </Breadcrumb>
        </div>

        <div>
          <Tabs defaultActiveKey="1" className="tabs">
            <TabPane tab="商城" key="1" className="tab">
              <Issue type="1" {...this.props} />
              {/* 0表示待审核 */}
            </TabPane>

            <TabPane tab="供应商管理" key="2" className="tab">
              <Issue type="2" {...this.props} />
              {/* 1 表示已通过 */}
            </TabPane>

            <TabPane tab="园区管理" key="3" className="tab">
              <Issue type="3" {...this.props} />
              {/* 2表示已驳回 */}
            </TabPane>
          </Tabs>
        </div>
      </div>
    );
  }
}

export default NewIssueTab;
