import React, { Component } from 'react';
import { Tabs } from 'antd';
import SettingAuthRow from './SettingAuthRow';

import './SettingAuth.less';

const TabPane = Tabs.TabPane;

// 业务逻辑在业务组件内处理，通用组件不要越权处理
// 权限的初始数据是在哪里获取，组件内吗还是props属性？props属性，因为有3种不同业务逻辑初始数据
// 怎么得到权限选中的结果，提供方法吗还是受控组件？提供方法，因为在角色创建和用户分配权限的两处地方用到，这两处地方确定按钮对应的业务逻辑不同
// TODO:权限没有加载出来的时候，这里显示什么？
export default class SettingAuth extends Component {
    constructor(props) {
        super(props);
        this.state = this.getState();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.authMapInit !== this.props.authMapInit) {
            this.setState(this.getState());
        }
    }

    getState() {
        // TODO:authMapInit是否需要转换数据结构
        const authMap = this.props.authMapInit || {};
        return {
            authMap,
            firstLevelAuthCodes: getFirstLevelAuthCodes(authMap)
        };
    }

    changeCheck = (isChecked, authCode) => {
        const nextAuthMap = { ...this.state.authMap }; // THINK:是否要用深拷贝?
        const authConfig = nextAuthMap[authCode];
        authConfig.checked = isChecked;
        authConfig.indeterminate = false;

        setParentCheck(isChecked, authCode, nextAuthMap);

        setSubsCheck(isChecked, authCode, nextAuthMap);

        patchCheck(isChecked, authCode, nextAuthMap);

        this.setState({
            authMap: nextAuthMap
        });
    }

    changeSelect = (value, authCode) => {
        const nextAuthMap = { ...this.state.authMap }; // THINK:是否要用深拷贝?
        nextAuthMap[authCode].scopeValue = value;

        this.setState({
            authMap: nextAuthMap
        });
    }

    value() {
        const codes = [];
        for (const [code, auth] of Object.entries(this.state.authMap)) {
            if (auth.checked) {
                codes.push([code, auth.scopeValue]);
            }
        }
        return codes;
    }

    render() {
        const { authMap, firstLevelAuthCodes } = this.state;
        return (
            <Tabs>
                {
                    firstLevelAuthCodes.map(code => {
                        const auth = authMap[code];
                        const secondLevelAuthCodes = auth.subs;
                        return (
                            <TabPane tab={auth.desc} key={code}>
                                {secondLevelAuthCodes.map(authCode => (<SettingAuthRow authMap={authMap} authCode={authCode} changeCheck={this.changeCheck} changeSelect={this.changeSelect} key={authCode} />))}
                            </TabPane>);
                    })
                }
            </Tabs>
        );
    }

}

function getFirstLevelAuthCodes(authMap) {
    const codes = [];
    for (const [code, auth] of Object.entries(authMap)) {
        if (auth.level === 1) {
            codes.push(code);
        }
    }
    return codes;
}

function setParentCheck(isChecked, authCode, authMap) {
    const auth = authMap[authCode];
    const parentAuthCode = auth.parentId;
    if (parentAuthCode) {
        const parentAuth = authMap[parentAuthCode];
        if (isChecked) {
            // 选中时把上级也选中
            parentAuth.checked = true;
            parentAuth.indeterminate = parentAuth.subs.some(subAuthCode => {
                const { checked, indeterminate } = authMap[subAuthCode];
                return indeterminate || !checked;
            });
            setParentCheck(isChecked, parentAuthCode, authMap);
        } else {
            // 未选中时，上级的其他下级也没选中，则把上级设置为未选中
            if (parentAuth.subs.every(subAuthCode => !authMap[subAuthCode].checked)) {
                parentAuth.checked = false;
                parentAuth.indeterminate = false;
                setParentCheck(isChecked, parentAuthCode, authMap);
            } else {
                parentAuth.indeterminate = true;
                setParentCheck(true, parentAuthCode, authMap);
            }
        }
    }
}

function setSubsCheck(isChecked, authCode, authMap) {
    // 把下级权限设置为同样选中状态
    const auth = authMap[authCode];
    if (auth.subs && auth.subs.length) {
        auth.subs.forEach(subAuthCode => {
            const authConfig = authMap[subAuthCode];
            authConfig.checked = isChecked;
            authConfig.indeterminate = false;
            setSubsCheck(isChecked, subAuthCode, authMap);
        });
    }
}

function patchCheck(isChecked, authCode, authMap) {
    // 打补丁
    if (isChecked && authCode === '99030402') {
        // 若勾选“模板管理与配置”，则“查看列表”必选；
        authMap[99030401].checked = true;
        authMap[990304].indeterminate = false;
    }
    if(!isChecked && authCode === '99030401'){
        // 若不勾选“查看列表”，则“模板管理与配置”不勾选；
        authMap[99030402].checked = false;
        authMap[990304].checked = false;
        authMap[990304].indeterminate = false;
    }
}