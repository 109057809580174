import React, { Component } from 'react';
import { Input, Tag, DatePicker, List, Form, Popover, Button } from 'antd';
import './CapitalManager.less'
import { CapitalFloatInfo } from './CapitalCommon'
import { getDataStatus, getItemColor, getRentStatus } from './Utils'
import { TAG_DATA_STATUS } from './CapitalConfig'
import { NumericInput } from './NumericInput'
import moment from 'moment';
import http from '@/http'
import { API } from '@/http/api';
import AuthContainer from 'CMPT/common/auth/AuthContainer';
import { AUTH_KEYS } from '@/utils/auth';
import { each } from '../../../../../node_modules/zrender/lib/core/util';

const dateFormat = 'YYYY-MM-DD';

/**
 * 区域管理
 */
class CapitalManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            isOkBtnShow: false,
            selectValues: {
                projectEncode: null,       //项目ID
                status: 0,          //状态, 0:全部，1：可出租，2：已出租，3:60天内到期，4：装修中，5：已逾期
                keyword: null,        //关键字，模糊搜索
                signDateFrom: null,   //签约日期-开始
                signDateTo: null,     //签约日期-结束
                expireDateFrom: null,     //到期时间-开始
                expireDateTo: null,       //到期时间-结束
                areaFrom: null,       //区域面积-开始
                areaTo: null          //区域面积-结束 
            },
            resData: null,
            visibleEncode: null,    //气泡卡片显示的iD
            selectTags: [ //与selectValues的status保持一致
                {
                    status: 0,
                    desc: "全部"
                }, {
                    status: 1,
                    desc: "未出租"
                }, {
                    status: 2,
                    desc: "已出租"
                }, {
                    status: 3,
                    desc: "60天内到期"
                }, {
                    status: 4,
                    desc: "装修中"
                }, {
                    status: 5,
                    desc: "已逾期"
                }
            ]
        };
    }

    getProjectInfo = (encode) => {
        const config = {
            api: API.ProjectsInfo,
            path: {
                projectEncode: encode,
            }
        }
        http(config).then(result => {
            if (result.success) {
                this.initSearch(encode, result.data.regionName + '-' + result.data.name)
            }
        })
    }

    componentDidMount() {
        this.getProjectInfo(this.props.encode);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.encode !== nextProps.encode) {
            this.getProjectInfo(nextProps.encode);
        }
    }

    initSearch(code, name) {
        let that = this;
        this.setState({
            selectValues: {
                ...this.state.selectValues,
                projectEncode: code
            },
            title: name
        }, function () {
            that.doSearch();
        })
    }

    handlerBackRefresh() {
        this.doSearch()
    }

    handlerBackMenuClick(name) {
        this.setState({
            visibleEncode: null,
        });
    }

    onPopoverVisibleChange = (item, visible) => {
        this.setState({
            visibleEncode: visible ? item.encode : null
        })
    }

    selectTag = (res) => {
        this.setState({
            selectValues: {
                ...this.state.selectValues,
                status: res.status
            }
        }, () => {
            this.doSearch()
        })
    }

    selectKeyWord = (e) => {
        this.setState({
            selectValues: {
                ...this.state.selectValues,
                keyword: e.target.value != '' ? e.target.value : null
            }
        })
    }

    searchKeyWord = (value) => {
        this.setState({
            selectValues: {
                ...this.state.selectValues,
                keyword: value
            }
        }, () => {
            this.doSearch()
        })

    }

    selectQyTime = (date, dateString) => {
        this.setState({
            selectValues: {
                ...this.state.selectValues,
                signDateFrom: dateString[0],
                signDateTo: dateString[1]
            }
        }, () => {
            this.doSearch()
        })
    }

    selectDqTime = (date, dateString) => {
        this.setState({
            selectValues: {
                ...this.state.selectValues,
                expireDateFrom: dateString[0],
                expireDateTo: dateString[1]
            }
        }, () => {
            this.doSearch()
        })
    }

    selectMjMin = (value) => {
        this.setState({
            selectValues: {
                ...this.state.selectValues,
                areaFrom: value
            }
        }, function () {
            this.showOkBtn()
        })
    }

    selectMjMax = (value) => {
        this.setState({
            selectValues: {
                ...this.state.selectValues,
                areaTo: value
            }
        }, function () {
            this.showOkBtn()
        })
    }

    showOkBtn() {
        this.setState({
            isOkBtnShow: (this.state.selectValues.areaFrom != '' || this.state.selectValues.areaTo != '') ? true : false
        })
    }

    onReset() {
        let that = this;
        this.setState({
            isOkBtnShow: false,
            selectValues: {
                ...this.state.selectValues,
                status: 0,
                keyword: null,
                signDateFrom: null,
                signDateTo: null,
                expireDateFrom: null,
                expireDateTo: null,
                areaFrom: null,
                areaTo: null
            }
        }, function () {
            that.doSearch();
        })
    }

    async doSearch() {
        const config = {
            api: API.ProjectsSearch,
            data: this.state.selectValues
        }
        http(config).then(result => {
            this.setState({
                resData: result.data
            })
        })
    }

    // 当前楼层其他可租区域
    combineZoneVos = (zoneVos) => {
        let results = zoneVos.filter((item) => {
            let codes = item.dataStatus.map(eachItem => {
                return eachItem.code;
            });
            let hasFee = codes.indexOf(4) >= 0; // 4:欠费
            return !hasFee && (item.rentStatus == 1); // 显示未出租并且是未欠费的区域
        });
        return results;
    };

    floatPopContent(itemGd, zoneVos) {
        let QkDays = null;    //含有欠款及欠款天数
        if (itemGd.dataStatus) {
            for (let item of itemGd.dataStatus) {
                if (item.code == TAG_DATA_STATUS.Debt.value) {    //含有欠费
                    QkDays = item.decription;
                    break;
                }
            }
        }

        return <CapitalFloatInfo key={itemGd.encode} doSearch={this.doSearch.bind(this)} zoneVosData={itemGd} zoneVos={zoneVos} QkDays={QkDays}
            visibleEncode={this.state.visibleEncode} history={this.props.history}
            handlerBackRefresh={this.handlerBackRefresh.bind(this)}
            handlerBackMenuClick={this.handlerBackMenuClick.bind(this)} />
    }

    gotoAddRegion(itemLs) {
        this.props.history.push(`/app/project/region/add/${itemLs.floor.projectEncode}?floorEncode=${itemLs.floor.encode}`)
    }

    render() {
        const selectValues = this.state.selectValues;
        const resData = this.state.resData;

        return (
            <div className="capital-manager">


                {/* 条件筛选 */}
                <div className='capital-tag-list gray-background'>
                    <div>
                        <div className='float-left'>
                            {
                                this.state.selectTags.map((res, i) => {
                                    return <Tag.CheckableTag key={i} className={selectValues.status == res.status ? 'active' : 'unactive'} onChange={checked => this.selectTag(res)}>{res.desc}</Tag.CheckableTag>
                                })
                            }
                        </div>
                        <div className='y-line'>&nbsp;</div>
                        <div className='float-left' style={{ width: '30%', marginLeft: '15px' }}>
                            <Input.Search value={selectValues.keyword} onChange={this.selectKeyWord} onSearch={value => this.searchKeyWord(value)}
                                placeholder="请输入区域门牌号/租客信息" maxLength={20} />
                        </div>
                    </div>

                    <div className='clear-float' style={{ paddingTop: '20px', fontSize: '12px' }}>
                        签约时间 <DatePicker.RangePicker
                            style={{ marginLeft: '8px', marginRight: '28px' }}
                            value={
                                (selectValues.signDateFrom && selectValues.signDateTo) ?
                                    [
                                        moment(selectValues.signDateFrom, dateFormat),
                                        moment(selectValues.signDateTo, dateFormat)
                                    ] : []
                            }
                            onChange={this.selectQyTime} />
                        到期时间 <DatePicker.RangePicker
                            style={{ marginLeft: '8px', marginRight: '28px' }}
                            value={
                                (selectValues.expireDateFrom && selectValues.expireDateTo) ?
                                    [
                                        moment(selectValues.expireDateFrom, dateFormat),
                                        moment(selectValues.expireDateTo, dateFormat)
                                    ] : []
                            }
                            onChange={this.selectDqTime} />
                        建筑面积 <NumericInput className='input' placeholder="最小值"
                            style={{ marginLeft: '8px' }}
                            onChange={this.selectMjMin} value={selectValues.areaFrom}
                            suffix={
                                <div>m²</div>
                            }
                        /> ~ <NumericInput className='input' placeholder="最大值" onChange={this.selectMjMax} value={selectValues.areaTo}
                            suffix={
                                <div>m²</div>
                            }
                        />
                        <Button style={{ background: '#FBBC06', color: '#000000', marginLeft: '10px', display: this.state.isOkBtnShow ? 'inline' : 'none' }} onClick={() => this.doSearch()}>确定</Button>
                        <a href="javascript:;" className='underline' style={{ marginLeft: '20px', color: '#E0E4F1' }} onClick={() => this.onReset()}>重置</a>
                    </div>
                </div>

                {/* 列表 */}
                <div style={{ marginTop: '20px' }}>
                    <List
                        split={false}
                        grid={{ gutter: 5 }}
                        header={<div className='space-bw' style={{ fontSize: '12px', color: '#333' }}>
                            <div>{`满足筛选条件共${(resData && resData.projectQueryDescVo && resData.projectQueryDescVo.zoneCnt) || 0}个区域，
                            共占${(resData && resData.projectQueryDescVo && resData.projectQueryDescVo.areaCnt) || 0}m²`}</div>
                            <div>图例：
                                <button className='border-no'>未出租
                                </button> <button className='border-yes'>已出租
                                </button> <button className='border-forbid'>不可招商
                                </button>
                            </div>
                        </div>}
                        dataSource={(resData && resData.floorVos) || []}
                        renderItem={itemLs => (
                            <List.Item key={itemLs.floor.floorEncode}>
                                <div className='list-item'>
                                    <div className='space-bw' style={{padding:'0 4px'}}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <div style={{ fontSize: '20px', fontWeight: 'bold' }}>{itemLs.floor.name}</div>
                                            <div style={{ fontSize: '12px', marginLeft: '3px' }}>层 <span style={{color: '#535465'}}>|</span> 面积：{itemLs.floor.area}m² 单价：{itemLs.floor.price}元/m²/日</div>
                                        </div>
                                        <AuthContainer key="1" authCodes={AUTH_KEYS.PROJECT_NEW_REGION}>
                                            <a href="javascript:;" style={{ fontSize: '12px', color: '#E0E4F1' }} className='underline' onClick={() => this.gotoAddRegion(itemLs)}>添加区域</a>
                                        </AuthContainer>
                                    </div>
                                    {/* 以下同样使用list，设置column来达到grid的效果 */}
                                    <List
                                        className='item-list'
                                        split={false}

                                        style={{
                                            overflow: 'hidden',
                                            marginTop: (itemLs.floor.zones == null || itemLs.floor.zones.length <= 0) ? '0px' : '16px'
                                        }}
                                        dataSource={(itemLs.floor.zones == null || itemLs.floor.zones.length <= 0) ? ['null'] : itemLs.floor.zones}
                                        renderItem={itemGd => (
                                            <List.Item style={{
                                                width: '20%', float: "left",
                                                padding:'0 4px',
                                                display: itemGd == 'null' ? 'none' : 'block'
                                            }} key={itemGd.encode}>
                                                <Popover overlayClassName="popover" content={this.floatPopContent(itemGd, this.combineZoneVos(itemLs.floor.zones))} trigger='click'
                                                    visible={itemGd.encode === this.state.visibleEncode} onVisibleChange={(e) => this.onPopoverVisibleChange(itemGd, e)}>
                                                    <div className={getRentStatus(itemGd.rentStatus)}>
                                                        <a href="javascript:;" style={{ color: getItemColor(itemGd.rentStatus) }}>
                                                            <div className='space-bw'>
                                                                <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{itemGd.zoneNo}</div>
                                                                <div>{itemGd.buildingArea}m²</div>
                                                            </div>
                                                            <div className='item-des'>
                                                                {itemGd.rentStatus === 2 ? itemGd.customerName || '-' : `预计租金：${itemGd.unitPrice}元/m²/日`}
                                                            </div>

                                                            {
                                                                (itemGd.dataStatus == null || itemGd.dataStatus.length <= 0) ?
                                                                    <span>
                                                                        <Tag className='traslate'>hidden</Tag>
                                                                    </span>
                                                                    :
                                                                    <span className='item-tag'>
                                                                        {itemGd.dataStatus.map((dataStatusItem, i) => {
                                                                            return <Tag key={i} className={getDataStatus(dataStatusItem, 0)} style={{ fontSize: '12px' }}>{getDataStatus(dataStatusItem, 1)}</Tag>
                                                                        })}
                                                                    </span>
                                                            }
                                                        </a>
                                                    </div>
                                                </Popover>
                                            </List.Item>
                                        )}
                                    />

                                </div>
                            </List.Item>)}
                    />
                </div>
            </div>
        );
    }
}

export default CapitalManager;