import React, { Component } from 'react';

class NoJob extends Component {
    render() {
        return (
            <div className="no-job no-data" >
                    <img style={{width:'48px',height:'48px'}} src={require('@/images/empty_schedule@2x.png')} alt=""/>
                    <p>
                        {this.props.label}
                    </p>
            </div>
        )
    }
}

export default NoJob
