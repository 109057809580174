import http from "@/http";
import { API } from "@/http/api";
import { formatLinkSearch } from "@/utils";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table from "CMPT/common/Table";
import SearchForm from "./searchForm";
import moment from "moment";

const openAccountTypeMap = {
  0: "个人",
  1: "企业",
};

// 筛选项默认值
const defaultParams = {
  pageNo: 1, // 需要查询的页数
  pageSize: 10, // 每页条数
  plate: '', //车牌
  identityType: '', //身份
  name: '' // 企业/人员名称

};
class VehicleManageList extends Component {
  state = {
    total: 0, // 当前数据的总数
    pageNo: 1, // 当前所展示的页码
    currentPageCount: 0, //当前页面的数量
    selectParams: {
      // 接口入参，必须放这里。方便分页时保存信息
      ...defaultParams,
    },
    columns: [
      {
        title: '序号',
        width: "60px",
        key: "id",
        render: (text, record, index) => {
            return (<span>{index + 1}</span>);
        },
      },
      {
        title: "车牌号码",
        key: "plate",
        align: "center",
        dataIndex: "plate",
      },
      {
        title: "车型",
        key: "vehicleTypeName",
        align: "center",
        dataIndex: "vehicleTypeName",
      },
      {
        title: "车长/车宽/车高",
        key: "vehicleBaseInfo",
        align: "center",
        dataIndex: "vehicleBaseInfo"
      },
      {
        title: "是否拖挂",
        key: "withTrailerName",
        align: "center",
        dataIndex: "withTrailerName",
      },
      {
        title: "身份",
        key: "identityName",
        align: "center",
        dataIndex: "identityName",
      },
      // {
      //   title: this.props.state == 0 ? "需求提交时间" : "需求提交时间", // 0 表示待审核状态
      //   key: this.props.state == 0 ? "submitDate" : "submitDate",
      //   align: "center",
      //   dataIndex: this.props.state == 0 ? "submitDate" : "submitDate",
      //   render: (text, record) => {
      //     return (
      //       <span>{text ? moment(text).format("YYYY-MM-DD HH:mm") : "-"}</span>
      //     );
      //   },
      // },
      // {
      //   title: this.props.state == 0 ? "申请提交时间" : "申请提交时间", // 0 表示待审核状态
      //   key: this.props.state == 0 ? "completeDate" : "completeDate",
      //   align: "center",
      //   dataIndex: this.props.state == 0 ? "completeDate" : "completeDate",
      //   render: (text, record) => {
      //     return (
      //       <span>{text ? moment(text).format("YYYY-MM-DD HH:mm") : "-"}</span>
      //     );
      //   },
      // },
      {
        title: "企业/人员名称",
        key: "identityUserName",
        align: "center",
        dataIndex: "identityUserName",
      },
      {
        title: "创建人员",
        key: "createUserName",
        align: "center",
        dataIndex: "createUserName",
      },
      {
        title: "创建时间", // 0 表示待审核状态
        key: "createDate",  
        align: "center",
        dataIndex: "createDate",
      },
      {
        title: "操作",
        key: "operate",
        align: "center",
        width: 120,
        render: (text, record, index) => {

          return (<span className="table-operate-btn">
          <Link
            to={
              "/app/crm/vehicleAndEquipment/VehicleDetail/" +
              record.code +
              "?pageNo=" +
              this.state.pageNo +
              "&currentPageCount=" +
              this.state.currentPageCount
            }
            style={{ color: "#2E4AFF" }}
          >
            查看详情
          </Link>
        </span>)
        },
      },
    ],
    data: [],
  };

  componentWillMount() {
    if (this.props.location) {
      // 获取参数
      const search = this.props.location.search;
      const formatData = formatLinkSearch(search);

      // 解析
      let pageNo1 = null;
      if (formatData && formatData.backPageNo) {
        pageNo1 = formatData.backPageNo;
      }

      // 赋值
      this.setState({
        selectParams: {
          pageNo: pageNo1, // 当前页数
          pageSize: 10, // 每页条数
        },
      });
    }
  }

  //初始化
  componentDidMount() {
    this.getLists();
  }
  //查询列表
  getLists = () => {
    const httpParams = { ...this.state.selectParams };
    console.log(httpParams, '搜索项参数');
    

    for (let [key, value] of Object.entries(httpParams)) {
      if (value === -1 || value === "") {
        //    console.log('[key, value]',[key, value])
        httpParams[key] = undefined;
      }
    }
    const config = {
      api: API.vAndEVehicleListPage,
      data: { ...httpParams },
      showLoading: true,
    };

    http(config).then((res) => {
      if (res.code == 200) {
        const data = res.data;
        this.setState({
          data: data.datas || [],
          total: Number(data.total),
          pageNo: data.pageNo,
          currentPageCount: data.datas ? data.datas.length : 0,
          selectParams: { ...this.state.selectParams, pageNo: data.pageNo },
        });
      }
    });
  };

  handleDelete = (code) => {
    const config = {
      api: API.sourcingFreightAuditForcedDown,
      data: {
        code: code,
      },
    };
    http(config).then((res) => {
      if (res && res.code == 200) {
        this.getLists();
      }
    });
  };

  //分页
  onChangePage = (pageNo, pageSize, sort) => {
    this.setState(
      {
        selectParams: {
          ...this.state.selectParams,
          pageNo: pageNo,
          pageSize: pageSize,
        },
      },
      () => {
        this.getLists();
      }
    );
  };

  // 搜索
  onValuesChange = (allValues) => {
    this.setState({
      selectParams: { ...this.state.selectParams, ...allValues },
    });
  };

  reset = (allValues) => {
    this.setState(
      {
        selectParams: { ...this.state.selectParams, ...defaultParams },
      },
      () => {
        this.getLists();
      }
    );
  };

  render() {
    const selectParams = this.state.selectParams;
    return (
      <div className="enterprise-auth-list">
        <div className="searchBox">
          <SearchForm
            onValuesChange={this.onValuesChange}
            types={false}
            reset={this.reset}
            onSearch={() => {
              this.getLists();
            }}
          />
        </div>
        <Table
          columns={this.state.columns}
          rowKey={(record, index) => record.code}
          selfProcessData={this.state.data}
          total={this.state.total}
          change={this.onChangePage}
          current={selectParams.pageNo}
        />
      </div>
    );
  }
}

export default VehicleManageList;
