import React, { Component, useRef, useCallback } from "react";
import {
	Breadcrumb,
	Input,
	Button,
	Tree,
	Modal,
	message,
	Form,
	Select,
} from "antd";
import "./index.less";
import http from "@/http";
import { API } from "@/http/api";
import Table from "CMPT/common/Table";
import { Link } from "react-router-dom";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";

const BreadcrumbItem = Breadcrumb.Item;
const { TreeNode } = Tree;

class BackendCategory extends Component {
	state = {
		autoExpandParent: true,
		expandedKeys: [],
		checkedKeys: [],
		selectedKeys: ["qeZDWfgpvSs"],
		// 左侧树数据
		treeData: [],
		// 右侧列表数据
		organizationListData: [],
		cards: [],
		total: 0,
		params: {
			pageNo: 1,
			pageSize: 10,
			organizationCode: "",
			organizationName: "",
		},
		organizationList: [],
		departMentModal: false,
		organizationCode: ''
	};

	loadData = () => {
		this.getOrganizationTree();
		this.getOrganizationPageList();
		this.accountGetOrganizationList()
	};

	componentWillMount() {
		this.loadData();
	}

	// 获取所有组织列表
	accountGetOrganizationList = () => {
		const config = {
		  api: API.accountGetOrganizationList,
		  showLoading: true,
		};
		http(config).then((res) => {
		  if (res && res.success) {
			this.setState({
			  organizationList: res.data
			})
		  }
		});
	  }

	// 获取左侧部门树数据
	getOrganizationTree = () => {
		http({
			api: API.departmentManagementTree,
			showLoading: true,
		}).then(res => {
			if (res.success) {
				this.setState({
					treeData: res.data,
				});
			}
		});
	};

	// 获取右侧部门列表数据
	getOrganizationPageList = () => {
		http({
			api: API.departmentManagementList,
			data: this.state.params,
			showLoading: true,
		}).then(res => {
			if (res.success && res.data) {
				this.setState({
					organizationListData: res.data,
					total: res.data.total,
				});
			}
		});
	};

	// 按部门名称搜索
	setKeyWord = e => {
		let params = this.state.params;
		params.organizationName = e.target.value;
		this.setState({
			params,
		});
	};

	// 按部门名称搜索
	keyWordSearch = value => {
		let params = this.state.params;
		params.pageNo = 1;
		params.organizationName = value;
		this.setState(
			{
				params,
			},
			this.getOrganizationPageList
		);
	};

	// 重置
	reset = () => {
		if (this.state.params.organizationName == "") {
			return;
		}
		let params = this.state.params;
		params.pageNo = 1;
		params.organizationName = "";
		this.setState(
			{
				params,
			},
			this.getOrganizationPageList
		);
	};

	// 渲染树节点
	renderTreeNodes = data => {
		return data.map(item => {
			if (item.children) {
				return (
					<TreeNode title={item.title} key={item.key} dataRef={item}>
						{this.renderTreeNodes(item.children)}
					</TreeNode>
				);
			}
			return <TreeNode title={item.title} key={item.key} dataRef={item} />;
		});
	};

	// 展开树
	onExpand = expandedKeys => {
		this.setState({
			expandedKeys,
			autoExpandParent: false,
		});
	};

	// 选中树节点
	onSelect = (selectedKeys, { selected: bool, selectedNodes, node, event }) => {
		let params = this.state.params;
		params.pageNo = 1;
		params.organizationCode = selectedKeys[0];

		this.setState(
			{
				params,
				selectedKeys,
			},
			this.getOrganizationPageList
		);
	};

	// 分页
	onChangePage = (pageNo, pageSize, sort) => {
		let params = this.state.params;
		params.pageNo = pageNo;
		params.pageSize = pageSize;
		this.setState(
			{
				params,
			},
			this.getOrganizationPageList
		);
	};
	// 新增组织
	addDepartment = () => {
		this.props.form.setFieldsValue({
			parentOrganizationCode: null,
			organizationName: ''
		});
		this.setState({
			departMentModal: true,
			organizationCode: ''
		})
	}

	editDepartment = (record) => {
		this.props.form.setFieldsValue({
			parentOrganizationCode: record.parentOrganizationCode,
			organizationName: record.organizationName
		});
		this.setState({
			departMentModal: true,
			organizationCode: record.organizationCode
		})
	}

	handleOk = (e) => {
		e.preventDefault();
		this.props.form.validateFieldsAndScroll((err, values) => {
			if (!err) {
				console.log(values, 'values');
				this.departmentDo(values)
			}
		});
	}

	// 获取所有组织列表
	departmentDo = (values) => {
		let {organizationCode} = this.state;
		const config = {
		  api: organizationCode ? API.accountUpdateOrganization : API.accountAddOrganization,
		  showLoading: true,
		  data: {
			organizationCode,
			...values
		  }
		};
		http(config).then((res) => {
		  if (res && res.success) {
			message.success('操作成功')
			this.setState({
				departMentModal: false
			})
			this.loadData()
		  }
		});
	  }


	accountDeleteOrganization = (record) => {
		Modal.confirm({
			title: '删除组织',
			content: `确认删除该组织【${record.organizationName}】吗？`,
			icon: 'none',
			onOk: () => {
			  const config = {
				api: API.accountDeleteOrganization,
				data: {
					organizationCode: record.organizationCode,
				},
				showLoading: true,
			  };
			  http(config).then((res) => {
				if (res && res.success) {
				  message.success('删除成功');
				  this.loadData()
				}
			  });
			}
		  })
	}

	render() {
		const { params, treeData, organizationListData, departMentModal, organizationCode } = this.state;

		const columns = [
			{
				title: "序号",
				dataIndex: "index",
				render: (text, record, index) => {
					return index + 1;
				},
			},
			{
				title: "部门层级",
				dataIndex: "levelName",
			},
			{
				title: "部门名称",
				dataIndex: "organizationName",
			},

			{
				title: "操作",
				key: "operate",
				render: (text, record, index) => {
					return (
						<div>
							<span className="table-operate-btn operate" onClick={() => this.editDepartment(record)}>编辑</span>
							<span className="table-operate-btn operate" onClick={() => this.accountDeleteOrganization(record)}>删除</span>
						</div>
					);
				},
			},
		];


		const { getFieldDecorator } = this.props.form;

		return (
			<div className="root">
				<div>
					<Breadcrumb>
						<BreadcrumbItem>系统设置</BreadcrumbItem>
						<BreadcrumbItem>部门管理</BreadcrumbItem>
					</Breadcrumb>
				</div>
				<div className="function-part">
					<div className="category-header">
						<div>
							<span className="item">
								<Input.Search
									placeholder="部门名称"
									onChange={this.setKeyWord}
									onSearch={this.keyWordSearch}
									value={params.organizationName}
									style={{ width: "240px" }}
									maxLength={20}
								/>
							</span>

							<Button
								type="primary"
								onClick={() => {
									this.reset();
								}}
							>
								重置
							</Button>

						</div>
						<Button className="account_manage_create" type="primary" onClick={this.addDepartment}>
							新增
						</Button>
					</div>
					<div className="main-part">
						<div className="tree-part">
							{treeData && treeData.length ? (
								<Tree
									defaultExpandAll
									onExpand={this.onExpand}
									expandedKeys={this.state.expandedKeys}
									autoExpandParent={this.state.autoExpandParent}
									onSelect={this.onSelect}
									selectedKeys={this.state.selectedKeys}
								>
									{this.renderTreeNodes(this.state.treeData)}
								</Tree>
							) : (
								<div className="empty-tree">暂无部门</div>
							)}
						</div>
						<div className="table-part">
							<div>
								<div className="backend-category-list" id="drag-table">
									<DndProvider backend={HTML5Backend}>
										<Table
											columns={columns}
											selfProcessData={organizationListData.datas}
											total={organizationListData.total}
											change={this.onChangePage}
											current={params.pageNo}
											rowKey="organizationCode"
										/>
									</DndProvider>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Modal
					title={organizationCode ? "编辑部门" : "新增部门"}
					visible={departMentModal}
					onOk={this.handleOk}
					onCancel={() => {
						this.setState({departMentModal: false})
					}}
					width={"600px"}
					className="channel-nav-modal"
				>
					<Form className="login-form">
						<Form.Item label="选择父级部门">
							{getFieldDecorator("parentOrganizationCode", {
								rules: [],
							})(
								<Select placeholder="请选择">
									{this.state.organizationList && this.state.organizationList.map((item) => (
										<Select.Option value={item.organizationCode}>{item.organizationName}</Select.Option>
									))}
								</Select>
							)}
						</Form.Item>
						<Form.Item label="部门名称">
							{getFieldDecorator("organizationName", {
								rules: [
								{
									required: true,
									message: "请输入部门名称",
								},
								],
							})(<Input placeholder="请输入部门名称" />)}
						</Form.Item>
					</Form>
				</Modal>
			</div>
		);
	}
}
export default Form.create()(BackendCategory);
