import { AJAX_TYPE } from "../config";

// 公告

const prefix = "yqy";

export default {
  [`${prefix}List`]: {
    url: "/web/crm/yqy/list",
    type: AJAX_TYPE.GET
  },
  [`${prefix}Add`]: {
    url: "/web/crm/yqy/add",
    type: AJAX_TYPE.POST
  },
  [`${prefix}Edit`]: {
    url: "/web/crm/yqy/edit",
    type: AJAX_TYPE.POST
  },
  [`${prefix}Del`]: {
    url: "/web/crm/yqy/delete/:encode",
    type: AJAX_TYPE.DELETE
  },
  [`${prefix}OpenClose`]: {
    url: "/web/crm/yqy/openOrClose/:encode",
    type: AJAX_TYPE.POST
  },
  [`${prefix}Sort`]: {
    url: "/web/crm/yqy/sort",
    type: AJAX_TYPE.POST
  }
};
