import { API } from "@/http/api";
import http from "@/http";
import { showErrorMessage } from "@/utils/globalMessageReminder";
import { formatLinkSearch } from "@/utils";
import React, { Component } from "react";
import "./EnterpriseUpgrade.less";
import {
  Layout,
  Form,
  Breadcrumb,
  Button,
  Row,
  Col,
  DatePicker,
  Cascader,
  Radio,
  Icon,
  Modal,
  Input,
  Checkbox
} from "antd";
import TitlePanel from "CMPT/TitlePanel";
import { ManagementModel } from "./CommonConst.js";
import {
  CommonFlag,
  AccountTypeMap,
  AccountTypeFlag,
  FormatSearch,
  convertEnumToMap
} from "CMPT/common.js";
import ImageUploadBiz from "CMPT/common/ImageUpLoadBiz";
import FileUploadBiz from "CMPT/common/FileUploadBiz";
import { Link } from "react-router-dom";
import Select2 from "CMPT/common/Select";
import DateRange from "CMPT/common/DateRange";
import moment from "moment";
const { TextArea } = Input;

const RadioGroup = Radio.Group;

const { Content } = Layout;

class EnterpriseUpgrade extends Component {
  state = {
    isShowSupplierButon: false, // 是否展示升级到供应商的按钮
    isShowGardenButton: false, // 是否展示 升级到园区方的按钮
    isShowSupplierForm: false,
    isShowGraderForm: false,

    errorTitle: null,
    errorMsg: null,

    trialAccountDays: -1,
    accountType: null,
    region: [],
    modeValue: [],
    manageType: [], // 主营类型

    fileInfoList: [],

    formData: {
      contractStartDate: null,
      contractEndDate: null,
      trialStartDate: null,
      trialEndDate: null,

      provinceEncode: null,
      cityEncode: null,
      regionEncode: null,

      upgradeTo: null
    }
  };

  componentDidMount() {
    let search = this.props.history.location.search;
    var result = FormatSearch(search);

    // 传过的事 是不是 供应商，园区方。 所以要置反下。
    this.setState({
      isShowSupplierButon: !(result
        ? result.isSupplier == "true"
          ? true
          : false
        : false),
      isShowGardenButton: !(result
        ? result.isGarden == "true"
          ? true
          : false
        : false)
    });
  }

  contractDateChange = (field, value) => {
    let newformData = this.state.formData;
    if (field === "startValue") {
      newformData.contractStartDate = value;
    } else {
      newformData.contractEndDate = value;
    }

    this.setState({
      formData: newformData
    });
  };

  trialDateChange = (field, value) => {
    let newformData = this.state.formData;
    if (field === "startValue") {
      newformData.trialStartDate = value;
    } else {
      newformData.trialEndDate = value;
    }

    this.setState({
      formData: newformData
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        // console.log(this.props.form.getFieldsValue());
        let fieldsObject = this.props.form.getFieldsValue();
        // console.log(fieldsObject);

        if (this.state.accountType == AccountTypeFlag.TRIAL) {
          if (
            this.state.formData.trialStartDate &&
            this.state.formData.trialEndDate
          ) {
            fieldsObject.contractStartDate = moment(
              this.state.formData.trialStartDate
            ).format("YYYY-MM-DD");
            fieldsObject.contractEndDate = moment(
              this.state.formData.trialEndDate
            ).format("YYYY-MM-DD");
          }
        } else {
          // 下面是正式账号
          if (fieldsObject.signDate) {
            fieldsObject.signDate = moment(fieldsObject.signDate).format(
              "YYYY-MM-DD"
            );
          }

          if (
            this.state.formData.contractStartDate &&
            this.state.formData.contractEndDate
          ) {
            fieldsObject.contractStartDate = moment(
              this.state.formData.contractStartDate
            ).format("YYYY-MM-DD");
            fieldsObject.contractEndDate = moment(
              this.state.formData.contractEndDate
            ).format("YYYY-MM-DD");
          }
        }

        fieldsObject.enterpriseCode = this.props.match.params.encode;
        fieldsObject.provinceEncode = this.state.formData.provinceEncode;
        fieldsObject.cityEncode = this.state.formData.cityEncode;
        fieldsObject.regionEncode = this.state.formData.regionEncode;
        fieldsObject.fileInfoList = this.state.fileInfoList;
        fieldsObject.upgradeTo = this.state.formData.upgradeTo;

        delete fieldsObject.trialTimeRange;
        delete fieldsObject.contractDate;
        delete fieldsObject.addressEncode;

        //console.log("   doSubmit ", fieldsObject);
        this.doSubmit(fieldsObject).then(res => {
          if (res && res.success) {
            this.goback();
          } else if (res && res.code === "300103") {
            this.showErrorInfoDialog(
              "通过",
              res.msg ? res.msg : "企业账号信息已发生变更，请重新进行申请"
            );
          } else if (res && res.code === "300104") {
            this.showErrorInfoDialog(
              "通过",
              res.msg ? res.msg : "客户信息发生变更，请重新操作"
            );
          }
        });
      }
    });
  };

  goback = () => {
    this.props.history.go(-1);
  };

  showErrorInfoDialog(title, msg) {
    this.setState({
      errorTitle: title,
      errorMsg: msg
    });
  }

  doSubmit(data) {
    let config = {
      api: API.crmCustomerUpgrade,
      showLoading: true,
      data: data
    };

    return http(config);
  }

  switchIsSupplier = e => {
    let newValue = e.target.checked;
    this.state.isShowSupplierForm = newValue;
    this.setState({});

    if (
      newValue &&
      (!this.state.manageType || this.state.manageType.length <= 0)
    ) {
      this.loadManageType();
    }

    this.changeUpgradeTo();
  };

  changeUpgradeTo() {
    let newValue = null;
    if (this.state.isShowSupplierForm && this.state.isShowGraderForm) {
      newValue = "2,3";
    } else if (this.state.isShowGraderForm) {
      newValue = "3";
    } else if (this.state.isShowSupplierForm) {
      newValue = "2";
    }

    this.state.formData.upgradeTo = newValue;
  }

  loadManageType = () => {
    const config = {
      api: API.enterpriseUpgradeCategoryQuery,
      showLoading: true
    };
    http(config).then(res => {
      if (res && res.success) {
        // 组装
        let newManageType = res.data.map(valueReturn => {
          return {
            value: valueReturn.code,
            name: valueReturn.name
          };
        });

        // 赋值
        this.setState({
          ManageType: newManageType
        });
      }
    });
  };
  // 省份列表

  loadRegion = selectedOptions => {
    console.log(selectedOptions);
    const targetOption = selectedOptions[selectedOptions.length - 1];

    http({
      api: API.areaSubAreas,
      data: {
        encode: targetOption.code
      },
      showLoading: false
    }).then(res => {
      targetOption.children = res.data;
      if (selectedOptions.length < 2) {
        res.data.forEach(item => {
          item.isLeaf = false;
          return item;
        });
      }
      this.setState({ region: [...this.state.region] });
    });
  };

  switchIsGrader = e => {
    let newValue = e.target.checked;
    this.state.isShowGraderForm = newValue;
    this.setState({});

    if (newValue) {
      this.getTrialSetting();
    }

    if (newValue && (!this.state.region || this.state.region.length <= 0)) {
      this.loadProvince();
    }

    this.changeUpgradeTo();
  };

  // 省份列表
  loadProvince = () => {
    http({
      api: API.areaProvinces,
      showLoading: false
    }).then(res => {
      if (res.success) {
        this.setState({
          region: res.data.map(item => {
            item.isLeaf = false;
            return item;
          })
        });
      }
    });
  };

  onAddressRegionChange = (value, selectedOptions) => {
    if (value && value.length >= 3) {
      let formData = this.state.formData;
      formData.provinceEncode = value[0];
      formData.cityEncode = value[1];
      formData.regionEncode = value[2];
    }
  };

  fileUp = fileList => {
    this.setState({
      fileInfoList: fileList
    });
  };

  handleManagerTypeChange = value => { };

  //TODO  新增和升级 使用了相同的代码：获取决策列表。 完全可以封装出一个 试用期的业务组件
  getTrialSetting = () => {
    if (this.state.trialAccountDays > 0) {
      //获取过就不用再获取
      return;
    }

    // 获取项目配置
    const defaultConfig = {
      api: API.decisionGetList,
      path: {
        bizType: 1
      },
      data: {
        bizType: "1"
      }
    };

    let myState = this.state;
    //trial_account_days
    http(defaultConfig).then(res => {
      if (res.data && res.data.length) {
        for (let j = 0, len = res.data.length; j < len; j++) {
          let item = res.data[j];
          if (item && item.key == "trial_account_days") {
            let trialAccountDaysValue = item.value;
            myState.formData.trialStartDate = moment();
            myState.formData.trialEndDate = moment().add(
              trialAccountDaysValue,
              "days"
            );

            //  这个仅仅方便 变量误判为null
            let fieldsObject = this.props.form.getFieldsValue();
            fieldsObject.trialTimeRange = myState.formData.trialStartDate;

            this.setState({
              trialAccountDays: trialAccountDaysValue
            });
            break;
          }
        }
      }
    });
  };

  handleAccountTypeChange = value => {
    this.setState({
      accountType: value
    });
  };

  cancel = () => {
    this.props.history.go(-1);
  };

  createGraderForm() {
    const { getFieldDecorator } = this.props.form;

    const config = {
      rules: [{ required: true, message: "请选择" }]
    };

    const contractDateConfig = {
      rules: [{ required: true, message: "请选择" }]
    };

    return (
      <TitlePanel key="grader" title="园区信息">
        <div>
          <Row gutter={24}>
            <Col className="gutter-row" span={16}>
              <Form.Item label={"总部地址"} required>
                <Input.Group>
                  <Row gutter={24}>
                    <Col span={8}>
                      {getFieldDecorator("addressEncode", {
                        required: true,
                        message: "请选择"
                      })(
                        <Cascader
                          style={{ width: "100%" }}
                          loadData={this.loadRegion}
                          fieldNames={{
                            label: "name",
                            value: "code",
                            children: "children"
                          }}
                          allowClear={false}
                          changeOnSelect
                          suffixIcon={<Icon type="caret-down" />}
                          placeholder={"省市区"}
                          options={this.state.region}
                          onChange={(value, selectedOptions) => {
                            this.onAddressRegionChange(value, selectedOptions);
                          }}
                        />
                      )}
                    </Col>
                    <Col span={12}>
                      {getFieldDecorator("address", {
                        required: true,
                        message: "请选择"
                      })(
                        <Input
                          style={{ width: "100%" }}
                          placeholder={"详细地址"}
                        />
                      )}
                    </Col>
                  </Row>
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>
        </div>

        {/*   项目数量  */}
        <div>
          <Row gutter={24}>
            <Col className="gutter-row" span={8}>
              <Form.Item label="项目数量">
                {getFieldDecorator("projectNumber", {
                  rules: [
                    {
                      pattern: /^[1-9][0-9]{0,10}$/,
                      message: "只能输入10位数以内正整数"
                    },
                    {
                      max: 5,
                      message: "最多10位数"
                    },
                    { required: true, message: "请选择" }
                  ]
                })(
                  <Input
                    style={{ width: "100%" }}
                    maxLength={5}
                    placeholder="请输入"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        </div>

        {/*   账号类型  */}
        <div>
          <Row gutter={24}>
            <Col className="gutter-row" span={8}>
              <Form.Item label="账号类型">
                {getFieldDecorator(
                  "acountType",
                  config
                )(
                  <Select2
                    style={{ width: "100%" }}
                    data={convertEnumToMap(AccountTypeMap)}
                    placeholder="请选择"
                    selectMethod={this.handleAccountTypeChange.bind(this)}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        </div>

        {/*   账号类型:正式  */}
        {this.state.accountType == AccountTypeFlag.FORMAL ? (
          <div>
            <Row gutter={24}>
              <Col className="gutter-row" span={8}>
                <Form.Item label="合同有效期">
                  {getFieldDecorator(
                    "contractDate",
                    contractDateConfig
                  )(
                    <DateRange
                      onDateChange={this.contractDateChange}
                      startValue={this.state.formData.contractStartDate}
                      endValue={this.state.formData.contractEndDate}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col className="gutter-row" span={8}>
                <Form.Item label="签约时间">
                  {getFieldDecorator(
                    "signDate",
                    config
                  )(
                    <DatePicker style={{ width: "100%" }} allowClear={false} />
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col className="gutter-row" span={24}>
                <Form.Item label="附件">
                  {getFieldDecorator(
                    "fileInfoList",
                    {}
                  )(
                    <FileUploadBiz
                      fileUp={this.fileUp}
                      fileList={this.state.fileInfoList}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </div>
        ) : (
            ""
          )}

        {this.state.accountType == AccountTypeFlag.TRIAL ? (
          <div>
            <Row gutter={24}>
              <Col className="gutter-row" span={8}>
                <Form.Item label="试用期">
                  {getFieldDecorator("trialTimeRange", {
                    initialValue: moment(), //TODO  这个日期只是为了 防止 提交时，控件 判Null。有时间 研究下 getFieldDecorator.
                    rules: [{ required: true, message: "请选择" }]
                  })(
                    <DateRange
                      onDateChange={this.trialDateChange}
                      startValue={this.state.formData.trialStartDate}
                      endValue={this.state.formData.trialEndDate}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </div>
        ) : (
            ""
          )}
      </TitlePanel>
    );
  }

  createSupplierForm() {
    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      isFieldTouched
    } = this.props.form;

    const config = {
      rules: [{ required: true, message: "请选择" }]
    };

    // console.log('  this.state.ManageType==> ', this.state.ManageType);

    return (
      <TitlePanel key="supplier" title="供应商信息">
        {this.state.ManageType && this.state.ManageType.length > 0 ? (
          <div>
            <Row gutter={24}>
              <Col className="gutter-row" span={8}>
                <Form.Item label="主营类型">
                  {getFieldDecorator(
                    "category",
                    config
                  )(
                    <Select2
                      style={{ width: "100%" }}
                      data={this.state.ManageType}
                      placeholder="请选择"
                      selectMethod={this.handleManagerTypeChange}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </div>
        ) : (
            ""
          )}

        <div>
          <Row gutter={5}>
            <Col span={5}>
              <Form.Item label="佣金比例">
                {getFieldDecorator("commission", {
                  rules: [
                    {
                      pattern: /^99$|^(\d|[1-9]\d)(\.\d{1,5})*$/,
                      message: "仅能输入数字，0<=n< 100，小数点后最多保留5位"
                    },
                    { required: true, message: "请输入佣金比例" }
                  ]
                })(
                  <Input
                    suffix="%"
                    placeholder={"佣金比例"}
                  />
                )}
              </Form.Item >
            </Col>
          </Row>
        </div>
        <div>
          <Row gutter={24}>
            <Col className="gutter-row" span={12}>
              <Form.Item label="经营模式">
                {getFieldDecorator("managementModel", {
                  rules: [
                    {
                      required: true,
                      message: "请选择"
                    }
                  ]
                })(
                  <RadioGroup
                    onChange={this.onModeChange}
                    value={this.state.modeValue}
                  >
                    {ManagementModel.map(item => {
                      return (
                        <Radio key={item.value} value={item.value}>
                          {item.label}
                        </Radio>
                      );
                    })}
                  </RadioGroup>
                )}
              </Form.Item>
            </Col>
          </Row>
        </div>
      </TitlePanel>
    );
  }

  onModeChange = e => {
    console.log("radio checked", e.target.value);
    this.setState({
      modeValue: e.target.value
    });
  };

  addErrorInfoDialog(errorTitle, errorMsg, okCallback) {
    return (
      <Modal
        title={errorTitle}
        visible={true}
        footer={[
          <Button
            key="primary"
            style={{ float: "none" }}
            type="primary"
            onClick={okCallback}
            size="large"
          >
            我知道了
          </Button>
        ]}
        closable={false}
        maskClosable={false}
        width={400}
        onOk={okCallback}
      >
        <div>{errorMsg}</div>
      </Modal>
    );
  }

  render() {
    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      isFieldTouched
    } = this.props.form;

    let my = this;
    let myState = this.state;

    return (
      <Content className="upgrade-detail-content">
        <div className="upgrade-detail-body flexbox">
          <Breadcrumb className="customer-detail-head-bread">
            <Breadcrumb.Item>
              <Link style={{ color: "#333" }} to={"/app/crm/client"}>
                客户管理
              </Link>
            </Breadcrumb.Item>

            <Breadcrumb.Item>
              <Link
                style={{ color: "#333" }}
                to={"/app/crm/client/newdetail/" + my.props.match.params.encode}
              >
                客户详情
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>企业账号升级</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        {/*   顶部选择器  */}
        <span className="my_btns">
          <span style={{ marginRight: "15px" }}>升级为:</span>
          {myState.isShowSupplierButon ? (
            <Checkbox
              onChange={e => {
                my.switchIsSupplier(e);
              }}
            >
              {" "}
              供应商
            </Checkbox>
          ) : (
              ""
            )}
          {myState.isShowGardenButton ? (
            <Checkbox
              onChange={e => {
                my.switchIsGrader(e);
              }}
            >
              {" "}
              园区方
            </Checkbox>
          ) : (
              ""
            )}
        </span>

        <Form onSubmit={this.handleSubmit}>
          {myState.isShowSupplierForm ? my.createSupplierForm() : ""}

          {myState.isShowGraderForm ? my.createGraderForm() : ""}

          <div className="btn-group">
            <Button type="primary" htmlType="submit">
              提交
            </Button>
            <Button onClick={this.cancel} size="large">
              取消
            </Button>
          </div>
        </Form>

        {myState.errorMsg
          ? this.addErrorInfoDialog(myState.errorTitle, myState.errorMsg, () =>
            this.goback()
          )
          : ""}
      </Content>
    );
  }
}

export default Form.create({ name: "EnterpriseUpgrade" })(EnterpriseUpgrade);
