import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom';
import { Form, Input, Button, Select, Row, Col, DatePicker, message, Modal, Icon, Checkbox } from 'antd'
import moment from 'moment';
import userUtil from '@/utils/user'
import { AUTH_KEYS } from '@/utils/auth'
import http from '@/http'
import { API } from '@/http/api'
import { changeObjkey } from '@/utils'
import AuthContainer from 'CMPT/common/auth/AuthContainer';
import BreadcrumbHeader from 'CMPT/common/breadcrumb/breadcrumb'
import BsCascader from 'CMPT/common/Cascader'
import MultiCascader from 'CMPT/common/MultiCascader';
import Table from 'CMPT/common/Table'
import LogisticsTabnums from 'CMPT/common/tabnums/tabnums'
import SendOrder from './sendorder'
import AcceptanceOrder from './acceptanceorder'
import CostOrder from './costorder'
import CancelOrder from './cancelorder'
import './maintain.less'

const { RangePicker } = DatePicker;
const { Option }= Select;
const { Search } = Input;
const keyMapCascader = {key:'value',value:'label'};
const FormatSet = 'YYYY-MM-DD HH:mm:ss';

class OperateMaintainform extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sendModelstatus: false,
            acceptanceModelstatus: false,
            costorderModelstatus: false,
            cancelModelstatus: false,
            optionstimeTypeList: [
                {
                    label: '预计处理时间',
                    value: 1
                },
                {
                    label: '完成时间',
                    value: 2
                },
                {
                    label: '上报时间',
                    value: 3
                }
            ],
            tabnums: [{
                name: '全部',
                value: -1,
                num: 0
            }],
            projects: [],
            projectschildren: [],
            urgent: [],
            service: [],
            columns: [
                {
                    title: '工单编号',
                    dataIndex: 'key',
                    fixed: 'left',
                    width: 150,
                    render: (text, row) => (
                        <span className="table-operate-btn color" onClick={this.operateID.bind(this, text, row)}>{text}</span>
                    )
                },{
                    title: '项目-房源',
                    dataIndex: 'projectList',
                    width: 200
                },{
                    title: '维修类型',
                    dataIndex: 'bizTypetext',
                    width: 200
                },{
                    title: '紧急程度',
                    dataIndex: 'urgentLeveltext',
                    width: 120
                },{
                    title: '地址',
                    dataIndex: 'address',
                    width: 250
                },{
                    title: '工单状态',
                    dataIndex: 'statustext',
                    width: 120
                },{
                    title: '上报人信息',
                    dataIndex: 'applicant', //applicantName + mobile
                    width: 120
                },{
                    title: '上报时间',
                    dataIndex: 'createTime',
                    width: 180
                },{
                    title: '受理人信息',
                    dataIndex: 'labor', //laborUserName + laborMobile
                    width: 120
                },{
                    title: '预计处理时间',
                    dataIndex: 'processTime',
                    width: 180
                },{
                    title: '完成时间',
                    dataIndex: 'completedTime',
                    width: 180
                },{
                    title: '操作',
                    dataIndex: 'operate',
                    fixed: 'right',
                    width: 180,
                    render: (text, row) => (
                        <span>
                            {text.map(per => {
                                return <span className="table-operate-btn" key={per} onClick={this.operate.bind(this, per, row)}>{per}</span>
                            })}
                        </span>
                    )
                }
            ],
            tabledata: [],
            tabletotal: 0,
            searchObj: {
                bizType: null, // 维修类型（1级大类(1-屋内设施、2-公共设施、3-耗材))
                type: null, // 维修类型（2级用户自定义 ）
                completedTimeEnd: null, // 处理完成时间结束 yyyy-MM-dd HH:mm:ss
                completedTimeStart: null, // 处理完成时间开始 yyyy-MM-dd HH:mm:ss
                createTimeEnd: null, // 上报时间结束 yyyy-MM-dd HH:mm:ss
                createTimeStart: null, // 上报时间开始 yyyy-MM-dd HH:mm:ss
                processTimeEnd: null, // 预计处理时间结束 yyyy-MM-dd HH:mm:ss
                processTimeStart: null, // 预计处理时间开始 yyyy-MM-dd HH:mm:ss
                fuzzyKey: null, // 模糊，工单ID/地址/上报人姓名/上报人手机号/维修工姓名/维修工手机号
                projectEncode: null, // 项目code
                zoneEncodeList: null, // 房间code list
                status: this.props.location.hash ? Number(this.props.location.hash.substr(1)) : null, // 状态（1待派单、2待处理、3处理中、4待验收、5已完成、0已取消）
                timeoutFlag: null, // 只看超时工单 0否 1是
                urgentLevel: null, // 紧急程度 （1紧急、2一般）
                orderByList : null, //  排序 1创建时间正序、2创建时间倒序、3最后更新时间正序、4最后更新时间倒序、5名称正序 6名称倒序 7预计处理时间正序、8预计处理时间倒序、9订单ID正序、10订单ID倒序
                pageNo: 1, // 当前页数
                pageSize: 10 // 每页条数
            },
            defaultObj: {
                fuzzyKey: null,
                bizType: null,
                projectEncode: undefined,
                zoneEncodeList: [],
                urgentLevel: undefined,
                timeSetFlag: undefined,
                momentTime: null,
                timeoutFlag: null,
                status: '-1'
            },
            sendorderdata: {},
            acceptanceorder: {},
            cancelorder: {},
            servicerList: [],
            currentCode: null,
            currentStatus: null,
            result: [],
            costtypeLists: [],
            bearerLists: []
        }
    }
    //tabs选中哪一项
    getnumindex(val) {
        const searchObj = this.state.searchObj;
        const defaultObj = this.state.defaultObj;
        searchObj.status = Number(val) === -1 ? null : Number(val);
        searchObj.pageNo = 1;
        defaultObj.status = val;
        setTimeout(()=>{
            this.setState({
                searchObj,
                defaultObj
            })
        },0);
        this.postitemList(searchObj)
    }
    //模糊搜索
    handleSearch(val) {
        const searchObj = this.state.searchObj;
        searchObj.fuzzyKey = val === '' ? null : val;
        searchObj.pageNo = 1;
        setTimeout(()=>{
            this.setState({
                searchObj
            })
        },0);
        this.postitemList(searchObj)
    }
    handleChange(event) {
        const val = event.target.value;
        const defaultObj = this.state.defaultObj;
        defaultObj.fuzzyKey = val;

        this.setState({
            defaultObj
        });

        if (val === '' && event.type === 'click') this.handleSearch(null)
    }
    //选择维修类型某项
    chooseService(val) {
        const searchObj = this.state.searchObj;
        const defaultObj = this.state.defaultObj;
        searchObj.bizType = val[0];
        searchObj.type = val[1];
        searchObj.pageNo = 1;
        defaultObj.bizType = val;
        setTimeout(()=>{
            this.setState({
                searchObj,
                defaultObj
            })
        },0);
        this.postitemList(searchObj)
    }
    //选择项目-房源某项
    chooseProject(val) {
        const searchObj = this.state.searchObj;
        const defaultObj = this.state.defaultObj;
        searchObj.projectEncode = val;
        searchObj.pageNo = 1;
        defaultObj.projectEncode = val;
        setTimeout(()=>{
            this.setState({
                searchObj,
                defaultObj
            })
        },0);
        if (val) this.loadDataProject(val);
        this.postitemList(searchObj)
    }
    chooseProjectchildren(val) {
        const searchVal = val && val.map(item => item.id);
        const searchObj = this.state.searchObj;
        const defaultObj = this.state.defaultObj;
        searchObj.zoneEncodeList = searchVal;
        searchObj.pageNo = 1;
        defaultObj.zoneEncodeList = searchVal;
        setTimeout(()=>{
            this.setState({
                searchObj,
                defaultObj
            })
        },0);
        this.postitemList(searchObj)
    }
    //选择紧急程度某项
    chooseUrgent(val) {
        const searchObj = this.state.searchObj;
        const defaultObj = this.state.defaultObj;
        searchObj.urgentLevel = Number(val);
        searchObj.pageNo = 1;
        defaultObj.urgentLevel = val;
        setTimeout(()=>{
            this.setState({
                searchObj,
                defaultObj
            })
        },0);
        this.postitemList(searchObj)
    }
    //选择处理时间类型
    choosedateType(val) {
        const defaultObj = this.state.defaultObj;
        const searchObj = this.state.searchObj;
        const timeFirst = searchObj.processTimeStart || searchObj.completedTimeStart || searchObj.createTimeStart;
        const timeSecond = searchObj.processTimeEnd || searchObj.completedTimeEnd || searchObj.createTimeEnd;

        defaultObj.timeSetFlag = val;
        setTimeout(()=>{
            this.setState({
                defaultObj
            })
        },0);

        if (timeFirst || timeSecond) {
            searchObj.processTimeStart = null;
            searchObj.completedTimeStart = null;
            searchObj.createTimeStart = null;
            searchObj.processTimeEnd = null;
            searchObj.completedTimeEnd = null;
            searchObj.createTimeEnd = null;
            searchObj.pageNo = 1;
            switch (Number(val)) {
                case 1: // 预计处理时间
                    searchObj.processTimeStart = timeFirst;
                    searchObj.processTimeEnd = timeSecond;
                    break;
                case 2: // 完成时间
                    searchObj.completedTimeStart = timeFirst;
                    searchObj.completedTimeEnd = timeSecond;
                    break;
                case 3: // 上报时间
                    searchObj.createTimeStart = timeFirst;
                    searchObj.createTimeEnd = timeSecond;
                    break;
                default:
                    break
            }
            setTimeout(()=>{
                this.setState({
                    searchObj
                })
            },0);

            this.postitemList(searchObj)
        }

    }
    //改变上报时间
    onChangedate(date) {
        const timeSetFlag = this.state.defaultObj.timeSetFlag;

        if (timeSetFlag) {
            const searchObj = this.state.searchObj;
            const defaultObj = this.state.defaultObj;
            const timeFirst = date[0] ? date[0].format('YYYY-MM-DD') : null;
            const timeSecond = date[1] ? date[1].format('YYYY-MM-DD') : null;

            searchObj.processTimeStart = null;
            searchObj.completedTimeStart = null;
            searchObj.createTimeStart = null;
            searchObj.processTimeEnd = null;
            searchObj.completedTimeEnd = null;
            searchObj.createTimeEnd = null;
            searchObj.pageNo = 1;
            defaultObj.momentTime = date;

            switch (Number(timeSetFlag)) {
                case 1: // 预计处理时间
                    searchObj.processTimeStart = timeFirst;
                    searchObj.processTimeEnd = timeSecond;
                    break;
                case 2: // 完成时间
                    searchObj.completedTimeStart = timeFirst;
                    searchObj.completedTimeEnd = timeSecond;
                    break;
                case 3: // 上报时间
                    searchObj.createTimeStart = timeFirst;
                    searchObj.createTimeEnd = timeSecond;
                    break;
                default:
                    break
            }
            setTimeout(()=>{
                this.setState({
                    searchObj,
                    defaultObj
                })
            },0);

            this.postitemList(searchObj)
        } else {
            message.warning('请先选择处理时间类型');
        }
    }
    //仅显示超时
    onshowovertime(event){
        const val = event.target.checked ? 1 : null;
        const searchObj = this.state.searchObj;
        const defaultObj = this.state.defaultObj;
        searchObj.timeoutFlag = val;
        searchObj.pageNo = 1;
        defaultObj.timeoutFlag = val;
        setTimeout(()=>{
            this.setState({
                searchObj,
                defaultObj
            })
        },0);
        this.postitemList(searchObj)
    }
    //点击重置
    clickreaset() {
        const defaultObjList = this.state.defaultObj;
        const defaultObj = {
            fuzzyKey: null,
            bizType: null,
            projectEncode: undefined,
            zoneEncodeList: [],
            urgentLevel: undefined,
            timeSetFlag: undefined,
            momentTime: null,
            timeoutFlag: null,
            status: '-1'
        };
        if (JSON.stringify(defaultObjList) !== JSON.stringify(defaultObj)) {
            const searchObj = {
                bizType: null, // 维修类型（1级大类(1-屋内设施、2-公共设施、3-耗材))
                type: null, // 维修类型（2级用户自定义 ）
                completedTimeEnd: null, // 处理完成时间结束 yyyy-MM-dd HH:mm:ss
                completedTimeStart: null, // 处理完成时间开始 yyyy-MM-dd HH:mm:ss
                createTimeEnd: null, // 上报时间结束 yyyy-MM-dd HH:mm:ss
                createTimeStart: null, // 上报时间开始 yyyy-MM-dd HH:mm:ss
                processTimeEnd: null, // 预计处理时间结束 yyyy-MM-dd HH:mm:ss
                processTimeStart: null, // 预计处理时间开始 yyyy-MM-dd HH:mm:ss
                fuzzyKey: null, // 模糊，工单ID/地址/上报人姓名/上报人手机号/维修工姓名/维修工手机号
                projectEncode : null, // 项目id
                zoneEncodeList: null, // 房间id
                status: null, // 状态（1待派单、2待处理、3处理中、4待验收、5已完成、0已取消）
                timeoutFlag: null, // 只看超时工单 0否 1是
                urgentLevel: null, // 紧急程度 （1紧急、2一般）
                orderByList : null, //  排序 1创建时间正序、2创建时间倒序、3最后更新时间正序、4最后更新时间倒序、5名称正序 6名称倒序 7预计处理时间正序、8预计处理时间倒序、9订单ID正序、10订单ID倒序
                pageNo: 1, // 当前页数
                pageSize: 10 // 每页条数
            };

            setTimeout(()=>{
                this.setState({
                    searchObj,
                    defaultObj,
                    projectschildren: []
                })
            },0);
            this.postitemList(searchObj)
        }
    }
    //下一页 改变table页码
    onChangetable(pageNo,pageSize) {
        const searchObj = this.state.searchObj;
        searchObj.pageNo = Number(pageNo);
        searchObj.pageSize = Number(pageSize);

        setTimeout(()=>{
            this.setState({
                searchObj
            })
        },0);
        this.postitemList(searchObj)
    }
    //点击工单跳转详情
    operateID(data, row) {
        this.props.history.push({
            pathname:"/app/operate/serviceCenter/logistics/maintain/orderDetail",
            hash: `#${row.encode}`
        })
    }
    //点击派单 验收等按钮
    operate(str,item) {
        //将所选的当前列表code取出
        setTimeout(()=>{
            this.setState({
                currentCode: item.encode
            })
        },0)
        //根据不同操作进行不同方法
        switch (str) {
            case '派单':
            case '重新派单':
                this.showsendModal(item.projectVo.encode);
                break;
            case '验收':
                this.showacceptanceModal();
                break;
            case '费用清单':
                setTimeout(()=>{
                    this.setState({
                        currentStatus: item.status
                    })
                },0)
                this.showcostorderModal();
                break;
            case '取消工单':
                this.showcancelModal();
                break;
            default:
                break
        }
    }
    //派单
    handlesendOk(event) {
        event.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const dataObj = {
                    encodeList: [this.state.currentCode],
                    userEncode: this.state.sendorderdata.sendOrder,
                    userName: this.state.sendorderdata.userName,
                    remark: this.state.sendorderdata.remarks
                };
                this.showsendModal();
                this.postsendDateSave(dataObj)
            }
        });
    }
    async showsendModal(projectEncode) {
        if (!this.state.sendModelstatus) await this.postservicerList({projectEncode}); //请求维修工list

        this.setState({
            sendModelstatus: !this.state.sendModelstatus
        });
    }
    changesendModal(type,val,option) {
        const sendorderdata = this.state.sendorderdata;
        let targetVal = null;
        if (val && val.target) targetVal = val.target.value;

        switch (type) {
            case 1:
                sendorderdata.sendOrder = val;
                sendorderdata.userName = this.state.servicerList.filter(item => item.value === val)[0].userName
                break;
            case 2:
                sendorderdata.remarks = targetVal;
                break;
            default:
                break
        }

        this.setState({
            sendorderdata
        })
    }
    //验收
    handleacceptanceOk(event) {
        event.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const acceptanceorder = this.state.acceptanceorder;
                this.setState({
                    acceptanceorder: {}
                });
                const dataObj = changeObjkey(acceptanceorder,{Accresult:'acceptorResultType',rate:'acceptorRank',remarks:'acceptorRemark',reason:'acceptorReason'});
                dataObj.encodeList = [this.state.currentCode];
                this.showacceptanceModal();
                this.postaccepDateSave(dataObj)
            }
        });
    }
    showacceptanceModal() {
        this.setState({
            acceptanceModelstatus: !this.state.acceptanceModelstatus
        });
    }
    changeacceptanceModal(type,val,option) {
        const acceptanceorder = this.state.acceptanceorder;
        let targetVal = null;
        if (val && val.target) targetVal = val.target.value;

        switch (type) {
            case 1:
                acceptanceorder.Accresult = Number(val);
                break;
            case 2:
                acceptanceorder.rate = Number(val);
                break;
            case 3:
                acceptanceorder.remarks = targetVal;
                break;
            case 4:
                acceptanceorder.reason = targetVal;
                break;
            default:
                break
        }

        this.setState({
            acceptanceorder
        })
    }
    //费用清单
    showcostorderModal() {
        this.setState({
            costorderModelstatus: !this.state.costorderModelstatus
        });
    }
    //取消工单
    handlecancelOk(event) {
        event.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const dataObj = changeObjkey(this.state.cancelorder,{cancelresult:'cancelReason'});
                dataObj.encodeList = [this.state.currentCode];
                this.showcancelModal();
                this.postcancelDateSave(dataObj)
            }
        });
    }
    showcancelModal() {
        this.setState({
            cancelModelstatus: !this.state.cancelModelstatus
        });
    }
    changecancelModal(type,val,option) {
        const cancelorder = this.state.cancelorder;
        let targetVal = null;
        if (val && val.target) targetVal = val.target.value;

        switch (type) {
            case 1:
                cancelorder.cancelresult = targetVal;
                break;
            default:
                break
        }

        this.setState({
            cancelorder
        })
    }
    //处理options数据类型
    orderOptionsList(options) {
        return this.state[options].map(d => <Option key={d.value}>{d.label}</Option>)
    }

    componentWillMount() {
        this.getmaintainInit();
    }

    //请求初始化枚举
    getmaintainInit() {
        const tabnums = this.state.tabnums;
        const config = {
            api: API.maintainInit
        }
        http(config).then(result => {
            const { data } = result;
            let { maintainTypeEnum, urgentLevelEnum, maintainStatusEnum, orderCheckTypeEnum, feeTypeEnum, payTypeEnum } = data;

            //工单状态
            maintainStatusEnum = maintainStatusEnum.map(item => changeObjkey(item,{key:'value',value:'name'}));

            //维修服务
            maintainTypeEnum = maintainTypeEnum.map(item => {
                const list = changeObjkey(item,keyMapCascader);
                this.loadDataService(item.key,list);
                return list
            });

            //紧急程度
            urgentLevelEnum = urgentLevelEnum.map(item => changeObjkey(item,keyMapCascader));

            //通过不通过
            orderCheckTypeEnum = orderCheckTypeEnum.map(item => changeObjkey(item,{key:'value',value:'name'}));

            //费用类型
            feeTypeEnum = feeTypeEnum.map(item => changeObjkey(item,{key:'value',value:'name'}));

            //承担方类型
            payTypeEnum = payTypeEnum.map(item => changeObjkey(item,{key:'value',value:'name'}));

            setTimeout(()=>{
                this.setState({
                    tabnums: tabnums.concat(maintainStatusEnum),
                    service: maintainTypeEnum,
                    urgent: urgentLevelEnum,
                    result: orderCheckTypeEnum,
                    costtypeLists: feeTypeEnum,
                    bearerLists: payTypeEnum
                })
            },0);

            //工单状态数量查询
            this.postitemStatuscount({},tabnums.concat(maintainStatusEnum));
            //请求项目列表
            this.getitemInit();
        })

    }
    //请求初始化的项目
    getitemInit() {
        const config = {
            api: API.commonProjectfilter
        };
        http(config).then(result => {
            const { data } = result;
            const json = data.map(item => changeObjkey(item,{encode:'value',name:'label'},true));

            this.setState({
                projects: json
            })
        })
    }
    //请求工单状态数量
    postitemStatuscount(obj,tablists) {
        const config = {
            api: API.maintainStatusCount,
            data: obj
        };

        http(config).then(result => {
            const { data } = result;
            const tabnums = tablists || this.state.tabnums;
            tabnums.map(item => {
                data.forEach(map => {
                    if (String(item.value) === String(map.key)) {
                        item.num = map.value
                    }
                })
                return item
            })

            this.setState({
                tabnums
            })

            this.postitemList(this.state.searchObj)
        })
    }
    //请求工单list
    postitemList(data) {
        const config = {
            api:API.maintainList,
            data: data,
            showLoading: true
        };

        http(config).then(result => {
            const { data } = result;
            const { datas, total } = data;

            const tabledata = datas.map(item => {
                const list = changeObjkey(item,{code:'key'});
                const statustextName = this.state.tabnums.filter(itemkey => Number(itemkey.value) === Number(item.status))[0].name;
                let operate = [],statustext = '';
                list.applicant = item.applicantName ? <div><div>{item.applicantName}</div><div>{item.mobile}</div></div> : '-';
                list.labor = item.laborUserName ? <div><div>{item.laborUserName}</div><div>{item.laborMobile}</div></div> : '-';
                if (item.urgentLevel) list.urgentLeveltext = Number(item.urgentLevel) === 1 ?
                    <div><span style={{color: '#E94437', marginRight: '6px'}}>●</span>紧急</div>
                    :
                    <div><span style={{color: '#E94437', marginRight: '6px'}}>●</span>一般</div>;
                if (item.createTime) list.createTime = moment(list.createTime).format(FormatSet);
                if (item.processTime) list.processTime = moment(list.processTime).format(FormatSet);
                if (item.completedTime) list.completedTime = moment(list.completedTime).format(FormatSet);
                if (item.bizType) {
                    const serviceFirst = this.state.service.filter(itemkey => Number(itemkey.value) === Number(item.bizType))[0];
                    const serviceSecond = serviceFirst.children.filter(itemkey => Number(itemkey.value) === Number(item.type))[0];
                    list.bizTypetext = `${serviceFirst.label}${serviceSecond ? '/' + serviceSecond.label : ''}`
                }
                if (item.projectVo) {
                    const projectEncodeFirst = item.projectVo && item.projectVo.name;
                    const projectEncodeSecond = item.projectVo.floors && item.projectVo.floors[0].name;
                    const projectEncodeThird = item.projectVo.floors[0].zones && item.projectVo.floors[0].zones[0].zoneNo;
                    list.projectList = `${projectEncodeFirst}-${projectEncodeSecond}-${projectEncodeThird}`
                }

                switch (Number(item.status)) {
                    case 1:
                        operate = [
                            userUtil.hasAuth(AUTH_KEYS.OPERATE_SERVICE_LOGISTICS_MAINTAIN_SENDORDER) && (item.dispatchFlag === 1 ? '重新派单' : '派单'),
                            userUtil.hasAuth(AUTH_KEYS.OPERATE_SERVICE_LOGISTICS_MAINTAIN_COSTORDER) && '费用清单',
                            userUtil.hasAuth(AUTH_KEYS.OPERATE_SERVICE_LOGISTICS_MAINTAIN_CANCELORDER) && '取消工单'
                        ];
                        statustext = <div><span style={{color: '#FBBC06', marginRight: '6px'}}>●</span>{statustextName}<span>{item.timeoutFlag === 1 ? (<i style={{color: '#FBBC06', marginLeft: '4px', verticalAlign: 'middle'}} className={'icon iconfont iconsign_warning'} />) : null}</span></div>;
                        break;
                    case 2:
                        operate = [
                            userUtil.hasAuth(AUTH_KEYS.OPERATE_SERVICE_LOGISTICS_MAINTAIN_COSTORDER) && '费用清单',
                            userUtil.hasAuth(AUTH_KEYS.OPERATE_SERVICE_LOGISTICS_MAINTAIN_CANCELORDER) && '取消工单'
                        ];
                        statustext = <div><span style={{color: '#FBBC06', marginRight: '6px'}}>●</span>{statustextName}<span>{item.timeoutFlag === 1 ? (<i style={{color: '#FBBC06', marginLeft: '4px', verticalAlign: 'middle'}} className={'icon iconfont iconsign_warning'} />) : null}</span></div>;
                        break;
                    case 3:
                        operate = [
                            userUtil.hasAuth(AUTH_KEYS.OPERATE_SERVICE_LOGISTICS_MAINTAIN_COSTORDER) && '费用清单',
                            userUtil.hasAuth(AUTH_KEYS.OPERATE_SERVICE_LOGISTICS_MAINTAIN_CANCELORDER) && '取消工单'
                        ];
                        statustext = <div><span style={{color: '#FBBC06', marginRight: '6px'}}>●</span>{statustextName}<span>{item.timeoutFlag === 1 ? (<i style={{color: '#FBBC06', marginLeft: '4px', verticalAlign: 'middle'}} className={'icon iconfont iconsign_warning'} />) : null}</span></div>;
                        break;
                    case 4:
                        operate = [
                            userUtil.hasAuth(AUTH_KEYS.OPERATE_SERVICE_LOGISTICS_MAINTAIN_ACCEPTANCEORDER) && '验收',
                            userUtil.hasAuth(AUTH_KEYS.OPERATE_SERVICE_LOGISTICS_MAINTAIN_COSTORDER) && '费用清单',
                            userUtil.hasAuth(AUTH_KEYS.OPERATE_SERVICE_LOGISTICS_MAINTAIN_CANCELORDER) && '取消工单'
                        ];
                        statustext = <div><span style={{color: '#FBBC06', marginRight: '6px'}}>●</span>{statustextName}<span>{item.timeoutFlag === 1 ? (<i style={{color: '#FBBC06', marginLeft: '4px', verticalAlign: 'middle'}} className={'icon iconfont iconsign_warning'} />) : null}</span></div>;
                        break;
                    case 5:
                        operate = [
                            userUtil.hasAuth(AUTH_KEYS.OPERATE_SERVICE_LOGISTICS_MAINTAIN_COSTORDER) && '费用清单',
                        ];
                        statustext = <div><span style={{color: '#E94437', marginRight: '6px'}}>●</span>{statustextName}<span>{item.timeoutFlag === 1 ? (<i style={{color: '#FBBC06', marginLeft: '4px', verticalAlign: 'middle'}} className={'icon iconfont iconsign_warning'} />) : null}</span></div>;
                        break;
                    case 6:
                        operate = [
                            userUtil.hasAuth(AUTH_KEYS.OPERATE_SERVICE_LOGISTICS_MAINTAIN_COSTORDER) && '费用清单',
                        ];
                        statustext = <div><span style={{color: '#333', marginRight: '6px'}}>●</span>{statustextName}<span>{item.timeoutFlag === 1 ? (<i style={{color: '#FBBC06', marginLeft: '4px', verticalAlign: 'middle'}} className={'icon iconfont iconsign_warning'} />) : null}</span></div>;
                        break;
                    default:
                        operate = [];
                        statustext = '';
                        break
                }
                list.operate = operate;
                list.statustext = statustext;

                return list
            })

            this.setState({
                tabletotal: total,
                tabledata
            })
        })
    }
    //请求维修工list
    postservicerList(data) {
        const config = {
            api: API.maintainGetLaborList,
            data
        }
        http(config).then(result => {
            const { data } = result;
            const servicerList = data && data.map(item => {
                const list = changeObjkey(item,{encode:'value'});
                list.name = <Row><Col span={4}>{item.name}</Col><Col span={8}>{item.mobile}</Col><Col span={8}>待维修共{item.wppCount}单</Col></Row>;
                list.userName = item.name;
                return list
            })
            setTimeout(()=>{
                this.setState({
                    servicerList
                })
            },0)
        })
    }
    //请求服务类型二级
    loadDataService(selectedOptions,obj) {
        const url = Number(selectedOptions) === 1 ? API.sysconfigListRoomEquipment : API.sysconfigListPublicEquipment;
        const config = {
            api: url
        };

        http(config).then(result => obj.children = result.data && result.data.map(item => changeObjkey(item,{id:'value',name:'label'},true)))
    }
    //请求房源项目二级
    loadDataProject(selectedOptions) {
        const config = {
            api: API.commonFloorZoneFilter,
            data: {
                projectEncode: selectedOptions
            }
        };

        http(config).then(result => {
            const { data } = result;
            const projectschildren = [];
            data && data.length > 0 && data.forEach(item => {
                if (item.zones && item.zones.length > 0) {
                    const list = changeObjkey(item,{encode:'id',floorNo:'no',name:'name'},true);
                    list.child = item.zones.map(itemChild => changeObjkey(itemChild,{zoneNo:'name',encode:'id',floorEncode:'parentId'},true));
                    projectschildren.push(list)
                }
            });

            setTimeout(()=>{
                this.setState({
                    projectschildren
                })
            },0)
        })
    }
    //请求派单保存
    postsendDateSave(data) {
        const config = {
            api: API.maintainDispatchDone,
            data: {
                ...data
            },
            showLoading: true
        };
        const searchObj = this.state.searchObj;
        searchObj.pageNo = 1;
        http(config).then(result => result.success && (message.success(`派单成功`) && this.postitemStatuscount({})))
    }
    //请求验收保存
    postaccepDateSave(data) {
        const config = {
            api: API.maintainAcceptanceDone,
            data: {
                ...data
            },
            showLoading: true
        };
        const searchObj = this.state.searchObj;
        searchObj.pageNo = 1;
        http(config).then(result => result.success && (message.success(`操作成功`) && this.postitemStatuscount({})))
    }
    //请求取消工单
    postcancelDateSave(data) {
        const config = {
            api: API.maintainCancel,
            data: {
                ...data
            },
            showLoading: true
        };
        const searchObj = this.state.searchObj;
        searchObj.pageNo = 1;
        http(config).then(result => result.success && (message.success(`取消成功`) && this.postitemStatuscount({})))
    }

    render() {
        const breadlist = [{name:'维修服务'}];
        const addorderButton =  <AuthContainer authCodes={AUTH_KEYS.OPERATE_SERVICE_LOGISTICS_MAINTAIN_ADDORDER}>
            <Button type="primary" className='new-role-btn' >
                <Link to='/app/operate/serviceCenter/logistics/maintain/addorder'>新增工单</Link>
            </Button>
        </AuthContainer>;
        const optionsLevel = this.orderOptionsList('urgent');
        const optionstimeType = this.orderOptionsList('optionstimeTypeList');
        const optionProjects = this.orderOptionsList('projects');
        const { tabnums, defaultObj, service, columns, tabledata, tabletotal, sendModelstatus, servicerList, acceptanceModelstatus, costorderModelstatus, cancelModelstatus, searchObj } = this.state;

        return (
            <div className='OperateMaintain'>
                <BreadcrumbHeader breadlist={breadlist} buttonobj={addorderButton}></BreadcrumbHeader>
                <div className='OperateMaintainnav'>
                    <LogisticsTabnums
                        nums={tabnums}
                        activeKey={this.props.location.hash ? this.props.location.hash.substr(1) : defaultObj.status}
                        isminWidth={true}
                        getnumindex={this.getnumindex.bind(this)}>
                    </LogisticsTabnums>
                </div>
                <div className='OperateMaintaincontent'>
                    <Row className='OperateMaintaincontent-select'>
                        <Col span={6}>
                            <Search
                                style={{width: '100%'}}
                                allowClear
                                placeholder="工单编号/地址/上报人信息/受理人信息"
                                value={defaultObj.fuzzyKey}
                                onChange={this.handleChange.bind(this)}
                                onSearch={this.handleSearch.bind(this)}
                            />
                        </Col>
                        <Col span={3}>
                            <BsCascader
                                options={service}
                                placeholder="维修服务/公共"
                                value={defaultObj.bizType}
                                onChange={this.chooseService.bind(this)}>
                            </BsCascader>
                        </Col>
                        <Col span={3}>
                            <Select style={{width: '100%'}}
                                    placeholder="请选择项目"
                                    allowClear
                                    value={defaultObj.projectEncode}
                                    suffixIcon={<Icon type="caret-down" />}
                                    onChange={this.chooseProject.bind(this)}>
                                {optionProjects}
                            </Select>
                        </Col>
                        <Col span={3}>
                            <MultiCascader
                                    style={{ width: '100%' }}
                                    placeholder="请选择房源"
                                    value={defaultObj.zoneEncodeList}
                                    data={this.state.projectschildren}
                                    noShowArea={true}
                                    onChange={this.chooseProjectchildren.bind(this)}>
                            </MultiCascader>
                        </Col>
                        <Col span={3}>
                            <Select style={{width: '100%'}}
                                    placeholder="紧急程度"
                                    allowClear
                                    value={defaultObj.urgentLevel}
                                    suffixIcon={<Icon type="caret-down" />}
                                    onChange={this.chooseUrgent.bind(this)}>
                                {optionsLevel}
                            </Select>
                        </Col>
                    </Row>
                    <Row className='OperateMaintaincontent-select special'>
                        <Col span={2}>
                            <Select style={{width: '100%'}}
                                    placeholder="处理时间类型"
                                    value={defaultObj.timeSetFlag}
                                    suffixIcon={<Icon type="caret-down" />}
                                    onSelect={this.choosedateType.bind(this)}>
                                {optionstimeType}
                            </Select>
                        </Col>
                        <Col span={5}>
                            <RangePicker onChange={this.onChangedate.bind(this)}
                                         style={{width: '100%'}}
                                         value={defaultObj.momentTime} />
                        </Col>
                        <Col span={2} style={{width: 130}}><Checkbox onChange={this.onshowovertime.bind(this)} checked={defaultObj.timeoutFlag}>仅显示超时工单</Checkbox></Col>
                        <Col span={2} style={{marginLeft: 0}}><span style={{cursor: 'pointer',fontSize: '12px',textDecoration:'underline',textUnderlinePosition: 'under'}} onClick={this.clickreaset.bind(this)}>重置</span></Col>
                    </Row>
                    <div className='OperateMaintaintable-body contract-table'>
                        <Table
                            scroll={{ x: 1950 }}
                            columns={columns}
                            data={tabledata}
                            total={tabletotal}
                            current={searchObj.pageNo}
                            change={this.onChangetable.bind(this)} />
                    </div>
                </div>

                <div>
                    <Modal wrapClassName='Operate-sendorder'
                           title='派单'
                           destroyOnClose={true}
                           visible={sendModelstatus}
                           onCancel={this.showsendModal.bind(this)}
                           onOk={this.handlesendOk.bind(this)}>
                        <SendOrder  urgent={servicerList}
                                    form={this.props.form}
                                    chooseProject={this.changesendModal.bind(this)}>
                        </SendOrder>
                    </Modal>
                    <Modal wrapClassName='Operate-acceptanceorder'
                           title='验收'
                           destroyOnClose={true}
                           visible={acceptanceModelstatus}
                           onCancel={this.showacceptanceModal.bind(this)}
                           onOk={this.handleacceptanceOk.bind(this)}>
                        <AcceptanceOrder encode={this.state.currentCode}
                                         form={this.props.form}
                                         Accresult={this.state.acceptanceorder.Accresult}
                                         result={this.state.result}
                                         chooseProject={this.changeacceptanceModal.bind(this)}>
                        </AcceptanceOrder>
                    </Modal>
                    <Modal wrapClassName='Operate-costorder'
                           title='费用清单'
                           destroyOnClose={true}
                           visible={costorderModelstatus}
                           onCancel={this.showcostorderModal.bind(this)}
                           onOk={this.showcostorderModal.bind(this)}>
                        <CostOrder status={this.state.currentStatus}
                                   encode={this.state.currentCode}
                                   from={'list'}
                                   costtypeLists={this.state.costtypeLists}
                                   bearerLists={this.state.bearerLists}>
                        </CostOrder>
                    </Modal>
                    <Modal wrapClassName='Operate-cancel'
                           title='取消工单'
                           destroyOnClose={true}
                           visible={cancelModelstatus}
                           onCancel={this.showcancelModal.bind(this)}
                           onOk={this.handlecancelOk.bind(this)}>
                        <CancelOrder encode={this.state.currentCode}
                                     form={this.props.form}
                                     chooseProject={this.changecancelModal.bind(this)}>
                        </CancelOrder>
                    </Modal>
                </div>
            </div>
        )
    }
}

const OperateMaintain = Form.create({ name: 'OperateMaintainform' })(OperateMaintainform);

export default withRouter(OperateMaintain)
