import React, { Component } from 'react';
import './SettingOrganizationDepartment.less'
import empty from '@/images/placeholder_empty@2x.png';
import { Breadcrumb, Tree, Input, Divider, Tag, Button,message,Modal } from 'antd';
import { Link } from 'react-router-dom';
import Table from 'CMPT/common/Table';
import http from '@/http';
import AuthContainer from 'CMPT/common/auth/AuthContainer';
import { AUTH_KEYS } from '@/utils/auth';
import { API } from '@/http/api';
import src from '@/images/placeholder_empty@2x.png'
const { TreeNode } = Tree;
const Search = Input.Search;
class Department extends Component {
    state = {
        autoExpandParent: true,
        expandedKeys: [],
        checkedKeys: [],
        selectedKeys: [],
        data: [],
        value: '',
        treeData: [],
        pageNo: 1,
        pageSize: 10,
    }
    componentWillMount() {
        this.expandedKey = this.props.history.location.search&&this.props.history.location.search.replace('?from=','')
        if(this.expandedKey){
            this.setState({
                selectedKeys:[this.expandedKey]
            })
            http({
                api: API.departmentGetList,
                path: {
                    idCode: this.expandedKey
                }, // 路径参数
                data: {
                    departmentEncode: this.expandedKey,//(string): 部门code ,
                    // id (integer, optional),
                    // lastMaxId (integer, optional): 上次查询的最大id ,
                    // name (string, optional): 部门名字 ,
                    // orderByList (Array[integer], optional): 排序 1创建时间正序、2创建时间倒序、3最后更新时间正序、4最后更新时间倒序、5名称正序 6名称倒序 7预计处理时间正序、8预计处理时间倒序、9订单ID正序、10订单ID倒序 ,
                    pageNo: this.state.pageNo,// (integer, optional): 当前页数 ,
                    pageSize: this.state.pageSize,// (integer, optional): 每页条数 ,
                    // searchTotalNumber (integer, optional): 搜索最终的个数 为solr的group by 之类的服务
                },
                showLoading: true // 是否显示全局加载
            }).then(res => {
                console.log(res)
                if (res.success) {
                    var _data = res.data.datas.map(res => {
                        return {
                            key: res.idEncode,
                            name: res.name,
                            department: res.parentName,
                            tele: res.phone,
                            description: res.description,
                            operation: ['编辑', '删除']
                        }
                    })
                    this.setState({
                        data: _data,
                        total: res.data.totalRecord
                    })
                }
            })
        }
        http({
            api: API.departmentGetDeptTree,
            path: {}, // 路径参数
            data: {},
            showLoading: true // 是否显示全局加载
        }).then(res => {
            this.setState({
                treeData: [res.data],
                expandedKeys:[res.data.idCode,this.expandedKey]
            })

        })
    }

    onChange = (page, pageSize) => {
        this.setState({
            pageNo:page
        })
        console.log('contract list', page, pageSize)
        http({
            api: this.state.useSearch?API.departmentSearch:API.departmentGetList, // 请求的url,
            path: {
                idCode: this.state.selectedKeys[0]
            }, // 路径参数
            data: {
                departmentEncode: this.state.selectedKeys[0],//(string): 部门code ,
                // id (integer, optional),
                // lastMaxId (integer, optional): 上次查询的最大id ,
                name: this.state.value,//(string, optional): 部门名字 ,
                // orderByList (Array[integer], optional): 排序 1创建时间正序、2创建时间倒序、3最后更新时间正序、4最后更新时间倒序、5名称正序 6名称倒序 7预计处理时间正序、8预计处理时间倒序、9订单ID正序、10订单ID倒序 ,
                pageNo: page,// (integer, optional): 当前页数 ,
                pageSize: pageSize,// (integer, optional): 每页条数 ,
                // searchTotalNumber (integer, optional): 搜索最终的个数 为solr的group by 之类的服务
            },
            showLoading: true // 是否显示全局加载
        }).then(res => {
            console.log(res)
            if (res.success) {
                var _data = res.data.datas.map(res => {
                    return {
                        key: res.idEncode,
                        name: res.name,
                        department: res.parentName,
                        tele: res.phone,
                        description: res.description,
                        operation: ['编辑', '删除']
                    }
                })
                this.setState({
                    data: _data,
                    total: res.data.totalRecord
                })
            }
        })
    }
    onExpand = expandedKeys => {
        console.log('onExpand', expandedKeys);
        // if not set autoExpandParent to false, if children expanded, parent can not collapse.
        // or, you can remove all expanded children keys.
        this.setState({
            expandedKeys,
            autoExpandParent: false,
        });
    };

    onCheck = checkedKeys => {
        console.log('onCheck', checkedKeys);
        this.setState({ checkedKeys });


    };
    departmentChange = (e) => {
        console.log(e.target.value)
        this.setState({
            value: e.target.value
        })
    }
    departmentSearch = (value) => {
        // 根据条件搜索
        this.setState({
            useSearch:true,
            pageNo:1,
            pageSize:10
        })
            http({
                api: API.departmentSearch,
                path: {}, // 路径参数
                data: {
                    name: value,
                    pageNo: this.state.pageNo,// (integer, optional): 当前页数 ,
                    pageSize: this.state.pageSize,// (integer, optional): 每页条数 ,
                },
                showLoading: true // 是否显示全局加载
            }).then(res => {
                var _data = res.data.datas.map(res => {
                    return {
                        key: res.idEncode,
                        name: res.name,
                        department: res.parentName,
                        tele: res.phone,
                        description: res.description,
                        operation: ['编辑', '删除']
                    }
                })
                this.setState({
                    data: _data,
                    expandedKeys: [],
                    checkedKeys: [],
                    selectedKeys: [],
                    total: res.data.totalRecord
                })
            })
        
    }
    onSelect = (selectedKeys, info) => {
        this.setState({
            useSearch:false,
            pageNo:1,
            pageSize:10
        })
        console.log('onSelect', selectedKeys, info);
        this.props.history.replace('/app/setting/organization/department?from='+selectedKeys[0])
        this.setState({ selectedKeys, value: '' });
        // 请求选中的数据
        http({
            api: API.departmentGetList,
            path: {
                idCode: selectedKeys[0]
            }, // 路径参数
            data: {
                departmentEncode: selectedKeys[0],//(string): 部门code ,
                // id (integer, optional),
                // lastMaxId (integer, optional): 上次查询的最大id ,
                // name (string, optional): 部门名字 ,
                // orderByList (Array[integer], optional): 排序 1创建时间正序、2创建时间倒序、3最后更新时间正序、4最后更新时间倒序、5名称正序 6名称倒序 7预计处理时间正序、8预计处理时间倒序、9订单ID正序、10订单ID倒序 ,
                pageNo: this.state.pageNo,// (integer, optional): 当前页数 ,
                pageSize: this.state.pageSize,// (integer, optional): 每页条数 ,
                // searchTotalNumber (integer, optional): 搜索最终的个数 为solr的group by 之类的服务
            },
            showLoading: true // 是否显示全局加载
        }).then(res => {
            console.log(res)
            if (res.success) {
                var _data = res.data.datas.map(res => {
                    return {
                        key: res.idEncode,
                        name: res.name,
                        department: res.parentName,
                        tele: res.phone,
                        description: res.description,
                        operation: ['编辑', '删除']
                    }
                })
                this.setState({
                    data: _data,
                    total: res.data.totalRecord
                })
            }
        })
    };



    renderTreeNodes = data =>
        data.map(item => {
            if (item.children) {
                return (
                    <TreeNode title={item.text} key={item.idCode} dataRef={item}>
                        {this.renderTreeNodes(item.children)}
                    </TreeNode>
                );
            }
            return <TreeNode {...item} />;
        });
    editDepartment(value) {
        console.log(value)
        // 跳编辑
        this.props.history.push('/app/setting/organization/department/new?edit='+value.key)
    }
    deleteDepartment(value) {
        console.log(value)
        this.setState({
            idCode:value.key
        },()=>{
            this.setState({
                visible:true
            })
        })
        
    }
    handleCancel=()=>{
        this.setState({
            visible:false
        })
    }
    handleOk=()=>{
        http({
            api: API.departmentDelete,
            path: {
                idCode:this.state.idCode
            }, // 路径参数
            data: {},
            showLoading: true // 是否显示全局加载
        }).then(res=>{
            console.log(res)
            if(res.success){
                this.setState({
                    visible:false
                })
                http({
                    api: this.state.useSearch?API.departmentSearch:API.departmentGetList, // 请求的url,
                    path: {
                        idCode: this.state.selectedKeys[0]
                    }, // 路径参数
                    data: {
                        departmentEncode: this.state.selectedKeys[0],//(string): 部门code ,
                        // id (integer, optional),
                        // lastMaxId (integer, optional): 上次查询的最大id ,
                        name: this.state.value,//(string, optional): 部门名字 ,
                        // orderByList (Array[integer], optional): 排序 1创建时间正序、2创建时间倒序、3最后更新时间正序、4最后更新时间倒序、5名称正序 6名称倒序 7预计处理时间正序、8预计处理时间倒序、9订单ID正序、10订单ID倒序 ,
                        pageNo: 1,// (integer, optional): 当前页数 ,
                        pageSize: this.state.pageSize,// (integer, optional): 每页条数 ,
                        // searchTotalNumber (integer, optional): 搜索最终的个数 为solr的group by 之类的服务
                    },
                    showLoading: true // 是否显示全局加载
                }).then(res => {
                    console.log(res)
                    if (res.success) {
                        var _data = res.data.datas.map(res => {
                            return {
                                key: res.idEncode,
                                name: res.name,
                                department: res.parentName,
                                tele: res.phone,
                                description: res.description,
                                operation: ['编辑', '删除']
                            }
                        })
                        this.setState({
                            data: _data,
                            total: res.data.totalRecord
                        })
                    }
                })

                http({
                    api: API.departmentGetDeptTree,
                    path: {}, // 路径参数
                    data: {},
                    showLoading: true // 是否显示全局加载
                }).then(res => {
                    this.setState({
                        treeData: [res.data],
                        // expandedKeys:[res.data.idCode]
                    })
        
                })
                message.success('删除成功',()=>{
                })
            }
        })
    }
    detailDepartment=(value)=>{
        this.props.history.push('/app/setting/organization/department/detail?code='+value.key)
    }
    render() {

        const columns = [{
            title: '名称',
            dataIndex: 'name',
            key: 'name',
            render: (text,row) => <span className="name" onClick={this.detailDepartment.bind(this,row)}>{text}</span>,
        }, {
            title: '所属部门',
            dataIndex: 'department',
            key: 'department',
            render: text => <span>{text}</span>
        }, {
            title: '部门电话',
            dataIndex: 'tele',
            key: 'tele',
            render: text => <span>{text}</span>
        }, {
            title: '描述',
            dataIndex: 'description',
            key: 'description',
            render: text => (
                <span>
                    {text}
                </span>
            ),
        }, {
            title: '操作',
            dataIndex: 'operation',
            key: 'operation',
            render: (text,row) => (
                <div>
                    {
                        text.map((res, item) => {
                            if(item == 0){
                                return (
                                    <AuthContainer key="1" authCodes={AUTH_KEYS.SETTING_ORGANIZATION_DEPARTMENT_MODIFY}>
                                        <span className="action" onClick={this.editDepartment.bind(this,row)} key={res}>{res}</span>
                                    </AuthContainer>    
                                )
                            }else{

                                return (
                                    <AuthContainer key="2" authCodes={AUTH_KEYS.SETTING_ORGANIZATION_DEPARTMENT_DELETE}>
                                    <span className="action" onClick={this.deleteDepartment.bind(this,row)} key={res}>{res}</span>
                                    </AuthContainer>                                
                                )
                            }
                            
                        })
                    }
                </div>
            ),
        }];





        return (
            <div className="department">
                <Modal
                    closable={false}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    okText='确认'
                    width={400}         
                    onCancel={this.handleCancel}           
                >
                    <p>是否确认删除</p>
                </Modal>
                <div className="department-breadcrumb">
                    <Breadcrumb>
                        <Breadcrumb.Item>部门列表页</Breadcrumb.Item>                        
                    </Breadcrumb>

                    <AuthContainer authCodes={AUTH_KEYS.SETTING_ORGANIZATION_DEPARTMENT_NEW}>
                        <Link to='/app/setting/organization/department/new'>
                            <Button type="primary">新增部门</Button>
                        </Link>
                    </AuthContainer>

                </div>
                <div className="department-content">
                    <div className="department-Sider">
                        <Tree
                            // checkable
                            onExpand={this.onExpand}
                            expandedKeys={this.state.expandedKeys}
                            autoExpandParent={this.state.autoExpandParent}
                            onCheck={this.onCheck}
                            checkedKeys={this.state.checkedKeys}
                            onSelect={this.onSelect}
                            selectedKeys={this.state.selectedKeys}
                        >
                            {this.renderTreeNodes(this.state.treeData)}
                        </Tree>
                    </div>
                    <div className="department-main">
                        <div>
                            <div className="department-search">
                                <Search
                                    placeholder="请输入部门名称"
                                    value={this.state.value}
                                    onSearch={this.departmentSearch}
                                    onChange={this.departmentChange}
                                    style={{ width: 240 }}
                                />
                            </div>
                            {this.state.data.length ? (

                                // {/* 部门列表 */}
                                <div className="department-list">
                                    <AuthContainer authCodes={AUTH_KEYS.SETTING_ORGANIZATION_DEPARTMENT_LIST}>
                                        <Table columns={columns} data={this.state.data} current={this.state.pageNo} total={this.state.total} change={this.onChange} />
                                    </AuthContainer>
                                </div>

                            ) : (
                                    <div className="department-main-image">
                                        <img src={src} alt="" />
                                        <p>暂无数据</p>
                                    </div>
                                )}
                        </div>
                    </div>
                </div>

                {/* 底部白板 */}
                <div className="department-black">

                </div>
            </div>
        );
    }
}

export default Department;
