import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import http from '@/http';
import { API } from '@/http/api';
import { Breadcrumb, Button, Tabs, Row, Col, Tooltip  } from 'antd';
import Table from 'CMPT/common/Table';
import wordSrc from '@/images/files_word@2x.png';
import pdfSrc from '@/images/files_pdf@2x.png';
import { getNewPhone} from '@/utils/index';
const BreadcrumbItem = Breadcrumb.Item;
const TabPane = Tabs.TabPane;

class AuditInfo extends Component {
    state = {
        marginColumns: [
            {
                title: '费用类型',
                dataIndex: 'type',
                width: '255px'
            }, {
                title: '保证金(元)',
                dataIndex: 'marginNum',
            }, {
                title: '付款日期',
                dataIndex: 'payDate',
            }, {
                title: '备注',
                dataIndex: 'note',
                render: (value) => {
                    if (value && value.length > 20) {
                        return <Tooltip placement="top" title={value}>{value.slice(0, 20)}...</Tooltip>
                    }
                    return value || '--'
                }
            }
        ],
        rentColumns: [
            {
                title: '房源',
                dataIndex: 'projectRoomName',
                width: '255px'
            }, {
                title: '面积(m²)',
                dataIndex: 'area'
            }, {
                title: '单价(元/㎡/天) ',
                dataIndex: 'price'
            }
        ],
        estateColumns: [
            {
                title: '房源',
                dataIndex: 'projectRoomName',
                width: '255px'
            }, {
                title: '面积(m²)',
                dataIndex: 'area'
            }, {
                title: '单价(元/㎡/月) ',
                dataIndex: 'price'
            }
        ],
        firstChooseTypeOptions: [
            {value: 1, name: '合同签订后'},
            {value: 2, name: '租期开始前'},
            {value: 3, name: '租期开始后'},
            {value: 4, name: '指定日期'},
        ],
        firstRangeTypeOptions: [
            {value: 1, name: '首个计费周期'},
            {value: 2, name: '前两个计费周期'},
            {value: 3, name: '指定缴费日期'}
        ]
    }
    componentWillReceiveProps(nextProps) {
        const firstYears = this.props.contractYears
        const years = nextProps.contractYears
        if (firstYears !== years) {
            let rentColumns = this.state.rentColumns
            let estateColumns = this.state.estateColumns
            for (let i = 1; i <= years; i++) {
                rentColumns.push({
                    title: `第${i}年`,
                    dataIndex: `year${i}`
                })
                estateColumns.push({
                    title: `第${i}年`,
                    dataIndex: `year${i}`
                })
            }
            this.setState({
                rentColumns,
                estateColumns
            })
        }
    }
    transRooms = (data) => {
        let strList = []
        data.forEach(per => {
            let str = ''
            str += `${per.floor}层-${per.name}-${per.area}m²`;
            strList.push(str)
        })
        return strList.join('，')
    }
    transRangeDate = (info) => {
        let str = ''
        if (info.exemptBeforeDateRange && info.exemptBeforeDateRange.length) {
            str += `（前）${info.exemptBeforeDateRange[0]} 至 ${info.exemptBeforeDateRange[1]}`
        }
        if (info.exemptAfterDateRange && info.exemptAfterDateRange.length) {
            str += ` | （后）${info.exemptAfterDateRange[0]} 至 ${info.exemptAfterDateRange[1]}`
        }
        return str || '--'
    }
    downfile = () => {
        const config = {
            api: API.contractDownloadMedia,
            responseType: 'blob',
            data:  this.props.info.mediaInfo,
            showLoading: true
        }
        http(config).then(res => {
            const content = res
            const blob = new Blob([content])
            const fileName = this.props.info.mediaInfo.name
            if ('download' in document.createElement('a')) { // 非IE下载
                const elink = document.createElement('a')
                elink.download = fileName
                elink.style.display = 'none'
                elink.href = URL.createObjectURL(blob)
                document.body.appendChild(elink)
                elink.click()
                URL.revokeObjectURL(elink.href) // 释放URL 对象
                document.body.removeChild(elink)
            } else { // IE10+下载
                navigator.msSaveBlob(blob, fileName)
            }
        })
    }
    render() {
        const { info } = this.props;
        const { firstChooseTypeOptions, firstRangeTypeOptions } = this.state;
        var firstChooseTypeDesc = '';
        var firstRangeTypeDesc = '';
        if (info.firstChooseType && info.firstChooseType !== 4 && info.firstFeeDays) {
            for (let i = 0; i < firstChooseTypeOptions.length; i++) {
                if (firstChooseTypeOptions[i].value === info.firstChooseType) {
                    console.log(firstChooseTypeOptions[i].name, i, '------------------')
                    firstChooseTypeDesc = firstChooseTypeOptions[i].name + info.firstFeeDays + '天';
                }
            }
        } else if (info.firstChooseType && info.firstChooseType == 4 && info.firstFeePointDate) {
            firstChooseTypeDesc = info.firstFeePointDate;
        } else {
            firstChooseTypeDesc = '-';
        }
        if (info.firstRangeType && info.firstRangeType !== 3) {
            for (let i = 0; i < firstRangeTypeOptions.length; i++) {
                if (firstRangeTypeOptions[i].value === info.firstRangeType) {
                    firstRangeTypeDesc = firstRangeTypeOptions[i].name;
                }
            }
        } else if (info.firstRangeType && info.firstRangeType == 3 && info.firstBeginDay && info.firstEndDay) {
            firstRangeTypeDesc = info.firstBeginDay + ' 至 ' + info.firstEndDay;
        } else {
            firstRangeTypeDesc = '-';
        }
        return (
            <div>
                 <div className="contract-info">
                    <div className="headline">企业信息</div>
                    <Row>
                        <Col span={8}>客户名称：{info.customerName}</Col>
                        <Col span={8}>客户类型：{info.customerType}</Col>
                        <Col span={8}>行业：{info.customerIndustry}</Col>
                    </Row>
                    <Row>
                        <Col span={8}>统一社会信用代码：{info.customerTaxNumber}</Col>
                        <Col span={8}>联系人：{info.RelatedPerson}</Col>
                        <Col span={8}>联系人电话：{getNewPhone(info.RelatedPersonPhone)}</Col>
                    </Row>
                    <Row>
                        <Col span={8}>联系人身份证号：{info.RelatedPersonIdentity}</Col>
                        <Col span={8}>邮箱地址：{info.customerEmail}</Col>
                        <Col span={8}>银行卡号：{info.customerBankCard}</Col>
                    </Row>
                    <Row>
                        <Col span={8}>省市区：{info.customerLocation}</Col>
                        <Col span={8}>板块：{info.customerBlock}</Col>
                        <Col span={8}>详细地址：{info.customerAddress}</Col>
                    </Row>
                    <Row style={{display: info.customerCompanyLogo ? "block" : "none"}}>
                        <Row><Col>公司logo：</Col></Row>
                        <div className="project-image">
                            <img src={info.customerCompanyLogo} alt=""/>
                        </div>
                    </Row>
                    <Row style={{display: info.mediaInfo && info.mediaInfo.mediaKey ? "block" : "none"}}>
                        <Row>合同附件：</Row>
                        <div className="contract-file">
                            <div className="download">
                                <div onClick={this.downfile}>下载</div>
                            </div>
                            {info.mediaInfo.type=='word'?<img src={wordSrc} alt={info.mediaInfo.name}/>:<img src={pdfSrc} alt={info.mediaInfo.name}/>}
                            <div className="filename">
                                {info.mediaInfo.name}
                            </div>
                        </div>
                    </Row>
                </div>
                <div className="contract-info">
                    <div className="headline">房源信息</div>
                    <Row>
                        <Col span={8}>项目名称：{info.projectName}</Col>
                        <Col span={8}>总面积：{info.projectArea}</Col>
                    </Row>
                    <Row>
                        <Col span={24}>楼层区域：{this.transRooms(info.projectRooms)}</Col>
                    </Row>
                    <div className="project-image" style={{display: info.projectImg ? "block" : "none"}}>
                        <img src={info.projectImg} alt=""/>
                    </div>
                </div>
                <div className="contract-info">
                    <div className="headline">租约信息</div>
                    <Row>
                        <Col span={8}>合同编号：{info.contractNumber}</Col>
                        <Col span={8}>合同期限：{info.contractDateRange[0]} 至 {info.contractDateRange[1]}</Col>
                        <Col span={8}>签约时间：{info.contractSignDate}</Col>
                    </Row>
                    <Row>
                        <Col span={24}>合同内免租期：{this.transRangeDate(info)}</Col>
                    </Row>
                    <div className="tenancy-information">
                        <div className="type">租赁条款</div>
                        <Row>
                            <Col span={8}>支付方式：押{info.contractDeposit}月付{info.contractPayment}{info.contractPaymentUnit}</Col>
                            <Col span={8}>{info.contractPaymentType === 1 ? `提前付款天数：${info.contractPaymentDate}天` : `固定付款日期：${info.contractPaymentDate}号`}</Col>
                        </Row>
                        <Row>
                            <Col span={8}>
                                首期款缴费期限：{firstChooseTypeDesc}
                            </Col>
                            <Col span={8}>
                                首期款涵盖范围：{firstRangeTypeDesc}
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>租金年递增率及折扣：{info.contractFei}</Col>
                        </Row>
                        <div className="infor-table">
                            <Table columns={this.state.marginColumns} data={info.marginData} pagination={false}/>
                        </div>
                        <div className="infor-table">
                            <Table columns={this.state.rentColumns} data={info.contractRentList} pagination={false}/>
                        </div>
                    </div>
                    <div className="tenancy-information">
                        <div className="type">物业条款</div>
                        <Row>
                            <Col span={8}>支付方式：押{info.estateDeposit}月付{info.estatePayment}{info.estatePaymentUnit}</Col>
                            <Col span={8}>{info.estatePaymentType === 1 ? `提前付款天数：${info.estatePaymentDate}天` : `固定付款日期：${info.estatePaymentDate}号`}</Col>
                        </Row>
                        <Row>
                            <Col span={24}>租金年递增率及折扣：{info.estateFei}</Col>
                        </Row>
                        <div className="infor-table">
                            <Table columns={this.state.marginColumns} data={info.estateMarginData} pagination={false}/>
                        </div>
                        <div className="infor-table">
                            <Table columns={this.state.estateColumns} data={info.estateRentList} pagination={false}/>
                        </div>
                    </div>

                </div>
            </div>
           
        );
    }
}

export default AuditInfo;