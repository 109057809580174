import React, { Component } from "react";
import { Breadcrumb, Tabs } from "antd";
import YqyGoodsList from "./YqyGoodsList";
import "./YqyGoodsManager.less";
const { TabPane } = Tabs;
const BreadcrumbItem = Breadcrumb.Item;

class YqyGoodsManager extends Component {
  render() {
    return (
      <div className="goods_all">
        <div>
          <Breadcrumb>
            <BreadcrumbItem>一起赢商品</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div>
          <Tabs defaultActiveKey="1" className="tabs">
            <TabPane tab="商城web" key="1" className="tab">
              <YqyGoodsList type="1" source={1} />
            </TabPane>
            <TabPane tab="商城小程序" key="2" className="tab">
              <YqyGoodsList type="2" source={1} />
            </TabPane>
          </Tabs>
        </div>
      </div>
    );
  }
}

export default YqyGoodsManager;
