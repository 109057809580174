import React, { Component } from "react";
import { Breadcrumb, Tabs } from "antd";
import OperateClient from "./OperateClient";
import CustomerList from "./CustomerList";
import "./CustomerTabs.less";

const { TabPane } = Tabs;
class CustomerTabs extends Component {
  render() {
    return (
      <div className="customer_all">
        {/* <Tabs defaultActiveKey="1" className="customer_tabs">
          <TabPane tab="全部" key="1" className="tab">
            <CustomerList history={this.props.history} />
          </TabPane>
          <TabPane tab="园区方" key="2" className="tab">
            <OperateClient history={this.props.history} />
          </TabPane>
        </Tabs> */}

<CustomerList history={this.props.history} />

      </div>
    );
  }
}

export default CustomerTabs;
