import http from "@/http";
import { API } from "@/http/api";
import { formatLinkSearch } from "@/utils";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table from "CMPT/common/Table";
import { Tabs } from "antd";
import SearchForm from "./searchForm";
// import { AuditStatus } from "../enum";
import moment from "moment";
import "./EnterpriseAccountOpening.less";

const AuditStatus = [
  { alias: "NEW", text: "新申请", value: 0 },
  { alias: "REVIEW", text: "易宝审核中", value: 2 },
  { alias: "REJECT", text: "易宝审核驳回", value: 3 },
  { alias: "SUCCESS ", text: "易宝审核通过", value: 4 },
  { alias: "SIGING", text: "待签署", value: 5 },
  { alias: "UNDER_REVIEW", text: "平台审核中", value: 7 },
  { alias: "PLATFORMREJECT", text: "平台驳回", value: 6 },
  { alias: "AUTHENTICATION", text: "待微信实名认证", value: 8 },
  { alias: "COMPLETE", text: "已开户", value: 1 },
]
const { TabPane } = Tabs;

const openAccountTypeMap = {
  0: "个人",
  1: "企业",
};

// 筛选项默认值
const defaultParams = {
  pageNo: 1, // 需要查询的页数
  pageSize: 10, // 每页条数
  name: "", // 商户名称
  unifiedSocialCode: "", // 统一社会信用代码
  // type: '', // 商户类型
  contactName: "", // 联系人姓名
  contactMobile: "", // 联系人手机号
  // contactEmail: '', // 联系人邮箱
  status: "", // 状态
  submitDateState: "", // 需求开始时间
  submitDateEnd: "", // 需求结束时间
  completeDateStart: "", // 申请开始时间
  completeDateEnd: "", // 申请结束时间
};
class EnterpriseAccountOpening extends Component {
  state = {
    total: 0, // 当前数据的总数
    pageNo: 1, // 当前所展示的页码
    currentPageCount: 0, //当前页面的数量
    selectParams: {
      // 接口入参，必须放这里。方便分页时保存信息
      auditStatus: this.props.state, // 审批状态
      ...defaultParams,
    },
    // 审核状态
    currentTab: "-1",
    columns: [
      {
        title: "商户名称",
        key: "name",
        align: "center",
        dataIndex: "name",
      },
      {
        title: "统一社会信用代码",
        key: "unifiedSocialCode",
        align: "center",
        dataIndex: "unifiedSocialCode",
      },
      {
        title: "商户类型",
        key: "openAccountType",
        align: "center",
        dataIndex: "openAccountType",
        render: (text) => {
          return <span>{openAccountTypeMap[text]}</span>;
        },
      },
      {
        title: "联系人姓名",
        key: "contactName",
        align: "center",
        dataIndex: "contactName",
      },
      {
        title: "联系人手机号",
        key: "contactMobile",
        align: "center",
        dataIndex: "contactMobile",
      },
      {
        title: this.props.state == 0 ? "需求提交时间" : "需求提交时间", // 0 表示待审核状态
        key: this.props.state == 0 ? "submitDate" : "submitDate",
        align: "center",
        dataIndex: this.props.state == 0 ? "submitDate" : "submitDate",
        render: (text, record) => {
          return (
            <span>{text ? moment(text).format("YYYY-MM-DD HH:mm") : "-"}</span>
          );
        },
      },
      {
        title: this.props.state == 0 ? "申请提交时间" : "申请提交时间", // 0 表示待审核状态
        key: this.props.state == 0 ? "completeDate" : "completeDate",
        align: "center",
        dataIndex: this.props.state == 0 ? "completeDate" : "completeDate",
        render: (text, record) => {
          return (
            <span>{text ? moment(text).format("YYYY-MM-DD HH:mm") : "-"}</span>
          );
        },
      },
      {
        title: "审核状态",
        key: "openAccountStatusName",
        align: "center",
        dataIndex: "openAccountStatusName",
      },
      {
        title: "操作",
        key: "operate",
        align: "center",
        width: 120,
        render: (text, record, index) => {
          return record.openAccountStatus == 0 ? (
            <span className="table-operate-btn">
              <Link
                to={
                  "/app/crm/account_open/detail/" +
                  record.code +
                  "?pageNo=" +
                  this.state.pageNo +
                  "&currentPageCount=" +
                  this.state.currentPageCount
                }
                style={{ color: "#2E4AFF" }}
              >
                提交开户申请{" "}
              </Link>
            </span>
          ) : record.openAccountStatus == 7 ? (
            <span className="table-operate-btn">
              <Link
                to={
                  "/app/crm/account_open/agreement/" +
                  record.code +
                  "?pageNo=" +
                  this.state.pageNo +
                  "&currentPageCount=" +
                  this.state.currentPageCount
                }
                style={{ color: "#2E4AFF" }}
              >
                确认协议
              </Link>
            </span>
          ) : record.openAccountStatus == 8 ? (
            <span
              className="table-operate-btn"
              onClick={() => {
                this.onComplete(record.code);
              }}
            >
              完成开户
            </span>
          ) : record.openAccountStatus == 4 ? (
            <span className="table-operate-btn">
              <Link
                to={
                  "/app/crm/account_open/agreement/" +
                  record.code +
                  "?pageNo=" +
                  this.state.pageNo +
                  "&currentPageCount=" +
                  this.state.currentPageCount +
                  "&status=" +
                  record.openAccountStatus
                }
                style={{ color: "#2E4AFF" }}
              >
                生成协议
              </Link>
            </span>
          ) : record.openAccountStatus == 5 ||
            record.openAccountStatus == 6 ||
            record.openAccountStatus == 1 ? (
            <span className="table-operate-btn">
              <Link
                to={
                  "/app/crm/account_open/agreement/" +
                  record.code +
                  "?pageNo=" +
                  this.state.pageNo +
                  "&currentPageCount=" +
                  this.state.currentPageCount
                }
                style={{ color: "#2E4AFF" }}
              >
                协议详情
              </Link>
            </span>
          ) : (
            <span className="table-operate-btn">
              <Link
                to={
                  "/app/crm/account_open/detail/" +
                  record.code +
                  "?pageNo=" +
                  this.state.pageNo +
                  "&currentPageCount=" +
                  this.state.currentPageCount
                }
                style={{ color: "#2E4AFF" }}
              >
                查看详情
              </Link>
            </span>
          );
        },
      },
    ],
    data: [],
  };

  componentWillMount() {
    if (this.props.location) {
      // 获取参数
      const search = this.props.location.search;
      const formatData = formatLinkSearch(search);

      // 解析
      let pageNo1 = null;
      if (formatData && formatData.backPageNo) {
        pageNo1 = formatData.backPageNo;
      }

      // 赋值
      this.setState({
        selectParams: {
          auditStatus: this.props.state, // 审批状态
          pageNo: pageNo1, // 当前页数
          pageSize: 10, // 每页条数
        },
      });
    }
  }

  //初始化
  componentDidMount() {
    this.getLists();
  }
  //查询列表
  getLists = () => {
    const httpParams = {
      ...this.state.selectParams,
      status: this.state.currentTab,
    };
    for (let [key, value] of Object.entries(httpParams)) {
      if (value === -1 || value === "-1" || value === "") {
        httpParams[key] = undefined;
      }
    }
    const config = {
      api: API.accountListForPage,
      data: { ...httpParams },
      showLoading: true,
    };

    http(config).then((res) => {
      if (res.code == 200) {
        const data = res.data;
        this.setState({
          data: data.datas || [],
          total: Number(data.total),
          pageNo: data.pageNo,
          currentPageCount: data.datas ? data.datas.length : 0,
          selectParams: { ...this.state.selectParams, pageNo: data.pageNo },
        });
      }
    });
  };

  handleDelete = (code) => {
    const config = {
      api: API.sourcingFreightAuditForcedDown,
      data: {
        code: code,
      },
    };
    http(config).then((res) => {
      if (res && res.code == 200) {
        this.getLists();
      }
    });
  };

  changeTabs = (value) => {
    this.setState(
      {
        currentTab: value,
      },
      () => {
        this.getLists();
      }
    );
  };

  //分页
  onChangePage = (pageNo, pageSize, sort) => {
    this.setState(
      {
        selectParams: {
          ...this.state.selectParams,
          pageNo: pageNo,
          pageSize: pageSize,
        },
      },
      () => {
        this.getLists();
      }
    );
  };

  // 搜索
  onValuesChange = (allValues) => {
    this.setState({
      selectParams: { ...this.state.selectParams, ...allValues },
    });
  };

  reset = (allValues) => {
    this.setState(
      {
        selectParams: { ...this.state.selectParams, ...defaultParams },
      },
      () => {
        this.getLists();
      }
    );
  };

  onComplete = (code) => {
    const config = {
      api: API.accountCompleteOpening,
      data: {
        code: code,
      },
    };
    http(config).then((res) => {
      if (res && res.code == 200) {
        this.getLists();
      }
    });
  };

  render() {
    const selectParams = this.state.selectParams;
    return (
      <div className="enterprise-auth-list">
        <Tabs
          activeKey={this.state.currentTab}
          onChange={this.changeTabs}
          className="tabs"
          style={{ margin: 0 }}
        >
          <TabPane tab="全部" key="-1" className="tab"></TabPane>
          {AuditStatus.map((item) => (
            <TabPane tab={item.text} key={item.value} className="tab"></TabPane>
          ))}
        </Tabs>
        <div className="searchBox">
          <SearchForm
            onValuesChange={this.onValuesChange}
            types={false}
            reset={this.reset}
            onSearch={() => {
              this.getLists();
            }}
          />
        </div>
        <Table
          columns={this.state.columns}
          rowKey={(record, index) => record.code}
          selfProcessData={this.state.data}
          total={this.state.total}
          change={this.onChangePage}
          current={selectParams.pageNo}
        />
      </div>
    );
  }
}

export default EnterpriseAccountOpening;
