import React, { Component } from 'react';
import MissionList from './MissionList'
import NoJob from './NoJob'
import { taskCancel,taskSwitchStatus} from './service/taskService'

class TodayMission extends Component {
    state = {
        title:'今日任务',
        screenHeight:(window.innerHeight || window.screen.availHeight || window.screen.height || 700) * 0.88
    }

    checkItem =(v) => {
        taskSwitchStatus({code:v}).then(res=>{
            this.props.getList();
        })
    }

    deleteItem = (v) => {
        taskCancel({code:v}).then(res=>{
            this.props.getList();
        })       
    }

    editItem = (v) => {
        this.props.editItem(v)
    }

    resizeListener = ()=>{
        this.setState({
            screenHeight:(window.innerHeight || window.screen.availHeight || window.screen.height || 700) * 0.88
        })
    }

    componentDidMount() {
        window.addEventListener('resize',this.resizeListener)
    }
    componentWillUnmount() {
        window.removeEventListener('resize',this.resizeListener)
    }

    render() {
        const data = this.props.data;
        const screenHeight = this.state.screenHeight;
        const dataHtml = data.length> 0 ?  <MissionList title={this.state.title} operate={false} collapse={false}  data={this.props.data} checkItem={this.checkItem} deleteItem={this.deleteItem} editItem={this.editItem} isMore={false}/> : <NoJob label="无今日任务" />
        return (
            <div className="today-mission" style={{ maxHeight: (screenHeight - 208) + 'px', overflowY: 'auto', position: 'relative', overflowX: 'hidden' }}>
               {dataHtml}
            </div>
        )
    }
}

export default TodayMission
