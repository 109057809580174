import React, { Component } from 'react';
import { List, Tabs, Tag, Popover, Radio } from 'antd';
import InfoCard from '../common/card/InfoCard';
import './MessageCenter.less';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { API } from '@/http/api';
import http from '@/http';
import InfiniteScroll from 'react-infinite-scroller';
const TabPane = Tabs.TabPane;

const labels = [
    { desc: '通知', color: '#E94437' },
    { desc: '到期', color: '#FBBC06' },
    { desc: '逾期', color: '#E94437' }
]
const Announcelabels = [
    { desc: '通知', color: '#E94437' },
    { desc: '节日', color: '#E94437' },
    { desc: '奖惩', color: '#FBBC06' },
    { desc: '奖惩', color: '#FBBC06' }
]

class Message extends React.Component {
    loading = false
    requests = [{
        'orderByListptional': '1',
        'pageNo': 1,
        'pageSize': 10,
        'readFlag': '0',//0未读 1已读
    }, {
        'orderByListptional': '1',
        'pageNo': 1,
        'pageSize': 10,
        'readFlag': '1',//0未读 1已读
    }]

    announceRequests = [
        {
            'pageNo': 1,
            'pageSize': 10,
            'readStatus': 1,//1未读 2已读
        }, {
            'pageNo': 1,
            'pageSize': 10,
            'readStatus': 2,//1未读 2已读
        }];

    state = {
        data: {
            0: [],
            1: []
        },
        encodes: [],
        label: null,
        hasMore: true,
        loading: false,
        tab: 0,
        noticeType: 'message'

    }
    callback = (key) => {
        this.setState({
            tab: key
        }, () => {
            if (this.state.noticeType === 'message') {
                this.requests[key].pageNo = 0;
                this.messageList(key);
            } else if (this.state.noticeType === 'announce') {
                this.announceRequests[key].pageNo = 0;
                this.announceList(key);
            }
        })
    }
    messageList = (key) => {
        this.requests[key].pageNo++;
        http({
            api: API.messageList,
            isLoading: true,
            data: this.requests[key],
        }).then(res => {
            if (res.success) {
                const newData = [...this.state.data[key]];
                if (res.data.datas.length !== 0 || res.data.pageNo === 1) {
                    this.setState({
                        data: {
                            ...this.state.data,
                            [key]: res.data.pageNo === 1 ? res.data.datas : newData.concat(res.data.datas),
                            hasMore: res.data.totalPages >= res.data.pageNo,
                        }
                    });
                } else {
                    this.requests[key].pageNo--;
                }
                this.loading = false;
            }
        })
    }

    announceList(key) {
        this.announceRequests[key].pageNo++;
        // const key = this.state.tab;
        http({
            api: API.announceMyList,
            isLoading: true,
            data: this.announceRequests[key],
        }).then(res => {
            if (res.success) {
                const newData = [...this.state.data[key]];
                if (res.data.datas.length !== 0 || res.data.pageNo === 1) {
                    this.setState({
                        data: {
                            ...this.state.data,
                            [key]: res.data.pageNo === 1 ? res.data.datas : newData.concat(res.data.datas),
                            hasMore: res.data.totalPages >= res.data.pageNo,
                        }
                    });
                } else {
                    this.announceRequests[key].pageNo--;
                }
                this.loading = false;
            }
        })
    }




    handleInfiniteOnLoad = (i) => {
        // let data = this.state.data[i];
        if (this.loading) return;
        // this.setState({
        //     hasMore: false,
        // });
        this.loading = true;
        console.log('handleInfiniteOnLoad')
        // if (data.length > 4) {
        //     this.setState({
        //         hasMore: false,
        //         loading: false,
        //     });
        //     return;
        // }
        if (this.state.noticeType === 'message') {
            this.messageList(i);
        } else if (this.state.noticeType === 'announce') {
            this.announceList(i);
        }



    };

    messageRead = () => {
        // http({
        //     api: API.messageRead,
        //     data:this.state.data.map(item=>item.encode)
        // }).then(res => {
        //     if (res.success) {
        //         this.messageList();
        //     }
        // })
        if (this.state.data[0].length > 0) {
            http({
                api: API.messageReadAll
            }).then(res => {
                if (res.success) {
                    this.callback(0);
                }
            })
        }
    }

    componenDidMount() {
        // this.callback(this.state.tab);
    }
    onVisibleChange = visible => {
        if (visible) {
            this.callback(this.state.tab)
        }

    }

    getList = (type) => {
        let data = {
            0: [],
            1: []
        }
        // const type = key.target.value;
        this.setState({
            noticeType: type,
            data
        }, () => {
            if (type === 'message') {
                this.requests[this.state.tab].pageNo = 0;
                this.messageList(this.state.tab);
            } else if (type === 'announce') {
                this.announceRequests[this.state.tab].pageNo = 0;
                this.announceList(this.state.tab);
            }
        })
    }

    render() {

        const item = (item) => {
            if (this.state.noticeType == 'message') {
                return (
                    <List.Item>
                        <List.Item.Meta
                            avatar={<Tag className='lable' color={labels[item.type - 1].color}>{labels[item.type - 1].desc}</Tag>}
                            title={<div className='reminder'><div className='reminder-title'>{item.title}</div><div className='time'>{item.createTimeDesc}</div></div>}
                            description={<div className='msg-content'>{item.context}</div>}
                        />
                    </List.Item>
                )
            }
            if (this.state.noticeType == 'announce') {
                console.log(item)
                return (
                    <List.Item>
                        <List.Item.Meta
                            avatar={<Tag className='lable' color={Announcelabels[item.type - 1].color}>{Announcelabels[item.type - 1].desc}</Tag>}
                            title={<div className='reminder'><div className='reminder-title announce-msg-title'>{item.title}</div><div className='time'>{formatTime(item.createTime)}</div></div>}
                            description={<div className='msg-content announce-msg-content'>{item.pureText}</div>}
                            onClick={() => { this.props.history.push('/app/read/' + item.encode) }}
                        />
                    </List.Item>
                )
            }
        };

        const readAll = () => {
            if (this.state.noticeType === 'message') {
                return (
                    <span className='allRead' style={this.state.data[0].length == 0 ? { color: '#5D637B' } : null} onClick={this.messageRead}>全部已读</span>
                )
            }
        }

        const content = (<div className='msg-card'>
            <div className="title">
                <span onClick={() => { this.getList('message') }} className={this.state.noticeType == 'message' ? 'activeTitle' : ''}>通知</span><span> / </span><span className={this.state.noticeType == 'announce' ? 'activeTitle' : ''} onClick={() => { this.getList('announce') }}>公告</span>

                {/* <Radio.Group onChange={this.getList} defaultValue='message' value={this.state.noticeType} style={{ marginBottom: 8 }}>
                    <Radio.Button value="message">通知</Radio.Button>
                    <Radio.Button value="announce">公告</Radio.Button>
                </Radio.Group> */}
            </div>
            <Tabs defaultActiveKey="0" onChange={this.callback}>
                <TabPane tab="未读" key="0" >
                    <div className="msg-infinite-container" style={this.state.noticeType == 'announce' ? { height: '340px' } : { height: '256px' }}>
                        <InfiniteScroll
                            initialLoad={false}
                            pageStart={0}
                            loadMore={() => {
                                this.handleInfiniteOnLoad(0);
                            }}
                            hasMore={this.state.hasMore}
                            useWindow={false}
                        >
                            <List
                                itemLayout="horizontal"
                                dataSource={this.state.data[0]}
                                renderItem={item}
                            />
                        </InfiniteScroll>
                    </div>
                    {
                        readAll()
                    }
                </TabPane>
                <TabPane tab="已读" key="1">
                    <div className="msg-infinite-container" style={this.state.noticeType == 'announce' ? { height: '340px' } : { height: '320px' }}>
                        <InfiniteScroll
                            initialLoad={false}
                            pageStart={0}
                            loadMore={() => {
                                this.handleInfiniteOnLoad(1);
                            }}
                            hasMore={this.state.hasMore}
                            useWindow={false}
                        >
                            <List
                                itemLayout="horizontal"
                                dataSource={this.state.data[1]}
                                renderItem={item}
                            />
                        </InfiniteScroll>
                    </div>
                </TabPane>

            </Tabs>
        </div>);
        const align = {
            offset: [-100, 20]
        }
        return (
            <Popover overlayClassName="message-popup" {...this.props} onVisibleChange={this.onVisibleChange} align={align} content={content}>
                {this.props.children}
            </Popover>
        );

    }
}
export default withRouter(Message)

function formatTime(time) {
    if (time) {
        return moment(time).format('YYYY-MM-DD HH:MM:SS');
    }
    return '';
}