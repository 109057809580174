import React, { Component } from 'react';
import { Row,Select,Form } from 'antd';
import http from '@/http';
import { API } from '@/http/api';
import MultiCascader from 'CMPT/common/MultiCascader';
import "../../financeApproval/finance.less"
import '../bill.less'

const Item = Form.Item;
const Option = Select.Option;

class Region extends Component {
    state = {
        projects:[],
        cascaderData: [],
    }
    componentDidMount() {
        this.getProjectList();
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.resetBill !== this.props.resetBill) {
            if (nextProps.resetBill.projectEncode) {
                this.getFloorZone(nextProps.resetBill.projectEncode)
            }
        }
    }
    getProjectList(){
        http({
            api: API.commonProjectfilter,
        }).then(res => {
            this.setState({
                projects:res.data
            })
        })
    }
    getFloorZone = (value, options) => {
        console.log(options)
        let config = {
            api: API.commonFloorZoneFilter,
            data: {
                projectEncode: value
            }
        }
        if (options) {
            this.props.form.setFieldsValue({
                floorTemp:[]
            })
        }
        http(config).then(res => {
            let targetList = []
            console.log(res.data)
            if (res.data) {
                res.data.forEach(per => {
                    if (per.zones && per.zones.length) {
                        let obj = {}
                        obj.name = per.name
                        obj.id = per.encode
                        obj.no = per.floorNo
                        obj.child = []
                        per.zones.forEach(zo => {
                            let zoObj = {}
                            zoObj.name = zo.zoneNo
                            zoObj.id = zo.encode
                            zoObj.parentId = zo.floorEncode
                            zoObj.price = zo.unitPrice
                            zoObj.area = zo.rentalArea
                            zoObj.zoneNo = zo.zoneNo
                            obj.child.push(zoObj)
                        })
                        targetList.push(obj)
                    }
                })
            }
            console.log(targetList)
            this.setState({
                cascaderData: targetList
            })
        })
    }
    render(){
        // console.log('000000:',this.props)
        const { billType } = this.props;
        const info = this.props.resetBill
        console.log('info', info)
        const options = this.state.projects.map(per => {
            return <Option value={per.encode} key={per.encode}>{per.name}</Option>
        })
        const { getFieldDecorator } = this.props.form;
        return (
            <div>
                <Row className="fs12">
                    <Item label="项目名称" style={{ width: '237px'}}>
                        {getFieldDecorator('projectEncode1', {
                            rules: [{
                                required: billType == 1, message: '请选择',
                            }],
                            initialValue: info.projectEncode
                        })(
                            <Select placeholder="请输入" notFoundContent='无匹配结果' onChange={this.getFloorZone} showArrow={false}>
                                {options}
                            </Select>
                        )}
                    </Item>
                </Row>
                <Row className="fs12">
                    <Item label="选择区域" style={{ width: '396px'}}>
                        {getFieldDecorator('floorTemp', {
                            rules: [{
                                required: billType == 1, message: '请选择',
                            }],
                            initialValue: info.floorTemp
                        })(
                            <MultiCascader notFoundContent='无匹配结果' data={this.state.cascaderData} placeholder="请先选择项目" noShowArea={true}/>
                        )}
                    </Item>
                </Row>     
            </div>            
        )
    }
}

export default Region;