import React, { Component } from 'react'
import { Modal, Form, Input,message } from 'antd';
import http from '@/http';
import { API } from '@/http/api';
const { TextArea } = Input;
const FormItem = Form.Item;
class RejectTemplate extends Component {
    state = { visible: false }
    componentWillReceiveProps(nextProps) {

        this.setState({
            visible: nextProps.visible
        })
    }


    handleOk = (e) => {
        console.log(e);
        this.props.form.validateFields((err,value)=>{
            if(!err){
                http({
                    api: API.templateReject,
                    path: {}, // 路径参数
                    data: {
                        encode: this.props.templateId,
                        remark:this.props.form.getFieldValue('remark'),
                        type:this.props.type,
                    },
                    showLoading: true // 是否显示全局加载
                }).then(res => {
                    if (res.success) {
                        this.setState({
                            visible: false,
                        });
                        this.props.reload(true)                    
                        message.success('审批成功').then(()=>{
                        })
                    }
                })
            }
        })
    }

    handleCancel = (e) => {
        this.props.reload()
        this.setState({
            visible: false,
        });
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div>
                <Modal
                    title='审批驳回'
                    closable={false}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    okText='确定'
                    width={400}
                    destroyOnClose={true}
                    onCancel={this.handleCancel}                    
                >
                    <Form>
                        <Form.Item label="审批意见"
                        >
                            {getFieldDecorator('remark', {
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入审批意见'
                                    },
                                ],
                                initialValue: undefined
                            })(
                                <TextArea rows={4} maxLength={200} placeholder="请输入" />
                            )}
                        </Form.Item>

                    </Form>
                </Modal>
            </div>
        );
    }
}

export default Form.create({ name: 'RejectTemplate' })(RejectTemplate)