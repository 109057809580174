import React, { Component } from 'react';
import { Breadcrumb, Tabs, Badge, Button, Form, Input, Radio, Select, Icon, message, Modal } from 'antd';
import { Link } from "react-router-dom";
import './index.less';

import debounce from 'lodash/debounce';
import Upload from 'CMPT/common/Upload';
import { API } from '@/http/api';
import http from '@/http';
import userUtil from "@/utils/user";


const RadioGroup = Radio.Group
const Option = Select.Option
class BuyForm extends Component {

    state = {
        payType: 1,
        supplierArray: [],
        isSubCharging: true,
        payPhotos: [],
        customerType: 1
    }

    componentDidMount() {
        // 获取
        let {match} = this.props;
        let isSubCharging = match.path.indexOf('goBuy') == -1;
        this.setState({isSubCharging})
    }
    // 切换付款方式
    radioChange = (e) => {
        this.setState({
            payType: e.target.value
        })
    }
    customTypeRadioChange = (e) => {
        this.setState({
            customerType: e.target.value,
            supplierArray: []
        })
    }

    inputSearch = (e) => {
        this.initData(e)
    }

    initData(name) {
        let { customerType } = this.state;
        const config = {
            api: customerType == 2 ? API.valueAddServiceSelectSimpleList : API.invoiceSupplierList, 
            path: { supplierName: name || 'null' } // null 表示 全部数据 

        };

        // setup 2:请求，并初步处理
        let _this = this;
        http(config).then(res => {
            if (res && res.code == 200) {
                _this.setState({
                    supplierArray: res.data
                });
            }
        });
    }
    
    // 文件上传前拦截
    handleBeforeUpload = file => {
        const size = file.size;
        if (size / 1024 / 1024 > 2) {
            message.error("请上传2M以内的图片");
            return false;
        }
    };
    // 付款凭证上传回调
    payMoneyChange = value => {
        if (value.file.status === "done") {
            let item = value.file.response.file;
            let {payPhotos} = this.state;
            payPhotos.push({name: '', url: item.absolutePath})
          this.setState({ payPhotos });
        }
    };

    // 表单请求接口
    platformDeposit = (params) => {
        let {isSubCharging} = this.state;
        const config = {
            api: isSubCharging ? API.valueAddServiceInPlaceOfpay : API.valueAddServicePlatformDeposit, 
            showLoading: true,
            data: params
        };
        http(config).then(res => {
            if (res.code == 200) {
                message.success('操作成功')
                this.props.history.go(-1)
            }
        })
    }
    // 提交表单
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {payPhotos} = this.state;
                values.payPhotos = payPhotos;
                console.log(values, 'values', err);
                let {isSubCharging} = this.state;
                if (isSubCharging) {
                    Modal.confirm({
                        title: '提示',
                        icon: 'none',
                        content: <div className='confirm_text'>
                            <div className='confirm_1'>确定已收到对方转账吗？</div>
                            <div className='confirm_2'>说明：确定后将自动为该用户充值</div>
                        </div>,
                        onOk: () => {
                            this.platformDeposit(values)
                        }
                    })
                } else {
                    this.platformDeposit(values)
                }
            }
        })
    }


    checkNum = (rule, value, callback) => {
        console.log(value, 'value');
        if (!value) {
            callback('请输入');
        }
        if(value.indexOf('.') > -1) {
            callback('请输入正整数');

        }
        return callback();
    };
    



    render() {
        const {payType, isSubCharging, supplierArray, payPhotos, customerType} = this.state;
        const { getFieldDecorator } = this.props.form;
        return (

            <div className='buy_form_content enterprise-auth-con'>
                <div className='enterprise-auth-header'>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link
                                style={{ color: "#333" }}
                                to={`/app/crm/valueaddService/trajectoryFind?tab=${isSubCharging?2:1}`}
                            >
                                {isSubCharging ? '用户订单': '数据概览'}
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{isSubCharging ? '代充': '添加购买'}</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className='form_box'>
                    <Form onSubmit={this.handleSubmit} layout="horizontal" labelCol={{ span: 4 }} wrapperCol={{ span: 8 }}>
                        <div className="intention-add-body">
                            <div>
                            {isSubCharging && <div>
                                    <Form.Item label="客户类型" >
                                        {getFieldDecorator(
                                            "customerType", {
                                                rules: [{
                                                    required: true, message: '请输入',
                                                }],
                                                initialValue: customerType
                                            }
                                        )(
                                            <RadioGroup name="radiogroup" onChange={this.customTypeRadioChange}>
                                                <Radio value={1} style={{ fontSize: '12px' }}>企业</Radio>
                                                <Radio value={2} style={{ fontSize: '12px' }}>个人</Radio>
                                            </RadioGroup>
                                        )
                                        }
                                    </Form.Item>
                                    <Form.Item label="客户名称" >
                                        {getFieldDecorator(
                                            customerType == 1 ?"supplierId" : "userId", {
                                                rules: [{
                                                    required: true, message: '请输入',
                                                }],
                                                // initialValue: params.selectedCategoryType
                                            }
                                        )(
                                            <Select allowClear showSearch optionFilterProp="label" onChange={this.changeSupplier} onSearch={debounce(this.inputSearch, 1000)}
                                                placeholder="企业/个人名称/输入搜索" style={{ display:'inline-block' }}>
                                                {supplierArray.map(item => (
                                                    (
                                                        customerType == 1 ? <Option key={item.supplierId} value={item.supplierId} label={item.supplierName}>
                                                            <span><span>{item.supplierName || ''}</span>&nbsp;&nbsp;&nbsp;<span>{item.unifiedSocialCode || ''}</span>&nbsp;&nbsp;&nbsp;<span>{(item.userName || '') + (item.userName ? '/' : "") + (item.userPhone || '')}</span></span>
                                                        </Option> : 
                                                        <Option key={item.id} value={item.id} label={item.displayName}>
                                                            <span>{item.displayName || ''}</span>&nbsp;&nbsp;&nbsp;<span>{item.mobile || ''}</span>
                                                        </Option>
                                                    )
                                                ))}
                                            </Select>
                                        )
                                        }
                                    </Form.Item>
                                    <Form.Item label="付款人" >
                                        <Input.Group compact>
                                            <Form.Item >
                                                {getFieldDecorator(
                                                    "accountName", {
                                                        rules: [{
                                                            required: true, message: '请输入',
                                                        }],
                                                        // initialValue: params.selectedCategoryType
                                                    }
                                                )(
                                                    <Input placeholder="请输入姓名" />
                                                )
                                                }
                                            </Form.Item>
                                            <Form.Item >
                                                {getFieldDecorator(
                                                    "accountPhone", {
                                                        rules: [{
                                                            required: true, message: '请输入',
                                                        }],
                                                        // initialValue: params.selectedCategoryType
                                                    }
                                                )(
                                                    <Input  placeholder="请输入手机号码" />
                                                )
                                                }
                                            </Form.Item>
                                                    
                                        </Input.Group>
                                    </Form.Item>
                                    
                                    
                                </div>}
                                <Form.Item label="充值金额" >
                                    {getFieldDecorator(
                                        "payPrice", {
                                            rules: [{
                                                required: true, message: '请输入',
                                            }],
                                            // initialValue: params.selectedCategoryType
                                        }
                                    )(
                                        <Input addonAfter="元" placeholder='请输入' />
                                    )
                                    }
                                </Form.Item>

                                <Form.Item label="充值条数" >
                                    {getFieldDecorator(
                                        "serviceNumber", {
                                            rules: [{
                                                // required: true, message: '请输入',
                                                validator: this.checkNum 
                                            }],
                                            // initialValue: params.selectedCategoryType
                                        }
                                    )(
                                        <Input addonAfter="次数" placeholder='请输入'  type='Number' />
                                    )
                                    }
                                </Form.Item>

                               {!isSubCharging && <Form.Item label="订单备注" >
                                    {getFieldDecorator(
                                        "desc", {
                                            rules: [{
                                                required: true, message: '请输入',
                                            }],
                                            // initialValue: params.selectedCategoryType
                                        }
                                    )(
                                        <Input placeholder='请输入' />
                                    )
                                    }
                                </Form.Item>}

                                <Form.Item label="付款方式" >
                                    {getFieldDecorator(
                                        "payType", {
                                            rules: [{
                                                required: true, message: '请选择',
                                            }],
                                            initialValue: payType
                                        }
                                    )(
                                        <RadioGroup name="radiogroup" onChange={this.radioChange}>
                                            <Radio value={1} style={{ fontSize: '12px' }}>现结</Radio>
                                            <Radio value={2} style={{ fontSize: '12px' }}>账期</Radio>
                                        </RadioGroup>
                                    )
                                    }
                                </Form.Item>

                                {
                                    payType == 1 && (
                                        <Form.Item label="付款凭证" >
                                            {getFieldDecorator(
                                                "payPhotos", {
                                                    rules: [{
                                                        required: true, message: '请上传',
                                                    }],
                                                }
                                            )(
                                                <div>
                                                    <span>请将付款流水凭证图片上传，并且确认已到账</span>
                                                        <div className="upload-img-list">
                                                            {payPhotos && payPhotos.map((per, index) => {
                                                                return <img src={per.url} key={index} />
                                                            })}
                                                            <div className='upload_box'>
                                                                <Upload action={API.uploadAdd.url}
                                                                    beforeUpload={this.handleBeforeUpload}
                                                                    accept=".png,.jpg"
                                                                    onChange={this.payMoneyChange}
                                                                    showUploadList={false}
                                                                    multiple
                                                                    listType="picture-card">
                                                                </Upload>
                                                            </div>
                                                    </div>

                                                </div>
                                                
                                            )
                                            }
                                        </Form.Item>
                                    )
                                }


                            </div>
                        </div>
                        <div className="btn-group">
                            <Button type="primary" htmlType="submit">
                                确定
                            </Button>{" "}
                            <Button
                                type="link"
                                onClick={() =>
                                    this.props.history.go(-1)
                                }
                            >
                                取消
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>

        );
    }
}

const BuyTForm = Form.create({ name: "BuyForm" })(BuyForm);
export default BuyTForm;