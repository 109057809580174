/***
 * 一， 当前负责人： lvwenzhuo875  --> 功能有bug，或者 新功能需求 或者 好建议，就找他了
 *     历史贡献人:
 *
 * 二,  目标：本class主要为了节省页码切换中的重复代码。
 *          后端返回列表数据，pageNo,pageSize等页信息固定在 res 中，而入参时，变量名也是固定的。因此我们可以统一下
 *
 * 三， 使用方法:
 *      1) 引入(依赖Table控件):
 *               import Table from "CMPT/common/Table";
 *               import PageInfo from "@/utils/pageInfo.js";
 *
 *      2)变量声明:   state = {
 *                   ...
 *                   pageInfo: new PageInfo(),  ---> Look  here
 *                    .....
 *                   }
 *
 *      3) 结合Table使用:
 *         <Table
 *        rowKey="encode"
 *        columns={this.state.columns}
 *        selfProcessData={this.state.httpData}
 *        {...this.state.pageInfo.paramForTable()} ---> Look  here
 *        change={this.onChangePage}
 *       />
 *
 *      4) 当手动换页:
 *           onChangePage = (pageNo, pageSize, sort) => {
 *                 this.state.pageInfo.changeForClick(pageNo, pageSize);   ---> Look  here
 *                 this.setState({}, () => this.queryList());
 *            };
 *
 *       5) 接口调用前 组装入参时:
 *              let myState = this.state;
 *
 *               // setup 1: 构造入参
 *               let dataParam = {
 *                    ...myState.searchParam,
 *                   ...myState.pageInfo.paramForSearch()  ---> Look  here
 *                };
 *
 *                // setup 2: 参数配置
 *                   const config = {
 *                          api: API.XXXYYY,
 *                         data: dataParam,  ---> Look  here
 *                        showLoading: true
 *                 };
 *
 *          6) 接口返回后，更新页码信息，并更新
 *             if (res && res.success  ) {
 *                  myState.pageInfo.changeFromJson(res.data); ---> Look  here
 *
 *                   ...........
 *                   this.setState({
 *                        httpData: listData || []
 *                   });
 *                   ...........
 *             }
 *
 *
 *
 *  四, 后续计划：
 *      暂无
 *
 *  五, 修改记录：
 *     1),  2020-03-13  初步实现
 *
 *
 *
 *
 */

export default class PageInfo {
  constructor() {
    this.pageNo = 1; // 提供给Table使用
    this.pageSize = 10; // 提供给Table使用
    this.totalPage = 0; // 提供给Table使用
    this.totalRecord = 0; // 提供给Table使用

    this.SearchPageNo = this.pageNo; // 用户点击页码信息后 选择的第几页。供接口使用
    this.SearchPageSize = 10; // 用户点击页码信息后 选择的 页面数量。供接口使用
  }

  changeFromJson(httpResult) {
    if (!httpResult) {
      return this;
    }
    this.pageNo = httpResult.pageNo;
    this.pageSize = httpResult.pageSize;

    // 后端返回结果，关于 页码定义有两套，需要兼容支持
    if (httpResult.totalPage===0 || httpResult.totalPage) {
      this.totalPage = httpResult.totalPage;
    }

    if (httpResult.totalPages===0 || httpResult.totalPages) {
      this.totalPage = httpResult.totalPages;
    }

    // 后端返回结果，关于 页码定义有两套，需要兼容支持
    if (httpResult.totalRecord===0 ||  httpResult.totalRecord) {
      this.totalRecord = httpResult.totalRecord;
    }

    if (httpResult.total===0 ||  httpResult.total) {
      this.totalRecord = httpResult.total;
    }

    return this;
  }

  changeForClick(pageNo, pageSize) {
    this.SearchPageNo = pageNo;
    this.SearchPageSize = pageSize;
  }

  paramForTable() {
    return {
      total: this.totalRecord,
      current: this.pageNo
    };
  }
  paramForSearch() {
    return {
      pageNo: this.SearchPageNo,
      pageSize: this.SearchPageSize
    };
  }

  resetPageInfo(){
    this.pageNo = 1; 
    this.SearchPageNo = this.pageNo; 
  }
}
