import {
    AJAX_TYPE
} from '../config';

// 供应商

const prefix = 'supplier';

export default {
    // 新增供应商
    [`${prefix}Add`]: {
        url: '/web/supplier/add',
        type: AJAX_TYPE.POST
    },
    // 审批
    [`${prefix}Audit`]: {
        url: '/web/supplier/audit',
        type: AJAX_TYPE.POST
    },
    // 删除供应商
    [`${prefix}Delete`]: {
        url: '/web/supplier/delete/:encode',
        type: AJAX_TYPE.DELETE
    },
    // 修改供应商
    [`${prefix}Edit`]: {
        url: '/web/supplier/edit',
        type: AJAX_TYPE.PUT
    },
    // 供应商编辑初始化
    [`${prefix}EditInit`]: {
        url: '/web/supplier/editInit',
        type: AJAX_TYPE.GET
    },
    // 供应商列表初始化
    [`${prefix}Init`]: {
        url: '/web/supplier/init',
        type: AJAX_TYPE.GET
    },
    // 供应商详情
    [`${prefix}QueryDetail`]: {
        url: '/web/supplier/queryDetail/:encode',
        type: AJAX_TYPE.GET
    },
    // 查询列表
    [`${prefix}QueryList`]: {
        url: '/web/supplier/queryList',
        type: AJAX_TYPE.POST
    },
    // 供应商保存并提交
    [`${prefix}SaveAndSubmit`]: {
        url: '/web/supplier/saveAndSubmit',
        type: AJAX_TYPE.PUT
    },
    // 提交审批
    [`${prefix}Submit`]: {
        url: '/web/supplier/submit/:encode',
        type: AJAX_TYPE.POST
    },
    // 对接人列表
    [`${prefix}UserList`]: {
        url: '/web/supplier/userList',
        type: AJAX_TYPE.GET
    },
}