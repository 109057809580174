import React, { Component } from 'react';
import { Breadcrumb, Button, Row, Col, DatePicker, Input, Tooltip ,message } from 'antd';
import Table from 'CMPT/common/Table';
import http from '@/http';
import { API } from '@/http/api';
const BreadcrumbItem = Breadcrumb.Item;
const { RangePicker } = DatePicker;
const Search = Input.Search;
var moment = require('moment');

class ContractReport extends Component {
    state = {
        columns: [
            {
                title: '创建时间',
                width: 140,
                dataIndex: 'creatTime',
                fixed: 'left',
            }, {
                title: '合同编号',
                width: 170,
                dataIndex: 'contractNumber',
                fixed: 'left',
            }, {
                title: '签约日期',
                width: 120,
                dataIndex: 'contractDate',
            }, {
                title: '租客名称',
                width: 120,
                dataIndex: 'tenant',
                render(text, record) {
                    if(text && text.length > 13) {
                        return <Tooltip placement="top" title={text}>
                                <span className="lineEllipsis" style={{'WebkitBoxOrient': 'vertical'}}>{text.slice(0, 20)}...</span>
                            </Tooltip>
                    } else {
                        return text
                    }
                }
            }, {
                title: '签约项目',
                width: 150,
                dataIndex: 'projectName',
                render(text, record) {
                    if(text && text.length > 15) {
                        return <Tooltip placement="top" title={text}>
                                <span className="lineEllipsis" style={{'WebkitBoxOrient': 'vertical'}}>{text.slice(0, 20)}...</span>
                            </Tooltip>
                    } else {
                        return text
                    }
                }
            }, {
                title: '签约区域',
                width: 150,
                dataIndex: 'zoneNo',
                render(text, record) {
                    if(text && text.length > 15) {
                        return <Tooltip placement="top" title={text}>
                                <span className="lineEllipsis" style={{'WebkitBoxOrient': 'vertical'}}>{text.slice(0, 20)}...</span>
                            </Tooltip>
                    } else {
                        return text
                    }
                }
            }, {
                title: '签约人',
                width: 120,
                dataIndex: 'signUserName',
                render(text, record) {
                    return text.substring(0, 1) + "****"
                }
            }, {
                title: '合同期限开始',
                width: 120,
                dataIndex: 'startDate'
            }, {
                title: '合同到期',
                width: 100,
                dataIndex: 'endDate'
            }, {
                title: '租金支付方式',
                width: 120,
                dataIndex: 'paymentWay'
            }, {
                title: '物业支付方式',
                width: 120,
                dataIndex: 'propertyPayType'
            }, {
                title: '租金合计',
                width: 120,
                dataIndex: 'totalRent'
            }, {
                title: '物业合计',
                width: 120,
                dataIndex: 'totalProperty'
            }
        ],
        data: [],
        total: 0,
        startDateMoment: undefined,
        endDateMoment: undefined,
        selectParams: {
            keyWords: null,
            createTimeStart: moment().subtract(1, 'years').add(1, 'days').format('YYYY-MM-DD'),
            createTimeEnd: moment().format('YYYY-MM-DD'),
            pageNo: 1,// 当前页数
            pageSize: 10,// 每页条数
        }
    }
    componentDidMount() {
        this.getLists()
    }
    onSearchName = (val) => {
        let selectParams = this.state.selectParams;
        selectParams.pageNo = 1;
        selectParams.pageSize = 10;
        selectParams.keyWords = val;
        this.setState({
            selectParams
        }, () => {
            this.getLists();
        })
    }
    setName = (e) => {
        let selectParams = this.state.selectParams;
        selectParams.keyWords = e.target.value;
        this.setState({
            selectParams
        })
    }
    handleDate = (value, date) => {
        let selectParams = this.state.selectParams;
        selectParams.pageNo = 1;
        selectParams.pageSize = 10;
        selectParams.createTimeStart = date[0];
        selectParams.createTimeEnd = date[1];
        this.setState({
            selectParams,
            startDateMoment: value[0],
            endDateMoment: value[1]
        }, () => {
            this.getLists();
        })
    }
    onChangePage = (pageNo, pageSize) => {
        let selectParams = this.state.selectParams;
        selectParams.pageNo = pageNo;
        selectParams.pageSize = pageSize;
        this.setState({
            selectParams
        }, () => {
            this.getLists();
        })
    }
    getLists() {
        const config = {
            api: API.reportformContract,
            data: this.state.selectParams,
            showLoading: true
        };
        http(config).then(res => {
            const data = res.data;
            const list = data && data.datas.map(item => {
                return {
                    key: item.id,
                    creatTime: item.createTime ,
                    contractNumber: item.contractCode ,
                    contractDate: item.signTime ,
                    tenant: item.customerName ,
                    projectName: item.projectName ,
                    zoneNo: item.zoneNo ,
                    signUserName: item.signUserName ,
                    startDate: item.startTime ,
                    endDate: item.endTime ,
                    paymentWay: item.rentPayType ,
                    propertyPayType: item.propertyPayType ,
                    totalRent: item.rentTotalAmount ,
                    totalProperty: item.propertyTotalAmount 
                }
            })
            this.setState({
                data: list || [],
                total: data && data.totalRecord
            })
        })
    }
    export = () => {
        const config = {
            api: API.reportformContractExport,
            data: this.state.selectParams,
            responseType: 'blob',
            showLoading: true
        };
        http(config).then(res => {
            if (res.size < 1024) {
                message.error('下载异常，请稍后重试')
            } else { 
            const content = res
            const blob = new Blob([content], { type: 'application/vnd.ms-excel' })
            const fileName = `合同报表${moment(new Date()).format('YYYY-MM-DD HH:mm')}.xlsx`
            if ('download' in document.createElement('a')) { // 非IE下载
                const elink = document.createElement('a')
                elink.download = fileName
                elink.style.display = 'none'
                elink.href = URL.createObjectURL(blob)
                document.body.appendChild(elink)
                elink.click()
                URL.revokeObjectURL(elink.href) // 释放URL 对象
                document.body.removeChild(elink)
            } else { // IE10+下载
                navigator.msSaveBlob(blob, fileName)
            }
        }
        })
    }
    reset = () => {
        this.setState({
            isReset:true,
            startDateMoment: moment().subtract(1, 'years').add(1,'days').format('YYYY-MM-DD'),
            endDateMoment: moment().format('YYYY-MM-DD'),
            selectParams: {
                keyWords: null,
                createTimeStart: moment().subtract(1, 'years').add(1,'days').format('YYYY-MM-DD'),
                createTimeEnd:  moment().format('YYYY-MM-DD'),
                pageNo: 1,// 当前页数
                pageSize: 10,// 每页条数
            }
        }, () => {
            this.setState({
                isReset:false
            })
            this.getLists();
        })
    }
    render() {
        return (
            <div className="report-con">
                <div className='report-header'>
                    <Breadcrumb>
                        <BreadcrumbItem>报表中心</BreadcrumbItem>
                        <BreadcrumbItem>合同报表</BreadcrumbItem>
                    </Breadcrumb>
                    <Button type="primary" className='download' onClick={this.export}>下载报表</Button>
                </div>
                <div className='report-list'>
                    {
                        !this.state.isReset ? (
                            <div className='manage-filter'>
                                <Search
                                    placeholder="请输入合同编号/租客名称"
                                    value={this.state.selectParams.keyWords}
                                    onSearch={this.onSearchName}
                                    onChange={this.setName}
                                    className='manage-search'
                                />
                                创建时间
                                <RangePicker className='manage-filter-date'
                                    defaultValue={[moment().subtract(1, 'years').add(1, 'days'), moment()]} allowClear={false} disabledDate={disabledDate}
                                    onChange={this.handleDate} style={{ width: '206px' }} />
                                <span className="report-reset" style={{ cursor: 'pointer' }} onClick={this.reset}>重置</span>
                            </div>
                        ) : (null)
                    }
                    <Table columns={this.state.columns} data={this.state.data} total={this.state.total} change={this.onChangePage} current={this.state.selectParams.pageNo} scroll={{ x: 1678 }} />
                </div>
            </div>
        );
    }
}

export default ContractReport;


function disabledDate(current) {
    // Can not select days before today and today
    return current > moment() || current < moment().subtract(5, 'years'); // February 28;
}