import React, { Component } from 'react'
import './ContractFiling.less'
import {
    Breadcrumb,
    //Table
} from "antd";
import { API } from "@/http/api";
import http from "@/http";
import {Link} from "react-router-dom";
import Table from "CMPT/common/Table";
import PageInfo from "@/utils/pageInfo.js";
const BreadcrumbItem = Breadcrumb.Item;

class ContractFiling extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataSource: [],
            columns: [
                {
                    title: '备案号',
                    dataIndex: 'orderNo',
                    key: 'orderNo',
                },
                {
                    title: '采购方',
                    dataIndex: 'subjectName',
                    key: 'subjectName',
                },
                {
                    title: '供货方',
                    dataIndex: 'supplierName',
                    key: 'supplierName',
                },
                {
                    title: '供货方所在地',
                    dataIndex: 'supplierPlace',
                    key: 'supplierPlace',
                },
                {
                    title: '项目名称',
                    dataIndex: 'projectName',
                    key: 'projectName',
                },
                {
                    title: '项目类型',
                    dataIndex: 'projectTypeName',
                    key: 'projectTypeName',
                },
                {
                    title: '送货地点',
                    dataIndex: 'projectPlace',
                    key: 'projectPlace',
                },
                {
                    title: '合同金额',
                    dataIndex: 'contractPrice',
                    key: 'contractPrice',
                },
                {
                    title: '回款周期',
                    dataIndex: 'remittanceCycle',
                    key: 'remittanceCycle',
                },
                {
                    title: '创建时间',
                    dataIndex: 'createDateStr',
                    key: 'createDateStr',
                },
                {
                    title: '成品料采供',
                    dataIndex: '',
                    key: '',
                    render(text, record) {
                        return(
                            <Link
                            to={
                                "/app/crm/contract/contractFin/" +
                                record.code
                            }>
                                <span className="text-color-highlight">详情</span>
                            </Link>
                        )
                    }
                },
                {
                    title: '原材料采供',
                    dataIndex: '',
                    key: '',
                    render(text, record) {
                        return(
                            <Link
                            to={
                                "/app/crm/contract/contractRaw/" +
                                record.code
                            }>
                                <span className="text-color-highlight">详情</span>
                            </Link>
                        )
                    }
                },
                {
                    title: '操作',
                    dataIndex: '',
                    key: '',
                    render(text, record) {
                        return(
                            <Link
                                to={
                                    "/app/crm/contract/detail/" +
                                    record.code
                                }
                            >
                                <span className="text-color-highlight">详情</span>
                            </Link>
                        )
                    }
                },
            ],
            pageInfo: new PageInfo(),
        }
    }

    componentDidMount() {
        this.initList()
    }
    initList = () => {
        const { pageInfo } = this.state
        let myData = {
            ...pageInfo.paramForSearch()
        };
        const config = {
            api: API.orderContractFiling,
            data: myData,
            showLoading: true
        }
        http(config).then(res => {
            if(res.code == 200) {
                let {
                    datas,
                } = res.data
                // datas = [{
                //     key: 1,
                //     orderNo: 10000,
                //     subjectName: '测试1',
                //     projectName: '测试1',
                //     projectTypeName: '测试1',
                //     projectPlace: '北京',
                //     supplierName: '测试1',
                //     supplierPlace: '北京',
                //     code: '001'
                // },{
                //     key: 1,
                //     orderNo: 10000,
                //     subjectName: '测试1',
                //     projectName: '测试1',
                //     projectTypeName: '测试1',
                //     projectPlace: '北京',
                //     supplierName: '测试1',
                //     supplierPlace: '北京',
                //     code: '002'
                // },{
                //     key: 1,
                //     orderNo: 10000,
                //     subjectName: '测试1',
                //     projectName: '测试1',
                //     projectTypeName: '测试1',
                //     projectPlace: '北京',
                //     supplierName: '测试1',
                //     supplierPlace: '北京',
                //     code: '003'
                // }]
                datas&&datas.map((item, index) => {
                    item.key = index + 1
                })
                for (let index = 0; index < datas.length; index++) {
                    if(datas[index].remittanceCycle == null){
                        datas[index].remittanceCycle ="0天"
                    }else{
                        datas[index].remittanceCycle = datas[index].remittanceCycle +"  天";
                    }
                }
                this.setState({
                    dataSource: datas,
                    pageInfo: pageInfo.changeFromJson(res.data)
                })
            }
        })
    }
    // 页码切换
    tableChange = (pageNo, pageSize, sort) => {
        let pageInfo = this.state.pageInfo;
        pageInfo.changeForClick(pageNo, pageSize);
        this.setState({ pageInfo }, this.initList);
    };
    render(){
        const {
            dataSource,
            columns,
            pageInfo
        } = this.state
        return(
            <div className='userlist-content'>
                <div className='enterprise-auth-header'>
                    <Breadcrumb>
                        <BreadcrumbItem>合同备案</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <div className="userlist-body">
                    <Table
                        // dataSource={dataSource}
                        // columns={columns}
                        change={this.tableChange}
                        rowKey="code"
                        {...pageInfo.paramForTable()}
                        scroll={{ x: "max-content" }}
                        selfProcessData={dataSource}
                        columns={columns}
                    />
                </div>
            </div>
        )
    }
}

export default ContractFiling
