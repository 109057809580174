import React, { Component } from 'react'
import { Form, Button, Row, Col, Icon, Modal } from 'antd';
import './SplitAreaPopView.less'
import EditableTable from './SplitEditableTable';
import http from '@/http'
import { API } from '@/http/api';

class SplitAreaPopView extends React.Component {
    constructor(props) {
        super(props);

        

        this.merchants = ['可招商', '不可招商'];
        this.rents = ['未出租', '已出租', '不可招商'];

        this.data = {
            floorEncode: props.zoneVosData.floorEncode,
            originalEncode: props.zoneVosData.encode,
            partZoneList: [],
            projectEncode: props.zoneVosData.projectEncode
        };

        this.state = {
            columns:[],
            dataSource : [
                {
                    key: 1,
                    code: 1,
                    zoneNo: null,
                    merchantStatus: 1,
                    rentStatus: 1,
                    buildingArea: null,
                    unitPrice: null,
                    useArea: null,
                    innerArea: null,
                    remark: null
                },
                {
                    key: 2,
                    code: 2,
                    zoneNo: null,
                    merchantStatus: 1,
                    rentStatus: 1,
                    buildingArea: null,
                    unitPrice: null,
                    useArea: null,
                    innerArea: null,
                    remark: null
                },
            ]
        };
        
    }
    componentWillMount(){
        this.getProjectSetting();
    }
    getProjectSetting = () => {
        // 获取项目配置
        const defaultConfig = {
            api: API.decisionByProjectEncode,
            path: {
                projectEncode: "qeZDWfgpvSs"
            },
            data: {
                bizType: '1'
            }
        }
        http(defaultConfig).then(res => {            
            this.setState({
                defaultSquare : res.data.length?res.data[res.data.length-1].description:'建筑面积'
            })
            this.defaultSquare = res.data.length?res.data[res.data.length-1].description:'建筑面积'
            this.setState({
                columns : [
                    { title: '门牌号', dataIndex: 'zoneNo', key: 'zoneNo', editable: true, required: true },
                    { title: '招商状态', dataIndex: 'merchantStatus', key: 'merchantStatus', editable: true, required: true },
                    { title: '出租状态', dataIndex: 'rentStatus', key: 'rentStatus', editable: true, required: true },
                    { title: '建筑面积(m²)', dataIndex: 'buildingArea', key: 'buildingArea', editable: true, required: true },
                    { title: '预计单价(元/m²/日)', dataIndex: 'unitPrice', key: 'unitPrice', editable: true, required: true },
                    { title: '使用面积(m²)', dataIndex: 'useArea', key: 'useArea', editable: true,required:this.defaultSquare=="使用面积"?true:false },
                    { title: '套内面积(m²)', dataIndex: 'innerArea', key: 'innerArea', editable: true ,required:this.defaultSquare=="套内面积"?true:false},
                    { title: '备注', dataIndex: 'remark', key: 'remark', editable: true },
                    {
                        title: '操作',
                        dataIndex: 'operation',
                        key: 'operation',
                        editable: false,
                        align: 'center',
                        width: 100,
                        render: (text, record) => (
                            <Form.Item style={{ margin: 0 }}>
                                <a className={"btn-row-delete" + (this.state.dataSource.length <= 2 ? " btn-row-delete-disabled" : '')}
                                    href="javascript:;"
                                    onClick={() => this.handleDelete(record.key)} >删除</a>
                            </Form.Item>
                        ),
                    }
                ]
            })
            
        })
    }
    handleDelete = (key) => {
        if (this.state.dataSource.length > 2) {
            Modal.confirm({
                icon: false,
                content: '确认删除？',
                onOk: () => {
                    const dataSource = [...this.state.dataSource];
                    this.setState({
                        dataSource: dataSource.filter(item => item.code !== key)
                    });
                },
                onCancel: () => { }
            });
        }
    }

    checkAreaConstraint = () => {
        let result = true;
        const dataSource = [...this.state.dataSource];
        function checkByKey(item, key) {
            if (!item[key]) {
                item.constraint = {
                    ...item.constraint,
                    [key]: {
                        message: '必填',
                        status: 'error'
                    }
                }
            }
        }
        const areaCodeMap = {};
        const checkResult = dataSource.map((item, index) => {
            item.constraint = null;
            if (item.code) {
                let indexArr = areaCodeMap[item.code];
                if (!indexArr) {
                    areaCodeMap[item.code] = indexArr = [];
                }
                indexArr.push(index);
            }
            checkByKey(item, 'code');
            checkByKey(item, 'zoneNo');
            // checkByKey(item, 'merchantStatus');
            // checkByKey(item, 'rentStatus');
            checkByKey(item, 'buildingArea');
            checkByKey(item, 'unitPrice');
            return item;
        }).filter(item => item.constraint);
        if (checkResult.length > 0) {
            result = false;
        }
        if (!result) {
            this.setState({ dataSource: dataSource });
        }
        return result;
    }

    handleSubmit = (e) => {
        if (!this.checkAreaConstraint()) {
            return;
        }

        let data = this.state.dataSource.map((item) => {
            return {
                zoneNo: item.zoneNo,
                merchantStatus: item.merchantStatus,
                rentStatus: item.rentStatus,
                buildingArea: parseFloat(item.buildingArea),
                unitPrice: parseFloat(item.unitPrice),
                useArea: parseFloat(item.useArea),
                innerArea: parseFloat(item.innerArea),
                remark: item.remark
            };
        });
        Object.assign(this.data.partZoneList, data);

        this.splitArea();
    }

    cancelEdit = () => {
        this.props.handlerBackSplitAreaClick('cancel')
    }

    areaSingleAdd = () => {
        let dataSource = this.state.dataSource;
        let code = dataSource[dataSource.length - 1].code + 1;

        dataSource.push({
            key: code,
            code: code,
            zoneNo: null,
            merchantStatus: 1,
            rentStatus: 1,
            buildingArea: null,
            unitPrice: null,
            useArea: null,
            innerArea: null,
            remark: null
        });
        this.setState({
            dataSource: dataSource
        });
    }

    splitArea = () => {
        const config = {
            api: API.zoneDivide,
            data: this.data,
            showLoading: true,
        }

        console.log('config:', config);
        http(config).then(result => {
            if (result.success) {
                this.props.handlerBackSplitAreaClick('ok')
            }
        })
    }

    onDataSourceChange = (items) => {
        console.log(items)
        this.setState({
            dataSource: items
        })
    }

    render() {
        return (
            <div className="split-container">
                <div className="split-title">分割区域</div>
                <div className="split-origin-part">原区域</div>
                <div className="split-origin-part-info">
                    <span>门牌号：{this.props.zoneVosData.zoneNo}</span>
                    <span>招商状态：{this.merchants[this.props.zoneVosData.merchantStatus]}</span>
                    <span>出租状态：{this.rents[this.props.zoneVosData.rentStatus]}</span>
                    <span>建筑面积：{this.props.zoneVosData.buildingArea}m²</span>
                    <span>预计单价：{this.props.zoneVosData.unitPrice}元/m²/月</span>
                    <span>使用面积：{!!this.props.zoneVosData.useArea ? `${this.props.zoneVosData.useArea}m²` : '-'}</span>
                    <span>套内面积：{!!this.props.zoneVosData.innerArea ? `${this.props.zoneVosData.innerArea}m²` : '-'}</span>
                </div>
                <div className="split-note">备注：{this.props.zoneVosData.remark}</div>
                <div className="split-table-info">
                    <div className="split-left-part">分割后区域</div>
                    <div className="split-right-part" onClick={this.areaSingleAdd.bind(this)}>
                        <i className="iconfont iconadd_circle"></i>
                        分割
                    </div>
                </div>
                <EditableTable dataSource={this.state.dataSource} columns={this.state.columns} onChange={this.onDataSourceChange}/>
                <Row style={{marginTop : '20px'}}>
                    <Col span={24}>
                        <div className='space-bw' style={{ marginBottom: '20px' }}>
                            <span>
                                <Button type="primary" style={{marginRight: '10px',color:'#272A3A'}}
                                    htmlType="submit" onClick={this.handleSubmit}>
                                    确定
                                </Button>
                                <Button onClick={this.cancelEdit}>取消</Button>
                            </span>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Form.create({ name: 'SplitAreaPopView' })(SplitAreaPopView);