import React from "react";
import moment from "moment";
import { Button } from "antd";

import '../components/WorkListItem.less'
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function (props) {
  const { workName, workStatusName, startDate, endDate, showButton, orderCode, workType} = props;
  const date =
    moment(startDate).format("YYYY-MM-DD") +
    "~" +
    moment(endDate).format("YYYY-MM-DD");
   
  return (
    <div className="work-list-item">
      <div>{date}</div>
      <div>项目名称：{workName}</div>
      <div className="work-list-item-status">
        <div>状态：{workStatusName}</div>
        {/* <Link to={'/app/crm/orderManage/equipment_order/detail/' + orderCode} className="table-operate-btn"><Button>查看订单</Button></Link> */}
        {(showButton && workType == '2') && <Link to={'/app/crm/orderManage/equipment_order/detail/' + orderCode} className="table-operate-btn"><Button>查看订单</Button></Link>}
      </div>
    </div>
  );
}
