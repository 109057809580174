import { API } from "@/http/api";
import http from "@/http";
import { showErrorMessage } from "@/utils/globalMessageReminder";
import { formatLinkSearch } from "@/utils";
import React, { Component } from "react";
import "./EnterpriseUpgradeDetail.less";
import {
  Layout,
  Breadcrumb,
  Button,
  Row,
  Col,
  Tooltip,
  Modal,
  Input
} from "antd";
import { Link } from "react-router-dom";
import {
  convertAuthenticationToLabel,
  convertUpgradeToLabel
} from "CMPT/operate/customerConfig.js";
import { CommonFlag, AccountTypeFlag } from "CMPT/common.js";
import moment from "moment";
const { TextArea } = Input;

const { Content } = Layout;

class EnterpriseUpgradeDetail extends Component {
  //TODO state 太长了，可优化
  state = {
    code: null, // 当前页面的ID,从前一个页面传递过来的
    pageNo: null, // 当前企业在前一个列表中的页码
    currentageCount: null, // 前一个页面中 列表内当前页的数量
    companyName: null,
    creditCode: null,
    businessLicenseUrl: null,
    isSupplier: null,
    category: null,
    categoryDesc: null,
    managementModel: null,
    personalName: null,
    personalIdentity: null,
    personalPhone: null,
    personalEmail: null,
    authenticationTime: 1,
    upgradeStatus: null, // 认证状态（0、待认证 1、通过 2、驳回）
    rejectionReason: null,
    applyBy: null,
    applyTime: null,
    managementModelDesc: null, //经营模式，1生产厂家 2经销批发 3商业服务 4招商代理 5其它
    authenticationTime: null,
    shortName: null,
    havaEmail: null,
    enterpriseEmailDomain: null,
    upgradeTo: null,

    showPassConfirmFlag: false, // 是否展示通过 对话框
    showRejectConfirmFlag: false, // 是否展示 驳回 对话框

    rejectInput: "", //用户输入的驳回理由

    errorTitle: null, // 审核时失败信息 对话框的title
    errorMsg: null, // 审核时失败信息
    showErrorFlag: false, // 是否展示 审核失败信息

    backPageNo: 1 //返回列表页面的页码
  };

  componentWillMount() {
    if (this.props.location.search) {
      // 获取参数
      const search = this.props.location.search;
      const formatData = formatLinkSearch(search);

      // 解析
      let code1 = this.props.match.params.code;
      let pageNo1 = null;
      let currentageCount1 = null;

      if (formatData && formatData.pageNo) {
        pageNo1 = formatData.pageNo;
      }
      if (formatData && formatData.currentPageCount) {
        currentageCount1 = formatData.currentPageCount;
      }

      // 赋值
      this.setState({
        code: code1,
        pageNo: pageNo1,
        backPageNo: pageNo1, //如果什么都不做，返回列表的原始页码即可。否则，需要判断跳转哪一页，尤其删除一个页面中的唯一一条数据
        currentageCount: currentageCount1
      });
    }
  }

  componentDidMount() {
    this.getDetail();
  }

  refresh() {
    this.getDetail();
  }

  getDetail = () => {
    const config = {
      api: API.enterpriseUpgradeDetail,
      path: {
        encode: this.props.match.params.encode
      },
      showLoading: true
    };

    http(config).then(res => {
      if (res.code == 0) {
        let data = res.data;

        this.setState({
          ...data
        });
      }
    });
  };

  /* start: 审核驳回的相关逻辑  */

  hidenRejectAuthDialog = () => {
    this.setState({ showRejectConfirmFlag: false });
  };

  showRejectAuthDialog = () => {
    this.setState({ showRejectConfirmFlag: true });
  };

  onRejectInput = e => {
    this.state.rejectInput = e.target.value;
    //console.log('   onRejectInput  ', this.state.rejectInput);
  };

  gotoReject = () => {
    //console.log('   gotoReject  ', this.state.rejectInput);

    // 不能为null
    if (!this.state.rejectInput) {
      showErrorMessage("请输入驳回原因");
      return;
    }

    // 去掉两边可能的空格
    var rejectInput = this.state.rejectInput.replace(/(^\s*)|(\s*$)/g, "");
    if (rejectInput.length < 1) {
      showErrorMessage("请输入有效的驳回原因");
      return;
    }

    // 参数
    let selectParams = {
      code: this.state.code,
      upgradeStatus: 2,
      rejectionReason: rejectInput
    };

    // 去请求
    http({
      api: API.enterpriseUpgradeDo,
      data: selectParams,
      showLoading: true,
      successTip: "已驳回"
    })
      .then(result => {
        if (result.success) {
          this.hidenRejectAuthDialog();
          this.changeBackPageNo();
          this.refresh();
        } else if (result.code === "9998") {
          this.showErrorInfoDialog("驳回", result.msg);
        }
      })
      .catch(erorr => {
        //
      });
  };

  addRejectAuthDialog() {
    return (
      <Modal
        title="驳回"
        visible={true}
        okText="确定"
        cancelText="取消"
        closable={false}
        maskClosable={false}
        onOk={this.gotoReject}
        onCancel={this.hidenRejectAuthDialog}
        width={400}
      >
        <TextArea
          rows={4}
          onChange={this.onRejectInput}
          placeholder="请输入驳回理由 （100字以内）"
          maxLength={100}
        />
      </Modal>
    );
  }

  /* end: 审核驳回的相关逻辑  */

  /* start: 审核通过的相关逻辑  */
  // 添加 审核通过的组件，但不是立即展示
  addPassAuthDialog() {
    return (
      <Modal
        title={"通过"}
        visible={true}
        okText="确定"
        cancelText="取消"
        closable={false}
        maskClosable={false}
        onOk={this.gotoPass}
        onCancel={this.hiddenPassAuthDialog}
        width={400}
      >
        <div>
          {"确认通过升级" +
            this.state.companyName +
            "为" +
            convertUpgradeToLabel(this.state.upgradeTo) +
            "的审核？"}
        </div>
      </Modal>
    );
  }

  // 去展示 审核通过 对话框
  showPassAuthDialog = () => {
    this.setState({
      showPassConfirmFlag: true
    });
  };

  hiddenPassAuthDialog = () => {
    this.setState({
      showPassConfirmFlag: false
    });
  };

  gotoPass = () => {
    // 参数
    let selectParams = {
      code: this.state.code, //this.props.location.query.code,//this.props.match.params.id,
      upgradeStatus: 1
    };

    // 去请求
    http({
      api: API.enterpriseUpgradeDo,
      data: selectParams,
      showLoading: true,
      successTip: "已审核通过"
    })
      .then(result => {
        if (result.success) {
          this.hiddenPassAuthDialog();
          this.changeBackPageNo();
          this.refresh();
        } else if (result.code === "9998") {
          this.showErrorInfoDialog("通过", result.msg);
        }
      })
      .catch(erorr => {
        //
      });
  };

  /* end: 审核通过的相关逻辑  */

  // 判断返回上一页面，列表应该去哪一页
  changeBackPageNo() {
    let backPageNo = 1;
    if (this.state.pageNo == 1) {
      // 原来是第一页，回去还看第一页
      backPageNo = 1;
    } else {
      // 这里是 除了1 之外的页码
      if (this.state.currentageCount == 1) {
        backPageNo = this.state.pageNo - 1;
      } else {
        backPageNo = this.state.pageNo;
      }
    }

    this.setState({
      backPageNo: backPageNo
    });
  }

  /* 审核，通过或者驳回时 需要 */
  showErrorInfoDialog(title, msg) {
    this.setState({
      showPassConfirmFlag: false,
      showRejectConfirmFlag: false,
      errorTitle: title,
      errorMsg: msg,
      showErrorFlag: true
    });
  }

  hiddenAuthErrorInfoDialog = () => {
    this.setState({
      showPassConfirmFlag: false,
      showRejectConfirmFlag: false,
      errorTitle: null,
      errorMsg: null,
      showErrorFlag: false
    });
  };

  watchBigImage = src => {
    this.setState({
      bigImgSrc: src
    });
  };

  addErrorInfoDialog() {
    return (
      <Modal
        title={this.state.errorTitle}
        visible={true}
        footer={[
          <Button
            key="primary"
            style={{ float: "none" }}
            type="primary"
            onClick={this.hiddenAuthErrorInfoDialog}
            size="large"
          >
            我知道了
          </Button>
        ]}
        closable={false}
        maskClosable={false}
        width={400}
        onOk={this.hiddenAuthErrorInfoDialog}
      >
        <div>{this.state.errorMsg}</div>
      </Modal>
    );
  }

  /* 通过审核状态下的状态信息 */
  showPassInfo() {
    return (
      <div className="customer-detail-body">
        <label>审批信息</label>
        <div>
          <Row gutter={20}>
            <Col className="gutter-row" span={12}>
              审批通过时间：
              {this.state.upgradeTime
                ? moment(this.state.upgradeTime).format("YYYY-MM-DD HH:mm")
                : "-"}
            </Col>
          </Row>
          <Row gutter={20}>
            <Col className="gutter-row" span={12}>
              审批人：admin
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  /* 驳回状态下的状态信息 */
  showRejectInfo() {
    return (
      <div className="customer-detail-body">
        <label>审批信息</label>
        <div>
          <Row gutter={20}>
            <Col className="gutter-row" span={12}>
              审批驳回时间：
              {this.state.upgradeTime
                ? moment(this.state.upgradeTime).format("YYYY-MM-DD HH:mm")
                : "-"}
            </Col>
          </Row>

          <Row gutter={20}>
            <Col className="gutter-row" span={12}>
              审批驳回原因：{this.state.rejectionReason || "-"}
            </Col>
          </Row>
          <Row gutter={20}>
            <Col className="gutter-row" span={12}>
              审批人：admin
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  /* 底部按钮 */
  showAuthButton() {
    return (
      <div className="operate" style={{ marginBottom: 20 }}>
        <Button type="primary" onClick={this.showPassAuthDialog.bind(this)}>
          通过
        </Button>
        <Button onClick={this.showRejectAuthDialog.bind(this)}> 驳回 </Button>
      </div>
    );
  }

  showSuppplyInfo() {
    return (
      <div style={{ marginTop: "20px" }}>
        <div>供应商信息：</div>
        <div
          style={{
            border: "1px solid #535465",
            marginTop: "5px",
            padding: "10px"
          }}
        >
          <Row gutter={20}>
            <Col className="gutter-row" span={12}>
              主营类目：{this.state.categoryDesc}
            </Col>
          </Row>
          <Row gutter={20}>
            <Col className="gutter-row" span={12}>
              经营模式：{this.state.managementModelDesc}
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  shwoGraderInfo() {
    return (
      <div style={{ marginTop: "20px" }}>
        <div>园区信息：</div>
        <div
          style={{
            border: "1px solid #535465",
            marginTop: "5px",
            padding: "10px"
          }}
        >
          <Row gutter={20}>
            <Col className="gutter-row" span={12}>
              总部地址：{this.state.addressDesc}
            </Col>
          </Row>
          <Row gutter={20}>
            <Col className="gutter-row" span={12}>
              项目数量：{this.state.projectNumber}
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  render() {
    let myState = this.state;
    const { bigImgSrc } = this.state;

    return (
      <Content className="customer-detail-content">
        <div className="customer-detail-head flexbox">
          <Breadcrumb className="customer-detail-head-bread">
            <Breadcrumb.Item>
              <Link
                style={{ color: "#333" }}
                to={{
                  pathname: "/app/crm/enterprise_upgrade",
                  search: "backPageNo=" + myState.backPageNo
                }}
              >
                升级列表
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>升级详情</Breadcrumb.Item>
          </Breadcrumb>
          {/* <ul className="customer-detail-head-data data-detail">
                        {this.checkDispatchAuth(state) ? <li className="data-li"> <Button type="primary" onClick={this.showDeleteConfirm}>删除</Button> </li> : null}
                        <li className="data-li"> <Button type="primary" onClick={this.assign}>{state.acountStatus ? '停用' : '启用'}</Button> </li>
                        {this.checkEditAuth(state) ? <li className="data-li ">  <Button type="link" onClick={() => this.props.history.push('/app/crm/client/edit/' + id)}>编辑</Button>   </li> : null}
        </ul> */}
        </div>
        <Row gutter={24} className="customer-detail-content">
          <Col span={24} className="customer-detail-left">
            {[
              /*认证状态（0、待认证 1、通过 2、驳回） */
            ]}
            {myState.upgradeStatus == 1 ? this.showPassInfo() : ""}
            {myState.upgradeStatus == 2 ? this.showRejectInfo() : ""}

            {[
              /* 企业信息相关 */
            ]}
            <div className="customer-detail-body">
              <div>
                <Row gutter={20}>
                  <Col className="gutter-row" span={12}>
                    提交时间：
                    {myState.applyTime
                      ? moment(myState.applyTime).format("YYYY-MM-DD HH:mm")
                      : "-"}
                  </Col>
                </Row>
                <Row gutter={20}>
                  <Col className="gutter-row" span={12}>
                    企业名称：{myState.companyName}
                  </Col>
                </Row>

                {/*  <Row gutter={20}>
                                    <Col className="gutter-row" span={12}>
                                        企业简称：{state.shortName}
                                    </Col>
                                 </Row>
                                <Row gutter={20}>
                                    <Col className="gutter-row" span={12}>
                                        统一社会信用代码：{state.creditCode}
                                    </Col>

                                </Row>

                                <Row gutter={20}>
                                    <Col className="gutter-row" span={24}>
                                        营业执照 :
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="gutter-row gutter-row-pic" span={4}>
                                        <img style={{ width: '120px', height: '90px' }} src={state.businessLicenseUrl} alt="" onClick={() => { this.watchBigImage(state.businessLicenseUrl) }} />
                                    </Col>
                                </Row>

                                <Row gutter={20}>
                                    <Col className="gutter-row" span={12}>
                                        是否供应商：{state.isSupplier == 1 ? '是' : '否'}
                                    </Col>

                                </Row>*/}

                <Row gutter={20}>
                  <Col className="gutter-row" span={24}>
                    {"企业身份:" +
                      convertAuthenticationToLabel(myState.authenticationTo)}
                  </Col>
                </Row>

                <Row gutter={20}>
                  <Col className="gutter-row" span={24}>
                    {"升级成为:" + convertUpgradeToLabel(myState.upgradeTo)}
                  </Col>
                </Row>
                {myState.upgradeTo && myState.upgradeTo.indexOf("2") >= 0
                  ? this.showSuppplyInfo()
                  : ""}
                {myState.upgradeTo && myState.upgradeTo.indexOf("3") >= 0
                  ? this.shwoGraderInfo()
                  : ""}
              </div>
            </div>

            {[
              /* 个人信息相关 */
            ]}
            <div className="customer-detail-body">
              <div>
                <Row gutter={20}>
                  <Col className="gutter-row" span={24}>
                    申请人姓名：{myState.personalName}
                  </Col>
                </Row>

                <Row gutter={20}>
                  <Col className="gutter-row" span={24}>
                    申请人手机号：{myState.personalPhone}
                  </Col>
                </Row>
              </div>
            </div>

            {[
              /*认证状态（0、待认证 1、通过 2、驳回） */
            ]}
            {myState.upgradeStatus == 0 ? this.showAuthButton() : ""}
          </Col>
        </Row>

        {myState.showPassConfirmFlag ? this.addPassAuthDialog() : ""}
        {myState.showRejectConfirmFlag ? this.addRejectAuthDialog() : ""}
        {myState.showErrorFlag ? this.addErrorInfoDialog() : ""}

        {bigImgSrc ? (
          <div
            className="big_img"
            onClick={() => {
              this.setState({ bigImgSrc: null });
            }}
          >
            <img src={bigImgSrc} alt="" />
          </div>
        ) : null}
      </Content>
    );
  }
}

export default EnterpriseUpgradeDetail;
