import React, { Component } from 'react';
import { Modal } from 'antd';
import http from '@/http';
import { API } from '@/http/api';

class AuditModel extends Component {
    state = {
         visible: false,
         supplierId: ''
     }
    componentWillReceiveProps(nextProps){       
        this.setState({
            visible: nextProps.visible,
            supplierId: nextProps.supplierId
        })
    }
    componentDidMount(){
        // this.setState({
        //     visible: true
        // })
    }
    handleOk = (e) => {
        const config = {
            api: API.supplierSubmit,
            path: {encode: this.state.supplierId},
            showLoading: true
        }
        http(config).then(res => {
           if(res.success) {
            this.setState({
                visible: false,
            },() => {
                this.props.goList();
            });
           }        
        })     
    }
    handleCancel = (e) => {
        this.setState({
            visible: false,
        });
    }
    render() {
        return (
            <div>
                 <Modal
                    closable={false}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    width={400}
                    onCancel={this.handleCancel}
                    okText="提交"
                >
                    <p>确认提交审批？</p>
                </Modal>
                
            </div>
        );
    }

}


export default AuditModel;