import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NotYet from './NotYet'
import Schedule from './Schedule'
import './MyjobUpcoming.less'
import user from '@/utils/user'
const { hasAuth } = {...user}

class MyjobUpcoming extends Component {

    componentDidMount() {
        const authArray = [990204010101,990204010201,99030201,99030202,9902030301,99020102,99020101]
        let auth = authArray.reduce((acc,cur)=>{
            if(typeof(acc)==="boolean"){
                return acc||hasAuth(cur)
            }else{
                return hasAuth(acc)||hasAuth(cur)
            }
            
        })
        this.setState({
            isShowYet:auth
        })
        /*
            维修服务_查看 990204010101 
            投诉管理_查看 990204010201
            模板管理_合同模板 99030201
            模板管理_收据模板 99030202
            财务收支_账单管理_查看 9902030301
            审批列表 99020102
            合同列表 99020101
        */
    }

    getData() {

    }


    state = {
        isShowYet:true
    }

    render() {
        console.log('auth:'+this.state.isShowYet)
        const yetHtml = this.state.isShowYet ?  <NotYet /> : null
        return (
            <div className="job-container" id="jobContainer">
                {yetHtml}
                <Schedule yet={this.state.isShowYet} />
            </div>
        )
    }
}

export default MyjobUpcoming;
