import React, { Component } from 'react';
import { Upload, message, Icon, Tooltip,Row, Col } from 'antd';
import {
    API
} from '@/http/api';
import {formRequest} from '@/http';
import '../bill.less'

//由于附件需要上传的文件比较多，特地封装一下，无特殊需求的 antd的upload应该可以满足需求
const fileTypeArray = ['image/jpeg', 'jpg', 'png','jpeg']
class CxUpload extends Component {

    state = {
        fileList:[],
        loading:false,
    }


    handleChange = info => {
        if (info.file.status === 'uploading') {
          this.setState({ loading: true });
          return;
        }
      };

    beforeUpload = (file,fileList) => {
        console.log(file,fileList)
        const fileNameToArray = file.name.split('.');
        const fileType = fileNameToArray[fileNameToArray.length - 1];
        console.log(fileType)
        const isFile = !!fileTypeArray.find(item => item == fileType)
        console.log(isFile)
        if (!isFile) {
            message.error('只支持' + fileTypeArray.join() + '文件');
        }
        if (this.state.fileList && this.state.fileList.length>=3) {
            message.error('上传图片不得超过三张');
            return false;
        }
        console.log('文件大小:',file.size);
        const isLt5M = file.size / 1024 <= 5000;
        if (!isLt5M) {
            message.error('文件大小不能超过5M');
        }
        return isFile && isLt5M;
    }

    doUploadFile(value) {
        console.log(value)
        value.action = API.uploadAdd.url;
        const file = value.file;
        const fileNameToArray = file.name.split('.');
        const fileType = fileNameToArray[fileNameToArray.length - 1];
        formRequest(value).then(res=>{
            console.log(value)
            if(res.code==0){
                let data = res.data;
                if(!data){
                    message.error('上传失败，请重试')
                    return;
                }
                let fileList = this.state.fileList;
                fileList.push({
                    mediaExt: fileType,
                    mediaKey: data.key,
                    name: data.fileName,
                    type: fileType,
                    url:data.publicUrl,
                    uid:fileList.length-10
                })
               this.setState({
                   fileList:fileList,
                   loading: false,
               },()=>{
                    this.props.imgUp(this.state.fileList)
               })
           }
        })
    }
    remove(e){
        let arr = [];
        let temp = this.state.fileList;
        for(let obj of temp){
            if(obj.uid != e.uid){
                arr.push(obj);
            }
        }
        this.setState({
            fileList:arr
        },()=>{
            this.props.imgUp(this.state.fileList)
        })
    }
    componentWillReceiveProps(nextProps){
        if(!nextProps.fileList||nextProps.fileList.length==0){
            return;
        }
        const temp = nextProps.fileList;
        for(let [key,value] of temp.entries()){
            value.uid = key-10;
        }
        this.setState({
            fileList:nextProps.fileList
        })
    }
    render() {
        const { fileList } = this.state;
        const uploadButton = (
            <div>
                { !this.state.loading  ? <span style={{ fontSize: '24px' }} className='icon iconfont iconupload'></span> : <Icon style={{ fontSize: '42px' }} type='loading' />} 
                <div className="ant-upload-text" style={{fontSize:"12px",color:"#333"}}>
                    拖拽至此 或 
                    <div>点击上传</div> 
              </div>
            </div>
        );
        return (
            <span className="cx-upload">
                <Upload
                    action={API.uploadAdd.url}
                    listType="picture-card"
                    fileList={fileList}
                    beforeUpload={this.beforeUpload}
                    customRequest={this.doUploadFile.bind(this)}
                    onChange={this.handleChange}
                    onRemove={this.remove.bind(this)}
                    >
                    {uploadButton}
                </Upload>
            </span>           
        )
    }
}

export default CxUpload;
