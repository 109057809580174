import {
  AJAX_TYPE
} from '../config';

// 签约管理

export default {
  // 签约列表
  ['sign_getContractList']: {
    url: '/web/crm/contract/getContractList',
    type: AJAX_TYPE.POST
  },
  // 签约详情
  ['sign_getAuditDetail']: {
    url: '/web/crm/contract/getAuditDetail',
    type: AJAX_TYPE.GET
  },
  // 签约审核
  ['sign_auditContract']: {
    url: '/web/crm/contract/auditContract',
    type: AJAX_TYPE.POST
  },
  // 签约取消
  ['sign_cancel']: {
    url: '/web/crm/contract/cancel',
    type: AJAX_TYPE.POST
  },
  // 签约记录
  ['sign_sealList']: {
    url: '/web/crm/contract/sealList',
    type: AJAX_TYPE.GET
  },
  // 上传合同
  ['sign_uploadContract']: {
    url: '/web/crm/contract/uploadContract',
    type: AJAX_TYPE.POST
  },

}
