import React, {
    Component
} from 'react';
import {
    Route,
    Redirect,
    Switch
} from 'react-router-dom';

import getRouteConfig from './config';
import routeComponents from './routeComponents';
import sendEventPoint, { EVENT_TYPES, EVENT_NAMES } from '@/utils/eventPoint';

class RouteMap extends Component {
    static getRoute(route) {
        const routeConfig = getRouteConfig();
        const baseConfig = routeConfig[route];
        const subRoutes = baseConfig.subs;
        if (baseConfig.component) {
            const Component = routeComponents[baseConfig.component];
            let routeMap = [];
            if (!baseConfig.dispense && subRoutes && subRoutes.length > 0) {
                routeMap = subRoutes.map(RouteMap.getRoute);
            }
            routeMap.push(<Route key={route} path={route} exact={!baseConfig.dispense} render={props => {
                if (props.match.isExact) {
                    // 路由精确匹配，发送页面埋点
                    sendEventPoint(EVENT_TYPES.LOAD, EVENT_NAMES.PAGE_LOAD, window.location.href);
                }
                return <Component {...props} />
            }} />)
            return routeMap;
        } else {
            if (subRoutes && subRoutes.length > 0) {
                const subRouteMap = subRoutes.map(RouteMap.getRoute);
                subRouteMap.unshift(<Route exact path={route} render={() => <Redirect to={subRoutes[0]} push />} />);
                return subRouteMap;
            } else {
                return null;
            }
        }
    }

    render() {
        const { route } = this.props;
        const routeConfig = getRouteConfig();
        const baseConfig = routeConfig[route];
        const subRoutes = baseConfig.subs;
        if (subRoutes && subRoutes.length > 0) {
            return (
                <Switch>
                    <Route exact path={route} render={() => <Redirect to={subRoutes[0]} push />} />
                    {
                        subRoutes.map(RouteMap.getRoute)
                    }
                    <Route render={() => <Redirect to="/404" />} />
                </Switch>
            );
        } else {
            return null;
        }
    }
}

export default RouteMap;