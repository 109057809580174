import React, { Component } from "react";
import axios from "axios";
import { message } from "antd";
import Editor from "wangeditor";
import { API } from "@/http/api";
import userUtil from "@/utils/user";
import { formRequest } from "@/http";

//const { Dragger } = Upload;

export default class MyEditor extends Component {
  componentDidMount() {
    this.initEditor();
  }
  componentWillUnmount(){
     // 销毁编辑器
     this.editor.destroy()
     this.editor = null
  }


  doUpload(insert,byteArray, file) {

    axios.defaults.crossDomain = true;
     axios.defaults.withCredentials = true; //设置cross跨域 并设置访问权限 允许跨域携带cookie信息
    axios.defaults.headers.common["Authorization"] = ""; // 设置请求头为 Authorization

     var fileName =  file.name;
     var fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1);


  axios({
      method: 'put',
      url: "/api/open/fileUpload" + (fileExtension ? ("?suffix=" + fileExtension) : ''),
      data: new Blob([byteArray]),
      headers: {
          'Content-Type': 'multipart/form-data', // 关键
      },
  }).then((res) => {
      if(res && res.success && res.data &&res.data.absolutePath){
        insert(res.data.absolutePath);
      }else{
        message.error(res && res.success && res.data&& res.message ? res.message:'上传异常');
      }
  }).catch(err => {
     message.error(err||'上传异常');
  });

 };

  initEditor() {
    const  context =this;
    const editor = new Editor('#div')

    console.log(editor);
    this.editor = editor;

    // 设置编辑区域高度为 300px
    editor.config.height = 300

    editor.config.showFullScreen = true

    editor.config.zIndex = 100;
    editor.config.uploadImgServer = {};
    // 限制一次最多上传 1 张图片
    editor.config.uploadImgMaxLength = 1;
    editor.config.customUploadImg = function (files, insert) {
      // files 是 input 中选中的文件列表
      //console.log("iamges --->", files);
      if (files[0]) {
        var reader = new FileReader();
        reader.readAsArrayBuffer(files[0]);
        reader.onload = function () {
          context.doUpload(insert,reader.result, files[0]);
        };
      } else {
        message.info("请选择要上传的图片");
      }
    };
    editor.config.menus = [
      'head',
      'bold',
      'fontSize',
      'fontName',
      'italic',
      'underline',
      'strikeThrough',
      'indent',
      'lineHeight',
      'foreColor',
      'backColor',
      'link',
      'list',
      'todo',
      'justify',
      'quote',
      'emoticon',
      'image',
      'video',
      'table',
      'splitLine',
      'undo',
      'redo',
    ];
    editor.config.lang = {
      设置标题: "Title",
      字号: "Size",
      文字颜色: "Color",
      设置列表: "List",
      有序列表: "",
      无序列表: "",
      对齐方式: "Align",
      靠左: "",
      居中: "",
      靠右: "",
      正文: "p",
      链接文字: "link text",
      链接: "link",
      上传图片: "Upload",
      网络图片: "Web",
      图片link: "image url",
      插入视频: "Video",
      格式如: "format",
      上传: "Upload",
      创建: "init"
    };
    // 使用 onchange 函数监听内容的变化，并实时更新到 state 中
    editor.config.onchange = html => {
      /* this.setState({
                 formData: {
                     ...this.state.formData,
                     content: html,
                     pureText: editor.txt.text()
                 }
             })*/
      // console.log(this.state)
      //将html值设为form表单的desc属性值
      // this.props.form.setFieldsValue({
      //     'desc': html
      // });

      if (this.props.onChange) {
        this.props.onChange(html);
      }

      if (this.props.onChange) {
        this.props.onChange(html);
      }
    };

    // 关闭粘贴样式的过滤
    editor.config.pasteFilterStyle = false;
    // 忽略粘贴内容中的图片
    //editor.config.pasteIgnoreImg = true;

    // 自定义处理粘贴的文本内容
    /*editor.config.pasteTextHandle = function (content) {
      if (content == '' && !content) return ''
      var str = content
      str = str.replace(/<xml>[\s\S]*?<\/xml>/ig, '')
      str = str.replace(/<style>[\s\S]*?<\/style>/ig, '')
      str = str.replace(/<\/?[^>]*>/g, '')
      str = str.replace(/[ | ]*\n/g, '\n')
      str = str.replace(/&nbsp;/ig, '')
      return str;
    };*/

    editor.create();

    //console.log("  this.props.defaultValue --->", this.props.defaultValue);

    editor.txt.html(this.props.default?this.props.default.rollbackXss():undefined);
  }

  render() {
    return <div ref="editorElem" style={{ textAlign: "left", width:"90%" }} id="div"></div>;
  }
}
