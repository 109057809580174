import React, { Component } from 'react';
import { Modal,message } from 'antd';
import http from '@/http';
import { API } from '@/http/api';
class ApproveTemplate extends Component {
    state = { visible: false }
    componentWillReceiveProps(nextProps){
        
        this.setState({
            visible: nextProps.visible
        })
    }
    
    

    handleOk = (e) => {
        http({
            api:API.templatePass,
            path: {}, // 路径参数
            data: {
                encode: this.props.templateId,
                type:this.props.type,
            },
            showLoading: true // 是否显示全局加载
        }).then(res => {
            if (res.success) {
                this.setState({
                    visible: false,
                });
                this.props.reload(true)                    
                message.success('审批成功').then(()=>{
                })
            }
        })
    
        
    }

    handleCancel = (e) => {
        this.props.reload()
        this.setState({
            visible: false,
        });
    }
    render() {

        return (
            <div>
                <Modal
                    closable={false}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    okText='确定'
                    width={400}
                    onCancel={this.handleCancel}
                >
                    <p>是否确认审批通过此模版？</p>
                </Modal>
            </div>
        );
    }

}

export default ApproveTemplate