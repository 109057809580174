import React, { Component } from 'react'
import { Breadcrumb, Icon, Row, Col, Button, Popover } from 'antd';
import { Link } from 'react-router-dom'
import Tip from 'CMPT/common/Tip';
import "./finance.less"
import Table from 'CMPT/common/Table';
import CandPDetail from '../billManage/CandPDetail';
import ApprovalChain from 'CMPT/common/ApprovalChain';
import Modelmodel from 'CMPT/common/drawer/model';
import HistoryPayRecord from './HistoryPayRecord';
import BaseInfo from './BaseInfo'
import http from '@/http';
import { API } from '@/http/api';
const BreadcrumbItem = Breadcrumb.Item;

const iInfo = {
    t1: '收',
    t2: '付'
}
const oInfo = {
    t1: '付',
    t2: '收'
}

class Detail extends Component {
    state = {
        billInfo: {},
        stepList: [],
        auditStatus: 0,
        columns: [
            {
                title: '时间',
                dataIndex: 'createTime',
            }, {
                title: '操作人',
                dataIndex: 'createUserName',
            }, {
                title: '操作类型',
                dataIndex: 'operateType',
            }, {
                title: '备注',
                key: 'content',
                width: 450,
                render: (obj) => {
                    return (
                        <div>{obj.content}</div>
                    )
                }
            }
        ],
        data: [],
        textDes: {
            t1: '收',
            t2: '付'
        },
        isShouFu: false,
        isRefund: false,
        paymentEncode: undefined,
        paymentInfo: {},
        refundEncode: undefined,
        refundInfo: {},
        searchType: 0,
        auditObj: {},
    }
    componentDidMount() {
        console.log('=======================')

        this.getRecord();
        if (this.state.paymentEncode) {
            this.getPaymentInfo();
            if (this.state.searchType == 1) {
                this.getAuditFlowList(34, this.state.paymentEncode)
            }
            if (this.state.searchType == 2) {
                this.getAuditFlowList(32, this.state.paymentEncode)
            }
        } else if (this.state.refundEncode) {
            this.getRefundInfo();
            this.getAuditFlowList(33, this.state.refundEncode)
        } else {
            this.getInfo();
            this.getAuditFlowList(31, this.props.match.params.id)
        }
    }
    componentWillMount() {
        if (this.props.location.search) {
            let arr = this.props.location.search.substr(1).split('&');
            let type = undefined;
            let paymentEncode = undefined;
            let refundEncode = undefined;
            if (arr[0]) {
                type = arr[0].split('=')[1];
            }
            if (arr[1]) {
                console.log(arr[1].split('=')[1])
                paymentEncode = ('paymentEncode' == arr[1].split('=')[0]) ? arr[1].split('=')[1] : undefined;
                refundEncode = ('refundEncode' == arr[1].split('=')[0]) ? arr[1].split('=')[1] : undefined;
            }
            this.setState({
                paymentEncode: paymentEncode,
                refundEncode: refundEncode,
                searchType: type
            })
            if (type == 1) {
                this.setState({
                    textDes: iInfo,
                    isShouFu: true,
                    isBill: false
                })
            }
            else if (type == 2) {
                this.setState({
                    textDes: oInfo,
                    isShouFu: true,
                    isBill: false
                })
            }
            else if (type == 3) {
                this.setState({
                    isRefund: true,
                    isBill: false
                })
            }
        }
    }
    getAuditFlowList(type, code) {
        http({
            api: API.billGetAuditFlowList,
            data: {
                encode: code,
                type: type
            }
        }).then(res => {
            if (res.data) {
                this.setState({
                    stepList: res.data
                })
            }
        })
    }
    getRefundInfo() {
        http({
            api: API.billrefundGetById,
            data: {
                encode: this.state.refundEncode
            }
        }).then(res => {
            if (res.data) {
                this.setState({
                    refundInfo: res.data,
                    auditObj: res.data,
                    billInfo: res.data.billVo,
                })
            }
        })
    }
    getPaymentInfo() {
        http({
            api: API.billpayGetById,
            data: {
                encode: this.state.paymentEncode
            }
        }).then(res => {
            if (res.data) {
                this.setState({
                    paymentInfo: res.data,
                    billInfo: res.data.billVo,
                    auditObj: res.data
                })
            }
        })
    }
    getRecord() {
        let bizType = 15;
        let code = this.props.match.params.id;
        if (this.state.paymentEncode) {
            bizType = 16;
            code = this.state.paymentEncode
        }
        if (this.state.refundEncode) {
            bizType = 17;
            code = this.state.refundEncode
        }
        http({
            api: API.billAuditRecord,
            data: {
                bizEncode: code,
                bizType: bizType
            }
        }).then(res => {
            if (res.data) {
                this.setState({
                    data: res.data
                })
            }
        })
    }
    getInfo() {
        http({
            api: API.billGetById,
            data: {
                encode: this.props.match.params.id
            }
        }).then(res => {
            if (res.data) {
                console.log("loadCaptcha--------------", res.data);
                this.setState({
                    billInfo: res.data,
                })
                if (!this.state.paymentEncode && !this.state.refundEncode) {
                    this.setState({
                        auditObj: res.data
                    })
                }
            }
        })
    }
    render() {
        const { isShouFu, textDes, isRefund, paymentInfo, refundInfo, auditObj } = this.state;
        const auditComponent = (
            <Popover overlayClassName="bo-audit-popover" content={<ApprovalChain stepList={this.state.stepList} />} trigger="click" placement="bottomRight">
                <div className="clickPopover"><i className="iconfont iconapprove"></i><span>查看审批流程</span></div>
            </Popover>
        )
        const ImgList = paymentInfo.photos && paymentInfo.photos.map(obj => {
            return (
                <img src={obj.url} key={obj.id} />
            )
        })
        const titleTxt = ['账单', '收款', '付款', '退款'];
        const payChannel = ['现金', '刷卡', '转账', '支付宝', '微信'];
        return (
            <div className="contract-list-out">
                <div className="contract-header flexCenter">
                    <Breadcrumb>
                        <BreadcrumbItem>

                            <Link style={{ color: '#333' }} to={{ pathname: "/app/operate/finance/approval" }}>
                                财务收支
                            </Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <Link style={{ color: '#333' }} to={{ pathname: "/app/operate/finance/approval" }}>
                                财务审批
                            </Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem>{titleTxt[this.state.searchType]}详情</BreadcrumbItem>
                    </Breadcrumb>
                    <span style={{ display: isShouFu ? '' : 'none' }}>
                        <HistoryPayRecord {...this.props} {...this.state} />
                    </span>
                </div>
                <div style={{ display: auditObj.auditStatus == 0 ? '' : 'none' }}>
                    <Tip type="wait" title="审批中" hint={true} secondTitle={'当前审批人：' + auditObj.auditUserName} hintNode={auditComponent} />
                </div>
                <div style={{ display: auditObj.auditStatus == 1 ? '' : 'none' }}>
                    <Tip type="warn" title="审批驳回" hint={true} hintNode={auditComponent} des={auditObj.auditComment} />
                </div>
                <div style={{ display: auditObj.auditStatus == 2 ? '' : 'none' }}>
                    <Tip type="pass" title="审批通过" hint={true} hintNode={auditComponent} />
                </div>
                <BaseInfo {...this.state} {...this.props} noshowOverdue={true} />
                <div style={{ display: isShouFu ? '' : 'none' }}>
                    <div className="approval-baseInfo">
                        <div className="title">{textDes.t1}款信息</div>
                        <Row className="fs12 b12">
                            <Col span={8}>本次{textDes.t1}款金额：{paymentInfo.amount}元</Col>
                            <Col span={8}>{textDes.t1}款日期：{paymentInfo.date}</Col>
                        </Row>
                        <Row className="fs12 b12">
                            <Col span={8}>支付途径：{paymentInfo.channel == 9 ? '其他' : payChannel[paymentInfo.channel - 1]}</Col>
                            <Col span={8}>银行流水：{paymentInfo.bankStatementNo}</Col>
                            <Col span={8}>票据号：{paymentInfo.receiptNo}</Col>
                        </Row>
                        <Row className="fs12 b12">
                            <Col span={8}>{textDes.t1}款银行：{paymentInfo.companyBank}</Col>
                            <Col span={8}>{textDes.t1}款账号：{paymentInfo.companyAccount}</Col>
                        </Row>
                        <Row className="fs12 b12">
                            <Col span={24}>备注：{paymentInfo.description}</Col>
                        </Row>
                        <Row className="fs12 b12">
                            <Col className="b12" span={24}>相关凭证：</Col>
                            <Col span={24}>
                                {ImgList}
                            </Col>
                        </Row>
                    </div>
                    <div className="approval-baseInfo">
                        <div className="title">{textDes.t2}款信息</div>
                        <Row className="fs12 b12">
                            <Col span={8}>{textDes.t2}款人：{paymentInfo.customerName}</Col>
                            <Col span={8}>{textDes.t2}款人手机号：{paymentInfo.customerPhone}</Col>
                            <Col span={8}>{textDes.t2}款银行：{paymentInfo.bank}</Col>
                        </Row>
                        <Row className="fs12 b12">
                            <Col span={8}>{textDes.t2}款账号：{paymentInfo.account}</Col>
                        </Row>
                    </div>
                </div>
                <div style={{ display: isRefund ? '' : 'none' }}>
                    <div className="approval-baseInfo">
                        <div className="title">退款信息</div>
                        <Row className="fs12 b12">
                            <Col span={8}>退款金额：{refundInfo.amount}元</Col>
                            <Col span={8}>退款申请日期：{refundInfo.createTime}</Col>
                        </Row>
                        <Row className="fs12 b12">
                            <Col span={24}>退款原因：{refundInfo.reason}</Col>
                        </Row>
                    </div>
                </div>
                <div className="approval-baseInfo">
                    <div className="title">操作日志</div>
                    <div className="infor-table">
                        <Table rowKey="encode" columns={this.state.columns} data={this.state.data} pagination={false} />
                    </div>
                </div>
            </div>
        )
    }
}

export default Detail;