import { API } from '@/http/api';
import http from '@/http';

function taskAdd(data){
    let  config = {
        api: API.taskAdd,
        data:data
    }
    return http(config)
}

function taskCancel(data){
    let  config = {
        api: API.taskCancel,
        path:data
    }
    return http(config)
}

function taskCompletedTasks(data){
    let  config = {
        api: API.taskCompletedTasks,
        isLoading:true,
        data:data
    }
    return http(config)
}

function taskEdit(data){
    let  config = {
        api: API.taskEdit,
        isLoading:true,
        data:data
    }
    return http(config)
}


function taskSwitchStatus(data){
    let  config = {
        api: API.taskSwitchStatus,
        isLoading:true,
        path:data
    }
    return http(config)   
}

function taskToday(){
    let  config = {
        api: API.taskTodayTasks,
    }
    return http(config)   
}

function unCompleteTasks(data){
    let  config = {
        api: API.taskUnCompleteTasks,
        isLoading:true,
        data:data
    }
    return http(config)   
}






export {
    taskAdd,
    taskCancel,
    taskCompletedTasks,
    taskEdit,
    taskSwitchStatus,
    taskToday,
    unCompleteTasks
}