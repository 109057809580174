import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom';
import './LogisticsOrderDetail.less'
import { API } from '@/http/api';
import http from '@/http';
import { Breadcrumb, Button, Modal, Input, message } from 'antd';
import IMGurl from '@/images/empty_schedule@2x.png'
import { showErrorMessage, } from '@/utils/globalMessageReminder';
import { formatLinkSearch } from '@/utils';
import moment from 'moment';
import ImgPreview from 'CMPT/common/ImgPreview';

const { TextArea } = Input;

const ownerInfoData = [
  { label: "昵称", field: "goodsOwnerNickname" },
  { label: "姓名", field: "goodsOwnerName" },
  { label: "手机号", field: "goodsOwnerTelephone" },
  { label: "货主身份", field: "goodsOwnerIdentityName" },
]

const baseInfo = [
  { label: "运输方类型", field: "driverIdentityName" },
  { label: "司机姓名", field: "driverName" },
  { label: "司机联系电话", field: "driverMobile" },
  { label: "驾龄", field: "driverDrivingExperience" },
  { label: "紧急联系人", field: "emergencyContactName" },
  { label: "紧急联系人电话", field: "emergencyContactTelephone" },
  { label: "车牌号", field: "plate" },
  { label: "车型", field: "typeName" },
  { label: "车长", field: "length" },
  { label: "载量", field: "deadWeight" },
]

const paymentInfo = [
  { label: "支付方式", field: "payModeName" },
  { label: "支付时间", field: "payDate" },
  { label: "订单总额", field: "totalPrice" },
  { label: "收款人", field: "payeeUserName" },
  { label: "收款人联系方式", field: "payeeMobile" },
]

const logisticsOrderInfo = [
  { label: "订单编号", field: "subOrderNumber" },
  { label: "订单状态", field: "subOrderStatusName" },
  { label: "下单平台", field: "placeOrderPlatformName" },
  { label: "装货时间", field: "installGoodsDate" },
  { label: "装货量", field: "installGoodsWeightDesc" },
  { label: "装货备注", field: "installGoodsRemark" },
  { label: "装货填写人", field: "installGoodsUserName" },
  { label: "卸货时间", field: "dischargeGoodsDate" },
  { label: "卸货量", field: "dischargeGoodsWeightDesc" },
  { label: "回程物品", field: "backTransportGoods" },
  { label: "回程物品数量", field: "backTransportGoodsNumber" },
  { label: "卸货填写人", field: "dischargeGoodsUserName" },
  { label: "卸货备注", field: "dischargeGoodsRemark" },
  { label: "订单创建时间", field: "createDate" },
  { label: "订单确认时间", field: "confirmDate" },
  { label: "订单取消时间", field: "cancelDate" },
  { label: "订单完成时间", field: "completeDate" },
]
const invoiceOrderInfo = [
  { label: "发货单号", field: "freightNo" },
  { label: "货物类型", field: "freightGoodsTypeName" },
  { label: "货物量", field: "freightGoodsWeightDesc" },
  { label: "已装", field: "totalInstallGoodsWeightDesc" },
  { label: "已卸", field: "totalDischargeGoodsWeightDesc" },
  { label: "已损耗", field: "totalUllageDesc" },
  { label: "是否跟车", field: "carFollowingFlagName" },
  { label: "服务要求", field: "freightServicesName" },
  { label: "服务备注", field: "freightServicesRemark" },
  { label: "发货人姓名", field: "loadingName" },
  { label: "联系方式", field: "installGoodsTelephone" },
  { label: "期望装货时间", field: "expectLoadingDateStart" },
  { label: "发货人地址", field: "installGoodsAddress" },
  { label: "收货人姓名", field: "unloadingName" },
  { label: "联系方式", field: "dischargeGoodsTelephone" },
  { label: "期望卸货时间", field: "expectUnloadingDateStart" },
  { label: "收货人地址", field: "dischargeGoodsAddress" },
  { label: "发票信息", field: "freightInvoiceName" },
]


class LogisticsOrderDetail extends Component {
  state = {
    code: null, // 当前页面的ID,从前一个页面传递过来的
    pageNo: null, // 当前企业在前一个列表中的页码
    currentageCount: null, // 前一个页面中 列表内当前页的数量
    backPageNo: 1, //返回列表页面的页码
    rejectInput: '', // 驳回原因
    data: [],
    showRejectConfirmFlag: false, // 是否展示 驳回 对话框
    auditRecords: [], // 审核记录
    previewVisible: false,
    IMGURL: '', // 缩放图
    modalTitle: '',
    modalPlaceholder: '',
    modalNum: null,

  }
  componentWillMount() {

    if (this.props.location.search) {
      // 获取参数
      const search = this.props.location.search
      const formatData = formatLinkSearch(search)

      // 解析
      let code1 = this.props.match.params.code;
      let pageNo1 = null;
      let currentageCount1 = null;

      if (formatData && formatData.pageNo) {
        pageNo1 = formatData.pageNo
      }
      if (formatData && formatData.currentPageCount) {
        currentageCount1 = formatData.currentPageCount
      }

      // 赋值
      this.setState({
        code: code1,
        pageNo: pageNo1,
        backPageNo: pageNo1, //如果什么都不做，返回列表的原始页码即可。否则，需要判断跳转哪一页，尤其删除一个页面中的唯一一条数据
        currentageCount: currentageCount1,
      });
    }
    this.cDetail();

  }
  componentDidMount() { }

  cDetail = () => {
    console.log(this.props, 'this.props')
    const config = {
      api: API.orderDetailManageLogisticsOrder,
      path: { code: this.props.match.params.code },
    };
    http(config).then(res => {
      if (res && res.code == 200) {
        let { data } = res;
        if (data.length) { // 车长
          data.length = data.length + '米'
        }
        if (data.deadWeight) { // 载量
          data.deadWeight = data.deadWeight + 'kg'
        }
        if (data.freightGoodsWeight) { // 货物重量
          data.freightGoodsWeight = data.freightGoodsWeight + '吨'
        }
        if (data.freightGoodsVolume) { // 货物体积
          data.freightGoodsVolume = data.freightGoodsVolume + '方'
        }
        if (data.carFollowingFlagName == '是' && data.carFollowingName && data.carFollowingMobile) { // 跟车
          console.log(data.carFollowingFlagNamem, 1111111111111111111111111111111);
          invoiceOrderInfo.splice(8, 0, { label: "跟车人姓名", field: "carFollowingName" })
          invoiceOrderInfo.splice(9, 0, { label: "跟车人电话", field: "carFollowingMobile" })
        }
        data.totalPrice = data.includedTax ? data.totalPrice + '（含税）' : data.totalPrice + '（不含税）'
        this.setState({
          data
        });
      }
    })
  }
  // onRejectInput = (e) => {
  //   this.setState({
  //     rejectInput: e.target.value
  //   })
  // };
  // // 去展示 审核通过 对话框
  // showPassAuthDialog = () => {
  //   console.log(this.state.data, 'this.state.data')
  //   let config = {
  //     api: API.sourcingTcAuditDoAudit,
  //     data: {
  //       auditStatus: 10,
  //       code: this.props.match.params.code,
  //     },
  //   }
  //   http(config).then(res => {
  //     console.log(res, 'res1111111111111111111')
  //     if (res.code == 200) {
  //       message.success('操作成功')
  //       this.cDetail()
  //     } else {
  //       message.error(res.message)
  //     }
  //   })
  // }

  // gotoReject = () => {
  //   const { modalNum } = this.state
  //   let strReason = modalNum == 10 ? "请输入下架原因" : "请输入驳回原因"
  //   let regStrReason = modalNum == 10 ? "请输入有效的下架原因" : "请输入有效的驳回原因"
  //   // 不能为null
  //   if (!this.state.rejectInput) {
  //     showErrorMessage(strReason);
  //     return;
  //   }
  //   // 去掉两边可能的空格
  //   var rejectInput = this.state.rejectInput.replace(/(^\s*)|(\s*$)/g, "");
  //   if (rejectInput.length < 1) {
  //     showErrorMessage(regStrReason);
  //     return;
  //   }
  //   let data = modalNum == 10 ? {
  //     code: this.props.match.params.code,
  //     auditRemark: this.state.rejectInput,
  //   } : {
  //     code: this.props.match.params.code,
  //     auditStatus: 20,
  //     auditRemark: this.state.rejectInput,
  //   }
  //   let config = {
  //     api: modalNum == 10 ? API.sourcingTcAuditForcedDown : API.sourcingTcAuditDoAudit,
  //     data,
  //   }
  //   http(config).then(res => {
  //     console.log(res, 'res 驳回、下架')
  //     if (res.code == 200) {
  //       this.setState({ showRejectConfirmFlag: false });
  //       message.success('操作成功')
  //       this.cDetail()
  //     } else {
  //       this.setState({ showRejectConfirmFlag: false });
  //       message.error(res.message)
  //     }
  //   })

  // }
  /* start: 审核驳回的相关逻辑  */
  // hidenRejectAuthDialog = () => {
  //   this.setState({ showRejectConfirmFlag: false });
  // };

  // showRejectAuthDialog = () => { // 驳回
  //   this.setState({ 
  //     showRejectConfirmFlag: true,
  //     modalTitle: '驳回',
  //     modalPlaceholder: '请输入驳回理由 （100字以内）',
  //     modalNum: 0,
  //   });
  // };

  // showForcedDialog = () => { // 强制下架
  //   this.setState({ 
  //     showRejectConfirmFlag: true,
  //     modalTitle: '强制下架',
  //     modalPlaceholder: '请输入下架理由 （100字以内）',
  //     modalNum: 10,
  //   });
  // };

  // 获取审批记录
  watchRecord = () => {
    let recordHtml = null
    const config = {
      api: API.sourcingTcAuditAuditRecord,
      path: {
          code: this.props.match.params.code
      },
    }
    http(config).then(res => {
        if (res && res.code == 200) {
            let auditRecords = res.data || []
            const recordContent = auditRecords && auditRecords.length > 0 ? auditRecords.map(item => {
              return <div className="record_item">
                <div className="status">{item.auditStatusName}</div>
                {item.auditRemark ? <div className="info_item">
                  <span>理由：</span>
                  <span>{item.auditRemark}</span>
                </div> : ''}
                <div className="info_item">
                  <span>审批人：</span>
                  <span>{item.createUserName}</span>
                </div>
                <div className="info_item">
                  <span>时间：</span>
                  <span>{item.createDate ? moment(item.createDate).format('YYYY-MM-DD HH:mm') : '-'}</span>
                </div>
              </div>
            }) :
              <div className='log-no-data'><div className="log-no-data-text">暂无数据</div></div>;
        
            recordHtml = <div className="sp_records">{recordContent}</div>;
        
          }
          Modal.info({
            title: '审批记录',
            icon: null,
            content: recordHtml
          });
        })

  }

  // 设置图片
  closeImagePreview = () => {
    this.setState({ previewVisible: false });
  }
  // 设置图片缩放
  cImage = (value, currentIndex = 0) => {
    if (!value) return;
    this.setState({
      previewVisible: true,
      // IMGURL: value,
      imgList: [value],
      currentIndex: currentIndex
    });
  };

  render() {
    const { data, IMGURL,imgList, currentIndex, previewVisible, modalTitle, modalPlaceholder } = this.state
    return (
      <div className="userInfo">
        <div>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={{ pathname: "/app/crm/orderManage", search: '?tab=2' }}>
                物流订单
              </Link>
            </Breadcrumb.Item >
            <Breadcrumb.Item>
              订单详情
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div className="userInfo_body">
          <div>
            <h2><i></i>  货主信息</h2>
            <div>
              <ul>
                {ownerInfoData.map(item => {
                  return (
                    <li key={item.field}><span>{item.label}</span><span className="span">{data[item.field] ? data[item.field] : '-'}</span></li>
                  )
                })}
              </ul>
            </div>
          </div>

          <div>
            <h2><i></i>  司机/企业信息</h2>
            <ul>
              {baseInfo.map(item => {
                return (
                  <li key={item.field}><span>{item.label}</span><span className="span">{data[item.field] ? data[item.field] : '-'}</span></li>
                )
              })}
            </ul>
          </div>

          <div>
            <h2><i></i>  付款信息</h2>
            <ul>
              {paymentInfo.map(item => {
                return (
                  <li key={item.field}><span>{item.label}</span><span className="span">{data[item.field] ? data[item.field] : '-'}</span></li>
                )
              })}
              <li className="body_img"><span style={{width: '75px'}}>付款凭证</span>
                {data.payFileList && data.payFileList.length > 0 ? data.payFileList.map((item, index) => <img key={index} src={item.url} alt="" onClick={() => this.cImage(item.url)} />) : '-'}
                {/* <Modal visible={previewVisible} title=' ' footer={null} onCancel={this.closeImagePreview}>
                  <img alt="图片预览" style={{ width: '100%', margin: '0 0 24px' }} src={IMGURL} />
                </Modal> */}
              </li>
            </ul>

          </div>

          <div>
            <h2><i></i>  物流单信息</h2>
            <div>
              <ul>
                {logisticsOrderInfo.map(item => {
                  return (
                    <li key={item.field}><span>{item.label}</span><span className="span">{data[item.field] ? data[item.field] : '-'}</span></li>
                  )
                })}
                <li className="body_img"><span style={{width: '75px'}}>装货凭证</span>
                {data.installGoodsFileList && data.installGoodsFileList.length > 0 ? data.installGoodsFileList.map((item, index) => <img key={index} src={item.url} alt="" onClick={() => this.cImage(item.url)} />) : '-'}
                {/* <Modal visible={previewVisible} title=' ' footer={null} onCancel={this.closeImagePreview}>
                  <img alt="图片预览" style={{ width: '100%', margin: '0 0 24px' }} src={IMGURL} />
                </Modal> */}
              </li>
              <li className="body_img"><span style={{width: '75px'}}>卸货凭证</span>
                {data.dischargeGoodsFileList && data.dischargeGoodsFileList.length > 0 ? data.dischargeGoodsFileList.map((item, index) => <img key={index} src={item.url} alt="" onClick={() => this.cImage(item.url)} />) : '-'}
                {/* <Modal visible={previewVisible} title=' ' footer={null} onCancel={this.closeImagePreview}>
                  <img alt="图片预览" style={{ width: '100%', margin: '0 0 24px' }} src={IMGURL} />
                </Modal> */}
              </li>
              </ul>
            </div>
          </div>

          <div>
            <h2><i></i>  发货单相关信息</h2>
            <div>
              <ul>
                {invoiceOrderInfo.map(item => {
                  return (
                    <li key={item.field}><span>{item.label}</span><span className="span">{data[item.field] ? data[item.field] : '-'}</span></li>
                  )
                })}
                <li className="body_img"><span style={{width: '75px'}}>货物图片</span>
                {data.goodsPhotoList && data.goodsPhotoList.length > 0 ? data.goodsPhotoList.map((item, index) => <img key={index} src={item.url} alt="" onClick={() => this.cImage(item.url)} />) : '-'}
                {/* <Modal visible={previewVisible} title=' ' footer={null} onCancel={this.closeImagePreview}>
                  <img alt="图片预览" style={{ width: '100%', margin: '0 0 24px' }} src={IMGURL} />
                </Modal> */}
              </li>
              </ul>
            </div>
          </div>
          {/* <div className="approval-record" onClick={this.watchRecord}>审批记录</div> */}
        </div>
        {/* {data.auditStatus == 0 ?
          (<div className="detail_btn">
            <Button type="primary" onClick={this.showPassAuthDialog.bind(this)}> 通过 </Button>
            <Button onClick={this.showRejectAuthDialog.bind(this)}> 驳回  </Button>
          </div>) : null
        }
        {data.auditStatus == 10 ?
          (<div className="detail_btn">
            <Button type="primary" onClick={this.showForcedDialog.bind(this)}> 强制下架 </Button>
          </div>) : null
        } */}
        <Modal
          title={modalTitle}
          visible={this.state.showRejectConfirmFlag}
          okText='确定'
          cancelText='取消'
          closable={false}
          maskClosable={false}
          onOk={this.gotoReject}
          onCancel={this.hidenRejectAuthDialog}
          width={400} >
          <TextArea rows={4} onChange={this.onRejectInput} placeholder={modalPlaceholder} maxLength={100} />
        </Modal>
        
        <ImgPreview show={previewVisible} current={currentIndex} imgList={imgList} onClose={this.closeImagePreview}/>

      </div>
    )
  }
}
export default LogisticsOrderDetail;
