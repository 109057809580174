import React, { Component } from "react";
import { Form, Select, Input, Button, DatePicker } from "antd";
import moment from "moment";
import { API } from "@/http/api";
import http from "@/http";

const { Option } = Select;
const { RangePicker } = DatePicker;

class SearchForm extends Component {
  state = {};

  reset = () => {
    const { form } = this.props;
    form.resetFields();
    this.props.form.validateFields({ force: true }, (err, values) => {
      console.log(err, values, "err, values");
      if (!err) {
        this.props.reset(values);
        // this.props.onSearch()
      }
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form layout="inline">
     
        {/* <Form.Item label="发布类型">
          {getFieldDecorator("auditStatus")(
            <Select allowClear placeholder="全部" style={{ width: "100px" }}>
              <Option key="" value={-1}>
                全部
              </Option>
              <Option key="0" value={0}>
                招标公告
              </Option>
              <Option key="1" value={1}>
                中标公告
              </Option>
              <Option key="2" value={2}>
                废标公告
              </Option>
            </Select>
          )}
        </Form.Item> */}
          <Form.Item label="发布人">
          {getFieldDecorator("publisher", {
            rules: [{ required: false, message: "123" }],
          })(<Input style={{ width: "200px" }} placeholder="姓名/手机号码" />)}
        </Form.Item>

        <Form.Item label="">
          {getFieldDecorator("corp", {
            rules: [{ required: false, message: "123" }],
          })(<Input style={{ width: "200px" }} placeholder="所属企业" />)}
        </Form.Item>
        <Form.Item label="招标状态">
          {getFieldDecorator("status")(
            <Select allowClear placeholder="全部" style={{ width: "100px" }}>
              <Option key="" value={-1}>
                全部
              </Option>
              <Option key="0" value={1}>
                待发布
              </Option>
              <Option key="1" value={2}>
                进行中
              </Option>
              <Option key="2" value={3}>
                报名截止
              </Option>
              <Option key="2" value={4}>
                已成交
              </Option>
              <Option key="2" value={5}>
                已作废
              </Option>
              <Option key="2" value={6}>
                投标截止
              </Option>
            </Select>
          )}
        </Form.Item>

        <Form.Item label="">
          {getFieldDecorator("title", {
            rules: [{ required: false, message: "" }],
          })(<Input style={{ width: "200px" }} placeholder="标题" />)}
        </Form.Item>
        
        <Form.Item label="">
          <Button type="primary" onClick={this.props.onSearch}>
            搜索
          </Button>
        </Form.Item>
        <Form.Item label="">
          <a className="reset" onClick={this.reset}>
            重置
          </a>
        </Form.Item>
      </Form>
    );
  }
}

const onValuesChanges = (props, changedValues, allValues) => {
  let result = { ...allValues, pageNo: 1 };

  for (let key in result) {
    if (result[key] == undefined) {
      result[key] = "";
    }
  }
  props.onValuesChange(result);
};

export default Form.create({
  name: "EnterpriseAuthList",
  onValuesChange: onValuesChanges,
})(SearchForm);
