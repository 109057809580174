import React, { Component } from 'react';
import { Modal,message } from 'antd';
import http from '@/http';
import { API } from '@/http/api';
class DeleteTemplate extends Component {
    state = { visible: false }
    componentWillReceiveProps(nextProps){        
        this.setState({
            visible: nextProps.visible
        })
    }
    
    handleOk = (e) => {
        // 删除模板
        http({
            api: API.templateDelete,
            path: {}, // 路径参数
            data: {
                encode:this.props.templateId
            },
            showLoading: true // 是否显示全局加载
        }).then(res => {
            if(res.success){
                this.setState({
                    visible: false,
                });
                this.props.reload(true)
                message.success('该模板已删除');
            }
        })
        
    }

    handleCancel = (e) => {
        this.props.reload()
        this.setState({
            visible: false,
        });
    }
    render() {

        return (
            <div>
                <Modal
                    closable={false}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    okText='删除'
                    width={400}
                    onCancel={this.handleCancel}
                >
                    <p>模版删除后不可恢复，是否确认删除此模版？</p>
                </Modal>
            </div>
        );
    }

}

export default DeleteTemplate