import React, { Component } from 'react';
import { Form, Select, Input, Button, DatePicker } from 'antd';
import moment from 'moment';
import { API } from '@/http/api';
import http from '@/http';
import { IdentityType, CertType, AuthenticationTypes } from "./enum";

const { Option } = Select;
const { RangePicker } = DatePicker;

class SearchForm extends Component{
  state = { }

  reset = () => {
    const { form } = this.props;
    form.resetFields();
    this.props.form.validateFields(
      { force: true },
      (err, values) => {
        console.log(err, values, 'err, values')
        if (!err) {
          this.props.reset(values);
          // this.props.onSearch()
        }
      },
    );
  }
  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <Form layout='inline'>
        {!this.props.types ? null : (
          <>
          <Form.Item label="身份类型">
          {getFieldDecorator('identityType')(
            <Select allowClear placeholder="全部" style={{width: '100px'}}>
              <Option key="-1" value={-1}>
                全部
              </Option>
              {IdentityType.toSelectArray().map(item => (
                <Option key={item.id} value={item.id}>
                  {item.text}
                </Option>
              ))}
            </Select>,
          )}
        </Form.Item>
        <Form.Item label="认证类型">
          {getFieldDecorator('auditType')(
            <Select allowClear placeholder="全部" style={{width: '100px'}}>
              <Option key="-1" value={-1}>
                全部
              </Option>
              {AuthenticationTypes.toSelectArray().map(item => (
                <Option key={item.id} value={item.id}>
                  {item.text}
                </Option>
              ))}
            </Select>,
          )}
        </Form.Item>
        </>
        )}
        {/* <Form.Item label="类型">
          {getFieldDecorator('certType')(
            <Select allowClear placeholder="全部" style={{width: '160px'}}>
              <Option key="-1" value={-1}>
                全部
              </Option>
              {CertType.toSelectArray().map(item => (
                <Option key={item.id} value={item.id}>
                  {item.text}
                </Option>
              ))}
            </Select>,
          )}
        </Form.Item> */}
        <Form.Item label="提交时间">
          {getFieldDecorator('commitTime')(
            <RangePicker
              style={{width: '280px'}}
              format="YYYY-MM-DD"
            />
          )}
        </Form.Item>
        <Form.Item label="">
          {getFieldDecorator('name', {
            rules: [{ required: false, message: 'Please select your country!' }],
          })(
            <Input
              style={{width: '250px'}}
              placeholder={this.props.types ? '企业名称/昵称/手机号' : '姓名/昵称/手机号'}
            />
          )}
        </Form.Item>
        <Form.Item label="">
          <Button type='primary' onClick={this.props.onSearch}>搜索</Button>
        </Form.Item>
        <Form.Item label="">
          <a className='reset' onClick={this.reset}>重置</a>
        </Form.Item>
      </Form>
    );
  }
}

const onValuesChanges = (props, changedValues, allValues) => {
  let result = {...allValues,pageNo: 1}
  if(allValues.commitTime && allValues.commitTime.length > 0){
    result.commitTimeStart = moment(allValues.commitTime[0]).format('YYYY-MM-DD')
    result.commitTimeEnd = moment(allValues.commitTime[1]).format('YYYY-MM-DD')
  } else {
    result.commitTimeStart = ''
    result.commitTimeEnd = ''
  }
  delete result['commitTime']
  for(let key in result){
    if(result[key] == undefined){
      result[key] = ''
    }
  }
  props.onValuesChange(result)
}

export default Form.create({ name: "EnterpriseAuthList", onValuesChange: onValuesChanges })(SearchForm);
