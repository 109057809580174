import React, { Component } from "react";
import { Form, Select, Input, Button, DatePicker } from "antd";
import moment from "moment";
import { API } from "@/http/api";
import http from "@/http";
import { AccountType, AuditStatus } from "../enum";

const { Option } = Select;
const { RangePicker } = DatePicker;

class SearchForm extends Component {
  state = {};

  reset = () => {
    const { form } = this.props;
    form.resetFields();
    this.props.form.validateFields({ force: true }, (err, values) => {
      console.log(err, values, "err, values");
      if (!err) {
        this.props.reset(values);
        // this.props.onSearch()
      }
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form layout="inline">
        <Form.Item label="商户名称">
          {getFieldDecorator("name")(
            <Input style={{ width: "200px" }} placeholder={"请输入商户名称"} />
          )}
        </Form.Item>
        <Form.Item label="信用代码">
          {getFieldDecorator("unifiedSocialCode")(
            <Input
              style={{ width: "200px" }}
              placeholder={"请输入统一社会信用代码"}
            />
          )}
        </Form.Item>
        {/* <Form.Item label="商户类型">
          {getFieldDecorator('type')(
            <Select allowClear placeholder="全部" style={{width: '100px'}}>
              <Option key="-1" value={-1}>
                全部
              </Option>
              {AccountType.toSelectArray().map(item => (
                <Option key={item.id} value={item.id}>
                  {item.text}
                </Option>
              ))}
            </Select>,
          )}
        </Form.Item> */}
        <Form.Item label="联系人姓名">
          {getFieldDecorator("contactName")(
            <Input
              style={{ width: "200px" }}
              placeholder={"请输入联系人姓名"}
            />
          )}
        </Form.Item>
        <Form.Item label="联系人手机号">
          {getFieldDecorator("contactMobile")(
            <Input
              style={{ width: "200px" }}
              placeholder={"请输入联系人手机号"}
            />
          )}
        </Form.Item>
        {/* <Form.Item label="联系人邮箱">
          {getFieldDecorator('contactEmail', )(
            <Input
              style={{width: '200px'}}
              placeholder={'请输入联系人邮箱'}
            />
          )}
        </Form.Item> */}

        {/* <Form.Item label="审核状态">
          {getFieldDecorator('status')(
            <Select allowClear placeholder="全部" style={{width: '200px'}}>
              <Option key="-1" value={-1}>
                全部
              </Option>
              {AuditStatus.toSelectArray().map(item => (
                <Option key={item.id} value={item.id}>
                  {item.text}
                </Option>
              ))}
            </Select>,
          )}
        </Form.Item> */}
        <Form.Item label="需求时间">
          {getFieldDecorator("demandTime")(
            <RangePicker style={{ width: "200px" }} format="YYYY-MM-DD" />
          )}
        </Form.Item>
        <Form.Item label="提交时间">
          {getFieldDecorator("commitTime")(
            <RangePicker style={{ width: "200px" }} format="YYYY-MM-DD" />
          )}
        </Form.Item>

        <Form.Item label="">
          <Button type="primary" onClick={this.props.onSearch}>
            搜索
          </Button>
        </Form.Item>
        <Form.Item label="">
          <a className="reset" onClick={this.reset}>
            重置
          </a>
        </Form.Item>
      </Form>
    );
  }
}

const onValuesChanges = (props, changedValues, allValues) => {
  let result = { ...allValues, pageNo: 1 };
  if (allValues.commitTime && allValues.commitTime.length > 0) {
    result.completeDateStart = moment(allValues.commitTime[0]).format(
      "YYYY-MM-DD"
    );
    result.completeDateEnd = moment(allValues.commitTime[1]).format(
      "YYYY-MM-DD"
    );
  } else {
    result.completeDateStart = "";
    result.completeDateEnd = "";
  }
  if (allValues.demandTime && allValues.demandTime.length > 0) {
    result.submitDateState = moment(allValues.demandTime[0]).format(
      "YYYY-MM-DD"
    );
    result.submitDateEnd = moment(allValues.demandTime[1]).format("YYYY-MM-DD");
  } else {
    result.submitDateState = "";
    result.submitDateEnd = "";
  }

  delete result["demandTime"];
  delete result["commitTime"];
  for (let key in result) {
    if (result[key] == undefined) {
      result[key] = "";
    }
  }
  // console.log(result, 'result')
  props.onValuesChange(result);
};

export default Form.create({
  name: "EnterpriseAccountOpening",
  onValuesChange: onValuesChanges,
})(SearchForm);
