import React,{Component} from 'react'
import userUtil from '@/utils/user';
export default class GoodsSnapShot extends Component{
    goMallSnapshot = () => {
        console.log('go mall', this.props.orderSubCode)
        window.open(`${userUtil.mallUrlForLink}/snapshot/${this.props.orderSubCode}`)
    }
    render(){
        const {img, goodsName, spec, num, goodsPrice, saleStatusName} = this.props
        let str = ''
        if (spec && Array.isArray(spec)) {
            spec.forEach(ss => {
                str += `${ss.specName}: ${ss.specValueName} `
            })
        }
        return (
            <div className="bo-snapshot-item" >
                <img src={img} />
                <div className="item-right">
                    <div className="item-text ellipsis">{goodsName}</div>
                    <div className="item-text-lighter ellipsis">{str}</div>
                    <div>{goodsPrice}*{num} <span style={{color: '#2E4AFF', cursor: 'pointer'}} onClick={this.goMallSnapshot}>商品快照</span> <span>{saleStatusName}</span> </div>
                </div>
            </div>
        )
    }
}
