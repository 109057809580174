import { AJAX_TYPE } from "../config";

// 设备相关

const prefix = "equipment";

export default {
  [`${prefix}IntentionList`]: {
    url: "/api/equipment/v1/basis/crm/orderAdmin/intentionList",
    type: AJAX_TYPE.POST
  },
  [`${prefix}IntentionDetail`]: {
    url: "/api/equipment/v1/basis/crm/orderAdmin/intentionDetail/:code",
    type: AJAX_TYPE.GET
  },  
  [`${prefix}OrderList`]: {
    url: "/api/equipment/v1/basis/crm/orderAdmin/orderList",
    type: AJAX_TYPE.POST
  },
  [`${prefix}OrderDetail`]: {
    url: "/api/equipment/v1/basis/crm/orderAdmin/orderDetail/:code",
    type: AJAX_TYPE.GET
  }
 
};
