import React, { Component } from "react";
import { Checkbox } from 'antd';
import BseAuthModel from "./BseAuthModel.js";
import '../AuthSetting.less';
export default class OperateAuthModel extends BseAuthModel {
    render() {
      return <Checkbox key={'chb_' + this.id} className="sm_auth_line" checked={this.isChecked} onChange={this.onCheckChange}>{this.name}</Checkbox>;
    }

    onCheckPassDown(parent) {
      // 祖父节点 选中事件，并不会影响OPERATION的选中与否
      if (!parent.isChecked) {
        this.setChecked(false);
      }
    }
  }
  