import React, { Component } from "react";
import { Breadcrumb,Tabs } from "antd";
import { Link } from 'react-router-dom';
import DetailInfo from './DetailInfo';
import ShopList from './ShopList';
import { formatLinkSearch } from "@/utils";
import './Detail.less'

const { TabPane } = Tabs;


class Detail extends React.Component {
    state = {
        promotionCode:null,
        promotionName:null,
        supplierLimitFlag:false,
        defaultTab:1 //默认是第一个tab
    };

    componentWillMount() {
        let code = this.props.match.params.code;

        const searchData = formatLinkSearch(this.props.location.search);

        // 读取tab
        let tab =1;
        if(searchData.tab){
            tab = searchData.tab;
        }

        // 是否限制 供应商
        let supplierLimitFlag= false;
        if(searchData.supplierLimitFlag=='true'){
            supplierLimitFlag = true;
        }


        // 设置数据
        this.setState({
            promotionCode :code,
            promotionName:searchData.name,
            defaultTab:tab,
            supplierLimitFlag
        });
    }


    render() {
     
        let myState = this.state;
        return (<div className="detail_panel">
            <div  >
                <Breadcrumb>
                <Breadcrumb.Item>
                            <Link style={{ color: '#333' }} to={{ pathname: '/app/crm/staff-site-activity-list', search: 'backPageNo=' + this.state.backPageNo }} >
                                活动列表
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            活动详情
                        </Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className='tabs_panel' >
            <Tabs defaultActiveKey={''+myState.defaultTab}     className='tabs'>
                    <TabPane tab="活动详情" key="1" className='tab' >
                     <DetailInfo  code={myState.promotionCode} />
                    </TabPane>
                    <TabPane tab="参与店铺" key="2" className='tab' >
                    <ShopList code={myState.promotionCode} name={myState.promotionName} supplierLimitFlag={myState.supplierLimitFlag} />
                    </TabPane>
                   
                </Tabs>
            </div>

        </div>);
    }
}


export default Detail;
