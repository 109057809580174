import React, { Component } from "react";
import { Modal, message, Form, InputNumber, Input, Icon, Button } from "antd";
import Upload from "CMPT/common/Upload";
import { API } from "@/http/api";
import { convertEnumToMap } from "CMPT/common.js";
import http from "@/http";
import Select2 from "CMPT/common/Select";
import "./BannerAddEdit.less";

class BannerAddEdit extends Component {
  state = {
    imgUrl: null,
    imgSpec:undefined
  };

  handleBeforeUpload = file => {
    const size = file.size;
    if (size / 1024 / 1024 > 2) {
      message.error("请上传2M以内的图片");
      return false;
    }
  };

  setBannerImgUrl = value => {
    if (value.file.status === "done") {
     let item = value.file.response.file;

      this.props.form.setFieldsValue({
        imgUrl:  item.absolutePath   //item.relativePath
      });

      this.setState({
        imgUrl:  item.absolutePath
      });
    }
  };

  removeImg = e => {
    if (e) e.stopPropagation();

    this.props.form.setFieldsValue({
      imgUrl: null
    });
    this.setState({
      imgUrl: null
    });
  };


  componentDidMount() {
    this.initUI();
    this.initImgSpec();
  }

  initImgSpec(){
    var source = this.props.source;
    var position = this.props.position;
    if(source==1){//1是web端，暂时只有首页
       this.initWebImgSpec(position);
    }else if(source==2){//2是小程序端
       this.initMpImgSpec(position);
    }
  }

  //TODO 推荐尺寸 后续 可以考虑在后端配置，这样就可以改动后端即可  20211109
  initMpImgSpec(position){
    var imgSpec=undefined;
    switch ( parseInt(position)) {
      case 1://首页商机
          imgSpec = '推荐尺寸:1077*258';
          break;
      case 10://采购板块
          imgSpec = '推荐尺寸:1125*780';
          break;
      case 6://招标页面
      case 7://询价页面
      case 8://寻购大厅
          imgSpec = '推荐尺寸:1077*330 (圆角24)';
          break;
      case 9://首页弹窗
          imgSpec = '推荐尺寸:840*855 (圆角24)';
          break;
      case 11://首页弹窗
          imgSpec = '推荐尺寸:1077*966';
       case 12://首页
          imgSpec = '推荐尺寸:1125*780';
       break;
      default:
        break;
    }


    if(imgSpec){
      this.setState({imgSpec });
    }

  }

    //TODO 推荐尺寸 后续 可以考虑在后端配置，这样就可以改动后端即可  20211109
  initWebImgSpec(position){
    if(position==1){//云商城首页
       this.setState({imgSpec:'推荐尺寸:2220*1260'});
    }else if(position==6){// 合作伙伴
      this.setState({imgSpec:'推荐尺寸:3600*1530 (圆角24)'});
    }

  }
 

  initUI() {
    let data = this.props.data;

    if (data) {//有值 表示 是编辑。 没有data表示 是新增
      var newData= {...data};
      newData.position = (newData.position + '');//需要转为字符
      this.props.form.setFieldsValue(newData);

      this.setState({
        imgUrl: data.imgUrl
      });
    }
  }




  doAddEdit = formData => {
    let path = {};
    if (this.props.data) { //修改
      path.encode = this.props.data.encode;
    } else {// 新增
      formData.sortNum = this.props.listCount+1;//后端约定，sortNum倒序排
    }
    formData.source = this.props.source;
    formData.imgUrl = this.state.imgUrl;
    formData.position= this.props.position; // 新增才需要定位置


    const config = {
      api: this.props.data ? API.bannerEdit : API.bannerAdd,
      showLoading: true,
      path,
      data: formData
    };
    http(config).then(res => {
      if (res.success) {
        this.handleCancel();
        if (this.props.refresh) {
          this.props.refresh();
        }

      }
    });
  };

  handleCancel = () => {
    this.props.cancelDialog();
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let fieldsObject = this.props.form.getFieldsValue();

        this.doAddEdit({ ...fieldsObject });
      }
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const {position,source} = this.props;
    let {imgSpec} = this.state;

    return (
      <Modal
        title={this.props.data ? "编辑Banner" : "新增Banner"}
        visible={true}
        okText="确定"
        cancelText="取消"
        closable={false}
        maskClosable={false}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        width={500}
        footer={[]}
      >
        <Form className="banner-form" onSubmit={this.handleSubmit}>
          <Form.Item label="Banner标题">
            {getFieldDecorator("bannerTitle", {
              rules: [
                { required: true, message: "请输入Banner标题" },
                { max: 50, message: "Banner标题不能超过50位" }
              ]
            })(<Input placeholder="请输入(最长50位)" maxLength={50} />)}
          </Form.Item>

          <Form.Item label="Banner图片">
            {getFieldDecorator("imgUrl", {
              rules: [{ required: true, message: "请上传Banner图片" }]
            })(
              <Upload
                action={API.uploadAdd.url}
                beforeUpload={this.handleBeforeUpload}
                accept=".png,.jpg"
                onChange={this.setBannerImgUrl}
                showUploadList={false}
                listType="picture-card"
              >
                {this.state.imgUrl && (
                  <div className="upload-img"  >
                    <img style={{ width: "122px", height: "50px" }} src={this.state.imgUrl} alt="" />
                    <Icon type="close-circle" onClick={this.removeImg} />
                  </div>
                )}
              </Upload>
            )}
            {this.props.source ? <div style={{ marginTop: '10px', }}><span>{imgSpec}</span></div> : ''}
          </Form.Item>


          {/*  2表示是小程序， 10表示是 采购板块   20211012 */}
          {source==2&&position==10&&<Form.Item label="背景色值">
            {getFieldDecorator(`bgColor`, {
              rules: [
                {
                  required: true,
                  pattern: new RegExp(/^[A-Z0-9]{6}$/, "g"),
                  message: "请输入背景色(6位16进制字符),例如 00FF00"
                }
              ]
            })(<Input addonBefore="#"   />)}
          </Form.Item>}

          <Form.Item label="跳转Url">
            {getFieldDecorator(`goUrl`, {
              rules: [
                // {
                //   required: true,
                //   message: "请输入跳转URL"
                // }
              ]
            })(<Input />)}
          </Form.Item>



          <div className="btn-group">
            <Button type="primary" htmlType="submit">
              提交
            </Button>
            <Button onClick={this.handleCancel} size="large">
              取消
            </Button>
          </div>
        </Form>
      </Modal>
    );
  }
}

export default Form.create({ name: "BannerAddEdit" })(BannerAddEdit);
