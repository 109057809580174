import { AJAX_TYPE } from "../config";

// 公告

const prefix = "specialGoods";

export default {
  [`getTopSpecialGoodsList`]: {
    url: "/web/mgr/specialGoods/getcrmSpecialGoodsList",
    type: AJAX_TYPE.POST
  },

  [`getTopSpecialAllGoodsList`]: {
    url: "/web/mgr/specialGoods/getGoodsList",
    type: AJAX_TYPE.POST
  },

  [`addSpecialGoods`]: {
    url: "/web/mgr/specialGoods/addSpecialGoods",
    type: AJAX_TYPE.POST
  },

  [`doTopSpecialGoods`]: {
    url: "/web/mgr/specialGoods/topSpecialGoods",
    type: AJAX_TYPE.POST
  },

  [`delSpecialGoods`]: {
    url: "/web/mgr/specialGoods/delSpecialGoods",
    type: AJAX_TYPE.POST
  },

  

  
  

  

  

};
