import React, { Component } from 'react';

import RouteMap from "@/route";

class Setting extends Component {
    render() {
        return (
            <div>
                Setting
                <RouteMap route='/app/setting'/>
            </div>
        );
    }
}

export default Setting;