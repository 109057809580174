import React from 'react';
import { Tabs } from 'antd';
import './tabnums.less'

const TabPane = Tabs.TabPane;

export default function LogisticsTabnums(props) {
    const { nums, getnumindex, activeKey, isminWidth } = props;
    const lists = nums.map( item=> {
        const tabnum = item.num != null ? `( ${Number(item.num)} )` : '';
        return <TabPane tab={`${item.name} ${tabnum}`} key={item.value.toString()}></TabPane>
    })

    return (
        <Tabs className={`logisticstabnums ${isminWidth? 'min-width' : ''}`} activeKey={activeKey} onChange={getnumindex.bind(this)} {...props}>{lists}</Tabs>
    )
}
