import React from 'react';
import './ProjectInfoCard.less'
import {numberFormat} from '@/utils/fliter';
import AuthContainer from 'CMPT/common/auth/AuthContainer';
import { AUTH_KEYS } from '@/utils/auth';
import userUtil from '@/utils/user';

class ColorCard extends React.Component {
    toContract=()=>{
        if(userUtil.hasAuth(9902010101)){
            this.props.history.push('/app/operate/contract?source=projectall')
        }
    }
    render() {
        return (
            <div className="project-info-component">
                <div className="project-info-card">
                    <p className="info-title">项目数量</p>
                    <p><span className="info-value">{numberFormat(this.props.projectInfo.projectCnt)}</span><span className="info-unit">栋</span></p>
                </div>
                <div className="project-info-card">
                    <p className="info-title">可招商面积</p>
                    <p><span className="info-value">{numberFormat(this.props.projectInfo.availableArea)}</span><span className="info-unit">㎡</span></p>
                </div>
                <div className="project-info-card">
                    <p className="info-title">出租率</p>
                    <p><span className="info-value">{this.props.projectInfo.rentalRate}</span><span className="info-unit">%</span></p>
                </div>
                {/* <div className="project-info-card">
                    <p className="info-title">签约均价</p>
                    <p><span className="info-value">{numberFormat(this.props.projectInfo.onlineAveragePrice)}</span><span className="info-unit">元/㎡</span></p>
                </div> */}
                <div className="project-info-card" onClick={this.toContract}>
                    <p className="info-title">承租合同数量</p>
                    <p><span className="info-value">{numberFormat(this.props.projectInfo.contractCnt)}</span><span className="info-unit">份</span>
                        <AuthContainer key="1" authCodes={9902010101}>                                                                       
                            <span className="iconfont iconarrow_line_right"></span>
                        </AuthContainer>    
                    </p>
                </div>
            </div>
        )
    }
}


export default ColorCard