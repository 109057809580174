import React, { Component } from 'react';
import CopyKey from './CopyKey';
import {Icon, message,Modal, Button,Form } from 'antd'
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import Steps from 'CMPT/common/Steps';
import Upload from 'CMPT/common/Upload';
import http from '@/http';
import { API } from '@/http/api';
import wordSrc from '@/images/files_word@2x.png';
import userUtil from '@/utils/user';
import { AUTH_KEYS } from '@/utils/auth';
const BreadcrumbItem = Breadcrumb.Item;

class ReceiptTemSecondInfo extends Component {
    componentWillUnmount() {

        setTimeout(() => {
            window.onbeforeunload = null
        }, 0);
    }
    componentWillMount() {
        // 获取变量模板
        http({
            api: API.templateGetVariable,
            path: {}, // 路径参数
            data: {
                type:2  // 1合同，2收据
            }, // 请求参数            
            showLoading: true // 是否显示全局加载
        }).then(res => {
            if(res.success){
                console.log(res.data)
                this.setState({
                    keyList:res.data
                })
            }
        })


        const templateId = this.props.history.location.search&&this.props.history.location.search.replace('?edit=','')
        this.templateId = templateId        
        this.setState({
            templateId,
            edit:true
        })
        setTimeout(() => {
            window.onbeforeunload = function (e) {
                e = e || window.event;
    
                // 兼容IE8和Firefox 4之前的版本
                if (e) {
                    e.returnValue = '您编辑的信息尚未提交，您确定要离开吗？';
                }
    
                // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
                return '您编辑的信息尚未提交，您确定要离开吗？';
            };    
        }, 100);
        console.log(this.props.history.location.state)
        this.setState({
            lastState: this.props.history.location.state
        }, () => {
            // console.log(this.state.lastState)
            if (!this.state.lastState) {
                // 没有值放回新增第一步
                // console.log(this.props.history)
                this.props.history.replace({ pathname: '/app/setting/template/receipttemplate/receipteditfirst',search: (this.templateId?'?edit='+this.templateId:'')})
            }
        })

    }
    state = {
        keyList:[],
        isUpload: false,
        steps: {
            nodeNum: 2,
            current: 2,
            currentNodeStatus: 2,
            nodeNameList: ['基本信息', '上传模板']
        },
        lastState:{}
    }
    toScrollUp = () => {

        this.setState({
            isUpload: false
        })

    }
    toScrollDown = () => {

        this.setState({
            isUpload: true
        })
        document.querySelector('.ant-layout-content').scrollTo(0, this.refs['template-upload'].offsetTop)
    }
    getType=({fileType,fileExt,fileName,fileKey})=>{
        this.setState({
            upload:true
        })
        this.setState({
            fileType,
            fileExt,
            fileName,
            fileKey
        })
        
        this.setState({
            lastState:{...this.state.lastState,fileType,fileExt,fileName,fileKey}
        })
    }
    submit = ()=>{
        if(!this.state.upload){
            message.info('请先上传模板')
            return false
        }
        let api = API.templateAdd
        if(this.templateId){
            api = API.templateUpdate
        }
        http({
            api: api,
            path: {}, // 路径参数
            data: {
                encode:this.templateId,
                media: {
                    name: this.state.fileName,
                    type: this.state.fileType,                    
                    mediaKey:this.state.fileKey
                },
                name: this.state.lastState.templateName,
                projectIds: this.state.lastState.projectId,
                type: 2,
            }, // 请求参数            
            showLoading: false // 是否显示全局加载
        }).then(res => {
            if(res.success){
                this.props.history.push({ pathname: "/app/setting/template/receipttemplate"})
                message.success(res.msg||(this.templateId?'模板提交成功':'模板提交成功'),1,()=>{
                });
            }
        })
    }
    cancel=()=>{
        this.setState({
            visible:true,
        })
    }
    handleOk=()=>{
        this.props.history.push({ pathname: "/app/setting/template/receipttemplate"})        
    }
    handleCancel=()=>{
        this.setState({
            visible:false,
        })
    }
    changeStatus=(status)=>{
        this.setState({
            upload:status
        })
    }
    render() {
        
        return (
            <div className="template-edit">
                <Modal
                    closable={false}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    okText='确认'
                    width={400}         
                    onCancel={this.handleCancel}           
                >
                    <p>当前页面未保存，是否离开？</p>
                </Modal>
                <Breadcrumb>
                    <BreadcrumbItem>
                    <Link style={{ color: '#333' }} to={{ pathname: "/app/setting/template/receipttemplate" }}>
                                模版管理
                            </Link>
                    
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                    <Link style={{ color: '#333' }} to={{ pathname: "/app/setting/template/receipttemplate" }}>
                    收据模版
                            </Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>新增模版</BreadcrumbItem>
                </Breadcrumb>
                <div className="template-steps">
                    <Steps steps={this.state.steps} />
                </div>
                <div className="steps-content">
                    <div className="templete-second-info">

                        <div className="templete-second-info-main">
                            <div className="templete-second-info-main-header">
                                <span className={"header-bar " + (this.state.isUpload ? '' : 'current')} onClick={this.toScrollUp}>复制关键词</span>
                                <span className={"header-bar " + (this.state.isUpload ? 'current' : '')} onClick={this.toScrollDown}>上传模板</span>
                                <span className="header-tip">注：您需要打开合同文档，将本页面中的关键词复制，最后粘贴到文档中的对应位置，保存后上传模版</span>
                            </div>
                            {/* 关键词 */}
                            <div className="templete-second-info-content">
                                <div className="templete-second-info-key">
                                    <div className="templete-second-info-title">
                                        复制关键词
                            </div>
                                    <div className="templete-second-info-key-content">
                                    {
                                        this.state.keyList&&this.state.keyList.map(res=>{
                                            return <CopyKey key={res.name} data={res} />    
                                        })
                                    }
                                       
                                    </div>
                                </div>
                                <div className="templete-second-info-tem" ref="template-upload">
                                    <div className="templete-second-info-title">
                                        上传模板
                                    </div>
                                    <div className="templete-second-info-tem-content">
                                        <div className="templete-second-info-tem-tip">
                                            仅支持10M以内的Word文件
                                    </div>
                                        <Avatar lastState={this.state.lastState} backType={this.getType} changeStatus={this.changeStatus}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="receipt-btn">
                    <Link to={{ pathname: "/app/setting/template/receipttemplate/receipteditfirst",search: (this.state.templateId?'?edit='+this.state.templateId:''), state: { ...this.state.lastState } }}>
                        <Button>上一步</Button>
                    </Link>
                    {
                        this.state.edit&&userUtil.hasAuth(AUTH_KEYS.SETTING_TEMPLATE_RECEIPT_MODIFY)?
                        <Button type="primary" onClick={this.submit}>提交</Button>:null
                    }
                    {
                        !this.state.edit&&userUtil.hasAuth(AUTH_KEYS.SETTING_TEMPLATE_RECEIPT_NEW)?
                        <Button type="primary" onClick={this.submit}>提交</Button>:null
                    }
                    
                    <span onClick={this.cancel} className="cancel">取消</span>
                </div>
            </div>

        );
    }
}
export default ReceiptTemSecondInfo;





class Avatar extends React.Component {
    state = {
        loading: false,
        fileType:''
    };
    componentWillMount(){
        
        if(this.props.lastState&&this.props.lastState.stateFromSecond){
            if(this.props.lastState.stateFromSecond.fileType=='word'){
                this.setState({
                    imageUrl:wordSrc
                })
            }
            if(this.props.lastState.stateFromSecond.fileName){
                this.setState({
                    fileName:this.props.lastState.stateFromSecond.fileName
                })
            }
            this.props.backType({
                fileType:this.props.lastState.stateFromSecond.fileType,
                fileExt:this.props.lastState.stateFromSecond.fileExt,
                fileName:this.props.lastState.stateFromSecond.fileName,
                fileKey:this.props.lastState.stateFromSecond.fileKey,
            })
        }
        
    }
    beforeUpload=(file)=> {
        // return true
        // console.log(file)
        // 判断文件是不是word/pdf
        const isWord = /application\/.*word/.test(file.type)
        // const isPdf = /application\/.*pdf/.test(file.type)
        // console.log(isWord,isPdf)
        if(isWord){
            this.setState({
                fileType:'word'
            })
            if(file.name.split('.doc')[0].length>20){
                message.error('文件名需小于20字');
                return false
            }
        }
        // if(isPdf){
        //     this.setState({
        //         fileType:'pdf'
        //     })
        // }
        
        if (!isWord) {
            message.error('请上传10M以内的word文件');
        }
        const isLt10M = file.size / 1024 / 1024 < 10;
        if (!isLt10M) {
            message.error('请上传10M以内的word文件');
        }
        return isWord && isLt10M;
    }
    handleChange = (info) => {
        // console.log(info)
        if (info.file.status === 'uploading') {
            this.props.changeStatus(false)
            this.setState({ loading: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            this.props.changeStatus(true)
            this.setState({              
                loading: false,
                fileName:decodeURI(info.file.response.file.fileName),
            })
            
            // console.log(info.file.response.file)
            if(this.state.fileType=='word'){                
                this.setState({
                    imageUrl:wordSrc
                })
            }
            this.props.backType({
                fileType:this.state.fileType,
                fileExt:info.file.response.file.md5,
                fileName:decodeURI(info.file.response.file.fileName),
                fileKey:info.file.response.file.key
            })
        }
        
        
    }

    render() {
        const uploadButton = (
            <div>
                <Icon type={this.state.loading ? 'loading' : 'upload'} />
                <div className="ant-upload-text" style={{margin: 'auto', width: '66%'}}>拖拽至此 或 点击上传</div>
            </div>
        );
        return (
            <Upload
                name="模板"
                listType="picture-card"
                className="avatar-uploader"
                withCredentials={true}
                // customRequest={this.customRequest}
                showUploadList={false}
                headers={{'Content-Type': 'multipart/form-data'}}
                action={API.uploadAdd.url}
                beforeUpload={this.beforeUpload}
                onChange={this.handleChange}
            >
                {this.state.imageUrl ? <img src={this.state.imageUrl} alt="avatar" /> : uploadButton}
                {this.state.fileName ? <p style={{width:'120px'}}>{this.state.fileName}</p> : null}
            </Upload>
        );
    }
}