import React, { Component } from 'react';
import { Form, Select, Input, Button, DatePicker } from 'antd';
import moment from 'moment';
import { API } from '@/http/api';
import http from '@/http';
import { IdentityType, CertType, LeaseAuditType } from "../enum";

const { Option } = Select;
const { RangePicker } = DatePicker;

class SearchForm extends Component{
  
  state = {
     supplierArray:[]
   }

    //初始化
    componentDidMount() {
       
      let that = this;
       
      http({
        api:API.enterpriseAllList,
        data:{},
        showLoading: false
      }).then(res => {
         if(res&&res.code==200&&res.data&&Array.isArray(res.data)){
           let supplierArray=[];
          res.data.forEach((item)=>{
            supplierArray.push({id:item.id,name:item.name});
          });
          that.setState({supplierArray});

         }
      });
    }

  reset = () => {
    const { form } = this.props;
    form.resetFields();
    this.props.form.validateFields(
      { force: true },
      (err, values) => {
        console.log(err, values, 'err, values')
        if (!err) {
          this.props.reset(values);
          // this.props.onSearch()
        }
      },
    );
  }
  render() {
    const {supplierArray} = this.state;
    const { getFieldDecorator } = this.props.form
    return (
      <Form layout='inline'>
        <Form.Item label="状态">
          {getFieldDecorator('auditType')(
            <Select allowClear placeholder="全部" style={{width: '100px'}}>
              <Option key="-1" value={-1}>
                全部
              </Option>
              {LeaseAuditType.toSelectArray().map(item => (
                <Option key={item.id} value={item.id}>
                  {item.text}
                </Option>
              ))}
            </Select>,
          )}
        </Form.Item>
    
        <Form.Item label="提交人">
          {getFieldDecorator('queryInfo', )(
            <Input
              style={{width: '200px'}}
              placeholder={'姓名/电话号码'}
            />
          )}
        </Form.Item>
        

        <Form.Item label="">
          {getFieldDecorator('supplierId')(
            <Select allowClear showSearch optionFilterProp="label" placeholder="企业名称" style={{width: '200px'}}>
              <Option key="-1" value={-1}>
                全部
              </Option>
              {supplierArray.map(item => (
                <Option key={item.id} value={item.id} label={item.name}>
                  {item.name}
                </Option>
              ))}
            </Select>,
          )}
        </Form.Item>


        <Form.Item label="">
          <Button type='primary' onClick={this.props.onSearch}>搜索</Button>
        </Form.Item>
        <Form.Item label="">
          <a className='reset' onClick={this.reset}>重置</a>
        </Form.Item>
      </Form>
    );
  }
}

const onValuesChanges = (props, changedValues, allValues) => {
  let result = {...allValues,pageNo: 1}
  if(allValues.commitTime && allValues.commitTime.length > 0){
    result.commitTimeStart = moment(allValues.commitTime[0]).format('YYYY-MM-DD')
    result.commitTimeEnd = moment(allValues.commitTime[1]).format('YYYY-MM-DD')
  } else {
    result.commitTimeStart = ''
    result.commitTimeEnd = ''
  }
  delete result['commitTime']
  for(let key in result){
    if(result[key] == undefined){
      result[key] = ''
    }
  }
  props.onValuesChange(result)
}

export default Form.create({ name: "EnterpriseAuthList", onValuesChange: onValuesChanges })(SearchForm);
