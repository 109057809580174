import {
    AJAX_TYPE
} from '../config';

// verifycode service

const prefix = 'valueAddService';

export default {
    // 判断是否需要图片验证码
    [`${prefix}GetStatisticsPayData`]: {
        url: '/api/isx/v1/track/service/getStatisticsPayData',
        type: AJAX_TYPE.POST
    },
    // 平台购买
    [`${prefix}PlatformDeposit`]: {
        url: '/api/isx/v1/track/service/platformDeposit',
        type: AJAX_TYPE.POST
    },
    // 购买记录
    [`${prefix}AuditList`]: {
        url: '/api/isx/v1/track/service/auditList',
        type: AJAX_TYPE.POST
    },
    // 凭证上传
    [`${prefix}DepositUpload`]: {
        url: '/api/isx/v1/track/service/depositUpload',
        type: AJAX_TYPE.POST
    },
    // 获取来源
    [`${prefix}GetSourceList`]: {
        url: '/api/isx/v1/commonDictionary/getSourceList',
        type: AJAX_TYPE.GET
    },
    // 审核
    [`${prefix}AuditDeposit`]: {
        url: '/api/isx/v1/track/service/auditDeposit',
        type: AJAX_TYPE.POST
    },

    // 操作记录
    [`${prefix}AuditRecord`]: {
        url: '/api/isx/v1/track/service/auditRecord/:code',
        type: AJAX_TYPE.GET
    },

    // 客户列表
    [`${prefix}SelectSimpleList`]: {
        url: '/api/isx/v1/authUser/selectSimpleList/:supplierName',
        type: AJAX_TYPE.GET
    },

    // 代充
    [`${prefix}InPlaceOfpay`]: {
        url: '/api/isx/v1/track/service/inPlaceOfpay',
        type: AJAX_TYPE.POST
    },
    // 用户消费记录
    [`${prefix}CrmUsedRecordsList`]: {
        url: '/api/isx/v1/track/service/crmUsedRecordsList',
        type: AJAX_TYPE.POST
    },

    // 获取待审批 数量
    [`${prefix}CountAuditDeposit`]: {
        url: '/api/isx/v1/track/service/countAuditDeposit',
        type: AJAX_TYPE.GET
    },

    

    
    
    
    


    
    

    
    

    
}