import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Button, Tabs, Row, Col, Popover, Icon, Modal, Input, message } from 'antd';
import Tip from 'CMPT/common/Tip';
import AuditInfo from './detail/AuditInfo';
import RXDetail from './detail/RXDetail';
import RelatedContract from './detail/RelatedContract';
import OperateRecord from './detail/OperateRecord';
import ApprovalChain from 'CMPT/common/ApprovalChain';
import AbandonModal from './list/AbandonModal';
import SignoutModal from './list/SignoutModal';
import http from '@/http';
import { API } from '@/http/api';
import userUtil from '@/utils/user';
import moment from 'moment';
import { deepclone } from '@/utils'
const BreadcrumbItem = Breadcrumb.Item;
const TabPane = Tabs.TabPane;

class Audit extends Component {
    state = {
        auditDesc: '',
        stepList: [],
        contractYears: 0,
        contractInfo: {
            contractCode: '',
            status: 0, // '0 待生效 10已失效 20租期中 30已变更 40已退租 41 退租未结算 42已违约 43 违约未结算 50已续租 60已作废'
            auditStatus: 2, // '0审批中 1已驳回 2审批通过 3审批不通过'
            auditNote: '', // 审批备注
            canAudit: true, // 是否具有审批权限
            canEdit: false, // 根据合同状态（待生效）、审批状态（被驳回）和合同发起人来判断
            canOperate: true, // 当前合同关联的项目，登录人是否有权限查看，防止没有权限时，登录人续租或者变更合同，项目匹配不到
            auditType: 10, // 0 无需审批 10新签审批 11 变更审批 12 续签审批 13 退租审批 14 作废审批
            customerName: '',
            customerEncode: '',
            customerType: '',
            customerIndustry: '',
            customerTaxNumber: '', // 客户统一社会信用代码
            customerCompanyLogo: '',
            RelatedPerson: '', // 联系人
            RelatedPersonPhone: '', // 联系人电话
            RelatedPersonIdentity: '', // 联系人身份证
            customerEmail: '', // 客户邮箱
            customerBankCard: '', // 客户银行卡号
            customerLocation: '', // 客户省市区
            customerBlock: '', // 客户所在版块
            customerAddress: '', // 客户详情地址
            projectName: '', // 合同关联项目名称
            projectEncode: '',
            projectImg: '', // 房源图片
            projectArea: '', // 项目的房间
            projectRooms: [],
            contractNumber: '', // 合同编号
            contractDateRange: [],
            contractSignDate: '',
            exemptBeforeDateRange: [],
            exemptAfterDateRange: [],
            contractDeposit: undefined, // 合同押金月数 
            contractPayment: undefined, // 合同付款月数
            contractPaymentType: 1, // 合同付款类型 1 提前付款天数 2 固定付款日期
            contractPaymentDate: undefined, // 合同付款日期
            contractFei: '', // 折扣、递增率
            marginData: [],
            contractRentList: [],
            estateDeposit: undefined, //
            estatePayment: undefined, //
            estatePaymentType: 1, //
            estatePaymentDate: undefined, //
            estateFei: '',
            estateMarginData: [],
            estateRentList: [],
            mediaInfo: '' //附件信息
        },
        showSignout: false, // 退租modal
        signoutInfo: {
            contractCode: '',
            contractDateRange: [],
            signoutFei: []
        },
        showAbandon: false, // 作废modal
        showModal: false, // 审批modal
        auditFlag: '', // 1 审批通过 2 审批驳回 3 审批终止
        activeTabKey: '1',
        auditReason: '',
        // 退租编辑时回显信息
        signoutEditInfo: {
            signoutType: '',
            signoutDate: '',
            signoutReason: '',
            signoutDesc: ''
        },
        hashChangeFunction: (e) => {
            if (e.newURL.indexOf('/app/operate/contract/detail') !== -1) {
                this.getDetail()
            }
        }
    }
    componentDidMount() {
        window.addEventListener('hashchange', this.state.hashChangeFunction)
        this.getDetail();
    }
    componentWillUnmount() {
        window.removeEventListener('hashchange', this.state.hashChangeFunction)
    }
    formatSearch = (ss) => {
        const ssSplit = ss.split('?')[1].split('&')
        let obj = {}
        ssSplit.forEach(per => {
            let perSplit = per.split('=')
            obj[perSplit[0]] = perSplit[1]
        })
        return obj
    }
    //获取审批详情
    getAuditDetail = (code, type) => {
        const config = {
            api: API.contractApprovenode,
            data: {
                encode: code,
                auditType: type
            }
        }
        http(config).then(res => {
            let auditDesc = ''
            const nodes = res.data.nodes
            const item = nodes.find(per => {
                return per.reached && !per.processed
            })
            let character = ''
            let names = []
            if (item) {
                character = item.type === 2 ? ' + ' : ' / '
                names = item.users.map(i => i.name)
                auditDesc = `当前审批人：${names.join(character)}`
            }
            this.setState({
                stepList: nodes,
                auditDesc
            })
        })
    }
    // 获取退租信息
    getSignoutInfo = (code) => {
        const config = {
            api: API.contractInitRentWithdrawalApply,
            path: {
                enCode: code
            }
        }
        http(config).then(res => {
            const data = res.data
            let info = {}
            info.signoutType = data.refundRentType === 1 ? '正常退租' : '非正常退租'
            info.signoutDate = data.refundRentTime
            info.signoutReason = data.remark
            info.signoutDesc = this.getSignoutDesc(data.billList)
            this.setState({
                signoutEditInfo: info
            })
        })
    }
    getSignoutDesc = (list) => {
        let str = ''
        if (list && list.length) {
            list.forEach(per => {
                str += per.budgetType === 2 ? '付，' : '收，'
                str += per.budgetType === 2 ? `保证金-${per.payAmount}元，` : `保证金${per.payAmount}元，`
                str += per.payDate + '；'
            })
        }
        return str || '-'
    }
    //获取详情
    getDetail = () => {
        this.setState({
            activeTabKey: '1'
        })
        const location = this.props.history.location
        const pathSplit = location.pathname.split('/')
        const searchFormat = location.search ? this.formatSearch(location.search) : {}
        const contractCode = pathSplit[pathSplit.length - 1]
        if (searchFormat.audit === '13') {
            this.getSignoutInfo(contractCode)
        }
        const config = {
            api: API.contractApproveinfo,
            data: {
                encode: contractCode,
                auditType: searchFormat.audit
            },
            showLoading: true
        };
        http(config).then(res => {
            if(res.success) {
                const data = res.data;        
                const contractInfo = this.state.contractInfo;
                contractInfo.contractCode = contractCode // 合同code
                contractInfo.status = data.contractVo.status
                contractInfo.auditStatus = data.contractVo.auditStatus
                contractInfo.contractDateRange = [data.contractVo.startTime, data.contractVo.endTime]
                if (data.contractVo.freeEndTime) {
                    contractInfo.exemptBeforeDateRange = [data.contractVo.startTime, data.contractVo.freeEndTime]
                }
                if (data.contractVo.freeStartTime) {
                    contractInfo.exemptAfterDateRange = [data.contractVo.freeStartTime, data.contractVo.endTime]
                }
                contractInfo.contractSignDate = data.contractVo.signTime
                contractInfo.contractNumber = data.contractVo.contractCode || '-' // 合同编号
                
                contractInfo.canEdit = data.canEdit
                contractInfo.canAudit = data.canAudit
                contractInfo.canOperate = data.canOperate
                contractInfo.auditNote = data.auditDesc
                contractInfo.cancelNote = data.cancelDesc
                contractInfo.auditType = Number(searchFormat.audit)
                if (searchFormat.audit && ([0, 1, 3].indexOf(contractInfo.auditStatus) !== -1)) {
                    this.getAuditDetail(contractCode, searchFormat.audit)
                }
                if (data.contractVo.mediaDto) {
                    contractInfo.mediaInfo = {
                        encode: data.contractVo.mediaDto.encode,
                        mediaKey: data.contractVo.mediaDto.mediaKey,
                        name: data.contractVo.mediaDto.name,
                        type: data.contractVo.mediaDto.type,
                        url: data.contractVo.mediaDto.url
                    }
                }
                //企业信息
                const customerInfo = data.contactCustomerVo;
                contractInfo.customerName =  customerInfo.customerName
                contractInfo.customerEncode = customerInfo.encode
                contractInfo.customerType = customerInfo.customerTypeStr
                contractInfo.customerCompanyLogo = customerInfo.companyLogo
                contractInfo.customerIndustry =  customerInfo.industryTypeStr || '-'
                contractInfo.customerTaxNumber = customerInfo.creditNumber || '-'
                contractInfo.RelatedPerson = customerInfo.contactName || '-'
                contractInfo.RelatedPersonPhone = customerInfo.phone || '-'
                contractInfo.RelatedPersonIdentity = customerInfo.identityNumber || '-'
                contractInfo.customerEmail = customerInfo.email || '-' // 客户邮箱
                contractInfo.customerBankCard = customerInfo.bankNo || '-' // 客户银行卡号
                contractInfo.customerLocation = customerInfo.provinceName ? `${customerInfo.provinceName}${customerInfo.cityName}${customerInfo.regionName}` : '-' // 客户省市区
                contractInfo.customerBlock = customerInfo.blockName || '-' // 客户所在版块
                contractInfo.customerAddress = customerInfo.address || '-'
                //房源信息
                const houseInfor = data.contractZoneVo;
                contractInfo.projectName = houseInfor.projectName;
                contractInfo.projectEncode = houseInfor.projectCode
                contractInfo.projectImg = houseInfor.mediaList && houseInfor.mediaList.length && houseInfor.mediaList[0].url
                contractInfo.projectArea = houseInfor.totalArea + 'm²';
                contractInfo.projectRooms = houseInfor.zoneList && houseInfor.zoneList.map(item => {
                    return {
                        floor: item.floorName, name: item.zoneNo, area: item.zoneArea
                    }
                })
                // 租客合同
                const contractClause = data.rentClauseVo
                // 记录合同年数
                const contractYears = contractClause.contractZonePriceList[0].priceList.length
                contractInfo.contractDeposit = contractClause.depositNum
                contractInfo.contractPayment = contractClause.paymentNum
                contractInfo.contractPaymentUnit = ['月', '季', '年'][contractClause.paymentNumUnit - 1]
                contractInfo.contractPaymentType = contractClause.advanceType
                contractInfo.contractPaymentDate = contractClause.advanceDays
                contractInfo.contractFei = this.transFei(contractClause.rentChangeFormList)
                contractInfo.marginData = contractClause.billVoList && contractClause.billVoList.map((item, index) => {
                    return {
                        key: index + 1,
                        type: item.typeStr,
                        marginNum: item.amount,
                        payDate: item.paymentDate,
                        note: item.description
                    }
                }) || []

                contractInfo.firstBeginDay = contractClause.firstBeginDay;
                contractInfo.firstChooseType = contractClause.firstChooseType;
                contractInfo.firstEndDay = contractClause.firstEndDay;
                contractInfo.firstFeeDays = contractClause.firstFeeDays;
                contractInfo.firstFeePointDate = contractClause.firstFeePointDate;
                contractInfo.firstRangeType = contractClause.firstRangeType;

                contractInfo.contractRentList = this.transRentlist(contractClause.contractZonePriceList)
                // 物业合同
                const estateClause = data.propertyClauseVo
                contractInfo.estateDeposit = estateClause.depositNum
                contractInfo.estatePayment = estateClause.paymentNum
                contractInfo.estatePaymentUnit = ['月', '季', '年'][estateClause.paymentNumUnit - 1]
                contractInfo.estatePaymentType = estateClause.advanceType
                contractInfo.estatePaymentDate = estateClause.advanceDays
                contractInfo.estateFei = this.transFei(estateClause.rentChangeFormList)
                contractInfo.estateMarginData = estateClause.billVoList && estateClause.billVoList.map((item, index) => {
                    return {
                        key: index + 1,
                        type: item.typeStr,
                        marginNum: item.amount,
                        payDate: item.paymentDate,
                        note: item.description || '-'
                    }
                }) || []
                contractInfo.estateRentList = this.transRentlist(estateClause.contractZonePriceList)
                this.setState({
                  contractInfo,
                  contractYears
                }, () => {
                    if (searchFormat.action === '1') { // 1 退租 2 查看收支
                        this.signout(true, false)
                    } else if (searchFormat.action === '2') {
                        this.setState({
                            activeTabKey: '2'
                        })
                    }
                })
            }
                    
        }) 
    }
    transRentlist = (list) => {
        let target = []
        list.forEach((per, index) => {
            let obj = {}
            obj.key = index + 1
            obj.projectRoomName = per.zoneFullName
            obj.area = per.zoneArea
            obj.price = per.rentPrice
            per.priceList.forEach(item => {
                obj[`year${item.yearNumber}`] = item.price
            })
            target.push(obj)
        })
        return target
    }
    transFei = (list) => {
        let str = ''
        if (list && list.length) {
            list.forEach((per, index, all) => {
                if (index === 0) {
                    str += `第1年${per.discount}折；`
                } else if (index === all.length -1) {
                    str += `第${index+1}年递增${per.increamentalRate}%，折扣${per.discount}折`
                } else {
                    str += `第${index+1}年递增${per.increamentalRate}%，折扣${per.discount}折；`
                }
            })
        }
        return str
    }
    download = () => {
        let config = {
            api: API.contractDownload,
            path: {
                enCode: this.state.contractInfo.contractCode
            },
            responseType: 'blob',
        }
        http(config).then(res => {
            let reader = new FileReader()
            reader.onload = e => {
                if (e.target.result.indexOf('"success":false') !== -1) {
                    const resultParse = JSON.parse(e.target.result)
                    message.error(resultParse.msg)
                } else {
                    const content = res
                    const blob = new Blob([content], { type: 'application/zip' })
                    const fileName = '合同模板'
                    if ('download' in document.createElement('a')) { // 非IE下载
                        const elink = document.createElement('a')
                        elink.download = fileName
                        elink.style.display = 'none'
                        elink.href = URL.createObjectURL(blob)
                        document.body.appendChild(elink)
                        elink.click()
                        URL.revokeObjectURL(elink.href) // 释放URL 对象
                        document.body.removeChild(elink)
                    } else { // IE10+下载
                        navigator.msSaveBlob(blob, fileName)
                    }
                }
            }
            reader.readAsText(res)
        })
    }
    terminal = () => {
        this.setState({
            showModal: true,
            auditFlag: 3
        })
    }
    reject = () => {
        this.setState({
            showModal: true,
            auditFlag: 2
        })
    }
    pass = () => {
        this.setState({
            showModal: true,
            auditFlag: 1
        })
    }
    // 作废
    abandon = (status, value, reason) => {
        if (typeof value === 'boolean' && value) {
            const config = {
                api: API.contractInvalidApply,
                data: {
                    encode: this.state.contractInfo.contractCode,
                    remark: reason
                }
            }
            http(config).then(res => {
                if (res.success) {
                    message.success('操作成功')
                    setTimeout(() => {
                        this.props.history.push('/app/operate/contract')
                    }, 2000)
                    this.setState({
                        showAbandon: status
                    })
                }
            })
            return
        }
        this.setState({
            showAbandon: status
        })
    }
    // 退租
    signout = (status, flag) => {
        this.setState({
            showSignout: status
        })
        if (typeof flag === 'boolean' && flag) {
            const info = this.state.contractInfo
            this.props.history.push(`/app/operate/contract/detail/${info.contractCode}?audit=${info.auditType}`)
        }
        if (status) {
            const config = {
                api: API.contractInitRentWithdrawalApply,
                path: {
                    enCode: this.state.contractInfo.contractCode
                }
            }
            http(config).then(res => {
                const data = res.data
                let signoutInfo = {
                    contractCode: this.state.contractInfo.contractCode,
                    allPaid: data.allPaid,
                    customerName: data.customerNames.join('，'),
                    contractDateRange: [data.startTime, data.endTime],
                    endDisabledDate: data.nextStartDate,
                    contractNumber: data.contractCode,
                    projectRoomNames: data.projectNames.join('，'),
                    signoutType: data.refundRentType || 1,
                    signoutTime: moment(data.refundRentTime),
                    signoutReason: data.remark,
                    signoutFei: data.billList && data.billList.map(item => {
                        return {
                            marginId: item.encode,
                            feiType: item.budgetType,
                            bizTypeSub: item.bizTypeSub, // 1 租赁收付 2 物业 3 其他收付
                            marginType: String(item.feeType),
                            marginNum: item.payAmount,
                            paidAmount: item.paidAmount,
                            auditPaidAmount: item.auditPaidAmount,
                            marginDate: moment(item.payDate)
                        }
                    })
                }
                this.setState({
                    signoutInfo
                })
            })
        }
    }
    // 此编辑暂时隐藏
    edit = () => {
        let type = {10: 1, 11: 3, 12: 2}[this.state.contractInfo.auditType]
        this.props.history.push(`/app/operate/contract/add?code=${this.state.contractInfo.contractCode}&type=${type}&isEdit=true`)
    }
    alter = () => {
        this.props.history.push(`/app/operate/contract/add?code=${this.state.contractInfo.contractCode}&type=3`)
    }
    resign = () => {
        this.props.history.push(`/app/operate/contract/add?code=${this.state.contractInfo.contractCode}&type=2`)
    }
    cancel = () => {
        this.setState({
            showModal: false
        })
    }
    confirm = () => {
        // if (!this.state.auditReason) {
        //     message.warning('请填写备注信息')
        //     return
        // }
        const config = {
            api: API.contractContractapprovecallback,
            data: {
                code: this.state.contractInfo.contractCode,
                auditStatus: {1:1, 2:2, 3:0}[this.state.auditFlag], // 1 审批通过 => 1; 2 审批驳回 => 2; 3 审批终止 => 0
                desc: this.state.auditReason,
                bizType: this.state.contractInfo.auditType
            }
        }
        http(config).then(res => {
            if (res.success) {
                message.success('操作成功')
                this.getDetail()
                this.setState({
                    showModal: false
                })
            }
        })
    }
    changeTab = (tab) => {
        this.setState({
            activeTabKey: tab
        })
    }
    setAuditReason = (value) => {
        this.setState({
            auditReason: value.target.value
        })
    }
    render() {
        const { status, auditStatus, auditType, canAudit, canEdit, canOperate, auditNote } = this.state.contractInfo
        const auditComponent = (
            <Popover overlayClassName="bo-audit-popover" content={<ApprovalChain stepList={this.state.stepList}/>} trigger="click" placement="bottomRight">
                <div className="clickPopover"><i className="iconfont iconapprove"></i><span>查看审批流程</span></div>
            </Popover>
        )
        let AuditCom = null
        let AuditTip = null
        if (auditStatus === 0) {
            AuditTip = (
            <div className="contract-block">
                <Tip type="wait" title="待审批" hint={true} hintNode={auditComponent} secondTitle={this.state.auditDesc}/>
            </div>)
        } else if (auditStatus === 1) {
            AuditTip = (
                <div className="contract-block">
                    <Tip type="warn" title="审批驳回" hint={true} hintNode={auditComponent} des={auditNote}/>
                </div>
            )
        } else if (auditStatus === 3) {
            AuditTip = (
                <div className="contract-block">
                    <Tip type="minus" title="审批终止" hint={true} hintNode={auditComponent} des={auditNote}/>
                </div>
            )
        }
        if ([0, 1, 3].indexOf(auditStatus) !== -1 && auditType === 13) {
            AuditCom = (
                <div className="contract-block">
                    <div className="contract-approve">
                        <div className="approve-name">退租申请</div>
                        <div className="approve-content">
                            <Row gutter={24} className="approve-row">
                                <Col span={8}>
                                    <span className="approve-key">退租类型：</span>
                                    <span className="approve-value">{this.state.signoutEditInfo.signoutType}</span>
                                </Col>
                                <Col span={8}>
                                    <span className="approve-key">预计搬离时间：</span>
                                    <span className="approve-value">{this.state.signoutEditInfo.signoutDate}</span>
                                </Col>
                            </Row>
                            <Row className="approve-row">
                                <Col span={24}>
                                    <span className="approve-key">退租原因：</span>
                                    <span className="approve-value">{this.state.signoutEditInfo.signoutReason}</span>
                                </Col>
                            </Row>
                            <Row className="approve-row">
                                <Col span={24}>
                                    <span className="approve-key">退租费用：</span>
                                    <span className="approve-value">{this.state.signoutEditInfo.signoutDesc}</span>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            )
        } else if ([0, 1, 3].indexOf(auditStatus) !== -1 && auditType === 14) {
            AuditCom = (
                <div className="contract-block">
                    <div className="contract-approve">
                        <div className="approve-name">作废申请</div>
                        <div className="approve-content">
                            作废原因：{this.state.contractInfo.cancelNote}
                        </div>
                    </div>
                </div>
            )
        }
        let buttons = []
        userUtil.hasAuth(9902010106) && buttons.push(<Button key="1" onClick={this.download}>下载</Button>)
        if (status === 20 && [2, 3].indexOf(auditStatus) !== -1) { // 保持按钮的顺序才这样做的
            if (canOperate) {
                userUtil.hasAuth(9902010105) && buttons.push(<Button key="5" onClick={this.abandon.bind(this, true)} className="btn-warning">作废</Button>)
                userUtil.hasAuth(9902010104) && buttons.push(<Button key="2" onClick={this.signout.bind(this, true)} className="btn-warning">退租</Button>)
                userUtil.hasAuth(9902010102) && buttons.push(<Button key="3" onClick={this.alter} type="primary">变更</Button>)
                userUtil.hasAuth(9902010103) && buttons.push(<Button key="4" onClick={this.resign} type="primary">续租</Button>)
            }
        } else if ((status === 0 || status === 20) && auditStatus === 1) { // 被驳回
            if (canOperate) {
                userUtil.hasAuth(9902010105) && buttons.push(<Button key="5" onClick={this.abandon.bind(this, true)} className="btn-warning">作废</Button>)
            }
            // if (canEdit) {
            //     buttons.push(<Button key="6" onClick={this.edit}>编辑</Button>)
            // }
        } else if ([40, 41, 42, 43].indexOf(status) !== -1) { // 退租情况下只能作废
            if (canOperate) {
                userUtil.hasAuth(9902010105) && buttons.push(<Button key="5" onClick={this.abandon.bind(this, true)} className="btn-warning">作废</Button>)
            }
        } else if ([30, 50].indexOf(status) !== -1 && [2, 3].indexOf(auditStatus) !== -1) {
            // 已变更\已续签或者退租未结算\违约未结算并被驳回的也可以退租
            if (canOperate) {
                userUtil.hasAuth(9902010104) && buttons.push(<Button key="2" onClick={this.signout.bind(this, true)} className="btn-warning">退租</Button>)
                userUtil.hasAuth(9902010105) && buttons.push(<Button key="5" onClick={this.abandon.bind(this, true)} className="btn-warning">作废</Button>)
            }
        } else {
            if (canOperate) {
                userUtil.hasAuth(9902010105) && buttons.push(<Button key="5" onClick={this.abandon.bind(this, true)} className="btn-warning">作废</Button>)
            }
        }
        if (canAudit && auditStatus === 0) { // 审批的时候只有三个审批的按钮
            buttons = []
            buttons.push(<Button key="7" onClick={this.terminal} type="danger">终止</Button>)
            buttons.push(<Button key="8" onClick={this.reject} type="danger">驳回</Button>)
            buttons.push(<Button key="9" onClick={this.pass} type="dashed">通过</Button>)
        }
        return (
            <div className="contract-detail">
                 <div className="contract-breadcrumb">
                    <Breadcrumb>
                        <BreadcrumbItem>操作中心</BreadcrumbItem>
                        <BreadcrumbItem><Link to='/app/operate/contract'>租客合同</Link></BreadcrumbItem>
                        <BreadcrumbItem>合同详情</BreadcrumbItem>
                    </Breadcrumb>
                    <div className="action-btn">
                        {buttons}
                    </div>
                </div>
                { AuditTip }
                { AuditCom }
                <div className="detail-block">
                    <div className="title">{this.state.contractInfo.customerName} · {this.state.contractInfo.projectName}</div>
                    <Tabs activeKey={this.state.activeTabKey} onChange={this.changeTab} animated={false}>
                        <TabPane tab="合同信息" key="1">
                            <AuditInfo info={this.state.contractInfo} contractYears={this.state.contractYears}/>
                        </TabPane>
                        <TabPane tab="收付详情" key="2">
                            <RXDetail info={this.state.contractInfo}/>
                        </TabPane>
                        <TabPane tab="关联合同" key="3">
                            <RelatedContract  history={this.props.history} code={this.state.contractInfo.contractCode}/>
                        </TabPane>
                        <TabPane tab="操作记录" key="4">
                            <OperateRecord code={this.state.contractInfo.contractCode}/>
                        </TabPane>
                    </Tabs>
                </div>
                <AbandonModal show={this.state.showAbandon} close={this.abandon.bind(this, false)} info={this.state.contractInfo} />
                <SignoutModal show={this.state.showSignout} close={this.signout.bind(this, false)} info={this.state.signoutInfo} />
                <Modal
                    visible={this.state.showModal}
                    closable={false}
                    maskClosable={false}
                    width={400}
                    title={['通过', '驳回', '终止'][this.state.auditFlag - 1]}
                    onCancel={this.cancel}
                    onOk={this.confirm}
                    okText={['通过', '驳回', '终止'][this.state.auditFlag - 1]}
                >   <div className="contract-detail-modal">
                        <p>确认{['通过', '驳回', '终止'][this.state.auditFlag - 1]}此合同？</p>
                        <Input.TextArea placeholder={"请输入备注（选填）" }
                                        onChange={this.setAuditReason} value={this.state.auditReason} maxLength={200} />
                    </div>
                </Modal>
            </div>
        );
    }
}

export default Audit;