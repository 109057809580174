import React, { Component } from 'react';
import { API } from '@/http/api';
import http from '@/http';
import Select from 'CMPT/common/Select';
import OrderTable from './order/OrderTable';
import OrderPriceChange from './order/OrderPriceChange';
import OrderConfirmContent from './order/OrderConfirmContent';
import OrderCredentialsCheck from './order/OrderCredentialsCheck';
import moment from 'moment';
import storage from '@/utils/storage';
import { Breadcrumb, Input, DatePicker, Tabs, Modal, Icon, Tag, Tooltip, Spin, Button, message } from 'antd';
import LogisticsOrderList from './LogisticsOrder/LogisticsOrderList';
import  EquipmentIntentionList  from  './EquipmentIntention/List';
import EquipmentOrderList from "./EquipmentOrder/List";
import userUtils from '@/utils/user';
const { isUs } = userUtils;
const BreadcrumbItem = Breadcrumb.Item;
const TabPane = Tabs.TabPane;
const { confirm } = Modal;
export default class Order extends Component{
    state = {
        uuid:moment(new Date()).format(),
        currentTab: '1',
        params: {
            payType:undefined,//支付方式
            pageSize: 10,
            pageNo: 1,
            keyWord: '',
            keywordBuyer:undefined,
            saleStatus: undefined,
            // commentStatus: undefined,
            orderStatus: undefined,
            orderDate: undefined,
            orderDateMoment: undefined,
            shop: undefined,
            total: 0
        },
        saleStatusList: [
            { value: 1, name: '待审核' }
        ],
        commentStatusList: [
            { value: 1, name: '已评论' }
        ],
        pingtaiList: [
            { value: null, name: '全部' },
            { value: 1, name: 'WEB端' },
            { value: 2, name: '小程序端' }
        ],
        payMethod: [
            { value: 1, name: '线上支付' },
            { value: 0, name: '线下支付' },
            { value: 2, name: '账期支付' }
        ],
        orderStatusList: [],
        shopList: [],
        activeTabKey: '99',
        columns: [
            {
                key: 'orderMainCode',
                name: '商品信息',
                width: '24%',
            },
            {
                key: 'orderTotal',
                name: '订单总额',
                width: '8%'
            },
            {
                key: 'buyerType',
                name: '买家类型',
                width: '8%'
            },
            // {
            //     key: 'num',
            //     name: '数量',
            //     width: '5%'
            // },
            // {
            //     key: 'saleType',
            //     name: '售后类型',
            //     width: '7%'
            // },
            // {
            //     key: 'saleStatus',
            //     name: '售后状态',
            //     width: '7%'
            // },
            {
                key: 'nikeName', 
                name: '买家',
                width: '8%'
            },
            {
                key: 'companyName', //  
                name: '商家名称',
                width: '10%'
            },
            {
                key: 'shopName', //  
                name: '店铺名称',
                width: '10%'
            },
            {
                key: 'orderStatus',
                name: '订单状态',
                width: '8%'
            },
            
            {
                key: 'source',
                name: '下单平台',
                width: '8%'
            },
            {
                key: 'payMethod',
                name: '付款方式',
                width: '6%'
            },
            // {
            //     key: 'buyerRemark',
            //     name: '买家备注',
            //     width: '7%'
            // },
            // {
            //     key: 'invoiceStatus',
            //     name: '发票状态',
            //     width: '7%'
            // },
            {
                key: 'operate',
                name: '操作',
                width: '10%'
            }
        ],
        orderList: [],
        showPriceModal: false,
        closeOrderDesc: '',
        showCredentialsModal: false,
        currentOrderCode: ''
    }
    componentDidMount() {
        let {search} = this.props.location
            if(search){
                var str = search.substr(1);
                this.setState({
                currentTab: str.split("=")[1]
            })
        }
        let order_params = storage.get('order_params') || null;
        if (order_params) {
            for (const key in order_params) {
                if (key == 'orderDateMoment' && order_params['orderDate'] && order_params['orderDate'][0]) {
                    order_params[key] = [moment(order_params['orderDate'][0]), moment(order_params['orderDate'][1])]
                }
            }
            this.setState({params: order_params})
        }
        this.getSearchCondition(this.getOrderList)
        this.searchShop()

    }
    getSearchCondition = (cb) => {
        const config = {
            api: API.orderCondition,
        }
        http(config).then(res => {
            let saleList = res.data.afterServiceStatus.map(per => {
                return {
                    value: per.key,
                    name: per.value
                }
            })
            saleList.unshift({value: 99, name: '全部'})
            let statusList = res.data.orderStatus.map(per => {
                return {
                    value: per.key,
                    name: per.value
                }
            })
            statusList.unshift({ value: 99, name: '全部' })
            if (res.success) {
                this.setState({
                    saleStatusList: saleList,
                    orderStatusList: statusList
                }, () => { // 搜索条件返回后再调列表，否则订单状态名字不能回显
                    if (cb) {
                        cb()
                    }
                })
            }
        })
    }
    searchShop = (keyWord) => {
        const config = {
            api: API.commonShopList,
        }
        if (keyWord) {
            config.data = {
                shopName: keyWord
            }
        }
        http(config).then(res => {
            if (res.success) {
                const shopList = res.data && res.data.map(per => {
                    return {
                        value: per.code,
                        name: per.shopName
                    }
                }) || []
                // console.log(shopList)
                this.setState({shopList})
            }
        })
    }
    getOrderList = () => {
        const config = {
            api: API.orderList,
            showLoading: true,
            data: this.mapAttr()
        }
        http(config).then(res => {
            if (res && res.success) {
                this.mapResponse(res.data.datas)
                this.setState({
                    params: Object.assign({}, this.state.params, { total: parseInt(res.data.total) })
                })
            }
        })
    }
    mapAttr = () => {
        let params = this.state.params
        let obj = {
            pageNo: params.pageNo,
            pageSize: params.pageSize,
            keywordMgr: params.keyWord ? params.keyWord.trim() : '',
            keywordBuyer: params.keyWordBuyer ? params.keyWordBuyer.trim() : '',
            afterServiceStatus: params.saleStatus,
            orderStatus: params.orderStatus,
            channelType: params.channelType,
            shopCodeList: params.shop ? [params.shop] : null,
            payType:params.payType
        }
        if (params.orderDate && params.orderDate) {
            obj.startDate = params.orderDate[0]
            obj.endDate = params.orderDate[1]
        }
        return obj
    }
    mapResponse = (list) => {
        let target = []
        if (list && list.length) {
            list.forEach(main => {
                let obj = {}
                obj.orderMainCode = main.orderMainCode
                obj.orderNo = main.orderNo
                obj.orderDate = main.createTime
                obj.ordertotal = main.finalAmount && main.finalAmount.toFixed(2)
                obj.goodsInfo = main.orderSubVos.map(sub => {
                    if(sub.pricingType == 1) {
                        obj.ordertotal = '需询价'
                    }
                    const info = sub.afterServiceInfo || {}
                    let pp = {
                        skuId: sub.skuCode,
                        img: sub.coverImageUrl,
                        orderSubCode: sub.orderSubCode,
                        goodsName: sub.orderSkuSnapshotDto && sub.orderSkuSnapshotDto.itemName,
                        spec: sub.orderSkuSnapshotDto && sub.orderSkuSnapshotDto.skuSpecValue,
                        goodsPrice: sub.orderSkuSnapshotDto && sub.orderSkuSnapshotDto.salePrice && sub.orderSkuSnapshotDto.salePrice.toFixed(2),
                        num: sub.quantities,
                        saleType: info.serviceType || '-',
                        saleTypeName: info.serviceTypeName || '-',
                        saleStatus: info.serviceStatus || '-',
                        saleStatusName: info.serviceStatusName || '无需售后',
                        className: this.mapSkuClassName(info.serviceStatus),
                    }
                    if (info.serviceCode) {
                        pp.saleLink = `/#/app/Supplier/transaction/AfterSales/details?serviceCode=${info.serviceCode}`
                    }
                    return pp
                })
                // const express = main.expressInfo || {}
                // obj.logisticsInfo = {
                //     isService: express.logisticsBigType === 2, // 1普通物流 2无需物流
                //     servicerName: express.serviceStaffName || '-',
                //     servicerPhone: express.serviceStaffPhone || '-',
                //     logisticsCompany: express.expressCompany || '-',
                //     logisticsCompanySimple: express.expressSimpleName,
                //     logisticsNo: express.expressNo || '-',
                //     logisticsTime: express.expressTime || '-',
                //     buyerAddress: this.getAddressStr(main.buyerOrderAddressVo, buyerInfo.nickName) || '-',
                //     shopAddress: this.getAddressStr(main.deliveryOrderAddressVo) || '-',
                //     sendVoucher: express.sendVoucher? express.sendVoucher.map(item => item.url): [],
                //     receiptVoucher: express.receiptVoucher? express.receiptVoucher.map(item => item.url): [],
                //     orderMainCode:main.orderMainCode
                // }
                obj.buyerInfo = {
                    nickName: main.buyerInfo ?main.buyerInfo.nickName:'',
                    mobile: main.buyerInfo ?main.buyerInfo.mobile:''
                }
                obj.companyName = main.companyName;
                obj.buyerType = main.buyerInfo && main.buyerInfo.isSupplier ? '公司' : '个人'
                obj.orderStatus = main.status
                obj.className = [90, 91].indexOf(obj.orderStatus) !== -1 ? 'dark' : 'lighter'
                obj.orderStatusName = this.mapStatusName(obj.orderStatus)
                obj.freight = main.finalExpressAmount && main.finalExpressAmount.toFixed(2)
                obj.source = main.channelTypeName
                obj.shopName = main.shopVo.shopName
                obj.buyerRemark = main.buyerRemark || '-'
                obj.invoiceStatus = {0: '-', 1: '已开票', 2: '开票中' }[main.invoiceStatus || 0];
                // 支付方式
                obj.paytypemethod = main&& main.payInfo&& main.payInfo.payType;
                obj.payMethod = obj.orderStatus === 10 ? '-' : obj.paytypemethod === 1 ? '线上支付' : obj.paytypemethod === 2 ? '账期支付' : '线下支付';
                // operations => '查看订单', '修改价格', '关闭交易', '凭证审核', '发货'
                // { value: 10, name: '待付款' }, // 查看订单、修改价格、关闭交易
                // { value: 20, name: '已付款' }, // 查看订单、凭证审核
                // { value: 30, name: '待发货' }, // 查看订单、发货
                // { value: 40, name: '待收货' }, // 查看订单
                // { value: 90, name: '订单成功' }, // 查看订单
                // { value: 91, name: '订单关闭' } // 查看订单
                obj.operations = [1]
                switch(obj.orderStatus) {
                    // case 10:
                    //     obj.operations.push(2, 3)
                    //     break
                    // case 20:
                    //     obj.operations.push(4)
                    //     break
                    // case 30:
                    //     obj.operations.push(5)
                    //     break
                    default:
                        
                }
                target.push(obj)
            })
        }
        this.setState({
            orderList: target
        })
    }
    mapStatusName = (status) => {
        const item = this.state.orderStatusList.find(per => {
            return per.value === status
        })
        return item ? item.name : '-'
    }
    mapSkuClassName = (status) => {
        if (!status && status !== 0) return ''
        let name = 'operating'
        if (status === 0) {
            name = 'pending'
        } else if ([6, 7].indexOf(status) !== -1) {
            name = 'done'
        }
        return name
    }
    storageParams = () => {
        storage.set('order_params', this.state.params)
    }
    keyWordSearch = (value) => {
        let params = this.state.params
        params.pageNo = 1
        params.keyWord = value;
        params.orderStatus = null;
        params.channelType = null;
        params.orderDate = undefined;
        params.orderDateMoment = undefined;
        this.setState({
            params
        }, this.getOrderList)
        this.storageParams()
    }

    keyWordSearchBuyer = (value) => {
        let params = this.state.params
        params.pageNo = 1
        params.keyWordBuyer = value;
        params.orderStatus = null;
        params.channelType = null;
        params.orderDate = undefined;
        params.orderDateMoment = undefined;
        this.setState({
            params
        }, this.getOrderList)
        this.storageParams()
    }

    

    setKeyWord = (e) => {
        let params = this.state.params
        params.keyWord = e.target.value
        this.setState({
            params
        })
    }

    setKeyWordBuyer = (e) => {
        let params = this.state.params
        params.keywordBuyer = e.target.value
        this.setState({
            params
        })
    }

    


    selectChange = (attr, data) => {
        let params = this.state.params
        params[attr] = data

        if (data === 99) {
            params[attr] = undefined
        }
        params.pageNo = 1
        this.setState({ params }, this.getOrderList)
        this.storageParams()
    }
    dateRangeChange = (attr, value, list) => {
        let params = this.state.params
        params.pageNo = 1
        params[attr] = list
        params[attr + 'Moment'] = value
        this.setState({
            params
        }, this.getOrderList)
        this.storageParams()
    }
    paginationChange = (pageNo, pageSize) => {
        let params = this.state.params;
        params.pageNo = pageNo;
        params.pageSize = pageSize;
        this.setState({params}, this.getOrderList)
        this.storageParams()
    }
    resetParams = () => {
        let params = this.state.params
        Object.keys(params).forEach(key => {
            if (key === 'keyWord') {
                params[key] = ''
            } else {
                params[key] = undefined
            }
        })
        params.pageNo = 1
        params.pageSize = 10
        this.setState({
            params
        }, this.getOrderList)
        this.storageParams()
    }
    exportData = () => {
        const config = {
            api: API.orderExport,
            showLoading: true,
            data: this.mapAttr(),
            responseType: 'blob',
        }
        http(config).then(res => {
            let reader = new FileReader()
            reader.onload = e => {
                if (e.target.result.indexOf('"success":false') !== -1) {
                    const resultParse = JSON.parse(e.target.result)
                    message.error(resultParse.msg)
                } else {
                    const content = res
                    const blob = new Blob([content], { type: 'application/vnd.ms-excel' })
                    const fileName = '订单列表'
                    if ('download' in document.createElement('a')) { // 非IE下载
                        const elink = document.createElement('a')
                        elink.download = fileName
                        elink.style.display = 'none'
                        elink.href = URL.createObjectURL(blob)
                        document.body.appendChild(elink)
                        elink.click()
                        URL.revokeObjectURL(elink.href) // 释放URL 对象
                        document.body.removeChild(elink)
                    } else { // IE10+下载
                        navigator.msSaveBlob(blob, fileName)
                    }
                }
            }
            reader.readAsText(res)
        })
    }
    changeTab = (key) => {
        this.setState({
            activeTabKey: key
        })
    }
    operate = (status, code) => {
        switch(status) {
            case 1: // 查看详情
                this.props.history.push(`/app/crm/orderManage/${code}`)
                break
            case 2: // 修改价格
                this.updateModal(true, code)
                break
            case 3: // 关闭交易
                this.closeOrder(code)
                break
            case 4: // 凭证审核
                this.updateCheckModal(true, code)
                break
            case 5: // 发货
                this.props.history.push(`/app/Supplier/transaction/ship/${code}`)
                break
            default:
                
        }
    }
    closeOrder = (code) => {
        confirm({
            content: <OrderConfirmContent label="请您在与卖家达成一致前提下使用此功能~" placeholder="请输入" onChange={this.setCloseDesc} length={50} />,
            className: 'order-modal-confirm',
            okText: '关闭交易',
            onOk: () => {
                if (!this.state.closeOrderDesc) {
                    message.warning('请输入关闭订单原因')
                    return Promise.reject()
                }
                this.orderCancel(code)
            }
        });
    }
    orderCancel = (code) => {
        const config = {
            api: API.orderClose,
            showLoading: true,
            data: {
                cancelReason: this.state.closeOrderDesc,
                orderMainCode: code
            }
        }
        http(config).then(res => {
            if (res && res.success) {
                message.success('关闭订单成功')
                this.getOrderList()
                this.setState({
                    closeOrderDesc: ''
                })
            }
        })
    }
    setCloseDesc = (e) => {
        this.setState({
            closeOrderDesc: e.target.value
        })
    }
    updateModal = (status, code) => {
        this.setState({
            showPriceModal: status,
            currentOrderCode: code || ''
        })
    }
    priceChangeSuccess = () => {
        this.updateModal(false)
        this.getOrderList()
    }
    updateCheckModal = (status, code) => {
        this.setState({
            showCredentialsModal: status,
            currentOrderCode: code || ''
        })
    }
    credentialsChecked = () => {
        this.updateCheckModal(false)
        this.getOrderList()
    }
    // 物流订单 -- 2021.9.2
    changeTabs = (value) => {
        this.props.history.push(`/app/crm/orderManage?tab=${value}`);
         this.setState({
             currentTab: value
         })
    }
    render() {
        const { params,uuid } = this.state;

        return (
            <div className="supply-order-page">
                <div className="order-breadcrumb">
                    <Breadcrumb>
                        <BreadcrumbItem>订单管理</BreadcrumbItem>
                    </Breadcrumb>

                    {/* 物流订单 -- 2021.9.2 */}
                    <Tabs activeKey={this.state.currentTab}  onChange={this.changeTabs} className='tabs' style={{margin: 0,paddingBottom:0}}>
                        <TabPane tab="采购订单" key="1" className='tab'></TabPane>
                        {
                            isUs && 
                                <TabPane tab="物流订单" key="2" className='tab'>
                                    <Tabs defaultActiveKey="" >
                                        <TabPane tab="全部" key="" className='tab' >
                                            <LogisticsOrderList state="" />   
                                        </TabPane>
                                        <TabPane tab="待装货" key="3" className='tab' >
                                            <LogisticsOrderList state="3" /> 
                                        </TabPane>
                                        <TabPane tab="运输中" key="4" className='tab'>
                                            <LogisticsOrderList state="4" /> 
                                        </TabPane>
                                        <TabPane tab="待支付" key="5" className='tab'>
                                            <LogisticsOrderList state="5" /> 
                                        </TabPane>
                                        <TabPane tab="已支付" key="6" className='tab'>
                                            <LogisticsOrderList state="6" /> 
                                        </TabPane>
                                        <TabPane tab="待完成" key="7" className='tab'>
                                            <LogisticsOrderList state="7" /> 
                                        </TabPane>
                                        <TabPane tab="已完成" key="1" className='tab'>
                                            <LogisticsOrderList state="1" /> 
                                        </TabPane>
                                        <TabPane tab="已取消" key="2" className='tab'>
                                            <LogisticsOrderList state="2" /> 
                                        </TabPane>
                                    </Tabs>
                                </TabPane>
                        }
                        {   isUs &&     <TabPane tab="设备租赁意向单" key={'3'} className='tab'>
                                <Tabs defaultActiveKey="" >
                                    <TabPane tab="全部" key="" className='tab'>
                                    <EquipmentIntentionList  pageId={uuid}  status=""/>
                                    </TabPane>
                                    <TabPane tab="待确认" key="0" className='tab'>
                                    <EquipmentIntentionList pageId={uuid} status="0"/>
                                    </TabPane>

                                    <TabPane tab="待修改" key="1" className='tab'>
                                    <EquipmentIntentionList  pageId={uuid} status="1"/>
                                    </TabPane>

                                    <TabPane tab="已成交" key="2" className='tab'>
                                    <EquipmentIntentionList  pageId={uuid} status="2"/>
                                    </TabPane>
                                    <TabPane tab="已失效" key="3" className='tab'>
                                    <EquipmentIntentionList  pageId={uuid} status="3"/>
                                    </TabPane>

                                    <TabPane tab="已取消" key="4" className='tab'>
                                    <EquipmentIntentionList pageId={uuid}  status="4"/>
                                    </TabPane>
                                </Tabs>
                                </TabPane> 
                                }
                                {   isUs &&   
                                <TabPane tab="设备租赁订单" key={'4'} className='tab'>
                                <Tabs defaultActiveKey="" >
                                    <TabPane tab="全部" key="" className='tab'>
                                        <EquipmentOrderList pageId={uuid} status=""/>
                                    </TabPane>
                                    <TabPane tab="待付款" key="3" className='tab'>
                                        <EquipmentOrderList pageId={uuid} status="3"/>
                                    </TabPane>
                                    <TabPane tab="重新付款" key="5" className='tab'>
                                        <EquipmentOrderList pageId={uuid} status="5"/>
                                    </TabPane>
                                    <TabPane tab="待确认" key="4" className='tab'>
                                        <EquipmentOrderList pageId={uuid} status="4"/>
                                    </TabPane>
                                    <TabPane tab="已付款" key="1" className='tab'>
                                        <EquipmentOrderList pageId={uuid} status="1"/>
                                    </TabPane>
                                    <TabPane tab="取消中" key="2" className='tab'>
                                        <EquipmentOrderList  pageId={uuid} status="2"/>
                                    </TabPane>
                                    <TabPane tab="已取消" key="0" className='tab'>
                                        <EquipmentOrderList pageId={uuid} status="0"/>
                                    </TabPane>
                                
                                
                                
                                
                                
                                    </Tabs>
                                </TabPane>
                        }

                    
              
                       
                        {/* <TabPane tab="租赁订单" key={'3'} className='tab'>

                        </TabPane> */}
                       
                </Tabs>
                </div>
                {this.state.currentTab == 1 ? 
                (
                    <>
<div className="order-search order-block">
                    <ul className="status-tab">
                        {this.state.orderStatusList.map(per => {
                            return <li key={per.value} className={(params.orderStatus ? params.orderStatus : 99) === per.value ? 'active' : '' }
                                        onClick={this.selectChange.bind(this, 'orderStatus', per.value)}>{per.name}</li>
                        })}
                    </ul>
                    <div className="search-row">
                   
                        <span>
                            <Select data={this.state.shopList} placeholder="店铺" value={params.shop} showSearch
                                filterOption={false} onSearch={this.searchShop} allowClear  onChange={this.selectChange.bind(this, 'shop')}
                                 style={{ width: '124px' }} />
                        </span>
                        <span>
                            <Select data={this.state.pingtaiList} placeholder="下单平台" value={params.channelType}
                                selectMethod={this.selectChange.bind(this, 'channelType')} style={{ width: '100px' }} />
                        </span>
                        <span>
                            <Select data={this.state.payMethod} placeholder="付款方式" value={params.payType}
                                selectMethod={this.selectChange.bind(this, 'payType')} style={{ width: '100px' }} />
                        </span>
                        <span className="row-span">
                            <span className="search-text">下单时间</span>
                            <DatePicker.RangePicker style={{ width: '280px', paddingLeft: '64px' }} value={params.orderDateMoment}
                                onChange={this.dateRangeChange.bind(this, 'orderDate')} />
                        </span>
                        <span>
                            <Input.Search placeholder="买家姓名、手机号码" onChange={this.setKeyWordBuyer}
                                onSearch={this.keyWordSearchBuyer} value={params.keywordBuyer} style={{ width: '180px' }} maxLength={20} />
                        </span>

                        <span>
                            <Input.Search placeholder="订单编号、商品名称" onChange={this.setKeyWord}
                                onSearch={this.keyWordSearch} value={params.keyWord} style={{ width: '180px' }} maxLength={20} />
                        </span>
                        {/* <span>
                            <Select data={this.state.orderStatusList} placeholder="订单状态" value={params.orderStatus}
                                selectMethod={this.selectChange.bind(this, 'orderStatus')} style={{ width: '104px' }} />
                        </span> */}
                        
                        <span>
                            <a onClick={this.resetParams}>重置</a>
                        </span>
                        {/* <span>
                            <Button onClick={this.exportData}>导出订单</Button>
                        </span> */}
                    </div>
                </div>
                <div className="order-block order-table">
                    <div className="order-status">
                        
                        <OrderTable
                            columns={this.state.columns}
                            orderList={this.state.orderList}
                            operate={this.operate}
                            showMore={true}
                            total={params.total}
                            pageNo={params.pageNo}
                            onChange={this.paginationChange}
                        />
                    </div>
                </div>
                <OrderPriceChange show={this.state.showPriceModal}
                                  orderCode={this.state.currentOrderCode}
                                  cancel={this.updateModal}
                                  handleSuccess={this.priceChangeSuccess} />
                <OrderCredentialsCheck
                                  orderCode={this.state.currentOrderCode}
                                  show={this.state.showCredentialsModal}
                                  onAudit={this.credentialsChecked}
                                  onCancel={this.updateCheckModal} />
                    </>
                ) : null
                }
               
                
            </div>
        );
    }
}
