import Table from 'CMPT/common/Table';
import http from '@/http';
import { API } from '@/http/api';
import { formatLinkSearch } from '@/utils'
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import { convertAuthenticationToLabel, convertUpgradeToLabel } from 'CMPT/operate/customerConfig.js'
import userUtil from '@/utils/user';
import './EnterpriseUpgradeList.less';
import moment from 'moment';


class EnterpriseUpgradeList extends Component {
    state = {

        total: 0, // 当前数据的总数
        currentPageCount: 0, // 当前页的数量
        pageNo: 1, // 当前所展示的页码
        currentPageCount: 0, //当前页面的数量

        selectParams: {  // 接口入参，必须放这里。方便分页时保存信息
            upgradeStatus: this.props.state,// 审批状态
            pageNo: 1, // 需要查询的页数
            pageSize: 10, // 每页条数
        },
        columns: [
            {
                title: '序号',
                key: "code",//(text,record,index)=>index,
                render: (text, record, index) => {
                    return (<span>{index + 1}</span>);
                }
            }, {
                title: '企业名称',
                dataIndex: 'companyName',
                key: 'companyName',
                render: (text, record) => {
                    let value;
                    if (text && text.length > 20) {
                        value = <Tooltip placement="top" title={text}>{text.slice(0, 20)}...</Tooltip>
                    } else {
                        value = text
                    }
                    return (<span>{value ? value : '-'}</span>)
                }
            },
            {
                title: '申请人姓名',
                dataIndex: 'personalName',
                key: 'personalName',
                render: (text, record) => { //待提交状态的，打开编辑页
                    let value;
                    if (text && text.length > 20) {
                        value = <Tooltip placement="top" title={text}>{text.slice(0, 20)}...</Tooltip>
                    } else {
                        value = text
                    }

                    return (<span   >{value}</span>);

                }
            }, {
                title: '申请人手机号',
                dataIndex: 'personalPhone',
                key: 'personalPhone',
                render: (text, record) => {
                    return (<span>{text}</span>);
                }
            },
            {
                title: '企业目前身份',
                dataIndex: 'authenticationTo',
                key: 'authenticationTo',
                render: (text, record) => {
                    return (<span>{convertAuthenticationToLabel(text)}</span>);
                }
            }, {
                title: '升级成为',
                key: 'upgradeTo',
                dataIndex: 'upgradeTo',
                render: (text, record, index) => {
                    return (<span  > {convertUpgradeToLabel(text)}</span>)
                }
            },
            {
                title: this.props.state == 0 ? '提交时间' : '审核时间',  // 0 表示待审核状态
                dataIndex: this.props.state == 0 ? 'applyTime' : 'upgradeTime',
                key: this.props.state == 0 ? 'applyTime' : 'upgradeTime',
                render: (text, record) => {
                    console.log(' applyTime;%s', text);
                    //return (<span>{moment(text).format('YYYY-MM-DD HH:mm')}</span>);
                    return (<span>{text}</span>);
                }
            }, {
                title: '操作',
                key: 'operate',
                render: (text, record, index) => {
                    return (<span className="table-operate-btn">
                        <Link to={'/app/crm/enterprise_upgrade/detail/' + record.code} className="operate">查看详情</Link>
                    </span>)
                }
            }
        ],
        data: []
    }




    componentWillMount() {
        if (this.props.location) {
            // 获取参数
            const search = this.props.location.search
            const formatData = formatLinkSearch(search)

            // 解析
            let pageNo1 = null;
            let currentageCount1 = null;


            if (formatData && formatData.backPageNo) {
                pageNo1 = formatData.backPageNo
            }


            // 赋值
            this.setState({
                selectParams: {
                    authenticationStatus: this.props.state,// 审批状态
                    pageNo: pageNo1, // 当前页数
                    pageSize: 10, // 每页条数
                }
            });
        }
    }

    //初始化
    componentDidMount() {
        this.getLists();
    }


    //查询列表
    getLists() {
        const config = {
            api: API.enterpriseUpgradeList,
            data: this.state.selectParams,
            showLoading: true
        };
        http(config).then(res => {
            if (res && res.data && res.data.datas) {

                const data = res.data;

                this.setState({
                    data: res.data.datas || [],
                    total: data.totalRecord,
                    pageNo: data.pageNo,
                    currentPageCount: res.data.datas ? res.data.datas.length : 0,
                })
            }

        })
    }


    //分页
    onChangePage = (pageNo, pageSize, sort) => {
        //console.log(pageNo, pageSize, sort, '====')

        this.getListFun({ type: 'pageNo', data: pageNo }, { type: 'pageSize', data: pageSize })
    }
    getListFun(obj1, obj2) {
        let selectParams = this.state.selectParams;
        // selectParams.pageNo = 1;

        if (obj1) {
            selectParams[obj1.type] = obj1.data;
        }
        if (obj2) {
            selectParams[obj2.type] = obj2.data;
        }
        this.setState({
            selectParams
        }, () => {
            this.getLists();
        })
    }

    reset = () => {
        this.setState({
            selectParams: {
                authenticationStatus: this.props.state,// 审批状态
                pageNo: 1, // 当前页数
                pageSize: 10, // 每页条数
            }
        }, () => {
            this.getLists();
        })
    }


    render() {
        const selectParams = this.state.selectParams
        return (
            <div className='enterprise-auth-list'>
                <Table rowKey="code" columns={this.state.columns} selfProcessData={this.state.data} total={this.state.total} change={this.onChangePage} current={selectParams.pageNo} />
            </div>

        );
    }
}

export default EnterpriseUpgradeList;
