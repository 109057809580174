import React, { Component } from 'react';
import { Tooltip } from "antd";
import PropTypes from 'prop-types';
import "./LineAverage.less";


//TODO   需求还需要再理理，进行扩展，应该还可以加个 Button。 比如有些情况下 会有 跳转到其他页面的需求
export default class LineAverage extends React.Component {
    render() {
       return  <div className="line_average">
            {React.Children.map(this.props.children, child => {
                return React.cloneElement(child, {
                    //把父组件的props.name赋值给每个子组件
                    className: "child_average"
                })

            })}

        </div>
    }
}



