import React, { Component } from 'react';
import { Breadcrumb,Icon,Form, Input, Button, Checkbox,Cascader, Modal,Rate} from 'antd';
import { Link } from 'react-router-dom'
import debounce from 'lodash/debounce';
import Select from 'CMPT/common/Select';
import regex from '@/utils/regex';
import { changeObjkey } from '@/utils'
// import BsCascader from 'CMPT/common/Cascader';
import http from '@/http';
import { API } from '@/http/api';
import AuthContainer from 'CMPT/common/auth/AuthContainer';
import { AUTH_KEYS } from '@/utils/auth';
import './SupplierEdit.less';
const BreadcrumbItem = Breadcrumb.Item;
const FormItem = Form.Item;
const { TextArea } = Input;


class SupperlierEdit extends Component {  
    state = {
        visible: false,
        isNew: true,
        auditStatus: 60,
        id: '',
        identitype: [],
        payeeTypes: [],
        userLists: [],
        initialPayeeType: '',
        supperlierForm : {
            name: null, //供应商名称
            creditType: 1, //唯一标识
            creditNumber: null ,// 统一信用码
            countryEncode: '1xuCXiocC8A', //国家 
            provinceEncode: null, //省 
            cityEncode: null, //市 
            regionEncode: null, //区 
            address:null,
            contactName: null, //联系人姓名
            contactPhone: null, //联系人电话
            userEncode: undefined, //对接人
            remark: null, //说明
            payeeType: undefined, // 收款方式
            payeeTypeDesc: null, //收款方式描述
            payee: null, //收款人姓名
            payeeAccount: null, //收款账户
            goodsInfo: null, // 商品信息
            score: null, // 评分
        },
        residences: []
    }  
    componentWillMount() {
        const search = this.props.location.search;
        if(search) {
            this.setState({
                auditStatus: search.split('=')[1],
                isNew: false,
                id: this.props.match.params.id
            }) 
        }
        this.editInit();
        this.getArea();
        this.getUser();
    }
    componentDidMount() {
        let that = this
        // if(!this.state.isNew) {
        //     setTimeout(function() {
        //         that.getDetail();
        //     },1000)
        // }
    }
    getDetail = () => {
        const config = {
            api: API.supplierQueryDetail,
            path: {encode: this.state.id},
            showLoading: true
        };
        http(config).then(res => {
                const data = res.data;
                this.setState({
                    initialPayeeType: data.payeeType,
                    creditTypeDesc: data.creditTypeDesc,
                    supperlierForm: {
                        name: data.name,
                        creditType: data.creditType,
                        creditNumber: data.creditNumber,
                        countryEncode: data.countryEncode, //国家
                        provinceEncode: data.provinceEncode, //省
                        cityEncode: data.cityEncode, //市
                        regionEncode: data.regionEncode, //区
                        address: data.address,
                        contactName: data.contactName, 
                        contactPhone: data.contactPhone, 
                        userEncode: data.userEncode ? data.userEncode : undefined, 
                        remark: data.remark,
                        payeeType: data.payeeType ? data.payeeType : undefined, 
                        payeeTypeDesc: data.payeeTypeDesc,
                        payee: data.payee, 
                        payeeAccount: data.payeeAccount,
                        goodsInfo: data.goodsInfo ? data.goodsInfo : undefined,
                        score: data.score ? data.score/2 : null
                    }
                })  
        })
    }
    editInit = () => {
        const config = {
            api: API.supplierEditInit,
            showLoading: false
        };
        http(config).then(res => {
                this.setState({
                    identitype: res.data.creditType&& res.data.creditType.map(item => { return { value: item.key, name: item.value } }) ,
                    payeeTypes: res.data.payeeType&& res.data.payeeType.map(item => { return { value: item.key, name: item.value } }),
                    auditConfig:  res.data.auditConfig
                })
        })
    }
    //国家
    getArea() {
        const config = {
            api: API.areaCountries,
            showLoading: false
        };
        http(config).then(res => {
                const json = res.data && res.data.map(item => {
                    if(item.code == '1xuCXiocC8A') {
                        this.getProvince(item);
                    }
                    return item
                });          
                this.setState({
                    residences: json
                },()=> {
                    if(!this.state.isNew) {                
                        this.getDetail();                 
                    }
                })                 
        })   
    }
    //省
    getProvince(obj) {
        const config = {
            api: API.areaChinaArea      
        };
        http(config).then(res => {
                obj.subAreas = res.data
        })
    }      
    //对接人
    getUser() {
        const config = {
            api: API.userGetList,
            data: {pageSize: null},
            showLoading: false
        };
        http(config).then(res => {
                this.setState({
                    userLists: res.data.datas && res.data.datas.map(item => {
                        return { value: item.idCode, name: item.name, account: item.account }
                    }) 
                })                  
        }) 
    }
    //选择唯一标识
    chooseIdentitype = (data) => {
        setTimeout(()=>{
            this.props.form.setFieldsValue({
                creditType: data
            })
        },0)       
    }
    //选择省市区
    chooseResidences = (value, selectedOptions) => {
        setTimeout(()=>{
            this.props.form.setFieldsValue({
                residence: value
            }) 
        },0)     
    }
      //选择对接人
    chooseUser = (data) => {
        setTimeout(()=>{
            this.props.form.setFieldsValue({
                userEncode: data
            })
        },0)
    }
    //搜索对接人
    onSearchUser = (nameLike) => {
        if(!nameLike) nameLike = null;
            const config = {
                api: API.userGetList,
                data: { nameLike, pageSize: null}, 
                showLoading: false
            };  
            http(config).then(res => {
                    this.setState({
                        userLists: res.data.datas && res.data.datas.map(item => {
                            return { value: item.idCode, name: item.name, account: item.account }
                       }) 
                    })
        })     
    }
    //选择收款方式
    choosePayeeType = (data) => {
        if(data == 99) {
            this.setState({
                initialPayeeType: data
            })
        } else {
            this.setState({
                initialPayeeType: ''
            })
        }
        setTimeout(()=>{
            this.props.form.setFieldsValue({
                payeeType: data
            }) 
        },0)    
    }
    params(values) {
        return {
            name: values.name, //供应商名称
            creditType: values.creditType, //唯一标识
            creditNumber: values.creditNumber ,// 统一信用码
            countryEncode: values.residence[0] || null, //国家
            provinceEncode: values.residence[1] || null, //省
            cityEncode: values.residence[2] || null, //市
            regionEncode: values.residence[3] || null, //区
            address: values.address,
            contactName: values.contactName, //联系人姓名
            contactPhone: values.contactPhone, //联系人电话
            userEncode: values.userEncode, //对接人
            remark: values.remark, //说明
            payeeType: values.payeeType, // 收款方式
            payeeTypeDesc: values.payeeTypeDesc, //收款方式描述
            payee: values.payee, //收款人姓名
            payeeAccount: values.payeeAccount, //收款账户
            goodsInfo: values.goodsInfo, // 商品信息
            score: values.score ? values.score*2 : null // 评分
        }
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
          if (!err) {
            this.setState ({
                supperlierForm: this.params(values)
            }, ()=> {
                if(this.state.auditStatus == 30) {     
                    this.submitAudit()  
                    //alert('驳回')          
                    //驳回，重新提交
                } else { //保存按钮
                   // this.state.isNew ? alert('新增') : alert("通过--待提交") 
                   this.state.isNew ? this.addSupplier(0) : this.editSupplier() 
                }         
            })        
          }
        });
    }
    saveAndSubmit = (e) => { //提交审批
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            console.log(values, 'values')
          if (!err) {
              this.setState({
                visible: true,
                values
              })       
          }
        });
    } 
    //弹窗确认提交审批
    handleOkSubmit = () => {
        this.setState ({
            supperlierForm: this.params(this.state.values)
        }, ()=> {   
            this.state.isNew ? this.addSupplier(1) : this.submitAudit()                    
        }) 
    }
    handleCancel = () => {
        this.setState({
            visible: false
        });
    }
    addSupplier(submitFlag) { //新增
        var data = {
            submitFlag
       };     
        Object.assign(data,this.state.supperlierForm)
        const config = {
            api: API.supplierAdd,
            data,
            showLoading: true 
        };
        http(config).then(res => {
            if(res.success) {
                this.props.history.push('/app/operate/serviceCenter/supplier');
            }
        })
    }
    editSupplier() { //编辑
        var data = {
            encode : this.state.id
       };     
        Object.assign(data,this.state.supperlierForm)
        const config = {
            api: API.supplierEdit,
            data,
            showLoading: true
        };
        http(config).then(res => {
            if(res.success) {
                this.props.history.push('/app/operate/serviceCenter/supplier');
            }
        })
    }
    submitAudit() { //重新提交审批
        var data = {
            encode : this.state.id
       };     
        Object.assign(data,this.state.supperlierForm)
        const config = {
            api: {url:'/web/supplier/saveAndSubmit', type: 'put',},
            data,
            showLoading: true
        };
        http(config).then(res => {
            if(res.success) {
                this.props.history.push('/app/operate/serviceCenter/supplier');
            }
        })
    }
    // 校验
    phoneValidator = (rule, value, cb) => {
        if (value && !/^([\d\+\-]){0,20}$/.test(value)) {
            cb('电话号码不合法')
        } else {
            cb()
        }
    }
    creditNumValidator = (rule, value, cb) => {      
        if (value && !/^[a-zA-Z\d]+$/.test(value)) {
            cb('请输入数字和字母')
        } else {
            cb()
        }
    }
    payeeAccountValidator = (rule, value, cb) => {      
        if (value && /[\u4E00-\u9FA5]/g.test(value)) {
            cb('只支持数字、英文和特殊字符')
        } else {
            cb()
        }
    }
    cancle() {
        this.props.history.go(-1)
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const supperlierForm = this.state.supperlierForm;
        const otherPayeeType = this.state.initialPayeeType == 99
         ?  
         <FormItem style={{ display: 'inline-block'}}>
            {getFieldDecorator('payeeTypeDesc', {
                rules: [{
                    required: false
                }],
                initialValue: supperlierForm.payeeTypeDesc
            })(
                <Input className="editForm-item" autoComplete="off" placeholder="请输入"/>
            )}
        </FormItem> : '';
        const auditStatusBool = this.state.auditConfig && (this.state.auditStatus == 10 || this.state.auditStatus == 60 ) ;
        return (
            <div className="supperlier-edit">
                 <div className='edit-header'>
                    <Breadcrumb>
                        <BreadcrumbItem>
                        <Link style={{ color: '#333' }} to={{ pathname: "/app/operate/serviceCenter/supplier" }}>
                        供应商列表页
                            </Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem>{this.state.isNew ? '新增': '编辑'}供应商</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <div>
                <Form onSubmit={this.handleSubmit} className="editForm">
                    <div className="edit-main">                                                                                                            
                        <div style={{display: this.state.auditStatus == 40 ? 'block' : 'none'}}>
                            <div className="title">账户信息</div>
                            <div>
                                <div className="read">
                                    供应商名称：{supperlierForm.name}
                                </div>
                                <div className="read">
                                    唯一标识：{this.state.creditTypeDesc}<span>{supperlierForm.creditNumber}</span>
                                </div>
                                <div className="read">
                                    商品信息：{supperlierForm.goodsInfo}
                                </div>
                            </div>
                        </div>
                       
                        <div style={{display: this.state.auditStatus == 40 ? 'none' : 'block'}}>
                            <FormItem label="供应商名称" required={true}>
                                {getFieldDecorator('name', {
                                    rules: [{
                                        required: true, message: '请填写供应商名称'
                                    }],
                                    initialValue: supperlierForm.name
                                })(
                                    <Input className="editForm-item" autoComplete="off" placeholder="请输入" maxLength={50}/>
                                )}
                            </FormItem>
                            
                            <FormItem label="商品信息" required={true}>
                                {getFieldDecorator('goodsInfo', {
                                    rules: [{
                                        required: true, message: '请填写商品信息'
                                    }],
                                    initialValue: supperlierForm.goodsInfo
                                })(
                                    <Input className="editForm-item" autoComplete="off" placeholder="请输入" maxLength={100}/>
                                )}
                            </FormItem>

                            <FormItem label="唯一标识" required={true}>
                                <FormItem style={{ display: 'inline-block',margin: '0px 20px 0px 0px'}}>
                                    {getFieldDecorator('creditType', {
                                        rules: [{
                                            required: this.state.auditStatus == 40 ? false : true, message: '请选择!'
                                        }],
                                        initialValue: supperlierForm.creditType
                                    })(
                                        <Select style={{width: '240px'}} data={this.state.identitype} selectMethod={this.chooseIdentitype} />
                                    )}
                                </FormItem>
                                <FormItem style={{ display: 'inline-block'}}>
                                    {getFieldDecorator('creditNumber', {
                                        rules: [{
                                            required: true, message: '请输入!'
                                        },{
                                            validator: this.creditNumValidator
                                        }],
                                        initialValue: supperlierForm.creditNumber
                                    })(
                                        <Input className="editForm-item" autoComplete="off" placeholder="请输入" maxLength={50}/>
                                    )}
                                </FormItem>
                            </FormItem>
                        </div>
                       
                        <FormItem label="地址">
                            <FormItem style={{ display: 'inline-block',margin: '0px 20px 0px 0px',width: '240px'}}>
                                {getFieldDecorator('residence', {
                                    initialValue: [supperlierForm.countryEncode, supperlierForm.provinceEncode, supperlierForm.cityEncode, supperlierForm.regionEncode],
                                    rules: [{ type: 'array', required: false}],
                                })(
                                    <Cascader options={this.state.residences} placeholder="请选择 国家/省份/市区" onChange={this.chooseResidences} suffixIcon={<Icon type="caret-down"/>} fieldNames={{ label: 'name', value: 'code', children: 'subAreas' }}/>
                                )}
                            </FormItem>
                            <FormItem style={{ display: 'inline-block'}}>
                                {getFieldDecorator('address', {
                                    rules: [{
                                        required: false
                                    }],
                                    initialValue: supperlierForm.address
                                })(
                                    <Input className="editForm-item" autoComplete="off" placeholder="请输入详细地址" maxLength={100}/>
                                )}
                            </FormItem>
                        </FormItem>
                        <FormItem label="联系人信息" required={true}>
                            <FormItem style={{ display: 'inline-block',margin: '0px 9px 0px 0px',width: '92px'}}>
                                {getFieldDecorator('contactName', {
                                    rules: [{
                                        required: true, message: '请输入姓名!'
                                    }],
                                    initialValue: supperlierForm.contactName
                                })(
                                    <Input placeholder="请输入姓名" autoComplete="off" maxLength={20}/>
                                )}
                            </FormItem>
                            <FormItem style={{ display: 'inline-block',width: '140px'}}>
                                {getFieldDecorator('contactPhone', {
                                    rules: [{
                                        required: true, message: '请输入联系方式!'
                                    },{
                                        validator: this.phoneValidator
                                    }],
                                    initialValue: supperlierForm.contactPhone
                                })(
                                    <Input placeholder="请输入联系方式" autoComplete="off" maxLength={20}/>
                                )}
                            </FormItem>
                        </FormItem>
                        <FormItem label="对接人" required={false}>
                            {getFieldDecorator('userEncode', {
                                rules: [{
                                    required: false
                                }],
                                initialValue: supperlierForm.userEncode
                            })(
                                <Select style={{width: '240px'}} placeholder="请选择" showSecondTip='true' data={this.state.userLists} selectMethod={this.chooseUser} showSearch  onSearch={debounce(this.onSearchUser,1000)} filterOption={false}/>
                            )}
                        </FormItem>
                        <FormItem label="评分" required={false}>
                            {getFieldDecorator('score', {
                                rules: [{
                                    required: false
                                }],
                                initialValue: supperlierForm.score
                            })(
                                <Rate allowHalf  />
                            )}
                        </FormItem>
                        <FormItem label="说明" required={false}>
                            {getFieldDecorator('remark', {
                                rules: [{
                                    required: false
                                }],
                                initialValue: supperlierForm.remark
                            })(
                                <TextArea rows={4} maxLength={200} placeholder="请输入"/>
                            )}
                        </FormItem>
                    </div>
                    <div className="edit-main">
                        <div className="title">收款信息</div>
                        <FormItem label="收款方式" required={false}>
                            <FormItem style={{ display: 'inline-block',margin: '0px 20px 0px 0px'}}>
                                {getFieldDecorator('payeeType', {
                                    rules: [{
                                        required: false
                                    }],
                                    initialValue: supperlierForm.payeeType
                                })(
                                     <Select style={{width: '240px'}} placeholder="请选择" data={this.state.payeeTypes} selectMethod={this.choosePayeeType}/>
                                )}
                            </FormItem>

                            {/* 收款方式选择其他时展示 */}
                        {otherPayeeType}

                        </FormItem>
                        <FormItem label="收款人姓名" required={false}>
                            {getFieldDecorator('payee', {
                                rules: [{
                                    required: false
                                }],
                                initialValue: supperlierForm.payee
                            })(
                                <Input className="editForm-item" autoComplete="off" placeholder="请输入" maxLength={20}/>
                            )}
                        </FormItem>
                        <FormItem label="收款账户" required={false}>
                            {getFieldDecorator('payeeAccount', {
                                rules: [{
                                    required: false
                                },{
                                    validator: this.payeeAccountValidator
                                }],
                                initialValue: supperlierForm.payeeAccount
                            })(
                                <Input className="editForm-item" autoComplete="off" placeholder="请输入" maxLength={50}/>
                            )}
                        </FormItem>
                    </div>
                    <FormItem>
                         
                        <div className="edit-btn">
                            {/* 新增供应商，待提交状态10显示提交审批，保存，取消*/}
                            {/* 审批驳回状态30显示重新提交，取消 */}
                            {/* --0,审批通过状态40显示保存，取消 */}
                            <AuthContainer authCodes={AUTH_KEYS.OPERATE_SERVICE_SUPPLIER_SUPPLIEREDIT}>
                                <Button style={{display:  auditStatusBool ? '': 'none'}} type="primary" className="login-form-button" onClick={this.saveAndSubmit}>
                                    提交审批
                                </Button>
                                <Button style={{display: this.state.auditStatus == 30 && this.state.auditConfig ? '': 'none'}} type='primary' htmlType="submit">重新提交</Button>
                                <Button style={{display: this.state.auditStatus == 30 && this.state.auditConfig ? 'none': ''}} type={auditStatusBool ? '': 'primary'} htmlType="submit" className="login-form-button">
                                    保存
                                </Button>
                            </AuthContainer>                        
                            <Button style={{minWidth: '0px',width: auditStatusBool ? '36px' : '120px',
                                        border: auditStatusBool ? 'none' : '1px solid #fabc05'
                            }} onClick={this.cancle.bind(this)}>取消</Button>
                        </div>          
                    </FormItem>
                </Form>
                </div>    
                <Modal
                    closable={false}
                    visible={this.state.visible}
                    onOk={this.handleOkSubmit}
                    okText='确定'
                    width={400}
                    onCancel={this.handleCancel}
                    okText="提交"
                >
                    <p>确认提交审批？</p>
                </Modal>          
            </div>
        );
    }
}

export default Form.create({ name: 'SupperlierEdit' })(SupperlierEdit);