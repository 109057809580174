import React, { Component } from 'react';
import GoodsSnapshotItem from 'CMPT/common/GoodsSnapshotItem';
import Pagination from "CMPT/common/Pagination";
import LogisticsStep from "CMPT/common/LogisticsStep";
import { getLogisticsInfo } from "@/utils";
import { Tooltip,Modal } from 'antd'

import emptImg from '@/images/placeholder_empty@2x.png'

export default class OrderTable extends Component{
    constructor(props) {
        super()
        this.state = {
            showLogisticsModal: false,
            logisticsInfo:{}
        }
        this.mapWidth = this.mapWidth.bind(this)
    }

    paginationChange = (pageNo, pageSize) => {
        this.props.onChange(pageNo, pageSize)
    }
    mapWidth(key) {
        const columns = this.props.columns
        const item =columns.find(per => {
            return per.key === key
        })
        return item && item.width || 0
    }
    getLogisticsInfo = (obj) => {
        getLogisticsInfo(obj,1).then(res => {
            let logisticsInfo = this.state.logisticsInfo
            logisticsInfo.timeList = res
            this.setState({logisticsInfo})
        })
    }
    logisticsModalShow = (bo) => {
        this.setState({
            showLogisticsModal: bo
        })
    }
    render() {
        const { columns, orderList, showMore } = this.props
        return (
            <div className="supply-order-table-page">
                <div className="table-header">
                    <ul className="header-ul" style={{marginBottom: showMore ? '12px' : '0px',width:'1200px'}}>
                        {columns.map(per => {
                            return <li key={per.key} style={{width: per.width}}>{per.name}</li>
                        })}
                    </ul>
                </div>
                <div className="table-body"  style={{width:'1200px'}}>
                    {orderList.length ? orderList.map(item => {
                        return (
                            <div className="single" key={item.orderMainCode}>
                                {
                                    showMore && <div className="single-header">
                                        <span>订单编号：<i>{item.orderNo}</i></span>
                                        <span>下单时间：{item.orderDate}</span>
                                    </div>
                                }
                                <div className="single-detail">
                                    <ul className="single-ul">
                                        <li style={{width: this.mapWidth('orderMainCode')}}>
                                            {item.goodsInfo.map(per => {
                                                return (
                                                    <div className="li-inner" key={per.orderSubCode}>
                                                        <GoodsSnapshotItem saleStatusName={per.saleStatusName} num={per.num} goodsPrice={per.goodsPrice} img={per.img} goodsName={per.goodsName} spec={per.spec} orderSubCode={per.orderSubCode} />
                                                    </div>
                                                )
                                            })}
                                        </li>
                                        <li style={{width: this.mapWidth('orderTotal')}}>
                                            <p className="header-primary">¥ {item.ordertotal}</p>
                                            <p>（含快递：{item.freight}元）</p>
                                            {/* <p> 物流单号：
                                        {logisticsInfo.logisticsNo !== '-'
                                        ? <span className="detail-logistics-info" onClick={this.logisticsModalShow.bind(this, true)}>
                                            {logisticsInfo.logisticsNo + '[物流跟踪]'}
                                        </span>:'-'}
                                        </p> */}
                                        </li>
                                        {/* <li style={{width: this.mapWidth('goodsPrice')}}>
                                            {item.goodsInfo.map(per => {
                                                return (
                                                    <div className="li-inner" key={per.orderSubCode}>
                                                        <span>{per.goodsPrice}</span>
                                                    </div>
                                                )
                                            })}
                                        </li>
                                        <li style={{width: this.mapWidth('num')}}>
                                            {item.goodsInfo.map(per => {
                                                return (
                                                    <div className="li-inner" key={per.orderSubCode}>
                                                        <span>{per.num}</span>
                                                    </div>
                                                )
                                            })}
                                        </li>
                                        <li style={{width: this.mapWidth('saleType')}}>
                                            {item.goodsInfo.map(per => {
                                                return (
                                                    <div className="li-inner" key={per.orderSubCode}>
                                                        <span>{per.saleTypeName}</span>
                                                    </div>
                                                )
                                            })}
                                        </li>
                                        <li style={{width: this.mapWidth('saleStatus')}}>
                                            {item.goodsInfo.map(per => {
                                                return (
                                                    <div className="li-inner" key={per.orderSubCode}>
                                                        {per.saleLink ? <a href={per.saleLink} className={per.className}>
                                                            {per.saleStatusName}</a> : <span className={per.className}>{per.saleStatusName}</span>}
                                                    </div>
                                                )
                                            })}
                                        </li> */}
                                        <li style={{display: this.mapWidth('buyerType') ? 'inline-block' : 'none',width: this.mapWidth('buyerType')}}>{item.buyerType}</li>
                                        <li style={{display: this.mapWidth('nikeName') ? 'inline-block' : 'none',width: this.mapWidth('nikeName')}}>
                                            <div>{item.buyerInfo && item.buyerInfo.nickName || '-'}</div>
                                            <div>{item.buyerInfo && item.buyerInfo.mobile || '-'}</div>
                                        </li>
                                        <li style={{width: this.mapWidth('companyName')}}>{item.companyName || '-'}</li>
                                        <li style={{width: this.mapWidth('shopName')}}>{item.shopName || '-'}</li>
                                        <li style={{width: this.mapWidth('orderStatus')}} className={item.className}>{item.orderStatusName}</li>
                                        
                                        <li style={{width: this.mapWidth('source')}}>{item.source}</li>
                                        <li style={{width: this.mapWidth('payMethod')}}>{item.payMethod}</li>
                                        {/* <li style={{width: this.mapWidth('buyerRemark')}} className="eclipse">
                                            <Tooltip title={item.buyerRemark}>{item.buyerRemark}</Tooltip>
                                        </li> */}
                                        <li style={{display: this.mapWidth('operate') ? 'inline-block' : 'none',width: this.mapWidth('operate')}}>
                                            {item.operations && item.operations.map(op => {
                                                return <p key={op} className="table-operate" onClick={this.props.operate.bind(this, op, item.orderMainCode)}>
                                                        {['查看详情', '修改价格', '关闭交易', '凭证审核', '发货'][op - 1]}
                                                    </p>
                                            })}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        )
                    }) : <div className="order-empty">
                        <img src={emptImg} />
                        <p>这里还没有订单</p>
                    </div>}
                </div>
                {
                    showMore && orderList.length > 0 && <div className="table-footer">
                        <Pagination change={this.paginationChange.bind(this)} total={this.props.total} current={this.props.pageNo} />
                    </div>
                }
                <Modal
                    visible={this.state.showLogisticsModal}
                    title="物流跟踪"
                    closable={false}
                    wrapClassName="order-logistics-modal"
                    okText="我知道了"
                    onOk={this.logisticsModalShow.bind(this, false)}
                >
                    <LogisticsStep info={this.state.logisticsInfo} />
                </Modal>
            </div>
        );
    }
}
