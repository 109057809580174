import React, { Component } from "react";
import { Input, Button, Switch, DatePicker, Modal } from "antd";
import EmptyView from "CMPT/widget/EmptyView";
import Select from 'CMPT/common/Select';
import DateRange from "CMPT/common/DateRange";
import BannerAddEdit from "./BannerAddEdit";
import DragTable from "CMPT/widget/DragTable";
import MultiLineText from "CMPT/widget/MultiLineText";
import { API } from "@/http/api";
import http from "@/http";
import moment from "moment";
import "./BannerList.less";

import userUtils from '@/utils/user';
import { AUTH_KEYS } from "@/utils/auth";
const { checkAuthByKey } = userUtils;

const bannerAddAuth = checkAuthByKey(AUTH_KEYS.MALL_CONTENT_MANAGE_BANNER_MANAGE_ADD); // 新增banner；
const canShowStatusAuth = checkAuthByKey(AUTH_KEYS.MALL_CONTENT_MANAGE_BANNER_MANAGE_STATUS_CHANGE); // 启用/禁用；
const canShowChangeAuth = checkAuthByKey(AUTH_KEYS.MALL_CONTENT_MANAGE_BANNER_MANAGE_CONTENT_CHANGE); // banner修改

const InputSearch = Input.Search;
 class BannerList extends Component {


  state = {
    columns:  this.createColumns(undefined),
    positionMap:{},

    httpData: [],
    keyHttpData: '',
    activePosition: '',// 被选中的position
    filterParm: {
      endTime: null,
      name: null,
      startTime: null,
      status: undefined
    },
    bigImgSrc: undefined,

    tempData: {
      isLoading: true,

      // 所有的临时变量，放这里
      showAddEditDialog: false,
      willEditObj: null,

      willDelObj: null, //可能要删除的一条数据
      willCloseObj: null, //可能要关闭的一条数据
    },

    statusMap: [
      { value: "1", name: "开启" },
      { value: "2", name: "关闭" }
    ]
  };



  componentDidMount() {
    this.queryPosition();
  }

  queryPosition = () => {
    let source= this.props.source ;
    // setup 1:基本入参
    const config = {
      api: API.bannerPositionSort,
      path: {source }, //1是 web、2是 小程序
      showLoading: true
    };

    // setup 2:请求，并初步处理
    http(config).then(res => {
      if (res && res.success && res.data) {
        let tempData = this.state.tempData;
        tempData.isLoading = false;

        var positionMap = {};
        if (res.data.length) {
          res.data.forEach(item => {
            positionMap[item.type] = item.position;
          })
        } else {
          positionMap = res.data;
        }


        /* start: 当前需要隐藏  合作伙伴 ，后续可能再放开 20211110 */
        // var keyArray = Object.keys(positionMap);
        //  for(var i=keyArray.length-1; i>=0;i--){
        //    if( /*source=='1' && keyArray[i]=='6') ||*/ source=='2' && keyArray[i]=='11'){
        //         delete positionMap[keyArray[i]];
        //    } 
        //  }
        /* end: 当前需要隐藏  合作伙伴 ，后续可能再放开 20211110 */


        this.setState({
          positionMap,
          activePosition: Object.keys(positionMap)[0]
        },  this.readyQuery);
      }
    });
  }

  createFixParam = () => {
    let param = { source: this.props.source };//1是 web、2是 小程序
    if (this.state.activePosition) {
      param.position = this.state.activePosition;
    }

    return param;
  }

  queryList = () => {
    let tempData = this.state.tempData;
    tempData.isLoading = true;
    this.setState({
      tempData
    }, this.doQueryList);
  }

  createQueryParam = () => {
    let param = this.createFixParam();
    let filterParm = this.state.filterParm;

    Object.keys(filterParm).forEach(key => {
      if (filterParm[key]) {
        param[key] = filterParm[key];
      }
    });

    if (param.startTime) {
      param.startTime = param.startTime.format('YYYY-MM-DD');
    }

    if (param.endTime) {
      param.endTime = param.endTime.format('YYYY-MM-DD');
    }


    return param;
  }

  doQueryList = () => {
    // setup 1:基本入参
    const config = {
      api: API.bannerList,
      data: this.createQueryParam(),
      showLoading: true
    };

    // setup 2:请求，并初步处理
    http(config).then(res => {
      if (res && res.success && res.data) {
        let tempData = this.state.tempData;
        tempData.isLoading = false;

        this.setState({
          httpData: res.data || [],
          keyHttpData: (new Date().getTime() + ''),
          tempData
        });
      }
    });
  };

  watchBigImage = src => {
    this.setState({
      bigImgSrc: src
    });
  };

     /**
      * 需要动态变化了
      * @param activePosition
      * @returns {({title: string, render: (function(*, *, *)), key: string}|{dataIndex: string, title: string, render: (function(*=, *)), key: string}|{dataIndex: string, title: string, render: (function(*, *)), key: string}|{dataIndex: string, title: string, render: (function(*=, *)), key: string})[]}
      */
  createColumns( activePosition ) {
    var columns =  [
      {
        title: "序号",
        key: "encode",
        render: (text, record, index) => {
          return <span>{index + 1}</span>;
         // return <span>{record.sortNum}</span>;
        }
      },
      {
        title: "添加时间",
        dataIndex: "createTime",
        key: "createTime",
        render: (text, record) => {
          return <span>{moment(text).format("YYYY-MM-DD HH:mm")}</span>;
        }
      },
      {
        title: "标题",
        dataIndex: "bannerTitle",
        key: "bannerTitle",
        render: (text, record) => {
          return <MultiLineText showTips={true} value={text} />
        }
      },
      {
        title: "Banner图片",
        dataIndex: "imgUrl",
        key: "imgUrl",
        render: (text, record) => {
          return <img src={text} style={{ width: "122px", height: "50px" }} onClick={() => { this.watchBigImage(text) }} />;
        }
      }
    ];
      

    // 只有 小程序的采购板块 需要添加背景色
    if(this.props.source==2 && activePosition==10){//2表示是小程序， 10表示是 采购板块   20211012
      columns.push({
        title: "背景色值",
        dataIndex: "bgColor",
        key: "bgColor",
        render: (text, record) => {
          return <span>{"#"+text}</span>;
        }
      });
     }
  


     // 背景色之后的列
      columns=columns.concat([{
        title: "跳转Url",
        dataIndex: "goUrl",
        key: "goUrl",
        width: "30%",
        render: (text, record) => {
          return <MultiLineText showTips={true} value={text} />
        }
      },
      {
        title: "开启状态",
        dataIndex: "status",
        width: "15%",
        render: (text, record, index) => {
          const { encode, stateLoading } = record;
          let checked = text == 1 ? true : false;
          if (canShowStatusAuth) {
            return (
              <>
                <span className="status-tip">{checked ? "启用" : "停用"}</span>
                <Switch
                  key={encode}
                  checked={checked}
                  loading={stateLoading}
                  onChange={checked => this.openCloseChange(checked, record)}
                />
              </>
            );
          } else {
            return null;
          }
        }
      },

      {
        title: "操作",
        key: "operate",
        render: (text, record, index) => {
          return (
            <div>
              <center>
                {
                  canShowChangeAuth ? 
                  <span
                      className="text-color-highlight"
                      style={{ marginRight: "16px" }}
                      onClick={() => {
                        this.editBanner(record);
                      }}
                    >
                      修改
                  </span>
                  : null
                }
                {record.status != 1 ? (
                  <span
                    className="text-color-highlight"
                    onClick={() => {
                      this.delBanner(record);
                    }}
                  >
                    删除
                </span>
                ) : (
                    ""
                  )}</center>
            </div>
          );
        }
      }
    ]);


    return columns;
  };

  openCloseChange = (checked, obj) => {
    if (checked) {
      this.doOpenClose(obj);
    } else {
      this.setState({
        willCloseObj: obj
      });
    }
  };

  doOpenClose = obj => {
    const config = {
      api: API.bannerOpenClose,
      path: { encode: obj.encode },
      showLoading: true
    };

    http(config).then(res => {
      if (res && res.success) {
        this.hideDialog();
        this.queryList();
      }
    });
  };

  editBanner = obj => {
    let newTempData = this.state.tempData;
    newTempData.willEditObj = obj;

    this.setState({ tempData: newTempData, showAddEditDialog: true });
  };

  addBanner = () => {
    let newTempData = this.state.tempData;
    newTempData.willEditObj = null;

    this.setState({ tempData: newTempData, showAddEditDialog: true });
  };

  delBanner = obj => {
    this.setState({
      willDelObj: obj
    });
  };


  gotoDel = () => {
    const config = {
      api: API.bannerDel,
      path: { encode: this.state.willDelObj.encode },
      showLoading: true
    };

    http(config).then(res => {
      if (res && res.success) {
        this.hideDialog();
        this.queryList();
      }
    });
  };

  gotoClose = () => {
    this.doOpenClose(this.state.willCloseObj);
  };

  hideDialog = () => {
    this.setState({
      willDelObj: null,
      willCloseObj: null,
      showAddEditDialog: false,
      willEditObj: null
    });
  };

  addDelDialog() {
    return (
      <Modal
        title={"删除"}
        visible={true}
        okText="确定"
        cancelText="取消"
        closable={false}
        maskClosable={false}
        onOk={this.gotoDel}
        onCancel={this.hideDialog}
        width={400}
      >
        <div>{"请确认是否删除" + this.state.willDelObj.bannerTitle}</div>
      </Modal>
    );
  }

  addCloseDialog() {
    return (
      <Modal
        title={"关闭"}
        visible={true}
        okText="确定"
        cancelText="取消"
        closable={false}
        maskClosable={false}
        onOk={this.gotoClose}
        onCancel={this.hideDialog}
        width={400}
      >
        <div>{"请确认是否关闭" + this.state.willCloseObj.bannerTitle}</div>
      </Modal>
    );
  }

  addAddEditDialog() {
    let {activePosition} = this.state;
    return (
      <BannerAddEdit
        data={this.state.tempData.willEditObj}
        cancelDialog={this.hideDialog}
        refresh={this.queryList} // 这个是刷新，不需要重置 httpData httpData，无需调用readyQuery
        source={this.props.source}
        position={activePosition}
        listCount={this.state.httpData ? this.state.httpData.length : 0}
      />
    );
  }


  tabsChange = (value) => {
    // 重复点击 无效
    let {activePosition} = this.state;
    if(activePosition==value){
      return ;
    }
     
    // 去更新
    this.setState({ activePosition:value  },
       this.readyQuery);
  }

  // 改变position后，表格的列 集合 需要更新,数据也需要刷新下
  readyQuery=()=>{
     var httpData = [];
     var columns = this.createColumns(this.state.activePosition);

     this.setState({httpData,columns},this.queryList);

  }
  

  onChange = (dragIndex, hoverIndex, newData, oldData) => {
    if(dragIndex==hoverIndex){
         return;
    }

    // setup 1: 构造参数
    let dataParam = this.createFixParam();

    let sourceItem = oldData[dragIndex];
    let targetItem = oldData[hoverIndex];

    dataParam.sourceEncode = sourceItem.encode;
    dataParam.sourceSortNum = sourceItem.sortNum;
    dataParam.targetSortNum = targetItem.sortNum;
    dataParam.targetEncode = targetItem.encode;


    // setup 2: 构造配置
    const config = {
      api: API.bannerSort,
      data: dataParam,
      showLoading: true
    };

    // setup 2:请求，并初步处理
    http(config).then(res => {
      if (res && res.success) {
        this.queryList();
      }
    });
  };

  resetSearch = () => {
    let filterParm = this.state.filterParm;
    Object.keys(filterParm).forEach(key => {
      filterParm[key] = undefined
    })

    this.setState({ filterParm }, this.queryList);
  }

  searchChange = ({ target: { value } }) => {
    let filterParm = this.state.filterParm;
    filterParm.name = value ? value.replace(/^\s+|\s+$/g, "") : "";
    this.setState({ filterParm })
  };


  selectStatusChange = (value) => {
    let { filterParm } = this.state;
    filterParm.status = value;


    this.setState({ filterParm },
      () => this.queryList());
  }

  onDateChange = (field, value) => {
    let { filterParm } = this.state;
    if (field === "startValue") {
      filterParm.startTime = value;
    } else {
      filterParm.endTime = value;
    }

    this.setState({ filterParm }, this.queryList);
  };

  render() {
    const { activePosition, filterParm, statusMap, bigImgSrc ,positionMap} = this.state;

    let isLoading = this.state.tempData.isLoading;
    return (
      <div className="banner_panel" >
        <div className="status-tab"  >
          {positionMap && Object.keys(positionMap).map(item => {
            return <span key={item} className={activePosition === item ? 'active' : ''}
              onClick={() => { this.tabsChange(item) }}>{positionMap[item]}</span>
          })}
        </div>

        <div className="banner_header2">

          <Select data={statusMap} placeholder="开启状态" value={filterParm.status} showSearch
            filterOption={false}
            selectMethod={this.selectStatusChange.bind(this)} style={{ width: '124px' }} />
          <span style={{ marginLeft: '10px', marginRight: '3px' }}>添加时间</span>
          <DateRange
            style={{ width: '100px', marginTop: '5px', marginRight: '10px', textAlign: 'center' }}
            onDateChange={this.onDateChange}
            startValue={filterParm.startTime}
            endValue={filterParm.endTime}
          />



          <InputSearch
            placeholder="请输入标题进行搜索"
            onChange={this.searchChange}
            onSearch={() => this.queryList()}
            value={filterParm.name}
            style={{ width: "280px", marginLeft: '10px' }}
            maxLength={50}
          />
          <span className="banner_reset" onClick={this.resetSearch}>重置</span>
          {
            bannerAddAuth?
            <Button
              style={{ marginLeft: "20px", display: isLoading ? 'none' : 'block' }}
              type="primary"
              onClick={() => this.addBanner()}
            >
              新增
            </Button>
            : null
          }
        </div>

        {this.state.httpData && this.state.httpData.length > 0 ? (
          <DragTable
            rowKey={"encode"}
            key={this.state.keyHttpData}
            columns={this.state.columns}
            dataSource={this.state.httpData}
            onChange={this.onChange}
          />
        ) : (isLoading ? "" : <EmptyView tips="暂无Banner" />)}




        {this.state.willCloseObj ? this.addCloseDialog() : ""}
        {this.state.willDelObj ? this.addDelDialog() : ""}
        {this.state.showAddEditDialog ? this.addAddEditDialog() : ""}

        {bigImgSrc ? (
          <div
            className="big_img"
            onClick={() => {
              this.setState({ bigImgSrc: null });
            }}
          >
            <img src={bigImgSrc} alt="" />
          </div>
        ) : null}
      </div>
    );
  }
}

export default BannerList;
