import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Modal, message ,Breadcrumb} from 'antd'
import userUtil from '@/utils/user'
import { AUTH_KEYS } from '@/utils/auth'
import http from '@/http'
import { API } from '@/http/api'
import { changeObjkey } from '@/utils'
// import BreadcrumbHeader from 'CMPT/common/breadcrumb/breadcrumb'
import Modelmodel from 'CMPT/common/drawer/model'
import LogisticsTabnums from 'CMPT/common/tabnums/tabnums'
import Table from 'CMPT/common/Table'
import SettingServiceadd from './settingserviceadd'
import './settingservice.less'

class SettingServiceform extends Component {
    constructor(props) {
        super(props);
        this.state = {
            authCodes: null,
            addModalstatus: false,
            editModalstatus: false,
            deteleModalstatus: false,
            tabnums: [
                {
                    name: '屋内设施保修配置',
                    value: 1,
                    authCodes: AUTH_KEYS.SETTING_SERVICE_INDOORFACILITIES,
                    authCodetext: 'SETTING_SERVICE_INDOORFACILITIES'
                },
                {
                    name: '公共设施保修配置',
                    value: 2,
                    authCodes: AUTH_KEYS.SETTING_SERVICE_PUBLICFACILITIES,
                    authCodetext: 'SETTING_SERVICE_PUBLICFACILITIES'
                },
                {
                    name: '耗材配置',
                    value: 3,
                    authCodes: AUTH_KEYS.SETTING_SERVICE_CONSUMABLES,
                    authCodetext: 'SETTING_SERVICE_CONSUMABLES'
                }
            ],
            tabstatus: null,
            addserviceobj: {
                itemType: null,
                itemname: null,
                itemnums: null
            },
            editserviceobj: {
                itemname: null,
                itemnums: null
            },
            tabledata: [],
            detelekey: null
        }
    }
    componentDidMount() {
        this.Changetabledata()
    }

    //显示按钮
    showuseButton() {
        return {
            auth: this.authcodeButton(),
            name: '新增类型',
            title: '新增类型',
            buttontype: 'primary',
            ModelName: 'new-role-btn',
            destroyOnClose: true,
            visible: this.state.addModalstatus,
            handleOk: this.handleaddOk.bind(this),
            handleCancel: this.showaddModal.bind(this),
            showModal: this.showaddModal.bind(this),
            Drawermodel: <SettingServiceadd form={this.props.form} isshowType={true} defaultValueList={this.state.addserviceobj} defaultValue={this.authcodestatus()} handleChange={this.changeaddModal.bind(this)}></SettingServiceadd>
        }
    }
    authcodeButton() {
        const authCodes = this.shownumtab()[0].authCodetext;
        return this.state.authCodes || `${authCodes}_ADD`
    }
    authcodestatus() {
        const tabstatus = this.shownumtab()[0].value;
        return this.state.tabstatus || tabstatus
    }
    //新增类型
    handleaddOk(event) {
        event.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const addserviceobj = this.state.addserviceobj;
                this.setState({
                    addserviceobj: {}
                });
                const status = Number(this.authcodestatus());
                const dataObj = changeObjkey(addserviceobj, { itemname: 'name', itemnums: 'value' });
                const src = status === 1 ? 'sysconfigAddRoomEquipment' : status === 2 ? 'sysconfigAddPublicEquipment' : 'sysconfigAddMaterial';
                this.showaddModal();
                this.postaddSave(src, dataObj, status)
            }
        });
    }
    showaddModal() {
        this.setState({
            addModalstatus: !this.state.addModalstatus
        });
    }
    changeaddModal(type, val) {
        const addserviceobj = this.state.addserviceobj;
        let targetVal = null;
        if (val && val.target) targetVal = val.target.value;

        switch (type) {
            case 2:
                addserviceobj.itemname = targetVal;
                break;
            case 3:
                addserviceobj.itemnums = targetVal;
                break;
            default:
                break
        }

        this.setState({
            addserviceobj
        })
    }
    //编辑类型
    handleeditOk(event) {
        event.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const editserviceobj = this.state.editserviceobj;
                this.setState({
                    editserviceobj: {}
                });
                const status = Number(this.authcodestatus());
                const dataObj = changeObjkey(editserviceobj, { itemname: 'name', itemnums: 'value', itemid: 'id' });
                const src = status === 1 ? 'sysconfigEditRoomEquipment' : status === 2 ? 'sysconfigEditPublicEquipment' : 'sysconfigEditMaterial';
                this.handleeditCancel();
                this.posteditSave(src, dataObj, status)
            }
        });
    }
    handleeditCancel() {
        this.setState({
            editModalstatus: !this.state.editModalstatus
        });
    }
    showeditModal(per) {
        setTimeout(() => {
            this.setState({
                editserviceobj: {
                    itemname: per.projectList,
                    itemnums: per.settingnums,
                    itemid: per.key
                }
            })
        }, 0);
        this.handleeditCancel()
    }
    changeeditModal(type, val) {
        const editserviceobj = this.state.editserviceobj;
        let targetVal = null;
        if (val && val.target) targetVal = val.target.value;

        switch (type) {
            case 1:
                break;
            case 2:
                editserviceobj.itemname = targetVal;
                break;
            case 3:
                editserviceobj.itemnums = targetVal;
                break;
            default:
                break
        }

        this.setState({
            editserviceobj
        })
    }
    //删除类型
    deteleitem(iscan, per) {
        if (per.hasOwnProperty('key')) {
            this.setState({
                detelekey: per.key
            })
        }
        if (iscan) {
            const status = Number(this.authcodestatus());
            const src = status === 1 ? 'sysconfigDeleteRoomEquipment' : status === 2 ? 'sysconfigDeletePublicEquipment' : 'sysconfigDeleteMaterial';
            this.postdeteleSave(src, { id: this.state.detelekey }, status)
        }
        this.setState({
            deteleModalstatus: !this.state.deteleModalstatus
        });
    }
    //tabs选中哪一项
    getnumindex(val) {
        setTimeout(() => {
            this.setState({
                tabstatus: val,
                authCodes: Number(val) === 1 ? 'SETTING_SERVICE_INDOORFACILITIES_ADD' : Number(val) === 2 ? 'SETTING_SERVICE_PUBLICFACILITIES_ADD' : 'SETTING_SERVICE_CONSUMABLES_ADD',
                addserviceobj: {}
            })
        }, 0)
        this.Changetabledata(val)
    }
    //哪些tabs是可用的
    shownumtab() {
        const tabnums = this.state.tabnums;

        return tabnums.filter(item => userUtil.hasAuth(item.authCodes))
    }
    //根据tabs的标签改变数据结构
    changetablecolumns() {
        const status = Number(this.authcodestatus()) === 3;

        return [
            {
                title: 'ID',
                dataIndex: 'key'
            },
            {
                title: status ? '耗材名称' : '保修项目名称',
                dataIndex: 'projectList'
            },
            {
                title: status ? '数量单位' : '超时设置(小时)',
                dataIndex: 'settingnums'
            },
            {
                title: '操作',
                dataIndex: 'operate',
                render: (text, row) => (
                    <span>
                        {text.map((per, index) => {
                            return <span className="table-operate-btn" key={index} onClick={this.operate.bind(this, per, row)}>{['编辑', '删除'][per - 1]}</span>
                        })}
                    </span>
                )
            }]
    }
    //根据tabs的标签改变数据
    Changetabledata(val) {
        const status = Number(val || this.authcodestatus());
        const editauthCode = status === 1 ? AUTH_KEYS.SETTING_SERVICE_INDOORFACILITIES_EDIT : status === 2 ? AUTH_KEYS.SETTING_SERVICE_PUBLICFACILITIES_EDIT : AUTH_KEYS.SETTING_SERVICE_CONSUMABLES_EDIT;
        const deteleauthCode = status === 1 ? AUTH_KEYS.SETTING_SERVICE_INDOORFACILITIES_DETELE : status === 2 ? AUTH_KEYS.SETTING_SERVICE_PUBLICFACILITIES_DETELE : AUTH_KEYS.SETTING_SERVICE_CONSUMABLES_DETELE;
        const src = status === 1 ? 'sysconfigListRoomEquipment' : status === 2 ? 'sysconfigListPublicEquipment' : 'sysconfigListMaterial';
        this.getlistconfig(src, editauthCode, deteleauthCode);
    }
    //根据权限显示编辑/删除modal
    showautheditbutton() {
        const status = Number(this.authcodestatus());
        return [
            {
                title: status === 1 ? '屋内设施报修配置' : status === 2 ? '公共设施报修配置' : '耗材配置',
                wrapClassName: 'table-operate-edit',
                destroyOnClose: true,
                visible: this.state.editModalstatus,
                onOk: this.handleeditOk.bind(this),
                onCancel: this.handleeditCancel.bind(this),
                Drawermodel: <SettingServiceadd form={this.props.form} isshowType={false} defaultValueList={this.state.editserviceobj} defaultValue={this.authcodestatus()} handleChange={this.changeeditModal.bind(this)}></SettingServiceadd>
            },
            {
                wrapClassName: 'table-operate-detele',
                visible: this.state.deteleModalstatus,
                onOk: this.deteleitem.bind(this, true),
                onCancel: this.deteleitem.bind(this, false),
                Drawermodel: <div>确认删除?</div>
            },
        ]
    }
    //根据编辑/删除产生操作
    operate(key, item) {
        switch (key) {
            case 1:
                this.showeditModal(item);
                break;
            case 2:
                this.deteleitem(false, item);
                break;
            default:
                break
        }
    }

    //查询公共设施报修
    getlistconfig(url, editauthCode, deteleauthCode) {
        const config = {
            api: API[url],
            showLoading: true
        }
        http(config).then(result => {
            const { data } = result;
            const tabledata = data && data.map(item => {
                const list = changeObjkey(item, { id: 'key', name: 'projectList', value: 'settingnums' }, true);
                list.operate = [];
                return list
            });

            if (userUtil.hasAuth(editauthCode)) {
                tabledata.map(item => {
                    if (!item.operate.includes(1)) item.operate.push(1);
                    return item
                })
            }
            if (userUtil.hasAuth(deteleauthCode)) {
                tabledata.map(item => {
                    if (!item.operate.includes(2)) item.operate.push(2);
                    return item
                })
            }

            this.setState({
                tabledata
            })
        })
    }
    //新增保存
    postaddSave(url, data, status) {
        const config = {
            api: API[url],
            data: {
                ...data
            },
        }
        http(config).then(result => result.success && (message.success(`新增成功`) && this.Changetabledata(status)))
    }
    //编辑保存
    posteditSave(url, data, status) {
        const config = {
            api: API[url],
            data: {
                ...data
            },
        }
        http(config).then(result => result.success && (message.success(`编辑成功`) && this.Changetabledata(status)))
    }
    //删除保存
    postdeteleSave(url, data, status) {
        const config = {
            api: API[url],
            data: {
                ...data
            },
        }
        http(config).then(result => result.success && (message.success(`删除成功`) && this.Changetabledata(status)))
    }

    render() {
        const breadlist = [{ name: '服务管理' }];
        const addorderButton = <Modelmodel {...this.showuseButton()}></Modelmodel>
        const tabnums = this.shownumtab();

        return (
            <div className='SettingService'>
                <Breadcrumb>
                    <Breadcrumb.Item>设置中心</Breadcrumb.Item>
                    <Breadcrumb.Item>字典</Breadcrumb.Item>
                    <Breadcrumb.Item>服务管理</Breadcrumb.Item>
                </Breadcrumb>
                {/* <BreadcrumbHeader breadlist={breadlist} buttonobj={addorderButton}></BreadcrumbHeader> */}
                <LogisticsTabnums
                    nums={tabnums}
                    activeKey={this.state.tabstatus || String(tabnums[0].value)}
                    getnumindex={this.getnumindex.bind(this)}>
                </LogisticsTabnums>
                <div className='SettingService-body'>
                    <Table
                        columns={this.changetablecolumns()}
                        data={this.state.tabledata}
                        pagination={false} />
                </div>
                <div>
                    {
                        this.showautheditbutton().map((item, index) => <Modal {...item} key={index} width={400}>{item.Drawermodel}</Modal>)
                    }
                </div>
            </div>
        )
    }
}

const SettingService = Form.create({ name: 'SettingServiceform' })(SettingServiceform);

export default withRouter(SettingService);
