import Table from 'CMPT/common/Table';
import http from '@/http';
import { API } from '@/http/api';
import React, { Component } from 'react';
import { Breadcrumb, Tooltip } from 'antd';
import { getAuditStatusDesc, getPublishStatusDesc, getAuditStatusActions } from './sourcingStatus';

import './SourcingList.less';

const BreadcrumbItem = Breadcrumb.Item;

class EnterpriseAuthList extends Component {
    state = {
        total: 0, // 当前数据的总数
        filterParams: {

        },
        pageParams: {  // 接口入参，必须放这里。方便分页时保存信息
            pageNo: 1, // 需要查询的页数
            pageSize: 10, // 每页条数
        },
        dataList: []
    }

    componentWillMount() {
        this.props.onRef(this)
        // this.getLists()
    }

    // 刷新列表数据
    refreshDataWithParams(params) {
        this.state.pageParams.pageNo = 1
        this.state.filterParams = params
        this.getLists(params)
    }

    // 网络请求列表数据
    getLists = (params) => {
        let that = this;
        let { filterParams } = this.state
        let { pageNo, pageSize } = this.state.pageParams

        const config = {
            api: API.sourcingGetList,
            data: {
                pageNo: pageNo,
                pageSize: pageSize,
                ...filterParams
            },
            showLoading: true
        }
        http(config).then(res => {
            if (res && res.success && res.data) {
                let dataList = res.data.datas || []
                dataList.forEach((item) => {
                    item.statusDesc = getPublishStatusDesc(item.status)
                    item.actions = getAuditStatusActions(item.auditStatus)
                    item.auditStatusDesc = getAuditStatusDesc(item.auditStatus)
                })
                that.setState({
                    dataList,
                    total: res.data.totalRecord
                })
            }
        })
    }

    //分页
    onChangePage = (pageNo, pageSize, sort) => {
        this.getListFun(pageNo, pageSize)
    }

    getListFun(pageNo, pageSize) {
        let pageParams = this.state.pageParams;

        if (pageNo) {
            pageParams['pageNo'] = pageNo;
        }
        if (pageSize) {
            pageParams['pageSize'] = pageSize;
        }
        this.setState({
            pageParams
        }, () => {
            this.getLists();
        })
    }

    // 点击行
    clickRow = (record, index) => {
        let item = this.state.dataList[index]
        let inquiryCode = item.inquiryCode
        window.location.href = `/#/app/crm/sourcing/source_detail/${inquiryCode}`
    }

    columns = [
        {
            title: '编号',
            dataIndex: 'inquiryNo',
            key: 'inquiryNo',
            render: (text, record) => { //待提交状态的，打开编辑页
                return (<span>{text}</span>);
            }
        }, {
            title: '标题',
            dataIndex: 'title',
            key: 'title',
            render: (text, record) => {
                let value;
                if (text && text.length > 20) {
                    value = <Tooltip placement="top" title={text}>{text.slice(0, 20)}...</Tooltip>
                } else {
                    value = text
                }
                return (<span>{value ? value : '-'}</span>)
            }
        }, {
            title: '状态',
            dataIndex: 'statusDesc',
            key: 'statusDesc',
            render: (text, record) => {
                return (<span>{text ? text : '-'}</span>)
            }
        }, {
            title: '审核状态',
            dataIndex: 'auditStatusDesc',
            key: 'auditStatusDesc',
            render: (text, record) => {
                return (<span>{text ? text : '-'}</span>)
            }
        }, {
            title: '发布人',
            dataIndex: 'createNickName',
            key: 'createNickName',
            render: (text, record) => {
                var phone = record.createUserPhone;
                var name = record.createNickName;

                var value= (name||'')+(name?"/":'')+(phone||'');
                

                return (<span>{value}</span>);
            }
        }, {
            title: '所属企业',
            dataIndex: 'purchaserName',
            key: 'purchaserName',
            render: (text, record) => {
                let value;
                if (text && text.length > 20) {
                    value = <Tooltip placement="top" title={text}>{text.slice(0, 20)}...</Tooltip>
                } else {
                    value = text
                }
                return (<span>{value ? value : '-'}</span>);
            }
        },
        {
            title: '发布时间',
            dataIndex: 'createTime',
            key: 'createTime',
            render: (text, record) => {
                let value = text
                return (<span>{value ? value : '-'}</span>);
            }
        }, {
            title: '操作',
            dataIndex: 'actions',
            key: 'actions',
            render: (actions, record, index) => {
                return (
                    <div>
                        {
                            actions.map((item) => {
                                return <span key='item' className="table-operate-btn">{item ? item : '-'}</span>
                            })
                        }
                    </div>
                )
            }
        }
    ]

    render() {
        const { pageParams } = this.state;
        return (
            <div className='sourcing-list'>
                <Table rowKey='inquiryNo' onRow={(record, index) => {
                    return {
                        onClick: event => {
                            this.clickRow(record, index)
                        },
                    };
                }} columns={this.columns} selfProcessData={this.state.dataList} total={this.state.total} change={this.onChangePage} current={pageParams.pageNo} />
            </div>

        );
    }
}

export default EnterpriseAuthList;