import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Button, Icon, Input, Row, Col, Modal,Tag, message  } from 'antd';
import Select from 'CMPT/common/Select';
import Table from 'CMPT/common/Table';
import DeleteTemplate from './DeleteTemplate';
import ApproveTemplate from './ApproveTemplate';
import CopyTemplate from './CopyTemplate';
import AuthContainer from 'CMPT/common/auth/AuthContainer';
import { AUTH_KEYS } from '@/utils/auth';
import './Template.less';
import http from '@/http';
import { API } from '@/http/api';
import storage from '@/utils/storage';
import empty from '@/images/empty_Transparent@2x.png'
const BreadcrumbItem = Breadcrumb.Item;
const Search = Input.Search;
const { CheckableTag } = Tag;
var moment = require('moment');

class ReceiptTemplate extends Component {
    componentWillMount(){
        const search = this.props.location.search;
        if(search) {
            this.setState({
                checked: search.split('=')[1]
            }) 
        } 
        http({
            api: API.templateInit,
            path: {}, // 路径参数
            data: {}, // 请求参数            
            showLoading: true // 是否显示全局加载
        }).then(res=>{
            storage.set('template_init',res.data)
            // 初始化搜索选项
            const flowStatus={0:'全部'}
            res.data.flowStatus.forEach(element => {
                flowStatus[element.key] = element.value                
            });
            this.setState({
                fetching:false,
                // projectList:[{ value: '', name: '全部' }].concat( res.data.projectList && res.data.projectList.map(item => { return { value: item.id, name: item.name } }) ) ,
                projectList: [{ value: '', name: '全部' }].concat(res.data.projectList && res.data.projectList.map(item => { return { value: item.id, name: item.name } }) ) ,
                // projectType:[{ value: '', name: '全部' }].concat( res.data.type && res.data.type.map(item => { return { value: item.key, name: item.value } }) ) ,
                auditStatus:[{ value: '', name: '全部' }].concat( res.data.status && res.data.status.map(item => { return { value: item.key, name: item.value } }) ) ,
                flowStatusObj:flowStatus,
                // 默认选第一个项目
                projectId:  undefined
            }, () => {

                http({
                    api: API.templateCount,
                    path: {}, // 路径参数
                    data: {
                        name: "",
                        orderByList: [2],// 排序 1创建时间正序、2创建时间倒序、3最后更新时间正序、4最后更新时间倒序、7预计处理时间正序、8预计处理时间倒序、9订单ID正序、10订单ID倒序 ,
                        pageNo: this.state.pageNo||1,// 当前页数 ,
                        pageSize: this.state.pageSize||10,// 每页条数 ,
                        projectId: null, // 项目id ,                        
                        status: null,// 状态 ,
                        type: 2,// 类型 1合同 2收据
                    },
                    showLoading: false // 是否显示全局加载
                }).then(res => {
                    this.setState({
                        flowStatus:res.data&&res.data.map(res=>{
                            return {key:res.key?res.key:0,value:res.value}
                        })
                    })
                })
                http({
                    api: API.templateList,
                    path: {}, // 路径参数
                    data: {
                        name: "",
                        orderByList: [2],// 排序 1创建时间正序、2创建时间倒序、3最后更新时间正序、4最后更新时间倒序、7预计处理时间正序、8预计处理时间倒序、9订单ID正序、10订单ID倒序 ,
                        pageNo: this.state.pageNo||1,// 当前页数 ,
                        pageSize: this.state.pageSize||10,// 每页条数 ,
                        flowStates:this.state.checked===0?null:this.state.checked,
                        projectId: null, // 项目id ,                        
                        status: null,// 状态 ,
                        type: 2,// 类型 1合同 2收据
                    },
                    showLoading: false // 是否显示全局加载
                }).then(res => {
                    this.setState({
                        total:res.data&&res.data.total
                    })
                    // 生成表单数据
                    const list = res.data&&res.data.datas && res.data.datas.map(res => {
                        return {
                            key: res.encode,
                            templateName: res.name,
                            templateType: res.typeDesc,
                            status:res.status,
                            auditStatus: res.statusDesc,
                            media:res.media,
                            templateStatus: res.statusDesc,
                            defaultFlag:res.defaultFlag,
                            type:res.type,
                            creator: res.createName,
                            createTime: res.createTime&&moment(res.createTime).format('YYYY-MM-DD HH:mm')||'-',
                            approver: res.verifyName && res.verifyName.join('/')||'-',
                            approvalTime: res.verifyTime&&moment(res.verifyTime).format('YYYY-MM-DD HH:mm')||'-',
                            operate: [res.isDelete ? 1 : 0, res.isDownload ? 2 : 0, res.isUpdate ? 3 : 0, res.isCopy ? 4 : 0, res.isVerify ? 5 : 0,]
                        }
                    })
                    // 收据列表
                    this.setState({                        
                        data: list,                        
                    })
                })
            })

        }).catch(err=>{
            
        })
    }
    // 搜索条件----------
    // 模板名称
    templateName = ''

    // 审批状态
    statusId = undefined
    // 模板类型
    templateTypeId = undefined
    total=0
    // -----
    state = {
        fetching:true,
        flowStatusObj:[],
        checked: 0,
        sortedInfo: {},
        total:0,
        pageNo:1,
        pageSize:10,
        // 适用项目
        projectId: undefined,
        orderByList:[2],
        showDelete: false,
        showCopy: false,
        showApprove: false,
        reset: false,
        projectList: [],
        auditStatus: [],
        projectType: [],
        flowStatus:[
            {key:0,value:0},
            {key:1,value:0},
            {key:3,value:0}
        ],
        data: [
            // { key: 1, templateName: 'A栋大厦运营费用收据', templateType: '物业合同', auditStatus: '审批中', creator: '哆啦A梦和小丸子', createTime: '2019-08-05', approver: '长得帅', approvalTime: '2018-06-08', operate: [1, 2, 3, 4, 5] },

        ],
        
    }
    chooseProject = (data) => {
        this.setState({
            projectId: data || data === 0 ? data : undefined,
            pageNo:1
        },()=>{
            this.getList()
        })

    }
    chooseStatus = (data) => {
        // console.log('choose status', data)
        this.statusId = data || data === 0 ? data : undefined
        this.setState({
            pageNo:1
        },()=>{
            this.getList()
        })
    }
    chooseType = (data) => {
        // console.log('choose type', data)
        this.templateTypeId = data
        this.setState({
            pageNo:1
        },()=>{
            this.getList()
        })
    }
    clearSort = () => {
        this.setState({ sortedInfo: null });
    }
    handleChange = (sorter) => {
        
        // 排序搜索
        
        
        if(sorter.columnKey=="createTime"){
            if(sorter.order=="ascend"){
                this.setState({
                    pageNo:1,
                    orderByList:[1]
                },()=>{
                    this.getList({orderByList:[1]})
                })
                
            }else if(sorter.order=="descend"){
                this.setState({
                    pageNo:1,
                    orderByList:[2]
                },()=>{
                    this.getList({orderByList:[2]})
                })
                
            }
        }
        if(sorter.columnKey=="templateName"){
            if(sorter.order=="ascend"){
                this.setState({
                    pageNo:1,
                    orderByList:[5]
                },()=>{
                    this.getList({orderByList:[5]})
                })
                
            }else if(sorter.order=="descend"){
                this.setState({
                    pageNo:1,
                    orderByList:[6]
                },()=>{
                    this.getList({orderByList:[6]})
                })
                
            }
        }
        
        this.setState({
          
          sortedInfo: sorter,
        });
    };
    // 获取列表
    getList({orderByList}={orderByList:[2]}) {
        http({
            api: API.templateCount,
            path: {}, // 路径参数
            data: {
                name: this.templateName,
                orderByList: orderByList,// 排序 1创建时间正序、2创建时间倒序、3最后更新时间正序、4最后更新时间倒序、7预计处理时间正序、8预计处理时间倒序、9订单ID正序、10订单ID倒序 ,
                pageNo: this.state.pageNo||1,// 当前页数 ,
                pageSize: this.state.pageSize||10,// 每页条数 ,
                projectId: this.state.projectId, // 项目id ,                        
                status: this.statusId,// 状态 ,
                type: 2,// 类型 1合同 2收据 ,          
                flowStates :this.state.checked===0?null:this.state.checked,
                
            },
            showLoading: false // 是否显示全局加载
        }).then(res => {
            this.setState({
                flowStatus:res.data.map(res=>{
                    return {key:res.key?res.key:0,value:res.value}
                })
            })
        })
        http({
            api: API.templateList,
            path: {}, // 路径参数
            data: {
                name: this.templateName,
                orderByList: orderByList,// 排序 1创建时间正序、2创建时间倒序、3最后更新时间正序、4最后更新时间倒序、7预计处理时间正序、8预计处理时间倒序、9订单ID正序、10订单ID倒序 ,
                pageNo: this.state.pageNo||1,// 当前页数 ,
                pageSize: this.state.pageSize||10,// 每页条数 ,
                projectId: this.state.projectId, // 项目id ,                        
                status: this.statusId,// 状态 ,
                type: 2,// 类型 1合同 2收据 ,          
                flowStates :this.state.checked===0?null:this.state.checked,
                    
            },            
            showLoading: true // 是否显示全局加载
        }).then(res => {
            this.setState({
                total:res.data.total
            })
            // 生成表单数据
            const list = res.data.datas && res.data.datas.map(res => {
                return {
                    key: res.encode,
                    templateName: res.name,
                    templateType: res.typeDesc,
                    status:res.status,
                    auditStatus: res.statusDesc,
                    templateStatus: res.statusDesc,
                    defaultFlag:res.defaultFlag,
                    type:res.type,
                    media:res.media,
                    creator: res.createName,
                    createTime: res.createTime&&moment(res.createTime).format('YYYY-MM-DD HH:mm')||'-',
                    approver: res.verifyName && res.verifyName.join('/')||'-',
                    approvalTime: res.verifyTime&&res.verifyTime&&moment(res.verifyTime).format('YYYY-MM-DD HH:mm')||'-',
                    operate: [res.isDelete ? 1 : 0, res.isDownload ? 2 : 0, res.isUpdate ? 3 : 0, res.isCopy ? 4 : 0, res.isVerify ? 5 : 0,]
                }
            })
            //收据列表
            this.setState({
                data: list,
            })
        })
    }
    search=(value)=>{
        this.templateName = value
        console.log(value)
        console.log(this.state.projectId)
        console.log(this.statusId)
        console.log(this.templateTypeId)
        this.setState({
            pageNo:1
        },()=>{
            this.getList()
        })
    }
    downfile=(media)=>{

        http({
            api: API.templateDownLoad,
            path: {}, // 路径参数
            data: {
                ...media
            }, // 请求参数
            responseType:'blob',
            showLoading: true // 是否显示全局加载
        }).then(res => {
            if (res.size < 1024) {
                message.error('下载异常，请稍后重试')
            } else {
            const content = res
            const blob = new Blob([content])
            const fileName = media.name
            if ('download' in document.createElement('a')) { // 非IE下载
                const elink = document.createElement('a')
                elink.download = fileName
                elink.style.display = 'none'
                elink.href = URL.createObjectURL(blob)
                document.body.appendChild(elink)
                elink.click()
                URL.revokeObjectURL(elink.href) // 释放URL 对象
                document.body.removeChild(elink)
            } else { // IE10+下载
                navigator.msSaveBlob(blob, fileName)
            }
        } 
        })
    }
    operate = (data, templateId,item) => {
        // 删除 ，如果状态是1的话
        if (data == 1) {

            this.setState({
                
                templateIdDelete: templateId,
                showDelete: true
            })

        }
        // 下载
        if (data == 2) {
            if(item.defaultFlag==1){
                var fileName = ''
                if(item.type==101){
                    // 租赁合同
                    fileName = '商业租赁合同模板-租赁.docx'
                }else if(item.type==102){
                    // 物业合同
                    fileName = '商业租赁合同模板-物业.docx'
                }else if(item.type==2){
                    // 收据
                    fileName = '商业租赁收据模板.docx'
                }
                const elink = document.createElement('a')
                elink.download = fileName
                elink.style.display = 'none'
                elink.href = '/template/'+fileName
                document.body.appendChild(elink)
                elink.click()
                document.body.removeChild(elink)
            }else{
                this.downfile(item.media)
            }

        }
        // 复制 ，如果状态是4的话
        if (data == 4) {

            this.setState({
                
                templateIdCopy: templateId,
                showCopy: true
            })
        }
        // 审批 ，如果状态是5的话
        if (data == 5) {

            // this.setState({                
            //     templateIdApprove: templateId,
            //     showApprove: true
            // })
            this.props.history.push("/app/setting/template/receipttemplate/templatedetail/"+templateId+"?type=approve")
        }
        // 编辑
        if (data == 3) {
            this.props.history.push("/app/setting/template/receipttemplate/receipteditfirst?edit="+templateId)      
            
            
        }
        console.log('operate', data, templateId)
    }
    onChange = (pageNo, pageSize,sorter) => {
        if(sorter){
            this.handleChange(sorter)
            return false
        }
        console.log(this.state.showCopy)
        console.log('contract list', pageNo, pageSize)
        // 页面更新    
        this.setState({
            pageNo,
            pageSize
        },()=>{
            this.getList()
        })
    }
    reset=()=>{
        this.statusId = undefined
        this.templateTypeId = undefined
        this.templateName = undefined
        this.setState({
            reset: true
        }, () => {
            this.setState({
                reset: false,
                projectId: undefined,
                pageNo:1,
                checked:0,
            },()=>{
                this.getList()
                this.clearSort()
            })
            
        })
    }
    reload=(e)=>{
        this.setState({
            showDelete: false,
            showCopy: false,
            showApprove: false,
        })
        if(e){
            this.getList()
        }
        
    }
    handleChangeTag = checked => {
        console.log(checked)
        this.setState({ 
            checked,
            pageNo:1
        },()=>{
            http({
                api: API.templateList,
                path: {}, // 路径参数
                data: {
                    name: this.templateName,
                    orderByList:this.state.orderByList ,// 排序 1创建时间正序、2创建时间倒序、3最后更新时间正序、4最后更新时间倒序、7预计处理时间正序、8预计处理时间倒序、9订单ID正序、10订单ID倒序 ,
                    pageNo: this.state.pageNo||1,// 当前页数 ,
                    pageSize: this.state.pageSize||10,// 每页条数 ,
                    projectId: this.state.projectId, // 项目id ,                        
                    status: this.statusId,// 状态 ,
                    type: 2,// 类型 1合同 2收据 ,          
                    flowStates :this.state.checked===0?null:this.state.checked,
                        
                },            
                showLoading: true // 是否显示全局加载
            }).then(res => {
                this.setState({
                    total:res.data.total
                })
                // 生成表单数据
                const list = res.data.datas && res.data.datas.map(res => {
                    return {
                        key: res.encode,
                        templateName: res.name,
                        templateType: res.typeDesc,
                        status:res.status,
                        auditStatus: res.statusDesc,
                        templateStatus: res.statusDesc,
                        defaultFlag:res.defaultFlag,
                        type:res.type,
                        creator: res.createName,
                        media:res.media,
                        createTime: res.createTime&&moment(res.createTime).format('YYYY-MM-DD HH:mm')||'--',
                        approver: res.verifyName && res.verifyName.join('/')||'--',
                        approvalTime: res.verifyTime&&res.verifyTime&&moment(res.verifyTime).format('YYYY-MM-DD HH:mm')||'--',
                        operate: [res.isDelete ? 1 : 0, res.isDownload ? 2 : 0, res.isUpdate ? 3 : 0, res.isCopy ? 4 : 0, res.isVerify ? 5 : 0,]
                    }
                })
                // 收据列表
                this.setState({
                    data: list,
                })
            })
        });
    };
    notFount() {
        return (
            <div style={{ textAlign: 'center', height: '100px', lineHeight: '100px' }}>加载中...</div>
        )
    }
    notFoundContent() {
        return (
            <div className='ant-empty ant-empty-normal ant-empty-small'><div className="ant-empty-image"></div><p className="ant-empty-description">暂无数据</p></div>)
    }


    render() {
        let { sortedInfo,fetching } = this.state;
        console.log(sortedInfo)
        sortedInfo = sortedInfo || {};
        
        let columns= [
            {
                title: '模板名称',
                dataIndex: 'templateName',
                render(text, record) {
                    return (<Link to={'/app/setting/template/receipttemplate/templatedetail/' + record.key} className="table-operate-btn">{text}</Link>);
                },
                sortOrder: sortedInfo.columnKey === 'templateName' && sortedInfo.order,
                sorter: true
            }, {
                title: '审批状态',
                dataIndex: 'auditStatus',
                render(text, record) {
                    var style = {}
                    if(record.status==1){
                        style = {color:'#FBBC06'}
                    }
                    if(record.status==0||record.status==2){
                        style = {color:'#E94437'}
                    }
                    if(record.status==3){
                        style = {color:'#E94437'}
                    }
                    return (<span><span style={style}>● </span> {text}</span>);
                },
            }, {
                title: '创建人',
                dataIndex: 'creator',
            }, {
                title: '创建时间',
                dataIndex: 'createTime',                
                sortOrder: sortedInfo.columnKey === 'createTime' && sortedInfo.order,
                sorter: true
            }, {
                title: '审批人',
                dataIndex: 'approver',
            }, {
                title: '审批时间',
                dataIndex: 'approvalTime',
            }, {
                title: '操作',
                dataIndex: 'operate',
                render: (text, record, index) => (
                    <span>
                        {text.map(per => {
                            if (per) {
                                switch (per - 1) {
                                    case 0:
                                        return  <AuthContainer key="1" authCodes={AUTH_KEYS.SETTING_TEMPLATE_RECEIPT_DELETE}>
                                                    <span className="table-operate-btn" key={per} onClick={this.operate.bind(this, per, record.key,record)}>{['删除', '下载', '编辑', '复制', '审批'][per - 1]}</span>
                                                </AuthContainer>    
                                        
                                    case 1:
                                        return  <AuthContainer key="2" authCodes={AUTH_KEYS.SETTING_TEMPLATE_RECEIPT_DOWNLOAD}>
                                                    <span className="table-operate-btn" key={per} onClick={this.operate.bind(this, per, record.key,record)}>{['删除', '下载', '编辑', '复制', '审批'][per - 1]}</span>
                                                </AuthContainer>    
                                    case 2:
                                        
                                    return  <AuthContainer key="3" authCodes={AUTH_KEYS.SETTING_TEMPLATE_RECEIPT_MODIFY}>
                                                <span className="table-operate-btn" key={per} onClick={this.operate.bind(this, per, record.key,record)}>{['删除', '下载', '编辑', '复制', '审批'][per - 1]}</span>
                                            </AuthContainer>    
                                    case 3:
                                        
                                    return  <AuthContainer key="4" authCodes={AUTH_KEYS.SETTING_TEMPLATE_RECEIPT_COPY}>
                                                <span className="table-operate-btn" key={per} onClick={this.operate.bind(this, per, record.key,record)}>{['删除', '下载', '编辑', '复制', '审批'][per - 1]}</span>
                                            </AuthContainer>    
                                    case 4:
                                        
                                    return <span className="table-operate-btn" key={per} onClick={this.operate.bind(this, per, record.key,record)}>{['删除', '下载', '编辑', '复制', '审批'][per - 1]}</span>
                                            
                                    default:
                                        return <span className="table-operate-btn" key={per} onClick={this.operate.bind(this, per, record.key,record)}>{['删除', '下载', '编辑', '复制', '审批'][per - 1]}</span>
                                        
                                }
                                
                            }
                        })}
                    </span>
                )
            }
        ]
        

        
        return (
            <div className='template-con'>

                <DeleteTemplate visible={this.state.showDelete} templateId={this.state.templateIdDelete} reload={this.reload}/>
                {/* <ApproveTemplate visible={this.state.showApprove} templateId={this.state.templateIdApprove} reload={this.reload}/> */}
                <CopyTemplate visible={this.state.showCopy} templateId={this.state.templateIdCopy} reload={this.reload} projectList={this.state.projectList.slice(1)}/>
                <div className='template-header'>
                    <Breadcrumb>
                        <BreadcrumbItem>模板管理</BreadcrumbItem>
                        <BreadcrumbItem>收据模板</BreadcrumbItem>
                    </Breadcrumb>
                    <AuthContainer authCodes={AUTH_KEYS.SETTING_TEMPLATE_RECEIPT_NEW}>
                        <Link to='/app/setting/template/receipttemplate/receipteditfirst'><Button type="primary" className='new-template-btn' >新增模板</Button></Link>
                    </AuthContainer>
                </div>
                <div className='template-list'>
                    {this.state.reset ? (<div className="search"></div>) : (

                        <div className="search">
                            <Search
                                placeholder="请输入模板名称"
                                onSearch={this.search}
                                maxLength={20}
                            />
                            <Select data={this.state.projectList} value={this.state.projectId} otFoundContent={fetching ? this.notFount() : this.notFoundContent()} placeholder="适用项目" selectMethod={this.chooseProject} />
                            <Select data={this.state.auditStatus} value={this.statusId} otFoundContent={fetching ? this.notFount() : this.notFoundContent()} placeholder="审批状态" selectMethod={this.chooseStatus} />
                            <span className="reset" onClick={this.reset}>重置</span>

                        </div>
                        
                    )}
                    {
                        this.state.reset?(
                            <div className="approve-tag">
                                null
                            </div>):(
                            <div className="approve-tag">
                                {
                                    this.state.flowStatus&&this.state.flowStatus.map((res,item)=>{
                                        return <Tag key={res.key} className={this.state.checked==res.key?'active':''} onClick={this.handleChangeTag.bind(this,res.key)}>{this.state.flowStatusObj[res.key]}({res.value})</Tag>        
                                    })
                                }
                            </div>
                        )
                    }                    
                    {this.state.total?(
                        
                        (
                            <AuthContainer key="1" authCodes={AUTH_KEYS.SETTING_TEMPLATE_RECEIPT_LOOK}>                                    
                                    <Table columns={columns} current={this.state.pageNo} selfProcessData={this.state.data} total={this.state.total} change={this.onChange}/>
                            </AuthContainer>    
                    )                            
                        
                    ):(<div className="template-empty">
                        <div className="template-empty-img">
                            <img src={empty} alt=""/>
                        </div>
                        <div className="template-empty-des">
                            抱歉，未找到对应模板，请换个条件再试一次
                        </div>
                    </div>)}
                    

                </div>
                <div className="template-black">
                    {/* 空白底 */}
                </div>
            </div>
        );
    }
}

export default ReceiptTemplate;


