import React, { Component } from 'react';
import {
    Select, Icon, Row, Col, Tooltip
} from 'antd';
const Option = Select.Option;
export default class BOSelect extends Component {
    render() {
        const { data, disabled, selectMethod, style, showSecondTip, mode, label, ...child } = this.props
        let options = null;
        let text = ""
        if (showSecondTip) {
            options = data.map(per => {
                return <Option value={per.value} key={per.value}><Row><Col style={{ textAlign: "left" }} span={12}>{per.name}</Col><Col style={{ textAlign: "right" }} span={12}>
                    {per.account}
                </Col></Row></Option>


            })
        } else {
            options = data.map(per => {
                if (per.name && per.name.length > 6) {
                    text = <Tooltip placement="top" title={per.name}>
                        {per.name}
                    </Tooltip>
                } else {
                    text = per.name
                }

                return <Option value={per.value} key={per.value}>
                    {text}</Option>
            })
        }

        let body = undefined;
        if (mode) {
            body = <Select style={style || { width: '100%' }} suffixIcon={<Icon type="caret-down" />}
                onSelect={selectMethod} {...child} disabled={disabled} mode={mode}
            >
                {options}
            </Select>;
        } else {
            body = <Select style={style || { width: '100%' }} suffixIcon={<Icon type="caret-down" />}
                onSelect={selectMethod} {...child} disabled={disabled}  >
                {options}
            </Select>;
        }

        if (!label || label.length <= 0) {
            return body;
        }

        // 有lable 就返回一个带label的组件
        //TODO 下面的这些参数，应该有系统 定制一套基本UI参数，引用即可.
        return <span style={{ textAlign: 'center' }}>
            <span style={{ fontSize: '12px', color: '#333',marginRight:'5px' }}>{label}</span>
            {body}
        </span>
    }
}
