import React, { Component } from "react";

import { Breadcrumb  ,message,Button } from 'antd';
import { Link } from 'react-router-dom';
import Table from 'CMPT/common/Table';
import http from '@/http';
import { API } from '@/http/api';
import moment from "moment";
import TitlePanel from "CMPT/TitlePanel";
import EmptyView from "CMPT/widget/EmptyView";
import { DivConvert, UrlConvert, ImgConvert,CustomFunctionConvert,RowTwoConvert,RowThreeConvert } from '@/utils/detail_convert.js'
import EquipmentInfo from "../EquipmentOrder/EquipmentInfo";

import './DetailInfo.less'

class DetailInfo extends React.Component {
    state = {
        code: null,//
        datas: null,

        titleInfoMap:new DivConvert('code', '意向单编号') ,
        accountMap1:  new RowTwoConvert(new DivConvert('leasing', '求租方账号'),new DivConvert('equipmentParty', '设备方账号')),

        deviceInfoMap:[
            new RowTwoConvert(new DivConvert('equipmentNumber', '设备编号'),new DivConvert('equipmentName', '出租名称')),
            new RowTwoConvert(new DivConvert('typeName', '设备类型'),new DivConvert('brandModelName', '品牌/型号')),
            new RowTwoConvert(new DivConvert('dateOfProductionDesc', '出厂日期'),new DivConvert('locatin_info', '设备所在地')),
            new DivConvert('expectedPriceDesc', '期望价'),
            new DivConvert('remark', '设备简介'),
            new RowTwoConvert(new DivConvert('contactsName', '租赁联系人'),new DivConvert('contactsPhone', '手机号')),
            new DivConvert('equipmentPartyCompany', '设备方公司')
        ],
        intentionInfoMap:[
            new RowTwoConvert(new DivConvert('locatin_info', '使用地区'),new DivConvert('dateDesc', '租赁日期')),
            new RowTwoConvert(new DivConvert('expectedPrice', '报价'),new DivConvert('paymentMethodName', '付款方式')),
            new RowTwoConvert(new DivConvert('projectName', '项目名称'),new DivConvert('remark', '备注')),
            new RowTwoConvert(new DivConvert('contactsName', '联系人'),new DivConvert('contactsPhone', '手机号')),
            new DivConvert('leasingCompany', '求租方公司')
        ],


        // 求租信息
        searchDeviceInfoMap:[
            new RowTwoConvert(new DivConvert('demandNumber', '求租编号'),new DivConvert('demandName', '求租名称')),
            new RowTwoConvert(new DivConvert('typeName', '设备类型'),new DivConvert('brandModelName', '品牌/型号')),
            new RowTwoConvert(new DivConvert('demandQuantity', '求租台数'),new DivConvert('locatin_info', '施工地点')),
            new RowTwoConvert(new DivConvert('rentDate', '求租日期'),new DivConvert('expectedPriceDesc', '期望价')),
            new DivConvert('remark', '求租简介'),
            new RowTwoConvert(new DivConvert('contactsName', '联系人'),new DivConvert('contactsPhone', '手机号')),
            new DivConvert('leasingCompany', '求租方公司')

        ],

        recordsColumns: this.createColumn(),

        contactInfoMap: [ 
             new RowTwoConvert(new DivConvert('contactsName', '联系人'),new DivConvert('contactsPhone', '手机号')),
            new RowTwoConvert(new DivConvert('equipmentPartyCompany', '设备方公司'),new DivConvert('remark', '备注'))
         ]

    };

    createColumn(){
       return [
        {
            title: '时间',
            dataIndex: 'handleTime',
            render: (value, record, index) => {
                return <span>{ value}</span>;
            }
        },   {
            title: '备注',
            dataIndex: 'orderRemark',
            render: (text, record, index) => {
                return <span>{'备注:'+text}</span>;
            }
        }
    ];
    }

    componentWillMount() {
        const { match } = this.props;
        this.state.code =  match.params.code;
     }


    getDetail() {

        let that = this;
        let dataParams = { code: this.state.code };
        const config = {
            api: API.equipmentIntentionDetail,
            path: dataParams,
            showLoading: true
        };

        http(config).then(res => {
             if (res && res.code==200&&res.data) {
                 let datas=res.data;
                that.convertHttpData(datas);
                that.setState({datas});
            }else{
                message.error(res.message ||'订单详情查询失败');
            }

        });
    };

    convertHttpData(data){
       if(!data){
           return;
       }

              // 设备信息
          if(data.equipmentInfo && data.equipmentInfo.equipmentReleaseDetailDTO){
            let  equipmentReleaseDetail =  data.equipmentInfo.equipmentReleaseDetailDTO;
            equipmentReleaseDetail['typeName'] = (equipmentReleaseDetail.primaryTypeName||'')+"  "+(equipmentReleaseDetail.secondaryTypeName||'');
            equipmentReleaseDetail['brandModelName'] = (equipmentReleaseDetail.brandName||'')+"  "+(equipmentReleaseDetail.modelName||'');
            equipmentReleaseDetail['locatin_info'] = (equipmentReleaseDetail.cityName==equipmentReleaseDetail.provinceName ? '':(equipmentReleaseDetail.provinceName||''))+(equipmentReleaseDetail.cityName||'')+(equipmentReleaseDetail.areaName||'');
            equipmentReleaseDetail['equipmentPartyCompany'] = data.equipmentInfo.dealBothInfo ? data.equipmentInfo.dealBothInfo.equipmentPartyCompany:undefined;
             equipmentReleaseDetail['dateOfProductionDesc'] = (equipmentReleaseDetail.dateOfProduction && !isNaN(equipmentReleaseDetail.dateOfProduction)) ?(moment(equipmentReleaseDetail.dateOfProduction).format("YYYY-MM-DD HH:mm")):undefined; 
            
            

            // 期望价格
           /* if(equipmentReleaseDetail.priceList && Array.isArray(equipmentReleaseDetail.priceList)&&equipmentReleaseDetail.priceList.length){
                 var expectedPrice ='';
                 equipmentReleaseDetail.priceList.forEach(item=>{
                     if(item.expectPrice&&item.priceTypeName){
                        expectedPrice+=(item.expectPrice+item.priceTypeName+"   ");
                     }
                 });
                 equipmentReleaseDetail['expectedPrice'] = expectedPrice;                 
            }*/
            equipmentReleaseDetail['expectedPriceDesc'] = (equipmentReleaseDetail.expectedPrice&&equipmentReleaseDetail.expectedPriceName)?(equipmentReleaseDetail.expectedPrice+equipmentReleaseDetail.expectedPriceName):'';                 

          }



          // 租用意向
          if(data.equipmentInfo && data.equipmentInfo.intentionDetailResultDTO){
              let intentionDetail = data.equipmentInfo.intentionDetailResultDTO;
              intentionDetail['locatin_info'] = (intentionDetail.provinceName||'') + (  intentionDetail.cityName==intentionDetail.provinceName ?"":(intentionDetail.cityName||''))/*直辖市，不展示市 */ + (intentionDetail.areaName||'');

              intentionDetail['typeName'] = (intentionDetail.primaryTypeName||'')+"  "+(intentionDetail.secondaryTypeName||'');
              intentionDetail['brandModelName'] = (intentionDetail.brandName||'')+"  "+(intentionDetail.modelName||'');

              intentionDetail['dateDesc']= (intentionDetail.entryDate&&intentionDetail.leavingDate&&!isNaN(intentionDetail.entryDate)&&!isNaN(intentionDetail.leavingDate)) ?
              (moment(intentionDetail.entryDate).format("YYYY-MM-DD HH:mm")+"~"+moment(intentionDetail.leavingDate).format("YYYY-MM-DD HH:mm")):'-';

              intentionDetail['expectedPrice']= (intentionDetail.expectedPriceCode&&intentionDetail.expectedPriceName)?(intentionDetail.expectedPriceCode+intentionDetail.expectedPriceName):'-';
              intentionDetail['leasingCompany'] = data.equipmentInfo.dealBothInfo ? data.equipmentInfo.dealBothInfo.leasingCompany:undefined;
              intentionDetail['locatin_info'] = (intentionDetail.provinceName||'')+(intentionDetail.cityName||'')+(intentionDetail.areaName||'');
          }




          
        // 租用意向
        if(data.demandInfo && data.demandInfo.releaseDetailDTO){
            let releaseDetail = data.demandInfo.releaseDetailDTO;
            releaseDetail['locatin_info'] = (releaseDetail.provinceName||'') + (  releaseDetail.cityName==releaseDetail.provinceName ?"":(releaseDetail.cityName||''))/*直辖市，不展示市 */ + (releaseDetail.areaName||'');

            releaseDetail['typeName'] = (releaseDetail.primaryTypeName||'')+"  "+(releaseDetail.secondaryTypeName||'');
            releaseDetail['brandModelName'] = (releaseDetail.brandName||'')+"  "+(releaseDetail.modelName||'');

            releaseDetail['rentDate']= (releaseDetail.rentDateStart&&releaseDetail.rentDateEnd&&!isNaN(releaseDetail.rentDateStart)&&!isNaN(releaseDetail.rentDateEnd)) ?
            (moment(releaseDetail.rentDateStart).format("YYYY-MM-DD HH:mm")+"~"+moment(releaseDetail.rentDateEnd).format("YYYY-MM-DD HH:mm")):'-';

            releaseDetail['leasingCompany'] = data.demandInfo.dealBothInfo ? data.demandInfo.dealBothInfo.leasingCompany:undefined;
            releaseDetail['locatin_info'] = (releaseDetail.provinceName||'')+(releaseDetail.cityName||'')+(releaseDetail.areaName||'');

            releaseDetail['expectedPriceDesc'] =  releaseDetail.expectedPriceType==2?"面议": ((releaseDetail.expectedPrice&&releaseDetail.priceTypeName)?(releaseDetail.expectedPrice+releaseDetail.priceTypeName):'-');
            
        }


        if(data.demandInfo &&data.demandInfo.demandIntentionDetailResultDTO){
           let demandIntentionDetail = data.demandInfo.demandIntentionDetailResultDTO;
           demandIntentionDetail['equipmentPartyCompany']=data.demandInfo.dealBothInfo ? data.demandInfo.dealBothInfo.equipmentPartyCompany:undefined;
        }
    }

    componentDidMount() {
        this.getDetail();
    }


    render() {
        let { datas,recordsColumns,titleInfoMap,deviceInfoMap,intentionInfoMap,accountMap1,searchDeviceInfoMap,contactInfoMap} = this.state;
        
        return (  <div className="detailInfo">
        <div>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={{ pathname: "/app/crm/orderManage", search: '?tab=3' }}>
                订单管理
              </Link>
            </Breadcrumb.Item >
            <Breadcrumb.Item>
              租赁意向单详情
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>


        {
          (!datas)&&<EmptyView  tips="暂无数据" />
         }

        <div class="detailInfo_body">
         {/* 头部信息 */}
         {/*
                datas&& (<TitlePanel   > {titleInfoMap.convert(datas)}</TitlePanel> )
         */}

         {
                datas&& (<TitlePanel></TitlePanel> )
         }
      
        {/* 设备信息 */}
        {
            datas&&datas.equipmentInfo&&datas.equipmentInfo.equipmentReleaseDetailDTO&&(<TitlePanel   title="设备信息" >
            { deviceInfoMap.map((item, index) => {
                return item.convert(datas.equipmentInfo.equipmentReleaseDetailDTO, index);
               })
            }
            </TitlePanel>)
        }



           {/* 租用设备信息 */}
       {
         datas&&datas.equipmentInfo&&datas.equipmentInfo.intentionDetailResultDTO&&(<TitlePanel   title="租用意向单" >
         { intentionInfoMap.map((item, index) => {
             return item.convert(datas.equipmentInfo.intentionDetailResultDTO, index);
            })
         }
     
         </TitlePanel>)
        }



      {/* 求租设备信息 */}
       {
         datas&&datas.demandInfo&& datas.demandInfo.releaseDetailDTO&&(<TitlePanel   title="求租信息" >
         { searchDeviceInfoMap.map((item, index) => {
             return item.convert(datas.demandInfo.releaseDetailDTO, index);
            })
         }
     
         </TitlePanel>)
        }



  
       {
           datas&&datas.demandInfo && datas.demandInfo.demandIntentionDetailResultDTO &&  datas.demandInfo.demandIntentionDetailResultDTO.equipmentList&&         
            (<TitlePanel  title="设备意向单" >
            
            <div style={{width:'800px'}}>
           <EquipmentInfo type={2}  data={datas.demandInfo.demandIntentionDetailResultDTO.equipmentList}/>
            
            {/** 平均价  */}

            {(datas.demandInfo.demandIntentionDetailResultDTO.expectedPriceAverage && datas.demandInfo.demandIntentionDetailResultDTO.expectedPriceName)&&
            <div  >
                <span style={{float:'right',fontSize:'12px'}}>{"报价平均单价:"+datas.demandInfo.demandIntentionDetailResultDTO.expectedPriceAverage+datas.demandInfo.demandIntentionDetailResultDTO.expectedPriceName}</span>
             </div>
            }
            </div>
            
             {/* 设备信息下的信息 */}
             <div style={{marginTop:'25px'}}>
              {
               contactInfoMap.map((item, index) => {
                return item.convert(datas.demandInfo.demandIntentionDetailResultDTO, index);
               })
              }
              </div>
          </TitlePanel>)  
        }  
          

        {/* 操作记录 */}
        {       
           datas&&datas.handleRecord ? (<TitlePanel   title="沟通记录" >
            {
                 datas&&datas.equipmentInfo&&datas.equipmentInfo.dealBothInfo &&accountMap1.convert(datas.equipmentInfo.dealBothInfo)
            }
               <Table columns={ recordsColumns} showHeader={false} data={datas.handleRecord} pagination={false}/>
               </TitlePanel>):""    

        }  

         </div>


         <div className="detail_btn">
              <Button onClick={() => this.props.history.goBack()}>
                返回
              </Button>
            </div>

        </div>);
 
    }
}


export default DetailInfo;
