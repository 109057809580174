import React, { Component } from 'react';
import ImgPreview from 'CMPT/common/ImgPreview';

export default class Attachment extends Component {

    state = {

    };

    // 设置图片
    closeImagePreview = () => {
        this.setState({ previewVisible: false });
    };

    //TODO 这里完全可以把所有图片放进预览图片中，每次点击一个缩略图，则把被点击图片的url作为显示与否的判断，并作为当前被预览的url。这种无非这块冗余代码   20211218
    // 设置图片缩放
    cImage = (imgList, currentIndex = 0, isForArr) => {
        imgList = isForArr ? imgList.map(item => {
            return item.url || item.src || item;
        }) : imgList;
        this.setState({
            previewVisible: true,
            imgList,
            currentIndex: currentIndex
        });
    };

    render() {
        let { previewVisible, currentIndex, imgList } = this.state;
        let data = this.props.data;

        //TODO 后续这里可能针对 图片 文档进行分类 20211220
        return (
            <div>
                <div style={{ display: 'flex', flexDirection: "row", flexWrap: 'wrap' }}>
                    {data && data.length > 0 ? data.map((item, index) => {
                        if (!item.name) {
                            return '';
                        }

                        //TODO 这些逻辑完全可以封装在ImgPreview这个组件中。没必要裸露在外面     20211218
                        var first = item.name.lastIndexOf(".");//取到文件名开始到最后一个点的长度
                        var filesuffix = item.name.substring(first + 1, item.name.length);//截取获得后缀名
                        filesuffix = filesuffix.toLowerCase();

                        if (filesuffix == 'png' || filesuffix == 'jpg' || filesuffix == 'jpeg') {
                            return <a style={{ marginBottom: '10px', marginTop: '10px' }} key={index} onClick={(e) => { e.preventDefault(); this.cImage([item.url]) }} href={item.url}><img style={{ width: '120px', height: '90px', marginLeft: '10px', marginRight: '10px' }} src={item.url} /></a>
                        }
                        
                        return (<a style={{ marginBottom: '10px', marginTop: '10px' }} key={index} href={item.url} download={item.name}>{item.name}</a>)
                    }) : <span>无</span>}

                </div>
                <ImgPreview show={previewVisible} current={currentIndex} imgList={imgList} onClose={this.closeImagePreview} />

            </div>
        );
    }

}