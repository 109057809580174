import { API } from '@/http/api';
import http from '@/http';
import { showErrorMessage, } from '@/utils/globalMessageReminder';
import { formatLinkSearch } from '@/utils';
import { convertAuthenticationToLabel } from 'CMPT/operate/customerConfig'
import React, { Component, useState, useEffect } from 'react';
import { Layout, Breadcrumb, Button, Row, Col, Tabs, Modal, Input } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Table from 'CMPT/common/Table';
import SingleImageViewer from "CMPT/common/widget/SingleImageViewer";
import { CommonFlag } from "CMPT/common.js";
import { CertType, AuthenticationStatus } from "./enum";
import tianyanchaColumns from "./TianyanchaTableColumns";

import './TianYanCha.less';
const { TextArea } = Input;
const { Content } = Layout;
const { TabPane } = Tabs;
// vpvg4p36SYQBxMjBPn121s
class TianYanCha extends Component {
    state = {
        isScroll: false,
        current: 1,
        baseInfo: {},        
        updateTime:[],
    }
    componentWillMount(){
        if (this.props.location.search) {
            // 获取参数
            const search = this.props.location.search
            const formatData = formatLinkSearch(search)

            // 解析
            let code1 = this.props.match.params.code;
            let pageNo1 = null;
            let currentageCount1 = null;

            if (formatData && formatData.pageNo) {
                pageNo1 = formatData.pageNo
            }
            if (formatData && formatData.currentPageCount) {
                currentageCount1 = formatData.currentPageCount
            }

            // 赋值
            this.setState({
                code: code1,
                pageNo: pageNo1,
                backPageNo: pageNo1, //如果什么都不做，返回列表的原始页码即可。否则，需要判断跳转哪一页，尤其删除一个页面中的唯一一条数据
                currentageCount: currentageCount1,
            });
        }
    }

    componentDidMount() {
        this.getBaseInfo();

        var _box = document.querySelector('.customer-detail-content')
        var _empty = document.querySelector('.tianyan-get-data.empty')

        setTimeout(() => {

            if (_box.scrollHeight > _box.offsetHeight) {
                this.setState({
                    isScroll: true,
                    emptyOffsetWidth: _empty.offsetWidth
                })
            }
        }, 1000);

    }
    // 天眼查企业信息刷新最新
    getNewest = () => {
        const config = {
            api: API['tyc_flush'],
            data: {
                enterpriseCode: location.href.replace(/.*\/tianyan\/(.*)\?.*/, '$1'),
                // 查询数据类型 1基本信息,2司法风险,3经营风险,4：知识产权，5：历史信息  刷新指定页
                flushType: this.state.current
            },
            showLoading: true
        };
        http(config).then(res => {
            var lastCurrent = this.state.current
            if (res.success) {
                const data = res.data;
                this.setState({
                    
                    current: 0
                })
            }
            setTimeout(() => {
                
                this.getBaseInfo()
                
                this.setState({
                    current: lastCurrent
                })
            }, 300);
        })
    }
    // 获取基本信息
    getBaseInfo() {

        const config = {
            api: API['tyc_baseinfo'],
            path: {
                encode: location.href.replace(/.*\/tianyan\/(.*)\?.*/, '$1')
            },
            showLoading: true
        };
        http(config).then(res => {
            if (res.success) {
                const data = res.data;
                this.setState({
                    baseInfo: data,
                    updateTime:[data.lastFlushDate_1,data.lastFlushDate_2,data.lastFlushDate_3,data.lastFlushDate_4,data.lastFlushDate_5]
                    
                })
            }

        })
    }


    render() {
        let { data, isScroll, updateTime, emptyOffsetWidth, current, baseInfo } = this.state;


        return (
            <Content className="customer-detail-content">
                <div className="customer-detail-head flexbox">
                    <Breadcrumb className="customer-detail-head-bread">
                        <Breadcrumb.Item>
                            <Link to={{ pathname: '/app/crm/enterprise_auth', search: 'backPageNo=' + this.state.backPageNo }} >
                                    认证列表
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <a href={ location.href.replace('tianyan','detail')}>
                                详情
                            </a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            天眼查辅助数据
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className="customer-detail-content-box">
                    <h2>{baseInfo.name}</h2>
                    <div className="tianyan-tabs">
                        <ul>
                            <li className={current == 1 ? "current" : ''} onClick={() => { this.setState({ current: '1' }) }}>基本信息</li>
                            <li className={current == 2 ? "current" : ''} onClick={() => { this.setState({ current: '2' }) }}>司法风险</li>
                            <li className={current == 3 ? "current" : ''} onClick={() => { this.setState({ current: '3' }) }}>经营风险</li>
                            <li className={current == 4 ? "current" : ''} onClick={() => { this.setState({ current: '4' }) }}>知识产权</li>
                            <li className={current == 5 ? "current" : ''} onClick={() => { this.setState({ current: '5' }) }}>历史信息</li>
                        </ul>
                    </div>
                    <div className="content-main">
                        {
                            current == 1 ? <BaseInfo baseInfo={baseInfo}></BaseInfo> : null
                        }
                        {
                            current == 2 ? <LegalRisk></LegalRisk> : null
                        }
                        {
                            current == 3 ? <OperationalRisk></OperationalRisk> : null
                        }
                        {
                            current == 4 ? <IntellectualProperty></IntellectualProperty> : null
                        }
                        {
                            current == 5 ? <HistoricalInformation></HistoricalInformation> : null
                        }
                    </div>

                </div>
                <div className='tianyan-get-data empty' style={isScroll ? null : { 'height': '0', 'marginTop': 0 }}></div>
                <div className={isScroll ? 'fixed tianyan-get-data ' : 'tianyan-get-data '} style={{ 'width': emptyOffsetWidth + 'px' }}>
                    <p>
                        <span style={{ 'marginRight': '40px' }}>数据来源：天眼查</span>
                        <span>更新时间：{updateTime[current-1]}</span>
                    </p>
                    <p>
                        <span style={{ 'color': '#999' }}>如果对数据有疑问，可尝试 <i style={{ 'color': '#2E4AFF', 'cursor': 'pointer' }} onClick={this.getNewest}>获取最新数据</i></span>
                    </p>
                </div>
            </Content>
        )
    }

}


export default TianYanCha;


// 基本信息
class BaseInfo extends Component { 

    render() {
        let { name, isMicroEnt, industry, legalPersonName, staffNumRange, type,
            creditCode, regStatus, taxNumber, companyOrgType, orgNumber,
            regCapital, phoneNumber, regLocation, businessScope
        } = this.props.baseInfo
        return (
            <div className="tianyan-baseInfo">
                <div className="tianyan-enterprise-baseinfo">
                    <h2>企业基本信息</h2>
                    <table className="baseinfo">
                        <tbody>
                            <tr>
                                <td>企业名称</td>
                                <td>{name}</td>
                                <td>是否是小微企业</td>
                                <td>{isMicroEnt==null?'':(isMicroEnt ? '是' : '否')}</td>
                            </tr>
                            <tr>
                                <td>行业</td>
                                <td>{industry}</td>
                                <td>法人</td>
                                <td>{legalPersonName}</td>
                            </tr>
                            <tr>
                                <td>人员规模</td>
                                <td>{staffNumRange}</td>
                                <td>法人类型</td>
                                <td>{type ==null?'':(type === 1 ? '人' : '公司')}</td>
                            </tr>
                            <tr>
                                <td>统一社会信用代码</td>
                                <td>{creditCode}</td>
                                <td>企业状态</td>
                                <td>{regStatus}</td>
                            </tr>
                            <tr>
                                <td>纳税人识别号</td>
                                <td>{taxNumber}</td>
                                <td>企业类型</td>
                                <td>{companyOrgType}</td>
                            </tr>
                            <tr>
                                <td>组织机构代码</td>
                                <td>{orgNumber}</td>                                
                                <td>企业联系方式</td>
                                <td>{phoneNumber}</td>
                            </tr>
                            <tr>
                                <td>注册资本</td>
                                <td colSpan="3">{regCapital}</td>
                                
                            </tr>
                            <tr>
                                <td>注册地址</td>
                                <td colSpan="3">{regLocation}</td>
                            </tr>
                            <tr>
                                <td>经营范围</td>
                                <td colSpan="3">{businessScope}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <TianYanChaTable title="变更信息" api_columns="tyc_changelog" pageSize={20} serialNumber={true} detailsBtn={true} />
            </div>
        )
    }
}

// 司法风险
class LegalRisk extends Component {
    state = {

    }
    render() {
        return (
            <div className="legal-risk">
                <TianYanChaTable title="法律诉讼" api_columns="tyc_legalproceeding" pageSize={20} serialNumber={true} detailsBtn={true} />
                <TianYanChaTable title="法院公告" api_columns="tyc_courtnotic" pageSize={20} serialNumber={true} detailsBtn={true} />
                <TianYanChaTable title="失信人" api_columns="tyc_dishonestperson" pageSize={50} serialNumber={true} detailsBtn={true} />
                <TianYanChaTable title="被执行人" api_columns="tyc_executedby" pageSize={20} serialNumber={true} detailsBtn={true} />
                <TianYanChaTable title="司法协助" api_columns="tyc_judicialassistance" pageSize={20} serialNumber={true} detailsBtn={true} />


            </div>
        )
    }
}

// 经营风险
class OperationalRisk extends Component {
    state = {

    }
    render() {
        return (
            <div className="legal-risk">
                <TianYanChaTable title="经营异常" api_columns="tyc_abnormaloperation" pageSize={20} serialNumber={true} detailsBtn={true} />
                <TianYanChaTable title="行政处罚" api_columns="tyc_aadminstrativesanction" pageSize={20} serialNumber={true} detailsBtn={true} />
                <TianYanChaTable title="行政处罚-信用中国" api_columns="tyc_creditchina" pageSize={50} serialNumber={true} detailsBtn={true} />
                <TianYanChaTable title="严重违法" api_columns="tyc_illegalinfo" pageSize={20} serialNumber={true} detailsBtn={true} />
                <TianYanChaTable title="欠税公告" api_columns="tyc_arrearsnotice" pageSize={20} serialNumber={true} detailsBtn={true} />
                <TianYanChaTable title="税收违法" api_columns="tyc_taxviolation" pageSize={20} serialNumber={true} detailsBtn={true} />
            </div>
        )
    }
}

// 知识产权
class IntellectualProperty extends Component {
    state = {

    }
    render() {
        return (
            <div className="legal-risk">
                <TianYanChaTable title="商标信息" api_columns="tyc_trademarkinfo" pageSize={20} serialNumber={true} detailsBtn={true} />
            </div>
        )
    }
}
// 历史信息
class HistoricalInformation extends Component {
    state = {

    }
    render() {
        return (
            <div className="legal-risk">
                <TianYanChaTable title="历史法律诉讼" api_columns="tyc_historicallitigation" pageSize={20} serialNumber={true} detailsBtn={true} />
                <TianYanChaTable title="历史开庭公告" api_columns="tyc_historcalopencourt" pageSize={20} serialNumber={true} detailsBtn={true} />
                <TianYanChaTable title="历史法院公告" api_columns="tyc_historcalcourtnotice" pageSize={20} serialNumber={true} detailsBtn={true} />
                <TianYanChaTable title="历史失信人" api_columns="tyc_historcaldishonest" pageSize={20} serialNumber={true} detailsBtn={true} />
                <TianYanChaTable title="历史被执行人" api_columns="tyc_historcalexecuted" pageSize={20} serialNumber={true} detailsBtn={true} />
                <TianYanChaTable title="历史经营异常" api_columns="tyc_historcalabnormaloperation" pageSize={20} serialNumber={true} detailsBtn={true} />
                <TianYanChaTable title="历史行政处罚" api_columns="tyc_historcalpunish" pageSize={20} serialNumber={true} detailsBtn={true} />
            </div>
        )
    }
}



class TianYanChaTable extends Component {

    state = {
        visible: false,
        total: 0, // 当前数据的总数
        pageNo: 1, // 当前所展示的页码
        pageSize: this.props.pageSize || 10,
        columns: (this.props.serialNumber ? [({
            title: '序号',
            key: "code",
            dataIndex: "code",
            width: "60px",
            render: (text, record, index) => {
                return (<span>{text}</span>);
            },
        })].concat(tianyanchaColumns[this.props.api_columns]) : tianyanchaColumns[this.props.api_columns]).filter(res => !res.disabled).concat(this.props.detailsBtn ?
            {
                title: '操作',
                key: 'operate',
                dataIndex: 'operate',
                width: "120px",
                render: (text, record, index) => {
                    return (<span className="table-operate-btn" onClick={() => { this.showModal(record) }}>
                        查看详情
                    </span>)
                }
            } : []),
        data: []
    }
    componentWillMount() {

        this.getLists();
    }
    showModal = (record) => {
        console.log(record)
        var detailData = []
        for (var item in record) {

            for (var li of tianyanchaColumns[this.props.api_columns]) {
                if (li.dataIndex == item || li.key == item) {
                    detailData.push({
                        name: li.title,
                        value: record[item]
                    })
                    break
                }
            }

        }


        this.setState({
            visible: true,
            detailData
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };
    //查询列表
    getLists() {
        console.log(API[this.props.api_columns])
        const config = {
            api: API[this.props.api_columns],
            data: {
                enterpriseCode: location.href.replace(/.*\/tianyan\/(.*)\?.*/, '$1'),
                pageNo: this.state.pageNo,
                pageSize: this.state.pageSize
            },
            showLoading: true
        };
        http(config).then(res => {
            if (res.success) {
                let data = res.data;
                data.datas = data.datas && data.datas.map((res, index) => {
                    return {
                        code: index + 1,
                        ...res
                    }
                })
                this.setState({
                    data: data.datas || [],
                    total: data.totalRecord,
                    pageNo: data.pageNo,
                })
            }

        })
    }
    onChangePage = (pageNo, pageSize, sort) => {
        console.log(pageNo, pageSize, sort)
        this.setState({
            pageNo, pageSize
        }, () => {
            this.getLists();
        })
    }
    render() {
        return (
            <div className="tianyan-enterprise-table">
                <h2>{this.props.title}</h2>
                <Table rowKey="code" columns={this.state.columns} selfProcessData={this.state.data} total={this.state.total} change={this.onChangePage} current={this.state.pageNo} pageSize={this.state.pageSize} />

                {
                    this.state.visible && this.props.detailsBtn ? (
                        <Modal
                            width="70%"
                            className="tianyan-model"
                            title="详情"
                            visible={this.state.visible}
                            footer={null}
                            onCancel={this.handleCancel}
                        >
                            <table className="baseinfo">
                                <tbody>
                                    {
                                        this.state.detailData && this.state.detailData.map((res, index) => {
                                            return Array.isArray(res.value) ?
                                                (<tr key={index}>
                                                    <td>{res.name}</td>
                                                    <td>{res.value.map((item, i) => <p key={i}>{item.name}</p>)}</td>
                                                </tr>)
                                                :
                                                (<tr key={index}>
                                                    <td>{res.name}</td>
                                                    {
                                                        res.name == '商标图片' ? <td> <img src={res.value} alt="" /> </td> : <td dangerouslySetInnerHTML={{ __html: res.value }}></td>
                                                    }

                                                </tr>)

                                        })
                                    }


                                </tbody>
                            </table>
                        </Modal>
                    ) : null
                }
            </div>
        )
    }
}