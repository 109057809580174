import React, { Component } from 'react';
import { Breadcrumb, Tabs, Table, Modal, Form, Input, Radio, DatePicker, Icon, message } from 'antd';
import { Link } from "react-router-dom";
import './index.less';

import { API } from '@/http/api';
import http from '@/http';
import userUtil from "@/utils/user";
import moment from "moment";
import ImgPreview from 'CMPT/common/ImgPreview';
import Upload from 'CMPT/common/Upload';
const { RangePicker } = DatePicker;
const BreadcrumbItem = Breadcrumb.Item;
const { TabPane } = Tabs;

const { Search } = Input;

const RadioGroup = Radio.Group
class AddRecord extends Component {

    state = {
        payMoneyVoucherModal: false,
        payPhotos: []
    }

    componentDidMount() {
        
    }


    rangeChange = (e) => {
        console.log(e, 'eeee');
    }



    payMoneyVoucherModalOk = () => {
        this.props.payMoneyVoucherModalOk(this.state.payPhotos)
    }


    payMoneyVoucherModalCancel = () => {
        this.props.payMoneyVoucherModalCancel()
        // this.setState({
        //     payMoneyVoucherModal: false
        // })
    }

    handleBeforeUpload = file => {
        const size = file.size;
        if (size / 1024 / 1024 > 2) {
            message.error("请上传2M以内的图片");
            return false;
        }
    };

    payMoneyChange = value => {
        if (value.file.status === "done") {
            let item = value.file.response.file;
            let {payPhotos} = this.state;
            payPhotos.push({name: '', url: item.absolutePath})
            this.setState({ payPhotos });
        }
    };
    


    render() {
        const { payPhotos } = this.state;
        const { payMoneyVoucherModal } = this.props;
        return (

            <Modal
                    visible={payMoneyVoucherModal}
                    onOk={this.payMoneyVoucherModalOk.bind(this)}
                    okText='确定'
                    cancelText='取消'
                    width={400}
                    title="上传凭证"
                    onCancel={this.payMoneyVoucherModalCancel.bind(this)}
                >   
                    <div>
                        <span>请将付款流水凭证图片上传，并且确认已到账</span>
                            <div className="upload-img-list">
                                {payPhotos && payPhotos.map((per, index) => {
                                    return <img src={per.url} key={index} />
                                })}
                                <div className='upload_box'>
                                    <Upload action={API.uploadAdd.url}
                                        beforeUpload={this.handleBeforeUpload}
                                        accept=".png,.jpg"
                                        onChange={this.payMoneyChange}
                                        showUploadList={false}
                                        multiple
                                        listType="picture-card">
                                    </Upload>
                                </div>
                        </div>

                    </div>
                </Modal>

        );
    }
}

const AddTRecord = Form.create({ name: "AddRecord" })(AddRecord);
export default AddTRecord;