import {
    AJAX_TYPE
} from '../config';

// 区域报表

const prefix = 'statementzone';

export default {
    // 下载报表
    [`${prefix}Export`]: {
        url: '/statement/zone/export',
        type: AJAX_TYPE.POST
    },
    // 根据条件查询
    [`${prefix}Get`]: {
        url: '/statement/zone/get',
        type: AJAX_TYPE.POST
    },
}