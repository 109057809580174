import http from '@/http';
import { API } from '@/http/api';
import { formatLinkSearch } from '@/utils'
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Table from 'CMPT/common/Table';
import Select from 'CMPT/common/Select';
import SearchFormLogistics from './searchFormLogistics'
import moment from 'moment';
import { Popconfirm } from 'antd';


// 筛选项默认值
const defaultParams = {
    pageNo: 1, // 需要查询的页数
    pageSize: 10, // 每页条数
    auditObject: 101, // 货源100 运力101
    companyName: '', // 企业名称
    mobile: '', // 手机
    name: '', // 提交人
    auditType: '', // 状态

};
class CapacityAuditList extends Component {
    state = {
        total: 0, // 当前数据的总数
        pageNo: 1, // 当前所展示的页码
        currentPageCount: 0, //当前页面的数量
        selectParams: {  // 接口入参，必须放这里。方便分页时保存信息
            auditStatus: this.props.state,// 审批状态
            ...defaultParams
        },
        columns: [
            {
                title: '名称',
                key: "companyName",
                dataIndex: 'companyName',
            }, {
                title: '运力服务身份',
                key: "driverTypeName",
                dataIndex: 'driverTypeName',
            }, {
                title: '联系人',
                key: "contactsName",
                dataIndex: 'contactsName',
            }, {
                title: '联系方式',
                key: "contactsMobile",
                dataIndex: 'contactsMobile',
            }, {
                title: '单据状态',
                key: "auditStatusName",
                dataIndex: 'auditStatusName',
            }, {
                title: '审批类型',
                key: "auditTypeName",
                dataIndex: 'auditTypeName',
            },{
                title: '提交人',
                key: "applyName",
                dataIndex: 'applyName',
            },{
                title: this.props.state == 0 ? '提交时间' : '审核时间',  // 0 表示待审核状态
                key: this.props.state == 0 ? 'createDate' : 'createDate',
                dataIndex: this.props.state == 0 ? 'createDate' : 'createDate',
                render: (text, record) => {
                    return (<span>{moment(text).format('YYYY-MM-DD HH:mm')}</span>);
                }
            }, {
                title: '操作',
                key: 'operate',
                width: 116,
                render: (text, record, index) => {
                    return (record.auditStatus == 10 ? (
                        <div>
                            <span className="table-operate-btn">
                                <Link to={'/app/crm/sourcing/capacityDetail/' + record.code + "?pageNo=" + this.state.pageNo + "&currentPageCount=" + this.state.currentPageCount} style={{ color: '#2E4AFF' }}>强制下架 </Link>
                            </span>
                            {/* <Popconfirm title="确定强制下架吗?" onConfirm={() => this.handleDelete(record.code)}>
                                <span style={{ color: '#2E4AFF', cursor: 'pointer' }}> 强制下架</span>
                            </Popconfirm> */}
                        </div>
                    ) : (
                        <span className="table-operate-btn">
                            <Link to={'/app/crm/sourcing/capacityDetail/' + record.code + "?pageNo=" + this.state.pageNo + "&currentPageCount=" + this.state.currentPageCount} style={{ color: '#2E4AFF' }}>操作</Link>
                        </span>
                    ))
                    
                }
            }
        ],
        data: [],
    }

    componentWillMount() {
        if (this.props.location) {
            // 获取参数
            const search = this.props.location.search
            const formatData = formatLinkSearch(search)

            // 解析
            let pageNo1 = null;
            if (formatData && formatData.backPageNo) {
                pageNo1 = formatData.backPageNo
            }

            // 赋值
            this.setState({
                selectParams: {
                    auditStatus: this.props.state,// 审批状态
                    pageNo: pageNo1, // 当前页数
                    pageSize: 10, // 每页条数
                }
            });
        }
    }

    //初始化
    componentDidMount() {
        this.getLists();
    }
    //查询列表
    getLists = () => {
        const httpParams = { ...this.state.selectParams };
        for (let [key, value] of Object.entries(httpParams)) {

            if (value === -1 || value === '') {
                //    console.log('[key, value]',[key, value])
                httpParams[key] = undefined;
            }
        }
        const config = {
            api: API.sourcingTcAuditByPage,
            data: { ...httpParams },
            showLoading: true
        };

        http(config).then(res => {
            // console.log(res, 'res1111111111111111111')
            if (res.code == 200) {
                const data = res.data;
                this.setState({
                    data: data.datas || [],
                    total: Number(data.total),
                    pageNo: data.pageNo,
                    currentPageCount: data.datas ? data.datas.length : 0,
                    selectParams: { ...this.state.selectParams, pageNo: data.pageNo }
                });
            }
        })
    }



    //分页
    onChangePage = (pageNo, pageSize, sort) => {
        this.setState({
            selectParams: { ...this.state.selectParams, pageNo: pageNo, pageSize: pageSize }
        }, () => {
            this.getLists()
        })
    }

    // 搜索
    onValuesChange = (allValues) => {
        this.setState({ selectParams: { ...this.state.selectParams, ...allValues } })
    }

    reset = (allValues) => {
        this.setState({
            selectParams: { ...this.state.selectParams, ...defaultParams }
        }, () => {
            this.getLists();
        })
    }

    render() {
        const selectParams = this.state.selectParams
        return (
            <div className='enterprise-auth-list'>
                <div className='searchBox'>
                    <SearchFormLogistics onValuesChange={this.onValuesChange} types={false} reset={this.reset} onSearch={() => { this.getLists() }} />
                </div>
                <Table columns={this.state.columns} rowKey={(record, index) => record.code} selfProcessData={this.state.data} total={this.state.total} change={this.onChangePage} current={selectParams.pageNo} />
            </div>

        );
    }
}

export default CapacityAuditList;