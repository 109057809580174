import React, { Component } from 'react';
import { Layout, Menu, Breadcrumb, Button, Row, Col, Tooltip, Timeline, Modal } from 'antd';
import { Link } from 'react-router-dom'
import { numberFormat } from '@/utils/fliter';
import IntentionTable from './IntentionTable';
import moment from 'moment';
import { industryTypeConfig, areaTypeConfig } from './intentionConfig';
import { channelTypeConfig, sourceTypeConfig, statusConfig, customerTypeConfig } from './customerConfig'
import { customerInit, customerGet } from './service/customerService';
import wordSrc from '@/images/files_word@2x.png';
import pdfSrc from '@/images/files_pdf@2x.png';
import excelSrc from '@/images/files_excel@2x.png';
import pptSrc from '@/images/files_ppt@2x.png';
import txtSrc from '@/images/files_txt@2x.png';
import unknowSrc from '@/images/files_unknown@2x.png';
import Table from 'CMPT/common/Table';
import AssignFollower from './AssignFollower';
import AddFollow from './AddFollow';
import { changeObjkey, getNewPhone } from '@/utils/index'
import {
    AUTH_KEYS
} from '@/utils/auth';
import userUtil from "@/utils/user";
import './CustomerDetail.less';
import { API } from '@/http/api';
import http from '@/http';

const { Header, Content, Footer } = Layout;

class CustomerDetail extends Component {
    state = {
        "encode": null,
        "userName": null,
        "code": null,
        "name": null,
        "customerType": null,
        "mobile": "",
        "status": null,
        "channelType": null,
        "sourceType": null,
        "phone": null,
        "industryType": 1,
        "email": null,
        "website": null,
        "provinceId": null,
        "cityId": null,
        "regionId": null,
        "blockId": null,
        "geoPointAddress": null,
        "address": null,
        "zipCode": null,
        "creditNumber": null,
        "identityNumber": null,
        "remark": null,
        "contactName": null,
        "contactMobile": null,
        "contactEmail": null,
        "contactWechat": null,
        "contactRemark": null,
        "createTime": null,
        "modifiedTime": null,
        "pictureInfoList": [

        ],
        "fileInfoList": [

        ],
        "operateLogList": [

        ],
        assign: {
            visible: false,
            id: [{ encode: this.props.match.params.id }]
        },
        follow: {
            visible: false,
            id: this.props.match.params.id
        },
        showDeleteConfirmFlag: false,
        deleteLoading: false,
        areaData:[],
        proviceName:'',
        cityName:'',
        regonName:''
    }


    componentDidMount() {
        this.loadProvince()
       
    }

    getDetail = () => {
        customerGet({ encode: this.props.match.params.id }).then(res => {
            if (res.code == 0) {
                let data = res.data;
                let proviceName = this.state.areaData.find(item=>{
                    return item.code == data.provinceEncode
                }).name
                let cityData = this.state.areaData.find(item=>{
                    return item.code == data.provinceEncode
                }).subAreas
                let cityName = cityData.find(item=>item.code==data.cityEncode).name
                let regonData = cityData.find(item=>item.code==data.cityEncode).subAreas
                let regonName = regonData.find(item=>item.code == data.regionEncode).name
                this.setState({
                    ...data,
                    assign: {
                        visible: false,
                        id: [{ encode: this.props.match.params.id }]
                    },
                    showDeleteConfirmFlag: false,
                    deleteLoading: false,
                    cityData:cityData,
                    regonData:regonData,
                    proviceName:proviceName,
                    cityName:cityName,
                    regonName:regonName
                }, () => {
                    console.log(res.data.contractStartDate, '------')
                    console.log(res.data.contractEndDate)
                });
            }
        })
    }


    checkEditAuth = (record) => {
        let userInfo = userUtil.get()
        if (!userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_CLIENT_EDIT)) {
            return false
        } else {
            if (userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_CLIENT_EDIT_ALL)) {
                return true
            }
            else if (userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_CLIENT_EDIT_SELF) && userInfo.idCode == record.userEncode) {
                return true
            } else {
                return false
            }
        }
    }

    checkDispatchAuth = record => {
        let userInfo = userUtil.get()
        if (!userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_CLIENT_DISPATCH)) {
            return false
        } else {
            if (userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_CLIENT_DISPATCH_ALL)) {
                return true
            }
            else if (userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_CLIENT_DISPATCH_SELF) && userInfo.idCode == record.userEncode) {
                return true
            } else {
                return false
            }
        }
    }

    checkFollowAuth = record => {
        let userInfo = userUtil.get()
        if (!userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_CLIENT_FOLLOW)) {
            return false
        } else {
            if (userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_CLIENT_FOLLOW_ALL)) {
                return true
            }
            else if (userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_CLIENT_FOLLOW_SELF) && userInfo.idCode == record.userEncode) {
                return true
            } else {
                return false
            }
        }
    }

    assign = () => {
        this.setState({
            assign: {
                visible: !this.state.assign.visible,
                id: [{ encode: this.props.match.params.id }],
                status: this.state.acountStatus,
                companyName: this.state.enterpriseName,
            }
        })
    }

    follow = () => {
        this.setState({
            follow: {
                visible: !this.state.follow.visible,
                id: this.props.match.params.id
            }
        })
    }

    operateLogListPageChange = (page, pageSize) => {
        this.setState({
            current: page,
            pageSize: pageSize
        })
    }

    changeUser = (user) => {
        this.setState({
            userName: user.name
        })
    }

    // 显示删除弹框
    showDeleteConfirm = () => {
        this.setState({
            showDeleteConfirmFlag: true,
        })
    }
    // 删除
    deleteCustomer = () => {
        this.setState({
            deleteLoading: true
        })
        http({
            api: API.customerDelete,
            path: {
                encode: this.props.match.params.id 
            },
            showLoading: true,
            successTip: '删除成功'
        }).then(result => {
            this.setState({
                deleteLoading: false
            })
            if (result.success) {
                this.hideDeleteConfirm();
                this.props.history.push('/app/crm/client')
            }
        }).catch(erorr => {
            this.setState({
                deleteLoading: false
            })
        });

    }
    // 隐藏删除弹框
    hideDeleteConfirm = () => {
        this.setState({
            showDeleteConfirmFlag: false,
            currentCustomerId: {
                current: null
            }
        })
    }

    loadProvince = () => {
        http({
            api: API.areaChinaArea,
            showLoading: false
        }).then(res => {
            if (res.success) {
                this.setState({
                    areaData: res.data
                },()=>{
                    this.getDetail()
                })
            }
        });
    }

    render() {
        const id = this.props.match.params.id;
        let state = this.state;
        // const stateKeys = Object.keys(state);
        // stateKeys.map(item => {
        //     if (state[item] === null || state[item] === '') {
        //         state[item] = '-'
        //     }
        // })
        const dateformat = 'YYYY-MM-DD';
        const datetimeformat = 'YYYY-MM-DD HH:mm';
        const pictureInfoList = state.pictureInfoList.map((item, index) => <Col key={index} span={24}><img style={{ width: '120px', height: '90px' }} src={item.url} alt="" /> </Col>)
        console.log('fileInfoList')
        console.log(state.fileInfoList)
        const fileInfoList = state.fileInfoList.map((item, index) => <Col key={index} span={24}> {item.mediaExt == 'jpg' || item.mediaExt == 'png' ? <a className="file-link" target="_blank" href={item.url}> <img style={{ width: '120px', height: '90px' }} src={item.url} alt="" /> </a> : <a className="file-link" target="_blank" href={item.url}> <img src={item.mediaExt == 'doc' || item.mediaExt == 'docx' ? wordSrc : item.mediaExt == 'xlsx' ? excelSrc : item.mediaExt == 'jpg' || item.mediaExt == 'png' ? item.url : unknowSrc} alt="" /></a>} {item.name} </Col>)
        let tempOperateLogList = JSON.parse(JSON.stringify(state.operateLogList));
        console.log((state.current - 1) * state.pageSize, state.current * state.pageSize <= state.operateLogList.length ? state.current * state.pageSize : state.operateLogList.length);
        const operateLogList = tempOperateLogList.splice((state.current - 1) * state.pageSize, state.current * state.pageSize <= state.operateLogList.length ? state.current * state.pageSize : state.operateLogList.length);
        console.log(state.current, tempOperateLogList.length, (state.current - 1) * 10, state.current * 10 <= state.operateLogList.length ? state.current * 10 : state.operateLogList.length);
        const tableconfig = {
            rowKey: 'encode',
            data: operateLogList,
            current: state.current,
            pageSize: state.pageSize,
            total: state.total,
            change: this.operateLogListPageChange
        }
        return (
            <Content className="intention-list intention-content">
                <div className="intention-list-head flexbox">
                    <Breadcrumb className="intention-list-head-bread">
                        <Breadcrumb.Item>
                            <Link style={{ color: '#333' }} to={{ pathname: "/app/crm/client" }}>
                                客户管理
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            客户详情
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <ul className="intention-list-head-data data-detail">
                        <li className="data-li"> <Button type="danger" onClick={this.showDeleteConfirm}>删除</Button> </li>
                        <li className="data-li"> <Button type={this.state.acountStatus ? "danger" : "primary" } onClick={this.assign}>{this.state.acountStatus ? '停用' : '启用'}</Button> </li>
                        <li className="data-li "> <Button type="link" onClick={() => this.props.history.push('/app/crm/client/edit/' + id)}>编辑</Button>   </li>
                    </ul>
                </div>
                <Row gutter={24} className="customer-detail-content">
                    <Col span={16} className="customer-detail-left">
                        <div className="customer-detail-body">
                            <label>
                                账号信息
                            </label>
                            <div>
                                <Row gutter={20}>
                                    <Col className="gutter-row" span={12}>
                                        公司码：{state.companyId}
                                    </Col>
                                    <Col className="gutter-row" span={12}>
                                        类型：{customerTypeConfig[state.acountType] || '-'}
                                    </Col>
                                </Row>
                                <Row gutter={20}>
                                    <Col className="gutter-row" span={12}>
                                        状态：{statusConfig[state.acountStatus]}
                                    </Col>
                                    <Col className="gutter-row" span={12}>
                                        管理员手机号：{getNewPhone(state.adminPhone)}
                                    </Col>
                                </Row>
                                <Row gutter={20}>
                                    <Col className="gutter-row" span={12}>
                                        管理员邮箱：{state.adminEmail}
                                    </Col>
                                    <Col className="gutter-row" span={12}>
                                        管理员身份证号：{state.adminIdentityCard}
                                    </Col>
                                </Row>
                                <Row gutter={20}>
                                    <Col className="gutter-row" span={12}>
                                        创建人：{state.createName}
                                    </Col>
                                    <Col className="gutter-row" span={12}>
                                        创建时间：{state.createTime ? moment(state.createTime).format('YYYY/MM/DD HH:MM') : '-'}
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div className="customer-detail-body">
                            <label>企业信息</label>
                            <div>
                                <Row gutter={20}>
                                    <Col className="gutter-row" span={12}>
                                        企业名称：{state.enterpriseName}
                                    </Col>
                                    <Col className="gutter-row" span={12}>
                                        统一社会信用代码：{state.creditNumber}
                                    </Col>
                                </Row>
                                <Row gutter={20}>
                                    <Col className="gutter-row" span={24}>
                                        总部地址： {  state.proviceName + state.cityName + state.regonName + state.address}
                                    </Col>
                                </Row>
                                <Row gutter={20}>
                                    <Col className="gutter-row" span={12}>
                                        联系人：{state.contactName}
                                    </Col>
                                    <Col className="gutter-row" span={12}>
                                        联系方式：{state.contactPhone}
                                    </Col>
                                </Row>
                                <Row gutter={20}>
                                    <Col className="gutter-row" span={24}>
                                        项目数量：{state.projectNumber}
                                    </Col>
                                </Row>
                                <Row gutter={20}>
                                    <Col className="gutter-row" span={24}>
                                        企业简介：{state.enterpriseDesc}
                                    </Col>
                                </Row>
                                <Row gutter={20}>
                                    <Col className="gutter-row" span={24}>
                                        营业执照 :
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="gutter-row gutter-row-pic" span={4}>
                                        {pictureInfoList}
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div className="customer-detail-body">
                            <label>
                                签约信息
                            </label>
                            <div>
                                <Row>
                                    <Col className="gutter-row" span={12}>
                                        {console.log(state.contractStartDate, '=====')}
                                        合同日期：{state.contractStartDate ? moment(state.contractStartDate).format('YYYY/MM/DD') : ''}~{state.contractEndDate == null ? '' : moment(state.contractEndDate).format('YYYY/MM/DD')}
                                    </Col>
                                    <Col className="gutter-row" span={12}>
                                        签约时间：{state.signDate != null ? moment(state.signDate).format('YYYY/MM/DD') : '-'}
                                    </Col>
                                </Row>
                                <Row >
                                    <Col className="gutter-row" span={24}>
                                        合同附件 :
                                    </Col>
                                </Row>
                                <Row >
                                    <Col className="gutter-row gutter-row-file" span={4}>
                                        {fileInfoList}
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    <Col span={8} className="customer-detail-right">
                        <div className="customer-detail-body">
                            <label>修改记录</label>
                            <div style={{ paddingTop: '20px', paddingBottom: '48px' }}>
                                {state.logListVo && state.logListVo.length > 0 ?
                                    <Timeline>
                                        {
                                            state.logListVo.map((item, index) =>
                                                <Timeline.Item key={index} span={24}>
                                                    <div>{moment(item.createTime).format('YYYY-MM-DD HH:mm')}</div>
                                                    <div>操作人：{item.createBy}</div>
                                                    {
                                                        item.childList.map((child, index) =>
                                                            <div>修改【{child.desc}】：{child.afterValue}</div>
                                                        )
                                                    }
                                                </Timeline.Item>
                                            )
                                        }
                                    </Timeline>
                                    :
                                    <div className='log-no-data'><div className="log-no-data-text">暂无数据</div></div>
                                }
                                {/* <Table  {...tableconfig} /> */}

                            </div>
                        </div>
                    </Col>
                </Row>

                <AssignFollower visible={this.state.assign.visible} id={this.state.assign.id} status={this.state.assign.status} companyName={this.state.assign.companyName} assignCancel={this.assign} batch={false} getDetail={this.getDetail} isCustomer={true} />
                {/* {userUtil.hasAuth(AUTH_KEYS.OPERATE_INVITATION_CLIENT_DISPATCH) ? <AssignFollower visible={this.state.assign.visible} id={this.state.assign.id} assignCancel={this.assign} batch={false} getCustomerList={this.changeUser} isCustomer={true} /> : null} */}
                {/* <AddFollow intentionStatus={this.state.intentionStatusConfigArray} followupType={this.state.followupTypeConfigArray} followupFlag={this.state.followupFlagConfigArray} lostReasonType={this.state.lostReasonTypeConfigArrray} visible={this.state.follow.visible} id={this.state.follow.id}  getIntentionList={this.getDetail} followCancel={this.follow} isCustomer={false}/> */}

                <Modal
                    title={"确认删除" + this.state.enterpriseName + "的企业账号？"}
                    content=""
                    visible={this.state.showDeleteConfirmFlag}
                    okText='删除'
                    cancelText='保留'
                    confirmLoading={this.state.deleteLoading}
                    closable={false}
                    maskClosable={false}
                    onOk={this.deleteCustomer}
                    width={400}
                    onCancel={this.hideDeleteConfirm}
                >
                    <p style={{ fontSize: '12px' }}>删除后，该公司码下管理员账号及所有子账号将不能使用</p>
                </Modal>
            </Content>
        )
    }

}

export default CustomerDetail;