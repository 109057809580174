import React, { Component } from 'react';
import MissionList from './MissionList'
import { Input, DatePicker, Icon } from 'antd';
import NoJob from './NoJob'
import { taskCancel, taskSwitchStatus } from './service/taskService'

const Search = Input.Search;
const { RangePicker } = DatePicker;

class DateWork extends Component {
    state = {
        beginDate: null,
        endDate: null,
        hadDate: false,
        keyWords: null,
        title: '日程',
        rangedate: [],
        umCompleteCollapse: true,
        completeCollapse: true,
        screenHeight:(window.innerHeight || window.screen.availHeight || window.screen.height || 700) * 0.7
    }

    umCompleteCheckItem = (v) => {
        taskSwitchStatus({ code: v }).then(res => {
            this.props.getUmCompleteList();
        })
    }

    completeCheckItem = (v) => {
        taskSwitchStatus({ code: v }).then(res => {
            this.props.getCompleteList();
        })
    }

    umCompleteCeleteItem = (v) => {
        taskCancel({ code: v }).then(res => {
            this.props.getUmCompleteList();
        })
    }

    completeCeleteItem = (v) => {
        taskCancel({ code: v }).then(res => {
            this.props.getCompleteList();
        })
    }

    editItem = (v) => {
        this.props.editItem(v)
    }

    changeDate = (e) => {
        this.setState({
            beginDate: e[0].format('YYYY-MM-DD'),
            endDate: e[1].format('YYYY-MM-DD'),
            hadDate: true
        }, () => {
            let data = {
                beginDate: this.state.beginDate,
                endDate: this.state.endDate,
                keyWords: this.state.keyWords
            }
            this.props.onChange(data)
        })
    }
    cancelDate = (e) => {
        this.setState({
            beginDate: null,
            endDate: null,
            hadDate: false
        }, () => {
            let data = {
                beginDate: this.state.beginDate,
                endDate: this.state.endDate,
                keyWords: this.state.keyWords
            }
            this.props.onChange(data)
        })
    }

    changeUmCompeleteCollapse = () => {
        this.setState({
            umCompleteCollapse: !this.state.umCompleteCollapse
        })
    }

    changeCompeleteCollapse = () => {
        this.setState({
            completeCollapse: !this.state.completeCollapse
        })
    }

    searchWord = value => {
        this.setState({
            keyWords: value
        }, () => {
            let data = {
                beginDate: this.state.beginDate,
                endDate: this.state.endDate,
                keyWords: this.state.keyWords
            }
            this.props.onChange(data)
        })
    }

    getMoreUmComplete = () => {
        this.props.getMoreUmComplete()
    }

    getMoreComplete = () => {
        this.props.getMoreComplete()
    }

    scrollFun = () => {
        const workList = document.getElementById('workList')
        const umCompleteMission = document.getElementById('umCompleteMission')
        const umCompleteMissionTitle = document.getElementById('umCompleteMissionTitle')
        const completeMission = document.getElementById('completeMissionTitle')
        const completeMissionTitle = document.getElementById('completeMissionTitle')
        let workListScrollTop = workList.scrollTop;
        let umCompleteMissionClientHeight = umCompleteMission.clientHeight
        let completeMissionClientHeight = completeMission.clientHeight
        if (workListScrollTop > 0) {
            workList.style.paddingTop = '40px';
            umCompleteMissionTitle.style.position = 'fixed';
            umCompleteMissionTitle.style.top = '222px'
            umCompleteMissionTitle.style.width = workList.clientWidth + 'px'
        } else {
            workList.style.paddingTop = '0px';
            umCompleteMissionTitle.style.position = 'static';
            umCompleteMissionTitle.style.top = '0px'
            umCompleteMissionTitle.style.width = 'auto'
        }
        if (workListScrollTop >= umCompleteMissionClientHeight) {
            completeMissionTitle.style.position = 'fixed';
            completeMissionTitle.style.top = '222px';
            completeMissionTitle.style.zIndex = '99'
            completeMissionTitle.style.width = workList.clientWidth + 'px'
        } else {
            completeMissionTitle.style.position = 'static';
            completeMissionTitle.style.top = '0px';
            completeMissionTitle.style.zIndex = '9'
            completeMissionTitle.style.width = 'auto'
        }
    }


    resizeListener = ()=>{
        this.setState({
            screenHeight:(window.innerHeight || window.screen.availHeight || window.screen.height || 700) * 0.7
        })
    }

    componentDidMount() {
        const workList = document.getElementById('workList')
        window.addEventListener('resize',this.resizeListener)
        workList.addEventListener('scroll', this.scrollFun);
    }
    componentWillUnmount() {
        const workList = document.getElementById('workList')
        workList&&workList.removeEventListener('scroll',this.scrollFun);
        window.removeEventListener('resize',this.resizeListener)
    }

    render() {
        const screenHeight = this.state.screenHeight;
        const { complete,umCompleteList} = {...this.props.pageData}
        const listHtml = (this.props.umCompleteList.length + this.props.completeList.length) > 0 ? <div id="workList" style={{ maxHeight: (screenHeight - 112) + 'px', overflowY: 'auto', position: 'relative', overflowX: 'hidden' }}>
            <MissionList idName="umCompleteMission" title="未完成日程" data={this.props.umCompleteList} checkItem={this.umCompleteCheckItem} deleteItem={this.umCompleteCeleteItem} editItem={this.editItem} operate={true} collapse={this.state.umCompleteCollapse} onCollapse={this.changeUmCompeleteCollapse} isMore={umCompleteList.pageNo<umCompleteList.total} getMore={this.getMoreUmComplete} />
            <MissionList idName="completeMission" title="已完成日程" data={this.props.completeList} checkItem={this.completeCheckItem} deleteItem={this.completeCeleteItem} editItem={this.editItem} operate={true} collapse={this.state.completeCollapse} onCollapse={this.changeCompeleteCollapse} isMore={complete.pageNo< complete.total } getMore={this.getMoreComplete} />
        </div> : <div id="workList" style={{ maxHeight: (screenHeight - 208) + 'px', overflowY: 'auto', position: 'relative', overflowX: 'hidden' }}> <NoJob label="无日程" />  </div> 
        return (
            <div className="today-mission">
                <div className="mission-search">
                    <Search
                        placeholder="输入关键字查询"
                        onSearch={this.searchWord}
                        style={{ width: '250px' }}
                    />
                </div>
                <div className="mission-datepicker">
                    <p className="mission-date-text">
                        <Icon type="calendar" />
                        <span style={this.state.hadDate ? null : { display: 'none' }}>
                            {this.state.beginDate} - {this.state.endDate}
                        </span>
                        <span type="close-circle" theme="filled" style={this.state.hadDate ? null : { display: 'none' }} onClick={this.cancelDate}></span>
                    </p>
                    <RangePicker id="datepick" onChange={this.changeDate} className="mission-date-picker" />
                </div>
                {listHtml}
            </div>
        )
    }
}

export default DateWork
