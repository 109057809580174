import React, { Component } from 'react';
import { Upload, message, Icon, Tooltip,Row, Col } from 'antd';
import axios from 'axios';
import wordSrc from '@/images/files_word@2x.png';
import pdfSrc from '@/images/files_pdf@2x.png';
import excelSrc from '@/images/files_excel@2x.png';
import pptSrc from '@/images/files_ppt@2x.png';
import txtSrc from '@/images/files_txt@2x.png';
import unknowSrc from '@/images/files_unknown@2x.png';

//由于附件需要上传的文件比较多，特地封装一下，无特殊需求的 antd的upload应该可以满足需求
const fileTypeArray = ['rar', 'zip', 'doc', 'docx', 'xlsx', 'image/jpeg', 'jpg', 'png']
const hasPicFile = ['doc', 'docx', 'xlsx']

class fileUploadSecret extends Component {

    state = {
        fileList: []
    }


    beforeUpload = (file) => {
        // console.log(file);
        const fileNameToArray = file.name.split('.');
        const fileType = fileNameToArray[fileNameToArray.length - 1];
        // console.log(fileType);
        const isFile = !!fileTypeArray.find(item => item == fileType)
        if (!isFile) {
            message.error('只支持' + fileTypeArray.join() + '文件');
        }
        const isLt5M = file.size / 1024 / 1024 <= 5;
        if (!isLt5M) {
            message.error('文件大小不能超过5MB');
        }
        return isFile && isLt5M;
    }

    doUploadFile(value) {

        const fromData = new FormData()
        fromData.append('file', value.file)
        const config = {
            onUploadProgress: progressEvent => {
                value.onProgress(progressEvent.loaded / progressEvent.total * 100 | 0);
            },
            headers: {
                ...value.headers,
                'Content-Type': 'multipart/form-data'
            }
        }
        axios.post(value.action, fromData, config)
            .then(res => {
                let file = res.file;
                let fileList = this.state.fileList;
                let fileInfo = {
                    mediaExt: file.sExt,
                    mediaKey: file.sKey,
                    name: file.sFilename,
                    type: file.sExt,
                    url: file.sExt == 'doc' || file.sExt == 'docx' ? wordSrc : file.sExt == 'xlsx' ? excelSrc : unknowSrc
                }
                fileList.push(fileInfo)
                this.setState({
                    fileList: fileList
                },()=>this.fileUp())
                value.onSuccess(res);
            }).catch(err => {
                value.onError(err);
            });
    }

    previewFile = value => {
        console.log('preview');
        console.log(value);
    }

    deleteFile = index => {
        let fileList = this.state.fileList;
        fileList.splice(index,1)
        this.setState({
            fileList:fileList
        },()=>this.fileUp())
    }

    fileUp = ()=>{
        this.props.fileUp(this.state.fileList)
    }
    render() {
        const uploadButton = (
            <div>
                <Icon style={{ fontSize: '42px' }} type={this.state.loading ? 'loading' : 'to-top'} />
                <div className="ant-upload-text">
                    拖拽至此 或 点击上传
              </div>
            </div>
        );
        const fileList = this.state.fileList;
        const previewList =fileList.map((item, index) => <Col className="gutter-row" span={4}>
                <img src={item.url} />
                <p>{item.name.length > 5 ? <Tooltip placement="top" title={item.name}>{item.name.slice(0, 5)}...</Tooltip> : item.name}<Icon onClick={this.deleteFile(index)} type="delete" /></p>
            </Col>)
            
  
        return (
            <Row gutter={80}>
                <Col className="gutter-row" span={4}>
                    <Upload
                        listType="picture-card"
                        beforeUpload={this.beforeUpload}
                        action={process.env.IMG_UPLOAD + "secret.html"}
                        customRequest={this.doUploadFile.bind(this)}
                        showUploadList={false}
                    >
                        {uploadButton}
                    </Upload>
                </Col>
                {previewList}
            </Row>
        )
    }
}

export default fileUploadSecret
