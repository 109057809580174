import { AJAX_TYPE } from "../config";

// 供应商

const prefix = "newHelp";

export default {
  // 查询列表
  [`${prefix}List`]: {
    url: "/web/helpCatalog/getHelpCatalogListByPage",
    type: AJAX_TYPE.POST
  },
  [`${prefix}CatalogDel`]: {
    url: "/web/helpCatalog/deleteCatalog",
    type: AJAX_TYPE.POST
  },
  [`${prefix}CatalogAdd`]: {
    url: "/web/helpCatalog/addCatalog",
    type: AJAX_TYPE.POST
  },
  [`${prefix}CatalogEdit`]: {
    url: "/web/helpCatalog/updateCatalog",
    type: AJAX_TYPE.POST
  }
};
