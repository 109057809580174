import React from 'react';
import './DisplayAuthRow.less'

export default function DisplayAuthRow({ authMap, authCode }) {
    const auth = authMap[authCode];
    if (auth) {
        const subAuthCodes = auth.subs;
        let hasNoGrandChildren = true;
        let children;
        if (subAuthCodes && subAuthCodes.length) {
            // 下级权限
            children = subAuthCodes.map(subAuthCode => {
                const subAuth = authMap[subAuthCode];
                if (subAuth.subs && subAuth.subs.length) {
                    hasNoGrandChildren = false;
                }

                return <DisplayAuthRow authMap={authMap} authCode={subAuthCode} key={subAuthCode} />;
            });
        }

        const authScope = auth.scope;
        let scopeSelect;
        if (authScope && authScope.length) {
            // 数据权限
            const scopeValue = auth.scopeValue;
            scopeSelect = '';
            for (const { value, desc } of authScope) {
                if (value === scopeValue) {
                    scopeSelect = '（' + desc + '）';
                    break;
                }
            }
        }


        return (
            <div className={`display-auth-row level-${auth.level}`}>
                <span>{auth.desc}</span>
                {scopeSelect}
                {children && <div className={'sub-auth ' + (hasNoGrandChildren ? 'flex' : 'row')}>{children}</div>}
            </div>
        );
    }
    return null;
}