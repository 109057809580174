import React, { Component, useRef, useCallback } from "react";
import { Breadcrumb, Input, Button, Tree, Modal, message,Radio, TreeSelect } from "antd";
import Select from 'CMPT/common/Select'
import "./BackendCategory.less";
import CategorySort from './BackendCategorySort';
import http from '@/http';
import { API } from '@/http/api';
import Table from 'CMPT/common/Table';
import { Link } from 'react-router-dom';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import  HTML5Backend  from 'react-dnd-html5-backend';
import { ServiceTelType } from './enum'

import userUtils from '@/utils/user';
import { AUTH_KEYS } from "@/utils/auth";
import { number } from "prop-types";
const { checkAuthByKey } = userUtils;
const addAuth = checkAuthByKey(AUTH_KEYS.GOODS_CATEGORY_MANAGE_AFTER_MANAGE_ADD); // 新增
const editAuth = checkAuthByKey(AUTH_KEYS.GOODS_CATEGORY_MANAGE_AFTER_MANAGE_CHANGE); // 编辑
const statusChangeAuth = checkAuthByKey(AUTH_KEYS.GOODS_CATEGORY_MANAGE_AFTER_MANAGE_STATUS_CHANGE); // 启用/禁用
const deleteAuth = checkAuthByKey(AUTH_KEYS.GOODS_CATEGORY_MANAGE_AFTER_MANAGE_DELETE); // 删除


const BreadcrumbItem = Breadcrumb.Item;
const { TreeNode } = Tree;
// const { TreeNode } = TreeSelect;



class BackendCategory extends Component {
    state = {
        serviceTelEditObj:undefined,//用来修改客服设置的对象object.会从接口数据，拷贝一部分
        autoExpandParent: true,
        expandedKeys: [],
        checkedKeys: [],
        selectedKeys: ['qeZDWfgpvSs'],
        treeData: [],
        top2Data: [], // 1, 2 级数据
        cards: [],
        total: 0,

        sortStateChild: null,

        // 禁用/启用类目
        isShowStatusModel: false,
        isEnableCategory: false,
        selectedThirdLevelCode: null,

        // 删除类目
        isShowDeleteModel: false,
        selectedDeleteCode: null,

        // 排序
        isShowSortModel: false,
        // 排序数据源
        sorts: [],

        params: {
            pageNo: 1,
            pageSize: 10,
            name: undefined,                    // 类目名称
            sortType: 1,                 // 1. 时间倒序 2. 排序值正序
            level: undefined,            // 类目级别
            status: undefined,           // 类目状态
            parentCode: 'qeZDWfgpvSs',            // 上级类目code
        },

        levelName: undefined,
        categoryTypes: [
            {value: '', name: '全部'},
            { value: 1, name: '一级类目' },
            { value: 2, name: '二级类目' },
            { value: 3, name: '三级类目' }
        ],

        categoryStates: [
            {value: '', name: '全部'},
            { value: 0, name: '禁用' },
            { value: 1, name: '启用' }
        ],
        columns:[],// 要使用的字段集合

        allColumns: [ // 全部字段集合，会根据类目进行筛选
            {
                title: '类目名称',
                dataIndex: 'categoryName',
                render: (value) => {
                    return (<div> {value.length > 15 ? `${value.substr(0, 15)}...` : value}</div>);
                }
            }, {
                title: '状态',
                dataIndex: 'status',
                render: (value) => {
                    return (<div> {value == 1 ? '启用' : '禁用'}</div>);
                }
            }, {
                title: '使用商品数',
                dataIndex: 'useCount',
                render: (value) => {
                    return (<div> {value || 0}</div>);
                }
            }, {
                title: '操作人',
                dataIndex: 'updateUser',
                render: (value) => {
                    return (<div> {value || '--'}</div>);
                }
            }, {
                title: '操作日期',
                dataIndex: 'updateTime',
                render: (value) => {
                    return (<div> {value || '--'}</div>);
                }
            }, {
                title: '类目级别',
                dataIndex: 'level',
                render: (value) => {
                    return (<div> {['一级类目', '二级类目', '三级类目'][value - 1]}</div>);
                }
            }, {
                title: '类目描述',
                dataIndex: 'remark',
                render: (value) => {
                    return (<div> {(value == null || value == '') ? '--' : (value.length > 15 ? `${value.substr(0, 15)}...` : value)}</div>);
                }
            }, {
                title: '客服配置',
                dataIndex: 'serviceTelType',
                render: (text, record) => {
                    return (<span className="table-operate-btn operate" onClick={this.showServiceTelDialog.bind(this, record)}>{ ServiceTelType.getTextFromValue(text) || '-'}</span>);
                }
            },{
                title: '操作',
                key: 'operate',
                render: (text, record, index) => {
                    if (record.level == 1) {
                        return (
                        <div>
                            {
                                editAuth?
                                <span className="table-operate-btn">
                                    <Link to={`/app/crm/category/backend/edit?code=${record.code}`} className="operate">修改</Link>
                                </span>
                                : null
                            }
                            {
                                deleteAuth?
                                <span className="table-operate-btn operate" onClick={this.confirmDelete.bind(this, record)}>删除</span>
                                : null
                            }
                        </div>)
                    } else if (record.level == 2) {
                        return (
                        <div>
                            {
                                editAuth?
                                <span className="table-operate-btn">
                                    <Link to={`/app/crm/category/backend/edit?code=${record.code}`} className="operate">修改</Link>
                                </span>
                                : null
                            }
                            {
                                deleteAuth?
                                <span className="table-operate-btn operate" onClick={this.confirmDelete.bind(this, record)}>删除</span>
                                : null
                            }
                        </div>)
                    } else {
                        return (
                        <div>
                            {
                                editAuth?
                                <span className="table-operate-btn">
                                    <Link to={`/app/crm/category/backend/edit?code=${record.code}`} className="operate">修改</Link>
                                </span>
                                : null
                            }
                            {
                                statusChangeAuth?
                                <span className="table-operate-btn operate" onClick={this.confirmStatus.bind(this, record)}>{record.status == 1 ? '禁用' : '启用'}</span>
                                : null
                            }
                            {
                                deleteAuth?
                                <span className="table-operate-btn operate" onClick={this.confirmDelete.bind(this, record)}>删除</span>
                                : null
                            }
                            {
                                deleteAuth && Number(record.useCount) > 0?
                                <span className="table-operate-btn operate" onClick={this.goodsTransfer.bind(this, record)}>商品转移</span>
                                : null
                            }
                        </div>)
                    }
                }
            }
        ],
        transforFromLevel3Code: null,
        selectedLevel3NodeModal: false,
        transforToLevel3Code: null
    }

    confirmStatus = (record) => {
        this.setState({ selectedThirdLevelCode: record.code, isEnableCategory: record.status == 0, isShowStatusModel: true });
    }
    // 删除类目
    confirmDelete = (record) => {
        if(record.level == 1) {
            let canDelete = true;
            this.state.treeData.forEach(item => {
                item.childList && item.childList.forEach(level1 => {
                    if (level1.code == record.code && level1.childList && level1.childList.length > 0) {
                        canDelete = false;
                    }
                })
            })
            if (!canDelete) {
                message.error('该类目下存在子类目，不允许删除')
                return false;
            }
        } else if(record.level == 2) {
            //删除二级类目时，判断是否有子类，有子类不允许删除
            let canDelete = true;
            this.state.treeData.forEach(item => {
                item.childList && item.childList.forEach(level1 => {
                    level1.childList && level1.childList.forEach(level2 => {
                        if (level2.code == record.code && level2.childList && level2.childList.length > 0) {
                            canDelete = false;
                        }
                    })
                })
            })
            if(!canDelete) {
                message.error('该类目下存在子类目，不允许删除')
                return false;
            }
        } else if(record.level == 3) {
            //删除三级类目时，判断该类目下商品的使用树是否为0
            if(record.useCount) {
                message.error('该类目使用商品数不为0，不允许删除')
                return false;
            }
        }
        this.setState({ selectedDeleteCode: record.code, isShowDeleteModel: true });
    }
    // 商品转移
    goodsTransfer = (record) => {
        this.setState({transforFromLevel3Code: record.code, selectedLevel3NodeModal: true})
    }

    // 找出所有level为1，2的node
    getTop2Nodes(list, result) {
        for (var i in list) {
            let item = list[i]

            if (item.level == 1 || item.level == 2) {
                result.push(item)
            }

            if (item.childList) {
                this.getTop2Nodes(item.childList, result)
            }
        }
    }

    confirmSort = (record) => {
        this.queryById(record.code);
    }

    handleStatusOk() {
        http({
            api: this.state.isEnableCategory ? API.categoryOpen : API.categoryClose,
            successTip: this.state.isEnableCategory ? "启用成功！" : "禁用成功",
            data: {
                code: this.state.selectedThirdLevelCode
            },
            showLoading: true
        }).then(res => {
            if (res.success) {
                this.setState({
                    isShowStatusModel: false
                }, () => {
                    this.loadData()
                })
            }
        })
    }
    handleStatusCancel() {
        this.setState({ isShowStatusModel: false })
    }

    handleDeleteOk() {
        http({
            api: API.categoryDelete,
            successTip: "删除成功",
            data: {
                code: this.state.selectedDeleteCode
            },
            showLoading: true
        }).then(res => {
            if (res.success) {
                this.setState({
                    isShowDeleteModel: false
                }, () => {
                    this.loadData()
                })
            }
        })
    }

    handleServiceTelOk=()=>{
        let {serviceTelEditObj}=this.state;//这个是复制出来的
        
        http({
            api:   API.itemEditServiceTel,
            data: {
                code: serviceTelEditObj.code,
                serviceTelType:serviceTelEditObj.serviceTelType
            },
            showLoading: true
        }).then(res => {
            if (res&&res.success) {
                this.handleServiceTelCancel();
                this.queryCrmCategoryList();//不修改参数，仅仅刷新
            }
        })

    }

    onServiceTelChange=(e)=>{
        let {serviceTelEditObj} = this.state;// 这个对象是复制出来的，不影响原始记录
         serviceTelEditObj.serviceTelType=  e.target.value;
         this.setState({serviceTelEditObj});
    }

    handleServiceTelCancel(){
        this.setState({ serviceTelEditObj: undefined })
    }

    showServiceTelDialog(record){
        this.setState({ serviceTelEditObj: {code:record.code,serviceTelType:record.serviceTelType} })// 复制出一个对象，不要影响 原有的对象
    }

    handleDeleteCancel() {
        this.setState({ isShowDeleteModel: false })
    }

    handleSortOk() {
        if (this.state.sortStateChild == null) {
            return;
        }

        this.state.sortStateChild.doChangeSortState()
    }

    handleSortCancel() {
        this.setState({ isShowSortModel: false })
    }

    onSortStateRef = (ref) => {
        this.setState({
            sortStateChild: ref
        })
    }

    handlerStateChanged = () => {
        this.handleSortCancel()
        this.queryCrmCategoryList()
    }

    setKeyWord = (e) => {
        let params = this.state.params
        params.name = e.target.value
        this.setState({
            params
        })
    }

    keyWordSearch = (value) => {
        let params = this.state.params
        params.pageNo = 1
        params.sortType = 1
        params.name = value;
        this.setState({
            params
        }, this.queryCrmCategoryList)
    }

    selectChange = (attr, data) => {
        let params = this.state.params
        params[attr] = data
        params.sortType = 2
        params.pageNo = 1
        if(attr == 'level') {
            params.parentCode = undefined
            this.setState({
                levelName: data,
                selectedKeys: []
            })
        }
        this.setState({ params }, this.queryCrmCategoryList)
    }

    loadData = () => {
        this.queryCategoryTree();
        this.queryCrmCategoryList();
    }

    componentWillMount() {
        this.loadData()
    }

    queryCategoryTree = () => {
        http({
            api: API.categoryTree,
            showLoading: true
        }).then(res => {
            if (res.success) {
               // let top2Data = [];
               // this.getTop2Nodes(res.data, top2Data)
               let allLevel = [{
                code: 'qeZDWfgpvSs',
                name: '全部一级类目',
                childList: res.data
               }]
               
                this.setState({
                    treeData: allLevel,                  
                   // expandedKeys: res.data.map((item) => item.code),
                    expandedKeys: ['qeZDWfgpvSs']
                   // expandedKeys: allLevel[0].childList.map((item) => item.code)
                   // top2Data
                })
            }
        })
    }

    queryById = (code) => {
        http({
            api: API.categoryQueryById,
            path: {
                code: code
            },
            showLoading: true
        }).then(res => {
            if (res.success) {

                let source = res.data || [];
                if (source.length <= 1) {
                    message.info('无可排序类目');
                    return;
                }

                let sorts = source.map((item, index) => {
                    return {
                        number: index,
                        code: item.code,
                        categoryIndex: index,
                        categoryName: item.name
                    }
                })

                this.setState({
                    sorts,
                    isShowSortModel: true
                })
            }
        })
    }

    queryCrmCategoryList = () => {
        http({
            api: API.categoryList,
            data: this.state.params,
            showLoading: true
        }).then(res => {
            if (res.success) {
                 let cards= res.data.datas || [];
                 /* start:只有3级类目才增加客服配置  */
                 let {allColumns, params }=  this.state;
                 let columns = undefined;
                 if(!cards.length || cards[0].level!=3){ // 第一个元素不是3级数据，那么其他也不是. 没有有效数据，那也不展示客服电话
                    columns = allColumns.filter((item)=>{
                           return item.dataIndex!='serviceTelType';
                    });
                 }else{
                    columns = allColumns;
                 }
                /* end:只有3级类目才增加客服配置  */


                this.setState({
                    columns,
                    cards ,
                    total: res.data.totalRecord
                })
            }
        })
    }

    onExpand = expandedKeys => {
        this.setState({
            expandedKeys,
            autoExpandParent: false,
        });
    }

    onCheck = checkedKeys => {
        this.setState({ checkedKeys });
    }

    onSelect = (selectedKeys, {selected: bool, selectedNodes, node, event}) => {
        let level = node.props.dataRef.level
        let params = this.state.params
        params.pageNo = 1
        params.sortType = 2
        params.parentCode = selectedKeys[0]
        params.level = undefined
        //查询3级目录，需要传level
        if(level == 3) {
            params.level = 3
        }
        this.setState({
            params,
            levelName: undefined,
            selectedKeys
        }, this.queryCrmCategoryList)
    }

    // 只展示一、二级
    renderTreeNodes = data =>
        data.map(item => {
            if (item.childList) {
                return (
                    <TreeNode title={item.name} key={item.code} dataRef={item}>
                        {this.renderTreeNodes(item.childList)}
                    </TreeNode>
                );
            }
            return <TreeNode title={item.name} key={item.code} dataRef={item} />;
        })

    onChangePage = (pageNo, pageSize, sort) => {
        let params = this.state.params
        params.pageNo = pageNo
        params.pageSize = pageSize
        this.setState({
            params
        }, this.queryCrmCategoryList)
    }
    // 选择三级类目，点击确定
    selectedLevel3NodeModalOk = () => {
        let {transforToLevel3Code, transforFromLevel3Code} = this.state;
        if (!transforToLevel3Code) {
            message.warning('请选择分类')
            return false;
        }
        // TODO
        http({
            api: API.categoryTransfer,
            data: {
                code: transforFromLevel3Code,
                afterCode: transforToLevel3Code
            },
            showLoading: true
        }).then(res => {
            if (res.success) {
                message.success('转移成功')
                this.setState({ selectedLevel3NodeModal: false })
            }
        })
    }
    // 选择三级类目，点击取消
    selectedLevel3NodeModalCancel = () => {
        this.setState({ selectedLevel3NodeModal: false })
    }
    // 选择三级类目
    selectTreeLevel3 = (level3Code) => {
        console.log(level3Code, 'level3Code')
        this.setState({transforToLevel3Code: level3Code})
    }

    categoryMove = (dragIndex, hoverIndex, cb) => {
        const params = {
            code: this.state.cards[dragIndex].code,
            afterCode: this.state.cards[hoverIndex].code,
        }
        http({
            api: API.categoryMove,
            data: params,
            showLoading: true
        }).then(res => {
            if (res.success) {
                message.success('排序成功')
                cb && cb()
            }
        })
    }

    render() {

        const { params, levelName, treeData } = this.state;

        const dragType = 'DraggableBodyRow';

        const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
            const ref = useRef();
            const [{ isOver, dropClassName }, drop] = useDrop({
                accept: dragType,
                collect: monitor => {
                    const { index: dragIndex } = monitor.getItem() || {};
                    if (dragIndex === index) {
                        return {};
                    }
                    return {
                        isOver: monitor.isOver(),
                        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
                    };
                },
                drop: item => {
                    moveRow(item.index, index);
                },
            });
            const [, drag] = useDrag({
                
                item: { index, type: dragType, },
                collect: monitor => ({
                    isDragging: monitor.isDragging(),
                }),
            });
            drop(drag(ref));

            return (
                <tr
                    ref={ref}
                    className={`${className}${isOver ? dropClassName : ''}`}
                    style={{ cursor: 'move', ...style }}
                    {...restProps}
                />
            );
        };

        const moveRow = (dragIndex, hoverIndex) => {
            // 移动完成，互换位置
            console.log(dragIndex, hoverIndex)
            this.categoryMove(dragIndex, hoverIndex, () => {
                let {cards} = this.state;
                cards[hoverIndex] = cards.splice(dragIndex, 1, cards[hoverIndex])[0];
                this.setState({cards})
            })
        }

        const components = {
            body: {
              row: DraggableBodyRow,
            },
        };
        
        const treeNodeDom = treeData && treeData.length > 0 && treeData.map(item => {
            return <TreeNode key={item.code} value={item.code} title={item.name} selectable={false} >
                    {
                        item.childList && item.childList.map(level1 => {
                        return <TreeNode key={level1.code} value={level1.code} title={level1.name} selectable={false} >
                                    {
                                        level1.childList && level1.childList.map(level2 => {
                                            return <TreeNode key={level2.code} value={level2.code} title={level2.name} selectable={false} >
                                                    {
                                                        level2.childList && level2.childList.map(level3 => {
                                                            return <TreeNode key={level3.code} value={level3.code} title={level3.name} selectable={true} ></TreeNode>                                                            
                                                        })
                                                    }
                                                </TreeNode>
                                            
                                        })
                                    }
                                </TreeNode>
                            
                        })
                    }
                </TreeNode>
            
        })

        return (
            <div className="root">
                <Modal
                    title={this.state.isEnableCategory ? '启用后，该类目将在创建商品时显示，是否确定启用' : '禁用后，该类目将不在创建商品时显示，该类目下的商品的类目也将失效，是否确定禁用'}
                    visible={this.state.isShowStatusModel}
                    onOk={this.handleStatusOk.bind(this)}
                    okText='确定'
                    cancelText='取消'
                    width={400}
                    onCancel={this.handleStatusCancel.bind(this)}
                >
                </Modal>

                <Modal
                    title='删除后，该类目将不再显示，是否确定删除？'
                    visible={this.state.isShowDeleteModel}
                    onOk={this.handleDeleteOk.bind(this)}
                    okText='确定'
                    cancelText='取消'
                    width={400}
                    onCancel={this.handleDeleteCancel.bind(this)}
                >

                </Modal>

               { typeof(this.state.serviceTelEditObj)!="undefined" && <Modal
                    title='客服设置'
                    visible={ true}
                    onOk={this.handleServiceTelOk.bind(this)}
                    okText='确定'
                    cancelText='取消'
                    width={400}
                    onCancel={this.handleServiceTelCancel.bind(this)}
                > 
                  <Radio.Group onChange={this.onServiceTelChange} value={this.state.serviceTelEditObj.serviceTelType+''}>
                  <Radio key={1} value='1'>平台客服</Radio>
                  <Radio key={2} value='2'>店铺客服</Radio>
                  <Radio key={0} value='0'>无</Radio>

                 </Radio.Group>
                </Modal>
                    }

                <Modal
                    visible={this.state.isShowSortModel}
                    onOk={this.handleSortOk.bind(this)}
                    okText='确定'
                    cancelText='取消'
                    width={800}
                    onCancel={this.handleSortCancel.bind(this)}
                >
                    <CategorySort cards={this.state.sorts} onRef={this.onSortStateRef} handlerBackSortState={this.handlerStateChanged.bind(this)} />
                </Modal>

                <Modal
                    visible={this.state.selectedLevel3NodeModal}
                    onOk={this.selectedLevel3NodeModalOk.bind(this)}
                    okText='确定'
                    cancelText='取消'
                    width={400}
                    onCancel={this.selectedLevel3NodeModalCancel.bind(this)}
                >
                    {
                        treeData && treeData.length > 0 ?
                            <TreeSelect
                                showSearch
                                style={{ width: '90%' }}
                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                placeholder="请选择三级类目"
                                allowClear
                                showSearch={false}
                                // treeCheckable
                                multiple={false}
                                onChange={this.selectTreeLevel3.bind(this)}
                            >
                                {
                                    treeNodeDom
                                }
                            </TreeSelect> : null
                    }
                </Modal>

                <div>
                    <Breadcrumb>
                        <BreadcrumbItem>商品类目</BreadcrumbItem>
                        <BreadcrumbItem>后台类目</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <div className="function-part">
                    <div className="category-header">
                        <span className="item">
                            <Input.Search placeholder="类目名称" onChange={this.setKeyWord}
                                onSearch={this.keyWordSearch} value={params.name} style={{ width: '240px' }} maxLength={20} />
                        </span>
                        <span className="item">
                            <Select data={this.state.categoryTypes} placeholder='类目级别' value={levelName} allowClear
                                onChange={this.selectChange.bind(this, 'level')} style={{ width: '150px' }} />
                        </span>
                        <span className="item">
                            <Select data={this.state.categoryStates} placeholder='类目状态' value={params.status} value={params.status} allowClear
                                onChange={this.selectChange.bind(this, 'status')} style={{ width: '150px' }} />
                        </span>
                        {
                            addAuth?
                            <Button type="primary" onClick={() => {this.props.history.push("/app/crm/category/backend/add");}}>新建类目</Button>
                            : null
                        }
                    </div>
                    <div className="main-part">
                        <div className="tree-part">
                            {
                                this.state.treeData.length ? (
                                    <Tree
                                        onExpand={this.onExpand}
                                        //defaultSelectedKeys={['qeZDWfgpvSs']}
                                       // defaultSelectedKeys={['qeZDWfgpvSs']}
                                        expandedKeys={this.state.expandedKeys}
                                        autoExpandParent={this.state.autoExpandParent}
                                        onCheck={this.onCheck}
                                        checkedKeys={this.state.checkedKeys}
                                        onSelect={this.onSelect}
                                        selectedKeys={this.state.selectedKeys}
                                    >
                                        {this.renderTreeNodes(this.state.treeData)}
                                    </Tree>
                                ) : (
                                        <div className='empty-tree'>
                                            暂无类目
                                        </div>
                                    )
                            }
                        </div>
                        <div className="table-part">
                            <div>
                                <div className='backend-category-list' id="drag-table">
                                    <DndProvider backend={HTML5Backend}>
                                        <Table 
                                            columns={this.state.columns} 
                                            selfProcessData={this.state.cards} 
                                            total={this.state.total} 
                                            change={this.onChangePage} 
                                            current={params.pageNo} 
                                            components={components}
                                            onRow={(record, index) => ({
                                                index,
                                                moveRow,
                                              })}
                                            rowKey='code' />
                                    </DndProvider>
                                </div>                               
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        );
    }
}

export default BackendCategory;
