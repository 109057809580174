import React, { Component } from 'react';
import { Icon, Row, Col, Input, Form, Cascader, DatePicker, AutoComplete, message} from 'antd';
import moment from 'moment';
import InfoCard from '../../../common/card/InfoCard';
import http from '@/http';
import { API } from '@/http/api';
import './Add.less';
import BsUpload from '@/components/common/Upload'

const dateFormat = 'YYYY-MM-DD';
const fileTypeArray = ['image/jpeg','jpg', 'png']

export default
    class BaseInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showReuploadBtn: false,
            showMap: false,
            mapTip: '定位不准？请拖动地图手动修改',
            showMapTip: true,
            region: [],
            suggest: [],
            constraint: {
                name: {
                    message: null,
                    status: null
                },
                address: {
                    message: null,
                    status: null
                },
                buildingArea: {
                    message: null,
                    status: null
                },
                floorArea: {
                    message: null,
                    status: null
                },
                floorCount: {
                    message: null,
                    status: null
                },
                contract:{
                    message: null,
                    status: null                   
                },
                elevatorCount:{
                    message: null,
                    status: null                      
                }
            },
            dataSource: props.dataSource || {//基础信息
                name: null,//项目名称
                code: null,//项目编号
                address: {//详细地址
                    province: null,//省份
                    city: null,//城市
                    district: null,//区域
                    detail: null,//详细地址
                },
                point: null,//坐标
                cover: {//封面图片
                    mediaExt: null,
                    mediaKey: null,
                    name: null,
                    type: null,
                    url: null
                },
                buildingArea: null,//建筑面积
                floorArea: null,//占地面积
                floorCount: null,//楼层数
                elevatorCount: null,//电梯数
                completionTime: null,//竣工时间
                contract: null,//联系方式
                desc: null,//项目介绍
            }
        }

        this.loadProvince();
    }

    closeTipBar() {
        this.setState({ showMapTip: false });
    }

    componentDidMount() {

        const drawCenterMarker = (point) => {
            // console.log(point)
            this.map.getOverlays().forEach(o => this.map.removeOverlay(o));
            const marker = new window.BMap.Marker(point);
            this.map.addOverlay(marker);
            
        }

        this.map = new window.BMap.Map("map-container");
        this.map.enableScrollWheelZoom(true);
        this.map.addEventListener("moving", () => {
            const point = this.map.getCenter()
            drawCenterMarker(point);
            const { dataSource } = this.state;
            const newDataSource = {
                ...dataSource,
                point
            };

            if (typeof this.props.moveMap == 'function') {
                this.props.moveMap(newDataSource);
            }
        });
        this.map.addEventListener("tilesloaded", () => {
            drawCenterMarker(this.map.getCenter());
        });
        this.map.addEventListener("resize", () => {
            drawCenterMarker(this.map.getCenter());
        });
    }

    componentWillReceiveProps(props) {
        const { region, dataSource, constraint } = this.state;
        // const { province, city, district } = props.dataSource.address;
        // const index = region.findIndex(item => item.code === province.code);
        // const newRegion = [...region];
        // if (index != -1) {
        //     newRegion[index].children = city;
        //     city.children = district;
        // }
        this.setState({
            // region: newRegion,
            constraint: {
                ...constraint,
                ...props.constraint
            },
            showMap: props.dataSource.point,
            dataSource: {
                ...dataSource,
                ...props.dataSource
            }
        });
        let point = props.dataSource.point;
        if (point) {
            if (point.point) {
                point = point.point;
            }
            const { lat, lng } = point;
            var zoom = null
            if(!this.firstZoom){
                zoom= 13
                this.firstZoom = true
            }
            this.map.centerAndZoom(new window.BMap.Point(lng, lat), zoom);
        }
    }

    onImageUploaded = (res) => {        
        const {  md5, fileName, key ,publicUrl } = res.file;
        let fileLen = fileName.split('.')
        const cover = {
            mediaExt: fileLen[fileLen.length-1],
            mediaKey: key,
            name: fileName,
            type: fileLen[fileLen.length-1],
            url: publicUrl
        }
        const { dataSource } = this.state;
        const newDataSource = {
            ...dataSource,
            cover: cover
        };

        if (typeof this.props.onChange == 'function') {
            this.props.onChange(newDataSource);
        }
    }

    //修改省市区
    onAddressRegionChange = (value, selectedOptions) => {
        const { dataSource } = this.state;
        const newDataSource = {
            ...dataSource,
            address: {
                ...dataSource.address,
                province: selectedOptions[0],
                city: selectedOptions[1],
                district: selectedOptions[2],
            }
        };
        // this.setState({
        //     dataSource: newDataSource
        // });
        if (selectedOptions.length > 2) {
            const options = {
                onSearchComplete: (results) => {
                    if (local.getStatus() == window.BMAP_STATUS_SUCCESS) {
                        this.setState({
                            showMap: true,
                        })
                        const point = results.getPoi(0).point;
                        this.map.centerAndZoom(point, 13);
                    }
                }
            };
            var local = new window.BMap.LocalSearch(this.map, options);
            local.search(selectedOptions[0].name + selectedOptions[1].name + selectedOptions[2].name);
        }


        if (typeof this.props.onChange == 'function') {
            this.props.onChange(newDataSource);
        }
    }

    loadRegion = (selectedOptions) => {
        const targetOption = selectedOptions[selectedOptions.length - 1];

        http({
            api: API.areaSubAreas,
            data: {
                encode: targetOption.code
            },
            showLoading: false
        }).then(res => {
            targetOption.children = res.data;
            if (selectedOptions.length < 2) {
                res.data.forEach(item => {
                    item.isLeaf = false;
                    return item;
                });
            }
            this.setState({ region: [...this.state.region] });
        });
    }

    onChange = (key, e) => {
        const dataSource = {
            ...this.state.dataSource
        }
        console.log(key,e.target.value)
        if (key === 'completionTime') {
            dataSource[key] = e;
        } else {
            
            if(key=='floorArea'||key=='buildingArea'){
                dataSource[key] = e.target.value.split('.')[0]+(e.target.value.split('.')[1]!==undefined?('.'+e.target.value.split('.')[1].slice(0,2)):'');
            }else{
                dataSource[key] = e.target.value;
            }
            
        }
        if (typeof this.props.onChange == 'function') {
            this.props.onChange(dataSource);
        }
    }

    loadProvince = () => {
        http({
            api: API.areaProvinces,
            showLoading: false
        }).then(res => {
            if (res.success) {
                this.setState({
                    region: res.data.map(item => {
                        item.isLeaf = false;
                        return item;
                    })
                })
            }
        });
    }

    getRegionDefaultValue = () => {
        let def = [];

        const address = this.state.dataSource.address;
        if (address.province) {
            def.push(address.province.code);
        }
        if (address.city) {
            def.push(address.city.code);
        }
        if (address.district) {
            def.push(address.district.code);
        }
        return def;
    }

    onAddressDetailTextChange = (v) => {
        let _address = null;
        const index = this.state.suggest.findIndex(item => item.uid === v);
        if (index != -1) {
            _address = this.state.suggest[index];
        }
        const dataSource = {
            ...this.state.dataSource,
            address: {
                ...this.state.dataSource.address,
                detail: _address ? _address.address : v
            },
            point: _address&&_address.point
        }
        if (typeof this.props.onChange == 'function') {
            this.props.onChange(dataSource);
        }
    }

    handleAddressSuggestSearch = (v) => {        
        const point = [];
        if (v) {
            const options = {
                onSearchComplete: (results) => {
                    if (local.getStatus() === window.BMAP_STATUS_SUCCESS) {
                        for (var i = 0; i < results.getCurrentNumPois(); i++) {
                            point.push(results.getPoi(i));
                        }
                        // console.log(point);
                    } else /*if(local.getStatus() === window.BMAP_STATUS_UNKNOWN_LOCATION)*/ {

                    }
                    this.setState({
                        suggest: point
                    });
                }
            };
            const local = new window.BMap.LocalSearch(this.map, options);
            local.search(v);
        } else {
            this.setState({
                suggest: point
            });
        }
    }

    onAddressSuggestSelect = (v) => {
        const index = this.state.suggest.findIndex(item => item.uid === v);
        if (index != -1) {            
            const { address, point } = this.state.suggest[index];
            const dataSource = {
                ...this.state.dataSource,
                address: {
                    ...this.state.dataSource.address,
                    detail: address,
                    point: point
                }
            }                        
            setTimeout(() => {
                this.map.centerAndZoom(this.state.suggest[index].point, 13);            
            }, 100);
            if (typeof this.props.onChange == 'function') {
                this.props.onChange(dataSource);
            }
        }

    }
    beforeUpload = (file) => {
        const fileNameToArray = file.name.split('.');
        const fileType = fileNameToArray[fileNameToArray.length - 1];
        const isFile = !!fileTypeArray.find(item => item == fileType)
        if (!isFile) {
            message.error('只支持' + fileTypeArray.join() + '文件');
        }
        const isLt5M = file.size / 1024 / 1024 <= 10;
        if (!isLt5M) {
            message.error('图片大小不能超过10M');
        }
        return isFile && isLt5M;
    }

    render() {
        const { dataSource, constraint, suggest, showMap } = this.state;
        const {
            name,
            code,
            address,
            buildingArea,
            floorArea,
            floorCount,
            elevatorCount,
            completionTime,
            contract,
            desc,
            cover,
        } = dataSource;
        const uploadButton = (
            <div className="upload-button">
                <Icon type={this.state.loading ? 'loading' : 'cloud-upload'} />
                <div className="upload-text">上传项目照片</div>
                <div className="upload-text-desc">(拖拽至此 或 点击上传)</div>
            </div>
        );
        let addressSuggestOptions;
        if (!address.province || !address.city || !address.district) {
            addressSuggestOptions = (<AutoComplete.Option key={-1} disabled>请先选择省市区</AutoComplete.Option>);
        } else if (suggest.length == 0) {
            addressSuggestOptions = (<AutoComplete.Option key={-1} disabled>无匹配结果</AutoComplete.Option>)
        } else {
            addressSuggestOptions = suggest.map(item => (<AutoComplete.Option key={item.uid}>{item.title}</AutoComplete.Option>));
        }
        return (
            <InfoCard title="基本信息">
                <Form layout={'vertical'}>
                    <Row gutter={80}>
                        <Col span={16}>
                            <Row gutter={80}>
                                <Col span={12}>
                                    <Form.Item label={'项目名称'} required validateStatus={constraint.name.status} help={constraint.name.message}>
                                        <Input value={name}
                                            placeholder={'请输入'}
                                            maxLength={15}
                                            onChange={(value) => { this.onChange('name', value) }} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label={'项目编号'}>
                                        <Input value={code}
                                            placeholder={'请输入'}
                                            maxLength={20}
                                            onChange={(value) => { this.onChange('code', value) }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item label={'详细地址'} required validateStatus={constraint.address.status} help={constraint.address.message}>
                                <Input.Group>
                                    <Row gutter={8}>
                                        <Col span={5}>
                                            <Cascader
                                                style={{ width: '100%' }}
                                                loadData={this.loadRegion}
                                                fieldNames={{ label: 'name', value: 'code', children: 'children' }}
                                                allowClear={false}
                                                changeOnSelect
                                                value={this.getRegionDefaultValue()}
                                                suffixIcon={<Icon type='caret-down' />}
                                                placeholder={'省市区'}
                                                options={this.state.region}
                                                onChange={(value, selectedOptions) => { this.onAddressRegionChange(value, selectedOptions) }}
                                                displayRender={label => label.join('')} />
                                        </Col>
                                        <Col span={19}>
                                            <AutoComplete style={{ width: "100%" }}
                                                value={address.detail}
                                                placeholder={'详细地址'}
                                                onChange={this.onAddressDetailTextChange}
                                                onSelect={this.onAddressSuggestSelect}
                                                onSearch={this.handleAddressSuggestSearch} 
                                                >

                                                {addressSuggestOptions}

                                            </AutoComplete>

                                        </Col>
                                    </Row>
                                </Input.Group>
                                <div className="map-view" style={{ display: showMap ? 'block' : 'none' }}>
                                    {this.state.showMapTip ? (<div className="tip-bar">
                                        <div className="tips">
                                            <Icon type="info-circle" />
                                            <span>{this.state.mapTip}</span>
                                        </div>
                                        <span className="btn-close" onClick={this.closeTipBar.bind(this)}>关闭提示</span>
                                    </div>) : null}
                                    <div id="map-container" className="map-container"></div>
                                </div>
                            </Form.Item>

                        </Col>
                        <Col span={8}>
                            <BsUpload
                                listType="picture-card"
                                className="image-uploader"
                                beforeUpload={this.beforeUpload}
                                showUploadList={false}
                                onSuccess={this.onImageUploaded}
                                action={API.uploadAdd.url}
                            >
                                {cover.url ?
                                    (<div className="cover-preview"
                                        onMouseEnter={() => this.setState({ showReuploadBtn: true })}
                                        onMouseOut={() => this.setState({ showReuploadBtn: false })}>
                                        <img src={cover.url} alt={cover.name} />
                                        <div className="btn-cover-reupload"
                                            style={{ display: this.state.showReuploadBtn ? 'block' : 'none' }}
                                            onMouseEnter={() => this.setState({ showReuploadBtn: true })}>重新上传</div>
                                    </div>) : uploadButton}
                            </BsUpload>
                        </Col>
                    </Row>

                    <Row gutter={80} style={{ marginTop: '24px' }}>
                        <Col span={8}>
                            <Form.Item label={'建筑面积'} required  validateStatus={constraint.buildingArea.status} help={constraint.buildingArea.message}>
                                <Input value={buildingArea}
                                    placeholder={'请输入'}
                                    
                                    suffix={'m²'}
                                    onChange={(value) => { this.onChange('buildingArea', value) }} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label={'占地面积'} required validateStatus={constraint.floorArea.status} help={constraint.floorArea.message}>
                                <Input value={floorArea}
                                    placeholder={'请输入'}
                                    
                                    suffix={'m²'}
                                    onChange={(value) => { this.onChange('floorArea', value) }} />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item label={'总楼层数'} required validateStatus={constraint.floorCount.status} help={constraint.floorCount.message}>
                                <Input value={floorCount}
                                    placeholder={'请输入'}
                                    maxLength={3}
                                    suffix={'层'}
                                    onChange={(value) => { this.onChange('floorCount', value) }} />
                            </Form.Item>
                        </Col>

                    </Row>

                    <Row gutter={80}>

                        <Col span={8}>
                            <Form.Item label={'电梯数'} validateStatus={constraint.elevatorCount.status} help={constraint.elevatorCount.message}>
                                <Input value={elevatorCount}
                                    placeholder={'请输入'}
                                    maxLength={3}
                                    suffix={'台'}
                                    onChange={(value) => { this.onChange('elevatorCount', value) }} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label={'竣工时间'}>
                                <DatePicker value={completionTime ? moment(completionTime, dateFormat) : null}
                                    format={dateFormat}
                                    placeholder={'请选择'} style={{ width: '100%' }}
                                    onChange={(value) => { this.onChange('completionTime', value) }} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label={'联系方式'}  validateStatus={constraint.contract.status} help={constraint.contract.message}>
                                <Input value={contract}
                                    placeholder={'请输入'}
                                    maxLength={14}
                                    onChange={(value) => { this.onChange('contract', value) }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item className="clear-margin" label={'项目介绍'}>
                        <Input.TextArea value={desc}
                            autosize={{ minRows: 4 }}
                            placeholder={'请输入'}
                            maxLength={500}
                            onChange={(value) => { this.onChange('desc', value) }} />
                    </Form.Item>
                </Form>
            </InfoCard>
        );
    }
}