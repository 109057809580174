import React, { Component } from 'react';
import CommonSelect from 'CMPT/common/Select';
import SourcingList from 'CMPT/sourcing/SourcingList';
import { Breadcrumb, Input, Select, Badge } from 'antd';
import { API } from '@/http/api';
import http from '@/http';
import './index.less'

const BreadcrumbItem = Breadcrumb.Item;
const { Option } = Select;

export default class Sourcing extends Component {
    state = {
        publishStatusList: [],
        enterpriseList: [],
        params: {
            purchaserName:'',//企业名称
            pubKeyWord: '', //发布人：手机号，昵称
            purchaseCode: '', //采购商id
            auditStatus: 1,//1：待审核，2：已通过，3：已驳回，4：强制下架
            status: null, // 询价单的状态 0：待发布，1：已发布，2：待开始，3：进行中，4：已结束，5：已成交，6：未成交，7：已作废，8：违规下架，9：已删除
            inquiryKeyWord: '' //标题、编号模糊搜索
        },
    }

    auditStatusList = [
        {
            name: '全部',
            value: 99
        },
        {
            name: '待审核',
            value: 1
        },
        {
            name: '已通过',
            value: 2
        },
        {
            name: '已驳回',
            value: 3
        },
        {
            name: '违规下架',
            value: 4
        }
    ]

    componentWillMount() {
        this.getPublishStatusList()
       // this.getEnterpriseList('')
    }

    componentDidMount () {
        this.sourcingList.refreshDataWithParams(this.state.params)
    }

    // 根据关键字搜索企业
    onEnterpriseSearch = (value) => {
        this.getEnterpriseList(value)
    }

    // 获取所有企业列表
    getEnterpriseList = (keyWords) => {
        let that = this;
        const config = {
            api: API.sourcingEnterpriseList,
            data: {
                keywords: keyWords
            }
        }
        http(config).then(res => {
            if (res && res.success && res.data) {
                let enterprises = res.data || []
                let enterpriseList = []
                enterprises.forEach((item) => {
                    let enterpriseItem = {}
                    enterpriseItem.name = item.companyName
                    enterpriseItem.code = item.code
                    enterpriseList.push(enterpriseItem)
                })
                enterpriseList.splice(0,0,{
                    name: '全部',
                    code: 99
                })
                that.setState({
                    enterpriseList
                })
            } else {
                that.setState({
                    enterpriseList: []
                })
            }
        })
    }

    // 获取询价单发布的状态列表
    getPublishStatusList = () => {
        let that = this;
        const config = {
            api: API.sourcingInquiryStatusList,
        }
        http(config).then(res => {
            if (res && res.success && res.data) {
                let publishStatusList = res.data || []
                publishStatusList.forEach((item) => {
                    let statusItem = item
                    item.name = statusItem.value
                    item.value = statusItem.key
                })
                publishStatusList.splice(0,0,{
                    name: '全部',
                    value: 99
                })
                that.setState({
                    publishStatusList
                })
            }
        })
    }

    // 切换审核状态的tab
    auditTabsChange = (attr, data) => {
        console.log(attr, data)
        let params = this.state.params
        if(params[attr] === data){
            return 
        }

        this.clearFilter()
        let filterParams = this.state.params
        filterParams[attr] = data
        if (data === 99) {
            filterParams[attr] = null
        }
        this.setState({
            filterParams
        })
        this.searchList()
        this.props.getTabsCount()
    }

    // 下拉框选择
    selectChange = (attr, data) => {
        let params = this.state.params
        params[attr] = data
        if (data === 99) {
            params[attr] = null
        }
        this.setState({
            params
        })
        
    }

    onPublisherKeyWordChange = (e) => {
        let params = this.state.params
        params['pubKeyWord'] = e.target.value
        this.setState({
            params
        })
    }

    

    onPurchaserNameKeyWordChange = (e) => {
        let params = this.state.params
        params['purchaserName'] = e.target.value
        this.setState({
            params
        })
    }

    onTitleKeyWordChange = (e) => {
        let params = this.state.params
        params['inquiryKeyWord'] = e.target.value
        this.setState({
            params
        })
    }

    // 搜索
    searchList = () => {
        let params = this.state.params
        console.log(this.state.params)
        this.sourcingList.refreshDataWithParams(params)
    }

    // 清空筛选项
    clearFilter = () => {
        let auditStatus = this.state.params.auditStatus
        let params = {
            pubKeyWord: '', 
            purchaseCode: null, 
            auditStatus: auditStatus,
            status: null,
            inquiryKeyWord: ''
        }
        this.state.params = params
        this.setState({
            params
        })
    }

    // 重置
    resetFilter = () => {
        this.clearFilter()
        this.searchList()
    }

    render() {
        const { params, enterpriseList, publishStatusList } = this.state;
        const {countNumber} = this.props;
        console.log(countNumber, 'countNumber')
        const EnterpriseOptions = enterpriseList.map(item => <Option key={item.code} value={item.code}>{item.name}</Option>)
        return (
            <div>
                <div className="content-part">
                    <ul className="status-tab">
                        {this.auditStatusList.map(per => {
                            return <li key={per.value} className={(params.auditStatus ? params.auditStatus : 99) === per.value ? 'active' : ''}
                                onClick={this.auditTabsChange.bind(this,'auditStatus',per.value)}>
                                    {
                                        per.value == 1 && countNumber > 0 ?
                                        per.name+"("+countNumber+")"
                                         :
                                        per.name
                                    }
                                </li>
                        })}
                    </ul>
                    <div className="filter-part">
                        <span>
                            <span className='condition-title'>发布人</span>
                            <Input placeholder="姓名/手机号码" onChange={this.onPublisherKeyWordChange}
                                 style={{ width: '160px', marginRight: 12, marginLeft: 12 }} maxLength={20} value={params.pubKeyWord}/>
                        </span>
                        <span>
                            <span className='condition-title'>所属企业</span>
                            <Input placeholder="企业名称" onChange={this.onPurchaserNameKeyWordChange}
                                 style={{ width: '160px', marginRight: 12, marginLeft: 12 }} maxLength={20} value={params.purchaserName}/>
                        </span>
                       {/*  <span>
                            <span className='condition-title'>所属企业</span>
                            <Select showSearch onSearch={this.onEnterpriseSearch} onChange={this.selectChange.bind(this,'purchaseCode')} placeholder={'全部'} style={{ width: '200px', marginRight: 12, marginLeft: 12 }} value={params.purchaseCode ? params.purchaseCode : 99}>
                                    { EnterpriseOptions }
                            </Select>
                       </span> */}
                        <span>
                            <span className='condition-title'>状态</span>
                            <CommonSelect data={publishStatusList} placeholder="全部"
                                selectMethod={this.selectChange.bind(this,'status')} style={{ width: '120px', marginRight: 12, marginLeft: 12 }} value={params.status!=null ? params.status : 99}/>
                        </span>
                        <span>
                            <Input placeholder="标题/编号" onChange={this.onTitleKeyWordChange} style={{ width: '200px', marginRight: 12 }} maxLength={20} value={params.inquiryKeyWord}/>
                        </span>
                        <span>
                            <button className='search' onClick={this.searchList}>搜索</button>
                        </span>
                        <span className="search_reset" onClick={this.resetFilter}>重置</span>
                    </div>
                    <SourcingList onRef={(ref)=>{ this.sourcingList = ref}} searchParams={this.state.params} />
                </div>
            </div>
        );
    }
}