import { AJAX_TYPE } from '../config';

const prefix = 'testAccount'

export default {
    // 获取测试用户列表
    [`${prefix}List`] : {
        url: '/web/crm/trip/getList',
        type: AJAX_TYPE.POST
    },
    // 获取非测试用户列表
    [`${prefix}FormalList`] : {
        url: '/web/crm/trip/getFormalList',
        type: AJAX_TYPE.POST
    },
    // 添加到测试用户
    [`${prefix}Trans`] : {
        url: '/web/crm/trip/trans',
        type: AJAX_TYPE.POST
    },
}