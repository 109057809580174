import React, { Component } from "react";
import { InputNumber } from "antd";
import "./BackendCategory.less";
import Table from 'CMPT/common/Table';
import { API } from "@/http/api";
import http from "@/http";

class BackendCategorySort extends Component {
    
    state = {
        cards: [],
        sortList: []
    }

    confirmSort = (record) => {

        let cards = this.state.cards
        let fromCode = record.code;
        let fromIndex = cards.find(item => item.code == fromCode).number;
        
        let toCode = cards[record.categoryIndex].code
        let toIndex = cards.find(item => item.code == toCode).number;
        
        // 点击了应用，但是类目序号没有改变
        if (fromIndex == toIndex) {
            return;
        }

        // 本地排序
        [cards[fromIndex], cards[toIndex]] = [cards[toIndex], cards[fromIndex]];
        cards[fromIndex].number = fromIndex
        cards[fromIndex].categoryIndex = fromIndex
        cards[toIndex].number = toIndex
        cards[toIndex].categoryIndex = toIndex
        
        // 交换后code对应的sort
        let fromItem = {
            code: fromCode,
            sort: toIndex
        }
        let toItem = {
            code: toCode,
            sort: fromIndex
        }

        // 将排序的数据源放入sortList
        let sortList = this.state.sortList
        let originFromItem = sortList.find((item) => item.code == fromItem.code)
        if (originFromItem == undefined) {
            sortList.push(fromItem)
        } else {
            originFromItem.sort = fromItem.sort
        }

        let originToItem = sortList.find((item) => item.code == toItem.code)
        if (originToItem == undefined) {
            sortList.push(toItem)
        } else {
            originToItem.sort = toItem.sort
        }
        
        this.setState({
            cards,
            sortList
        })
    }

    doChangeSortState = () => {
        // 直接点击了“确定”，没有任何“应用”操作
        if (this.state.sortList.length == 0) {
            this.props.handlerBackSortState()
            return
        }

        http({
            api: API.categoryBatchsort,
            data: {
                sortList: this.state.sortList
            },
            showLoading: true
        }).then(res => {
            if (res.success) {
                this.setState({
                    sortList: []
                })
                this.props.handlerBackSortState()
            }
        })
    }

    handleSort = () => {
        http({
            api: API.categoryBatchsort,
            successTip: "排序成功",
            data: this.state.sortList,
            showLoading: true
        }).then(res => {
            if (res.success) {
                this.props.history.push("/app/crm/helphandle/catalog/backend");
            }
        })
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.onRef(this);
        this.setState({
            cards: this.props.cards
        }) 
    }

    componentWillReceiveProps(props, nextProps) {
        this.setState({
            cards: props.cards
        })
    }

    handleChange = (value, name, index) => { // 依据需求而定，各个选择框可实现联动
        const newValue = value;
        const { cards } = this.state;
        cards[index][name] = newValue - 1;
        this.setState({
            cards
        })
    }

    render() {
        const columns = [
            {
                title: '序号',
                width: '10%',
                dataIndex: 'number',
                render: (value) => {
                    return (<div> {value + 1}</div>);
                }
            },{
                title: '类目名称',
                dataIndex: 'categoryName',
                render: (value) => {
                    return (<div> {value}</div>);
                }
            }, {
                title: '类目序号',
                dataIndex: 'categoryIndex',
                render: (text, record, index) => {
                    return (<InputNumber min={1} max={this.state.cards.length} value={record.categoryIndex + 1} onChange={(value) => { this.handleChange(value, 'categoryIndex', index); }} />);
                }
            }, {
                title: '操作',
                key: 'operate',
                render: (text, record, index) => {
                    return (<span className="table-operate-btn operate" onClick={this.confirmSort.bind(this, record)}>应用</span>)
                }
            }
        ]
        return (
            <div style={{height: '500px', overflow: 'auto'}}>
                <Table columns={columns} selfProcessData={this.state.cards} pagination={false} rowKey='code'/>
            </div>
        );
    }
}

export default BackendCategorySort;
