let channelTypeConfig = {
  1: "陌生拜访",
  2: "上门",
  3: "朋友介绍",
  4: "客户介绍",
  5: "渠道客户",
  0: "其他"
};

let sourceTypeConfig = {
  0: "其他",
  1: "手工录入",
  2: "导入"
};

let statusConfig = {
  0: "停用",
  1: "启用"
};

let customerTypeConfig = {
  1: "正式",
  0: "试用"
};

let dateTypeConfig = {
  0: "合同开始时间",
  1: "合同结束时间"
};

let CustomerRole = {
  Supplier: 1,
  GRader: 2
};

let CustomerRoleMap = {
  "1": "采购方",
  "1,2": "供应方",
  "1,3": "园区方",
  "1,2,3": "供应方，园区方"
};

let CustomerCreateType = {
  1: "企业认证",
  2: "后台创建"
};

const completeFlag = {
  2: '是',
  1: '否'
}

const UserLabelType = {
  1:"个人", // 后端没有这个值，算是特殊的值
  20: "司机",
  30: "操作员"
};

const UserStatusType = {
  1:"正常", // 后端没有这个值，算是特殊的值
  0: "禁用" 
};

const UserSupplierType = {
  1:"是", // 后端没有这个值，算是特殊的值
  0: "否" 
};

function convertAuthenticationToLabel(authenticationTo) {
 // 为空 ，是个人账号，没有企业类型
  if (!authenticationTo || authenticationTo.length<1) {
    return "-";
  }

  if (
    authenticationTo.indexOf("3") > -1 &&
    authenticationTo.indexOf("2") > -1
  ) {
    return "供应方+园区方";
  } else if (authenticationTo.indexOf("3") > -1) {
    return "园区方";
  } else if (authenticationTo.indexOf("2") > -1) {
    return "供应方";
  }

  return "-";
}

function convertUpgradeToLabel(upgradeTo) {
  // 默认至少是 采购方
  if (!upgradeTo) {
    return "";
  }

  if (upgradeTo.indexOf("3") > -1 && upgradeTo.indexOf("2") > -1) {
    return "供应方+园区方";
  } else if (upgradeTo.indexOf("3") > -1) {
    return "园区方";
  } else if (upgradeTo.indexOf("2") > -1) {
    return "供应方";
  }

  return "";
}

export {
  channelTypeConfig,
  sourceTypeConfig,
  statusConfig,
  customerTypeConfig,
  dateTypeConfig,
  CustomerRole,
  CustomerRoleMap,
  CustomerCreateType,
  convertAuthenticationToLabel,
  convertUpgradeToLabel,
  completeFlag,
  UserLabelType,
  UserStatusType,
  UserSupplierType
};
