import React, { Component } from 'react';
import { Cascader, Icon } from 'antd';

export default class BsCascader extends Component {

    render() {
        const { suffixIcon, style } = this.props;

        return(
            <Cascader
                {...this.props}
                style={style || {width: '100%'}}
                suffixIcon={suffixIcon || <Icon type="caret-down"/>}
            />
        )
    }
}
