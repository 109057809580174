import React, { Component } from 'react'
import { API } from '@/http/api';
import http from '@/http';
import userUtil from '@/utils/user';
import moment from 'moment';
import storage from '@/utils/storage';
import { changeObjkey } from '@/utils';
import Select from 'CMPT/common/Select';
import Table from 'CMPT/common/Table';
import SignoutModal from '../list/SignoutModal';
import { Input, Tag, Tooltip } from 'antd';
class AuditList extends Component {
    state = {
        fetching: true,
        projects: [],
        fetchingprojects:true,
        floors: [],
        fetchingfloors:true,
        departmentPerson: [], // {value: 部门code-人员code, name: 部门name-人员name}
        fetchingdep:true,
        rentStatus: [],
        auditStatus: [],
        category: [ // (1, "待我审批"), (2, "我发起的"), (3, "我已审批")
            { id: 1, name: '待我审批', num: 0, desc: 'waitApproveCount' },
            { id: 2, name: '我发起的', num: 0, desc: 'myApplyCount' },
            { id: 3, name: '我已审批', num: 0, desc: 'myApprovedCount' }
        ],
        columns: [
            {
                title: '状态',
                dataIndex: 'statusName',    
                 
            }, {
                title: '审批状态',
                dataIndex: 'auditStatus',
                render(value, row){
                    let color = ''
                    switch(value) { // 0 待审批，1 已驳回 2 通过 3 不通过
                        case 0:
                            color = '#FBBC06'
                            break
                        case 1:
                            color = '#E94437'
                            break;
                        case 3:
                            color = '#838694'
                            break
                        default:
                            color = '#E94437'
                    }
                    return <div><span style={{color: color, marginRight: '6px'}}>●</span>{row.auditStatusName}</div>
                }
            }, {
                title: '租客',
                dataIndex: 'customer',
                
                render(value){
                    let buttons = []
                    if (value.includes.contract) {
                        buttons.push(<span className="contract-table-btn" key="1">租</span>)
                    }
                    if (value.includes.estate) {
                        buttons.push(<span className="contract-table-btn btn-bg" key="2">物</span>)
                    }
                    let com = value.name
                    if (value.name.length > 10) {
                        com = <Tooltip placement="top" title={value.name}>{value.name.slice(0, 10)}...</Tooltip>
                    }
                    return <div>
                        {buttons}
                        {com}
                    </div>
                }
            }, {
                title: '合同编号',
                dataIndex: 'contractNum',
                render: (value, row) => {
                    const obj = {10: '新签', 11: '变更', 12: '续签', 13: '退租', 14: '作废'}
                    return <span><span className="contract-table-btn audit-table-btn">{obj[row.auditType]}</span>{value}</span>
                }
            }, {
                title: '项目名称',
                dataIndex: 'projectName'
            }, {
                title: '区域信息',
                dataIndex: 'roomName'
            }, {
                title: '提交时间',
                dataIndex: 'commitTime',
                defaultSortOrder: 'ascend',
                sorter: true
            }, {
                title: '签约人',
                dataIndex: 'signPerson'
            }, {
                title: '操作',
                dataIndex: 'operate',
                fixed: 'right',
                width: '150px',
                render:(value, row) => (
                    <div>
                        {value.map(per => {
                            return <span className="table-operate-btn" key={per} onClick={this.operate.bind(this, per, row)}>
                                       {['查看', '审批', '编辑'][per -1]}
                                   </span>
                        })}
                    </div>
                )

            }
        ],
        data: [],
        queryParams: {
            page: 1,
            pageSize: 10,
            total: 0,
            currentCategory: 1,
            keyWord: '', // 搜索关键字
            projectId: undefined, //项目id
            floorIds: [], //楼层ids
            rentStatus: undefined, //租约状态
            auditStatus: undefined, //审批状态
            userId: undefined, //部门人员
            orderFlag: 1, // 提交时间升降序排列，默认升序
        },
        signoutInfo: {
            contractCode: '',
            contractDateRange: [],
            signoutFei: []
        },
        showModal: false
    }
    componentWillMount() {
        let search = this.props.history.location.search
        if (search) {
            const formatData = this.formatSearch(search)
            let queryParams = this.state.queryParams
            if (formatData.type === '1') {
                queryParams.currentCategory = 1
            } else if (formatData.type === '2') {
                queryParams.currentCategory = 2
                queryParams.auditStatus = 0
            }
            this.setState({
                queryParams
            })
        }
    }
    componentDidMount() {
        this.getList()
        // 获取汇总数据
        this.getStatistics()
        // 获取枚举类型
        this.getTypeList()
        // 获取项目、部门等信息
        this.getSearchData()
        // // 检查tab权限
        // this.chectAuth()
    }
    formatSearch = (ss) => {
        const ssSplit = ss.split('?')[1].split('&')
        let obj = {}
        ssSplit.forEach(per => {
            let perSplit = per.split('=')
            obj[perSplit[0]] = perSplit[1]
        })
        return obj
    }
    chectAuth = () => {
        let category = this.state.category
        userUtil.hasAuth(9902010202) &&  category.push({ id: 2, name: '我发起的', num: 0, desc: 'myApplyCount' })
        userUtil.hasAuth(9902010203) &&  category.push({ id: 3, name: '我已审批', num: 0, desc: 'myApprovedCount' })
        this.setState({
            category
        })
    }
    getList = () => {
        const config = {
            api: API.contractApprovelist,
            showLoading: true,
            data: this.mapAttr()
        }
        http(config).then(res => {
            this.mapResponse(res.data.datas)
            this.setState({
                queryParams: Object.assign({}, this.state.queryParams, {total: res.data.totalRecord})
            })
        })
    }
    // 发起请求时字段隐射
    mapAttr = () => {
        const queryParams = this.state.queryParams
        let params = {}
        params.auditStatus = queryParams.auditStatus
        params.userCode  = queryParams.userId
        params.floorCodes =queryParams.floorIds
        params.keyWords =queryParams.keyWord
        params.pageNo =queryParams.page
        params.pageSize =queryParams.pageSize
        params.projectCode =queryParams.projectId
        params.status =queryParams.rentStatus
        params.orderFlag = queryParams.orderFlag
        params.approveFlag = queryParams.currentCategory
        return params
    }
    mapResponse = (list) => {
        const currentCategory = this.state.queryParams.currentCategory
        let data = []
        list.forEach(per => {
            let obj = {}
            obj.key = per.encode
            obj.caseFlowSwitch = per.caseFlowSwitch // 当前项目是否开启审批
            obj.status = per.status
            obj.statusName = per.statusDesc
            obj.auditStatus = per.auditStatus
            obj.auditType = per.auditType
            obj.auditStatusName = per.auditStatusDesc
            obj.customer = {name: per.customerNames.join('，'), includes: {contract: per.contractClause.rentClause, estate: per.contractClause.property}}
            obj.contractNum = per.contractCode || '--'
            obj.projectName = per.projectNames.join(',')
            obj.roomName = per.zoneNos ? per.zoneNos.join(',') : '--'
            obj.signType = per.signType
            obj.signTypeName = per.signTypeDesc
            obj.commitTime = per.commitDateTimeStr
            obj.signPerson = per.signUserName
            obj.operate = []
            //'查看', '审批', '编辑'
            // 0审批中 1已驳回 2审批通过 3审批不通过
            // currentCategory (1, "待我审批"), (2, "我发起的"), (3, "我已审批")
            if (currentCategory === 1) {
                obj.operate.push(2)
            } else if (currentCategory === 2) {
                userUtil.hasAuth(9902010201) && obj.operate.push(1)
                 // 新签、已续签、已变更, 已退租，退租未结算，已违约，违约未结算被驳回后，发起人可以修改
                if (per.caseFlowSwitch && per.auditStatus === 1 && [10, 11, 12, 13].indexOf(per.auditType) !== -1) {
                    obj.operate.push(3)
                }
            } else {
                obj.operate.push(1)
            }
            data.push(obj)
        })
        this.setState({data})
    }
    getStatistics = () => {
        const config = {
            api: API.contractApproveCount,
            showLoading: true,
            data: this.mapAttr()
        }
        http(config).then(res => {
            let category = this.state.category
            const statists = res.data
            Object.keys(statists).forEach(key => {
                category.forEach(per => {
                    if (per.desc === key) {
                        per.num = statists[key]
                        return
                    }
                })
            })
            this.setState({
                category
            })
        })
    }
    getTypeList = () => {
        const config = {
            api: API.contractSearchInit
        }
        http(config).then(res => {
            const { auditStatus, status } = res.data
            this.setState({
                fetching: false,
                rentStatus: this.generateList(status),
                auditStatus: this.generateList(auditStatus)
            })
        })
    }
    generateList = (list, key, addUnit) => {
        let keyMap = {key: 'value', value: 'name'}
        if (key) {
            keyMap = key
        }
        let target = []
        list.forEach(per => {
            if (key && addUnit) {
                per.name += '层'
            }
            target.push(changeObjkey(per, keyMap, true))
        })
        return target
    }
    getSearchData = () => {
        const projectConfig = {
            api: API.commonProjectfilter,
        }
        // 项目
        http(projectConfig).then(proRes => {
            const mapKey = {encode: 'value', name: 'name'}
            this.setState({
                fetchingprojects:false,
                projects: this.generateList(proRes.data, mapKey)
            })
        })
    }
    getDetPerData = () => {
        const departmentPerson = this.state.departmentPerson
        if (departmentPerson && departmentPerson.length) return
        const deptConfig = {
            api: API.userGetList,
            data: {
                departmentEncode: storage.get('USER').departmentIdCode
            }
        }
        // 部门人员
        http(deptConfig).then(deptRes => {
            this.setState({
                fetchingdep:false,
                departmentPerson: this.transDeptData(deptRes.data.datas)
            })
        })
    }
    transDeptData = (list) => {
        let transList = []
        list.forEach(per => {
            transList.push({value: per.idCode, name: `${per.departmentName}-${per.name}`})
        })
        return transList
    }
    keyWordSearch = (value) => {
        let queryParams = this.state.queryParams
        queryParams.keyWord = value
        queryParams.page = 1
        this.setState({
            queryParams
        }, () => {
            this.getList()
            this.getStatistics()
        })
    }
    selectChange = (attr, data) => {
        let queryParams = this.state.queryParams
        queryParams.page = 1
        if (attr === 'floorIds') {
            queryParams[attr].push(data)
        } else {
            queryParams[attr] = data
            if (attr === 'projectId') {
                this.getFloorData(data)
            }
        }
        this.setState({queryParams}, () => {
            this.getList()
            this.getStatistics()
        })
    }
    getFloorData = (id) => {
        const config = {
            api: API.commonFloorfilter,
            data: {projectEncode: id}
        }
        http(config).then(res => {
            const mapKey = {encode: 'value', name: 'name'}
            this.setState({
                fetchingfloors:false,
                floors: this.generateList(res.data, mapKey, true)
            })
        })
    }
    floorDeselect = (value) => {
        let queryParams = this.state.queryParams
        let floorIds = queryParams.floorIds
        const index = floorIds.findIndex(per => per === value)
        if (index !== -1) {
            floorIds.splice(index, 1)
        }
        queryParams.floorIds = floorIds
        this.setState({
            queryParams
        }, () => {
            this.getList()
            this.getStatistics()
        })
    }
    dateRangeChange = (attr, value, list) => {
        let queryParams = this.state.queryParams
        queryParams[attr] = list
        queryParams.page = 1
        this.setState({
            queryParams
        }, () => {
            this.getList()
            this.getStatistics()
        })
    }
    resetParams = () => {
        let queryParams = this.state.queryParams
        Object.keys(queryParams).forEach(key => {
            if (key === 'currentCategory') {
                queryParams[key] = 1
            } else if (key === 'page' || key === 'pageSize') {
                queryParams.page = 1
                return
            } else if (key === 'floorIds') {
                queryParams[key] = []
            } else {
                queryParams[key] = undefined
            }
        })
        this.setState({
            queryParams,
            floors: [],
        }, () => {
            this.getList()
            this.getStatistics()
        })
    }
    setKeyWord = (e) => {
        let queryParams = this.state.queryParams
        queryParams.keyWord = e.target.value
        this.setState({
            queryParams
        })
    }
    operate=(status, obj)=>{
        if (status === 1 || status === 2) {
            this.props.history.push(`/app/operate/contract/detail/${obj.key}?audit=${obj.auditType}`)
        } else if (status === 3) {
            // 新签，变更，续签去编辑页面 {10: '新签', 11: '变更', 12: '续签', 13: '退租', 14: '作废'}
            if ([10, 11, 12].indexOf(obj.auditType) !== -1) {
                // 10新签审批 11 变更审批 12 续签审批
                const type = {10: 1, 11: 3, 12: 2}[obj.auditType]
                this.props.history.push(`/app/operate/contract/add?code=${obj.key}&type=${type}&isEdit=true`)
            } else { // 退租编辑
                // 先调初始化的接口
                const config = {
                    api: API.contractInitRentWithdrawalApply,
                    path: {
                        enCode: obj.key
                    }
                }
                http(config).then(res => {
                    const data = res.data
                    let signoutInfo = {
                        contractCode: obj.key,
                        appPaid: data.allPaid,
                        customerName: data.customerNames.join('，'),
                        contractDateRange: [data.startTime, data.endTime],
                        contractNumber: data.contractCode,
                        projectRoomNames: data.projectNames.join('，'),
                        signoutType: data.refundRentType || 1,
                        signoutTime: moment(data.refundRentTime),
                        signoutReason: data.remark,
                        signoutFei: data.billList && data.billList.map(item => {
                            return {
                                marginId: item.encode,
                                feiType: item.budgetType,
                                bizTypeSub: item.bizTypeSub, // 1 租赁收付 2 物业 3 其他收付
                                marginType: String(item.feeType),
                                marginNum: item.payAmount,
                                paidAmount: item.paidAmount,
                                auditPaidAmount: item.auditPaidAmount,
                                marginDate: moment(item.payDate)
                            }
                        })
                    }
                    this.setState({
                        signoutInfo
                    })
                })
                this.setState({
                    showModal: true
                })
            }
        }
    }
    modalClose = (flag) => {
        this.setState({
            showModal: false
        })
        if (typeof flag === 'boolean' && flag) {
            this.getList()
            this.getStatistics()
        }
    }
    onChange = (page, pageSize, sorter) => {
        let queryParams = this.state.queryParams
        queryParams.page = page
        queryParams.pageSize = pageSize
        if (sorter && sorter.field) {
            queryParams.orderFlag = 1
            if (sorter.order === 'descend') {
                queryParams.orderFlag = 0
            }
        }
        this.setState({queryParams}, () => {
            this.getList()
        })
    }
    changeCategory = (id) => {
        let queryParams = this.state.queryParams
        queryParams.currentCategory = id
        this.setState({queryParams})
        this.getList()
    }

    notFount() {
        return (
            <div style={{ textAlign: 'center', height: '100px', lineHeight: '100px' }}>加载中...</div>
        )
    }
    notFoundContent() {
        return (
            <div className='ant-empty ant-empty-normal ant-empty-small'><div className="ant-empty-image"></div><p className="ant-empty-description">暂无数据</p></div>
        )
    }
    render() {
        const { queryParams: params ,fetching,fetchingprojects,fetchingfloors,fetchingdep} = this.state
        return (
            <div className="contract-list">
                <div className="contract-search">
                    <div className="contract-list-row">
                        <span>
                            <Input.Search placeholder="租客名称、租客手机号、区域、合同编号" onChange={this.setKeyWord}
                                onSearch={this.keyWordSearch} value={params.keyWord} style={{width: '280px'}} />
                        </span>
                        <span>
                            <Select data={this.state.rentStatus} placeholder="租约状态" notFoundContent={fetching ? this.notFount() : this.notFoundContent()} value={params.rentStatus}
                                    selectMethod={this.selectChange.bind(this, 'rentStatus')} style={{width: '220px'}} />
                        </span>
                        <span>
                            <Select data={this.state.auditStatus} placeholder="审批状态" notFoundContent={fetching ? this.notFount() : this.notFoundContent()} value={params.auditStatus}
                                    selectMethod={this.selectChange.bind(this, 'auditStatus')} style={{width: '220px'}} />
                        </span>
                    {/* </div> */}
                    {/* <div className="contract-list-row"> */}
                        <span>
                            <Select data={this.state.projects} placeholder="请选择项目" notFoundContent={fetchingprojects ? this.notFount() : this.notFoundContent()} value={params.projectId}
                                    selectMethod={this.selectChange.bind(this, 'projectId')} style={{width: '280px'}} />
                        </span>
                        <span>
                            <Select notFoundContent="请先选择项目" data={this.state.floors} notFoundContent={fetchingfloors ? this.notFount() : this.notFoundContent()} placeholder="请选择楼层" value={params.floorIds} mode="multiple"
                                    selectMethod={this.selectChange.bind(this, 'floorIds')}  onDeselect={this.floorDeselect} style={{width: '220px'}}/>
                        </span>
                        <span>
                            <Select data={this.state.departmentPerson} placeholder="部门-人员" tFoundContent={fetchingdep ? this.notFount() : this.notFoundContent()} value={params.userId} onDropdownVisibleChange={this.getDetPerData}
                                    selectMethod={this.selectChange.bind(this, 'userId')} style={{width: '220px'}} />
                        </span>
                        <span className="contract-col">
                            <a onClick={this.resetParams}>重置</a>
                        </span>
                    </div>
                </div>
                <div className="contract-tag">
                    {this.state.category.map(per => {
                        return <Tag key={per.id} className={per.id === params.currentCategory ? 'active' : ''}
                                    onClick={this.changeCategory.bind(this, per.id)}
                                >
                                    {per.name}（{per.num}）
                                </Tag>
                    })}
                </div>
                <div className="contract-table">
                    <Table columns={this.state.columns} data={this.state.data} total={params.total} change={this.onChange} current={params.page} />
                </div>
                <SignoutModal show={this.state.showModal} close={this.modalClose} info={this.state.signoutInfo} />
            </div>
        )
    }
}

export default AuditList