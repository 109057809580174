import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Button, Input, Modal, Switch, Select, TreeSelect } from 'antd';
import Table from 'CMPT/common/Table';
import moment from 'moment';
import AuthContainer from 'CMPT/common/auth/AuthContainer';
import { AUTH_KEYS } from '@/utils/auth';
import http from '@/http';
import { API } from '@/http/api';
import './SettingOrganizationStaff.less';

const BreadcrumbItem = Breadcrumb.Item;
const Search = Input.Search;
const Option = Select.Option;

export default function SettingOrganizationStaff() {
    const [data, setData] = useState([]);
    const [pager, setPager] = useState({ total: 0, pageNo: 1 });

    const [searchValue, setSearchValue] = useState();
    const [departmentValue, setDepartmentValue] = useState();
    const [roleValue, setRoleValue] = useState();
    const [stateValue, setStateValue] = useState();

    const [roleList, setRoleList] = useState([]);

    const [showDeleteConfirm, toggleDeleteConfirm] = useState(false);
    const [deleteLoading, toggleDeleteLoading] = useState(false);
    const [showDeleteTitle, toggleDeleteTitle] = useState('');

    const currentStaffId = useRef();
    const currentPageSize = useRef(10);
    const currentSearchValue = useRef();

    useEffect(() => {
        resetCurrent();
        loadRole();
    }, []);

    function resetCurrent() {
        currentStaffId.current = null;
        currentPageSize.current = 10;
        currentSearchValue.current = null;
    }

    // 角色列表-筛选 
    function loadRole() {
        http({
            api: API.roleGetList,
            data: {
                pageNo:1,
                pageSize:10
            },
            showLoading: true
        }).then(ajaxData => {
            const data = ajaxData.data;
            const datas = (data && data.datas) || [];
            setRoleList(datas);
        });
    }

    // 员工列表
    function loadTable(pageNo = 1) {
        http({
            api: API.userGetList,
            data: {
                pageNo,
                pageSize: currentPageSize.current,
                nameLike: currentSearchValue.current || null,
                roleEncode: roleValue,
            },
            showLoading: true
        }).then(ajaxData => {
            const resData = ajaxData.data;
            if (resData) {
                const staffs = resData.datas || [];
                staffs.forEach(person => person.stateLoading = false);
                const total = resData.totalRecord || 0;
                setData(staffs);
                setPager({ total, pageNo });
            }
        });
    }

    const columns = [{
        title: '账号',
        dataIndex: 'username',
        width: '10%',
    }, {
        title: '员工姓名',
        dataIndex: 'name',
        width: '10%',
    }, {
        title: '身份证号',
        dataIndex: 'identity',
        width: '15%',
    }, {
        title: '联系方式',
        dataIndex: 'mobile',
        width: '10%',
    }, {
        title: '角色',
        dataIndex: 'roleName',
        width: '10%',
    }, {
        title: '创建时间',
        dataIndex: 'createTime',
        width: '15%',
        render(text) {
            return formatTime(text);
        }
    }, {
        title: '状态',
        dataIndex: 'status',
        width: '15%',
        render: (text, record, index) => {
            const { id, stateLoading } = record;
            let checked;
            if(text==1){
                checked = true;
            }
            if(text==2){
                checked = false;
            }

            return <><span className='status-tip'>{checked ? '启用' : '停用'}</span><Switch checked={checked} loading={stateLoading} onChange={(checked) => changeStaffStatus(checked, id, index)} /></>
        }
    }, {
        title: '操作',
        key: 'operate',
        width: '15%',
        render: (text, record, index) => {
            const staffId = record.id;
            return [
                // <AuthContainer key='modify' authCodes={AUTH_KEYS.SETTING_ORGANIZATION_STAFF_MODIFY}>
                    <Link to={'/app/crm/setting/staff/modify/' + staffId} className='staff-operate'>编辑</Link>,
                // </AuthContainer>,
                // <AuthContainer key='delete' authCodes={AUTH_KEYS.SETTING_ORGANIZATION_STAFF_DELETE}>
                    <span className='staff-operate' onClick={() => showDeleteStaffConfirm(record)}>删除</span>
                // </AuthContainer>,
            ]
        }
    }];

    function changeStaffStatus(checked, id, index) {
        http({
            api: API.userSetStatus,
            path: {
                id:id
            },
            data: {
                status: checked ? 1 : 2
            },
            showLoading: true,
            successTip: '操作成功'
        }).then(result => {
            if (result.success) {
                // person.status = checked;
                loadTable();
            }
            // person.stateLoading = false;
            // setData(nextData);
        }).catch(error => {
            // const nextData = [...data];
            // const person = nextData[index];
            // person.stateLoading = false;
            // setData(nextData);
        });
    }

    function showDeleteStaffConfirm(record) {
        toggleDeleteTitle('确认删除【'+ record.username +'】账号？删除后该账号将不能登录该系统');
        toggleDeleteConfirm(true);
        currentStaffId.current = record.id;
    }

    function deleteStaff() {
        if (currentStaffId.current) {
            toggleDeleteLoading(true);

            http({
                api: API.userDelete,
                path: {
                    idCode: currentStaffId.current
                },
                showLoading: true,
                successTip: '删除成功'
            }).then(result => {
                toggleDeleteLoading(false);
                if (result.success) {
                    hideDeleteStaffConfirm();
                    loadTable();
                }
            }).catch(erorr => {
                toggleDeleteLoading(false);
            });
        }
    }

    function hideDeleteStaffConfirm() {
        toggleDeleteConfirm(false);
        currentStaffId.current = null;
    }

    function changeSearch(e) {
        setSearchValue(e.target.value);
    }

    function search(likeName) {
        currentSearchValue.current = likeName;
        loadTable();
    }

    function changePager(pageNo, pageSize) {
        currentPageSize.current = pageSize;
        loadTable(pageNo, pageSize);
    }

    useEffect(() => {
        loadTable();
    }, [departmentValue, roleValue, stateValue]);

    function reset() {
        currentSearchValue.current = null;
        setSearchValue();
        if (departmentValue || roleValue || stateValue) {
            // 这3个值改变会主动触发加载，不需要主动调用加载列表的方法
            setDepartmentValue();
            setRoleValue();
            setStateValue();
        } else {
            // 需主动调用加载列表的方法，以防search重置
            loadTable();
        }
    }

    return (
        <div className='setting-organization-staff'>
            <div className='staff-header'>
                <Breadcrumb>
                    <BreadcrumbItem>组织管理</BreadcrumbItem>
                    <BreadcrumbItem>员工管理</BreadcrumbItem>
                </Breadcrumb>
                {/* <AuthContainer authCodes={AUTH_KEYS.SETTING_ORGANIZATION_STAFF_NEW}> */}
                    <Link to='/app/crm/setting/staff/new' className='new-staff-btn' >
                        <Button type="primary">
                            新增
                        </Button>
                    </Link>
                {/* </AuthContainer> */}
            </div>
            <div className='staff-main'>
                <div className='staff-filter'>
                    <Search
                        placeholder="请输入账号/员工姓名"
                        value={searchValue}
                        onChange={changeSearch}
                        onSearch={search}
                        className='staff-search'
                    />
                    {/* <TreeSelect allowClear placeholder="所属部门" dropdownStyle={{maxHeight:'60vh'}} className='staff-filter-department' treeData={departmentList} value={departmentValue} onChange={setDepartmentValue} /> */}
                    <Select allowClear placeholder="角色" className='staff-filter-role' value={roleValue} onChange={setRoleValue} >
                        {
                            roleList.map(({ id, name }) => (<Option value={id} key={id}>{name}</Option>))
                        }
                    </Select>
                    <span className='staff-filter-reset' onClick={reset}>重置</span>
                </div>
                <Table showEmpty selfProcessData={data} columns={columns} total={pager.total} current={pager.pageNo} change={changePager} rowKey='idCode' />
            </div>
            <Modal
                title={showDeleteTitle}
                visible={showDeleteConfirm}
                okText='删除'
                confirmLoading={deleteLoading}
                closable={false}
                maskClosable={false}
                onOk={deleteStaff}
                width={400}
                onCancel={hideDeleteStaffConfirm}
            >
            </Modal>
        </div>
    );

}
function formatTime(time) {
    if (time) {
        return moment(time).format('YYYY-MM-DD HH:mm');
    }
}