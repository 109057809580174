import React, { Component } from "react";
import Viewer from "viewerjs";
import PropTypes from 'prop-types'
import "viewerjs/dist/viewer.css";
import "./SingleImageViewer.less";

class SingleImageViewer extends Component {
    state = {
        viewer: null,
        eleId:'image'+ (Date.now()+Math.random+'')+(~~(Math.random()*10000)+'') //动态生成Image id 否则所有image ID一致 viewerjs 就无法正常运作
    }

    registerBackListener() {
        if (window&&window.history && window.history.pushState) {
            //history.pushState(null, null, document.URL);
            window.addEventListener('popstate', this.onBack, false);
        }
    }

    componentWillMount(){
        Viewer.noConflict();
    }
    componentWillUnmount() {
        if(window){
            window.removeEventListener('popstate', this.onBack, false);
        }

        if (this.state.viewer) {
            this.state.viewer.destroy();
        }
    };

    onBack = () => {
        if (this.state.viewer) {
            try {
                this.state.viewer.hidden();
                
            } catch (error) {
                
            }
        }
    }

    componentDidMount() {
        const ViewerDom = document.getElementById(this.state.eleId);


        const viewer = new Viewer(ViewerDom, {
            url: "data-original",
            viewed() {
                viewer.zoomTo(1);
            },
            oneToOne: true,
            toolbar: true,
            title: false,
            navbar: false,
            rotatable: true,
            rotateLeft: 15,
            rotateRight: 15,
            play: false,
            prev: false,
            next: false,
            transition: false
        });

        this.setState({
            viewer
        }, () => this.registerBackListener());
    }

    render() {
        return <div>
            <img id={this.state.eleId} style={{ width: (this.props.width ? '' + this.props.width : '120') + 'px', height: (this.props.height ? '' + this.props.height : '90') + 'px' }} src={this.props.imgUrl} data-original={this.props.bigImgUrl} />
        </div>
    }

}


SingleImageViewer.propTypes = {
    imgUrl: PropTypes.string.isRequired,
    bigImgUrl: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number

};


export default SingleImageViewer