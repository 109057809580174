import React, { Component } from 'react';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/line';
import 'echarts/lib/component/tooltip';
import { Icon, Tooltip, DatePicker } from 'antd';
import img from '@/images/placeholder_chart@2x.png'
const { RangePicker } = DatePicker;

export default class Line extends Component{
    state={
        hideCanvas:false
    }
    componentWillReceiveProps(nextProps) {
        // console.log(nextProps)
        const { text, mainColor, lineColor, fontColor, xaxisData, yaxisData } = nextProps.options        
        var result = yaxisData.some(res=>{
            if(res>0){
                return true
            }
        })
        if(!result){
            this.setState({
                hideCanvas:true,
            })
        }else{
            this.setState({
                hideCanvas:false
            },()=>{
                if(document.getElementById(this.props.options.id)){
                
                    const myLineChart = echarts.init(document.getElementById(this.props.options.id))
                    const lineOptions = {
                        grid: {
                            containLabel:true,
                            left:0,
                            right:1,
                            top:20,
                            bottom:0,
                        },
                        tooltip : {
                            trigger: 'axis',
                            axisPointer:{
                                type:'line',
                                lineStyle:{
                                    color:'#535465',                              
                                },
                                label:{
                                    fontSize: 13,
                                    color: '#E0E4F1'
                                }
                            },       
                            backgroundColor: '#fff',
                            borderWidth:1,
                            borderColor:'#535465',
                            padding: [5,12],                     
                            textStyle: {
                                fontSize: 12
                            },
                            formatter: function (params, ticket, callback) {
                                // console.log(params[0])
                                return params[0].axisValue.slice(0,7)+"<br> 出租率："+params[0].value+"%";
                            }
                        },
                        calculable : true,
                        xAxis : [
                            {
                                type : 'category',
                                boundaryGap :  true,
                                data: xaxisData,
                                axisTick : {
                                    show:false
                                },
                                axisLabel: {
                                    color: fontColor,
                                    formatter: function (params) {
                                        
                                        return params.slice(0,7);
                                    }
                                },
                                axisLine: {
                                    show: true,
                                    lineStyle: {
                                        color: lineColor,
                                        type: 'solid',
                                        width: 1
                                    }
                                }
                            }
                        ],
                        yAxis : [
                            {
                                type : 'value',
                                axisLabel : {
                                    formatter: '{value}%',
                                    color: fontColor
                                },
                                axisTick : {
                                    show:false
                                },
                                axisLine: {
                                    show: true,
                                    lineStyle: {
                                        color: lineColor,
                                        type: 'solid',
                                        width: 1
                                    }
                                },
                                splitLine: {
                                    show: true,
                                    lineStyle: {
                                        color: [lineColor]
                                    }
                                }
                            },
                            {
                                axisLine: {
                                    show: true,
                                    lineStyle: {
                                        color: lineColor,
                                        type: 'solid',
                                        width: 1
                                    }
                                }
                            }
                        ],
                        series : [
                            {
                                name: text,
                                type:'line',
                                data: yaxisData,
                                itemStyle: {
                                    normal: {
                                        color: mainColor,
                                        lineStyle: {
                                            width: 1,
                                            color: mainColor,
                                            // shadowColor: mainColor,
                                            // shadowBlur: 20,
                                            // shadowOffsetX: 10,
                                            // shadowOffsetY: 10
                                        }
                                    }
                                }
                            }
                        ]
                    }
                    myLineChart.setOption(lineOptions)
                }
            })
        }
    }

    render() {
        return (
            <div className="echart-bak">
                <div className="chart-title">
                    <span></span>
                    {this.props.options.title}
                    <Tooltip title={this.props.options.reminderInfo} placement="right" arrowPointAtCenter>
                        <Icon type="question-circle" className="chart-circle" 
                          style={{display : this.props.options.showReminder ? 'inline-block' : 'none'}}/>
                    </Tooltip>
                    <RangePicker
                            className="chart-title-month-picker"
                            placeholder={['开始月份', '结束月份']}
                            format="YYYY-MM月"
                            value={this.props.monthValues}
                            mode={['month', 'month']}
                            onOpenChange={this.props.handleOpenChange}
                            onPanelChange={this.props.handleMonthPanelChange}
                            onChange={this.props.clearRangePicker}
                            // open={this.props.isOpenRangePicker}
                            allowClear={false}
                            style={{display : this.props.options.showMonthPicker ? 'inline-block' : 'none'}}
                        />
                </div>
                {
                    this.state.hideCanvas?<div style={{ width: this.props.options.width, height: this.props.options.height ,textAlign:'center',paddingTop:'100px'}}>
                        <img src={img} width="104" height="88" alt=""/>
                        <p>暂无数据</p>
                    </div>:<div key={this.state.hideCanvas} id={this.props.options.id} style={{ width: this.props.options.width, height: this.props.options.height }}></div>
                }
            </div>
        );
    }   
}
