import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Button, Input, Modal, Icon, Select, DatePicker, Tooltip } from 'antd';
import moment from 'moment';
import Table from 'CMPT/common/Table';
import http from '@/http';
import { API } from '@/http/api';
import AuthContainer from 'CMPT/common/auth/AuthContainer';
import { AUTH_KEYS } from '@/utils/auth';
import userUtil from '@/utils/user';
import useActionLoading from 'CMPT/common/hooks/useActionLoading';
import './SettingApproveManage.less'

const BreadcrumbItem = Breadcrumb.Item;
const Search = Input.Search;
const Option = Select.Option;
const { RangePicker } = DatePicker;

export default function SettingApproveManage({ history }) {
    const [data, setData] = useState({ datas: [], total: 0, pageNo: 1 });
    const [enums, setEnums] = useState({ status: [], type: [] });

    const [searchValue, setSearchValue] = useState();
    const [typeValue, setTypeValue] = useState();
    const [stateValue, setStateValue] = useState();
    const [createTimeValue, setCreateTimeValue] = useState();

    const [showOnlineTip, toggleOnlineTip] = useState(false);

    const [showDeleteConfirm, toggleDeleteConfirm] = useState(false);
    const [deleteLoading, takeDeleteAction] = useActionLoading(false);

    const currentManageId = useRef();
    const currentPageSize = useRef(10);
    const currentSearchValue = useRef();
    const noAuthPeople = useRef([]);

    useEffect(() => {
        resetCurrent();
        initEnums();
    }, []);

    useEffect(() => {
        loadTable();
    }, [typeValue, stateValue, createTimeValue]);

    function resetCurrent() {
        currentManageId.current = null;
        currentPageSize.current = 10;
        currentSearchValue.current = null;
        noAuthPeople.current = [];
    }

    function initEnums() {
        http({
            api: API.flowTemplateInit,
            showLoading: true
        }).then(data => {
            let { status,
                type } = data.data || {};

            status = status || [];
            status.unshift({
                value: '全部',
                key: ''
            });

            type = type || [];
            type.unshift({
                value: '全部',
                key: ''
            });

            setEnums({
                status,
                type
            })
        });
    }

    function loadTable(pageNo = 1) {
        let createTimeEnd;
        let createTimeStart;
        if (createTimeValue && createTimeValue.length === 2) {
            createTimeStart = createTimeValue[0].format('YYYY-MM-DD') + ' 00:00:00';
            createTimeEnd = createTimeValue[1].format('YYYY-MM-DD') + ' 23:59:59';
        }
        http({
            api: API.flowTemplateList,
            data: {
                pageNo,
                pageSize: currentPageSize.current,
                key: currentSearchValue.current || null,
                status: stateValue,
                type: typeValue,
                createTimeStart,
                createTimeEnd
            },
            showLoading: true
        }).then(data => {
            setData(data.data || ({ datas: [], total: 0, pageNo: 1 }));
        });
    }

    const columns = [{
        title: '审批模板名称',
        dataIndex: 'name',
        render(text, record) {
            return (<Link to={'/app/setting/approve/manage/read/' + record.encode} className='manage-name'>{text}</Link>);
        }
    }, {
        title: '审批类型',
        dataIndex: 'typeDesc',
    }, {
        title: '状态',
        dataIndex: 'status',
        render(text, { statusDesc }) {
            switch (text) {
                case 0:
                    return <span className='state-ready'>{statusDesc}</span>;
                case 1:
                    return <span className='state-online'>{statusDesc}</span>;
                case 2:
                    return <span className='state-using'>{statusDesc}</span>;
                default:
                    return '';
            }
        }
    }, {
        title: '所属项目',
        dataIndex: 'projectList',
        className: 'project-info',
        render(text) {
            const info = (text || []).join('、');
            if (info) {
                return (
                    <Tooltip title={info} overlayClassName='info-tooltip' >
                        {info}
                    </Tooltip>);
            }
            return null;
        }
    }, {
        title: '创建时间',
        dataIndex: 'createTime',
        render(text) {
            return formatTime(text);
        }
    }, {
        title: '修改时间',
        dataIndex: 'modifyTime',
        render(text) {
            return formatTime(text);
        }
    }, {
        title: <span>审批数量 <Tooltip title='该审批模板处理过的工单总数（括号内为当前进行中的工单数）' overlayClassName='num-tooltip'><Icon type="question-circle" /></Tooltip></span>,
        render(text, { verifyNum, pendingNum }, index) {
            if (!verifyNum && !pendingNum) {
                return '--';
            }
            return `${verifyNum}(${pendingNum})`;
        }
    }, {
        title: '操作',
        key: 'operate',
        render(text, record, index) {
            if (userUtil.checkAuthByKey(AUTH_KEYS.SETTING_APPROVE_CONFIG)) {
                // 对不同的状态显示不同的操作按钮：
                // 1）未上线：【上线】、【编辑】、【复制】、【删除】；
                // 2）已上线：【下线】、【复制】；
                // 3）使用中：【复制】；

                const manageId = record.encode;

                const online = <span key='online' className='manage-operate' onClick={() => onlineManage(manageId)}>上线</span>;
                const offline = <span key='offline' className='manage-operate' onClick={() => offlineManage(manageId)}>下线</span>;
                const copy = <Link key='copy' to={'/app/setting/approve/manage/copy/' + manageId} className='manage-operate'>复制</Link>;
                const modify = <Link key='modify' to={'/app/setting/approve/manage/modify/' + manageId} className='manage-operate'>编辑</Link>;
                const remove = <span key='delete' className='manage-operate' onClick={() => displayDelete(manageId)}>删除</span>;

                switch (record.status) {
                    case 0:
                        return [online, modify, copy, remove];
                    case 1:
                        return [offline, copy];
                    case 2:
                        return [copy];
                    default:
                        return [];
                }
            }
        }
    }];

    function displayDelete(encode) {
        currentManageId.current = encode;
        toggleDeleteConfirm(true);
    }

    function onlineManage(encode) {
        http({
            api: API.flowTemplateOnline,
            data: {
                encode
            },
            showLoading: true,
            successTip: '上线成功'
        }).then(ajaxData => {
            if (ajaxData.success) {
                loadTable();
            } else if (Array.isArray(ajaxData.data)) {
                currentManageId.current = encode;
                noAuthPeople.current = [];
                ajaxData.data.forEach(name => {
                    noAuthPeople.current.push(<span key={name} className='no-auth-person'>{name}</span>, '、');
                });
                noAuthPeople.current.pop();
                toggleOnlineTip(true);
            }
        });
    }

    function offlineManage(encode) {
        http({
            api: API.flowTemplateOffline,
            data: {
                encode
            },
            showLoading: true,
            successTip: '下线成功'
        }).then(ajaxData => {
            if (ajaxData.success) {
                loadTable();
            }
        });
    }

    function deleteManage() {
        if (currentManageId.current) {
            takeDeleteAction({
                api: API.flowTemplateDelete,
                data: {
                    encode: currentManageId.current
                },
                showLoading: true,
                successTip: '删除成功'
            }, () => {
                hideDeleteConfirm();
                loadTable();
            });
        }
    }

    function goEdit() {
        const id = currentManageId.current;
        hideOnlineTip();
        history.push('/app/setting/approve/manage/modify/' + id);
    }

    function hideOnlineTip() {
        toggleOnlineTip(false);
        currentManageId.current = null;
    }

    function hideDeleteConfirm() {
        toggleDeleteConfirm(false);
        currentManageId.current = null;
    }

    function search(likeName) {
        currentSearchValue.current = likeName;
        loadTable();
    }

    function changeSearch(e) {
        setSearchValue(e.target.value);
    }

    function changeTypeValue(value) {
        value = handleAll(value);
        setTypeValue(value);
    }

    function handleAll(value) {
        // 点击全部要回到palceholder状态，产品和UI合计后要加的！！！
        if (value === '') {
            value = undefined;
            if (typeValue === value) {
                loadTable();
            }
        }
        return value;
    }

    function changeStateValue(value) {
        value = handleAll(value);
        setStateValue(value);
    }

    function changeDateRange(dates, datesStr) {
        setCreateTimeValue(dates);
    }

    function reset() {
        currentSearchValue.current = null;
        setSearchValue();
        if (typeValue !== undefined || stateValue !== undefined || createTimeValue) {
            setTypeValue();
            setStateValue();
            setCreateTimeValue();
        } else {
            loadTable();
        }
    }

    function changePager(pageNo, pageSize) {
        currentPageSize.current = pageSize;
        loadTable(pageNo, pageSize);
    }



    return (
        <div className='setting-approve-manage'>
            <div className='manage-header'>
                <Breadcrumb>
                    <BreadcrumbItem>审批管理</BreadcrumbItem>
                    <BreadcrumbItem>审批模板管理</BreadcrumbItem>
                </Breadcrumb>
                <AuthContainer authCodes={AUTH_KEYS.SETTING_APPROVE_CONFIG}>
                    <Link to='/app/setting/approve/manage/new' className='new-manage-btn' >
                        <Button type="primary">
                            新增审批模板
                        </Button>
                    </Link>
                </AuthContainer>
            </div>
            <div className='manage-main'>
                <div className='manage-filter'>
                    <Search
                        placeholder="请输入审批模板名称/所属项目"
                        value={searchValue}
                        onChange={changeSearch}
                        onSearch={search}
                        className='manage-search'
                    />
                    <Select placeholder="审批类型" className='manage-filter-type' value={typeValue} onChange={changeTypeValue}>
                        {
                            enums.type.map(({ key, value }) => (<Option value={key} key={key}>{value}</Option>))
                        }
                    </Select>
                    <Select placeholder="状态" className='manage-filter-state' value={stateValue} onChange={changeStateValue}>
                        {
                            enums.status.map(({ key, value }) => (<Option value={key} key={key}>{value}</Option>))
                        }
                    </Select>
                    创建时间
                    <RangePicker className='manage-filter-date' value={createTimeValue} onChange={changeDateRange} />
                    <span className='manage-filter-reset' onClick={reset}>重置</span>
                </div>
                <Table showEmpty selfProcessData={data.datas || []} current={data.pageNo} total={data.total} columns={columns} change={changePager} rowKey='encode' />
            </div>
            <Modal
                title={<span>{noAuthPeople.current}不具备所选项目或所选业务的操作权限，请修改审批模板。</span>}
                visible={showOnlineTip}
                closable={false}
                maskClosable={false}
                width={400}
                okText='立即修改'
                cancelText='暂不修改'
                onOk={goEdit}
                onCancel={hideOnlineTip}
            />
            <Modal
                title="确认删除此模板？"
                visible={showDeleteConfirm}
                okText='删除'
                confirmLoading={deleteLoading}
                width={400}
                closable={false}
                maskClosable={false}
                onOk={deleteManage}
                onCancel={hideDeleteConfirm}
            />
        </div>
    );
}

function formatTime(time) {
    if (time) {
        return moment(time).format('YYYY-MM-DD');
    }
    return '';
}
