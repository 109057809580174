import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import "./VehicleDetail.less";
import { API } from "@/http/api";
import http from "@/http";
import { Breadcrumb, Button, Modal, Input, message, Select, Spin } from "antd";
import IMGurl from "@/images/empty_schedule@2x.png";
import { showErrorMessage } from "@/utils/globalMessageReminder";
import { formatLinkSearch } from "@/utils";
import moment from "moment";
const { TextArea } = Input;
const { Option } = Select;

const topVehicleInfoData = [
  { label: "车型", field: "typeName" },
  { label: "车牌号", field: "plate" },
  { label: "车架号", field: "vinNo" },
  { label: "是否拖挂", field: "withTrailerName" },
]

const bottomVehicleInfoData = [
  { label: "车长", field: "length" },
  { label: "车宽", field: "width" },
  { label: "车高", field: "hight" },
  { label: "车轴", field: "axleName" },
  { label: "国标", field: "nsName" },
  { label: "燃油", field: "fuelName" },
  { label: "功能", field: "functionName" },
]

// 是拖挂
const isTrailerVehicleInfoData = [
  ...topVehicleInfoData,
  { label: "空车质量", field: "emptyMass" },
  { label: "准牵引总质量", field: "tractionMass" },
  ...bottomVehicleInfoData
];

// 非拖挂
const noTrailerVehicleInfoData = [
  ...topVehicleInfoData,
  { label: "核载重量", field: "deadWeight" },
  { label: "车货总质量", field: "totalMass" },
  ...bottomVehicleInfoData
]


class VehicleDetail extends Component {
  state = {
    code: null, // 当前页面的ID,从前一个页面传递过来的
    pageNo: null, // 当前企业在前一个列表中的页码
    currentageCount: null, // 前一个页面中 列表内当前页的数量
    backPageNo: 1, //返回列表页面的页码
    rejectInput: "", // 驳回原因
    data: [],
    // goodsOwnerCard: null,
    showRejectConfirmFlag: false, // 是否展示 驳回 对话框
    auditRecords: [], // 审核记录
    rateMethod: [
      // 费率方式
      { name: "单笔百分比", value: "1" },
      // { name: '单笔固定值', value: 2 },
      // { name: '单笔固定值+单笔百分比', value: 3 },
      // { name: '单笔百分比+封顶值', value: 4 },
    ],
    previewVisible: false,
    IMGURL: "", // 缩放图
    spinning: false, // 是否为加载中
    vehicleInfoData: []
  };
  componentWillMount() {
    this.cDetail();

    if (this.props.location.search) {
      // 获取参数
      const search = this.props.location.search;
      const formatData = formatLinkSearch(search);

      // 解析
      let code1 = this.props.match.params.code;
      let pageNo1 = null;
      let currentageCount1 = null;

      if (formatData && formatData.pageNo) {
        pageNo1 = formatData.pageNo;
      }
      if (formatData && formatData.currentPageCount) {
        currentageCount1 = formatData.currentPageCount;
      }

      // 赋值
      this.setState({
        code: code1,
        pageNo: pageNo1,
        backPageNo: pageNo1, //如果什么都不做，返回列表的原始页码即可。否则，需要判断跳转哪一页，尤其删除一个页面中的唯一一条数据
        currentageCount: currentageCount1,
      });
    }
  }
  componentDidMount() {}

  cDetail = () => {
    console.log(this.props, 'this.props', this.props.match.params.code, '请求数据')
    const config = {
      api: API.vAndEVehicleDetail,
      path: { code: this.props.match.params.code }, 
    };
    http(config).then((res) => {
      if (res && res.code == 200) {
        let { data } = res;
        let vehicleInfoData = noTrailerVehicleInfoData;

        if(data.withTrailer) {
          vehicleInfoData = isTrailerVehicleInfoData
        }
        this.setState({
          data,
          vehicleInfoData
        });
      }
    });
  };


  cencel = () => {
    // console.log("点击取消...");
  };
  /* start: 审核驳回的相关逻辑  */

  showRejectAuthDialog = () => {
    this.setState({ showRejectConfirmFlag: true });
  };

  // 设置图片
  closeImagePreview = () => {
    this.setState({ previewVisible: false });
  };
  // 设置图片缩放
  cImage = (value) => {
    if (!value) return;
    this.setState({
      previewVisible: true,
      IMGURL: value,
    });
  };
 

 
  render() {
    const {
      IMGURL,
      data,
      rateMethod,
      previewVisible,
      spinning,
      vehicleInfoData,
    } = this.state;
    return (
      <div className="accountInfo">
        <Spin className="spin" spinning={spinning}>
          <div>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link
                  to={{ pathname: "/app/crm/vehicleAndEquipment", search: "?tab=1" }}
                >
                  车辆管理
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>车辆详情</Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <div className="userInfo_body">
            {
              <div>
                <div>
                  <h2>
                    <i></i> 车辆信息
                  </h2>
                  <div>
                    <ul>
                      {vehicleInfoData.map((item) => {
                          return (
                            <li key={item.label}>
                              <span>{item.label}</span>
                              <span className="span">{data[item.field]}</span>
                            </li>
                          );
                        })}
                      <li className="body_img">
                        <span>车辆照片</span>
                        {
                          data.vechileImgList &&
                            data.vechileImgList.map((item,idx) => {
                            return <img 
                              key={idx}
                              src={ item } alt="" 
                              onClick={() =>
                              this.cImage(item)
                              }/>
                            })
                        }
                      </li>
                      <li className="body_img">
                        <span>行驶证</span>
                          {data.vehicleLicenseFront && (
                              <img
                                src={data.vehicleLicenseFront }
                                alt=""
                                onClick={() => this.cImage(data.vehicleLicenseFront )}
                              />
                          )}
                          {data.vehicleLicenseBack && (
                              <img
                                src={data.vehicleLicenseBack }
                                alt=""
                                onClick={() => this.cImage(data.vehicleLicenseBack )}
                              />
                          )}
                      </li>
                      <li className="body_img">
                        <span>营运许可证</span>
                        <img
                          src={
                            data.businessLicenseImg
                          }
                          alt=""
                          onClick={() =>
                            this.cImage(data.businessLicenseImg)
                          }
                        />
                      </li>
                      <li className="body_img">
                        <span>车辆强制险</span>
                        {/* vehicleCiov */}
                        {
                          data.vehicleCiov &&
                            data.vehicleCiov.map((item,idx) => {
                            return <img 
                              key={idx}
                              src={ item } alt="" 
                              onClick={() =>
                              this.cImage(item)
                              }/>
                            })
                        }
                      </li>
                      
                    </ul>
                  </div>
                </div>
              </div>
            }
          </div>
          {
            <div className="detail_btn">
              <Button onClick={() => this.props.history.goBack()}>
                返回
              </Button>
            </div>
          }
        </Spin>
        <Modal
          visible={previewVisible}
          title=" "
          footer={null}
          onCancel={this.closeImagePreview}
        >
          <img
            alt="图片预览"
            style={{ width: "100%", margin: "0 0 24px" }}
            src={IMGURL}
          />
        </Modal>
      </div>
    );
  }
}
export default VehicleDetail;
