import React, { Component } from 'react';
import { Breadcrumb, Modal, Button, Input, Tree, List, message, Form, Select } from 'antd';
import './RuleCenter.less';
import CategoryTree from './category/CategoryTree'
import { API } from '@/http/api';
import http, { ajax } from '@/http';
import storage from '@/utils/storage';
// import message from '../../../http/apilist/message';



import userUtils from '@/utils/user';
import { AUTH_KEYS } from "@/utils/auth";
const { checkAuthByKey } = userUtils;
const canShowAddAuth = checkAuthByKey(AUTH_KEYS.RULE_MANAGE_CATEGORY_MANAGE); // 新增目录；


const { TreeNode } = Tree;
const BreadcrumbItem = Breadcrumb.Item;
const pageClass = 'address_rule';
const { Option } = Select;


class RuleCenter extends React.Component {
    state = {
        gData: [],
        modalMsg: {
			modalType: 0,
			title: '添加一级目录',
			tips: '注：一级目录一般做为“分类”，规则内容只可关联在子目录下',
			catalogId: null,
			disable: false,
			showSecond: false
		},
		modalMsgArr: [
			{
				modalType: 0,
				title: '添加一级目录',
				tips: '注：一级目录一般做为“分类”，规则内容只可关联在子目录下',
				catalogId: null,
				disable: false,
				showSecond: false,
			},
			{
				modalType: 1,
				title: '编辑目录',
				tips: null,
				catalogId: null,
				disable: false,
				showSecond: false,
			},
			{
				modalType: 2,
				title: '添加子目录',
				tips: null,
				catalogId: null,
				disable: true,
				showSecond: true,
			},
			{
				modalType: 3,
				title: '编辑目录',
				tips: null,
				catalogId: null,
				disable: false,
				showSecond: true
			}
		],
		ruleModalShow: false,
		categoryMsg: {
			code: null,
			title: null,
			parentCategoryCode: undefined
		},
		// 一级规则列表
		parentCategoryList: [],
		modalLoading: false
	}
   
	componentDidMount () {
		// console.log(this.state.gData, 'data')
		this.getTreeData()
		// this.initTreeData()
	}
	// 类目列表
	getTreeData = () => {
        http({
			api: API.ruleCategoryGetList,
			showLoading: true
		}).then(res => {
			if (res.success) {
				this.initTreeData(res.data || [])
				let parentCategoryList = [];
				res.data && res.data.map(item => {
					parentCategoryList.push({code: item.code, title: item.title})
				})
				this.setState({parentCategoryList})
			}
		});
	}
	// 初始化数据
	initTreeData = (gData) => {
			var gData = gData || this.state.gData;
			gData && gData.map(item => {
					item.key = item.code;
					item.level = 1;
					if (!item.children) {item.children = []}
					item.children && item.children.length > 0 && item.children.map(child => {
						if (child) {
							child.key = child.code;
							child.level = 2;
							if (child.children) {
								child.children = null;
							}
						}
					})
			})
			this.setState({
				gData
			})
	}

	handleCancel = () => {
		this.setState({ruleModalShow: false})
	}

	handleSubmit = e => {
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
		  if (!err) {
			// console.log('Received values of form: ', values);
			values.parentCategoryCode = values.parentCategoryCode || '-1';
			this.CategoryAjax(values)
			this.setState({modalLoading: true})
		  }
		});
	};
	// 目录调整ajax
	CategoryAjax = (ajaxConfig) => {
		const { modalMsg, categoryMsg } = this.state;
		let apiUrl = null;
		let successTip = null;
		if (modalMsg.modalType == 0 || modalMsg.modalType == 2) {
			apiUrl = API.ruleCategoryAdd;
			successTip = '添加成功';
		} else {
			ajaxConfig.code = categoryMsg.code;
			apiUrl = API.ruleCategoryUpdate;
			successTip = '编辑成功';
		}
		http({
			api: apiUrl,
			data: ajaxConfig,
			successTip: successTip
		}).then(res => {
			if (res.success) {
				this.getTreeData()
				this.setState({
					ruleModalShow: false
				})
			}
			this.setState({modalLoading: false})

		});
	}
	// 编辑 / 新增
	showModalGoCategoryDoing = (type, item) => {
		let categoryMsg = {};
		if (type == 1) {
			categoryMsg.title = item.title;
			categoryMsg.code = item.code;
		} else if (type == 2) {
			categoryMsg.title = null;
			categoryMsg.parentCategoryCode = item.code;
			categoryMsg.code = item.code;
		} else if (type == 2 || type == 3) {
			// 获取规则详情
			this.getCategoryInfo(item.code)
			return
		} 
		this.setState({ 
			modalMsg: this.state.modalMsgArr[type],
			ruleModalShow: true,
			categoryMsg
		});
	}
	// 获取规则详情
	getCategoryInfo = (code) => {
		http({
			api: API.ruleCategoryGetInfo,
			data: {
				code: code
			},
			showLoading: true
		}).then(res => {
			if (res.success && res.data) {
				let categoryMsg = {
					code: res.data.code,
					parentCategoryCode: res.data.parentCategoryCode,
					title: res.data.title,
				}
				this.setState({ 
					modalMsg: this.state.modalMsgArr[3],
					ruleModalShow: true,
					categoryMsg
				});
			}
		});
	}


    render() {
		const { modalLoading, gData, modalMsg, categoryMsg, parentCategoryList, ruleModalShow } = this.state;
		const { getFieldDecorator } = this.props.form;
		const formItemLayout = {
			labelCol: {
			  xs: { span: 24 },
			  sm: { span: 4 },
			},
			wrapperCol: {
			  xs: { span: 24 },
			  sm: { span: 16 },
			},
		  };
        return (
            <div className={pageClass}>
				<div className={`${pageClass}-header`}>
					<Breadcrumb>
						<BreadcrumbItem>规则中心</BreadcrumbItem>
						<BreadcrumbItem>目录管理</BreadcrumbItem>
					</Breadcrumb>
					
				</div>
				<div className="tree_box">
					<div className="category_title">
						<span>目录管理</span>
						{
							canShowAddAuth ?
							<Button className={`${pageClass}-edit`} type="primary" onClick={() => {this.showModalGoCategoryDoing(0)}}>添加目录</Button>
							: null
						}
					</div>
					{
						gData && gData.length ?
						<CategoryTree showModalGoCategoryDoing={this.showModalGoCategoryDoing}  getTreeData={this.getTreeData} gData={gData} initTreeData={this.initTreeData} />
						: 
						<div className="empy_box">
							<p>请先添加目录，添加目录后在子目录中可添加规则内容</p>
							{
								canShowAddAuth ?
								<Button className={`${pageClass}-edit`} type="primary" onClick={() => {this.showModalGoCategoryDoing(0)}}>添加目录</Button>
								: null
							}
						</div>
					}
				</div>
				{
					ruleModalShow && <Modal
						title={modalMsg.title}
						visible={ruleModalShow}
						onOk={this.handleSubmit}
						onCancel={this.handleCancel}
						confirmLoading={modalLoading}
					>
						<Form onSubmit={this.handleSubmit} className="catalog-form" {...formItemLayout}>
							<Form.Item label="标题">
								{getFieldDecorator('title', {
									rules: [{ required: true, message: '请填写标题' }],
									initialValue: categoryMsg.title
								})(
									<Input
										placeholder="请输入目录名称，最多16字符"
										maxLength={16}
									/>,
								)}
							</Form.Item>
							{
								modalMsg.tips ? 
								<span>{modalMsg.tips}</span>
								: null
							}
							{
								modalMsg.showSecond ? 
								<Form.Item label="所属目录">
									{getFieldDecorator('parentCategoryCode', {
										rules: [{ required: true, message: '请选择' }],
										initialValue: categoryMsg.parentCategoryCode
									})(
										<Select 
											placeholder="项目空间" 
											disabled={modalMsg.disable}
										>
											{
												parentCategoryList && parentCategoryList.map(item => {
													return <Option key={item.code} value={item.code}>{item.title}</Option>
												})
											}
										</Select>
									)}
								</Form.Item>
								: null
							}
						</Form>
					</Modal>
				}
			</div>
        );
    }
}

export default Form.create({ name: 'rule_form' })(RuleCenter);